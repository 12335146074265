import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import { selectRevenue, setValues } from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

export function RevenueRange({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectRevenue);

    return (
        <Autocomplete
            multiple
            fullWidth
            size="small"
            ChipProps={{ color: "success" }}
            options={Object.keys(options).map((i) => i)}
            value={values.map(({ value }) => value)}
            onChange={(_, value) => {
                dispatch(
                    setValues({
                        key: id,
                        value: value.map((value) => ({ value, excluded: false })),
                    })
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={
                        <Stack direction="row" alignItems="center">
                            <AttachMoneyIcon fontSize="small" htmlColor="green" /> Revenue Range (USD)
                        </Stack>
                    }
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    InputProps={{
                        ...params.InputProps,
                        sx: { fontSize: 14 },
                        endAdornment: params.InputProps.endAdornment,
                    }}
                />
            )}
        />
    );
}
