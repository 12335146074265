export function timeUnitConverter(number : number) {
    const minutes = number % 60;  
    const hours = Math.floor(number / 60) % 24; 
    const days = Math.floor(number / (60 * 24)) % 7;  
    const weeks = Math.floor(number / (60 * 24 * 7));  

    return {
        minutes: minutes,
        hours: hours,
        days: days,
        weeks: weeks,
    };
}



interface TimeConversionResult {
    minutes: string;
    hours: string;
    days: string;
    weeks: string;
}

export function reverseTimeConversion(time: TimeConversionResult): number {
    const totalMinutes =
        Number(time.minutes) + Number(time.hours) * 60 + Number(time.days) * 24 * 60 + Number(time.weeks) * 7 * 24 * 60;
    return totalMinutes;
}
