import { generateCryptoHashId } from "./generate-crypto-hash";

import { SearchState } from "@/store/reducers/search/search.types";
import { SearchType } from "@/store/reducers/searchCandidates/searchCandidates.slice";

async function generateSearchFilterHash(searchState: SearchState, searchType: SearchType): Promise<string> {
    switch (searchType) {
        case "salesnav":
            return await generateCryptoHashId({
                geography: searchState.geography,
                yearsOfExperience: searchState.yearsOfExperience,
                jobTitle: searchState.jobTitle,
                pastJobTitle: searchState.pastJobTitle,
                jobFunction: searchState.jobFunction,
                industry: searchState.industry,
                currentCompany: searchState.currentCompany,
                pastCompany: searchState.pastCompany,
                keywords: searchState.keywords,
                postalCode: searchState.postalCode,
                isFixedSearch: searchState.isFixedSearch,
                profileLanguage: searchState.profileLanguage,
                openToWork: searchState.openToWork,
                companyCsvUrl: searchState.companyCSVUrl,
            });
        case "resume-library":
        case "rocketReach":
        case "monster":
        case "indeed":
        case "zipRecruiter":
        case "salesnavCompany":
            return "";
        default:
            return "";
    }
}

export { generateSearchFilterHash };
