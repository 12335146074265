import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";

import ProjectsDropdown from "../../../components/Dropdowns/ProjectsDropdown";

interface ProjectsDropdownProps {
    value: number;
    onChange: (value: number) => void;
    userId?: number;
    callback?: (value: string) => void;
    disabled?: boolean;
}

export default function ProjectsFilterDropdown(props: ProjectsDropdownProps) {
    const { value, onChange, userId, callback, disabled } = props;
    const { t } = useTranslation();

    return (
        <FormControl sx={{ mx: 1 }} size="small">
            <ProjectsDropdown
                width={240}
                userId={userId}
                disabled={disabled}
                projectId={value}
                handleChange={(value, project) => {
                    onChange(value);
                    callback?.(project.name ?? t("analytics.projectListDropdownLabel"));
                }}
            />
        </FormControl>
    );
}
