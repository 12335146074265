import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DraftsRoundedIcon from "@mui/icons-material/DraftsRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useCallback, useMemo, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

import documents from "../../../assets/img/documents.svg";
import docxIcon from "../../../assets/img/docx_icon.svg";
import pdfIcon from "../../../assets/img/pdf-icon.svg";
import txtIcon from "../../../assets/img/txt-file-icon.svg";
import zipIcon from "../../../assets/img/zip-icon.svg";
import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import TextWithEllipses from "../../../components/TextWithEllipses";
import { RootState, useAppDispatch } from "../../../store";
import { useUploadAttachmentsMutation } from "../../../store/apis/outreach/outreach.api";
import { Attachment, EventNames } from "../../../store/apis/outreach/outreach.types";
import { getFileExtension } from "../../../store/apis/outreach/outreach.utils";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../../utils/helper";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import FileTile from "../../triggerWorkflow/molecules/FileTile";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import {
    removeAttachment,
    selectCurrentOutreachElementAttachments,
    selectCurrentOutreachElementType,
} from "../outreach.slice";

const buttonShouldVisibleFor: EventNames[] = ["email"];

export function AttachmentsModal() {
    const { currentOutreachElementId } = useOutreachParams();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );
    const shouldVisible = buttonShouldVisibleFor.includes(currentElementType);
    const [showModal, setShowModal] = useState(false);
    const handleOpen = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    if (!shouldVisible) {
        return null;
    }

    return (
        <>
            <div>
                <Tooltip title={`Attach ${currentElementType} files`}>
                    <IconButton onClick={handleOpen} sx={{ padding: 0 }}>
                        <DraftsRoundedIcon />
                    </IconButton>
                </Tooltip>
            </div>
            {showModal && (
                <BaseModal onClose={handleClose}>
                    <UploadSection close={handleClose} />
                </BaseModal>
            )}
        </>
    );
}

function UploadSection({ close }: { close: () => void }) {
    const { projectId, currentOutreachElementId } = useOutreachParams();
    const [upload, { isLoading }] = useUploadAttachmentsMutation();
    const [myFiles, setMyFiles] = useState<FileWithPath[]>([]);

    const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
        setMyFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }, []);

    const removeFile = (file: FileWithPath) => () => {
        setMyFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
    };

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            "application/zip": [".zip"],
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        },
        multiple: true,
        maxFiles: 20,
        maxSize: 10000000,
        onDrop,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const files = myFiles.map((file) => {
        return <FileTile key={file.size} name={file.name} handleRemove={removeFile(file)} />;
    });

    const handleSubmit = () => {
        if (myFiles.length) {
            upload({ files: myFiles, projectId, currentOutreachElementId })
                .unwrap()
                .then(() => {
                    close();
                });
        }
    };

    return (
        <Stack spacing={2}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <Stack alignItems="center" sx={{ position: "relative" }}>
                    <Box component="img" src={documents} alt="" sx={{ maxHeight: "200px", maxWidth: "200px" }} />
                    <Typography variant="h4" align="center" sx={{ fontSize: "18px" }}>
                        Drop your pdf, docs here, or{" "}
                        <Typography
                            component="button"
                            variant="h4"
                            sx={{
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                fontWeight: "600",
                                fontSize: "16px",
                            }}
                        >
                            Browse
                        </Typography>
                    </Typography>
                    <Typography variant="body1" align="center">
                        Supports: .pdf, .docx, .doc, .zip
                    </Typography>
                </Stack>
            </div>
            <Stack spacing={1.5}>
                {files}
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                        height: "fit-content",
                        width: "fit-content",
                        alignSelf: "center",
                    }}
                >
                    <ButtonTextWithLoading text="Submit" isLoading={isLoading} />
                </Button>
            </Stack>
        </Stack>
    );
}

export function AttachmentList() {
    const dispatch = useAppDispatch();
    const { currentOutreachElementId } = useOutreachParams();
    const attachments = useSelector((state: RootState) =>
        selectCurrentOutreachElementAttachments(state, currentOutreachElementId)
    );

    const handleRemoveAttachment = (id: string) =>
        dispatch(removeAttachment({ attachmentId: id, currentOutreachElementId }));

    return (
        <Stack direction="row" sx={{ height: "fit-content" }} gap={0.5}>
            {attachments?.map((i) => {
                return <AttachmentTile key={i?.id} handleRemove={() => handleRemoveAttachment(i?.id)} {...i} />;
            })}
        </Stack>
    );
}

type AttachmentTileProps = Attachment & {
    handleRemove: () => void;
};

function AttachmentTile({ name, handleRemove, link }: AttachmentTileProps) {
    const fileType = getFileExtension(link);
    const fileIcon =
        fileType === "pdf"
            ? pdfIcon
            : fileType === "docx" || fileType === "doc"
              ? docxIcon
              : fileType === "zip"
                ? zipIcon
                : txtIcon;
    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={0.7}
            py={0.5}
            px={0.5}
            sx={(theme) => ({
                backgroundColor: "#fafafb",
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: "0.3rem",
            })}
        >
            <img src={fileIcon} alt="file" height={20} width={20} />
            <TextWithEllipses charLimit={20} typographyProps={{ variant: "caption", fontSize: "11px" }}>
                {name}
            </TextWithEllipses>
            <IconButton sx={{ padding: 0 }} onClick={handleRemove}>
                <CloseRoundedIcon sx={{ height: 18, width: 18 }} />
            </IconButton>
        </Stack>
    );
}
