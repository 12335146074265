import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

import { CompanyIntent } from "./BlendedSearch/CompanyIntent";

import useSearchState from "../hooks/useSearchState";
import AutocompleteWithExclusion from "../molecules/AutocompleteWithExclusion/AutocompleteWithExclusion";
import { selectCurrentCompany, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

export function CurrentCompany({ id }: { id: SearchStateKeys }) {
    const { isLoading, query, setQuery, dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectCurrentCompany);
    const handleChange = (value: typeof values) => {
        dispatch(
            setValues({
                key: id,
                value,
            })
        );
    };

    return (
        <>
            <Stack spacing={1} direction="row" alignItems="center">
                <AutocompleteWithExclusion
                    fullWidth
                    size="small"
                    label="Enter company name"
                    freeSolo
                    value={values}
                    options={Object.keys(options).map((i) => i)}
                    onChange={handleChange}
                    inputValue={query}
                    onInputChange={setQuery}
                    loading={isLoading}
                    doNotFilter={true}
                    autoSave
                    tooltipProps={{ title: "Press 'enter' after each keyword" }}
                    sx={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        paddingTop: "12px",
                        borderRadius: "5px",
                        borderBottom: "1px solid lightgrey",
                        "&:hover": {
                            borderBottom: "1px solid black",
                        },
                        "& .MuiAutocomplete-listbox": {
                            maxHeight: "120px",
                            overflowY: "auto",
                        },
                    }}
                />
            </Stack>
            <CompanyIntent />
        </>
    );
}
