export function dispatchEnterKeyboardEvent(ref: HTMLInputElement | null) {
    const enterKeyEvent = new KeyboardEvent("keydown", {
        key: "Enter",
        code: "Enter",
        keyCode: 13,
        which: 13,
        bubbles: true,
    });

    // Dispatch the event on the input element
    if (ref) ref.dispatchEvent(enterKeyEvent);
}
