import { useSelector } from "react-redux";

import {
    selectProjectInsightGenerationStatus,
    selectProjectStatus,
} from "@/store/reducers/allProjects/allProjects.reducer";
import { checkLinkedinEnrich } from "@/store/reducers/project/project.reducer";

function useCheckProjectInProgress() {
    const projectStatus = useSelector(selectProjectStatus);
    const insightGenerationStatus = useSelector(selectProjectInsightGenerationStatus);
    const linkedinEnrich = useSelector(checkLinkedinEnrich);

    const isProjectInProgress =
        projectStatus === "IN_PROGRESS" ||
        projectStatus === "PENDING" ||
        insightGenerationStatus === "IN_PROGRESS" ||
        linkedinEnrich;

    return isProjectInProgress;
}

export { useCheckProjectInProgress };
