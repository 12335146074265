import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { get } from "lodash";

import "firebase/compat/auth";
import { findCookie } from "./cookie";
import { getFirebaseWebToken } from "./firebase";

import { setErrorNotification } from "../components/Notification/index.reducer";
import { setLiBasedToken } from "../container/AppContainer/AppContainer.reducer";
import { setIsTokenExpired } from "../pages/Signin/Signin.reducer";
import store from "../store";

type ErrorResponse = {
    message?: string;
    error?: string;
};

export const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;

export const apiBase = baseUrl === "http://localhost:4000" ? baseUrl : `${baseUrl}/api`;
export const openExtensionLink = "https://www.linkedin.com/search/results/people/";
export const openLinkedinRecruiter = "https://www.linkedin.com/talent/home";
export const openLIProfileOrigin = "https://www.linkedin.com/in";

const responseBody = (response: AxiosResponse) => {
    return response.data;
};

const responseHeader = (response: AxiosResponse) => {
    return response;
};

class API {
    instance: AxiosInstance;
    noAuth: boolean;
    getHeader: boolean;
    redirectOnError: boolean;

    constructor(
        config: AxiosRequestConfig = {
            baseURL: apiBase,
            timeout: 300000,
        },
        noAuth?: boolean,
        getHeader?: boolean,
        redirectOnError?: boolean
    ) {
        this.instance = axios.create(config);
        this.noAuth = noAuth || false;
        this.getHeader = getHeader || false;
        this.redirectOnError = redirectOnError || false;
        //@ts-ignore
        this.instance.interceptors.request.use(this.requestInterceptors);
        this.instance.interceptors.response.use(this.responseSuccessInterceptors, this.responseErrorInterceptors);
    }

    responseSuccessInterceptors = (response: AxiosResponse): AxiosResponse => response;

    responseErrorInterceptors = (error: AxiosError): Promise<AxiosError> => {
        const responseData: ErrorResponse = error.response?.data || {};

        const isTokenExpired = responseData.message === "Token expired" || responseData.error === "Token expired";

        if (isTokenExpired) {
            store.dispatch(setIsTokenExpired(true));
        } else {
            store.dispatch(setErrorNotification(responseData.error || ""));
        }

        return Promise.reject(error);
    };

    requestInterceptors = async (params?: AxiosRequestConfig) => {
        const state = store.getState();
        const isSuperAdminView = get(state, "superAdmin.isSuperAdminView");
        const userId = get(state, "superAdmin.userDetails.id");
        const liBasedToken = get(state, "appContainerReducer.selectLiBasedToken");

        const token = findCookie("accessToken");
        const webToken = await getFirebaseWebToken();

        let url = params?.url;

        const isCompanyPage = url && url?.indexOf("/v2/project/companyPage/") > -1;

        if (!token && !webToken && !isCompanyPage) return;
        if (!webToken && liBasedToken !== token) store.dispatch(setLiBasedToken(token));

        if (url && isSuperAdminView) {
            const isQueryPresent = url?.indexOf("?") > -1;
            if (isQueryPresent) {
                url = `${url}&isSuperAdminView=true&userId=${userId}`;
            } else {
                url = `${url}?isSuperAdminView=true&userId=${userId}`;
            }
        }
        const newParams = {
            ...(params || {}),
            headers: {
                ...(get(params, "headers") || {}),
                appType: "web",
                version: 1.0,
                timezone: -330,
                // linkedin
                "x-authorization": token,
                // firebase
                "x-webAuthorization": webToken,
            },
            defaults: {
                mode: "cors",
                withCredentials: false,
                credentials: "same-origin",
                headers: {
                    ...(get(params, "headers") || {}),
                    appType: "web",
                    version: 1.0,
                    timezone: -330,
                    "x-authorization": token,
                    "x-webAuthorization": webToken,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                },
            },
            url,
        };
        return newParams;
    };

    setLocalStorage = ({ key, value }: { key: string; value: string }) => {
        localStorage.setItem(key, value);
    };

    getLocalStorage = (key: string) => {
        return localStorage.getItem(key || "");
    };

    get = (url: string, config?: AxiosRequestConfig) =>
        this.instance.get(url, config).then(this.getHeader ? responseHeader : responseBody);
    post = <T, B, R = AxiosResponse<T>>(url: string, body: B, config?: AxiosRequestConfig): Promise<R> =>
        this.instance.post(url, body, config).then(this.getHeader ? responseHeader : responseBody);
    put = <T, B, R = AxiosResponse<T>>(url: string, body: B, config?: AxiosRequestConfig): Promise<R> =>
        this.instance.put(url, body, config).then(this.getHeader ? responseHeader : responseBody);
    delete = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> =>
        this.instance.delete(url, config).then(this.getHeader ? responseHeader : responseBody);
}

export default API;
