import { MenuProps } from "rc-menu";

import CLAUDELOGO from "../assets/img/ai-providers-logo/claude-logo.png";
import GEMINILOGO from "../assets/img/ai-providers-logo/gemini-logo.png";
import METALOGO from "../assets/img/ai-providers-logo/meta-logo.webp";
import PERPLEXITYLOGO from "../assets/img/ai-providers-logo/perplexity-logo.png";
import arrowDownIcon from "../assets/img/arrow-down.svg";
import bambooHRLogo from "../assets/img/bambooHR-logo.png";
import billGates from "../assets/img/bill_gates.jpeg";
import blueTickIcon from "../assets/img/blue-tick.svg";
import GptLogoTransperent from "../assets/img/chatgpt-transperent.png";
import chevronDownIconBlue from "../assets/img/chevron-down-blue.svg";
import chevronDownIcon from "../assets/img/chevron-down.svg";
import cloneIcon from "../assets/img/clone.svg";
import closeIcon from "../assets/img/close-btn.svg";
import coinsRewardIcon from "../assets/img/coins-reward.png";
import crunchbaseLogo from "../assets/img/crunchbase-logo.png";
import deleteIcon from "../assets/img/delete.svg";
import easyGrowthLogo from "../assets/img/easy-growth-logo.svg";
import easyGrowthSymbol from "../assets/img/easygrowth-symbol.svg";
import editGrayIcon from "../assets/img/edit-gray.svg";
import editIcon from "../assets/img/edit.svg";
import ApolloLogo from "../assets/img/email-providers-logo/apollo-logo.jpeg";
import BouncifyLogo from "../assets/img/email-providers-logo/bouncify-logo.jpeg";
import ContactsOutLogo from "../assets/img/email-providers-logo/contacts-out-logo.jpeg";
import PeopleDataLabLogo from "../assets/img/email-providers-logo/people-data-lab-logo.png";
import RetentionLogo from "../assets/img/email-providers-logo/retention-logo.png";
import RocketReachLogo from "../assets/img/email-providers-logo/rocket-reach-logo.png";
import ZeroBounceLogo from "../assets/img/email-providers-logo/zero-bounce-logo.avif";
import filterIcon from "../assets/img/filter.svg";
import flyingJatt from "../assets/img/flying-jatt.svg";
import goldenStar from "../assets/img/golden-star.svg";
import googleLogo from "../assets/img/google-logo.svg";
import googleSignInBtn from "../assets/img/google-signin-btn.png";
import greenhouseLogo from "../assets/img/greenhouse.png";
import greyStacksIcon from "../assets/img/grey-stacks.svg";
import hqInitialsLogo from "../assets/img/hq-initials-logo.png";
import hyperPerson from "../assets/img/hyper-person.png";
import infoLightBlueIcon from "../assets/img/info-light-blue.svg";
import jazzHrLogo from "../assets/img/jazzHR.jpeg";
import jobviteLogo from "../assets/img/jobvite-logo.png";
import laborEdge from "../assets/img/laborEdge.png";
import leverLogo from "../assets/img/lever-logo.png";
import linkedInLogo from "../assets/img/linkedin-logo.svg";
import loxoAtsLogo from "../assets/img/loxo.png";
import notInterestedIcon from "../assets/img/not Interested.png";
import nucleusLogo from "../assets/img/nucleus.png";
import nudgeCloseIcon from "../assets/img/nudge-close-icon.svg";
import oprahWinfrey from "../assets/img/oprah_winfrey.jpeg";
import OracleLogo from "../assets/img/oracle.png";
import outlookLogo from "../assets/img/outlook-logo.png";
import pencilIcon from "../assets/img/pencil.svg";
import poolExhausedIcon from "../assets/img/poolExhausted.svg";
import personaliseIcon from "../assets/img/personalise.png";
import profileIcon from "../assets/img/profile.svg";
import RecruiterFlowIcon from "../assets/img/recruiterflow.svg";
import satyaNadella from "../assets/img/satya_nadella.jpeg";
import sendIcon from "../assets/img/send.svg";
import shortlistedPlus from "../assets/img/shortlistedPlus.png";
import slackLogo from "../assets/img/slack.svg";
import trashGrayMedium from "../assets/img/trash-gray-medium.svg";
import trashGrayIcon from "../assets/img/trash-gray.svg";
import TwilloIcon from "../assets/img/twillo.svg";
import userInitialIcon from "../assets/img/user-initial.svg";
import userIcon from "../assets/img/user.svg";
import zohoRecruitLogo from "../assets/img/zoho-recruit-logo.png";

import monsterLogo from "@/assets/img/email-providers-logo/monster-logo.png";
import { IEmailData } from "@/store/reducers/message/message.types";

const PROJECT_FUNCTION_KEY = {
    "1": "Software Developer",
    "2": "Technical Manger",
    "3": "Data Science & ML",
};
const VISIBILITY_KEY = {
    "1": "Only you",
    "2": "Individual Member",
    "3": "Everyone in the team",
};

const QUILL_MODULES = {
    toolbar: {
        container: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "color", "align"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
        ],
    },
};

const GET_STAGE_COLOR = (stage: string) => {
    if (!stage) return;
    if (stage === "REJECTED") return "bg-red";
    if (stage === "ON_HOLD") return "bg-gray";
    return "bg-green";
};

const GET_INBOX_EMAILS = (emailData: IEmailData[]) => {
    if (!emailData?.length) return;

    const INBOX_DATA = emailData
        .map((candidateThread: IEmailData) => {
            const EMAIL_THREADS = candidateThread.msgs;
            const IS_NEW_MESSAGE = EMAIL_THREADS.some((item) => !item.sentByRecruiter);
            if (IS_NEW_MESSAGE) return candidateThread;
            return null;
        })
        .filter((item: IEmailData) => item);

    return INBOX_DATA;
};

const SAMPLE_PROJECT = {
    _id: "9999",
    name: "Sample Project",
    dashboardStatus: "ACTIVE",
    pipelined: 3,
    contacted: 0,
    responded: 0,
};

const SAMPLE_RECOMMENDATION_TEMPLATES = {
    email: {
        subject: "Exciting Opportunity for Marketing Manager Role at {organization}",
        body: "Dear {firstName},\n\nWe are thrilled to announce an exciting opportunity for the role of Marketing Manager at {organization}. As a leading company in the industry, we are looking for a dynamic and experienced individual to join our team.\n\nAs a Marketing Manager, you will be responsible for developing and implementing marketing strategies to drive business growth. You will work closely with the sales team to identify new opportunities and develop campaigns to increase brand awareness and customer engagement.\n\nThe ideal candidate should have a proven track record in marketing, excellent communication skills, and the ability to work in a fast-paced environment. Experience in digital marketing, social media, and content creation is preferred.\n\nAt {organization}, we value our employees and offer a competitive salary package, flexible working hours, and opportunities for career growth.\n\nIf you are passionate about marketing and want to be part of a dynamic team, we encourage you to apply for this exciting opportunity. Please send your resume and cover letter to {senderName} at {emailAddress}.\n\nWe look forward to hearing from you soon.\n\nBest regards,\n\n{senderName}",
    },
    inmail: {
        subject: "Dear [Candidate Name],",
        body: "I came across your profile and was impressed by your experience in marketing. I am reaching out to you because we have an exciting opportunity for a Marketing Manager at [Company Name]. \n\nAs a Marketing Manager, you will be responsible for developing and executing marketing strategies to drive brand awareness and revenue growth. You will work closely with cross-functional teams to create and implement campaigns that resonate with our target audience. \n\nWe are looking for someone who has a proven track record of success in marketing, with experience in digital marketing, content creation, and event management. Experience in the [industry] is preferred, but not required. \n\nIf you are a creative thinker with a passion for marketing and a desire to work in a dynamic and fast-paced environment, we would love to hear from you. \n\nPlease let me know if you are interested in learning more about this opportunity. I look forward to hearing from you soon.\n\nBest regards,\n\n[Your Name]",
    },
    note: '"Our client is hiring for a Marketing Manager. Are you interested in learning more about this exciting opportunity?"',
};

const SAMPLE_CANDIDATES = [
    {
        _id: "64def2f71bdbc4e8809c88d2",
        name: "Bill Gates",
        profileUrl: "https://www.linkedin.com/in/williamhgates/",
        source: "LINKEDIN",
        sourceId: "williamhgates",
        title: "Co-chair, Bill & Melinda Gates Foundation",
        location: "Seattle, Washington, United States",
        profileImage: billGates,
        description:
            "Co-chair of the Bill & Melinda Gates Foundation. Founder of Breakthrough Energy. Co-founder of Microsoft. Voracious reader. Avid traveler. Active blogger.",
        email: ["sampleemail1@gmail.com"],
        phone: [],
        dob: "-",
        resume: "-",
        canShowEmail: true,
        scrapStatus: "COMPLETED",
        emailStatus: "PENDING",
        stage: "PIPELINE",
        isDeleted: "false",
        totalExperience: {
            label: "48 years 5 months",
        },
        education: [
            {
                institute: "Harvard University",
                course: "-",
                description: null,
                duration: "-",
                _id: "645e3390422acbcc5bfeeec4",
            },
            {
                institute: "Lakeside School",
                course: "-",
                description: null,
                duration: "-",
                _id: "645e3390422acbcc5bfeeec5",
            },
        ],
        experience: [
            {
                position: "Co-chair",
                org: "Bill & Melinda Gates Foundation",
                location: "-",
                duration: "2000 - Present · 23 yrs 5 mos",
                description: "-",
                _id: "645e3390422acbcc5bfeeec1",
            },
            {
                position: "Founder",
                org: "Breakthrough Energy ",
                location: "-",
                duration: "2015 - Present · 8 yrs 5 mos",
                description: "-",
                _id: "645e3390422acbcc5bfeeec2",
            },
            {
                position: "Co-founder",
                org: "Microsoft",
                location: "-",
                duration: "1975 - Present · 48 yrs 5 mos",
                description: "-",
                _id: "645e3390422acbcc5bfeeec3",
            },
        ],
        certificates: [],
        skills: [
            {
                name: "People Management",
                _id: "6450d946b6aa6ed5d8c9d448",
            },
            {
                name: "Team Leadership",
                _id: "6450d946b6aa6ed5d8c9d449",
            },
            {
                name: "Customer Service",
                _id: "6450d946b6aa6ed5d8c9d44a",
            },
        ],
        languages: [],
        createdAt: "2023-05-12T12:39:25.207Z",
        updatedAt: "2023-05-12T12:39:49.649Z",
        __v: 0,
        additionalInfo: {
            joiningStatus: "true",
            totalExperience: {
                label: "",
                value: 0,
            },
            updatedOn: 1683895189648,
            visa: "0",
        },
        isSample: true,
    },
    {
        _id: "64def2f71bdbc4e8809c88db",
        name: "Oprah Winfrey",
        profileUrl: "https://www.linkedin.com/in/owinfrey/",
        source: "LINKEDIN",
        sourceId: "owinfrey",
        title: "CEO, Producer, Publisher, Actress and Innovator",
        location: "Los Angeles, California, United States",
        stage: "PIPELINE",
        profileImage: oprahWinfrey,
        description:
            "Oprah Winfrey is a global media leader, philanthropist, producer and actress. She has created an unparalleled connection with people around the world, making her one of the most respected and admired people today. As Chairman and CEO, she's guiding her successful cable network, OWN: Oprah Winfrey Network, and is the founder of O, The Oprah Magazine and Harpo Films. This December, Winfrey will embark on a multi-city speaking tour in Australia and New Zealand, on the heels of her eight-city U.S. arena tour and transformational weekend in 2014. Winfrey is an Academy Award-nominated actress for her role in “The Color Purple” and earned critical acclaim in “Lee Daniels’ The Butler.” Most recently, she produced and acted in the Academy Award-winning film “Selma.” For 25 years, Winfrey was host of the award-winning talk show “The Oprah Winfrey Show.” One of the world’s leading philanthropists, Winfrey has long believed that education is the door to freedom. In April of 2014, Winfrey partnered with Starbucks to create the Teavana Oprah Chai tea, which is sold in Starbucks and Teavana stores across the U.S. and Canada. A donation from each purchase is made from Starbucks to the Oprah Winfrey Leadership Academy Foundation to benefit educational opportunities for youth, which in turn provides funding to national charity organizations including Girls Inc., National CARES Mentoring Movement, Pathways to College and U.S. Dream Academy. In October 2015, The Oprah Winfrey Leadership Academy for Girls in South Africa will be graduating its fifth class. Winfrey established the school in 2007 to provide education for academically gifted girls from disadvantaged backgrounds. Graduates of the school have continued on to higher education both in South Africa and at colleges and universities around the world. In 2013, Winfrey was awarded the Medal of Freedom, the nation’s highest civilian honor.",
        email: ["sampleemail2@gmail.com"],
        phone: [],
        dob: "-",
        resume: "-",
        scrapStatus: "COMPLETED",
        emailStatus: "PENDING",
        canShowEmail: true,
        isDeleted: "false",
        education: [],
        totalExperience: {
            label: "49 years",
        },
        experience: [
            {
                position: "Chairman and CEO",
                org: "OWN: Oprah Winfrey Network",
                location: "-",
                duration: "Jan 2011 - Present · 12 yrs 5 mos",
                description:
                    "OWN has nearly doubled its prime-time viewership since it launched four years ago, and is now available in 82 million homes. On Sunday, October 18 at 8 p.m. ET/PT on OWN will premiere Belief, a seven-part “docuseries” that explores faith and spirituality.",
                _id: "645e349a422acbcc5bfef116",
            },
            {
                position: "Founder and Publisher",
                org: "O, The Oprah Magazine",
                location: "-",
                duration: "Jan 2000 - Dec 2020 · 21 yrs",
                description: "Oversaw a magazine devoted to helping people better their lives.",
                _id: "645e349a422acbcc5bfef117",
            },
            {
                position: "Host",
                org: "The Oprah Winfrey Show",
                location: "-",
                duration: "Jan 1986 - Dec 2011 · 26 yrs",
                description: "Hosted a nationally syndicated award-winning talk show that aired for 25 seasons. ",
                _id: "645e349a422acbcc5bfef118",
            },
        ],
        certificates: [],
        skills: [
            {
                name: "Decision Making",
                _id: "6450d946b6aa6ed5d8c9d448",
            },
            {
                name: "Persuasion",
                _id: "6450d946b6aa6ed5d8c9d449",
            },
            {
                name: "Psychological Leadership",
                _id: "6450d946b6aa6ed5d8c9d44a",
            },
        ],
        languages: [],
        createdAt: "2023-05-12T12:43:58.969Z",
        updatedAt: "2023-05-12T12:44:23.037Z",
        __v: 0,
        additionalInfo: {
            joiningStatus: "true",
            totalExperience: {
                label: "37 years 5 months ",
                value: 37.42,
            },
            updatedOn: 1683895463037,
            visa: "0",
        },
        isSample: true,
    },
    {
        _id: "64def2f71bdbc4e8809c88e5",
        name: "Satya Nadella",
        profileUrl: "https://www.linkedin.com/in/satyanadella/",
        sourceId: "satyanadella",
        source: "LINKEDIN",
        title: "Chairman and CEO at Microsoft",
        stage: "PIPELINE",
        location: "Redmond, Washington, United States",
        profileImage: satyaNadella,
        description:
            "As chairman and CEO of Microsoft, I define my mission and that of my company as empowering every person and every organization on the planet to achieve more.",
        email: ["sampleemail3@gmail.com"],
        phone: [],
        dob: "-",
        resume: "-",
        scrapRequestIds: ["645e34ff422acbcc5bfef1de"],
        scrapStatus: "COMPLETED",
        emailStatus: "PENDING",
        canShowEmail: true,
        isDeleted: "false",
        education: [
            {
                institute: "The University of Chicago Booth School of Business",
                course: "-",
                description: null,
                duration: "-",
                _id: "645e3513422acbcc5bfef21c",
            },
            {
                institute: "Manipal Institute of Technology",
                course: "Bachelor’s Degree, Electrical Engineering",
                description: null,
                duration: "-",
                _id: "645e3513422acbcc5bfef21d",
            },
            {
                institute: "University of Wisconsin-Milwaukee",
                course: "Master’s Degree, Computer Science",
                description: null,
                duration: "-",
                _id: "645e3513422acbcc5bfef21e",
            },
        ],
        experience: [
            {
                position: "Chairman and CEO",
                org: "Microsoft",
                location: "Greater Seattle Area",
                duration: "Feb 2014 - Present · 9 yrs 4 mos",
                description: "-",
                _id: "645e3513422acbcc5bfef217",
            },
            {
                position: "Chairman",
                org: "The Business Council U.S.",
                location: "-",
                duration: "2021 - Present · 2 yrs 5 mos",
                description: "-",
                _id: "645e3513422acbcc5bfef218",
            },
            {
                position: "Member Board Of Trustees",
                org: "University of Chicago",
                location: "-",
                duration: "2018 - Present · 5 yrs 5 mos",
                description: "-",
                _id: "645e3513422acbcc5bfef219",
            },
            {
                position: "Board Member",
                org: "Starbucks",
                location: "-",
                duration: "2017 - Present · 6 yrs 5 mos",
                description: "-",
                _id: "645e3513422acbcc5bfef21a",
            },
            {
                position: "Board Member",
                org: "Fred Hutch",
                location: "-",
                duration: "2016 - 2022 · 6 yrs",
                description: "-",
                _id: "645e3513422acbcc5bfef21b",
            },
        ],
        totalExperience: {
            label: "29 years 9 months",
        },
        certificates: [],
        skills: [
            {
                name: "People Management",
                _id: "6450d946b6aa6ed5d8c9d448",
            },
            {
                name: "Management",
                _id: "6450d946b6aa6ed5d8c9d449",
            },
            {
                name: "Collaboration Leadership",
                _id: "6450d946b6aa6ed5d8c9d44a",
            },
        ],
        languages: [],
        createdAt: "2023-05-12T12:45:59.379Z",
        updatedAt: "2023-05-12T12:46:15.801Z",
        __v: 0,
        additionalInfo: {
            joiningStatus: "true",
            totalExperience: {
                label: "9 years 4 months ",
                value: 9.33,
            },
            updatedOn: 1683895575800,
            visa: "1",
        },
        isSample: true,
    },
];

const CLEVER_TAP_EXCLUDED_EMAIL_IDS = [
    "aniket@test.com",
    "jkl@jkl.jklw",
    "shivam964350@gmail.com",
    "pppp@gmail.com",
    "laj@outlook.com",
    "aishwarya12may@gmail.com",
    "asasasasas@yahoo.in",
    "myexamineuser+hirequotient@gmail.com",
    "gpratik985@gmail.com",
    "ankur.gr93@gmail.com",
    "hirequotient94@gmail.com",
    "research@workforceresearch.org",
    "pratikkedia44@gmail.com",
    "jkljkljkl@jkljljl.jkl",
    "email@email.com",
    "brobillionaire1@gmail.com",
    "hqaccount@gmail.com",
    "aishwaryasingh@yahoo.in",
    "dosof33483@in2reach.com",
    "vijeyij565@jobbrett.com",
    "motabhaihq@gmail.com",
    "vifibir406@meidecn.com",
    "aayushsaurav05@gmail.com",
    "hewoxo6933@soombo.com",
    "shivam98118@outlook.com",
    "Testclevertap2@gmail.com",
    "Testprodfirstpurchase@gmail.com",
    "hirequotientnotvalid@gmail.com",
    "hqemail@hirequotient.com",
    "testclevertap@outlook.com",
    "shivam98118+1@outlook.com",
    "hirequotient.com",
];

const HYPER_EMAIL_SUBJECT = `Same as for individual candidates`;

const HYPER_EMAIL2_BODY = `<p>Dear {firstName},</p>
<p><br></p>
<p>I hope this email finds you well. I wanted to follow up on my previous email regarding the exciting job opportunity in the {jobTitle} field.</p>
<p><br></p>
<p>We truly believe that you possess the skills and experience necessary to excel in this role. We are eager to discuss the opportunity in more detail and answer any questions you may have.</p>
<p><br></p>
<p>Please let me know if you would like to schedule a call or if there's a convenient time for us to connect. We're looking forward to hearing from you soon!</p>
<p><br></p>
<p>Best regards,</p>
<p>{senderName}</p>`;

const HYPER_EMAIL3_BODY = `<p>Dear {firstName},</p>
<p><br></p>
<p>I hope this email finds you well. I wanted to touch base and see if you had any further thoughts on the exciting job opportunity we discussed in the {jobTitle} field.</p>
<p><br></p>
<p>We value your skills and experience and believe that you could bring immense value to our team. If you have any questions or would like to explore this opportunity further, please don't hesitate to reach out.</p>
<p><br></p>
<p>We're excited to hear from you and discuss how we can move forward. Let's connect and make great things happen!</p>
<p><br></p>
<p>Best regards,</p>
<p>{senderName}</p>`;

const CANDIDATE_STAGE_ITEMS: MenuProps["items"] = [
    {
        label: "Shortlisted",
        key: "SHORTLISTED",
    },
    {
        label: "Rejected",
        key: "REJECTED",
    },
    {
        label: "Not interested",
        key: "NOT_INTERESTED",
    },
    {
        label: "To Be Reviewed",
        key: "TO_BE_REVIEWED",
    },
];

const CANDIDATE_STAGE_ITEMS_MUI = [
    {
        label: "Shortlisted",
        key: "SHORTLISTED",
    },
    {
        label: "Rejected",
        key: "REJECTED",
    },
    {
        label: "Not interested",
        key: "NOT_INTERESTED",
    },
    {
        label: "To Be Reviewed",
        key: "TO_BE_REVIEWED",
    },
];

const CREATE_DASHBOARD_USER = "CREATE_DASHBOARD_USER";
const WRONG_PASSWORD =
    "Incorrect passwrod for existing email account. Please enter correct password or use different email.";
const DROPDOWN_CONTAINER = "DROPDOWN_CONTAINER";
const SELECT_CONTAINER = "SELECT_CONTAINER";

const CV_SOURCE_OPTIONS = [
    { label: "Career Builder", value: "Career Builder" },
    { label: "Glassdoor", value: "Glassdoor" },
    { label: "Indeed", value: "Indeed" },
    { label: "Monster", value: "Monster" },
    { label: "Nexxt", value: "Nexxt" },
    { label: "Resume-library", value: "Resume-library" },
    { label: "ZipRecruiter", value: "ZipRecruiter" },
    { label: "SalesHeads", value: "SalesHeads" },
    { label: "EasySource", value: "EasySource" },
    { label: "Linkedin", value: "Linkedin" },
    { label: "ATS", value: "ATS" },
    { label: "Other", value: "" },
];

const CONTACT_TYPE_PROVIDER_MAP = {
    APOLLO: "Apollo.io",
    BOUNCIFY: "Bouncify",
    CONTACTS_OUT: "ContactOut",
    EASYSOURCE_DATA: "EasySource Data",
    PEOPLE_DATA_LABS: "People Data Labs",
    PROXYCURL: "ProxyCurl",
    RB2BAPI: "RB2B",
    ROCKETREACH: "RocketReach",
    ZEROBOUNCE: "ZeroBounce",
    ZOOMINFO: "ZoomInfo",
};

export {
    WRONG_PASSWORD,
    CREATE_DASHBOARD_USER,
    DROPDOWN_CONTAINER,
    SELECT_CONTAINER,
    jazzHrLogo,
    arrowDownIcon,
    cloneIcon,
    closeIcon,
    filterIcon,
    profileIcon,
    sendIcon,
    userInitialIcon,
    userIcon,
    editIcon,
    deleteIcon,
    chevronDownIcon,
    trashGrayIcon,
    editGrayIcon,
    infoLightBlueIcon,
    chevronDownIconBlue,
    trashGrayMedium,
    TwilloIcon,
    blueTickIcon,
    linkedInLogo,
    outlookLogo,
    greyStacksIcon,
    loxoAtsLogo,
    crunchbaseLogo,
    personaliseIcon,
    googleLogo,
    googleSignInBtn,
    pencilIcon,
    goldenStar,
    nudgeCloseIcon,
    hyperPerson,
    flyingJatt,
    greenhouseLogo,
    coinsRewardIcon,
    slackLogo,
    zohoRecruitLogo,
    leverLogo,
    hqInitialsLogo,
    jobviteLogo,
    bambooHRLogo,
    easyGrowthLogo,
    CLAUDELOGO,
    easyGrowthSymbol,
    notInterestedIcon,
    shortlistedPlus,
    RocketReachLogo,
    poolExhausedIcon,
    ApolloLogo,
    BouncifyLogo,
    GptLogoTransperent,
    ContactsOutLogo,
    PeopleDataLabLogo,
    ZeroBounceLogo,
    PERPLEXITYLOGO,
    METALOGO,
    GEMINILOGO,
    RetentionLogo,
    OracleLogo,
    laborEdge,
    RecruiterFlowIcon,
    nucleusLogo,
    CLEVER_TAP_EXCLUDED_EMAIL_IDS,
    CONTACT_TYPE_PROVIDER_MAP,
    CV_SOURCE_OPTIONS,
    HYPER_EMAIL_SUBJECT,
    HYPER_EMAIL2_BODY,
    HYPER_EMAIL3_BODY,
    CANDIDATE_STAGE_ITEMS,
    QUILL_MODULES,
    VISIBILITY_KEY,
    PROJECT_FUNCTION_KEY,
    SAMPLE_RECOMMENDATION_TEMPLATES,
    SAMPLE_PROJECT,
    SAMPLE_CANDIDATES,
    CANDIDATE_STAGE_ITEMS_MUI,
    GET_INBOX_EMAILS,
    GET_STAGE_COLOR,
    monsterLogo,
};
