/* eslint-disable import/no-unused-modules */
/* eslint-disable filenames/match-regex */
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export function getNotificationTime(timestamp: Date) {
    /* if timestamp is of current day, then show time in sec ago, min ago, hours ago, else show date */
    if (dayjs().isSame(timestamp, "day")) {
        return dayjs(timestamp).fromNow(true) + " ago";
    }
    return dayjs(timestamp).format("DD MMM, YYYY HH:MM A");
}