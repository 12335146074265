import { forwardRef } from "react";

import { IconProps } from "./index";

export const LinkedinMobile = forwardRef<SVGSVGElement, IconProps>(({ size = 24, ...rest }, ref) => {
    return (
        <svg
            // style="enable-background:new 0 0 48 48;"
            version="1.1"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            ref={ref}
            {...rest}
        >
            <g id="Icons">
                <g>
                    <g>
                        <path
                            d="M30.6735,39.0482H17.3301c-1.081,0-1.9573-0.8763-1.9573-1.9573v-0.9204l8.463-0.9203     l8.795,0.9203v0.9204C32.6308,38.1719,31.7545,39.0482,30.6735,39.0482z"
                            style={{ fill: "#444444" }}
                        />
                        <polygon
                            points="15.3728,12.75 24.2411,11.9451 32.6308,12.75 32.6308,36.17 15.3728,36.17    "
                            style={{ fill: "#E9F5FF" }}
                        />
                        <circle cx="24.00183" cy="37.56393" r="0.64535" style={{ fill: "#FFFFFF" }} />
                        <g>
                            <g>
                                <path
                                    d="M20.1592,12.75l-4.79,4.43v1.1l6-5.53H20.1592z M23.1892,12.75l-7.82,7.2v2.9l10.96-10.1       H23.1892z"
                                    style={{ fill: "#E9F5FF" }}
                                />
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g style={{ opacity: "0.5" }}>
                                            <polygon
                                                points="21.3692,12.75 15.3692,18.28 15.3692,17.18 20.1592,12.75         "
                                                style={{ fill: "#FFFFFF" }}
                                            />
                                            <polygon
                                                points="26.3292,12.75 15.3692,22.85 15.3692,19.95 23.1892,12.75         "
                                                style={{ fill: "#FFFFFF" }}
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path
                                d="M30.1678,8.9518H17.8359c-1.3603,0-2.4631,1.1156-2.4631,2.4917v1.3157h17.258v-1.3157      C32.6308,10.0674,31.5281,8.9518,30.1678,8.9518z"
                                style={{ fill: "#444444" }}
                            />
                            <g>
                                <g>
                                    <ellipse
                                        cx="26.88467"
                                        cy="10.8256"
                                        rx="0.36535"
                                        ry="0.3287"
                                        style={{ fill: "#FFFFFF" }}
                                    />
                                </g>
                                <g>
                                    <path
                                        d="M24.4768,11.1757h-3.7232c-0.1933,0-0.3501-0.1568-0.3501-0.3501        c0-0.1934,0.1568-0.3501,0.3501-0.3501h3.7232c0.1933,0,0.3501,0.1567,0.3501,0.3501        C24.8269,11.0189,24.6701,11.1757,24.4768,11.1757z"
                                        style={{ fill: "#FFFFFF" }}
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Icons_24_">
                        <g>
                            <path
                                d="M24.2489,24.1952c0-0.137-0.0024-0.2739,0.001-0.4108c0.0015-0.0623-0.0185-0.0857-0.0831-0.0853      c-0.3366,0.0024-0.6731,0.0023-1.0096,0.0001c-0.0617-0.0004-0.0848,0.0164-0.0847,0.0822      c0.0018,1.2398,0.0017,2.4797,0.0001,3.7196c-0.0001,0.0644,0.0205,0.0837,0.0835,0.0833      c0.352-0.0023,0.7041-0.0025,1.0561,0.0001c0.064,0.0005,0.082-0.0192,0.0817-0.083c-0.0023-0.5889-0.003-1.1779-0.0001-1.7668      c0.0007-0.1454,0.0096-0.2916,0.0269-0.436c0.0188-0.1576,0.0693-0.3066,0.18-0.4269c0.1422-0.1543,0.3269-0.1891,0.5264-0.1854      c0.2783,0.0052,0.4518,0.1349,0.5313,0.4014c0.0513,0.1717,0.0554,0.3482,0.0555,0.5252c0.0003,0.6265,0,1.2531,0.0002,1.8797      c0,0.0875,0.0028,0.0904,0.0889,0.0905c0.3432,0.0003,0.6863,0.0002,1.0295,0.0001c0.107,0,0.1076-0.0002,0.1071-0.1056      c-0.0031-0.7592-0.0051-1.5184-0.0105-2.2775c-0.0017-0.2326-0.0329-0.4625-0.0966-0.6867      c-0.0975-0.3432-0.2812-0.6232-0.6186-0.7694c-0.3529-0.1529-0.7226-0.1806-1.0963-0.0889      C24.695,23.7342,24.4401,23.9152,24.2489,24.1952z M22.3395,25.6453c0-0.6199-0.0011-1.2397,0.0016-1.8596      c0.0002-0.0672-0.0177-0.0874-0.085-0.0868c-0.3497,0.0028-0.6995,0.0027-1.0493,0.0001      c-0.0655-0.0005-0.0876,0.0179-0.0875,0.0862c0.0019,1.2375,0.0019,2.475,0,3.7125c-0.0001,0.0677,0.0208,0.0872,0.087,0.0867      c0.3498-0.0026,0.6996-0.0027,1.0493,0.0001c0.0663,0.0005,0.0858-0.0181,0.0855-0.0863      C22.3383,26.8806,22.3395,26.2629,22.3395,25.6453z M21.0268,22.4931c0.0001,0.3865,0.3166,0.7101,0.7037,0.7062      c0.3994-0.004,0.7093-0.3048,0.7086-0.707c-0.0007-0.4093-0.3162-0.7073-0.7093-0.7051      C21.3106,21.7897,21.0276,22.1252,21.0268,22.4931z"
                                style={{ fill: "#0097D3" }}
                            />
                            <path
                                d="M24.2489,24.1952c0.1912-0.28,0.4461-0.461,0.7687-0.5401      c0.3737-0.0917,0.7434-0.064,1.0963,0.0889c0.3374,0.1462,0.5211,0.4262,0.6186,0.7694      c0.0637,0.2242,0.0949,0.4541,0.0966,0.6867c0.0054,0.7591,0.0074,1.5183,0.0105,2.2775      c0.0005,0.1054-0.0001,0.1056-0.1071,0.1056c-0.3431,0.0001-0.6863,0.0002-1.0295-0.0001      c-0.0861-0.0001-0.0889-0.003-0.0889-0.0905c-0.0002-0.6266,0.0001-1.2532-0.0002-1.8797      c-0.0001-0.177-0.0042-0.3535-0.0555-0.5252c-0.0795-0.2665-0.253-0.3962-0.5313-0.4014      c-0.1995-0.0037-0.3842,0.0311-0.5264,0.1854c-0.1107,0.1203-0.1612,0.2693-0.18,0.4269      c-0.0173,0.1444-0.0262,0.2906-0.0269,0.436c-0.0029,0.5889-0.0021,1.1779,0.0001,1.7668      c0.0003,0.0637-0.0177,0.0835-0.0817,0.083c-0.352-0.0026-0.7041-0.0024-1.0561-0.0001      c-0.063,0.0004-0.0836-0.0189-0.0835-0.0833c0.0016-1.2399,0.0017-2.4798-0.0001-3.7196      c-0.0001-0.0658,0.023-0.0826,0.0847-0.0822c0.3365,0.0022,0.673,0.0023,1.0096-0.0001      c0.0646-0.0004,0.0846,0.023,0.0831,0.0853C24.2465,23.9213,24.2489,24.0582,24.2489,24.1952z"
                                style={{ fill: "#0097D3" }}
                            />
                            <path
                                d="M22.3395,25.6453c0,0.6176-0.0012,1.2353,0.0016,1.8529      c0.0003,0.0682-0.0192,0.0868-0.0855,0.0863c-0.3497-0.0028-0.6995-0.0027-1.0493-0.0001      c-0.0662,0.0005-0.0871-0.019-0.087-0.0867c0.0019-1.2375,0.0019-2.475,0-3.7125c-0.0001-0.0683,0.022-0.0867,0.0875-0.0862      c0.3498,0.0026,0.6996,0.0028,1.0493-0.0001c0.0673-0.0006,0.0852,0.0196,0.085,0.0869      C22.3384,24.4056,22.3395,25.0254,22.3395,25.6453z"
                                style={{ fill: "#0097D3" }}
                            />
                            <path
                                d="M21.0268,22.4931c0.0008-0.3679,0.2838-0.7034,0.703-0.7059      c0.3931-0.0022,0.7086,0.2958,0.7093,0.7051c0.0007,0.4022-0.3092,0.703-0.7086,0.707      C21.3434,23.2032,21.0269,22.8796,21.0268,22.4931z"
                                style={{ fill: "#0097D3" }}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
});
