import { useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { addToJobvite, getJobviteJobs, selectJobviteJobs } from "../../../integrations/integrations.reducer";

type JobviteModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
};

export default function JobviteModal({ open, onClose, id, selectedCandidateIds }: JobviteModalProps) {
    const dispatch = useDispatch();
    const jobviteJobs = useSelector(selectJobviteJobs);
    const isJobviteJobsLoading = useSelector(checkIfLoading(getJobviteJobs.type));
    const isJobviteSubmitting = useSelector(checkIfLoading(addToJobvite.type));

    const [jobId, setJobId] = useState("");

    useEffect(() => {
        if (open) {
            dispatch(getJobviteJobs({ action: getJobviteJobs.type }));
        }
    }, [open]);

    const handleCloseModal = () => {
        setJobId("");
        onClose();
    };

    const handleAddJobvite = () => {
        if (jobId === "") {
            dispatch(setErrorNotification("Please select job"));
            return;
        }

        dispatch(
            addToJobvite({
                projectId: Number(id),
                candidateIds: selectedCandidateIds,
                action: addToJobvite.type,
                jobId,
                onSuccess: handleCloseModal,
            })
        );
    };

    return (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={"xs"}>
            <DialogTitle>Send to Jobvite</DialogTitle>
            <DialogContent>
                {isJobviteJobsLoading ? (
                    <Stack alignItems="center" mt={2}>
                        <CircularProgress size={24} />
                    </Stack>
                ) : jobviteJobs?.length ? (
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <Typography>Select Requisition:</Typography>
                        <TextField
                            hiddenLabel
                            value={jobId}
                            onChange={(e) => setJobId(e.target.value)}
                            select
                            placeholder="Select..."
                            sx={{
                                "& .MuiSelect-select.MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                        >
                            {jobviteJobs.map((job) => (
                                <MenuItem value={job.eId}>{job.title}</MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                ) : (
                    <Typography fontSize={14} textAlign="center" color="red">
                        No requisitions found. Create one on your jobvite dashboard
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button
                    disabled={isJobviteJobsLoading || isJobviteSubmitting}
                    variant="contained"
                    onClick={handleAddJobvite}
                >
                    <ButtonTextWithLoading isLoading={isJobviteSubmitting} text={"Confirm"} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
