import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Typography } from "@mui/joy";
import Sheet, { SheetProps } from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";

export function TeamsTable({ children, headers, sx }: { children: React.ReactNode[]; headers: string[], sx?: SheetProps["sx"] }) {
    return (
        <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
                borderRadius: "sm",
                flexShrink: 1,
                overflow: "auto",
                minHeight: 0,
                fontFamily: "Inter",
                maxHeight: "calc(100vh - 190px)",
                marginBottom: "10px",
                ...(sx || {}), // Merge the provided sx with default styles
            }}
        >
            <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                    "--TableCell-headBackground": "var(--joy-palette-background-level1)",
                    "--Table-headerUnderlineThickness": "1px",
                    "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
                    "--TableCell-paddingY": "4px",
                    "--TableCell-paddingX": "8px",
                }}
            >
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th
                                style={{ minWidth: 140, padding: "12px 6px" }}
                                dangerouslySetInnerHTML={{ __html: header }}
                            />
                        ))}
                    </tr>
                </thead>
                {children.length === 0 ? (
                    <tbody style={{ height: "200px", position: "relative" }}>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                flexDirection: "column",
                                gap: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <ErrorOutlineRoundedIcon />
                            <Typography level="body-xs">No data to display</Typography>
                        </div>
                        {/* </div> */}
                    </tbody>
                ) : (
                    <tbody>{children}</tbody>
                )}
            </Table>
        </Sheet>
    );
}
