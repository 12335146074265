import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../Store";

export type InMailInputs = {
    subject: string;
    body: string;
};

export type InMailModes = {
    standard: InMailInputs;
};

export type ConnectionRequest = {
    inMail: InMailModes;
};

type State = {
    connectionRequest: ConnectionRequest;
};

const initialState: State = {
    connectionRequest: {
        // enableInMail: false,
        inMail: {
            standard: {
                subject: "Hiring for {jobTitle}, Interested ?",
                body: `
                <p>Dear {firstName},</p>
                <p><br></p>
                <p>Hope you're doing well :)</p>
                <p><br></p>
                <p>One of our clients is hiring for {roleName}, and we found your profile very relevant for this. I am happy to share more about this opportunity if you're interested. Please let me know!</p>
                `,
            },
        },
    },
};

export const stepsSlice = createSlice({
    name: "steps",
    initialState,
    reducers: {
        changeInMailAtCursorPosition(state, action: PayloadAction<{ label: string; cursorPosition: number }>) {
            const { cursorPosition, label } = action.payload;
            let subject = state.connectionRequest.inMail.standard.subject;

            const newSubject = subject.substring(0, cursorPosition) + label + subject.substring(cursorPosition);

            state.connectionRequest.inMail.standard.subject = newSubject;
        },
        resetInMailInputs(state) {
            state.connectionRequest.inMail.standard.body = "";
            state.connectionRequest.inMail.standard.subject = "";
        },
        changeInMailSubject(state, action: PayloadAction<string>) {
            state.connectionRequest.inMail.standard.subject = action.payload;
        },
        changeInMailBody(state, action: PayloadAction<string>) {
            state.connectionRequest.inMail.standard.body = action.payload;
        },
        addLabelInSubject(state, action: PayloadAction<{ position: number; value: string }>) {
            const { position, value } = action.payload;
            const newSubject =
                state.connectionRequest.inMail.standard.subject?.substring(0, position) +
                value +
                state.connectionRequest.inMail.standard.subject?.substring(position);
            state.connectionRequest.inMail.standard.subject = newSubject;
        },
    },
});

export default stepsSlice.reducer;

export const { changeInMailSubject, changeInMailBody, resetInMailInputs, changeInMailAtCursorPosition } =
    stepsSlice.actions;

export const getInMailSubject = (state: RootState) => state.steps.connectionRequest.inMail.standard.subject;
export const getInMailBody = (state: RootState) => state.steps.connectionRequest.inMail.standard.body;
