import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Experimental_CssVarsProvider } from "@mui/material";
import Stack from "@mui/joy/Stack";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Tooltip from "@mui/joy/Tooltip";
import Skeleton from "@mui/joy/Skeleton";
import Rating from "@mui/material/Rating";
import { SvgIconProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";

import { CandidateInfoContentContainer } from "./ContactInfo";
import { AllCandidatesReachOutBodyContainer } from "../AllCandidatesReachout";
import { CandidatesReachoutEmptyPlaceholder } from "./CandidatesReachoutEmptyPlaceholder";
import {
    SelectProject,
    SelectProjectFilterProps,
    useFetchCandidateProjects,
    useSelectProjectValues,
} from "./SelectProject";
import {
    useFetchCandidateInsightsQuery,
    InsightDescription,
} from "../../../store/apis/all-candidates/all-candidates.api";
import { selectInsightsSelectedProject, setInsightsSelectedProject } from "../all-candidates-reachout.slice";
import { useAppDispatch } from "../../../store";

const iconProps: SvgIconProps["sx"] = {
    marginTop: "0.3rem",
    marginX: "0.3rem",
};

function InsightPoint(props: InsightDescription) {
    const { text, criterion, intent } = props;
    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack gap={1.5} direction="row">
                <FiberManualRecordRoundedIcon
                    sx={{
                        marginTop: "0.5rem",
                        fontSize: "0.8rem",
                    }}
                />
                <Tooltip title={criterion} arrow>
                    <Typography level="body-sm" fontWeight={400}>
                        {text}
                    </Typography>
                </Tooltip>
            </Stack>
            {intent ? (
                <CheckRoundedIcon color="success" sx={iconProps} />
            ) : (
                <WarningRoundedIcon color="warning" sx={iconProps} />
            )}
        </Stack>
    );
}

function InsightEmptyPlaceholder() {
    return (
        <CandidatesReachoutEmptyPlaceholder
            icon={<BallotRoundedIcon sx={{ fontSize: "4rem", color: grey[400] }} />}
            title="No Insights found"
            message="Insights has not been generated for this candidate on this project."
        />
    );
}

export function Insights() {
    const params = useParams();
    return (
        <CandidateInfoContentContainer>
            <AllCandidatesReachOutBodyContainer>
                <InsightsHeader />
                <Divider />
                <InsightsMain candidateId={params.id} />
            </AllCandidatesReachOutBodyContainer>
        </CandidateInfoContentContainer>
    );
}

function InsightsLoading() {
    const skeletons = (
        <>
            {Array(5)
                .fill(1)
                .map((_, idx) => (
                    <Skeleton key={`loading-${idx}`} variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
                ))}
        </>
    );
    return (
        <Card
            variant="outlined"
            sx={{
                p: 2,
                m: 2,
            }}
        >
            <CardContent>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30%" }} />
                </Stack>
                <Divider />
                <Stack
                    gap={1}
                    sx={(theme) => ({
                        overflow: "auto",
                        height: "calc(100vh - 325px)",
                    })}
                >
                    {skeletons}
                </Stack>
            </CardContent>
        </Card>
    );
}

function InsightsMain({ candidateId }: { candidateId?: string }) {
    const project = useSelector(selectInsightsSelectedProject);
    const { isLoading: isFetchingProjects } = useFetchCandidateProjects();
    const { isLoading, data, isError } = useFetchCandidateInsightsQuery(
        {
            candidateId: candidateId || "",
            projectId: project.value,
        },
        {
            skip: !project.value || !candidateId,
        }
    );

    if (isLoading || isFetchingProjects) {
        return <InsightsLoading />;
    }

    if (isError || isEmpty(data)) {
        return <InsightEmptyPlaceholder />;
    }

    const { value, description } = data;

    return (
        <Card
            variant="outlined"
            sx={{
                p: 2,
                m: 2,
            }}
        >
            <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography level="title-lg">Star Rating</Typography>
                    <Experimental_CssVarsProvider>
                        <Rating value={value} readOnly />
                    </Experimental_CssVarsProvider>
                </Stack>
                <Divider />
                <Stack
                    gap={1}
                    sx={() => ({
                        overflow: "auto",
                        maxHeight: "calc(100vh - 325px)",
                    })}
                >
                    {isLoading ? (
                        <InsightsLoading />
                    ) : (
                        <>
                            {description.map((i) => {
                                return <InsightPoint {...i} key={i.criterion} />;
                            })}
                        </>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}

function InsightsProjectFilter() {
    const dispatch = useAppDispatch();
    const selectedProject = useSelector(selectInsightsSelectedProject);
    const { ...rest } = useSelectProjectValues({ project: selectedProject });

    const handleSelectChange: SelectProjectFilterProps["handleSelectChange"] = (value) => {
        dispatch(setInsightsSelectedProject(value));
    };
    return <SelectProject {...rest} selectedProject={selectedProject} handleSelectChange={handleSelectChange} />;
}

function InsightsHeader() {
    return (
        <Stack p={2} pb={1}>
            <InsightsProjectFilter />
        </Stack>
    );
}
