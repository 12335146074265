import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const CustomStack = styled((props: PaperProps) => (
    <Paper elevation={0} {...props} />
))<PaperProps>(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    gridTemplateColumns: "1fr",
    height: "100%",
    overflow: "auto",
}));
