import { forwardRef } from "react";

import { IconProps } from "./index";

export const Nucleus = forwardRef<SVGSVGElement, IconProps>(({ size = 24, ...rest }, ref) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            {...rest}
        >
            <g transform="translate(1.5, 0)">
                <path
                    d="M25.2229 28.6107C21.4464 26.9026 18.1859 26.0105 14.1819 26.0105H14.0008C14.104 24.1988 14.5168 22.5774 17.493 23.0235C18.4197 23.163 18.7293 23.275 19.6561 23.4674L13.3794 9.5342V9.53842L13.3752 9.5342L7.09858 23.4674C8.02534 23.275 8.33496 23.163 9.26171 23.0235C12.2378 22.5774 12.6507 24.1988 12.7539 26.0105H12.5727C8.56875 26.0105 5.30826 26.9026 1.53175 28.6107C1.43697 28.6551 1.33797 28.5578 1.3822 28.4627L13.272 2.06765C13.2931 2.02325 13.3352 2 13.3752 2H13.3794C13.4195 2 13.4616 2.02325 13.4826 2.06765L25.3725 28.4627C25.4167 28.5578 25.3177 28.6551 25.2229 28.6107Z"
                    fill="#947FEE"
                />
            </g>
        </svg>
    );
});
