import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { checkEasyGrowth } from "../pages/Signin/Signin.reducer";

export function EasyGrowthValidator({ children }: { children: React.ReactNode }) {
    const isEasyGrowth = useSelector(checkEasyGrowth);

    if (!isEasyGrowth) {
        return <Navigate to="/" />;
    }

    return <>{children}</>;
}
