import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

import Navbar from "../../components/Navbar/Navbar";
import { selectUser } from "../Signin/Signin.reducer";
import IntegrationsData from "./components/IntegrationData";

export default function Integrations() {
    const user = useSelector(selectUser);
    return (
        <Stack height={user.emailAuthorized ? "calc(100vh - 60px)" : "calc(100vh - 86px)"} sx={{ overflowY: "auto" }}>
            <Navbar />

            <IntegrationsData />
        </Stack>
    );
}
