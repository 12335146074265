import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Avatar, Checkbox, Grid, Stack, Typography } from "@mui/material";
import { isEmpty, uniq } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ICandidate } from "../../../../project/project.types";
import styles from "../Candidates.module.css";
import { selectCandidate, selectCandidates, selectSelectedCandidate } from "../Candidates.reducer";
import { skillType } from "../Candidates.type";

const CadidateDetails = ({ data }: { data: ICandidate }) => {
    const dispatch = useDispatch();
    const candidates = useSelector(selectCandidates);
    const selectedCandidate: string[] = useSelector(selectSelectedCandidate);
    const [showCadidateModal, setShowCadidateModal] = useState(false);

    const onSelectCandidate = (isChecked: boolean, item: string) => {
        const newCandidates: string[] = [...selectedCandidate];
        if (isChecked) {
            newCandidates.push(item);
        } else {
            const index = newCandidates.indexOf(item);
            newCandidates.splice(index, 1);
        }
        dispatch(selectCandidate(newCandidates));
    };

    let experience: any = [];
    if (!isEmpty(data.experience)) {
        experience = uniq(data.experience.map((exp) => exp.org.split(" ·")[0]));
    }

    const isChecked: boolean = selectedCandidate.indexOf(data._id) > -1;

    return (
        <Stack flexDirection="row" alignItems="flex-start">
            <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelectCandidate(e.target.checked, data._id)}
                checked={isChecked}
                size="small"
            />
            <Stack
                flexDirection="row"
                className={styles.cardContainer}
                key={data._id}
                onClick={() => setShowCadidateModal(true)}
            >
                <Avatar
                    alt={data.name}
                    src={data.profileImage}
                    className={styles.profileImage}
                    sx={{ width: 60, height: 60 }}
                />
                <Stack width="100%">
                    <Grid container>
                        <Grid item xs={6} pl={0} pr="5px" spacing={2}>
                            <BasicInfo item={data} />
                        </Grid>
                        <Grid item xs={3} pl={0}>
                            <Typography variant="body1" className={styles.candidateText}>
                                <strong className={styles.locationTitle}>Email:</strong>
                                ***@gmail.com
                            </Typography>
                            <Typography variant="body1" className={styles.candidateText}>
                                <strong className={styles.locationTitle}>Location:</strong>
                                {data.location || "NA"}
                            </Typography>
                            <Typography variant="body1" className={styles.candidateText}>
                                <strong className={styles.locationTitle}>Past companies:</strong>
                                {isEmpty(experience)
                                    ? "NA"
                                    : experience?.slice(0, 3)?.map((item: any, i: number) => (
                                          <Typography key={i} variant="body2">
                                              {item}
                                          </Typography>
                                      ))}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} pl={0}>
                            {data.profileUrl && (
                                <Link to={data.profileUrl} target="_blank" className={styles.linkedInLink}>
                                    <LinkedInIcon />
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
            {/* {showCadidateModal && (
                <BaseModal
                    onClose={() => setShowCadidateModal(false)}
                    overlayStyles={{ width: "70vw", height: "80vh" }}
                >
                    <CandidateModal data={data} allCandidates={candidates} index={1} />
                </BaseModal>
            )} */}
        </Stack>
    );
};

const BasicInfo = ({ item }: { item: ICandidate }) => {
    return (
        <>
            <Typography variant="h5" className={styles.candidateName}>
                <strong>{item.name}</strong>
            </Typography>
            <Typography variant="body1" className={styles.candidateText}>
                {item.title}
                <strong className={styles.org}>{item.experience[0]?.org}</strong>
            </Typography>
            <Typography variant="h6" mt="20px" className={styles.candidateText}>
                <strong>Experience: </strong>
                {item?.totalExperience?.label || "NA"}
            </Typography>
            <div>
                <Typography variant="h6" mt="20px" className={styles.candidateText} sx={{ display: "inline-flex" }}>
                    <strong>Skills:</strong>
                </Typography>
                {isEmpty(item.skills)
                    ? "NA"
                    : item.skills?.slice(0, 5)?.map((item: skillType) => (
                          <Typography variant="body2" className={styles.candidateSkills}>
                              {item.name}
                          </Typography>
                      ))}
            </div>
        </>
    );
};

export default CadidateDetails;
