import Accordion, { AccordionProps } from "@mui/joy/Accordion";
import AccordionSummary, { AccordionSummaryProps } from "@mui/joy/AccordionSummary";
import AccordionDetails, { AccordionDetailsProps } from "@mui/joy/AccordionDetails";
import { styled } from "@mui/joy/styles";

const CustomAccordion = styled((props: AccordionProps) => <Accordion {...props} />)(() => ({
    boxShadow: "none",
    border: 1,
    borderColor: "divider",
    marginY: "0 !important",
    "&:before": {
        display: "none",
    },
}));

export const CustomAccordionDetails = styled(AccordionDetails)<AccordionDetailsProps & { isExpanded?: boolean }>(
    ({ theme, isExpanded = false }) => ({
        backgroundColor: "#fbfcfe",
        border: `1px solid ${theme.palette.divider}`,
        borderTop: 0,
        padding: isExpanded ? "0.7rem" : 0,
    })
);

export const CustomAccordionSummary = styled(
    ({ isExpanded, ...rest }: AccordionSummaryProps & { isExpanded: boolean }) => <AccordionSummary {...rest} />
)(({ theme, isExpanded }) => ({
    backgroundColor: "#fbfcfe",
    border: `1px solid ${theme.palette.divider}`,
    "& .MuiStack-root ": {
        margin: "0.5rem 0rem",
        marginLeft: "0.5rem",
    },
    "& .MuiAccordionSummary-indicator": {
        marginRight: "0.5rem",
    },
}));

export default CustomAccordion;
