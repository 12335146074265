import { useCallback, useMemo } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { Stack, Typography } from "@mui/material";
import { useDropzone, type FileWithPath } from "react-dropzone";

import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../../utils/helper";

type JDUploadProps = {
    handleJdUpload: (key: File[]) => void;
};

export default function JDUpload({ handleJdUpload }: JDUploadProps) {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        });
        handleJdUpload(files);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "application/pdf": [".pdf"],
            },
            multiple: false,
            onDrop,
            maxSize: 10000000,
        });

    const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
        <Stack key={file.path} direction={"row"} alignItems={"center"} spacing={0.5}>
            <DescriptionIcon />
            <Typography>{file.path}</Typography>
        </Stack>
    ));

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? "Drop JD here ..." : "Click to upload your JD or drag and drop (PDF format only)"}
            </div>
            <Stack mt={1} spacing={0.5}>
                {acceptedFileItems}
            </Stack>
        </>
    );
}
