import dayjs, { Dayjs } from "dayjs";

export const getDiffInDays = (d: Dayjs | null) => dayjs().diff(dayjs(d), "day");

export const calculateExperienceDuration = (duration: string | null) => {
    if (!duration) return null;
    try {
        const isPresent = (mon: string) => mon?.trim() === "Present";

        const [startDate, endDateWithDuration] = duration.split(/\s[-|–]\s/);

        if (!startDate && !endDateWithDuration) return duration;

        let endDate = "";

        if (endDateWithDuration.indexOf("|") !== -1) {
            endDate = endDateWithDuration.split("|")[0].trim(); // some duration are of form 2021 - 2023 | 2 years
        }
        if (endDateWithDuration.indexOf("·") !== -1) {
            endDate = endDateWithDuration.split("·")[0];
        }
        if (endDateWithDuration.indexOf("•") !== -1) {
            endDate = endDateWithDuration.split("•")[0];
        }
        if (endDateWithDuration.indexOf(".") !== -1) {
            endDate = endDateWithDuration.split(".")[0];
        }

        if (isPresent(startDate) && isPresent(endDate)) {
            return "-";
        }

        const dayjsEndDate = isPresent(endDate) ? dayjs() : dayjs(endDate);
        const dayjsStartDate = dayjs(startDate);

        const yearDiff = dayjsEndDate.diff(dayjsStartDate, "years");
        const monthDiff = dayjsEndDate.diff(dayjsStartDate, "months") % 12;

        return `${startDate} - ${endDate} . ${`${yearDiff ? `${yearDiff} ${yearDiff > 1 ? "yrs " : "yr "}` : ""}${
            monthDiff ? `${monthDiff} ${monthDiff > 1 ? "mos" : "mo"}` : ""
        }`}`.trim();
    } catch (error: any) {
        return duration;
    }
};
