import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { companyDetailsListSchema } from "./companies.schema";
import { CompanyDetailsList, CompanyNames } from "./companies.types";

import { fetchBaseQueryWithZod } from "../utils";

export const companiesApi = createApi({
    reducerPath: "companiesApi",
    baseQuery: fetchBaseQueryWithZod,
    endpoints: (builder) => ({
        getCompaniesDetails: builder.query<CompanyDetailsList, CompanyNames>({
            query: (companyNames = []) => ({
                url: `/companyDesc/desc?company_names=${companyNames}`,
                method: "GET",
            }),
            extraOptions: {
                dataSchema: companyDetailsListSchema,
            },
        }),
    }),
});

export const { useGetCompaniesDetailsQuery } = companiesApi;
