import { useGetCandidateMultipleProjectStatusDetailsQuery } from "@/store/apis/projects/projects.api";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Stack } from "@/ui/Stack/Stack";
import { useParams } from "react-router-dom";
import Loader from "@/components/Loader/Loader";
import { Tooltip } from "@/ui";
import { useSelector } from "react-redux";
import { isShowMultipleProjectStatusEnabled } from "@/store/reducers/signin/Signin.reducer";

export default function CandidateMultipleProjectStatusTab({ data }: { data: any }) {
    const { id: projectId } = useParams();
    const showMultipleProjectStatus = useSelector(isShowMultipleProjectStatusEnabled);

    const {
        data: candidateMultipleProjectStatusDetailsData,
        isFetching,
        isLoading,
    } = useGetCandidateMultipleProjectStatusDetailsQuery(
        {
            candidateId: data?._id,
        },
        { skip: !data?._id || !showMultipleProjectStatus, refetchOnMountOrArgChange: true }
    );

    const mapStatusText = (status: string) => {
        if (!status) return null;
        switch (status) {
            case "PIPELINE":
                return "Not contacted";
            case "NOT_CONTACTED":
                return "Not contacted";
            case "CONTACTED":
                return "Contacted";
            case "RESPONDED":
                return "Responded";
            case "POSITIVELY_RESPONDED":
                return "Positively responded";
            case "SHORTLISTED":
                return "Shortlisted";
            case "REJECTED":
                return "Rejected";
            case "TO_BE_REVIEWED":
                return "To be reviewed";
            case "NOT_INTERESTED":
                return "Not interested";
            case "TRIGGERED":
                return "Triggered";
            default:
                return status;
        }
    };

    if (isFetching || isLoading)
        return (
            <Stack className="max-h-[35vh] overflow-y-auto pb-2 px-4 mt-5 ">
                <div className="rounded-lg  overflow-auto">
                    <Loader />
                </div>
            </Stack>
        );

    return (
        <Stack className="max-h-[35vh] overflow-y-auto pb-2 px-4 mt-5 ">
            <div className="rounded-lg border border-gray-200 overflow-auto">
                <table className="min-w-full bg-white text-left text-sm text-gray-700">
                    <thead className="bg-gray-100 text-gray-900" style={{ position: "sticky", top: 0 }}>
                        <tr className="">
                            {/* <th className="px-6 py-3 text-center text-sm font-normal">Project ID</th> */}
                            <th className="px-6 py-3 text-sm font-normal">Project Name</th>
                            <th className="px-6 py-3 text-sm font-normal">Created By</th>
                            <th className="px-6 py-3 text-sm font-normal">Rating</th>
                            <th className="px-6 py-3 text-center text-sm font-normal">Review stage</th>
                            <th className="px-6 py-3 text-center text-sm font-normal">Outreach stage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {candidateMultipleProjectStatusDetailsData?.map((project) => (
                            <tr
                                key={project?._id}
                                className="border-b hover:bg-gray-50 cursor-pointer"
                                style={{
                                    display:
                                        projectId?.toString() === project?.projectId?.toString() ? "none" : "table-row",
                                }}
                            >
                                {/* <td className="px-6 py-4 text-center text-xs font-normal">{project?.projectId}</td> */}
                                <td className="px-6 py-4 text-xs font-normal">
                                    <Tooltip title={project?.projectName?.length > 25 && project?.projectName} arrow>
                                        <span>
                                            {project?.projectName?.length > 25
                                                ? `${project?.projectName.substring(0, 25)}...`
                                                : project?.projectName}
                                        </span>
                                    </Tooltip>
                                </td>{" "}
                                <td className="px-6 py-4 text-xs font-normal">{project?.projectCreatedBy}</td>
                                <td className="px-6 py-4 text-xs font-normal">
                                    {project?.starRating?.label && project?.starRating?.label !== "UNQ" ? (
                                        <>
                                            {project?.starRating?.label}
                                            <StarRoundedIcon
                                                style={{ color: "#FCC737", fontSize: "18px", marginRight: "5px" }}
                                            />
                                        </>
                                    ) : project?.starRating?.label === "UNQ" ? (
                                        "Unqualified"
                                    ) : (
                                        "Not rated"
                                    )}
                                </td>
                                <td className="px-6 py-4 text-center text-xs font-normal">
                                    {project?.shortlist1 ? "Shortlisted ++" : mapStatusText(project?.reviewStage)}
                                </td>
                                <td className="px-6 py-4 text-center text-xs font-normal">
                                    {mapStatusText(project?.stage)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Stack>
    );
}
