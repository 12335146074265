import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAccountRootState, ICheckoutPayload, IEmailType, IUserPlan } from "./myAccount.types";

import { RootState } from "@/store";
import { IActionPayload } from "@/store/reducers/loaders.reducer";

const initialState: IAccountRootState = {
    plans: [],
    userPlan: {
        name: "Free",
        createdAt: "2023-04-27T07:49:01.798Z",
        validTill: "Forever",
        remainingCredits: 30,
        totalCredits: 100,
    },
    emailType: "",
    accountType: "",
    aliasName: {
        alias: "",
        outboundEmail: "",
    },
    defaultAlias: "",
    virtualUserSignature: "",
    virtualUser: {
        alias: "",
        outboundEmail: "",
    },
    allOrgMembers: [],
    profileCreditInfo: "",
    linkedinOutreachCredit: "",
    smsOutreachCredit: "",
    emailCredit: "",
    organizationDetails: "",
    emailEnrichmentCredit: "",
    phoneEnrichmentCredit: "",
    exclusionList: [],
};

const reducers = {
    fetchPlans(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setPlans(state: typeof initialState, { payload }: { payload: any[] }) {
        state.plans = payload;
    },
    setVirtualUser(state: typeof initialState, { payload }: { payload: any }) {
        state.allOrgMembers = payload;
    },
    setVirtualUserSignature(state: typeof initialState, { payload }: { payload: any }) {
        state.virtualUserSignature = payload;
    },
    fetchUserPlan(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setUserPlan(state: typeof initialState, { payload }: { payload: IUserPlan }) {
        state.userPlan = payload;
    },
    createCheckoutSession(state: typeof initialState, { payload }: { payload: IActionPayload & ICheckoutPayload }) {},
    resetMyAccount(state: typeof initialState) {},
    setEmailType(state: typeof initialState, { payload }: { payload: IEmailType }) {
        state.emailType = payload;
    },
    addVirtualUserAlias(state: typeof initialState, { payload }: { payload: any }) {
        state.aliasName = payload;
    },
    setDefaultAlias(state: typeof initialState, { payload }: { payload: any }) {
        state.defaultAlias = payload;
    },
    setSmtpAuthorization(state: typeof initialState, { payload }: { payload: any }) {},
    updateAlias(state: typeof initialState, { payload }: { payload: any }) {
        state.virtualUser = payload;
    },
    deleteAlias(state: typeof initialState, { payload }: { payload: any }) { },
    fetchAlias(state: typeof initialState, { payload }: { payload: IActionPayload }) { },
    fetchExclusionList(state: typeof initialState, { payload }: { payload: IActionPayload }) { },
    disconnectAlias(state: typeof initialState, { payload: any }) { },
    disconnectLIAlias(state: typeof initialState, { payload: any }) { },

    // Credits tracking apis
    fetchProfileCreditInfo(state: typeof initialState, { payload }: { payload: IActionPayload & any }) {},
    setProfileCreditsInfo(state: typeof initialState, { payload }: { payload: any }) {
        state.profileCreditInfo = payload;
    },

    // Linkedin Outreach Credits
    fetchLinkedinOutreachCredit(state: typeof initialState, { payload }: { payload: IActionPayload & any }) {},
    setLinkedinOutreachCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.linkedinOutreachCredit = payload;
    },

    // SMS Outreach Credits
    fetchSmsOutreachCredit(state: typeof initialState, { payload }: { payload: IActionPayload & any }) {},
    setSmsOutreachCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.smsOutreachCredit = payload;
    },

    // Email Credits
    fetchEmailCredit(state: typeof initialState, { payload }: { payload: IActionPayload & any }) {},
    setEmailCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.emailCredit = payload;
    },

    // Organization Details
    fetchOrganizationDetails(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setOrganizationDetails(state: typeof initialState, { payload }: { payload: any }) {
        state.organizationDetails = payload;
    },

    // Email Enrichment credits
    fetchEmailEnrichmentCredit(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setEmailEnrichmentCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.emailEnrichmentCredit = payload;
    },

    // Phone Enrichment credits
    fetchPhoneEnrichmentCredit(state: typeof initialState, { payload }: { payload: IActionPayload }) {},
    setPhoneEnrichmentCredit(state: typeof initialState, { payload }: { payload: any }) {
        state.phoneEnrichmentCredit = payload;
    },

    // Alert Toggle
    setCreditAlertPreference(_state: typeof initialState, _action: PayloadAction<any>) {},

    setAccountType(state: typeof initialState, { payload }: { payload: any }) {
        state.accountType = payload;
    },
    setExclusionList(state: typeof initialState, { payload }: { payload: any }) {
        state.exclusionList = payload;
    },
};

export const myAccountSlice = createSlice({
    name: "myAccount",
    initialState,
    reducers,
});

export default myAccountSlice.reducer;


export const {
    fetchPlans,
    setPlans,
    fetchUserPlan,
    setUserPlan,
    createCheckoutSession,
    resetMyAccount,
    setEmailType,
    setDefaultAlias,
    setSmtpAuthorization,
    addVirtualUserAlias,
    fetchAlias,
    fetchExclusionList,
    setVirtualUser,
    setVirtualUserSignature,
    updateAlias,
    deleteAlias,
    disconnectAlias,
    disconnectLIAlias,
    fetchProfileCreditInfo,
    setProfileCreditsInfo,
    fetchLinkedinOutreachCredit,
    setLinkedinOutreachCredit,
    fetchSmsOutreachCredit,
    setSmsOutreachCredit,
    fetchEmailCredit,
    setEmailCredit,
    fetchOrganizationDetails,
    setOrganizationDetails,
    fetchEmailEnrichmentCredit,
    setEmailEnrichmentCredit,
    fetchPhoneEnrichmentCredit,
    setPhoneEnrichmentCredit,
    setAccountType,
    setExclusionList,
    setCreditAlertPreference,
} = myAccountSlice.actions;

export const selectalias = (state: RootState) => state.myAccount.aliasName;
export const selectDefaultAlias = (state: RootState) => state.myAccount.defaultAlias;
export const selectEmailType = (state: RootState) => state.myAccount.emailType;
export const selectUserPlan = (state: RootState) => state.myAccount.userPlan;
export const selectVirtualUser = (state: RootState) => state.myAccount.allOrgMembers;
export const selectProfileCreditInfo = (state: RootState) => state.myAccount.profileCreditInfo;
export const selectLinkedinOutreachCredit = (state: RootState) => state.myAccount.linkedinOutreachCredit;
export const selectSmsOutreachCredit = (state: RootState) => state.myAccount.smsOutreachCredit;
export const selectEmailCredit = (state: RootState) => state.myAccount.emailCredit;
export const selectOrganizationDetails = (state: RootState) => state.myAccount.organizationDetails;
export const selectEmailEnrichmentCredit = (state: RootState) => state.myAccount.emailEnrichmentCredit;
export const selectPhoneEnrichmentCredit = (state: RootState) => state.myAccount.phoneEnrichmentCredit;
export const selectAccountType = (state: RootState) => state.myAccount.accountType;
