import Stack from "@mui/material/Stack";
import { Fragment } from "react";

import FunnelView from "./components/FunnelView/FunnelView";
import MultipleInteractionView from "./components/FunnelView/MultipleInteractionView";
import GenerateReportModal from "./components/GenerateReport/GenerateReportModal";
import UsageStats from "./components/UsageStats/UsageStats";

import Navbar from "../../components/Navbar/Navbar";
import useDisclosure from "../../hooks/useDisclosure";

export default function AnalyticsV2() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Fragment>
            <Navbar />
            <Stack height={"calc(100dvh - 65px)"} overflow={"auto"} direction="column" bgcolor="white" px={4}>
                <MultipleInteractionView />
                <FunnelView onOpenGenerateReportModal={onOpen} />
                <UsageStats />
            </Stack>
            <GenerateReportModal isOpen={isOpen} onClose={onClose} />
        </Fragment>
    );
}
