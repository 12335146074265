import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Box, FormControl, FormLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import { selectSalaryRange, setValues } from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

export function SalaryRange({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectSalaryRange);

    const defaultValue = { value: "", excluded: false };
    const [start, end] = [
        { defaultValue, ...values[0] },
        { defaultValue, ...values[1] },
    ];

    const handleChangeStartSalaryRange = (value: string) => {
        if (Number(value) >= Number(end.value || 0)) {
            end.value = "";
        }
        start.value = value;
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    const handleChangeEndSalaryRange = (value: string) => {
        if (Number(value) <= Number(start.value || 0)) {
            start.value = "";
        }
        end.value = value;
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    return (
        <FormControl component={Box}>
            <FormLabel sx={{ mb: 1, fontSize: 14 }}>Salary Range</FormLabel>
            <Box display="flex" alignItems="center" gap={2}>
                <Select
                    startAdornment={<MonetizationOnIcon htmlColor="green" sx={{ mr: 1 }} />}
                    fullWidth
                    id={id}
                    size="small"
                    label="From"
                    value={start?.value}
                    onChange={(e) => handleChangeStartSalaryRange(e.target.value)}
                    MenuProps={{
                        sx: {
                            "& .MuiPaper-root.MuiList-root.MuiButtonBase-root": {
                                fontSize: "14px",
                            },
                        },
                    }}
                >
                    {Object.entries(options).map(([label, range]) => (
                        <MenuItem disabled={end.value === range} key={label} value={range}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
                to
                <Select
                    startAdornment={<MonetizationOnIcon htmlColor="green" sx={{ mr: 1 }} />}
                    fullWidth
                    size="small"
                    label="From"
                    value={end?.value}
                    onChange={(e) => handleChangeEndSalaryRange(e.target.value)}
                    MenuProps={{
                        sx: {
                            "& .MuiPaper-root.MuiList-root.MuiButtonBase-root": {
                                fontSize: "14px",
                            },
                        },
                    }}
                >
                    {Object.entries(options).map(([label, range]) => (
                        <MenuItem disabled={Number(range || 0) <= Number(start.value || 0)} key={label} value={range}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </FormControl>
    );
}
