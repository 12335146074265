import { useSelector } from "react-redux";

import { CandidateList as ResumeLibraryCandidateList } from "../../components/BlendedSearch/ResumeLibrary/CandidatesList";
import { CandidateList as RocketReachCandidateList } from "../../components/BlendedSearch/RocketReach/CandidatesList";
import { CompanyList as SalesNavCompanyList } from "../../components/BlendedSearch/SalesNavCompany/CompaniesList";
import { CandidateList as SalesNavSearchCandidateList } from "../../components/BlendedSearch/SalesNavSearch/CandidatesList";
import { selectSearchType } from "../../store/reducers/searchCandidates/searchCandidates.slice";

export function CandidatesList() {
    const searchType = useSelector(selectSearchType);

    const renderList = () => {
        switch (searchType) {
            case "resume-library":
                return <ResumeLibraryCandidateList />;
            case "salesnav":
                return <SalesNavSearchCandidateList />;
            case "salesnavCompany":
                return <SalesNavCompanyList />;
            case "rocketReach":
                return <RocketReachCandidateList />;
            default:
                return null;
        }
    };

    return renderList();
}
