import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/joy";
import Alert from "@mui/joy/Alert";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import Button from "@mui/joy/Button";
import ListItemContent from "@mui/joy/ListItemContent";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Experimental_CssVarsProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { JoyProvider } from "../../../../components/JoyProvider";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    addToLever,
    getFromLever,
    getLeverJobs,
    getLeverStages,
    selectLeverJobs,
    selectLeverStages,
    setLeverExportCandidates,
} from "../../../integrations/integrations.reducer";
import { ILeverOpportunity, ILeverOpportunityType, ILeverStageType } from "../../../integrations/integrations.types";

type LeverModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

const headers = [
    { label: "id", key: "id" },
    { label: "name", key: "name" },
    { label: "linkedInProfileUrl", key: "links" },
    { label: "emails", key: "emails" },
    { label: "resume", key: "resume" },
];

export default function LeverModal({ open, onClose, id, selectedCandidateIds, variant }: LeverModalProps) {
    const dispatch = useDispatch();
    const leverJobs = useSelector(selectLeverJobs);
    const leverStages = useSelector(selectLeverStages);
    const isLeverJobsLoading = useSelector(checkIfLoading(getLeverJobs.type));
    const isLeverStagesLoading = useSelector(checkIfLoading(getLeverStages.type));
    const isLeverSubmitting = useSelector(checkIfLoading(addToLever.type));
    const isLeverFetching = useSelector(checkIfLoading(getFromLever.type));
    const [dateError, setDateError] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            dispatch(setLeverExportCandidates([]));
        };
    }, []);

    const [opportunityType, setOpportunityType] = useState<ILeverOpportunityType>({
        type: "GENERAL",
        jobId: "",
    });
    const [stageType, setStageType] = useState<ILeverStageType>({
        open: false,
        stageId: "",
    });
    const [createdAtStart, setCreatedAtStart] = useState<Dayjs | null>(null);
    const [createdAtEnd, setCreatedAtEnd] = useState<Dayjs | null>(null);

    const isVariantFetch = variant === "FETCH";

    useEffect(() => {
        if (isVariantFetch && open) {
            setOpportunityType((prev) => ({ ...prev, type: "SPECIFIC" }));
            dispatch(
                getLeverJobs({
                    action: getLeverJobs.type,
                })
            );
        }
    }, [variant, open]);

    const handleCloseModal = () => {
        setOpportunityType({
            type: "GENERAL",
            jobId: "",
        });
        setStageType({
            open: false,
            stageId: "",
        });
        onClose();
    };

    const handleOpportunityChange = (value: ILeverOpportunity) => {
        if (value === "SPECIFIC") {
            dispatch(
                getLeverJobs({
                    action: getLeverJobs.type,
                })
            );
        }
        setOpportunityType({
            ...opportunityType,
            type: value as ILeverOpportunity,
        });
    };

    const handleStageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            dispatch(
                getLeverStages({
                    action: getLeverStages.type,
                })
            );
        }
        setStageType({
            ...stageType,
            open: e.target.checked,
        });
    };

    const handleLeverAdd = () => {
        if (opportunityType.type === "SPECIFIC" && !opportunityType.jobId) {
            dispatch(setErrorNotification("Please select job"));
            return;
        }

        if (stageType.open && !stageType.stageId) {
            dispatch(setErrorNotification("Please select stage"));
            return;
        }

        if (isVariantFetch) {
            dispatch(
                getFromLever({
                    action: getFromLever.type,
                    job: opportunityType,
                    stage: stageType,
                    createdAtStart,
                    createdAtEnd,
                    projectId: Number(id),
                    onSuccess: handleCloseModal,
                })
            );
        } else {
            dispatch(
                addToLever({
                    projectId: Number(id),
                    candidateIds: selectedCandidateIds,
                    action: addToLever.type,
                    job: opportunityType,
                    stage: stageType,
                    onSuccess: handleCloseModal,
                })
            );
        }
    };

    return (
        <JoyProvider>
            <Modal
                open={open}
                onClose={onClose}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: 500,
                        borderRadius: "md",
                        p: 2,
                        py: 4,
                        boxShadow: "lg",
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography level="h4" mb={3}>
                        {!isVariantFetch ? "Send to Lever" : "Fetch from Lever"}
                    </Typography>

                    <Stack gap={3}>
                        {!isVariantFetch && (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <Typography level="body-sm">Select Opportunity Type:</Typography>

                                <Select
                                    onChange={(e, newValue) => handleOpportunityChange(newValue as ILeverOpportunity)}
                                    placeholder="Select Opportunity"
                                    sx={{ width: 200 }}
                                >
                                    <Option value={"GENERAL"}>General</Option>
                                    <Option value="SPECIFIC">Specific Job</Option>
                                </Select>
                            </Stack>
                        )}
                        {opportunityType.type === "SPECIFIC" ? (
                            isLeverJobsLoading ? (
                                <Stack alignItems="center">
                                    <CircularProgress size="sm" />
                                </Stack>
                            ) : leverJobs?.length ? (
                                <Stack>
                                    <Alert sx={{ marginBottom: 1 }}>
                                        These are recent published/internal postings from the past 6 months that have
                                        not been archived.
                                    </Alert>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        mt={2}
                                    >
                                        <Typography level="body-sm">Select Job:</Typography>
                                        <Autocomplete
                                            onChange={(e, newValue) => {
                                                const val = newValue?.id;
                                                setOpportunityType({
                                                    ...opportunityType,
                                                    jobId: val as string,
                                                });
                                            }}
                                            placeholder="Select Job"
                                            getOptionKey={(option) => option.id}
                                            options={leverJobs.map((item) => {
                                                return { label: item.text, id: item.id };
                                            })}
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option) => (
                                                <AutocompleteOption key={option.id} {...props}>
                                                    <ListItemContent sx={{ fontSize: "sm" }}>
                                                        {option.label}
                                                    </ListItemContent>
                                                </AutocompleteOption>
                                            )}
                                            sx={{ width: 200 }}
                                        />
                                    </Stack>
                                </Stack>
                            ) : (
                                <Typography py={1} level="body-sm" textAlign="center">
                                    No jobs found. Create one on your lever dashboard
                                </Typography>
                            )
                        ) : null}
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography level="body-sm">
                                {isVariantFetch ? "Fetch" : "Map"} candidates {isVariantFetch ? "from" : "to"} specific
                                stage:{" "}
                            </Typography>
                            <Switch checked={stageType.open} onChange={handleStageChange} />
                        </Stack>

                        {stageType.open ? (
                            isLeverStagesLoading ? (
                                <Stack alignItems="center">
                                    <CircularProgress size={"sm"} />
                                </Stack>
                            ) : leverStages?.length ? (
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography level="body-sm">Select Stage:</Typography>
                                    <Autocomplete
                                        onChange={(e, newValue) => {
                                            const val = newValue?.id;
                                            setStageType({
                                                ...stageType,
                                                stageId: val as string,
                                            });
                                        }}
                                        options={leverStages.map((item) => {
                                            return { label: item.text, id: item.id };
                                        })}
                                        placeholder="Select Stage"
                                        getOptionLabel={(option) => option.label}
                                        getOptionKey={(option) => option.id}
                                        renderOption={(props, option) => (
                                            <AutocompleteOption key={option.id} {...props}>
                                                <ListItemContent sx={{ fontSize: "sm" }}>
                                                    {option.label}
                                                </ListItemContent>
                                            </AutocompleteOption>
                                        )}
                                        sx={{ width: 200 }}
                                    />
                                </Stack>
                            ) : (
                                <Typography py={1} level="body-sm" textAlign="center">
                                    No stages found. Create one on your lever dashboard
                                </Typography>
                            )
                        ) : null}

                        {isVariantFetch ? (
                            <>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography level="body-sm">Select Created After:</Typography>
                                    <Experimental_CssVarsProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                sx={{
                                                    "& input": {
                                                        fontSize: "15px",
                                                        width: "135px",
                                                        paddingY: "8px",
                                                    },
                                                }}
                                                value={createdAtStart}
                                                onChange={(newValue, ctx) => {
                                                    setCreatedAtStart(newValue);
                                                    const { validationError } = ctx;
                                                    if (validationError === null) {
                                                        setDateError(false);
                                                    }
                                                }}
                                                onError={() => setDateError(true)}
                                            />
                                        </LocalizationProvider>
                                    </Experimental_CssVarsProvider>
                                </Stack>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography level="body-sm">Select Created Before:</Typography>
                                    <Experimental_CssVarsProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                sx={{
                                                    "& input": {
                                                        fontSize: "15px",
                                                        width: "135px",
                                                        paddingY: "8px",
                                                    },
                                                }}
                                                value={createdAtEnd}
                                                onChange={(newValue, ctx) => {
                                                    setCreatedAtEnd(newValue);
                                                    const { validationError } = ctx;
                                                    if (validationError === null) {
                                                        setDateError(false);
                                                    }
                                                }}
                                                onError={() => setDateError(true)}
                                            />
                                        </LocalizationProvider>
                                    </Experimental_CssVarsProvider>
                                </Stack>
                            </>
                        ) : null}

                        <Stack marginTop={1} gap={1} direction="row" alignItems={"center"} justifyContent={"flex-end"}>
                            <Button variant="outlined" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    ":disabled": {
                                        color: "white",
                                        background: "#0b6bcbe6",
                                    },
                                }}
                                disabled={
                                    isLeverSubmitting ||
                                    isLeverFetching ||
                                    isLeverJobsLoading ||
                                    isLeverStagesLoading ||
                                    dateError
                                }
                                onClick={handleLeverAdd}
                            >
                                {isLeverSubmitting || isLeverFetching ? "Loading" : "Confirm"}
                            </Button>
                        </Stack>
                    </Stack>
                </Sheet>
            </Modal>
        </JoyProvider>
    );
}
