import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

import { CV_SOURCE_OPTIONS } from "../../../constant/Constant";

export type TCVSource = {
    label: string;
    value: string;
};

interface CVSourceProps {
    onChange: (option: TCVSource) => void;
    value?: TCVSource | null;
}

export default function CVSource(props: CVSourceProps) {
    const { value, onChange } = props;

    return (
        <Stack direction="column" gap={2}>
            <InputLabel sx={{ pb: 1 }} size="small" id="cv-source-select">
                Select source
            </InputLabel>
            <Typography component="i" variant="caption">
                EasySource helps track your hiring outcomes from different sources of CVs/candidate profiles
            </Typography>
            <Select size="small" id="cv-source-select" value={value?.label || ""}>
                {CV_SOURCE_OPTIONS.map((source) => (
                    <MenuItem key={source.label} value={source.label} onClick={() => onChange(source)}>
                        {source.label}
                    </MenuItem>
                ))}
            </Select>
            {value?.label === "Other" && (
                <TextField
                    type="text"
                    label="Please specify"
                    size="small"
                    onChange={(e) => onChange({ label: value.label, value: e.target.value })}
                />
            )}
        </Stack>
    );
}
