import { Typography, Card, CardContent, CardActions, Button } from "@mui/material";
import React , { useState ,createElement } from "react"
 const CardComponent = (props) => {
    const [hovered, setHovered] = useState<string | null>(null);
    const [open, setOpen] = useState<boolean>(false);
  
    return (
      <>
        <Card onMouseEnter={() => {
          setHovered(props.data.title)
        }}
          onMouseLeave={() => {
            setHovered(null);
          }}
          sx={{
            width: 350, height: 230, padding: "10px", cursor: "pointer", border: "0.5px solid lightgrey",
            borderWidth: "0.2px",
            backgroundColor: hovered === props.data.title ? "#f0f4f8" : "white",
          }}
        >
          <CardContent>
  
            <img src={props.data.logo} alt="logo" style={{ width:  props.data.imageSize ? "100px":"40px"
            , height: "40px" }} />
            <Typography variant="h5" component="div" sx={{ mt: 1, fontSize: "14px" , fontWeight: 600}}>
              {props.data.title}
            </Typography>
            <Typography sx={{
              mb: 1.5, fontSize: "12px", height: "60px" , fontWeight:410
  
            }} color="text.secondary">
              {props.data.description}
            </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {!props.data.comingSoon ? <Button
                variant="outlined"
                sx={{
                  fontSize: "12px",
                  padding: "5px 10px",
                  borderRadius: 1,
                  backgroundColor: !props.data.isModal ? "#73BBA3" : "white",
                  color: !props.data.isModal ? "white" : "primary.main", // Use theme color or custom color here
                  borderColor: props.data.isModal ? "primary.main" : "#73BBA3",
                  ...(!props.data.isModal && {
                    '&.Mui-disabled': {
                      backgroundColor: "#73BBA3", // Green background when disabled
                      color: "white", // Text color when disabled
                      borderColor: "#73BBA3" // Border color when disabled
                    }
                  }),
                  ...(!props.data.isModal && props.data.canEdit && {
                    '&.MuiButton-outlined': {
                      '&:hover': {
                        backgroundColor: "#73BBA3",
                        color: "white"
                      },
                      '&:active': {
                        backgroundColor: "#73BBA3",
                        color: "white"
                      }
                    }
                  }),
                }}
                disabled={!props.data.isModal && !props.data.canEdit}
                onClick={() => props.data.isModalOpen ? setOpen(true) : null}
                size="small"
              >
                {props.data.isModal ? "Connect" : "Connected"}
              </Button> : <Button variant="outlined" sx={{ fontSize: "12px", padding: "5px 10px", borderRadius: 1, }} disabled={props.data.comingSoon} size="small">Coming Soon</Button>}
            </div>
          </CardContent>
          <CardActions>
  
          </CardActions>
        </Card>
        {open &&  props.data.component && createElement(props.data.component, { onClose: () => setOpen(false) })}
      </>
    )
  }
  export default CardComponent;