import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";

type CandidatesReachoutEmptyPlaceholderProps = {
    icon: React.ReactNode;
    trigger?: React.ReactNode;
    message: string;
    title: string;
};

export function CandidatesReachoutEmptyPlaceholder({
    icon,
    message,
    title,
    trigger,
}: CandidatesReachoutEmptyPlaceholderProps) {
    return (
        <Stack alignItems="center" gap={1} py={10}>
            {icon}
            <Typography level="body-lg">{title}</Typography>
            <Typography level="body-sm" textAlign="center">
                {message}
            </Typography>
            {trigger}
        </Stack>
    );
}
