import { Box, Stack, Step, StepLabel, Stepper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CandidatesList } from "./CandidatesList";
import { SetupOutreach } from "./SetupOutreach";
import { SidebarForm } from "./SidebarForm";

import { DisplayMessage } from "../../components/BlendedSearch/molecules/DisplayMessage";
import useBeforeUnloadConfirmation from "../../hooks/useBeforeUnloadConfirmation";
import { RootState } from "../../store";
import { VettingCriteria } from "../project/components/vettingCriteria/VettingCriteria";
import { refreshCandidateRating } from "../project/index.reducer";
import { checkOutreachVersion } from "../Signin/Signin.reducer";

function useShowUnloadConfirmation() {
    const searchState = useSelector((state: RootState) => state.search);
    const isShowConfirmation = Object.values(searchState).some(({ values }) => values?.length);
    return isShowConfirmation;
}

function OnboardingSteps({ step }: { step: number }) {
    const isOutreachV2 = useSelector(checkOutreachVersion) === "v2";
    const steps = ["Set up search", "Screen with AI", isOutreachV2 ? "Go to project" : "Set up outreach"];

    return (
        <Stepper
            activeStep={step}
            alternativeLabel
            sx={{
                "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                    marginTop: "0.5rem",
                },
            }}
        >
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

function ScreenWithAI() {
    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const dispatch = useDispatch();

    if (!projectId) {
        return <DisplayMessage text="Project id is required to screen with AI." />;
    }
    const handleGenerateInsights = () => {
        dispatch(
            refreshCandidateRating({
                action: refreshCandidateRating.type,
                projectId,
                candidateIds: [],
                selectAll: true,
                onSuccess: () => {
                    setSearchParams((searchParams) => {
                        searchParams.set("step", "2");
                        return searchParams;
                    });
                },
            })
        );
    };

    return (
        <Stack py={2} px={2} borderRadius={4} bgcolor="white" overflow="auto">
            <VettingCriteria
                height={840}
                variant="SEARCH"
                handleGenerateInsights={handleGenerateInsights}
                onSuccess={() => {
                    setSearchParams((searchParams) => {
                        searchParams.set("step", "2");
                        return searchParams;
                    });
                }}
            />
        </Stack>
    );
}

export function BlendSearchCandidates() {
    const [searchParams] = useSearchParams();
    const stepFromUrl = searchParams.get("step");
    const step = stepFromUrl ? Number(stepFromUrl) : 0;
    const isEdit = searchParams.get("isEdit") === "true";
    const showUnloadConfirmation = useShowUnloadConfirmation();

    useBeforeUnloadConfirmation(showUnloadConfirmation);

    return (
        <Stack
            spacing={2}
            height="100%"
            width="100%"
            sx={{
                background: "#f3f6f9",
                maxHeight: "100vh",
                maxWidth: "100vw",
                padding: "0.5rem 0",
            }}
        >
            {!isEdit && <OnboardingSteps step={step} />}
            <Box
                width="100%"
                height="100%"
                sx={{
                    display: "grid",
                    gridTemplateColumns: step === 2 ? "1fr" : "30vw 2.5fr",
                    gridTemplateRows: "1fr",
                    gap: "1rem",
                    overflowY: "auto",
                }}
            >
                {(step === 0 || step === 1) && (
                    <Box sx={{ height: "100%", overflowY: "auto" }}>
                        <SidebarForm disabled={step === 1} />{" "}
                    </Box>
                )}
                <Box sx={{ overflowY: "auto" }}>
                    {step === 0 && <CandidatesList />}
                    {step === 1 && <ScreenWithAI />}
                    {step === 2 && <SetupOutreach />}
                </Box>
            </Box>
        </Stack>
    );
}
