import { ArrowDropDown } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Accordion, AccordionDetails, AccordionSummary, Dropdown, Menu, MenuButton, MenuItem, Stack } from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./index.module.scss";

import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { SelectExclusionList } from "../../../OutReach/components/ExclusingList";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";

import { setAuthorizeModal } from "@/store/reducers/mail-alert/mailAlert.reducers";
import { selectMembers } from "@/store/reducers/manage-teams/manageTeams.reducers";
import {
    fetchExclusionList,
    setAccountType,
    setEmailType,
    setExclusionList,
} from "@/store/reducers/my-account/myAccount.reducer";
import { exclusionListIds } from "@/store/reducers/outreach/outreach.slice";
import {
    checkEasySource,
    isSESIntegrated,
    isSuperAdminUser,
    selectUser,
    selectUserOrgId,
    updateUser,
} from "@/store/reducers/signin/Signin.reducer";
import { EmailType } from "@/store/reducers/signin/Signin.types";
import { Button, Input, Separator, Switch, Tooltip, Typography } from "@/ui";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";

const emailTypeLabels: { [key in EmailType]: string } = {
    ONLY_PERSONAL: "Only personal",
    ONLY_PROFESSIONAL: "Only professional",
    PERSONAL_PREFERRED: "Personal preferred",
    PROFESSIONAL_PREFERRED: "Professional preferred",
};

const RowWithSwitch = ({
    label,
    checked,
    onChange,
    title,
}: {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    title?: string;
}) => (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
        <div style={{ display: "flex" }}>
            <Typography className="mr-2 text-sm">{label}</Typography>
            <Tooltip title={title} style={{ maxWidth: "400px" }}>
                <div>
                    <InfoOutlinedIcon
                        sx={{
                            color: "grey",
                            fontSize: "15px",
                        }}
                    />
                </div>
            </Tooltip>
        </div>
        <Switch checked={checked} onChange={onChange} />
    </Stack>
);

// Reusable Component for Row Display with Label and Status
function RowWithStatus({
    label,
    status,
    selector,
    color,
    title,
}: {
    label: string;
    status: string;
    selector: boolean;
    color: string;
    title?: string;
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const orgMembers = useSelector(selectMembers);
    const orgMembersCount = orgMembers?.filter((member) => member?.virtualUser?.parentUserId === user?._id)?.length;
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <div style={{ display: "flex" }}>
                <Typography className="mr-2 text-sm">{label}</Typography>
                <Tooltip title={title} style={{ maxWidth: "400px" }}>
                    <div>
                        <InfoOutlinedIcon
                            sx={{
                                color: "grey",
                                fontSize: "15px",
                            }}
                        />
                    </div>
                </Tooltip>
            </div>

            {selector ? (
                <Tooltip
                    style={{ maxWidth: "400px" }}
                    title="Please contact your account manager to manage controls over these features"
                >
                    <div>
                        <Button
                            label={status}
                            className="ml-2 cursor-pointer rounded-[5px] text-[14px] font-medium p-[6px]"
                            style={{ color: color, border: "1px solid", borderColor: color }}
                            size="sm"
                            variant="outline"
                        />
                    </div>
                </Tooltip>
            ) : (
                <>
                    {label === "Authorized email account" ? (
                        <Typography className="mb-2 text-sm">
                            {status ? (
                                status
                            ) : (
                                <Button
                                    size="sm"
                                    variant="secondary"
                                    style={{ padding: "2px" }}
                                    onClick={() => {
                                        dispatch(setAuthorizeModal(true));
                                    }}
                                    label={"Authorize"}
                                />
                            )}
                            <span
                                style={{
                                    color: "#0c6bcc",
                                    cursor: "pointer",
                                    display: orgMembersCount > 0 ? "inline" : "none",
                                }}
                                onClick={() => navigate(`/my-account/my-virtual-users`)}
                            >
                                {" "}
                                +{orgMembersCount} more{" "}
                            </span>
                        </Typography>
                    ) : (
                        <Typography className="mb-2">{status}</Typography>
                    )}
                </>
            )}
        </Stack>
    );
}

const useConnectionStatuses = () => {
    const { t } = useTranslation();
    const user = useSelector(selectUser);
    return [
        {
            label: "SMS configuration",
            status: user?.features?.smsEnabled ? "Enabled" : "Disabled",
            color: "grey",
            title: `Whether or not your ${t("titleLabel")} account has SMS functionality configured`,
        },
        {
            label: "LinkedIn account status",
            status: user?.liCookie ? "Connected" : "Not Connected",
            color: user?.liCookie ? "green" : "gray",
            title: `Whether or not your LinkedIn account is connected to ${t("titleLabel")}`,
        },
    ];
};

const SendGridAccountToggle = ({ checked, onChange }: { checked: boolean; onChange: (value: boolean) => void }) => {
    const [isChecked, setIsChecked] = useState(checked);
    return (
        <RowWithSwitch
            title="Enable to send out emails using SendGrid delivery. Contact your account manager for further assistance here."
            label="Enable SendGrid account"
            checked={isChecked}
            onChange={(checked) => {
                setIsChecked(checked);
                onChange(isChecked);
            }}
        />
    );
};

const SesToggle = ({ checked, onChange }: { checked: boolean; onChange: (value: boolean) => void }) => {
    const [isChecked, setIsChecked] = useState(checked);
    return (
        <RowWithSwitch
            title="Enable SES service for your account. Contact your account manager for further assistance here."
            label="Enable SES integration"
            checked={isChecked}
            onChange={(checked) => {
                setIsChecked(checked);
                onChange(isChecked);
            }}
        />
    );
};

const SkipWeekendsToggle = ({ checked, onChange }: { checked: boolean; onChange: (value: boolean) => void }) => {
    const [isChecked, setIsChecked] = useState(checked);
    return (
        <RowWithSwitch
            title="Skip weekends in Email/SMS outreach workflows: Enable to avoid sending out your email/sms workflow messages over the weekend"
            label="Skip weekends in Email/SMS outreach workflows"
            checked={isChecked}
            onChange={(checked) => {
                setIsChecked(checked);
                onChange(isChecked);
            }}
        />
    );
};

const SkipLiWeekendsToggle = ({ checked, onChange }: { checked: boolean; onChange: (value: boolean) => void }) => {
    const [isChecked, setIsChecked] = useState(checked);
    return (
        <RowWithSwitch
            title="Skip weekends in Linkedin outreach workflows: Enable to avoid sending out your linkedin workflow messages over the weekend"
            label="Skip weekends in Linkedin outreach workflows"
            checked={isChecked}
            onChange={(checked) => {
                setIsChecked(checked);
                onChange(isChecked);
            }}
        />
    );
};

const DynamicInboxRotationToggle = ({
    checked,
    onChange,
}: {
    checked: boolean;
    onChange: (value: boolean) => void;
}) => {
    const [isChecked, setIsChecked] = useState(checked);
    return (
        <RowWithSwitch
            title="Dynamic inbox rotation will provide you a setting to send out an email campaign from multiple email Ids during outreach. Switch to 'Add virtual users' tab to authorize multiple email accounts"
            label="Enable dynamic inbox rotation"
            checked={isChecked}
            onChange={(checked) => {
                setIsChecked(checked);
                onChange(isChecked);
            }}
        />
    );
};

const OutreachControl = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector(selectUser);
    const checkSESIntegrated = useSelector(isSESIntegrated);
    const checkedItems = useSelector(exclusionListIds);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const isEasySource = useSelector(checkEasySource);
    const orgId = useSelector(selectUserOrgId);
    const saveLoading = useSelector(checkIfLoading(setExclusionList.type));
    const [isEditMode, setIsEditMode] = useState(false);
    const [isLiConnEditMode, setIsLiConnEditMode] = useState(false);
    const [signature, setSignature] = useState(user?.signature);
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [selectedAccountType, setSelectedAccountType] = useState(user?.userLiProfile?.accountType || "");
    const [tempAccountType, setTempAccountType] = useState("");
    const [liConn, setLiConn] = useState<string>(user?.dailyLimitation?.linkedin ?? "60");
    const connectionStatuses = useConnectionStatuses();

    useEffect(() => {
        dispatch(fetchExclusionList({ action: fetchExclusionList.type }));
        setSelectedAccountType(user?.userLiProfile?.accountType || "");
        setLiConn(user?.dailyLimitation?.linkedin || "60");
    }, [user?.userLiProfile?.accountType]);

    const emailTypeDescriptions: { [key in EmailType]: string } = {
        ONLY_PERSONAL: `Only personal emails of the ${t("commonSingular")} will be enriched`,
        ONLY_PROFESSIONAL: `Only professional emails of the ${t("commonSingular")} will be enriched`,
        PERSONAL_PREFERRED: "Personal emails will be preferred while enriching",
        PROFESSIONAL_PREFERRED: "Professional emails will be preferred while enriching",
    };

    const userPayload = {
        signature: signature,
        name: user?.name ?? "",
        orgName: user?.orgData?.name ?? "",
        companyName: user?.companyName ?? "",
        companyLink: user?.companyLink ?? "",
        linkedinUrl: user?.userLiProfile?.profileUrl ?? "",
        authorizeEmailAddress: user.authorizedEmailAddress ?? "",
        email: user?.email ?? "",
        action: updateUser.type,
    };

    const handleSaveSignature = () => {
        setIsEditMode(false);
        dispatch(
            updateUser({
                ...userPayload,
            })
        );
    };

    const currType = user.emailSendType;
    const [emailTypeBtn, setEmailTypeBtn] = useState<EmailType | "" | null | undefined>(currType);
    const [emailTypeDescription, setEmailTypeDescription] = useState(currType ? emailTypeDescriptions[currType] : "");

    const handleEmailTypeChange = (value: EmailType, description: string) => {
        setEmailTypeBtn(value);
        setEmailTypeDescription(description);
        dispatch(setEmailType({ emailType: value }));
    };

    const handleLinkedinAccountTypeChange = (accountType: string) => {
        setTempAccountType(accountType);
        setConfirmation(true);
    };

    const handleConfirmAccountTypeChange = () => {
        setSelectedAccountType(tempAccountType);
        dispatch(setAccountType({ accountType: tempAccountType }));
        setConfirmation(false);
    };

    const handleSaveExclusionList = () => {
        dispatch(setExclusionList({ exclusionList: checkedItems }));
    };

    const handleEditLiConn = () => {
        setIsLiConnEditMode(!isLiConnEditMode);
        if (isLiConnEditMode) {
            if(+liConn > 60 || +liConn < 10){
                dispatch(setErrorNotification("LinkedIn connection request count should be between 10 to 60"));
                setLiConn(user?.dailyLimitation?.linkedin || "60");
                return;
            }
            dispatch(updateUser({ ...userPayload, liConnectionRequest: liConn }));
        }
    };

    const isDisabled = (isEasySource && orgId === 1575) || (!isEasySource && orgId === 825);

    return (
        <>
            <JoyProvider>
                <div style={{ overflowY: "hidden", height: "100%" }}>
                    <Stack sx={{ overflow: "scroll" }}>
                        <Stack
                            sx={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                minWidth: "73%",
                                maxWidth: "73%",
                                // overflowY: "scroll",
                                maxHeight: "90vh",
                            }}
                        >
                            <Accordion defaultExpanded>
                                <AccordionSummary>
                                    <div style={{ display: "flex" }}>
                                        <Typography className="text-lg font-bold p-1 pl-0 mr-2">
                                            Exclusion list
                                        </Typography>

                                        <Tooltip
                                            title={
                                                "Default settings of exclusion list for your organization. This will be applied to all your projects by default."
                                            }
                                            placement="top"
                                            style={{ maxWidth: "400px" }}
                                        >
                                            <div style={{ marginTop: "8px" }}>
                                                <InfoOutlinedIcon
                                                    sx={{
                                                        color: "grey",
                                                        fontSize: "15px",
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{ border: "1px solid #E0E0E0", borderRadius: "10px", marginBottom: "10px" }}
                                >
                                    <SelectExclusionList />
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            justifyContent: "flex-end",
                                            padding: 1.5,
                                            borderTop: "1px solid #E0E0E0",
                                        }}
                                    >
                                        <Button
                                            size="sm"
                                            variant="default"
                                            onClick={handleSaveExclusionList}
                                            label={saveLoading ? `Saving...` : `Save`}
                                        />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>

                            {/* Outreach Settings Accordion */}
                            <Accordion defaultExpanded>
                                <AccordionSummary>
                                    <Typography className="text-lg font-bold p-1 pl-0 mr-2">
                                        Outreach settings
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack spacing={3} sx={{ overflowY: "scroll" }}>
                                        <Stack direction="column">
                                            <Typography className="font-normal">Signature</Typography>
                                            <div>
                                                <RichTextEditor
                                                    enableImageModule
                                                    onChange={(e) => {
                                                        setSignature(e);
                                                    }}
                                                    value={signature?.replaceAll("\n", "<br>")}
                                                    className={`${isEditMode ? styles.signatureEditor : styles.disabled} ${!isEditMode ? styles.hideToolbar : ""}`}
                                                    style={{
                                                        height: isEditMode ? "250px" : "275px",
                                                        pointerEvents: isEditMode ? "auto" : "none",
                                                    }}
                                                />
                                            </div>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    justifyContent: "flex-end",
                                                    marginTop: isEditMode ? "70px" : "20px",
                                                }}
                                            >
                                                {isEditMode ? (
                                                    <>
                                                        <Button
                                                            size="sm"
                                                            variant="outline"
                                                            onClick={() => setIsEditMode(false)}
                                                            label="Cancel"
                                                        />

                                                        <Button
                                                            size="sm"
                                                            variant="default"
                                                            onClick={handleSaveSignature}
                                                            label="Save"
                                                        />
                                                    </>
                                                ) : (
                                                    <Button
                                                        size="sm"
                                                        variant="default"
                                                        onClick={() => setIsEditMode(true)}
                                                        label="Edit"
                                                    />
                                                )}
                                            </Stack>
                                        </Stack>

                                        <RowWithStatus
                                            label="Authorized email account"
                                            status={user?.authorizedEmailAddress ?? ""}
                                            color="black"
                                            selector={false}
                                            title={"Authorized email account associated with your profile"}
                                        />

                                        <RowWithStatus
                                            label="SMS phone number"
                                            status={user?.smsAuth?.phoneNumber ?? ""}
                                            color="black"
                                            selector={false}
                                            title={"SMS phone number associated with your profile"}
                                        />

                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <div style={{ display: "flex" }}>
                                                <Typography className="mr-2 text-sm">
                                                    LinkedIn connection request limit per day
                                                </Typography>
                                                <Tooltip
                                                    title={
                                                        "Max number of connection requests you can send with LinkedIn account in a day"
                                                    }
                                                    style={{ maxWidth: "400px" }}
                                                >
                                                    <div>
                                                        <InfoOutlinedIcon
                                                            sx={{
                                                                color: "grey",
                                                                fontSize: "15px",
                                                            }}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Input
                                                    type="number"
                                                    value={liConn}
                                                    onChange={(e) => setLiConn(e)}
                                                    style={{
                                                        maxWidth: "70px",
                                                        padding: "2px",
                                                        borderRadius: "5px",
                                                        marginRight: 5,
                                                        border: isLiConnEditMode ? "1px solid #0891B2" : "none",
                                                    }}
                                                    disabled={!isLiConnEditMode}
                                                />
                                                <Button
                                                    size="sm"
                                                    variant="default"
                                                    onClick={handleEditLiConn}
                                                    label={isLiConnEditMode ? "Save" : "Edit"}
                                                    className="h-[30px]"
                                                />
                                            </div>
                                        </Stack>

                                        {connectionStatuses.map((item, index) => (
                                            <RowWithStatus
                                                key={index}
                                                label={item.label}
                                                status={item.status}
                                                selector={true}
                                                color={item.color}
                                                title={item.title}
                                            />
                                        ))}

                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{ justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <div style={{ display: "flex" }}>
                                                <Typography className="mr-2 text-sm">Linkedin account type</Typography>
                                                <Tooltip
                                                    title={`Type of LinkedIn account that you have connected to ${t("titleLabel")} `}
                                                    style={{ maxWidth: "400px" }}
                                                >
                                                    <div>
                                                        <InfoOutlinedIcon
                                                            sx={{
                                                                color: "grey",
                                                                fontSize: "15px",
                                                            }}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <Dropdown>
                                                <MenuButton
                                                    sx={{
                                                        fontSize: "15px",
                                                        fontWeight: "400",
                                                        border: "1px solid darkgrey",
                                                        width: "210px",
                                                        justifyContent: "space-between",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "0 10px",
                                                    }}
                                                    endDecorator={<ArrowDropDown />}
                                                >
                                                    {selectedAccountType
                                                        ? selectedAccountType === "SALESNAV"
                                                            ? "Sales Navigator"
                                                            : selectedAccountType.charAt(0).toUpperCase() +
                                                              selectedAccountType.slice(1).toLowerCase()
                                                        : "Choose account type"}
                                                </MenuButton>

                                                <Menu sx={{ minWidth: 160, "--ListItemDecorator-size": "25px" }}>
                                                    {["NORMAL", "PREMIUM", "RECRUITER", "SALESNAV"].map(
                                                        (accountType) => (
                                                            <MenuItem
                                                                key={accountType}
                                                                onClick={() =>
                                                                    handleLinkedinAccountTypeChange(accountType)
                                                                }
                                                            >
                                                                {accountType === "SALESNAV"
                                                                    ? "Sales Navigator"
                                                                    : accountType.charAt(0).toUpperCase() +
                                                                      accountType.slice(1).toLowerCase()}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Menu>
                                            </Dropdown>
                                        </Stack>

                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{ justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <div style={{ display: "flex" }}>
                                                <Typography className="mr-2 text-sm">
                                                    Email enrichment and outreach preference
                                                </Typography>
                                                <Tooltip
                                                    title={
                                                        "Email enrichment and outreach preference: Preference in the type of emails you want to send (personal vs professional)"
                                                    }
                                                    style={{ maxWidth: "400px" }}
                                                >
                                                    <div>
                                                        <InfoOutlinedIcon
                                                            sx={{
                                                                color: "grey",
                                                                fontSize: "15px",
                                                            }}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            {emailTypeDescription && (
                                                <Typography className="text-xs ml-2 text-gray-500 italic">
                                                    [{emailTypeDescription}]
                                                </Typography>
                                            )}
                                            <Dropdown>
                                                <MenuButton
                                                    sx={{
                                                        fontSize: "15px",
                                                        fontWeight: "400",
                                                        border: "1px solid darkgrey",
                                                        width: "210px",
                                                        justifyContent: "space-between",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "0 10px",
                                                    }}
                                                    endDecorator={<ArrowDropDown />}
                                                    disabled={isDisabled}
                                                >
                                                    {emailTypeBtn ? emailTypeLabels[emailTypeBtn] : "Choose email type"}
                                                </MenuButton>
                                                <Menu sx={{ minWidth: 160, "--ListItemDecorator-size": "25px" }}>
                                                    {Object.keys(emailTypeLabels).map((key) => (
                                                        <MenuItem
                                                            key={key}
                                                            onClick={() =>
                                                                handleEmailTypeChange(
                                                                    key as EmailType,
                                                                    emailTypeDescriptions[key as EmailType]
                                                                )
                                                            }
                                                        >
                                                            {emailTypeLabels[key as EmailType]}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </Dropdown>
                                        </Stack>

                                        <div style={{ marginBottom: "5px" }}>
                                            <SendGridAccountToggle
                                                checked={user?.sendGrid ?? false}
                                                onChange={(isChecked) => {
                                                    dispatch(
                                                        updateUser({
                                                            sendGrid: !isChecked,
                                                            ...userPayload,
                                                        })
                                                    );
                                                }}
                                            />
                                        </div>

                                        {(isSuperAdmin || checkSESIntegrated) && (
                                            <div style={{ marginBottom: "5px" }}>
                                                <SesToggle
                                                    checked={user?.sesIntegrated ?? false}
                                                    onChange={(isChecked) => {
                                                        dispatch(
                                                            updateUser({
                                                                sesIntegrated: !isChecked,
                                                                ...userPayload,
                                                            })
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <div style={{ marginBottom: "5px" }}>
                                            <SkipWeekendsToggle
                                                checked={user?.features?.skipWeekends ?? false}
                                                onChange={(isChecked) => {
                                                    dispatch(
                                                        updateUser({
                                                            skipWeekends: !isChecked,
                                                            ...userPayload,
                                                        })
                                                    );
                                                }}
                                            />
                                        </div>

                                        <div style={{ marginBottom: "5px" }}>
                                            <SkipLiWeekendsToggle
                                                checked={user?.features?.skipLiWeekends ?? false}
                                                onChange={(isChecked) => {
                                                    dispatch(
                                                        updateUser({
                                                            skipLiWeekends: !isChecked,
                                                            ...userPayload,
                                                        })
                                                    );
                                                }}
                                            />
                                        </div>

                                        <div style={{ marginBottom: "5px" }}>
                                            <DynamicInboxRotationToggle
                                                checked={user?.features?.rotatingMailBox ?? false}
                                                onChange={(isChecked) => {
                                                    dispatch(
                                                        updateUser({
                                                            rotatingMailBox: !isChecked,
                                                            ...userPayload,
                                                        })
                                                    );
                                                }}
                                            />
                                        </div>

                                        <Typography className="text-sm">
                                            Switch to "Add virtual users" tab to authorize multiple email addresses.
                                            Please consult your account manager to understand how dynamic inbox rotation
                                            works.
                                        </Typography>
                                    </Stack>

                                    <Separator style={{ marginTop: "20px" }} />
                                    <Typography className="p-1.5 pt-2 pb-5 text-sm">
                                        Have any questions? Contact your account manager or reach out on{" "}
                                        <a
                                            style={{ color: "#2296f3", textDecoration: "none" }}
                                            href="mailto:customer-support@hirequotient.com"
                                        >
                                            customer-support@hirequotient.com
                                        </a>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </Stack>
                </div>
            </JoyProvider>

            {confirmation && (
                <MaterialProvider>
                    <BaseModal
                        onClose={() => setConfirmation(false)}
                        overlayStyles={{
                            padding: "1.7rem 1.5rem",
                            width: 550,
                        }}
                        hideCloseButton={false}
                    >
                        <Typography className="text-xl font-semibold mb-2">
                            Sure you want to change your LinkedIn account type?
                        </Typography>

                        <Typography className="text-sm">
                            Please consult your account manager before changing this field.
                        </Typography>

                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={4}>
                            <Button
                                variant="default"
                                className="p-[4px]"
                                onClick={() => setConfirmation(false)}
                                label="No"
                                size="sm"
                            />
                            <Button
                                variant="default"
                                className="p-[4px]"
                                onClick={handleConfirmAccountTypeChange}
                                size="sm"
                                label="Yes"
                            />
                        </Stack>
                    </BaseModal>
                </MaterialProvider>
            )}
        </>
    );
};

export default OutreachControl;
