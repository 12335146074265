import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Card, List, ListItem, ListItemText, Rating, Stack, Typography } from "@mui/material";
import { User as UserAvatar } from "lucide-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import FeedbackModal from "./feedbackModal";

import style from "../../pages/project/components/candidateProfileCard/candidateProfileCard.module.scss";
import ProfileTab from "../project/components/profileTab/ProfileTab";

import { CriteriaTag } from "@/components/VettingCriteriaRating";
import { selectCandidatesData, setCandidateStatus, updateCandidatesData } from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { Avatar, Tooltip } from "@/ui";

interface CandidateDetailsProps {
    candidate: any;
    index: number;
}

function CandidateDetails({ candidate, index }: CandidateDetailsProps) {
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const isViewMode = searchParams.get("isViewOnly") === "true";
    const candidatesData = useSelector(selectCandidatesData);

    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [hovered, setHovered] = useState(false);

    const isLiked = candidatesData?.[index].action === "ACCEPTED";
    const isDisliked = candidatesData?.[index].action === "REJECTED";
    const status = candidatesData?.[index].action;

    const handleLike = (action: string) => {
        dispatch(
            setCandidateStatus({
                candidateId: candidate?.candidateDetails?.[0]?._id,
                status: action,
            })
        );

        dispatch(
            updateCandidatesData({
                uuid: params.id,
                candidateReviews: [
                    {
                        candidateId: candidate?.candidateDetails?.[0]?._id,
                        action: action,
                        review: "",
                        like: true,
                        reasons: [],
                        userName: "Hiring_Manager",
                    },
                ],
            })
        );
    };

    const handleDislike = (action: string) => {

        dispatch(
            setCandidateStatus({
                candidateId: candidate?.candidateDetails?.[0]?._id,
                status: action,
            })
        );

        dispatch(
            updateCandidatesData({
                uuid: params.id,
                candidateReviews: [
                    {
                        candidateId: candidate?.candidateDetails?.[0]?._id,
                        action: action,
                        review: "",
                        like: false,
                        reasons: [],
                        userName: "Hiring_Manager",
                    },
                ],
            })
        );
    };

    return (
        <div style={{ flex: 1, padding: "20px" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    padding: 1,
                    overflow: "hidden",
                }}
            >
                <Typography variant="h5" className="font-semibold">
                    Candidate Details
                </Typography>

                <Stack
                    direction="row"
                    spacing={2}
                    style={{
                        opacity: isViewMode ? 0.5 : 1,
                        cursor: isViewMode ? "not-allowed" : "pointer",
                    }}
                >
                    <Tooltip
                        title={
                            !isLiked && !isDisliked
                                ? "Please like or dislike candidate to give feedback"
                                : "Give feedback"
                        }
                    >
                        <button
                            onClick={() => {
                                setFeedbackOpen(true);
                                setHovered(false);
                            }}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            style={{
                                marginTop: "5px",
                                display: "flex",
                                alignItems: "center",
                                minWidth: isLiked || isDisliked ? "160px" : "0px",
                                color: "#0891B2",
                                border: isLiked || isDisliked ? "1px solid #0891b2" : "none",
                                textTransform: "none",
                                padding: "4px 12px",
                                transition: "all 0.4s ease",
                                width: isLiked || isDisliked ? "fit-content" : "0px",
                                overflow: "hidden",
                                justifyContent: "flex-start",
                                fontSize: "22px",
                                height: "35px",
                                opacity: isLiked || isDisliked ? 1 : 0,
                                visibility: isLiked || isDisliked ? "visible" : "hidden",
                                borderRadius: "4px",
                                pointerEvents: isViewMode ? "none" : "auto",
                            }}
                        >
                            <EditNoteIcon
                                style={{
                                    transition: "margin-right 0.4s ease",
                                    fontSize: "28px",
                                    marginRight: isLiked || isDisliked ? "8px" : "0",
                                }}
                            />
                            <p className="ml-2 text-sm whitespace-nowrap italic">Add feedback</p>
                        </button>
                    </Tooltip>

                    <Box
                        onClick={() => handleLike(isLiked ? "PENDING" : "ACCEPTED")}
                        sx={{
                            borderRadius: "50%",
                            p: 1,
                            transition: "all 0.2s ease-in-out",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                transform: "scale(1.1)",
                            },
                            pointerEvents: isViewMode ? "none" : "auto",
                        }}
                    >
                        <ThumbUpIcon sx={{ fontSize: "x-large", color: isLiked ? "#03B043" : "#828283" }} />
                    </Box>

                    <Box
                        onClick={() => handleDislike(isDisliked ? "PENDING" : "REJECTED")}
                        sx={{
                            borderRadius: "50%",
                            p: 1,
                            transition: "all 0.2s ease-in-out",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                transform: "scale(1.1)",
                            },
                            pointerEvents: isViewMode ? "none" : "auto",
                        }}
                    >
                        <ThumbDownIcon sx={{ fontSize: "x-large", color: isDisliked ? "#e20027" : "#828283" }} />
                    </Box>
                </Stack>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2, mb: 2 }}>
                <Avatar
                    src={candidate?.candidateDetails?.[0]?.profileImage}
                    alt={candidate?.candidateDetails?.[0]?.name}
                    className={style["spcard__card-avatar"]}
                    style={{ backgroundColor: "#8cc0e3" }}
                    fallback={<UserAvatar />}
                />
                <Box sx={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <div>
                        <p className="font-bold text-[16px]"> {candidate?.candidateDetails?.[0]?.name}</p>
                        <p className="font-semibold text-[14px]">
                            <span className="font-semibold">Location:</span>{" "}
                            <span className="font-normal text-gray-700">
                                {candidate?.candidateDetails?.[0]?.location}
                            </span>
                        </p>

                        <div className="flex items-center gap-2">
                            {candidate?.candidateDetails?.[0]?.profileUrl && (
                                <button
                                    onClick={() => window.open(candidate?.candidateDetails?.[0]?.profileUrl, "_blank")}
                                >
                                    <LinkedInIcon style={{ color: "#0c66c2" }} />
                                </button>
                            )}
                        </div>
                    </div>

                    <Stack direction="row" alignItems="center">
                        <p className="font-semibold text-[#6C6C6C] mr-3">Candidate Rating: </p>
                        <Rating
                            className="cursor-pointer pointer-events-none"
                            value={candidate?.candidateDetails?.[0]?.starRating?.value || 0}
                            precision={0.5}
                        />
                    </Stack>
                </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Card variant="outlined">
                    {candidate?.candidateDetails?.[0]?.starRating.description?.length ? (
                        <>
                            <p className="text-lg font-semibold text-gray-800 p-1"> AI Summary</p>
                            <hr></hr>
                            <List>
                                {candidate?.candidateDetails?.[0]?.starRating.description.map(
                                    ({ text, intent, criterion, tag }, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                secondaryAction={
                                                    intent ? (
                                                        <Tooltip title="Good to have">
                                                            <CriteriaTag variant={tag}>
                                                                <DoneRoundedIcon htmlColor="#44B700" />
                                                            </CriteriaTag>
                                                        </Tooltip>
                                                    ) : (
                                                        <CriteriaTag variant={tag}>
                                                            <WarningRoundedIcon htmlColor="#ED6C02" />
                                                        </CriteriaTag>
                                                    )
                                                }
                                            >
                                                {!criterion ? (
                                                    <ListItemText
                                                        sx={{
                                                            "& .MuiTypography-root": {
                                                                fontSize: 14,
                                                            },
                                                        }}
                                                        primary={`• ${text}`}
                                                    />
                                                ) : (
                                                    <Tooltip title={`Criterion: ${criterion}`} arrow>
                                                        <ListItemText
                                                            sx={{
                                                                "& .MuiTypography-root": {
                                                                    fontSize: 14,
                                                                },
                                                            }}
                                                            primary={`• ${text}`}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </ListItem>
                                        );
                                    }
                                )}
                            </List>
                        </>
                    ) : null}
                </Card>
            </Box>

            <Box sx={{ mt: 2 }}>
                <ProfileTab data={candidate?.candidateDetails?.[0]} />
            </Box>
            <FeedbackModal
                onClose={() => setFeedbackOpen(false)}
                open={feedbackOpen}
                status={status}
                candidate={candidate}
            />
        </div>
    );
}

export default CandidateDetails;

// messages={candidate?.emailConversationData}
// chats={candidate?.linkedinConnectionData}
