// DemoPopupContext.tsx
import React, { useEffect, createContext, useContext, useState, Suspense, ReactNode, lazy } from "react";
import { selectUser, updateBetaVersion, authLogin } from "@/store/reducers/signin/Signin.reducer";
import { useSelector, useDispatch } from "react-redux";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";

// Lazy load the DemoPopup for proper Suspense handling
const DemoPopup = lazy(() => import("./showPopup"));

interface DemoPopupContextType {
    isDemoPopupOpen: boolean;
    closeDemoPopup: () => void;
    onClose: () => void;
}

const DemoPopupContext = createContext<DemoPopupContextType>({
    isDemoPopupOpen: false,
    closeDemoPopup: () => {},
    onClose: () => {},
});

export const DemoPopupProvider = ({ children }: { children: ReactNode }) => {
    const [isDemoPopupOpen, setIsDemoPopupOpen] = useState(false);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const checkAuthLoginLoading = useSelector(checkIfLoading(authLogin.type));

    useEffect(() => {
        if (checkAuthLoginLoading || user?.announcementFlag?.betaVersionAccess || user?.announcementFlag?.count <= 0) {
            return;
        }
        const sessionFlag = sessionStorage.getItem("demoPopupShown");
        if (sessionFlag === "true") {
            return;
        }
        setIsDemoPopupOpen(true);
        sessionStorage.setItem("demoPopupShown", "true");
    }, [user]);

    const closeDemoPopup = () => {
        if (user?.announcementFlag?.count > 0) {
            const payload = {
                count: user.announcementFlag.count ? user.announcementFlag.count - 1 : 0,
                betaVersionAccess: false,
                flagType: "hiring_manager",
            };
            dispatch(updateBetaVersion({ ...payload, action: updateBetaVersion.type }));
        }
        setIsDemoPopupOpen(false);
    };

    const onClose = () => {
        setIsDemoPopupOpen(false);
    };

    return (
        <DemoPopupContext.Provider value={{ isDemoPopupOpen, closeDemoPopup, onClose }}>
            {children}
            <Suspense fallback={<div>Loading...</div>}>{isDemoPopupOpen && <DemoPopup />}</Suspense>
        </DemoPopupContext.Provider>
    );
};

export const useDemoPopup = () => useContext(DemoPopupContext);
