import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";

import MessageInfoDropdown from "./MessageInfoDropdown";

import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import { EUser } from "../../../../store/apis/super-inbox/super-inbox.enums";
import { TUser } from "../../../../store/apis/super-inbox/super-inbox.types";
import styles from "../../superinbox.module.scss";
import { formatMessageDate } from "../../utils";
import { ChannelProps } from "../channels/ChannelTabs";

interface ChatBubbleProps {
    variant: "sent" | "received";
    body: string;
    time: string;
    senderType: TUser;
    candidateStatus: string;
    isMessageFailed: boolean;
    isQueued: boolean;
    candidateProfile: ChannelProps["candidateProfile"];
    theme: "light" | "dark";
    fullWidth?: boolean;
    from?: string;
    to?: string;
}

const themeConfig: { [key in ChatBubbleProps["theme"]]: { bgColor: string; textColor: string; border: string } } = {
    light: {
        bgColor: "background.body",
        border: "2px solid #C2E7FF",
        textColor: "var(--joy-palette-text-primary)",
    },
    dark: {
        border: "none",
        bgColor: "var(--joy-palette-primary-solidBg)",
        textColor: "var(--joy-palette-common-white)",
    },
};

export default function ChatBubble(props: ChatBubbleProps) {
    const {
        body,
        time,
        senderType,
        candidateStatus,
        isMessageFailed,
        isQueued,
        candidateProfile,
        theme,
        fullWidth,
        from,
        to,
    } = props;

    const isYou = senderType === EUser.USER;

    return (
        <Box sx={{ maxWidth: { xs: "100%", md: fullWidth ? "100%" : "60%" }, minWidth: fullWidth ? "100%" : "auto" }}>
            <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mb: 0.25 }}>
                <Box display="flex" alignItems="center">
                    <HoverTruncateText maxWidth={250}>
                        {isYou ? "You" : from ?? candidateProfile?.name}
                    </HoverTruncateText>
                    {(from || to) && (
                        <MessageInfoDropdown
                            options={[
                                ...(from ? [{ label: "from", value: from }] : []),
                                ...(to ? [{ label: "to", value: to }] : []),
                            ]}
                        />
                    )}
                </Box>
                <Typography level="body-xs" alignSelf="center">
                    {isQueued && `${candidateStatus} • `}
                    {formatMessageDate(time, true)}
                </Typography>
            </Stack>

            <Box sx={{ position: "relative" }}>
                <Sheet
                    color={isYou ? "primary" : "neutral"}
                    variant={isYou ? "solid" : "soft"}
                    sx={{
                        p: 1.25,
                        borderRadius: "lg",
                        borderTopRightRadius: isYou ? 0 : "lg",
                        borderTopLeftRadius: isYou ? "lg" : 0,
                        backgroundColor: isYou ? themeConfig[theme].bgColor : "background.body",
                        border: isYou ? themeConfig[theme].border : "none",
                    }}
                >
                    <Box
                        className={styles["chartBubble"]}
                        sx={{
                            color: isYou ? themeConfig[theme].textColor : "var(--joy-palette-text-primary)",
                            fontSize: 14,
                            wordBreak: "break-word",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: body === null ? "<i>(Document received)</i>" : body?.replaceAll("\n", "<br>"),
                        }}
                    />
                </Sheet>
                {isMessageFailed && (
                    <Stack
                        direction="row"
                        justifyContent={isYou ? "flex-end" : "flex-start"}
                        spacing={0.5}
                        sx={{
                            position: "absolute",
                            top: "50%",
                            p: 1.5,
                            ...(isYou
                                ? {
                                      left: 0,
                                      transform: "translate(-100%, -50%)",
                                  }
                                : {
                                      right: 0,
                                      transform: "translate(100%, -50%)",
                                  }),
                        }}
                    >
                        <Tooltip variant="soft" color="danger" title="Message failed to deliver">
                            <IconButton variant="plain" color="danger" size="sm">
                                <ErrorRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}
            </Box>
        </Box>
    );
}
