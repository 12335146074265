import { Navigate } from "react-router-dom";

import { useFetchOutreachByIdQuery } from "../../../store/apis/outreach/outreach.api";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

export function ValidateOutreachTemplateId({ children }: { children: React.ReactNode }) {
    const { outreachId, currentOutreachElementId, candidateId, projectId } = useOutreachParams();
    const { data: { workflowSteps = [] } = {} } = useFetchOutreachByIdQuery(outreachId);

    if (!workflowSteps.find((i) => i._id === currentOutreachElementId)) {
        return <Navigate to={`/outreach/${projectId}/${outreachId}/${candidateId}/${workflowSteps[0]._id}/base/`} />;
    }

    return <>{children}</>;
}
