import { useEffect, useState } from "react";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { LeadRatingModalData } from "../../../easy-growth/components/LeadRating/LeadRating";
import useMenu from "../../../hooks/useMenu";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectCurrProject, stopGenerateInsights } from "../../allProjects/index.reducer";
import { checkEasySource } from "../../Signin/Signin.reducer";
import { refreshCandidateRating, selectProjectCandidates } from "../index.reducer";
import VettingCriteriaModal from "./vettingCriteria/VettingCriteria";

type GenerateInsightsProps = {
    projectId: string;
    candidateIds: string[];
    selectAll: boolean;
};

export default function GenerateInsights({ projectId, candidateIds, selectAll }: GenerateInsightsProps) {
    const { t } = useTranslation();
    const isEasySource = useSelector(checkEasySource);
    const dispatch = useDispatch();
    const { anchorEl, handleClick, handleClose, menuSX, open } = useMenu();
    const isGeneratingInsights: boolean = useSelector(checkIfLoading(refreshCandidateRating.type));
    const [openModal, setOpenModal] = useState(false);
    const [openGenerateInsightModal, setOpenGenerateInsightModal] = useState(false);
    const projectData = useSelector(selectCurrProject);
    const candidates = useSelector(selectProjectCandidates);
    const candidateIdsdata = candidates.map((item) => item._id);
    const [allCandidates, setAllCandidates] = useState(false);
    const isInsightsInProgress = projectData?.generateInsightsStatus === "IN_PROGRESS";
    const isStopGenerateInsightsInProgress = useSelector(checkIfLoading(stopGenerateInsights.type));

    const showModal = () => {
        setOpenModal(true);
        handleClose();
    };
    const closeModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        if (candidateIds?.length === 0) {
            setAllCandidates(true);
        } else {
            setAllCandidates(false);
        }
    }, [candidateIds]);
    const closeGenerativeModal = () => {
        setOpenGenerateInsightModal(false);
    };
    const openGenerativeModal = () => {
        setOpenGenerateInsightModal(true);
        handleClose();
    };
    interface ValueItem {
        label: string;
        onClick: () => void;
        disabled?: boolean;
        tooltip?: string;
    }

    const values: ValueItem[] = [
        { label: t("projectsPage.editCriteria"), onClick: showModal, disabled: false },
        {
            label: t("projectsPage.aiScreening"),
            onClick: openGenerativeModal,
            disabled: !Boolean(candidateIds.length),
            tooltip: `Generate insights on all ${isEasySource ? "candidates" : "prospects"} in this project`,
        },
    ];

    const handleGenerateInsights = async (type?: boolean) => {
        if (isInsightsInProgress) {
            dispatch(stopGenerateInsights({ projectId, closeModal: () => { } }));
        }

        const checkAndRefresh = () => {
            if (!isStopGenerateInsightsInProgress) {
                dispatch(
                    refreshCandidateRating({
                        action: refreshCandidateRating.type,
                        projectId,
                        candidateIds: allCandidates ? candidateIdsdata : candidateIds,
                        selectAll: allCandidates ? true : selectAll,
                        onSuccess: type ? closeGenerativeModal : closeModal,
                        allCandidates: allCandidates ? true : false,
                    })
                );
            }
        };
        setTimeout(checkAndRefresh, 0);
    };

    return (
        <>
            <Tooltip title="Generate Insights" placement="top">
                <span style={{ cursor: "not-allowed" }}>
                    <IconButton color="primary" onClick={handleClick}>
                        <AutoAwesomeRoundedIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                sx={{
                    ...menuSX,
                    "& .MuiPaper-root": {
                        ...menuSX["& .MuiPaper-root"],
                        top: "190px !important",
                    },
                }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                {values.map((value, index) => (
                    <Tooltip key={index} title={value.disabled ? value.tooltip : ""} placement="right">
                        <div>
                            <MenuItem key={index} onClick={value.onClick}>
                                {value.label}
                            </MenuItem>
                        </div>
                    </Tooltip>
                ))}
            </Menu>
            {openModal &&
                (isEasySource ? (
                    <VettingCriteriaModal
                        close={closeModal}
                        variant="GENERATE"
                        handleGenerateInsights={handleGenerateInsights}
                        checkIds={Boolean(candidateIds.length)}
                    />
                ) : (
                    <>
                        <LeadRatingModalData onClose={closeModal} />
                    </>
                ))}
            <Dialog
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: 0,
                    },
                }}
                open={openGenerateInsightModal}
                onClose={closeGenerativeModal}
            >
                <DialogTitle fontSize={16} fontWeight={500}>
                    Are you sure you want to generate insights?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={closeGenerativeModal}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={() => handleGenerateInsights(true)}
                        disabled={isGeneratingInsights}
                    >
                        <ButtonTextWithLoading isLoading={isGeneratingInsights} text="Submit" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
