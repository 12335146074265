import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import IconButton from "@mui/material/IconButton";
import { Experimental_CssVarsProvider } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";

import useCopyToClipboard from "../hooks/useCopyToClipboard.hook";

interface CopyableTextProps extends TypographyProps {
    message?: string;
    textToCopy?: string;
}

export default function CopyableText({ children, message, textToCopy, ...rest }: CopyableTextProps) {
    const [copiedText, copyToClipboard] = useCopyToClipboard(!message ? { onSuccessfulCopy() { } } : undefined);

    if (typeof children !== "string" && !textToCopy) {
        throw Error("Provided children is not of type string please provide textToCopy!");
    }

    const handleCopy = () => {
        const copyableText = textToCopy ?? children;
        copyToClipboard(copyableText as string, message);
    };

    return (
        <Experimental_CssVarsProvider>
            <Typography width="fit-content" display="flex" alignItems="center" {...rest}>
                {children}
                <Tooltip title={copiedText ? "Copied" : "Click to copy"}>
                    <IconButton sx={{ padding: 0.5 }} onClick={handleCopy}>
                        <ContentCopyRoundedIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                </Tooltip>
            </Typography>
        </Experimental_CssVarsProvider>
    );
}
// suraj test cypress