import { EmailContents } from "../pages/triggerWorkflow/types/customTemplate.types";

type AppendToOutReachArgs = {
    outReachIntent: string;
    email: EmailContents<string | null>;
    linkedInReq?: string;
    inMail?: EmailContents<string | null>;
    linkedin?: {
        linkedinBody: string;
        inMailSubject: string;
        inMailBody: string;
        inMailFollowUpSubject: string;
        inMailFollowUpBody: string;
    };
    followUp: string;
    sms: string | null;
};

export default function appendToOutReach({
    email,
    followUp,
    inMail,
    linkedInReq,
    outReachIntent,
    linkedin,
    sms,
}: AppendToOutReachArgs) {
    return `
        ${outReachIntent} 

        Email Template: 
        Subject: ${email.subject}
        Body: ${email.body}

        Linkedin Request:
        Body: ${linkedInReq ? linkedInReq : linkedin?.linkedinBody}

        Follow Up:
        Body: ${followUp}

        In Mail:
        Subject: ${inMail ? inMail.subject : linkedin?.inMailSubject}
        Body: ${inMail ? inMail.body : linkedin?.inMailBody}
        ${
            sms
                ? `
            SMS: 
            Body: ${sms}
            `
                : ""
        }
    `;
}
