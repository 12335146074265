import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";

import { getProjectChipColor } from "../utils";

export interface TagsProps {
    projects: { _id: number; name: string; dashboardStatus: string }[];
    tags: string[];
}

export default function Tags(props: TagsProps) {
    return (
        <Stack direction="row" gap={1} display="flex" alignItems="center" height={40}>
            {props.tags.map((tag) => (
                <Chip key={tag} color="primary" size="sm">
                    {tag}
                </Chip>
            ))}
            {props.projects.map((project) => (
                <Chip key={project._id} color={getProjectChipColor(project.dashboardStatus as any)} size="sm">
                    {project.name}
                </Chip>
            ))}
        </Stack>
    );
}
