import List from "@mui/joy/List";
import { Experimental_CssVarsProvider } from "@mui/material";
import { useSelector } from "react-redux";

import ChatListItem from "./ChatListItem";
import ChatsLoader from "./ChatsLoader";
import ChatsPaneEmptyState from "./ChatsPaneEmptyState";

import { ErrorPlaceholder } from "../../../../components/ErrorPlaceholder";
import { checkSMSEnabled } from "../../../Signin/Signin.reducer";
import { useSuperInboxContext } from "../../Context";
import { MobilePagination } from "../Pagination";

export default function ChatsPaneContent() {
    const isSmsEnabled = useSelector(checkSMSEnabled);

    const {
        isError,
        isLoadingCandidates,
        filters: { applyFilters, channels },
        candidates,
    } = useSuperInboxContext();

    if (isError) {
        return (
            <Experimental_CssVarsProvider>
                <ErrorPlaceholder
                    containerProps={{ justifyContent: "center", p: 2 }}
                    typographyProps={{ fontSize: 12 }}
                    reload={applyFilters}
                />
            </Experimental_CssVarsProvider>
        );
    }

    if (isLoadingCandidates) {
        return <ChatsLoader />;
    }

    if (!channels.email && !channels.linkedin && channels.sms && !isSmsEnabled) {
        return (
            <ChatsPaneEmptyState>
                You do not have EasySource SMS capabilities integrated yet. Please contact your account manager if you
                wish to activate this feature
            </ChatsPaneEmptyState>
        );
    }

    return (
        <List sx={{ py: 0, "--ListItem-paddingY": "0.75rem", "--ListItem-paddingX": "1rem" }}>
            {/* -115px for header height */}
            <MobilePagination height="calc(100dvh - 115px)">
                {candidates.map((candidate, index) => (
                    <ChatListItem key={`${candidate.candidateId}-${index}`} {...candidate} />
                ))}
            </MobilePagination>
        </List>
    );
}
