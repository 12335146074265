import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
    Badge,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    Popover,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import millify from "millify";
import { useEffect, useState } from "react";

import { ExclusionSelector } from "./ExclusionSelector";

import { setWarningNotification } from "../../../components/Notification/index.reducer";
import useSearchState from "../../../hooks/useSearchState";
import { checkEasySource, selectUser } from "../../../pages/Signin/Signin.reducer";
import BaseModal from "../../../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import {
    addCustomCompaniesToAccountList,
    selectAccountListsSearch,
    setValues,
} from "../../../store/reducers/search/search.slice";
import { fetchAccountLists, selectAccountLists } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { useSelector } from "react-redux";

function AccountInfo({ onClose = () => {} }: { onClose: () => void }) {
    const user = useSelector(selectUser);
    const isFetchingAccountLists = useSelector(checkIfLoading(fetchAccountLists.type));
    const accountLists = useSelector(selectAccountLists);
    const { values } = useSelector(selectAccountListsSearch);
    const { dispatch } = useSearchState("accountLists");

    const [selectedAccounts, setSelectedAccounts] = useState<{ value: string; excluded: boolean }[]>(values);
    const selectedAccountsListIds = new Set(selectedAccounts.map((val) => val.value));

    const handleChange = (id: string) => {
        let value = [];

        if (selectedAccountsListIds.has(id)) {
            value = selectedAccounts.filter((val) => val.value !== id);
        } else {
            value = [...selectedAccounts, { value: id, excluded: false }];
        }

        setSelectedAccounts(value);
    };

    const handleStatusChange = (id: string, newStatus: string) => {
        const updatedAccounts = selectedAccounts.map((account) =>
            account.value === id ? { ...account, excluded: newStatus === "EXCLUDE" } : account
        );
        setSelectedAccounts(updatedAccounts);
    };

    const handleSubmit = () => {
        dispatch(
            setValues({
                key: "accountLists",
                value: selectedAccounts,
            })
        );
        onClose();
    };

    const handleClear = () => {
        setSelectedAccounts([]);
        dispatch(
            setValues({
                key: "accountLists",
                value: [],
            })
        );
    };

    if (isFetchingAccountLists && accountLists?.length === 0) {
        return (
            <Stack p={4} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress size={30} />
            </Stack>
        );
    }

    if (!accountLists?.length) {
        return (
            <Typography variant="caption" p={2}>
                No account lists found!
            </Typography>
        );
    }
    return (
        <div>
            <div style={{ maxHeight: "75vh", overflowY: "auto" }}>
                {accountLists.map((accountList) => {
                    const {
                        headquartersGeography = [], // [{labe, value}]
                        minRevenue,
                        maxRevenue,
                        industryCompany = [], // [{labe, value}]
                        minDepartmentHeadCount,
                        maxDepartmentHeadCount,
                        minDepartmentHeadCountGrowth,
                        maxDepartmentHeadCountGrowth,
                        companyHeadcount = [], // [String]
                        currency,
                        departmentCount,
                        departmentGrowth,
                        fortune = [], // [String]
                        jobOpportunity,
                        mincompanyHeadGrowth,
                        maxcompanyHeadGrowth,
                        noOfFollowers = [],
                        technologiesUsed = [], // [{labe, value}]
                    } = accountList?.salesnavcompany?.filters ?? {};

                    return (
                        <Box key={accountList._id}>
                            <Accordion sx={{ boxShadow: "none", minWidth: 550 }}>
                                <AccordionSummary
                                    expandIcon={!(accountList?.userCompaniesCount > 0) ? <ExpandMore /> : null}
                                    aria-controls="panel1bh-content"
                                    sx={{
                                        "&.MuiAccordionSummary-root": {},
                                    }}
                                >
                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                        <Box display="flex" alignItems="center">
                                            <Checkbox
                                                checked={selectedAccountsListIds.has(accountList?._id)}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={() => handleChange(accountList._id)}
                                            />

                                            <ExclusionSelector
                                                status={
                                                    selectedAccounts.find(
                                                        (account) => account.value === accountList._id
                                                    )?.excluded
                                                        ? "EXCLUDE"
                                                        : "INCLUDE"
                                                }
                                                menuClickHandler={(e: any) => handleStatusChange(accountList._id, e)}
                                            />
                                            <Typography fontSize={16} fontWeight={500} sx={{ marginLeft: "7px" }}>
                                                {accountList?.name}
                                            </Typography>
                                        </Box>

                                        <Typography fontSize={14} color="text.secondary" mr={2}>
                                            {accountList?.userCompaniesCount > 0
                                                ? `${accountList?.userCompaniesCount} companies`
                                                : `${millify(accountList?.salesnavcompany?.universeCount)} companies`}
                                            <Chip
                                                label={
                                                    accountList?.userCompaniesCount > 0
                                                        ? `by ${user?.name?.split(" ")[0]}`
                                                        : "AI scraping"
                                                }
                                                size="small"
                                                sx={{ marginLeft: 1 }}
                                            />
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                {!(accountList?.userCompaniesCount > 0) && (
                                    <AccordionDetails>
                                        <Stack direction="row" gridTemplateColumns={"repeat(2, 1fr)"} spacing={2}>
                                            <Stack spacing={1} width={"100%"}>
                                                {headquartersGeography.length > 0 && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Geography</b>:{" "}
                                                        {headquartersGeography.map((g) => g.label).join(", ")}
                                                    </Typography>
                                                )}
                                                {minRevenue && maxRevenue && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Revenue</b>: ${minRevenue} Mn - ${maxRevenue} Mn
                                                    </Typography>
                                                )}
                                                {industryCompany.length > 0 && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Industry</b>:{" "}
                                                        {industryCompany.map((g) => g.label).join(", ")}
                                                    </Typography>
                                                )}
                                                {jobOpportunity?.trim() === "Hiring on Linkedin" && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Signals</b>: Hiring on LinkedIn (YES)
                                                    </Typography>
                                                )}
                                                {jobOpportunity?.trim() ===
                                                    "Senior leadership changes in past 3 months" && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Signals</b>: Senior leadership changes in past 3 months (YES)
                                                    </Typography>
                                                )}
                                                {jobOpportunity?.trim() === "Funding events in past 12 months" && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Signals</b>: Funding events in past 12 months (YES)
                                                    </Typography>
                                                )}
                                            </Stack>
                                            <Stack spacing={1} width={"100%"}>
                                                {minDepartmentHeadCount && maxDepartmentHeadCount && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Dept headcount</b>: {minDepartmentHeadCount}-
                                                        {maxDepartmentHeadCount} ({departmentCount})
                                                    </Typography>
                                                )}
                                                {minDepartmentHeadCountGrowth &&
                                                    maxDepartmentHeadCountGrowth &&
                                                    departmentGrowth && (
                                                        <Typography sx={{ fontSize: 12 }}>
                                                            <b>Dept headcount growth</b>: {minDepartmentHeadCountGrowth}
                                                            % - {maxDepartmentHeadCountGrowth}% ({departmentGrowth})
                                                        </Typography>
                                                    )}
                                                {companyHeadcount.length > 0 && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Company headcount</b>: {companyHeadcount.join(", ")}
                                                    </Typography>
                                                )}
                                                {technologiesUsed.length > 0 && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Technology used</b>:{" "}
                                                        {technologiesUsed.map((t) => t.label).join(", ")}
                                                    </Typography>
                                                )}
                                                {noOfFollowers.length > 0 && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b># followers</b>: {noOfFollowers.join(", ")}
                                                    </Typography>
                                                )}
                                                {fortune.length > 0 && (
                                                    <Typography sx={{ fontSize: 12 }}>
                                                        <b>Fortune</b>:{" "}
                                                        {fortune.map((f) => f.replace("Fortune", "")).join(", ")}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Stack>
                                    </AccordionDetails>
                                )}
                            </Accordion>
                            <Divider variant="middle" />
                        </Box>
                    );
                })}
            </div>
            <div>
                <Button
                    variant="contained"
                    sx={{ mx: 2, mt: 2 }}
                    disabled={selectedAccountsListIds.size === 0}
                    onClick={handleSubmit}
                >
                    Add to filters
                </Button>
                <Button sx={{ mt: 2 }} onClick={handleClear} variant="outlined">
                    Clear
                </Button>
            </div>
        </div>
    );
}

export function AccountLists() {
    const { dispatch } = useSearchState("accountLists");
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [openAccountListModal, setOpenAccountListModal] = useState(false);
    const { values: selectedAccounts } = useSelector(selectAccountListsSearch);
    const [accountListName, setAccountListName] = useState("");
    const isEasySource = useSelector(checkEasySource);
    useEffect(() => {
        dispatch(fetchAccountLists());
    }, []);

    const handleOpen = (event: any) => {
        dispatch(fetchAccountLists());
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isAddingCustomCompaniesToAccountList = useSelector(checkIfLoading(addCustomCompaniesToAccountList.type));
    const handleSubmitAccountList = () => {
        if (!accountListName) {
            return dispatch(setWarningNotification("Please enter account list name"));
        }
        dispatch(
            addCustomCompaniesToAccountList({
                name: accountListName,
                successCallback() {
                    setOpenAccountListModal(false);
                },
            })
        );
    };

    const open = Boolean(anchorEl);
    const idPopover = open ? "simple-popover" : undefined;

    return (
        <>
            <Stack spacing={1} direction="row" alignItems="center" marginTop={2}>
                <Tooltip title="Save selected and uploaded company list">
                    <Button
                        variant="contained"
                        sx={{ width: "10px", borderRadius: "5px", paddingTop: "2px", paddingBottom: "2px" }}
                        onClick={() => {
                            setOpenAccountListModal(true);
                        }}
                    >
                        {" "}
                        Save
                    </Button>
                </Tooltip>
                <Button
                    onClick={handleOpen}
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{
                        justifyContent: "space-between",
                        textTransform: "none",
                        borderRadius: isEasySource ? "5px" : null,
                        marginBottom: isEasySource ? "10px" : null,
                    }}
                >
                    Saved account lists
                    <Badge
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        badgeContent={selectedAccounts.length}
                        color="primary"
                    >
                        <ArrowRightIcon />
                    </Badge>
                </Button>
                <Popover
                    id={idPopover}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        "& .MuiPaper-root": {
                            borderRadius: "18px",
                            maxWidth: 550,
                        },
                        overflowY: "auto",
                    }}
                >
                    <Box py={2}>
                        <Typography px={3} variant="h6">
                            Account lists
                        </Typography>
                        <AccountInfo onClose={handleClose} />
                    </Box>
                </Popover>
            </Stack>

            {openAccountListModal && (
                <BaseModal
                    overlayStyles={{
                        padding: "1.7rem 1.5rem",
                        width: 600,
                    }}
                    hideCloseButton
                    onClose={() => setOpenAccountListModal(false)}
                >
                    <Typography sx={{ fontSize: "25px", fontWeight: "600", marginBottom: "10px" }}>
                        Sure want to save as account list?
                    </Typography>

                    <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        This list of uploaded and selected companies will be available in the account list section for
                        future use.
                    </Typography>
                    <TextField
                        required
                        size="small"
                        label="Account list name"
                        onChange={(e) => setAccountListName(e.target.value)}
                    />
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={4}>
                        <Button
                            variant="outlined"
                            sx={{ borderRadius: "4px", fontSize: "12px" }}
                            onClick={() => setOpenAccountListModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ borderRadius: "4px", fontSize: "12px", border: "none" }}
                            onClick={handleSubmitAccountList}
                            disabled={isAddingCustomCompaniesToAccountList || !accountListName}
                        >
                            Save
                        </Button>
                    </Stack>
                </BaseModal>
            )}
        </>
    );
}
