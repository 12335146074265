import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";

import { RootState } from "../../Store";
import { TScrapeSource } from "./linkedin-scraping.types";

type InitialState = {
    linkedinUrl: string;
    linkedinCookie: string;
    fromPage: number;
    profileCount: number;
    scrapeSource: TScrapeSource;
    projectId: number;
    openLinkedinScrapingModal: boolean;
    addSalesNavUrlProfiles: boolean;
};

const initialState: InitialState = {
    linkedinUrl: "",
    linkedinCookie: "",
    fromPage: 1,
    profileCount: 10,
    scrapeSource: "linkedin",
    projectId: 0,
    openLinkedinScrapingModal: false,
    addSalesNavUrlProfiles: false,
};

export const linkedinScrapingSlice = createSlice({
    name: "linkedinScraping",
    initialState,
    reducers: {
        setLinkedinUrl(state, action: PayloadAction<string>) {
            state.linkedinUrl = action.payload;
        },
        setLinkedinCookie(state, action: PayloadAction<string>) {
            state.linkedinCookie = action.payload;
        },
        setFromPage(state, action: PayloadAction<number>) {
            state.fromPage = action.payload;
        },
        setProfileCount(state, action: PayloadAction<number>) {
            state.profileCount = action.payload;
        },
        setScrapeSource(state, action: PayloadAction<TScrapeSource>) {
            state.scrapeSource = action.payload;
        },
        setProjectId(state, action: PayloadAction<number>) {
            state.projectId = action.payload;
        },
        setLinkedinScrapingState(
            state,
            action: PayloadAction<{ linkedinUrl: string; fromPage: number; profileCount: number }>
        ) {
            const { linkedinUrl, fromPage, profileCount } = action.payload;
            if (linkedinUrl) state.linkedinUrl = linkedinUrl;
            if (fromPage) state.fromPage = fromPage;
            if (profileCount) state.profileCount = profileCount;
        },
        openLinkedinScrapingModal(state) {
            state.openLinkedinScrapingModal = true;
        },
        closeLinkedinScrapingModal(state) {
            state.openLinkedinScrapingModal = false;
        },
        enableAddSalesNavUrlProfiles(state) {
            state.addSalesNavUrlProfiles = true;
        },
        disableAddSalesNavUrlProfiles(state) {
            state.addSalesNavUrlProfiles = false;
        },
        resetLinkedinScrapingState(state) {
            return initialState;
        },
        submitLinkedinScrapingForm(state, action: PayloadAction<{ navigate: NavigateFunction }>) {},
        cancelSagas() {},
    },
});

export const {
    setLinkedinUrl,
    setLinkedinCookie,
    setFromPage,
    setProfileCount,
    setScrapeSource,
    setProjectId,
    openLinkedinScrapingModal,
    closeLinkedinScrapingModal,
    setLinkedinScrapingState,
    resetLinkedinScrapingState,
    submitLinkedinScrapingForm,
    disableAddSalesNavUrlProfiles,
    enableAddSalesNavUrlProfiles,
    cancelSagas,
} = linkedinScrapingSlice.actions;

export const selectLinkedinUrl = (state: RootState) => state.linkedinScraping.linkedinUrl;

export const selectLinkedinCookie = (state: RootState) => state.linkedinScraping.linkedinCookie;

export const selectFromPage = (state: RootState) => state.linkedinScraping.fromPage;

export const selectProfileCount = (state: RootState) => state.linkedinScraping.profileCount;

export const selectScrapeSource = (state: RootState) => state.linkedinScraping.scrapeSource;

export const selectProjectId = (state: RootState) => state.linkedinScraping.projectId;

export const checkLinkedinScrapeModalOpen = (state: RootState) => state.linkedinScraping.openLinkedinScrapingModal;

export const checkAddSalesNavUrlProfilesEnabled = (state: RootState) => state.linkedinScraping.addSalesNavUrlProfiles;

export default linkedinScrapingSlice.reducer;
