import dayjs from "dayjs";

import { TCustomDateLabel } from "../../store/apis/analytics/analytics.types";

export const customDateOptions: { label: TCustomDateLabel; value: any }[] = [
    { label: "Today", value: dayjs() },
    { label: "Last 7 days", value: dayjs().subtract(7, "days") },
    { label: "Last 4 weeks", value: dayjs().subtract(4, "weeks") },
    { label: "Last 3 months", value: dayjs().subtract(3, "months") },
];
