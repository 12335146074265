import { useState } from "react";

import RadioButtonsGroup, { RadioButtonsGroupProps, RadioOptionType } from "../../../atoms/RadioButtonGroup/RadioButtonGroup";

interface RadioButtonsTabProps extends Omit<RadioButtonsGroupProps, "options"> {
    options: Array<RadioOptionType & { component: JSX.Element }>;
}

export function RadioButtonsTab(props: RadioButtonsTabProps) {
    const { options, label, defaultValue, ...rest } = props;
    const [selectedValue, setSelectedValue] = useState(defaultValue || "");

    const renderComponent = () => options.find((option) => option.value === selectedValue)?.component;

    return (
        <>
            <RadioButtonsGroup
                row
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
                label={label}
                options={options}
                {...rest}
            />
            {renderComponent()}
        </>
    );
}
