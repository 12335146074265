import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { getAllProjectsList, selectAllProjects } from "../../../allProjects/index.reducer";
import { IProject } from "../../../project/project.types";
import styles from "./Filters.module.css";
import {
    scrapLinkedin,
    selectSelectedLinkedinUrl,
    setSelectedLinkedinUrl,
    setShowScrapLinkedinPopup,
} from "./Filters.reducer";
import { optionType } from "./Filters.type";

const LinkedinScrape = () => {
    const dispatch = useDispatch();
    const selectedLinkedinUrl: string = useSelector(selectSelectedLinkedinUrl);
    const projectList: IProject[] = useSelector(selectAllProjects);
    const isScrapLinkedinLoading = useSelector(checkIfLoading(scrapLinkedin.type));

    const [platform, setPlatform] = useState("linkedin");
    const [cookie, setCookie] = useState("");
    const [fromPage, setFromPage] = useState(1);
    const [profileCount, setProfileCount] = useState(10);
    const [projects, setProjects] = useState<optionType[]>([]);
    const [selectedProject, setSelectedProject] = useState<optionType>({
        value: "",
        label: "",
    });
    const [error, setError] = useState({
        url: "",
        cookie: "",
        fromPage: "",
        profileCount: "",
        selectedProject: "",
    });

    useEffect(() => {
        dispatch(getAllProjectsList({ action: getAllProjectsList.type }));
    }, []);

    useEffect(() => {
        const projects: optionType[] = projectList.map((item) => {
            return {
                value: item?._id.toString(),
                label: item?.name,
            };
        });

        setProjects(projects);
    }, [projectList]);

    const onProceed = () => {
        const newError: any = {};
        if (!selectedLinkedinUrl) newError.url = "Should be valid link";
        if (!cookie) newError.cookie = "Should be valid cookie";
        if (fromPage < 1) newError.fromPage = "From page should be greater than 0";
        if (profileCount < 1) newError.profileCount = "Profile Count page should be greater than 0";
        if (!selectedProject?.value) newError.selectedProject = "Please selecet any project";
        if (!platform) newError.platform = "Please selecet any platform";
        setError(newError);
        if (isEmpty(newError)) {
            dispatch(
                scrapLinkedin({
                    linkedinUrl: selectedLinkedinUrl,
                    cookie,
                    fromPage,
                    profileCount,
                    platform,
                    projectId: parseInt(selectedProject?.value),
                })
            );
        }
    };

    return (
        <Dialog
            open={true}
            onClose={() => dispatch(setShowScrapLinkedinPopup(false))}
            classes={{
                paper: styles.popupContainer,
            }}
        >
            <DialogTitle>Upload linkedin url</DialogTitle>
            <DialogContent>
                <Stack className={styles.fieldContainer}>
                    <TextField
                        placeholder="Linkedin url"
                        value={selectedLinkedinUrl}
                        fullWidth
                        onChange={(e) => dispatch(setSelectedLinkedinUrl(e.target.value))}
                    />
                    <Typography className={styles.error} variant="body2">
                        {error.url}
                    </Typography>
                </Stack>
                <Stack className={styles.fieldContainer}>
                    <TextField placeholder="Linkedin cookie" fullWidth onChange={(e) => setCookie(e.target.value)} />
                    <Typography className={styles.error} variant="body2">
                        {error.cookie}
                    </Typography>
                </Stack>
                <Stack flexDirection="row">
                    <Stack className={styles.fieldContainer} sx={{ flex: 1 }}>
                        <TextField
                            placeholder="From page"
                            type="number"
                            value={fromPage}
                            onChange={(e) => setFromPage(parseInt(e.target.value))}
                            sx={{ marginRight: "15px" }}
                            label="From page"
                        />
                        <Typography className={styles.error} variant="body2">
                            {error.fromPage}
                        </Typography>
                    </Stack>
                    <Stack className={styles.fieldContainer} sx={{ flex: 1 }}>
                        <TextField
                            placeholder="Profile count"
                            type="number"
                            value={profileCount}
                            onChange={(e) => setProfileCount(parseInt(e.target.value))}
                            label="Profile count"
                        />
                        <Typography className={styles.error} variant="body2">
                            {error.profileCount}
                        </Typography>
                    </Stack>
                </Stack>
                <RadioGroup name="radio-buttons-group" value={platform} row>
                    <FormControlLabel
                        value="linkedin"
                        control={<Radio />}
                        label="Linkedin"
                        onClick={() => setPlatform("linkedin")}
                    />
                    <FormControlLabel
                        value="salesnav"
                        control={<Radio />}
                        label="Salesnav"
                        onClick={() => setPlatform("salesnav")}
                    />
                </RadioGroup>
                <Stack className={styles.fieldContainer}>
                    <FormControl fullWidth>
                        <FormHelperText sx={{ margin: 0 }}>Select project</FormHelperText>
                        <Select
                            autoWidth
                            value={selectedProject.value}
                            classes={{
                                select: styles.addToProjectSelect,
                            }}
                        >
                            {projects.map((item) => (
                                <MenuItem value={item.value} onClick={() => setSelectedProject(item)}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography className={styles.error} variant="body2">
                        {error.selectedProject}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => dispatch(setShowScrapLinkedinPopup(false))}>
                    Close
                </Button>
                <Button variant="contained" onClick={onProceed} disabled={isScrapLinkedinLoading}>
                    {isScrapLinkedinLoading ? "Processing..." : "Proceed"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LinkedinScrape;
