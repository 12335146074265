import { ReactNode, createContext, useContext } from "react";

import useFilters, { defaultFilters } from "./hooks/useFilters";

import { useLazyGetWorkflowListQuery } from "../../store/apis/linkedin-workflow/linkedin-workflow.api";
import { TFiltersStateGlobal } from "../../store/apis/linkedin-workflow/linkedin-workflow.types";

type LinkedinWorkflowContextValue = {
    filters: TFiltersStateGlobal;
};

const defaultLinkedinWorkflowContextValue = {
    filters: defaultFilters,
    isError: false,
};

const LinkedinWorkflowContext = createContext<LinkedinWorkflowContextValue>(defaultLinkedinWorkflowContextValue);

export const useLinkedinWorkflowContext = () => useContext(LinkedinWorkflowContext);

interface LinkedinWorkflowProviderProps {
    children: ReactNode;
}

export default function LinkedinWorkflowContextProvider({ children }: LinkedinWorkflowProviderProps) {
    const useLazyGetWorkflowQuery = useLazyGetWorkflowListQuery;
    const [
        getWorkflowList,
        {
            isLoading: isLoadingCandidates = true,
            isFetching: isFetchingCandidates,
            data: workflowListData,
            isError,
            error,
        },
    ] = useLazyGetWorkflowQuery();
    const filters = useFilters((filters) => getWorkflowList(filters));

    const value: LinkedinWorkflowContextValue = {
        filters,
    };

    return <LinkedinWorkflowContext.Provider value={value}>{children}</LinkedinWorkflowContext.Provider>;
}
