import { PayloadAction } from "@reduxjs/toolkit";
import { MultiValue } from "react-select";

import { Option } from "../../../common";

import { ICandidate, ICandidateEmail, ICopyToProjectPayload, IProject } from "@/store/reducers/project/project.types";

export type ProjectMode = "AUTO_PILOT" | "MANUAL";

export type SetProjectModePayload = PayloadAction<{
    status: boolean;
    project?: string;
}>;

export type TEmailProviderConfig = { type: string; status: boolean }[];

export interface Location {
    label: string;
    value: string;
    _id: string;
}

export interface IGetProjectPayload {
    projectId?: string;
    start?: number;
    limit?: number;
    sse?: boolean;
    doNotShowLoader?: boolean;
    doNotFetchCandidates?: boolean;
    doNotFetchStats?: boolean;
    searchQuery?: string;
    setBlendSearchFilters?: boolean;
    jazzHRMetaData?: {
        jobId: string;
        jobName: string;
    };
    sendRequestId?: boolean;
}

export type IRemoveCandidateFromProjectPayload = ICopyToProjectPayload;

export type IDeleteProjectPayload = PayloadAction<{
    projectIds: number[];
}>;

export interface IEditNotePayload {
    candidateId: string;
    active?: boolean;
    title: string;
    body: string;
    notesId: number;
    onSuccess?: () => void;
}

export interface IRootState {
    projectList: IProject[];
    project: IProject | null;
    notes: INote[];
    activities: IActivity[];
    candidateEmail: ICandidateEmail | null;
    candidate: ICandidate | null;
    projectsFetchError: false;
    totalProjects: number;
    emailProviderConfig: TEmailProviderConfig;
    jazzHRMetaData?: {
        jobId: string;
        jobName: string;
    };
    query: string;
    currentPage: number;
    pageSize: number;
    customFields?: {
        label: string;
        value: string;
    }[];
    rb2bWebhookUrl?: string;
    projectsStatus?: { [key: string]: IProject["projectStatus"] };
    projectStatus?: IProject["projectStatus"];
}

export interface IActivity {
    body: string;
    createdAt: string;
}

export interface INote {
    candidateId: string;
    _id: number;
    body: string;
    createdAt: string;
}

export interface ICreateNotePayload {
    candidateId: string;
    title: string;
    body: string;
}

export interface IUpdateProjectCreditsPayload {
    project: {
        _id: number;
        creditsRewarded: boolean;
    };
    onSuccess?: () => void;
}

export type StopGenerateInsightsPayload = PayloadAction<{
    projectId: string;
    closeModal: () => void;
}>;

export interface ISaveAndRunExclusionList {
    _id: string;
    exclusionLists: MultiValue<Option>;
    onSuccess: () => void;
}

export type EditProjectPayload = {
    _id?: IProject["_id"];
    name?: IProject["name"];
    poolExhausted?: IProject["poolExhausted"];
    dashboardStatus?: IProject["dashboardStatus"];
    vettingStatus?: IProject["vettingStatus"];
    exclusionLists?: Option[];
    onSuccess?: () => void;
    handleGenerateInsights?: () => void;
    action?: string;
    excludeVettingCriteria: boolean;
    scrapDetailedSkills?: IProject["scrapDetailedSkills"];
    scrapDetailedLanguages?: IProject["scrapDetailedLanguages"];
    scrapDetailedEducation?: IProject["scrapDetailedEducation"];
    scrapDetailedCertifications?: IProject["scrapDetailedCertifications"];
    emailProviderConfig?: TEmailProviderConfig;
};

export const emailProviders = [
    {
        type: "APOLLOIO",
        status: true,
    },
    { type: "ZOOMINFO", status: false },
    {
        type: "RB2B",
        status: true,
    },
    {
        type: "ZEROBOUNCE",
        status: false,
    },
    {
        type: "CONTACTS_OUT",
        status: true,
    },
];
