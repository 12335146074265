import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { setSuccessNotification } from "../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser, setUser, updateUser } from "../../Signin/Signin.reducer";

export default function NucleusConnect({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const {
        companyName,
        companyUrl,
        linkedinUrl,
        name,
        outboundEmail,
        userEmail,
        workspaceName,
        role,
        signature,
    }: any = user;
    const [apiKey, setApiKey] = useState("");

    const isLoading = useSelector(checkIfLoading(updateUser.type));

    useEffect(() => {
        if (isEmpty(user)) return;
        setApiKey(user.nucleusApiKey ?? "");
    }, [user]);

    const handleConnect = () => {
        dispatch(
            updateUser({
                name,
                orgName: workspaceName,
                companyName,
                companyLink: companyUrl,
                linkedinUrl,
                authorizeEmailAddress: outboundEmail,
                email: userEmail,
                nucleusApiKey: apiKey,
                onSuccess: onClose,
            })
        );
        dispatch(setUser({ ...user, nucleusApiKey: apiKey }));
        dispatch(setSuccessNotification("Successfully connected to Nucleus ATS"));
    };

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Connect Nucleus ATS</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Nucleus ATS is a powerful applicant tracking system. Enter your API key to connect your Nucleus ATS
                    account.
                </Typography>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Nucleus API Key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose} sx={{ borderRadius: 1 }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleConnect}
                    sx={{ borderRadius: 1 }}
                    disabled={isLoading || !!user.nucleusApiKey}
                >
                    <ButtonTextWithLoading isLoading={isLoading} text={user.nucleusApiKey ? "Connected" : "Connect"} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
