import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { Fragment, ReactNode, useRef, useState } from "react";

import { dispatchEnterKeyboardEvent } from "../../utils/keyboardEvent";

type TValue = {
    value: string;
    excluded: boolean;
};

interface AutocompleteWithExclusionProps
    extends Omit<
        AutocompleteProps<string, boolean, boolean, any>,
        "options" | "value" | "onInputChange" | "onChange" | "renderInput" | "autoSave"
    > {
    value: TValue[];
    doNotFilter?: boolean;
    onChange: (value: TValue[]) => void;
    inputValue?: string;
    onInputChange?: (value: string) => void;
    options: string[];
    error?: string | null;
    placeholder?: string;
    label?: string;
    renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
    tooltipProps?: Omit<TooltipProps, "children">;
    autoSave?: boolean;
    renderLoading?: () => ReactNode;
}

export default function AutocompleteWithExclusion(props: AutocompleteWithExclusionProps) {
    const ref = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const {
        value,
        onChange,
        options,
        inputValue,
        error,
        onInputChange,
        loading,
        placeholder,
        label,
        renderTags,
        renderInput,
        autoSave,
        tooltipProps,
        renderLoading,
        doNotFilter = false,
        ...rest
    } = props;
    const stringValue = value.map(({ value }) => value);

    const handleChange = (selectedValue: string[]) => {
        const updatedValues = selectedValue?.map((val) => {
            const selectedOption = value.find((option) => option.value === val);
            return {
                value: val,
                excluded: selectedOption?.excluded ?? false,
            };
        });
        onChange(updatedValues);
    };

    const handleDelete = (selectedValue: string) => {
        const updatedValue = value.filter((option) => option.value !== selectedValue);
        onChange(updatedValue);
    };

    const toggleExclude = (selectedValue: string) => {
        const updatedValue = value.map((option) => {
            const isSelected = option.value === selectedValue;
            return isSelected ? { ...option, excluded: !option.excluded } : option;
        });
        onChange(updatedValue);
    };

    const defaultRenderTags = () => {
        return value.map((option, index) => {
            const excluded = option?.excluded ?? false;
            return (
                <Chip
                    key={`chip-${index}`}
                    sx={{
                        m: 0.5,
                        flexDirection: "row-reverse",
                        "& .MuiChip-icon": {
                            margin: 0,
                        },
                    }}
                    size="small"
                    variant="filled"
                    color={excluded ? "error" : "success"}
                    label={option.value}
                    icon={
                        <Box>
                            <IconButton size="small" color="inherit" onClick={() => toggleExclude(option.value)}>
                                <Tooltip arrow placement="top" title={option.excluded ? "Include" : "Exclude"}>
                                    <DoDisturbIcon fontSize="small" />
                                </Tooltip>
                            </IconButton>
                            <IconButton size="small" color="inherit" onClick={() => handleDelete(option.value)}>
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    }
                />
            );
        });
    };

    // NOTE: IF EVER YOU ENCOUNTER INPUTS GETTING CLEARED IN WEIRD WAY MAKE FOLLOWING FUNCTION A PURE COMPONENT, PUT IT OUTSIDE THIS COMPONENT
    const defaultRenderInput = (params: any) => {
        return (
            <Tooltip arrow open={isFocused} title={tooltipProps?.title} {...tooltipProps}>
                <TextField
                    {...params}
                    label={label}
                    value={inputValue}
                    error={Boolean(error)}
                    helperText={error}
                    onChange={(e) => onInputChange?.(e.target.value)}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? (
                                    renderLoading ? (
                                        renderLoading()
                                    ) : (
                                        <CircularProgress color="inherit" size={20} />
                                    )
                                ) : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                        sx: { fontSize: 14 },
                    }}
                    inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 14, width: "200px" },
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                        setIsFocused(false);
                        if (autoSave) {
                            dispatchEnterKeyboardEvent(ref.current);
                        }
                    }}
                    ref={ref}
                />
            </Tooltip>
        );
    };

    const { freeSolo, ...restProps } = rest;

    return (
        <Autocomplete
            multiple
            freeSolo
            value={stringValue}
            options={options as any[]}
            renderTags={renderTags ?? defaultRenderTags}
            onChange={(e: any, selectedValue): any => {
                if (Array.isArray(selectedValue)) {
                    if (!freeSolo && selectedValue.some((option) => !options.includes(option))) return;
                    /*
                     something weired with autocomplete selectedValue seems off on Enter event,
                     sometimes returns the new entered value but it should return all selected value.
                     */
                    if (e?.type === "keydown" && e?.keyCode === 13) {
                        const uniqueValue = Array.from(new Set([...stringValue, ...selectedValue]));
                        handleChange(uniqueValue);
                    } else {
                        handleChange(selectedValue);
                    }
                }
            }}
            filterOptions={doNotFilter ? (option) => option : undefined}
            getOptionLabel={(option) => option}
            getOptionKey={(option) => option}
            renderOption={(props, option) => (
                <li {...props} key={option}>
                    {option}
                </li>
            )}
            renderInput={renderInput ?? defaultRenderInput}
            {...restProps}
        />
    );
}
