import { Button, ButtonProps, styled } from "@mui/material";

const CustomButton = styled(Button)<ButtonProps>(() => ({
    width: "fit-content",
    height: "fit-content",
    fontSize: "12px",
    "&:disabled": {
        cursor: "not-allowed",
        pointerEvents: "all !important",
    },
}));

export default CustomButton; 
