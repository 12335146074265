import { createApi } from "@reduxjs/toolkit/dist/query/react";
import queryString from "query-string";

import { EQueryKeys } from "./linkedin-workflow.enums";
import { TCandidatesListResponse } from "./linkedin-workflow.types";

import { fetchBaseQueryWithZod } from "../utils";

type CandidateChannelProps = {
    candidateId: string;
    projectId: number[];
    userId: number;
};

const {
    SMS_CONVERSATIONS,
    EMAIL_CONVERSATIONS,
    LINKEDIN_CONVERSATIONS,
    CANDIDATES,
    PROJECTS,
    CANDIDATE_INFO,
    SWITCH_WORKFLOW,
    RESCHEDULE_WORKFLOW,
    WORKFLOWS,
    WORKFLOWS_LIST,
} = EQueryKeys;

export const linkedinWorkflowApi = createApi({
    reducerPath: "linkedinWorkflowApi",
    baseQuery: fetchBaseQueryWithZod,
    keepUnusedDataFor: 10,
    tagTypes: [WORKFLOWS, RESCHEDULE_WORKFLOW, SWITCH_WORKFLOW, WORKFLOWS_LIST],
    endpoints: (builder) => ({
        getWorkflowList: builder.query<any, any>({
            query: (filters) => ({
                url: `reschedule/workflows-list?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            keepUnusedDataFor: 2,
            transformResponse(baseQueryReturnValue: TCandidatesListResponse) {
                return baseQueryReturnValue.data;
            },
            providesTags: [WORKFLOWS],
        }),

        getWorkflowDetails: builder.query<any, any>({
            query: (filters) => ({
                url: `reschedule/workflow-details?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            // keepUnusedDataFor: 2,
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
            providesTags: [WORKFLOWS_LIST],
        }),

        rescheduleWorkflow: builder.mutation<void, { workflowId: any; scheduledFor: any }>({
            query: (payload) => {
                return {
                    url: "reschedule/reschedule-queue",
                    method: "POST",
                    body: { ...payload },
                };
            },
            invalidatesTags: [RESCHEDULE_WORKFLOW],
        }),

        switchWorkflow: builder.mutation<void, { workflowId1: any; workflowId2: any }>({
            query: (payload) => {
                return {
                    url: "reschedule/switch-queue",
                    method: "POST",
                    body: { ...payload },
                };
            },
            invalidatesTags: [SWITCH_WORKFLOW],
        }),
    }),
});

export const {
    // queries
    useGetWorkflowListQuery,
    useGetWorkflowDetailsQuery,

    //lazyqueries
    useLazyGetWorkflowListQuery,
    useLazyGetWorkflowDetailsQuery,

    //mutations
    useRescheduleWorkflowMutation,
} = linkedinWorkflowApi;
