import { CircularProgress } from "@mui/joy";

import style from "./loader.module.scss";

import { JoyProvider } from "../JoyProvider";

export default function Loader({ size = "md" }: { size?: "sm" | "lg" | "md" }) {
    return (
        <div className={style["loader"]}>
            <JoyProvider>
                <CircularProgress size={size} />
            </JoyProvider>
        </div>
    );
}
