import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import { zohoRecruitLogo } from "../../../constant/Constant";
import useOAuth2 from "../../../hooks/useOAuth2/useOAuth2";
import { selectUser, selectZohoRefreshCreds, setUser } from "../../Signin/Signin.reducer";
import ConnectWrapper from "./connectWrapper";

export default function ZohoRecruitConnect({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const zohoRecruitCreds = useSelector(selectZohoRefreshCreds);
    const zohoRefreshToken = zohoRecruitCreds?.refresh_token;

    const { loading, getAuth } = useOAuth2({
        authorizeUrl: `https://accounts.zoho.com/oauth/v2/auth`,
        clientId: `${import.meta.env.VITE_REACT_APP_ZOHO_CLIENT_ID}`,
        redirectUri: `${import.meta.env.VITE_REACT_APP_BASE_URL}/auth/zoho`,
        scope: "ZohoRECRUIT.modules.all,ZohoRecruit.setup.operation.all,ZohoRecruit.bulk.read",
        responseType: "code",
        extraQueryParameters: { prompt: "consent", access_type: "offline" },
        exchangeCodeForTokenServerURL: `${import.meta.env.VITE_REACT_APP_BASE_URL}/api/zohoRecruit/save-creds`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess: (response) => {
            dispatch(setSuccessNotification(response.message));
            dispatch(
                setUser({
                    ...user,
                    zohoRecruitCreds: {
                        ...user.zohoRecruitCreds,
                        refresh_token: "0000000",
                    },
                })
            );
        },
        onError: () => dispatch(setErrorNotification("Error in connecting zoho recruit")),
    });

    const handleConnect = () => {
        getAuth();
    };

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Connect Zoho Recruit</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Zoho Recruit is a cloud based applicant tracking system that’s built to provide diverse, end-to-end hiring solutions for staffing agencies, corporate HRs and temporary workforce.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose} sx={{ borderRadius: 1 }}>
                    Cancel
                </Button>
                <Button
                    variant={zohoRefreshToken ? "outlined" : "contained"}
                    color={"primary"}
                    onClick={handleConnect}
                    disabled={loading}
                    sx={{ borderRadius: 1 }}
                >
                    <ButtonTextWithLoading isLoading={loading} text={zohoRefreshToken ? "Connected" : "Connect With OAuth"} />
                </Button>
            </DialogActions>
        </Dialog>

    );
}

// export default function ZohoRecruitAts() {
//     return (
//         <ConnectWrapper
//             logo={zohoRecruitLogo}
//             logoAlt="zoho-recruit-logo"
//             title="Zoho Recruit"
//             description="Zoho Recruit is a cloud based applicant tracking system that’s built to provide diverse, end-to-end hiring solutions for staffing agencies, corporate HRs and temporary workforce."
//             disabled={false}
//         >
//             <ZohoRecruitConnect />
//         </ConnectWrapper>
//     );
// }
