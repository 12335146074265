import { useSelector } from "react-redux";
import { checkEasyGrowth, checkEasySource } from "../pages/Signin/Signin.reducer";

type WithConfigModeHOC<EsProps, EgProps> = {
    Es?: React.ComponentType<EsProps>;
    Eg?: React.ComponentType<EgProps>;
};

export const withConfigModeHOC = <EsProps extends {}, EgProps extends {}>({
    Eg,
    Es,
}: WithConfigModeHOC<EsProps, EgProps>) => {
    return ({ egProps, esProps }: { esProps?: EsProps; egProps?: EgProps }) => {
        const isEasySource = useSelector(checkEasySource);

        const isEasyGrowth = useSelector(checkEasyGrowth);

        if (isEasySource && Es) {
            return <Es {...(esProps ?? ({} as EsProps))} />;
        }

        if (isEasyGrowth && Eg) {
            return <Eg {...(egProps ?? ({} as EgProps))} />;
        }

        return null;
    };
};
