import { Fragment, useState } from "react";
import { HomeRepairServiceOutlined } from "@mui/icons-material";
import HouseIcon from "@mui/icons-material/House";
import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { isEmpty } from "lodash";
import Linkify from "react-linkify";

import { VettingCriteriaRating } from "../../../../components/VettingCriteriaRating";
import { LeadScoreRating } from "../../../../easy-growth/components/LeadScoreRating";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

const CandidateProfileHeading = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600",
    color: theme.palette.grey[800],
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: "0.875rem",
    color: "#32383f",
    fontWeight: "400",
}));

function TypographyWithReadMore({ text, title, limit = 250 }: { text: string; title: string; limit?: number }) {
    const [readMore, setReadMore] = useState(false);
    const spanText = readMore ? "Read Less" : "Read More";
    const value = readMore ? text : text?.slice(0, limit) + "...";
    const linkDecorator = (href: string, text: string, key: string) => (
        <p style={{ fontSize: "30px" }}>
            {" "}
            <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        </p>
    );
    return (
        <CustomTypography variant="subtitle2">
            <span style={{ fontSize: "0.85rem", color: "#32383f", fontWeight: "600" }}>Description: </span>{" "}
            <Linkify componentDecorator={linkDecorator}>{value}</Linkify>{" "}
            <span
                style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "400" }}
                onClick={() => setReadMore((prev) => !prev)}
            >
                {spanText}
            </span>
        </CustomTypography>
    );
}

function Repository({ repository }: { repository: any }) {
    return (
        <Stack spacing={1.4} p={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography
                    variant="h6"
                    component="a"
                    href={repository.html_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        color: "#01579b",
                        textDecoration: "none",
                        fontSize: "1rem",
                        lineHeight: "1.5",
                        fontWeight: "500",
                    }}
                >
                    {repository?.full_name.split(" ").length > 9
                        ? `${repository.full_name.split(" ").slice(0, 9).join(" ")}...`
                        : repository.full_name}{" "}
                    <sup>↗</sup>
                </Typography>
                {repository?.created_at && (
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {new Date(repository.created_at).getFullYear()}
                    </Typography>
                )}
            </Stack>

            {repository?.description && repository?.description !== "N/A" && (
                <Typography variant="body2">
                    {repository?.description && repository?.description !== 0 && (
                        <span>
                            <b>Description:</b> {repository?.description}
                        </span>
                    )}
                </Typography>
            )}

            {repository?.language && (
                <Typography variant="body2">
                    {repository?.language && repository?.language !== 0 && (
                        <span>
                            <b>Language:</b> {repository?.language}
                        </span>
                    )}
                </Typography>
            )}

            {repository?.size && (
                <Typography variant="body2">
                    {repository.star_count !== 0 && (
                        <span>
                            <b>Star:</b> {repository.star_count} {" | "}
                        </span>
                    )}

                    {repository?.size && repository?.size !== 0 && (
                        <span>
                            <b>Size:</b> {repository?.size}
                        </span>
                    )}
                </Typography>
            )}
        </Stack>
    );
}

export default function RepositoriesTab({
    data,
    starRating,
    leadScore,
    projectId,
    candidateId,
    vettingFixed,
}: {
    data: any;
    starRating: any;
    leadScore: any;
    projectId: any;
    candidateId: any;
    vettingFixed: any;
}) {
    return (
        <Stack spacing={5} py={2} sx={{ maxHeight: "80vh", overflowY: "auto" }}>
            {data?.repositories && data?.repositories?.length > 1 && (
                <Stack spacing={1}>
                    <CandidateProfileHeading variant="body2">Repositories</CandidateProfileHeading>
                    {data.repositories.map((repository: any, i: any) => (
                        <Fragment key={`repository-${i}`}>
                            <Repository repository={repository} />
                            {data.repositories.length - 1 !== i && <Divider />}
                        </Fragment>
                    ))}
                </Stack>
            )}
        </Stack>
    );
}
