import { Collapse, Skeleton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";

import style from "./delay.module.scss";

import Button from "../../../../components/Button/Button";
import DisableWorkflowComponentOverlay from "../../../../components/DisableWorkflowComponentOverlay";
import Modal from "../../../../components/Modal/Modal";
import { chevronDownIconBlue, cloneIcon, trashGrayIcon } from "../../../../constant/Constant";
import { RootState } from "../../../../store";

import { checkSMSEnabled } from "@/store/reducers/signin/Signin.reducer";
import { selectCurrentFollowUpDelay, selectEnableDelay } from "@/store/reducers/template/template.reducer";
import dayjs from "dayjs";

type TimeArrProps = Array<{
    key: "hours" | "mins" | "days" | "weeks";
    label: string;
}>;

const timeArr: TimeArrProps = [
    {
        key: "mins",
        label: "Mins",
    },
    {
        key: "hours",
        label: "Hours",
    },
    {
        key: "days",
        label: "Days",
    },
    {
        key: "weeks",
        label: "Weeks",
    },
];

type InputComponentProps = {
    label: string;
    value?: string;
    onChange: (val: string) => void;
    disabled?: boolean;
};

function InputComponent({ label, value, onChange, disabled }: InputComponentProps) {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{label}</Typography>
            <TextField size="small" disabled={disabled} value={value} onChange={(e) => onChange(e.target.value)} />
        </Stack>
    );
}

type DelayProps = {
    isOpen: boolean;
    index: number;
    editorVal?: ReactQuill.Value;
    hours?: string;
    mins?: string;
    days?: string;
    weeks?: string;
    toggleCollapse: (tarInd: number) => void;
    handleRemove?: (tarInd: number) => void;
    handleDuplicate?: (tarInd: number) => void;
    handleTimeChange?: (tarInd: number, key: string, newValue: string) => void;
    readable?: boolean;
    isLoading?: boolean;
    isFollowUpDelay?: boolean;
    status?: React.ReactNode;
    expectedStartTime?: string;
};

export default function Delay(props: DelayProps) {
    const {
        index,
        isOpen,
        toggleCollapse,
        handleRemove,
        handleDuplicate,
        handleTimeChange,
        readable,
        isLoading,
        status,
        expectedStartTime,
    } = props;
    const isSMSEnabled = useSelector(checkSMSEnabled);
    const isNewFollowUpEmailDelay = index > 5;
    const enableDelay = useSelector((state: RootState) =>
        isNewFollowUpEmailDelay ? selectCurrentFollowUpDelay(index)(state) : selectEnableDelay(state, index)
    );

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    // const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const handleInputChange = (type: string, value: string) => {
        if (handleTimeChange) handleTimeChange(index, type, value);
    };

    if (readable || !handleDuplicate || !handleRemove || !handleTimeChange) {
        return (
            <>
                <div className={style["rem__container"]}>
                    <div className={`${style["rem__legendBox"]} ${index === 0 ? style["rem__legendBox-start"] : ""}`}>
                        {isOpen ? (
                            <div className={style["rem__actionbar"]}>
                                {handleDuplicate ? (
                                    <div className={style["rem__actionBtn"]} onClick={() => handleDuplicate(index)}>
                                        <Tooltip title={"Duplicate"}>
                                            <img src={cloneIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                ) : null}
                                {handleRemove ? (
                                    <div className={style["rem__actionBtn"]} onClick={() => handleRemove(index)}>
                                        <Tooltip title={"Delete"}>
                                            <img src={trashGrayIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        <div
                            className={style["rem__legend"]}
                            onClick={() => toggleCollapse(index)}
                            style={{ display: "flex" }}
                        >
                            Delay
                            <img
                                className={isOpen ? style["rem__invert"] : ""}
                                src={chevronDownIconBlue}
                                alt=""
                                style={{ marginLeft: "10px" }}
                            />
                            {status}
                        </div>
                        {expectedStartTime?.length > 1 && (
                            <div
                                style={{
                                    marginLeft: "600px",
                                    marginTop: "7px",
                                    position: "absolute",
                                    fontSize: "14px",
                                    color: "#0891B2",
                                }}
                            >
                                <p>
                                    {dayjs(expectedStartTime).isAfter(dayjs()) ? "Starting on: " : "Started on: "}
                                    {dayjs(expectedStartTime).format("MMM D, h:mm A")}
                                </p>
                            </div>
                        )}
                    </div>

                    <Collapse in={isOpen}>
                        <div className={style["rem__body"]}>
                            <Stack direction="row" spacing={2} flexWrap="nowrap">
                                {timeArr.map((time) => (
                                    <InputComponent
                                        label={time.label}
                                        value={props[time.key]}
                                        disabled={readable}
                                        onChange={(val) => handleInputChange(time.key, val)}
                                    />
                                ))}
                            </Stack>
                        </div>
                    </Collapse>
                </div>
            </>
        );
    }

    // REASON: sms delay is always at index 3, that's why if sms is not enabled, we should not render the delay component
    if (index === 3 && !isSMSEnabled) {
        return null;
    }

    const message =
        index === 1
            ? "Enable either connection request or in-mail to set delay"
            : index === 3
              ? "Enable SMS to set delay"
              : "Enable followup email to set delay.";

    return (
        <>
            <div className={style["rem"]}>
                <div className={style["rem__container"]}>
                    <div className={style["rem__legendBox"]}>
                        {/* {isOpen ? (
                                    <div className={style["rem__actionbar"]}>
                                        <div
                                            className={style["rem__actionBtn"]}
                                            onClick={() =>
                                                handleDuplicate(index)
                                            }
                                        >
                                            <Tooltip title={"Duplicate"}>
                                                <img src={cloneIcon} alt="" />
                                            </Tooltip>
                                        </div>
                                        <div
                                            className={style["rem__actionBtn"]}
                                            onClick={() => handleRemove(index)}
                                        >
                                            <Tooltip title={"Delete"}>
                                                <img
                                                    src={trashGrayIcon}
                                                    alt=""
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : null} */}
                        <p
                            className={style["rem__legend"]}
                            onClick={() => toggleCollapse(index)}
                            style={{ display: "flex" }}
                        >
                            Delay{" "}
                            <img
                                className={isOpen ? style["rem__invert"] : ""}
                                src={chevronDownIconBlue}
                                alt=""
                                style={{ marginLeft: "10px" }}
                            />
                        </p>
                        {expectedStartTime?.length > 1 && (
                            <div
                                style={{
                                    marginLeft: "600px",
                                    marginTop: "7px",
                                    position: "absolute",
                                    fontSize: "14px",
                                    color: "#0891B2",
                                }}
                            >
                                <p>
                                    {dayjs(expectedStartTime).isAfter(dayjs()) ? "Starting on: " : "Started on: "}
                                    {dayjs(expectedStartTime).format("MMM D, h:mm A")}
                                </p>
                            </div>
                        )}
                    </div>
                    <Collapse in={isOpen}>
                        <div className={style["rem__body"]}>
                            {isLoading ? (
                                <Skeleton variant="rounded" width="100%" height={50} />
                            ) : (
                                <Stack direction="row" spacing={2} alignItems="center">
                                    {timeArr.map((time) => (
                                        <InputComponent
                                            label={time.label}
                                            value={props[time.key]}
                                            onChange={(val) => handleInputChange(time.key, val)}
                                        />
                                    ))}
                                </Stack>
                            )}
                            {!enableDelay && (
                                <DisableWorkflowComponentOverlay>{message}</DisableWorkflowComponentOverlay>
                            )}
                        </div>
                    </Collapse>
                </div>
            </div>
            <Modal open={isDeleteModalOpen} onClose={closeDeleteModal} title={"Confirm Delete"} width={"350px"}>
                <div className={style["rem__btnBox"]}>
                    <Button label="Cancel" variant="secondary" onClick={closeDeleteModal} />
                    <Button label="Delete" variant="primary" onClick={() => handleRemove(index)} />
                </div>
            </Modal>
        </>
    );
}