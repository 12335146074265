import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { CircularProgress, InputAdornment, styled, TextField } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

export const CustomTextFieldForAutocomplete = styled(
    (props: AutocompleteRenderInputParams & { isLoading?: boolean }) => {
        const { InputProps, isLoading } = props;
        return (
            <TextField
                {...props}
                size="small"
                variant="outlined"
                InputProps={{
                    ...InputProps,
                    placeholder: "Assign to",
                    type: "search",
                    endAdornment: (
                        <InputAdornment position="end">
                            {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <SearchRoundedIcon fontSize="small" />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    "& .MuiOutlinedInput-input": {
        fontSize: "13px",
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "13px",
    },
}));
