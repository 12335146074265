import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Button, { ButtonProps } from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function NavigateBackButton(props: ButtonProps) {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <Button
            variant="contained"
            color="info"
            onClick={goBack}
            startIcon={<NavigateBeforeIcon fontSize="inherit" />}
            {...props}
        >
            {props.children ?? "Go Back"}
        </Button>
    );
}
