import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircleOutline";
import SensorOccupiedRoundedIcon from "@mui/icons-material/SensorOccupiedRounded";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import DisableEmailSwitch from "./DisableEmailSwitch";
import style from "./email.module.scss";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import CustomDropdown, { CustomDropdownOptionsType } from "../../../../components/CustomDropdown/CustomDropdown";
import DateModal from "../../../../components/DateModal/DateModal";
import DisableWorkflowComponentOverlay from "../../../../components/DisableWorkflowComponentOverlay";
import InputWithWordCount from "../../../../components/InputWithWordCount/InputWithWordCount";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import Modal from "../../../../components/Modal/Modal";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { chevronDownIconBlue, cloneIcon, trashGrayIcon } from "../../../../constant/Constant";
import usePersonalizeValues from "../../../../hooks/usePersonalizeValues";
import { RootState } from "../../../../store";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import quillWordCount from "../../../../utils/quillWordCount";
import CustomMUIInput from "../../../project/components/CustomMUIInput";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import SaveTemplate from "../../../triggerWorkflow/components/SaveTemplate";
import SelectTemplate from "../../../triggerWorkflow/components/SelectTemplate";
import CalendlyMenu from "../../../triggerWorkflow/molecules/CalendlyMenu";

import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import {
    isFollowupWithIndexPresent,
    selectCurrentEnableFollowUpEmail,
    selectEnableEmail,
    selectEnableFollowUpEmail,
    setEditMsgPopup,
    setEnableCurrentFollowUpEmail,
    setEnableEmail,
    setEnableFollowUpEmail,
} from "@/store/reducers/template/template.reducer";
import { changeExtraFollowupStatus } from "@/store/reducers/trigger-workflow/customTemplates.slice";
import {
    selectCandidates,
    selectIndex,
    setEditTemplatesModal,
} from "@/store/reducers/trigger-workflow/personalizedWorkflow.slice";
import {
    postTestMail,
    selectHyperPersonalizeMode,
    selectWorkflowMode,
} from "@/store/reducers/workflow/workflow.reducer";
import dayjs from "dayjs";
import React from "react";

interface emailPropsType {
    isOpen: boolean;
    index: number;
    subject?: string;
    editorVal?: string;
    setSubject?: (tarInd: number, newValue: string) => void;
    setEditorVal?: (tarInd: number, newValue: string) => void;
    toggleCollapse: (tarInd: number) => void;
    handleRemove?: (tarInd: number) => void;
    handleDuplicate?: (tarInd: number) => void;
    type?: string;
    readable?: boolean;
    cloneDisabled?: boolean;
    isLoading?: boolean;
    handleBlurChange?: () => void;
    handleRemoveStep?: (index: number) => void;
    status?: React.ReactNode;
    emailEncountered?: boolean;
    expectedStartTime?: string;
}

const CustomButton = styled((props: ButtonProps) => <Button {...props} />)(() => ({
    color: "#fff",
    backgroundColor: "#088397",
    borderRadius: 8,
    borderColor: "#088397",
    padding: "0 1rem",
    "&:hover": {
        backgroundColor: "#088397",
        borderColor: "#088397",
    },
    "&:disabled": {
        backgroundColor: "#088397",
        borderColor: "#088397",
    },
}));

const getIsNewFollowupEmail = (index: number) => index >= 7;

type DisableSwitchProps = {
    children: React.ReactNode;
};

function DisableSwitchContainer(props: DisableSwitchProps) {
    const { children } = props;
    return (
        <Stack
            style={{
                maxHeight: "50px",
                padding: "0.3rem 1rem",
                position: "absolute",
                top: "-40px",
                right: "50px",
                backgroundColor: "white",
                borderTopLeftRadius: "0.7rem",
                borderTopRightRadius: "0.7rem",
            }}
        >
            {children}
        </Stack>
    );
}

function DisableFollowUpEmailSwitch({ index }: { index: number }) {
    const dispatch = useDispatch();
    const isNewFollowUpEmail = getIsNewFollowupEmail(index);

    const isFollowupSwitchStatusAlreadyPresent = useSelector((state: RootState) =>
        isFollowupWithIndexPresent(state, index)
    );
    const enableInitialFollowUpEmail = useSelector(selectEnableFollowUpEmail);
    const enableCurrentFollowUpEmail = useSelector(selectCurrentEnableFollowUpEmail(index));
    const enableAllNewFolllowUpEmails = useSelector((state: RootState) =>
        Object.entries(state.template)
            .filter(([key]) => key.startsWith("FollowUpEmail"))
            .sort((a, b) => Number(a[0].split("-")[1]) - Number(b[0].split("-")[1]))
    );

    useEffect(() => {
        // initialize the new followup email state
        if (isNewFollowUpEmail && !isFollowupSwitchStatusAlreadyPresent) {
            dispatch(setEnableCurrentFollowUpEmail({ index, value: true }));
        }
    }, [isFollowupSwitchStatusAlreadyPresent]);

    useEffect(() => {
        /*
            if initial follow-up email is disabled and new follow-up email is enabled,
            then disable new follow-up emails
             */
        if (!enableInitialFollowUpEmail && enableCurrentFollowUpEmail) {
            dispatch(setEnableCurrentFollowUpEmail({ index, value: false }));
        }
    }, [enableInitialFollowUpEmail, enableCurrentFollowUpEmail]);

    const enablePrevfollowupEmails = () => {
        const currentIdx = enableAllNewFolllowUpEmails.findIndex((a) => a[0] === `FollowUpEmail-${index}`);
        for (let i = 0; i < currentIdx; i++) {
            const currEnableEmail = enableAllNewFolllowUpEmails[i];
            const isEnabled = currEnableEmail[1];
            if (!isEnabled) {
                const index = Number(currEnableEmail[0].split("-")[1]);
                dispatch(setEnableCurrentFollowUpEmail({ index, value: true }));
            }
        }
    };

    const enableFollowUpEmailFn = (value: boolean) => {
        if (isNewFollowUpEmail) {
            dispatch(setEnableCurrentFollowUpEmail({ index, value }));
            dispatch(changeExtraFollowupStatus({ index, isEnabled: value }));
            /*
            if new follow-up email is enabled and initial follow-up email is disabled,
            then enable initial follow-up email 
             */
            if (!enableInitialFollowUpEmail && value) {
                dispatch(setEnableFollowUpEmail(true));
            }
            if (value) {
                /*
                if new follow-up email is enabled and previous follow-emails are disabled,
                then enable previous follow-up email 
             */
                enablePrevfollowupEmails();
            }
        } else {
            dispatch(setEnableFollowUpEmail(value));
        }
    };

    return (
        <DisableEmailSwitch
            checked={isNewFollowUpEmail ? enableCurrentFollowUpEmail : enableInitialFollowUpEmail}
            dispatch={enableFollowUpEmailFn}
        />
    );
}

function DisableInitialEmailSwitch() {
    const dispatch = useDispatch();
    const enableInitialEmail = useSelector(selectEnableEmail);

    return (
        <DisableEmailSwitch
            checked={enableInitialEmail}
            dispatch={(value: boolean) => dispatch(setEnableEmail(value))}
        />
    );
}

export function OutReachElementHelperMessage() {
    const { t } = useTranslation();
    return (
        <Typography
            style={{
                fontSize: "12px",
                color: "#6C6C6C",
            }}
        >
            {t("outreach.outreachElementsHelperMessage")}
        </Typography>
    );
}

export default function Email(props: emailPropsType) {
    const {
        index,
        editorVal,
        setEditorVal,
        isOpen,
        toggleCollapse,
        handleRemove,
        handleDuplicate,
        subject = "",
        setSubject,
        type,
        readable,
        isLoading,
        handleBlurChange,
        handleRemoveStep,
        status,
        emailEncountered,
        expectedStartTime,
    } = props;
    const templateType = type === "in-mail" ? "INMAIL" : "EMAIL";
    const isNewFollowUpEmail = getIsNewFollowupEmail(index);

    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(selectUser);
    const candidateIndex = useSelector(selectIndex);
    const candidates = useSelector(selectCandidates);
    const workflowMode = useSelector(selectWorkflowMode);
    const isTestMailLoading = useSelector(checkIfLoading(`${index}email${postTestMail.type}`));

    const {
        subjectRef,
        bodyRef,
        setAddTo,
        calendly: { calendlyActions, showCalendlyMenu, handleAddCalendlyLink },
    } = usePersonalizeValues();

    const [isDateModalOpen, setIsDateModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [checkInputFocus, setCheckInputFocus] = useState<{
        index: number;
        value: string;
    }>({
        index: 0,
        value: "",
    });
    const [emailBodyWordCount, setEmailBodyWordCount] = useState(editorVal?.split(" ").length || 0);
    const [testMailModal, setTestMailModal] = useState(false);
    const [testMailInput, setTestMailInput] = useState("");
    const isHyperPersonalized = useSelector(selectHyperPersonalizeMode);

    const enableFollowUpEmail = useSelector((state: RootState) =>
        isNewFollowUpEmail ? selectCurrentEnableFollowUpEmail(index)(state) : selectEnableFollowUpEmail(state)
    );

    const enableInitialEmail = useSelector(selectEnableEmail);

    // const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    // const showDateModal = () => setIsDateModalOpen(true);
    const closeDateModal = () => setIsDateModalOpen(false);

    const openTestMailModal = () => setTestMailModal(true);
    const closeTestMailModal = () => {
        setTestMailInput("");
        setTestMailModal(false);
    };

    const handleSelectDate = function (dayVal: Dayjs | null) {
        closeDateModal();
    };

    const handlePersonaliseValues = (key: string) => {
        if (!checkInputFocus.value || !setSubject || !setEditorVal) return;

        if (checkInputFocus.value === "subject" && subjectRef?.current) {
            const cursorPosition = subjectRef.current?.selectionStart || 0;
            const tempSubject = subject?.substring(0, cursorPosition) + key + subject?.substring(cursorPosition);
            setSubject(checkInputFocus.index, tempSubject);
            subjectRef.current?.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        } else if (checkInputFocus.value === "body") {
            // @ts-ignore
            bodyRef.current.editor.focus();
            // @ts-ignore
            const selection = bodyRef.current.editor.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            // @ts-ignore
            bodyRef.current.editor.insertText(cursorPosition, key);
        }
    };

    const dispatchTestMail = (email?: string) => {
        const candidatesFromWorkflow = location?.state?.candidates;

        const candidate =
            isHyperPersonalized && candidates?.length ? candidates[candidateIndex]?.id : candidatesFromWorkflow[0];

        dispatch(
            postTestMail({
                subject,
                email: email,
                action: `${index}email${postTestMail.type}`,
                body: editorVal || "",
                projectId: location.state?.projectId,
                candidateId: candidate,
                onSuccess: closeTestMailModal,
            })
        );
    };

    const sendTestMail = () => {
        if (user.emailAuthorized) {
            dispatchTestMail();
        } else {
            openTestMailModal();
        }
    };

    const handleReactQuillClick = () => {
        dispatch(setEditTemplatesModal(true));
        dispatch(setEditMsgPopup(true));
        setTimeout(() => {
            dispatch(setEditMsgPopup(false));
        }, 500);
    };

    const personaliseOptions: CustomDropdownOptionsType[] = [
        {
            label: "{name}",
            key: "{name}",
            handleClick: () => handlePersonaliseValues("{name}"),
        },
        {
            label: "{lastname}",
            key: "{lastname}",
            handleClick: () => handlePersonaliseValues("{lastname}"),
        },
        {
            label: "{role}",
            key: "{role}",
            handleClick: () => handlePersonaliseValues("{role}"),
        },
        {
            label: "{company}",
            key: "{company}",
            handleClick: () => handlePersonaliseValues("{company}"),
        },
    ];

    if (readable || !handleDuplicate || !handleRemove || !setSubject || !setEditorVal)
        return (
            <>
                <div className={`${style["email__legendBox"]} ${index === 0 ? style["email__legendBox-start"] : ""}`}>
                    {isOpen ? (
                        <div className={style["email__actionbar"]}>
                            {handleDuplicate ? (
                                <div
                                    className={style["email__actionBtn"]}
                                    onClick={() => {
                                        handleDuplicate(index);
                                    }}
                                >
                                    <Tooltip title={"Duplicate"}>
                                        <img src={cloneIcon} alt="" />
                                    </Tooltip>
                                </div>
                            ) : null}
                            {handleRemove ? (
                                <div className={style["email__actionBtn"]} onClick={() => handleRemove(index)}>
                                    <Tooltip title={"Delete"}>
                                        <img src={trashGrayIcon} alt="" />
                                    </Tooltip>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    <p
                        className={style["email__legend"]}
                        onClick={() => toggleCollapse(index)}
                        style={{ display: "flex" }}
                    >
                        {type === "in-mail"
                            ? "LinkedIn Inmail"
                            : emailEncountered !== undefined
                                ? emailEncountered
                                    ? "Follow-up email"
                                    : "Email"
                                : index === 0
                                    ? "Email"
                                    : `Follow-up email `}
                        <img
                            className={isOpen ? style["email__invert"] : ""}
                            src={chevronDownIconBlue}
                            alt=""
                            style={{ marginLeft: "10px" }}
                        />
                        {status}
                    </p>
                    {expectedStartTime?.length > 1 && (
                        <div
                            style={{
                                marginLeft: "600px",
                                marginTop: "7px",
                                position: "absolute",
                                fontSize: "14px",
                                color: "#0891B2",
                            }}
                        >
                            <p>
                                {dayjs(expectedStartTime).isAfter(dayjs()) ? "Starting on: " : "Started on: "}
                                {dayjs(expectedStartTime).format("MMM D, h:mm A")}
                            </p>
                        </div>
                    )}
                </div>
                <Collapse in={isOpen}>
                    <div className={style["email__body"]}>
                        <div className={style["email__selectBox"]}>
                            {/* <input
                                ref={subjectRef}
                                type="text"
                                placeholder="Subject"
                                value={subject}
                                disabled={readable}
                                style={{
                                    width: "100%",
                                    padding: "0.5rem 1rem",
                                    borderRadius: "0.5rem",
                                }}
                            /> */}
                            <TextField
                                fullWidth
                                multiline={true}
                                disabled={true}
                                inputProps={{ style: { fontSize: "14px" } }}
                                value={subject}
                            />
                        </div>
                        <div onBlur={handleBlurChange}>
                            <RichTextEditor enableImageModule ref={bodyRef} value={editorVal} readOnly={readable} />
                        </div>
                    </div>
                </Collapse>
            </>
        );

    const isInitialStep = index <= 6;

    return (
        <>
            <div className={style["email"]}>
                <div className={style["email__container"]} id="workflow-email">
                    <div
                        className={`${style["email__legendBox"]} ${index === 0 ? style["email__legendBox-start"] : ""}`}
                    >
                        {/* {isOpen ? (
                                    <div className={style["email__actionbar"]}>
                                        {!cloneDisabled ? (
                                            <div
                                                className={
                                                    style["email__actionBtn"]
                                                }
                                                onClick={() =>
                                                    handleDuplicate(index)
                                                }
                                            >
                                                <Tooltip title={"Duplicate"}>
                                                    <img
                                                        src={cloneIcon}
                                                        alt=""
                                                    />
                                                </Tooltip>
                                            </div>
                                        ) : null}
                                        <div
                                            className={
                                                style["email__actionBtn"]
                                            }
                                            onClick={() => handleRemove(index)}
                                        >
                                            <Tooltip title={"Delete"}>
                                                <img
                                                    src={trashGrayIcon}
                                                    alt=""
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : null} */}
                        <div style={{ position: "relative" }}>
                            <div
                                className={style["email__legend"]}
                                onClick={() => toggleCollapse(index)}
                                style={{ display: "flex" }}
                            >
                                {type === "in-mail"
                                    ? "LinkedIn Inmail"
                                    : emailEncountered !== undefined
                                        ? emailEncountered
                                            ? "Follow-up email"
                                            : "Email"
                                        : index === 0
                                            ? "Email"
                                            : `Follow-up email `}

                                <img
                                    className={isOpen ? style["email__invert"] : ""}
                                    src={chevronDownIconBlue}
                                    alt=""
                                    style={{ marginLeft: "10px" }}
                                />
                            </div>
                            {expectedStartTime?.length > 1 && (
                                <div
                                    style={{
                                        marginLeft: "600px",
                                        marginTop: "7px",
                                        position: "absolute",
                                        fontSize: "14px",
                                        color: "#0891B2",
                                    }}
                                >
                                    <p>
                                        {dayjs(expectedStartTime).isAfter(dayjs()) ? "Starting on: " : "Started on: "}
                                        {dayjs(expectedStartTime).format("MMM D, h:mm A")}
                                    </p>
                                </div>
                            )}
                            {index > 6 && handleRemoveStep && (
                                <Box marginLeft={2} alignItems="center" position="absolute" left={165} top="15%">
                                    <Tooltip title="Remove follow-up email">
                                        <RemoveCircleIcon
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleRemoveStep(index)}
                                            color="error"
                                        />
                                    </Tooltip>
                                </Box>
                            )}
                        </div>
                    </div>
                    <Collapse in={isOpen}>
                        <div
                            className={style["email__body"]}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <DisableSwitchContainer>
                                {index === 0 ? (
                                    <DisableInitialEmailSwitch />
                                ) : (
                                    <DisableFollowUpEmailSwitch index={index} />
                                )}
                            </DisableSwitchContainer>
                            {!(index === 0 ? enableInitialEmail : enableFollowUpEmail) && (
                                <DisableWorkflowComponentOverlay>
                                    Enable this step to send follow-up email.
                                </DisableWorkflowComponentOverlay>
                            )}
                            <div className={style["email__selectBox"]}>
                                {isLoading ? (
                                    <Skeleton variant="rounded" width="100%" height={40} />
                                ) : (
                                    <InputWithWordCount
                                        ref={subjectRef}
                                        wordLimit={15}
                                        disabled={isHyperPersonalized}
                                        placeholder="Subject"
                                        customValue={subject}
                                        handleOnChange={(val) => setSubject(index, val)}
                                        wrapperStyle={{
                                            width: "100%",
                                        }}
                                        inputStyle={{
                                            width: "100%",
                                        }}
                                        onClick={
                                            isHyperPersonalized && isInitialStep ? handleReactQuillClick : undefined
                                        }
                                        onFocus={() => {
                                            setCheckInputFocus({
                                                index,
                                                value: "subject",
                                            });
                                            // const cursorPosition =
                                            //     subjectRef.current
                                            //         ?.selectionStart ||
                                            //     0;
                                            // subjectRef.current?.setSelectionRange(
                                            //     cursorPosition,
                                            //     cursorPosition
                                            // );
                                        }}
                                    />
                                )}
                                <div className={style["email__templateBox"]}>
                                    {/* <Dropdown
                                                trigger={["click"]}
                                                menu={{ items: tempDropDown }}
                                            >
                                                <div
                                                    className={
                                                        style["email__select"]
                                                    }
                                                >
                                                    <p>{selectedTemplate}</p>
                                                </div>
                                            </Dropdown> */}
                                    <>
                                        {showCalendlyMenu && (
                                            <CalendlyMenu
                                                calendlyActions={calendlyActions}
                                                addCalendlyTag={(text, link) => handleAddCalendlyLink(text, link)}
                                            />
                                        )}
                                        <CustomDropdown options={personaliseOptions} tooltipTitle="Personalise">
                                            <SensorOccupiedRoundedIcon
                                                fontSize="large"
                                                sx={{
                                                    height: "28px",
                                                    width: "28px",
                                                }}
                                            />
                                        </CustomDropdown>
                                    </>
                                </div>
                            </div>
                            {isLoading ? (
                                <Skeleton variant="rounded" width="100%" height={200} />
                            ) : (
                                <>
                                    <div
                                        onClick={
                                            isHyperPersonalized && isInitialStep ? handleReactQuillClick : undefined
                                        }
                                        onBlur={handleBlurChange}
                                    >
                                        <RichTextEditor
                                            enableImageModule
                                            className={isHyperPersonalized && isInitialStep ? "email-quill" : ""}
                                            readOnly={isHyperPersonalized && isInitialStep}
                                            ref={bodyRef}
                                            value={editorVal}
                                            onChange={(value) => {
                                                const wordCount = quillWordCount(value);

                                                setEmailBodyWordCount(wordCount);
                                                setEditorVal(index, value);
                                            }}
                                            onFocus={() => {
                                                setAddTo("BODY");
                                                setCheckInputFocus({
                                                    index,
                                                    value: "body",
                                                });
                                            }}
                                        />
                                    </div>
                                    <Stack mt={1} direction="row" alignItems="center" justifyContent="space-between">
                                        {type === "in-mail" ? (
                                            <Stack>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    *Incase of normal LinkedIn licence, Inmails will be sent only to
                                                    open profiles (no Linkedin credits will be used)
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#6C6C6C",
                                                    }}
                                                >
                                                    **Incase of Recruiter, Inmails will be sent based on the remaining
                                                    credits in your Recruiter account
                                                </Typography>
                                            </Stack>
                                        ) : (
                                            <OutReachElementHelperMessage />
                                        )}
                                        <Stack spacing={1} direction="row" alignItems="center">
                                            {workflowMode === "REGULAR_WORKFLOW" ? (
                                                <>
                                                    <SaveTemplate
                                                        type={templateType}
                                                        subject={subject}
                                                        editorVal={editorVal}
                                                    />
                                                    <SelectTemplate
                                                        type={templateType}
                                                        setSubject={(value) => setSubject(index, value)}
                                                        setEditorVal={(value) => setEditorVal(index, value)}
                                                    />
                                                </>
                                            ) : null}
                                            {type === "email" ? (
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    startIcon={
                                                        isTestMailLoading ? null : (
                                                            <ForwardToInboxIcon fontSize="small" />
                                                        )
                                                    }
                                                    disabled={isTestMailLoading}
                                                    onClick={sendTestMail}
                                                    sx={{
                                                        width: "fit-content",
                                                    }}
                                                >
                                                    <ButtonTextWithLoading
                                                        isLoading={isTestMailLoading}
                                                        progressSize={16}
                                                        progressStyle={{
                                                            color: "#0891b2",
                                                        }}
                                                        text="Test Mail"
                                                    />
                                                </Button>
                                            ) : null}
                                            {/* <p
                                                    className={
                                                        style[
                                                            "email__bodyWords"
                                                        ]
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            color:
                                                                emailBodyWordCount >
                                                                125
                                                                    ? "red"
                                                                    : "inherit",
                                                        }}
                                                    >
                                                        {emailBodyWordCount}
                                                    </span>
                                                    /125 words
                                                </p> */}
                                        </Stack>
                                    </Stack>
                                </>
                            )}
                        </div>
                    </Collapse>
                </div>
            </div>
            <Modal open={isDateModalOpen} onClose={closeDateModal} title={"Select Date"} width={"350px"}>
                <DateModal closeModal={closeDateModal} handleSelectDate={handleSelectDate} />
            </Modal>
            <Modal open={isDeleteModalOpen} onClose={closeDeleteModal} title={"Confirm Delete"} width={"350px"}>
                <MaterialProvider>
                    <div className={style["rem__btnBox"]}>
                        <Button variant="outlined" onClick={closeDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={() => handleRemove(index)}>
                            Delete
                        </Button>
                    </div>
                </MaterialProvider>
            </Modal>
            {testMailModal ? (
                <BaseModal
                    onClose={closeTestMailModal}
                    overlayStyles={{
                        padding: "1rem 1.5rem 1.5rem",
                        width: 550,
                    }}
                >
                    <Typography fontSize={20} fontWeight={500} mb={2}>
                        Test Mail
                    </Typography>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            dispatchTestMail(testMailInput);
                        }}
                    >
                        <Stack direction="row" alignItems="stretch" spacing={2}>
                            <CustomMUIInput
                                type="email"
                                required
                                placeholder="Enter your email id"
                                value={testMailInput}
                                fullWidth
                                onChange={(e) => setTestMailInput(e.target.value)}
                            />
                            <CustomButton type="submit" disabled={isTestMailLoading}>
                                <ButtonTextWithLoading isLoading={isTestMailLoading} text="Submit" />
                            </CustomButton>
                        </Stack>
                    </form>
                </BaseModal>
            ) : null}
        </>
    );
}