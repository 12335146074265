import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface VerificationModalProps { }

export function VerificationModal(_props: VerificationModalProps) {
    return (
        <Stack spacing={2}>
            <Stack spacing={1.5}>
                <Typography variant="body1" sx={{ fontSize: "14px", lineHeight: "1.5" }}>
                    We sent a notification to your signed in devices. Open your Linkedin app and tap <b>Yes</b> to
                    confirm your sign-in attempt.
                </Typography>
            </Stack>
        </Stack>
    );
}
