import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { capitalize } from "lodash";

import useMenu from "../../../hooks/useMenu";

export function ExclusionSelector({
    status,
    menuClickHandler,
}: {
    status: string;
    menuClickHandler: (e) => void;
}) {
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();

    const actions = [
        {
            id: nanoid(),
            label: "Include",
            handleClick: () => menuClickHandler("INCLUDE"),
        },
        {
            id: nanoid(),
            label: "Exclude",
            handleClick: () => menuClickHandler("EXCLUDE"),
        },
    ];

    const handleMenuItemClick = (callback: () => void) => {
        handleClose();
        callback();
    };

    const getColor = () => {
        switch (status) {
            case "INCLUDE":
                return "success";
            case "EXCLUDE":
                return "error";
            default:
                return "primary";
        }
    };

    return (
        <>
            <Box flexShrink={0}>
                <Button
                    size="small"
                    sx={{
                        fontSize: 10,
                        height: "22px",
                        minWidth: 48,
                        "& .MuiButton-endIcon": {
                            marginLeft: 0.5,
                        },
                    }}
                    variant="outlined"
                    color={getColor()}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick(e);
                    }}
                    endIcon={<KeyboardArrowDownRoundedIcon />}
                >
                    {status === "ON_HOLD" ? "On hold" : capitalize(status)}
                </Button>
            </Box>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiMenuItem-root": {
                        fontSize: 13,
                        borderRadius: "0.5rem",
                        padding: "6px 10px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    // @ts-ignore
                    e.stopPropagation();
                    handleClose();
                }}
                onBlur={(e) => e.stopPropagation()}
            >
                {actions.map(({ id, label, handleClick }) => {
                    return (
                        <MenuItem
                            key={id}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleMenuItemClick(handleClick);
                            }}
                        >
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
