import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useDebounce from "./useDebounce";

import { SearchStateKeys } from "../store/reducers/search/search.types";
import { DEBOUNCE_DELAY } from "../utils/Constants";

import { useGetTypeAheadOptionsQuery } from "@/store/apis/search/search.api";
import { setOptions } from "@/store/reducers/search/search.slice";
import { typeAheadOptionsMapping } from "@/store/sagas/search.saga";

const disableFetchOptions = [
    "pastCompany",
    "keywords",
    "jobFunction",
    "industry",
    "companyHeadcount",
    "zipRecruiterPastCompany",
    "indeedGeography",
    "isFixedSearch",
    // "monsterGeography",
    "monsterCity",
    "monsterState",
    "zipRecruiterGeography",
] as SearchStateKeys[];

export default function useSearchState(key: SearchStateKeys) {
    const dispatch = useDispatch();
    const [query, setQuery] = useState("");
    const debouncedValue = useDebounce<string>(query, DEBOUNCE_DELAY);

    const canSkip = disableFetchOptions.includes(key) || debouncedValue.length === 0;

    const { data = [], isFetching } = useGetTypeAheadOptionsQuery(
        {
            label: typeAheadOptionsMapping[key],
            value: debouncedValue,
            category: "",
        },
        {
            skip: canSkip,
        }
    );

    useEffect(() => {
        // Skip if the key is in the disableFetchOptions list and loading is not in progress
        if (canSkip || isFetching) return;
        dispatch(setOptions({ key, options: data }));
    }, [canSkip, isFetching, dispatch, key, data]);

    return { isLoading: isFetching, query, setQuery, dispatch };
}
