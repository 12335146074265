import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import { setErrorNotification } from "../../components/Notification/index.reducer";
import { IActionPayload, startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { resetUnsubscribe, setUnsubscribeEmail, unsubscribeEmail } from "./unsubscribe.reducer";
import { IUnsubscribePayload } from "./unsubscribe.types";

function* unsubscribeEmailSaga({ payload }: { payload: IUnsubscribePayload & IActionPayload }): SagaIterator {
    try {
        const reqPayload = {
            token: payload.token,
            reason: payload.reason,
        };

        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/communication/unsubscribe", reqPayload, {
            headers: {
                appType: "web",
                version: 1.0,
                timezone: -330,
            },
        });
        yield put(setUnsubscribeEmail(response?.data || true));
    } catch (error: any) {
        console.error("**unsubscribeEmailSagaError=", error);
        yield put(setErrorNotification(error.message));
        handleException(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(unsubscribeEmail.type, unsubscribeEmailSaga),
    ];
    // @ts-ignore
    yield takeLatest(resetUnsubscribe.type, CancelSagas, tasks);
}
