import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { IChatGptTemplates, ICreateTemplatePayload, IEditTemplatePayload, IRootState } from "./template.types";

import { RootState } from "@/store";
import { IActionPayload } from "../loaders.reducer";

const initialState: IRootState = {
    templateList: [],
    chatgptTemplates: null,
    editMsgPop: false,
    enableEmail: true,
    enableReminderMsg: false,
    enableInMailReminderMsg: false,
    enableConnectionReq: false,
    enableFirstDelay: false,
    enableSecondDelay: false,
    enableThirdDelay: false,
    enableFollowUpEmail: false,
    enableInMail: false,
    enableSMS: false,
    virtualUserId: "",
};

const reducers = {
    resetSwitches(state: typeof initialState) {
        return {
            ...state,
            enableReminderMsg: false,
            enableInMailReminderMsg: false,
            enableConnectionReq: false,
            enableFollowUpEmail: false,
            selectEnableFirstDelay: false,
            enableInMail: false,
            enableEmail: true,
            enableSecondDelay: false,
            virtualUserId:""
        };
    },
    getTemplateList(state: typeof initialState) {},
    setTemplateList(state: typeof initialState, { payload }: { payload: any }) {
        state.templateList = payload;
    },
    setVirtualUserId(state: typeof initialState, { payload }: { payload: any }) {
        state.virtualUserId = payload;
    },
    createNewTemplate(state: typeof initialState, action: PayloadAction<ICreateTemplatePayload>) {},
    editTemplate(state: typeof initialState, action: PayloadAction<IEditTemplatePayload>) {},
    leaveTemplate(state: typeof initialState) {},
    getChatGptTemplates(
        state: typeof initialState,
        { payload }: { payload: { projectId?: string; type: string } & IActionPayload }
    ) {},
    setChatGptTemplates(state: typeof initialState, { payload }: { payload: IChatGptTemplates }) {
        state.chatgptTemplates = payload;
    },
    setEditMsgPopup(state: typeof initialState, { payload }: { payload: boolean }) {
        state.editMsgPop = payload;
    },
    setEnableConnectionReq(state: typeof initialState, action: PayloadAction<boolean>) {
        state.enableConnectionReq = action.payload;
        if (state.enableConnectionReq || state.enableInMail) {
            state.enableFirstDelay = true;
        }
        if (!state.enableInMail && !state.enableConnectionReq) {
            state.enableFirstDelay = false;
        }
    },
    setEnableInMail(state: typeof initialState, action: PayloadAction<boolean>) {
        state.enableInMail = action.payload;
        if (state.enableConnectionReq || state.enableInMail) {
            state.enableFirstDelay = true;
        }

        if (!state.enableInMail && !state.enableConnectionReq) {
            state.enableFirstDelay = false;
        }
    },
    setEnableFollowUpEmail(state: typeof initialState, action: PayloadAction<boolean>) {
        if (state.enableEmail) {
            state.enableFollowUpEmail = action.payload;
        } else {
            state.enableFollowUpEmail = false;
        }
        state.enableThirdDelay = state.enableFollowUpEmail;
    },
    setEnableCurrentFollowUpEmail(
        state: typeof initialState,
        action: PayloadAction<{ index: number; value: boolean }>
    ) {
        // @ts-ignore
        state[`FollowUpEmail-${action.payload.index}`] = action.payload.value;
    },
    setEnableBulkCurrentFollowUpEmail(
        state: typeof initialState,
        action: PayloadAction<{ index: number; value: boolean }[]>
    ) {
        action.payload.forEach((i) => {
            const { index, value } = i;
            // @ts-ignore
            state[`FollowUpEmail-${index}`] = value;
        });
    },
    setEnableCurrentDelay(state: typeof initialState, action: PayloadAction<{ index: number; value: boolean }>) {
        // @ts-ignore
        state[`Delay-${action.payload.index}`] = action.payload.value;
    },
    setEnableSMS(state: typeof initialState, action: PayloadAction<boolean>) {
        state.enableSMS = action.payload;

        state.enableSecondDelay = state.enableSMS;
    },
    setEnableEmail(state: typeof initialState, action: PayloadAction<boolean>) {
        state.enableEmail = action.payload;
        // if email = false then follow-up email must be false
        if (!state.enableEmail) {
            state.enableFollowUpEmail = false;
        }
    },
    setEnableReminderMsg(state: typeof initialState, action: PayloadAction<boolean>) {
        state.enableReminderMsg = action.payload;
    },
    setEnableInMailReminderMsg(state: typeof initialState, action: PayloadAction<boolean>) {
        state.enableInMailReminderMsg = action.payload;
    },
};

export const templatePage = createSlice({
    name: "template",
    initialState,
    reducers,
});

export default templatePage.reducer;

export const {
    setEnableBulkCurrentFollowUpEmail,
    setEnableSMS,
    setEnableInMail,
    getTemplateList,
    setTemplateList,
    createNewTemplate,
    editTemplate,
    leaveTemplate,
    getChatGptTemplates,
    setChatGptTemplates,
    setEditMsgPopup,
    setEnableConnectionReq,
    setEnableFollowUpEmail,
    setEnableEmail,
    setEnableReminderMsg,
    resetSwitches,
    setEnableCurrentDelay,
    setEnableCurrentFollowUpEmail,
    setEnableInMailReminderMsg,
    setVirtualUserId
} = templatePage.actions;

export const selectTemplateList = (state: RootState) => state.template.templateList;
export const selectChatGptTemplates = (state: RootState) => state.template.chatgptTemplates;
export const selectEditMsgPopup = (state: RootState) => state.template.editMsgPop;
export const selectEnableConnectionReq = (state: RootState) => state.template.enableConnectionReq;
export const selectEnableFollowUpEmail = (state: RootState) => state.template.enableFollowUpEmail;
export const isFollowupWithIndexPresent = createSelector(
    [(state: RootState) => state.template, (_: RootState, index: number) => index],
    (state, index) => {
        return `FollowUpEmail-${index}` in state;
    }
);
export const selectCurrentEnableFollowUpEmail = (index: number) => (state: RootState) =>
    // @ts-ignore
    !!state.template[`FollowUpEmail-${index}`];
export const selectCurrentFollowUpDelay = (index: number) => (state: RootState) =>
    // @ts-ignore
    !!state.template[`FollowUpEmail-${index + 1}`];
export const selectEnableEmail = (state: RootState) => state.template.enableEmail;
export const selectEnableReminderMsg = (state: RootState) => state.template.enableReminderMsg;
export const selectEnableInMailReminderMsg = (state: RootState) => state.template.enableInMailReminderMsg;
export const selectEnableFirstDelay = (state: RootState) => state.template.enableFirstDelay;
export const selectEnableSecondDelay = (state: RootState) => state.template.enableSecondDelay;
export const selectTemplateListPerType = (type: string) => (state: RootState) =>
    state.template.templateList.filter(({ type: templateType }) => templateType === type);
export const selectEnableDelay = createSelector(
    [
        (state: RootState) => state.template.enableFirstDelay,
        (state: RootState) => state.template.enableSecondDelay,
        (state: RootState) => state.template.enableThirdDelay,
        (_: RootState, index: number) => index,
    ],
    (firstDelay: boolean, secondDelay: boolean, thirdDelay: boolean, index: number) => {
        // 1: index of first delay, 3: index of second delay, 5: index of third delay
        const delayMapper: { [key: number]: boolean } = {
            1: firstDelay,
            3: secondDelay,
            5: thirdDelay,
        };

        return delayMapper[index] || false;
    }
);
export const selectEnableInMailSwitch = (state: RootState) => state.template.enableInMail;
export const selectEnableSMSSwitch = (state: RootState) => state.template.enableSMS;
export const selectVirtualUserId = (state: RootState) => state.template.virtualUserId;
