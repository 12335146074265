import { getlocalStorage } from "./useLocalStorage";

// Format the arguments to a standard format for comparison and storage in local storage to avoid storing duplicate data for the same query with different argument orders.

const formatArgs = (args: any) => {
    if (Array.isArray(args)) {
        if (args.every((item) => typeof item === "number")) {
            // Sort numbers in descending order
            return [...args].sort((a, b) => b - a);
        } else if (args.every((item) => typeof item === "string")) {
            // Sort strings in ascending order
            return [...args].sort();
        } else if (args.every((item) => typeof item === "object" && !Array.isArray(item))) {
            // Sort objects by keys in ascending order and apply the same to their values
            return args.map((obj) => formatArgs(obj));
        }
    } else if (args && typeof args === "object" && !Array.isArray(args)) {
        // Sort object keys in ascending order and apply the same to their values
        return Object.keys(args)
            .sort()
            .reduce((sortedObj, key) => {
                sortedObj[key] = formatArgs(args[key]);
                return sortedObj;
            }, {});
    }
    return args;
};

// The useRTKCachedState function is a custom hook that reads the state from local storage and returns it. If the data is not found in the local storage, it returns the default state from the query.

export const useRTKQueryCachedState = <T extends (...args: any[]) => any, Args extends Parameters<T>>(
    config: { reducerPath: string; skip?: boolean },
    queryHook: T,
    ...args: Args
): ReturnType<T> => {
    // Pass the arguments to the queryHook.
    const result = queryHook(...args);

    if (result.status === "pending" && result.endpointName) {
        const args = formatArgs(result.originalArgs);
        const queryCacheArgs = JSON.stringify(args);
        const queryCacheKey = `${result.endpointName}(${queryCacheArgs})`;
        if (!config.skip) {
            const cachedData = getlocalStorage.read(`rtk/${config.reducerPath}/${queryCacheKey}`);
            if (cachedData) {
                result.data = cachedData.data;
                result.isLoading = false;
            }
        }
    }

    return result;
};

export const clearRTKQueryCachedState = () => {
    const data = getlocalStorage.readAll();
    Object.entries(data).forEach(([key, value]) => {
        if (key.startsWith("rtk")) {
            const validity = Date.now() - value.timestamp;
            // if validity is more than 1 day, remove the data
            if (validity > 1000 * 60 * 60 * 24) {
                getlocalStorage.remove(key);
            }
        }
    });
};
