import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import { selectFundingTotalAmountRange, setValues } from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

export function FundingTotalAmountRange({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values } = useSelector(selectFundingTotalAmountRange);

    const defaultValue = { value: "", excluded: false };
    const [start, end] = [
        { ...defaultValue, ...values[0] },
        { ...defaultValue, ...values[1] },
    ];

    const handleChangeStartRange = (value: string) => {
        if (Number(value) > 0) {
            start.value = value;
        } else {
            start.value = "";
        }
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    const handleChangeEndRange = (value: string) => {
        if (Number(value) > 0) {
            end.value = value;
        } else {
            end.value = "";
        }
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    return (
        <Box>
            <Typography color="GrayText" fontSize={14} pb={1}>
                Funding total amount (USD)
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
                <Autocomplete
                    fullWidth
                    freeSolo
                    itemType="number"
                    size="small"
                    options={[]}
                    value={values[0]?.value || ""}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            type="number"
                            size="small"
                            InputLabelProps={{ sx: { fontSize: 14 } }}
                            value={values[0]?.value || ""}
                            onChange={(e) => {
                                handleChangeStartRange(e.target.value || "");
                            }}
                            onClick={(e: any) => {
                                if (e.target?.dataset?.testid === "CloseIcon" || e.target?.nodeName === "path") {
                                    handleChangeStartRange("");
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                sx: { fontSize: 14 },
                                startAdornment: (
                                    <AttachMoneyIcon
                                        sx={{ pointerEvents: "none" }}
                                        fontSize="small"
                                        htmlColor="green"
                                    />
                                ),
                                endAdornment: params.InputProps.endAdornment,
                            }}
                        />
                    )}
                />
                to
                <Autocomplete
                    fullWidth
                    freeSolo
                    itemType="number"
                    size="small"
                    options={[]}
                    value={values[1]?.value || ""}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            type="number"
                            size="small"
                            InputLabelProps={{ sx: { fontSize: 14 } }}
                            value={values[1]?.value || ""}
                            onChange={(e) => {
                                handleChangeEndRange(e.target.value || "");
                            }}
                            onClick={(e: any) => {
                                if (e.target?.dataset?.testid === "CloseIcon" || e.target?.nodeName === "path") {
                                    handleChangeEndRange("");
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                sx: { fontSize: 14 },
                                startAdornment: (
                                    <AttachMoneyIcon
                                        sx={{ pointerEvents: "none" }}
                                        fontSize="small"
                                        htmlColor="green"
                                    />
                                ),
                                endAdornment: params.InputProps.endAdornment,
                            }}
                        />
                    )}
                />
            </Box>
        </Box>
    );
}
