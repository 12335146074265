import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

import { RootState } from "../../store";

const initialState = Object.freeze({
    showUploadCompanyPage: false,
    overview: "",
    companyName: "",
    companyLogo: {
        size: 0,
    },
    companyVideo: {
        size: 0,
    },
    companyVideoLink: "",
    companyPage: {},
});

const reducers = {
    resetState(state: any) {
        state.overview = "";
        state.companyLogo = {
            size: 0,
        };
        state.companyVideo = {
            size: 0,
        };
        state.companyVideoLink = "";
    },
    setShowUploadCompanyPage(state: any, { payload }: { payload: boolean }) {
        state.showUploadCompanyPage = payload;
    },
    setOverview(state: any, { payload }: { payload: string }) {
        state.overview = payload;
    },
    setCompanyName(state: any, { payload }: { payload: string }) {
        state.companyName = payload;
    },
    setCompanyLogo(state: any, { payload }: { payload: any }) {
        state.companyLogo = payload;
    },
    setCompanyVideo(state: any, { payload }: { payload: any }) {
        state.companyVideo = payload;
    },
    setCompanyVideoLink(state: any, { payload }: { payload: string }) {
        state.companyVideoLink = payload;
    },
    updateCompanyPage(state: any, { payload }: { payload: any }) {},
    getCompanyPage(state: any, action: PayloadAction<string>) {},
    setCompanyPage(state: any, { payload }: { payload: any }) {
        state.companyPage = payload;
    },
    leavePage(state: typeof initialState) {},
};

export const pageSlice = createSlice({
    name: "CompanyPage",
    initialState,
    reducers,
});

export default pageSlice.reducer;

export const {
    setShowUploadCompanyPage,
    setOverview,
    setCompanyName,
    setCompanyLogo,
    setCompanyVideo,
    setCompanyVideoLink,
    updateCompanyPage,
    resetState,
    getCompanyPage,
    setCompanyPage,
    leavePage,
} = pageSlice.actions;

export const selectShowUploadCompanyPage = (state: RootState) => state.companyPageReducer.showUploadCompanyPage;
export const selectOverview = (state: RootState) => state.companyPageReducer.overview;
export const selectCompanyName = (state: RootState) => state.companyPageReducer.companyName;
export const selectCompanyLogo = (state: RootState) => get(state, "companyPageReducer.companyLogo[0]");
export const selectCompanyVideoLink = (state: RootState) => get(state, "companyPageReducer.companyVideoLink");
export const selectCompanyVideo = (state: RootState) => get(state, "companyPageReducer.companyVideo[0]");
export const selectCompanyPage = (state: RootState) => get(state, "companyPageReducer.companyPage");
