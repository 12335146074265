import InfoIcon from "@mui/icons-material/Info";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    DialogContent,
    Modal,
    ModalDialog,
    Stack,
    Tooltip,
    Typography,
} from "@mui/joy";
import {
    Divider,
    Experimental_CssVarsProvider,
    Button as MaterialButton,
    Stack as MaterialStack,
    Tooltip as MaterialTooltip,
    Typography as MaterialTypography,
    Popover,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import WorkflowDetails from "./WorkflowDetails";

import CustomButton from "../../../../components/CustomButton";
import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import MembersDropdown from "../../../../components/MembersDropdown";
import { setErrorNotification, setSuccessNotification } from "../../../../components/Notification/index.reducer";
import {
    useGetWorkflowListQuery,
    useRescheduleWorkflowMutation,
} from "../../../../store/apis/linkedin-workflow/linkedin-workflow.api";
import ProjectsDropdown from "../../../analytics-v2/components/ProjectsDropdown";
import { isSuperAdminUser, selectUser } from "../../../Signin/Signin.reducer";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import { useLinkedinWorkflowContext } from "../../Context";
import Scrollable from "../Scrollable";

const WorkflowPane = () => {
    const user = useSelector(selectUser);
    const recruiterStatus = user?.features?.recruiterInMail;
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const [expandedWorkflow, setExpandedWorkflow] = useState<string | null>(null);
    const [checkedWorkflows, setCheckedWorkflows] = useState<Record<number, boolean>>({});
    const [dayValue, setDayValue] = useState<Dayjs | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRecruiterMail, setSelectedRecruiterMail] = useState<boolean>(false);
    const [singleCheckedWorkflowId, setSingleCheckedWorkflowId] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rescheduleWorkflow, { isLoading }] = useRescheduleWorkflowMutation();
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const dispatch = useDispatch();
    const {
        filters: { projectId, userId, setProjectId, setUserId },
    } = useLinkedinWorkflowContext();

    const {
        isFetching,
        isError: isQueryError,
        data,
        refetch,
    } = useGetWorkflowListQuery({
        userId,
        projectId,
    });

    let hour = 0;
    if (dayValue) {
        hour = dayjs(dayValue).hour();
    }

    const handleWorkflowToggle = (workflowId: string) => {
        setExpandedWorkflow(expandedWorkflow === workflowId ? null : workflowId);
    };

    const handleCheckboxToggle = (index: any, workflowId: any, event: any, scheduledFor: any) => {
        event.stopPropagation();
        setCheckedWorkflows((prev) => {
            const newCheckedWorkflows = {
                ...prev,
                [index]: !prev[index],
            };

            // const checkedData = Object.keys(newCheckedWorkflows)
            //     .filter((key) => newCheckedWorkflows[parseInt(key)])
            //     .map((key) => data[parseInt(key)]);

            // const checkedIds = checkedData.map((item) => item?.workflowId);
            // const checkedSelectedRecruiterMail = checkedData.map((item) => item?.recruiterMail);
            // setSelectedRecruiterMail(checkedSelectedRecruiterMail.length === 1 ? checkedSelectedRecruiterMail[0] : null);

            const checkedIds = [];
            for (const key in newCheckedWorkflows) {
                if (!newCheckedWorkflows[parseInt(key)]) continue;
                const workflowId = data[parseInt(key)].workflowId;
                checkedIds.push(workflowId);
            }

            setSingleCheckedWorkflowId(checkedIds.length === 1 ? checkedIds[0] : null);
            if (newCheckedWorkflows[index]) {
                const scheduledDate = dayjs(scheduledFor);
                setDayValue(scheduledDate);
            }

            return newCheckedWorkflows;
        });
    };

    const handleSwitchWorkflow = async () => {
        handleModalClose();
        const checkedWorkflowIds = Object.keys(checkedWorkflows)
            .filter((key) => checkedWorkflows[parseInt(key)])
            .map((key) => data[parseInt(key)].workflowId);
        console.log("Switching workflows:", checkedWorkflowIds[0], checkedWorkflowIds[1]);
        try {
            if (checkedWorkflowIds.length === 2) {
                await rescheduleWorkflow({
                    workflowId: checkedWorkflowIds[0],
                    scheduledFor: data[parseInt(Object.keys(checkedWorkflows)[1])].scheduledFor,
                }).unwrap();
                await rescheduleWorkflow({
                    workflowId: checkedWorkflowIds[1],
                    scheduledFor: data[parseInt(Object.keys(checkedWorkflows)[0])].scheduledFor,
                }).unwrap();
                dispatch(setSuccessNotification("Workflows switched successfully"));
            }
        } catch (error) {
            dispatch(setErrorNotification("Error switching workflows"));
        }
    };

    const handleModalOpen = () => {
        const checkedWorkflowIds = Object.keys(checkedWorkflows)
            .filter((key) => checkedWorkflows[parseInt(key)])
            .map((key) => data[parseInt(key)].workflowId);
        if (checkedWorkflowIds.length === 2) {
            setIsModalOpen(true);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const formatScheduledDate = (dateString: any) => {
        const date = new Date(dateString);
        return dayjs(dateString).format("MMM D, h:mm A");
    };

    if (isQueryError) {
        return <Typography>Error loading data</Typography>;
    }

    const currentCheckedCount = Object.values(checkedWorkflows).filter(Boolean).length;
    const isSingleChecked = currentCheckedCount === 1;

    const handleRescheduleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (isSingleChecked) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleRescheduleWorkflow = async () => {
        setAnchorEl(null);
        setConfirmationModal(false);
        try {
            if (singleCheckedWorkflowId && dayValue) {
                await rescheduleWorkflow({
                    workflowId: singleCheckedWorkflowId,
                    scheduledFor: dayValue.toISOString(),
                }).unwrap();
                dispatch(setSuccessNotification("Rescheduled successfully"));
                refetch();
                setCheckedWorkflows({});
            }
        } catch (error) {
            dispatch(setErrorNotification("Error while rescheduling this LinkedIn outreach!"));
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleDateChange = (newDate: Dayjs | null) => {
        setDayValue(newDate);
    };

    const redirectToProjectPage = (projectId: any) => {
        window.open(`/projects/${projectId}`);
    };

    return (
        <>
            <Scrollable.Container
                sx={{ width: "100%", height: "90vh", padding: 2, overflowY: "auto", backgroundColor: "white" }}
            >
                <Stack direction="row" justifyContent="space-between" sx={{ marginLeft: "30px" }}>
                    <Typography
                        pb={2}
                        display="flex"
                        alignItems="center"
                        component="h2"
                        fontSize={24}
                        fontWeight="600"
                        gap={1}
                        title={"LinkedIn Scheduled Outreach"}
                    >
                        LinkedIn scheduled outreach
                        <Tooltip
                            title="View all your LinkedIn scheduled outreach in one place and you can reschedule them here"
                            sx={{
                                border: "1px solid lightgrey",
                                maxWidth: "25rem",
                                color: "black",
                                backgroundColor: "white",
                            }}
                        >
                            <InfoIcon color="disabled" sx={{ fontSize: "20px" }} />
                        </Tooltip>
                    </Typography>
                </Stack>
                <Stack
                    mb={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginLeft: "30px" }}
                >
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <MembersDropdown value={userId} onChange={setUserId} />
                        <Experimental_CssVarsProvider>
                            <ProjectsDropdown userId={userId} value={projectId} onChange={setProjectId} />
                        </Experimental_CssVarsProvider>
                    </Box>
                    <Box ml="auto">
                        <Experimental_CssVarsProvider>
                            <MaterialTooltip sx={{ fontStyle: "italic" }} title="This feature is in progress.">
                                <CustomButton
                                    disabled={true}
                                    variant="outlined"
                                    sx={{
                                        fontSize: "15.5px",
                                        padding: "0.4rem 1rem",
                                        borderRadius: "0.3rem",
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                        color: "#2196F3",
                                        "&:active": {
                                            opacity: 1,
                                            backgroundColor: "#2196F3",
                                            color: "white",
                                        },
                                        alignSelf: "flex-end",
                                        textTransform: "none",
                                        marginRight: "5px",
                                    }}
                                >
                                    Switch
                                </CustomButton>
                            </MaterialTooltip>

                            <MaterialTooltip
                                sx={{ fontStyle: "italic" }}
                                title="You can only reschedule one outreach at a time."
                            >
                                <CustomButton
                                    onClick={handleRescheduleClick}
                                    disabled={currentCheckedCount !== 1}
                                    variant="outlined"
                                    sx={{
                                        fontSize: "15.5px",
                                        padding: "0.4rem 1rem",
                                        borderRadius: "0.3rem",
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                        color: "#2196F3",
                                        "&:active": {
                                            opacity: 1,
                                            backgroundColor: "#2196F3",
                                            color: "white",
                                        },
                                        alignSelf: "flex-end",
                                        textTransform: "none",
                                    }}
                                >
                                    Reschedule
                                </CustomButton>
                            </MaterialTooltip>
                        </Experimental_CssVarsProvider>
                    </Box>
                </Stack>
                <Divider sx={{ marginBottom: "10px" }} />
                {isFetching || isLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "60vh",
                        }}
                    >
                        <CircularProgress thickness={4} />
                    </Box>
                ) : (
                    <Scrollable.Container
                        sx={{ width: "100%", height: "90vh", padding: 2, overflowY: "auto", backgroundColor: "white" }}
                    >
                        {data?.map((workflow: any, index: number) => (
                            <Accordion
                                key={workflow.workflowId}
                                expanded={expandedWorkflow === workflow.workflowId}
                                onChange={() => handleWorkflowToggle(workflow.workflowId)}
                                sx={{ marginBottom: 2, boxShadow: 3 }}
                            >
                                <AccordionSummary
                                    sx={{
                                        paddingLeft: "3px",
                                        height: "40px",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Tooltip
                                        title={
                                            currentCheckedCount >= 2 && !checkedWorkflows[index]
                                                ? "You cannot select more than 2 workflows to switch"
                                                : ""
                                        }
                                    >
                                        <span>
                                            <Checkbox
                                                sx={{ marginLeft: "10px", marginTop: "6px" }}
                                                checked={checkedWorkflows[index] || false}
                                                onChange={(event) =>
                                                    handleCheckboxToggle(
                                                        index,
                                                        workflow.workflowId,
                                                        event,
                                                        workflow.scheduledFor
                                                    )
                                                }
                                                onClick={(event) => event.stopPropagation()}
                                                disabled={currentCheckedCount >= 2 && !checkedWorkflows[index]}
                                            />
                                        </span>
                                    </Tooltip>
                                    <Typography
                                        sx={{ marginLeft: "5px", fontWeight: "600", fontSize: "18px" }}
                                        onClick={(event) => redirectToProjectPage(workflow.projectId)}
                                    >
                                        {workflow.projectName.length > 30
                                            ? `${workflow.projectName.substring(0, 30)}...`
                                            : workflow.projectName}
                                    </Typography>
                                    {isSuperAdmin && (
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                fontSize: "13px",
                                                fontStyle: "italic",
                                                marginBottom: "-5px",
                                            }}
                                        >
                                            {" "}
                                            ({workflow.projectId}){" "}
                                        </Typography>
                                    )}

                                    <Box sx={{ flexGrow: 1 }} />
                                    {isSuperAdmin && workflow?.recruiterInmail === true && (
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                fontSize: "13px",
                                                fontStyle: "italic",
                                            }}
                                        >
                                            Recruiter
                                        </Typography>
                                    )}
                                    <Typography
                                        sx={{
                                            fontWeight: "500",
                                            fontSize: "13px",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        {workflow.candidateCount}{" "}
                                        {workflow.candidateCount === 1 ? "Candidate" : "Candidates"}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            marginLeft: "5px",
                                            fontWeight: "500",
                                            fontSize: "13px",
                                            fontStyle: "italic",
                                            width: "250px",
                                        }}
                                    >
                                        Scheduled for {formatScheduledDate(workflow.scheduledFor)}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {workflow.workflowId === expandedWorkflow && (
                                        <WorkflowDetails workflow={workflow} />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Scrollable.Container>
                )}

                <Experimental_CssVarsProvider>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <Box sx={{ padding: 2, position: "relative" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* <DateTimePicker value={dayValue} onChange={handleDateChange} disablePast /> */}
                                <MobileDateTimePicker
                                    value={dayValue}
                                    onChange={handleDateChange}
                                    orientation="landscape"
                                    disablePast
                                />
                            </LocalizationProvider>
                            <JoyProvider>
                                <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                                    <Button
                                        color="primary"
                                        //  onClick={handleRescheduleWorkflow}
                                        onClick={() => {
                                            setConfirmationModal(true);
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </JoyProvider>
                        </Box>
                    </Popover>
                </Experimental_CssVarsProvider>

                <Modal open={isModalOpen} onClose={handleModalClose}>
                    <ModalDialog layout="center" sx={{ width: 500 }}>
                        <DialogContent>
                            Please confirm if you want to switch the scheduled dates of the selected workflows.
                        </DialogContent>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button color="primary" onClick={handleSwitchWorkflow}>
                                Yes
                            </Button>
                            <Button sx={{ marginLeft: 2 }} onClick={handleModalClose}>
                                No
                            </Button>
                        </Box>
                    </ModalDialog>
                </Modal>
            </Scrollable.Container>

            <MaterialProvider>
                {confirmationModal && (
                    <BaseModal
                        onClose={() => {
                            setConfirmationModal(false);
                        }}
                        overlayStyles={{
                            padding: "1.7rem 1.5rem",
                            width: 600,
                        }}
                        hideCloseButton={false}
                    >
                        <MaterialTypography sx={{ fontSize: "25px", fontWeight: "600", marginBottom: "10px" }}>
                            Sure you want to reschedule?
                        </MaterialTypography>

                        {selectedRecruiterMail && hour > 17 ? (
                            <MaterialTypography sx={{ fontSize: "14px" }}>
                                This workflow will be scheduled for{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {formatScheduledDate(dayValue.add(1, "day").toISOString())}
                                </span>{" "}
                                (i.e., 24 hrs after your selected scheduled date) on your LinkedIn Recruiter account.
                            </MaterialTypography>
                        ) : (
                            <MaterialTypography sx={{ fontSize: "14px" }}>
                                This workflow will be scheduled for{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {formatScheduledDate(dayValue.toISOString())}
                                </span>{" "}
                                on your LinkedIn {recruiterStatus ? "Recruiter" : ""} account.
                            </MaterialTypography>
                        )}

                        <MaterialStack
                            direction="row"
                            sx={{ alignItems: "center", justifyContent: "flex-end" }}
                            spacing={1}
                            mt={4}
                        >
                            <MaterialButton
                                variant="outlined"
                                sx={{ borderRadius: "4px", fontSize: "12px" }}
                                onClick={() => {
                                    setConfirmationModal(false);
                                }}
                            >
                                Cancel
                            </MaterialButton>
                            <MaterialButton
                                variant="contained"
                                sx={{ borderRadius: "4px", fontSize: "12px" }}
                                onClick={handleRescheduleWorkflow}
                            >
                                Proceed
                            </MaterialButton>
                        </MaterialStack>
                    </BaseModal>
                )}
            </MaterialProvider>
        </>
    );
};

export default WorkflowPane;
