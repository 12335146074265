import { ComponentType, lazy } from "react";

import { hardRefresh } from "./refresh";

// DO NOT REMOVE: This is a list of error messages that are thrown by the import function
// const importErrorMessages = ["load module script", "dynamically imported module", "Unable to preload CSS"];

function extractModuleName(input: string) {
    if (!input) {
        return "";
    }
    const regex = /(?<=import\(["'].*\/)([^\/.]+)(?=\.js|\.ts|["'])|(?<=default:e\.)\w+/;
    const match = input.match(regex);
    return match ? match[0] : "";
}

async function retryImport<T>(importFunction: () => Promise<T>): Promise<T> {
    const moduleName = extractModuleName(importFunction?.toString?.());
    try {
        console.log(`Importing... ${moduleName}`);
        return await importFunction();
    } catch (error: any) {
        console.log(`Failed to import... ${moduleName}`, error?.message);
        const alreadyRefreshed = hardRefresh();
        if (alreadyRefreshed) {
            throw error;
        } else {
            throw "WAITING_FOR_REFRESH";
        }
    }
}

export function lazyImport<T extends { default: ComponentType<any> }>(importFunction: () => Promise<T>) {
    return lazy(() => retryImport(importFunction));
}
