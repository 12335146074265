import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import { Typography } from "@/ui";

const CandidateProfileHeading = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600",
    color: theme.palette.grey[800],
}));

const actionMapping: { [key: string]: string } = {
    REJECTED: "Rejected",
    ACCEPTED: "Accepted",
    PENDING: "Pending",
};

export default function FeedbackTab({ data }: { data: any }) {
    const hmFeedback = data?.hiringManager;

    return (
        <Stack spacing={5} py={2} sx={{ maxHeight: "80vh", overflowY: "auto" }}>
            {hmFeedback && (
                <Stack spacing={1}>
                    <CandidateProfileHeading variant="body2">Feedback</CandidateProfileHeading>

                    <div style={{ width: "fit-content", padding: "10px", borderRadius: "7px" }}>
                        <Typography className="font-semibold  mb-2">
                            <span> Hiring manager feedback </span>
                        </Typography>{" "}
                        <Typography className="font-medium text-[14.5px]">
                            <span> Status: </span>{" "}
                            <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                {actionMapping[hmFeedback?.action]}
                            </span>{" "}
                        </Typography>{" "}
                        <Typography className="font-medium text-[14.5px]">
                            <span> Reviewed on: </span>{" "}
                            <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                {dayjs(hmFeedback?.reviewedAt).format("MMM D, YYYY")}
                            </span>{" "}
                        </Typography>{" "}
                        {hmFeedback?.review?.length > 2 && (
                            <Typography className="font-medium text-[14.5px]">
                                <span> Comment: </span>{" "}
                                <span style={{ fontSize: "14px", fontWeight: "400" }}> {hmFeedback?.review} </span>{" "}
                            </Typography>
                        )}
                        {hmFeedback?.reasons?.length > 0 && hmFeedback?.reasons?.some((reason) => reason !== "Other") && (
                            <>
                                {/* <Typography className="font-medium text-[14.5px]">
                                    <span> Shortlisted because : </span>{" "}
                                </Typography> */}
                                <ul style={{ paddingLeft: "5px" }}>
                                    {hmFeedback?.reasons
                                        .filter((text) => text !== "Other")
                                        .map((text, index) => {
                                            return (
                                                <li key={index}>
                                                    <span
                                                        style={{ fontSize: "14px", fontWeight: "400" }}
                                                    >{`• ${text}`}</span>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </>
                        )}
                    </div>
                </Stack>
            )}
        </Stack>
    );
}
