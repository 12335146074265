import { useState, createContext, useContext } from "react";

export const RichTextEditorContext = createContext({
    isUploadingImage: false,
    setIsUploadingImage: (_isUploadingImage: boolean) => {},
});

export const useRichTextEditorContext = () => useContext(RichTextEditorContext);

export function RichTextEditorContextProvider({ children }) {
    const [isUploadingImage, setIsUploadingImage] = useState(false);

    return (
        <RichTextEditorContext.Provider value={{ isUploadingImage, setIsUploadingImage }}>
            {children}
        </RichTextEditorContext.Provider>
    );
}
