import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { setErrorNotification } from "../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser, updateUser } from "../../Signin/Signin.reducer";

export default function JazzHRConnect({ onClose }: { onClose: () => void }) {
    const user: any = useSelector(selectUser);

    const {
        companyName,
        companyUrl,
        linkedinUrl,
        name,
        outboundEmail,
        userEmail,
        workspaceName,
        role,
        signature,
    }: any = user;
    const isCredsSubmitting = useSelector(checkIfLoading(updateUser.type));

    const dispatch = useDispatch();

    const [JazzHR, setJazzHR] = useState({
        jazzHRFeedURL: user.jazzHR?.jazzHRFeedURL,
        jazzHRAPIKey: user.jazzHR?.jazzAPIKey,
    });


    useEffect(() => {
        setJazzHR({
            jazzHRFeedURL: user.jazzHR?.jazzHRFeedURL ?? "",
            jazzHRAPIKey: user.jazzHR?.jazzHRAPIKey ?? "",
        });
    }, [user.jazzHR]);


    const handleSubmit = () => {
        if (JazzHR.jazzHRAPIKey?.length === 0) {
            dispatch(setErrorNotification("Please enter jazzHR API Key "));
            return;
        }
        if (JazzHR.jazzHRFeedURL?.length === 0) {
            dispatch(setErrorNotification("Please enter jazzHR Feed URL "));
            return;
        }

        dispatch(
            updateUser({
                name,
                orgName: workspaceName,
                companyName,
                companyLink: companyUrl,
                linkedinUrl,
                authorizeEmailAddress: outboundEmail,
                email: userEmail,
                action: updateUser.type,
                jazzHR: JazzHR,
                onSuccess: onClose,
            })
        );
    };

    return (
        <>
            {/* <Button variant={isJazzHRConnected ? "outlined" : "contained"} onClick={openCredsModal}>
                {isJazzHRConnected ? "Manage" : "Connect"}
            </Button> */}
            <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Connect JazzHR</DialogTitle>
                <DialogContent>
                    {/* <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography fontSize={14} noWrap>
                            Username
                        </Typography>
                        <TextField
                            hiddenLabel
                            size="small"
                            placeholder="Enter Username"
                            value={JazzHR.username}
                            onChange={(e) =>
                                setJazzHR((prev) => ({
                                    ...prev,
                                    username: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                            
                        />
                    </Stack>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography fontSize={14} noWrap>
                            Password
                        </Typography>
                        <TextField
                            hiddenLabel
                            size="small"
                            placeholder="Enter password"
                            value={JazzHR.password}
                            onChange={(e) =>
                                setJazzHR((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                           
                        />
                    </Stack> */}
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography fontSize={14} noWrap>
                            JazzHR Feed Url
                        </Typography>
                        <TextField
                            hiddenLabel
                            size="small"
                            placeholder="Enter JazzHR Feed Url"
                            value={JazzHR.jazzHRFeedURL}
                            onChange={(e) =>
                                setJazzHR((prev) => ({
                                    ...prev,
                                    jazzHRFeedURL: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography fontSize={14} noWrap>
                            Api Key
                        </Typography>
                        <TextField
                            hiddenLabel
                            size="small"
                            placeholder="Enter JazzHR Api Key"
                            value={JazzHR.jazzHRAPIKey}
                            onChange={(e) =>
                                setJazzHR((prev) => ({
                                    ...prev,
                                    jazzHRAPIKey: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose} sx={{ borderRadius: 1 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} disabled={isCredsSubmitting} sx={{ borderRadius: 1 }}>
                        <ButtonTextWithLoading text="Submit" isLoading={isCredsSubmitting} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}