import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Tooltip from "@mui/joy/Tooltip";

import MessageInfoDropdown from "./MessageInfoDropdown";

import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import { EUser } from "../../../../store/apis/super-inbox/super-inbox.enums";
import { TUser } from "../../../../store/apis/super-inbox/super-inbox.types";
import styles from "../../superinbox.module.scss";
import { formatMessageDate } from "../../utils";
import { ChannelProps } from "../channels/ChannelTabs";

import { cn } from "@/lib/utils";
import { Typography } from "@/ui";

interface ChatBubbleProps {
    variant: "sent" | "received";
    body: string;
    time: string;
    senderType: TUser;
    candidateStatus: string;
    isMessageFailed: boolean;
    isQueued: boolean;
    candidateProfile: ChannelProps["candidateProfile"];
    theme: "light" | "dark";
    fullWidth?: boolean;
    from?: string;
    to?: string;
}

const themeConfig: { [key in ChatBubbleProps["theme"]]: { bgColor: string; textColor: string; border: string } } = {
    light: {
        border: "1.5px solid #86b2b2",
        bgColor: "white",
        textColor: "var(--joy-palette-text-primary)",
    },
    dark: {
        border: "none",
        bgColor: "rgb(8 145 178)",
        textColor: "var(--joy-palette-common-white)",
    },
};

export default function ChatBubble(props: ChatBubbleProps) {
    const {
        body,
        time,
        senderType,
        candidateStatus,
        isMessageFailed,
        isQueued,
        candidateProfile,
        theme,
        fullWidth,
        from,
        to,
    } = props;

    const isYou = senderType === EUser.USER;

    return (
        <div
            className="w-auto"
            style={{
                maxWidth: fullWidth ? "100%" : "60%",
                minWidth: fullWidth ? "100%" : "auto",
            }}
        >
            <div className="flex flex-row justify-between space-x-2 mb-[0.25rem]">
                <div className="flex items-center">
                    <HoverTruncateText maxWidth={250}>
                        {isYou ? "You" : from ?? candidateProfile?.name}
                    </HoverTruncateText>
                    {(from || to) && (
                        <MessageInfoDropdown
                            options={[
                                ...(from ? [{ label: "from", value: from }] : []),
                                ...(to ? [{ label: "to", value: to }] : []),
                            ]}
                        />
                    )}
                </div>
                <Typography className="text-xs self-center">
                    {isQueued && `${candidateStatus} • `}
                    {formatMessageDate(time, true)}
                </Typography>
            </div>

            <div className="relative">
                <div
                    className={cn("p-3 rounded-lg", {
                        "rounded-tr-none rounded-tl-lg": isYou,
                        "rounded-tl-none rounded-tr-lg": !isYou,
                    })}
                    style={{
                        backgroundColor: isYou ? themeConfig[theme].bgColor : "white",
                        border: isYou ? themeConfig[theme].border : "1px solid #0891B2",
                    }}
                >
                    <div
                        className={styles["chartBubble"]}
                        style={{
                            color: isYou ? themeConfig[theme].textColor : "var(--joy-palette-text-primary)",
                            fontSize: 14,
                            wordBreak: "break-word",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: body === null ? "<i>(Document received)</i>" : body?.replaceAll("\n", "<br>"),
                        }}
                    />
                </div>
                {isMessageFailed && (
                    <div
                        className={`absolute top-1/2 flex flex-row ${isYou ? "justify-end left-0" : "justify-start right-0"} space-x-2 p-1.5`}
                        style={{
                            transform: isYou ? "translate(-100%, -50%)" : "translate(100%, -50%)",
                        }}
                    >
                        <Tooltip variant="soft" color="danger" title="Message failed to deliver">
                            <button className="bg-transparent text-red-600 p-2 rounded hover:bg-red-100 focus:outline-none">
                                <ErrorRoundedIcon style={{ color: "#E53935" }} />
                            </button>
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    );
}
