import CloseIcon from "@mui/icons-material/Close";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Input,
    Modal,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { ReactNode, useState } from "react";

import RichTextEditor from "../RichTextEditor/RichTextEditor";

interface EditorProps {
    subject?: string;
    title: string;
    info?: ReactNode;
    show?: boolean;
    setShow: (show: boolean) => void;
    onSubmit?: (body: string, fromEmail: string[], toEmail: string[]) => void;
    loading?: boolean;
}

export function MailModal(props: EditorProps) {
    const { show, setShow, subject, onSubmit, title, loading } = props;
    const [body, setBody] = useState("");
    const [fromEmail, setFromEmail] = useState<string[]>([""]);
    const [toEmail, setToEmail] = useState<string[]>([""]);

    const handleSend = () => {
        onSubmit?.(body, fromEmail, toEmail);
        setBody("");
    };

    const isBodyEmpty = (body: string) => {
        const trimmedBody = body.trim();
        return !trimmedBody || trimmedBody === "<p><br></p>";
    };

    const isSendDisabled = isBodyEmpty(body);

    return (
        <>
            {show && (
                <Modal
                    open={show}
                    onClose={() => setShow(true)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}
                >
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            width: "600px",
                            position: "relative",
                            backgroundColor: "white",
                            borderRadius: "8px",
                        }}
                    >
                        <IconButton
                            onClick={() => setShow(false)}
                            sx={{
                                position: "absolute",
                                top: 2,
                                right: 8,
                                color: "blue",
                                marginBottom: "40px",
                            }}
                        >
                            <CloseIcon style={{ color: "#0c6bcc" }} />
                        </IconButton>

                        <Typography sx={{ fontWeight: 500 }}>Write your email</Typography>

                        <Autocomplete
                            freeSolo
                            multiple
                            options={[]}
                            onChange={(_, value) => setFromEmail(value)}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" label="From email" placeholder="From email" />
                            )}
                        />

                        <Autocomplete
                            freeSolo
                            multiple
                            options={[]}
                            onChange={(_, value) => setToEmail(value)}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" label="To email" placeholder="To email" />
                            )}
                        />

                        {subject ? (
                            <Input
                                placeholder="Subject"
                                value={subject}
                                sx={{ marginTop: "5px", color: "rgb(106 106 106)" }}
                                disabled
                            />
                        ) : (
                            <Box sx={{ marginTop: "25px" }}></Box>
                        )}

                        <RichTextEditor
                            enableImageModule
                            style={{ height: "300px" }}
                            value={body}
                            onChange={(value) => setBody(value)}
                        />

                        <Box sx={{ display: "flex", justifyContent: "right", marginTop: "50px" }}>
                            <Tooltip title={isSendDisabled ? "Please enter some text to send" : ""}>
                                <span>
                                    <Button
                                        onClick={handleSend}
                                        variant="outlined"
                                        disabled={isSendDisabled || loading}
                                        sx={{ borderRadius: "5px", minWidth: "100px" }}
                                    >
                                        {loading ? <CircularProgress size={20} /> : `Send ${title}`}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                </Modal>
            )}
        </>
    );
}
