import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    checkLinkedinScrapeModalOpen,
    closeLinkedinScrapingModal,
    selectFromPage,
    selectLinkedinUrl,
    selectProfileCount,
    setFromPage,
    setLinkedinUrl,
    setProfileCount,
} from "../../store/reducers/linkedin-scraping/linkedin-scraping.slice";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { submitSearch } from "../../store/reducers/search/search.slice";
import { validateLinkedinSalesNavUrl } from "../../utils/validateLinkedinUrl";

export function LinkedinScrapingModal() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const linkedinUrl: string = useSelector(selectLinkedinUrl);
    const profileCount: number = useSelector(selectProfileCount);
    const fromPage: number = useSelector(selectFromPage);
    const isModalOpen: boolean = useSelector(checkLinkedinScrapeModalOpen);
    const isValidLinkedinUrl = linkedinUrl ? validateLinkedinSalesNavUrl(linkedinUrl) : true;
    const isPreviewing = useSelector(checkIfLoading("addSalesNavUrlProfiles"));
    const isSubmitting = useSelector(checkIfLoading(submitSearch.type));
    const handleSubmitScrapingModalForm = (isPreview: boolean) => dispatch(submitSearch({ navigate, isFromLinkedinScraperModal: true, addSalesNavUrlProfiles: isPreview }));

    const onProceed = (isPreview = false) => {
        if (!linkedinUrl || !isValidLinkedinUrl) return;
        handleSubmitScrapingModalForm(isPreview);
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={() => {
                dispatch(closeLinkedinScrapingModal());
            }}
        >
            <DialogTitle>Upload linkedin Salesnav url</DialogTitle>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onProceed();
                }}
            >
                <DialogContent>
                    <Stack gap={2}>
                        <TextField
                            required
                            size="small"
                            placeholder="Linkedin salesnav url"
                            value={linkedinUrl}
                            fullWidth
                            onChange={(e) => dispatch(setLinkedinUrl(e.target.value))}
                            error={!isValidLinkedinUrl}
                            {...(!isValidLinkedinUrl && { helperText: "Invalid linkedin salesnav url" })}
                        />
                        <Stack flexDirection="row">
                            <Stack sx={{ flex: 1 }}>
                                <TextField
                                    required
                                    size="small"
                                    placeholder="From page"
                                    type="number"
                                    value={fromPage}
                                    onChange={(e) => dispatch(setFromPage(parseInt(e.target.value)))}
                                    sx={{ marginRight: "15px" }}
                                    label="From page"
                                    inputProps={{ min: 1 }}
                                />
                            </Stack>
                            <Stack sx={{ flex: 1 }}>
                                <TextField
                                    required
                                    size="small"
                                    placeholder="Profile count"
                                    type="number"
                                    value={profileCount}
                                    onChange={(e) => dispatch(setProfileCount(parseInt(e.target.value)))}
                                    label="Profile count"
                                    inputProps={{ min: 1 }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            dispatch(closeLinkedinScrapingModal());
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="outlined"
                        disabled={isPreviewing}
                        onClick={() => {
                            onProceed(true);
                        }}
                    >
                        {isPreviewing ? "Previewing..." : "Preview"}
                    </Button>
                    <Button onClick={() => onProceed(false)} variant="contained" disabled={isSubmitting}>
                        {isSubmitting ? "Processing..." : "Proceed"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}