import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";

import {
    handleChangeRowsPerPage,
    handlePageChange,
    selectPage,
    selectRows,
    selectRowsPerPage,
} from "../contact-list.slice";

export default function ContactListPagination() {
    const dispatch = useDispatch();
    const rows = useSelector(selectRows);
    const rowsPerPage = useSelector(selectRowsPerPage);
    const page = useSelector(selectPage);

    return (
        <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => dispatch(handlePageChange(newPage))}
            onRowsPerPageChange={(e) => dispatch(handleChangeRowsPerPage(e.target.value))}
        />
    );
}
