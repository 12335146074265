import { createContext, useContext } from "react";

import usePersonalizeValues from "./hooks/usePersonalizeValues";

type TemplateEditorContextType = ReturnType<typeof usePersonalizeValues>;

const TemplateEditorContext = createContext<TemplateEditorContextType | null>(null);

export function TemplateEditorProvider({ children }: { children: React.ReactNode }) {
    const { ...rest } = usePersonalizeValues();
    return <TemplateEditorContext.Provider value={{ ...rest }}>{children}</TemplateEditorContext.Provider>;
}

export function useTemplateEditorValues() {
    const templateEditorValues = useContext(TemplateEditorContext);

    if (!templateEditorValues) {
        throw new Error("useTemplateEditorValues has to be used within <TemplateEditorContext.Provider>");
    }

    return templateEditorValues;
}
