import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import CustomDateRangePicker from "../../../molecules/CustomDateRangePicker/CustomDateRangePicker";
import { TDate } from "../../../molecules/DateRangePicker/DateRangePicker";
import { selectLastFundingDateRange, setValues } from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

const getOptions = () => {
    const today = dayjs();

    return [
        { label: "Past 30 Days", value: today.subtract(30, "days") },
        { label: "Past 60 Days", value: today.subtract(60, "days") },
        { label: "Past 90 Days", value: today.subtract(90, "days") },
        { label: "Past Year", value: today.subtract(1, "year") },
    ];
};

export function LastFundingDateRange({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values } = useSelector(selectLastFundingDateRange);

    const startDate = values[0]?.value ? dayjs(values[0]?.value) : null;
    const endDate = values[1]?.value ? dayjs(values[1]?.value) : null;

    const handleDateChange = (value) => {
        const [start, end] = value;

        dispatch(
            setValues({
                key: id,
                value: [
                    { value: start, excluded: false },
                    { value: end, excluded: false },
                ],
            })
        );
    };

    return (
        <Box>
            <Typography color="GrayText" fontSize={14} pb={1}>
                Last Funding Date Range
            </Typography>
            <CustomDateRangePicker
                onChange={handleDateChange}
                value={[startDate as TDate, endDate as TDate]}
                options={getOptions()}
                selectProps={{ sx: { maxWidth: endDate ? 100 : "100%", height: 36 } }}
                rangePickerProps={{
                    disableHighlightToday: true,
                    clearable: true,
                    buttonProps: {
                        sx: { height: 36 },
                    },
                }}
            />
        </Box>
    );
}
