import { useEffect, useState } from "react";

interface SleepProps {
    delay?: number;
    children: JSX.Element | null;
    fallback: JSX.Element | null;
}

export default function Sleep({ delay = 3000, children = null, fallback = null }: SleepProps) {
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(true);
        }, delay);

        return () => clearTimeout(timer);
    }, [delay]);

    return showContent ? children : fallback;
}
