import { useMemo } from "react";

import { getActions, getConnection } from "./db";
import { StoreName } from "./interface";

async function initializeIndexedDB() {
    return new Promise<void>(async (resolve, reject) => {
        try {
            await getConnection();
            resolve();
        } catch (e) {
            console.error(e);
            const idbInstance = typeof window !== "undefined" ? window.indexedDB : null;
            if (idbInstance) indexedDB.deleteDatabase("HQ"); // in case of version conflict delete database and re-initialize db
            // reject(e);
        }
    });
}

export function useIndexedDBStore<T>(storeName: StoreName) {
    const _actions = useMemo(() => getActions<T>(storeName), [storeName]);
    return _actions;
}

export default initializeIndexedDB;
