import { Box } from "@mui/joy";
import Tooltip from "@mui/joy/Tooltip";
import Typography, { TypographyProps } from "@mui/joy/Typography";
import { useEffect, useRef, useState } from "react";

import { JoyProvider } from "../components/JoyProvider";

interface HoverTruncateTextProps extends TypographyProps {
    tooltipPlacement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
    placeholderText?: string;
    stopPropagation?: boolean;
}

function HoverTruncateText(props: HoverTruncateTextProps) {
    const {
        maxWidth = 200,
        children,
        tooltipPlacement = "top",
        placeholderText,
        stopPropagation = true,
        ...rest
    } = props;
    const [openTooltip, setOpenTooltip] = useState(false);
    const boxRef = useRef<HTMLElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const checkTrunncated = () => {
            const rect = boxRef?.current?.getBoundingClientRect();
            const width = rect?.width;
            setIsTruncated(Number(width + 10) >= Number(maxWidth));
        };
        checkTrunncated();
    }, [boxRef.current, maxWidth]);

    return (
        <JoyProvider>
            <Tooltip
                placement={tooltipPlacement}
                variant="outlined"
                arrow
                open={openTooltip}
                title={children}
                onClose={() => setOpenTooltip(false)}
                onClick={(event) => stopPropagation && event.stopPropagation()}
                sx={{ maxWidth }}
            >
                <Box display="flex" gap={0.2} alignItems="center">
                    <Typography
                        level="title-sm"
                        display="inline-block"
                        maxWidth={maxWidth}
                        ref={boxRef}
                        onMouseEnter={(_event) => {
                            if (isTruncated) {
                                setOpenTooltip(true);
                            }
                        }}
                        onMouseLeave={(_event) => {
                            if (isTruncated) {
                                setOpenTooltip(false);
                            }
                        }}
                        noWrap={isTruncated}
                        {...rest}
                        //
                        sx={{
                            paddingRight: "5px",
                            paddingLeft: "5px",
                            fontWeight: 400,
                            ...rest?.sx,
                        }}
                    >
                        {isTruncated && placeholderText ? placeholderText : children}
                    </Typography>
                </Box>
            </Tooltip>
        </JoyProvider>
    );
}

export default HoverTruncateText;
