import Quill from 'quill';

export const applyDoubleCurlyFormat = (editorRef: any, run: boolean) => {
    if (!run) return;
    if (!editorRef.current) return;

    const quill = editorRef.current.getEditor();
    const text = quill.getText();
    const selection = quill.getSelection();

    // Reset background color and tooltip for the entire text
    quill.formatText(0, text.length, { "background-color": false, tooltip: false }, "silent");

    // Apply formatting to double curly matches
    const regex = /{{[^{}]*}}/g;
    let match;
    while ((match = regex.exec(text)) !== null) {
        const matchStart = match.index;
        const matchLength = match[0].length;
        quill.formatText(matchStart, matchLength, { "background-color": "#BBE9FF", tooltip: "AI Generated" }, "silent");
    }

    if (selection) {
        quill.setSelection(selection, "silent");
    }
};