import { CSSProperties } from "react";

import { Modal } from "@/ui";

// const styles = `
//     absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px]
//     bg-white p-8 shadow-lg rounded-lg border-none outline-none
//     max-h-[90vh] md:zoom-[0.8]
// `;

type Props = {
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    children: React.ReactNode;
    overlayStyles?: CSSProperties;
    hideCloseButton?: boolean;
    hiddenButton?: boolean;
    className?: string;
    backdrop?: boolean;
};

export default function BaseModal(props: Props) {
    const { children, overlayStyles, onClose, hideCloseButton, hiddenButton, backdrop = false, className } = props;
    return (
        <Modal
            open={true}
            onClose={() => {
                onClose();
            }}
            className={className}
            style={overlayStyles}
            disableBackdropClick={backdrop}
            showClose={!hideCloseButton}
            hiddenButton={hiddenButton}
        >
            {children}
        </Modal>
    );
}
