import { Stack, StackProps, styled } from "@mui/material";
import { TypeAnimation } from "react-type-animation";

const CustomStack = styled((props: StackProps) => <Stack {...props} />)(() => ({
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
}));

export function TypingLoaderCompanies() {
    return (
        <CustomStack>
            <TypeAnimation
                sequence={[
                    "Analyzing your search query…",
                    2000,
                    "Understanding your company persona…",
                    2000,
                    "Building initial AI-powered searches across the web…",
                    2000,
                    "Still searching…",
                    2000,
                    "Preparing your initial company list...",
                    2000,
                ]}
                speed={50}
                style={{
                    fontWeight: 500,
                    color: "#479bd2",
                }}
            />
        </CustomStack>
    );
}
