import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import CustomButton from "../../../components/CustomButton";
import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import { RootState, useAppDispatch } from "../../../store";
import { useTriggerTestEmailMutation } from "../../../store/apis/outreach/outreach.api";
import { selectUser } from "../../Signin/Signin.reducer";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { selectCurrentOutreachElement, selectCurrentOutreachElementType } from "../outreach.slice";

export function TestEmailModal({ onClose }: { onClose: () => void }) {
    const user = useSelector(selectUser);
    const dispatch = useAppDispatch();
    const [triggerTestEmailMutation, { isLoading, ...saveMutationState }] = useTriggerTestEmailMutation();
    const { currentOutreachElementId, candidateId, projectId } = useOutreachParams();
    const currentOutreachElement = useSelector((state: RootState) =>
        selectCurrentOutreachElement(state, { templateId: currentOutreachElementId })
    );

    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const testEmail = () => {
        if (currentElementType !== "email" && currentElementType !== "follow-up") return;
        const body = currentOutreachElement.eventBody.body;
        const subject = (currentOutreachElement.eventBody as any).subject;
        const attachments = currentOutreachElement.attachments;

        triggerTestEmailMutation({
            email: user.email,
            projectId: projectId,
            candidateId: candidateId,
            subject: subject,
            body: body,
            attachments: attachments,
        })
            .unwrap()
            .then((res: any) => {
                dispatch(setSuccessNotification(res.message || "Test email sent successfully"));
                onClose();
            })
            .catch(() => {
                dispatch(setErrorNotification("Error in sending test email, please try again after some time"));
            });
    };

    return (
        <BaseModal onClose={onClose}>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography sx={{ fontSize: "25px" }} variant="h6">
                        Trigger a test email
                    </Typography>
                    <Typography variant="body2">
                        A test email for the selected candidate will be sent to your email address{" "}
                        <span style={{ fontWeight: 500, color: "#2196F3", fontStyle: "italic" }}>{user.email}</span> .
                        Do you want to send this?
                    </Typography>
                </Stack>

                <Stack gap={1.3} flexDirection="row" mt={1}>
                    <CustomButton
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                        }}
                    >
                        No, do not send
                    </CustomButton>
                    <CustomButton
                        onClick={() => testEmail()}
                        disabled={isLoading}
                        variant="contained"
                        sx={{
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                        }}
                    >
                        {isLoading ? "Loading..." : "Yes, send email"}
                    </CustomButton>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
