import AddchartIcon from "@mui/icons-material/Addchart";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckIcon from "@mui/icons-material/Check";
// for active
import CloseIcon from "@mui/icons-material/Close";
import ContactMailRoundedIcon from "@mui/icons-material/ContactMailRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import DoneIcon from "@mui/icons-material/Done";
import EmailIcon from "@mui/icons-material/Email";
// for invalid
import FmdGoodRoundedIcon from "@mui/icons-material/FmdGoodRounded";
// for unknown
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import Person4Icon from "@mui/icons-material/Person4";
import SpeedIcon from "@mui/icons-material/Speed";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import TextsmsRoundedIcon from "@mui/icons-material/TextsmsRounded";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import WorkHistoryRoundedIcon from "@mui/icons-material/WorkHistoryRounded";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Badge, Chip as JoyChip, ChipProps as JoyChipProps, Tooltip as JoyTooltip } from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import {
    Avatar,
    Box,
    BoxProps,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
    Link as MuiLink,
    Skeleton,
    Stack,
    StackProps,

    Typography,
    styled,
} from "@mui/material";
import dayjs from "dayjs";
import {Tooltip} from "@/ui"
import { get, isEmpty, isNil, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import style from "./candidateProfileCard.module.scss";

import googleScholarLogo from "../../../../assets/img/ai-providers-logo/google-scholar.png";
import houseLogo from "../../../../assets/img/ai-providers-logo/home.png";
import githubLogo from "../../../../assets/img/email-providers-logo/githubIcon.png";
import CopyableText from "../../../../atoms/CopyableText";
import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import { ContactTypeTooltip } from "../../../../components/ContactTypeTooltip";
import { JoyProvider } from "../../../../components/JoyProvider";
import Loader from "../../../../components/Loader/Loader";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import Nudge from "../../../../components/Nudges/Nudges";
import {
    CANDIDATE_STAGE_ITEMS_MUI,
    blueTickIcon,
    linkedInLogo,
    notInterestedIcon,
    shortlistedPlus,
} from "../../../../constant/Constant";
import useMenu from "../../../../hooks/useMenu";
import useWindowSize from "../../../../hooks/useWindowSize";
import {
    TContactType,
    useGetCandidateSourceTypesQuery,
    useGetOutreachTimestampsQuery,
} from "../../../../store/apis/projects/projects.api";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { csReviewStages, reviewStageObj } from "../../../../utils/Constants";
import { DetailedProjectStage } from "../../../all-candidates-reachout/components/ContactInfo";
import EditCandidate from "../editCandidate/EditCandidate";
import Experience from "../Experience";
import { trimText } from "../exportCandidates/pdfDataTemplate";
import FeedbackForm from "../FeedBackModule/FeedBackModule";
import LogActivity from "../logActivity/LogActivity";
import NA from "../NA";
import SendosoGift from "../SendosoModule/Sendoso";
import Skills from "../skillsSection/SkillsSection";
import Stage, { StageType } from "../Stage";
import TwilioLogs from "../TwilloModule/twilloLogs";
import TwilioVoice from "../TwilloModule/twilloModal";

import { selectCurrProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { selectInstallExtensionNudge, selectShowEmailNudge } from "@/store/reducers/nudges/Nudges.reducer";
import {
    changeCandidatePrimaryEmail,
    getCandidateEmail,
    moveTo,
    selectProjectCandidates,
    selectSelectedCandidates,
    setCandidateDetailedProjectStatus,
    setCandidates,
} from "@/store/reducers/project/project.reducer";
import {
    CS_STATUS,
    ConnectionRequestStatus,
    EmailStatus,
    FollowUpEmailStatus,
    ICandidate,
    IEditCandidatePayload,
    InEmailStatus,
    OutReachStatus,
    ReviewStage,
    SMSStatus,
    SmsStatusLables,
    connectionRequestLabels,
    emailStatusLabels,
    followupEmailStatusLabels,
    inMailLabels,
} from "@/store/reducers/project/project.types";
import {
    checkEasyGrowth,
    checkSendosoIntegrated,
    isSuperAdminUser,
    selectUser,
    selectUserOrgId,
} from "@/store/reducers/signin/Signin.reducer";

const SkeletonUI = () => {
    return <Skeleton variant="text" sx={{ fontSize: "1rem", marginLeft: 1 }} width={100} />;
};

type CandidateProfileCardProps = {
    variant: "card" | "modal";
    projectId?: string;
    index?: number;
    candidate: ICandidate;
    // children?: React.ReactNode;
    handleSeeMore: () => void;
    onEditCandidate?: (candidate: IEditCandidatePayload) => void;
};

const CandidateCardLabelsStack = styled(({ spacing, alignItems, direction, ...rest }: StackProps) => (
    <Stack spacing={1} alignItems="center" direction="row" {...rest} />
))();

const BottomTooltip = (props) => <Tooltip placement="bottom" {...props} />;

const GridBox = styled(Box)<BoxProps>(() => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
}));

type StatusMenuProps = {
    variant?: "card" | "modal";
    stage?: string;
    candidateId?: string;
    feedback?: object;
    handleStatusChange: (type: ReviewStage, shortlist1?: boolean) => void;
};
type StatusMenuAnimButtonProps = {
    stage: string;
    handleChangeStatus: (type: ReviewStage, shortlist1?: boolean) => void;
    isDefaultStageShortlisPlus?: boolean;
    candidateId?: string;
    feedback?: object;
};

interface IconRendererProps {
    iconName: string;
}

type IconType = React.ComponentType | JSX.Element;

const iconMap: Record<string, IconType> = {
    NOT_INTERESTED: <img src={notInterestedIcon} alt="icon" width={32} color="grey" />,
    REJECTED: ThumbDownIcon,
    SHORTLISTED: ThumbUpAltIcon,
    TO_BE_REVIEWED: ThumbUpOffAltIcon,
    "Shortlisted++": <img src={shortlistedPlus} alt="icon" width={20} />,
};
const IconRenderer: React.FC<IconRendererProps> = ({ iconName }) => {
    const IconComponent = iconMap[iconName];

    if (!IconComponent) {
        return null;
    }

    // Render the icon appropriately
    if (React.isValidElement(IconComponent)) {
        // If IconComponent is a JSX element (like <img>)
        return React.cloneElement(IconComponent);
    } else {
        // If IconComponent is a React component (like ThumbDownIcon)
        const SvgIconComponent = IconComponent as React.ComponentType<{ style?: React.CSSProperties }>;
        return <SvgIconComponent />;
    }
};

const CandidateStatusMap: { [key: string]: string } = {
    "Not Interested": "NOT_INTERESTED",
    Rejected: "REJECTED",
    Shortlisted: "SHORTLISTED",
    "To Be Reviewed": "TO_BE_REVIEWED",
    "Shortlisted++": "Shortlisted++",
};

export function StatusMenuAnimButton({
    handleChangeStatus,
    stage,
    isDefaultStageShortlisPlus,
    candidateId,
    feedback,
}: StatusMenuAnimButtonProps) {
    const { t } = useTranslation();
    const [currentStage, setCurrentStage] = useState(
        isDefaultStageShortlisPlus ? "Shortlisted++" : CandidateStatusMap[stage]
    );
    const [isAnyStageSelected, setIsAnyStageSelected] = useState(CandidateStatusMap[stage] !== "TO_BE_REVIEWED");

    useEffect(() => {
        setCurrentStage(isDefaultStageShortlisPlus ? "Shortlisted++" : CandidateStatusMap[stage]);
        setIsAnyStageSelected(CandidateStatusMap[stage] !== "TO_BE_REVIEWED");
    }, [candidateId, stage]);

    const handleStatusChangeToDeafault = (type: string) => {
        setIsAnyStageSelected(false);
        handleChangeStatus(type as ReviewStage, false);
        setCurrentStage("SHORTLISTED");
    };

    const handleStatusChange = (type: string) => {
        const isShortlistedPlusPlus: boolean = type === "Shortlisted++";
        if (isShortlistedPlusPlus) {
            handleChangeStatus("SHORTLISTED", isShortlistedPlusPlus);
            setCurrentStage("Shortlisted++");
        } else {
            handleChangeStatus(type as ReviewStage, isShortlistedPlusPlus);
            setCurrentStage(type);
        }
        setIsAnyStageSelected(true);
    };

    const getBackgroundColor = (stage: string) => {
        switch (stage) {
            case "REJECTED":
                return "#eb7c7c8b"; // Red
            case "SHORTLISTED":
                return "#45ae4a8b"; // Green
            case "NOT_INTERESTED":
                return "#e7ecf0"; // Grey
            case "TO_BE_REVIEWED":
                return "";
            default:
                return "#FFD700"; // Golden
        }
    };
    return (
        <>
            <div
                style={{ position: "relative", display: "flex" }}
                className={style["statusMenuContainer"]}
                onClick={(e) => e.stopPropagation()}
            >
                <JoyProvider>
                    <Box
                        sx={{
                            width: "32px",
                            alignContent: "center",
                            borderRadius: "50%",
                        }}
                    >
                        {isAnyStageSelected ? (
                            currentStage ? (
                                <JoyTooltip
                                    title={
                                        currentStage !== "NOT_INTERESTED"
                                            ? currentStage.toLowerCase()
                                            : "not interested"
                                    }
                                    placement="bottom"
                                >
                                    <IconButton
                                        size="sm"
                                        onClick={() => handleStatusChangeToDeafault("TO_BE_REVIEWED")}
                                        sx={{
                                            backgroundColor: getBackgroundColor(currentStage),
                                        }}
                                    >
                                        <IconRenderer iconName={currentStage} />
                                    </IconButton>
                                </JoyTooltip>
                            ) : null
                        ) : (
                            <IconButton size="sm" color="neutral">
                                <IconRenderer iconName={"TO_BE_REVIEWED"} />
                            </IconButton>
                        )}
                    </Box>
                    {!isAnyStageSelected && (
                        <div className={style["statusMenuAnimButton"]}>
                            <JoyTooltip title="Not Interested" placement="top">
                                <IconButton size="sm" onClick={() => handleStatusChange("NOT_INTERESTED")}>
                                    {/* <AcUnitIcon /> */}
                                    <img src={notInterestedIcon} alt="icon" width={36} />
                                </IconButton>
                            </JoyTooltip>

                            <JoyTooltip title="Reject" placement="top">
                                <IconButton size="sm" onClick={() => handleStatusChange("REJECTED")}>
                                    <ThumbDownOffAltOutlinedIcon />
                                </IconButton>
                            </JoyTooltip>

                            <JoyTooltip title="Shortlist" placement="top">
                                <IconButton size="sm" onClick={() => handleStatusChange("SHORTLISTED")}>
                                    <ThumbUpOffAltIcon />
                                </IconButton>
                            </JoyTooltip>

                            <JoyTooltip title={`Shortlist++ (select ideal ${t("commonSingular")})`} placement="top">
                                <IconButton size="sm" onClick={() => handleStatusChange("Shortlisted++")}>
                                    {/* <AccessTimeFilledIcon /> */}
                                    <img src={shortlistedPlus} alt="icon" width={18} />
                                </IconButton>
                            </JoyTooltip>
                        </div>
                    )}
                </JoyProvider>
            </div>
            {feedback && <FeedbackForm Reviewstatus={stage} id={candidateId} candidateFeedback={feedback} />}
        </>
    );
}
export function StatusMenu({
    stage = "PENDING",
    handleStatusChange,
    variant = "card",
    candidateId,
    feedback,
}: StatusMenuProps) {
    const { anchorEl, handleClick, handleClose, open, menuSX } = useMenu();
    return (
        <div onClick={(e) => e.stopPropagation()} className={style["statusMenu"]}>
            <Button
                variant="contained"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon sx={{ fontSize: "16px !important" }} />}
                sx={(theme) => ({
                    fontSize: variant === "modal" ? "11px" : "9px",
                    padding: `${theme.typography.pxToRem(3)} ${theme.typography.pxToRem(8)}`,
                    boxShadow: "none",
                    width: "fit-content",
                    alignSelf: "flex-end",
                })}
            >
                {stage}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={menuSX}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {CANDIDATE_STAGE_ITEMS_MUI.map(({ key, label }) => {
                    return (
                        <MenuItem
                            onClick={() => {
                                handleStatusChange(key as ReviewStage, false);
                                handleClose();
                            }}
                            key={key}
                        >
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}

const starIconShouldBeIncluded = (value: number, isEasyGrowth: boolean) => {
    if (isEasyGrowth) return value > -1 && value < 6;
    return value > 0;
};

const TimeStampTooltipTitle = (sentAt: any[], receivedAt: any[], label?: string) => {
    const failedLabels = ["failed", "undelivered", "unsuccessful"];
    const isFailed = failedLabels.some((str) => label?.toLowerCase().includes(str));

    const formattedReceivedAt = receivedAt?.map((obj: any) => {
        return dayjs(obj?.eventTime).format("hh:mm A, MMM DD");
    });
    const formattedSentAt = sentAt?.map((obj: any) => {
        return dayjs(obj?.eventTime).format("hh:mm A, MMM DD");
    });
    return (
        <Stack flexDirection="column" gap={1}>
            {formattedReceivedAt?.length ? (
                <Stack>
                    <strong style={{ color: "#00599a", fontSize: "13px" }}>
                        {label?.includes("accepted") ? "Accepted at: " : "Received at: "}
                    </strong>
                    {formattedReceivedAt?.map((date: string, i) => (
                        <Typography key={`lrec` + i} variant="body2" fontSize="12px">
                            {date}
                        </Typography>
                    ))}
                </Stack>
            ) : null}
            {formattedSentAt?.length ? (
                <Stack>
                    <strong style={{ color: "#00599a", fontSize: "13px" }}>
                        {isFailed ? "Failed at:" : "Sent at:"}{" "}
                    </strong>
                    {formattedSentAt?.map((date: string, i) => (
                        <Typography key={`lsent` + i} variant="body2" fontSize="12px">
                            {date}
                        </Typography>
                    ))}
                </Stack>
            ) : null}
        </Stack>
    );
};

type LinkedInConnReqAndInMailStatusProps = {
    id: string;
    linkedInConnReqStatus?: ConnectionRequestStatus;
    inMailStatus?: InEmailStatus;
    projectId: string;
    outreachStatus: OutReachStatus;
    outreachTimestamps: any;
};

const LinkedinConnectionDropdown = ({
    id,
    projectId,
    outreachStatus,
    status,
    sentAt,
    receivedAt,
}: {
    outreachStatus: OutReachStatus;
    id: string;
    projectId: string;
    status: string;
    sentAt: any[];
    receivedAt: any[];
}) => {
    const dispatch = useDispatch();
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();
    const isSuperAdmin = useSelector(isSuperAdminUser);

    const toShowTooltip = (sentAt && sentAt.length > 0) || (receivedAt && receivedAt.length > 0);

    const linkedInConnActions: {
        label: string;
        value: ConnectionRequestStatus;
    }[] = [
        {
            label: "Request sent with note",
            value: ConnectionRequestStatus.SENT_WITH_NOTE,
        },
        {
            label: "Request sent without note",
            value: ConnectionRequestStatus.SENT_WITHOUT_NOTE,
        },
        {
            label: "Reminder message sent",
            value: ConnectionRequestStatus.MESSAGE_SENT,
        },
        {
            label: "Reminder message failed",
            value: ConnectionRequestStatus.MESSAGE_FAILED,
        },
    ];

    const handleChangeStatus = (id: string, status: ConnectionRequestStatus) => {
        const updatedOutReachStatus = { ...outreachStatus, connection: status };
        dispatch(
            moveTo({
                candidateIds: [id],
                outreachStatus: updatedOutReachStatus,
                projectId: Number(projectId),
            })
        );
        return;
    };

    return (
        <>
            <JoyProvider>
                <JoyTooltip
                    title={toShowTooltip ? TimeStampTooltipTitle(sentAt, receivedAt) : null}
                    placement="right"
                    arrow
                >
                    <MaterialProvider>
                        <Button
                            size="small"
                            sx={{
                                fontSize: 10,
                                height: "22px",
                                width: "fit-content",
                                borderRadius: "2rem",
                                "& .MuiButton-endIcon": {
                                    marginLeft: 0.5,
                                },
                                maxWidth: "150px",
                                marginLeft: "14px",
                                marginBottom: "10px",
                                textTransform: "none",
                            }}
                            variant="outlined"
                            color={`${
                                status === "ACTIVE"
                                    ? "success"
                                    : status === "ON_HOLD"
                                      ? "info"
                                      : status === "CLOSED"
                                        ? "disabled"
                                        : "primary"
                            }`}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClick(e);
                            }}
                            endIcon={<KeyboardArrowDownRoundedIcon />}
                        >
                            {status}
                        </Button>
                    </MaterialProvider>
                </JoyTooltip>
            </JoyProvider>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiMenuItem-root": {
                        fontSize: 13,
                        borderRadius: "0.5rem",
                        padding: "6px 10px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    // @ts-ignore
                    e.stopPropagation();
                    handleClose();
                }}
                onBlur={(e) => e.stopPropagation()}
            >
                {linkedInConnActions.map(({ label, value }) => {
                    return (
                        <MenuItem
                            key={value}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleChangeStatus(id, value);
                            }}
                        >
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

const InMailConnectionDropdown = ({
    projectId,
    outreachStatus,
    id,
    status,
    sentAt,
    receivedAt,
}: {
    outreachStatus: OutReachStatus;
    projectId: string;
    id: string;
    status: string;
    sentAt: any[];
    receivedAt: any[];
}) => {
    const dispatch = useDispatch();
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();
    const toShowTooltip = (sentAt && sentAt.length > 0) || (receivedAt && receivedAt.length > 0);

    const inMailConnActions: {
        label: string;
        value: InEmailStatus;
    }[] = [
        {
            label: "InMail sent",
            value: InEmailStatus.SENT,
        },
    ];

    const handleChangeStatus = (id: string, status: InEmailStatus) => {
        const updatedOutReachStatus = { ...outreachStatus, inMail: status };
        dispatch(
            moveTo({
                candidateIds: [id],
                outreachStatus: updatedOutReachStatus,
                projectId: Number(projectId),
            })
        );
        return;
    };

    return (
        <>
            <JoyProvider>
                <JoyTooltip
                    variant="outlined"
                    title={toShowTooltip ? TimeStampTooltipTitle([sentAt], [receivedAt]) : null}
                    placement="right"
                    arrow
                >
                    <MaterialProvider>
                        <Button
                            size="small"
                            sx={{
                                fontSize: 10,
                                width: "fit-content",
                                borderRadius: "2rem",
                                "& .MuiButton-endIcon": {
                                    marginLeft: 0.5,
                                },
                                maxWidth: "150px",
                                marginLeft: "14px",
                                marginBottom: "10px",
                            }}
                            variant="outlined"
                            color={`${
                                status === "ACTIVE"
                                    ? "success"
                                    : status === "ON_HOLD"
                                      ? "info"
                                      : status === "CLOSED"
                                        ? "disabled"
                                        : "primary"
                            }`}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClick(e);
                            }}
                            endIcon={<KeyboardArrowDownRoundedIcon />}
                        >
                            {status}
                        </Button>
                    </MaterialProvider>
                </JoyTooltip>
            </JoyProvider>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiMenuItem-root": {
                        fontSize: 13,
                        borderRadius: "0.5rem",
                        padding: "6px 10px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    // @ts-ignore
                    e.stopPropagation();
                    handleClose();
                }}
                onBlur={(e) => e.stopPropagation()}
            >
                {inMailConnActions?.map(({ label, value }) => {
                    return (
                        <MenuItem
                            key={value}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleChangeStatus(id, value);
                            }}
                        >
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

function LinkedInConnReqAndInMailStatus({
    id,
    outreachStatus,
    projectId,
    linkedInConnReqStatus,
    inMailStatus,
    outreachTimestamps,
}: LinkedInConnReqAndInMailStatusProps) {
    const isSuperAdmin = useSelector(selectUser)?.isSuperAdmin;
    if (!linkedInConnReqStatus && !inMailStatus) {
        return null;
    }

    const connReqStatusValues = linkedInConnReqStatus ? connectionRequestLabels[linkedInConnReqStatus] : null;

    const inMailStatusValues = inMailStatus ? inMailLabels[inMailStatus] : null;

    const connReqLabel = connReqStatusValues
        ? isSuperAdmin
            ? connReqStatusValues.superAdmin
            : connReqStatusValues.client
        : "";

    const inMailLabel = inMailStatusValues
        ? isSuperAdmin
            ? inMailStatusValues.superAdmin
            : inMailStatusValues.client
        : "";

    // if both resolves to empty string then show nothing
    if (!connReqLabel && !inMailLabel) {
        return null;
    }

    return (
        <CandidateCardLabelsStack sx={{ marginTop: "10px", alignItems: "flex-start", width: "100%" }}>
            <BottomTooltip title="Linkedin Connection Request and InMail Status">
                <ContactMailRoundedIcon
                    sx={{
                        height: "22px",
                        width: "22px",
                        color: "#242222",
                        marginBottom: "10px",
                    }}
                />
            </BottomTooltip>
            <Stack sx={{ marginLeft: "0px !important" }}>
                {connReqLabel ? (
                    isSuperAdmin && (linkedInConnReqStatus === "TRIGGERED" || linkedInConnReqStatus === "FAILED") ? (
                        <LinkedinConnectionDropdown
                            outreachStatus={outreachStatus}
                            id={id}
                            projectId={projectId}
                            status={connReqLabel}
                            sentAt={outreachTimestamps?.connectionSentAt}
                            receivedAt={outreachTimestamps?.connectionRespondedAt}
                        />
                    ) : (
                        <CustomStatusChip
                            label={connReqLabel}
                            sx={{
                                maxWidth: "150px",
                                marginLeft: "14px",
                                marginBottom: "10px",
                            }}
                            sentAt={outreachTimestamps?.connectionSentAt}
                            receivedAt={outreachTimestamps?.connectionRespondedAt}
                        />
                    )
                ) : null}

                {inMailLabel ? (
                    isSuperAdmin && (inMailStatus === "TRIGGERED" || inMailStatus === "FAILED") ? (
                        <InMailConnectionDropdown
                            projectId={projectId}
                            outreachStatus={outreachStatus}
                            id={id}
                            status={inMailLabel}
                            sentAt={outreachTimestamps?.inMailSentAt}
                            receivedAt={outreachTimestamps?.inMailRespondedAt}
                        />
                    ) : (
                        <CustomStatusChip
                            label={inMailLabel}
                            sx={{
                                width: "fit-content",
                                maxWidth: "150px",
                                marginLeft: "14px",
                            }}
                            sentAt={outreachTimestamps?.inMailSentAt}
                            receivedAt={outreachTimestamps?.inMailRespondedAt}
                        />
                    )
                ) : null}
            </Stack>
        </CandidateCardLabelsStack>
    );
}

type EmailAndFollowupStatusProps = {
    emailStatus?: EmailStatus;
    followupStatus?: FollowUpEmailStatus;
    outreachTimestamps: any;
};

const CustomStatusChip = styled((props: JoyChipProps & { sentAt?: any[]; receivedAt?: any[]; label?: string }) => {
    const { sentAt, receivedAt, label } = props;

    const toShowTooltip = (sentAt && sentAt.length > 0) || (receivedAt && receivedAt.length > 0);

    return (
        <JoyProvider>
            <JoyTooltip
                // open
                variant="outlined"
                title={toShowTooltip ? TimeStampTooltipTitle(sentAt, receivedAt, label) : null}
                placement="right"
                arrow
            >
                <JoyChip size="sm" variant="outlined" {...props}>
                    {label}
                </JoyChip>
            </JoyTooltip>
        </JoyProvider>
    );
})(({ theme }) => ({
    pointerEvents: "all",
    // cursor: "pointer",
    "& .MuiChip-label": {
        fontSize: "10px",
    },
}));

function EmailAndFollowupStatus({ emailStatus, followupStatus, outreachTimestamps }: EmailAndFollowupStatusProps) {
    const isSuperAdmin = useSelector(selectUser)?.isSuperAdmin;
    if (!emailStatus && !followupStatus) {
        return null;
    }

    const emailStatusValues = emailStatus ? emailStatusLabels[emailStatus] : null;
    const followUpEmailStatusValues = followupStatus ? followupEmailStatusLabels[followupStatus] : null;

    const emailLabel = emailStatusValues
        ? isSuperAdmin
            ? emailStatusValues.superAdmin
            : emailStatusValues.client
        : "";

    const followupLabel = followUpEmailStatusValues
        ? isSuperAdmin
            ? followUpEmailStatusValues.superAdmin
            : followUpEmailStatusValues.client
        : "";

    if (!emailLabel && !followupLabel) {
        return null;
    }

    return (
        <CandidateCardLabelsStack sx={{ width: "100%", marginTop: "10px" }}>
            <Stack gap={1}>
                {emailLabel ? (
                    <Stack direction="row">
                        <BottomTooltip title="Email and Follow-up email Status">
                            <MarkEmailUnreadIcon
                                sx={{
                                    height: "25px",
                                    width: "25px",
                                    color: "#242222",
                                }}
                            />
                        </BottomTooltip>
                        <CustomStatusChip
                            label={emailLabel}
                            sx={{ marginLeft: "10px", maxWidth: "150px" }}
                            sentAt={outreachTimestamps?.emailSentAt}
                            receivedAt={outreachTimestamps?.emailRespondedAt}
                        />
                    </Stack>
                ) : null}
                {emailLabel && followupLabel ? (
                    <Stack direction="row" sx={{ width: "100%" }}>
                        <MarkEmailUnreadIcon
                            sx={{
                                height: "25px",
                                width: "25px",
                                color: "#242222",
                                visibility: "hidden",
                            }}
                        />
                        <CustomStatusChip
                            label={followupLabel}
                            sx={{ marginLeft: "10px", maxWidth: "150px" }}
                            sentAt={outreachTimestamps?.followUpEmailSentAt}
                            receivedAt={outreachTimestamps?.followUpEmailRespondedAt}
                        />
                    </Stack>
                ) : null}
            </Stack>
        </CandidateCardLabelsStack>
    );
}

function SMSFollowupStatus({ smsStatus, outreachTimestamps }: { smsStatus?: SMSStatus; outreachTimestamps: any }) {
    const isSuperAdmin = useSelector(selectUser)?.isSuperAdmin;
    if (!smsStatus) return null;

    const smsStatusValue = smsStatus ? SmsStatusLables[smsStatus] : null;
    const smsLable = smsStatusValue ? (isSuperAdmin ? smsStatusValue.superAdmin : smsStatusValue.client) : "";

    if (!smsLable) return null;

    return (
        <CandidateCardLabelsStack sx={{ width: "100%", marginTop: "10px" }}>
            <Stack gap={1}>
                {smsLable ? (
                    <Stack direction="row">
                        <BottomTooltip title="SMS Status">
                            <TextsmsRoundedIcon
                                sx={{
                                    height: "25px",
                                    width: "25px",
                                    color: "#242222",
                                }}
                            />
                        </BottomTooltip>
                        <CustomStatusChip
                            label={smsLable}
                            sx={{ marginLeft: "10px", maxWidth: "150px" }}
                            sentAt={outreachTimestamps?.smsSentAt}
                            receivedAt={outreachTimestamps?.smsRespondedAt}
                        />
                    </Stack>
                ) : null}
            </Stack>
        </CandidateCardLabelsStack>
    );
}

type EmailStatusIconProps = {
    finalStatus: {
        title: string;
        color: string;
        Icon: any;
    };
};

const EmailStatusIcon = (props: EmailStatusIconProps) => {
    const { title, color, Icon } = props?.finalStatus;

    return (
        <JoyTooltip title={title} placement="left">
            <Icon sx={{ color, fontSize: "16px" }} />
        </JoyTooltip>
    );
};

type CandidateEmailProps = {
    index: number | undefined;
    email: string[];
    professional_email: string[];
    emailLoading: boolean;
    groupCandidatesLoading: boolean;
    canShowEmail: boolean;
    primaryEmail?: string;
    handleShowEmail: () => void;
    onClickEmail: ({ primaryEmail }: { primaryEmail: string }) => void;
    contactType: any[];
    emailResponseStats: {};
};

function CandidateEmails({
    index,
    email,
    canShowEmail,
    emailLoading,
    groupCandidatesLoading,
    professional_email,
    primaryEmail,
    handleShowEmail,
    onClickEmail,
    contactType,
    emailResponseStats,
}: CandidateEmailProps) {
    const emailsWithoutEmptyValues = email?.length ? email.filter((i: string) => Boolean(i)) : [];

    const professionalEmailsWithoutEmptyValues = professional_email?.length
        ? professional_email.filter((i: string) => Boolean(i))
        : [];

    if (emailLoading || groupCandidatesLoading) {
        return (
            <div style={{ display: "inline-flex", alignItems: "center" }}>
                <Loader size="sm" />
            </div>
        );
    }

    if (!emailsWithoutEmptyValues?.length && !professionalEmailsWithoutEmptyValues?.length) {
        return null;
    }

    return (
        <Stack alignContent="center">
            <div className={style["spcard__showHideEmail"]} id={`card${index}ShowHideEmail`}>
                {!canShowEmail ? (
                    <>
                        {/* <button
                            className={style["spcard__showHideEmail-btn"]}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleShowEmail();
                            }}
                        >
                            Show
                        </button> */}
                        <span className={style["spcard__showHideEmail-text"]}>{email}</span>
                    </>
                ) : emailsWithoutEmptyValues?.length ? (
                    emailsWithoutEmptyValues.map((item: string, i: number) => (
                        <CopyableText
                            key={i}
                            textToCopy={item}
                            message="Email copied succesfully"
                            onClick={(e: any) => {
                                e.stopPropagation();
                               
                            }}
                        >
                            <HoverTruncateText
                                sx={{ color: "#242222" }}
                                className={style["spcard__professionalEmail"]}
                                maxWidth={300}
                            >
                                <ContactTypeTooltip
                                    contactTypeData={contactType}
                                    professionalEmailsWithoutEmptyValues={professionalEmailsWithoutEmptyValues}
                                    emailResponseStats={emailResponseStats}
                                >
                                    <div>{item}</div>
                                </ContactTypeTooltip>
                            </HoverTruncateText>
                        </CopyableText>
                    ))
                ) : professionalEmailsWithoutEmptyValues?.length ? (
                    professionalEmailsWithoutEmptyValues.map((email) => (
                        <CopyableText key={email} message="Email copied succesfully" textToCopy={email}>
                            <HoverTruncateText
                                sx={{ color: "#242222" }}
                                className={style["spcard__professionalEmail"]}
                                maxWidth={300}
                            >
                                <ContactTypeTooltip
                                    contactTypeData={contactType}
                                    professionalEmailsWithoutEmptyValues={professionalEmailsWithoutEmptyValues}
                                    emailResponseStats={emailResponseStats}
                                >
                                    <div>{email}</div>
                                </ContactTypeTooltip>
                            </HoverTruncateText>
                        </CopyableText>
                    ))
                ) : (
                    <p className={style["spcard__showHideEmail-text"]}>{/* Email not available */}</p>
                )}
            </div>
        </Stack>
    );
}

export const isInValidLinkedinProfile = (linkedinUrl: string): boolean => {
    const profileId = linkedinUrl.split("/").pop();
    if (!profileId) return false;
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    const isValid = regexExp.test(profileId);
    if (!isValid) {
        // it's a regex , very less possiblity that it will be a linkedin profile id
        return false;
    }
    return true;
};

const LinkedInUrlStatusBadge = ({ status }: { status: string }) => {
    const isSuccess = status === "SUCCESS";
    if (!isSuccess && status !== "FAILED") return null;

    return (
        <JoyTooltip arrow title={`Linkedin url status: ${isSuccess ? "success" : "failed"}`} sx={{ fontSize: "12px" }}>
            <Badge
                size="sm"
                variant="plain"
                badgeContent={
                    isSuccess ? (
                        <DoneIcon sx={{ fontSize: "16px", color: "#57be58" }} />
                    ) : (
                        <CloseIcon sx={{ fontSize: "16px", color: "red" }} />
                    )
                }
            >
                <LinkedInIcon
                    sx={{
                        color: "#0891B2",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }}
                />
            </Badge>
        </JoyTooltip>
    );
};

function CandidateProfileCard({
    variant,
    projectId,
    index,
    candidate,
    handleSeeMore,
    onEditCandidate,
}: CandidateProfileCardProps) {
    const {
        csReviewStage,
        detailedStage,
        _id,
        feedback,
        name,
        email = [],
        visa,
        location,
        skills,
        stage,
        reviewStage,
        shortlist1,
        experience,
        totalExperience,
        joiningStatus,
        profileUrl = "",
        gsMetaData,
        gitMetaData,
        courses,
        roleWarning,
        relevancy_skills = [],
        profileImage,
        canShowEmail,
        professional_email = [],
        linkedInExp,
        scrapStatus,
        isSample,
        starRating,
        resume,
        primaryEmail,
        conversationCount,
        outreachStatus,
        exclusionRating,
        phone = [],
        languages,
        cpSource,
        cvSource,
        greenhouseMetaData,
        retention,
        emailResponseStats,
        openToWork,
        linkedinUrlStatus,
    } = candidate;
    const dispatch = useDispatch();
    const orgId = useSelector(selectUserOrgId);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const projectData = useSelector(selectCurrProject);
    const candidates = useSelector(selectProjectCandidates);
    const isSendosoIntegrated = useSelector(checkSendosoIntegrated);
    const installExtensionNudge = useSelector(selectInstallExtensionNudge);
    const showEmailNudge = useSelector(selectShowEmailNudge);
    const [canShowPrimaryEmailPopup, setCanShowPrimaryEmailPopup] = useState(false);
    const [changedPrimaryEmail, setChangedPrimaryEmail] = useState("");
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const isSelected = Boolean(selectedCandidates.find((item) => item.candidateId === _id));
    const emailLoading = useSelector(checkIfLoading(`${getCandidateEmail.type}${_id}`));
    const groupCandidatesLoading = useSelector(checkIfLoading(getCandidateEmail.type)) && !canShowEmail;
    const showNudgeCondn = false;
    let isCandidateRatingUpdated = false;
    const vettingCriteriaUpdatedTime = get(projectData, "vettingCriteria[0].updatedAt");
    const emailsWithoutEmptyValues = email?.length ? email.filter((i: string) => Boolean(i)) : [];
    const professionalEmailsWithoutEmptyValues = professional_email?.length
        ? professional_email?.filter((i: string) => Boolean(i))
        : [];

    const emailsCombined = [...emailsWithoutEmptyValues, ...professionalEmailsWithoutEmptyValues]?.map((email) => ({
        contactType: "email" as TContactType,
        value: email,
    }));

    const candidateNumber = Array.isArray(phone)
        ? phone.filter((number) => {
              return number && number !== "-" && number !== "";
          })
        : [];

    const phoneNumbers = candidateNumber.map((number) => ({
        contactType: "phone" as TContactType,
        value: number,
    }));

    const contactsTypeData = [...emailsCombined, ...phoneNumbers];

    const { data: contactsType } = useGetCandidateSourceTypesQuery(
        {
            profileUrl,
            data: contactsTypeData,
        },
        { skip: !profileUrl || !contactsTypeData?.length }
    );

    const isOutreachStatusNotSent = outreachStatus
        ? Object.values(outreachStatus).every((status) => status === "NOT_SENT")
        : true;

    const { data: outreachTimestamps } = useGetOutreachTimestampsQuery(
        {
            candidateId: _id,
            projectId: Number(projectId),
            bodyOutreachStatus: outreachStatus,
        },
        { skip: isOutreachStatusNotSent }
    );

    const filterContactType = (type: TContactType) => {
        return contactsType?.sourceTypeData?.filter((item: any) => item.contactType === type);
    };

    if (vettingCriteriaUpdatedTime && starRating?.updatedAt) {
        const vettingCriteriaDate = new Date(vettingCriteriaUpdatedTime).getTime();
        const starRatingDate = new Date(starRating?.updatedAt).getTime();
        isCandidateRatingUpdated = starRatingDate > vettingCriteriaDate;
    }

    const normalSkills = isEmpty(skills) ? [] : skills.map((item: { name: string }) => item.name);
    const all_skills = uniq([...relevancy_skills, ...normalSkills]);

    const isScrapStatusCompleted = scrapStatus === "COMPLETED" || scrapStatus === "CANCELLED";
    const isLoading =
        !isScrapStatusCompleted &&
        !(projectData?.projectStatus === "COMPLETED" || projectData?.projectStatus === "CANCELLED");

    const handleShowEmail = () => {
        if (isSample) {
            const tmpCandidates = candidates.map((item: ICandidate) => {
                if (item._id === _id) {
                    return {
                        ...item,
                        canShowEmail: true,
                        email: ["sampleemail3@gmail.com"],
                    };
                }

                return item;
            });
            dispatch(setCandidates(tmpCandidates));
            return;
        }
        dispatch(
            getCandidateEmail({
                candidateIds: [_id],
                action: `${getCandidateEmail.type}${_id}`,
            })
        );
    };

    const handleCSStatusChange = (id: string, status: CS_STATUS) => {
        dispatch(
            moveTo({
                candidateIds: [id],
                csReviewStage: status,
                projectId: Number(projectId),
            })
        );
    };

    const handleChangeStatus = (id: string, type: ReviewStage, shortlist1?: boolean) => {
        if (isSample) {
            const tmpCandidates = candidates.map((item: ICandidate) => {
                if (item._id === _id) {
                    return {
                        ...item,
                        reviewStage: type,
                    };
                }

                return item;
            });
            dispatch(setCandidates(tmpCandidates));
            return;
        }
        dispatch(
            moveTo({
                candidateIds: [id],
                stage: type,
                shortlist1: shortlist1,
                projectId: Number(projectId),
            })
        );
        return;
    };

    const onClickEmail = ({ primaryEmail }: { primaryEmail: string }) => {
        if (!primaryEmail) return;
        setChangedPrimaryEmail(primaryEmail);
        setCanShowPrimaryEmailPopup(true);
    };

    const showLinkedInConnAndInMailStatusOnLeft = all_skills.length > 3;

    const showOutReachStatus = !isEmpty(outreachStatus);

    const user = useSelector(selectUser);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const size = useWindowSize();

    const shouldShowIcon =
        (user?.emailSendType === "ONLY_PERSONAL" && emailsWithoutEmptyValues?.length > 0) ||
        (user?.emailSendType === "PERSONAL_PREFERRED" &&
            (emailsWithoutEmptyValues?.length > 0 || professionalEmailsWithoutEmptyValues?.length > 0)) ||
        (user?.emailSendType === "PROFESSIONAL_PREFERRED" &&
            (emailsWithoutEmptyValues?.length > 0 || professionalEmailsWithoutEmptyValues?.length > 0)) ||
        (user?.emailSendType === "ONLY_PROFESSIONAL" && professionalEmailsWithoutEmptyValues?.length > 0);

    const getCharLimit = (width: any) => {
        switch (true) {
            case width < 1300:
                return 15;
            case width >= 1300 && width < 1350:
                return 18;
            case width >= 1350 && width < 1450:
                return 21;
            case width >= 1450 && width < 1500:
                return 25;
            case width >= 1500 && width < 1550:
                return 27;
            case width >= 1550 && width < 1600:
                return 29;
            default:
                return 33;
        }
    };
    const charLimit = getCharLimit(size?.width);

    return (
        <>
            {isSample ? <p className={style["spcard__demo"]}>Sample data</p> : null}
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 200px",
                    padding: variant === "modal" ? "0.5rem" : 0,
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        gap: "1rem",
                    }}
                >
                    <Stack
                        position="relative"
                        height="fit-content"
                        sx={{
                            alignSelf: "start",
                        }}
                        pt={1.5}
                    >
                        <Avatar
                            src={profileImage?.startsWith("data:") ? name : profileImage}
                            alt={name}
                            className={style["spcard__card-avatar"]}
                        />
                        {((!isNil(starRating) && !isNil(starRating?.value)) ||
                            (!isNil(exclusionRating) &&
                                !isNil(exclusionRating?.value) &&
                                exclusionRating?.value !== 0)) &&
                        variant === "card" ? (
                            <Tooltip
                                title={
                                    starRating?.value == 0 && isEasyGrowth ? (
                                        "Person did not qualify your lead scoring criteria"
                                    ) : starRating?.description?.length ? (
                                        <ul
                                            style={{
                                                paddingInlineStart: 10,
                                            }}
                                        >
                                            {starRating.description.map(({ text }) => (
                                                <li
                                                    key={text}
                                                    style={{
                                                        fontSize: 10,
                                                    }}
                                                >
                                                    {text}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : null
                                }
                            >
                                <Chip
                                    sx={{
                                        bgcolor: "#fff",
                                        boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px`,
                                        fontSize: 10,
                                        fontWeight: 600,
                                        position: "absolute",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        cursor: "pointer",
                                        bottom: "-20%",
                                        maxWidth: "fit-content",
                                        "& .MuiChip-label": {
                                            padding: "0 6px",
                                            overflow: "unset",
                                        },
                                        "& .MuiChip-icon": {
                                            color: starRating?.value == 0 ? "grey" : "#FDCC0D",
                                        },
                                    }}
                                    icon={
                                        exclusionRating?.value === 1 ? undefined : starRating &&
                                          starIconShouldBeIncluded(starRating?.value || 0, isEasyGrowth) ? (
                                            <StarRoundedIcon />
                                        ) : undefined
                                    }
                                    label={
                                        exclusionRating?.value === 1
                                            ? "Excluded"
                                            : starRating && starRating?.value == 0
                                              ? isEasyGrowth
                                                  ? ""
                                                  : "Unqualified"
                                              : starRating && starRating?.value !== -1
                                                ? starRating.label
                                                : starRating && starRating?.value === -1
                                                  ? "Unqualified"
                                                  : ""
                                    }
                                    size="small"
                                />
                            </Tooltip>
                        ) : null}
                        {!isEasyGrowth && openToWork && openToWork?.status ? (
                            <Chip
                                label="#OpenToWork"
                                sx={{
                                    bgcolor: "#fff",
                                    boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px`,
                                    fontSize: 8,
                                    fontWeight: 600,
                                    position: "absolute",
                                    left: "50%",
                                    top: "125%",
                                    transform: "translateX(-50%)",
                                    cursor: "pointer",
                                    bottom: "-20%",
                                    maxWidth: "fit-content",
                                    color: "rgb(64, 154, 78)",
                                    "& .MuiChip-label": {
                                        padding: "0 6px",
                                        overflow: "unset",
                                    },
                                }}
                                size="small"
                            />
                        ) : null}
                        {cvSource && cvSource?.label === "ATS" && (
                            <MuiLink
                                href={
                                    cvSource?.value?.toLowerCase() === "greenhouse"
                                        ? `${user?.greenHouse?.clientGreenHouseUrl}/${greenhouseMetaData?.applications?.[0]?.candidate_id}`
                                        : null
                                }
                                underline="none"
                                sx={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(event) => event.stopPropagation()}
                            >
                                <Typography
                                    sx={{
                                        marginTop: "20px",
                                        backgroundColor: "#dfff0b",
                                        padding: "5px",
                                        fontSize: "10px",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                    }}
                                >
                                    {cvSource?.value?.toLowerCase()?.charAt(0)?.toUpperCase() +
                                        cvSource?.value?.slice(1)?.toLowerCase()}
                                </Typography>
                            </MuiLink>
                        )}
                    </Stack>
                    <Stack spacing={3}>
                        <GridBox>
                            <Stack>
                                <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                                    <Tooltip title={name}>
                                        <p className={style["spcard__name"]}>
                                            {trimText(name, charLimit)}
                                            {joiningStatus ? (
                                                <span>{Number(joiningStatus) === 1 ? "Immediate Joiner" : null}</span>
                                            ) : null}
                                        </p>
                                    </Tooltip>

                                    {profileUrl &&
                                    !isInValidLinkedinProfile(profileUrl) &&
                                    !gsMetaData?.scholarUrl &&
                                    !gitMetaData?.html_url ? (
                                        <Link
                                            to={profileUrl}
                                            target={"_blank"}
                                            className={style["spcard__linkedIn"]}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <img
                                                className={style["spcard__linkedInLogo"]}
                                                src={linkedInLogo}
                                                alt="linkedin-logo"
                                            />
                                        </Link>
                                    ) : null}
                                    {gitMetaData?.html_url && !gsMetaData?.scholarUrl && (
                                        <Tooltip title="Github">
                                            <Link
                                                to={gitMetaData?.html_url}
                                                target={"_blank"}
                                                className={style["spcard__linkedIn"]}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <img
                                                    className={style["spcard__linkedInLogo"]}
                                                    src={githubLogo}
                                                    width={40}
                                                    height={40}
                                                    alt="github-logo"
                                                />
                                            </Link>
                                        </Tooltip>
                                    )}

                                    {gsMetaData?.scholarUrl ? (
                                        <>
                                            <Tooltip title="Google scholar">
                                                <Link
                                                    to={gsMetaData?.scholarUrl}
                                                    target={"_blank"}
                                                    className={style["spcard__linkedIn"]}
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <img
                                                        className={style["spcard__linkedInLogo"]}
                                                        src={googleScholarLogo}
                                                        width={40}
                                                        height={40}
                                                        alt="linkedin-logo"
                                                    />
                                                </Link>
                                            </Tooltip>

                                            {gsMetaData?.homePage && gsMetaData?.homePage !== "N/A" && (
                                                <Tooltip title="Home page">
                                                    <Link
                                                        to={gsMetaData?.homePage}
                                                        target={"_blank"}
                                                        className={style["spcard__linkedIn"]}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <img
                                                            className={style["spcard__linkedInLogo"]}
                                                            src={houseLogo}
                                                            width={40}
                                                            height={40}
                                                            alt="linkedin-logo"
                                                        />
                                                    </Link>
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : null}

                                    {
                                        // <StatusMenu
                                        //     feedback={feedback}
                                        //     variant={variant}
                                        //     candidateId={_id}
                                        //     stage={reviewStageObj[reviewStage]}
                                        //     handleStatusChange={(stage, shortlist1) =>
                                        //         handleChangeStatus(_id, stage, shortlist1)
                                        //     }
                                        // />
                                        <StatusMenuAnimButton
                                            candidateId={_id}
                                            feedback={feedback}
                                            stage={reviewStageObj[reviewStage]}
                                            isDefaultStageShortlisPlus={candidate?.shortlist1 || false}
                                            handleChangeStatus={(stage, shortlist1) =>
                                                handleChangeStatus(_id, stage, shortlist1)
                                            }
                                        />
                                    }
                                </Stack>

                                {!gsMetaData?.degreeType &&
                                experience &&
                                experience?.length &&
                                experience[0]?.position &&
                                experience[0]?.org ? (
                                    experience[0]?.org.split("·")[0].trim().length + experience[0]?.position.length >
                                    30 ? (
                                        <Stack>
                                            <HoverTruncateText
                                                maxWidth={300}
                                                tooltipPlacement="bottom"
                                                placeholderText={experience[0]?.position.trim() + ", "}
                                            >
                                                <p className={style["spcard__desc"]}>{experience[0]?.position}</p>
                                            </HoverTruncateText>

                                            <HoverTruncateText
                                                maxWidth={300}
                                                tooltipPlacement="bottom"
                                                placeholderText={experience[0]?.org.trim()}
                                                sx={{ fontWeight: 600 }}
                                            >
                                                <p className={style["spcard__desc"]}>
                                                    <b>
                                                        {experience && experience?.length
                                                            ? experience[0]?.org?.split("·")?.[0]?.trim()
                                                            : ""}
                                                    </b>
                                                </p>
                                            </HoverTruncateText>
                                        </Stack>
                                    ) : (
                                        <p className={style["spcard__desc"]}>
                                            {experience && experience?.length ? experience[0]?.position : ""}
                                            {", "}
                                            <b>
                                                {experience && experience?.length
                                                    ? experience[0]?.org?.split("·")?.[0]?.trim()
                                                    : ""}
                                            </b>
                                        </p>
                                    )
                                ) : null}

                                {gsMetaData?.degreeType ? (
                                    <HoverTruncateText maxWidth={300} tooltipPlacement="bottom">
                                        {gsMetaData?.degreeType}
                                    </HoverTruncateText>
                                ) : null}

                                {gitMetaData?.bio && !gsMetaData?.degreeType ? (
                                    <HoverTruncateText maxWidth={300} tooltipPlacement="bottom">
                                        {gitMetaData?.bio}
                                    </HoverTruncateText>
                                ) : null}
                            </Stack>

                            <Stack direction={"column"} gap={1}>
                                {!email.length && !professional_email?.length ? null : (
                                    <CandidateCardLabelsStack>
                                        {shouldShowIcon && (
                                            <BottomTooltip title="Primary email">
                                                <EmailIcon
                                                    sx={{
                                                        height: "25px",
                                                        width: "25px",
                                                        color: "#838383",
                                                    }}
                                                />
                                            </BottomTooltip>
                                        )}
                                        {showNudgeCondn && (
                                            <Nudge
                                                variant="top"
                                                type="showEmail"
                                                text={"Click here to extract personal email IDs 📧"}
                                                wrapperStyle={{
                                                    position: "absolute",
                                                    top: "110%",
                                                    left: 0,
                                                    textAlign: "left",
                                                    cursor: "default",
                                                }}
                                                containerStyle={{
                                                    flexDirection: "row-reverse",
                                                }}
                                                arrowStyle={{
                                                    margin: "0.75rem 1.5rem 0",
                                                }}
                                                closeIconStyle={{
                                                    top: "calc(100% - 18px)",
                                                }}
                                            />
                                        )}
                                        <CandidateEmails
                                            index={index}
                                            email={
                                                user.emailSendType === "ONLY_PERSONAL" && email?.length
                                                    ? email?.slice(0, 1)
                                                    : user?.emailSendType === "PERSONAL_PREFERRED" && email?.length
                                                      ? email?.slice(0, 1)
                                                      : user.emailSendType === "PROFESSIONAL_PREFERRED" &&
                                                          !professional_email?.length
                                                        ? email?.slice(0, 1)
                                                        : []
                                            }
                                            canShowEmail={canShowEmail}
                                            professional_email={
                                                user.emailSendType === "ONLY_PROFESSIONAL"
                                                    ? professional_email?.slice(0, 1)
                                                    : user.emailSendType === "PROFESSIONAL_PREFERRED" &&
                                                        professional_email?.length
                                                      ? professional_email?.slice(0, 1)
                                                      : user.emailSendType === "PERSONAL_PREFERRED" && !email?.length
                                                        ? professional_email?.slice(0, 1)
                                                        : []
                                            }
                                            // primaryEmail={primaryEmail}
                                            emailLoading={emailLoading}
                                            groupCandidatesLoading={groupCandidatesLoading}
                                            handleShowEmail={handleShowEmail}
                                            onClickEmail={onClickEmail}
                                            contactType={filterContactType("email")}
                                            emailResponseStats={emailResponseStats}
                                        />
                                    </CandidateCardLabelsStack>
                                )}
                                {candidateNumber?.length ? (
                                    <CandidateCardLabelsStack>
                                        <BottomTooltip title="Phone">
                                            <LocalPhoneRoundedIcon
                                                sx={{
                                                    height: "25px",
                                                    width: "25px",
                                                    color: "#838383",
                                                }}
                                            />
                                        </BottomTooltip>
                                        {isLoading ? (
                                            <SkeletonUI />
                                        ) : (
                                            <div>
                                                {candidateNumber ? (
                                                    <>
                                                        <HoverTruncateText
                                                            sx={{ color: "#242222" }}
                                                            className={style["spcard__professionalEmail"]}
                                                            maxWidth={300}
                                                        >
                                                            <ContactTypeTooltip
                                                                contactTypeData={filterContactType("phone")}
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        color: "#242222",
                                                                        fontSize: "12px",
                                                                    }}
                                                                >
                                                                    {candidateNumber[0]}
                                                                </Typography>
                                                            </ContactTypeTooltip>
                                                        </HoverTruncateText>
                                                    </>
                                                ) : (
                                                    <NA />
                                                )}
                                            </div>
                                        )}
                                    </CandidateCardLabelsStack>
                                ) : null}
                            </Stack>
                        </GridBox>

                        <GridBox>
                            <Stack spacing={2}>
                                {gitMetaData && !gsMetaData?.scholarUrl && (
                                    <>
                                        {gitMetaData?.login && (
                                            <CandidateCardLabelsStack>
                                                <BottomTooltip title="It is the unique id that searchable through https://github.com/id">
                                                    <Person4Icon
                                                        sx={{
                                                            height: "25px",
                                                            width: "25px",
                                                            color: "#838383",
                                                        }}
                                                    />
                                                </BottomTooltip>
                                                <p style={{ fontSize: "12px", color: "#242222" }}>
                                                    UserId: {gitMetaData?.login}
                                                </p>
                                            </CandidateCardLabelsStack>
                                        )}

                                        {gitMetaData?.userScore && (
                                            <CandidateCardLabelsStack>
                                                <BottomTooltip title="Custom score based on our propreitary algorithm taking in to account every single parameter of a user">
                                                    <AddchartIcon
                                                        sx={{
                                                            height: "22px",
                                                            width: "22px",
                                                            color: "#838383",
                                                        }}
                                                    />
                                                </BottomTooltip>
                                                <p style={{ fontSize: "12px", color: "#242222" }}>
                                                    User Score: {gitMetaData?.userScore}
                                                </p>
                                            </CandidateCardLabelsStack>
                                        )}
                                    </>
                                )}

                                {!gsMetaData?.scholarUrl && !gitMetaData?.html_url && (
                                    <>
                                        {linkedInExp?.label && linkedInExp?.value && (
                                            <CandidateCardLabelsStack>
                                                <BottomTooltip title="Years of Experience">
                                                    <WorkHistoryRoundedIcon
                                                        sx={{
                                                            height: "25px",
                                                            width: "25px",
                                                            color: "#838383",
                                                        }}
                                                    />
                                                </BottomTooltip>
                                                {isLoading ? <SkeletonUI /> : <Experience linkedInExp={linkedInExp} />}
                                            </CandidateCardLabelsStack>
                                        )}

                                        <CandidateCardLabelsStack>
                                            <BottomTooltip title="Work History">
                                                <ApartmentRoundedIcon
                                                    sx={{
                                                        height: "25px",
                                                        width: "25px",
                                                        color: "#838383",
                                                    }}
                                                />
                                            </BottomTooltip>
                                            {isLoading ? (
                                                <SkeletonUI />
                                            ) : (
                                                <>
                                                    <HoverTruncateText
                                                        maxWidth={300}
                                                        tooltipPlacement="bottom"
                                                        sx={{ fontSize: 12 }}
                                                    >
                                                        {experience?.length ? (
                                                            <p
                                                                style={{
                                                                    fontSize: "12px",
                                                                    color: "#242222",
                                                                    fontWeight: "400",
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: uniq(
                                                                        experience.map(
                                                                            (ex: { org: string }) =>
                                                                                ex.org?.split(" ·")[0]
                                                                        )
                                                                    )
                                                                        ?.slice(0, 2)
                                                                        ?.join(", "),
                                                                }}
                                                            />
                                                        ) : (
                                                            <NA />
                                                        )}
                                                    </HoverTruncateText>
                                                </>
                                            )}
                                        </CandidateCardLabelsStack>
                                    </>
                                )}

                                {gsMetaData ? (
                                    <>
                                        {(gsMetaData?.i10Index && gsMetaData?.i10Index !== 0) || gsMetaData?.rating ? (
                                            <CandidateCardLabelsStack>
                                                {gsMetaData?.i10Index && gsMetaData?.i10Index !== 0 && (
                                                    <>
                                                        <BottomTooltip title="The i10-index was created by Google Scholar as an index to rank author impact. Simply, it is the number of publications the researcher has written that have at least 10 citations.">
                                                            <AddchartIcon
                                                                sx={{
                                                                    height: "22px",
                                                                    width: "22px",
                                                                    color: "#838383",
                                                                }}
                                                            />
                                                        </BottomTooltip>
                                                        <p style={{ fontSize: "12px", color: "#242222" }}>
                                                            I10-Index: {gsMetaData?.i10Index}
                                                        </p>
                                                    </>
                                                )}

                                                {gsMetaData?.rating && (
                                                    <>
                                                        <BottomTooltip title="Candidates are rated based on our proprietary algorithms.">
                                                            <SpeedIcon
                                                                sx={{
                                                                    height: "25px",
                                                                    width: "25px",
                                                                    color: "#838383",
                                                                }}
                                                            />
                                                        </BottomTooltip>

                                                        <p style={{ fontSize: "12px", color: "#242222" }}>
                                                            Rating: {gsMetaData?.rating}
                                                        </p>
                                                    </>
                                                )}
                                            </CandidateCardLabelsStack>
                                        ) : null}

                                        {gsMetaData?.citations || gsMetaData?.hIndex ? (
                                            <CandidateCardLabelsStack>
                                                {gsMetaData?.citations && (
                                                    <>
                                                        <BottomTooltip title="Citations are a way of giving credit when certain material in your work came from another source. It also gives your readers the information necessary to find that source again.">
                                                            <AutoStoriesIcon
                                                                sx={{
                                                                    height: "22px",
                                                                    width: "22px",
                                                                    color: "#838383",
                                                                }}
                                                            />
                                                        </BottomTooltip>
                                                        <p style={{ fontSize: "12px", color: "#242222" }}>
                                                            Citations: {gsMetaData?.citations}
                                                        </p>
                                                    </>
                                                )}

                                                {gsMetaData?.hIndex && (
                                                    <>
                                                        <BottomTooltip title="The h-index is a number intended to represent both the productivity and the impact of a particular scientist or scholar, or a group of scientists or scholars.">
                                                            <BarChartIcon
                                                                sx={{
                                                                    height: "25px",
                                                                    width: "25px",
                                                                    color: "#838383",
                                                                }}
                                                            />
                                                        </BottomTooltip>
                                                        <p style={{ fontSize: "12px", color: "#242222" }}>
                                                            H-Index: {gsMetaData?.hIndex}
                                                        </p>
                                                    </>
                                                )}
                                            </CandidateCardLabelsStack>
                                        ) : null}

                                        {gsMetaData?.labels ? (
                                            <CandidateCardLabelsStack id="3">
                                                {gsMetaData.labels?.slice(0, 2)?.map((label, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontStyle: "italic",
                                                            fontSize: "13px",
                                                            fontWeight: "400",
                                                            padding: "1.5px",
                                                            borderRadius: "10px",
                                                            backgroundColor: "#dfff0b",
                                                            paddingLeft: "7px",
                                                            paddingRight: "7px",
                                                            marginRight: "5px", // Add some margin between spans
                                                        }}
                                                    >
                                                        <HoverTruncateText maxWidth={140} tooltipPlacement="bottom">
                                                            {label}
                                                        </HoverTruncateText>
                                                    </span>
                                                ))}
                                            </CandidateCardLabelsStack>
                                        ) : null}

                                        {gsMetaData?.labels ? (
                                            <CandidateCardLabelsStack id="4">
                                                {gsMetaData.labels
                                                    ?.filter((label) => label.split(" ").length <= 2)
                                                    ?.slice(2, 4) // This will get the 3rd and 4th labels after filtering
                                                    ?.map((label, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                fontStyle: "italic",
                                                                fontSize: "13px",
                                                                fontWeight: "400",
                                                                padding: "1.5px",
                                                                borderRadius: "10px",
                                                                backgroundColor: "#dfff0b",
                                                                paddingLeft: "7px",
                                                                paddingRight: "7px",
                                                                marginRight: "5px", // Add some margin between spans
                                                            }}
                                                        >
                                                            <HoverTruncateText maxWidth={140} tooltipPlacement="bottom">
                                                                {label}
                                                            </HoverTruncateText>
                                                        </span>
                                                    ))}
                                            </CandidateCardLabelsStack>
                                        ) : null}
                                    </>
                                ) : null}

                                {/* {showOutReachStatus && (
                                    <EmailAndFollowupStatus
                                        emailStatus={outreachStatus?.email}
                                        followupStatus={
                                            outreachStatus?.followUpEmail
                                        }
                                    />
                                )} */}
                            </Stack>
                            <Stack spacing={2}>
                                <CandidateCardLabelsStack>
                                    <BottomTooltip title="Location">
                                        <FmdGoodRoundedIcon
                                            sx={{
                                                height: "25px",
                                                width: "25px",
                                                color: "#838383",
                                            }}
                                        />
                                    </BottomTooltip>

                                    {isLoading ? (
                                        <SkeletonUI />
                                    ) : (
                                        <>
                                            {location ? (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: "#242222",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    {location}
                                                </Typography>
                                            ) : (
                                                <NA />
                                            )}
                                        </>
                                    )}
                                </CandidateCardLabelsStack>
                                <CandidateCardLabelsStack>
                                    {!gsMetaData?.scholarUrl && (
                                        <>
                                            <BottomTooltip title="Skills">
                                                <WorkspacePremiumIcon
                                                    sx={{
                                                        height: "25px",
                                                        width: "25px",
                                                        color: "#838383",
                                                    }}
                                                />
                                            </BottomTooltip>
                                            <Skills
                                                all_skills={all_skills}
                                                relevancy_skills={relevancy_skills}
                                                isLoading={isLoading}
                                                isSelected={isSelected && variant === "card"}
                                                handleSeeMore={handleSeeMore}
                                            />
                                        </>
                                    )}
                                </CandidateCardLabelsStack>
                            </Stack>
                        </GridBox>
                    </Stack>
                </div>
                <div className={style["spcard__cardLast"]}>
                    <Stack spacing={1} mb={1}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={0.3}>
                            {/* {isSuperAdmin && csReviewStage !== "ACCEPTED" ? (
                                <CSReviewStatus
                                    variant={variant}
                                    stage={csReviewStages[csReviewStage || "PENDING"]}
                                    handleStatusChange={(stage) => handleCSStatusChange(_id, stage)}
                                />
                            ) : null} */}
                            {isSuperAdmin && csReviewStage && csReviewStage !== "ACCEPTED" && (
                                <>
                                    {cpSource === "AP" && (
                                        <Tooltip title="Added from auto pilot">
                                            <Chip color="success" label="AP" size="small" />
                                        </Tooltip>
                                    )}
                                    <Chip
                                        color={csReviewStage === "PENDING" ? "warning" : "error"}
                                        label={csReviewStages[csReviewStage] ?? csReviewStage}
                                        size="small"
                                    />
                                </>
                            )}
                            {visa ? (
                                <div className={style["spcard__visaItem"]}>
                                    <img src={blueTickIcon} alt="blue-tick-icon" />
                                    <p className={style["spcard__visaItem-text"]}>VISA</p>
                                </div>
                            ) : null}
                            {isCandidateRatingUpdated && (
                                <JoyProvider>
                                    <JoyTooltip arrow title="Rating is up to date" sx={{ fontSize: "12px" }}>
                                        <CheckIcon
                                            fontSize="large"
                                            sx={{
                                                color: "success.400",
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                            }}
                                        />
                                    </JoyTooltip>
                                </JoyProvider>
                            )}
                            {resume && resume !== "-" ? (
                                <JoyProvider>
                                    <JoyTooltip arrow title="CV" sx={{ fontSize: "12px" }}>
                                        <IconButton onClick={(e) => e.stopPropagation()} target="_blank" size="sm">
                                            <a href={resume} target="_blank" rel="noreferrer">
                                                <DescriptionRoundedIcon htmlColor="#1C274C" />
                                            </a>
                                        </IconButton>
                                    </JoyTooltip>
                                </JoyProvider>
                            ) : null}

                            {/* {profileUrl ? (
                                <Link
                                    to={profileUrl}
                                    target={"_blank"}
                                    className={style["spcard__linkedIn"]}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <img
                                        className={
                                            style["spcard__linkedInLogo"]
                                        }
                                        src={linkedInLogo}
                                        alt="linkedin-logo"
                                    />
                                </Link>
                            ) : null} */}
                            {user?.twilioPhoneNumber && candidateNumber?.length > 0 && (
                                <>
                                    <TwilioVoice candidate={candidate} />
                                    <TwilioLogs candidate={candidate} />
                                </>
                            )}
                            {isSendosoIntegrated && <SendosoGift candidate={candidate} />}

                            {onEditCandidate && (
                                <EditCandidate candidate={candidate} onEditCandidate={onEditCandidate} />
                            )}
                            <LogActivity candidate={candidate} />
                            {isSuperAdmin && (
                                <JoyProvider>
                                    {linkedinUrlStatus && <LinkedInUrlStatusBadge status={linkedinUrlStatus} />}
                                </JoyProvider>
                            )}
                        </Stack>
                        <Stack gap={1} direction="column">
                            {variant === "modal" && !isSuperAdmin && (
                                <Box alignSelf="flex-end">
                                    {/* <FeedbackForm Reviewstatus={stage} id={_id} candidateFeedback={feedback} /> */}
                                    {/* <StatusMenu
                                        candidateId={_id}
                                        variant={variant}
                                        stage={reviewStageObj[reviewStage]}
                                        handleStatusChange={(stage, shortlist1) =>
                                            handleChangeStatus(_id, stage, shortlist1)
                                        }
                                    /> */}
                                    {/* <StatusMenuAnimButton
                                            candidateId={_id}
                                            feedback={feedback}
                                            stage={reviewStageObj[reviewStage]}
                                            isDefaultStageShortlisPlus={candidate?.shortlist1 || false}
                                            handleChangeStatus={(stage, shortlist1) =>
                                                handleChangeStatus(_id, stage, shortlist1)
                                            }
                                        /> */}
                                </Box>
                            )}
                            {variant === "modal" && !isSuperAdmin && detailedStage && orgId === 1074 && (
                                <Box alignSelf="flex-end">
                                    <DetailedProjectStage
                                        projectId={Number(projectId)}
                                        initialStage={{
                                            label: detailedStageOptions[
                                                (detailedStage as DetailedStageOptionKey) || "in_play"
                                            ],
                                            value: (detailedStage as DetailedStageOptionKey) || "in_play",
                                        }}
                                        candidateId={_id}
                                        successCallback={(detailedStage: DetailedStageOptionKey) =>
                                            dispatch(
                                                setCandidateDetailedProjectStatus({
                                                    candidateId: _id,
                                                    detailedStage,
                                                })
                                            )
                                        }
                                    />
                                </Box>
                            )}
                            <Box alignSelf="flex-end" fontSize={"10px"}>
                                {variant === "modal" && <Stage stage={stage as StageType} />}
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack>
                        {showOutReachStatus && (
                            <EmailAndFollowupStatus
                                emailStatus={outreachStatus?.email}
                                followupStatus={outreachStatus?.followUpEmail}
                                outreachTimestamps={outreachTimestamps}
                            />
                        )}
                        {showOutReachStatus && (
                            <LinkedInConnReqAndInMailStatus
                                id={_id}
                                outreachStatus={outreachStatus}
                                projectId={projectId as string}
                                linkedInConnReqStatus={outreachStatus?.connection}
                                inMailStatus={outreachStatus?.inMail}
                                outreachTimestamps={outreachTimestamps}
                            />
                        )}
                        {showOutReachStatus && (
                            <SMSFollowupStatus
                                smsStatus={outreachStatus?.sms}
                                outreachTimestamps={outreachTimestamps}
                            />
                        )}
                    </Stack>
                    {/* {variant === "modal" ? (
                        <Dropdown
                            trigger={["click"]}
                            menu={{
                                items: CANDIDATE_STAGE_ITEMS,
                                onClick: (e: MenuInfo) => {
                                    e.domEvent.stopPropagation();
                                    handleChangeStatus(
                                        _id,
                                        e.key as ReviewStage
                                    );
                                },
                            }}
                        >
                            <div
                                className={style["spcard__candStatus"]}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <p>
                                    {reviewStage
                                        ? reviewStageObj[reviewStage]
                                        : reviewStageObj.TO_BE_REVIEWED}
                                </p>
                                <ArrowDropDownIcon />
                            </div>
                        </Dropdown>
                    ) : null} */}
                    {/* <Stack width="100%">
                        {!!conversationCount?.emailCount && (
                            <p className={style.conversationMedium}>
                                <CheckIcon
                                    sx={{
                                        fontSize: "14px",
                                        marginRight: "10px",
                                    }}
                                />{" "}
                                Email
                            </p>
                        )}
                        {!!conversationCount?.connectionCount && (
                            <p className={style.conversationMedium}>
                                <CheckIcon
                                    sx={{
                                        fontSize: "14px",
                                        marginRight: "10px",
                                    }}
                                />
                                Linkedin Connection
                            </p>
                        )}
                        {!!conversationCount?.inMailCount && (
                            <p className={style.conversationMedium}>
                                <CheckIcon
                                    sx={{
                                        fontSize: "14px",
                                        marginRight: "10px",
                                    }}
                                />
                                Linkedin Inmail
                            </p>
                        )}
                    </Stack> */}
                </div>
            </div>
            {retention?.seenAt && retention.capturedURL && (
                <Typography sx={{ fontSize: "12px", mt: 2 }}>
                    First identified visiting on{" "}
                    <span style={{ fontStyle: "italic", fontWeight: "500" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            // style={{ textDecoration: "none" }}
                            href={retention.capturedURL}
                        >
                            {retention.capturedURL}
                        </a>
                    </span>{" "}
                    on{" "}
                    <span style={{ fontStyle: "italic", fontWeight: "500" }}>
                        {dayjs(retention?.seenAt, "YYYY-MM-DDTHH:mm:ss:SS.ZZ").format("MMMM D YYYY [at] h:mm A")}
                    </span>
                </Typography>
            )}
            {roleWarning || courses ? (
                <div className={style["spcard__relevanceText"]}>
                    {!isEmpty(roleWarning) ? (
                        <>
                            <p>
                                Candidate is at the {roleWarning.role} role for only {roleWarning.months} months. Kindly
                                check the previous job roles to validate the candidature
                            </p>
                            <br />
                        </>
                    ) : null}
                    {courses?.length ? <p>Relevant courses: {courses.map((ele: string) => ele).join(", ")}</p> : null}
                </div>
            ) : null}
            {canShowPrimaryEmailPopup && (
                <Dialog
                    open={canShowPrimaryEmailPopup}
                    onClose={(e: any) => {
                        e.stopPropagation();
                        setCanShowPrimaryEmailPopup(false);
                    }}
                >
                    <DialogTitle>Change primary email !</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure want to change primary email?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                    changeCandidatePrimaryEmail({
                                        candidateId: _id,
                                        email,
                                        primaryEmail: changedPrimaryEmail,
                                    })
                                );
                                setCanShowPrimaryEmailPopup(false);
                            }}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default CandidateProfileCard;
