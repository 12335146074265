import { Typography } from "@mui/material";
import { MRT_TableInstance } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../../components/CustomButton";
import { selectAllCandidatesSearchResults, toggleSelectAllSearchResults } from "../all-candidates.slice";
import { Candidate } from "../all-candidates.types";

type Props = {
    table: MRT_TableInstance<Candidate>;
};

export default function SelectionPrompt({ table }: Props) {
    const isAllPageRowsSelected = table.getIsAllPageRowsSelected();
    const isSomeRowsSelected = table.getIsSomeRowsSelected();
    const dispatch = useDispatch();
    const isAllSelected = useSelector(selectAllCandidatesSearchResults);

    if (!isSomeRowsSelected && !isAllSelected && !isAllPageRowsSelected) {
        return null;
    }

    const handleClick = () => {
        dispatch(toggleSelectAllSearchResults());
    };

    return (
        <Typography
            textAlign="center"
            variant="body2"
            p={1}
            sx={{
                backgroundColor: "rgba(241, 243, 244, 0.871)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center",
            }}
        >
            {isAllSelected
                ? "All contacts have been selected."
                : "Currently, our search results are limited. If you wish to select all results"}
            &nbsp;
            <CustomButton
                onClick={handleClick}
                variant="text"
                sx={{
                    padding: 0,
                    border: 0,
                    backgroundColor: "transparent !important",
                    fontSize: "14px",
                    boxShadow: "none !important",
                    outline: 0,
                }}
            >
                {isAllSelected ? "clear" : "click here"}.
            </CustomButton>
        </Typography>
    );
}
