import { SagaIterator } from "redux-saga";
import { takeLatest, put, call } from "redux-saga/effects";

import API from "../../utils/API";

import { CancelSagas } from "../../utils/saga.utils";
import { setUserDetail, leavePage, setUserCredits } from "./superAdmin.reducer";
import handleException from "../../utils/sentry";

function* getCreditsSaga({
    payload,
}: {
    payload: {
        id: number;
    };
}): SagaIterator {
    try {
        const response = yield call(new API().get, `/organization/get-credit`);

        if (!response?.data) return;

        yield put(setUserCredits(response.data));
    } catch (e: unknown) {
        handleException(e);
        console.error(e);
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(setUserDetail.type, getCreditsSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
