import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";

import {
    handleContactListItemsChangeRowsPerPage,
    handleContactListItemsPageChange,
    selectContactListItemsPage,
    selectContactListItemsRows,
    selectContactListItemsRowsPerPage,
} from "../contact-list-items.slice";

export default function ContactListItemsPagination() {
    const dispatch = useDispatch();
    const rows = useSelector(selectContactListItemsRows);
    const rowsPerPage = useSelector(selectContactListItemsRowsPerPage);
    const page = useSelector(selectContactListItemsPage);

    return (
        <TablePagination
            rowsPerPageOptions={[25, 50, 100, 500]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => dispatch(handleContactListItemsPageChange(newPage))}
            onRowsPerPageChange={(e) => dispatch(handleContactListItemsChangeRowsPerPage(e.target.value))}
        />
    );
}
