import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    MenuItem,
    Modal,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LocationPicker from "./LocationPicker";

import scholarIcon from "../../../assets/img/email-providers-logo/googlescholar.png";
import { getProject } from "../../../pages/allProjects/index.reducer";
import { tooltipSetting } from "../../../pages/analytics-v2/utils";
import { useFetchBetaAICandidatesMutation } from "../../../store/apis/all-candidates/all-candidates.api";
import { selectGeography } from "../../../store/reducers/search/search.slice";
import { addAIcandidatesToProject } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ISampleMessage } from "../../../store/reducers/searchCandidates/searchCandidates.types";
import { setErrorNotification, setSuccessNotification } from "../../Notification/index.reducer";

type Candidate = {
    name: string;
    scholarUrl: string;
    profileUrl: string;
    degreeType: string;
    citations: number | string;
    hIndex: number | string;
    relevanceScore: number;
    rating: any;
    highlight: string;
};

type AISearchModalProps = {
    open: boolean;
    onClose: () => void;
    projectId: string;
};

const AISearchModal: React.FC<AISearchModalProps> = ({ open, onClose, projectId }) => {
    const [inputValue, setInputValue] = useState("");
    const [isFinding, setIsFinding] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [candidates, setCandidates] = useState<Candidate[]>([]);
    const [showCandidateDetails, setShowCandidateDetails] = useState(false);
    const [noCandidatesFound, setNoCandidatesFound] = useState<boolean | null>(null);
    const [searchId, setSearchId] = useState<string>("");
    const [expanded, setExpanded] = useState(true);
    const dispatch = useDispatch();
    const [fetchBetaAICandidates] = useFetchBetaAICandidatesMutation();

    const handleFindClick = async () => {
        setExpanded(false);
        setIsFinding(true);
        setFetchError(false);
        try {
            const { data } = await fetchBetaAICandidates({
                statement: inputValue,
            }).unwrap();

            if (data && data.total === 0) {
                setFetchError(false);
                setNoCandidatesFound(true);
                setShowCandidateDetails(false);
            } else if (data && data.profiles) {
                const mappedCandidates = data.profiles.map((candidate: any) => ({
                    scholarUrl: candidate.scholarUrl,
                    name: candidate.name,
                    citations: candidate.citations !== null ? candidate.citations : "N/A",
                    hIndex: candidate.hIndex !== null ? candidate.hIndex : "N/A",
                    degreeType: candidate.degreeType,
                    profileUrl: candidate.profileUrl,
                    relevanceScore: candidate.relevanceScore,
                    rating: candidate.rating,
                    highlight: candidate.highlight,
                }));
                setCandidates(mappedCandidates);
                setShowCandidateDetails(true);
                setNoCandidatesFound(false);
            }
            setSearchId(data.searchId);
        } catch (error) {
            console.error("Failed to fetch candidates:", error);
            setFetchError(true);
            setShowCandidateDetails(false);
        } finally {
            setIsFinding(false);
        }
    };

    const handleAddAICandidates = () => {
        if (projectId) {
            try {
                dispatch(
                    addAIcandidatesToProject({
                        projectId,
                        searchId,
                        projectName: null,
                        navigate: () => {},
                        canNavigateNextStep: false,
                        onSuccess() {
                            dispatch(
                                getProject({
                                    projectId,
                                    action: getProject.type,
                                })
                            );
                        },
                    })
                );
                onClose();
                setShowCandidateDetails(false);
                dispatch(setSuccessNotification("Adding candidates to project"));
            } catch (error) {
                console.error("Error adding AI candidates to project:", error);
                dispatch(setErrorNotification("Error adding AI candidates to project"));
            }
        }
    };

    const handleGoogleScholarClick = (url: string) => {
        if (url) {
            window.open(url);
        } else {
            dispatch(setErrorNotification("No Google scholar url exists"));
        }
    };

    function SampleMessage(props: ISampleMessage) {
        const { message, onClick } = props;
        return (
            <Typography
                color="#068093"
                fontSize={12}
                p={0.5}
                sx={{
                    border: "1px solid #068093",
                    borderRadius: 1,
                    cursor: "pointer",
                    marginBottom: "5px",
                    width: "94%",
                    marginLeft: "18px",
                }}
                onClick={() => onClick(message)}
            >
                <span style={{ fontSize: "14px" }}>💡</span> <i>{message}</i>
            </Typography>
        );
    }

    function CustomMessage(props: ISampleMessage) {
        const { message, onClick } = props;
        const [role, setRole] = useState("Artificial Intelligence");
        const [location, setLocation] = useState("USA");
        const [position, setPosition] = useState("PhD");
        const { values } = useSelector(selectGeography);

        const roles = [
            "Artificial Intelligence",
            "Machine Learning",
            "Deep Learning",
            "Neural Networks",
            "Generative AI",
            "Computer Vision",
            "Natural Language Processing",
            "Reinforcement Learning",
            "Representation Learning",
            "AI Explainability",
            "Data Efficiency",
            "Conversational AI",
            "Multimodal Data Intelligence",
            "Multitask Learning",
            "Supervised Learning",
            "Unsupervised Learning",
            "Semi Supervised Learning",
            "Transfer Learning",
            "Meta Learning",
            "Graph Neural Networks",
            "Convolutional Neural Networks",
            "Recurrent Neural Networks",
            "Generative Adversarial Networks",
            "Self Supervised Learning",
            "Explainable Ai",
            "Ai Ethics",
            "Ai Safety",
            "Data Science",
            "Medical Image Analysis",
            "Image Recognition",
            "Machine Translation",
            "Speech Recognition",
            "Speech Synthesis",
            "Text To Speech",
            "Information Retrieval",
            "Sentiment Analysis",
            "Language Modeling",
            "Embedding Models",
            "Transformer Models",
        ];

        const handleClick = () => {
            const customizedMessage = `Find top candidates in the fields of ${role} in the location of ${values[0]?.value}.`;
            setInputValue(customizedMessage);
        };

        return (
            <div style={{ display: "flex" }}>
                <Box
                    fontSize={15}
                    p={0.5}
                    sx={{
                        cursor: "pointer",
                        marginBottom: "5px",
                        width: "80%",
                        marginLeft: "18px",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        textAlign: "center",
                        height: "auto",
                    }}
                >
                    <Box fontSize={15} marginLeft={1}>
                        <i>Find top candidates in the field of </i>
                    </Box>

                    <Select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        variant="standard"
                        sx={{
                            mx: 1,
                            fontSize: "14px",
                            width: "120px",
                            maxWidth: "120px",
                            border: "1px solid lightgrey",
                            padding: "3px",
                            borderRadius: "5px",
                        }}
                    >
                        {roles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Select>

                    <Box fontSize={15} marginLeft={1}>
                        <i>in the areas of</i>
                    </Box>

                    <LocationPicker id={"geography"} multiple={false} />
                </Box>

                <Box
                    fontSize={14}
                    p={0.5}
                    sx={{
                        cursor: "pointer",
                        marginBottom: "5px",
                        width: "20%",
                        marginLeft: "18px",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        textAlign: "center",
                        height: "auto",
                        justifyContent: "center",
                        justifyItems: "center",
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={handleClick}
                        sx={{
                            fontSize: "14px",
                            marginLeft: "10px",
                            height: "30px",
                        }}
                    >
                        Use
                    </Button>
                </Box>
            </div>
        );
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                position="absolute"
                top="5%"
                left="54%"
                sx={{
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: 2,
                    maxHeight: "88vh",
                    width: "575px",
                    overflow: "hidden",
                    transform: "translateX(-50%)",
                    outline: "none",
                }}
            >
                <Box display="flex" alignItems="center" sx={{ position: "relative" }}>
                    <Typography variant="h6" sx={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>
                        AI Recruiter: Find the top AI talent
                        <Tooltip
                            {...tooltipSetting}
                            title={
                                "EasySource helps you uncover hard-to-find, highly qualified, and sought after talent in artificial intelligence, machine learning, and other similar fields"
                            }
                        >
                            <InfoIcon
                                fontSize="small"
                                color="disabled"
                                sx={{ marginLeft: "6px", marginBottom: "-3px" }}
                            />
                        </Tooltip>
                    </Typography>

                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        sx={{ position: "absolute", right: 0, marginBottom: "20px" }}
                    >
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>

                {expanded && (
                    <>
                        <Typography sx={{ textAlign: "left", marginLeft: "18px", fontSize: "14px" }}>
                            Sample queries
                        </Typography>
                        <SampleMessage
                            message="AI researchers with a background in AI Architecture and understand LLMs in US."
                            onClick={(val) => setInputValue(val)}
                        />
                        <SampleMessage
                            message="Researchers experienced in Generative AI and Natural Language Processing."
                            onClick={(val) => setInputValue(val)}
                        />
                        <SampleMessage
                            message="PhD students who have researched in General Adverserial Networks in the US."
                            onClick={(val) => setInputValue(val)}
                        />

                        <Typography sx={{ textAlign: "left", marginLeft: "18px", fontSize: "14px", marginTop: "10px" }}>
                            Customize your query
                        </Typography>

                        <CustomMessage
                            message="I am looking for XYZ engineer who have skills in XYZ from XYZ."
                            onClick={(val) => setInputValue(val)}
                        />
                    </>
                )}
                <Box display="flex" gap={1} mt={2} sx={{ marginBottom: "20px" }}>
                    <TextField
                        variant="outlined"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Enter search query..."
                        sx={{
                            height: "40px",
                            flexGrow: 1,
                            paddingLeft: "18px",
                            paddingRight: "18px",
                            backgroundColor: "white",
                        }}
                        InputProps={{ sx: { height: "100%" } }}
                    />
                    <Button
                        variant="contained"
                        onClick={handleFindClick}
                        sx={{ height: "40px", marginRight: "15px" }}
                        disabled={!inputValue || isFinding}
                    >
                        {isFinding ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Find"}
                    </Button>
                </Box>

                {isFinding ? (
                    <Typography
                        sx={{
                            textAlign: "center",
                            marginTop: "5px",
                            fontSize: "12px",
                            fontStyle: "italic",
                            color: "#5a5656",
                        }}
                    >
                        This should take a few moments...
                    </Typography>
                ) : null}

                {fetchError ? (
                    <>
                        <Typography
                            sx={{
                                textAlign: "center",
                                marginTop: "5px",
                                fontSize: "12px",
                                fontStyle: "italic",
                                color: "#5a5656",
                            }}
                        >
                            Results were not fetched successfully at this point in time.
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontSize: "12px",
                                fontStyle: "italic",
                                color: "#5a5656",
                            }}
                        >
                            Please try again in a while or contact your Account Manager
                        </Typography>
                    </>
                ) : null}

                {!fetchError && noCandidatesFound === true && !isFinding ? (
                    <Typography
                        sx={{
                            textAlign: "center",
                            marginTop: "5px",
                            fontSize: "12px",
                            fontStyle: "italic",
                            color: "#5a5656",
                        }}
                    >
                        Unfortunately, no relevant results were found for your query. Please try changing and refreshing
                        your results.
                    </Typography>
                ) : null}

                {showCandidateDetails && (
                    <>
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: "center",
                                padding: "10px",
                                borderTop: "2px solid lightgrey",
                                paddingBottom: "10px",
                            }}
                        >
                            Candidate details ({candidates?.length} candidates)
                        </Typography>
                        <Container sx={{ maxHeight: "400px", overflowY: "auto", scrollBehavior: "smooth" }}>
                            {candidates.map((candidate, index) => (
                                <Box
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                    border="1px solid rgba(0, 0, 0, 0.2)"
                                    borderRadius={2}
                                    p={2}
                                    mt={2}
                                    sx={{ backgroundColor: "#f8fcfd" }}
                                >
                                    <Box flexGrow={1}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {candidate?.name && (
                                                <Typography
                                                    sx={{
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        color: "black",
                                                        display: "inline",
                                                    }}
                                                >
                                                    {candidate.name?.length > 20
                                                        ? `${candidate.name.substring(0, 20)}...`
                                                        : candidate.name}

                                                    {candidate?.scholarUrl && (
                                                        <Tooltip title="Google scholar" placement="bottom">
                                                            <img
                                                                src={scholarIcon}
                                                                alt="icon"
                                                                width={20}
                                                                height={20}
                                                                style={{ paddingTop: "5px", marginLeft: "5px" }}
                                                                onClick={() =>
                                                                    handleGoogleScholarClick(candidate.scholarUrl)
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            )}

                                            {candidate?.highlight && (
                                                <span
                                                    style={{
                                                        fontStyle: "italic",
                                                        fontSize: "13px",
                                                        fontWeight: "500",
                                                        padding: "1.5px",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#dfff0b",
                                                        paddingLeft: "7px",
                                                        paddingRight: "7px",
                                                    }}
                                                >
                                                    {candidate.highlight}
                                                </span>
                                            )}
                                        </Box>

                                        {candidate?.degreeType && (
                                            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {" "}
                                                    {candidate.degreeType}{" "}
                                                </span>
                                            </Typography>
                                        )}

                                        {candidate?.rating && (
                                            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                Rating:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.rating}
                                                </span>
                                            </Typography>
                                        )}

                                        {candidate?.relevanceScore && (
                                            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                Relevance :{" "}
                                                {candidate.relevanceScore === 1 && (
                                                    <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>High</span>
                                                )}
                                                {candidate.relevanceScore !== 1 && (
                                                    <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                        Moderate
                                                    </span>
                                                )}
                                            </Typography>
                                        )}

                                        {candidate?.citations && (
                                            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                Citations:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.citations}
                                                </span>{" "}
                                            </Typography>
                                        )}

                                        {candidate?.hIndex && (
                                            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                H-Index:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.hIndex}
                                                </span>
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Container>

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            mt={4}
                            sx={{
                                position: "fixed",
                                bottom: 1,
                                right: 20,
                                backgroundColor: "white",
                                width: "100%",
                                paddingTop: "5px",
                                paddingBottom: "4px",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleAddAICandidates}
                                sx={{
                                    height: "35px",
                                    alignSelf: "flex-end",
                                }}
                            >
                                Add list to project
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default AISearchModal;
