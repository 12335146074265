import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../../components/Loader/Loader";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { exportToCsvc } from "../../../../utils/helper";
import styles from "./Filters.module.css";
import {
    getCompanyLinkedinUrlList,
    scrapCompaniesLinkedin,
    selectOtherLinkedinUrlList,
    selectSelectedLinkedinUrl,
    setSelectedLinkedinUrl,
    setShowScrapeCompanyPopup,
} from "./Filters.reducer";
import { optionType } from "./Filters.type";

const CompanyScrapeUrl = () => {
    const dispatch = useDispatch();
    const otherLinkedinUrlList = useSelector(selectOtherLinkedinUrlList);
    const selectedLinkedinUrl: string = useSelector(selectSelectedLinkedinUrl);
    const isSearchLoading = useSelector(checkIfLoading(getCompanyLinkedinUrlList.type));
    const [error, setError] = useState({
        url: "",
        cookie: "",
        fromPage: "",
        companyCount: "",
    });
    const [cookie, setCookie] = useState("");
    const [fromPage, setFromPage] = useState(1);
    const [companyCount, setCompanyCount] = useState(10);

    const onProceed = () => {
        const newError: any = {};
        if (!selectedLinkedinUrl) newError.url = "Should be valid link";
        if (!cookie) newError.cookie = "Should be valid cookie";
        if (!fromPage || fromPage < 1) newError.fromPage = "From page should be greater than 0";
        if (!companyCount || companyCount < 1) newError.companyCount = "Profile Count page should be greater than 0";
        setError(newError);
        if (isEmpty(newError)) {
            dispatch(
                scrapCompaniesLinkedin({
                    linkedinUrl: selectedLinkedinUrl,
                    cookie,
                    fromPage,
                    companyCount,
                })
            );
        }
    };

    return (
        <Dialog
            open={true}
            onClose={() => dispatch(setShowScrapeCompanyPopup(false))}
            classes={{
                paper: styles.popupContainer,
            }}
        >
            <DialogTitle>Company scrape</DialogTitle>
            <DialogContent>
                {isSearchLoading ? (
                    <Loader />
                ) : selectedLinkedinUrl ? (
                    <>
                        <Stack className={styles.fieldContainer}>
                            <TextField
                                placeholder="Linkedin url"
                                value={selectedLinkedinUrl}
                                fullWidth
                                onChange={(e) => dispatch(setSelectedLinkedinUrl(e.target.value))}
                            />
                            <Typography className={styles.error} variant="body2">
                                {error.url}
                            </Typography>
                        </Stack>
                        <Stack className={styles.fieldContainer}>
                            <TextField
                                placeholder="Linkedin cookie"
                                fullWidth
                                onChange={(e) => setCookie(e.target.value)}
                            />
                            <Typography className={styles.error} variant="body2">
                                {error.cookie}
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row">
                            <Stack className={styles.fieldContainer} sx={{ flex: 1 }}>
                                <TextField
                                    placeholder="From page"
                                    type="number"
                                    value={fromPage}
                                    onChange={(e) => setFromPage(parseInt(e.target.value))}
                                    sx={{ marginRight: "15px" }}
                                    label="From page"
                                />
                                <Typography className={styles.error} variant="body2">
                                    {error.fromPage}
                                </Typography>
                            </Stack>
                            <Stack className={styles.fieldContainer} sx={{ flex: 1 }}>
                                <TextField
                                    placeholder="Company count"
                                    type="number"
                                    value={companyCount}
                                    onChange={(e) => setCompanyCount(parseInt(e.target.value))}
                                    label="Profile count"
                                />
                                <Typography className={styles.error} variant="body2">
                                    {error.companyCount}
                                </Typography>
                            </Stack>
                        </Stack>
                    </>
                ) : (
                    <DialogContentText>Oops no urls found</DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => dispatch(setShowScrapeCompanyPopup(false))}>
                    Close
                </Button>
                <Button variant="contained" onClick={onProceed} disabled={!selectedLinkedinUrl || !cookie}>
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { CompanyScrapeUrl };
