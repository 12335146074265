import { iDBConfig } from "./config";
import { IndexedDBConfig, StoreName } from "./interface";

function validateStore(db: IDBDatabase, storeName: string) {
    return db.objectStoreNames.contains(storeName);
}

export function validateBeforeTransaction(db: IDBDatabase, storeName: StoreName, reject: Function) {
    if (!db) {
        reject("Queried before opening connection");
    }
    if (!validateStore(db, storeName)) {
        reject(`Store ${storeName} not found`);
    }
}

export function createTransaction(
    db: IDBDatabase,
    dbMode: IDBTransactionMode,
    currentStore: StoreName,
    resolve: any,
    reject?: any,
    abort?: any
): IDBTransaction {
    const tx: IDBTransaction = db.transaction(currentStore, dbMode);
    tx.onerror = reject;
    tx.oncomplete = resolve;
    tx.onabort = abort;
    return tx;
}

export async function getConnection(): Promise<IDBDatabase> {
    const config: IndexedDBConfig = iDBConfig;
    const idbInstance = typeof window !== "undefined" ? window.indexedDB : null;

    return new Promise<IDBDatabase>((resolve, reject) => {
        if (idbInstance) {
            const request: IDBOpenDBRequest = idbInstance.open(config.databaseName, config.version);

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (e: any) => {
                reject(e.target.error.name);
            };

            request.onupgradeneeded = (e: any) => {
                const db = e.target.result;
                // @ts-ignore
                config.stores.forEach((s) => {
                    if (!db.objectStoreNames.contains(s.name)) {
                        const store = db.createObjectStore(s.name, s.id);
                        s.indices.forEach((c) => {
                            store.createIndex(c.name, c.keyPath, c.options);
                        });
                    }
                });
                db.close();
                // @ts-ignore
                resolve(undefined);
            };
        } else {
            reject("Failed to connect");
        }
    });
}

export function getActions<T>(currentStore: StoreName) {
    return {
        getCount() {
            return new Promise<T[]>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readonly", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const request = objectStore.count();
                        request.onsuccess = (e: any) => {
                            resolve(e.target.result as T[]);
                        };
                    })
                    .catch(reject);
            });
        },
        getByID(id: string | number) {
            return new Promise<T>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readonly", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const request = objectStore.get(id);
                        request.onsuccess = (e: any) => {
                            resolve(e.target.result as T);
                        };
                    })
                    .catch(reject);
            });
        },
        getOneByKey(keyPath: string, value: string | number) {
            return new Promise<T | undefined>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readonly", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const index = objectStore.index(keyPath);
                        const request = index.get(value);
                        request.onsuccess = (e: any) => {
                            resolve(e.target.result);
                        };
                    })
                    .catch(reject);
            });
        },
        getManyByKey(keyPath: string, value: string | number) {
            return new Promise<T[]>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readonly", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const index = objectStore.index(keyPath);
                        const request = index.getAll(value);
                        request.onsuccess = (e: any) => {
                            resolve(e.target.result);
                        };
                    })
                    .catch(reject);
            });
        },
        getAll() {
            return new Promise<T[]>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readonly", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const request = objectStore.getAll();
                        request.onsuccess = (e: any) => {
                            resolve(e.target.result as T[]);
                        };
                    })
                    .catch(reject);
            });
        },

        add(value: T, key?: any) {
            return new Promise<number>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readwrite", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const request = objectStore.add(value, key);
                        request.onsuccess = (e: any) => {
                            (tx as any)?.commit?.();
                            resolve(e.target.result);
                        };
                    })
                    .catch(reject);
            });
        },

        update(value: T, key?: any) {
            return new Promise<any>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readwrite", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const request = objectStore.put(value, key);
                        request.onsuccess = (e: any) => {
                            (tx as any)?.commit?.();
                            resolve(e.target.result);
                        };
                    })
                    .catch(reject);
            });
        },

        updateAll(value: T[]) {
            return new Promise<any>((resolve, reject) => {
                getConnection().then((db) => {
                    validateBeforeTransaction(db, currentStore, reject);
                    const tx = createTransaction(db, "readwrite", currentStore, resolve, reject);
                    const objectStore = tx.objectStore(currentStore);
                    for (const v of value) {
                        objectStore.put(v);
                    }
                    tx.oncomplete = (e: any) => {
                        resolve(e);
                    };
                });
            });
        },

        deleteByID(id: any) {
            return new Promise<any>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readwrite", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const request = objectStore.delete(id);
                        request.onsuccess = (e: any) => {
                            (tx as any)?.commit?.();
                            resolve(e);
                        };
                    })
                    .catch(reject);
            });
        },

        deleteAll() {
            return new Promise<any>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readwrite", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const request = objectStore.clear();
                        request.onsuccess = (e: any) => {
                            (tx as any)?.commit?.();
                            resolve(e);
                        };
                    })
                    .catch(reject);
            });
        },

        openCursor(cursorCallback: any, keyRange?: IDBKeyRange) {
            return new Promise<IDBCursorWithValue | void>((resolve, reject) => {
                getConnection()
                    .then((db) => {
                        validateBeforeTransaction(db, currentStore, reject);
                        const tx = createTransaction(db, "readonly", currentStore, resolve, reject);
                        const objectStore = tx.objectStore(currentStore);
                        const request = objectStore.openCursor(keyRange);
                        request.onsuccess = (e) => {
                            cursorCallback(e);
                            resolve();
                        };
                    })
                    .catch(reject);
            });
        },
    };
}
