import { Alert, Button, Snackbar } from "@mui/material";
import { Call, Device } from "@twilio/voice-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useCallContext } from "./TwilloCallContext";

import handleException from "../../../../utils/sentry";
import { selectUser } from "../../../Signin/Signin.reducer";
import { getTwilloCall, selectTwilloToken } from "../../index.reducer";

const TwilloCallComponent: React.FC = () => {
    const { incomingCall, setIncomingCall, device, setDevice, callInProgress, setCallInProgress, setCallConnected } =
        useCallContext();
    const dispatch = useDispatch();
    const [snackbarState, setSnackbarState] = useState<{
        open: boolean;
        message: string;
        severity: "info" | "success" | "warning";
        action?: JSX.Element;
    }>({ open: false, message: "", severity: "info" });
    const [callStartTime, setCallStartTime] = useState<Date | null>(null);
    const [callDuration, setCallDuration] = useState<string>("00:00");

    const twilioToken = useSelector(selectTwilloToken);
    const user = useSelector(selectUser);

    useEffect(() => {
        const initializeClient = async () => {
            dispatch(getTwilloCall({}));
        };
        if (user?.twilioPhoneNumber) {
            initializeClient();
        }
    }, [dispatch, user]);

    useEffect(() => {
        console.log("twilioToken: ", twilioToken, device);
        if (twilioToken && !device) {
            const setupDevice = (token: string) => {
                console.log("Initializing Twilio Device...");

                // 'tokenWillExpire' event will be emitted 1 Minutes before the AccessToken expires
                const newDevice = new Device(token, { tokenRefreshMs: 1 * 1000 * 60 });

                newDevice.on("registered", () => {
                    console.log("Twilio Device is ready.");
                });

                newDevice.on("tokenWillExpire", () => {
                    console.log("tokenWillExpire: Token will expire called, refreshing...");
                    dispatch(getTwilloCall({ onSuccess: (token: string) => newDevice.updateToken(token) }));
                });
                newDevice.on("error", (error) => {
                    if ([20104, 31009, 31005, 20101, 31000].includes(error.code)) {
                        console.log("error: Token expired called, refreshing...");
                        dispatch(getTwilloCall({ onSuccess: (token: string) => newDevice.updateToken(token) }));
                    } else {
                        handleException(error);
                    }
                });

                newDevice.on("incoming", (call: Call) => {
                    console.log(`Incoming call from ${call.parameters.From} with SID ${call.parameters.CallSid}`);
                    if (!incomingCall) {
                        setIncomingCall(call);
                        setSnackbarState({
                            open: true,
                            message: `Incoming call from: ${call.parameters.From}`,
                            severity: "info",
                            action: (
                                <>
                                    <Button color="inherit" size="small" onClick={handleAccept(call)}>
                                        Accept
                                    </Button>
                                    <Button color="inherit" size="small" onClick={handleReject(call)}>
                                        Reject
                                    </Button>
                                </>
                            ),
                        });
                    }

                    const handleCallDisconnect = () => {
                        console.log("Call disconnected.");
                        setSnackbarState({
                            open: true,
                            message: "Call Ended",
                            severity: "warning",
                        });
                        setCallInProgress(false);
                        setCallConnected(false);
                        setCallStartTime(null);
                        setCallDuration("00:00");
                        setIncomingCall(null);
                        device?.disconnectAll();
                    };

                    call.on("disconnect", handleCallDisconnect);
                    call.on("cancel", handleCallDisconnect);

                    return () => {
                        call.off("disconnect", handleCallDisconnect);
                        call.off("cancel", handleCallDisconnect);
                    };
                });

                newDevice.on("closed", () => {
                    device?.disconnectAll();
                    setCallInProgress(false);
                    setIncomingCall(null);
                    setSnackbarState({
                        open: true,
                        message: "Call Ended",
                        severity: "warning",
                    });
                });

                newDevice.on("disconnect", () => {
                    setCallInProgress(false);
                    setCallConnected(false);
                    device?.disconnectAll();
                    setIncomingCall(null);
                    setSnackbarState({
                        open: true,
                        message: "Call Ended",
                        severity: "warning",
                    });
                });

                setDevice(newDevice);
                newDevice.register();
            };
            setupDevice(twilioToken);
        }
    }, [twilioToken, device, dispatch, incomingCall, setCallInProgress, setCallConnected, setDevice, setIncomingCall]);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (callInProgress && callStartTime) {
            timer = setInterval(() => {
                const duration = Math.floor((Date.now() - callStartTime.getTime()) / 1000);
                const minutes = String(Math.floor(duration / 60)).padStart(2, "0");
                const seconds = String(duration % 60).padStart(2, "0");
                setCallDuration(`${minutes}:${seconds}`);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [callInProgress, callStartTime]);

    const handleAccept = (call: Call) => () => {
        if (call) {
            call.accept();
            setCallInProgress(true);
            setCallStartTime(new Date());
            setSnackbarState({
                open: true,
                message: `Call in progress ${callDuration}`,
                severity: "success",
                action: (
                    <Button color="inherit" size="small" onClick={() => handleEndCall(call)}>
                        End Call
                    </Button>
                ),
            });
        }
    };

    const handleReject = (call: Call) => () => {
        if (call) {
            call.reject();
            setIncomingCall(null);
            setCallInProgress(false);
            setCallDuration("00:00");
            setSnackbarState({
                open: true,
                message: "Call Rejected",
                severity: "warning",
            });
        }
    };

    const handleEndCall = (call) => {
        call.disconnect();
        device?.disconnectAll();
        setCallInProgress(false);
        setCallStartTime(null);
        setCallDuration("00:00");
        setIncomingCall(null);
        setSnackbarState({
            open: true,
            message: "Call Ended",
            severity: "warning",
        });
    };

    useEffect(() => {
        if (callInProgress) {
            setSnackbarState({
                ...snackbarState,
                message: `Call in progress ${callDuration}`,
            });
        }
    }, [callDuration, callInProgress]);

    return (
        <Snackbar
            open={snackbarState.open}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setSnackbarState({ message: "", severity: "info", open: false })}
            autoHideDuration={snackbarState.severity === "warning" ? 3000 : null}
            ClickAwayListenerProps={{ onClickAway: () => null }}
        >
            <Alert severity={snackbarState.severity} action={snackbarState.action}>
                {snackbarState.message}
            </Alert>
        </Snackbar>
    );
};

export default TwilloCallComponent;
