import { Bell, Blocks, Contact, Sparkles, UserRoundSearch } from "lucide-react";

import { cn } from "@/lib/utils";

const notificationIconMap = {
    ATS: (
        <div className="bg-red-100 h-full w-[40px] rounded-sm flex justify-center items-center min-w-10">
            <Blocks className="h-5 w-5 text-red-600" />
        </div>
    ),
    VETTING: (
        <div className="bg-purple-100 h-full w-[40px] rounded-sm flex justify-center items-center min-w-10">
            <Sparkles className="h-5 w-5 text-purple-600" />
        </div>
    ),
    SOURCING: (
        <div className="bg-emerald-100 h-full w-[40px] rounded-sm flex justify-center items-center min-w-10">
            <UserRoundSearch className="h-5 w-5 text-emerald-600" />
        </div>
    ),
    ENRICHMENT: (
        <div className="bg-fuchsia-100 h-full w-[40px] rounded-sm flex justify-center items-center min-w-10">
            <Contact className="h-5 w-5 text-fuchsia-600" />
        </div>
    ),
    DEFAULT: (
        <div className="bg-blue-50 h-full w-[40px] rounded-sm flex justify-center items-center min-w-10">
            <Bell className="h-5 w-5 text-blue-600" />
        </div>
    ),
};

const notificationTypeChipColorMap = {
    SOURCING: "border-transparent bg-emerald-100",
    VETTING: "border-transparent bg-purple-100",
    ATS: "bg-red-100",
    ENRICHMENT: "bg-fuchsia-100",
    DEFAULT: "bg-blue-50",
};

export function notificationIcons(notificationType) {
    return notificationIconMap[notificationType] || notificationIconMap.DEFAULT;
}

export function notificationTypeChips(notificationType) {
    return (
        <span
            className={cn(
                "text-[8px] px-2 py-0 h-[16px] rounded-md text-center flex items-center font-medium",
                notificationTypeChipColorMap[notificationType] || notificationTypeChipColorMap.DEFAULT
            )}
        >
            {notificationType}
        </span>
    );
}
