import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { ReferTo, useFetchOrganizationMembersQuery } from "../../../../../store/apis/all-candidates/all-candidates.api";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import { useCreateActivityFormState } from "../NotesTab";
import { CustomTextField } from "../../../../all-candidates/components/CustomTextField";
import { CustomFormHelperText } from "../../../../../components/CustomFormHelperText";
import { useAutocompleteProps } from "../../../../../components/useCustomAutocomplete";

export default function AssignedToMenu() {
    const { isLoading, data = [] } = useFetchOrganizationMembersQuery();
    const { getAutoCompleteProps } = useAutocompleteProps<ReferTo>();
    const { control } = useCreateActivityFormState();

    const defaultProps = getAutoCompleteProps(isLoading);

    return (
        <Controller
            control={control}
            name="referTo"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <Autocomplete
                            {...defaultProps}
                            sx={{ ...defaultProps?.sx, width: "180px" }}
                            disabled={isSubmitting}
                            value={value}
                            isOptionEqualToValue={(option, value) => option._id === value?._id}
                            getOptionLabel={(option) => option.name}
                            options={data.filter((i) => i._id !== value?._id)}
                            onChange={(e, v) => {
                                e.stopPropagation();
                                if (v) {
                                    onChange(v);
                                }
                            }}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <CustomTextField
                                    {...params}
                                    placeholder="Select assignee"
                                    label="Assigned to"
                                    error={error !== undefined}
                                    legendWidth={70}
                                    isLoading={isLoading}
                                    inputProps={{ onClick: (e) => e.stopPropagation(), ...params.inputProps }}
                                />
                            )}
                        />
                        {error !== undefined && (
                            <CustomFormHelperText>{error?.message || "Please select assignee"}</CustomFormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}
