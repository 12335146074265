import { BarChart } from "@mui/x-charts/BarChart";
import { useSelector } from "react-redux";

import EmptyChartState from "./EmptyChartState";

import { checkEasySource } from "../../../../Signin/Signin.reducer";
import { defaultChartColors, defaultChartSetting } from "../../../utils";
import { FunnelViewProps } from "../FunnelView";

interface SourceBarChartProps extends FunnelViewProps {}

export default function SourceBarChart(props: SourceBarChartProps) {
    const { selectedOverview, data } = props;
    const isEasySource = useSelector(checkEasySource);

    const colorMap = data?.graphs?.sourceGraph?.["relevantCandidates"]?.reduce((acc: any, item: any, index: number) => {
        const { _id } = item;
        const key = !_id ? (isEasySource ? "EasySource" : "EasyGrowth") : _id;
        acc[key] = defaultChartColors[index];
        return acc;
    }, {});

    if (!selectedOverview) return <EmptyChartState>Select funnel stage from above to display split</EmptyChartState>;

    const series = data?.graphs?.sourceGraph?.[selectedOverview["key"]] ?? [];

    if (!series.length) return <EmptyChartState>No split data is available for this stage</EmptyChartState>;

    const groupSeries = series.reduce((acc: any, item: any) => {
        const { _id, count } = item;
        const key = !_id ? (isEasySource ? "EasySource" : "EasyGrowth") : _id;
        if (acc[key]) {
            acc[key] += count;
        } else {
            acc[key] = count;
        }
        return acc;
    }, {});

    const formattedSeries = Object.entries(groupSeries).map(([key, value]) => ({
        _id: key,
        count: value,
        color: colorMap[key],
    }));

    return (
        <BarChart
            series={formattedSeries.map((item: any) => ({
                data: [item.count],
                stack: "A",
                label: item._id,
                ...(item.color && { color: item.color }),
            }))}
            tooltip={{ trigger: "item" }}
            layout="horizontal"
            margin={{ left: 0, top: 5, bottom: 0 }}
            {...{ ...defaultChartSetting, height: 50 }}
            slotProps={{
                bar: {
                    rx: 5,
                },
            }}
        />
    );
}
