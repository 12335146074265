import { useState } from "react";
import { useDispatch } from "react-redux";

import { setSuccessNotification } from "../components/Notification/index.reducer";
import handleException from "../utils/sentry";

type CopiedValue = string | null;
type CopyFn = (text: string, message?: string) => Promise<boolean>; // Return success

type Callbacks = {
    onSuccessfulCopy?: (text: string) => void;
    onError?: (text: string) => void;
};

function useCopyToClipboard(callbacks?: Callbacks): [CopiedValue, CopyFn] {
    const [copiedText, setCopiedText] = useState<CopiedValue>(null);
    const dispatch = useDispatch();

    const copy: CopyFn = async (text, message = "Text Copied Successfully") => {
        if (!navigator?.clipboard) {
            console.warn("Clipboard not supported");
            return false;
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text);
            if (callbacks?.onSuccessfulCopy) {
                callbacks?.onSuccessfulCopy(text);
            } else {
                dispatch(setSuccessNotification(message));
            }
            setCopiedText(text);
            return true;
        } catch (error) {
            handleException(error);
            if (callbacks?.onError) {
                callbacks?.onError(text);
            } else {
                console.warn("Copy failed", error);
            }
            setCopiedText(null);
            return false;
        }
    };

    return [copiedText, copy];
}

export default useCopyToClipboard;
