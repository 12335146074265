import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import { useSelector } from "react-redux";

import Inbox from "./components/Inbox";
import LinkedinWorkflowContextProvider from "./Context";

import { ErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import Navbar from "../../components/Navbar/Navbar";
import { checkLinkedinOutreachEnabled, isSuperAdminUser } from "../Signin/Signin.reducer";

export default function LinkedinWorkflow() {
    const linkedinOutreachEnabled = useSelector(checkLinkedinOutreachEnabled);
    const isSuperAdmin = useSelector(isSuperAdminUser);

    if (!linkedinOutreachEnabled && !isSuperAdmin) {
        return null;
    }

    return (
        <>
            <ErrorBoundary>
                <LinkedinWorkflowContextProvider>
                    <Box sx={{ display: { lg: "block", xs: "none" } }}>
                        <Navbar />
                    </Box>
                    <CssVarsProvider disableTransitionOnChange>
                        <CssBaseline />
                        <Box sx={{ display: "flex" }}>
                            <Box component="main" className="MainContent" sx={{ flex: 1 }}>
                                <Inbox />
                            </Box>
                        </Box>
                    </CssVarsProvider>
                </LinkedinWorkflowContextProvider>
            </ErrorBoundary>
        </>
    );
}
