import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { fetchBaseQueryWithZod } from "../utils";

export type submitBody = {
    status: string;
    inviteId: string;
    name: string;
    role: string;
};

enum Queries {
    INVITATION = "Invititation",
}

export const invitationsApi = createApi({
    reducerPath: "invitationsApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [Queries.INVITATION],
    endpoints: (builder) => ({
        getInvitation: builder.query<any, string>({
            query(invitationId) {
                return {
                    // url: `/api/invitations/${invitationId}`,
                    url: `/invitations/invitePage${`?invitationId=${invitationId}`}`,
                    method: "GET",
                };
            },
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),

        submitInvitation: builder.mutation<any, submitBody>({
            query(body) {
                return {
                    url: `/invitations/invitation-response`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [Queries.INVITATION],
        }),
    }),
});

export const { useGetInvitationQuery, useSubmitInvitationMutation } = invitationsApi;
