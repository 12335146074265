import { CSSProperties, useCallback, useMemo, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { Stack, Typography } from "@mui/material";
import { FileRejection, useDropzone, type FileWithPath } from "react-dropzone";

import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../../utils/helper";

const MAX_FILE_UPLOAD_LIMIT = 50;

type ZipUploadProps = {
    handleZipUpload: (key: File[]) => void;
    style?: CSSProperties;
    text?: string;
};

export default function ZipUpload({ handleZipUpload, text, ...rest }: ZipUploadProps) {
    const [error, setError] = useState<string | null>(null);

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (fileRejections.length > MAX_FILE_UPLOAD_LIMIT) {
            setError(
                "You can upload up to 50 individual files at once. To upload more than 50 files, please upload a .zip folder containing all the files."
            );
        } else {
            setError(null);
        }
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        });
        handleZipUpload(files);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "application/zip": [".zip"],
                "application/pdf": [".pdf"],
                "application/msword": [".doc"],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            },
            multiple: true,
            onDrop,
            maxFiles: MAX_FILE_UPLOAD_LIMIT,
            maxSize: 50000000,
        });

    const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
        <Stack key={file.path} direction={"row"} alignItems={"center"} spacing={0.5}>
            <DescriptionIcon />
            <Typography>{file.path}</Typography>
        </Stack>
    ));

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...rest.style,
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive
                    ? "Drop CVs here ..."
                    : text ?? "Click to upload your CVs or drag and drop (Allowed formats: .pdf, .doc, .docx, .zip)"}
            </div>
            {error && (
                <Typography fontSize={"13px"} color={"red"}>
                    *{error}
                </Typography>
            )}
            <Stack maxHeight={300} overflow={"auto"} mt={1} spacing={0.5}>
                {acceptedFileItems}
            </Stack>
        </>
    );
}
