import { useEffect, useState } from "react";
import { Button, Stack } from "@mui/joy";

import { TextField, InputAdornment, Box, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';
import { CountryCodes } from "../../../../constant/countryCodes";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { MaterialProvider } from "../../../../components/MaterialProvider";

interface IFilterMenuProps {
    minPrice: string;
    setMinPrice: any;
    maxPrice: string;
    setMaxPrice: any;
    clearFilter: any;
    selectedRange: string;
    handleRangeChange: any;
    selectedCountryCodes: any;
    setSelectedCountryCodes: any;
    setApplyCountry: any;
    filterGifts: any;
    candidate: any;
}
    //if max and minPrice changes and doesn't match the selected range, set selected range to custom
    const predefinedRanges = {
        '0-25': [0, 25],
        '25-50': [25, 50],
        '50-75': [50, 75],
        '75-100': [75, 100],
        '100-200': [100, 200],
    };

export default function FilterMenu({ minPrice, setMinPrice, maxPrice, setMaxPrice, clearFilter, selectedRange, handleRangeChange, selectedCountryCodes, setSelectedCountryCodes, setApplyCountry, filterGifts, candidate }: IFilterMenuProps) {

    const [focused, setFocused] = useState<string>('');
    useEffect(() => {
        const matchedRange = Object.entries(predefinedRanges).find(([key, range]) => {
            const [min, max] = range;
            return (
                (parseInt(minPrice) === min) &&
                (parseInt(maxPrice) === max)
            );
        });

        if (matchedRange) {
            handleRangeChange({ target: { value: matchedRange[0] } });
        } else if (selectedRange !== 'custom' && selectedRange !== '') {
            handleRangeChange({ target: { value: 'custom' } });

        }
    }, [minPrice, maxPrice]);



    return (
        <div>
            <Stack sx={{ display: 'flex', flexDirection: "col", flexWrap: "wrap", px: "30px" }}>
                <Stack sx={{ width: 200, height: 320, margin: "20px", borderRadius: "sm", backgroundColor: "white" }}>
                    <div>


                        <MaterialProvider>


                            <Box>
                                <Typography variant="h6" gutterBottom>Price range</Typography>
                                <Box mb={2}>
                                    <TextField
                                        label="Minimum price"
                                        variant="outlined"
                                        // type="number"
                                        value={minPrice}
                                        onChange={(e) => setMinPrice(e.target.value)}
                                        size="small"
                                        sx={{ mr: 2 }}
                                        onFocus={() => setFocused('min')}
                                        onBlur={() => setFocused('')}
                                        InputLabelProps={{
                                            shrink: focused === 'min' || minPrice !== '' ? true : false,
                                            sx: {
                                                ml: focused === 'min' || minPrice !== '' ? 0 : 2,
                                            }
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />
                                    <Typography variant="caption" sx={{ ml: 2, color: 'text.secondary' }}>to</Typography>

                                    <TextField
                                        label="Maximum price"
                                        variant="outlined"
                                        // type="number"
                                        size="small"
                                        value={maxPrice}
                                        onChange={(e) => setMaxPrice(e.target.value)}
                                        sx={{ mr: 2, mt: 2 }}
                                        onFocus={() => setFocused('max')}
                                        onBlur={() => setFocused('')}
                                        InputLabelProps={{
                                            shrink: focused === 'max' || maxPrice !== '' ? true : false,
                                            sx: {
                                                ml: focused === 'max' || maxPrice !== '' ? 0 : 2,
                                            }
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />

                                </Box>
                                <RadioGroup value={selectedRange} onChange={handleRangeChange}>
                                    {
                                        Object.entries(predefinedRanges).map(([key, range]) => {
                                            const [min, max] = range;
                                            return (
                                                <FormControlLabel key={key} value={key} control={<Radio />} label={`$${min} to $${max}`} />
                                            );
                                        })
                                    }
                                    <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                                </RadioGroup>
                            </Box>
                            <Autocomplete
                                multiple
                                sx={{ mt: 2 }}
                                id="tags-filled"
                                options={CountryCodes}
                                getOptionLabel={(option) => option.name}
                                defaultValue={[]}
                                size="small"
                                value={selectedCountryCodes}
                                onChange={(event, newValue) => {
                                    setSelectedCountryCodes(newValue);
                                    setApplyCountry((prevApplyCountry) => ({
                                        ...prevApplyCountry,
                                        [candidate?.candidateId]: [

                                            ...newValue.map((country) => country.code)
                                        ]
                                    }));
                                }
                                }

                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        const { key, ...tagProps } = getTagProps({ index });
                                        return (
                                            <Chip
                                                variant="outlined"
                                                label={option.name}
                                                key={key}
                                                {...tagProps}
                                            />
                                        );
                                    })
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Country"
                                        placeholder="Country"
                                    />
                                )}
                            />
                        </MaterialProvider>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Button
                                onClick={() => {
                                    filterGifts()
                                }}
                                color="primary"

                                sx={{ height: "36px" }}

                            >
                                Filter
                            </Button>
                            <Button
                                onClick={() => {
                                    clearFilter()
                                }}

                                color="neutral"
                                sx={{ height: "36px", ml: 2 }}
                            >
                                Clear
                            </Button>

                        </div>


                    </div>
                </Stack>
            </Stack>
        </div>
    )
}
