import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import useSearchState from "../../../hooks/useSearchState";
import { selectGeography, setValues } from "../../../store/reducers/search/search.slice";

interface GeographyProps {
    id: "geography";
    multiple?: boolean;
}

export default function LocationPicker({ id }: GeographyProps) {
    const { query, setQuery, isLoading, dispatch } = useSearchState(id);
    const { values, options, error } = useSelector(selectGeography);

    const handleChange = (value: string | null) => {
        dispatch(
            setValues({
                key: id,
                value: [{ value: value || "", excluded: false }],
            })
        );
    };

    const optionsWithQuery = query ? [query, ...Object.keys(options)] : Object.keys(options);

    return (
        <Autocomplete
            size="small"
            sx={{ width: "140px", maxWidth: "140px", marginLeft: "10px", marginTop: "2px" }}
            ChipProps={{ color: "success" }}
            value={values[0]?.value || ""}
            options={optionsWithQuery}
            loading={isLoading}
            onChange={(_, value) => {
                handleChange(value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    label="Location"
                    placeholder="USA"
                    value={query}
                    error={Boolean(error)}
                    helperText={error}
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                        sx: { fontSize: 14 },
                    }}
                />
            )}
        />
    );
}
