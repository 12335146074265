import AspectRatio from "@mui/joy/AspectRatio";
import Box, { BoxProps } from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";

import { useSuperInboxContext } from "../../Context";

interface MessagesPaneConversationsLoaderProps {}

const chatsSkeleton = [
    { sender: "candidate", size: 8, width: 20 },
    { sender: "candidate", size: 8, width: 30 },
    { sender: "candidate", size: 10, width: 40 },
    { sender: "you", size: 8, width: 20 },
    { sender: "you", size: 8, width: 30 },
    { sender: "you", size: 15, width: 60 },
    { sender: "you", size: 10, width: 40 },
    { sender: "candidate", size: 10, width: 30 },
    { sender: "candidate", size: 15, width: 50 },
    { sender: "candidate", size: 10, width: 30 },
    { sender: "candidate", size: 10, width: 20 },
    { sender: "you", size: 10, width: 20 },
    { sender: "you", size: 10, width: 30 },
    { sender: "you", size: 10, width: 30 },
    { sender: "you", size: 10, width: 40 },
    { sender: "candidate", size: 10, width: 30 },
];

export default function MessagesPaneConversationsLoader(_props: MessagesPaneConversationsLoaderProps) {
    const { isMobile } = useSuperInboxContext();
    return (
        <Stack
            sx={{
                height: {
                    lg: "calc(100dvh - var(--Header-height) - 100px)", // extra 100px for tabs height
                    xs: "calc(100dvh - var(--Header-height))",
                },
                overflowY: "scroll",
            }}
            spacing={2}
            useFlexGap
        >
            <Card variant="plain">
                {chatsSkeleton.map((chat, index) => {
                    const isYou = chat.sender === "you";
                    const boxProps: BoxProps = {
                        display: "flex",
                        justifyContent: isYou ? "flex-end" : "flex-start",
                    };
                    return (
                        <Box {...boxProps} key={`skeleton-${index}`}>
                            {!isYou && <Skeleton animation="wave" variant="circular" width={34} height={34} />}
                            <AspectRatio
                                ratio={isMobile ? chat.size - 5 : chat.size}
                                sx={{
                                    width: (isMobile ? chat.width + 10 : chat.width) + "%",
                                    borderRadius: "lg",
                                    borderTopRightRadius: isYou ? 0 : "lg",
                                    borderTopLeftRadius: isYou ? "lg" : 0,
                                    margin: !isYou ? "10px 0 0 10px" : 0,
                                }}
                            >
                                <Skeleton animation="wave" variant="overlay" />
                            </AspectRatio>
                        </Box>
                    );
                })}
            </Card>
        </Stack>
    );
}
