import { Button as MUIButton } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";

import NewTemplateModal from "./components/templateModal/TemplateModal";
import style from "./template.module.scss";


import Button from "../../components/Button/Button";
import CustomDropdown, { CustomDropdownOptionsType } from "../../components/CustomDropdown/CustomDropdown";
import Modal from "../../components/Modal/Modal";
import Navbar from "../../components/Navbar/Navbar";
import { trashGrayMedium } from "../../constant/Constant";

import { editTemplate } from "@/store/reducers/template/template.reducer";
import { IEditTemplatePayload } from "@/store/reducers/template/template.types";
import { useFetchTemplatesQuery } from "@/store/apis/outreach/outreach.api";


export default function Template() {
    const dispatch = useDispatch();
    // const templateList = useSelector((state) => get(state, "template.templateList"));
    const { data: templateList } = useFetchTemplatesQuery({}, { refetchOnMountOrArgChange: true });
    const [templateData, setTemplateData] = useState({
        email: [],
        inmail: [],
        conn: [],
    });

    const [type, setType] = useState("");
    const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);
    const showNewModal = () => setIsNewModalOpen(true);
    const closeNewModal = () => setIsNewModalOpen(false);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => {
        setCurrTemp({});
        setIsDeleteModalOpen(false);
    };

    const [quillVal, setQuillVal] = useState(["", "", ""]);
    const [nameVal, setNameVal] = useState(["", "", ""]);
    const [subjectVal, setSubjectVal] = useState(["", "", ""]);
    const [currTemp, setCurrTemp] = useState({});

    // useEffect(() => {
    //     dispatch(getTemplateList());
    // }, []);

    useEffect(() => {
        if (isEmpty(currTemp)) return;

        //@ts-ignore
        if (currTemp.action === "delete") {
            showDeleteModal();
        } else {
            let index = -1;

            //@ts-ignore
            if (currTemp.type === "LINKEDIN") index = 0;
            //@ts-ignore
            else if (currTemp.type === "EMAIL") index = 1;
            //@ts-ignore
            else if (currTemp.type === "INMAIL") index = 2;

            const tmpEditor = [...quillVal];
            //@ts-ignore
            tmpEditor[index] = currTemp.body;
            const tmpSubject = [...subjectVal];
            //@ts-ignore
            tmpSubject[index] = currTemp.subject;
            const tmpName = [...nameVal];
            //@ts-ignore
            tmpName[index] = currTemp.name;

            setQuillVal(tmpEditor);
            setNameVal(tmpName);
            setSubjectVal(tmpSubject);
        }
    }, [currTemp]);

    useEffect(() => {
        if (isEmpty(templateList)) return;

        const tmp = { email: [], inmail: [], conn: [] };

        //@ts-ignore
        for (const val of templateList) {
            //@ts-ignore
            if (val.type === "EMAIL") tmp.email.push(val);
            //@ts-ignore
            else if (val.type === "INMAIL") tmp.inmail.push(val);
            //@ts-ignore
            else if (val.type === "LINKEDIN") tmp.conn.push(val);
        }

        setTemplateData(tmp);
    }, [templateList]);

    useEffect(() => {
        if (!type) return;
        showNewModal();
    }, [type]);

    const handleSelect = (val: any) => {
        //@ts-ignore
        setCurrTemp(val);
    };

    const handleEdit = () => {
        const payload = { ...currTemp };

        let index = -1;

        //@ts-ignore
        if (currTemp.type === "LINKEDIN") index = 0;
        //@ts-ignore
        else if (currTemp.type === "EMAIL") index = 1;
        //@ts-ignore
        else if (currTemp.type === "INMAIL") index = 2;
        //@ts-ignore
        payload.name = nameVal[index];
        //@ts-ignore
        payload.subject = subjectVal[index];
        //@ts-ignore
        payload.body = quillVal[index];

        const tmpEditor = [...quillVal];
        tmpEditor[index] = "";

        const tmpSubject = [...subjectVal];
        tmpSubject[index] = "";

        const tmpName = [...nameVal];
        tmpName[index] = "";

        dispatch(editTemplate(payload as IEditTemplatePayload));
        setCurrTemp({});
        setQuillVal(tmpEditor);
        setNameVal(tmpName);
        setSubjectVal(tmpSubject);
    };

    const handleDelete = () => {
        dispatch(
            editTemplate({
                //@ts-ignore
                name: currTemp.name,
                //@ts-ignore
                type: currTemp.type,
                //@ts-ignore
                body: currTemp.body,
                //@ts-ignore
                active: false,
                //@ts-ignore
                _id: currTemp._id,
            })
        );

        setCurrTemp({});
        closeDeleteModal();
    };

    const handleQuillChange = (index: number, newVal: string) => {
        const tmp = [...quillVal];
        tmp[index] = newVal;
        setQuillVal(tmp);
    };

    const addTemplateDropDown: CustomDropdownOptionsType[] = [
        {
            label: (
                <p className={style["tmp__link"]} onClick={() => setType("Connection Request")}>
                    Connection Request
                </p>
            ),
            key: "0",
            handleClick: () => setType("Connection Request"),
        },
        {
            label: (
                <p className={style["tmp__link"]} onClick={() => setType("Inmail")}>
                    Inmail
                </p>
            ),
            key: "1",
            handleClick: () => setType("Inmail"),
        },
        {
            label: (
                <p className={style["tmp__link"]} onClick={() => setType("Email")}>
                    Email
                </p>
            ),
            key: "2",
            handleClick: () => setType("Email"),
        },
    ];

    const personaliseDropDown: CustomDropdownOptionsType[] = [
        {
            label: (
                <p className={style["tmp__link"]} onClick={() => navigator.clipboard.writeText("{{candidate.name}}")}>
                    Name
                </p>
            ),
            key: "0",
            handleClick: () => navigator.clipboard.writeText("{{candidate.name}}"),
        },
        {
            label: (
                <p className={style["tmp__link"]} onClick={() => navigator.clipboard.writeText("{{candidate.email}}")}>
                    Email
                </p>
            ),
            key: "1",
            handleClick: () => navigator.clipboard.writeText("{{candidate.email}}"),
        },
    ];

    return (
        <div className={style["tmp"]}>
            <div className={style["tmp__container"]}>
                <Navbar />

                <div className={style["tmp__header"]}>
                    <div className={style["tmp__heading"]}>
                        <p>Templates</p>
                        <CustomDropdown options={addTemplateDropDown}>
                            <MUIButton variant="contained">
                                <p>Add new template</p>
                            </MUIButton>
                        </CustomDropdown>
                    </div>
                </div>

                <div className={style["tmp__main"]}>
                    <div className={style["tmp__body"]}>
                        <div className={style["tmp__box"]}>
                            <div className={style["tmp__titleBox"]}>
                                <h3>Connection Request</h3>
                            </div>

                            {templateData.conn.length === 0 ? (
                                <p className={style["tmp__placeholder"]}>
                                    No template available! Start by creating a new template
                                </p>
                            ) : (
                                ""
                            )}

                            <div className={style["tmp__item"]}>
                                <div className={style["tmp__itemLeft"]}>
                                    {templateData.conn.map((val, i) => (
                                        <div className={style["tmp__checkBox"]} key={i}>
                                            <div
                                                className={style["tmp__check"]}
                                                onClick={() =>
                                                    handleSelect({
                                                        //@ts-ignore
                                                        ...val,
                                                        action: "edit",
                                                    })
                                                }
                                            >
                                                {
                                                    //@ts-ignore
                                                    val.name
                                                }
                                            </div>
                                            <div className={style["tmp__actionWrapper"]}>
                                                <div className={style["tmp__actionBox"]}>
                                                    {/* <img
                              src={editGrayIcon}
                              alt=""
                              //@ts-ignore 
                              onClick={() => handleSelect({ ...val, action: "edit" })}
                            /> */}
                                                    <img
                                                        src={trashGrayMedium}
                                                        alt=""
                                                        onClick={() =>
                                                            handleSelect({
                                                                //@ts-ignore
                                                                ...val,
                                                                action: "delete",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className={style["tmp__itemRight"]}>
                                    {
                                        //@ts-ignore
                                        templateData.conn.length &&
                                            (isEmpty(currTemp) ||
                                                //@ts-ignore
                                                currTemp.type !== "LINKEDIN" ||
                                                //@ts-ignore
                                                currTemp.action === "delete") ? (
                                            <p className={style["tmp__placeholder"]}>Select a templete to edit</p>
                                        ) : (
                                            ""
                                        )
                                    }
                                    {!isEmpty(currTemp) &&
                                        //@ts-ignore
                                        currTemp.type === "LINKEDIN" &&
                                        //@ts-ignore
                                        currTemp.action !== "delete" ? (
                                        <>
                                            <div className={style["tmp__nameBox"]}>
                                                <div className={style["tmp__name"]}>
                                                    <input
                                                        type="text"
                                                        placeholder="Name"
                                                        value={nameVal[0]}
                                                        onChange={(e) =>
                                                            setNameVal([e.target.value, nameVal[1], nameVal[2]])
                                                        }
                                                    />
                                                </div>
                                                <CustomDropdown options={personaliseDropDown}>
                                                    <MUIButton variant="contained">
                                                        <p>Personalise</p>
                                                    </MUIButton>
                                                </CustomDropdown>
                                            </div>

                                            <div className={style["tmp__textareaBox"]}>
                                                <ReactQuill
                                                    value={quillVal[0]}
                                                    onChange={(e) => handleQuillChange(0, e)}
                                                />

                                                <div className={style["tmp__btnBox"]}>
                                                    <Button label="Save" variant="primary" onClick={handleEdit} />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={style["tmp__box"]}>
                            <div className={style["tmp__titleBox"]}>
                                <h3>Email Template</h3>
                            </div>

                            {templateData.email.length === 0 ? (
                                <p className={style["tmp__placeholder"]}>
                                    No template available! Start by creating a new template
                                </p>
                            ) : (
                                ""
                            )}

                            <div className={style["tmp__item"]}>
                                <div className={style["tmp__itemLeft"]}>
                                    {templateData.email.map((val, i) => (
                                        <div className={style["tmp__checkBox"]} key={i}>
                                            <div
                                                className={style["tmp__check"]}
                                                onClick={() =>
                                                    handleSelect({
                                                        //@ts-ignore
                                                        ...val,
                                                        action: "edit",
                                                    })
                                                }
                                            >
                                                {
                                                    //@ts-ignore
                                                    val.name
                                                }
                                            </div>
                                            <div className={style["tmp__actionWrapper"]}>
                                                <div className={style["tmp__actionBox"]}>
                                                    {/* <img
                              src={editGrayIcon}
                              alt=""
                              //@ts-ignore
                              onClick={() => handleSelect({ ...val, action: "edit" })}
                            /> */}
                                                    <img
                                                        src={trashGrayMedium}
                                                        alt=""
                                                        //@ts-ignore
                                                        onClick={() =>
                                                            handleSelect({
                                                                //@ts-ignore
                                                                ...val,
                                                                action: "delete",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className={style["tmp__itemRight"]}>
                                    {
                                        //@ts-ignore
                                        templateData.email.length &&
                                            (isEmpty(currTemp) ||
                                                //@ts-ignore
                                                currTemp.type !== "EMAIL" ||
                                                //@ts-ignore
                                                currTemp.action === "delete") ? (
                                            <p className={style["tmp__placeholder"]}>Select a templete to edit</p>
                                        ) : (
                                            ""
                                        )
                                    }
                                    {!isEmpty(currTemp) &&
                                        //@ts-ignore
                                        currTemp.type === "EMAIL" &&
                                        //@ts-ignore
                                        currTemp.action !== "delete" ? (
                                        <>
                                            <div className={style["tmp__nameBox"]}>
                                                <div className={style["tmp__name"]}>
                                                    <input
                                                        type="text"
                                                        placeholder="Name"
                                                        value={nameVal[1]}
                                                        onChange={(e) =>
                                                            setNameVal([nameVal[0], e.target.value, nameVal[2]])
                                                        }
                                                    />
                                                </div>
                                                <CustomDropdown options={personaliseDropDown}>
                                                    <MUIButton variant="contained">
                                                        <p>Personalise</p>
                                                    </MUIButton>
                                                </CustomDropdown>
                                            </div>

                                            <div className={style["tmp__subjectBox"]}>
                                                <p>Subject : </p>
                                                <input
                                                    type="text"
                                                    placeholder="Subject"
                                                    value={subjectVal[1]}
                                                    onChange={(e) =>
                                                        setSubjectVal([subjectVal[0], e.target.value, subjectVal[2]])
                                                    }
                                                />
                                            </div>

                                            <div className={style["tmp__textareaBox"]}>
                                                <ReactQuill
                                                    value={quillVal[1]}
                                                    onChange={(e) => handleQuillChange(1, e)}
                                                />

                                                <div className={style["tmp__btnBox"]}>
                                                    <Button label="Save" variant="primary" onClick={handleEdit} />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={style["tmp__box"]}>
                            <div className={style["tmp__titleBox"]}>
                                <h3>Inmail Template</h3>
                            </div>

                            {templateData.inmail.length === 0 ? (
                                <p className={style["tmp__placeholder"]}>
                                    No template available! Start by creating a new template
                                </p>
                            ) : (
                                ""
                            )}

                            <div className={style["tmp__item"]}>
                                <div className={style["tmp__itemLeft"]}>
                                    {templateData.inmail.map((val, i) => (
                                        <div className={style["tmp__checkBox"]} key={i}>
                                            <div
                                                className={style["tmp__check"]}
                                                onClick={() =>
                                                    handleSelect({
                                                        //@ts-ignore
                                                        ...val,
                                                        action: "edit",
                                                    })
                                                }
                                            >
                                                {
                                                    //@ts-ignore
                                                    val.name
                                                }
                                            </div>
                                            <div className={style["tmp__actionWrapper"]}>
                                                <div className={style["tmp__actionBox"]}>
                                                    {/* <img
                              src={editGrayIcon}
                              alt=""
                              //@ts-ignore
                              onClick={() => handleSelect({ ...val, action: "edit" })}
                            /> */}
                                                    <img
                                                        src={trashGrayMedium}
                                                        alt=""
                                                        onClick={() =>
                                                            handleSelect({
                                                                //@ts-ignore
                                                                ...val,
                                                                action: "delete",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className={style["tmp__itemRight"]}>
                                    {
                                        //@ts-ignore
                                        templateData.conn.length &&
                                            (isEmpty(currTemp) ||
                                                //@ts-ignore
                                                currTemp.type !== "INMAIL" ||
                                                //@ts-ignore
                                                currTemp.action === "delete") ? (
                                            <p className={style["tmp__placeholder"]}>Select a templete to edit</p>
                                        ) : (
                                            ""
                                        )
                                    }
                                    {!isEmpty(currTemp) &&
                                        //@ts-ignore
                                        currTemp.type === "INMAIL" &&
                                        //@ts-ignore
                                        currTemp.action !== "delete" ? (
                                        <>
                                            <div className={style["tmp__nameBox"]}>
                                                <div className={style["tmp__name"]}>
                                                    <input
                                                        type="text"
                                                        placeholder="Name"
                                                        value={nameVal[2]}
                                                        onChange={(e) =>
                                                            setNameVal([nameVal[0], nameVal[1], e.target.value])
                                                        }
                                                    />
                                                </div>
                                                <CustomDropdown options={personaliseDropDown}>
                                                    <MUIButton variant="contained">
                                                        <p>Personalise</p>
                                                    </MUIButton>
                                                </CustomDropdown>
                                            </div>

                                            <div className={style["tmp__subjectBox"]}>
                                                <p>Subject : </p>
                                                <input
                                                    type="text"
                                                    placeholder="Subject"
                                                    value={subjectVal[2]}
                                                    onChange={(e) =>
                                                        setSubjectVal([subjectVal[0], subjectVal[1], e.target.value])
                                                    }
                                                />
                                            </div>

                                            <div className={style["tmp__textareaBox"]}>
                                                <ReactQuill
                                                    value={quillVal[2]}
                                                    onChange={(e) => handleQuillChange(2, e)}
                                                />

                                                <div className={style["tmp__btnBox"]}>
                                                    <Button label="Save" variant="primary" onClick={handleEdit} />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal open={isNewModalOpen} onClose={closeNewModal} title={`New ${type} Template`} width={"70%"}>
                    <NewTemplateModal type={type} closeModal={closeNewModal} />
                </Modal>

                <Modal open={isDeleteModalOpen} onClose={closeDeleteModal} title={"Delete Template"} width={"50%"}>
                    <div className={style["tmp__delBox"]}>
                        <Button label="Cancel" variant="secondary" onClick={closeDeleteModal} />
                        <Button label="Confirm" variant="primary" onClick={handleDelete} />
                    </div>
                </Modal>
            </div>
        </div>
    );
}
