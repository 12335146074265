import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { Queries } from "./projects.enums";
import {
    FetchCandidatesByProjectResponse,
    FetchCandidatesByProjectResponseData,
    GetCandidatesByProjectRequestPayload,
    SubmitFeedbackPayload,
    SubmitFeedbackResponse,
    fetchCandidatesByProjectResponse,
} from "./projects.schema";

import { fetchBaseQueryWithZod } from "../utils";

import { IProject } from "@/store/reducers/project/project.types";

export type TContactType = "email" | "phone";

export const projectsApi = createApi({
    reducerPath: "projectsApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [
        Queries.PROJECTS,
        Queries.CANDIDATE_SOURCE_TYPES,
        Queries.CANDIDATE_OUTREACH_TIMESTAMPS,
        Queries.CANDIDATE_MULTIPLE_PROJECT_STATUS,
    ],
    endpoints: (builder) => ({
        getProjects: builder.query<IProject[], void | number>({
            query: (userId) => ({
                url: `v2/project/list${userId ? `?userId=${userId}` : ""}`,
                method: "GET",
            }),
            providesTags: [Queries.PROJECTS],
            transformResponse(baseQueryReturnValue: { data: IProject[] }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getCandidateSourceTypes: builder.query<
            any,
            {
                profileUrl: string;
                data: {
                    contactType: TContactType;
                    value: string;
                }[];
            }
        >({
            query: (payload) => ({
                url: `v2/project/get/source-type`,
                method: "POST",
                body: payload,
            }),
            providesTags: [Queries.CANDIDATE_SOURCE_TYPES],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
        }),
        getCandidateMultipleProjectStatus: builder.query<
            any,
            {
                candidateId: string;
            }
        >({
            query: (payload) => ({
                url: `v2/project/exists-multi-project/${payload.candidateId}`,
                method: "GET",
            }),
            providesTags: [Queries.CANDIDATE_MULTIPLE_PROJECT_STATUS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
        }),
        getCandidateMultipleProjectStatusDetails: builder.query<
            any,
            {
                candidateId: string;
            }
        >({
            query: (payload) => ({
                url: `v2/project/multiple-project-details`,
                method: "POST",
                body: payload,
            }),
            providesTags: [Queries.CANDIDATE_MULTIPLE_PROJECT_STATUS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
        }),
        getOutreachTimestamps: builder.query<
            any,
            {
                candidateId: string;
                projectId: number;
                bodyOutreachStatus: any;
            }
        >({
            query: (payload) => ({
                url: `v2/project/get/outreach-timestamps`,
                method: "POST",
                body: payload,
            }),
            providesTags: [Queries.CANDIDATE_OUTREACH_TIMESTAMPS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || {};
            },
        }),
        getCandidatesByProject: builder.query<
            FetchCandidatesByProjectResponseData,
            GetCandidatesByProjectRequestPayload
        >({
            query: ({ projectId }) => ({
                url: `/v2/project/${projectId}/candidate-details`,
            }),
            transformResponse: (response: FetchCandidatesByProjectResponse) => {
                const { data } = response;

                return data;
            },
            extraOptions: {
                dataSchema: fetchCandidatesByProjectResponse,
            },
        }),
        submitFeedback: builder.mutation<SubmitFeedbackResponse, SubmitFeedbackPayload>({
            query: (payload) => ({
                url: "/v2/project/closing-remarks",
                method: "POST",
                body: payload,
            }),
        }),
        getFocusedSearchInfo: builder.query<any, any>({
            query: (payload) => ({
                url: `/super-admin/project-search-filter?projectId=${payload.projectId}`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetProjectsQuery,
    useGetCandidateSourceTypesQuery,
    useGetCandidateMultipleProjectStatusQuery,
    useGetCandidateMultipleProjectStatusDetailsQuery,
    useGetOutreachTimestampsQuery,
    useGetCandidatesByProjectQuery,
    useSubmitFeedbackMutation,
    useGetFocusedSearchInfoQuery,
    useLazyGetProjectsQuery,
} = projectsApi;
