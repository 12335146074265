import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";

import { changeBCCEmail, selectWorkflowData } from "../../workflow/index.reducer";
import ErrorLabel from "../atoms/ErrorLabel";
import validateEmail from "../../../utils/validateEmail";
import { dispatchEnterKeyboardEvent } from "../../../utils/keyboardEvent";

export default function BCCEmail() {
    const dispatch = useDispatch();
    const error = useSelector(selectWorkflowData)?.bccEmail?.error;
    const values = useSelector(selectWorkflowData)?.bccEmail?.value;
    const [isFocused, setIsFocused] = useState(false);
    const ref = useRef(null);

    const handleChange = (value: string[]) => {
        dispatch(changeBCCEmail(value));
    };

    const handleDelete = (values: string[], selectedValue: string) => {
        const updatedValue = values.filter((value) => value !== selectedValue);
        handleChange(updatedValue);
    };

    const renderTags = (values: string[]) => {
        return values.map((value) => {
            const error = !validateEmail(value);
            return (
                <Chip
                    sx={{ m: 0.5 }}
                    size="small"
                    variant={error ? "outlined" : "filled"}
                    color={error ? "error" : "default"}
                    onDelete={() => handleDelete(values, value)}
                    label={value}
                />
            );
        });
    };

    return (
        <Stack>
            <Autocomplete
                multiple
                freeSolo
                fullWidth
                size="small"
                value={values}
                options={[]}
                onChange={(_, value) => {
                    handleChange(value);
                }}
                renderTags={renderTags}
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                    setIsFocused(false);
                    dispatchEnterKeyboardEvent(ref.current);
                }}
                renderInput={(params) => (
                    <Tooltip open={isFocused && !values.length} title="Press 'enter' after each email">
                        <TextField {...params} ref={ref} label="Bcc email" placeholder="Enter emails to bcc" />
                    </Tooltip>
                )}
            />
            {error && <ErrorLabel message={error} />}
        </Stack>
    );
}
