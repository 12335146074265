import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common from "./assets/locales/common/en.json";
import easygrowth from "./assets/locales/easy-growth/en.json";
import easysource from "./assets/locales/easy-source/en.json";
import { config_mode } from "./utils/Constants";

export const resources = {
    en: {
        easysource: { ...common, ...easysource },
        easygrowth: { ...common, ...easygrowth },
    },
} as const;

export const defaultConfig =
    import.meta.env.VITE_REACT_APP_CONFIG_MODE === config_mode.EASY_SOURCE ? "easysource" : "easygrowth";

i18n.use(initReactI18next).init({
    debug: true,
    lng: "en",
    ns: ["easysource", "easygrowth"],
    defaultNS: defaultConfig,
    resources,
});
