import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";

import CopyableText from "../../../../atoms/CopyableText";

interface MessageInfoDropdownProps {
    options: Array<{ label: string; value: string }>;
}

export default function MessageInfoDropdown(props: MessageInfoDropdownProps) {
    const { options = [] } = props;

    return (
        <Dropdown>
            <MenuButton sx={{ margin: 0, padding: 0, height: "auto", minHeight: "auto", border: "none" }}>
                <Tooltip placement="right" title="Show details">
                    <ExpandMoreIcon />
                </Tooltip>
            </MenuButton>
            <Menu>
                {options.map((option, index) => {
                    return (
                        <MenuItem key={index} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <Typography fontSize={14}>{option.label}:</Typography>
                            <CopyableText variant="body1" fontSize={14} message="Email copied succesfully">
                                {option.value}
                            </CopyableText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Dropdown>
    );
}
