import { useSelector } from "react-redux";

import useSearchState from "@/hooks/useSearchState";
import { selectFilter, setValues } from "@/store/reducers/search/search.slice";
import { SearchStateKeys } from "@/store/reducers/search/search.types";
import { Autocomplete } from "@/ui/Autocomplete/Autocomplete";

interface BSAutoCompleteProps {
    id: SearchStateKeys;
    placeholder: string;
    multiple?: boolean;
    freeSolo?: boolean;
    excludable?: boolean;
    dontCloseOnSelect?: boolean;
}

export function BSAutoComplete({
    id,
    placeholder,
    multiple = true,
    freeSolo = false,
    excludable = false,
    dontCloseOnSelect = false,
}: BSAutoCompleteProps) {
    const { values, options, error } = useSelector(selectFilter(id));
    const { isLoading, dispatch } = useSearchState(id);

    const handleChange = (value: { value: string; excluded: boolean }[]) => {
        dispatch(
            setValues({
                key: id,
                value: value.map((item) => ({ ...item, excluded: item.excluded || false })),
            })
        );
    };

    return (
        <div>
            <Autocomplete
                multiple={multiple}
                freeSolo={freeSolo}
                excludable={excludable}
                placeholder={placeholder}
                value={values.map((o) => ({ ...o, label: String(o.value), value: String(o.value) }))}
                options={Object.keys(options).map((i) => ({ value: i, label: i }))}
                onChange={(option) =>
                    handleChange(option.map((o) => ({ value: String(o.value), excluded: !!o.excluded })))
                }
                loading={isLoading}
                dontCloseOnSelect={dontCloseOnSelect}
            />
            {error && <div className="text-error text-xs mt-1.5">{error}</div>}
        </div>
    );
}
