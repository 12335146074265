import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectProfileCount, setProfileCount } from "@/store/reducers/linkedin-scraping/linkedin-scraping.slice";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { submitSearch } from "@/store/reducers/search/search.slice";
import { Button, Input, Modal } from "@/ui";

export default function ExpandCountUp({ projectId, projectName }) {

    const [open, setOpen] = useState(false);

    const handleOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    
    return (
        <>
            <Button
                variant="default"
                onClick={() => handleOpenModal()}
                label="Expand Search"
                className="rounded-full"
            />

            <ProfileModal open={open} onClose={handleCloseModal} projectId={projectId} projectName={projectName} />
        </>
    );
}


const ProfileModal = ({ open, onClose, projectId, projectName }) => {
    const dispatch = useDispatch();
    const profileCount = useSelector(selectProfileCount);
    const navigate = useNavigate();
    const handleSubmitScrapingModalForm = (isExpandable: boolean = false) => {
        if (!profileCount || profileCount === 0) {
            dispatch(setErrorNotification("Profile Count must be sent"));
            return;
        } else if (profileCount > 1000) {
            dispatch(setErrorNotification("Max limit is 1000"));
            return;
        }
        dispatch(
            submitSearch({
                navigate,
                isFromLinkedinScraperModal: true,
                addSalesNavUrlProfiles: false,
                addExpandableSearch: isExpandable,
                project_id: projectId,
                project_name: projectName,
            })
        );
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <h2 className=" mb-3 mt-3 text-gray-600">Enter the profile count required to expand candidate search</h2>

            <div className="space-y-4">
                <Input
                    type="number"
                    placeholder="Enter profile count"
                    value={profileCount}
                    onChange={(value) => dispatch(setProfileCount(parseInt(value)))}
                    min="0"
                    fullWidth
                />
                <div className="flex justify-end">
                    <Button variant="default" onClick={() => handleSubmitScrapingModalForm(true)}>
                        Submit
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
