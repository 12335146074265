import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import {
    leavePage,
    setAutoReply,
    setAutomationHyperpersonalization,
    setAutomationLinkedin,
    setCalendlyMeetingUpdate,
    setCalendlyTracking,
    setClientWeeklyMail,
    setClientWeeklyMailSms,
    setCsEmail,
    setEmailReadForm,
    setEmailTracking,
    setEmailTrackingNew,
    setEmailTrackingOptimized,
    setIntelligentEmailTracker,
    setLiAutoSourcing,
    setLiConnReq,
    setLiConnection,
    setLiConversations,
    setLiRecruiter,
    setLiReminderMsg,
    setLiSalesnavCookie,
    setLiScraperHealth,
    setReadReply,
    setSendGridOptimized,
    setSmsTracking,
    setTrackingDaily,
    setUnauthorizedTrack,
} from "../reducers/crons/crons.reducer";
import { startAction, stopAction } from "../reducers/loaders.reducer";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";

const normalizeSources = (sources: string): string[] => {
    const allowedSources = ["Gmail", "Outlook", "Both"];
    if (allowedSources.includes(sources)) {
        return sources === "Both" ? ["Gmail", "Outlook"] : [sources];
    }
    return [];
};

function* intelligentEmailTrackerSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/email-tracker`, payload?.trackerPayload);

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            yield put(setErrorNotification("Error in fetching threads"));
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* autoReplySaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/auto-reply`, {
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* calendlyMeetingUpdateSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/calendlymeeting-update`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* calendlyTrackingSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/calendly-tracking`, {
            startTime: payload.startTime,
            endTime: payload.endTime,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* clientWeeklyMailSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/clientweekly-mail`, {
            usersInclude: payload.usersInclude,
            startTime: payload.startTime,
            endTime: payload.endTime,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* clientWeeklyMailSmsSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/clientweekly-mailSms`, {
            usersInclude: payload.usersInclude,
            startTime: payload.startTime,
            endTime: payload.endTime,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* csEmailSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/cs-email`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* emailReadFormSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/emailread-form`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* emailTrackingSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/email-tracking`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
            startTime: payload.startTime,
            endTime: payload.endTime,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* liConnectionSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/li-connection`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
            startTime: payload.startTime,
            endTime: payload.endTime,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* liConversationsSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/li-conversations`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
            startTime: payload.startTime,
            endTime: payload.endTime,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* liRecruiterSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/li-recruiter`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
            startTime: payload.startTime,
            endTime: payload.endTime,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* liReminderMsgSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/li-reminder`, {
            usersInclude: payload.usersInclude,
            startTime: payload.startTime,
            endTime: payload.endTime,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* readReplySaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/read-reply`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* smsTrackingSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/sms-tracking`, {
            usersInclude: payload.usersInclude,
            startTime: payload.startTime,
            endTime: payload.endTime,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* trackingDailySaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/tracking-daily`, {
            startTime: payload.startTime,
            endTime: payload.endTime,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* unauthorizedTrackSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/unauthorized-track`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* emailTrackingNewSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/emailTracking-new`, {
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* emailTrackingOptimizedSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/emailTracking-optimized`, {
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* liAutoSourcingSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/liAutoSourcing`, {
            sources: normalizeSources(payload.sources),
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* liConnReqSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/liConnReq`, {
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* automationHyperpersonalizationSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/automation-Hyperpersonalization`, {
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* sendGridOptimizedSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/sendGrid-optimized`, {
            usersInclude: payload.usersInclude,
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* automationLinkedinSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/automation-linkedin`, {
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* liSalesnavCookieSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/liSalesNav-cookie`, {
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* liScraperHealthSaga(action: PayloadAction<any>): SagaIterator {
    const { payload } = action;
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `crons/liScrapper-health`, {
            emailArr: payload.sendToEmail,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(setAutoReply.type, autoReplySaga),

        // @ts-ignore
        yield takeLatest(setCalendlyMeetingUpdate.type, calendlyMeetingUpdateSaga),

        // @ts-ignore
        yield takeLatest(setCalendlyTracking.type, calendlyTrackingSaga),

        // @ts-ignore
        yield takeLatest(setClientWeeklyMail.type, clientWeeklyMailSaga),

        // @ts-ignore
        yield takeLatest(setClientWeeklyMailSms.type, clientWeeklyMailSmsSaga),

        // @ts-ignore
        yield takeLatest(setCsEmail.type, csEmailSaga),

        // @ts-ignore
        yield takeLatest(setEmailReadForm.type, emailReadFormSaga),

        // @ts-ignore
        yield takeLatest(setEmailTracking.type, emailTrackingSaga),

        // @ts-ignore
        yield takeLatest(setLiConnection.type, liConnectionSaga),

        // @ts-ignore
        yield takeLatest(setLiConversations.type, liConversationsSaga),

        // @ts-ignore
        yield takeLatest(setLiRecruiter.type, liRecruiterSaga),

        // @ts-ignore
        yield takeLatest(setLiReminderMsg.type, liReminderMsgSaga),

        // @ts-ignore
        yield takeLatest(setReadReply.type, readReplySaga),

        // @ts-ignore
        yield takeLatest(setSmsTracking.type, smsTrackingSaga),

        // @ts-ignore
        yield takeLatest(setTrackingDaily.type, trackingDailySaga),

        // @ts-ignore
        yield takeLatest(setUnauthorizedTrack.type, unauthorizedTrackSaga),

        // @ts-ignore
        yield takeLatest(setEmailTrackingNew.type, emailTrackingNewSaga),

        // @ts-ignore
        yield takeLatest(setEmailTrackingOptimized.type, emailTrackingOptimizedSaga),

        // @ts-ignore
        yield takeLatest(setSendGridOptimized.type, sendGridOptimizedSaga),

        // @ts-ignore
        yield takeLatest(setLiAutoSourcing.type, liAutoSourcingSaga),

        // @ts-ignore
        yield takeLatest(setLiConnReq.type, liConnReqSaga),

        // @ts-ignore
        yield takeLatest(setAutomationHyperpersonalization.type, automationHyperpersonalizationSaga),

        // @ts-ignore
        yield takeLatest(setAutomationLinkedin.type, automationLinkedinSaga),

        // @ts-ignore
        yield takeLatest(setLiSalesnavCookie.type, liSalesnavCookieSaga),

        // @ts-ignore
        yield takeLatest(setLiScraperHealth.type, liScraperHealthSaga),

        // @ts-ignore
        yield takeLatest(setIntelligentEmailTracker.type, intelligentEmailTrackerSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
