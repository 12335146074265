import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { NavigateFunction } from "react-router-dom";

import { useProjectData } from "./hooks";

import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { addSearchCandidatesToProject } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";

interface AddCustomCandidatesProps {
    onConfirm?: (data: {
        projectId: string | null;
        projectName: string | null;
        searchId: string;
        navigate: NavigateFunction;
        profilesCount: number;
        canNavigateNextStep: boolean;
    }) => void;
    maxCount?: number;
    minCount?: number;
}

export default function AddCustomCandidates(props: AddCustomCandidatesProps) {
    const { maxCount = 1000, minCount = 0, onConfirm } = props;

    const [openModal, setOpenModal] = useState(false);
    const [candidateCount, setCandidateCount] = useState<number | "">(50);
    const {
        projectId,
        projectName,
        dispatch,
        searchId,
        navigate,
        candidates,
        isSuperAdmin,
        isAddingToProject,
        searchType,
    } = useProjectData();

    const canAccess = isSuperAdmin || searchType === "resume-library";

    if (!searchId || !candidates?.length || !canAccess) return null;

    const handleCloseModal = () => {
        setOpenModal(false);
        setCandidateCount(50);
    };

    const handleAddToProject = () => {
        if (Number(candidateCount) <= minCount || Number(candidateCount) > maxCount) return;
        const payload = {
            projectId,
            projectName,
            searchId,
            navigate,
            profilesCount: Number(candidateCount),
            canNavigateNextStep: false,
        };
        if (onConfirm) {
            onConfirm(payload);
        } else {
            dispatch(addSearchCandidatesToProject(payload));
        }
    };

    return (
        <>
            <Button variant="outlined" onClick={() => setOpenModal(true)}>
                Add candidates to project
            </Button>
            {openModal && (
                <BaseModal hideCloseButton onClose={handleCloseModal}>
                    <Stack spacing={2}>
                        <Typography fontSize={18} fontWeight={500} color="#334d6e">
                            Add candidates to project
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            type="text"
                            label="Candidates count"
                            value={candidateCount}
                            onChange={(e) =>
                                setCandidateCount(!isNaN(Number(e.target.value)) ? Number(e.target.value) || "" : "")
                            }
                            error={Number(candidateCount) <= minCount || Number(candidateCount) > maxCount}
                            helperText={
                                Number(candidateCount) <= minCount
                                    ? "*It should be greater than zero"
                                    : Number(candidateCount) > maxCount
                                        ? `*It should be less than ${maxCount}`
                                        : ""
                            }
                        />
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                            <Button variant="outlined" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button variant="contained" disabled={isAddingToProject} onClick={handleAddToProject}>
                                <ButtonTextWithLoading text="Submit" isLoading={isAddingToProject} />
                            </Button>
                        </Stack>
                    </Stack>
                </BaseModal>
            )}
        </>
    );
}
