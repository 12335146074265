import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../../components/CustomButton";
import {
    numberOfSelectedRowsInAllContacts,
    selectAllContactsSearchResults,
    toggleSelectAllSearchResults,
} from "../all-contacts.slice";

export default function SelectionPrompt() {
    const numSelected = useSelector(numberOfSelectedRowsInAllContacts);
    const dispatch = useDispatch();
    const isAllSelected = useSelector(selectAllContactsSearchResults);

    if (!numSelected && !isAllSelected) {
        return null;
    }

    const handleClick = () => {
        dispatch(toggleSelectAllSearchResults());
    };

    return (
        <Typography
            textAlign="center"
            variant="body2"
            p={1}
            sx={{
                backgroundColor: "rgba(241, 243, 244, 0.871)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            Currently, our search results are limited to 500. If you wish to select all results for this search&nbsp;
            <CustomButton
                onClick={handleClick}
                variant="text"
                sx={{
                    padding: 0,
                    border: 0,
                    backgroundColor: "transparent !important",
                    fontSize: "14px",
                    boxShadow: "none !important",
                    outline: 0,
                }}
            >
                {isAllSelected ? "Clear" : "Click Here"}.
            </CustomButton>
        </Typography>
    );
}
