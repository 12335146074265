/* eslint-disable import/no-unused-modules */
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { notificationToasts } from "./components/NotificationToasts";
import {
    NotificationContextType,
    NotificationStateType,
    NotificationV2Type,
    TotalNotificationsType,
    notificationsFiltersType,
} from "./notificationPage.types";

import { notificationEvents, notificationEventsSubTypes } from "@/sockets/sockets";
import { useWebSocketContext } from "@/sockets/WebSocketContext";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import { NotificationConfigTypes } from "@/store/reducers/signin/Signin.types";

const NotificationsContext = createContext<NotificationContextType | undefined>(undefined);

export function NotificationsContextProvider({ children }: { children: React.ReactNode }) {
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const { emit, useSocketListeners, isConnected } = useWebSocketContext();
    const [notificationState, setNotificationState] = useState<NotificationStateType>({
        UNREADS: [],
        RECENT: [],
        ARCHIVES: [],
    });
    const [totalNotifications, setTotalNotifications] = useState<TotalNotificationsType>({
        UNREADS: 0,
        RECENT: 0,
        ARCHIVES: 0,
    });
    const [selectedNotifications, setSelectedNotifications] = useState<Record<string, boolean>>({});
    const [allNotificationsSelected, setAllNotificationsSelected] = useState(false);
    const [filters, setFilters] = useState<notificationsFiltersType>({
        page: 1,
        perPage: 25,
        orderBy: -1,
        pageName: "RECENT",
        unReadOnly: false,
    });
    const [isFetching, setIsFetching] = useState(false);

    // Socket event handlers
    const handleNewNotification = (data: NotificationV2Type) => {
        setNotificationState((prev) => ({
            ...prev,
            UNREADS: [data, ...prev.UNREADS],
            RECENT: [data, ...prev.RECENT],
        }));
        setTotalNotifications((prev) => ({
            ...prev,
            UNREADS: prev.UNREADS + 1,
            RECENT: prev.RECENT + 1,
        }));
        notificationToasts(data);
    };

    const handleGetAllNotifications = (data: any) => {
        if (!data?.success) {
            return;
        }

        if (data?.eventSubType === "GET_ONLY_UNREADS") {
            setNotificationState((prev) => ({
                ...prev,
                UNREADS: data?.notifications,
            }));
            setTotalNotifications((prev) => ({
                ...prev,
                UNREADS: data?.totalDocuments,
            }));
            return;
        }

        const { notifications = [], totalDocuments = 0 } = data;
        const currentPage = filters?.pageName || "RECENT";

        setNotificationState((prev) => ({
            ...prev,
            [currentPage]: notifications,
        }));
        setTotalNotifications((prev) => ({
            ...prev,
            [currentPage]: totalDocuments,
        }));
        setIsFetching(false);
    };

    const handleNotificationsMarkedRead = (data: any) => {
        if (!data?.success) {
            return;
        }

        const { notificationIds = [] } = data;

        setNotificationState((prev) => {
            // Remove the read notifications from the unread list to keep notification drawer in sync
            const newUnreads = prev.UNREADS.filter((notification) => !notificationIds.includes(notification?._id));

            const updatedNotifications = !filters?.unReadOnly
                ? prev[filters?.pageName].map((notification) => {
                      return notificationIds.includes(notification?._id)
                          ? { ...notification, isRead: true }
                          : notification;
                  })
                : newUnreads;

            return {
                ...prev,
                UNREADS: newUnreads,
                [filters.pageName]: updatedNotifications,
            };
        });
    };

    const handleNotificationsMarkedAllAsRead = (data: any) => {
        if (!data?.success) {
            return;
        }

        setNotificationState((prev) => ({
            ...prev,
            UNREADS: [], // Remove all the read notifications from the unread list to keep notification drawer in sync
            [filters.pageName]: !filters?.unReadOnly ? prev[filters.pageName].map((n) => ({ ...n, isRead: true })) : [],
        }));
    };

    const handleNotificationsDeleted = (data: any) => {
        if (!data?.success) {
            return;
        }

        const { notificationIds } = data;
        const currentPage = filters.pageName || "RECENT";

        const newUnreads = notificationState.UNREADS.filter(
            (notification) => !notificationIds?.includes(notification._id)
        );

        setNotificationState((prev) => ({
            ...prev,
            UNREADS: newUnreads,
            [currentPage]: prev[currentPage].filter((notification) => !notificationIds?.includes(notification._id)),
        }));

        setTotalNotifications((prev) => ({
            ...prev,
            UNREADS: newUnreads.length,
            [currentPage]: prev[currentPage] - notificationIds?.length,
        }));
    };

    const handleClearAllNotifications = (data: any) => {
        if (!data?.success) {
            return;
        }

        const currentPage = filters.pageName || "RECENT";

        setNotificationState((prev) => ({
            ...prev,
            UNREADS: [],
            [currentPage]: [],
        }));

        setTotalNotifications((prev) => ({
            ...prev,
            UNREADS: 0,
            [currentPage]: 0,
        }));
    };

    const handleNotificationConfigUpdate = (data: any) => {
        if (!data?.success) {
            return;
        }

        setIsFetching(true);
        fetchNotifications(filters);
        fetchOnlyUnreadNotifications();
    };

    // Socket listeners setup
    useSocketListeners([
        {
            event: notificationEvents.GET_ALL_NOTIFICATIONS_RES,
            callback: handleGetAllNotifications,
        },
        {
            event: notificationEvents.NEW_NOTIFICATION,
            callback: handleNewNotification,
        },
        {
            event: notificationEvents.MARK_AS_READ_RES,
            callback: handleNotificationsMarkedRead,
        },
        {
            event: notificationEvents.MARK_ALL_AS_READ_RES,
            callback: handleNotificationsMarkedAllAsRead,
        },
        {
            event: notificationEvents.CLEAR_RES,
            callback: handleNotificationsDeleted,
        },
        {
            event: notificationEvents.CLEAR_ALL_RES,
            callback: handleClearAllNotifications,
        },
        {
            event: notificationEvents.NOTIFICATION_CONFIG_RES,
            callback: handleNotificationConfigUpdate,
        },
    ]);

    // Selection handlers remain the same
    const selectNotification = (id: string) => {
        setSelectedNotifications((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const selectAllNotifications = (selected: boolean) => {
        const currentNotifications = notificationState[filters.pageName || "RECENT"];

        if (selected) {
            const allSelected = currentNotifications.reduce(
                (acc, notification) => ({
                    ...acc,
                    [notification._id]: true,
                }),
                {}
            );
            setSelectedNotifications(allSelected);
        } else {
            setSelectedNotifications({});
        }
        setAllNotificationsSelected(selected);
    };

    // Action handlers remain mostly the same but now use current page context
    const markAsRead = (notificationIds: string[]) => {
        emit(notificationEvents.MARK_AS_READ_REQ, { notificationIds });
    };

    const markAllAsRead = () => {
        emit(notificationEvents.MARK_ALL_AS_READ_REQ, { filters: filters });
    };

    const deleteNotifications = (notificationIds: string[]) => {
        emit(notificationEvents.CLEAR_REQ, { notificationIds });
    };

    const deleteAllNotifications = () => {
        emit(notificationEvents.CLEAR_ALL_REQ, { filters: filters });
    };

    const updateNotificationConfig = (notificationConfigTypes: NotificationConfigTypes) => {
        emit(notificationEvents.NOTIFICATION_CONFIG_REQ, { notificationConfig: notificationConfigTypes });
    };

    const fetchNotifications = (newFilters: notificationsFiltersType) => {
        emit(notificationEvents.GET_ALL_NOTIFICATIONS_REQ, { filters: newFilters });
    };

    const fetchOnlyUnreadNotifications = () => {
        emit(notificationEvents.GET_ALL_NOTIFICATIONS_REQ, {
            filters: {
                page: 1,
                perPage: 20,
                orderBy: -1,
                pageName: "RECENT",
                unReadOnly: true,
            },
            eventSubType: notificationEventsSubTypes?.GET_ONLY_UNREADS,
        });
    };

    // Initialize notifications on mount or filters change
    useEffect(() => {
        if (!isSuperAdmin) {
            return;
        }

        setIsFetching(true);
        if (isConnected) {
            fetchOnlyUnreadNotifications();
            fetchNotifications(filters);
        }
    }, [filters, isConnected, isSuperAdmin]);

    const value = {
        // notifications: notificationState[filters.pageName || "RECENT"],
        notifications: notificationState,
        totalNotifications: totalNotifications,
        selectedNotifications,
        allNotificationsSelected,
        filters,
        isFetching,
        setIsFetching,
        markAsRead,
        markAllAsRead,
        deleteNotifications,
        deleteAllNotifications,
        updateNotificationConfig,
        fetchNotifications,
        fetchOnlyUnreadNotifications,
        selectNotification,
        selectAllNotifications,
        setFilters,
    };

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
}

// Custom hook remains the same
export function useNotifications() {
    const context = useContext(NotificationsContext);
    if (context === undefined) {
        throw new Error("useNotifications must be used within a NotificationsProvider");
    }
    return context;
}
