import { BarChart } from "@mui/x-charts/BarChart";
import { useSelector } from "react-redux";

import {
    EFunnelOverview,
    EFunnelOverviewLabel,
    EFunnelOverviewLabelEasyGrowth,
} from "../../../../../store/apis/analytics/analytics.enums";
import { TFunnelOverview } from "../../../../../store/apis/analytics/analytics.types";
import { checkEasySource } from "../../../../Signin/Signin.reducer";
import { defaultChartSetting } from "../../../utils";
import { FunnelViewProps } from "../FunnelView";

const valueFormatter = (value: number) => `${value}`;

const defaultData = {
    [EFunnelOverview.RELEVANT_CANDIDATES]: 0,
    [EFunnelOverview.TOTAL_CONTACTED]: 0,
    [EFunnelOverview.RESPONSES_RECEIVED]: 0,
    [EFunnelOverview.POSITIVE_RESPONSES]: 0,
};

const generateDataset = (isEasySource: boolean, data?: TFunnelOverview["counts"]) => {
    return Object.entries({ ...defaultData, ...data })
        .filter(([key, value]) => (key === EFunnelOverview.MEETINGS_BOOKED ? !!value : true))
        .map(([key, value]) => ({
            count: value,
            key: isEasySource ? (EFunnelOverviewLabel as any)[key] : (EFunnelOverviewLabelEasyGrowth as any)[key],
        }));
};

interface OverviewBarChartProps extends FunnelViewProps {}

export default function OverviewBarChart(props: OverviewBarChartProps) {
    const isEasySource = useSelector(checkEasySource);
    const { data, date } = props;
    const dataset = generateDataset(isEasySource, data?.counts);
    const seriesLabel = date[0]?.format?.("MMM D, YYYY") || "";
    return (
        <BarChart
            dataset={dataset}
            yAxis={[{ scaleType: "band", dataKey: "key" }]}
            series={[{ dataKey: "count", label: `Since ${seriesLabel}`, valueFormatter }]}
            layout="horizontal"
            margin={{ bottom: 0 }}
            slotProps={{
                bar: {
                    rx: 5,
                },
            }}
            {...{ ...defaultChartSetting, height: data?.counts?.meetingsBooked ? 260 : 220 }}
        />
    );
}
