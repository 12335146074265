import { FormControl } from "@mui/material";
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";

import { CustomFormHelperText } from "../../../../../components/CustomFormHelperText";
import { useFetchActivityTags } from "../../../../all-candidates/AllCandidatesContent";
import { commonPaperProps } from "../../../../all-candidates/components/ContactOverviewActivities/EditActivityForm";
import { CustomTextField } from "../../../../all-candidates/components/CustomTextField";
import { useCreateActivityFormState } from "../NotesTab";

export function ActivityTagsMenu() {
    const { control } = useCreateActivityFormState();
    const { isLoading, data = [] } = useFetchActivityTags();
    return (
        <Controller
            control={control}
            name="tag"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => (
                <FormControl error={Boolean(error)}>
                    <Autocomplete
                        disabled={isSubmitting}
                        filterOptions={(options, params) => {
                            const filtered = options.filter((option) =>
                                option.toLowerCase().includes(params.inputValue.toLowerCase())
                            );
                            if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
                                filtered.push(`Add "${params.inputValue}"`);
                            }
                            return filtered;
                        }}
                        value={value}
                        freeSolo
                        options={data}
                        onChange={(e, newValue) => {
                            e.stopPropagation();
                            if (
                                typeof newValue === "string" &&
                                newValue.startsWith('Add "') &&
                                newValue.endsWith('"')
                            ) {
                                onChange(newValue.slice(5, -1));
                            } else {
                                onChange(newValue);
                            }
                        }}
                        size="small"
                        sx={{
                            width: "180px",
                            "&.MuiAutocomplete-root": {
                                "& .MuiTextField-root": {
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.7rem",
                                    },
                                },
                            },
                        }}
                        slotProps={{
                            paper: {
                                sx: commonPaperProps,
                            },
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <CustomTextField
                                {...params}
                                legendWidth={70}
                                error={error !== undefined}
                                label="Activity Tag"
                                isLoading={isLoading}
                                inputProps={{
                                    ...params.inputProps,
                                    onClick: (e) => e.stopPropagation(),
                                    placeholder: "Select activity tag",
                                }}
                            />
                        )}
                    />
                    {error !== undefined && <CustomFormHelperText>Please select tag</CustomFormHelperText>}
                </FormControl>
            )}
        />
    );
}
