import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import { Avatar, Box, Divider, IconButton, Paper, Stack, Typography, useMediaQuery } from "@mui/material";

import HoverTruncateText from "../../atoms/HoverTruncateText";
import { SearchCandidate } from "../../store/reducers/searchCandidates/searchCandidates.types";

interface CandidateCardProps extends SearchCandidate {
    hideAvatar?: boolean;
}

export default function CandidateCard(props: CandidateCardProps) {
    const {
        desireMaxSalary,
        desireMinSalary,
        latestjobTitle,
        desiredjobTitle,
        company,
        location,
        name,
        photo,
        profileUrl,
        stability,
        title,
        hideAvatar,
    } = props;

    const desktop1000 = useMediaQuery("(min-width:1000px)");
    const desktop1100 = useMediaQuery("(min-width:1100px)");

    const getMaxWidth = () => {
        switch (true) {
            case desktop1000:
                return 300;
            case desktop1100:
                return 350;
            default:
                return 400;
        }
    };

    const maxWidth = getMaxWidth();

    if (company?.name === null || !name) {
        return null;
    }

    return (
        <Paper
            elevation={0}
            sx={(theme) => ({
                borderRadius: theme.shape.borderRadius,
                padding: "0.75rem 1.5rem",
                display: "grid",
                gridTemplateColumns: hideAvatar ? "2fr" : "80px 2fr",
                gap: "1rem",
                border: "1px solid rgba(0,0,0,0.1)",
                height: 120,
            })}
        >
            {!hideAvatar && (
                <Avatar
                    sx={{ width: 60, height: 60 }}
                    src={!photo || photo.startsWith("data:") ? undefined : photo}
                    alt="avatar"
                />
            )}
            <Stack
                sx={() => ({
                    display: "grid",
                    gridTemplateColumns: "1fr 1px 1fr",
                    gridTemplateRows: "1fr",
                    gap: "1rem",
                })}
            >
                <Stack>
                    <Stack direction="row" alignItems="center">
                        <HoverTruncateText maxWidth={maxWidth} fontSize={16} sx={{ fontWeight: 600, paddingLeft: 0 }}>
                            {name}
                        </HoverTruncateText>
                        {profileUrl && (
                            <IconButton color="info" size="small">
                                <LinkedInIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Stack>
                    <HoverTruncateText maxWidth={maxWidth} fontSize={14} sx={{ fontWeight: 500, paddingLeft: 0 }}>
                        {title}
                    </HoverTruncateText>
                    {desiredjobTitle && (
                        <Typography fontSize={12} variant="body2">
                            <b>Desired job title</b>: {desiredjobTitle}{" "}
                        </Typography>
                    )}
                    {latestjobTitle && (
                        <Typography fontSize={12} variant="body2">
                            <b>Current job title</b>: {latestjobTitle}{" "}
                        </Typography>
                    )}

                    <Typography fontSize={14} fontWeight={500}>
                        {company?.name ? (
                            <Typography
                                component="a"
                                color="#0288d1"
                                fontSize="inherit"
                                href={company.url}
                                target="_blank"
                            >
                                @ {company.name}
                            </Typography>
                        ) : null}
                    </Typography>
                </Stack>
                <Divider orientation="vertical" sx={{ height: "100%" }} />
                <Box>
                    <Typography display="flex" alignItems="center" gap={1} fontSize={12} marginBottom={1}>
                        <LocationOnIcon color="disabled" fontSize="small" /> {location}
                    </Typography>
                    {(stability?.role || stability?.company) && (
                        <Typography display="flex" alignItems="center" gap={1} fontSize={12} variant="body2">
                            <WorkIcon color="disabled" sx={{ fontSize: 16 }} />
                            {stability?.role && `${stability.role} in role`}
                            {stability?.role && stability?.company ? ", " : ""}
                            {stability?.company && `${stability.company} in company`}
                        </Typography>
                    )}
                    {typeof desireMinSalary === "number" && typeof desireMaxSalary === "number" ? (
                        <>
                            <Typography fontSize={12} variant="body2">
                                <b>Expected compensation range (USD)</b>
                            </Typography>
                            <Typography fontSize={12} variant="body2">
                                {desireMinSalary} (min) - {desireMaxSalary} (max)
                            </Typography>
                        </>
                    ) : null}
                </Box>
            </Stack>
        </Paper>
    );
}
