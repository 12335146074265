import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import { selectKeywords, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";
import { dispatchEnterKeyboardEvent } from "../utils/keyboardEvent";

interface KeywaordsProps {
    multiple?: boolean;
    id: SearchStateKeys;
}

export function Keywords({ id, multiple = true }: KeywaordsProps) {
    const { isLoading, dispatch } = useSearchState(id);
    const { values, options, error } = useSelector(selectKeywords);
    const [isFocused, setIsFocused] = useState(false);
    const ref = useRef(null);

    const handleChange = (value: string | string[] | null) => {
        const updatedValue = Array.isArray(value)
            ? value.map((value) => ({ value, excluded: false }))
            : [{ value: value || "", excluded: false }];

        dispatch(
            setValues({
                key: id,
                value: updatedValue,
            })
        );
    };

    return (
        <Autocomplete
            multiple={multiple}
            freeSolo
            size="small"
            ChipProps={{ color: "success" }}
            value={multiple ? values.map(({ value }) => value) : values[0]?.value || ""}
            options={Object.keys(options).map((i) => i)}
            loading={isLoading}
            onChange={(_, value) => {
                handleChange(value);
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
                setIsFocused(false);
                dispatchEnterKeyboardEvent(ref.current);
            }}
            renderInput={(params) => (
                <Tooltip
                    title={multiple ? "Press 'enter' after each keyword" : "Use 'AND' or 'OR' between keywords"}
                    open={isFocused}
                >
                    <TextField
                        {...params}
                        InputLabelProps={{ sx: { fontSize: 14 } }}
                        ref={ref}
                        label="Keywords"
                        error={Boolean(error)}
                        helperText={error}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                            sx: { fontSize: 14 },
                        }}
                    />
                </Tooltip>
            )}
        />
    );
}
