import { useEffect, useState } from "react";
 import MUIModal from "@mui/material/Modal";
 import { useDispatch, useSelector } from "react-redux";

 import { EditSignature } from "../../OutReach/components/EditSignature";
 import { isSignatureModalOpen, toggleEditSignatureModal } from "../../OutReach/outreach.slice";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
 

 export default function EditSignatureHandler() {
     const dispatch = useDispatch();
     const editSignature = useSelector(isSignatureModalOpen);
     const [showModal, setShowModal] = useState(false);
     useEffect(() => {
         if (editSignature !== undefined) {
             setShowModal(editSignature);
         }
     }, [editSignature]);

     const handleClose = () => {
         setShowModal(false);
         dispatch(toggleEditSignatureModal( false ));
     };
     return (
         <>
             <MUIModal open={showModal} onClose={handleClose}>
                 <BaseModal onClose={handleClose} hideCloseButton={!false} overlayStyles={{padding:0,maxWidth:'26vw'}}>
                     <EditSignature/>
                 </BaseModal>
             </MUIModal>
         </>
     );
 }