import SendRoundedIcon from "@mui/icons-material/SendRounded";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import FormControl from "@mui/joy/FormControl";
import Stack from "@mui/joy/Stack";
import Textarea, { TextareaProps } from "@mui/joy/Textarea";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import { useRef, useState } from "react";

import { SMS_BODY_LIMIT, SMS_ERROR_MESSAGE } from "../../../../utils/Constants";
import { calculateQuillCharacterCount } from "../../../../utils/helper";
import { useSuperInboxContext } from "../../Context";

interface MessageInputProps extends Omit<TextareaProps, "onSubmit"> {
    onSubmit: (body: string, callback: () => void) => void;
    isLoading?: boolean;
}

export default function MessageInput(props: MessageInputProps) {
    const { isMobile } = useSuperInboxContext();
    const { onSubmit, isLoading, ...rest } = props;
    const textAreaRef = useRef<HTMLDivElement>(null);
    const [textAreaValue, setTextAreaValue] = useState("");

    const characterCount = calculateQuillCharacterCount(textAreaValue);
    const isSMSLimitExceeded = characterCount > SMS_BODY_LIMIT;

    const handleClick = () => {
        if (textAreaValue.trim() !== "" && !isSMSLimitExceeded) {
            onSubmit(textAreaValue, () => setTextAreaValue(""));
        }
    };

    return (
        <Box sx={{ px: 2, pb: 3 }}>
            <Tooltip open={isSMSLimitExceeded} variant="soft" color="danger" title={SMS_ERROR_MESSAGE}>
                <FormControl>
                    <Textarea
                        placeholder="Type something here…"
                        aria-label="Message"
                        ref={textAreaRef}
                        onChange={(e) => {
                            setTextAreaValue(e.target.value);
                        }}
                        value={textAreaValue}
                        minRows={3}
                        maxRows={10}
                        endDecorator={
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                flexGrow={1}
                                sx={{
                                    p: 1,
                                    borderTop: "1px solid",
                                    borderColor: "divider",
                                }}
                            >
                                <Typography fontSize={14} textColor={isSMSLimitExceeded ? "danger.500" : "neutral.900"}>
                                    {characterCount} / {SMS_BODY_LIMIT}
                                </Typography>
                                <Button
                                    size="sm"
                                    color="primary"
                                    disabled={isLoading}
                                    sx={{
                                        cursor: isLoading ? "progress" : "auto",
                                        alignSelf: "center",
                                        borderRadius: "sm",
                                    }}
                                    endDecorator={isLoading ? <CircularProgress /> : <SendRoundedIcon />}
                                    onClick={handleClick}
                                >
                                    {!isMobile && "Send"}
                                </Button>
                            </Stack>
                        }
                        onKeyDown={(event) => {
                            if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
                                handleClick();
                            }
                        }}
                        sx={{
                            "& textarea:first-of-type": {
                                minHeight: 72,
                            },
                        }}
                        {...rest}
                    />
                </FormControl>
            </Tooltip>
        </Box>
    );
}
