import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import {
    FetchVettingCriteriaPayload,
    SetCriteriaPayload,
    cancelSagas,
    fetchVettingCriteria,
    setCriteria,
    setErrorWhileFetchingVettingCriteria,
} from "./vettingCriteria.slice";

import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../../../store/reducers/loaders.reducer";
import API from "../../../../utils/API";
import { CancelSagas } from "../../../../utils/saga.utils";
import handleException from "../../../../utils/sentry";

function* fetchVettingCriteriaSaga(action: FetchVettingCriteriaPayload): SagaIterator {
    try {
        const { id } = action.payload;
        yield put(setErrorWhileFetchingVettingCriteria(false));
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().get, "/v2/project/get/" + id);

        if (response?.data?.vettingCriteria) {
            const criteria = response.data.vettingCriteria;

            yield put(setCriteria(criteria as SetCriteriaPayload[]));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorWhileFetchingVettingCriteria(true));
        console.error({ error });
        yield put(setErrorNotification("error occurred while fetching vetting criteria's"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    // @ts-ignore
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchVettingCriteria.type, fetchVettingCriteriaSaga),
    ];

    yield takeLatest(cancelSagas.type, CancelSagas, tasks);
}
