import Box from "@mui/joy/Box";
import Checkbox from "@mui/joy/Checkbox";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet, { SheetProps } from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ProjectsDropdown from "../../../../components/Dropdowns/ProjectsDropdown";
import MembersDropdown from "../../../../components/MembersDropdown";
import useOnClickOutside from "../../../../hooks/useClickOutside";
import { EChannelLabel, ESentimentLabel, EStatusLabel } from "../../../../store/apis/super-inbox/super-inbox.enums";
import { isSuperAdminUser, selectUser } from "../../../Signin/Signin.reducer";
import { useSuperInboxContext } from "../../Context";
import { closeFiltersPane } from "../../utils";
import Scrollable from "../Scrollable";

interface FilterContainerProps extends Omit<SheetProps, "onChange"> {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    options?: { [key: string]: boolean };
    labelMap?: { [key: string]: string };
    multiSelect?: boolean;
    disabled?: boolean;
}

function FilterContainer(props: FilterContainerProps) {
    const { title, onChange, options, children, labelMap, multiSelect = true, disabled, ...rest } = props;
    return (
        <Sheet
            sx={{
                p: 2,
                my: 1,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 14,
            }}
            {...rest}
        >
            <Typography id={`${title}-group`} level="body-sm" fontWeight="lg" pb={1}>
                {title}
            </Typography>

            {options &&
                (multiSelect ? (
                    <div role="group" aria-labelledby={`${title}-group`}>
                        <List sx={{ pb: 0 }}>
                            {Object.entries(options).map(([key, value]) => (
                                <ListItem key={key} sx={{ py: 0, px: 0 }}>
                                    <Checkbox
                                        disabled={disabled}
                                        sx={{ fontSize: 14 }}
                                        checked={value}
                                        onChange={onChange}
                                        name={key}
                                        label={labelMap?.[key] ?? key}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                ) : (
                    <RadioGroup name="radio-buttons-group" sx={{ pt: 1 }}>
                        {Object.entries(options).map(([key, value]) => (
                            <Radio
                                disabled={disabled}
                                sx={{ fontSize: 14 }}
                                name={key}
                                key={key}
                                checked={value}
                                onChange={onChange}
                                value={key}
                                label={labelMap?.[key] ?? key}
                                variant="soft"
                            />
                        ))}
                    </RadioGroup>
                ))}

            {children}
        </Sheet>
    );
}

export function Filters() {
    const { t } = useTranslation();
    const {
        filters: {
            status,
            channels,
            sentiment,
            projectId,
            userId,
            setChannels,
            setStatus,
            setSentiment,
            setProjectId,
            setUserId,
        },
        totalCandidates,
        // applyFilters,
    } = useSuperInboxContext();
    const user = useSelector(selectUser);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const isMemberDropdownVisible = user?.roleType === "ADMIN" || isSuperAdmin;

    const ref = useRef(null);
    useOnClickOutside(ref, () => closeFiltersPane(), [document.getElementById("filter-drawer-button")]);

    const handleChannelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // TODO: to be removed multiple select option once we figure out how to optimise all channel aggregations on BE
        setChannels({
            ...channels,
            [event.target.name]: event.target.checked,
        });
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatus({
            ...status,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSentimentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSentiment({
            ...sentiment,
            [event.target.name]: event.target.checked,
        });
    };
    return (
        <Box ref={ref}>
            <Scrollable.Container sx={{ height: "calc(100dvh - 120px)" }}>
                <DialogTitle sx={{ p: 2 }}>Filters</DialogTitle>
                <Scrollable.Content>
                    <DialogContent sx={{ px: 2 }}>
                        {isMemberDropdownVisible && (
                            <FilterContainer title="Members">
                                <MembersDropdown value={userId} onChange={setUserId} />
                            </FilterContainer>
                        )}
                        <FilterContainer title={t("superInbox.projectFilterDropdownLable")}>
                            <ProjectsDropdown projectId={projectId} handleChange={setProjectId} userId={userId} />
                        </FilterContainer>
                        <FilterContainer
                            title="Channels"
                            onChange={handleChannelChange}
                            options={channels}
                            labelMap={EChannelLabel}
                        />
                        <FilterContainer
                            title="Status"
                            onChange={handleStatusChange}
                            options={status}
                            labelMap={EStatusLabel}
                        />
                        <FilterContainer
                            disabled={!status.responded}
                            title="Sentiment"
                            onChange={handleSentimentChange}
                            options={sentiment}
                            labelMap={ESentimentLabel}
                        />
                    </DialogContent>
                    <Typography display="flex" gap={0.5} justifyContent="center" px={2} fontSize={11} component={"i"}>
                        <b>{totalCandidates}</b> conversations found
                    </Typography>
                    <Box width="100%" display="flex" justifyContent="flex-end" px={2} py={1}>
                        {/* <Button variant="soft" onClick={applyFilters}>
                    Apply
                </Button> */}
                    </Box>
                </Scrollable.Content>
            </Scrollable.Container>
        </Box>
    );
}
