import { PayloadAction } from "@reduxjs/toolkit";
import { MRT_ColumnFiltersState, MRT_PaginationState, type MRT_VisibilityState } from "material-react-table";
import { z } from "zod";

import { ReadOnlyOption } from "../../common";

export const fetchActivityTagsResponseSchema = z.object({
    message: z.string(),
    data: z.string().array(),
});

export type FetchActivityTagsResponse = z.infer<typeof fetchActivityTagsResponseSchema>;

export type AddCandidatesToExclusionListPayload = PayloadAction<readonly string[]>;

export type Order = "asc" | "desc";

export type ActivityKeys =
    | "Call"
    | "Interview"
    | "Conversation"
    | "Notes"
    | "Candidate comments"
    | "Activities"
    | "Search Status"
    | "Referral"
    | "Next Steps"
    | "Client Comments"
    | "Referral/comments"
    | "Stats"
    | "Referral Source"
    | "Vetting Sources"
    | "References Summary";

const candidateProject = z.object({
    name: z.string(),
    id: z.number(),
});

export type CandidateProject = z.infer<typeof candidateProject>;

export function isCandidateProject(obj: any): obj is CandidateProject {
    return obj !== null && typeof obj === "object" && typeof obj.name === "string" && typeof obj.id === "number";
}

export function isString(value: unknown): value is string {
    return typeof value === "string";
}

export function isStringArray(value: unknown): value is string[] {
    return Array.isArray(value) && value.every((item) => typeof item === "string");
}

const candidateFieldValue = z.union([z.string(), z.array(z.string()), candidateProject]);

export type CandidateFieldValue = z.infer<typeof candidateFieldValue>;

const candidateSchema = z.record(candidateFieldValue).and(
    z.object({
        _id: z.string(),
    })
);

export type Candidate = z.infer<typeof candidateSchema>;

export type AllCandidatesTableItemKeys = keyof Candidate;

const allCandidatesFilterValue = z.object({
    label: z.string(),
    value: z.string(),
});

export type FilterValue = z.infer<typeof allCandidatesFilterValue>;

const allCandidatesFilterValueSchema = z.object({
    label: z.string(),
    hidden: z.boolean(),
    value: z.array(allCandidatesFilterValue),
});

export type AllCandidatesFilterValue = z.infer<typeof allCandidatesFilterValueSchema>;

export type AllCandidatesCSVUploadPayload = PayloadAction<FormData>;

export type SetErrorPayload = PayloadAction<{
    key: AllCandidatesTableItemKeys;
    text?: string;
    action?: "ADD" | "REMOVE";
}>;

export type SetAllCandidatesFilterValuePayload = {
    key: string;
    value: ReadOnlyOption[];
};

export type AddAllCandidatesFilterPayload = {
    key: string;
    hidden: boolean;
};

export type FilterBy = Record<string, string[]>;
export type SortBy = {
    id: string;
    desc: boolean;
};

export type FetchCandidatesPayload = {
    search?: string;
    filterBy?: FilterBy;
    sortBy?: SortBy;
    filterByColumn?: MRT_ColumnFiltersState;
    pageSize: number;
    start: number;
};

export type AllCandidatesTableState = {
    totalCandidates: number;
    order: Order;
    orderBy: keyof Candidate;
    selected: readonly string[];
    page: number;
    rowsPerPage: number;
    searchQuery: string;
    selectedCandidateIdForAction: string;
    isAllRowsSelected: boolean;
};

export type CandidateFilterValue = Record<string, AllCandidatesFilterValue>;

export type CandidateFilters = {
    values: CandidateFilterValue;
    order: string[];
};

export type CandidateErrors = Partial<Record<AllCandidatesTableItemKeys, string>>;

export type SavedFiltersList = {
    filters: CandidateFilterValue;
    name: string;
    orgId: number;
    userId: number;
    _id: string;
    createdAt: string;
}[];

export type AllCandidatesState = {
    visibility: MRT_VisibilityState;
    pagination: MRT_PaginationState;
    emailThreadId: string | null;
    tableState: AllCandidatesTableState;
    rows: Candidate[];
    showFiltersDrawer: boolean;
    filters: CandidateFilters;
    columnFilter: MRT_ColumnFiltersState;
    totalRows: number;
    errors: CandidateErrors;
    sorting: { id: string; desc: boolean }[];
    candidateReachouts: {
        candidateInfo: any;
        reachout: any;
    };
    savedFiltersList: SavedFiltersList;
};

export type SearchCandidatesPayload = PayloadAction<string>;

export type SetAllCandidatesPayload = Candidate[];

export type ExportWithSearchResultsRequestPayload = {
    selectAll?: boolean;
    search?: string;
    filterBy?: FilterBy;
};

export type CreateCandidateListPayload = {
    selectAll?: boolean;
    search?: string;
    filterBy?: FilterBy;
    hubspotCandidateIds?: readonly string[];
    name: string;
};

// create-Candidate list
export interface CreateCandidateListResponse {
    message: string;
    data: CreateCandidateListData;
}

export interface CreateCandidateListData {
    name: string;
    createdBy: number;
    belongsTo: number;
    metaData: CreateCandidateListMetaData;
    readOnly: boolean;
    _id: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

export interface CreateCandidateListMetaData {
    size: number;
}

// fetch-Candidates
export interface FetchCandidatesRequest {
    search?: string;
    filterBy?: FilterBy;
}

export type FetchCandidateDataActivityValues = {
    body: string;
    updatedAt: string;
}[];

export type FetchCandidateDataActivity = Record<Partial<ActivityKeys>, FetchCandidateDataActivityValues>;

export type FetchCandidateData = Omit<Candidate, "List of projects" | "Project Stage" | "Activity"> & {
    Projects?: {
        _id: number;
        name: string;
        stage: string;
        detailedStage: string;
    }[];
    Activity?: FetchCandidateDataActivity;
};

export type FetchCandidateResponseData = {
    candidates: FetchCandidateData[];
    totalCandidates: number;
};

export interface FetchCandidatesResponse {
    message: string;
    data: FetchCandidateResponseData;
}

const threadSchema = z.object({
    _id: z.string(),
    userId: z.number(),
    belongsTo: z.number(),
    candidate: z.string(),
    from: z.string(),
    to: z.string(),
    cc: z.string(),
    subject: z.string(),
    body: z.string(),
    emailId: z.string(),
    threadId: z.string(),
    sentOn: z.string(),
    source: z.union([z.literal("Gmail"), z.literal("Outlook")]),
    createdAt: z.string(),
    updatedAt: z.string(),
});

export type Thread = z.infer<typeof threadSchema>;

export const threadListSchema = z.record(z.array(threadSchema));

export type ThreadList = z.infer<typeof threadListSchema>;

export const fetchCandidateEmailThreadsSchema = z.object({
    message: z.string(),
    data: z.array(threadSchema),
});

export type FetchCandidateEmailThreadsResponse = z.infer<typeof fetchCandidateEmailThreadsSchema>;

// send reminder email types
const sendReminderEmailRequestSchema = z.object({
    activityId: z.string(),
    emailId: z.string(),
    scheduledFor: z.string(),
});

export type SendReminderEmailRequest = z.infer<typeof sendReminderEmailRequestSchema>;

export const sendReminderEmailResponseSchema = z.object({
    message: z.string(),
    success: z.boolean(),
});

export type SendReminderEmailResponse = z.infer<typeof sendReminderEmailResponseSchema>;

export type CandidateProjectActivity = {
    belongsTo: number;
    body: string;
    candidateId: string;
    createdAt: string;
    createdBy: number;
    isDeleted: boolean;
    projectId: number;
    read: boolean;
    title: string;
    updatedAt: string;
    __v: number;
    _id: string;
};

export type CreateCandidateRequestPayload = {
    url: string;
    firstName: string;
    lastName: string;
    project: string;
    prevProject: string;
    email?: string;
    profileUrl?: string;
    jobTitle?: string;
    companyInformation?: string;
    contact?: string;
    location?: string;
    resume?: File;
    labels?: string[];
};
