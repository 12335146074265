import { Stack, Tooltip } from "@mui/joy";
import { Typography } from "@mui/material";
import { useMemo } from "react";

import style from "./SplitChart.module.scss";

const getColor = (value, min, max) => {
    // Handle edge cases
    if (min === max) return "#16A818"; // Green for single value
    if (value === min) return "#16A818"; // Green for minimum value

    // Ensure value is within bounds
    if (value <= min) return "#16A818"; // Start color (green)
    if (value >= max) return "#B7222C"; // End color (red)

    // Calculate the ratio of value between min and max using a non-linear scaling for better differentiation
    const ratio = Math.pow((value - min) / (max - min), 1); // Adjust the exponent for better differentiation

    // Interpolate between green and red with a slight blue component to increase color variance
    const r = Math.round(255 * ratio); // Red increases with ratio
    const g = Math.round(255 * (1 - ratio)); // Green decreases with ratio
    const b = Math.round(100 * (1 - ratio)); // Adds a small blue component for differentiation

    // Convert RGB to hex format and ensure it's correctly padded
    const toHex = (component) => component.toString(16).padStart(2, "0").toUpperCase();
    const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

    return hex;
};

const findMinMax = (data) => {
    if (!data || data.length === 0) return { min: 0, max: 0 };

    return data.reduce(
        (acc, item) => {
            const value = item.totalCreditsUsed;
            return {
                min: Math.min(acc.min, value),
                max: Math.max(acc.max, value),
            };
        },
        { min: data[0]?.totalCreditsUsed, max: data[0]?.totalCreditsUsed }
    );
};

const getTotalUsedCredits = (data) => data.reduce((sum, item) => sum + item?.totalCreditsUsed, 0);

// Sample data example for testing
const sampleData = [
    { name: "My Project 1", totalCreditsUsed: 100, _id: 1 },
    { name: "My Project 2", totalCreditsUsed: 200, _id: 2 },
    { name: "My Project 3", totalCreditsUsed: 300, _id: 3 },
    { name: "My Project 4", totalCreditsUsed: 400, _id: 4 },
    { name: "My Project 5", totalCreditsUsed: 500, _id: 5 },
    { name: "My Project 6", totalCreditsUsed: 600, _id: 6 },
    { name: "My Project 7", totalCreditsUsed: 700, _id: 7 },
    { name: "My Project 8", totalCreditsUsed: 800, _id: 8 },
    { name: "My Project 9", totalCreditsUsed: 900, _id: 9 },
    { name: "My Project 10", totalCreditsUsed: 1000, _id: 10 },
    { name: "My Project 11", totalCreditsUsed: 10, _id: 11 },
    { name: "My Project 12", totalCreditsUsed: 200, _id: 12 },
];

const MIN_WIDTH_THRESHOLD = 3;

type SplitChartProps = {
    data: any;
    totalCredits?: number;
    isLoading?: boolean;
    onlyLabelForMax?: boolean;
    showEmptySegments?: boolean;
};

/**
 * A bar chart component that displays a split bar chart based on the provided data.
 *
 * Props:
 *  - data: an array of objects containing the following properties:
 *      - _id: a unique identifier for the segment
 *      - name: the name to be displayed in the tooltip
 *      - totalCreditsUsed: the value for the segment
 *  - totalCredits: the total number of credits allowed (optional)
 *  - isLoading: a boolean indicating whether the component should display a loading state (optional)
 *  - onlyLabelForMax: a boolean indicating whether only the label for the maximum value should be displayed (optional), when this is true totalCredits is ignored and the maximum value is used as the total credits
 *  - showEmptySegments: a boolean indicating whether to display empty segments (optional)
 *
 * Returns a JSX element.
 */
function SplitChart({ data, totalCredits, isLoading, onlyLabelForMax, showEmptySegments }: SplitChartProps) {
    const { min, max } = useMemo(() => findMinMax(data), [data]);

    // Calculate the total credits used to determine bar width percentages
    const totalUsedCredits = useMemo(() => getTotalUsedCredits(data), [data]);

    // const totalCreditsAllowed = totalCredits || totalUsedCredits;
    const totalCreditsAllowed =
        totalCredits && totalCredits !== -1 && !onlyLabelForMax ? totalCredits : totalUsedCredits;

    return (
        <Stack gap={1}>
            {!isLoading && data && (
                <div className={style["SplitChart__container"]}>
                    {data.map((item) => {
                        // Calculate the width percentage of each segment based on total credits
                        const widthPercentage = (item?.totalCreditsUsed / totalCreditsAllowed) * 100;

                        if (widthPercentage === 0 && !showEmptySegments) {
                            return null;
                        }

                        return (
                            <Tooltip
                                key={item._id}
                                title={item?.name ? `${item?.name}: ${item?.totalCreditsUsed}` : item?.totalCreditsUsed}
                                sx={{
                                    fontSize: 12,
                                }}
                            >
                                <div
                                    className={style["SplitChart__chunks"]}
                                    style={{
                                        width: `${widthPercentage + MIN_WIDTH_THRESHOLD}%`,
                                        backgroundColor: `${widthPercentage !== 0 ? getColor(item?.totalCreditsUsed, min, max) : "#cccbcb"}`,
                                    }}
                                />
                            </Tooltip>
                        );
                    })}
                </div>
            )}

            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {totalUsedCredits} Used
                </Typography>
                {totalCredits && totalCredits === -1 && (
                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                        Unlimited
                    </Typography>
                )}
                {totalCredits && totalCredits !== -1 && (
                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                        {totalCredits} Total
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}

export default SplitChart;
