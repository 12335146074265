import Menu, { MenuProps } from "@mui/material/Menu";
import { PersonalizeTagActionsWithMutation } from "../../../hooks/usePersonalizeValues";
import MenuItem from "@mui/material/MenuItem";

export default function PersonalizedTagsMenu({
    menuProps,
    actions,
}: {
    menuProps: MenuProps & {
        onClose: () => void;
    };
    actions: PersonalizeTagActionsWithMutation[];
}) {
    return (
        <Menu {...menuProps}>
            {actions.map(({ key, label, onClick }) => {
                return (
                    <MenuItem
                        key={key}
                        onClick={() => {
                            onClick?.();
                            // menuProps?.onClose?.();
                        }}
                    >
                        {label}
                    </MenuItem>
                );
            })}
        </Menu>
    );
}
