import { createElement, useState } from "react";
import { Card, CardActions, CardContent, Checkbox, Typography } from "@mui/material";

const CardComponent = (props) => {
    const { data, index, selectCard } = props;
    const [open, setOpen] = useState<boolean>(false);

    const handleCardClick = () => {
        selectCard(index, !data?.selected); 
    };

    return (
        <>
            <Card
                sx={{
                    width: 350,
                    height: 130,
                    padding: "10px",
                    cursor: "pointer",
                    border: !data?.selected ? "0.5px solid lightgrey" : "3px solid #46c2ff",
                    borderWidth: "0.2px",
                    backgroundColor: !data?.selected ? "white" : "#f0f4f8",
                    position: "relative",
                    "&:hover": {
                        backgroundColor: "#f0f4f8",
                    },
                    boxShadow: data?.selected ? "0 0 0 0.15rem #f0f4f8" : "none",
                }}
                onClick={handleCardClick}
                variant="outlined"
            >
                <CardContent>
                    <Typography variant="h5" component="div" sx={{ mt: 0.5, fontSize: "19px", fontWeight: 600 }}>
                        {data?.heading}
                    </Typography>
                    <Typography
                        sx={{
                            mb: 1.5,
                            mt: 1,
                            fontSize: "13px",
                            height: "60px",
                            fontWeight: 400,
                        }}
                        color="text.secondary"
                    >
                        {data?.description}
                    </Typography>
                </CardContent>

                <Checkbox
                    checked={data.selected || false}
                    onChange={handleCardClick}
                    inputProps={{ "aria-label": "Select card" }}
                    sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "lightgrey",
                        "&.Mui-checked": {
                            color: "#479cd2",
                            borderRadius: "30px",
                        },
                    }}
                />
            </Card>
            {open && data?.component && createElement(data.component, { onClose: () => setOpen(false) })}
        </>
    );
};

export default CardComponent;
