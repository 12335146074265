import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { hideWalkThrough, leavePage, setCanHideWalkThrough } from "./interActiveWalkthorughModal.reducer";

import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";

function* hideWalkThroughSaga({ payload }: { payload: boolean }): SagaIterator {
    try {
        const state = yield select();
        const canHideWalkThrough = get(state, "signin.canHideWalkThrough");
        if (canHideWalkThrough) return;
        yield call(new API().put, "/auth/hide-walkthrough", {
            canHideWalkThrough: true,
        });
        yield put(setCanHideWalkThrough(true));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(hideWalkThrough.type, hideWalkThroughSaga),
    ];
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
