import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

import { RootState } from "../../../../store";

interface IMarketingData {
    projectId?: number | string;
    marketingOverview?: string;
    marketingCompanyLogo?: string;
    marketingCompanyVideo?: string;
    marketingCompanyVideoLink?: string;
    showUploadMarketingCompanyPage?: boolean;
}

const initialState = Object.freeze({
    showUploadMarketingCompanyPage: false,
    marketingOverview: "",
    marketingCompanyName: "",
    marketingCompanyLogo: "",
    marketingCompanyVideo: "",
    marketingCompanyVideoLink: "",
    marketingCompanyPage: {},
});

const reducers = {
    resetState(state: any, { payload }: { payload: IMarketingData }) {
        state.marketingOverview = payload.marketingOverview;
        state.marketingCompanyLogo = payload.marketingCompanyLogo;
        state.marketingCompanyVideo = payload.marketingCompanyVideo;
        state.marketingCompanyVideoLink = payload.marketingCompanyVideoLink;
        if (payload.showUploadMarketingCompanyPage) {
            state.showUploadMarketingCompanyPage = payload.showUploadMarketingCompanyPage;
        }
        state.projectId = payload.projectId;
    },
    setShowUploadMarketingCompanyPage(state: any, { payload }: { payload: boolean }) {
        state.showUploadMarketingCompanyPage = payload;
    },
    setMarketingOverview(state: any, { payload }: { payload: string }) {
        state.marketingOverview = payload;
    },
    setMarketingCompanyName(state: any, { payload }: { payload: string }) {
        state.marketingCompanyName = payload;
    },
    setMarketingCompanyLogo(state: any, { payload }: { payload: any }) {
        state.marketingCompanyLogo = payload;
    },
    setMarketingCompanyVideo(state: any, { payload }: { payload: any }) {
        state.marketingCompanyVideo = payload;
    },
    setMarketingCompanyVideoLink(state: any, { payload }: { payload: string }) {
        state.marketingCompanyVideoLink = payload;
    },
    updateMarketingCompanyPage(state: any, { payload }: { payload: any }) {},
    getMarketingCompanyPage(state: any, action: PayloadAction<string>) {},
    setMarketingCompanyPage(state: any, { payload }: { payload: any }) {
        state.marketingCompanyPage = payload;
    },
    leaveMarketingPage(state: typeof initialState) {},
};

export const pageSlice = createSlice({
    name: "MarketingPage",
    initialState,
    reducers,
});

export default pageSlice.reducer;

export const {
    setShowUploadMarketingCompanyPage,
    setMarketingOverview,
    setMarketingCompanyName,
    setMarketingCompanyLogo,
    setMarketingCompanyVideo,
    setMarketingCompanyVideoLink,
    updateMarketingCompanyPage,
    resetState,
    getMarketingCompanyPage,
    setMarketingCompanyPage,
    leaveMarketingPage,
} = pageSlice.actions;

export const selectShowUploadMarketingCompanyPage = (state: RootState) =>
    state.marketingPageReducer.showUploadMarketingCompanyPage;
export const selectOverview = (state: RootState) => state.marketingPageReducer.marketingOverview;
export const selectCompanyName = (state: RootState) => state.marketingPageReducer.marketingCompanyName;
export const selectCompanyLogo = (state: RootState) => get(state, "marketingPageReducer.marketingCompanyLogo");
export const selectCompanyVideoLink = (state: RootState) =>
    get(state, "marketingPageReducer.marketingCompanyVideoLink");
export const selectCompanyVideo = (state: RootState) => get(state, "marketingPageReducer.marketingCompanyVideo");
export const selectMarketingCompanyPage = (state: RootState) => get(state, "marketingPageReducer.marketingCompanyPage");
export const selectMarketingProjectId = (state: RootState) => get(state, "marketingPageReducer.projectId");
