import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import { Box, Button, IconButton, Link, Skeleton, Switch, TextField, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import Stack from "@mui/system/Stack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AccountMenu from "./accountMenu/AccountMenu";
import { CreateProject } from "./CreateProject/CreateProject";
import UploadMarketingPage from "./CreateProject/MarketingPage/Component/UploadMarketingPage";
import { selectShowUploadMarketingCompanyPage } from "./CreateProject/MarketingPage/MarketingPage.reducer";
import { CSNotificationModal } from "./CSNotificationModal";
import GlobalSearch from "./GlobalSearch";
import style from "./navbar.module.scss";

import { uploadprojectStatsFile } from "../../pages/allProjects/index.reducer";
import {
    fetchPendingInvitations,
    getNotification,
    markActivityNotificationAsRead,
    markReadNotification,
    selectNotification,
    selectPendingInvitations,
} from "../../pages/manageTeams/manageTeams.reducers";
import { INotification, NotificationSMSType } from "../../pages/manageTeams/manageTeams.types";
import {
    checkIfAccountRB2B,
    checkIsV2Autopilot,
    isSuperAdminUser,
    selectFromInvitation,
    selectShowAllProjects,
    setFromInvitation,
    updateUser,
} from "../../pages/Signin/Signin.reducer";
import { selectIsSuperAdminView, setIsSuperAdminView } from "../../pages/superAdmin/superAdmin.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectAddExclusionListModal } from "../../store/reducers/modals.slice";
import { AutoPilot } from "../AutoPilot/AutoPilot";
import { AutoPilotProject } from "../AutoPilot/AutoPilotProject";
import { AutoPilotSwitch } from "../AutoPilot/AutoPilotSwitch";
import AddExclusionList from "../ExclusionList/AddExclusionList";

type INotificationTile = {
    body: string;
    title: string;
    _id: string;
    candidateId: string;
    updatedAt: string;
    createdAt: string;
    onClick?: () => void;
    isSms?: boolean;
};

function getElapsedTimeFormatted(creationTime: string) {
    const currentTime = new Date();
    const _creationTime = new Date(creationTime);
    const timeDifference = currentTime.getTime() - _creationTime.getTime();

    const elapsedHours = Math.floor(timeDifference / (1000 * 60 * 60));

    if (elapsedHours < 24) {
        return `${elapsedHours} hours ago`;
    } else {
        const day = _creationTime.getDate();
        const month = _creationTime.toLocaleString("default", {
            month: "short",
        });
        let daySuffix = "th";
        if (day === 1 || day === 21 || day === 31) {
            daySuffix = "st";
        } else if (day === 2 || day === 22) {
            daySuffix = "nd";
        } else if (day === 3 || day === 23) {
            daySuffix = "rd";
        }

        return `${day}${daySuffix} ${month}`;
    }
}

function groupSMSByProjectName(smsNotifications: NotificationSMSType[]) {
    const grouped: { [key: string]: NotificationSMSType[] } = {};

    smsNotifications.forEach((obj) => {
        const projectName = obj.projectName;
        if (!grouped[projectName]) {
            grouped[projectName] = [];
        }
        grouped[projectName].push(obj);
    });
    return grouped;
}

function EmptyNotificationsIcon() {
    return (
        <Stack justifyContent="center" alignItems="center" m={8}>
            <svg
                style={{ alignSelf: "center" }}
                fill="#479BD2"
                width="75px"
                height="75px"
                viewBox="0 0 1000 1000"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#479BD2"
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M888 853q-21-48-56-123-7-15 2-28 32-54 46-115t8-123q-8-93-58-172-49-77-127-125-79-49-172-56-88-7-170 24-78 29-137.5 88.5T135 362q-31 81-24 170 7 92 56 172 48 78 125 127 79 50 172 58 62 6 123-8t115-47q13-8 28-2l123 56q11 5 21 .5t14.5-14.5-.5-21zM485 721q-11 27-38.5 38.5t-55 0-39-39 0-55 39-38.5 55 0 38.5 38.5 0 54.5v1zm-25-127q-20-8-28-28t0-40l73-248q8-19 24.5-32.5t38-16 41 5.5 32.5 25 15.5 38-5.5 41L527 566q-8 19-27.5 27.5t-39.5.5z"></path>
                </g>
            </svg>
            <Typography variant="body1" sx={(theme) => ({ color: theme.palette.grey[500] })} fontWeight={500}>
                You have no new notifications
            </Typography>
        </Stack>
    );
}

function NotificationTile({
    title,
    body,
    _id,
    candidateId,
    createdAt,
    onClick,
    redirectUrl,
}: INotification & INotificationTile) {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(markReadNotification([_id]));
        dispatch(markActivityNotificationAsRead(_id));
        if (redirectUrl) {
            window.open(`/candidate-reachout${redirectUrl}`);
        }
        window.open(`/candidate-reachout/${candidateId}`);
        if (onClick) {
            onClick();
        }
    };

    return (
        <Stack
            sx={(theme) => ({
                borderRadius: 1,
                ":hover": {
                    cursor: "pointer",
                    background: theme.palette.grey[200],
                },
            })}
            width={"full"}
            padding={2}
            px={4}
            spacing={1}
            onClick={handleClick}
        >
            <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={1}>
                <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={0.5}>
                    <NotificationsRoundedIcon style={{ fill: "#479bd2" }} sx={{ width: 18, height: 18 }} />
                    <Typography fontSize={"15px"} color={"#479bd2"} fontWeight={"600"}>
                        Activity
                    </Typography>
                </Stack>
                <Typography sx={(theme) => ({ color: theme.palette.grey[600] })} fontSize={"15px"}>
                    {getElapsedTimeFormatted(createdAt)}
                </Typography>
            </Stack>

            <Stack fontWeight={"light"} direction="column" spacing={0.5}>
                <Typography sx={(theme) => ({ color: theme.palette.grey[600] })} fontSize={"15px"}>
                    {body}
                </Typography>
            </Stack>
        </Stack>
    );
}

function SMSTile({ onClick, notifications }: { onClick: () => void; notifications: NotificationSMSType[] }) {
    const uniqueCandidateIds = new Set();
    notifications = notifications.filter((notification) => {
        if (uniqueCandidateIds.has(notification.candidateId)) {
            return false;
        } else {
            uniqueCandidateIds.add(notification.candidateId);
            return true;
        }
    });

    const dispatch = useDispatch();

    const handleClick = (notificationIds: string[], redirectUrl: string) => {
        dispatch(markReadNotification(notificationIds));
        window.open(redirectUrl);
        onClick();
    };

    if (notifications?.length >= 4) {
        return (
            <Stack
                sx={(theme) => ({
                    borderRadius: 1,
                    ":hover": {
                        cursor: "pointer",
                        background: theme.palette.grey[200],
                    },
                })}
                width={"full"}
                padding={2}
                px={4}
                spacing={1}
                onClick={() =>
                    handleClick(
                        notifications.map((i) => i._id),
                        notifications[0].redirectUrl
                    )
                }
            >
                <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={1}>
                    <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={0.5}>
                        <MessageRoundedIcon style={{ fill: "#479bd2" }} sx={{ width: 18, height: 18 }} />
                        <Typography fontSize={"15px"} color={"#479bd2"} fontWeight={"600"}>
                            Message
                        </Typography>
                    </Stack>
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.grey[600],
                        })}
                        fontSize={"15px"}
                    >
                        {getElapsedTimeFormatted(notifications[0].createdAt)}
                    </Typography>
                </Stack>

                <Stack fontWeight={"light"} direction="column" spacing={0.5}>
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.grey[600],
                        })}
                        fontSize={"15px"}
                    >
                        {`You received ${notifications?.length} SMS replies in your project '${notifications[0]?.projectName}' with the latest SMS received from ${notifications[0]?.candidateName}`}
                    </Typography>
                </Stack>
            </Stack>
        );
    }

    return (
        <>
            {notifications.map((notification, index) => {
                return (
                    <Stack
                        key={index}
                        sx={(theme) => ({
                            borderRadius: 1,
                            ":hover": {
                                cursor: "pointer",
                                background: theme.palette.grey[200],
                            },
                        })}
                        width={"full"}
                        padding={2}
                        px={4}
                        spacing={1}
                        onClick={() => handleClick([notification._id], notification.redirectUrl)}
                    >
                        <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={1}>
                            <Stack fontWeight={"light"} direction="row" alignItems={"center"} spacing={0.5}>
                                <MessageRoundedIcon style={{ fill: "#479bd2" }} sx={{ width: 18, height: 18 }} />
                                <Typography fontSize={"15px"} color={"#479bd2"} fontWeight={"600"}>
                                    Message
                                </Typography>
                            </Stack>
                            <Typography
                                sx={(theme) => ({
                                    color: theme.palette.grey[600],
                                })}
                                fontSize={"15px"}
                            >
                                {getElapsedTimeFormatted(notification.createdAt)}
                            </Typography>
                        </Stack>

                        <Stack fontWeight={"light"} direction="column" spacing={0.5}>
                            <Typography
                                sx={(theme) => ({
                                    color: theme.palette.grey[600],
                                })}
                                fontSize={"15px"}
                            >
                                {`You received an SMS reply from ${notification.candidateName} in your project '${notification.projectName}' `}
                            </Typography>
                        </Stack>
                    </Stack>
                );
            })}
        </>
    );
}

function NotificationSkeleton() {
    return (
        <Stack width={"full"} spacing={1}>
            <Stack direction="row" alignItems={"center"} spacing={1}>
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton variant="rounded" sx={{ height: 20, width: 100 }} />
            </Stack>
            <Skeleton variant="rounded" height={45} />
        </Stack>
    );
}

function Notifications() {
    const invitations = useSelector(selectPendingInvitations);
    const fromInvitation = useSelector(selectFromInvitation);
    const isNotificationLoading = useSelector(checkIfLoading(getNotification.type));
    const isPendingInvitationsLoading = useSelector(checkIfLoading(fetchPendingInvitations.type));

    let notifications = useSelector(selectNotification);

    const dispatch = useDispatch();
    const notificationIconRef = useRef<HTMLButtonElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (!anchorEl && fromInvitation) {
            dispatch(setFromInvitation(false));
        }

        if (anchorEl) {
            dispatch(fetchPendingInvitations({ action: fetchPendingInvitations.type }));
            dispatch(getNotification());
        }
    }, [anchorEl]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const pendingInvitations = invitations.filter(({ status }) => status === "PENDING");

    notifications = notifications.filter((i) => i?.active);

    if (!notifications?.length) {
        return null;
    }

    const activityNotifications = notifications?.filter((notification) => !notification.isSms);

    const smsNotifications: NotificationSMSType[] = notifications?.filter((notification) => notification.isSms);

    const groupedSmsNotification = groupSMSByProjectName(smsNotifications);

    const totalNotifications =
        activityNotifications?.length +
        pendingInvitations?.length +
        Object.values(groupedSmsNotification)?.reduce((sum, i) => {
            return sum + (i?.length >= 4 ? 1 : i?.length);
        }, 0);

    return (
        <div style={{ position: "relative" }}>
            <IconButton size="small" ref={notificationIconRef} onClick={handleClick}>
                <Badge badgeContent={totalNotifications} color="primary" max={100} style={{ fontFamily: "Poppins" }}>
                    <NotificationsNoneIcon htmlColor="#C2CFE0" sx={{ height: "28px", width: "28px" }} />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    "& .MuiPaper-elevation": {
                        borderRadius: "0.5rem",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                    },
                }}
                style={{
                    borderRadius: "1rem",
                    marginTop: "10px",
                    zIndex: 99,
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Stack
                    pb={2}
                    sx={{
                        width: "430px",
                        height: notifications?.length === 0 ? "300px" : "500px",
                    }}
                >
                    {isNotificationLoading || isPendingInvitationsLoading ? (
                        <Stack direction={"column"} justifyContent="center" alignItems="stretch" gap={3} px={2} py={2}>
                            {[...Array(5)].map((_, idx) => {
                                return <NotificationSkeleton key={idx} />;
                            })}
                        </Stack>
                    ) : (
                        <>
                            {notifications?.length === 0 ? (
                                <EmptyNotificationsIcon />
                            ) : (
                                <>
                                    <Typography
                                        px={4}
                                        py={2}
                                        fontWeight={"medium"}
                                        fontSize={"15px"}
                                        sx={(theme) => ({
                                            borderBottom: "1px solid",
                                            borderColor: theme.palette.grey[200],
                                        })}
                                    >
                                        Notifications
                                    </Typography>
                                    <Box
                                        style={{
                                            maxHeight: "calc(100% - 40px - 1rem)",
                                            overflow: "auto",
                                        }}
                                        sx={(theme) => ({
                                            "& > div": {
                                                borderBottom: "1px solid",
                                                borderColor: theme.palette.grey[200],
                                            },
                                        })}
                                    >
                                        {activityNotifications
                                            .filter((item) => item?.active || item?.status === "PENDING")
                                            .map((item, index: number) => {
                                                return (
                                                    <NotificationTile
                                                        {...item}
                                                        onClick={handleClose}
                                                        key={`notification-${index}`}
                                                    />
                                                );
                                            })}

                                        {Object.keys(groupedSmsNotification).map((key, index) => {
                                            return (
                                                <SMSTile
                                                    key={`sms-tile-${index}`}
                                                    onClick={handleClose}
                                                    notifications={groupedSmsNotification[key]}
                                                />
                                            );
                                        })}
                                    </Box>
                                </>
                            )}
                            <Box sx={{ flexGrow: 1 }} />
                            <Stack mt={"1rem"} px={2} display={"flex"} justifyContent={"center"} height={"40px"}>
                                <Link target="_blank" href={"/teams"}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            borderRadius: "0.5rem",
                                            boxShadow: "none",
                                        }}
                                    >
                                        See More
                                    </Button>
                                </Link>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Popover>
        </div>
    );
}

type NavbarProps = {
    variant?: "base" | "modified";
    containerStyle?: React.CSSProperties;
    isAdmin?: boolean;
};

export default function Navbar({ variant = "base", containerStyle, isAdmin }: NavbarProps) {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const isAddExclusionListModalOpen = useSelector(selectAddExclusionListModal);
    const isRB2BAccount = useSelector(checkIfAccountRB2B);

    const showUploadMarketingCompanyPage = useSelector(selectShowUploadMarketingCompanyPage);
    const showAllProjects = useSelector(selectShowAllProjects);
    const isV2Autopilot = useSelector(checkIsV2Autopilot) ?? false;

    const isProjectPage = pathname.startsWith("/projects");

    const [isModalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className={style["nav__container"]} style={containerStyle}>
                <Stack direction="row" justifyContent={isProjectPage ? "space-between" : "flex-end"}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        {!isRB2BAccount &&
                            isProjectPage &&
                            (isV2Autopilot ? <AutoPilotProject /> : <AutoPilotSwitch />)}

                        {!isRB2BAccount && isSuperAdmin && <AutoPilot />}
                    </Stack>
                    <TextField
                        type="file"
                        id="uploadStatsCsv"
                        //@ts-ignore
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            e.target.files[0] && dispatch(uploadprojectStatsFile(e.target.files[0]));
                        }}
                        sx={{ display: "none" }}
                    />
                    {isSuperAdminView && (
                        <Button
                            onClick={() => {
                                navigate("/superAdmin/userList");
                                dispatch(setIsSuperAdminView(false));
                            }}
                        >
                            Disable super admin view
                        </Button>
                    )}
                    {showUploadMarketingCompanyPage && <UploadMarketingPage />}
                    {isAddExclusionListModalOpen && <AddExclusionList />}
                    <CreateProject />
                    
                    {isSuperAdmin && pathname === "+/" && ( // change the pathname to / to see this button
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{
                                    width: "fit-content",
                                    height: "fit-content",
                                    marginRight: "10px",
                                    borderRadius: "5px",
                                    // backgroundColor: "#1a76d3",
                                    "&:hover, &:focus": {
                                        // backgroundColor: "#1a76d3",
                                        boxShadow: "none",
                                        outline: "none",
                                    },
                                    "&:active": {
                                        outline: "none",
                                    },
                                }}
                                onClick={handleModalOpen}
                            >
                                CS Notification
                            </Button>
                        </Stack>
                    )}
                    <Stack direction="row" alignItems="center">
                        {isAdmin && (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography>{showAllProjects ? "All projects" : "My projects"}</Typography>
                                <Switch
                                    checked={showAllProjects}
                                    onChange={(e) => {
                                        // @ts-ignore
                                        dispatch(updateUser({ showAllProjects: e.target.checked }));
                                    }}
                                />
                            </Stack>
                        )}
                        <Notifications />

                        <GlobalSearch />
                        <AccountMenu />
                    </Stack>
                </Stack>
            </div>

            {isModalOpen && <CSNotificationModal isOpen={isModalOpen} handleClose={handleModalClose} />}
        </>
    );
}
