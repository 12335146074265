import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    selectCompanyCSVUrl,
    selectCurrentCompany,
    selectIncludePastCompanies,
    selectPastCompany,
    setValues,
} from "@/store/reducers/search/search.slice";
import { selectAccountLists } from "@/store/reducers/searchCandidates/searchCandidates.slice";

export function CheckPastCompanies() {
    const dispatch = useDispatch();
    const { values: includePastCompaniesValues } = useSelector(selectIncludePastCompanies);
    const { values: currentCompanyValues } = useSelector(selectCurrentCompany);
    const { values: pastCompanyValues } = useSelector(selectPastCompany);
    const { values: companyCSVUrlValues } = useSelector(selectCompanyCSVUrl);
    const accountLists = useSelector(selectAccountLists);

    const isChecked = (includePastCompaniesValues?.[0]?.value ?? false) as boolean;

    const setIsChecked = (val: boolean) => {
        dispatch(setValues({ key: "includePastCompanies", value: [{ excluded: false, value: val }] }));
    };

    const handlePastCompanyCheck = (_e: React.SyntheticEvent, val: boolean) => {
        setIsChecked(val);
    };

    useEffect(() => {
        dispatch(
            setValues({
                key: "pastCompany",
                value: isChecked ? currentCompanyValues : [],
            })
        );
    }, [isChecked]);

    useEffect(() => {
        if (!currentCompanyValues?.length && !companyCSVUrlValues?.length && !accountLists?.length) {
            setIsChecked(false);
        }
    }, [currentCompanyValues, companyCSVUrlValues, accountLists]);

    useEffect(() => {
        if (pastCompanyValues?.length && !isChecked) {
            setIsChecked(true);
        }
    }, [pastCompanyValues]);

    return (
        <FormControlLabel
            disabled={!currentCompanyValues?.length && !companyCSVUrlValues?.length && !accountLists?.length}
            checked={isChecked}
            onChange={handlePastCompanyCheck}
            control={<Checkbox size="small" />}
            sx={{
                "& .MuiFormControlLabel-label": {
                    fontSize: 12,
                },
            }}
            label="Include companies under past work experience as well"
        />
    );
}
