import Avatar from "@mui/joy/Avatar";
import Stack from "@mui/joy/Stack";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import Footer from "./Footer";

import { useGetSMSConversationsQuery, useSendSMSMutation } from "../../../../store/apis/super-inbox/super-inbox.api";
import { checkSMSEnabled, selectUser } from "../../../Signin/Signin.reducer";
import { useSuperInboxContext } from "../../Context";
import { ChannelProps } from "../channels/ChannelTabs";
import ChatBubble from "../messages/ChatBubble";
import MessageInput from "../messages/MessageInput";
import MessagesConversationContainer from "../messages/MessagesConversationContainer";

interface SMSProps extends ChannelProps {}

export default function SMS(props: SMSProps) {
    const { candidateProfile, setTags } = props;
    const {
        filters: { projectId, userId },
        candidateParamId,
    } = useSuperInboxContext();

    const isSMSEnabled = useSelector(checkSMSEnabled);
    const user = useSelector(selectUser);
    const canSendSms = userId ? user?._id === userId : true;
    const [sendSMS, { isLoading: isSubmittingSMS }] = useSendSMSMutation();
    const { isLoading, isFetching, data } = useGetSMSConversationsQuery({
        candidateId: candidateParamId,
        projectIds: [projectId],
        userId,
    });

    useEffect(() => {
        setTags({
            tags: data?.tags ?? [],
            projects: data?.projects ?? [],
        });
    }, [data]);

    return (
        <>
            <MessagesConversationContainer
                isLoading={isLoading || isFetching}
                message={
                    !isSMSEnabled
                        ? "You do not have EasySource SMS capabilities integrated yet. Please contact your account manager if you wish to activate this feature"
                        : undefined
                }
            >
                {data?.messages?.map((message, index) => {
                    const isYou = message.senderType === "USER";
                    const isSMSFailed = ["FAILED", "UNDELIVERED", "OPTED_OUT"].includes(message.candidateStatus);
                    const isQueued = message.candidateStatus === "QUEUED";

                    return (
                        <Stack key={index} direction="row" spacing={2} flexDirection={isYou ? "row-reverse" : "row"}>
                            {!isYou && <Avatar size="sm" src={candidateProfile?.profileImage || ""} />}
                            <ChatBubble
                                isQueued={isQueued}
                                isMessageFailed={isSMSFailed}
                                variant={isYou ? "sent" : "received"}
                                body={message.body}
                                time={message.time}
                                senderType={message.senderType}
                                candidateStatus={message.candidateStatus}
                                candidateProfile={candidateProfile}
                                theme="dark"
                            />
                        </Stack>
                    );
                })}
            </MessagesConversationContainer>
            {canSendSms ? (
                <MessageInput
                    disabled={isLoading || !data?.projects?.length}
                    isLoading={isSubmittingSMS}
                    onSubmit={(bodyText, callback) => {
                        if (data?.projects?.length) {
                            sendSMS({
                                candidateId: candidateParamId,
                                smsBody: bodyText,
                                projectId: data?.projects[0]._id,
                            }).then(callback);
                        }
                    }}
                />
            ) : (
                <Footer date={data?.lastUpdated} />
            )}
        </>
    );
}
