import { useEffect } from "react";
import { Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import MUILoader from "../../components/MUILoader";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import ContactListItemsTableContent from "./components/ContactListItemsTableContent";
import { fetchContactListItems, selectContactListItemsTotalRowCount } from "./contact-list-items.slice";

const GridBox = styled(Box)<BoxProps>(() => ({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto 1fr",
    padding: "1rem",
    gap: "1rem",
}));

const CustomButton = styled(Button)<ButtonProps>(() => ({
    width: "fit-content",
    height: "fit-content",
    fontSize: "12px",
}));

function ContactListItemsTitle() {
    const totalRows = useSelector(selectContactListItemsTotalRowCount);

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack>
                    <Typography variant="h4" fontWeight={700} sx={{ fontSize: "20px" }}>
                        Lists
                    </Typography>
                    <Typography variant="body2" fontWeight={700}>
                        {totalRows} Lists{" "}
                    </Typography>
                </Stack>
                {/* <Stack direction="row" spacing={1}>
                    <CustomButton
                        variant="contained"
                        // onClick={openCreateContactListModal}
                    >
                        Create list
                    </CustomButton>
                </Stack> */}
            </Stack>
        </>
    );
}

export default function ContactListItems() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const isFetchingContactItemsList = useSelector(checkIfLoading(fetchContactListItems.type));

    useEffect(() => {
        if (id) {
            dispatch(fetchContactListItems(id));
        }
    }, []);

    if (isFetchingContactItemsList) {
        return <MUILoader />;
    }

    return (
        <GridBox>
            {/* <ContactListItemsTitle /> */}
            {/* <Divider /> */}
            <ContactListItemsTableContent />
        </GridBox>
    );
}
