import DeleteIcon from "@mui/icons-material/Delete";
import FeedIcon from "@mui/icons-material/Feed";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { isArray } from "lodash";
import { Suspense, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ActivitiesView } from "./components/ActivitiesView";
import AddToPowerDialer from "./components/addToPowerDialer/addToPowerDailer";
import AddToWorkflow from "./components/addToWorkflow/addToWorkflow";
import ATSIntegration, { FetchFromAts } from "./components/atsIntegration/atsIntegration";
import ProjectCandidates, { calculateContainerHeight } from "./components/candidates/candidates";
import { CSReviewStatus } from "./components/CSReviewStatus";
import DeleteProject from "./components/DeleteProject";
import ExportCandidates from "./components/exportCandidates/exportCandidates";
import ExtractEmailAndPhone from "./components/ExtractEmailAndPhone/ExtractEmailAndPhone";
import GenerateInsights from "./components/GenerateInsights";
import ProjectHeader, { ProgressStatus } from "./components/header/Header";
import FetchFromLinkedin from "./components/linkedinCandidates/linkedinCandidates";
import MostActionableCandidateButton from "./components/MostActionableCandidateButton/MostActionableCandidateButton";
import { ProjectFilters } from "./components/ProjectFilters";
import ScrapeCandidateModal from "./components/ScrapeCandidateModal/ScrapeCandidateModal";
import Search from "./components/Search";
import SelectWorkflowModal from "./components/selectWorkflowModal/SelectWorkflowModal";
import SendToHiringManager from "./components/SendToHiringManager/sendToHiringManager";
import { SortCandidates } from "./components/sortCandidates/SortCandidates";
import UnlockCandidatesResume from "./components/UnlockCandidateResume";
import style from "./project.module.scss";

import Checkbox from "../../components/Checkbox/Checkbox";
import { ErrorBoundary, FallbackUI } from "../../components/ErrorBoundary/ErrorBoundary";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import Navbar from "../../components/Navbar/Navbar";
import { BDAddValuePropositionModal } from "../../easy-growth/components/AddValueProposition";
import { useAppDispatch } from "../../store";
import { CreateOutreachRequestPayload } from "../../store/apis/outreach/outreach.types";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import UploadCompanyPage from "../CompanyPage/Component/UploadCompanyPage";
import BaseModal from "../triggerWorkflow/components/BaseModal";

import { useCheckProjectCandidatesLoading } from "@/hooks/useCheckProjectCandidatesLoading";
import { useCheckProjectLoading } from "@/hooks/useCheckProjectLoading";
import {
    getProject,
    removeCandidateFromProject,
    selectCurrProject,
    selectCurrentProjectStatus,
    selectProjectInsightGenerationStatus,
    selectProjectStatus,
} from "@/store/reducers/allProjects/allProjects.reducer";
import { selectShowUploadCompanyPage } from "@/store/reducers/companyPage/CompanyPage.reducer";
import { optionType } from "@/store/reducers/filters/Filters.type";
import {
    addToWorkflow,
    checkEmailEnrich,
    checkLinkedinEnrich,
    checkPhoneEnrich,
    excludeCandidates,
    getProjectCandidates,
    getShowByProjectFilters,
    isAllCandidatesSelected,
    resetFilters,
    resetRequestId,
    searchCandidates,
    selectCandidateCurrentPage,
    selectGridViewColumns,
    selectPageSize,
    selectProjectAllCandidates,
    selectProjectCandidates,
    selectProjectFilters,
    selectProjectTotalCandidates,
    selectSearchQuery,
    selectSelectedCandidates,
    selectShowByPersonalEmails,
    selectShowSrapeCandidateModal,
    setCandidatePage,
    setCandidates,
    setGridViewColumns,
    setScrapeCandidateModal,
    setSearchQuery,
    setSelectAllCandidates,
    setSelectedCandidates,
    setShowByPersonalEmails,
    setShowCandidateSearch,
    updateProjectCandidatesCSReviewStage,
} from "@/store/reducers/project/project.reducer";
import { IProjectView, IStageFilters, gridViewColumnsOption } from "@/store/reducers/project/project.types";
import {
    checkEasyGrowth,
    checkEasySource,
    checkHiringManagerEnabled,
    checkIsLinkedinApplicantsFetchShow,
    isSuperAdminUser,
    selectEmailExtraction,
    selectUser,
} from "@/store/reducers/signin/Signin.reducer";
import { resetCriteria } from "@/store/reducers/vetting-criteria/vettingCriteria.slice";
import { selectWorkflowData } from "@/store/reducers/workflow/workflow.reducer";
import { Button, Typography } from "@/ui";
import { useProjectsSocket } from "@/sockets/useProjectsSocket";

function useFetchCandidatesEveryFiveSeconds() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const pageSize = useSelector(selectPageSize);
    const pageNo = useSelector(selectCandidateCurrentPage);
    const projectStatus = useSelector(selectProjectStatus);
    const insightGenerationStatus = useSelector(selectProjectInsightGenerationStatus);
    const linkedinEnrich = useSelector(checkLinkedinEnrich);
    const intervalRef = useRef<null | NodeJS.Timeout>(null);
    const showByProjectFilters = useSelector(selectProjectFilters);
    const isSuperAdmin = useSelector(isSuperAdminUser);

    useEffect(() => {
        return () => {
            dispatch(setCandidates([]));
        };
    }, []);

    useEffect(() => {
        if (
            intervalRef?.current &&
            (projectStatus === "IN_PROGRESS" ||
                projectStatus === "PENDING" ||
                insightGenerationStatus === "IN_PROGRESS" ||
                linkedinEnrich)
        ) {
            clearInterval(intervalRef.current);
        }

        if (
            projectStatus === "IN_PROGRESS" ||
            projectStatus === "PENDING" ||
            insightGenerationStatus === "IN_PROGRESS" ||
            linkedinEnrich
        ) {
            intervalRef.current = setInterval(() => {
                dispatch(resetRequestId({}));
                dispatch(
                    getProjectCandidates({
                        projectId: id,
                        start: pageNo,
                        limit: pageSize,
                    })
                );
            }, 15000); // fetch in every 15 seconds
        }

        return () => {
            if (intervalRef?.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [
        projectStatus,
        id,
        showByProjectFilters,
        pageSize,
        pageNo,
        isSuperAdmin,
        linkedinEnrich,
        insightGenerationStatus,
    ]);
}

export default function Project() {
    // useFetchCandidatesEveryFiveSeconds();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("query");
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const projectData = useSelector(selectCurrProject);
    const isProjectLoading = useCheckProjectLoading();
    const isCandidatesLoading = useCheckProjectCandidatesLoading();
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const extractEmail = useSelector(selectEmailExtraction);
    const candidates = useSelector(selectProjectCandidates);
    // const candidates = useSelector(selectCurrentProjectCandidates(id));
    const showByEmailId = useSelector(selectShowByPersonalEmails);

    const selectedCandidates = useSelector(selectSelectedCandidates);
    const candidatePageNo = useSelector(selectCandidateCurrentPage);
    const selectAllCandidates = useSelector(selectProjectAllCandidates);
    const totalCandidates = useSelector(selectProjectTotalCandidates);
    const scrapeCandidateModal = useSelector(selectShowSrapeCandidateModal);

    const showUploadCompanyPage = useSelector(selectShowUploadCompanyPage);
    const filters = useSelector(selectProjectFilters);

    const searchQuery = useSelector(selectSearchQuery);
    const gridViewColumns = useSelector(selectGridViewColumns);
    const selectCount = selectedCandidates.length;
    const candidateIds = candidates.map((item) => item._id);
    const selectedCandidateIds = selectedCandidates.map((item) => item.candidateId);
    const pageSelectCount = candidateIds.filter((item) => selectedCandidateIds.includes(item)).length;
    const detailedCandidates = candidates.filter((candidate) => selectedCandidateIds.includes(candidate._id));
    const hasNoEmailEvery = detailedCandidates.every(
        (candidate) =>
            isArray(candidate.email) &&
            !candidate.email.length &&
            isArray(candidate.professional_email) &&
            !candidate.professional_email.length
    );
    const hasEmailEvery = detailedCandidates.every(
        (candidate) =>
            (isArray(candidate.email) && candidate.email.length) ||
            (isArray(candidate.professional_email) && candidate.professional_email.length)
    );
    const selectAllCheck = useSelector(isAllCandidatesSelected);
    const projectStatus = useSelector(selectCurrentProjectStatus(id));

    const { phoneEnrichData, emailEnrichData, generateInsightsData } = useProjectsSocket(id);
    const isInsightsInProgress = generateInsightsData?.status
        ? generateInsightsData.status === "IN_PROGRESS"
        : projectData?.generateInsightsStatus === "IN_PROGRESS";
    const isCandidatesAdding = projectStatus === "IN_PROGRESS" || projectStatus === "PENDING";

    const emailEnrich = useSelector(checkEmailEnrich);
    const phoneEnrich = useSelector(checkPhoneEnrich);
    const linkedInEnrich = useSelector(checkLinkedinEnrich);

    const getUserFromLS = new API().getLocalStorage("user");
    const user = getUserFromLS ? JSON.parse(getUserFromLS) : null;
    const userData = useSelector(selectUser);
    const [isSlectWkModalOpen, setIsSlectWkModalOpen] = useState<boolean>(false);
    // const [isMsgModalOpen, setIsMsgModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const [wkTemp, setWkTemp] = useState();
    const [wkDate, setWkDate] = useState("");
    const [msgSubject, setMsgSubject] = useState("");
    const [msgValue, setMsgValue] = useState("");
    const [activeView, setActiveView] = useState<IProjectView>("list");
    const workflowData = useSelector(selectWorkflowData);
    const isEasySource = useSelector(checkEasySource);
    const isLinkedinShow = useSelector(checkIsLinkedinApplicantsFetchShow);
    const isHiringManagerEnabled = useSelector(checkHiringManagerEnabled);

    const candidatesWithPhoneNumbers = selectedCandidates
        .filter((candidateId) => {
            const candidate = candidates.find((c) => c?._id === candidateId?.candidateId);
            return candidate && candidate?.phone && candidate?.phone?.length > 0;
        })
        .map((candidate) => candidate?.candidateId);

    const excludeCandidatesLoading = useSelector(checkIfLoading(excludeCandidates.type));

    const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const getCreateOutreachV2Payload = (): CreateOutreachRequestPayload => {
        handleCleverTap("Add to workflow", {
            candidates: selectedCandidateIds?.length,
            projectId: id,
            partialEmails: !hasEmailEvery && !hasNoEmailEvery,
        });

        return {
            candidateIds: selectedCandidateIds,
            projectId: id || "",
            filterBy: Object.entries(filters)?.reduce((acc: string[], [key, value]) => {
                if (value === true) {
                    acc.push(key);
                }

                return acc;
            }, []),
            search: searchQuery,
            selectAll: selectAllCandidates,
        };
    };
    const appliedFilters = Object.keys(filters).filter((key) => filters[key as any]);

    const handleCreateOutreachV1 = () => {
        handleCleverTap("Add to workflow", {
            candidates: selectedCandidateIds?.length,
            projectId: id,
            partialEmails: !hasEmailEvery && !hasNoEmailEvery,
        });

        navigate("/workflows", {
            state: {
                candidates: selectedCandidateIds,
                projectId: id,
                isAllProjectCandidatesSelected: selectAllCandidates,
                totalCandidates,
                filters,
                searchQuery,
                showByPersonalEmails: showByEmailId,
            },
        });
    };

    const closeSlectWkModal = () => setIsSlectWkModalOpen(false);

    useEffect(() => {
        if (user?.isBDUser) handleGridView();

        window.history.replaceState({}, document.title);
        dispatch(setSearchQuery(query || ""));
        if (query) {
            dispatch(
                getProject({
                    projectId: id,
                    doNotFetchCandidates: true,
                    action: getProject.type,
                })
            );
            dispatch(setShowCandidateSearch(true));
            dispatch(
                searchCandidates({
                    projectId: id,
                    action: searchCandidates.type,
                })
            );
            searchParams.delete("query");
            setSearchParams(searchParams);
        } else {
            dispatch(setShowCandidateSearch(false));
            dispatch(getProject({ projectId: id, action: getProject.type }));
        }

        return () => {
            // reset page to 1
            dispatch(setSelectAllCandidates(false));
            dispatch(setCandidatePage(1));
            dispatch(resetCriteria());
            dispatch(setShowByPersonalEmails(false));

            // @ts-ignore
            dispatch(resetFilters()); // removed this for a fix for virtual user outreach...
            dispatch(
                setSelectedCandidates({
                    candidateId: [],
                    deselectAll: candidatePageNo,
                })
            );
        };
        // eslint-disable-next-line
    }, []);
    // useEffect(() => {
    //     if (projectStatus === "IN_PROGRESS" || projectStatus === "PENDING") {
    //         setIsCandidateAdding(true);
    //     } else if (projectStatus === "COMPLETED") {
    //         setTimeout(() => {
    //             setIsCandidateAdding(false);
    //         }, 5000);
    //     }
    // }, [projectStatus]);

    // const closeMsgModal = () => setIsMsgModalOpen(false);

    // const handleSendMsg = () => {
    //     if (!selectCount) return;

    //     handleCleverTap("Confirm send email", {
    //         "Project id": id,
    //         candidates: selectedCandidateIds?.toString(),
    //     });

    //     dispatch(
    //         sendEmail({
    //             candidates: selectedCandidateIds,
    //             emailRecipients: [],
    //             subject: msgSubject,
    //             body: msgValue,
    //             projectId: Number(id),
    //             onSuccess: closeMsgModal,
    //         })
    //     );
    // };

    const handleSetFilters = (projectFilters: IStageFilters) => {
        dispatch(
            getShowByProjectFilters({
                projectId: id,
                projectFilters: {
                    ...filters,
                    ...projectFilters,
                },
                action: getShowByProjectFilters.type,
            })
        );
    };

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            handleCleverTap("Select All candidates", {
                "Project id": id,
                candidates: candidateIds?.length,
            });
            dispatch(
                setSelectedCandidates({
                    candidateId: [...candidateIds],
                    pageNo: candidatePageNo,
                })
            );
        } else {
            if (selectAllCandidates) {
                dispatch(setSelectAllCandidates(false));
            }
            dispatch(
                setSelectedCandidates({
                    candidateId: [],
                    deselectAll: candidatePageNo,
                })
            );
        }
    };

    const handleAddToWk = () => {
        if (!wkTemp || !id) return;

        dispatch(
            addToWorkflow({
                workflowTemplateId: wkTemp,
                scheduledFor: dayjs(wkDate).toISOString(),
                project: Number(id),
                candidate: selectedCandidateIds,
                action: addToWorkflow.type,
                bcc: workflowData.bccEmail.value,
                unsubscribeEmail: workflowData.unsubscribeEmail,
            })
        );
        closeSlectWkModal();
    };

    const handleRemoveCandidate = () => {
        if (!selectCount || !id) return;

        const payload = {
            projectId: id,
            candidateIds: selectedCandidateIds,
        };

        dispatch(removeCandidateFromProject(payload));
        closeDeleteModal();
    };

    const handleSelectAllCandidates = () => {
        dispatch(setSelectAllCandidates(!selectAllCandidates));
    };

    const handleListView = () => {
        setActiveView("list");
        new API().setLocalStorage({ key: "gridView", value: "list" });
    };

    const handleGridView = () => {
        const localView = new API().getLocalStorage("gridView");
        // if (localView && localView !== "list" && localView !== "compact-list") {
        //     setActiveView(localView as IProjectView);
        //     return;
        // }

        // const gridView = `gridB` as IProjectView;
        // new API().setLocalStorage({ key: "gridView", value: gridView });
        // setActiveView(gridView);

        if (localView === "gridB") {
            new API().setLocalStorage({ key: "gridView", value: "list" });
            setActiveView("list");
            return;
        }

        const gridView = `gridB` as IProjectView;
        new API().setLocalStorage({ key: "gridView", value: gridView });
        setActiveView(gridView);
    };

    const handleCompactListView = () => {
        const localView = new API().getLocalStorage("gridView");
        if (localView && localView !== "list" && localView !== "compact-list") {
            setActiveView(localView as IProjectView);
            return;
        }

        const compactListView = `compact-list` as IProjectView;
        new API().setLocalStorage({ key: "gridView", value: compactListView });
        setActiveView(compactListView);
    };

    const onClickRefreshIcon = () => {
        dispatch(
            getProject({
                projectId: id,
                action: getProject.type,
            })
        );
    };

    const onSelectColumn = (e: any, item: optionType) => {
        dispatch(
            setGridViewColumns({
                checked: e.target.checked,
                item,
            })
        );
    };

    const handleChangeCVReviewStage = (stage: string) => {
        dispatch(updateProjectCandidatesCSReviewStage(stage));
    };

    // if (isProjectLoading) return <Loader />;
    const candidateContainerHeight = calculateContainerHeight({
        allCandidatesSelected: selectAllCheck,
        isCandidatesAdding,
        isInsightsInProgress,
        emailEnrich,
        phoneEnrich,
        linkedInEnrich,
    });

    return (
        <div className={style["sp"]} style={{ height: "100%" }}>
            <div className={style["sp__container"]}>
                <div style={{ height: "6%" }}>
                    <Navbar variant="modified" />
                </div>
                <div style={{ height: "8%" }}>
                    <ProjectHeader
                        activeView={activeView}
                        handleListView={handleListView}
                        handleGridView={handleGridView}
                        onClickRefreshIcon={onClickRefreshIcon}
                    />
                </div>
                <div style={{ height: "86%", paddingBottom: "10px" }}>
                    {activeView === "list" || activeView === "compact-list" ? (
                        <div className={style["sp__body"]} style={{ height: "100%" }}>
                            <div className={style["sp__bodyAction"]} style={{ height: "7%" }}>
                                <div className="flex items-center space-x-1">
                                    <Checkbox
                                        disabled={isCandidatesLoading}
                                        checked={selectAllCheck || selectAllCandidates}
                                        onChange={handleSelectAll}
                                        id="cy-select-all-candidates"
                                    />
                                    <Typography className="text-[14px]" variant="body2">
                                        {selectAllCandidates || selectCount === 0
                                            ? `${totalCandidates} ${selectAllCandidates ? "selected" : totalCandidates === 1 ? "result" : "results"}`
                                            : `${selectCount} selected`}
                                    </Typography>
                                </div>
                                <div className={style["sp__bodyActionList"]}>
                                    <Stack ml={1} spacing={1} direction="row" alignItems="center">
                                        <div className="flex flex-row items-center">
                                            {id ? (
                                                <>
                                                    <GenerateInsights
                                                        projectId={id}
                                                        candidateIds={selectedCandidateIds}
                                                        selectAll={selectAllCandidates}
                                                    />

                                                    <Tooltip
                                                        placement="top"
                                                        title={t("projectsPage.extractCandidates")}
                                                    >
                                                        <IconButton
                                                            color="primary"
                                                            disabled={!selectedCandidateIds?.length}
                                                            onClick={() => {
                                                                dispatch(setScrapeCandidateModal(true));
                                                            }}
                                                        >
                                                            <FeedIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            ) : null}

                                            {(isSuperAdmin || extractEmail) && <ExtractEmailAndPhone />}
                                            {isSuperAdmin && <UnlockCandidatesResume />}
                                        </div>
                                        {(isSuperAdmin || isHiringManagerEnabled) && <SendToHiringManager />}

                                        <AddToWorkflow
                                            getCreateOutreachPayload={getCreateOutreachV2Payload}
                                            handleCreateOutreachV1={handleCreateOutreachV1}
                                        />

                                        <ATSIntegration />
                                        <FetchFromAts />
                                        {isLinkedinShow && <FetchFromLinkedin />}
                                    </Stack>
                                </div>
                                <div className="flex flex-row items-center">
                                    {isSuperAdmin && !!selectedCandidateIds?.length && (
                                        <CSReviewStatus
                                            stage={"PENDING"}
                                            handleStatusChange={handleChangeCVReviewStage}
                                        />
                                    )}
                                    <Search />
                                    {userData?.twilioPhoneNumber && (
                                        <AddToPowerDialer candidateIds={candidatesWithPhoneNumbers} />
                                    )}
                                    {/* <Tooltip placement="top" title={"Add selected candidate to exclusion list"}> */}
                                    {/* <Tooltip placement="top" title={"Add selected candidate to exclusion list"}>
                                    <IconButton
                                        disabled={!Boolean(selectedCandidateIds?.length)}
                                        color="primary"
                                        onClick={() => {
                                            dispatch(excludeCandidates());
                                        }}
                                    >
                                        {excludeCandidatesLoading ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <CancelScheduleSendIcon fontSize="medium" />
                                        )}
                                    </IconButton>
                                </Tooltip> */}
                                    <Tooltip placement="top" title={t("projectsPage.candidateSection.deleteCandidate")}>
                                        <IconButton
                                            disabled={!selectedCandidateIds?.length}
                                            color="primary"
                                            onClick={showDeleteModal}
                                        >
                                            <DeleteIcon fontSize="medium" />
                                        </IconButton>
                                    </Tooltip>
                                    {id ? <ExportCandidates projectId={id} /> : null}
                                    <SortCandidates />
                                    <ProjectFilters setFilters={handleSetFilters} />
                                    <MostActionableCandidateButton />
                                    {user?.isBDUser ? (
                                        <Select
                                            multiple
                                            value={[{ value: "", label: "select" }]}
                                            input={<OutlinedInput label="Tag" />}
                                        >
                                            {gridViewColumnsOption.map((item) => {
                                                const index = gridViewColumns.findIndex(
                                                    (col: optionType) => item.value === col.value
                                                );

                                                return (
                                                    <MenuItem
                                                        key={item.value}
                                                        value={item.value}
                                                        onClick={(e) => onSelectColumn(e, item)}
                                                    >
                                                        <Checkbox checked={index > -1} />
                                                        <ListItemText
                                                            primary={item.label}
                                                            sx={{ marginLeft: "10px" }}
                                                        />
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    ) : null}
                                </div>
                            </div>

                            {!isProjectLoading ? (
                                <div style={{ width: "100%" }}>
                                    {isInsightsInProgress && (
                                        <div className={style["sp__paginationPrompt"]}>
                                            <ProgressStatus
                                                variant="INSIGHT_GENERATION"
                                                generateInsightsData={generateInsightsData}
                                            />
                                        </div>
                                    )}
                                    {isCandidatesAdding && (
                                        <div className={style["sp__paginationPrompt"]}>
                                            <ProgressStatus variant="CANDIDATES_FETCHING" />
                                        </div>
                                    )}
                                    {(emailEnrich || phoneEnrich || linkedInEnrich) && (
                                        <div className={style["sp__paginationPrompt"]}>
                                            <ProgressStatus
                                                variant="CANDIDATES_ENRICHING"
                                                data={phoneEnrichData}
                                                emailData={emailEnrichData}
                                            />
                                        </div>
                                    )}
                                    {(selectAllCheck || selectAllCandidates) && (
                                        <div className={style["sp__paginationPrompt"]}>
                                            <p className={style["sp__paginationPrompt-text"]}>
                                                All{" "}
                                                <b>
                                                    {selectAllCandidates
                                                        ? `${appliedFilters.length > 0 ? "filtered " : ""}${t("common")} in this project`
                                                        : `${pageSelectCount} ${t("common")} on this page`}
                                                </b>{" "}
                                                are selected.
                                            </p>

                                            <button
                                                className={style["sp__paginationPrompt-btn"]}
                                                onClick={handleSelectAllCandidates}
                                            >
                                                {selectAllCandidates
                                                    ? "Clear selection"
                                                    : `Select all ${appliedFilters.length > 0 ? "filtered " : ""} ${t("common")} in this project`}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : null}
                            <div
                                style={{
                                    height: `calc(93% - ${candidateContainerHeight})`,
                                }}
                            >
                                <ErrorBoundary fallbackRender={() => <FallbackUI sx={{ mt: "10rem" }} />}>
                                    <Suspense fallback={<Loader size="sm" />}>
                                        {!isProjectLoading && !isCandidatesLoading ? (
                                            <ProjectCandidates activeView={activeView} />
                                        ) : (
                                            <Loader size="sm" />
                                        )}
                                    </Suspense>
                                </ErrorBoundary>
                            </div>
                        </div>
                    ) : (
                        <ActivitiesView />
                    )}
                </div>
                <Modal open={isSlectWkModalOpen} onClose={closeSlectWkModal} title={"Add to Workflow"} width={"400px"}>
                    <SelectWorkflowModal
                        closeModal={closeSlectWkModal}
                        wkDate={wkDate}
                        setWkDate={setWkDate}
                        wkTemp={wkTemp}
                        setWkTemp={setWkTemp}
                        handleAddToWk={handleAddToWk}
                    />
                </Modal>
                {/* <Modal
                    open={isMsgModalOpen}
                    onClose={closeMsgModal}
                    title={"Send Message"}
                    width={"70%"}
                >
                    <MsgModal
                        handleSendMsg={handleSendMsg}
                        msgValue={msgValue}
                        setMsgValue={setMsgValue}
                        msgSubject={msgSubject}
                        setMsgSubject={setMsgSubject}
                    />
                </Modal> */}
                {isDeleteModalOpen && (
                    <BaseModal
                        onClose={closeDeleteModal}
                        overlayStyles={{
                            padding: "1.7rem 1.5rem",
                            width: 600,
                        }}
                        hideCloseButton={false}
                    >
                        <Typography className="text-[25px] font-semibold mb-[10px]">
                            Sure you want to delete?
                        </Typography>

                        {isEasySource ? (
                            <Typography className="text-[14px]">
                                Deleting candidates is irreversible and EasySource will ensure that the same candidates
                                are not pulled back in.
                            </Typography>
                        ) : (
                            <Typography className="text-[14px]">
                                Deleting prospects is irreversible and you would not be able to fetch the same prospect
                                into your campaign again.
                            </Typography>
                        )}

                        <div className="flex items-center justify-end space-x-1 mt-4">
                            <Button variant="outline" className="rounded text-xs" onClick={closeDeleteModal}>
                                Cancel
                            </Button>
                            <Button className="rounded text-xs border-none" onClick={handleRemoveCandidate}>
                                Delete
                            </Button>
                        </div>
                    </BaseModal>
                )}

                {id ? <DeleteProject id={id} /> : null}
            </div>
            {scrapeCandidateModal && <ScrapeCandidateModal />}
            {showUploadCompanyPage && <UploadCompanyPage />}
            <BDAddValuePropositionModal />
            <Outlet />
        </div>
    );
}
