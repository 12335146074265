import { useEffect } from "react";
import { Info } from "@mui/icons-material";
import { Box, Divider, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";

import { getProject, selectCurrProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { PersonalizeTagActionsWithMutation } from "../../OutReach/hooks/usePersonalizeValues";
import EditSignatureHandler from "../../OutReach/molecules/EditSignatureHandler";
import { currentEditor, currentOutreachStep } from "@/store/reducers/outreach/outreach.slice";
import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

export default function PersonalizedTagsMenu({
    menuProps,
    actions,
    customActions,
    isLoadingCustomFieldData,
    isErrorCustomFieldData,
    defaultCustomActions,
    signatureAction,
    rb2bAction,
}: {
    menuProps: MenuProps & {
        onClose: () => void;
    };
    customActions: { key: string; label: string; onClick: () => void }[];
    actions: PersonalizeTagActionsWithMutation[];
    isLoadingCustomFieldData: boolean;
    isErrorCustomFieldData: boolean;
    defaultCustomActions: PersonalizeTagActionsWithMutation[];
    signatureAction: PersonalizeTagActionsWithMutation[];
    rb2bAction: PersonalizeTagActionsWithMutation[];
}) {
    const project = useSelector(selectCurrProject);
    const { projectId } = useOutreachParams();
    const dispatch = useDispatch();
    const currEditor = useSelector(currentEditor);
    const currStep = useSelector(currentOutreachStep);
    const isEasySource = useSelector(checkEasySource);
    const setDisable = (currentLabel: string, value: string) => {
        if (currStep === "linkedin") {
            return (
                (currEditor === "editor4" || currEditor === "editor3" || currEditor === "editor1") &&
                currentLabel === value
            );
        }
        if (currStep === "SMS") {
            return currStep === "SMS" && currentLabel === value;
        }
        if (currStep === "email") return currEditor === "editor4" && currentLabel === value;
    };

    const source = project?.source;

    return (
        <>
            <EditSignatureHandler />
            <Menu
                {...menuProps}
                sx={{
                    "& .MuiList-root": {
                        // Target the <ul>
                        display: "flex",
                        flexDirection: "column",
                    },
                }}
            >
                <Stack
                    sx={{
                        paddingBottom: 0.5,
                        paddingLeft: 2,
                    }}
                    alignItems={"center"}
                    direction={"row"}
                    gap={0.5}
                >
                    <Typography
                        sx={{
                            color: "grey",
                        }}
                        fontSize={"12px"}
                    >
                        {isEasySource ? "Candidate" : "Prospect"} level tags
                    </Typography>

                    <Tooltip title="These personalization tags will be replaced using candidate information. Click on preview to see results.">
                        <Info sx={{ color: "grey", fontSize: "15px" }} />
                    </Tooltip>
                </Stack>

                {actions.map(({ key, label, buttonDesc, tooltip, showDivider, showEditSignature, onClick, link }) => {
                    const ishidden = setDisable(label, "{signature}");
                    return (
                        <Box>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Tooltip title={tooltip} placement="left-start" disableHoverListener={ishidden}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <MenuItem
                                            key={key}
                                            onClick={() => {
                                                onClick?.();
                                            }}
                                            style={{ fontSize: "small" }}
                                            disabled={ishidden}
                                        >
                                            {label}
                                        </MenuItem>
                                    </div>
                                </Tooltip>

                                {buttonDesc && (
                                    <div style={{ display: "flex", flexDirection: "column", color: "gray" }}>
                                        <MenuItem
                                            sx={[
                                                { "&:hover": { backgroundColor: "transparent", color: "black" } },
                                                { fontSize: "small" },
                                            ]}
                                            disableRipple={true}
                                            onClick={() => {
                                                showEditSignature?.();
                                            }}
                                        >
                                            {link ? (
                                                <a target="_blank" href={link}>
                                                    {buttonDesc}
                                                </a>
                                            ) : (
                                                buttonDesc
                                            )}
                                        </MenuItem>
                                    </div>
                                )}
                            </div>
                            {showDivider && <Divider />}
                        </Box>
                    );
                })}
                <Divider />
                <Stack
                    sx={{
                        paddingTop: 0.75,
                        paddingBottom: 0.5,
                        paddingLeft: 2,
                    }}
                    alignItems={"center"}
                    direction={"row"}
                    gap={0.5}
                >
                    <Typography
                        sx={{
                            color: "grey",
                        }}
                        fontSize={"12px"}
                    >
                        {" "}
                        User level tags
                    </Typography>

                    <Tooltip title="These personalization tags depend on your user information. Click preview to check this out.">
                        <Info sx={{ color: "grey", fontSize: "15px" }} />
                    </Tooltip>
                </Stack>

                {signatureAction.map(
                    ({ key, label, buttonDesc, tooltip, showDivider, showEditSignature, onClick, link }) => {
                        const ishidden = setDisable(label, "{signature}");
                        return (
                            <Box>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <Tooltip title={tooltip} placement="left-start" disableHoverListener={ishidden}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <MenuItem
                                                key={key}
                                                onClick={() => {
                                                    onClick?.();
                                                }}
                                                style={{ fontSize: "small" }}
                                                disabled={ishidden}
                                            >
                                                {label}
                                            </MenuItem>
                                        </div>
                                    </Tooltip>
                                    {buttonDesc && (
                                        <div style={{ display: "flex", flexDirection: "column", color: "gray" }}>
                                            <MenuItem
                                                sx={[
                                                    { "&:hover": { backgroundColor: "transparent", color: "black" } },
                                                    { fontSize: "small" },
                                                ]}
                                                disableRipple={true}
                                                onClick={() => {
                                                    showEditSignature?.();
                                                }}
                                            >
                                                {link ? (
                                                    <a target="_blank" href={link}>
                                                        {buttonDesc}
                                                    </a>
                                                ) : (
                                                    buttonDesc
                                                )}
                                            </MenuItem>
                                        </div>
                                    )}
                                </div>
                            </Box>
                        );
                    }
                )}
                {defaultCustomActions?.length !== 0 && (
                    <>
                        <Divider />
                        <Stack
                            sx={{
                                paddingTop: 0.75,
                                paddingBottom: 0.5,
                                paddingLeft: 2,
                            }}
                            alignItems={"center"}
                            direction={"row"}
                            gap={0.5}
                        >
                            <Typography
                                sx={{
                                    color: "grey",
                                }}
                                fontSize={"12px"}
                            >
                                Project level tags
                            </Typography>

                            <Tooltip title="These are project fields set by you and can be modified using the 'Edit project' option in the project">
                                <Info sx={{ color: "grey", fontSize: "15px" }} />
                            </Tooltip>
                        </Stack>
                        <>
                            {defaultCustomActions.map(({ key, label, onClick }) => {
                                return (
                                    <Box>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <MenuItem
                                                    key={key}
                                                    onClick={() => {
                                                        onClick?.();
                                                    }}
                                                    style={{ fontSize: "small" }}
                                                >
                                                    {label}
                                                </MenuItem>
                                            </div>
                                        </div>
                                    </Box>
                                );
                            })}
                        </>
                    </>
                )}
                {source === "RB2B" && (
                    <>
                        <Divider />
                        <Stack
                            sx={{
                                paddingTop: 0.75,
                                paddingBottom: 0.5,
                                paddingLeft: 2,
                            }}
                            alignItems={"center"}
                            direction={"row"}
                            gap={0.5}
                        >
                            <Typography
                                sx={{
                                    color: "grey",
                                }}
                                fontSize={"12px"}
                            >
                                RB2B level tags
                            </Typography>

                            <Tooltip title="These are RB2B specific fields.">
                                <Info sx={{ color: "grey", fontSize: "15px" }} />
                            </Tooltip>
                        </Stack>
                        <>
                            {rb2bAction.map(({ key, label, onClick }) => {
                                return (
                                    <Box>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <MenuItem
                                                    key={key}
                                                    onClick={() => {
                                                        onClick?.();
                                                    }}
                                                    style={{ fontSize: "small" }}
                                                >
                                                    {label}
                                                </MenuItem>
                                            </div>
                                        </div>
                                    </Box>
                                );
                            })}
                        </>
                    </>
                )}
                {customActions?.length !== 0 && <Divider />}
                {customActions?.length !== 0 && (
                    <Stack
                        sx={{
                            paddingTop: 0.75,
                            paddingBottom: 0.5,
                            paddingLeft: 2,
                        }}
                        alignItems={"center"}
                        direction={"row"}
                        gap={0.5}
                    >
                        <Typography
                            sx={{
                                color: "grey",
                            }}
                            fontSize={"12px"}
                        >
                            Custom fields
                        </Typography>

                        <Tooltip title="These are custom fields created by you. If a profile does not have data for these custom fields, they will be left blank">
                            <Info sx={{ color: "grey", fontSize: "15px" }} />
                        </Tooltip>
                    </Stack>
                )}

                {isLoadingCustomFieldData ? (
                    <Stack px={2}>
                        {[1, 2, 3].map((item) => {
                            return <Skeleton />;
                        })}
                    </Stack>
                ) : isErrorCustomFieldData || customActions?.length === 0 ? (
                    <></>
                ) : (
                    customActions.map(({ key, label, onClick }) => {
                        return (
                            <Box>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <MenuItem
                                            key={key}
                                            onClick={() => {
                                                onClick?.();
                                            }}
                                            style={{ fontSize: "small" }}
                                        >
                                            {label}
                                        </MenuItem>
                                    </div>
                                </div>
                            </Box>
                        );
                    })
                )}
            </Menu>
        </>
    );
}
