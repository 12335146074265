import EastRounded from "@mui/icons-material/EastRounded";
import { Button, CircularProgress, Stack } from "@mui/material";
import { useSelector } from "react-redux";

import AddCustomCandidates from "./AddCustomCandidates";
import AddListToProject from "./AddListToProject";
import { useProjectData } from "./hooks";

import { checkAddSalesNavUrlProfilesEnabled } from "../../store/reducers/linkedin-scraping/linkedin-scraping.slice";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { submitSearch } from "../../store/reducers/search/search.slice";
import { gotoScreenWithAI } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";

export default function AddToProject() {
    const {
        projectId,
        projectName,
        dispatch,
        searchId,
        navigate,
        candidates,
        isAddingToProject,
        isScreeningWithAI,
        status,
        isEdit,
    } = useProjectData();

    const isSearchCompleted = ["COMPLETED", "CANCELLED", "ZERO_PROFILE", "IN_PROGRESS"].includes(status || "");
    const showProceedButton = useSelector(checkAddSalesNavUrlProfilesEnabled);
    const isSubmittingSearch = useSelector(checkIfLoading(submitSearch.type));
    const isDisabled = isAddingToProject || isScreeningWithAI || !isSearchCompleted;

    if (!searchId) return null;

    const handleProceed = () => {
        dispatch(submitSearch({ navigate, isFromLinkedinScraperModal: true, addSalesNavUrlProfiles: false }));
    };

    const handleScreenWithAI = () => {
        dispatch(
            gotoScreenWithAI({
                projectId,
                projectName,
                searchId,
                navigate,
                canNavigateNextStep: true,
            })
        );
    };

    const renderNextStepBtn = (text: string) => {
        if (isEdit) return null;
        return (
            <Button
                variant="contained"
                disabled={isDisabled}
                onClick={handleScreenWithAI}
                endIcon={<EastRounded fontSize="inherit" />}
            >
                <ButtonTextWithLoading isLoading={isScreeningWithAI} text={text} />
            </Button>
        );
    };

    if (showProceedButton) {
        return (
            <Stack mt={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <Button
                    onClick={handleProceed}
                    variant="contained"
                    disabled={isSubmittingSearch}
                    sx={{ minWidth: "90px" }}
                >
                    {" "}
                    {isSubmittingSearch ? <CircularProgress size={22} sx={{ color: "white" }} /> : "Proceed"}
                </Button>
            </Stack>
        );
    }
    return (
        <Stack mt={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
            {candidates?.length ? (
                <>
                    <AddCustomCandidates />
                    <AddListToProject />
                    {renderNextStepBtn("Screen with AI")}
                </>
            ) : (
                renderNextStepBtn("Add AI criteria")
            )}
        </Stack>
    );
}
