import { nanoid } from "@reduxjs/toolkit";
import { MutableRefObject, useRef } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";

import { useOutreachParams } from "./useOutreachParams.hook";

import store, { RootState } from "../../../store";
import {
    useGetCalendlyEventTypesQuery,
    useGetCustomFieldEventTypeQuery,
} from "../../../store/apis/external-connect/external-connect.api";
import { selectJdDescription, selectJdTitle } from "../../allProjects/index.reducer";
import { checkCalendlyAuthorized, checkEasyGrowth } from "../../Signin/Signin.reducer";
import { currentEditor, selectCurrentOutreachElementType, toggleEditSignatureModal } from "../outreach.slice";

type PersonalizeTag = {
    label: string;
    value: string;
    buttonDesc?: string;
    tooltip?: string;
    onClick?: () => void;
    showEditSignature?: () => void;
    showDivider?: boolean;
    link?: string;
};

type PersonalizeTagActions = PersonalizeTag[];

const defaultActions: PersonalizeTagActions = [
    {
        label: "{name}",
        value: "{name}",
        tooltip: "Inserts first name of candidate",
    },
    {
        label: "{lastname}",
        value: "{lastname}",
        tooltip: "Inserts last name of candidate",
    },
    {
        label: "{role}",
        value: "{role}",
        tooltip: "Inserts current job title",
    },
    {
        label: "{company}",
        value: "{company}",
        tooltip: "Inserts current company",
    },
    {
        label: "{{AI prompt}}",
        value: "{{AI prompt}}",
        buttonDesc: "Best practices",
        tooltip: "Inserts a custom AI prompt",
        link: "https://docs.hirequotient.com/en/articles/9389905-our-ai-prompt-templates-cheat-sheet",
    },
];

const signatureAction: PersonalizeTagActions = [
    {
        label: "{signature}",
        value: "{signature}",
        buttonDesc: "Set signature",
        tooltip: "Inserts your signature",
    },
];

const RB2BAction: PersonalizeTagActions = [
    {
        label: "{visitedPage}",
        value: "{visitedpage}",
    },
    {
        label: "{firstVisitDate}",
        value: "{firstvisitdate}",
    },
];

const defaultCustomActions = (
    isEasyGrowth: boolean,
    jdTitle: boolean,
    jdDescription: boolean
): PersonalizeTagActions => {
    if (isEasyGrowth) return [];
    const actions = [];
    if (jdTitle) {
        actions.push({
            label: "{Job Title}",
            value: "{jobtitle}",
        });
    }
    if (jdDescription) {
        actions.push({
            label: "{Job Description}",
            value: "{jobdescription}",
        });
    }
    return actions;
};
export type PersonalizeTagActionsWithMutation = {
    key: string;
    label: string;
    buttonDesc?: string;
    tooltip?: string;
    showDivider?: boolean;
    showEditSignature?: () => void;
    onClick?: () => void;
    url?: string;
    link?: string;
};

export default function usePersonalizeValues(actions: PersonalizeTagActions = defaultActions) {
    const editor1 = useRef(null) as MutableRefObject<ReactQuill | null>;
    const editor2 = useRef(null) as MutableRefObject<ReactQuill | null>;
    const editor3 = useRef(null) as MutableRefObject<ReactQuill | null>;
    const editor4 = useRef<HTMLInputElement>(null);
    const editor5 = useRef(null) as MutableRefObject<ReactQuill | null>;
    const { projectId } = useOutreachParams();
    const jdTitle = useSelector(selectJdTitle);
    const jdDescription = useSelector(selectJdDescription);
    const isEasyGrowth = useSelector(checkEasyGrowth);

    const { currentOutreachElementId } = useOutreachParams();
    const elementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const addTo = useSelector(currentEditor);

    // const [addTo, setAddTo] = useState<"editor1" | "editor2" | "editor3" | "editor4">("editor1");
    const isCalendlyAuthorized = useSelector(checkCalendlyAuthorized);
    const { data, ...rest } = useGetCalendlyEventTypesQuery(undefined, {
        skip: !isCalendlyAuthorized,
    });
    const {
        data: customFieldData,
        isLoading: isLoadingCustomFieldData,
        isError: isErrorCustomFieldData,
    } = useGetCustomFieldEventTypeQuery(Number(projectId), {
        skip: !projectId,
    });
    const { isLoading, isError } = rest;
    const showCalendlyMenu = isCalendlyAuthorized && !isLoading && !isError;

    const handlePersonalizeValues = (label: string) => {
        if (addTo === "editor4" && editor4?.current) {
            const cursorPosition = editor4.current?.selectionStart || 0;
            editor4.current.focus();
            const textBeforeCursor = editor4.current.value.substring(0, cursorPosition);
            const textAfterCursor = editor4.current.value.substring(cursorPosition);
            editor4.current.value = textBeforeCursor + label + textAfterCursor;
            const newCursorPosition = cursorPosition + label.length;
            editor4.current.setSelectionRange(newCursorPosition, newCursorPosition);
            const event = new Event("input", { bubbles: true });
            editor4.current.dispatchEvent(event);
        }

        if (addTo === "editor1" && editor1?.current && editor1?.current?.editor) {
            editor1.current.editor.focus();
            const selection = editor1.current?.editor?.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            editor1.current?.editor.insertText(cursorPosition, label, "user");
        }
        if (addTo === "editor2" && editor2?.current && editor2?.current?.editor) {
            editor2.current.editor.focus();
            const selection = editor2.current?.editor?.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            editor2.current?.editor.insertText(cursorPosition, label, "user");
        }
        if (addTo === "editor3" && editor3?.current && editor3?.current?.editor) {
            editor3.current.editor.focus();
            const selection = editor3.current?.editor?.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            editor3.current?.editor.insertText(cursorPosition, label, "user");
        }
        if (addTo === "editor5" && editor5?.current && editor5?.current?.editor) {
            editor5.current.editor.focus();
            const selection = editor5.current?.editor?.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            editor5.current?.editor.insertText(cursorPosition, label, "user");
        }
    };
    const handleEditSignatureModal = (value?: string) => {
        if (value && value === "Set signature") store.dispatch(toggleEditSignatureModal(true));
    };
    const handleAddCalendlyLink = (text: string, link: string) => {
        if (addTo !== "editor4") {
            if (addTo === "editor1" && editor1?.current && editor1?.current?.editor) {
                if (elementType === "linkedin" || elementType === "SMS") {
                    editor1.current.editor.focus();
                    const selection = editor1.current?.editor?.getSelection();
                    const cursorPosition = selection ? selection.index : 0;
                    editor1.current?.editor.insertText(cursorPosition, link, "user");
                } else {
                    editor1.current.editor.focus();
                    const selection = editor1.current?.editor?.getSelection();
                    const cursorPosition = selection ? selection.index : 0;
                    editor1.current?.editor.insertText(
                        cursorPosition,
                        text,
                        {
                            link,
                        },
                        "user"
                    );
                }
            }
            if (addTo === "editor2" && editor2?.current && editor2?.current?.editor) {
                editor2.current.editor.focus();
                const selection = editor2.current?.editor?.getSelection();
                const cursorPosition = selection ? selection.index : 0;
                editor2.current?.editor.insertText(cursorPosition, link, "user");
            }
            if (addTo === "editor3" && editor3?.current && editor3?.current?.editor) {
                editor3.current.editor.focus();
                const selection = editor3.current?.editor?.getSelection();
                const cursorPosition = selection ? selection.index : 0;
                editor3.current?.editor.insertText(cursorPosition, link, "user");
            }
            if (addTo === "editor5" && editor5?.current && editor5?.current?.editor) {
                editor5.current.editor.focus();
                const selection = editor5.current?.editor?.getSelection();
                const cursorPosition = selection ? selection.index : 0;
                editor5.current?.editor.insertText(cursorPosition, link, "user");
            }
        }
    };

    const actionsWithStateMutations: PersonalizeTagActionsWithMutation[] = actions.map(
        ({ label, buttonDesc, showDivider, tooltip, value, showEditSignature, link }) => ({
            key: nanoid(),
            label,
            buttonDesc,
            tooltip,
            showDivider,
            onClick: () => handlePersonalizeValues(value),
            showEditSignature: () => handleEditSignatureModal(buttonDesc),
            link,
        })
    );

    const signatureWithStateMutations: PersonalizeTagActionsWithMutation[] = signatureAction.map(
        ({ label, buttonDesc, showDivider, tooltip, value, showEditSignature, link }) => ({
            key: nanoid(),
            label,
            buttonDesc,
            tooltip,
            showDivider,
            onClick: () => handlePersonalizeValues(value),
            showEditSignature: () => handleEditSignatureModal(buttonDesc),
            link,
        })
    );

    const defaultCustomActionsWithStateMutations: PersonalizeTagActionsWithMutation[] = defaultCustomActions(
        isEasyGrowth,
        Boolean(jdTitle),
        Boolean(jdDescription)
    ).map(({ label, buttonDesc, showDivider, tooltip, value, showEditSignature, link }) => ({
        key: nanoid(),
        label,
        buttonDesc,
        tooltip,
        showDivider,
        onClick: () => handlePersonalizeValues(value),
        showEditSignature: () => handleEditSignatureModal(buttonDesc),
        link,
    }));

    const customActionsWithStateMutations =
        customFieldData?.map(({ label, value }) => ({
            key: nanoid(),
            label,
            onClick: () => handlePersonalizeValues(label),
        })) || [];

    const RB2BActionStateMutation: PersonalizeTagActionsWithMutation[] = RB2BAction.map(({ label, value }) => ({
        key: nanoid(),
        label,
        onClick: () => handlePersonalizeValues(value),
    }));

    const calendlyActions: PersonalizeTagActionsWithMutation[] =
        data?.map(({ name, scheduling_url }) => ({
            key: nanoid(),
            label: name,
            url: scheduling_url,
            onClick: () => handlePersonalizeValues(` ${scheduling_url} `),
        })) || [];

    return {
        handlePersonalizeValues,
        editor1,
        editor2,
        editor3,
        editor4,
        editor5,
        actions: actionsWithStateMutations,
        customField: {
            customFieldActions: customActionsWithStateMutations,
            isLoadingCustomFieldData,
            isErrorCustomFieldData,
        },
        defaultCustomActions: defaultCustomActionsWithStateMutations,
        signatureAction: signatureWithStateMutations,
        rb2bAction: RB2BActionStateMutation,
        calendly: {
            calendlyActions,
            showCalendlyMenu,
            handleAddCalendlyLink,
            ...rest,
        },
    };
}
