import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { isSuperAdminUser } from "../../pages/Signin/Signin.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import {
    addSearchCandidatesToProject,
    gotoScreenWithAI,
    selectCandidates,
    selectSearch,
    selectSearchType,
} from "../../store/reducers/searchCandidates/searchCandidates.slice";

export function useProjectData() {
    const [searchParams] = useSearchParams();
    const search = useSelector(selectSearch);
    const searchType = useSelector(selectSearchType);

    return {
        projectId: searchParams.get("project"),
        projectName: searchParams.get("name"),
        navigate: useNavigate(),
        dispatch: useDispatch(),
        searchId: search._id,
        status: search.status,
        candidates: useSelector(selectCandidates),
        isSuperAdmin: useSelector(isSuperAdminUser),
        isAddingToProject: useSelector(checkIfLoading(addSearchCandidatesToProject.type)),
        isScreeningWithAI: useSelector(checkIfLoading(gotoScreenWithAI.type)),
        isEdit: searchParams.get("isEdit") === "true",
        searchType,
    };
}
