import { useEffect } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
    Box,
    CircularProgress,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getListOfPowerDialerCampaigns, selectTwilloPowerCallListCreate } from "../../../pages/project/index.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

const StyledPaper = styled(Paper)(({ theme }) => ({
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    overflow: "hidden",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "16px",
    textAlign: "center",
    "&.MuiTableCell-head": {
        backgroundColor: theme.palette.background.default,
        fontWeight: "bold",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
        cursor: "pointer",
    },
}));

const statusStyles = {
    PENDING: { backgroundColor: "#FFF7ED", color: "#F79009" },
    IN_PROGRESS: { backgroundColor: "#E0F7FA", color: "#00796B" },
    PAUSED: { backgroundColor: "#FFF3E0", color: "#FB8C00" },
    COMPLETED: { backgroundColor: "#ECFDF3", color: "#12B76A" },
    CANCELLED: { backgroundColor: "#FFEBEE", color: "#D32F2F" },
    DEFAULT: { backgroundColor: "#E0E0E0", color: "#757575" },
};

const StatusChip = styled(Box)(({ status }) => {
    const { backgroundColor, color } = statusStyles[status] || statusStyles.DEFAULT;
    return {
        backgroundColor,
        color,
        fontWeight: 500,
        fontSize: "0.75rem",
        padding: "2px 8px",
        borderRadius: "16px",
        display: "inline-block",
    };
});

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 3,
    width: "100%",
    backgroundColor: theme.palette.grey[200],
    "& .MuiLinearProgress-bar": {
        borderRadius: 3,
        backgroundColor: "#1976D2",
    },
}));

const CalendarIcon = () => (
    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 2V5"
            stroke="#637381"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 2V5"
            stroke="#637381"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.5 9.09H20.5"
            stroke="#637381"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
            stroke="#637381"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M15.6947 13.7H15.7037" stroke="#637381" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.6947 16.7H15.7037" stroke="#637381" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.9955 13.7H12.0045" stroke="#637381" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.9955 16.7H12.0045" stroke="#637381" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.29431 13.7H8.30329" stroke="#637381" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.29431 16.7H8.30329" stroke="#637381" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default function CampaignOverview() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const powerDialerCampaigns = useSelector(selectTwilloPowerCallListCreate);
    const isLoading = useSelector(checkIfLoading(getListOfPowerDialerCampaigns.type));

    const handleRowClick = (campaign) => {
        navigate(`/call-logs/power-dialer/${campaign._id}`);
    };

    useEffect(() => {
        dispatch(getListOfPowerDialerCampaigns({}));
    }, []);

    return (
        <StyledPaper>
            <TableContainer sx={{ height: 600, overflowY: "auto" }}>
                {isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "600px" }}>
                        <CircularProgress />
                    </Box>
                ) : powerDialerCampaigns?.bulkCallLogs?.length === 0 ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "600px" }}>
                        <Typography variant="h6">No data found</Typography>
                    </Box>
                ) : (
                    <Table sx={{ minWidth: 650 }} aria-label="campaign overview table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={6}>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                    >
                                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1C274C" }}>
                                            Campaign Overview
                                        </Typography>
                                    </Box>
                                </StyledTableCell>
                            </TableRow>
                            {["Index", "Name", "Status", "Candidates", "Created on"].map((header) => (
                                <StyledTableCell key={header}>{header}</StyledTableCell>
                            ))}
                        </TableHead>
                        <TableBody>
                            {powerDialerCampaigns?.bulkCallLogs?.map((campaign, index) => {
                                const progressPercentage = (campaign.completedCalls / campaign.totalCandidates) * 100;

                                return (
                                    <StyledTableRow key={campaign._id} onClick={() => handleRowClick(campaign)}>
                                        <StyledTableCell>{index + 1}</StyledTableCell>
                                        <StyledTableCell>{campaign.name}</StyledTableCell>
                                        <StyledTableCell>
                                            <StatusChip status={campaign.status}>
                                                {campaign?.status?.charAt(0).toUpperCase() +
                                                    campaign?.status?.replace(/_/g, " ").slice(1).toLowerCase()}
                                            </StatusChip>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 1,
                                                    alignItems: "start",
                                                }}
                                            >
                                                <Box sx={{ display: "flex" }}>
                                                    <PersonOutlineIcon sx={{ fontSize: 16, marginRight: 0.5 }} />
                                                    <Typography variant="body2" component="span">
                                                        {campaign.totalCandidates} total
                                                    </Typography>
                                                </Box>
                                                <CustomLinearProgress
                                                    variant="determinate"
                                                    value={progressPercentage}
                                                />
                                                <Typography variant="caption" sx={{ ml: 1, color: "text.secondary" }}>
                                                    {campaign.completedCalls} contacted
                                                </Typography>
                                            </Box>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Box
                                                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                            >
                                                <Box
                                                    component="span"
                                                    sx={{ mr: 1, display: "inline-flex", alignItems: "center" }}
                                                >
                                                    <CalendarIcon />
                                                </Box>
                                                {new Date(campaign.createdAt).toLocaleString("en-US", {
                                                    month: "short",
                                                    day: "numeric",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                })}
                                            </Box>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </StyledPaper>
    );
}
