import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import {
    ChangeOrgNamePayload,
    IEditMember,
    IInvitationUpdatePayload,
    IInvitationsTable,
    IInvitePayload,
    IMembersData,
    ITeamsState,
} from "./manageTeams.types";

const initialState: ITeamsState = {
    pendingInvitations: [],
    invitations: [],
    members: [],
    org: "",
    notification: [],
};

const reducers = {
    fetchMembers(state: ITeamsState, { payload }: { payload: IActionPayload }) {},
    editMember(state: ITeamsState, action: PayloadAction<IEditMember & { successCallback?: () => void }>) {},
    setMembers(state: ITeamsState, { payload }: { payload: IMembersData }) {
        if (payload.name) {
            state.org = payload.name;
        }
        state.members = payload.members;
    },
    inviteMembers(state: ITeamsState, { payload }: { payload: IInvitePayload & IActionPayload }) {},
    addInvitation(state: ITeamsState, { payload }: { payload: IInvitationsTable }) {
        state.invitations = state.invitations.concat(payload);
    },
    changeOrgName(state: ITeamsState, { payload }: { payload: IActionPayload & ChangeOrgNamePayload }) {},
    setOrgName(state: ITeamsState, { payload }: { payload: { name: string } }) {
        state.org = payload.name;
    },
    fetchInvitations(state: ITeamsState, { payload }: { payload: IActionPayload }) {},
    setInvitations(state: ITeamsState, { payload }: { payload: IInvitationsTable[] }) {
        state.invitations = payload;
    },
    acceptInvite(state: ITeamsState, { payload }: { payload: IActionPayload & IInvitationUpdatePayload }) {},
    rejectInvite(state: ITeamsState, { payload }: { payload: IActionPayload & IInvitationUpdatePayload }) {},
    changeInvitationStatus(state: ITeamsState, { payload }: { payload: IInvitationUpdatePayload }) {
        state.invitations = state.invitations.map((item) => {
            if (item.id === payload._id) {
                return { ...item, status: payload.status };
            }

            return item;
        });
        state.pendingInvitations = state.pendingInvitations.filter(({ id }) => id !== payload._id);
    },
    fetchPendingInvitations(state: ITeamsState, { payload }: { payload: IActionPayload }) {},
    setPendingInvitations(state: ITeamsState, { payload }: { payload: IInvitationsTable[] }) {
        state.pendingInvitations = payload;
    },
    resetTeams(state: ITeamsState) {},
    getNotification(state: ITeamsState) {},
    setNotification(state: ITeamsState, { payload }: { payload: any }) {
        state.notification = payload;
    },
    markReadNotification(state: ITeamsState, { payload }: { payload: string[] }) {},
    markActivityNotificationAsRead(state: ITeamsState, { payload }: { payload: string }) {
        state?.notification?.forEach((i: any) => {
            if (i._id === payload) {
                i.active = false;
            }
        });
    },
};

export const teamsSlice = createSlice({
    name: "teams",
    initialState,
    reducers,
});

export default teamsSlice.reducer;

export const fetchMembers = teamsSlice.actions.fetchMembers;
export const editMember = teamsSlice.actions.editMember;
export const resetTeams = teamsSlice.actions.resetTeams;
export const setMembers = teamsSlice.actions.setMembers;
export const inviteMembers = teamsSlice.actions.inviteMembers;
export const addInvitation = teamsSlice.actions.addInvitation;
export const changeOrgName = teamsSlice.actions.changeOrgName;
export const setOrgName = teamsSlice.actions.setOrgName;
export const fetchInvitations = teamsSlice.actions.fetchInvitations;
export const setInvitations = teamsSlice.actions.setInvitations;
export const acceptInvite = teamsSlice.actions.acceptInvite;
export const rejectInvite = teamsSlice.actions.rejectInvite;
export const changeInvitationStatus = teamsSlice.actions.changeInvitationStatus;
export const fetchPendingInvitations = teamsSlice.actions.fetchPendingInvitations;
export const setPendingInvitations = teamsSlice.actions.setPendingInvitations;

export const { getNotification, setNotification, markReadNotification, markActivityNotificationAsRead } =
    teamsSlice.actions;

export const selectOrganization = (state: RootState) => state.teams.org;
export const selectMembers = (state: RootState) => state.teams.members;
export const selectInvitations = (state: RootState) => state.teams.invitations;
export const selectPendingInvitations = (state: RootState) => state.teams.pendingInvitations;
export const selectNotification = (state: RootState) => state.teams.notification;
