import { useSelector } from "react-redux";

import { useCheckProjectInProgress } from "./useCheckProjectInProgress";

import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";

function useCheckProjectLoading() {
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const isProjectInProgress = useCheckProjectInProgress();

    return isProjectLoading && !isProjectInProgress;
}

export { useCheckProjectLoading };
