import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { getCookieInputValues } from "../../../store/reducers/cookiesInput.slice";
import { selectLiCookie } from "../../Signin/Signin.reducer";
import { selectEnableConnectionReq, selectEnableInMailSwitch } from "../../template/index.reducer";
import { selectCandidates } from "../../triggerWorkflow/reducers/personalizedWorkflow.slice";

export function MoreThan100CandidatesHelperMessage() {
    const customCookies = useSelector(getCookieInputValues);
    const candidates = useSelector(selectCandidates);
    const isLiCookieAvailable = useSelector(selectLiCookie);
    const isCustomCookiesAvailable = Object.keys(customCookies).every((key) => customCookies[key].cookie);
    const isConnectionRequestEnable = useSelector(selectEnableConnectionReq);
    const isInMailEnable = useSelector(selectEnableInMailSwitch);
    const shouldShowWarning =
        (isConnectionRequestEnable || isInMailEnable) &&
        (isCustomCookiesAvailable || isLiCookieAvailable) &&
        candidates.length >= 100;

    if (!shouldShowWarning) {
        return null;
    }

    return (
        <>
            <Typography variant="body2" fontStyle="italic" color="grey">
                You have selected over 100 candidates for LinkedIn outreach. This outreach will be distributed over the
                next few days to comply with LinkedIn's messaging limits
            </Typography>
        </>
    );
}
