import { createSlice } from "@reduxjs/toolkit";

interface CronState {
    usersInclude: number[];
    startTime: string;
    endTime: string;
    sources: string;
    sendToEmail: string[];
}

const initialState: CronState = {
    usersInclude: [],
    startTime: "",
    endTime: "",
    sources: "",
    sendToEmail:[],
};

const reducers = {
    setIntelligentEmailTracker(state: any, { payload }: { payload: any }) {
        state.memberId = payload.memberId;
        state.fromEmail = payload.fromEmail;
        state.toEmail = payload.toEmail;
        state.keyword = payload.keyword;
    },
    setAutoReply(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sources = payload.sources;
        state.sendToEmail= payload.sendToEmail;
    },
    setCalendlyMeetingUpdate(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sendToEmail= payload.sendToEmail;
    },
    setCalendlyTracking(state: CronState, { payload }: { payload: any }) {
        state.startTime = payload.startTime;
        state.endTime = payload.endTime;
        state.sendToEmail= payload.sendToEmail;
    },
    setClientWeeklyMail(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.startTime = payload.startTime;
        state.endTime = payload.endTime;
        state.sendToEmail= payload.sendToEmail;
    },
    setClientWeeklyMailSms(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.startTime = payload.startTime;
        state.endTime = payload.endTime;
        state.sendToEmail= payload.sendToEmail;
    },
    setCsEmail(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sendToEmail= payload.sendToEmail;
    },
    setEmailReadForm(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sendToEmail= payload.sendToEmail;
    },
    setEmailTracking(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sources = payload.sources;
        state.startTime = payload.startTime;
        state.endTime = payload.endTime;
        state.sendToEmail= payload.sendToEmail;
    },
    setLiConnection(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sendToEmail= payload.sendToEmail;
    },
    setLiConversations(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sendToEmail= payload.sendToEmail;
    },
    setLiRecruiter(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sendToEmail= payload.sendToEmail;
    },
    setReadReply(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sendToEmail= payload.sendToEmail;
    },
    setSmsTracking(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.startTime = payload.startTime;
        state.endTime = payload.endTime;
        state.sendToEmail =  payload.sendToEmail;
    },
    setTrackingDaily(state: CronState, { payload }: { payload: any }) {
        state.startTime = payload.startTime;
        state.endTime = payload.endTime;
        state.sendToEmail= payload.sendToEmail;
    },
    setUnauthorizedTrack(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sendToEmail= payload.sendToEmail;
    },
    setEmailTrackingNew(state: CronState, { payload }: { payload: any }) {
        state.sendToEmail= payload.sendToEmail;
    },
    setEmailTrackingOptimized(state: CronState, { payload }: { payload: any }) {
        state.sendToEmail= payload.sendToEmail;
    },
    setLiAutoSourcing(state: CronState, { payload }: { payload: any }) {
        state.sources = payload.sources;
        state.sendToEmail= payload.sendToEmail;
    },
    setLiConnReq(state: CronState, { payload }: { payload: any }) {
        state.sendToEmail= payload.sendToEmail;
    },
    setAutomationHyperpersonalization(state: CronState, { payload }: { payload: any }) {
        state.sendToEmail= payload.sendToEmail;
    },
    setAutomationLinkedin(state: CronState, { payload }: { payload: any }) {
        state.sendToEmail= payload.sendToEmail;
    },
    setSendGridOptimized(state: CronState, { payload }: { payload: any }) {
        state.usersInclude = payload.usersInclude;
        state.sendToEmail= payload.sendToEmail;
    },
    setLiSalesnavCookie(state: CronState, { payload }: { payload: any }) {
        state.sendToEmail= payload.sendToEmail;
    },
    setLiScraperHealth(state: CronState, { payload }: { payload: any }) {
        state.sendToEmail= payload.sendToEmail;
    },
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "crons",
    initialState,
    // @ts-ignore
    reducers,
});

export default page.reducer;
export const {
    setAutoReply,
    setCalendlyMeetingUpdate,
    setCalendlyTracking,
    setClientWeeklyMail,
    setClientWeeklyMailSms,
    setCsEmail,
    setEmailReadForm,
    setEmailTracking,
    setLiConnection,
    setLiConversations,
    setLiRecruiter,
    setReadReply,
    setSmsTracking,
    setTrackingDaily,
    setUnauthorizedTrack,
    setIntelligentEmailTracker,
    setEmailTrackingNew,
    setEmailTrackingOptimized,
    setLiAutoSourcing,
    setLiConnReq,
    setAutomationHyperpersonalization,
    setAutomationLinkedin,
    setSendGridOptimized,
    setLiSalesnavCookie,
    setLiScraperHealth,
    leavePage,
} = page.actions;

