import { Stack, Typography } from "@mui/material";

import ExtensionBtn from "../../../components/Navbar/extensionBtn";
import BaseModal from "./BaseModal";

type SampleProjectOutreachModalProps = {
    onClose: () => void;
};

export default function SampleProjectOutreachModal({ onClose }: SampleProjectOutreachModalProps) {
    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                padding: "1rem 1.5rem 1.5rem",
            }}
        >
            <Typography fontSize={20} textAlign="center" color="red">
                Action not allowed
            </Typography>
            <Typography fontSize={14} textAlign="center" my={3}>
                You cannot start outreach through a sample project, please go to the extension to search candidates and
                create a new project
            </Typography>
            <Stack alignItems="center">
                <ExtensionBtn variant="low" />
            </Stack>
        </BaseModal>
    );
}
