import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../../store/reducers/loaders.reducer";
import API from "../../../utils/API";
import { exportToCsvc } from "../../../utils/helper";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import { getUserList, leavePage, setUserList, updateUserFeature } from "./feature.reducers";

function* updateUserFeatureSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type + `${action?.payload?.userId}` }));
        const response = yield call(new API().post, `super-admin/updateUsers-data`, action.payload);

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
            yield put(
                getUserList({
                    action: getUserList.type,
                })
            );
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type + `${action?.payload?.userId}` }));
    }
}

function* getUserListSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().get, `/super-admin/users-data`);
        if (!response?.data) return;

        const users = response.data;
        yield put(setUserList(users));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getUserList.type, getUserListSaga),
        // @ts-ignore
        yield takeLatest(updateUserFeature.type, updateUserFeatureSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
