import { CommandLoading, Command as CommandPrimitive } from "cmdk";
import { matchSorter } from "match-sorter";
import { KeyboardEvent, useCallback, useMemo, useRef, useState } from "react";

import { Chip } from "../Chip/Chip";
import { Command, CommandGroup, CommandItem, CommandList } from "../Command/Command";
import PortalPopup from "../Core/PortalPopup";
import VirtualizedList from "../Core/VirtualizedList";

import HoverTruncateText from "@/atoms/HoverTruncateText";
import { cn } from "@/lib/utils";

type Option = {
    label: string | number;
    value: string | number;
    excluded?: boolean;
};

interface AutocompleteProps {
    /**
     * If true, allows selecting multiple options.
     * @default false
     */
    multiple?: boolean;
    /**
     * Custom class name for styling the component.
     */
    className?: string;

    /**
     * Placeholder text for the input field.
     * @default "Select"
     */
    placeholder?: string;

    /**
     * Additional props to pass to the input field, excluding the `value` prop.
     */
    inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, "value">;

    /**
     * If true, allows free-form entry not restricted to the options list.
     * @default false
     */
    freeSolo?: boolean;

    /**
     * If true, displays a loading indicator in the dropdown.
     * @default false
     */

    loading?: boolean;

    /**
     * If true, disables the input field.
     * @default false
     */

    disabled?: boolean;

    /**
     * List of options to display in the dropdown.
     */
    options?: Option[];

    /**
     * If true, enables the ability to "exclude" selected options.
     * @default false
     */
    excludable?: boolean;

    /**
     * The currently selected values.
     * This prop makes the component controlled when provided.
     */
    value?: Option[];

    /**
     * The error message to display below the input field.
     */
    error?: string;

    /**
     * Callback triggered when the selected value changes.
     * @param value - The new list of selected options.
     */
    onChange?: (value: Option[]) => void;
    /**
     * Callback triggered when the input value changes.
     * @param value - The new input value.
     */
    onInputChange?: (value: string) => void;

    /**
     * Callback triggered when the input field loses focus.
     * @param inputRef - The input field reference.
     */
    onBlur?: (inputRef: HTMLInputElement) => void;

    /**
     * Callback triggered when the input field gains focus.
     * @param inputRef - The input field reference.
     */
    onFocus?: (inputRef: HTMLInputElement) => void;
}

/**
 * A customizable Autocomplete component that allows users to select one or more options from a dropdown list,
 * with optional free-form entry and exclusion capabilities.
 *
 * @param {AutocompleteProps} props - The props for the component.
 * @returns {JSX.Element} - The rendered Autocomplete component.
 */
function Autocomplete(props: AutocompleteProps): JSX.Element {
    const {
        inputProps,
        placeholder = "Select",
        className,
        freeSolo = false,
        excludable = false,
        multiple = false,
        loading = false,
        disabled = false,
        error,
        options,
        value,
        onChange,
        onInputChange,
        onBlur,
        onFocus,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // Determine if the component is controlled by checking if `value` is provided
    const controlled = value !== undefined;

    // Determine if options are provided and the component should display them
    const enableOptions = Array.isArray(options);
    const isSingleSelect = !multiple && !freeSolo && enableOptions;

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<Option[]>([]);
    const [inputValue, setInputValue] = useState(isSingleSelect && controlled ? value[0]?.label?.toString() ?? "" : "");

    // Determine the selected value from props (controlled) or state (uncontrolled)
    const uniqueValues = useMemo(
        () => value?.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i),
        [value]
    );
    const selectedValue = controlled ? uniqueValues : selected;

    // Determine the change handler (controlled or uncontrolled)
    const handleChange = useCallback(
        (value: Option[]) => {
            const callback = controlled ? onChange : setSelected;
            if (multiple) {
                setInputValue("");
                callback(value);
            } else {
                const latestValue = value.slice(-1);
                // If single-select, close the dropdown when an option is selected and set the input value
                setInputValue(latestValue?.[0]?.label?.toString() ?? "");
                callback(latestValue);
            }
        },
        [controlled, multiple, onChange, setSelected, setInputValue, selected, value]
    );

    /**
     * Unselect an option from the selected list.
     * @param {Option} o - The option to unselect.
     */
    const handleUnselect = useCallback(
        (o: Option) => {
            handleChange?.(selectedValue.filter((s) => s.value !== o.value));
        },
        [selectedValue]
    );

    /**
     * Toggle the exclusion state of an option in the selected list.
     * @param {Option} o - The option to exclude or include.
     */
    const handleExclude = useCallback(
        (o: Option) => {
            handleChange?.(selectedValue.map((s) => (s.value === o.value ? { ...s, excluded: !s.excluded } : s)));
        },
        [selectedValue]
    );

    /**
     * Find the index of an option by its label in a list.
     * @param {Option[]} array - The list of options.
     * @param {string} value - The label to search for.
     * @returns {number} - The index of the option, or -1 if not found.
     */
    const findOptionIndex = useCallback((array: Option[] = [], value: string) => {
        return array.findIndex((o) => o.label?.toString().toLowerCase() === value?.toString().toLowerCase());
    }, []);

    /**
     * Handle key down events in the input field.
     * Supports deletion, exiting, and adding options via the Enter key.
     * @param {KeyboardEvent<HTMLDivElement>} e - The keyboard event.
     */
    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            const input = inputRef.current;
            if (input) {
                if (e.key === "Delete" || e.key === "Backspace") {
                    if (input.value === "") {
                        const newSelected = [...selectedValue];
                        newSelected.pop();
                        handleChange?.(newSelected);
                    }
                }
                if (e.key === "Escape") {
                    input.blur();
                }
                if (input.value && e.key === "Enter") {
                    e.preventDefault();
                    const indexOfValueInOptions = findOptionIndex(options, input.value);
                    if (indexOfValueInOptions !== -1) {
                        const indexOfValueInSelected = findOptionIndex(selectedValue, input.value);
                        if (indexOfValueInSelected === -1) {
                            handleChange?.([...selectedValue, options[indexOfValueInOptions]]);
                        }
                    } else if (freeSolo) {
                        const indexOfValueInSelected = findOptionIndex(selectedValue, input.value);
                        if (indexOfValueInSelected === -1) {
                            handleChange?.([...selectedValue, { value: input.value, label: input.value }]);
                        }
                    }
                }
            }
        },
        [selectedValue, options]
    );

    // Filter options to only include those not yet selected
    const selectables = (options || []).filter((o) => !selectedValue.some((s) => s.value === o.value));

    // Determine if options should be filtered
    const filteredOptions = isSingleSelect
        ? selectables
        : matchSorter(selectables, inputValue, {
              keys: ["label"],
              // disable sorting to keep the order of the options
              sorter: (items) => items,
          });

    return (
        <Command
            shouldFilter={false}
            className="overflow-visible bg-transparent"
            aria-multiselectable={multiple}
            onKeyDown={handleKeyDown}
        >
            <div
                ref={containerRef}
                className={cn(
                    "group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-primary",
                    { "ring-1 ring-error": !!error },
                    className
                )}
                onClick={() => {
                    inputRef.current?.focus();
                }}
            >
                <div className="flex flex-wrap gap-1">
                    {multiple &&
                        selectedValue.map((option) => (
                            <Chip
                                key={option.value}
                                variant={option.excluded ? "error" : "success"}
                                onDelete={() => handleUnselect(option)}
                                onExclude={excludable && (() => handleExclude(option))}
                                labelClassName="break-words max-w-96" // max-width: 24rem
                            >
                                {option.label}
                            </Chip>
                        ))}
                    <CommandPrimitive.Input
                        disabled={disabled}
                        ref={inputRef}
                        value={inputValue}
                        onValueChange={(value: string) => {
                            setInputValue(value);
                            onInputChange?.(value);
                        }}
                        onBlur={() => {
                            setOpen(false);
                            onBlur?.(inputRef.current);
                            // if freeSolo, single select is enabled and the input value is empty, add it to the selected value
                            if (!selectedValue.length && !multiple && freeSolo && inputValue) {
                                handleChange?.([...selectedValue, { value: inputValue, label: inputValue }]);
                            }
                        }}
                        onFocus={() => {
                            setOpen(true);
                            onFocus?.(inputRef.current);
                        }}
                        placeholder={placeholder}
                        className={cn(
                            "ml-2 flex-1 bg-transparent outline-none focus:placeholder:text-muted-foreground",
                            {
                                "placeholder:text-error": !!error,
                                "placeholder:text-muted-foreground": !error,
                            },
                            inputProps?.className
                        )}
                        {...inputProps}
                    />
                </div>
            </div>
            {error && <div className="text-error text-xs mt-1.5">{error}</div>}

            {(loading || filteredOptions.length > 0) && (
                <PortalPopup
                    // we dont want to show empty options if freeSolo is enabled and no options are provided
                    open={open}
                    anchorRef={containerRef}
                >
                    {(position) => {
                        return (
                            <CommandList>
                                {loading ? (
                                    <CommandLoading>
                                        <div className="flex items-center justify-center text-sm py-1 pt-2">
                                            Loading...
                                        </div>
                                    </CommandLoading>
                                ) : (
                                    <CommandPrimitive.Empty>
                                        <div className="flex items-center justify-center text-sm py-1 pt-2">
                                            No options!
                                        </div>
                                    </CommandPrimitive.Empty>
                                )}

                                <CommandGroup className="h-full overflow-auto" unselectable="on">
                                    {filteredOptions.length > 50 ? (
                                        <VirtualizedList
                                            containerHeight={280}
                                            numItems={filteredOptions.length}
                                            itemHeight={30}
                                            renderItem={({ index, ...rest }) => {
                                                const option = filteredOptions[index];
                                                return (
                                                    <CommandItem
                                                        style={{ ...rest.style }}
                                                        key={option.value}
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        onSelect={(_value) => {
                                                            handleChange?.([...selectedValue, option]);
                                                        }}
                                                        className="cursor-pointer"
                                                    >
                                                        <HoverTruncateText
                                                            maxWidth={position.width - 20}
                                                            stopPropagation={false}
                                                        >
                                                            {option.label}
                                                        </HoverTruncateText>
                                                    </CommandItem>
                                                );
                                            }}
                                        />
                                    ) : (
                                        filteredOptions.map((option) => (
                                            <CommandItem
                                                key={option.value}
                                                onMouseDown={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                                onSelect={(_value) => {
                                                    handleChange?.([...selectedValue, option]);
                                                    inputRef?.current?.blur?.();
                                                }}
                                                className="cursor-pointer"
                                            >
                                                {option.label}
                                            </CommandItem>
                                        ))
                                    )}
                                </CommandGroup>
                            </CommandList>
                        );
                    }}
                </PortalPopup>
            )}
        </Command>
    );
}

export { Autocomplete };
