import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import MessagesPaneContent from "./MessagesPaneContent";
import MessagesPaneEmptyState from "./MessagesPaneEmptyState";

import { useSuperInboxContext } from "../../Context";
import { openMessagesPane, validateChannel } from "../../utils";

interface MessagesPaneProps {}

export default function MessagesPane(_props: MessagesPaneProps) {
    const { t } = useTranslation();
    const { isMobile, candidateParamId, channelParmaType } = useSuperInboxContext();
    const shouldRenderMessages = candidateParamId && channelParmaType;

    if (!shouldRenderMessages) {
        if (isMobile) openMessagesPane();
        return <MessagesPaneEmptyState>{t("superInbox.messagePaneEmptyLabel")}</MessagesPaneEmptyState>;
    }

    // channel is required to render conversation messages
    if (!validateChannel(channelParmaType)) {
        return <Navigate to="/inbox" />;
    }

    return <MessagesPaneContent />;
}
