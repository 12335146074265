import { CircleX } from "lucide-react";
import { toast } from "sonner";

import { notificationIcons } from "./NotificationFragments";

export function notificationToasts(notificationData) {
    const { title, notificationType, data } = notificationData;

    return toast(title, {
        duration: 5000,
        description: data?.description,
        icon: notificationIcons(notificationType)?.props?.children,
        classNames: {
            icon: "mr-2 self-start pt-2",
            content: "flex flex-col gap-2",
        },
        action: {
            label: <CircleX className="h-5 w-5" />,
            onClick: () => {
                toast.dismiss();
            },
        },
    });
}
