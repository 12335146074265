import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import AutocompleteWithExclusion from "../../../molecules/AutocompleteWithExclusion/AutocompleteWithExclusion";
import { selectSchool, setValues } from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

export function School({ id }: { id: SearchStateKeys }) {
    const { isLoading, query, setQuery, dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectSchool);

    const handleChange = (value: typeof values) => {
        dispatch(
            setValues({
                key: id,
                value: value,
            })
        );
    };

    return (
        <AutocompleteWithExclusion
            fullWidth
            size="small"
            label="School"
            placeholder="School"
            value={values}
            options={Object.keys(options).map((i) => i)}
            onChange={handleChange}
            inputValue={query}
            onInputChange={setQuery}
            loading={isLoading}
            autoSave
            tooltipProps={{ title: "Press 'enter' after each keyword" }}
        />
    );
}
