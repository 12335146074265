import { Divider, Dropdown, Menu, MenuButton, MenuItem, Sheet, Skeleton, Stack, Typography } from "@mui/joy";
import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SaveOutreachFlow } from "./SaveOutreachFlow";
import SaveTemplate from "./SaveTemplate";

import ImportImg from "../../../assets/img/folder.png";
import SaveImg from "../../../assets/img/saveTemplate.png";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { MaterialProvider } from "../../../components/MaterialProvider";
import useDisclosure from "../../../hooks/useDisclosure";
import { RootState } from "../../../store";
import {
    useFetchTemplateNamesQuery,
    // useFetchCheckpointByIdQuery,
    // useFetchSavedCheckpointQuery,
    useFetchTemplatesQuery,
    useLazyFetchTemplateByIdQuery,
} from "../../../store/apis/outreach/outreach.api";
import { EVENT_KEYS } from "../../../store/apis/outreach/outreach.enums";
import { FetchTemplatesResponseData } from "../../../store/apis/outreach/outreach.types";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import {
    checkErrorInOutreachElement,
    checkIfOutreachReady,
    editOutreachElement,
    selectCurrentOutreachElementType,
    selectOutReachFlow,
} from "@/store/reducers/outreach/outreach.slice";
import { checkEasySource, checkIsLinkedinAuthorized, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Modal, Tooltip } from "@/ui";
import { selectVirtualUserId } from "@/store/reducers/template/template.reducer";

export const EVENT_KEYS_TEMPLATE_TYPE_MAP = {
    email: "EMAIL",
    "follow-up": "FOLLOW_UP",
    SMS: "SMS",
    linkedin: "LINKEDIN",
} as const;

const TEMPLATE_TYPE_EVENT_KEYS_MAP = {
    EMAIL: "email",
    FOLLOW_UP: "follow-up",
    SMS: "SMS",
    LINKEDIN: "linkedin",
} as const;

type TemplateTypes = "EMAIL" | "FOLLOW_UP" | "SMS" | "LINKEDIN";

interface RequestPayload {
    id: string;
    workflowId: string;
    virtualUserId?: string;
}

const LoadingSkeleton = () => {
    return (
        <>
            {[1, 2, 3, 4, 5].map((item) => {
                return (
                    <Stack px={2} key={item} gap={2}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Skeleton sx={{ position: "relative", width: "250px", height: "25px" }} />
                            <Skeleton sx={{ position: "relative", width: "60px", height: "25px" }} />
                        </Stack>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Skeleton sx={{ position: "relative", width: "200px", height: "15px" }} />
                            <Skeleton sx={{ position: "relative", width: "60px", height: "15px" }} />
                        </Stack>
                        <Divider sx={{ marginBottom: "10px" }} />
                    </Stack>
                );
            })}
        </>
    );
};

function TemplatesMenu({ type, isOpen, onClose }: { type: TemplateTypes; isOpen: boolean; onClose: () => void }) {
    const [searchQuery, setSearchQuery] = useState("");
    const { currentOutreachElementId } = useOutreachParams();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const { data, isFetching } = useFetchTemplatesQuery(
        { type: EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] },
        { refetchOnMountOrArgChange: true }
    );

    const dispatch = useDispatch();

    const dispatchInMailSubject = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                inMailSubject: value,
            })
        );
    };
    const dispatchInmailFollowupSubject = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                inmailFollowupSubject: value,
            })
        );
    };
    const dispatchInMailBody = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                inMailBody: value,
            })
        );
    };
    const dispatchConnectionReminderMessageBody = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                connectionReminderMessageBody: value,
            })
        );
    };
    const dispatchInMailFollowupBody = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                inmailFollowupBody: value,
            })
        );
    };
    const dispatchSubject = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                subject: value,
            })
        );
    };
    const dispatchBody = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                body: value,
            })
        );
    };

    const handleTemplateChange = ({
        newId,
        subject,
        body,
        inMailSubject,
        inMailBody,
        connectionReminderMessageBody,
        inmailFollowupBody,
        inmailFollowupSubject,
        connectionReminderMessageStatus,
        isScheduleRecruiterOnSameDay,
        inmailFollowupStatus,
        inmailFollowupReminder,
        connectionStatus,
        inMailStatus,
    }: {
        newId: string;
        body: string;
        subject?: string;
        inMailSubject?: string;
        inMailBody?: string;
        connectionReminderMessageBody?: string;
        inmailFollowupBody?: string;
        inmailFollowupSubject?: string;
        connectionReminderMessageStatus?: boolean;
        isScheduleRecruiterOnSameDay?: boolean;
        inmailFollowupStatus?: boolean;
        inmailFollowupReminder?: string;
        connectionStatus?: boolean;
        inMailStatus?: boolean;
    }) => {
        dispatchBody(body);
        if (subject) {
            dispatchSubject(subject);
        }
        if (inMailSubject || currentElementType === "linkedin") {
            dispatchInMailSubject(inMailSubject || "");
        }
        if (inMailBody) {
            dispatchInMailBody(inMailBody);
        }
        if (connectionReminderMessageBody) {
            dispatchConnectionReminderMessageBody(connectionReminderMessageBody);
        }
        if (inmailFollowupBody) {
            dispatchInMailFollowupBody(inmailFollowupBody);
        }
        if (inmailFollowupSubject) {
            dispatchInmailFollowupSubject(inmailFollowupSubject);
        }
        if (inmailFollowupReminder) {
            dispatch(
                editOutreachElement({
                    id: currentOutreachElementId,
                    inmailFollowupReminder: inmailFollowupReminder,
                })
            );
        }
        if (currentElementType === "linkedin") {
            if (connectionStatus || inMailStatus) {
                dispatch(
                    editOutreachElement({
                        id: currentOutreachElementId,
                        connectionReminderMessageStatus: connectionReminderMessageStatus,
                        isScheduleRecruiterOnSameDay: false,
                        inmailFollowupStatus: inmailFollowupStatus,
                        connectionStatus: connectionStatus,
                        inMailStatus: inMailStatus,
                    })
                );
            } else {
                dispatch(
                    editOutreachElement({
                        id: currentOutreachElementId,
                        connectionReminderMessageStatus: connectionReminderMessageStatus,
                        isScheduleRecruiterOnSameDay: false,
                        inmailFollowupStatus: inmailFollowupStatus,
                        connectionStatus: true,
                        inMailStatus: false,
                    })
                );
            }
        }
        onClose();
    };
    const isEasySource = useSelector(checkEasySource);
    const menuItem = data?.filter((item) => {
        return (
            item.type === type && (searchQuery === "" || item.name.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    });

    return (
        <Modal open={isOpen} onClose={onClose}>
            {isFetching ? (
                <Stack
                    sx={{
                        backgroundColor: "white",
                        width: 550,
                        borderRadius: "md",
                        py: 2,
                        boxShadow: "lg",
                    }}
                >
                    <LoadingSkeleton />
                </Stack>
            ) : (
                <div
                    style={{
                        width: 550,
                        borderRadius: "md",
                        paddingTop: 2,
                        paddingBottom: 2,
                        boxShadow: "lg",
                        // scrollbarWidth: "none",
                    }}
                >
                    <Stack style={{ padding: 3 }} gap={1.5}>
                        <input
                            placeholder="Search draft"
                            value={searchQuery}
                            className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 border-gray-300 focus:ring-blue-500`}
                            style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        {menuItem.length !== 0 && (
                            <Typography className="text-sm">
                                {searchQuery === ""
                                    ? `You have ${menuItem.length} saved drafts in your  ${isEasySource ? `EasySource` : `EasyGrowth`}
                                 workspace`
                                    : `You have ${menuItem.length} saved drafts that matches you search`}
                            </Typography>
                        )}
                    </Stack>

                    <Stack sx={{ overflowY: "scroll", maxHeight: "500px" }}>
                        {menuItem.length === 0 ? (
                            <Typography className="pt-2 pb-2 pl-3 pr-3">No templates found</Typography>
                        ) : (
                            menuItem.map((item) => {
                                return (
                                    <Stack
                                        className="hover:bg-[#dfdfdf] hover:cursor-pointer py-2 px-3 gap-1"
                                        onClick={() =>
                                            handleTemplateChange({
                                                newId: item._id,
                                                body: item.body,
                                                subject: item.subject,
                                                inMailSubject: item.inMailSubject,
                                                inMailBody: item.inMailBody,
                                                connectionReminderMessageBody: item.connectionReminderMessageBody,
                                                inmailFollowupBody: item.inmailFollowupBody,
                                                inmailFollowupSubject: item.inmailFollowupSubject,
                                                connectionReminderMessageStatus: item?.connectionReminderMessageStatus,
                                                isScheduleRecruiterOnSameDay: item?.isScheduleRecruiterOnSameDay,
                                                inmailFollowupStatus: item?.inmailFollowupStatus,
                                                inmailFollowupReminder: item?.inmailFollowupReminder,
                                                connectionStatus: item?.connectionStatus,
                                                inMailStatus: item?.inMailStatus,
                                            })
                                        }
                                    >
                                        <div className="text-sm text-normal" key={item._id}>
                                            {item.name}
                                        </div>
                                        <Stack sx={{ width: "100%", justifyContent: "space-between" }} direction="row">
                                            <Typography className="italic text-xs" style={{ fontSize: "12px" }}>
                                                Created by {item.user}
                                            </Typography>
                                            <Typography className="italic text-xs" style={{ fontSize: "12px" }}>
                                                Last updated on {dayjs(item.updatedAt).format("MMMM D, YYYY h:mm A")}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                );
                            })
                        )}
                    </Stack>
                </div>
            )}
        </Modal>
    );
}

function TemplateItem({ item }: { item: any }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { projectId, candidateId, outreachId } = useOutreachParams();
    const virtualUserId = useSelector(selectVirtualUserId);
    const user = useSelector(selectUser);

    const [fetchTemplateById, { isLoading: isLoadingTemplate }] = useLazyFetchTemplateByIdQuery();

    const handleClick = async (id: string) => {
        if (!id) {
            dispatch(setErrorNotification("Error fetching template"));
        }
        const requestPayload: RequestPayload = { id: id, workflowId: outreachId };
        if (user._id !== Number(virtualUserId)) {
            requestPayload.virtualUserId = virtualUserId;
        }
        const { data } = await fetchTemplateById(requestPayload);
        if (!data) {
            dispatch(setErrorNotification("template not available or error fetching template"));
            return;
        }

        if (data?.workflowData?._id && data?.workflowData?.workflowStepsIds?.[0]) {
            navigate(
                `/outreach/${projectId}/${data?.workflowData?._id}/${candidateId}/${data?.workflowData?.workflowStepsIds?.[0]}/base`
            );
        }
    };

    return (
        <Stack
            className="hover:bg-[#dfdfdf] hover:cursor-pointer py-2 px-3 gap-1"
            key={item._id}
            onClick={() => {
                handleClick(item._id);
            }}
        >
            <div className="text-sm text-normal" key={item._id}>
                {item.name}
            </div>
            <Stack sx={{ width: "100%", justifyContent: "space-between" }} direction="row">
                <Typography className="italic text-xs" style={{ fontSize: "12px" }}>
                    Last updated on {dayjs(item.updatedAt).format("MMMM D, YYYY h:mm A")}
                </Typography>
                {isLoadingTemplate && (
                    <Typography className="italic text-xs" style={{ fontSize: "12px" }}>
                        Loading...
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}

function OutreachFlowMenu({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const { data, isLoading } = useFetchTemplateNamesQuery(undefined, { refetchOnMountOrArgChange: true });
    const handleConfirmation = () => {
        onClose();
    };

    return (
        <>
            <Modal open={isOpen} onClose={onClose}>
                {isLoading ? (
                    <Stack
                        sx={{
                            backgroundColor: "white",
                            width: 550,
                            borderRadius: "md",
                            py: 2,
                            boxShadow: "lg",
                        }}
                    >
                        <LoadingSkeleton />
                    </Stack>
                ) : (
                    <Sheet
                        variant="outlined"
                        sx={{
                            width: 550,
                            borderRadius: "md",
                            py: 1,
                            boxShadow: "lg",
                        }}
                    >
                        <Stack sx={{ px: 3, pb: 2 }} gap={1.5}>
                            {data?.length !== 0 && (
                                <Typography level="body-sm">
                                    You have {data?.length} saved outreach{" "}
                                    {data?.length === 1 ? "sequence" : "sequences"}.
                                </Typography>
                            )}
                        </Stack>

                        <Stack sx={{ overflowY: "scroll", maxHeight: "500px" }}>
                            {data?.length === 0 ? (
                                <Typography sx={{ paddingY: "0.5rem", px: 2 }}>No outreach sequence found.</Typography>
                            ) : (
                                data?.map((item) => {
                                    return <TemplateItem item={item} />;
                                })
                            )}
                        </Stack>
                    </Sheet>
                )}
            </Modal>
            <MaterialProvider>
                <ConfirmationModal
                    title="The unsaved changes will be lost if you switch templates. Do you want to proceed?"
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    submit={handleConfirmation}
                    submitText="Yes"
                    isSubmitDisabled={isLoading}
                />
            </MaterialProvider>
        </>
    );
}
export const FetchFromTemplate = ({ type }: { type: EVENT_KEYS }) => {
    const { currentOutreachElementId } = useOutreachParams();

    const user = useSelector(selectUser);
    const outreach = useSelector(selectOutReachFlow);
    const isOutreachTemplatesReady = useSelector(checkIfOutreachReady);
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);
    const isEmailAuthorized = user.emailAuthorized;
    const isEmailPresent = outreach.find((item) => item?.eventName === "email");
    const isLinkedinPresent = outreach.find((item) => item?.eventName === "linkedin");

    const { isOpen: isOpenSaveTemplate, onOpen: onOpenSaveTemplate, onClose: onCloseSaveTemplate } = useDisclosure();
    const { isOpen: isOpenTemplateMenu, onOpen: onOpenTemplateMenu, onClose: onCloseTemplateMenu } = useDisclosure();
    const {
        isOpen: isOpenOutreachFlowMenu,
        onOpen: onOpenOutreachFlowMenu,
        onClose: onCloseOutreachFlowMenu,
    } = useDisclosure();
    const { isOpen: isOpenSaveModal, onOpen: onOpenSaveModal, onClose: onCloseSaveModal } = useDisclosure();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const isErrorInOutreachElement = useSelector((state: RootState) =>
        checkErrorInOutreachElement(state, currentOutreachElementId)
    );

    // done type instead of templateType
    const isDisabled =
        !isOutreachTemplatesReady ||
        !type ||
        (isEmailPresent && !isEmailAuthorized) ||
        (isLinkedinPresent && !isLinkedInAuthorized);

    const disableReason = !isDisabled ? (
        ""
    ) : !isOutreachTemplatesReady ? (
        <>
            Please <strong>Save Workflow</strong> before scheduling outreach
        </>
    ) : isEmailPresent && !isEmailAuthorized ? (
        "Email needs to be authorized first"
    ) : (
        "Linkedin needs to be authorized first"
    );

    // if (isFetching || !data) return <></>;
    return (
        <CssVarsProvider>
            <CssBaseline />
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                <Dropdown>
                    <MenuButton
                        variant="plain"
                        size="lg"
                        sx={{ paddingLeft: 0.5, paddingRight: 0.5, margin: 0 }}
                        disabled={isErrorInOutreachElement}
                    >
                        <Tooltip title="Save/Update templates" placement="top">
                            <img src={SaveImg} alt="save" height={20} width={20} />
                        </Tooltip>
                    </MenuButton>

                    <Menu>
                        <MenuItem sx={{ fontSize: "0.93rem" }} onClick={onOpenSaveTemplate}>
                            Save current {type} draft
                        </MenuItem>
                        <Tooltip title={isDisabled ? disableReason : ""}>
                            <div>
                                <MenuItem sx={{ fontSize: "0.9rem" }} onClick={onOpenSaveModal} disabled={isDisabled}>
                                    Save entire outreach sequence
                                </MenuItem>
                            </div>
                        </Tooltip>
                    </Menu>
                </Dropdown>

                <Dropdown>
                    <MenuButton variant="plain" size="lg" sx={{ paddingLeft: 0.5, paddingRight: 0.5, margin: 0 }}>
                        <Tooltip title="Use templates" placement="top">
                            <img src={ImportImg} alt="save" height={23} width={23} />
                        </Tooltip>
                    </MenuButton>
                    <Menu>
                        <MenuItem sx={{ fontSize: "0.9rem" }} onClick={onOpenTemplateMenu}>
                            Fetch {type} draft
                        </MenuItem>
                        <MenuItem sx={{ fontSize: "0.9rem" }} onClick={onOpenOutreachFlowMenu}>
                            Fetch outreach sequence
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </Stack>

            {isOpenSaveTemplate && <SaveTemplate isOpen={isOpenSaveTemplate} onClose={onCloseSaveTemplate} />}
            {isOpenTemplateMenu && (
                <TemplatesMenu
                    isOpen={isOpenTemplateMenu}
                    onClose={onCloseTemplateMenu}
                    type={EVENT_KEYS_TEMPLATE_TYPE_MAP[type]}
                />
            )}

            {isOpenOutreachFlowMenu && (
                <OutreachFlowMenu isOpen={isOpenOutreachFlowMenu} onClose={onCloseOutreachFlowMenu} />
            )}

            {isOpenSaveModal && <SaveOutreachFlow isOpen={isOpenSaveModal} onClose={onCloseSaveModal} />}
        </CssVarsProvider>
    );
};
