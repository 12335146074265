import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import useOAuth2 from "../../../hooks/useOAuth2/useOAuth2";
import { selectUser, setUser } from "../../Signin/Signin.reducer";

export default function LeverConnect({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const leverRefreshToken = user.leverRefreshToken;

    const { loading, getAuth } = useOAuth2({
        authorizeUrl: `${import.meta.env.VITE_REACT_APP_LEVER_AUTH_LINK}/authorize`,
        clientId: `${import.meta.env.VITE_REACT_APP_LEVER_CLIENT_ID}`,
        redirectUri: `${import.meta.env.VITE_REACT_APP_BASE_URL}/authorize-lever`,
        scope: "offline_access opportunities:write:admin postings:read:admin users:read:admin stages:read:admin uploads:write:admin resumes:read:admin",
        responseType: "code",
        extraQueryParameters: {
            prompt: "consent",
            audience: import.meta.env.VITE_REACT_APP_LEVER_AUDIENCE,
        },
        exchangeCodeForTokenServerURL: `${import.meta.env.VITE_REACT_APP_BASE_URL}/api/lever/token`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess: (response) => {
            dispatch(
                setUser({
                    ...user,
                    leverRefreshToken: response.refresh_token,
                })
            );
            dispatch(setSuccessNotification(response.message));
        },
        onError: () => {
            dispatch(setErrorNotification("Error in connecting lever"));
        },
    });

    const handleConnect = () => getAuth();

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Connect Lever</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Lever is a top-rated recruiting software that helps your talent teams scale recruitment efforts and
                    hire people faster. Click the button below to connect your Lever account though OAuth
                </Typography>
                {/* <Button
            variant={"contained"}
            color={leverRefreshToken ? "success" : "primary"}
            onClick={handleConnect}
            disabled={loading || !!leverRefreshToken}
        >
            <ButtonTextWithLoading isLoading={loading} text={leverRefreshToken ? "Connected" : "Connect"} />
        </Button> */}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose} sx={{ borderRadius: 1 }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleConnect}
                    sx={{ borderRadius: 1 }}
                    disabled={loading || !!leverRefreshToken}
                >
                    <ButtonTextWithLoading
                        isLoading={loading}
                        text={leverRefreshToken ? "Connected" : "Connect With OAuth"}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// export default function LeverAts() {
//     return (
//         <ConnectWrapper
//             logo={leverLogo}
//             logoAlt="lever-logo"
//             title="Lever"
//             description="Lever is a top-rated recruiting software that helps your talent teams scale recruitment efforts and hire people faster."
//         >
//             <LeverConnect />
//         </ConnectWrapper>
//     );
// }
