import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Drawer from "@mui/joy/Drawer";
import IconButton from "@mui/joy/IconButton";
import ModalClose from "@mui/joy/ModalClose";
import { useState } from "react";

import { Filters } from "./Filters";

import { useSuperInboxContext } from "../../Context";
import { toggleFiltersPane } from "../../utils";

const DrawerComponent = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
    return (
        <Drawer open={open} onClose={handleClose} size="sm">
            <ModalClose />
            <Filters />
        </Drawer>
    );
};

const FilterDrawerMobile = () => {
    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(!open);

    const handleClose = () => setOpen(false);

    return (
        <>
            <IconButton onClick={handleClick}>
                <FilterAltIcon />
            </IconButton>
            <DrawerComponent open={open} handleClose={handleClose} />
        </>
    );
};
export const FilterDrawer = () => {
    const { isMobile } = useSuperInboxContext();

    if (isMobile) return <FilterDrawerMobile />;

    return (
        <IconButton id="filter-drawer-button" onClick={() => toggleFiltersPane()}>
            <FilterAltIcon />
        </IconButton>
    );
};

