import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Alert, Button, Menu, MenuItem, Popper, Skeleton, Stack } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AutopilotSettings } from "./AutoPilotSettings";

import { checkIsV2Autopilot, isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";

import useOnClickOutside from "@/hooks/useClickOutside";
import {
    editProject,
    getProject,
    selectCurrProject,
    selectProjectAutoSearchStatus,
    submitAutoSearchStatus,
} from "@/store/reducers/allProjects/allProjects.reducer";



export const AutoPilotProject = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    const [isHovering, setIsHovering] = useState(false);

    const isAutoSourceEnabled = useSelector(selectProjectAutoSearchStatus);
    const isUpdatingStatus = useSelector(checkIfLoading(submitAutoSearchStatus.type));
    const currenntProject = useSelector(selectCurrProject);
    const isLoading = useSelector(checkIfLoading(getProject.type));
    const isV2AutoPilot = useSelector(checkIsV2Autopilot);
    const popperRef = useRef(null);

    const isSuperAdmin = useSelector(isSuperAdminUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formattedStatus = currenntProject?.dashboardStatus
        ?.toLowerCase()
        ?.replace(/_/g, " ")
        ?.replace(/\b(\w)/g, (char) => char?.toUpperCase());

    const showAlert = !isUpdatingStatus && isAutoSourceEnabled;
    const open = Boolean(anchorEl);

    useOnClickOutside(popperRef, () => {
        setIsHovering(false);
        handleMenuClose();
    });
    const getColor = () => {
        switch (formattedStatus) {
            case "Active":
                return "success";
            case "On Hold":
                return "info";
            case "Closed":
                return "disabled";
            default:
                return "primary";
        }
    };

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

    const handleMenuClose = () => setAnchorEl(null);

    const handleSettingsHover = (event) => {
        setSettingsAnchorEl(event.currentTarget);
        setIsHovering(true);
    };

    const handleSettingsLeave = () => {
        // setIsHovering(false);
    };

    const handleSettingsClose = (event) => {
        if (settingsAnchorEl && !settingsAnchorEl.contains(event.target)) {
            setSettingsAnchorEl(null);
            setIsHovering(false);
            setAnchorEl(null);
        }
    };

    const validateAction = (callback) => {
        return () => {
            if (formattedStatus === "CLOSED" && !isSuperAdmin) {
                dispatch(
                    setErrorNotification(
                        "Kindly contact your POC or customer-support@hirequotient.com to reactivate this project"
                    )
                );
                return;
            }
            callback();
        };
    };

    const actions = [
        {
            id: nanoid(),
            label: "Active",
            handleClick: validateAction(() => menuClickHandler("ACTIVE")),
        },
        {
            id: nanoid(),
            label: "Closed",
            handleClick: () => menuClickHandler("CLOSED"),
        },
        {
            id: nanoid(),
            label: "On hold",
            handleClick: validateAction(() => menuClickHandler("ON_HOLD")),
        },
    ];

    const menuClickHandler = (status) => {
        if (currenntProject.dashboardStatus === status) return;

        if (status === "CLOSED") {
            return navigate(`/close/${currenntProject._id}`);
        }

        if (status === "ON_HOLD") {
            return navigate(`/on-hold/${currenntProject._id}`);
        }

        dispatch(
            editProject({
                ...currenntProject,
                excludeVettingCriteria: true,
                dashboardStatus: status,
            })
        );
    };

    return isLoading ? (
        <Skeleton width={300} height={34} />
    ) : (
        <Stack direction="row" spacing={1}>
            <Stack direction="row" spacing={2}>
                <div style={{ marginTop: "2.5px" }}>
                    <Button
                        variant="outlined"
                        onClick={handleMenuOpen}
                        endIcon={<KeyboardArrowDownRoundedIcon />}
                        color={getColor()}
                        sx={{ height: "32px", minWidth: 48, fontSize: 12 }}
                    >
                        {formattedStatus || "Select Status"}
                    </Button>
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    {actions.map((action) => (
                        <MenuItem
                            key={action.id}
                            onClick={() => {
                                action.handleClick();
                                handleMenuClose();
                            }}
                            sx={{ fontSize: 14 }}
                        >
                            {action.label}
                        </MenuItem>
                    ))}
                </Menu>

                {formattedStatus === "Active" && (
                    <div className="cursor-pointer" onClick={handleSettingsHover} onMouseLeave={handleSettingsLeave}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#0891b2",
                                marginTop: "7px",
                                fontSize: "16px",
                            }}
                        >
                            <SettingsOutlinedIcon style={{ color: "#0891b2", marginRight: "5px", fontSize: "20px" }} />
                            <span>Autopilot settings</span>
                        </div>

                        <Popper
                            ref={popperRef}
                            open={isHovering}
                            anchorEl={settingsAnchorEl}
                            placement="bottom"
                            style={{ marginTop: "4rem", marginLeft: "25rem" }}
                        >
                            <AutopilotSettings onClose={handleSettingsClose} />
                        </Popper>
                    </div>
                )}
            </Stack>

            {showAlert && !isV2AutoPilot && (
                <Alert
                    icon={false}
                    severity="info"
                    sx={(theme) => ({
                        padding: theme.spacing(1 / 2),
                        paddingX: theme.spacing(1),
                        borderRadius: theme.shape.borderRadius,
                    })}
                >
                    {isAutoSourceEnabled
                        ? `Your ${t(
                            "project"
                        )} is now on autopilot. We recommend that you also inform your Account Manager`
                        : `Your ${t("project")} is currently on autopilot`}
                </Alert>
            )}
        </Stack>
    );
};
