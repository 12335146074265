import { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Menu, MenuItem } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useDispatch } from "react-redux";

import { setSuccessNotification } from "../../../components/Notification/index.reducer";
import useMenu from "../../../hooks/useMenu";
import { useUpdateContactOverviewActivityMutation } from "../../../store/apis/all-candidates/all-candidates.api";
import { useFetchActivityTags } from "../AllCandidatesContent";
import { ActivityColumn, CommonActivityCellProps } from "./ContactOverviewActivities/ContactOverviewActivities.types";

export function ActivityType({
    value,
    activityId,
}: { value: ActivityColumn["activityType"] } & CommonActivityCellProps) {
    const disaptch = useDispatch();

    const { anchorEl, handleClick, handleClose, open, menuSX } = useMenu();
    const [tag, setTag] = useState<string>(() => value);
    const { data = [] } = useFetchActivityTags();
    useEffect(() => {
        setTag(value);
    }, [value]);

    const [updateContactOverviewActivityMutation] = useUpdateContactOverviewActivityMutation();

    const handleActivityTypeUpdate = (value: string) => {
        updateContactOverviewActivityMutation({
            activityId: activityId,
            tag: value,
        })
            .unwrap()
            .then(() => {
                disaptch(setSuccessNotification("Activity tag updated successfully"));
            })
            .catch(() => {
                disaptch(setSuccessNotification("Error while updating activity tag"));
            });
    };

    const handleMenuItemClick = (value: string) => {
        const tagValue = value;
        handleClose();
        setTag(tagValue);
        handleActivityTypeUpdate(tagValue);
    };

    return (
        <>
            {/* @ts-ignore */}
            <Chip
                icon={<FiberManualRecordIcon fontSize="small" />}
                variant="filled"
                label={tag}
                onClick={handleClick}
                size="small"
                sx={{
                    flexDirection: "row",
                    fontSize: "12px",
                    width: "fit-content",
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
            />
            <Menu sx={menuSX} anchorEl={anchorEl} open={open} onClose={handleClose}>
                {data.map((i) => {
                    if (!i) {
                        return null;
                    }

                    return (
                        <MenuItem
                            key={i}
                            onClick={() => handleMenuItemClick(i)}
                            sx={{
                                "&.MuiMenuItem-root": {
                                    fontSize: "12px",
                                },
                            }}
                        >
                            {i}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
