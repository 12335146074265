import FormControl from "@mui/material/FormControl";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormLabel, { FormLabelProps } from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import { ReactNode } from "react";

export type RadioOptionType = {
    label: JSX.Element | ReactNode;
    value: string;
} & Omit<FormControlLabelProps, "control">;

export interface RadioButtonsGroupProps extends RadioGroupProps {
    label: string;
    labelProps?: FormLabelProps;
    options: RadioOptionType[];
}

export default function RadioButtonsGroup(props: RadioButtonsGroupProps) {
    const { options, label, labelProps, ...rest } = props;

    return (
        <FormControl
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <FormLabel id={`radio-button-group-${label}`} {...labelProps} sx={{ marginRight: "10px", fontSize: "15px", fontWeight: "500" }}>
                {label}
            </FormLabel>
            <RadioGroup aria-labelledby="radio-button-group" {...rest}>
                {options.map(({ value, label, ...rest }, index) => {
                    return (
                        <FormControlLabel
                            key={`radio-button-${index}`}
                            control={<Radio />}
                            value={value}
                            label={label}
                            {...rest}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
}
