import FeedbackIcon from "@mui/icons-material/Feedback"; // write feedback
import RateReviewIcon from "@mui/icons-material/RateReview"; // feedback Done color green
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import { Box, Checkbox, Dialog, FormControlLabel, FormGroup, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import style from "./feedbackModule.module.scss";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { JoyProvider } from "../../../../components/JoyProvider";
import { setErrorNotification, setSuccessNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { addCandidateFeedback } from "../../index.reducer";
import { IAddCandidateFeedbackPayload } from "../../project.types";

type FeedbackFormModalProps = {
    open: boolean;
    onClose: () => void;
    status: any;
    feedbackDone: boolean;
    id: string;
};

function FeedbackFormModal({ open, onClose, status, id }: FeedbackFormModalProps) {
    const dispatch = useDispatch();
    const isLoading = useSelector(checkIfLoading(addCandidateFeedback.type));
    const checkBoxSX = {
        color: "rgba(0, 0, 0, 0.42)",
        margin: 0,
        padding: "2px 6px",
    };

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [comments, setComments] = useState<string>("");

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setSelectedOptions((prev) => (checked ? [...prev, name] : prev.filter((option) => option !== name)));
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComments(event.target.value);
    };
    const showBnanner = () => {
        console.log("Success");
        // message to be shown to user on Submitting feedback
        dispatch(setSuccessNotification("Thank you for submitting feedback"));
        onClose();
    };
    const handleSubmit = () => {
        // Handle form submission
        if (comments.length === 0 && selectedOptions.length === 0) {
            dispatch(setErrorNotification("Atleast one option must be selected"));
            return;
        }
        console.log("Selected Options:", selectedOptions);
        console.log("Comments:", comments);
        const data: IAddCandidateFeedbackPayload = {
            candidateId: id,
            feedback: {
                selectedOptions: selectedOptions,
                comments: comments,
            },
            onSuccess: showBnanner,
        };

        dispatch(addCandidateFeedback(data));
    };

    const handleCancel = () => {
        // Handle form cancellation
        setSelectedOptions([]);
        setComments("");
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            {status === "Shortlisted" && (
                <Box
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        width: 500,
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                    }}
                >
                    <Typography gutterBottom fontSize={14} fontWeight={500}>
                        Feedback
                    </Typography>
                    <Typography gutterBottom fontSize={14} sx={{ paddingTop: 1 }}>
                        Why did you shortlist this candidate?
                    </Typography>
                    <Typography fontSize={12} fontStyle={"italic"}>
                        {" "}
                        select one or more
                    </Typography>

                    <FormGroup>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "rgba(221, 218, 218, 0.21)",
                                borderRadius: 2,
                                padding: 2,
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkBoxSX}
                                        checked={selectedOptions.includes("Current job title is relevant")}
                                        onChange={handleCheckboxChange}
                                        name="Current job title is relevant"
                                    />
                                }
                                label={
                                    <Typography fontSize={12} sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                        Current job title is relevant
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkBoxSX}
                                        checked={selectedOptions.includes("Relevant years of experience")}
                                        onChange={handleCheckboxChange}
                                        name="Relevant years of experience"
                                    />
                                }
                                label={
                                    <Typography fontSize={12} sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                        Relevant years of experience
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkBoxSX}
                                        checked={selectedOptions.includes(
                                            "Company/companies where candidate has worked"
                                        )}
                                        onChange={handleCheckboxChange}
                                        name="Company/companies where candidate has worked"
                                    />
                                }
                                label={
                                    <Typography fontSize={12} sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                        Company/companies where candidate has worked
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkBoxSX}
                                        checked={selectedOptions.includes("skills")}
                                        onChange={handleCheckboxChange}
                                        name="skills"
                                    />
                                }
                                label={
                                    <Typography fontSize={12} sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                        Relevant Skills
                                    </Typography>
                                }
                            />
                        </Box>
                    </FormGroup>

                    <Box sx={{ mt: 2 }}>
                        <Typography fontSize={14} gutterBottom>
                            What stood out for you?
                        </Typography>
                        <TextField
                            inputProps={{ style: { fontSize: "13px" } }}
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            value={comments}
                            onChange={handleTextChange}
                        />
                    </Box>
                    <Typography
                        level="body-sm"
                        sx={{ color: "rgba(0, 0, 0, 0.42)", mt: 2, ml: 1 }}
                        fontSize={12}
                        fontStyle={"italic"}
                    >
                        Help EasySource AI find even better candidates with your feedback
                    </Typography>
                    <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                        <JoyProvider>
                            <Button variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit}>
                                <ButtonTextWithLoading isLoading={isLoading} text="Submit" />
                            </Button>
                        </JoyProvider>
                    </Box>
                </Box>
            )}
            {status === "Rejected" && (
                <Box
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        width: 500,
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                    }}
                >
                    <Typography gutterBottom fontSize={20} fontWeight={500}>
                        Feedback
                    </Typography>
                    <Typography gutterBottom fontSize={16} sx={{ paddingTop: 1 }}>
                        Why did you reject this candidate?
                    </Typography>
                    <Typography fontSize={12} fontStyle={"italic"}>
                        {" "}
                        select one or more
                    </Typography>
                    <FormGroup>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "rgba(221, 218, 218, 0.21)",
                                borderRadius: 2,
                                padding: 2,
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkBoxSX}
                                        checked={selectedOptions.includes("Current job title is not relevant")}
                                        onChange={handleCheckboxChange}
                                        name="Current job title is not relevant"
                                    />
                                }
                                label={
                                    <Typography fontSize={14} sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                        Current job title is not relevant
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkBoxSX}
                                        checked={selectedOptions.includes(
                                            "Too few or too many relevant years of experience"
                                        )}
                                        onChange={handleCheckboxChange}
                                        name="Too few or too many relevant years of experience"
                                    />
                                }
                                label={
                                    <Typography fontSize={14} sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                        Too few or too many relevant years of experience
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkBoxSX}
                                        checked={selectedOptions.includes(
                                            "Current or previous companies of work are not suitable"
                                        )}
                                        onChange={handleCheckboxChange}
                                        name="Current or previous companies of work are not suitable"
                                    />
                                }
                                label={
                                    <Typography fontSize={14} sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                        Current or previous companies of work are not suitable
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={checkBoxSX}
                                        checked={selectedOptions.includes("Skills are not suitable")}
                                        onChange={handleCheckboxChange}
                                        name="Skills are not suitable"
                                    />
                                }
                                label={
                                    <Typography fontSize={14} sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                        Skills are not suitable
                                    </Typography>
                                }
                            />
                        </Box>
                    </FormGroup>

                    <Box sx={{ mt: 2 }}>
                        <Typography fontSize={16} gutterBottom>
                            Why is this candidate unsuitable?
                        </Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            value={comments}
                            onChange={handleTextChange}
                            required={true}
                        />
                    </Box>
                    <Typography
                        level="body-sm"
                        sx={{ color: "rgba(0, 0, 0, 0.42)", mt: 2 }}
                        fontSize={12}
                        fontStyle={"italic"}
                    >
                        Help EasySource AI find even better candidates with your feedback
                    </Typography>
                    <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                        <JoyProvider>
                            <Button variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit}>
                                <ButtonTextWithLoading isLoading={isLoading} text="Submit" />
                            </Button>
                        </JoyProvider>
                    </Box>
                </Box>
            )}
        </Dialog>
    );
}

export default function FeedbackForm({ Reviewstatus, id, candidateFeedback }: any) {
    const [openEditModal, setOpenEditModal] = useState(false);
    const isfeedbackDone = candidateFeedback?.comments?.length > 0 || candidateFeedback?.selectedOptions?.length > 0;
    const feedbackTextHeading = Reviewstatus === "Rejected" ? "What you disliked: " : "What you liked: ";
    const formatSelectedOptions = (options: string[]) => {
        if (!options) return "";
        return options.join(", ");
    };

    const showEditModal = () => {
        setOpenEditModal(true);
    };
    const closeEditModal = () => setOpenEditModal(false);

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {!isfeedbackDone && (Reviewstatus === "Rejected" || Reviewstatus === "Shortlisted") && (
                <div className={style["feedbackIconParent"]}>
                    <div
                        className={style["feedbackIconAnim"]}
                        onClick={showEditModal}
                        style={{ backgroundColor: "#fbfbfb" }}
                    >
                        <FeedbackIcon fontSize="small" />
                        <p> quick feedback?</p>
                    </div>
                    <div
                        className={style["feedbackIconHover"]}
                        onClick={showEditModal}
                        style={{ backgroundColor: "#fbfbfb" }}
                    >
                        <FeedbackIcon fontSize="small" />
                        <p> quick feedback?</p>
                    </div>
                </div>
            )}
            {isfeedbackDone && (
                <div className={style["feedbackdone"]}>
                    <Tooltip
                        sx={{ maxWidth: 300 }}
                        title={
                            <Box>
                                <Typography level="title-lg" fontWeight="lg" mb={1}>
                                    Feedback submitted
                                </Typography>
                                {candidateFeedback.selectedOptions.length > 0 && (
                                    <Typography level="body-sm" mb={1}>
                                        <Box component="span" fontWeight="lg">
                                            {feedbackTextHeading}
                                        </Box>{" "}
                                        {formatSelectedOptions(candidateFeedback.selectedOptions)}
                                    </Typography>
                                )}
                                {candidateFeedback.comments.length > 0 && (
                                    <Typography level="body-sm">
                                        <Box component="span" fontWeight="lg">
                                            Your feedback:
                                        </Box>{" "}
                                        {candidateFeedback.comments}
                                    </Typography>
                                )}
                            </Box>
                        }
                        placement="right-start"
                    >
                        <RateReviewIcon fontSize="small" />
                    </Tooltip>
                </div>
            )}

            {openEditModal && (
                <FeedbackFormModal
                    open={openEditModal}
                    onClose={closeEditModal}
                    status={Reviewstatus}
                    id={id}
                    feedbackDone={isfeedbackDone}
                />
            )}
        </div>
    );
}
