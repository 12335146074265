import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Tooltip } from "@mui/joy";
import Chip from "@mui/joy/Chip";

import { getProjectChipColor } from "../utils";

import { EDashboardStatusLabel } from "@/store/apis/super-inbox/super-inbox.enums";
import { TTags } from "@/store/apis/super-inbox/super-inbox.types";

function getProjectStatus(status: string) {
    switch (status) {
        case "ACTIVE":
            return "Active";
        case "ON_HOLD":
            return "On hold";
        case "CLOSED":
            return "Closed";
        default:
            return "Active";
    }
}

export default function Tags(props: TTags) {
    const firstProject = props?.projects[0];
    const firstTag = props?.tags[0];
    const projectCount = props?.projects?.length;
    const tagCount = props?.tags?.length;

    return (
        <>
            <div className="flex flex-row gap-1 items-center h-[40px]">
                {projectCount > 1 ? (
                    <>
                        {firstProject && (
                            <Tooltip
                                arrow
                                placement="bottom"
                                variant="outlined"
                                sx={{ marginTop: "3px" }}
                                title={
                                    <div>
                                        <div style={{ marginBottom: "5px" }}>
                                            <strong style={{ color: "#00599a", fontSize: "13px" }}>Projects</strong>
                                        </div>
                                        {props?.projects?.map((project) => (
                                            <div key={project?._id} style={{ fontSize: "11.5px", color: "black" }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginBottom: "3px",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p style={{ fontWeight: "500", marginRight: "5px" }}>
                                                        {project?.name}
                                                    </p>
                                                    <Tooltip
                                                        arrow
                                                        placement="left"
                                                        title={`Project status: ${getProjectStatus(project?.dashboardStatus)} `}
                                                        sx={{ fontSize: "11px" }}
                                                    >
                                                        <Chip
                                                            variant="solid"
                                                            size="sm"
                                                            color={getProjectChipColor(project?.dashboardStatus)}
                                                        >
                                                            {EDashboardStatusLabel[project?.dashboardStatus]}
                                                        </Chip>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            >
                                <Chip
                                    size="sm"
                                    key={firstProject?._id}
                                    color={getProjectChipColor(firstProject?.dashboardStatus as any)}
                                >
                                    Multiple projects
                                    <ArrowDropDownIcon fontSize="small" />
                                </Chip>
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <>
                        {firstProject && (
                            <Chip
                                size="sm"
                                key={firstProject?._id}
                                color={getProjectChipColor(firstProject?.dashboardStatus as any)}
                            >
                                {firstProject?.name}
                            </Chip>
                        )}
                    </>
                )}

                {tagCount > 1 ? (
                    <>
                        {firstTag && (
                            <Tooltip
                                arrow
                                placement="bottom"
                                variant="outlined"
                                sx={{ marginTop: "3px" }}
                                title={
                                    <div>
                                        <div style={{ marginBottom: "5px" }}>
                                            <strong style={{ color: "#00599a", fontSize: "13px" }}>Tags</strong>
                                        </div>
                                        {props?.tags?.map((tag, id) => (
                                            <div key={id} style={{ fontSize: "11.5px", color: "black" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <p style={{ fontWeight: "500" }}>{tag}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            >
                                <Chip size="sm" color="primary">
                                    Multiple tags
                                    <ArrowDropDownIcon fontSize="small" />
                                </Chip>
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <>
                        {firstTag && (
                            <Chip size="sm" color="primary">
                                {firstTag}
                            </Chip>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
