import Sheet from "@mui/joy/Sheet";

import ChatsPane from "./chats/ChatsPane";
import {Filters} from "./filters/Filters";
import MessagesPane from "./messages/MessagesPane";

export function Inbox() {
    return (
        <Sheet
            sx={{
                border: "1px solid",
                borderColor: "divider",
                flex: 1,
                width: "100%",
                mx: "auto",
                pt: 0,
                display: "grid",
                gridTemplateColumns: {
                    xs: "1fr",
                    sm: "minmax(min-content, min(400px, 400px)) 1fr",
                },
            }}
        >
            <Sheet
                sx={{
                    display: { xs: "none", sm: "block" },
                    borderRight: "1px solid",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                    position: "absolute",
                    // transition: "transform 0.4s, width 0.4s",
                    // transform: "translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))",
                    zIndex: "calc(11 * (var(--FiltersPane-slideIn, 0)))",
                    width: "calc(240px * (var(--FiltersPane-slideIn, 0)))",
                }}
            >
                <Filters />
            </Sheet>

            <Sheet
                sx={{
                    position: { xs: "fixed", sm: "sticky" },
                    transform: {
                        xs: "translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))",
                        sm: "none",
                    },
                    transition: "transform 0.4s, width 0.4s",
                    zIndex: 10,
                    width: "100%",
                    // top: 52,
                }}
            >
                <ChatsPane />
            </Sheet>
            <MessagesPane />
        </Sheet>
    );
}
