function formatRecord(args: any) {
    if (Array.isArray(args)) {
        if (args.every((item) => typeof item === "string") || args.every((item) => typeof item === "number")) {
            // Sort strings in ascending order
            return [...args].sort();
        } else if (args.every((item) => typeof item === "object" && !Array.isArray(item))) {
            // Sort objects by keys in ascending order and apply the same to their values
            return args.map((obj) => formatRecord(obj));
        }
    } else if (args && typeof args === "object" && !Array.isArray(args)) {
        // Sort object keys in ascending order and apply the same to their values
        return Object.keys(args)
            .sort()
            .reduce((sortedObj, key) => {
                sortedObj[key] = formatRecord(args[key]);
                return sortedObj;
            }, {});
    }
    return args;
}

async function generateCryptoHashId(args: any): Promise<string> {
    const sortedFilters = formatRecord(args);
    const str = JSON.stringify(sortedFilters);
    return new Promise((resolve) => {
        crypto.subtle.digest("SHA-256", new TextEncoder().encode(str)).then((hashBuffer) => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
            resolve(hashHex);
            return hashHex;
        });
    });
}

export { generateCryptoHashId };
