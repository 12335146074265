import { forwardRef, MutableRefObject, useMemo, useRef, type ForwardedRef } from "react";
import ReactQuill, { Quill, ReactQuillProps } from "react-quill";

import "react-quill/dist/quill.snow.css";

import { useSelector } from "react-redux";

import { defaultFonts, defaultSizes, defaultToolbarContainer } from "../../configs/editorConfig";
import { useQuillImageModule } from "../../hooks/useQuillImageModule";
import useTooltipOnHighlightQuill from "../../hooks/useTooltipOnHighlightQuill";
import { checkOutreachVersion } from "../../pages/Signin/Signin.reducer";
import { applyDoubleCurlyFormat } from "./utils/applyDoubleCurlyFormat";

const Size = Quill.import("formats/size");
const Font = Quill.import("formats/font");

Size.whitelist = defaultSizes;
Font.whitelist = defaultFonts;

Quill.register(Size, true);
Quill.register(Font, true);

function renderModuleHook(moduleHook: typeof useQuillImageModule, isEnabled: boolean) {
    const mockModuleHook = () => ({
        modules: {
            toolbar: {
                container: [],
            },
        },
    });

    if (isEnabled) {
        return moduleHook;
    }
    return mockModuleHook;
}

interface RichTextEditorProps extends ReactQuillProps {
    enableImageModule?: boolean;
}

function RichTextEditor(props: RichTextEditorProps, ref: ForwardedRef<ReactQuill>) {
    const outReachVersion = useSelector(checkOutreachVersion);
    const { enableImageModule = false, theme = "snow", ...rest } = props;
    const editorRef = useRef(null) as MutableRefObject<ReactQuill | null>;
    const {
        modules: { toolbar: imageToolbar, ...imageModules },
    } = renderModuleHook(useQuillImageModule, enableImageModule)(editorRef);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [...defaultToolbarContainer, ...imageToolbar.container],
            },
            ...imageModules,
        }),
        []
    );
    useTooltipOnHighlightQuill({
        editorRef: editorRef,
    });

    const setRefs = (element: ReactQuill) => {
        if (element) {
            if (ref) (ref as { current: ReactQuill }).current = element;
            editorRef.current = element;
            applyDoubleCurlyFormat(editorRef as MutableRefObject<ReactQuill>, outReachVersion === "v2");
        }
    };

    const memoisedEditor = useMemo(() => {
        return <ReactQuill theme={theme} modules={modules} ref={setRefs} {...rest} />;
    }, [theme, modules, setRefs]);

    return memoisedEditor;
}

export default forwardRef<ReactQuill, RichTextEditorProps>(RichTextEditor);
