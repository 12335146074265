import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import { CancelSagas } from "../../../../utils/saga.utils";
import { FetchEventsPayload, setEvents } from "./upComingEvents.slice";
import { takeLatest } from "redux-saga/effects";
import { fetchEvents, cancelActions } from "./upComingEvents.slice";
import { startAction, stopAction } from "../../../../store/reducers/loaders.reducer";
import API from "../../../../utils/API";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { setModal } from "../../../../store/reducers/modals.slice";
import handleException from "../../../../utils/sentry";

function* fetchEventsSaga(action: PayloadAction<FetchEventsPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const payload = action.payload;
        const { id } = payload;
        let url = "/calendly/getCalendlyMeetings";
        if (id) {
            url += `?projectId=${id}`;
        }

        const response = yield call(new API().get, url);
        if (response.success === true) {
            yield put(setEvents(response.data));
        }
    } catch (error) {
        handleException(error);
        console.error("**error while fetching events", { error });
        yield put(setModal({ key: "showUpcomingEventsModal", value: false }));
        yield put(setErrorNotification("Error while fetching events. Please try again later."));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchEvents.type, fetchEventsSaga),
    ];
    // @ts-ignore
    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
