import { useSelector } from "react-redux";

import TextLoading from "../../../components/TextLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { fetchContacts, selectAllContactsTotalCount } from "../all-contacts.slice";

export default function FetchLoadingWithData() {
    const isFetchingContacts = useSelector(checkIfLoading(fetchContacts.type));
    const rowsCount = useSelector(selectAllContactsTotalCount);

    if (isFetchingContacts && rowsCount) {
        return <TextLoading text="Loading..." />;
    }

    return null;
}
