import { combineReducers } from "redux";
import { withReduxStateSync } from "redux-state-sync";

import CompanyPageReducer from "./companyPage/CompanyPage.reducer";
import { contactListSlice } from "./contact-list/contact-list.slice";
import contactListItemsSlice from "./contact-list-items/contact-list-items.slice";
import cookiesInputReducer from "./cookiesInput.slice";
import CreateProject from "./create-project/CreateProject.reducer";
import passwordsReducer from "./forgotPassword.reducer";
import leadRatingReducer from "./lead-rating/LeadRating.slice";
import linkedinScraping from "./linkedin-scraping/linkedin-scraping.slice";
import loadersReducer from "./loaders.reducer";
import MarketingPageReducer from "./marketing-page/MarketingPage.reducer";
import messageSlice from "./message/message.slice";
import modalSlice from "./modals.slice";
import searchReducer from "./search/search.slice";
import searchCandidatesReducer from "./searchCandidates/searchCandidates.slice";
import slackReducer from "./slackSend.reducer";
import stepsReducer from "./steps.slice";
import workflowUploadSlice from "./workflow-upload.slice";

import { allCandidatesApi } from "../apis/all-candidates/all-candidates.api";
import allCandidatesReachoutSlice from "../apis/all-candidates-reachout/all-candidates-reachout.slice";
import allContactsSlice from "../apis/all-contacts/all-contacts.slice";
import { analyticsApi } from "../apis/analytics/analytics.api";
import { autoPilotApi } from "../apis/autopilot/autopilot.api";
import { chatbotApi } from "../apis/chatbot/chatbot.api";
import { companiesApi } from "../apis/companies/companies.api";
import { externalConnectApis } from "../apis/external-connect/external-connect.api";
import { invitationsApi } from "../apis/invitations/invitations.api";
import { linkedinWorkflowApi } from "../apis/linkedin-workflow/linkedin-workflow.api";
import { notificationApi } from "../apis/notification/notification.api";
import { organizationsApi } from "../apis/organizations/organizations.api";
import { outreachApi } from "../apis/outreach/outreach.api";
import { projectsApi } from "../apis/projects/projects.api";
import { searchApi } from "../apis/search/search.api";
import { superInboxApi } from "../apis/super-inbox/super-inbox.api";
import { userCreditApi } from "../apis/user-credit/user-credit.api";
import allCandidatesReducer from "../reducers/all-candidates/all-candidates.slice";
import AllProjects from "../reducers/allProjects/allProjects.reducer";
import AppContainerReducer from "../reducers/app-container/AppContainer.reducer";
import AdvancedFilterCandidates from "../reducers/candidates/Candidates.reducer";
import HcChatHistory from "../reducers/chat-history/ChatHistory.reducer";
import contactOverviewActivities from "../reducers/contact-overview-activities/contact-overview-activities.slice";
import ContractTracking from "../reducers/contract-tracking/contractTracking.reducers";
import Crons from "../reducers/crons/crons.reducer";
import editAutoSearchSlice from "../reducers/edit-auto-search/editAutoSearch.slice";
import exclusionListReducer from "../reducers/exclusion-list/ExclusionList.reducer";
import exportCandidatesReducer from "../reducers/export-candidates/exportCandidates.reducer";
import ExtTrackReducers from "../reducers/extension-tracking/extensionTrack.reducers";
import FeatureList from "../reducers/feature-list/feature.reducers";
import AdvancedFilters from "../reducers/filters/Filters.reducer";
import HiringManager from "../reducers/hiring-manager/hiringManager.reducer";
import integrationsReducer from "../reducers/integrations/integrations.reducer";
import InterActiveWalkthorugh from "../reducers/interactive-walkthrough/interActiveWalkthorughModal.reducer";
import InternalRB2B from "../reducers/internalRB2B/internalRB2B.reducer";
import mailAlertReducers from "../reducers/mail-alert/mailAlert.reducers";
import teamsReducer from "../reducers/manage-teams/manageTeams.reducers";
import myAccountReducer from "../reducers/my-account/myAccount.reducer";
import Notification from "../reducers/notification/notification.reducer";
import NudgesReducer from "../reducers/nudges/Nudges.reducer";
import OrgList from "../reducers/org-list/orgList.reducers";
import outreachReducer from "../reducers/outreach/outreach.slice";
import IProject from "../reducers/project/project.reducer";
import Signin from "../reducers/signin/Signin.reducer";
import SuperAdmin from "../reducers/super-admin/superAdmin.reducer";
import SupportReducer from "../reducers/support/Support.reducer";
import Template from "../reducers/template/template.reducer";
import TourContainerReducer from "../reducers/tour-container/TourContainer.reducer";
import customTemplatesReducer from "../reducers/trigger-workflow/customTemplates.slice";
import personalizedWorkflowReducer from "../reducers/trigger-workflow/personalizedWorkflow.slice";
import standardWorkflowSlice from "../reducers/trigger-workflow/standardWorkflow.slice";
import unsubscribeEmailReducer from "../reducers/unsubscribe/unsubscribe.reducer";
import upComingEventsReducer from "../reducers/upcoming-events/upComingEvents.slice";
import HcUserList from "../reducers/user-list/UserList.reducers";
import UserList from "../reducers/userList/userList.reducers";
import vettingCriteriaReducer from "../reducers/vetting-criteria/vettingCriteria.slice";
import Workflow from "../reducers/workflow/workflow.reducer";

export default withReduxStateSync(
    combineReducers({
        signin: Signin,
        allProjects: AllProjects,
        project: IProject,
        template: Template,
        workflow: Workflow,
        notification: Notification,
        teams: teamsReducer,
        loaders: loadersReducer,
        forgotPassword: passwordsReducer,
        appContainerReducer: AppContainerReducer,
        integrations: integrationsReducer,
        unsubscribeEmail: unsubscribeEmailReducer,
        orgList: OrgList,
        crons: Crons,
        internalRB2B: InternalRB2B,
        userList: UserList,
        featureList: FeatureList,
        hiringManager: HiringManager,
        contractTracking: ContractTracking,
        tour: TourContainerReducer,
        myAccount: myAccountReducer,
        extensionTrack: ExtTrackReducers,
        interActiveWalkthorugh: InterActiveWalkthorugh,
        superAdmin: SuperAdmin,
        nudges: NudgesReducer,
        slackSlice: slackReducer,
        personalizedWorkflow: personalizedWorkflowReducer,
        mailAlert: mailAlertReducers,
        hcUserList: HcUserList,
        hcChatHistory: HcChatHistory,
        advancedFilters: AdvancedFilters,
        advancedFilterCandidates: AdvancedFilterCandidates,
        createProject: CreateProject,
        workflowUpload: workflowUploadSlice,
        vettingCriteria: vettingCriteriaReducer,
        standardWorkflow: standardWorkflowSlice,
        exclusionList: exclusionListReducer,
        cookiesInput: cookiesInputReducer,
        steps: stepsReducer,
        exportCandidates: exportCandidatesReducer,
        companyPageReducer: CompanyPageReducer,
        marketingPageReducer: MarketingPageReducer,
        customTemplates: customTemplatesReducer,
        modals: modalSlice,
        contactList: contactListSlice,
        contactListItems: contactListItemsSlice,
        allContacts: allContactsSlice,
        // analytics: Analytics,
        editAutoSearch: editAutoSearchSlice,
        support: SupportReducer,
        search: searchReducer,
        searchCandidates: searchCandidatesReducer,
        allCandidatesReachout: allCandidatesReachoutSlice,
        allCandidates: allCandidatesReducer,
        outreach: outreachReducer,
        [outreachApi.reducerPath]: outreachApi.reducer,
        [companiesApi.reducerPath]: companiesApi.reducer,
        [allCandidatesApi.reducerPath]: allCandidatesApi.reducer,
        [externalConnectApis.reducerPath]: externalConnectApis.reducer,
        [projectsApi.reducerPath]: projectsApi.reducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
        [organizationsApi.reducerPath]: organizationsApi.reducer,
        [analyticsApi.reducerPath]: analyticsApi.reducer,
        [superInboxApi.reducerPath]: superInboxApi.reducer,
        [linkedinWorkflowApi.reducerPath]: linkedinWorkflowApi.reducer,
        [autoPilotApi.reducerPath]: autoPilotApi.reducer,
        [invitationsApi.reducerPath]: invitationsApi.reducer,
        [userCreditApi.reducerPath]: userCreditApi.reducer,
        [chatbotApi.reducerPath]: chatbotApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        message: messageSlice,
        upcomingEvents: upComingEventsReducer,
        leadRating: leadRatingReducer,
        contactOverviewActivities: contactOverviewActivities,
        linkedinScraping: linkedinScraping,
    })
);
