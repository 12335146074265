const commonMappingOptions = [
    { value: "name", label: "Name" },
    { value: "summary", label: "Description" },
    { value: "email", label: "Email" },
    { value: "linkedinprofileurl", label: "LinkedIn profile link" },
    { value: "phone", label: "Phone number" },
    { value: "company", label: "Company Name" },
    { value: "companyurl", label: "Company LinkedIn url" },
    { value: "durationincompany", label: "Duration in company" },
    { value: "title", label: "Job Title" },
    { value: "industry", label: "Industry" },
    { value: "employeecount", label: "Employee Count" },
    { value: "companylocation", label: "Company location" },
];

export const easySourceMappingOptions = [...commonMappingOptions, { value: "location", label: "Candidate location" }];

export const easyGrowthMappingOptions = [
    ...commonMappingOptions,
    { value: "location", label: "Prospect location" },
    { value: "revenue", label: "Company revenue" },
    { value: "funding", label: "Company funding amount" },
];
