import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchContacts, resetAllContacts, selectAllContactsTotalCount } from "./all-contacts.slice";
import AllContactsFilters from "./AllContactFilters";
import AllContactsContent from "./AllContactsContent";

import MUILoader from "../../components/MUILoader";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";

const GridBox = styled(Box)<BoxProps>(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto 1fr",
    padding: "1rem",
    gap: "1rem",
}));

export default function AllContacts() {
    const dispatch = useDispatch();
    const isFetchingContacts = useSelector(checkIfLoading(fetchContacts.type));
    const rowsCount = useSelector(selectAllContactsTotalCount);

    const isLoading = isFetchingContacts && !rowsCount;

    useEffect(() => {
        dispatch(fetchContacts());

        return () => {
            dispatch(resetAllContacts());
        };
    }, []);

    if (isLoading) {
        return <MUILoader />;
    }

    return (
        <GridBox>
            <AllContactsContent />
            <AllContactsFilters />
        </GridBox>
    );
}
