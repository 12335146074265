import { IComponentList } from "../../workflow/workflow.types";

const generateSteps = (componentList: IComponentList[]) => {
    let steps = [];
    for (let val of componentList) {
        let tmp;

        if (val.type === "rem") {
            tmp = {
                eventEnum: 3,
                eventName: "Reminder",
                eventBody: {
                    body: val.editorVal,
                },
            };
        } else if (val.type === "delay") {
            tmp = {
                eventEnum: 2,
                eventName: "Delay",
                eventBody: {
                    hours: val.hours,
                    weeks: val.weeks,
                    days: val.days,
                    mins: val.mins,
                    duration:
                        //@ts-ignore
                        (Number(val.weeks) * 7 * 24 * 60 +
                            //@ts-ignore
                            Number(val.days) * 24 * 60 +
                            //@ts-ignore
                            Number(val.hours) * 60 +
                            //@ts-ignore
                            Number(val.mins)) *
                        1,
                },
            };
        } else if (val.type === "email") {
            tmp = {
                eventEnum: 1,
                eventName: "Email",
                eventBody: {
                    subject: val.subject,
                    body: val.editorVal,
                },
            };
        } else if (val.type === "connection-request") {
            tmp = {
                eventEnum: 4,
                eventName: "connection-request",
                eventBody: {
                    body: val.editorVal,
                },
            };
        } else if (val.type === "linked-in-msg") {
            tmp = {
                eventEnum: 5,
                eventName: "linked-in-msg",
                eventBody: {
                    body: val.editorVal,
                },
            };
        } else if (val.type === "in-mail") {
            tmp = {
                eventEnum: 6,
                eventName: "in-mail",
                eventBody: {
                    subject: val.subject,
                    body: val.editorVal,
                },
            };
        } else if (val.type === "sms") {
            tmp = {
                eventEnum: 11,
                eventName: "sms",
                eventBody: {
                    body: val.editorVal,
                },
            };
        }

        steps.push(tmp);
    }

    return steps;
};

export default generateSteps;
