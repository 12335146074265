import { PayloadAction } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import {
    createNewTemplate,
    editTemplate,
    getChatGptTemplates,
    getTemplateList,
    leaveTemplate,
    setChatGptTemplates,
    setTemplateList,
} from "./index.reducer";
import { ICreateTemplatePayload, IEditTemplatePayload } from "./template.types";

import { setSuccessNotification } from "../../components/Notification/index.reducer";
import { SAMPLE_RECOMMENDATION_TEMPLATES } from "../../constant/Constant";
import { IActionPayload, startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";

function* getTemplateListSaga(): SagaIterator {
    try {
        const response = yield call(new API().get, "/template/get");
        if (isEmpty(response?.data)) return;

        yield put(setTemplateList(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* createNewTemplateSaga(action: PayloadAction<ICreateTemplatePayload>): SagaIterator {
    const { payload, type } = action;

    try {
        yield put(startAction({ action: type }));

        const { onSuccess, ...data } = payload;
        const response = yield call(new API().post, "/template/create/", data);
        if (!response?.data) return;

        if (onSuccess) onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(getTemplateList());
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* editTemplateSaga(action: PayloadAction<IEditTemplatePayload>): SagaIterator {
    const { payload, type } = action;

    try {
        yield put(startAction({ action: type }));

        const { onSuccess, _id, ...data } = payload;

        const response = yield call(new API().put, "/template/edit/" + _id, data);
        if (isEmpty(response)) return;

        if (onSuccess) onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(getTemplateList());
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getChatGptTemplatesSaga({
    payload,
}: {
    payload: { projectId: string; type: string } & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        let response;
        if (payload.projectId === "9999") {
            response = {
                data: SAMPLE_RECOMMENDATION_TEMPLATES,
                message: "Templates fetched successfully",
            };
        } else {
            const queryParams = payload.projectId ? `&projectId=${payload.projectId}` : "";
            const url = `/v2/chat-gpt-templates/get?type=${payload.type}${queryParams}`;
            response = yield call(new API().get, url);
        }

        const responseKey = payload.type === "email" ? "email" : payload.type === "inmail" ? "inmail" : "note";

        if (isEmpty(response.data) || isEmpty(response.data[responseKey])) return;

        yield put(setChatGptTemplates(response.data[responseKey]));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(createNewTemplate.type, createNewTemplateSaga),
        // @ts-ignore
        yield takeLatest(getTemplateList.type, getTemplateListSaga),
        // @ts-ignore
        yield takeLatest(editTemplate.type, editTemplateSaga),
        // @ts-ignore
        yield takeLatest(getChatGptTemplates.type, getChatGptTemplatesSaga),
    ];
    // @ts-ignore
    yield takeLatest(leaveTemplate.type, CancelSagas, tasks);
}
