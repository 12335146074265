import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircularProgress, Typography } from "@mui/joy";
import JoiButton from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import { Button as MaterialButton } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { z } from "zod";

import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { useAppDispatch } from "../../../../store";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { selectUser, updateUser } from "../../../Signin/Signin.reducer";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import styles from "../../myAccount.module.scss";

export const profileFormSchema = z.object({
    name: z.string().trim().min(1, { message: "Please enter name." }),
    userEmail: z.string(),
    outboundEmail: z.string(),
    workspaceName: z.string().trim().min(1, { message: "Please enter workspace name." }),
    companyName: z.string(),
    companyUrl: z.string(),
    role: z.union([z.literal(1), z.literal(0)]).optional(),
    linkedinUrl: z.string(),
    signature: z.string().optional(),
});

type ProfileForm = z.infer<typeof profileFormSchema>;

export function EditProfile() {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [exitConfirmation, setExitConfirmation] = useState<boolean>(false);
    const user = useSelector(selectUser);
    const isLoading = useSelector(checkIfLoading(updateUser.type));
    const dispatch = useAppDispatch();

    const { control, handleSubmit, reset } = useForm<ProfileForm>({
        resolver: zodResolver(profileFormSchema),
        values: {
            name: user.name ?? "",
            userEmail: user.email ?? "",
            outboundEmail: user.authorizedEmailAddress ?? "",
            workspaceName: user.orgData?.name ?? "",
            companyName: user.companyName ?? "",
            companyUrl: user.companyLink ?? "",
            role: user?.roleType === "ADMIN" ? 0 : 1,
            linkedinUrl: user?.userLiProfile?.profileUrl ?? "",
            signature: user?.signature ?? "",
        },
    });

    const changeMode = () => setIsEditMode(false);
    const onSubmit: SubmitHandler<ProfileForm> = (data) => {
        const { companyName, companyUrl, linkedinUrl, name, outboundEmail, userEmail, workspaceName, role, signature } =
            data;
        dispatch(
            updateUser({
                name,
                orgName: workspaceName,
                companyName,
                companyLink: companyUrl,
                linkedinUrl,
                authorizeEmailAddress: outboundEmail,
                email: userEmail,
                roleType: role === 0 ? "ADMIN" : "MEMBER",
                action: updateUser.type,
                signature,
                onSuccess: changeMode,
            })
        );
    };

    return (
        <>
            <JoyProvider>
                <Card variant="outlined" component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Stack
                        direction="row"
                        display={"grid"}
                        gridTemplateColumns={{ base: "1fr", md: "repeat(2,1fr)" }}
                        spacing={1.5}
                        sx={{
                            maxHeight: "calc(100vh - 250px)",
                            overflow: "scroll",
                        }}
                    >
                        <Stack>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Name</FormLabel>
                                            <Input size="sm" {...field} />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="userEmail"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>User Email</FormLabel>
                                            <Input size="sm" {...field} />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="outboundEmail"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Outbound Email</FormLabel>
                                            <Input size="sm" {...field} />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="workspaceName"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Workspace Name</FormLabel>
                                            <Input size="sm" {...field} />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="companyName"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Company Name</FormLabel>
                                            <Input size="sm" {...field} />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="companyUrl"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Company URL</FormLabel>
                                            <Input size="sm" {...field} />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="linkedinUrl"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Linkedin URL</FormLabel>
                                            <Input size="sm" {...field} />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                        </Stack>
                        <Stack>
                            <Controller
                                control={control}
                                name="role"
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& .css-p41fjr-JoySelect-root.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>User Role</FormLabel>
                                            <Select placeholder="Select a role" sx={{ minWidth: 200 }} {...field}>
                                                <Option value={0}>Admin</Option>
                                                <Option value={1}>Member</Option>
                                            </Select>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="signature"
                                render={({ field, fieldState: { error } }) => {
                                    const { value, onChange } = field;
                                    return (
                                        <FormControl
                                            error={!!error?.message}
                                            disabled={!isEditMode}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "#5e5d5d",
                                                },
                                            }}
                                        >
                                            <FormLabel sx={{ color: "#5e5d5d" }}>Signature</FormLabel>
                                            <div>
                                                <RichTextEditor
                                                    enableImageModule
                                                    onChange={(value) => onChange(value)}
                                                    value={value?.replaceAll("\n", "<br>")}
                                                    className={isEditMode ? styles.signatureEditor : styles.disabled}
                                                    readOnly={!isEditMode}
                                                />
                                            </div>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    );
                                }}
                            />
                        </Stack>
                    </Stack>
                    <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
                        <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                            {isEditMode && (
                                <>
                                    <JoiButton
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={() => {
                                            setExitConfirmation(true);
                                        }}
                                    >
                                        Cancel
                                    </JoiButton>
                                    <JoiButton size="sm" variant="solid" type="submit" sx={{ minWidth: "4rem" }}>
                                        {isLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <Typography sx={{ color: "white" }}>Save</Typography>
                                        )}
                                    </JoiButton>
                                </>
                            )}
                            {!isEditMode && (
                                <JoiButton
                                    size="sm"
                                    variant="solid"
                                    type="submit"
                                    onClick={() => {
                                        setIsEditMode(true);
                                    }}
                                >
                                    Edit
                                </JoiButton>
                            )}
                        </CardActions>
                    </CardOverflow>
                </Card>
            </JoyProvider>
            {exitConfirmation && (
                <MaterialProvider>
                    <BaseModal
                        onClose={() => setExitConfirmation(false)}
                        overlayStyles={{
                            padding: "1.7rem 1.5rem",
                            width: 550,
                        }}
                        hideCloseButton={false}
                    >
                        <Typography sx={{ fontSize: "25px", fontWeight: "600", marginBottom: "10px" }}>
                            Going somewhere else?
                        </Typography>

                        <Typography sx={{ fontSize: "14px" }}>
                            You will loose any unsaved changes to your message.
                        </Typography>

                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={4}>
                            <MaterialButton
                                variant="outlined"
                                sx={{ borderRadius: "4px", fontSize: "12px", textTransform: "none" }}
                                onClick={() => {
                                    setExitConfirmation(false);
                                }}
                            >
                                Keep editing
                            </MaterialButton>
                            <MaterialButton
                                variant="contained"
                                sx={{ borderRadius: "4px", fontSize: "12px", border: "none", textTransform: "none" }}
                                onClick={() => {
                                    reset(), setIsEditMode(false), setExitConfirmation(false);
                                }}
                            >
                                Continue
                            </MaterialButton>
                        </Stack>
                    </BaseModal>
                </MaterialProvider>
            )}
        </>
    );
}
