import { Chip, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import AutocompleteWithExclusion from "../molecules/AutocompleteWithExclusion/AutocompleteWithExclusion";
import { checkEasyGrowth } from "../pages/Signin/Signin.reducer";
import { selectJobTitle, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

export function JobTitle({ id }: { id: SearchStateKeys }) {
    const { isLoading, query, setQuery, dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectJobTitle);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const handleChange = (value: typeof values) => {
        dispatch(setValues({ key: id, value }));
    };

    return (
        <AutocompleteWithExclusion
            size="small"
            label={isEasyGrowth ? "Business title" : "Job title"}
            placeholder={isEasyGrowth ? "Business title" : "Job title"}
            freeSolo
            value={values}
            options={Object.keys(options).map((i) => i)}
            onChange={handleChange}
            inputValue={query}
            onInputChange={setQuery}
            loading={isLoading}
            doNotFilter={true}
            autoSave
            renderLoading={() => {
                return (
                    <Chip
                        size="small"
                        color="primary"
                        icon={<CircularProgress size={15} />}
                        label="Loading AI results"
                        sx={{
                            fontSize: 12,
                            px: 1,
                        }}
                    />
                );
            }}
        />
    );
}
