import { useState } from "react";
import { Option, Select } from "@mui/joy";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import CustomButton from "../../../components/CustomButton";
import { JoyProvider } from "../../../components/JoyProvider";
import {
    setSalesNavProfilesCount,
    setTotalCandidates,
    setTotalSearchCount,
} from "../../../pages/project/index.reducer";
import BaseModal from "../../../pages/triggerWorkflow/components/BaseModal";

import {
    addSearchCandidatesToProject,
    selectSupersetCount,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { useProjectData } from "./AddCustomCandidates";


const CANDIDATE_COUNT_MODAL = [200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500];

export function CandidateCountModal({ isModifying, onClose }: { isModifying: boolean; onClose: () => void }) {
    const [selectedCandidateCount, setSelectedCandidateCount] = useState<number>();
    const { projectId, projectName, dispatch, searchId, navigate, candidates } = useProjectData();
    const superSetCount = useSelector(selectSupersetCount);

    if (!searchId || !candidates?.length) return null;

    const onCreate = () => {
        dispatch(setTotalCandidates(0));
        dispatch(setTotalSearchCount(0));
        dispatch(setSalesNavProfilesCount(0));
        dispatch(
            addSearchCandidatesToProject({
                projectId,
                projectName,
                searchId,
                navigate,
                addCandidateCount: selectedCandidateCount,
            })
        );
        onClose();
    };

    return (
        <BaseModal overlayStyles={{ width: "450px" }} onClose={onClose}>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography sx={{ fontSize: "25px" }} variant="h6">
                        {isModifying ? "Modify your campaign" : "Set up your campaign"}
                    </Typography>

                    <Typography sx={{ pt: 2, pb: 1, fontWeight: 500, position: "relative", zIndex: 1000000000000 }}>
                        Add{" "}
                        <JoyProvider>
                            <Select
                                sx={{ minWidth: "100px", display: "inline-flex" }}
                                value={selectedCandidateCount}
                                onChange={(e, newValue) => setSelectedCandidateCount(newValue ?? 200)}
                            >
                                {Number(superSetCount) < 200 ? (
                                    <Option value={superSetCount}>{superSetCount}</Option>
                                ) : (
                                    CANDIDATE_COUNT_MODAL.map((count) => {
                                        if (count > Number(superSetCount)) return;
                                        return (
                                            <Option key={count} value={count}>
                                                {count}
                                            </Option>
                                        );
                                    })
                                )}
                                {/* <Option key="ALL" value={-1}>
                                    Get all
                                </Option> */}
                            </Select>
                        </JoyProvider>{" "}
                        {isModifying ? "new prospects to your campaign" : "prospects to this campaign"}
                    </Typography>
                    <Typography variant="body2">
                        {isModifying
                            ? "EasyGrowth will perform a real-time search powered by AI to add net new prospects, similar to your preview, to this campaign."
                            : "EasyGrowth will perform a real-time search powered by AI to add prospects, similar to your preview, to your new campaign. Change your search criteria if these do not fit your ICP."}
                    </Typography>
                </Stack>

                <Stack gap={1.3} justifyContent={"flex-end"} flexDirection="row" mt={1}>
                    <CustomButton
                        onClick={onCreate}
                        disabled={!selectedCandidateCount}
                        variant="contained"
                        sx={{
                            width: "fit-content",
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                        }}
                    >
                        {projectId ? "Add" : "Create"}
                    </CustomButton>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
