import { useState } from "react";

const read = (key: string) => {
    if (typeof window === "undefined") return null;
    try {
        const item = window.sessionStorage.getItem(key);
        return item && (item !== undefined || item !== "undefined") ? JSON.parse(item) : null;
    } catch (error) {
        console.warn(`Error reading sessionStorage key “${key}”:`, error); // eslint-disable-line no-console
        return null;
    }
};

const set = (key: string, value: any) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
};

const remove = (key: string) => {
    window.sessionStorage.removeItem(key);
};

const initiate = (key: string, value: any) => {
    const stored = read(key);
    if (stored) return stored;
    set(key, value);
    return value;
};

export const getSessionStorage = {
    read,
    set,
    remove,
    initiate,
};

export default function useSessionStorage(key: string, initialValue: any) {
    const [storedValue, setStoredValue] = useState<typeof initialValue>(initiate(key, initialValue));

    const setValue = (value: any) => {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        set(key, valueToStore);
        setStoredValue(valueToStore);
    };

    return [storedValue, setValue]; // optional (readValue, removeValue)
}
