import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

import LogoSvg from "../../assets/img/hqlogo.png";
import { AuthorizeLinkedIn } from "../../components/AuthorizeLinkedin/AuthorizeLinkedin";
import { AuthorizeLinkedinChecker } from "../../components/AuthorizeLinkedinChecker";
import Chatbot from "../../components/Chatbot/Chatbot";
import { selectCanHideWalkThrough } from "../../components/InterActiveWalkthroughModal/interActiveWalkthorughModal.reducer";
import Loader from "../../components/Loader/Loader";
import MailAlert from "../../components/MailAlert/MailAlert";
import { selectAuthorizeEmail } from "../../components/MailAlert/mailAlert.reducers";
import MailAuthModal from "../../components/MailAuthModal/MailAuthModal";
import Sidebar, { SidebarProps } from "../../components/Sidebar/Sidebar";
import { useMinimizeSidebar } from "../../components/Sidebar/SidebarItem";
import { useIsMobile } from "../../hooks/useDetectDevice";
import useVerifyUser from "../../hooks/useVerifyUser";
import { fetchPendingInvitations, getNotification } from "../../pages/manageTeams/manageTeams.reducers";
import Onboarding from "../../pages/OutReach/components/OutReachTour";
import CallContextProvider from "../../pages/project/components/TwilloModule/TwilloCallContext";
import {
    checkIsLinkedinAuthorized,
    selectFromInvitation,
    selectTokenExpired,
    selectUser,
} from "../../pages/Signin/Signin.reducer";
import TokenExpired from "../../pages/Signin/TokenExpired";
import { selectLinkedAuthorizeModal } from "../../store/reducers/modals.slice";
import { openExtensionLink } from "../../utils/API";
import { HQ_EASYSOURCE_WEBSITE, HQ_EXTENSION_ON_BOARDING, HQ_OPEN_IN_NEW_PAGE_EXTENSION } from "../../utils/Constants";
import styles from "./AppContainer.module.css";

interface AppContainerProps {
    sidebarStyleVariant?: SidebarProps["sidebarStyleVariant"];
    routesWithMobileView: string[];
}

export default function AppContainer({ sidebarStyleVariant, routesWithMobileView }: AppContainerProps) {
    const showLinkedinAuthorizeModal = useSelector(selectLinkedAuthorizeModal);
    const user = useSelector(selectUser);
    const { t } = useTranslation();
    const isTokenExpired = useSelector(selectTokenExpired);
    const openAuthorizeModal = useSelector(selectAuthorizeEmail);
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);
    const [showTour] = useLocalStorageState("outreach-tour");
    const isMobile = useIsMobile();
    const isVerifying = useVerifyUser();
    const { minimize } = useMinimizeSidebar();
    const location = useLocation();
    const fromInvitation = useSelector(selectFromInvitation);
    const dispatch = useDispatch();

    const isOutreachPage = !!matchPath("outreach/:projectId/:outreachId/:candidateId/:stepId/base", location.pathname);
    const showMobileView = !!routesWithMobileView.find((route) => matchPath(route, location.pathname));
    const isMailAlertVisible = (!user.emailAuthorized && !user.isSuperAdmin) || !isLinkedInAuthorized;

    useEffect(() => {
        if (fromInvitation) {
            dispatch(fetchPendingInvitations({ action: fetchPendingInvitations.type }));
            dispatch(getNotification());
        }
    }, [fromInvitation]);

    const sendExtensionEvent = (url: string, type: string) => {
        window.postMessage(
            {
                type: url === openExtensionLink ? HQ_OPEN_IN_NEW_PAGE_EXTENSION : HQ_EXTENSION_ON_BOARDING,
                from: HQ_EASYSOURCE_WEBSITE,
                url,
            },
            "*"
        );
    };

    if (isVerifying) return <Loader />;

    if (isMobile && !showMobileView) {
        return (
            <div className={styles.mobileView}>
                <img src={LogoSvg} className={styles.logo} alt="hirequotient-logo" />
                <div className={styles.text}>
                    {t("PLEASE_TRY_TO_LOGIN_USING")} <br />
                    {t("EASYSOURCE_ONLY_SUPPORTS_WEB_APPLICATION")}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.app}>
            {Boolean(showTour) && isOutreachPage && <Onboarding />}
            <div style={{ display: "flex", height: "100%" }}>
                {!isMobile && <Sidebar sidebarStyleVariant={sidebarStyleVariant} />}
                <div
                    className={`${!minimize ? styles.mainContainer : styles.minimizedMainContainer} ${
                        sidebarStyleVariant === "sharp" ? styles.mainContainerSharp : ""
                    }`}
                >
                    <Suspense fallback={<Loader />}>
                        <CallContextProvider>
                            <Outlet />
                        </CallContextProvider>
                    </Suspense>
                </div>
            </div>
            {isMailAlertVisible && <MailAlert />}
            {isTokenExpired && <TokenExpired open={isTokenExpired} />}
            {openAuthorizeModal && <MailAuthModal />}
            {showLinkedinAuthorizeModal && (
                <AuthorizeLinkedinChecker>
                    <AuthorizeLinkedIn />
                </AuthorizeLinkedinChecker>
            )}
            <Chatbot />
        </div>
    );
}
