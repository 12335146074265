import { OtherHousesOutlined } from "@mui/icons-material";
import AssistantIcon from "@mui/icons-material/Assistant";
import AssistantOutlinedIcon from "@mui/icons-material/AssistantOutlined";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import { Badge, TabPanel, Tooltip } from "@mui/joy";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Tab, { TabProps, tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import JoyTabs from "@mui/joy/Tabs";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import FAQ from "./FAQ";
import Home from "./Home";

import { JoyProvider } from "../../../JoyProvider";
import { useChatbotContext } from "../../Context";
import ActionbotKit from "../ActionbotKit/ActionbotKit";

interface TabsProps { }

function Tabs(_props: TabsProps) {
    const { tabIndex, setTabIndex, chatbot } = useChatbotContext();
    const params = useParams();
    const projectId = params?.id;


    useEffect(() => {
        setTabIndex(0)
    }, [params])

    const iconProps = {
        style: {
            fontSize: "28px",
            color: "black",
        },
    };



    const options = [
        {
            tab: (
                <>
                    <ListItemDecorator>
                        <OtherHousesIcon {...iconProps} />
                    </ListItemDecorator>
                    Home
                </>
            ),
            inactiveTab: (
                <>
                    <ListItemDecorator>
                        <OtherHousesOutlined {...iconProps} />
                    </ListItemDecorator>
                    Home
                </>
            ),
            component: <Home />,
        },
        // {
        //     tab: (
        //         <>
        //             <ListItemDecorator>
        //                 <SmsIcon {...iconProps} />
        //             </ListItemDecorator>
        //             Messages
        //         </>
        //     ),
        //     inactiveTab: (
        //         <>
        //             <ListItemDecorator>
        //                 <SmsOutlinedIcon {...iconProps} />
        //             </ListItemDecorator>
        //             Messages
        //         </>
        //     ),
        //     component: <History />,
        // },
        {
            tab: (
                <>
                    <ListItemDecorator>
                        <HelpIcon {...iconProps} />
                    </ListItemDecorator>
                    FAQs
                </>
            ),
            inactiveTab: (
                <>
                    <ListItemDecorator>
                        <HelpOutlineOutlinedIcon {...iconProps} />
                    </ListItemDecorator>
                    FAQs
                </>
            ),
            component: <FAQ />,
        },
        {
            tab: (
                <>
                    <ListItemDecorator>
                        <Badge badgeContent={"New"} color="primary">
                            <AssistantIcon {...iconProps} />
                        </Badge>
                    </ListItemDecorator>
                    Actions
                </>
            ),
            inactiveTab: (
                <>
                    <ListItemDecorator>
                        <Badge badgeContent={"New"} color="primary">
                            <AssistantOutlinedIcon {...iconProps} />
                        </Badge>
                    </ListItemDecorator>
                    Actions
                </>
            ),
            component: <ActionbotKit />,
            tooltip: "Please choose a project to access this feature.",
        },
    ];

    const activeTabProps: TabProps = {
        color: "primary",
    };

    return (
        <JoyProvider>
            <JoyTabs
                size="lg"
                aria-label="Bottom Navigation"
                value={tabIndex}
                onChange={(event, value) => setTabIndex(value as number)}
                sx={(_theme) => ({
                    borderRadius: 16,
                    mx: "auto",
                    flexDirection: "column-reverse",
                    bgcolor: "#f6fafa",
                    height: "100%",
                    [`& .${tabClasses?.root}`]: {
                        flex: 1,
                        transition: "0.3s",
                        fontWeight: "md",
                        fontSize: "md",
                        [`&:not(.${tabClasses?.selected}):not(:hover)`]: {
                            opacity: 0.7,
                        },
                    },
                    ...(chatbot?.open && { display: "none" }),
                })}
            >
                <TabList variant="plain" size="sm" disableUnderline sx={{ borderRadius: "lg", p: 0 }}>
                    {options?.map((tab, index) => {
                        return projectId === undefined && index === 2 ? (
                            <Tooltip title={tab?.tooltip} key={index}>
                                <span>
                                    <Tab
                                        disabled
                                        disableIndicator
                                        orientation="vertical"
                                        style={{
                                            backgroundColor: "white",
                                            fontSize: "13px",
                                            color: "black",
                                            borderRadius: "0px",
                                            height: "70px",
                                            borderTop: "0.5px solid lightgrey",
                                        }}
                                    >
                                        {tab?.inactiveTab}
                                    </Tab>
                                </span>
                            </Tooltip>
                        ) : (
                            <Tab
                                key={index}
                                disableIndicator
                                orientation="vertical"
                                style={{
                                    backgroundColor: "white",
                                    fontSize: "13px",
                                    color: "black",
                                    borderRadius: "0px",
                                    height: "70px",
                                    borderTop: "0.5px solid lightgrey",
                                }}
                                {...(tabIndex === index && activeTabProps)}
                            >
                                {tabIndex === index ? tab?.tab : tab?.inactiveTab}
                            </Tab>
                        );
                    })}
                </TabList>
                {options?.map((option, index) => {
                    return (
                        <TabPanel
                            sx={{
                                padding: 0,
                                height: "100%",
                            }}
                            value={index}
                            key={index}
                        >
                            {option?.component}
                        </TabPanel>
                    );
                })}
            </JoyTabs>
        </JoyProvider>
    );
}

export default Tabs;
