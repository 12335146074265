import { nanoid } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { EQueries } from "./outreach.enums";
import {
    AddWorkflowRequestPayload,
    AddWorkflowResponse,
    ApplyMimicWritingStylePayload,
    ApplyMimicWritingStyleResponse,
    Attachment,
    CreateOutreachRequestPayload,
    CreateOutreachResponse,
    CreateOutreachResponseData,
    CreatePreviewRequestPayload,
    CreatePreviewResponse,
    CreatePreviewResponseData,
    CreateTemplatePayload,
    DownloadExclusionListAutomaticPayload,
    DownloadExclusionListAutomaticResponse,
    DownloadExclusionListPayload,
    DownloadExclusionListResponse,
    EditTemplatePaylod,
    FetchExclusionListResponeType,
    FetchMimicWritingStylePayload,
    FetchMimicWritingStyleResponse,
    FetchMimicWritingStyleResponseData,
    FetchOrganizationMembersEmailResponse,
    FetchOrganizationMembersEmailResponseData,
    FetchOutreachByIdRequestPayload,
    FetchOutreachByIdResponse,
    FetchOutreachByIdResponseData,
    FetchOutreachCandidatesRequestPayload,
    FetchOutreachResponse,
    FetchOutreachResponseDataWithValue,
    FetchTemplatesResponseData,
    OutreachEventValue,
    SaveTemplatesRequestPayload,
    SaveTemplatesResponse,
    SaveWorkflowTemplatePayload,
    SaveWritingStylesPayload,
    ScheduleOutreachRequestPayload,
    ScheduleOutreachResponse,
    SendWritingStylesPayload,
    TestEmailPayload,
    UploadAttachmentsRequestPayload,
    UploadAttachmentsResponse,
    UploadAttachmentsResponseData,
    UploadExclusionListPayload,
    createOutreachResponseSchema,
    createPreviewResponseSchema,
    fetchOutreachByIdResponseSchema,
    fetchOutreachResponseSchema,
    saveTemplatesResponseSchema,
} from "./outreach.types";

import { fetchBaseQueryWithZod } from "../utils";

const { GET_WARNINGS, FETCH_TEMPLATE_NAMES } = EQueries;
export const outreachApi = createApi({
    reducerPath: "outreachApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [GET_WARNINGS, FETCH_TEMPLATE_NAMES],
    endpoints: (builder) => ({
        createOutreach: builder.mutation<CreateOutreachResponseData, CreateOutreachRequestPayload>({
            query: (payload) => {
                return {
                    url: "/workflow/fetch-outreach",
                    method: "POST",
                    body: payload,
                };
            },
            transformResponse: (response: CreateOutreachResponse) => {
                const { workflowId, workflowSteps = [] } = response.data;

                return {
                    workflowId,
                    workflowSteps,
                };
            },
            extraOptions: {
                dataSchema: createOutreachResponseSchema,
            },
        }),
        fetchOutreachCandidates: builder.query<
            FetchOutreachResponseDataWithValue,
            FetchOutreachCandidatesRequestPayload
        >({
            query: ({ outreachId }) => {
                if (!outreachId || outreachId === "") {
                    console.log("Outreach id not found"); // eslint-disable-line no-console
                    return null;
                }
                return {
                    url: `/hyper-personalization/${outreachId}/candidate-name`,
                };
            },
            transformResponse: (response: FetchOutreachResponse) => {
                const { data } = response;

                const dataWithValue = data.map((i) => ({
                    ...i,
                    id: i._id,
                    value: i._id,
                    label: i.name,
                }));

                return dataWithValue;
            },
            extraOptions: {
                dataSchema: fetchOutreachResponseSchema,
            },
        }),
        createPreview: builder.query<CreatePreviewResponseData, CreatePreviewRequestPayload>({
            query: (payload) => {
                return {
                    url: `/hyper-personalization/preview`,
                    method: "POST",
                    body: payload,
                };
            },
            transformResponse: (response: CreatePreviewResponse) => {
                return response.data;
            },
            extraOptions: {
                dataSchema: createPreviewResponseSchema,
            },
        }),
        saveOutreachTemplate: builder.mutation<OutreachEventValue, SaveTemplatesRequestPayload>({
            query: (payload) => {
                // removed _id for backend, but required after response comes
                const { _id, ...rest } = payload;
                return {
                    url: "/workflow/save-workflow-step",
                    method: "POST",
                    body: { ...rest },
                };
            },
            transformResponse: (response: SaveTemplatesResponse, _, arg) => {
                const { data } = response;

                // make sure to add back _id
                data._id = arg._id;

                return data;
            },
            extraOptions: {
                dataSchema: saveTemplatesResponseSchema,
            },
        }),
        fetchOutreachById: builder.query<FetchOutreachByIdResponseData, FetchOutreachByIdRequestPayload>({
            query: (id) => {
                return {
                    url: `/workflow/${id}/all-stepIds`,
                };
            },
            transformResponse: (response: FetchOutreachByIdResponse) => {
                const { data } = response;

                return data;
            },
            extraOptions: {
                dataSchema: fetchOutreachByIdResponseSchema,
            },
        }),
        fetchOrganizationMembersEmail: builder.query<FetchOrganizationMembersEmailResponseData, void>({
            query: () => {
                return {
                    url: "/organization/virtual-users",
                };
            },
            transformResponse: (response: any) => {
                const  {data}  = response;
                return data;
            },
        }),
        scheduleOutreach: builder.mutation<ScheduleOutreachResponse, ScheduleOutreachRequestPayload>({
            query: (payload) => {
                return {
                    url: "/workflow/schedule-outreach",
                    method: "POST",
                    body: payload,
                    timeout: 120000,
                };
            },
        }),

        sendWritingStyles: builder.mutation<FetchMimicWritingStyleResponse, SendWritingStylesPayload>({
            query: (payload) => {
                return {
                    url: `/hyper-personalization/get-writing-style`,
                    method: "POST",
                    body: payload,
                };
            },
        }),

        saveWritingStyles: builder.mutation<FetchMimicWritingStyleResponseData, SaveWritingStylesPayload>({
            query: (payload) => {
                return {
                    url: "/hyper-personalization/save-writing-style",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        applyWritingStyles: builder.mutation<ApplyMimicWritingStyleResponse, ApplyMimicWritingStylePayload>({
            query: (payload) => {
                return {
                    url: "/hyper-personalization/apply-writing-style",
                    method: "POST",
                    body: payload,
                };
            },
        }),

        fetchWritingStyles: builder.query<FetchMimicWritingStyleResponseData, FetchMimicWritingStylePayload>({
            query: (payload) => {
                return {
                    url: "/hyper-personalization/fetch-samples",
                    method: "POST",
                    body: payload,
                };
            },
            transformResponse: (res: { data: FetchMimicWritingStyleResponseData }) => {
                const { data } = res;
                return data;
            },
        }),

        uploadAttachments: builder.mutation<UploadAttachmentsResponseData, UploadAttachmentsRequestPayload>({
            query: (payload) => {
                const { files, projectId } = payload;
                const requestPayload = new FormData();
                requestPayload.append("projectId", projectId);
                for (const file of files) {
                    requestPayload.append("files", file);
                }
                return {
                    url: "/upload/files",
                    method: "POST",
                    body: requestPayload,
                };
            },
            transformResponse: (res: UploadAttachmentsResponse, _, arg) => {
                const { files, currentOutreachElementId } = arg;
                const attachments: Attachment[] = [];

                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    attachments.push({
                        link: res[i],
                        name: file.name,
                        id: nanoid(),
                    });
                }

                return {
                    currentOutreachElementId,
                    files: attachments,
                };
            },
        }),
        warningTemplatesSave: builder.mutation<any, any>({
            query: (payload) => {
                return {
                    url: "v2/project/show-outreach-warning",
                    method: "POST",
                    body: payload,
                };
            },
            invalidatesTags: [GET_WARNINGS],
        }),
        getWarningStatus: builder.query<any, any>({
            query: (projectId) => {
                return {
                    url: `/v2/project/show-outreach-warning?projectId=${projectId}`,
                    method: "GET",
                };
            },

            transformResponse(res: { data: any }) {
                return res.data;
            },
            providesTags: [GET_WARNINGS],
        }),
        fetchTemplates: builder.query<FetchTemplatesResponseData[], { type?: string }>({
            query: ({ type }) => {
                const queryParams = type ? `?type=${type}` : "";
                return {
                    url: `/template/get${queryParams}`,
                    method: "GET",
                };
            },
            transformResponse: (res: { data: FetchTemplatesResponseData[] }) => {
                const { data } = res;
                return data;
            },
        }),
        createNewTemplate: builder.mutation<void, CreateTemplatePayload>({
            query: (payload) => {
                return {
                    url: "template/create",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        editTemplate: builder.mutation<void, EditTemplatePaylod>({
            query: (payload) => {
                return {
                    url: "template/edit",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        deleteWorkflowStep: builder.mutation<void, { workflowId: string; stepIds: string[] }>({
            query: (payload) => {
                return {
                    url: "workflow/delete-workflow-step",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        addWorkflowStep: builder.mutation<AddWorkflowResponse, AddWorkflowRequestPayload>({
            query: (payload) => {
                return {
                    url: "workflow/get-workflow-step",
                    method: "POST",
                    body: payload,
                };
            },
            transformResponse(response: { data: AddWorkflowResponse }) {
                const { data } = response;
                return data;
            },
        }),
        triggerTestEmail: builder.mutation<void, TestEmailPayload>({
            query: (payload) => {
                return {
                    url: "/send-email/test",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getExclusionList: builder.query<FetchExclusionListResponeType, void>({
            query: () => {
                return {
                    url: "exclusionList/all",
                };
            },
            transformResponse: (response: { data: FetchExclusionListResponeType }) => {
                const { data } = response;
                return data;
            },
        }),

        uploadExclusionList: builder.mutation<void, UploadExclusionListPayload>({
            query: (payload) => {
                return {
                    url: "exclusionList/new",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        downloadExclusionList: builder.mutation<DownloadExclusionListResponse, DownloadExclusionListPayload>({
            query: (payload) => {
                return {
                    url: `exclusionList/exclusion-list-id/`,
                    method: "POST",
                    body: payload,
                };
            },
            transformResponse: (response: { data: DownloadExclusionListResponse }) => {
                return response.data;
            },
        }),
        downloadExclusionListAutomatic: builder.mutation<
            DownloadExclusionListAutomaticResponse,
            DownloadExclusionListAutomaticPayload
        >({
            query: (payload) => {
                return {
                    url: "v2/project/download-exclusion-list",
                    method: "POST",
                    body: payload,
                };
            },
            transformResponse: (response: { data: DownloadExclusionListAutomaticResponse }) => {
                return response.data;
            },
        }),
        fetchTimeRange: builder.query<
            { userId: number | string; startTime: string; endTime: string; totalTime: string },
            { candidateCount: number; isSuperAdminView: boolean; userId: number | string }
        >({
            query: ({ candidateCount, isSuperAdminView, userId }) => {
                if (!userId || !candidateCount) {
                    return null;
                }
                return {
                    url: `/v2/project/recruiter-inmail-time-range?isSuperAdminView=${isSuperAdminView}&userId=${userId}&candidateCount=${candidateCount}`,
                };
            },
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        saveWorkflowTemplate: builder.mutation<void, SaveWorkflowTemplatePayload>({
            query: (payload) => {
                return {
                    url: "/workflow/save-workflow-template",
                    method: "POST",
                    body: payload,
                };
            },
            invalidatesTags: [FETCH_TEMPLATE_NAMES],
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        fetchOutreachMessageModifications: builder.mutation<void, any>({
            query: (payload) => {
                return {
                    url: `/outreach-message-modifications/${payload?.type}`,
                    method: "POST",
                    body: payload?.payload,
                };
            },
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
        fetchTemplateNames: builder.query<any, void>({
            query: () => {
                return {
                    url: `/workflow/get-workflow-template`,
                };
            },
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
            providesTags: [FETCH_TEMPLATE_NAMES],
        }),
        fetchTemplateById: builder.query<any, any>({
            query: ({ id, workflowId, virtualUserId }) => {
                return {
                    url: virtualUserId? `/workflow/${id}/${workflowId}/get-workflow-template?virtualUserId=${virtualUserId}` : `/workflow/${id}/${workflowId}/get-workflow-template`,
                };
            },
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),
    }),
});

export const {
    useCreateOutreachMutation,
    useFetchOutreachCandidatesQuery,
    useFetchTimeRangeQuery,
    useSaveOutreachTemplateMutation,
    useCreatePreviewQuery,
    useFetchOutreachByIdQuery,
    useScheduleOutreachMutation,
    useSendWritingStylesMutation,
    useSaveWritingStylesMutation,
    useApplyWritingStylesMutation,
    useFetchWritingStylesQuery,
    useGetWarningStatusQuery,
    useWarningTemplatesSaveMutation,
    useUploadAttachmentsMutation,
    useFetchTemplatesQuery,
    useCreateNewTemplateMutation,
    useEditTemplateMutation,
    useDeleteWorkflowStepMutation,
    useAddWorkflowStepMutation,
    useTriggerTestEmailMutation,
    useFetchOrganizationMembersEmailQuery,
    useGetExclusionListQuery,
    useUploadExclusionListMutation,
    useDownloadExclusionListMutation,
    useDownloadExclusionListAutomaticMutation,
    useSaveWorkflowTemplateMutation,
    useFetchTemplateNamesQuery,
    useFetchTemplateByIdQuery,
    useLazyFetchTemplateByIdQuery,
    useFetchOutreachMessageModificationsMutation,
} = outreachApi;
