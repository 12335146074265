import { Action, PayloadAction, ThunkAction, ThunkDispatch, createAction, createSlice } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "../../../store";
import { nonPersonalizedTemplate } from "../../../utils/workflowTemplates";
import { IComponentList } from "../../workflow/workflow.types";

export type StandardWorkflowState = {
    componentList: IComponentList[];
};

const initialState: StandardWorkflowState = {
    componentList: nonPersonalizedTemplate as IComponentList[],
};

// export function updateStandardWorkflowThunk(
//     componentList: IComponentList[]
// ): ThunkAction<void, RootState, unknown, Action<string>> {
//     return (dispatch: AppDispatch, getState: () => RootState) => {
//         const state = getState();
//         // You can access the state properties and perform your logic here
//         const { workflow } = state;

//         if (workflow.workflowMode === "REGULAR_WORKFLOW") {
//             dispatch(updateStandardWorkflow(componentList));
//         }
//     };
// }

const standardWorkflowSlice = createSlice({
    name: "standardWorkflow",
    initialState,
    reducers: {
        updateStandardWorkflow(state, action: PayloadAction<IComponentList[]>) {
            state.componentList = action.payload;
        },
        resetStandardWorkflow() {
            return initialState;
        },
    },
});

export default standardWorkflowSlice.reducer;

export const { updateStandardWorkflow, resetStandardWorkflow } = standardWorkflowSlice.actions;

export const selectStandardWorkflowComponents = (state: RootState) => state.standardWorkflow.componentList;
