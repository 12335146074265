import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store";
import { trackingExtType } from "./extensionTracking.type";

const initialState = Object.freeze({
    tracking: [],
    startDate: "",
    userDetails: {
        name: "",
        email: "",
    },
    loading: false,
});

const reducers = {
    setDate(state: any, { payload }: { payload: string }) {
        state.startDate = payload;
    },
    getTracking(
        state: any,
        {
            payload,
        }: {
            payload: {
                userId: string;
                date: string;
            };
        }
    ) {
        state.loading = true;
    },
    setTracking(state: any, { payload }: { payload: trackingExtType[] }) {
        state.tracking = payload;
        state.loading = false;
    },
    setUserDetail(state: any, { payload }: { payload: { name: string; email: string } }) {
        state.userDetails = payload;
    },
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "extensionTrack",
    initialState,
    // @ts-ignore
    reducers,
});

export default page.reducer;
export const { setDate, getTracking, setTracking, setUserDetail, leavePage } = page.actions;

export const selectStartDate = (state: RootState) => state.extensionTrack.startDate;
export const selectTracking = (state: RootState) => state.extensionTrack.tracking;
export const selectLoading = (state: RootState) => state.extensionTrack.loading;
export const selectUserDetails = (state: RootState) => state.extensionTrack.userDetails;
