import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { Dialog, DialogActions, DialogTitle, IconButton, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import VettingCriteriaModal from "./vettingCriteria/VettingCriteria";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { LeadRatingModalData } from "../../../easy-growth/components/LeadRating/LeadRating";
import useMenu from "../../../hooks/useMenu";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

import { selectCurrProject, stopGenerateInsights } from "@/store/reducers/allProjects/allProjects.reducer";
import { refreshCandidateRating, selectProjectCandidates } from "@/store/reducers/project/project.reducer";
import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";
import { Button, Popover, Tooltip } from "@/ui";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { selectIsSuggestionsListEmpty } from "@/store/reducers/vetting-criteria/vettingCriteria.slice";
type GenerateInsightsProps = {
    projectId: string;
    candidateIds: string[];
    selectAll: boolean;
};

export default function GenerateInsights({ projectId, candidateIds, selectAll }: GenerateInsightsProps) {
    const { t } = useTranslation();
    const isEasySource = useSelector(checkEasySource);
    const dispatch = useDispatch();
    const { anchorEl, handleClick, handleClose, menuSX, open } = useMenu();
    const isGeneratingInsights: boolean = useSelector(checkIfLoading(refreshCandidateRating.type));
    const [openModal, setOpenModal] = useState(false);
    const [openSuggestModal, setOpenSuggestModal] = useState(false);
    const [openSuggestedCriteriaModal, setOpenSuggestedCriteriaModal] = useState(false);
    const [openGenerateInsightModal, setOpenGenerateInsightModal] = useState(false);
    const projectData = useSelector(selectCurrProject);
    const candidates = useSelector(selectProjectCandidates);
    const candidateIdsdata = candidates.map((item) => item._id);
    const [allCandidates, setAllCandidates] = useState(false);
    const isInsightsInProgress = projectData?.generateInsightsStatus === "IN_PROGRESS";
    const isStopGenerateInsightsInProgress = useSelector(checkIfLoading(stopGenerateInsights.type));
    const user = useSelector(selectUser);
    const isSuggestionsListEmpty = useSelector(selectIsSuggestionsListEmpty);

    const showModal = () => {
        setOpenModal(true);
        handleClose();
    };
    const closeModal = () => {
        setOpenModal(false);
        closeSuggestedCriteriaModal();
    };

    useEffect(() => {
        if (candidateIds?.length === 0) {
            setAllCandidates(true);
        } else {
            setAllCandidates(false);
        }
    }, [candidateIds]);
    const closeGenerativeModal = () => {
        setOpenGenerateInsightModal(false);
        setOpenSuggestedCriteriaModal(false);
    };
    const openGenerativeModal = () => {
        setOpenGenerateInsightModal(true);
        handleClose();
    };

    const openSuggestCriteriaModal = () => {
        setOpenSuggestModal(true);
        handleClose();
    };
    const closeSuggestCriteriaModal = () => {
        setOpenSuggestModal(false);
    };
    const openSuggestedModal = () => {
        setOpenSuggestedCriteriaModal(true);
        handleClose();
    };
    const closeSuggestedCriteriaModal = () => {
        setOpenSuggestedCriteriaModal(false);
    };

    interface ValueItem {
        label: string;
        onClick: () => void;
        disabled?: boolean;
        tooltip?: string;
        showTooltip?: boolean;
    }

    const values: ValueItem[] = [
        { label: t("projectsPage.editCriteria"), onClick: showModal, disabled: false },
        {
            label: t("projectsPage.aiScreening"),
            onClick: openGenerativeModal,
            disabled: false,
            tooltip: `Generate insights on all ${isEasySource ? "candidates" : "prospects"} in this project`,
            showTooltip: true,
        },
        ...(isEasySource && !isSuggestionsListEmpty
            ? [
                  {
                      label: t("projectsPage.suggestedCriteria"),
                      onClick: openSuggestedModal,
                      disabled: false,
                      tooltip: `No suggestions available at the moment`,
                      showTooltip: false,
                  },
              ]
            : []),
        ...(user?.isSuperAdmin && isEasySource
            ? [
                  {
                      label: t("projectsPage.suggestCriteria"),
                      onClick: openSuggestCriteriaModal,
                      disabled: false,
                  },
              ]
            : []),
    ];

    const handleGenerateInsights = async (type?: boolean) => {
        if (isInsightsInProgress) {
            dispatch(stopGenerateInsights({ projectId, closeModal: () => {} }));
        }
        const checkAndRefresh = () => {
            if (!isStopGenerateInsightsInProgress) {
                dispatch(
                    refreshCandidateRating({
                        action: refreshCandidateRating.type,
                        projectId,
                        candidateIds: allCandidates ? candidateIdsdata : candidateIds,
                        selectAll: allCandidates ? true : selectAll,
                        onSuccess: type ? closeGenerativeModal : closeModal,
                        allCandidates: allCandidates ? true : false,
                    })
                );
            }
        };

        setTimeout(checkAndRefresh, 0);
    };

    return (
        <>
            <Tooltip title="Generate Insights" placement="top">
                <span style={{ cursor: "not-allowed" }}>
                    <IconButton color="primary" onClick={handleClick}>
                        {!isSuggestionsListEmpty && (
                            <span className="absolute inset-0 rounded-full">
                                <span className="animate-pulse absolute inset-0 rounded-full  bg-cyan-100 opacity-5"></span>
                                <span className="animate-ping absolute inset-0 rounded-full border border-cyan-600 opacity-50"></span>
                            </span>
                        )}
                        <AutoAwesomeRoundedIcon className="h-5 w-5 relative z-10" />
                    </IconButton>
                </span>
            </Tooltip>
            <Popover
                anchorEl={anchorEl}
                open={open}
                className="mt-5 ml-[-10px]"
                onClose={handleClose}
                align="start"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {values.map((value, index) => (
                    <Tooltip key={index} title={value?.showTooltip ? value.tooltip : ""} placement="right">
                        <div className="relative flex items-center">
                            {value.label === t("projectsPage.suggestedCriteria") && (
                                <span className="absolute right-1 h-[6px] w-[6px] rounded-full bg-red-600"></span>
                            )}
                            <MenuItem key={index} onClick={value.onClick} disabled={value.disabled}>
                                {value.label}
                            </MenuItem>
                        </div>
                    </Tooltip>
                ))}
            </Popover>
            {openSuggestedCriteriaModal && (
                <VettingCriteriaModal
                    close={closeSuggestedCriteriaModal}
                    variant="SUGGESTED"
                    handleGenerateInsights={handleGenerateInsights}
                />
            )}
            {openSuggestModal && <VettingCriteriaModal close={closeSuggestCriteriaModal} variant="SUGGEST" />}
            {openModal &&
                (isEasySource ? (
                    <VettingCriteriaModal
                        close={closeModal}
                        variant="GENERATE"
                        handleGenerateInsights={handleGenerateInsights}
                        checkIds={Boolean(candidateIds.length)}
                    />
                ) : (
                    <>
                        <LeadRatingModalData onClose={closeModal} />
                    </>
                ))}

            <Dialog
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: 0,
                    },
                }}
                open={openGenerateInsightModal}
                onClose={closeGenerativeModal}
            >
                <>
                    <DialogTitle fontSize={16} fontWeight={500}>
                        Are you sure you want to generate insights?
                    </DialogTitle>
                    <DialogActions>
                        <Button className="outline" onClick={closeGenerativeModal}>
                            Cancel
                        </Button>
                        <Button
                            variant="default"
                            onClick={() => handleGenerateInsights(true)}
                            disabled={isGeneratingInsights}
                        >
                            <ButtonTextWithLoading isLoading={isGeneratingInsights} text="Submit" variant="light" />
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
        </>
    );
}
