import { MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import HiringManagerIcon from "../../../../assets/img/hiring-manager.png";

import {
    addCandidatesToHiringManager,
    getListName,
    selectSelectedList,
    setSelectedList,
} from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { setWarningNotification } from "@/store/reducers/notification/notification.reducer";
import { selectProjectAllCandidates, selectSelectedCandidates } from "@/store/reducers/project/project.reducer";
import { Popover, Tooltip } from "@/ui";

interface ValueItem {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: string;
}

export default function SendToHiringManager() {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectId = params.id;
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        dispatch(
            getListName({
                projectId,
            })
        );
    }, [open, projectId]);

    const selectedList = useSelector(selectSelectedList);
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const selectAll = useSelector(selectProjectAllCandidates);
    const selectedCandidateIds = selectedCandidates?.map((item) => item.candidateId);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleHiringManagerView = () => {
        setOpen(false);
        if (!selectedList?.uuid) {
            dispatch(setWarningNotification("Currently the list is empty. Please add candidates in the list."));
            return;
        }

        navigate(`/view-hiring-manager/${selectedList?.uuid}?isViewOnly=true`);
        dispatch(setSelectedList(null));
    };

    const handleAddToHiringManagerList = () => {
        const payload = {
            candidateIds: selectedCandidateIds,
            name: `Hiring-Manager-List-${projectId}`,
            projectId: projectId,
            selectAll: selectAll,
        };

        dispatch(addCandidatesToHiringManager(payload));
        setOpen(false);
    };

    const values: ValueItem[] = [
        {
            label: "Add to hiring manager list",
            onClick: handleAddToHiringManagerList,
            disabled: selectedCandidates.length === 0,
            tooltip: "Please select candidates to be added to hiring manager list",
        },
        { label: "View hiring manager list", onClick: handleHiringManagerView, disabled: false },
    ];

    return (
        <>
            <Tooltip placement="top" title="Hiring manager">
                <button style={{ borderRadius: "20px" }} className="rounded-full" onClick={handleOpen}>
                    <img src={HiringManagerIcon} alt="HM" style={{ width: 22, height: 22, marginBottom: "-4px" }} />
                </button>
            </Tooltip>

            <Popover
                open={open}
                className="mt-5 ml-[-20px]"
                onClose={handleClose}
                align="start"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {values.map((value, index) => (
                    <Tooltip key={index} title={value.disabled ? value.tooltip : ""} placement="right">
                        <div>
                            <MenuItem key={index} onClick={value.onClick} disabled={value.disabled}>
                                {value.label}
                            </MenuItem>
                        </div>
                    </Tooltip>
                ))}
            </Popover>
        </>
    );
}
