import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RedeemIcon from "@mui/icons-material/Redeem";
import IconButton from "@mui/joy/IconButton";
import JoyTooltip from "@mui/joy/Tooltip";
import { Badge, Box, Experimental_CssVarsProvider } from "@mui/material";
import { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JoyProvider } from "../../../../components/JoyProvider";
import { verifyCountry } from "../../../../utils/verifiyCountry";
import { getSendosoGifts, sentGiftsCandidatesData } from "../../index.reducer";
import SendosoModal from "./SendosoModal";
import { statusWithIcon } from "./statuses";

export default function SendosoGift({ candidate }: { candidate: any }) {
    const dispatch = useDispatch();
    const [openSendosoModal, setOpenSendosoModal] = useState(false);
    const sentGiftsCandidates = useSelector(sentGiftsCandidatesData);
    const [applyCountry, setApplyCountry] = useState(null);
    const country = verifyCountry(candidate?.location);

    const countryData = applyCountry ? applyCountry[candidate?.candidateId] ?? [country?.code] : [country?.code];
    const openSendosoModalWithGifts = async () => {
        dispatch(getSendosoGifts({ ship_to_country_codes: countryData }));
        setOpenSendosoModal(true);
    }
    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: -2,
            top: 20,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 2px',

        },
    }));

    return (
        <div style={{
            position: "relative", display: "flex",

        }} onClick={(e) => e.stopPropagation()}>
            <JoyProvider>
                <Box sx={{ width: "32px", alignContent: "center", borderRadius: "50%" }}>

                    <JoyTooltip title={candidate?.sendosoGiftStatus?.[0]?.status || sentGiftsCandidates?.includes(candidate?.candidateId)
                        ? (candidate?.sendosoGiftStatus?.[0]?.status ?? "Order Placed")
                        : "Send gift via Sendoso"}
                        placement="bottom" size="sm">
                        <div>

                            <IconButton size="sm" color="neutral" disabled={candidate?.sendosoGiftStatus?.[0]?.status || sentGiftsCandidates?.includes(candidate?.candidateId)} onClick={openSendosoModalWithGifts}>
                                {candidate?.sendosoGiftStatus?.[0]?.status || sentGiftsCandidates?.includes(candidate?.candidateId) ?
                                    <Experimental_CssVarsProvider>
                                        <StyledBadge badgeContent={statusWithIcon[candidate?.sendosoGiftStatus?.[0]?.status]?.icon ?? <CheckCircleIcon fontSize="small" sx={{ fontSize: "10px" }} />}
                                            color={statusWithIcon?.[candidate?.sendosoGiftStatus?.[0]?.status]?.color ?? "primary"}
                                        >
                                            <RedeemIcon />
                                        </StyledBadge>
                                    </Experimental_CssVarsProvider>

                                    : <RedeemIcon />}
                            </IconButton>
                        </div>
                    </JoyTooltip>
                </Box>
            </JoyProvider>

            <SendosoModal open={openSendosoModal} onClose={() => setOpenSendosoModal(false)} candidate={candidate} country={country} setApplyCountry={setApplyCountry} applyCountry={applyCountry} />
        </div>
    );

}



