import { Stack, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { ButtonTextWithLoading } from "@/components/ButtonTextWithLoading";
import {
    getCandidatesData,
    selectCandidatesData,
    selectProjectName,
    setPassCode,
} from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { Button, Modal } from "@/ui";

interface PassCodeModalProps {
    open: boolean;
    onClose: () => void;
}

function PassCodeModal({ open, onClose }: PassCodeModalProps) {  
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const uuid = params.id;
    const [inp, setInp] = useState("");
    const isLoading = useSelector(checkIfLoading(getCandidatesData.type));
    const isViewMode = searchParams.get("isViewOnly") === "true";
    const candidates = useSelector(selectCandidatesData);
    const projectName = useSelector(selectProjectName);


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(setPassCode(inp));
        dispatch(
            getCandidatesData({
                uuid: uuid,
            })
        );
    };

    if (isViewMode || projectName.length > 0 || candidates.length > 0) {
        return null;
    }

    return (
        <>
            {open ? (
                <Modal
                    open={open}
                    onClose={onClose}
                    className="bg-white rounded-lg shadow-lg p-6 w-100"
                    BackdropProps={{ style: { backgroundColor: "#eeeff1" } }}
                >
                    <form onSubmit={handleSubmit}>
                    <p style={{ fontSize: "20px", fontWeight: "600", marginBottom: "1rem" }}>Please enter PassCode</p>
                    <TextField value={inp} fullWidth size="small" onChange={(e) => setInp(e.target.value)} />
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ marginTop: "2rem" }}
                    >
                        <span style={{ fontWeight: "400", fontStyle: "italic", color: "darkgrey", fontSize: "10px" }}>
                            Please enter a the passcode that has been shared with you in the invite mail.
                        </span>
                        <Button variant="default" type="submit" style={{ minWidth: "100px" }}>
                            <ButtonTextWithLoading variant={"light"} isLoading={isLoading} text="Submit" />
                        </Button>
                    </Stack>
                    </form>
                </Modal>
            ) : null}
        </>
    );
}

export default PassCodeModal;
