import { cn } from "@/lib/utils";
import { Typography } from "@/ui";

interface NotificationPageCellWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    typographyClasses?: string;
}

function NotificationPageCellWrapper({
    children,
    className = "",
    typographyClasses = "",
    ...rest
}: NotificationPageCellWrapperProps) {
    return (
        <div className={cn("flex item-center justify-start", className)} {...rest}>
            <Typography variant="caption" className={cn("text-left my-auto", typographyClasses)}>
                {children}
            </Typography>
        </div>
    );
}

export default NotificationPageCellWrapper;
