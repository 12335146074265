import "../ChatbotKit/chatbotkit.css";

import { useEffect } from "react";
import Chatbot from "react-chatbot-kit";
import IConfig from "react-chatbot-kit/build/src/interfaces/IConfig"; //eslint-disable-line
import { IMessage } from "react-chatbot-kit/build/src/interfaces/IMessages"; //eslint-disable-line

import ActionProvider from "./ActionProvider";
import defaultConfig, { getConfig } from "./Config";
import { CustomBotChatMsg } from "./customBotChatMsg";
import { CustomUserChatMsg } from "./customUserChatMsg";
import Header from "./Header";
import MessageParser from "./MessageParser";

import { useChatbotContext } from "../../Context";

interface ChatbotProps {
    actionProvider: any;
    messageParser: any;
    config: IConfig;
    headerText?: string;
    placeholderText?: string;
    saveMessages?: (ref: any) => any;
    messageHistory?: IMessage[] | string;
    validator?: (input: string) => boolean;
    runInitialMessagesWithHistory?: boolean;
    disableScrollToBottom?: boolean;
}

interface ChatbotKitProps { }

export default function ActionbotKit(_props: ChatbotKitProps) {
    const { savedMessageState } = useChatbotContext();
    const config = getConfig({
        ...defaultConfig,
        customComponents: {
            header(_props) {
                return <Header />;
            },
            userChatMessage(props: any) {
                return <CustomUserChatMsg {...props} />;
            },
            botChatMessage(props: any) {
                return <CustomBotChatMsg {...props} />;
            },
        },
        initialMessages:
            savedMessageState?.messages?.length > 0 ? savedMessageState?.messages : defaultConfig?.initialMessages,
    });

    useEffect(() => {
        const el: any = document.querySelector(".react-chatbot-kit-chat-message-container");
        if (el?.style) {
            el.style.height = "586px";
        }
    }, []);

    const validator = (input: string) => {
        if (input.trim() === "") {
            return false;
        }
        return true;
    };

    const chatbotProps: ChatbotProps = {
        placeholderText: "Ask something...",
        actionProvider: ActionProvider,
        config,
        messageParser: MessageParser,
        validator,
    };

    return <Chatbot {...chatbotProps} />;
}
