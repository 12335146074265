import VirtualizedSelect from "../../../components/VirtualizedSelect";
import { useFetchOutreachCandidatesQuery } from "../../../store/apis/outreach/outreach.api";
import { useCreatePreview } from "../hooks/useCreatePreview.hook";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

export function CandidatesMenu() {
    const { candidateId: currentCandidateId, navigateToCandidate, outreachId } = useOutreachParams();
    const { isLoading: isCreatingPreview } = useCreatePreview();
    const { data = [], isLoading: isFetchingCandidates } = useFetchOutreachCandidatesQuery({
        outreachId,
    });
    const handleCandidateChange = (value: string) => {
        navigateToCandidate(value);
    };
    const isLoading = isCreatingPreview || isFetchingCandidates;
    const currentCandidateLabel = data?.find((item) => item.id === currentCandidateId)?.label;
    return (
        <VirtualizedSelect
            sx={{
                "& .MuiSelect-select": {
                    paddingLeft: "0.5rem",
                    paddingY: "0.1rem",
                },
                width: "225px",
            }}
            variant="soft"
            options={data?.map((m) => ({ label: m.label, value: m.id.toString(), ...m }))}
            value={currentCandidateId}
            disabled={isLoading}
            onChange={(option) => handleCandidateChange(option.value)}
            enableSearch={true}
            defaultOption={{ label: currentCandidateLabel, value: currentCandidateId.toString() }}
            hideDefaultOption
        />
    );
}
