import { Stack } from "@/ui/Stack/Stack";
import docxIcon from "@/assets/img/docx_icon.svg";
import pdfIcon from "@/assets/img/pdf-icon.svg";
import txtIcon from "@/assets/img/txt-file-icon.svg";
import zipIcon from "@/assets/img/zip-icon.svg";
import { getFileExtension } from "@/store/apis/outreach/outreach.utils";
import TextWithEllipses from "../TextWithEllipses";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface Attachment {
    link: string;
    name: string;
}

interface AttachmentListProps {
    files: any;
    handleRemoveAttachment: (id: string) => void;
}

type TileProps = Attachment & {
    handleRemove: () => void;
};

function Tile({ name, handleRemove, link }: TileProps) {
    const fileType = getFileExtension(link);
    const fileIcon =
        fileType === "pdf"
            ? pdfIcon
            : fileType === "docx" || fileType === "doc"
              ? docxIcon
              : fileType === "zip"
                ? zipIcon
                : txtIcon;
    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={0.7}
            py={0.5}
            px={0.5}
            style={{
                backgroundColor: "#fafafb",
                border: `1px solid lightgrey`,
                borderRadius: "0.3rem",
            }}
        >
            <img src={fileIcon} alt="file" height={20} width={20} />
            <TextWithEllipses charLimit={18} typographyProps={{ variant: "body2", className: "text-[11px]" }}>
                {name}
            </TextWithEllipses>
            <button style={{ padding: 0 }} onClick={handleRemove}>
                <CloseRoundedIcon sx={{ height: 18, width: 18 }} />
            </button>
        </Stack>
    );
}

export function PreviewTile({ files, handleRemoveAttachment }: AttachmentListProps) {
    return (
        <div className="grid grid-cols-3 mt-1 mb-1 gap-2" style={{ width: "fit-content" }}>
            {files?.map((i, index) => {
                return <Tile key={index} handleRemove={() => handleRemoveAttachment(i?.link)} {...i} />;
            })}
        </div>
    );
}
