import { useState } from "react";
import { useDispatch } from "react-redux";
import { useCSVReader, formatFileSize } from "react-papaparse";
import { Stack, Box, Typography, Select, MenuItem } from "@mui/material";
import {
    DEFAULT_REMOVE_HOVER_COLOR,
    styles,
    REMOVE_HOVER_COLOR_LIGHT,
    columnOptions,
} from "../../utils/csv-mapper.helpers";
import { setErrorNotification } from "../../components/Notification/index.reducer";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { ButtonTextWithLoading } from "../../components/ButtonTextWithLoading";
import CustomButton from "../../components/CustomButton";

type CSVHeaderMapperProps = {
    currentValue: string;
    handleClick: (key: string, value: string) => void;
    headerName: string;
};

function CSVHeaderMapper({ currentValue, handleClick, headerName }: CSVHeaderMapperProps) {
    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "1fr",
                alignItems: "center",
                gap: "1rem",
            }}
        >
            <Typography variant="body2">{headerName}</Typography>
            <Select placeholder="Select.." value={currentValue} size="small">
                {columnOptions.map(({ label, value }) => (
                    <MenuItem value={value} onClick={() => handleClick(headerName, value)} key={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
}

export type CSVHeaderMapping = {
    [x: string]: string;
};

type UseMapperModalState = {
    csvHeaderToColMapping: CSVHeaderMapping;
    handleClick: (key: string, value: string) => void;
};

type MapperModalProps = {
    handleModalClick: () => void;
    csvHeaders: string[];
};

function useMapperModalState({ csvHeaders, handleModalClick }: MapperModalProps): UseMapperModalState {
    const [csvHeaderToColMapping, setCsvHeaderToColMapping] = useState<CSVHeaderMapping>(() => {
        const mapping = csvHeaders.reduce((acc: CSVHeaderMapping, key) => {
            const containsHeaderValues = columnOptions.find(({ label }) => label === key);

            if (containsHeaderValues !== undefined) {
                acc[key] = containsHeaderValues.value;
            } else {
                acc[key] = "";
            }

            return acc;
        }, {});

        return mapping;
    });

    const handleClick = (key: string, value: string): void => {
        setCsvHeaderToColMapping((prev) => {
            const newMapping = { ...prev };

            const existingMapping = Object.entries(newMapping).find(
                ([mappedKey, mappedValue]) => mappedValue === value
            );

            if (existingMapping !== undefined) {
                const [existingKey] = existingMapping;
                newMapping[existingKey] = "";
            }

            return { ...newMapping, [key]: value };
        });
    };

    return { csvHeaderToColMapping, handleClick };
}

function MapperModal({
    handleModalClick,
    csvHeaders,
    children,
    isSubmitting,
}: MapperModalProps & {
    children: (csvHeaderToColMapping: CSVHeaderMapping) => React.ReactNode;
    isSubmitting: boolean;
}) {
    const { csvHeaderToColMapping, handleClick } = useMapperModalState({
        csvHeaders,
        handleModalClick,
    });
    return (
        <BaseModal onClose={handleModalClick} overlayStyles={{ maxHeight: "60vh" }} hideCloseButton={isSubmitting}>
            <Typography variant="h6" pb={1}>
                CSV Headers Mapping
            </Typography>
            <Stack sx={{ overflowY: "auto" }} gap={2} py={2}>
                {Object.entries(csvHeaderToColMapping).map(([key, value]) => {
                    return (
                        <CSVHeaderMapper headerName={key} currentValue={value} handleClick={handleClick} key={key} />
                    );
                })}
            </Stack>
            {children(csvHeaderToColMapping)}
        </BaseModal>
    );
}

export type CSVUploadWithHeadersMapperSubmit = {
    file: File;
    csvHeaderMapping: CSVHeaderMapping;
    closeModal?: () => void;
};

export type CSVUploadWithHeadersMapperProps = {
    handleSubmit: (args: CSVUploadWithHeadersMapperSubmit) => void;
    isSubmitting: boolean;
};

export function CSVUploadWithHeadersMapper({ handleSubmit, isSubmitting }: CSVUploadWithHeadersMapperProps) {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
    const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
    const [showMapperModal, setShowMapperModal] = useState(false);

    const handleClick = () => {
        if (selectedFile) {
            setShowMapperModal((prev) => !prev);
        } else {
            dispatch(setErrorNotification("Please select file..."));
        }
    };

    return (
        <>
            <Stack gap={2}>
                <CSVReader
                    onUploadAccepted={(results: any, file: any) => {
                        setSelectedFile(file);
                        setCsvHeaders(results.data[0]);
                        setZoneHover(false);
                    }}
                    onDragOver={(event: DragEvent) => {
                        event.preventDefault();
                        setZoneHover(true);
                    }}
                    onDragLeave={(event: DragEvent) => {
                        event.preventDefault();
                        setZoneHover(false);
                    }}
                    config={{
                        headers: true,
                    }}
                >
                    {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: any) => (
                        <>
                            <div
                                {...getRootProps()}
                                style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}
                            >
                                {acceptedFile ? (
                                    <>
                                        <div style={styles.file}>
                                            <div style={styles.info}>
                                                <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                                                <span style={styles.name}>{acceptedFile.name}</span>
                                            </div>
                                            <div style={styles.progressBar}>
                                                <ProgressBar />
                                            </div>
                                            <div
                                                {...getRemoveFileProps()}
                                                style={styles.remove}
                                                onMouseOver={(event: Event) => {
                                                    event.preventDefault();
                                                    setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                                                }}
                                                onMouseOut={(event: Event) => {
                                                    event.preventDefault();
                                                    setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                                                }}
                                            >
                                                <Remove color={removeHoverColor} />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    "Drop CSV file here or click to upload"
                                )}
                            </div>
                        </>
                    )}
                </CSVReader>
                <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ alignSelf: "flex-end" }}
                    onClick={handleClick}
                    disabled={csvHeaders.length === 0}
                >
                    <ButtonTextWithLoading isLoading={false} text="create" />
                </CustomButton>
            </Stack>
            {showMapperModal && selectedFile ? (
                <MapperModal
                    handleModalClick={() => setShowMapperModal((prev) => !prev)}
                    csvHeaders={csvHeaders}
                    isSubmitting={isSubmitting}
                >
                    {(csvHeaderMapping) => (
                        <CustomButton
                            type="submit"
                            variant="contained"
                            sx={{ alignSelf: "flex-end", marginTop: "1rem" }}
                            onClick={() =>
                                handleSubmit({
                                    file: selectedFile,
                                    csvHeaderMapping,
                                    closeModal: () => setShowMapperModal(false),
                                })
                            }
                            disabled={isSubmitting}
                        >
                            <ButtonTextWithLoading isLoading={isSubmitting} text="create" />
                        </CustomButton>
                    )}
                </MapperModal>
            ) : null}
        </>
    );
}
