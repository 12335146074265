import { PayloadAction, createSelector, createSlice, nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { RootState } from "../../store";
import { externalConnectApis } from "../../store/apis/external-connect/external-connect.api";
import { outreachApi } from "../../store/apis/outreach/outreach.api";
import { INVALID_EMAIL_DAYS_AND_TIME, INVALID_FOLLOW_UP_DAYS_AND_TIME } from "../../store/apis/outreach/outreach.enums";
import {
    BodyWithSubject,
    ChangeCurrentEditor,
    HyperPersonalizationState,
    LinkedinSchema,
    MoveToNextElementPayload,
    SetOutReachElementDaysPayload,
    ToggleCurrentOutreachStep,
    ToggleEditSignatureModal,
    WorkflowStepValue,
    WorkflowSteps,
} from "../../store/apis/outreach/outreach.types";
import {
    extractTextInCurlyBraces,
    modifyTimestampWithDays,
    moveElements,
    validateElement,
} from "../../store/apis/outreach/outreach.utils";
import { outReachEvents } from "../../utils/Constants";
import { objectKeys, objectValues } from "../../utils/helper";
import { checkFollowupEarlier, getFutureTimestampWithSameTime } from "../../utils/outreach.helpers";
import { AccountType } from "../Signin/Signin.types";

export const defaultExcludedDisabledIds = ["CANDIDATES_MARKED_REJECTED", "CANDIDATES_MARKED_NOT_INTERESTED"];

export const defaultExclusionListIds = [
    "CANDIDATES_REACHED_OUT_TO_IN_PAST_24_HRS_IN_THIS_PROJECT",
    "RESPONSE_FROM_CANDIDATE",
    "CANDIDATES_REACHED_OUT_IN_OTHER_PROJECT",
    "CANDIDATES_UNSUBSCRIBES_ACROSS_PROJECTS",
    ...defaultExcludedDisabledIds,
];

const initialState: HyperPersonalizationState = {
    baseTemplates: {},
    baseTemplatesOrder: [],
    isEditSignatureModalOpen: false,
    currentEditor: null,
    isMobileViewOn: false,
    exclusionListIds: [...defaultExclusionListIds],
    customPersonalizedFields: [],
    writingStyles: {},
};

type SetOutReachElementTimePayload = PayloadAction<{
    templateId: string;
    newTimeStamp: string;
    shouldReorder?: boolean;
    shouldMarkUnSave?: boolean;
}>;

function createOutReachElement({
    elementId,
    eventName,
    newOutReachElementTime = dayjs().toISOString(),
    lastOutReachElementDay = 1,
    subject,
    body,
    inMailBody,
    inMailSubject,
    connectionReminderMessageBody,
    inmailFollowupBody,
    inmailFollowupSubject,
    inmailFollowupReminder = "3",
}: {
    elementId: string;
    eventName: WorkflowStepValue["eventName"];
    newOutReachElementTime?: string;
    lastOutReachElementDay?: number;
    subject: string;
    body: string;
    inMailSubject: string;
    inMailBody: string;
    connectionReminderMessageBody: string;
    inmailFollowupBody: string;
    inmailFollowupSubject: string;
    inmailFollowupReminder: string;
}) {
    return {
        _id: elementId,
        eventName,
        eventEnum: outReachEvents[eventName],
        scheduledTime: newOutReachElementTime,
        scheduledDay: lastOutReachElementDay,
        isNew: true,
        isSaved: false,
        errors: {
            body: {},
        },
        eventBody:
            eventName === "SMS"
                ? { body: body }
                : eventName === "linkedin"
                  ? {
                        body: body,
                        connectionReminderMessageBody: connectionReminderMessageBody,
                        inmailFollowupBody: inmailFollowupBody,
                        inmailFollowupSubject: inmailFollowupSubject,
                        inmailFollowupReminder: inmailFollowupReminder,
                        connectionReminderMessageStatus: true,
                        inMailSubject: inMailSubject,
                        inMailBody: inMailBody,
                        inmailFollowupStatus: false,
                        connectionStatus: true,
                        inMailStatus: true,
                    }
                  : {
                        body: body,
                        subject: subject,
                    },
    } as WorkflowStepValue;
}

function sortElements(elements: WorkflowStepValue[]) {
    return elements.sort((a, b) => {
        if (dayjs(a.scheduledDay).isBefore(dayjs(b.scheduledDay))) {
            return -1;
        } else if (dayjs(a.scheduledDay).isAfter(dayjs(b.scheduledDay))) {
            return 1;
        }

        // If days are the same, first compare hours
        const hourA = dayjs(a.scheduledTime).hour();
        const hourB = dayjs(b.scheduledTime).hour();
        if (hourA < hourB) {
            return -1;
        } else if (hourA > hourB) {
            return 1;
        }

        // If hours are the same, then compare minutes
        const minuteA = dayjs(a.scheduledTime).minute();
        const minuteB = dayjs(b.scheduledTime).minute();
        if (minuteA < minuteB) {
            return -1;
        } else if (minuteA > minuteB) {
            return 1;
        }

        return 0;
    });
}

// handle case where there are no emails then adding follow-ups should be disallowed
const outreachState = createSlice({
    name: "outreach",
    initialState,
    reducers: {
        toggleMobileView(
            state,
            action: PayloadAction<{
                currentOutreachElementId: string;
            }>
        ) {
            const { currentOutreachElementId } = action.payload;
            const elementContent = state.baseTemplates?.[currentOutreachElementId];
            if (!elementContent) return;
            if (
                elementContent.eventName === "email" ||
                elementContent.eventName === "follow-up" ||
                elementContent.eventName === "SMS"
            ) {
                state.isMobileViewOn = !state.isMobileViewOn;
            }
        },
        validateOrdering(state) {
            // Constraint: The email must occur before any follow-up.
            // Logic:
            // 1. If the email is found to occur before the first follow-up :
            //    - Add an error to the email if it violates this constraint.
            //    - Otherwise, remove any existing error (ordering) from the email.
            //
            // 2. If any follow-up occurs before the email:
            //    - Add an error to the follow-up if it violates this constraint.
            //    - Otherwise, remove any existing error(ordering) from the follow-up.
            const templates = state.baseTemplates;

            let followupEncountered = false;
            let emailEncountered = false;

            objectKeys(templates).forEach((i) => {
                const template = state.baseTemplates[i];
                if (template.eventName === "follow-up") {
                    followupEncountered = true;
                    if (!emailEncountered) {
                        template.errors = {
                            ...template?.errors,
                            orderingErrors: {
                                ...template?.errors?.["orderingErrors"],
                                EMAIL_FOLLOW_UP_ERROR: INVALID_FOLLOW_UP_DAYS_AND_TIME,
                            },
                        };
                    } else {
                        if (template?.errors?.["orderingErrors"]) {
                            delete template.errors["orderingErrors"];
                        }
                    }
                }

                if (template.eventName === "email") {
                    emailEncountered = true;
                    if (followupEncountered) {
                        template.errors = {
                            ...template?.errors,
                            orderingErrors: {
                                ...template?.errors?.["orderingErrors"],
                                EMAIL_FOLLOW_UP_ERROR: INVALID_EMAIL_DAYS_AND_TIME,
                            },
                        };
                    } else {
                        if (template?.errors?.["orderingErrors"]) {
                            delete template?.errors["orderingErrors"];
                        }
                    }
                }
            });
        },
        changeCurrentEditor(state, action: ChangeCurrentEditor) {
            state.currentEditor = action.payload;
        },
        removeAttachment(
            state,
            action: PayloadAction<{
                currentOutreachElementId: string;
                attachmentId: string;
            }>
        ) {
            const { currentOutreachElementId, attachmentId } = action.payload;
            state.baseTemplates[currentOutreachElementId].attachments = (
                state.baseTemplates[currentOutreachElementId]?.attachments ?? []
            ).filter(({ id }) => id !== attachmentId);
            state.baseTemplates[currentOutreachElementId].isSaved = false;
        },
        // TODO: handle case incase on next email save
        toggleEditSignatureModal(state, action: ToggleEditSignatureModal) {
            state.isEditSignatureModalOpen = action.payload;
        },
        toggleCurrentOutreachStep(state, action: ToggleCurrentOutreachStep) {
            state.currentOutreachStep = action.payload;
        },
        switchToNextElement(state, action: MoveToNextElementPayload) {
            const { currentSelectedTemplateId, moveToOutreachElement } = action.payload;
            const currentTemplateIndex = state.baseTemplatesOrder.indexOf(currentSelectedTemplateId);
            if (currentTemplateIndex !== -1 && currentTemplateIndex + 1 !== state.baseTemplatesOrder.length) {
                moveToOutreachElement(state.baseTemplatesOrder[currentTemplateIndex + 1]);
            }
        },
        setWritingStyleChangePayload(state, payload: any) {
            state.writingStyles = payload?.payload;
        },

        setOutReachElementDays(state, action: SetOutReachElementDaysPayload) {
            const { days, templateId } = action.payload;

            const currentTemplate = state.baseTemplates[templateId];
            currentTemplate.scheduledDay = days;

            currentTemplate.scheduledTime = modifyTimestampWithDays(currentTemplate.scheduledTime, days - 1);
            currentTemplate.isSaved = false;

            // make sure follow-up email should not have a time before time of the email
            const elementType = state.baseTemplates[templateId]?.eventName;
            const emailValues = objectValues(state.baseTemplates)?.find((i) => i?.eventName === "email");
            const firstFollowupValues =
                state.baseTemplates[
                    state.baseTemplatesOrder?.find((i) => state.baseTemplates[i]?.eventName === "follow-up") || ""
                ];

            // compare firstFollowup and email time,
            // if followup have earlier time then add 5 minutes in followup time from email
            if (
                (elementType === "email" || elementType === "follow-up") &&
                emailValues?.scheduledDay === firstFollowupValues?.scheduledDay
            ) {
                if (elementType === "email") {
                    const isFollowupEarlier = checkFollowupEarlier({
                        emailTimestamp: emailValues.scheduledTime,
                        followupEmailTimestamp: firstFollowupValues.scheduledTime,
                    });

                    if (isFollowupEarlier) {
                        firstFollowupValues.scheduledTime = dayjs(emailValues.scheduledTime)
                            .add(5, "minutes")
                            .toISOString();
                        firstFollowupValues.isSaved = false;
                    }
                }

                if (elementType === "follow-up") {
                    const isFollowupEarlier = checkFollowupEarlier({
                        emailTimestamp: emailValues.scheduledTime,
                        followupEmailTimestamp: firstFollowupValues.scheduledTime,
                    });

                    if (isFollowupEarlier) {
                        firstFollowupValues.scheduledTime = dayjs(emailValues.scheduledTime)
                            .add(5, "minutes")
                            .toISOString();
                        firstFollowupValues.isSaved = false;
                    }
                }
            }

            // if current element type is email and it has more latter day than first followup
            // if current element type is follow-up and it has earlier day than email
            // if both has same days then compare time
            if (elementType === "email" && firstFollowupValues?._id) {
                const { scheduledDay } = firstFollowupValues;
                const firstFollowupDays = scheduledDay;
                const emailDays = days;

                // if email and follow-up have same day and then we need to check time
                if (emailDays === firstFollowupDays) {
                    const emailTimestamp = emailValues?.scheduledTime || "";
                    const followupTimestamp = firstFollowupValues.scheduledTime;

                    const emailTime = new Date(emailTimestamp).getTime();
                    const firstFollowupTime = new Date(followupTimestamp).getTime();

                    // if time of first follow-up is earlier than time of the email
                    if (firstFollowupTime <= emailTime) {
                        state.baseTemplates[templateId].errors = {
                            ...state.baseTemplates[templateId]?.errors,
                            orderingErrors: {
                                ...state.baseTemplates[templateId]?.errors?.["orderingErrors"],
                                EMAIL_FOLLOW_UP_ERROR: INVALID_EMAIL_DAYS_AND_TIME,
                            },
                        };
                    } else {
                        delete state.baseTemplates[templateId]?.errors?.orderingErrors;
                    }
                } else if (emailDays > firstFollowupDays) {
                    // if email days is greater than first followup days
                    state.baseTemplates[templateId].errors = {
                        ...state.baseTemplates[templateId]?.errors,
                        orderingErrors: {
                            ...state.baseTemplates[templateId]?.errors?.["orderingErrors"],
                            EMAIL_FOLLOW_UP_ERROR: INVALID_EMAIL_DAYS_AND_TIME,
                        },
                    };
                } else {
                    delete state.baseTemplates[templateId]?.errors?.orderingErrors;
                    delete state.baseTemplates[firstFollowupValues?._id]?.errors?.orderingErrors;
                }
            }

            if (elementType === "follow-up" && emailValues?._id) {
                const emailDays = emailValues.scheduledDay;
                const followupDays = days;

                if (emailDays === followupDays && emailValues?._id) {
                    const emailTimestamp = emailValues.scheduledTime;
                    const followupTimestamp = firstFollowupValues.scheduledTime;

                    const emailTime = new Date(emailTimestamp).getTime();
                    const firstFollowupTime = new Date(followupTimestamp).getTime();

                    // if time of first follow-up is earlier than time of the email
                    if (firstFollowupTime <= emailTime) {
                        state.baseTemplates[templateId].errors = {
                            ...state.baseTemplates[templateId]?.errors,
                            orderingErrors: {
                                ...state.baseTemplates[templateId]?.errors?.["orderingErrors"],
                                EMAIL_FOLLOW_UP_ERROR: INVALID_EMAIL_DAYS_AND_TIME,
                            },
                        };
                    } else {
                        delete state.baseTemplates[templateId].errors?.orderingErrors;
                    }
                } else if (emailDays > followupDays) {
                    state.baseTemplates[templateId].errors = {
                        ...state.baseTemplates[templateId]?.errors,
                        orderingErrors: {
                            ...state.baseTemplates[templateId]?.errors?.["orderingErrors"],
                            EMAIL_FOLLOW_UP_ERROR: INVALID_FOLLOW_UP_DAYS_AND_TIME,
                        },
                    };
                } else {
                    delete state.baseTemplates[templateId]?.errors?.orderingErrors;
                    delete state.baseTemplates[emailValues._id]?.errors?.orderingErrors;
                }
            }

            // make sure to sort at the end
            state.baseTemplatesOrder = sortElements(objectValues(state.baseTemplates)).map(({ _id }) => _id);
        },
        // TODO: while updating if follow up time is before or equal to email time then set follow-up time after 1 minute
        setOutReachElementTime(state, action: SetOutReachElementTimePayload) {
            const { newTimeStamp, templateId, shouldReorder = true, shouldMarkUnSave = true } = action.payload;

            const currentTemplate = state.baseTemplates[templateId];
            currentTemplate.scheduledTime = newTimeStamp;
            if (shouldMarkUnSave) {
                currentTemplate.isSaved = false;
            }

            // make sure follow-up email should not have a time before time of the email
            const elementType = state.baseTemplates[templateId]?.eventName;
            // if current element type is either follow-up or email, and both have same days
            const emailValues = objectValues(state.baseTemplates).find((i) => i?.eventName === "email");
            const firstFollowupValues =
                state.baseTemplates[
                    state.baseTemplatesOrder.find((i) => state.baseTemplates[i]?.eventName === "follow-up") || ""
                ];

            // compare firstFollowup and email time,
            // if followup have earlier time then add 5 minutes in followup time from email
            if (
                (elementType === "email" || elementType === "follow-up") &&
                emailValues?.scheduledDay === firstFollowupValues?.scheduledDay
            ) {
                // const isScheduledTimeBeforeCurrentTime = dayjs(scheduledTime).isBefore(dayjs());
                if (elementType === "email") {
                    const isFollowupEarlier = checkFollowupEarlier({
                        emailTimestamp: emailValues.scheduledTime,
                        followupEmailTimestamp: firstFollowupValues.scheduledTime,
                    });

                    if (isFollowupEarlier) {
                        firstFollowupValues.scheduledTime = dayjs(emailValues.scheduledTime)
                            .add(5, "minutes")
                            .toISOString();
                        if (shouldMarkUnSave) {
                            firstFollowupValues.isSaved = false;
                        }
                        // because if shouldReorder === true then we are going to reorder in the bottom
                        if (shouldReorder === false) {
                            state.baseTemplatesOrder = sortElements(objectValues(state.baseTemplates)).map(
                                ({ _id }) => _id
                            );
                        }
                    }
                }

                if (elementType === "follow-up") {
                    const isFollowupEarlier = checkFollowupEarlier({
                        emailTimestamp: emailValues.scheduledTime,
                        followupEmailTimestamp: firstFollowupValues.scheduledTime,
                    });

                    if (isFollowupEarlier) {
                        firstFollowupValues.scheduledTime = dayjs(emailValues.scheduledTime)
                            .add(5, "minutes")
                            .toISOString();
                        if (shouldMarkUnSave) {
                            firstFollowupValues.isSaved = false;
                        }
                        // because if shouldReorder === true then we are going to reorder in the bottom
                        if (shouldReorder === false) {
                            state.baseTemplatesOrder = sortElements(objectValues(state.baseTemplates)).map(
                                ({ _id }) => _id
                            );
                        }
                    }
                }
            }

            // if current elementType is either email and follow-up and both have same days
            if (
                (elementType === "email" || elementType === "follow-up") &&
                emailValues?.scheduledDay === firstFollowupValues?.scheduledDay
            ) {
                if (elementType === "email" && firstFollowupValues?._id) {
                    const { scheduledTime } = firstFollowupValues;
                    const firstFollowupTime = new Date(scheduledTime).getTime();
                    const emailTime = new Date(newTimeStamp).getTime();

                    if (emailTime >= firstFollowupTime) {
                        state.baseTemplates[templateId].errors = {
                            ...state.baseTemplates[templateId]?.errors,
                            orderingErrors: {
                                ...state.baseTemplates[templateId]?.errors?.["orderingErrors"],
                                EMAIL_FOLLOW_UP_ERROR: INVALID_EMAIL_DAYS_AND_TIME,
                            },
                        };
                    } else {
                        delete state.baseTemplates[templateId]?.errors?.["orderingErrors"];
                        delete state.baseTemplates[firstFollowupValues._id]?.errors?.["orderingErrors"];
                    }
                }

                if (elementType === "follow-up" && emailValues !== undefined) {
                    const emailTime = new Date(emailValues.scheduledTime).getTime();
                    const followUpTime = new Date(newTimeStamp).getTime();

                    if (followUpTime <= emailTime) {
                        state.baseTemplates[templateId].errors = {
                            ...state.baseTemplates[templateId]?.errors,
                            orderingErrors: {
                                ...state.baseTemplates[templateId]?.errors?.["orderingErrors"],
                                EMAIL_FOLLOW_UP_ERROR: INVALID_FOLLOW_UP_DAYS_AND_TIME,
                            },
                        };
                    } else {
                        delete state.baseTemplates[templateId]?.errors?.["orderingErrors"];
                        delete state.baseTemplates[emailValues._id]?.errors?.orderingErrors;
                    }
                }
            }

            // make sure to sort at the end
            if (shouldReorder) {
                state.baseTemplatesOrder = sortElements(objectValues(state.baseTemplates)).map(({ _id }) => _id);
            }
        },
        // TODO: add currentTemplateId in payload, navigate in payload
        addOutReachElement(
            state,
            action: PayloadAction<{
                subject?: string;
                body?: string;
                inMailBody?: string;
                inMailSubject?: string;
                connectionReminderMessageBody?: string;
                inmailFollowupSubject?: string;
                inmailFollowupReminder?: string;
                inmailFollowupBody?: string;
                eventName: WorkflowStepValue["eventName"];
                onSuccess?: (newOutreachElementId: string) => void;
            }>
        ) {
            const {
                eventName,
                subject = "",
                body = "",
                inMailBody = "",
                inMailSubject = "",
                connectionReminderMessageBody = "",
                inmailFollowupSubject = "",
                inmailFollowupReminder = "3",
                inmailFollowupBody = "",
                onSuccess,
            } = action.payload;
            const newTemplatedId = nanoid();

            const templatesOrder = state.baseTemplatesOrder;

            // if templates already present
            if (templatesOrder.length) {
                const templatesOrder = state.baseTemplatesOrder;
                const lastOutReachElementIndex = templatesOrder[templatesOrder.length - 1];
                const lastOutReachElement = state.baseTemplates[lastOutReachElementIndex];
                let element: WorkflowStepValue;
                const newOutReachElementTime = getFutureTimestampWithSameTime(
                    lastOutReachElement.scheduledDay + 1,
                    lastOutReachElement.scheduledTime
                ).toISOString();
                element = createOutReachElement({
                    elementId: newTemplatedId,
                    eventName,
                    newOutReachElementTime,
                    lastOutReachElementDay: lastOutReachElement.scheduledDay + 1,
                    subject: subject,
                    body: body,
                    inMailSubject: inMailSubject,
                    inMailBody: inMailBody,
                    connectionReminderMessageBody: connectionReminderMessageBody,
                    inmailFollowupSubject: inmailFollowupSubject,
                    inmailFollowupReminder: inmailFollowupReminder,
                    inmailFollowupBody: inmailFollowupBody,
                });
                state.baseTemplatesOrder.push(element._id);
                state.baseTemplates[element._id] = element;

                // follow-ups should have same subject as email, if email is present
                if (eventName === "follow-up") {
                    /**there should no case where email is not present while adding follow-up,
                    because user can't add follow-up if email is not present**/
                    const emailValues = objectValues(state.baseTemplates).find((i) => i?.eventName === "email");
                    state.baseTemplates[element._id].eventBody = {
                        ...state.baseTemplates[element._id].eventBody,
                        subject: (emailValues?.eventBody as BodyWithSubject).subject,
                    };
                }
            } else {
                const element = createOutReachElement({
                    elementId: newTemplatedId,
                    eventName,
                    subject: subject,
                    body: body,
                    inMailSubject: inMailSubject,
                    inMailBody: inMailBody,
                    connectionReminderMessageBody: connectionReminderMessageBody,
                    inmailFollowupSubject: inmailFollowupSubject,
                    inmailFollowupReminder: inmailFollowupReminder,
                    inmailFollowupBody: inmailFollowupBody,
                });
                state.baseTemplates[element._id] = element;
                state.baseTemplatesOrder.push(element._id);
            }
            const newOutreachElement = state.baseTemplates[newTemplatedId];
            validateElement({
                element: newOutreachElement,
                EXTRA_TEMPLATE_VALID_TAGS: state.customPersonalizedFields.map((item) => item.value),
            });
            onSuccess?.(newTemplatedId);
        },
        // TODO: receive current templateId in payload, navigate in the payload, currentTemplateId in payload
        removeOutReachElement(
            state,
            action: PayloadAction<{
                deleteTemplateId: string;
                currentSelectedTemplateId: string;
                moveToOutreachElement: (templateId: string) => void;
                onDelete?: (deletedIds: string[]) => void;
            }>
        ) {
            const { deleteTemplateId, currentSelectedTemplateId, moveToOutreachElement, onDelete } = action.payload;
            const deletedTemplateIndex = state.baseTemplatesOrder.indexOf(deleteTemplateId);
            const currentTemplateIndex = state.baseTemplatesOrder.indexOf(currentSelectedTemplateId);

            // if email is remove also remove all follow-ups
            // remove all followups from baseTemplates and baseTemplatesOrder
            // selected element should move to next element in-case the selected element is deleted.
            if (state.baseTemplatesOrder.length > 1) {
                const deleteTemplate = state.baseTemplates[deleteTemplateId];
                const deletedIndexes: number[] = [];
                const deletedTemplateIds: string[] = [];
                let newSelectedIndex: number = -1;

                if (deleteTemplate?.eventName === "email") {
                    const currentTemplate = state.baseTemplates[currentSelectedTemplateId];
                    console.log(JSON.parse(JSON.stringify(state.baseTemplates)));
                    console.log(JSON.parse(JSON.stringify(state.baseTemplatesOrder)));

                    // first remove all follow-ups because follow-ups can't exist without email
                    state.baseTemplatesOrder = state.baseTemplatesOrder.filter((i, idx) => {
                        const template = state.baseTemplates[i];

                        if (template?.eventName === "follow-up") {
                            deletedIndexes.push(idx);
                            if (template?._id) {
                                deletedTemplateIds.push(template._id);
                            }
                            delete state.baseTemplates[i];

                            return false;
                        }

                        return true;
                    });

                    // after removing follow-ups & there are more than 1 element present in outreach then remove email else don't
                    if (state.baseTemplatesOrder.length === 1) {
                        if (currentTemplate?.eventName === "follow-up") {
                            deletedIndexes.sort((a, b) => a - b);

                            if (deletedIndexes.includes(deletedTemplateIndex)) {
                                let closestDistance = state.baseTemplatesOrder.length;
                                let closestIndex = -1;
                                for (let i = 0; i < deletedIndexes.length; i++) {
                                    // Skip if this index was also deleted
                                    if (deletedIndexes.includes(i)) continue;

                                    const distance = Math.abs(i - currentTemplateIndex);
                                    if (distance < closestDistance) {
                                        closestDistance = distance;
                                        closestIndex = i;
                                    }
                                }
                                newSelectedIndex = closestIndex;
                            } else {
                                const adjustment = deletedIndexes?.reduce(
                                    (acc, cur) => acc + (cur < currentTemplateIndex ? 1 : 0),
                                    0
                                );
                                newSelectedIndex -= adjustment;
                            }

                            newSelectedIndex = Math.max(
                                0,
                                Math.min(state.baseTemplatesOrder.length - 1, newSelectedIndex)
                            );

                            const newSelectedOutreachElementId = state.baseTemplatesOrder[newSelectedIndex];

                            moveToOutreachElement(newSelectedOutreachElementId);
                        }
                    } else {
                        const deletedTemplateIndex = state.baseTemplatesOrder.indexOf(deleteTemplateId);
                        const currentTemplateIndex = state.baseTemplatesOrder.indexOf(currentSelectedTemplateId);
                        if (deleteTemplateId in state.baseTemplates) {
                            delete state.baseTemplates[deleteTemplateId];
                        }
                        console.log("before deleting base templates", {
                            baseTemplates: state.baseTemplatesOrder,
                        });
                        state.baseTemplatesOrder.splice(deletedTemplateIndex, 1);
                        console.log("after deleting base templates", {
                            baseTemplates: state.baseTemplatesOrder,
                        });
                        if (deletedTemplateIndex === currentTemplateIndex) {
                            const newSelectedIndex =
                                deletedTemplateIndex === state.baseTemplatesOrder.length
                                    ? deletedTemplateIndex - 1
                                    : deletedTemplateIndex;
                            const newSelectedOutreachElementId = state.baseTemplatesOrder[newSelectedIndex];
                            console.log({ newSelectedOutreachElementId });
                            moveToOutreachElement(newSelectedOutreachElementId);
                        }
                    }
                } else {
                    if (deleteTemplateId in state.baseTemplates) {
                        delete state.baseTemplates[deleteTemplateId];
                    }

                    state.baseTemplatesOrder.splice(deletedTemplateIndex, 1);

                    if (deletedTemplateIndex === currentTemplateIndex) {
                        const newSelectedIndex =
                            deletedTemplateIndex === state.baseTemplatesOrder.length
                                ? deletedTemplateIndex - 1
                                : deletedTemplateIndex;
                        const newSelectedOutreachElementId = state.baseTemplatesOrder[newSelectedIndex];
                        moveToOutreachElement(newSelectedOutreachElementId);
                    }
                }
                onDelete?.(deletedTemplateIds);
            }
        },
        changeOutReachElementsOrder(state, action: PayloadAction<{ source: number; destination: number }>) {
            // TODO: Figure out days, when you're re-arrange
            // if one of source or destination is follow-up then check make there is always a email before it's destination index
            const { source, destination } = action.payload;
            const sourceId = state.baseTemplatesOrder[source];
            const sourceElement = state.baseTemplates[sourceId];
            const sourceElementType = sourceElement?.eventName;

            if (sourceElementType === "email") {
                // find index of first followup, it should not be smaller than destination
                const firstFollowupIndex = state.baseTemplatesOrder.indexOf(
                    state.baseTemplatesOrder.find((i) => state.baseTemplates[i]?.eventName === "follow-up") || ""
                );

                if (destination < firstFollowupIndex) {
                    moveElements({ templatesOrder: state.baseTemplatesOrder, source, destination });
                }
            } else if (sourceElementType === "follow-up") {
                const emailId = objectKeys(state.baseTemplates).find(
                    (i) => state.baseTemplates[i]?.eventName === "email"
                );
                const emailIndex = state.baseTemplatesOrder.indexOf(emailId || "");

                // destination index of the follow-up should not be lesser than index of email
                if (emailIndex < destination) {
                    moveElements({ templatesOrder: state.baseTemplatesOrder, source, destination });
                }
            } else {
                moveElements({ templatesOrder: state.baseTemplatesOrder, source, destination });
            }
        },
        editOutreachElement(
            state,
            action: PayloadAction<{
                id: string;
                subject?: string;
                body?: string;
                accountType?: AccountType;
                connectionReminderMessageBody?: string;
                inmailFollowupBody?: string;
                inMailSubject?: string;
                inmailFollowupSubject?: string;
                inmailFollowupReminder?: string;
                inMailBody?: string;
            }>
        ) {
            const {
                id,
                subject,
                body,
                accountType,
                connectionReminderMessageBody,
                inmailFollowupBody,
                inMailSubject,
                inmailFollowupSubject,
                inmailFollowupReminder,
                inMailBody,
            } = action.payload;
            const element = state.baseTemplates?.[id];

            if (element && body) {
                element.eventBody.body = body;
                element.isSaved = false;
            }

            // setting template subject
            if (
                element &&
                subject !== undefined &&
                (element?.eventName === "email" || element?.eventName === "follow-up")
            ) {
                (element.eventBody as BodyWithSubject).subject = subject;
                element.isSaved = false;

                // if current element is email then update subjects for all the followups
                if (element?.eventName === "email") {
                    objectKeys(state.baseTemplates).forEach((i) => {
                        const template = state.baseTemplates[i];
                        if (template?.eventName === "follow-up") {
                            (template.eventBody as BodyWithSubject).subject = subject;
                        }
                    });
                }
            }

            if (element && element?.eventName === "linkedin") {
                // element.eventBody
                if (connectionReminderMessageBody) {
                    (element.eventBody as LinkedinSchema).connectionReminderMessageBody = connectionReminderMessageBody;
                    element.isSaved = false;
                }
                if (inmailFollowupBody) {
                    (element.eventBody as LinkedinSchema).inmailFollowupBody = inmailFollowupBody;
                    element.isSaved = false;
                }
                if (inmailFollowupSubject) {
                    (element.eventBody as LinkedinSchema).inmailFollowupSubject = inmailFollowupSubject;
                    element.isSaved = false;
                }
                //todo
                if (inmailFollowupReminder) {
                    (element.eventBody as LinkedinSchema).inmailFollowupReminder = inmailFollowupReminder;
                    element.isSaved = false;
                }
                if (inMailSubject !== undefined) {
                    (element.eventBody as LinkedinSchema).inMailSubject = inMailSubject;
                    element.isSaved = false;
                }
                if (inMailBody) {
                    (element.eventBody as LinkedinSchema).inMailBody = inMailBody;
                    element.isSaved = false;
                }
            }

            // NOTE: not a pure function due to immutability cost
            validateElement({
                element,
                accountType,
                EXTRA_TEMPLATE_VALID_TAGS: state.customPersonalizedFields.map((item) => item.value),
            });

            // case: helpful when you're created both email and follow-up as new element
            // because there is no subject in email, follow-ups also don't have any subject
            // when you edit email subject email subject errors got remove, and because follow-up subjects are also updated, it's necessary to remove follow-up errors
            if (element?.eventName === "email") {
                objectKeys(state.baseTemplates).forEach((i) => {
                    const template = state.baseTemplates[i];
                    if (template?.eventName === "follow-up") {
                        validateElement({
                            element: template,
                            accountType,
                            EXTRA_TEMPLATE_VALID_TAGS: state.customPersonalizedFields.map((item) => item.value),
                        });
                    }
                });
            }
        },
        setReminderMessageStatus(state, action: PayloadAction<{ currentOutreachElementId: string }>) {
            const { currentOutreachElementId } = action.payload;
            const elementContent = state.baseTemplates?.[currentOutreachElementId];
            if (elementContent?.eventName === "linkedin") {
                (elementContent.eventBody as LinkedinSchema).connectionReminderMessageStatus = !(
                    elementContent.eventBody as LinkedinSchema
                ).connectionReminderMessageStatus;
                (elementContent.eventBody as LinkedinSchema).connectionReminderMessageBody = "";
                elementContent.isSaved = false;
            }
        },
        setinmailFollowupStatus(
            state,
            action: PayloadAction<{ currentOutreachElementId: string; status?: boolean | undefined }>
        ) {
            const { currentOutreachElementId, status } = action.payload;
            const elementContent = state.baseTemplates?.[currentOutreachElementId];
            if (elementContent?.eventName === "linkedin") {
                if (status !== undefined) {
                    (elementContent.eventBody as LinkedinSchema).inmailFollowupStatus = status;
                } else {
                    (elementContent.eventBody as LinkedinSchema).inmailFollowupStatus = !(
                        elementContent.eventBody as LinkedinSchema
                    ).inmailFollowupStatus;
                }
                (elementContent.eventBody as LinkedinSchema).inmailFollowupBody = "";
                elementContent.isSaved = false;
            }
        },
        setInMailStatus(state, action: PayloadAction<{ currentOutreachElementId: string }>) {
            const { currentOutreachElementId } = action.payload;
            const elementContent = state.baseTemplates?.[currentOutreachElementId];
            if (elementContent?.eventName === "linkedin") {
                (elementContent.eventBody as LinkedinSchema).inMailStatus = !(
                    elementContent.eventBody as LinkedinSchema
                ).inMailStatus;
                elementContent.isSaved = false;
            }
        },
        setConnectionStatus(state, action: PayloadAction<{ currentOutreachElementId: string }>) {
            const { currentOutreachElementId } = action.payload;
            const elementContent = state.baseTemplates?.[currentOutreachElementId];
            if (elementContent?.eventName === "linkedin") {
                (elementContent.eventBody as LinkedinSchema).connectionStatus = !(
                    elementContent.eventBody as LinkedinSchema
                ).connectionStatus;
                elementContent.isSaved = false;
            }
        },
        markCurrentTemplateAsSave(state, action: PayloadAction<string>) {
            const currentOutreachElementId = action.payload;
            state.baseTemplates[currentOutreachElementId].isSaved = true;
        },
        // please do not use this function for any other usecase because it doesn't contains validations checks etc...
        scheduleFirstElementToCurrent(
            state,
            action: PayloadAction<{ onSuccess: (firstOutreachElementTempalte: WorkflowStepValue) => void }>
        ) {
            const { onSuccess } = action.payload;
            const firstElementId = state.baseTemplatesOrder[0];
            const firstElement = state.baseTemplates[firstElementId];
            firstElement.scheduledDay = 1;
            firstElement.scheduledTime = dayjs().toISOString();
            onSuccess(JSON.parse(JSON.stringify(firstElement)));
        },
        editExclusionList(state, action: PayloadAction<{ exclusionListIds: string[] }>) {
            const { exclusionListIds } = action.payload;
            // const updatedExclusionList = defaultExclusionListIds.filter((id) => !exclusionListIds.includes(id));
            // state.exclusionListIds = updatedExclusionList;
            state.exclusionListIds = exclusionListIds;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(externalConnectApis.endpoints.getCustomFieldEventType.matchFulfilled, (state, action) => {
            const customPersonalizedFields = action.payload;
            state.customPersonalizedFields = customPersonalizedFields;
            Object.keys(state.baseTemplates).forEach((key) => {
                const element = state.baseTemplates[key];
                validateElement({
                    element: element,
                    EXTRA_TEMPLATE_VALID_TAGS: customPersonalizedFields.map((item) => item.value),
                });
            });
        });
        builder.addMatcher(outreachApi.endpoints.fetchOutreachById.matchFulfilled, (state, action) => {
            const workflowSteps = action.payload.workflowSteps;

            state.baseTemplates = workflowSteps?.reduce((acc: WorkflowSteps, i) => {
                acc[i._id] = {
                    ...i,
                    errors: {
                        body: {},
                    },
                };
                // if (i?.eventName === "follow-up") {
                //     this is a follow up email
                //     const email = workflowSteps.find((el) => el?.eventName === "email")?.eventBody as BodyWithSubject;
                //     if (email) {
                //         console.log(acc[i._id].eventBody, "BodyWithSubject");
                //         (acc[i._id].eventBody as BodyWithSubject).subject = email.subject;
                //     }
                // }
                const scheduledDay = i.scheduledDay;
                acc[i._id].scheduledTime = modifyTimestampWithDays(i.scheduledTime, scheduledDay - 1);
                validateElement({
                    element: acc[i._id],
                    EXTRA_TEMPLATE_VALID_TAGS: state.customPersonalizedFields.map((item) => item.value),
                });

                return acc;
            }, {});

            state.baseTemplatesOrder = sortElements(objectValues(state.baseTemplates)).map(({ _id }) => _id);
        });
        builder.addMatcher(outreachApi.endpoints.createOutreach.matchFulfilled, (state, action) => {
            const workflowSteps = action.payload.workflowSteps;

            state.baseTemplates = workflowSteps?.reduce((acc: WorkflowSteps, i) => {
                acc[i._id] = {
                    ...i,
                    errors: {
                        body: {},
                    },
                };
                // if (i?.eventName === "follow-up") {
                //     // this is a follow up email
                //     const email = workflowSteps.find((el) => el.eventName === "email")?.eventBody as BodyWithSubject;
                //     if (email) {
                //         (acc[i._id].eventBody as BodyWithSubject).subject = email.subject;
                //     }
                // }
                const scheduledDay = i.scheduledDay;
                acc[i._id].scheduledTime = modifyTimestampWithDays(i.scheduledTime, scheduledDay - 1);
                validateElement({
                    element: acc[i._id],
                    EXTRA_TEMPLATE_VALID_TAGS: state.customPersonalizedFields.map((item) => item.value),
                });

                return acc;
            }, {});

            state.baseTemplatesOrder = workflowSteps.map(({ _id }) => _id);
        });
        builder.addMatcher(outreachApi.endpoints.saveOutreachTemplate.matchFulfilled, (state, action) => {
            const { _id, eventBody, scheduledDay, scheduledTime, isSaved, stepId } = action.payload;

            state.baseTemplates[_id].eventBody = eventBody;
            state.baseTemplates[_id].scheduledDay = scheduledDay;
            state.baseTemplates[_id].scheduledTime = scheduledTime;
            state.baseTemplates[_id].isSaved = isSaved;
            state.baseTemplates[_id].isNew = false;
            state.baseTemplates[_id].stepId = stepId;

            validateElement({
                element: state.baseTemplates[_id],
                EXTRA_TEMPLATE_VALID_TAGS: state.customPersonalizedFields.map((item) => item.value),
            });
        });
        builder.addMatcher(outreachApi.endpoints.uploadAttachments.matchFulfilled, (state, action) => {
            const { currentOutreachElementId, files } = action.payload;
            const currentAttachments = (state.baseTemplates[currentOutreachElementId].attachments =
                state.baseTemplates[currentOutreachElementId]?.attachments ?? []);
            currentAttachments.push(...files);
            state.baseTemplates[currentOutreachElementId].isSaved = false;
        });
    },
});

export const {
    markCurrentTemplateAsSave,
    removeAttachment,
    switchToNextElement,
    setReminderMessageStatus,
    setinmailFollowupStatus,
    setInMailStatus,
    setConnectionStatus,
    editOutreachElement,
    setOutReachElementTime,
    setOutReachElementDays,
    addOutReachElement,
    changeOutReachElementsOrder,
    removeOutReachElement,
    toggleEditSignatureModal,
    toggleCurrentOutreachStep,
    changeCurrentEditor,
    validateOrdering,
    toggleMobileView,
    scheduleFirstElementToCurrent,
    editExclusionList,
    setWritingStyleChangePayload,
} = outreachState.actions;

export const currentOutreachStep = (state: RootState) => {
    return state.outreach.currentOutreachStep;
};

export const currentEditor = (state: RootState) => {
    return state.outreach.currentEditor;
};

export const exclusionListIds = (state: RootState) => {
    return state.outreach.exclusionListIds;
};

export const selectCurrentOutreachElementAttachments = createSelector(
    [(state: RootState) => state.outreach, (_: RootState, templateId: string) => templateId],
    (state: HyperPersonalizationState, templateId: string) => {
        return state.baseTemplates[templateId]?.attachments ?? [];
    }
);
export const selectWritingStyleChangePayload = (state: RootState) => {
    return state.outreach.writingStyles;
};
export const isSignatureModalOpen = (state: RootState) => {
    return state.outreach.isEditSignatureModalOpen;
};
export const selectCurrentOutreachElement = createSelector(
    [(state: RootState) => state.outreach, (_: RootState, { templateId }: { templateId: string }) => templateId],
    (state: HyperPersonalizationState, templateId: string) => {
        return state.baseTemplates[templateId];
    }
);

export const selectOutreachTemplateContent = createSelector(
    [(state: RootState) => state.outreach, (_: RootState, templateId: string): string => templateId],
    (state: HyperPersonalizationState, templateId: string) => {
        return state.baseTemplates[templateId]?.eventBody;
    }
);

export const checkTempalateHaveUnsavedChanges = createSelector(
    [(state: RootState) => state.outreach, (_: RootState, templateId: string): string => templateId],
    (state: HyperPersonalizationState, templateId: string) => {
        return !state.baseTemplates[templateId]?.isSaved;
    }
);

// pass currentOutreachElement id
export const checkReminderMessageEnabledInBaseTemplates = createSelector(
    [
        (state: RootState) => state.outreach,
        (_: RootState, currentOutreachElementId: string) => currentOutreachElementId,
    ],
    (state: HyperPersonalizationState, currentOutreachElementId) => {
        const currentTemplateContent = state.baseTemplates[currentOutreachElementId].eventBody;

        return (
            "connectionReminderMessageStatus" in currentTemplateContent &&
            currentTemplateContent.connectionReminderMessageStatus === true
        );
    }
);

export const checkInMailReminderMessageEnabledInBaseTemplates = createSelector(
    [
        (state: RootState) => state.outreach,
        (_: RootState, currentOutreachElementId: string) => currentOutreachElementId,
    ],
    (state: HyperPersonalizationState, currentOutreachElementId) => {
        const currentTemplateContent = state.baseTemplates[currentOutreachElementId].eventBody;

        return "inmailFollowupStatus" in currentTemplateContent && currentTemplateContent.inmailFollowupStatus === true;
    }
);

type SelectCurrentOutreachElementTypeParams = {
    currentOutreachElementId: string;
};

// pass outreach preview type and currentTemplateId
export const selectCurrentOutreachElementType = createSelector(
    [
        (state: RootState) => state.outreach,
        (_: RootState, params: SelectCurrentOutreachElementTypeParams): SelectCurrentOutreachElementTypeParams =>
            params,
    ],
    (state: HyperPersonalizationState, { currentOutreachElementId }: SelectCurrentOutreachElementTypeParams) => {
        return state.baseTemplates[currentOutreachElementId]?.eventName;
    }
);
export const selectOutReachFlow = (state: RootState) => {
    return state.outreach.baseTemplatesOrder.map((id) => state.outreach.baseTemplates[id]);
};

export const selectEmailOutreachElementDays = (state: RootState) => {
    const emailId = state.outreach.baseTemplatesOrder.find(
        (i) => state.outreach.baseTemplates[i]?.eventName === "email"
    );

    if (emailId === undefined) {
        return null;
    }

    return state.outreach.baseTemplates[emailId].scheduledDay;
};

export const checkLastOutreachElement = createSelector(
    [
        (state: RootState) => state.outreach,
        (_: RootState, currentOutreachElementId: string) => currentOutreachElementId,
    ],
    (state: HyperPersonalizationState, currentOutreachElementId) => {
        return state.baseTemplatesOrder[state.baseTemplatesOrder.length - 1] === currentOutreachElementId;
    }
);

export const checkEmailPresentInOutreachFlow = createSelector(
    [(state: RootState) => state.outreach, (_: RootState, isHyperPersonalizedMode: boolean) => isHyperPersonalizedMode],
    (state, isHyperPersonalization) => {
        return Object.values(state.baseTemplates).some(({ eventName }) => eventName === "email");
    }
);

export const checkLinkedInPresent = (state: RootState) => {
    return Object.values(state.outreach.baseTemplates).some(({ eventName }) => eventName === "linkedin");
};

export const checkSMSPresent = (state: RootState) => {
    return Object.values(state.outreach.baseTemplates).some(({ eventName }) => eventName === "SMS");
};

export const shouldDisableDelete = createSelector(
    [(state: RootState) => state.outreach, (_: RootState, id: string) => id],
    (state: HyperPersonalizationState, id: string) => {
        const isOnlyOneElementPresent = state.baseTemplatesOrder.length <= 1;
        const onlyOutreachAndFollowIsPresent =
            state.baseTemplatesOrder.every((id) => {
                const eventName = state.baseTemplates[id]?.eventName;

                return eventName === "email" || eventName === "follow-up";
            }) && state.baseTemplates[id]?.eventName === "email";

        return isOnlyOneElementPresent || onlyOutreachAndFollowIsPresent;
    }
);

export const checkForUnsavedChangesInCurrentTemplate = createSelector(
    [(state: RootState) => state.outreach, (_: RootState, templateId: string) => templateId],
    (state: HyperPersonalizationState, templateId) => {
        return state.baseTemplates[templateId].isSaved === false;
    }
);

export const checkForUnsavedChangesInWorkflow = createSelector(
    [(state: RootState) => state.outreach],
    (state: HyperPersonalizationState) => {
        const templates = state.baseTemplates;
        let hasUnsavedChanges = false;
        objectKeys(templates).forEach((templateId) => {
            const template = templates[templateId];
            if (template.isSaved === false) {
                hasUnsavedChanges = true;
            }
        });
        return hasUnsavedChanges;
    }
);

type SelectTemplateErrorArgs = {
    currentOutreachElementId: string;
    accountType?: AccountType;
};

export const selectOutreachErrors = createSelector(
    [(state: RootState) => state.outreach, (_: RootState, args: SelectTemplateErrorArgs) => args],
    (state: HyperPersonalizationState, { currentOutreachElementId, accountType }: SelectTemplateErrorArgs) => {
        return state.baseTemplates[currentOutreachElementId]?.errors;
    }
);

export const checkIfOutreachReady = (state: RootState) => {
    const templates = state.outreach.baseTemplates;
    const templatesIds = state.outreach.baseTemplatesOrder;

    return templatesIds.every((i) => {
        const saveStatus = templates?.[i]?.isSaved;

        let totalErrors = 0;

        if (templates[i]?.eventName !== "linkedin") {
            totalErrors =
                Object.keys(templates[i]?.errors?.body ?? {}).length +
                Object.keys(templates[i]?.errors?.subject ?? {}).length +
                Object.keys(templates[i]?.errors?.orderingErrors ?? {}).length;
        } else {
            totalErrors = Object.keys(templates[i]?.errors?.orderingErrors ?? {}).length;
            if ((templates[i].eventBody as LinkedinSchema)?.inMailStatus) {
                totalErrors +=
                    Object.keys(templates[i]?.errors?.inMailBody ?? {}).length +
                    Object.keys(templates[i]?.errors?.inMailSubject ?? {}).length;
            }

            if ((templates[i].eventBody as LinkedinSchema)?.connectionReminderMessageStatus) {
                totalErrors += Object.keys(templates[i]?.errors?.connectionReminderMessageBody ?? {}).length;
            }
            //todo
            if ((templates[i].eventBody as LinkedinSchema)?.inmailFollowupStatus) {
                totalErrors +=
                    Object.keys(templates[i]?.errors?.inmailFollowupBody ?? {}).length +
                    Object.keys(templates[i]?.errors?.inmailFollowupSubject ?? {}).length;
                // Object.keys(templates[i]?.errors?.inmailFollowupReminder ?? {}).length;
            }

            if ((templates[i].eventBody as LinkedinSchema)?.connectionStatus) {
                totalErrors += Object.keys(templates[i]?.errors?.body ?? {}).length;
            }
        }

        return totalErrors === 0 && saveStatus === true;
    });
};

export const checkErrorInOutreachElement = createSelector(
    [
        (state: RootState) => state.outreach,
        (_: RootState, currentOutreachElementId: string) => currentOutreachElementId,
    ],
    (state: HyperPersonalizationState, currentOutreachElementId: string) => {
        const currentTemplate = state.baseTemplates[currentOutreachElementId];
        let totalErrors = 0;

        if (currentTemplate?.eventName !== "linkedin") {
            totalErrors =
                Object?.keys(currentTemplate?.errors.body ?? {}).length +
                Object?.keys(currentTemplate?.errors?.subject ?? {}).length +
                Object?.keys(currentTemplate?.errors?.orderingErrors ?? {}).length;
        } else {
            totalErrors = Object?.keys(currentTemplate?.errors?.orderingErrors ?? {}).length;
            if ((currentTemplate.eventBody as LinkedinSchema)?.inMailStatus) {
                totalErrors +=
                    Object.keys(currentTemplate?.errors?.inMailBody ?? {}).length +
                    Object.keys(currentTemplate?.errors?.inMailSubject ?? {}).length;
            }

            if ((currentTemplate.eventBody as LinkedinSchema)?.connectionReminderMessageStatus) {
                totalErrors += Object.keys(currentTemplate?.errors?.connectionReminderMessageBody ?? {}).length;
            }
            //todo
            if ((currentTemplate.eventBody as LinkedinSchema)?.inmailFollowupStatus) {
                totalErrors +=
                    Object.keys(currentTemplate?.errors?.inmailFollowupBody ?? {}).length +
                    Object.keys(currentTemplate?.errors?.inmailFollowupSubject ?? {}).length;
                // Object.keys(currentTemplate?.errors?.inmailFollowupReminder ?? {}).length;
            }

            if ((currentTemplate.eventBody as LinkedinSchema)?.connectionStatus) {
                totalErrors += Object.keys(currentTemplate?.errors?.body ?? {}).length;
            }
        }

        return totalErrors > 0;
    }
);

export const checkErrorsInOutreach = (state: RootState) => {
    const templates = state.outreach.baseTemplates;
    const templatesIds = state.outreach.baseTemplatesOrder;

    let totalErrors = 0;

    for (let i = 0; i < templatesIds.length; ++i) {
        const currentTemplate = templates?.[templatesIds[i]];
        if (currentTemplate?.eventName !== "linkedin") {
            totalErrors +=
                Object.keys(currentTemplate?.errors.body ?? {}).length +
                Object.keys(currentTemplate?.errors?.subject ?? {}).length +
                Object.keys(currentTemplate?.errors?.orderingErrors ?? {}).length;
        } else {
            totalErrors += Object.keys(currentTemplate?.errors?.orderingErrors ?? {}).length;
            if ((currentTemplate.eventBody as LinkedinSchema)?.inMailStatus) {
                totalErrors +=
                    Object.keys(currentTemplate?.errors?.inMailBody ?? {}).length +
                    Object.keys(currentTemplate?.errors?.inMailSubject ?? {}).length;
            }

            if ((currentTemplate.eventBody as LinkedinSchema)?.connectionReminderMessageStatus) {
                totalErrors += Object.keys(currentTemplate?.errors?.connectionReminderMessageBody ?? {}).length;
            }
            //todo
            if ((currentTemplate.eventBody as LinkedinSchema)?.inmailFollowupStatus) {
                totalErrors +=
                    Object.keys(currentTemplate?.errors?.inmailFollowupBody ?? {}).length +
                    Object.keys(currentTemplate?.errors?.inmailFollowupSubject ?? {}).length +
                    Object.keys(currentTemplate?.errors?.inmailFollowupReminder ?? {}).length;
            }

            if ((currentTemplate.eventBody as LinkedinSchema)?.connectionStatus) {
                totalErrors += Object.keys(currentTemplate?.errors.body ?? {}).length;
            }
        }
    }

    return totalErrors > 0;
};

export const checkIfCurrentTemplateEdited = createSelector(
    [
        (state: RootState) => state.outreach,
        (_: RootState, currentOutreachElementId: string) => currentOutreachElementId,
    ],
    (state: HyperPersonalizationState, currentOutreachElementId: string) => {
        const currentTemplate = state.baseTemplates[currentOutreachElementId];

        return currentTemplate?.isSaved;
    }
);

export const check_If_FollowupHasCustomHyperPersonalizedTags = createSelector(
    [
        (state: RootState) => state.outreach,
        (_: RootState, currentOutreachElementId: string) => currentOutreachElementId,
    ],
    (state: HyperPersonalizationState, currentOutreachElementId: string) => {
        const firstFollowupIndex = state.baseTemplatesOrder.findIndex(
            (id) => state.baseTemplates[id]?.eventName === "follow-up"
        );

        if (firstFollowupIndex === -1) {
            return false;
        }

        const moreThanOneFollowupPresent = state.baseTemplatesOrder
            ?.slice(firstFollowupIndex + 1)
            ?.some((id) => state.baseTemplates[id]?.eventName === "follow-up");

        const currentTemplate = state.baseTemplates[currentOutreachElementId];

        const isDoubleQuotesPresentInBody =
            extractTextInCurlyBraces(currentTemplate.eventBody.body).doubleCurlyBracesText.length > 0;

        const isFollowup = currentTemplate?.eventName === "follow-up";

        return isDoubleQuotesPresentInBody && isFollowup && moreThanOneFollowupPresent;
    }
);

export const checkErrorsInReminderMessage = createSelector(
    [
        (state: RootState) => state.outreach,
        (_: RootState, currentOutreachElementId: string) => currentOutreachElementId,
    ],
    (state: HyperPersonalizationState, currentOutreachElementId: string) => {
        const currentTemplate = state.baseTemplates[currentOutreachElementId];

        return currentTemplate?.errors?.connectionReminderMessageBody ?? {};
    }
);

export const checkErrorsInInMailReminderMessage = createSelector(
    [
        (state: RootState) => state.outreach,
        (_: RootState, currentOutreachElementId: string) => currentOutreachElementId,
    ],
    (state: HyperPersonalizationState, currentOutreachElementId: string) => {
        const currentTemplate = state.baseTemplates[currentOutreachElementId];

        return currentTemplate?.errors?.inmailFollowupBody ?? {};
    }
);

export const selectFirstOutreachElement = (state: RootState) => {
    const firstElementId = state.outreach.baseTemplatesOrder[0];
    return state.outreach.baseTemplates[firstElementId];
};

export default outreachState.reducer;
