import React, { useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { Fab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import useDragAndDrop from "../../../../hooks/useDragAndDrop";
import { selectUser } from "../../../Signin/Signin.reducer";
import { CallModal } from "./CallModal";
import { useCallContext } from "./TwilloCallContext";

const CallButton: React.FC = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const { phoneNumber, setPhoneNumber, callInProgress, currentCandidate } = useCallContext();

    const { position, handleMouseDown, elementRef } = useDragAndDrop({ bottom: 20, right: 80 });

    const toggleModal = () => {
        setModalOpen(!modalOpen);

        if (!callInProgress) {
            setPhoneNumber("");
        }
    };

    return (
        user?.twilioPhoneNumber && (
            <div>
                <CallModal
                    modalOpen={modalOpen}
                    toggleModal={toggleModal}
                    editPhoneNumber={() => {}}
                    candidate={currentCandidate}
                    setEditPhoneNumber={() => {}}
                    handleBlockCandidate={() => {}}
                />
                <div
                    ref={elementRef}
                    onMouseDown={handleMouseDown}
                    style={{
                        position: "fixed",
                        bottom: position.bottom,
                        right: position.right,
                        zIndex: 9000,
                        cursor: "pointer",
                    }}
                >
                    <Fab
                        color="primary"
                        aria-label="call"
                        size="medium"
                        onClick={(e) => {
                            toggleModal();
                        }}
                        sx={{
                            backgroundColor: "#008080f0",
                            "&:hover": {
                                backgroundColor: "#008080f0",
                            },
                        }}
                    >
                        <PhoneIcon />
                    </Fab>
                </div>
            </div>
        )
    );
};

export default CallButton;
