import Typography, { TypographyProps } from "@mui/material/Typography";
import Stack, { StackProps } from "@mui/material/Stack";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { DEFAULT_NETWORK_ERROR_MESSAGE } from "../utils/Constants";
import CachedIcon from "@mui/icons-material/Cached";

type ErrorPlaceholderProps = {
    text?: string;
    reload: () => void;
    typographyProps?: TypographyProps;
    iconButtonProps?: IconButtonProps;
    containerProps?: StackProps;
    children?: React.ReactNode;
};

export function ErrorPlaceholder({
    reload,
    text,
    iconButtonProps,
    typographyProps,
    children = <CachedIcon fontSize="small" />,
    containerProps,
}: ErrorPlaceholderProps) {
    return (
        <Stack flexDirection="row" alignItems="center" {...containerProps}>
            <Typography {...typographyProps}>{text || DEFAULT_NETWORK_ERROR_MESSAGE}</Typography>
            <IconButton {...iconButtonProps} onClick={reload}>
                {children}
            </IconButton>
        </Stack>
    );
}
