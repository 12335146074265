import { PayloadAction } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import { setModal } from "../../store/reducers/modals.slice";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { DeleteContactListPayload } from "../reducers/contact-list/contact-list.type";

import {
    AddExclusionListPayload,
    EditExclusionListPayload,
    EditExclusionListResponse,
    EditExclusionListStatusPayload,
    FetchExclusionListForCSVPayload,
    FetchExclusionListForCSVResponse,
    addExclusionList,
    editExclusionList,
    editExclusionListStatus,
    fetchExclusionListForCSV,
    getExclusionLists,
    getProjectExclusionLists,
    leavePage,
    setExclusionListCSVData,
    setExclusionLists,
    setProjectExclusionLists,
    submitDeleteExclusionList,
} from "@/store/reducers/exclusion-list/ExclusionList.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";

function* addExclusionListSaga(action: PayloadAction<AddExclusionListPayload>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const { name, csvFile, onSuccess, exclusionListType, exclusionField } = action.payload;

        const formData = new FormData();
        formData.append("name", name);
        formData.append("csv", csvFile);
        formData.append("type", exclusionListType);
        formData.append("exclusionField", exclusionField);

        const csvResponse = yield call(new API().post, `/exclusionList`, formData);
        if (!csvResponse.data) return;

        onSuccess();
        yield put(setSuccessNotification("Csv uploaded successfully"));
    } catch (e) {
        handleException(e);
        console.error("***addExclusionListSaga err=", e);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* editExclusionListSaga(action: EditExclusionListPayload): SagaIterator {
    try {
        const { type } = action;
        yield put(startAction({ action: type }));
        const {
            csvFile,
            emailsStringArray,
            exclusionField,
            exclusionListType,
            name,
            onSuccess,
            id,
            action: fileStatus,
        } = action.payload;
        const formData = new FormData();
        formData.append("name", name);
        formData.append("id", id);
        formData.append("action", fileStatus);
        if (csvFile) {
            formData.append("csv", csvFile);
        }
        formData.append("type", exclusionListType);
        formData.append("exclusionField", exclusionField);
        formData.append("emailStringArray", JSON.stringify(emailsStringArray));

        const csvResponse: EditExclusionListResponse = yield call(new API().post, `/exclusionList/edit`, formData);

        if (!csvResponse?.success) {
            throw new Error(csvResponse.message);
        } else {
            yield put(setSuccessNotification(csvResponse.message || "Exclusion list edited"));
            onSuccess();
        }
    } catch (error) {
        handleException(error);
        const message = (error as Error).message || "error while editing exclusion list";
        yield put(setErrorNotification(message));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getExclusionListsSaga(action: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const response = yield call(new API().get, "/exclusionList/list");
        if (isEmpty(response?.data)) return;

        yield put(setExclusionLists(response.data));
    } catch (e) {
        handleException(e);
        console.error("***getExclusionListsSaga err=", e);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getProjectExclusionListsSaga(action: PayloadAction<number>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const response = yield call(new API().get, `/exclusionList/list?projectId=${action.payload}`);
        if (isEmpty(response?.data)) return;

        yield put(setProjectExclusionLists(response.data));
    } catch (e) {
        handleException(e);
        console.error("***getProjectExclusionListsSaga err=", e);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitDeleteExclusionListSaga(action: DeleteContactListPayload): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `/exclusionList/delete`, {
            ids: action.payload.ids,
        });

        yield put(getExclusionLists());
        yield put(getProjectExclusionLists(+action.payload.projectId));

        if (!response?.success) {
            throw new Error();
        } else {
            yield put(setModal({ key: "showDeleteExclusionListModal", value: false }));
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Deletion of exclusion lists failed. Please try again later."));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* fetchExclusionListForCSVSaga(action: FetchExclusionListForCSVPayload): SagaIterator {
    const id = action.payload.id;
    try {
        yield put(startAction({ action: `${action.type}-${id}` }));
        const response: FetchExclusionListForCSVResponse = yield call(new API().get, `/exclusionList/${id}`);
        if (response?.data?._id) {
            let csvData;

            if (response?.data?.emails?.length) {
                csvData = response?.data?.emails?.map((email) => ({
                    email,
                    company: "",
                }));
            }

            if (response?.data?.companies?.length) {
                csvData = response?.data?.companies?.map((company) => ({
                    company,
                    email: "",
                }));
            }

            yield put(
                setExclusionListCSVData({
                    name: response?.data?.name || "",
                    data: csvData,
                })
            );

            setTimeout(() => {
                action.payload.handleLinkClick();
            }, 300);
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Unexpected error occurred while fetching exclusion list"));
    } finally {
        yield put(stopAction({ action: `${action.type}-${id}` }));
    }
}

function* editExclusionListStatusSaga(action: EditExclusionListStatusPayload): SagaIterator {
    const id = action.payload.id;
    const type = action.payload.type;
    try {
        yield put(startAction({ action: action.type }));
        const state = yield select();
        const projectExclusionLists = get(state, "exclusionList.projectExclusionLists");
        const exclusionLists = get(state, "exclusionList.exclusionLists");
        const updatedProjectExclusionLists = projectExclusionLists?.map((item) =>
            item._id === id ? { ...item, type: type } : item
        );
        const updatedExclusionLists = exclusionLists?.map((item) => (item._id === id ? { ...item, type: type } : item));
        yield put(setExclusionLists(updatedExclusionLists));
        yield put(setProjectExclusionLists(updatedProjectExclusionLists));
        const response = yield call(new API().post, `/exclusionList/status-change`, { id, type });
        if (response?.success) {
            yield put(setSuccessNotification("List status updated successfully"));
        } else {
            yield put(setExclusionLists(exclusionLists));
            yield put(setProjectExclusionLists(projectExclusionLists));
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Unexpected error occurred while updating exclusion list's status"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(addExclusionList.type, addExclusionListSaga),
        // @ts-ignore
        yield takeLatest(getExclusionLists.type, getExclusionListsSaga),
        // @ts-ignore
        yield takeLatest(submitDeleteExclusionList.type, submitDeleteExclusionListSaga),
        // @ts-ignore
        yield takeLatest(fetchExclusionListForCSV.type, fetchExclusionListForCSVSaga),
        // @ts-ignore
        yield takeLatest(editExclusionList.type, editExclusionListSaga),
        // @ts-ignore
        yield takeLatest(getProjectExclusionLists.type, getProjectExclusionListsSaga),
        // @ts-ignore
        yield takeLatest(editExclusionListStatus.type, editExclusionListStatusSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
