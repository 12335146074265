import * as Sentry from "@sentry/react";

export default function handleException(err: any) {
    Sentry.captureException(err);
}

/**
 * Starts a custom Sentry transaction for tracking.
 * @returns {Sentry.Transaction | undefined} The created transaction or undefined if not started.
 */
export function startTracking(name: string, op?: string) {
    const transaction = Sentry.startTransaction({
        name,
        op, // Optional: operation name, helps categorize the transaction
    });

    // Set the transaction on the current scope
    Sentry.configureScope((scope) => {
        scope.setSpan(transaction);
    });

    return transaction;
}

/**
 * Ends a custom Sentry transaction.
 * @param {Sentry.Transaction | undefined} transaction - The transaction to finish.
 */
export function endTracking(transaction: Sentry.Transaction | undefined) {
    if (transaction) {
        transaction.finish(); // Finish the transaction and send it to Sentry
    }
}
