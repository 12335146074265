import { Suspense } from "react";

import { lazyImport } from "../../utils/lazy-import";

const LazyDocumentViewer = lazyImport(() => import("./DocumentViewerComponent"));

export default function DocumentViewer(props: Parameters<typeof LazyDocumentViewer>[0]) {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <LazyDocumentViewer {...props} />
        </Suspense>
    );
}
