import { CountryCodes } from "../constant/countryCodes";

export const verifyCountry = (place: string) => {
   const country = place?.split(",")?.at(-1)  
    for (const [key, value] of Object.entries(CountryCodes)) {
        if (value?.name === country) {
            return value;
        }
    }
    
    return { code: "US", name: "United States" };
};
