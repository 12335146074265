import { useState } from "react";

export function getUsedSpaceOfLocalStorageInBytes() {
    // Returns the total number of used space (in Bytes) of the Local Storage
    let b = 0;
    for (const key in window.localStorage) {
        if (window.localStorage.hasOwnProperty(key)) {
            b += key.length + localStorage.getItem(key).length;
        }
    }
    return b;
}

export function getUnusedSpaceOfLocalStorageInBytes() {
    const testQuotaKey = "testQuota";
    const timeout = 20000;
    const startTime = new Date().getTime();

    let maxByteSize = 10485760; // 10MB
    let minByteSize = 0;
    let tryByteSize = 0;
    let unusedSpace = 0;
    let runtime = 0;

    do {
        runtime = new Date().getTime() - startTime;
        try {
            tryByteSize = Math.floor((maxByteSize + minByteSize) / 2);
            //localStorage.setItem(testQuotaKey, new Array(tryByteSize).join('1'));

            //Recommended by @pkExec and @jrob007
            localStorage.setItem(testQuotaKey, String("1").repeat(tryByteSize));
            minByteSize = tryByteSize;
        } catch (e) {
            maxByteSize = tryByteSize - 1;
        }
    } while (maxByteSize - minByteSize > 1 && runtime < timeout);

    localStorage.removeItem(testQuotaKey);

    if (runtime >= timeout) {
        console.log("Unused space calculation may be off due to timeout.");
    }

    // Compensate for the byte size of the key that was used, then subtract 1 byte because the last value of the tryByteSize threw the exception
    unusedSpace = tryByteSize + testQuotaKey.length - 1;
    return unusedSpace;
}

export function getLocalStorageQuotaInBytes() {
    // Returns the total Bytes of Local Storage Space that the browser supports
    const unused = getUnusedSpaceOfLocalStorageInBytes();
    const used = getUsedSpaceOfLocalStorageInBytes();
    const quota = unused + used;
    return quota;
}

const read = (key: string) => {
    if (typeof window === "undefined") return null;
    try {
        const item = window.localStorage.getItem(key);
        return item && (item !== undefined || item !== "undefined") ? JSON.parse(item) : null;
    } catch (error) {
        console.warn(`Error reading localStorage key “${key}”:`, error); // eslint-disable-line no-console
        return null;
    }
};

const set = (key: string, value: any) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error); // eslint-disable-line no-console
        remove(key);
    }
};

const remove = (key: string) => {
    window.localStorage.removeItem(key);
};

const initiate = (key: string, value: any) => {
    const stored = read(key);
    if (stored) return stored;
    set(key, value);
    return value;
};

const readAll = () => {
    if (typeof window === "undefined") return null;
    const items: { [key: string]: any } = {};
    Object.keys(window.localStorage).forEach((key) => {
        items[key] = read(key);
    });
    return items;
};

const keys = () => {
    if (typeof window === "undefined") return null;
    return Object.keys(window.localStorage);
};

export const getlocalStorage = {
    read,
    set,
    remove,
    initiate,
    readAll,
    keys,
};

export default function uselocalStorage(key: string, initialValue: any) {
    const [storedValue, setStoredValue] = useState<typeof initialValue>(initiate(key, initialValue));

    const setValue = (value: any) => {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        set(key, valueToStore);
        setStoredValue(valueToStore);
    };

    return [storedValue, setValue]; // optional (readValue, removeValue)
}
