import Box from "@mui/material/Box";
import { ReactNode } from "react";

interface EmptyChartStateProps {
    children: ReactNode;
}

export default function EmptyChartState(props: EmptyChartStateProps) {
    const { children } = props;
    return (
        <Box
            fontSize={14}
            borderRadius={1}
            py={1}
            px={1}
            my={1}
            sx={{
                borderColor: "rgba(0,0,0,0.5)",
                borderStyle: "dashed",
                display: "grid",
                placeItems: "center",
                borderWidth: 1,
            }}
        >
            {children}
        </Box>
    );
}
