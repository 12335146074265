import DescriptionIcon from "@mui/icons-material/Description";
import { Button, Chip, FormControlLabel, Radio, RadioGroup, Stack, Switch, TextField, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { EXCLUSION_FIELD, EXCLUSION_LIST_TYPE, addExclusionList } from "./ExclusionList.reducer";

import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { setModal } from "../../store/reducers/modals.slice";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../utils/helper";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";
import { setErrorNotification } from "../Notification/index.reducer";

type CsvUploadProps = {
    handleCsvUpload: (key: File[]) => void;
};

export function CsvUpload({ handleCsvUpload }: CsvUploadProps) {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        });
        handleCsvUpload(files);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "text/csv": [".csv"],
            },
            onDrop,
        });

    const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
        <Stack key={file.path} direction={"row"} alignItems={"center"} spacing={0.5}>
            <DescriptionIcon />
            <Typography>{file.path}</Typography>
        </Stack>
    ));

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <Stack>
            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                <Typography fontWeight="bold">Imp headers:</Typography>
                <Chip label="Email" />
                <Typography>OR</Typography>
                <Chip label="Company" />
            </Stack>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive
                    ? "Drop CSV here ..."
                    : "Click to upload your CSV or drag and drop (Allowed formats: .csv)"}
            </div>
            <Stack mt={1} spacing={0.5}>
                {acceptedFileItems}
            </Stack>
        </Stack>
    );
}

export default function AddExclusionList() {
    const dispatch = useDispatch();
    const isAddingList = useSelector(checkIfLoading(addExclusionList.type));
    const close = () => dispatch(setModal({ key: "showAddExclusionListModal", value: false }));

    const [name, setName] = useState("");
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [type, setType] = useState<EXCLUSION_LIST_TYPE>("LOCAL");
    const [listData, setListData] = useState<EXCLUSION_FIELD>("EMAIL");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setListData(event.target.value as EXCLUSION_FIELD);
    };

    const handleAddList = () => {
        if (!name || !csvFile) {
            dispatch(setErrorNotification(!name ? "Name is required" : "CSV file is required"));
            return;
        }
        dispatch(
            addExclusionList({
                name,
                csvFile,
                onSuccess: close,
                exclusionListType: type,
                exclusionField: listData,
            })
        );
    };

    return (
        <BaseModal
            onClose={close}
            overlayStyles={{
                padding: "1rem 1.5rem",
            }}
        >
            <Stack spacing={2}>
                <Typography fontSize={20} fontWeight={500} color="#334d6e">
                    Add List
                </Typography>
                <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                <CsvUpload handleCsvUpload={(file) => setCsvFile(file[0])} />
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1">Exclusion List Type</Typography>
                    <FormControlLabel
                        control={
                            <Switch checked={type === "GLOBAL"} onChange={(_, v) => setType(v ? "GLOBAL" : "LOCAL")} />
                        }
                        label={type?.slice(0, 1)?.toUpperCase() + type?.slice(1)?.toLowerCase()}
                    />
                </Stack>
                <Stack>
                    <Typography variant="body1">Exclusion List Data</Typography>
                    <RadioGroup
                        value={listData}
                        onChange={handleChange}
                        sx={{
                            flexDirection: "row",
                        }}
                    >
                        <FormControlLabel value="EMAIL" control={<Radio />} label="Email" />
                        <FormControlLabel value="COMPANY" control={<Radio />} label="Company" />
                    </RadioGroup>
                </Stack>
                <Stack alignItems="center">
                    <Button variant="contained" disabled={isAddingList} onClick={handleAddList}>
                        <ButtonTextWithLoading isLoading={isAddingList} text="Submit" />
                    </Button>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
