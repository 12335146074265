import { useMemo, useState } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { nanoid } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import useMenu from "../../../hooks/useMenu";
import { getComparator, stableSort } from "../../ContactList/utils/utils";
import ContactListItemsToolbar from "../components/ContactListItemsToolbar";
import ContactListItemsPagination from "../components/ContactlistItemsPagination";
import {
    handleContactListItemsRowClick,
    selectContactListItemsPage,
    selectContactListItemsRows,
    selectContactListItemsRowsPerPage,
    selectContactListItemsSearchQuery,
    selectContactListItemsSelected,
    selectContactListItemsTableOrder,
    selectContactListItemsTableOrderBy,
} from "../contact-list-items.slice";
import { ContactListItem } from "../contact-list-items.type";
import ContactListItemsEnhancedHead from "./ContactListItemEnhancedTableHead";

const CustomButton = styled(Button)<ButtonProps>(() => ({
    width: "fit-content",
    height: "fit-content",
    fontSize: "12px",
}));

function ContactListMenu({ removeHover }: { removeHover: () => void }) {
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu({
        onClose: removeHover,
    });

    const actions = [
        {
            id: nanoid(),
            label: "Add to project",
            onClick: () => {},
        },
        {
            id: nanoid(),
            label: "Add to exclusion list",
            onClick: () => {},
        },
        {
            id: nanoid(),
            label: "Export",
            onClick: () => {},
        },
        {
            id: nanoid(),
            label: "Delete",
            onClick: () => {},
        },
    ];

    return (
        <>
            <CustomButton variant="outlined" endIcon={<KeyboardArrowDownRoundedIcon />} onClick={handleClick}>
                More
            </CustomButton>
            <Menu sx={menuSX} anchorEl={anchorEl} open={open} onClose={handleClose}>
                {actions.map(({ id, label, onClick }) => {
                    return (
                        <MenuItem key={id} onClick={handleClose}>
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}

function ContactListName({ children, labelId, id }: { children: React.ReactNode; labelId: string; id: string }) {
    // const [isHover, setHover] = useState(false);
    return (
        <TableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            // onMouseEnter={() => setHover(true)}
            // onMouseLeave={() => setHover(false)}
            sx={{ width: "45%" }}
        >
            <Stack direction="row" spacing={3} alignItems="center">
                <Typography variant="body2">{children}</Typography>
                {/* <Link
                    to={`/contacts/${id}`}
                    style={{
                        fontWeight: "600",
                        textDecoration: isHover ? "underline" : "none",
                        color: "#0c6caa",
                    }}
                >
                    {children}
                </Link> */}
                {/* {isHover && (
                    <ContactListMenu removeHover={() => setHover(false)} />
                )} */}
            </Stack>
        </TableCell>
    );
}

function searchItems(query: string, items: ContactListItem[]): ContactListItem[] {
    let searchQuery = query.toLowerCase(); // Convert the query to lowercase for case-insensitive matching

    if (!searchQuery) {
        return items;
    }

    return items.filter((item) => {
        const name = item?.name?.toLowerCase() || "";
        const email = item?.email?.toLowerCase() || "";
        const company = item?.company?.toLowerCase() || "";

        return name.includes(searchQuery) || email.includes(searchQuery) || company.includes(searchQuery);
    });
}
function TableContent() {
    const rows = useSelector(selectContactListItemsRows);
    const page = useSelector(selectContactListItemsPage);
    const order = useSelector(selectContactListItemsTableOrder);
    const orderBy = useSelector(selectContactListItemsTableOrderBy);
    const rowsPerPage = useSelector(selectContactListItemsRowsPerPage);
    const selected = useSelector(selectContactListItemsSelected);
    const searchQuery = useSelector(selectContactListItemsSearchQuery);

    const isRowSelected = (id: string): boolean => selected.indexOf(id) !== -1;

    const visibleRows = useMemo(() => {
        const filteredRowsBySearch = searchItems(searchQuery, rows);

        return stableSort(filteredRowsBySearch, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }, [order, orderBy, page, rowsPerPage, searchQuery, rows]);

    return (
        <>
            {visibleRows.map(({ id, name, company, email }, index) => {
                const isItemSelected = isRowSelected(id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                    <TableRow
                        key={id}
                        hover
                        // onClick={(_) => dispatch(handleRowClick(id))}
                        tabIndex={-1}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                    >
                        {/* <TableCell padding="checkbox" sx={{ width: "5%" }}>
                            <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                onChange={(_) =>
                                    dispatch(handleContactListItemsRowClick(id))
                                }
                            />
                        </TableCell> */}
                        <ContactListName labelId={labelId} id={id}>
                            {name}
                        </ContactListName>
                        <TableCell sx={{ width: "25%" }}>{email}</TableCell>
                        <TableCell sx={{ width: "25%" }}>{company}</TableCell>
                    </TableRow>
                );
            })}
        </>
    );
}

export default function ContactListItemsTableContent() {
    return (
        <Stack spacing={1}>
            <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <ContactListItemsToolbar />
                    <TableContainer sx={{ maxHeight: "80vh" }}>
                        <Table sx={{ minWidth: 750 }} stickyHeader>
                            <ContactListItemsEnhancedHead />
                            <TableBody>
                                <TableContent />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ContactListItemsPagination />
                </Paper>
            </Box>
        </Stack>
    );
}
