import VirtualizedSelect from "@/components/VirtualizedSelect";
import { CreateOutreachRequestPayload } from "@/store/apis/outreach/outreach.types";
import { isArray } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
    selectSelectedCandidates,
    selectProjectCandidates,
    selectProjectFilters,
    selectSearchQuery,
    selectProjectAllCandidates,
    selectProjectTotalCandidates,
    selectShowByPersonalEmails,
} from "@/store/reducers/project/project.reducer";
import { selectUser, checkOutreachVersion } from "@/store/reducers/signin/Signin.reducer";
import handleCleverTap from "@/utils/clevertap";

import { useNavigate } from "react-router-dom";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { setVirtualUserId } from "@/store/reducers/template/template.reducer";
import { useCreateOutreachMutation, useFetchOutreachCandidatesQuery } from "@/store/apis/outreach/outreach.api";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { IStageFilters } from "@/store/reducers/project/project.types";
import { useEffect } from "react";
import { selectOutreachProjectFilters } from "@/store/reducers/outreach/outreach.slice";

function VirtualUserSelect({ data, isLoading }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { projectId, outreachId } = useOutreachParams();
    const { data: candidatesSelected = [] } = useFetchOutreachCandidatesQuery({
        outreachId,
    });
    const [createOutreach] = useCreateOutreachMutation();
    const outreachProjectFilters = useSelector(selectOutreachProjectFilters);
    const filters = outreachProjectFilters?.filterBy;
    const searchQuery = outreachProjectFilters?.search ?? "";
    const selectAllCandidates = outreachProjectFilters?.selectAll ?? false;
    const totalCandidates = useSelector(selectProjectTotalCandidates);
    const showByEmailId = useSelector(selectShowByPersonalEmails);
    const outreachVersion = useSelector(checkOutreachVersion);
    const user = useSelector(selectUser);
    const candidates = useSelector(selectProjectCandidates);
    const selectedCandidates = useSelector(selectSelectedCandidates);

    const candidateIdsSelected = candidatesSelected.map((item) => item?.id);
    const virtualUserCount = user?.virtualUserCount;
    const selectedCandidateIds = selectedCandidates.map((item) => item.candidateId);
    const detailedCandidates = candidates.filter((candidate) => selectedCandidateIds.includes(candidate._id));

    const hasEmailEvery = detailedCandidates.every(
        (candidate) =>
            (isArray(candidate.email) && candidate.email.length) ||
            (isArray(candidate.professional_email) && candidate.professional_email.length)
    );

    const hasNoEmailEvery = detailedCandidates.every(
        (candidate) =>
            isArray(candidate.email) &&
            !candidate.email.length &&
            isArray(candidate.professional_email) &&
            !candidate.professional_email.length
    );

    useEffect(() => {
        return () => {
            dispatch(setVirtualUserId(null));
        };
    }, []);

    const handleClick = (value) => {
        dispatch(setVirtualUserId(value));
        if (outreachVersion === "v2") {
            handleCreateReachout(value);
        } else {
            handleCreateOutreachV1(value);
        }
    };

    const getCreateOutreachV2Payload = (id: any): CreateOutreachRequestPayload => {
        const response: CreateOutreachRequestPayload = {
            candidateIds: candidateIdsSelected,
            projectId: projectId || "",
            filterBy: filters ?? [],
            search: searchQuery,
            selectAll: selectAllCandidates,
        };
        const cTPayload: { candidates: number; projectId: string; partialEmails: boolean; virtualUserId?: number } = {
            candidates: candidateIdsSelected?.length,
            projectId: projectId,
            partialEmails: !hasEmailEvery && !hasNoEmailEvery,
        };
        const isVirtualUser = Number(id) !== user?._id;
        if (isVirtualUser) {
            response.virtualUserId = Number(id);
            cTPayload.virtualUserId = Number(id);
        }
        handleCleverTap("Add to workflow", cTPayload);
        return response;
    };

    const handleCreateOutreachV1 = (id: any) => {
        const response: {
            candidates: string[];
            projectId: string;
            isAllProjectCandidatesSelected: boolean;
            totalCandidates: number;
            filters: IStageFilters;
            searchQuery: string;
            showByPersonalEmails: boolean;
            virtualUserId?: number;
        } = {
            candidates: candidateIdsSelected,
            projectId: projectId,
            isAllProjectCandidatesSelected: selectAllCandidates,
            totalCandidates,
            filters,
            searchQuery,
            showByPersonalEmails: showByEmailId,
        };
        const cTPayload: { candidates: number; projectId: string; partialEmails: boolean; virtualUserId?: number } = {
            candidates: candidateIdsSelected?.length,
            projectId: projectId,
            partialEmails: !hasEmailEvery && !hasNoEmailEvery,
        };
        const isVirtualUser = Number(id) !== user?._id;
        if (isVirtualUser) {
            response.virtualUserId = Number(id);
            cTPayload.virtualUserId = Number(id);
        }
        handleCleverTap("Add to workflow", cTPayload);
        navigate("/workflows", {
            state: response,
        });
    };

    const handleCreateReachout = (id: any) => {
        const requestPayload = getCreateOutreachV2Payload(id);
        const firstCandidateId = requestPayload.candidateIds[0];
        const projectId = requestPayload.projectId;

        createOutreach(requestPayload)
            .unwrap()
            .then((res) => {
                const { workflowId, workflowSteps } = res;
                if (workflowSteps.length) {
                    const stepId = workflowSteps[0]._id;
                    navigate(`/outreach/${projectId}/${workflowId}/${firstCandidateId}/${stepId}/base`);
                } else {
                    throw new Error();
                }
            })
            .catch((err) => {
                dispatch(setErrorNotification("Unexpected error while creating outreach"));
            });
    };

    if ((virtualUserCount && !data?.length) || !virtualUserCount) {
        return null;
    }

    return (
        <VirtualizedSelect
            inputProps={{
                placeholder: "Select user type",
            }}
            size="sm"
            sx={{ width: 400 }}
            variant="soft"
            value={"virtualUserId"}
            options={data?.map((m) => ({ label: m?.name, value: m?._id.toString(), ...m })) ?? []}
            onChange={(selected) => handleClick(selected.value)}
            defaultOption={{ label: "Select user type", value: "0" }}
            loading={isLoading}
            hideDefaultOption
            itemHeight={60}
            renderOption={(item) => {
                return (
                    <div className="flex w-full p-2 min-h-[40px] items-center box-border">
                        <div className="w-full flex flex-wrap justify-between items-center gap-3">
                            <div className="flex flex-col min-w-[120px]">
                                <div className="text-xs font-medium truncate">{item?.name}</div>
                                <div className="text-xs font-medium truncate py-1 text-gray-500">{item?.email}</div>
                            </div>

                            <div className="flex flex-wrap gap-2">
                                {item?.email === user?.email && (
                                    <span className="h-fit text-xs px-2 py-1 rounded bg-yellow-50 text-yellow-700 border border-yellow-700">
                                        Primary
                                    </span>
                                )}

                                {item?.email !== user?.email && (
                                    <span
                                        className={`h-fit text-xs px-2 py-1 rounded ${item?.linkedinAuthStatus === "SUCCESS" ? "bg-green-50 text-green-700 border border-green-700" : "bg-red-50 text-red-700 border border-red-700"}`}
                                    >
                                        LinkedIn
                                    </span>
                                )}

                                {item?.email !== user?.email && (
                                    <span
                                        className={`h-fit text-xs px-2 py-1 rounded ${item?.emailAuthorized ? "bg-green-50 text-green-700 border border-green-700" : "bg-red-50 text-red-700 border border-red-700"}`}
                                    >
                                        Email
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }}
        />
    );
}

export { VirtualUserSelect };
