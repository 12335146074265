/* eslint-disable import/no-unused-modules */
import { zodResolver } from "@hookform/resolvers/zod";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Autocomplete from "@mui/joy/Autocomplete";
import CircularProgress from "@mui/joy/CircularProgress";
import Divider from "@mui/joy/Divider";
import Dropdown from "@mui/joy/Dropdown";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";
import Tooltip from "@mui/joy/Tooltip";
import { Experimental_CssVarsProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Children, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { CandidatesReachoutEmptyPlaceholder } from "./CandidatesReachoutEmptyPlaceholder";
import { CandidateInfoContentContainer } from "./ContactInfo";
import { ScheduleReminderEmailForActivity } from "./ScheduleReminderEmailForActivity";
import {
    SelectProject as SelectProjectFilter,
    SelectProjectFilterProps,
    useFetchCandidateProjects,
    useSelectProjectValues,
} from "./SelectProject";

import { OptionWithValueAsNumber } from "../../../common";
import { useAppDispatch } from "../../../store";
import {
    ActivitiesData,
    CreateActivityPayload,
    SingleActivity,
    useCreateActivityMutation,
    useDeleteActivityMutation,
    useFetchCandidateActivitiesQuery,
    useFetchOrganizationMembersQuery,
    useMarkAsCompleteMutation,
    useUpdateActivityMutation,
} from "../../../store/apis/all-candidates/all-candidates.api";
import { useFetchActivityTags } from "../../all-candidates/AllCandidatesContent";
import { AllCandidatesReachOutBodyContainer } from "../AllCandidatesReachout";

import { cn } from "@/lib/utils";
import { criticalityOptions } from "@/pages/all-candidates/components/ContactOverviewActivities/EditActivityForm";
import {
    editActivity,
    initialEditActivityState,
    selectActivitiesProject,
    selectActivityId,
    selectActivityState,
    selectActivitySubmitMode,
    selectCandidateReachoutsEditDrawer,
    setActivitiesSelectedProject,
    setEditDrawer,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.slice";
import {
    ActivityFormState,
    activityFormStateSchema,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.types";
import { ACTIVITIES_SELECTED_PROJECT, formatDueDate } from "@/store/reducers/all-candidates/all-candidates.utils";
import { checkEasyGrowth, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Card, CardContent, Chip, Textarea, Typography } from "@/ui";
import { Avatar } from "@/ui/Avatar/Avatar";
import { ChipProps } from "@/ui/Chip/Chip";

export function Activity() {
    return (
        <CandidateInfoContentContainer enableEdit={true} editComponent={<ActivitySidebar />}>
            <AllCandidatesReachOutBodyContainer>
                <ActivityHeader />
                <Divider />
                <Stack
                    mx={2}
                    my={1}
                    pb={2}
                    gap={3}
                    sx={() => ({
                        overflow: "auto",
                        height: "calc(100vh - 215px)",
                        borderBottom: 1,
                        borderColor: "divider",
                    })}
                >
                    <ActivitiesMain />
                </Stack>
            </AllCandidatesReachOutBodyContainer>
        </CandidateInfoContentContainer>
    );
}

function ActivityProjectFilter() {
    const dispatch = useAppDispatch();
    const selectedProject = useSelector(selectActivitiesProject);
    const { ...rest } = useSelectProjectValues({ project: selectedProject });

    const handleSelectChange: SelectProjectFilterProps["handleSelectChange"] = (value) => {
        dispatch(setActivitiesSelectedProject(value));
    };
    return (
        <SelectProjectFilter
            {...rest}
            projects={[
                {
                    label: ACTIVITIES_SELECTED_PROJECT.label,
                    value: ACTIVITIES_SELECTED_PROJECT.value,
                },
                ...rest.projects,
            ]}
            selectedProject={selectedProject}
            handleSelectChange={handleSelectChange}
        />
    );
}

function ActivityHeader() {
    const dispatch = useAppDispatch();
    const isDrawerOpen = useSelector(selectCandidateReachoutsEditDrawer);
    const openDrawer = () => dispatch(setEditDrawer("TOGGLE"));
    return (
        <div className="flex justify-between mr-[2.5rem] p-[1rem] h-[70px]">
            <ActivityProjectFilter />
            {!isDrawerOpen && (
                <Button
                    size="sm"
                    startDecorator={<AddCircleRoundedIcon sx={{ color: "white" }} />}
                    onClick={openDrawer}
                    label="Log an activity"
                />
            )}
        </div>
    );
}

function ActivitiesMain() {
    const params = useParams();
    const { isLoading, isError, data = {} } = useFetchCandidateActivitiesQuery(params?.id || "");
    const selectedProjectId = useSelector(selectActivitiesProject).value;

    if (isLoading) {
        return <ActivitiesLoader />;
    }

    if (isError || isEmpty(data)) {
        return (
            // TODO: do it at the end, used in many components
            <EmptyActivitiesPlaceholder />
        );
    }

    const activitiesFilteredByProject = Object.entries(data)?.reduce((acc: ActivitiesData, [key, value]) => {
        acc[key] = value.filter(
            ({ project: { _id } }) =>
                // by default all projects has value = 0, so we don't need to filter when all projects is selected
                _id === selectedProjectId || selectedProjectId === 0
        );
        return acc;
    }, {});

    return (
        <>
            {Object.entries(activitiesFilteredByProject).map(([year, activities]) => {
                return (
                    <ActivitySection year={year} key={year}>
                        {activities.map((i) => {
                            return <ActivityCard {...i} key={i._id} />;
                        })}
                    </ActivitySection>
                );
            })}
        </>
    );
}

export function ActivitySection({ year, children }: { year: string; children: React.ReactNode }) {
    if (!Children.count(children)) {
        return <EmptyActivitiesPlaceholder />;
    }

    return (
        <Stack gap={1}>
            <Typography className="text-xs">{dayjs(year).format("MMMM YYYY")}</Typography>
            <Divider sx={{ mb: 1 }} />
            <Stack gap={1.5}>{children}</Stack>
        </Stack>
    );
}

type MenuOptions = {
    label: string;
    handleClick: () => void;
    disabled?: boolean;
};

export const MARK_AS_COMPLETE = "Mark as complete";

export const EDIT = "Edit";

const CustomChip = (props: ChipProps & { color?: string }) => {
    const { children, variant, color } = props;

    const classes = cn(
        "text-[10px] rounded-md font-medium px-1.5",
        {
            "text-primary-foreground": variant !== "outline",
            "text-default-700": variant === "outline" && color === "default",
            "text-success-700": variant === "outline" && color === "success",
        },
        {
            "bg-default-700 hover:bg-default-700": variant !== "outline" && color === "default",
            "bg-success-700 hover:bg-success-700": variant !== "outline" && color === "success",
        },
        {
            "border border-default-400": variant === "outline" && color === "default",
            "border border-success-400": variant === "outline" && color === "success",
        }
    );
    return (
        <Chip variant={variant || "default"} className={classes}>
            {children}
        </Chip>
    );
};

function ActivityActionsMenu({ children }: { children: React.ReactNode }) {
    const [open, setOpen] = useState(false);

    const handleOpenChange = useCallback((event: React.SyntheticEvent | null, isOpen: boolean) => {
        setOpen(isOpen);
    }, []);

    return (
        <Dropdown open={open} onOpenChange={handleOpenChange}>
            <MenuButton slots={{ root: IconButton }}>
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu style={{ zIndex: 100000 }}>{children}</Menu>
        </Dropdown>
    );
}

export function ActivityCard({
    _id,
    body,
    timestamp,
    project,
    tag,
    referTo,
    createdBy,
    read,
    handleEdit,
    variant = "CANDIDATE_REACHOUT",
    priority,
    dueDate,
}: SingleActivity & {
    handleEdit?: (value: string) => void;
    variant?: "CANDIDATE_REACHOUT" | "CANDIDATE_PROFILE_CARD";
}) {
    const dispatch = useDispatch();
    const divRef = useRef<HTMLDivElement>(null);
    const userId = useSelector(selectUser)._id;
    const { name: projectName, _id: pId } = project;
    const { name, profileImage } = createdBy;
    const [deleteActivity, { isLoading: isDeleting }] = useDeleteActivityMutation();
    const [markAsComplete, { isLoading: isMarkingAsComplete }] = useMarkAsCompleteMutation();
    const [isHashMatched, setHashMatched] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const activityId = searchParams.get("activityId");
        if (_id === activityId) {
            setHashMatched(true);

            setTimeout(() => {
                setHashMatched(false);
            }, 5000);

            divRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [_id, searchParams]);

    const menuOptions: MenuOptions[] = [
        {
            label: EDIT,
            handleClick: () => {
                if (variant === "CANDIDATE_PROFILE_CARD") {
                    handleEdit?.(_id);
                } else {
                    dispatch(
                        editActivity({
                            dueDate: dayjs(dueDate),
                            priority,
                            id: _id,
                            assignee:
                                referTo?._id && referTo?.name
                                    ? {
                                          value: referTo?._id,
                                          label: referTo?.name,
                                      }
                                    : null,
                            body,
                            tag: {
                                label: tag,
                                value: tag,
                            },
                            project: {
                                label: projectName,
                                value: pId,
                            },
                        })
                    );
                }
            },
        },
        {
            label: "Delete",
            handleClick: () => deleteActivity({ activityId: _id }),
            disabled: isDeleting,
        },
        {
            label: MARK_AS_COMPLETE,
            handleClick: () => markAsComplete({ activityId: _id }),
            disabled: isMarkingAsComplete,
        },
    ];

    return (
        <div ref={divRef}>
            <Card
                variant="outlined"
                className="flex gap-3 p-2 w-full flex-row justify-between bg-[#f0f4f8] hover:shadow-[0_8px_10px_-5px] hover:shadow-slate-200 "
            >
                <CardContent className="flex gap-2 flex-row p-3">
                    <Avatar
                        className="h-[56px] w-[56px] bg-[#e3effb] text-[#12467b] p-2"
                        src={profileImage && profileImage !== "-" ? profileImage : ""}
                        alt={name}
                        fallback={
                            <svg
                                className="w-40 h-40 bg-[#e3effb]"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                            </svg>
                        }
                    />
                    <div className="flex flex-col gap-1 w-full">
                        <div className="flex items-center gap-2">
                            <Typography className="text-xs">{name}</Typography>
                            {tag && (
                                <CustomChip color="default" variant="default">
                                    {tag}
                                </CustomChip>
                            )}
                            {referTo?.name && (
                                <CustomChip color="default" variant="outline">
                                    {referTo?.name}
                                </CustomChip>
                            )}
                            {priority && (
                                <Chip
                                    variant="secondary"
                                    className={cn("text-[10px] font-semibold px-1.5 cursor-pointer", {
                                        "bg-red-200 hover:bg-red-100 text-red-500": priority === "HIGH",
                                        "bg-orange-200 hover:bg-orange-100 text-orange-600": priority === "MEDIUM",
                                        "bg-blue-200 hover:bg-blue-100 text-blue-700": priority === "LOW",
                                    })}
                                >
                                    {" "}
                                    {priority}
                                </Chip>
                            )}
                            {dueDate && (
                                <Typography className="text-[11px] px-2 bg-primary-50 rounded-[4px] h-fit">
                                    {formatDueDate(dueDate)}
                                </Typography>
                            )}
                        </div>
                        <Typography className="break-words">
                            <Typography
                                component={"span"}
                                className={`text-xs ${isHashMatched ? "bg-warning-600/80" : "bg-transparent"}`}
                            >
                                {body}
                            </Typography>
                        </Typography>
                        <Stack direction="row" gap={1}>
                            <Typography className="text-xs italic">
                                {dayjs(timestamp).format("DD MMM YYYY, h:mm A")}
                            </Typography>
                            <Divider orientation="vertical" />
                            <Typography className="text-xs">{projectName}</Typography>
                        </Stack>
                    </div>
                </CardContent>
                <Stack justifyContent="space-between">
                    <Stack direction="row" height="fit-content" justifyContent="flex-end">
                        <ScheduleReminderEmailForActivity activityId={_id} />
                        <ActivityActionsMenu>
                            {menuOptions.map((i) => {
                                const { label, handleClick } = i;
                                if (label === MARK_AS_COMPLETE && (referTo?._id !== userId || read)) {
                                    return null;
                                }
                                return <MenuItem onClick={handleClick}>{label}</MenuItem>;
                            })}
                        </ActivityActionsMenu>
                    </Stack>
                    {read && (
                        <CustomChip variant="default" color="success">
                            completed
                        </CustomChip>
                    )}
                </Stack>
            </Card>
        </div>
    );
}

function ActivitiesCardLoader() {
    return (
        <Stack gap={1}>
            <Skeleton variant="text" sx={{ fontSize: "1.5rem", maxWidth: "8rem" }} />
            <Divider sx={{ mb: 1 }} />
            <Stack gap={1.5}>
                {Array(2)
                    .fill(1)
                    .map((_, idx) => {
                        return <Skeleton variant="rectangular" height={30} key={`activity-${idx}`} />;
                    })}
            </Stack>
        </Stack>
    );
}

function SelectProject() {
    const { control } = useActivityFormState();
    const { data: projects = [], isLoading } = useFetchCandidateProjects();
    const options = useMemo(() => {
        return projects.map(({ projectId, projectName }) => ({
            label: projectName,
            value: projectId,
        }));
    }, [projects]);
    const [query, setQuery] = useState("");

    return (
        <>
            <Controller
                control={control}
                name="project"
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <FormControl error={Boolean(error)}>
                            <FormLabel>Select Project</FormLabel>
                            <Autocomplete
                                size="sm"
                                value={isEmpty(value) ? undefined : value}
                                options={options}
                                onChange={(_event, value) => onChange(value)}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, selected) => {
                                    return option.value === selected.value;
                                }}
                                openOnFocus={true}
                                inputValue={query}
                                onInputChange={(_, newInputValue) => {
                                    setQuery(newInputValue);
                                }}
                                endDecorator={
                                    isLoading ? (
                                        <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} />
                                    ) : null
                                }
                            />
                            {error !== undefined && <FormHelperText>Please select project.</FormHelperText>}
                        </FormControl>
                    );
                }}
            />
        </>
    );
}

function TagContainer({ children, setEnabledFreeText }: { children: React.ReactNode; setEnabledFreeText: () => void }) {
    return (
        <Stack
            sx={{
                display: "grid",
                gridTemplateColumns: "1fr auto",
                gridTemplateRows: "1fr",
                gap: "0.5rem",
            }}
        >
            {children}
            <Tooltip title="create tag">
                <IconButton size="sm" onClick={setEnabledFreeText} sx={{ alignSelf: "end" }}>
                    <ModeEditRoundedIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function SelectTag() {
    const methods = useFormContext();
    const project: OptionWithValueAsNumber | null = methods.watch("project");
    const openEditDrawer = useSelector(selectCandidateReachoutsEditDrawer);
    const [enableFreeText, setEnabledFreeText] = useState(false);
    const { control } = useActivityFormState();
    const { isLoading, data = [] } = useFetchActivityTags({
        defaultProjectIds: project?.value ? [project.value] : [],
    });
    const options = useMemo(() => data.map((i) => ({ label: i, value: i })), [data]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        setEnabledFreeText(false);
    }, [openEditDrawer]);

    const handleEnabledFreeText = () => {
        setQuery("");
        setEnabledFreeText((prev) => !prev);
    };

    if (enableFreeText) {
        return (
            <Controller
                name="tag"
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    const isError = error !== undefined;
                    return (
                        <FormControl error={isError}>
                            <FormLabel>Create Tag</FormLabel>
                            <TagContainer setEnabledFreeText={handleEnabledFreeText}>
                                <Input
                                    placeholder="Enter text to create tag..."
                                    size="sm"
                                    error={isError}
                                    value={value?.value || ""}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        onChange({ label: value, value });
                                    }}
                                />
                            </TagContainer>
                            {isError && <FormHelperText>Please enter tag.</FormHelperText>}
                        </FormControl>
                    );
                }}
            />
        );
    }

    return (
        <Controller
            control={control}
            name="tag"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <FormLabel>Select Tag</FormLabel>
                        <TagContainer setEnabledFreeText={handleEnabledFreeText}>
                            <Autocomplete
                                size="sm"
                                value={value}
                                options={options}
                                onChange={(_event, value) => {
                                    onChange(value);
                                }}
                                isOptionEqualToValue={(option, selected) => {
                                    return option.value === selected.value;
                                }}
                                inputValue={query}
                                onInputChange={(_, newInputValue) => {
                                    setQuery(newInputValue);
                                }}
                                endDecorator={
                                    isLoading ? (
                                        <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} />
                                    ) : null
                                }
                            />
                        </TagContainer>
                        {error !== undefined && <FormHelperText>Please select tag.</FormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

function ActivityBody() {
    const { control } = useActivityFormState();

    return (
        <Controller
            control={control}
            name="body"
            render={({ field, fieldState: { error } }) => {
                return (
                    <div className="flex flex-col gap-2">
                        <Typography variant="body2" className="m-0 font-medium">
                            Body
                        </Typography>
                        <Textarea
                            {...field}
                            style={{ width: "100%", fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            className={`min-w-[300px] min-h-[300px] text-sm px-3 py-0.5 border rounded focus:outline-none focus:ring-2 
                                    ${error !== undefined ? "border-red-600 focus-visible:ring-red-600" : "border-gray-300 focus-visible:ring-blue-500 focus-visible:border-none"}`}
                            error={error !== undefined ? error?.message : undefined}
                            value={field.value || ""}
                            onChange={(e) => field.onChange(e.target.value)}
                        />
                    </div>
                );
            }}
        />
    );
}

function useActivityFormState() {
    const { ...rest } = useFormContext<ActivityFormState>();

    return { ...rest };
}

function SelectAssignee() {
    const { control } = useActivityFormState();
    const { isLoading, data = [] } = useFetchOrganizationMembersQuery();
    const options = useMemo(() => data.map(({ name, _id }) => ({ label: name, value: _id })), [data]);
    const [query, setQuery] = useState("");
    return (
        <Controller
            control={control}
            name="assignee"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <FormLabel>Select Assignee</FormLabel>
                        <Autocomplete
                            size="sm"
                            value={value}
                            options={options}
                            onChange={(_event, value) => onChange(value)}
                            isOptionEqualToValue={(option, selected) => {
                                return option.value === selected.value;
                            }}
                            inputValue={query}
                            onInputChange={(_, newInputValue) => {
                                setQuery(newInputValue);
                            }}
                            endDecorator={
                                isLoading ? <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} /> : null
                            }
                        />
                        {error !== undefined && <FormHelperText>Please Select Assignee.</FormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

function SelectPriority() {
    const { control } = useActivityFormState();
    return (
        <Controller
            control={control}
            name="priority"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <FormLabel>Select Priority</FormLabel>
                        <Select value={value} onChange={(_, newValue) => onChange(newValue)} size="sm">
                            <Option
                                value={""}
                                sx={{
                                    fontSize: "14px",
                                }}
                            >
                                None
                            </Option>
                            {criticalityOptions.map((i) => {
                                return (
                                    <Option
                                        value={i}
                                        key={i}
                                        sx={{
                                            fontSize: "14px",
                                        }}
                                    >
                                        {i}
                                    </Option>
                                );
                            })}
                        </Select>
                        {error !== undefined && <FormHelperText>Please Select Priority.</FormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

export function SelectDueDate() {
    const { control } = useActivityFormState();
    return (
        <Experimental_CssVarsProvider>
            <Controller
                control={control}
                name="dueDate"
                render={({ field, fieldState: { error } }) => (
                    <div className="relative w-full">
                        <label className="block text-sm font-medium text-gray-900 mb-1 font-inter">
                            Select Due date
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                disablePast
                                slotProps={{
                                    field: { clearable: true },
                                    actionBar: {
                                        actions: ["clear"],
                                    },
                                    textField: {
                                        variant: "standard",
                                        error: Boolean(error),
                                        helperText: error !== undefined ? "Please select valid time value" : null,
                                        FormHelperTextProps: { sx: { marginX: 0 } },
                                    },
                                }}
                                sx={(theme) => {
                                    const fontSize = theme.typography.body2;
                                    return {
                                        fontFamily: "Inter",
                                        width: "95%",
                                        marginTop: "0.2rem",
                                        "& .MuiFormLabel-root ": {
                                            ...fontSize,
                                            top: "-5px",
                                            '&[data-shrink="true"]': {
                                                top: "1px",
                                            },
                                        },
                                        "& .MuiInputBase-input": {
                                            ...fontSize,
                                            padding: "0.7rem",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline legend": {
                                            width: "57px",
                                        },
                                    };
                                }}
                                {...field}
                            />
                        </LocalizationProvider>
                    </div>
                )}
            />
        </Experimental_CssVarsProvider>
    );
}

function ActivitySidebar() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const activityFormState = useSelector(selectActivityState);
    const submitMode = useSelector(selectActivitySubmitMode);
    const activityId = useSelector(selectActivityId);
    const [createActivity, { isLoading: isCreatingActivity, isSuccess: isCreatingActivitySuccess }] =
        useCreateActivityMutation();
    const [updateActivity, { isLoading: isUpdatingActivity, isSuccess: isUpdatingActivitySuccess }] =
        useUpdateActivityMutation();
    const isLoading = isCreatingActivity || isUpdatingActivity;
    const methods = useForm<ActivityFormState>({
        defaultValues: {
            ...activityFormState,
            dueDate: activityFormState?.dueDate ? dayjs(activityFormState.dueDate) : null,
        },
        resolver: zodResolver(activityFormStateSchema),
    });

    useEffect(() => {
        if (submitMode === "EDIT") {
            methods.reset({
                ...activityFormState,
                dueDate: activityFormState?.dueDate ? dayjs(activityFormState.dueDate) : null,
            });
        }

        if (submitMode === "CREATE") {
            methods.reset(initialEditActivityState);
        }
    }, [activityFormState, submitMode]);

    useEffect(() => {
        if (isCreatingActivitySuccess || isUpdatingActivitySuccess) {
            methods.reset(initialEditActivityState);
            dispatch(setEditDrawer("CLOSE"));
        }
    }, [isCreatingActivitySuccess, isUpdatingActivitySuccess, methods]);

    const onSubmit: SubmitHandler<ActivityFormState> = (data) => {
        const { body, project, tag, assignee, dueDate, priority } = data;
        if (params?.id && tag && project?.value) {
            const payload: CreateActivityPayload = {
                body: body,
                projectId: project.value,
                title: typeof tag === "string" ? tag : tag?.value,
                candidateId: params?.id,
                dueDate: dueDate ? dueDate.toISOString() : undefined,
                priority,
            };

            if (assignee?.value) {
                payload.referTo = assignee?.value;
            }

            if (submitMode === "CREATE") {
                createActivity(payload);
            }

            if (submitMode === "EDIT" && activityId) {
                updateActivity({ ...payload, activityId });
            }
        }
    };

    const btnText = submitMode === "EDIT" ? "Update" : "Create";

    const handleCancel = () => {
        dispatch(setEditDrawer("CLOSE"));
        methods.reset(initialEditActivityState);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full">
                <div className="flex flex-col p-[1rem] gap-[1rem] h-[calc(100vh-135px)] overflow-auto border-b-[1px] border-divider">
                    <SelectProject />
                    <SelectTag />
                    <SelectAssignee />
                    <SelectPriority />
                    <SelectDueDate />
                    <ActivityBody />
                    <div className="flex justify-end gap-[1rem]">
                        <Button type="reset" onClick={handleCancel} size="sm" variant="outline" label="Cancel" />
                        <Button
                            type="submit"
                            disabled={isLoading}
                            loading={isLoading}
                            size="sm"
                            variant="default"
                            label={btnText}
                        />
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}

export function ActivitiesLoader() {
    return (
        <>
            {Array(3)
                .fill(1)
                .map((_, idx) => {
                    return <ActivitiesCardLoader key={`activities-loader-${idx}`} />;
                })}
        </>
    );
}

export function EmptyActivitiesPlaceholder() {
    const isEasyGrowth = useSelector(checkEasyGrowth);
    return (
        <CandidatesReachoutEmptyPlaceholder
            icon={<BallotRoundedIcon sx={{ fontSize: "4rem", color: "#bdbdbd" }} />}
            title="No Activity found"
            message={`Activities has not been added for this ${isEasyGrowth ? "prospect" : "candidate"} on this project.`}
        />
    );
}
