import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Avatar from "@mui/joy/Avatar";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useEffect, useState } from "react";

import Footer from "./Footer";

import { useGetLinkedinConversationsQuery } from "../../../../store/apis/super-inbox/super-inbox.api";
import { EUser } from "../../../../store/apis/super-inbox/super-inbox.enums";
import { useSuperInboxContext } from "../../Context";
import { ChannelProps } from "../channels/ChannelTabs";
import ChatBubble from "../messages/ChatBubble";
import MessagesConversationContainer from "../messages/MessagesConversationContainer";

interface LinkedinProps extends ChannelProps {}

export default function LinkedIn(props: LinkedinProps) {
    const { candidateProfile, setTags } = props;
    const [isFromRecruiter, setIsFromRecruiter] = useState(false);
    // const user = useSelector(selectUser);

    const {
        filters: { projectId, userId },
        candidateParamId,
    } = useSuperInboxContext();

    const { isLoading, isFetching, data } = useGetLinkedinConversationsQuery({
        candidateId: candidateParamId,
        projectIds: [projectId],
        userId,
    });

    const someFromRecruiter = data?.messages?.length ? data.messages.some((m) => m.fromRecruiter) : false;
    const enableTabs = data?.isRecruiter || someFromRecruiter;

    useEffect(() => {
        setTags({
            tags: data?.tags ?? [],
            projects: data?.projects ?? [],
        });

        if (data?.messages?.length) {
            setIsFromRecruiter(someFromRecruiter);
        }
    }, [data]);

    const messages = (data?.messages || []).filter((message) =>
        isFromRecruiter ? message.fromRecruiter : !message.fromRecruiter
    );

    return (
        <>
            {enableTabs && (
                <Stack px={1} direction={"row"} zIndex={2} gap={1} position="sticky" top={0} bgcolor="white">
                    <IconButton
                        size="sm"
                        color={isFromRecruiter ? "primary" : "neutral"}
                        sx={{
                            padding: 1,
                            gap: 1,
                            display: "flex",
                            background: isFromRecruiter ? "#E3EFFB" : "inherit",
                        }}
                        onClick={() => {
                            setIsFromRecruiter(true);
                        }}
                    >
                        <EmailIcon />
                        <Typography fontSize={14}>Recruiter InMail</Typography>
                    </IconButton>
                    <IconButton
                        color={!isFromRecruiter ? "primary" : "neutral"}
                        size="sm"
                        sx={{
                            padding: 1,
                            gap: 1,
                            display: "flex",
                            background: !isFromRecruiter ? "#E3EFFB" : "inherit",
                        }}
                        onClick={() => {
                            setIsFromRecruiter(false);
                        }}
                    >
                        <LinkedInIcon />
                        <Typography fontSize={14}>Connection request</Typography>
                    </IconButton>
                </Stack>
            )}
            <MessagesConversationContainer isLoading={isLoading || isFetching}>
                {messages?.map((message, index) => {
                    const isYou = message.senderType === EUser.USER;
                    return (
                        <Stack
                            key={`email-conversation-${index}`}
                            direction="row"
                            spacing={2}
                            flexDirection={isYou ? "row-reverse" : "row"}
                        >
                            {!isYou && <Avatar size="sm" src={candidateProfile?.profileImage || ""} />}
                            <ChatBubble
                                isQueued={false}
                                isMessageFailed={false}
                                variant={isYou ? "sent" : "received"}
                                body={message.body}
                                time={message.time}
                                senderType={message.senderType}
                                candidateStatus={message.candidateStatus}
                                candidateProfile={candidateProfile}
                                theme="dark"
                            />
                        </Stack>
                    );
                })}
            </MessagesConversationContainer>
            <Footer date={data?.lastUpdated} />
        </>
    );
}
