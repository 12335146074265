import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import resumeLibraryLogo from "../../assets/img/ai-providers-logo/resume-library-logo.png";
import easysourceLogo from "../../assets/img/email-providers-logo/easysource-logo.jpeg";
import monsterLogo from "../../assets/img/email-providers-logo/monster-logo.png";
import rocketReachLogo from "../../assets/img/email-providers-logo/rocket-reach-logo.png";
import { SidebarForm as IndeedSidebarSearchForm } from "../../components/BlendedSearch/Indeed/SidebarForm";
import { SidebarForm as MonsterSidebarSearchForm } from "../../components/BlendedSearch/Monster/SidebarForm";
import { SidebarForm as ResumeLibrarySidebarSearchForm } from "../../components/BlendedSearch/ResumeLibrary/SidebarForm";
import { SidebarForm as RocketReachSidebarSearchForm } from "../../components/BlendedSearch/RocketReach/SidebarForm";
import { SidebarSearchForm as SalesNavSidebarSearchForm } from "../../components/BlendedSearch/SalesNavSearch/SidebarSearchForm";
import { SidebarForm as ZipRecruiterSidebarForm } from "../../components/BlendedSearch/ZipRecruiter/SidebarForm";
import {
    SearchType,
    selectSearchFilterHashIds,
    selectSearchType,
    setCandidatesFetchStatus,
    setSearch,
    setSearchType,
} from "../../store/reducers/searchCandidates/searchCandidates.slice";

import { RootState } from "@/store";
import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    // checkIndeedEnabled,
    checkMonsterEnabled,
    checkResumeLibraryEnabled,
    checkRocketReachEnabled,
    // checkZipRecruiterEnabled,
    isSuperAdminUser,
} from "@/store/reducers/signin/Signin.reducer";
import { Typography } from "@/ui";
import { generateSearchFilterHash } from "@/utils/blended-search";

const getActiveFormConfig = (searchType: SearchType, disabled?: boolean) => {
    switch (searchType) {
        case "resume-library":
            return {
                title: "Find candidates with EasySource AI instead",
                tooltip:
                    "Keep toggle on to find authentic and up-to-date resumes from premium databases partnering with EasySource. This may lead to fewer initial results",
                form: <ResumeLibrarySidebarSearchForm disabled={disabled} />,
            };
        case "salesnav":
            return {
                title: "Find candidate resumes in US instead",
                tooltip:
                    "Keep toggle off to use EasySource AI to find pubilc candidate profiles from multiple sources and the internet",
                form: <SalesNavSidebarSearchForm disabled={disabled} />,
            };
        case "rocketReach":
            return {
                title: "Find candidates with RocketReach",
                tooltip:
                    "Keep toggle on to find authentic and up-to-date resumes from premium databases partnering with EasySource. This may lead to fewer initial results",
                form: <RocketReachSidebarSearchForm disabled={disabled} />,
            };
        case "indeed":
            return {
                title: "Find candidates with Indeed",
                tooltip:
                    "Keep toggle on to find authentic and up-to-date resumes from premium databases partnering with EasySource. This may lead to fewer initial results",
                form: <IndeedSidebarSearchForm disabled={disabled} />, // to be changed
            };
        case "monster":
            return {
                title: "Find candidates with Monster Jobs",
                tooltip:
                    "Keep toggle on to find authentic and up-to-date resumes from premium databases partnering with EasySource. This may lead to fewer initial results",
                form: <MonsterSidebarSearchForm disabled={disabled} />, // to be changed
            };
        case "zipRecruiter": {
            return {
                title: "Find candidates with ZipRecruiter",
                tooltip:
                    "Keep toggle on to find authentic and up-to-date resumes from premium databases partnering with EasySource. This may lead to fewer initial results",
                form: <ZipRecruiterSidebarForm disabled={disabled} />, // to be changed
            };
        }
        default:
            return {};
    }
};

export function SidebarForm({ disabled }: { disabled?: boolean }) {
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const isResumeLibraryEnabled = useSelector(checkResumeLibraryEnabled) || isSuperAdmin;
    const isRocketReachEnabled = useSelector(checkRocketReachEnabled) || isSuperAdmin;
    // const isIndeedEnabled = useSelector(checkIndeedEnabled) || isSuperAdmin;
    const isMonsterEnabled = useSelector(checkMonsterEnabled) || isSuperAdmin;
    // const isZipRecruiterEnabled = useSelector(checkZipRecruiterEnabled) || isSuperAdmin;

    const filterHashIds = useSelector(selectSearchFilterHashIds);
    const searchState = useSelector((state: RootState) => state.search);
    const searchType = useSelector(selectSearchType);

    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const [formsDisabled, setFormsDisabled] = useState<{ [key in SearchType]?: boolean }>({});

    useEffect(() => {
        async function checkCurrentFormDisabled() {
            const hashId = await generateSearchFilterHash(searchState, searchType);
            const savedHashIds = filterHashIds[searchType] || [];
            setFormsDisabled((prev) => ({ ...prev, [searchType]: savedHashIds.includes(hashId) }));
        }
        checkCurrentFormDisabled();
    }, [filterHashIds, searchState, searchType]);

    useEffect(() => {
        if (projectId) {
            dispatch(
                getProject({
                    projectId,
                    action: getProject.type,
                    doNotFetchCandidates: true,
                    setBlendSearchFilters: true,
                })
            );
        }
    }, []);

    const handleSearchTypeChange = (event: SelectChangeEvent<SearchType>) => {
        const newSearchType = event.target.value as SearchType;
        dispatch(setSearchType(newSearchType));
        dispatch(setSearch({ _id: null, status: null, updatedAt: null }));
        dispatch(setCandidatesFetchStatus("IDLE"));
    };

    const { form } = getActiveFormConfig(searchType, disabled || formsDisabled[searchType]);

    const anySearchEnabled = isResumeLibraryEnabled || isRocketReachEnabled || isMonsterEnabled;

    const dropdownOptions = [
        anySearchEnabled && {
            value: "salesnav",
            label: "EasySource AI",
            title: "Best for live/up-to-date profiles with deep search capabilities",
            icon: easysourceLogo,
        },
        isResumeLibraryEnabled && {
            value: "resume-library",
            label: "Resume-Library",
            title: "Fastest-growing database in the US",
            icon: resumeLibraryLogo,
        },
        isRocketReachEnabled && {
            value: "rocketReach",
            label: "RocketReach",
            title: "Suitable for healthcare profiles and niche roles",
            icon: rocketReachLogo,
        },
        // isIndeedEnabled && {
        //     value: "indeed",
        //     label: "Indeed",
        //     title: "Get the best sourced profiles from indeed",
        //     icon: indeedLogo,
        // },
        isMonsterEnabled && {
            value: "monster",
            label: "Monster",
            title: "Get the best sourced profiles from monster jobs",
            icon: monsterLogo,
        },
        // isZipRecruiterEnabled && {
        //     value: "zipRecruiter",
        //     label: "ZipRecruiter",
        //     title: "Get the best sourced profiles from ziprecruiter",
        //     icon: zipRecruiterLogo,
        // },
    ].filter((option): option is { value: string; label: string; title: string; icon } => Boolean(option)); // Type guard added

    return (
        <div>
            {dropdownOptions.length > 0 && (
                <Select
                    value={searchType}
                    onChange={handleSearchTypeChange}
                    fullWidth
                    sx={{
                        backgroundColor: "white",
                        marginBottom: 1,
                        height: "50px",
                        borderRadius: "10px",
                    }}
                >
                    {dropdownOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{ backgroundColor: "white" }}>
                            <div className="flex flex-row items-center gap-1">
                                <img src={option.icon} alt={option.label} style={{ width: 20, height: 20 }} />
                                <Typography>{option.label}</Typography>
                                <div className="flex">
                                    <Typography className="text-gray-500 ml-[10px] mt-[2px] break-words text-[11px]">
                                        {option.title}
                                    </Typography>
                                </div>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            )}
            {form}
        </div>
    );
}
