import { RefObject, useEffect, useRef } from "react";

type usePaginationNavigationProps = {
    totalPage: number;
    page: number;
    onNext: (nextPage: number) => void;
    onPrevious: (previousPage: number) => void;
    paginationSkip?: boolean;
};

type useScrollNavigationProps = {
    scrollContainerRef?: RefObject<HTMLElement>;
    itemsRef?: RefObject<Array<HTMLDivElement | null>>;
    onSelectItem?: (index: number, selected: boolean) => void; // Callback for item selection with Index
    skip?: boolean;
};

type useKeyboardNavigationProps = {
    pagination?: usePaginationNavigationProps;
    scroll?: useScrollNavigationProps;
};

function useKeyboardNavigation({ pagination, scroll }: useKeyboardNavigationProps, deps: any[] = []) {
    const activeIndex = useRef<number | null>(null);

    const handleItemSelection = (selectUp: boolean) => {
        if (scroll?.itemsRef?.current && scroll?.scrollContainerRef?.current && scroll?.onSelectItem) {
            const { scrollTop, offsetHeight } = scroll.scrollContainerRef.current;
            const scrollBottom = scrollTop + offsetHeight;

            for (let i = 0; i < scroll.itemsRef.current?.length; i++) {
                const item = scroll.itemsRef.current[i];
                if (!item) continue;

                const itemTop = item.offsetTop;
                const itemBottom = itemTop + item.offsetHeight;

                if (itemTop >= scrollTop && itemBottom <= scrollBottom) {
                    if (selectUp) {
                        if (activeIndex.current !== i) {
                            scroll.onSelectItem(i, selectUp);
                            activeIndex.current = i;
                        }
                    } else {
                        scroll.onSelectItem(i, selectUp);
                    }
                    break;
                }
            }
        }
    };

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        const handleKeyDown = (e: any) => {
            e.stopPropagation();
            const key = e.key;

            if (pagination) {
                const { page, totalPage, onNext, onPrevious } = pagination;

                switch (key) {
                    case "ArrowLeft": {
                        if (page > 0) {
                            timeout = setTimeout(() => {
                                onPrevious(page - 1);
                            }, 0);
                        }
                        break;
                    }

                    case "ArrowRight": {
                        if (totalPage > page + 1) {
                            timeout = setTimeout(() => {
                                onNext(page + 1);
                            }, 0);
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            if (scroll && scroll.scrollContainerRef?.current) {
                switch (key) {
                    case "ArrowUp": {
                        if (e.shiftKey) {
                            handleItemSelection(true);
                        }
                        scroll.scrollContainerRef.current.scrollTop -= 100;
                        break;
                    }
                    case "ArrowDown": {
                        if (e.shiftKey) {
                            handleItemSelection(false);
                        }
                        scroll.scrollContainerRef.current.scrollTop += 80;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        };

        if (scroll?.skip || pagination?.paginationSkip) {
            document.removeEventListener("keydown", handleKeyDown);
            return;
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [
        pagination?.page,
        pagination?.totalPage,
        scroll?.scrollContainerRef?.current,
        scroll?.skip,
        pagination?.paginationSkip,
        ...deps,
    ]);
}

export default useKeyboardNavigation;
