export interface Option {
    label: string;
    value: string;
}

export type OptionWithValueAsNumber = {
    label: Option["label"];
    value: number;
};

export type Action = {
    action: string;
};

export interface ReadOnlyOption {
    readonly label: string;
    readonly value: string;
}

export type RequireOnly<T, P extends keyof T> = Pick<T, P> & Partial<Omit<T, P>>;

export type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export function isOption(value: any): value is Option {
    return value && typeof value === "object" && typeof value.label === "string";
}

export function isOptionWithValueAsNumber(value: any): value is OptionWithValueAsNumber {
    return value && typeof value === "object" && typeof value.label === "string" && typeof value.value === "number";
}
