import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store";

const initialState = Object.freeze({
    chatsHistory: [],
    chats: [],
    orgId: "",
    total: 0,
    canShowConversations: false,
    isHistoryLoading: false,
    isConversationLoading: false,
    chatName: "",
});

const reducers = {
    getHcChatHistory(state: any, { payload }: { payload: string }) {
        state.orgId = payload;
        state.isHistoryLoading = true;
    },
    setHcChatHistory(state: any, { payload }: { payload: any }) {
        state.chatsHistory = payload;
        state.total = payload.length;
        state.isHistoryLoading = false;
    },
    getHcConversation(state: any, { payload }: { payload: string }) {
        state.canShowConversations = true;
        state.isConversationLoading = true;
    },
    setHcConversation(state: any, { payload }: { payload: any }) {
        state.chats = payload.messages;
        state.chatName = payload.name;
        state.isConversationLoading = false;
    },
    setCanShowConversations(state: any, { payload }: { payload: boolean }) {
        state.canShowConversations = payload;
    },
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "HcChatHistory",
    initialState,
    // @ts-ignore
    reducers,
});

export default page.reducer;
export const {
    getHcChatHistory,
    setHcChatHistory,
    getHcConversation,
    setHcConversation,
    setCanShowConversations,
    leavePage,
} = page.actions;

export const selectHcChatsHistory = (state: RootState) => state.hcChatHistory.chatsHistory;
export const selectHcTotalChatsHistory = (state: RootState) => state.hcChatHistory.total;
export const selectHcChats = (state: RootState) => state.hcChatHistory.chats;
export const selectCanShowConversations = (state: RootState) => state.hcChatHistory.canShowConversations;
export const selectIsHistoryLoading = (state: RootState) => state.hcChatHistory.isHistoryLoading;
export const selectIsConversationLoading = (state: RootState) => state.hcChatHistory.isConversationLoading;
export const selectChatName = (state: RootState) => state.hcChatHistory.chatName;
