import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

import style from "./dateModal.module.scss";

import Button from "../Button/Button";

interface clockModalProps {
    closeModal: () => void;
    handleSelectDate: (dayVal: Dayjs | null) => void;
}

export default function DateModal(props: clockModalProps) {
    const { closeModal, handleSelectDate } = props;
    const [dayValue, setDayValue] = useState<Dayjs | null>(dayjs(""));

    return (
        <div className={style["modal"]}>
            <div className={style["modal__container"]}>
                <div className={style["modal__dateBox"]}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker label="Date&Time picker" value={dayValue} onChange={setDayValue} />
                    </LocalizationProvider>
                </div>

                <div className={style["modal__btnBox"]}>
                    <Button label="Cancel" variant="secondary" onClick={closeModal} />
                    <Button
                        label={dayjs(dayValue).isValid() ? "Confirm" : "Choose Date"}
                        variant="primary"
                        onClick={() => handleSelectDate(dayValue)}
                    />
                </div>
            </div>
        </div>
    );
}
