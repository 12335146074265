import { useState } from "react";
import AttachEmailRoundedIcon from "@mui/icons-material/AttachEmailRounded";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { Box, Button, IconButton, Popover, Stack, Switch, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { editProject, selectEmailProviderConfig, updateEmailProviderConfig } from "../../../allProjects/index.reducer";

import { checkEasySource } from "../../../Signin/Signin.reducer";
import { getSuperAdminCandEmails, getSuperAdminCandPhoneNumbers, selectSelectedCandidates } from "../../index.reducer";
import {
    EASYSOURCE_FREE_PROVIDERS,
    EMAIL_VERIFICATION_PROVIDERS,
    FREE_EMAIL_PROVIDERS,
    EASYSOURCE_PROVIDERS,
    EASY_GROWTH_PROVIDERS,
    FREE_EMAIL_VERIFICATION_PROVIDERS,
    FREE_EMAIL_VERIFICATION_PROVIDERS_EASYGROWTH,
    LICENSED_EMAIL_PROVIDERS,
} from "./email-providers";

const ExtractTableRow = ({
    value,
    logo,
    name,
    description,
    isActive,
    disabled,
    tooltip,
    onChange,
}: {
    value: string;
    logo: string;
    name: string;
    description: string;
    isActive: boolean;
    disabled: boolean;
    tooltip?: string;
    onChange?: (value: boolean) => void;
}) => {
    return (
        <Stack gap={1} paddingY={0.75} key={value} alignItems={"center"} flexDirection={"row"}>
            <img src={logo} alt={`${name}-logo`} style={{ width: "18px", height: "18px" }} />
            <Typography sx={{ fontWeight: 500, fontSize: "13px" }}>{name}</Typography>
            <Box marginLeft={"auto"}>
                {tooltip ? (
                    <Tooltip title={tooltip}>
                        <div>
                            <Switch
                                size="small"
                                onChange={(e) => onChange?.(e.target.checked)}
                                checked={isActive}
                                disabled={disabled}
                            />
                        </div>
                    </Tooltip>
                ) : (
                    <div>
                        <Switch
                            onChange={(e) => onChange?.(e.target.checked)}
                            size="small"
                            checked={isActive}
                            disabled={disabled}
                        />
                    </div>
                )}
            </Box>
            <Box width={"300px"}>
                <Typography sx={{ fontSize: "12px", color: disabled ? "grey" : "black" }}>{description}</Typography>
            </Box>
        </Stack>
    );
};

const ExtractTable = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const emailProviderConfig = useSelector(selectEmailProviderConfig);
    const isFetchingCandidateEmails = useSelector(checkIfLoading(getSuperAdminCandEmails.type));
    const isFetchingCandidatePhoneNumbers = useSelector(checkIfLoading(getSuperAdminCandPhoneNumbers.type));
    const isEditingProject = useSelector(checkIfLoading(editProject.type));
    const isEasySource = useSelector(checkEasySource);

    const onToggle = (key: string, value: boolean) => {
        dispatch(
            updateEmailProviderConfig({
                key,
                value,
            })
        );
    };

    const FREE_EMAIL_PROVIDERS_EXTENDED = [ ...(isEasySource ? EASYSOURCE_PROVIDERS : EASY_GROWTH_PROVIDERS),
        ...FREE_EMAIL_PROVIDERS ,
         ...(isEasySource ? EASYSOURCE_FREE_PROVIDERS : [])];

    const LICENSED_EMAIL_PROVIDERS_EXTENDED = [
        ...LICENSED_EMAIL_PROVIDERS,
        ...(!isEasySource ? EASYSOURCE_FREE_PROVIDERS : []),
    ];

    return (
        <Box width={"625px"}>
            <Box
                sx={{
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                    padding: 1.5,
                    background: "white",
                    borderBottom: "1px solid #E0E3E7",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "13px",
                        fontWeight: 500,
                        color: "#3b3b3b",
                    }}
                >
                    Enrich contact information
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "grey" }}>
                    Select from top contact data solutions to find the best data and maximize your outreach results
                </Typography>
            </Box>
            <Box
                sx={{
                    borderBottom: "1px solid #E0E3E7",
                    maxHeight: "500px",
                    overflowY: "scroll",
                }}
            >
                <Box sx={{ paddingY: 1, paddingX: 1.5, background: "#f6f8fa" }}>
                    <Typography sx={{ fontSize: "12px", color: "#3b3b3b" }}>
                        {`Solutions included for free in your ${isEasySource ? "EasySource" : "EasyGrowth"} license (no credit limits)`}
                    </Typography>
                    <Box paddingY={1}>
                        {FREE_EMAIL_PROVIDERS_EXTENDED.map((provider) => {
                            const { name, value, logo, description } = provider;
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <ExtractTableRow
                                    name={name}
                                    value={value}
                                    logo={logo}
                                    description={description}
                                    isActive={true}
                                    disabled={true}
                                    tooltip={
                                        "Contact credits from this solution are enabled by default. Please contact your Account Manager to disable this"
                                    }
                                />
                            );
                        })}
                    </Box>
                </Box>
                <Box
                    sx={{
                        borderTop: "1px solid #E0E3E7",
                        paddingY: 1,
                        paddingX: 1.5,
                    }}
                >
                    <Typography sx={{ fontSize: "12px", color: "#3b3b3b" }}>
                        Best-in-class add-on solutions to save you from multiple licenses
                    </Typography>
                    <Box paddingY={1}>
                        {LICENSED_EMAIL_PROVIDERS_EXTENDED.map((provider) => {
                            const { name, value, logo, description } = provider;
                            const isActive = emailProviderConfig.find((item) => {
                                return item.type == value;
                            })?.status;
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <ExtractTableRow
                                    name={name}
                                    value={value}
                                    logo={logo}
                                    description={description}
                                    isActive={Boolean(isActive)}
                                    disabled={false}
                                    onChange={(checked) => onToggle(value, checked)}
                                />
                            );
                        })}
                    </Box>
                </Box>
                <Box
                    sx={{
                        borderTop: "1px solid #E0E3E7",
                        paddingY: 1,
                        paddingX: 1.5,
                    }}
                >
                    <Typography sx={{ fontSize: "12px", color: "#3b3b3b" }}>
                        Best-in-class email verification providers
                    </Typography>
                    <Box paddingY={1}>
                        {(isEasySource ? FREE_EMAIL_VERIFICATION_PROVIDERS : FREE_EMAIL_VERIFICATION_PROVIDERS_EASYGROWTH).map((provider) => {
                            const { name, value, logo, description } = provider;
                           
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <ExtractTableRow
                                    name={name}
                                    value={value}
                                    logo={logo}
                                    description={description}
                                    isActive={true}
                                    disabled={true}
                                    tooltip={
                                        "This is enabled by default. Please contact your Account Manager to disable this"
                                    }
                                />
                            );
                        })}
                        {EMAIL_VERIFICATION_PROVIDERS.map((provider) => {
                            const { name, value, logo, description } = provider;
                            const isActive = emailProviderConfig.find((item) => {
                                return item.type == value;
                            })?.status;
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <ExtractTableRow
                                    name={name}
                                    value={value}
                                    logo={logo}
                                    description={description}
                                    isActive={Boolean(isActive)}
                                    disabled={false}
                                    onChange={(checked) => onToggle(value, checked)}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ paddingTop: 2, paddingX: 1.5 }}>
                <Typography sx={{ fontSize: "12px", color: "#3b3b3b" }}>
                    {`You will only be charged for contact data enriched from paid solutions you switch on. ${isEasySource ? "EasySource" : "EasyGrowth"}'s
                    intelligence minimizes your contact billing while maximizing your contact data and outreach results`}
                </Typography>
                <Typography sx={{ paddingY: 1.5, fontSize: "12px", color: "#3b3b3b" }}>
                    {`These settings are project-specific. You (${isEasySource ? "EasySource" : "EasyGrowth"} license holder) will receive an email alert if
                    monthly billing crosses $99 in any project.`}
                </Typography>
            </Box>

            <Box
                sx={{
                    padding: 1.5,
                    borderTop: "1px solid #E0E3E7",
                }}
            >
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Link style={{ textDecoration: "none" }} to={"/my-account/contact-data-control"}>
                        <Typography sx={{ textDecoration: "underline", fontSize: "12px" }} color="primary">
                            View stats<sup>↗</sup>
                        </Typography>
                    </Link>
                    <Button
                        size="small"
                        sx={{
                            marginLeft: "auto",
                            backgroundColor: "#0b6bcb",
                            borderRadius: "8px",
                            border: "none",
                            boxShadow: "none",
                        }}
                        variant="contained"
                        disabled={isFetchingCandidatePhoneNumbers}
                        onClick={() => {
                            dispatch(getSuperAdminCandPhoneNumbers({ emailProviderConfig }));
                        }}
                    >
                        <Typography sx={{ fontSize: "13px", background: "primary", color: "white" }}>
                            {isFetchingCandidatePhoneNumbers ? "Enriching phone" : "Enrich phone"}
                        </Typography>
                    </Button>

                    <Button
                        size="small"
                        sx={{
                            backgroundColor: "#0b6bcb",
                            borderRadius: "8px",
                            border: "none",
                            boxShadow: "none",
                        }}
                        variant="contained"
                        disabled={isFetchingCandidateEmails}
                        onClick={() => {
                            dispatch(getSuperAdminCandEmails({ projectId: id || "", emailProviderConfig }));
                        }}
                    >
                        <Typography sx={{ fontSize: "13px", background: "primary", color: "white" }}>
                            {isFetchingCandidateEmails ? "Enriching emails" : "Enrich email"}
                        </Typography>
                    </Button>
                    <Button
                        size="small"
                        sx={{
                            border: "1px solid #0b6bcb",
                            borderRadius: "8px",
                            boxShadow: "none",
                        }}
                        disabled={isEditingProject}
                        onClick={() => {
                            dispatch(editProject({ _id: id, emailProviderConfig, excludeVettingCriteria: true }));
                        }}
                    >
                        <Typography sx={{ fontSize: "13px", color: "#0b6bcb", background: "primary" }}>
                            Save settings
                        </Typography>
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

const ExtractEmailAndPhone = () => {
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const selectedCandidateIds = selectedCandidates.map((item) => item.candidateId);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? "extract-email-phone" : undefined;

    return (
        <>
            <IconButton
                aria-describedby="extract-email-phone"
                color="primary"
                disabled={!Boolean(selectedCandidateIds?.length)}
                onClick={(e) => {
                    handleClick(e);
                }}
            >
                <SystemUpdateAltIcon />
            </IconButton>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <ExtractTable />
            </Popover>
        </>
    );
};

export default ExtractEmailAndPhone;
