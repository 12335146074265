import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import { getAnalytics, getGraph, leaveRoute, setAnalytics, setGraph } from "./Analytics.reducer";
import handleException from "../../utils/sentry";

type NewDate = Date & { nd?: Date };

function* getAnalyticsSaga({
    payload,
}: {
    payload: {
        userId?: number;
        fromDate: NewDate;
        toDate?: NewDate;
        projectId?: number;
    };
}): SagaIterator {
    try {
        yield put(startAction({ action: getAnalytics.type }));
        const response: any = yield call(new API().post, "/analytics", {
            fromDate: payload.fromDate?.nd || payload.fromDate,
            toDate: payload.toDate?.nd || payload.toDate || new Date(),
            projectId: payload.projectId,
            ...(payload.userId && { userId: payload.userId }),
        });
        if (!response?.data) return;

        yield put(setAnalytics(response.data));
    } catch (error) {
        handleException(error);
        console.error("**getAnalyticsSaga", { error });
    } finally {
        yield put(stopAction({ action: getAnalytics.type }));
    }
}

function* getGraphSaga({
    payload,
}: {
    payload: {
        userId?: number;
        fromDate: NewDate;
        toDate?: NewDate;
        projectId?: number;
    };
}): SagaIterator {
    try {
        yield put(startAction({ action: getGraph.type }));
        const response: any = yield call(new API().post, "/analytics/graph", {
            fromDate: payload.fromDate?.nd || payload.fromDate,
            toDate: payload.toDate?.nd || payload.toDate || new Date(),
            projectId: payload.projectId,
            ...(payload.userId && { userId: payload.userId }),
        });
        if (!response?.data) return;

        yield put(setGraph(response.data));
    } catch (error) {
        handleException(error);
        console.error("**getGraphSaga", { error });
    } finally {
        yield put(stopAction({ action: getGraph.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getAnalytics.type, getAnalyticsSaga),
        // @ts-ignore
        yield takeLatest(getGraph.type, getGraphSaga),
    ];
    // @ts-ignore
    yield takeLatest(leaveRoute.type, CancelSagas, tasks);
}
