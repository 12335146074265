import { createContext, useContext } from "react";

export type ActivitiesContextType = {
    candidateName: string;
    candidateId: string;
    selectedActivity: string;
    handleSelectedActivity: (value: string) => void;
    handleScrollIntoView: () => void;
};

export const ActivitiesContext = createContext<ActivitiesContextType | null>(null);

export const useCandidateProfileActivities = () => {
    const activitiesContext = useContext(ActivitiesContext);

    if (!activitiesContext) {
        throw new Error("useCandidateProfileActivities has to be used within <CurrentUserContext.Provider>");
    }

    return activitiesContext;
};
