export const liLoginEvents = {
    SHOW_CAPTCHA: "user:showCaptcha", //  {publicKey:string, data:string }
    CAPTCHA_TOKEN_SOLVED: "user:captchaTokenSolved", // token:string
    LI_LOGIN_REQ: "user:liLoginReq", // {email: string , password: string}
    LI_LOGIN_STATUS: "user:liLoginStatus", // {status: "success" | "failure" | "abort" | "wrongPassword"}
    SHOW_OTP: "user:showOtp", // {otpType: string, error?: string}
    OTP_RES: "user:otpRes", // {otp: string}
    SHOW_IN_APP_CHALLENGE: "user:ShowInAppChallenge", // (only show a dialog box with a message on FE)
    LI_LOGIN_ABORT_REQ: "user:liLoginAbortReq", // {status: "ABORT"}
    LI_LOGIN_WRONG_PASSWORD_REQ: "user:liLoginWrongPasswordReq", // {email: string , password: string}
};

export const blendSearchEvents = {
    SALES_NAV_SEARCH_RESULTS: "user:salesNavSearchResponse", // {results: [] }
    SALES_NAV_SEARCH_REQ: "user:salesNavSearchRequest", // {searchId: string}
};

export const notificationEvents = {
    // Request events
    MARK_AS_READ_REQ: "notification:markAsRead_REQ",
    MARK_ALL_AS_READ_REQ: "notification:markAllAsRead_REQ",
    CLEAR_REQ: "notification:clear_REQ",
    CLEAR_ALL_REQ: "notification:clearAll_REQ",
    GET_ALL_NOTIFICATIONS_REQ: "notification:getAllNotifications_REQ",
    NOTIFICATION_CONFIG_REQ: "notification:config_REQ",

    // Response events
    MARK_AS_READ_RES: "notification:markAsRead_RES",
    MARK_ALL_AS_READ_RES: "notification:markAllAsRead_RES",
    CLEAR_RES: "notification:clear_RES",
    CLEAR_ALL_RES: "notification:clearAll_RES",
    GET_ALL_NOTIFICATIONS_RES: "notification:getAllNotifications_RES",
    NOTIFICATION_CONFIG_RES: "notification:config_RES",

    // Notification events
    NEW_NOTIFICATION: "notification:newNotification",
    LIST_UPDATE: "notification:list",
};

export const notificationEventsSubTypes = {
    GET_ONLY_UNREADS: "GET_ONLY_UNREADS",
};

export const projectLevelNotificationEvents = {
    INSIGHTS_GENERATION_PROGRESS_RES: "project:insightsGenerationProgress_RES",
    PHONE_ENRICHMENT_PROGRESS_RES: "project:phoneEnrichmentProgress_RES",
    EMAIL_ENRICHMENT_PROGRESS_RES: "project:emailEnrichmentProgress_RES",
    REGISTER_PROJECT_REQ: "project:registerProject_REQ",
    UNREGISTER_PROJECT_REQ: "project:unregisterProject_REQ",
    UPDATE_PROJECT_FILTERS_REQ: "project:updateProjectFilters_REQ",
    GET_CANDIDATE_PROJECT_RES: "project:getCandidateProject_RES",
    SYNC_AI_SUGGESTION: "project:syncAiSuggestion",
    SYNC_AI_SUGGESTION_APPLIED: "project:syncAiSuggestionApplied",
};
