import { useLocation } from "react-router-dom";

const paths = ["/workflows", "/advanced-filter", "/search"];

export default function useMinimizeSidebarItems() {
    const location = useLocation();

    const shouldMinimize = paths.includes(location.pathname);

    return shouldMinimize;
}
