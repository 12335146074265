import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";

interface AnalyticsLoaderProps {
    loading?: string;
}

export default function AnalyticsLoader(props: AnalyticsLoaderProps) {
    const { loading } = props;
    return (
        <Box height={0}>
            {loading === "true" && (
                <Tooltip title="Syncing most updated information...">
                    <LinearProgress sx={{ height: 3 }} />
                </Tooltip>
            )}
        </Box>
    );
}
