import { Badge, Button } from "@mui/joy";
import Box from "@mui/joy/Box";
import { useSelector } from "react-redux";

import ChatbotPopup from "./components/ChatbotPopup";
import LauncherButton from "./components/LauncherButton";
import ChatbotContext from "./Context";

import { checkChatbotEnabled, checkEasyGrowth } from "../../pages/Signin/Signin.reducer";
import { JoyProvider } from "../JoyProvider";

export default function Chatbot() {
    const chatbotEnabled = useSelector(checkChatbotEnabled);
    const isEasyGrowth = useSelector(checkEasyGrowth);

    if (!chatbotEnabled || isEasyGrowth) {
        return null;
    }

    return (
        <ChatbotContext>
            <Box display="flex" bgcolor="white" justifyContent="center" alignItems="center">
                <ChatbotPopup />
                <LauncherButton>
                    <JoyProvider>
                        <Badge badgeContent={"Beta"} color="primary">
                            <Button
                                variant="plain"
                                color="neutral"
                                sx={{
                                    color: "black",
                                    fontSize: "16px",

                                    "&:hover": {
                                        backgroundColor: "#f5f5f5",
                                    },
                                }}
                            >
                                Copilot
                            </Button>
                        </Badge>
                    </JoyProvider>
                </LauncherButton>
            </Box>
        </ChatbotContext>
    );
}
