import Typography, { TypographyProps } from "@mui/material/Typography";

const statusMapping: Record<string, string> = {
    IN_PROGRESS: "In Progress",
    COMPLETED: "Completed",
    PENDING: "Pending",
    ERROR: "Error",
    STOPPED: "Stopped",
    CANCELLED: "Cancelled",
    FAILED: "Failed",
    RESUMED: "Resumed",

};

export function ReadOnlyStatus({ status, sx }: { status?: string; sx?: TypographyProps["sx"] }) {
    if (!status) {
        return null;
    }

    const displayStatus = status ? statusMapping[status] || status : null;

    if (!displayStatus) {
        return null;
    }

    return (
        <Typography
            variant="body2"
            sx={{
                position: "absolute",
                top: "7px",
                right: "-100px",
                ...sx,
            }}
        >
            {displayStatus}
        </Typography>
    );
}
