import { useEffect } from "react";

export default function useBeforeUnloadConfirmation(
    showConfirmation: boolean = true,
    message: string = "Are you sure you want to leave? You may lose unsaved data."
) {
    useEffect(() => {
        const listener = (e: BeforeUnloadEvent) => {
            if (showConfirmation) {
                e.preventDefault();
                return message;
            }
        };
        window.addEventListener("beforeunload", listener);
        return () => {
            window.removeEventListener("beforeunload", listener);
        };
    }, [showConfirmation]);
}
