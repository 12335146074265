import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

import { addContactListToProject } from "../../components/Navbar/CreateProject/CreateProject.reducer";
import { withConfigModeHOC } from "../../hocs/withConfigMode";
import { fetchContactList, selectRows } from "../../pages/ContactList/contact-list.slice";
import { useAppDispatch } from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";

type ImportFromContactListModalProps = {
    onClose: () => void;
};

export const ImportFromContactListModal = ({ onClose }: ImportFromContactListModalProps) => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(checkIfLoading(fetchContactList.type));
    const contactList = useSelector(selectRows);
    const [selectedContact, setSelectedContact] = useState({
        label: "",
        value: "",
    });

    return (
        <Dialog
            open={true}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: 2,
                },
            }}
        >
            <DialogTitle>Import prospects</DialogTitle>
            <DialogContent sx={{ paddingTop: "10px !important" }}>
                {isLoading ? (
                    <h1>Loading...</h1>
                ) : (
                    <FormControl size="small">
                        <InputLabel id="contact-list-label">Contact list</InputLabel>
                        <Select
                            labelId="contact-list-label"
                            id="contact-list-id"
                            value={selectedContact.value}
                            label="Contact list"
                            sx={{ marginBottom: "10px", width: "300px" }}
                        >
                            {contactList.map((item: any, i: number) => (
                                <MenuItem
                                    value={item?.id}
                                    onClick={() =>
                                        setSelectedContact({
                                            label: item?.name,
                                            value: item?.id,
                                        })
                                    }
                                    key={i}
                                >
                                    {item?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={() => dispatch(addContactListToProject(selectedContact.value))}>
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const BDImportFromContactListModal = withConfigModeHOC<{}, ImportFromContactListModalProps>({
    Eg: ImportFromContactListModal,
});
