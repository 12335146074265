import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import useDisclosure from "../../../hooks/useDisclosure";
import { checkEasyGrowth } from "../../../pages/Signin/Signin.reducer";
import {
    addSearchCandidatesToProject,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import AddCustomCandidates, { useProjectData } from "./AddCustomCandidates";
import { CandidateCountModal } from "./CandidateCountModal";

export default function AddToProject() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const { t } = useTranslation();
    const { projectId, projectName, dispatch, searchId, navigate, candidates, isAddingToProject } = useProjectData();

    if (!searchId || !candidates?.length) return null;

    const handleAddToProject = () => {
        if (isEasyGrowth) {
            return onOpen();
        }
        dispatch(
            addSearchCandidatesToProject({
                projectId,
                projectName,
                searchId,
                navigate,
            })
        );
    };

    return (
        <>
            <Stack mt={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <AddCustomCandidates />
                <Button variant="contained" disabled={isAddingToProject} onClick={handleAddToProject}>
                    <ButtonTextWithLoading isLoading={isAddingToProject} text={t("blendSearch.addTo")} />
                </Button>
            </Stack>
            {isOpen && <CandidateCountModal isModifying={projectId !== null} onClose={onClose} />}
        </>
    );
}
