import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UploadIcon from "@mui/icons-material/Upload";
import { Autocomplete, Link, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import AISearchModal from "./AISearchModal";
import CSVUpload from "./CSVUpload";
import CVSource, { TCVSource } from "./CVSource";
import GitSearchModal from "./GitSearchModal";

import AIImage from "../../../assets/img/ai-providers-logo/artificial-intelligence.png";
import GitImage from "../../../assets/img/ai-providers-logo/githublogo.png";
import style from "../../../container/AppContainer/AppContainer.module.css"
import { BDImportFromContactListModal } from "../../../easy-growth/components/ImportProspectsFromContactList";
import useMenu from "../../../hooks/useMenu";
import ZipUpload from "../../../pages/project/components/ZipUpload";
import BaseModal from "../../../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { openExtensionLink } from "../../../utils/API";
import {
    HQ_EASYSOURCE_WEBSITE,
    HQ_OPEN_PROJECT_FILTERS_EXTENSION,
    IMPORT_FROM_CONTACT_LIST,
    IMPORT_FROM_LINKEDIN_URL,
    SEARCH_PROSPECTS,
    UPLOAD_RESUMES,
} from "../../../utils/Constants";
import { startSse } from "../../../utils/firebase";
import { ButtonTextWithLoading } from "../../ButtonTextWithLoading";
import CsvHeaders from "../../CSVHeaders/CSVHeaders";
import ProjectsDropdown from "../../Dropdowns/ProjectsDropdown";
import { MaterialProvider } from "../../MaterialProvider";

import { selectCurrProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { selectIsExtensionInstalled } from "@/store/reducers/app-container/AppContainer.reducer";
import {
    createProject,
    resetState,
    selectCsvFile,
    selectDetailScrape,
    selectErrors,
    selectShowCsvHeaderDialog,
    setCanScrapeEmail,
    setDetailScrape,
    setIsSalesNav,
    setLiCookie,
    setShowCsvHeaderDialog,
    uploadCsv,
} from "@/store/reducers/create-project/CreateProject.reducer";
import { setErrorNotification, setWarningNotification } from "@/store/reducers/notification/notification.reducer";
import { bulkUploadCandidatesCVs, linkedinUrlUpload } from "@/store/reducers/project/project.reducer";
import {
    checkAiSearchEnabled,
    checkEasyGrowth,
    checkIfAccountRB2B,
    selectUser,
} from "@/store/reducers/signin/Signin.reducer";
import { Button, Chip, Modal, Switch, Typography } from "@/ui";

type UploadCandidateCVsModalProps = {
    closeModal: () => void;
};

function BulkUploadCVsModal({ closeModal }: UploadCandidateCVsModalProps) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const isLoading = useSelector(checkIfLoading(bulkUploadCandidatesCVs.type));
    const [candidateCVZip, setCandidateCVZip] = useState<File[] | null>(null);
    const [cvSource, setCVSource] = useState<TCVSource | null>(null);

    const handleSubmit = () => {
        if (!id) return;
        if (!candidateCVZip?.length) {
            dispatch(setErrorNotification("Please upload CVs zip"));
            return;
        }
        if (!cvSource?.value) {
            dispatch(setErrorNotification("Please update source of CVs"));
            return;
        }

        dispatch(
            bulkUploadCandidatesCVs({
                projectId: id,
                file: candidateCVZip,
                action: bulkUploadCandidatesCVs.type,
                cvSource,
                onSuccess: () => {
                    closeModal();
                    startSse();
                },
            })
        );
    };
    return (
        <BaseModal onClose={closeModal} overlayStyles={{ width: "500px", marginLeft: "0px" }}>
            <div className="space-y-2">
                <Typography className="text-lg font-semibold text-[#334d6e]">Upload Candidates CVs</Typography>
                <ZipUpload handleZipUpload={(zip) => setCandidateCVZip(zip)} />
                {candidateCVZip && <CVSource onChange={setCVSource} value={cvSource} />}
                <div className="flex flex-row items-center justify-center">
                    <Button variant="default" onClick={handleSubmit} disabled={isLoading}>
                        <ButtonTextWithLoading text="Submit" isLoading={isLoading} variant="light" />
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
}

type MODAL_TYPE =
    | "CSVs"
    | "CVs"
    | "none"
    | "UPLOAD_COMPANY_WELCOME"
    | "SOURCE_CANDIDATES"
    | "CONTACT_LIST"
    | typeof SEARCH_PROSPECTS
    | typeof IMPORT_FROM_CONTACT_LIST
    | typeof IMPORT_FROM_LINKEDIN_URL
    | typeof UPLOAD_RESUMES;

type ValueItem = {
    type: MODAL_TYPE;
    value: string;
};

const values: ValueItem[] = [
    {
        type: UPLOAD_RESUMES,
        value: "Upload resumes",
    },
    {
        type: "CSVs",
        value: "Bulk Upload CSV",
    },
    {
        type: SEARCH_PROSPECTS,
        value: "Search Prospects",
    },
    {
        type: IMPORT_FROM_CONTACT_LIST,
        value: "Import from contact list",
    },
    {
        type: IMPORT_FROM_LINKEDIN_URL,
        value: "Add via LinkedIn url",
    },
];

function UploadCSV() {
    const { t } = useTranslation();
    const project = useSelector(selectCurrProject);
    const aiSearchEnabled = useSelector(checkAiSearchEnabled);
    const isEasyGrowth: boolean = useSelector(checkEasyGrowth);
    const { anchorEl, handleClick, handleClose, menuSX, open } = useMenu();
    const [index, setIndex] = useState<MODAL_TYPE>("none");
    const isExtensionInstalled = useSelector(selectIsExtensionInstalled);
    const [isClicked, setIsClicked] = useState(false);
    const [isClickedGit, setIsClickedGit] = useState(false);
    const isRB2BAccount = useSelector(checkIfAccountRB2B);
    const { id } = useParams();

    const handleCloseModal = () => setIndex("none");

    const handleMenuItemClick = (type: MODAL_TYPE) => {
        if (type === "SOURCE_CANDIDATES") {
            window.postMessage(
                {
                    type: HQ_OPEN_PROJECT_FILTERS_EXTENSION,
                    from: HQ_EASYSOURCE_WEBSITE,
                    url: openExtensionLink,
                    projectId: id,
                },
                "*"
            );
        }

        if (type === "SEARCH_PROSPECTS") {
            window.open(`/generate-prospects?project=${project?._id}&name=${project?.name}`);
        }

        setIndex(type);
        handleClose();
    };

    const [openAI, setOpenAI] = useState(false);

    const handleIconClick = () => {
        setOpenAI(true);
        setIsClicked(true);
    };

    const handleAIModalClose = () => {
        setOpenAI(false);
    };

    const [openGit, setOpenGit] = useState(false);

    const handleGitIconClick = () => {
        setOpenGit(true);
        setIsClickedGit(true);
    };

    const handleGitModalClose = () => {
        setOpenGit(false);
    };

    return (
        <>
            {aiSearchEnabled && (
                <Button
                    variant="outline"
                    onClick={handleGitIconClick}
                    className={`h-[40px] ${!isClickedGit ? "shadow-[0_0_0_0.2rem_rgba(0,123,255,0.5)]" : "shadow-none"} 
                    rounded-md font-medium text-[10px] hover:bg-[#e3effb] hover:text-[#449cd4] focus:shadow-none`}
                >
                    <img src={GitImage} alt="" width="20px" height="20px" style={{ marginRight: "3px" }} />
                    Git Search
                </Button>
            )}

            {aiSearchEnabled && (
                <Button
                    variant="outline"
                    onClick={handleIconClick}
                    className={`h-[40px] ${!isClicked ? "shadow-[0_0_0_0.2rem_rgba(0,123,255,0.5)]" : "shadow-none"} 
                    rounded-md font-medium text-[10px] hover:bg-[#e3effb] hover:text-[#449cd4] focus:shadow-none`}
                >
                    <img src={AIImage} alt="" width="20px" height="20px" style={{ marginRight: "3px" }} />
                    Future Force
                </Button>
            )}

            {!isRB2BAccount && (
                <Button
                    variant="outline"
                    onClick={handleClick}
                    endDecorator={<KeyboardArrowDownIcon />}
                    className="focus:shadow-none active:shadow-none visited:shadow-none max-h-[35px] rounded-full"
                >
                    {t("projectsPage.addCandidates")}
                </Button>
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                sx={{
                    "& .MuiPaper-root": {
                        ...menuSX["& .MuiPaper-root"],
                        top: "130px !important",
                    },
                    ...menuSX,
                }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {values.map(({ value, type }, index) => {
                    const hideSourceCandidates = type === "SOURCE_CANDIDATES" && !isExtensionInstalled;

                    if (isEasyGrowth && type === UPLOAD_RESUMES) {
                        return null;
                    }

                    if (hideSourceCandidates) {
                        return null;
                    }

                    if (!isEasyGrowth && (type === SEARCH_PROSPECTS || type === IMPORT_FROM_CONTACT_LIST)) {
                        return null;
                    }

                    return (
                        <MenuItem key={value} onClick={() => handleMenuItemClick(type)}>
                            {value === "Add via LinkedIn url" ? (
                                <>
                                    <ul>
                                        <li>
                                            <AddCircleIcon sx={{ color: "#0891B2", marginRight: "5px" }} />
                                        </li>
                                    </ul>
                                    {value}
                                </>
                            ) : (
                                value
                            )}
                        </MenuItem>
                    );
                })}
            </Menu>
            {index === UPLOAD_RESUMES && <BulkUploadCVsModal closeModal={handleCloseModal} />}
            {index === "CSVs" && <BulkUploadCSVsModal open={true} onClose={handleCloseModal} />}
            {index === IMPORT_FROM_CONTACT_LIST && (
                <BDImportFromContactListModal egProps={{ onClose: () => setIndex("none") }} />
            )}
            {index === IMPORT_FROM_LINKEDIN_URL && <LinkedinUrlUploadModal open={true} onClose={handleCloseModal} />}
            <AISearchModal open={openAI} onClose={handleAIModalClose} projectId={id || ""} />
            <GitSearchModal open={openGit} onClose={handleGitModalClose} projectId={id || ""} />
        </>
    );
}

type BulkUploadCSVProps = {
    open: boolean;
    onClose: () => void;
};

export function LinkedinUrlUploadModal({ open, onClose }: BulkUploadCSVProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();
    const user = useSelector(selectUser);
    const userId = user?._id;
    const ref = useRef(null);
    const isLoading = useSelector(checkIfLoading(linkedinUrlUpload.type));
    const [inputUrls, setInputUrls] = useState<any>();
    const [isFocused, setIsFocused] = useState(false);
    const [cvSource, setCVSource] = useState<TCVSource>({ label: "Linkedin", value: "Linkedin" });
    const [projectId, setProjectId] = useState<number>(0);

    const linkedinUrls = [];

    const validateUrls = (urls: string[]) => {
        const validUrls = [];
        const invalidUrls = [];
        const linkedinUrlPattern = /(?:linkedin\.com\/(?:in|sales\/lead|sales\/people)\/)([^\/\?,]+)/;

        if (urls && urls?.length > 0) {
            urls.forEach((url) => {
                if (linkedinUrlPattern.test(url.trim())) {
                    validUrls.push(url.trim());
                } else {
                    invalidUrls.push(url.trim());
                }
            });
        }

        return { validUrls, invalidUrls };
    };

    const { validUrls, invalidUrls } = validateUrls(inputUrls);

    const handleChange = (value: string | string[] | null) => {
        setInputUrls(value);
    };

    const handleSubmitUrl = () => {
        if (!id && !projectId) return;
        if (inputUrls?.length === 0) {
            dispatch(setErrorNotification("Please enter a linkedin url!"));
            return;
        }

        if (inputUrls?.length > 20) {
            dispatch(setErrorNotification("Please use bulk upload csv feature for adding more than 20 profiles!"));
            return;
        }

        if (invalidUrls && invalidUrls.length > 0) {
            dispatch(
                setWarningNotification(
                    "One or more LinkedIn urls may be invalid. Valid LinkedIn profiles will be added to your project"
                )
            );
        }

        dispatch(
            linkedinUrlUpload({
                projectId: projectId ? projectId.toString() : id,
                url: validUrls,
                cvSource: cvSource,
                onSuccess: () => {
                    onClose();
                    startSse();
                },
                action: linkedinUrlUpload.type,
            })
        );
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
                dispatch(resetState());
            }}
            className={style["zoomHandler"]}
        >
            <h2 className="text-lg font-semibold">Add {t("common")} via LinkedIn url</h2>
            <div style={{ minWidth: "35rem", maxWidth: "35rem" }}>
                <div className="flex flex-col gap-1">
                    <div className="flex flex-col gap-1 pt-1">
                        {!id && (
                            <div style={{ display: "flex" }}>
                                <span style={{ marginRight: "10px", marginTop: "5px" }}>Select Project</span>
                                <MaterialProvider>
                                    <ProjectsDropdown
                                        userId={userId}
                                        projectId={projectId}
                                        handleChange={setProjectId}
                                    />
                                </MaterialProvider>
                            </div>
                        )}

                        <Autocomplete
                            sx={{ marginTop: "7px", marginBottom: "50px" }}
                            multiple
                            freeSolo
                            size="small"
                            ChipProps={{ color: "success" }}
                            options={linkedinUrls?.map((option) => option)}
                            loading={isLoading}
                            onChange={(_, value) => {
                                handleChange(value);
                            }}
                            onFocus={() => setIsFocused(true)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                    ref={ref}
                                    label="Enter LinkedIn url"
                                    placeholder="Enter LinkedIn url"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: <Fragment>{params?.InputProps?.endAdornment}</Fragment>,
                                        sx: { fontSize: 14 },
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-col">
                            <p className="text-gray-500 text-xs italic">
                                Press enter after each url for multiple urls.
                            </p>
                            <p className="text-gray-500 text-xs italic">
                                Candidates already present in your project will not be duplicated
                            </p>
                        </div>

                        <Button onClick={handleSubmitUrl} variant="default" disabled={!inputUrls}>
                            <ButtonTextWithLoading isLoading={isLoading} text="Proceed" variant="light" />
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

function BulkUploadCSVsModal({ onClose, open }: BulkUploadCSVProps) {
    const { t } = useTranslation();
    const { id = "" } = useParams();
    const dispatch = useDispatch();
    const canShowCookie = useSelector(selectDetailScrape);
    const isProjectCreating = useSelector(checkIfLoading(createProject.type));
    const isCsvUploading = useSelector(checkIfLoading(uploadCsv.type));
    const errors = useSelector(selectErrors);
    const showCsvHeaderDialog = useSelector(selectShowCsvHeaderDialog);
    const csvFile = useSelector(selectCsvFile);
    const isEasyGrowth: boolean = useSelector(checkEasyGrowth);
    const [cvSource, setCVSource] = useState<TCVSource>(() =>
        isEasyGrowth ? { label: "EasySource", value: "EasySource" } : { label: "", value: "" }
    );

    const CsvHeaderComponent = CsvHeaders;

    const handleCSVSubmit = () => {
        if (!id) return;

        if (csvFile && !cvSource?.value) {
            dispatch(setErrorNotification("Please update source of CVs"));
            return;
        }

        dispatch(setShowCsvHeaderDialog(true));
    };

    const onCloseUploadCSVDialog = () => {
        onClose();
        dispatch(resetState());
    };

    return (
        <Modal open={open} onClose={onCloseUploadCSVDialog}>
            <h2 className="text-lg font-semibold">Upload CSV</h2>
            <div>
                <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between items-center mb-2.5">
                        <Link
                            href="https://hq-cv-tech.s3.us-east-1.amazonaws.com/uat-candidate-sample.csv"
                            underline="always"
                            target="_blank"
                        >
                            Sample CSV
                        </Link>
                        <div className="flex flex-row items-center">
                            <Typography className="text-[10px]">
                                <strong>Imp headers:</strong>
                            </Typography>
                            <Chip label="name" style={{ marginLeft: "10px" }} />
                            <Chip label="linkedInProfileUrl" style={{ marginLeft: "10px" }} />
                            <Chip label="email" style={{ marginLeft: "10px" }} />
                        </div>
                    </div>
                    <Typography className="py-1 text-xs italic text-gray-500">
                        Files more than 10MB in size will not be uploaded
                    </Typography>
                    <CSVUpload />
                    {csvFile && !isEasyGrowth && <CVSource onChange={setCVSource} value={cvSource} />}
                    <div className="flex flex-row justify-between items-center mb-[15px]">
                        <Typography>Email Extraction</Typography>
                        <Switch onChange={(checked) => dispatch(setCanScrapeEmail(checked))} />
                    </div>
                    <div className="flex flex-row justify-between items-center mb-1">
                        <Typography>Detailed Extraction</Typography>
                        <Switch checked={canShowCookie} onChange={(checked) => dispatch(setDetailScrape(checked))} />
                    </div>
                    {canShowCookie && (
                        <div className="flex flex-col gap-1">
                            <TextField
                                fullWidth
                                sx={{ marginBottom: "4px" }}
                                onBlur={(e) => dispatch(setLiCookie(e.target.value))}
                                error={Boolean(errors.liCookie)}
                                helperText={errors.liCookie}
                            />
                            <div className="flex flex-row justify-between items-center mt-1">
                                <Typography>
                                    Has SalesNav Urls
                                    <Chip label="salesNavProfileUrl" style={{ marginLeft: 1 }} />
                                </Typography>
                                <Switch onChange={(checked) => dispatch(setIsSalesNav(checked))} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex justify-end space-x-2 mt-6">
                <Button
                    variant="default"
                    startDecorator={!isProjectCreating && !isCsvUploading && <UploadIcon />}
                    onClick={handleCSVSubmit}
                    disabled={isProjectCreating || isCsvUploading}
                    className="w-fit px-[15px] py-[5px] self-center"
                >
                    {isProjectCreating || isCsvUploading ? "Uploading..." : t("projectsPage.uploadCSVButtonLabel")}
                </Button>
            </div>
            {showCsvHeaderDialog && csvFile && (
                <CsvHeaderComponent
                    onSubmit={(mappedCsvHeaders: any) => {
                        dispatch(
                            uploadCsv({
                                projectId: id as string,
                                mappedCsvHeaders,
                                cvSource,
                                onSuccess: onCloseUploadCSVDialog,
                            })
                        );
                    }}
                />
            )}
        </Modal>
    );
}

export default UploadCSV;
