import firebase from "firebase/compat/app";

import "firebase/compat/auth";

import { EventSourcePolyfill } from "event-source-polyfill";
import { isEmpty, isString } from "lodash";

import API, { apiBase } from "./API";
import { findCookie } from "./cookie";

import { setSSECandidate } from "../container/AppContainer/AppContainer.reducer";
import store from "../store";

async function getFirebaseToken() {
    const auth = firebase.auth();
    const tokenResult = await auth.currentUser?.getIdTokenResult?.(true);

    return {
        token: tokenResult?.token,
        expirationTime: new Date(tokenResult?.expirationTime).getTime(),
    };
}

export async function startSse(oRun?: (sseProjectId) => void) {
    const webToken = await getFirebaseWebToken();
    const token = findCookie("accessToken");

    if (!webToken && !token) return;

    const getUserFromLS = new API().getLocalStorage("user");

    const user = getUserFromLS ? JSON.parse(getUserFromLS) : null;

    const url = `${apiBase}/auth/user/${user?._id}/events`;
    const headers = {
        appType: "extension",
        version: "1.0",
        timezone: "-330",
        "x-authorization": token,
        "x-webAuthorization": webToken,
    };

    const source = new EventSourcePolyfill(url, {
        headers,
        heartbeatTimeout: 300000,
    });
    source.onerror = () => source.close();

    source.addEventListener(user._id, (e: any) => {
        const data = isString(e.data) ? JSON.parse(e.data) : e.data;
        if (isEmpty(data)) return;
        store.dispatch(setSSECandidate(data));
        oRun?.(data?.projectId);
    });
}

// function checkFirebaseDB() {
//     if (typeof window !== "undefined" && window.indexedDB) {
//         (window.indexedDB.open("firebaseLocalStorageDb") as any).onsuccess = function (sender: any) {
//             if (!sender.target.result.objectStoreNames.length) {
//                 console.log("firebase local db token removed!");
//             }
//         };
//     }
// }

function checkFBTokenExpired(expirationTime: string) {
    if (!expirationTime) {
        return true;
    }

    const bufferTime = 5 * 60 * 1000; // 5 minutes in milliseconds
    const currentTime = Date.now();
    const isTokenExpired = Number(expirationTime) - bufferTime <= currentTime;

    return isTokenExpired;
}

export async function getFirebaseWebToken() {
    const tokenFromCookie = findCookie("webAccessToken")?.split(":");
    const expirationTime = tokenFromCookie?.[1];

    let webToken = tokenFromCookie?.[0];

    const setToken = async () => {
        const tokenResult = await getFirebaseToken();
        if (tokenResult?.token) {
            webToken = tokenResult?.token;
            document.cookie = `webAccessToken=${webToken}:${tokenResult.expirationTime}`;
        }
    };

    // check if token is expired, if so get updated token
    if (webToken) {
        if (checkFBTokenExpired(expirationTime)) {
            await setToken();
        }
    }

    // if token not available then get updated token
    if (!webToken) {
        await setToken();
    }

    // still not available
    if (!webToken) {
        console.warn("webAccessToken not available!");
    }

    return webToken;
}
