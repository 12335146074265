import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";

import {
    handleContactListItemsRequestSort,
    handleContactListItemsSelectAllClick,
    selectContactListItemsNumberOfSelectedRows,
    selectContactListItemsTableOrder,
    selectContactListItemsTableOrderBy,
    selectContactListItemsTotalRowCount,
} from "../contact-list-items.slice";
import { ContactListItemsTableItem, HeadCell } from "../contact-list-items.type";

const headCells: readonly HeadCell[] = [
    {
        id: "name",
        numeric: false,
        label: "Name",
    },
    {
        id: "email",
        numeric: false,
        label: "Email",
    },
    {
        id: "company",
        numeric: true,
        label: "Company",
    },
];

export default function ContactListItemsEnhancedHead() {
    const dispatch = useDispatch();
    const numSelected = useSelector(selectContactListItemsNumberOfSelectedRows);
    const rowCount = useSelector(selectContactListItemsTotalRowCount);
    const order = useSelector(selectContactListItemsTableOrder);
    const orderBy = useSelector(selectContactListItemsTableOrderBy);

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        dispatch(handleContactListItemsSelectAllClick(value));
    };

    const createSortHandler = (property: keyof ContactListItemsTableItem) => (event: React.MouseEvent<unknown>) => {
        dispatch(handleContactListItemsRequestSort(property));
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={handleSelect}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? "right" : "left"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
