import InfoIcon from "@mui/icons-material/Info";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Avatar, Divider, IconButton, Paper, Stack, StackProps, Tooltip, Typography, styled } from "@mui/material";
import millify from "millify";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

import AddToProject from "./AddToProject";
import TypingLoader from "./TypingLoader";

import VirtualizedList from "../../../components/VirtualizedList";
import Sleep from "../../../molecules/Sleep/Sleep";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectCurrentCompany, selectNoOfCompanies } from "../../../store/reducers/search/search.slice";
import {
    addSearchCandidatesToProject,
    fetchCandidates,
    selectCandidates,
    selectCandidatesFetchStatus,
    selectSearch,
    selectSupersetCount,
    selectTotalCandidates,
    selectTotalCompanies,
    sendEmailNotification,
    setCandidatesFetchStatus,
    setSearch,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { SearchCandidate } from "../../../store/reducers/searchCandidates/searchCandidates.types";
import { endTracking, startTracking } from "../../../utils/sentry";

import useWindowSize from "@/hooks/useWindowSize";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";

const CustomStack = styled((props: StackProps & { visible?: boolean }) => (
    <Stack {...props} {...(!props.visible && { sx: { display: "none" } })} />
))(() => ({
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
}));

export function useProjectData() {
    const [searchParams] = useSearchParams();
    return {
        projectId: searchParams.get("project"),
        projectName: searchParams.get("name"),
        navigate: useNavigate(),
        dispatch: useDispatch(),
        searchId: useSelector(selectSearch)._id,
        candidates: useSelector(selectCandidates),
        isSuperAdmin: useSelector(isSuperAdminUser),
        isAddingToProject: useSelector(checkIfLoading(addSearchCandidatesToProject.type)),
    };
}

function CandidateCard(props: SearchCandidate) {
    const { company, location, name, photo, profileUrl, stability, title } = props;

    return (
        <Paper
            elevation={0}
            sx={(theme) => ({
                borderRadius: theme.shape.borderRadius,
                padding: "0.75rem 1.5rem",
                display: "grid",
                gridTemplateColumns: "80px 2fr",
                gap: "1rem",
                height: 140,
            })}
        >
            <Avatar
                sx={{ width: 60, height: 60 }}
                src={!photo || photo.startsWith("data:") ? undefined : photo}
                alt="avatar"
            />
            <Stack
                sx={(theme) => ({
                    display: "grid",
                    gridTemplateColumns: "1fr auto 1fr",
                    gridTemplateRows: "1fr",
                    gap: "1rem",
                })}
            >
                <Stack>
                    <Stack direction="row" alignItems="center">
                        <Typography fontSize={16} fontWeight={600}>
                            {name}
                        </Typography>
                        <IconButton color="info" size="small" component="a" href={profileUrl} target="_blank">
                            <LinkedInIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                    <Typography fontSize={14} fontWeight={500}>
                        {title}{" "}
                        {company?.name ? (
                            <Typography
                                component="a"
                                color="#0288d1"
                                fontSize="inherit"
                                href={company.url}
                                target="_blank"
                            >
                                at {company.name}
                            </Typography>
                        ) : null}
                    </Typography>
                    <Typography fontSize={13} mt={0.5}>
                        {location}
                    </Typography>
                </Stack>
                <Divider orientation="vertical" sx={{ height: "60%" }} />
                {stability && (
                    <Stack gap={1}>
                        <Typography variant="body2">{stability.role} in role</Typography>
                        <Typography variant="body2">{stability.company} in company</Typography>
                    </Stack>
                )}
            </Stack>
        </Paper>
    );
}

// function useFetchCandidatesEveryMinute() {
//     const dispatch = useDispatch();
//     const { projectId } = useProjectData();

//     const { _id: searchId, status, updatedAt } = useSelector(selectSearch);
//     const noOfCompanies = useSelector(selectNoOfCompanies);
//     const intervalRef = useRef<null | NodeJS.Timeout>(null);
//     const fetchCount = useRef(0);
//     const errorCondn = !["COMPLETED", "CANCELLED", "ZERO_PROFILE"].includes(status || "");
//     const maxFetchCount = noOfCompanies?.values.length > 0 ? 600 : 60;
//     const [transaction, setTransaction] = useState(null);

//     useEffect(() => {
//         if (searchId) {
//             dispatch(fetchCandidates(searchId));
//             const tracking = startTracking("Blended search salesnav results", "EasyGrowth");
//             setTransaction(tracking);
//             fetchCount.current = 1;
//         }
//     }, [searchId, updatedAt]);

//     useEffect(() => {
//         if (intervalRef?.current && errorCondn) {
//             clearInterval(intervalRef.current);
//             intervalRef.current = null;
//             endTracking(transaction);
//         }

//         if (searchId && fetchCount.current < maxFetchCount && errorCondn) {
//             console.log(`fetching: ${fetchCount.current}/${maxFetchCount}`);
//             intervalRef.current = setInterval(() => {
//                 fetchCount.current += 1;
//                 if (fetchCount.current === maxFetchCount) {
//                     endTracking(transaction);
//                     clearInterval(intervalRef.current!);
//                     dispatch(sendEmailNotification({ projectId, searchId }));
//                     dispatch(setCandidatesFetchStatus("ERROR"));
//                     dispatch(
//                         setSearch({
//                             _id: searchId,
//                             updatedAt,
//                             status: "CANCELLED",
//                         })
//                     );
//                 } else {
//                     dispatch(fetchCandidates(searchId));
//                 }
//             }, 5000);
//         }

//         return () => {
//             if (intervalRef?.current) {
//                 clearInterval(intervalRef.current);
//             }
//         };
//     }, [status, searchId]);
// }

export default function CandidateList() {
    const { projectId } = useProjectData();
    const { status } = useSelector(selectSearch);
    const candidates = useSelector(selectCandidates);
    const totalCandidates = useSelector(selectTotalCandidates);
    const superSetCount = useSelector(selectSupersetCount);
    const candidatesFetchStatus = useSelector(selectCandidatesFetchStatus);

    const currentCompany = useSelector(selectCurrentCompany);
    const { values: noOfCompaniesArr } = useSelector(selectNoOfCompanies);
    const noOfCompanies = noOfCompaniesArr[0]?.value;
    const totalCompanies = useSelector(selectTotalCompanies);
    const containerRef = useRef<HTMLDivElement>(null);

    const size = useWindowSize();
    const containerHeight = size?.width <= 1280 ? size?.height - 60 : size?.height - 200;

    // useFetchCandidatesEveryMinute();

    if (candidatesFetchStatus === "IDLE") {
        return null;
    }

    if (
        (candidatesFetchStatus === "LOADING" && status === "PENDING") ||
        (status === "IN_PROGRESS" && !candidates.length)
    ) {
        return <TypingLoader />;
    }

    let showSelectedNoOfCompanies = currentCompany?.values?.length;
    if (noOfCompanies === totalCompanies) {
        showSelectedNoOfCompanies = parseInt(totalCompanies, 10);
    }
    if (parseInt(noOfCompanies, 10) > 1) {
        showSelectedNoOfCompanies = parseInt(noOfCompanies, 10);
    }
    if (currentCompany?.values?.length > showSelectedNoOfCompanies) {
        showSelectedNoOfCompanies = showSelectedNoOfCompanies;
    }

    const getEstimateText = () => {
        if (!status || !superSetCount) return "";
        if (status === "IN_PROGRESS") {
            if (superSetCount < 10000) {
                return "This real-time search estimate takes 5-7 minutes to become accurate";
            } else {
                return "This shows initial real-time visibility and should become an accurate estimate in a few minutes";
            }
        }
        return "";
    };

    const mode = projectId ? "EDIT" : "CREATE";
    const zeroProfile = status === "ZERO_PROFILE";
    const errorCandidateStatus = candidatesFetchStatus === "ERROR";

    return (
        <>
            <CustomStack visible={zeroProfile}>
                <Typography color="#0891b2" fontWeight={500}>
                    Our AI Agents were not able to find relevant profiles matching your requirements. Please modify your
                    search and try again.
                </Typography>
            </CustomStack>

            <CustomStack visible={!candidates.length && !zeroProfile && errorCandidateStatus}>
                <Typography color="#0891b2" fontWeight={500}>
                    AI scraping agents are busy right now. Search results typically become available within 15-30
                    minutes of this message
                </Typography>
            </CustomStack>

            <Stack style={{ height: "100%" }}>
                {candidates.length > 0 ? (
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography fontWeight={600}>
                                    {mode === "EDIT" ? "Net new prospects" : "Prospects Preview"}
                                </Typography>

                                <Tooltip
                                    title={
                                        mode === "EDIT"
                                            ? "These are net new prospects that are not already present in your campaign"
                                            : "You should see up to about 200 prospects in this preview, unless your search was very specific. Change your search filters to change your results preview. Otherwise, create your campaign to add as many prospects as you want"
                                    }
                                >
                                    <InfoIcon sx={{ fontSize: "18px", color: "grey" }} />
                                </Tooltip>
                                {status === "IN_PROGRESS" && (
                                    <TypeAnimation
                                        sequence={[
                                            "The AI is trying a broader search to get you relevant prospects",
                                            2000,
                                        ]}
                                        speed={50}
                                        style={{
                                            fontStyle: "italic",
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: "#0891b2",
                                        }}
                                    />
                                )}
                            </Stack>
                            <Tooltip title="This amount is based on estimate and actual amount may change">
                                <Typography fontSize={12}>
                                    <i>
                                        {totalCandidates} prospects{" "}
                                        {`${superSetCount ? `(estimated universe of ${millify(superSetCount)} prospects)` : ""}`}
                                    </i>
                                </Typography>
                            </Tooltip>
                        </Stack>
                        <Stack sx={{ overflowY: "hidden", height: containerHeight }}>
                            <div ref={containerRef} style={{ overflowY: "scroll" }}>
                                <VirtualizedList
                                    containerRef={containerRef}
                                    numItems={candidates.length}
                                    itemHeight={150}
                                    renderItem={({ index, ...rest }) => {
                                        const candidate = candidates[index];
                                        return (
                                            <div key={index} {...rest}>
                                                <CandidateCard {...candidate} />
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </Stack>
                    </>
                ) : null}
                <CustomStack visible={!candidates.length && !zeroProfile && !errorCandidateStatus}>
                    <Sleep fallback={<TypingLoader />}>
                        <Typography color="#0891b2" fontWeight={500}>
                            No prospects found for this search.
                        </Typography>
                    </Sleep>
                </CustomStack>

                <AddToProject />
            </Stack>
        </>
    );
}
