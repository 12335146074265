// Drawer.tsx
import * as React from "react";
import { Drawer as DrawerPrimitive } from "vaul";

import { cn } from "@/lib/utils";

export interface DrawerProps extends React.ComponentProps<typeof DrawerPrimitive.Root> {
    children: React.ReactNode;
    direction?: "top" | "right" | "bottom" | "left";
}

const Drawer = ({ children, ...props }: DrawerProps) => (
    <DrawerPrimitive.Root {...props}>{children}</DrawerPrimitive.Root>
);

Drawer.displayName = "Drawer";

const DrawerTrigger = DrawerPrimitive.Trigger;
const DrawerPortal = DrawerPrimitive.Portal;
const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <DrawerPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-black/80", className)} {...props} />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

interface DrawerContentProps extends React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> {
    position?: "top" | "right" | "bottom" | "left";
    size?: "sm" | "md" | "lg" | "xl" | "full";
}

const DrawerContent = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Content>, DrawerContentProps>(
    ({ className, children, position = "right", size = "md", ...props }, ref) => (
        <DrawerPortal>
            <DrawerOverlay />
            <DrawerPrimitive.Content
                ref={ref}
                className={cn(
                    "fixed z-50 flex flex-col bg-background shadow-lg transition ease-in-out",
                    position === "top" && "inset-x-0 top-0 border-b",
                    position === "bottom" && "inset-x-0 bottom-0 border-t",
                    position === "left" && "inset-y-0 left-0 h-full border-r",
                    position === "right" && "inset-y-0 right-0 h-full border-l",
                    (position === "top" || position === "bottom") && {
                        "h-1/4": size === "sm",
                        "h-1/3": size === "md",
                        "h-1/2": size === "lg",
                        "h-2/3": size === "xl",
                        "h-screen": size === "full",
                    },
                    (position === "left" || position === "right") && {
                        "w-1/4": size === "sm",
                        "w-1/3": size === "md",
                        "w-1/2": size === "lg",
                        "w-2/3": size === "xl",
                        "w-screen": size === "full",
                    },
                    className
                )}
                {...props}
            >
                {position === "top" && <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted" />}
                {children}
                {position === "bottom" && <div className="mx-auto mb-4 h-2 w-[100px] rounded-full bg-muted" />}
            </DrawerPrimitive.Content>
        </DrawerPortal>
    )
);
DrawerContent.displayName = "DrawerContent";

const DrawerHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("grid gap-1.5 p-4 text-center sm:text-left", className)} {...props} />
);
DrawerHeader.displayName = "DrawerHeader";

const DrawerFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("mt-auto flex flex-col gap-2 p-4", className)} {...props} />
);
DrawerFooter.displayName = "DrawerFooter";

const DrawerTitle = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DrawerPrimitive.Title
        ref={ref}
        className={cn("text-lg font-semibold leading-none tracking-tight", className)}
        {...props}
    />
));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = React.forwardRef<
    React.ElementRef<typeof DrawerPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DrawerPrimitive.Description ref={ref} className={cn("text-sm text-muted-foreground", className)} {...props} />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export {
    Drawer,
    DrawerPortal,
    DrawerOverlay,
    DrawerTrigger,
    DrawerClose,
    DrawerContent,
    DrawerHeader,
    DrawerFooter,
    DrawerTitle,
    DrawerDescription,
};
