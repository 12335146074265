import { IconButton, Tooltip } from "@mui/joy";
import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AcceptanceInvite from "../../components/AcceptanceInvite";
import { JoyProvider } from "../../components/JoyProvider";
import RejectanceInvite from "../../components/RejectanceInvite";
import { useGetInvitationQuery, useSubmitInvitationMutation } from "../../store/apis/invitations/invitations.api";

function InvitationsLayout({ children }: { children: React.ReactNode }) {
    return (
        <JoyProvider>
            <Box sx={{ backgroundColor: "background.body", minHeight: "100vh" }}>
                <Box sx={{ pt: 1, pr: 1 }}>
                    {/* <MaterialProvider>
                        <Navbar />
                    </MaterialProvider> */}
                </Box>
                {children}
            </Box>
        </JoyProvider>
    );
}

export default function Invitations() {
    // const query = useLocation()
    // const invitationId = query.get("invitationId");
    // const invitationId="";
    // console.log(invitationId);

    const [searchParams] = useSearchParams();
    const invitationId = searchParams.get("invitationId");
    console.log(invitationId);
    const navigate = useNavigate();
    const { data, isLoading } = useGetInvitationQuery(invitationId as string); // api call on page loading
    const [submitInvitation, { isLoading: isSubmitting }] = useSubmitInvitationMutation();
    const [acceptanceInviteModalOpen, setacceptanceInviteModalOpen] = useState(false);
    const [rejectanceInviteModalOpen, setrejectanceInviteModalOpen] = useState(false);

    useEffect(() => {
        if (data) {
            if (data.status === "ACCEPTED") {
                navigate("/invitation-already-accepted");
            } else if (data.status === "REJECTED") {
                navigate("/invitation-already-rejected");
            }
        }
    }, [data]);

    const handleSubmitInvitation = (status: string, inviteId: string, name: string, role: string) => {
        submitInvitation({
            status,
            inviteId,
            name,
            role,
        });
    };

    const handleInviteAccepted = () => {
        // console.log("ACCEPTED BUTTON CLICKED");

        if (invitationId) {
            handleSubmitInvitation("ACCEPTED", invitationId, data.receiverName, data.role);
            setacceptanceInviteModalOpen(true);
        } else {
            console.log("invitation id null");
        }
    };

    const handleInviteRejected = () => {
        // console.log("REJECTED BUTTON CLICKED");
        if (invitationId) {
            handleSubmitInvitation("REJECTED", invitationId, data.receiverName, data.role);
            setrejectanceInviteModalOpen(true);
        } else {
            console.log("invitation id null");
        }
    };

    const handleInviteSkipped = () => {
        // console.log("SKIPPED BUTTON CLICKED");
        if (invitationId) {
            handleSubmitInvitation("PENDING", invitationId, data.receiverName, data.role);
        }
        navigate("/");
    };

    if (isLoading) {
        return (
            <JoyProvider>
                <Stack sx={{ height: "90vh" }} alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Stack>
            </JoyProvider>
        );
    }

    return (
        <InvitationsLayout>
            <Stack
                spacing={2}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "75vh",
                }}
            >
                <Stack spacing={2}>
                    <Typography
                        level="h1"
                        sx={{
                            textAlign: "center",
                            fontWeight: "700",
                        }}
                    >
                        Invitation
                    </Typography>

                    <Typography
                        level="h3"
                        sx={{
                            textAlign: "center",
                            fontWeight: "500",
                        }}
                    >
                        {data.receiverName}
                    </Typography>

                    <Typography
                        sx={{
                            textAlign: "center",
                            lineHeight: "1px",
                        }}
                    >
                        You have been invited by {data.senderName} to collaborate on HireQuotient's {data.orgName}{" "}
                        Organization.
                    </Typography>

                    <Typography
                        sx={{
                            textAlign: "center",
                            lineHeight: "10px",
                        }}
                    >
                        Your role is {data.role}. To accept this invitation, follow the link below.
                    </Typography>
                </Stack>

                <Stack direction="row" justifyContent="center" alignItems="center" spacing={4} minHeight="10vh">
                    <Tooltip title="" onClick={handleInviteAccepted}>
                        <IconButton
                            color="primary"
                            sx={{
                                color: "white",
                                backgroundColor: "#479cd2",
                                border: "1px solid #97c3f0",
                                fontWeight: "700",
                                "&:hover": {
                                    backgroundColor: "white",
                                    color: "#479cd2",
                                },
                            }}
                        >
                            Accept Invite
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="" onClick={handleInviteRejected}>
                        <IconButton
                            color="primary"
                            sx={{ color: "#479cd2", border: "1px solid #97c3f0", fontWeight: "700" }}
                        >
                            Reject Invite
                        </IconButton>
                    </Tooltip>
                </Stack>

                <Tooltip title="" onClick={handleInviteSkipped}>
                    <IconButton
                        color="primary"
                        sx={{ color: "#479cd2", border: "1px solid #97c3f0", fontWeight: "700" }}
                    >
                        Skip for now
                    </IconButton>
                </Tooltip>

                <AcceptanceInvite
                    open={acceptanceInviteModalOpen}
                    onClose={() => setacceptanceInviteModalOpen(false)}
                />

                <RejectanceInvite
                    open={rejectanceInviteModalOpen}
                    onClose={() => setrejectanceInviteModalOpen(false)}
                />
            </Stack>
        </InvitationsLayout>
    );
}
