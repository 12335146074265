import {
    AutocompleteOption,
    Button,
    CircularProgress,
    ListItemContent,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Typography,
} from "@mui/joy";
import Autocomplete from "@mui/joy/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { JoyProvider } from "../../../../components/JoyProvider";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    addToNucleus,
    getNucleusJobs,
    getNucleusProjects,
    getNucleusStages,
    selectNucleusJobs,
    selectNucleusProjects,
    selectNucleusStages,
} from "../../../integrations/integrations.reducer";

type NucleusModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

export default function NucleusModal({ open, onClose, id, selectedCandidateIds, variant }: NucleusModalProps) {
    const dispatch = useDispatch();
    const [jobId, setJobId] = useState("");
    const [stageId, setStageId] = useState("");
    const [nucleusProjectId, setNucleusProjectId] = useState();

    const nucleusJobs = useSelector(selectNucleusJobs);
    const nucleusStages = useSelector(selectNucleusStages);
    const nucleusProjects = useSelector(selectNucleusProjects);
    const isNucleusJobsLoading = useSelector(checkIfLoading(getNucleusJobs.type));
    const isNucleusStagesLoading = useSelector(checkIfLoading(getNucleusStages.type));
    const isNucleusSubmitting = useSelector(checkIfLoading(addToNucleus.type));
    const isNucleusProjectsLoading = useSelector(checkIfLoading(getNucleusProjects.type));

    const isDisabled = isNucleusJobsLoading || isNucleusStagesLoading || isNucleusSubmitting;

    useEffect(() => {
        if (open) {
            dispatch(getNucleusJobs({ action: getNucleusJobs.type }));
            dispatch(getNucleusStages({ action: getNucleusStages.type }));
            dispatch(getNucleusProjects({ action: getNucleusProjects.type }));
        }
    }, [open, dispatch]);

    const handleCloseModal = () => {
        setJobId("");
        setStageId("");

        onClose();
    };

    const handleAddNucleus = () => {
        if (!stageId) {
            dispatch(setErrorNotification("Please select a stage"));
            return;
        }

        dispatch(
            addToNucleus({
                projectId: id,
                candidateIds: selectedCandidateIds,
                jobId,
                stageId,
                nucleusProjectId,
                action: addToNucleus.type,
                onSuccess: handleCloseModal,
            })
        );
    };

    return (
        <JoyProvider>
            <Modal open={open} onClose={handleCloseModal}>
                <ModalDialog
                    aria-labelledby="nucleus-modal-title"
                    aria-describedby="nucleus-modal-description"
                    sx={{ maxWidth: 600, width: 400 }}
                >
                    <ModalClose />
                    <Typography id="nucleus-modal-title" level="h2" mb={2}>
                        Send to Nucleus ATS
                    </Typography>
                    <Stack spacing={2}>
                        <JobSelector
                            isLoading={isNucleusJobsLoading}
                            jobs={nucleusJobs}
                            value={jobId}
                            onChange={setJobId}
                        />
                        <StageSelector
                            isLoading={isNucleusStagesLoading}
                            stages={nucleusStages}
                            value={stageId}
                            onChange={setStageId}
                        />
                        <ProjectSelector
                            isLoading={isNucleusProjectsLoading}
                            projects={nucleusProjects}
                            value={nucleusProjectId}
                            onChange={setNucleusProjectId}
                        />
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="flex-end" mt={3}>
                        <Button variant="outlined" color="neutral" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button disabled={isDisabled} onClick={handleAddNucleus}>
                            <ButtonTextWithLoading isLoading={isNucleusSubmitting} text="Confirm" />
                        </Button>
                    </Stack>
                </ModalDialog>
            </Modal>
        </JoyProvider>
    );
}

type SelectorProps<T> = {
    isLoading: boolean;
    value: string;
    onChange: (value: string) => void;
    items: T[];
    label: string;
};

function Selector<T extends { id: string; name: string }>({
    isLoading,
    value,
    onChange,
    items,
    label,
}: SelectorProps<T>) {
    return (
        <Autocomplete
            type="search"
            placeholder={label}
            disableClearable={false}
            options={items}
            getOptionKey={(option) => option?.id}
            renderOption={(props, option) => (
                <AutocompleteOption key={option.id} {...props}>
                    <ListItemContent sx={{ fontSize: "sm" }}>{option.name}</ListItemContent>
                </AutocompleteOption>
            )}
            getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
            onChange={(event, newValue) => {
                onChange(newValue?.id);
            }}
            endDecorator={isLoading ? <CircularProgress size={"sm"} thickness={2} /> : null}
            sx={{
                "& .MuiAutocomplete-clearIndicator": {
                    display: "none", // Hides the additional clear icon if it's added by default styling
                },
            }}
        />
    );
}

function JobSelector({ isLoading, jobs, value, onChange }) {
    return <Selector isLoading={isLoading} value={value} onChange={onChange} items={jobs} label="Select Job" />;
}

function StageSelector({ isLoading, stages, value, onChange }) {
    return <Selector isLoading={isLoading} value={value} onChange={onChange} items={stages} label="Select Stage" />;
}

function ProjectSelector({ isLoading, projects, value, onChange }) {
    return <Selector isLoading={isLoading} value={value} onChange={onChange} items={projects} label="Select Project" />;
}
