import { useEffect, useRef, useState } from "react";
import { capitalize } from "lodash";

import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import Popover from "@mui/material/Popover";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

import { POPOVER_BOX_SHADOW } from "../../../utils/Constants";
import { PersonalizeTagActionsWithMutation } from "../../../hooks/usePersonalizeValues";

type CalendlyMenuProps = {
    calendlyActions: PersonalizeTagActionsWithMutation[];
    addCalendlyTag: (text: string, link: string) => void;
};

export default function CalendlyMenu({ calendlyActions, addCalendlyTag }: CalendlyMenuProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedValue, setSelectedValue] = useState("");
    const shorthandRef = useRef<HTMLInputElement>(null);
    const [shorthandValue, setShortHandValue] = useState("");
    const open = Boolean(anchorEl);

    useEffect(() => {
        const open = Boolean(anchorEl);
        if (!open) {
            setSelectedValue("");
            setShortHandValue("");
        }
    }, [anchorEl]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelectedValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue((event.target as HTMLInputElement).value);
        shorthandRef?.current?.focus();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getFormControlProps = (value: string, url: string = ""): FormControlLabelProps => {
        return {
            value: url,
            label: capitalize(value),
            sx: {
                "& .MuiTypography-root": {
                    fontSize: 14,
                },
            },
            control: (
                <Radio
                    sx={{
                        "& .MuiSvgIcon-root": {
                            fontSize: 15,
                        },
                    }}
                />
            ),
        };
    };

    const handleSubmitShortHand = () => {
        if (shorthandValue && selectedValue) {
            addCalendlyTag(shorthandValue, selectedValue);
            handleClose();
        }
    };

    return (
        <>
            <IconButton onClick={handleClick} sx={{ alignSelf: "flex-end" }}>
                <TodayRoundedIcon fontSize="large" sx={{ height: "28px", width: "28px" }} />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        boxShadow: POPOVER_BOX_SHADOW,
                    },
                }}
            >
                <Stack p={1.5} gap={1}>
                    <RadioGroup value={selectedValue} onChange={handleSelectedValue}>
                        {calendlyActions.map(({ key, label, url }) => {
                            return <FormControlLabel {...getFormControlProps(label, url)} key={key} />;
                        })}
                    </RadioGroup>
                    <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{
                            height: "fit-content",
                            width: "fit-content",
                        }}
                    >
                        <TextField
                            label="Display Text"
                            variant="outlined"
                            value={shorthandValue}
                            onChange={(e) => setShortHandValue(e.target.value)}
                            placeholder="Enter display text"
                            size="small"
                            inputProps={{
                                ref: shorthandRef,
                            }}
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: 14,
                                },
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                                "& .MuiOutlinedInput-notchedOutline legend": {
                                    width: "max-content !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline span": {
                                    width: "70px",
                                },
                            }}
                        />
                        <IconButton
                            onClick={handleSubmitShortHand}
                            sx={(theme) => ({
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.common.white,
                                ":hover": {
                                    backgroundColor: theme.palette.primary.main,
                                },
                            })}
                        >
                            <NavigateNextRoundedIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
}
