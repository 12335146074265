import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../store/reducers/loaders.reducer";
import { fetchOptions } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";
import { DEBOUNCE_DELAY } from "../utils/Constants";
import useDebounce from "./useDebounce";

export default function useSearchState(key: SearchStateKeys) {
    const dispatch = useDispatch();
    const [query, setQuery] = useState("");
    const debouncedValue = useDebounce<string>(query, DEBOUNCE_DELAY);
    const isLoading = useSelector(checkIfLoading(`${fetchOptions.type}-${key}`));

    useEffect(() => {
        if (
            debouncedValue.length > 0 &&
            !(["pastCompany", "keywords", "jobFunction", "industry", "companyHeadcount"] as SearchStateKeys[]).includes(
                key
            )
        ) {
            dispatch(fetchOptions({ value: debouncedValue, key }));
        }
    }, [debouncedValue]);

    return { isLoading, query, setQuery, dispatch };
}
