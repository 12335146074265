import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

import useDebounce from "./useDebounce";

import { highlightText } from "../utils/highlight-text";

// Generate a unique ID for the search input element
const searchBarClass = "hq-searchInput-container";
const searchBarId = searchBarClass + Math.random().toString(36);
const contentDiv = document.getElementById("root");

/**
 * Closes the search bar and executes a callback function.
 * @param {() => void} onClose - The callback function to execute after closing the search bar.
 */

function closeSearchBar(onClose: () => void) {
    const searchBar = document.querySelector(`.${searchBarClass}`);
    if (searchBar) {
        searchBar.style.top = "-60px"; // Slide up effect
        setTimeout(() => searchBar.remove(), 100); // Remove after animation duration
        onClose();
    }
}

/**
 * Creates a floating search bar and sets up its functionality.
 * @param {(searchText: string) => void} onSearch - The function to call with the search text.
 */

function createFloatingSearchBar(onSearch: (searchText: string) => void = () => {}, enableTransition: boolean = true) {
    // Create search bar container
    const searchBar = document.createElement("div");
    searchBar.id = searchBarId;
    searchBar.classList.add(searchBarClass);
    searchBar.style.position = "fixed";
    searchBar.style.top = enableTransition ? "-60px" : "6px";
    searchBar.style.right = "50%";
    searchBar.style.transform = "translateX(50%)";
    searchBar.style.backgroundColor = "#fff";
    searchBar.style.padding = "8px 12px";
    searchBar.style.display = "flex";
    searchBar.style.alignItems = "center";
    searchBar.style.zIndex = "1000";
    searchBar.style.transition = "top 0.3s ease"; // Transition for sliding effect
    searchBar.style.boxShadow =
        "0 8px 16px rgba(0, 0, 0, 0.1),0 4px 8px rgba(0, 0, 0, 0.08), 0 -1px 3px rgba(0, 0, 0, 0.05)";
    // Create search input
    const searchInput = document.createElement("input");
    searchInput.type = "text";
    searchInput.placeholder = "Search";
    searchInput.style.backgroundColor = "transparent";
    searchInput.style.border = "none";
    searchInput.style.color = "#000";
    searchInput.style.outline = "none";
    searchInput.style.width = "350px";
    searchInput.style.padding = "4px";
    searchInput.style.fontSize = "14px";
    // searchInput.style.fontFamily = "Arial, sans-serif";
    searchInput.id = "searchInput";
    searchInput.autofocus = true;

    // Create close button
    const closeButton = document.createElement("button");
    closeButton.innerHTML = "✕";
    closeButton.style.backgroundColor = "transparent";
    closeButton.style.border = "none";
    closeButton.style.color = "#808080";
    closeButton.style.cursor = "pointer";
    closeButton.style.fontSize = "16px";
    closeButton.style.display = "flex";
    closeButton.style.alignItems = "center";
    closeButton.style.padding = "4px";
    closeButton.style.marginLeft = "8px";

    // Append input and close button to the search bar
    searchBar.appendChild(searchInput);
    searchBar.appendChild(closeButton);

    // Add search bar to the document body
    document.body.appendChild(searchBar);

    if (enableTransition) {
        // Trigger slide down animation by setting the top position
        setTimeout(() => {
            searchBar.style.top = "6px"; // Slide down to this position
        }, 0);
    }

    // Add event listener for the close button
    closeButton.addEventListener("click", () => {
        closeSearchBar(() => {
            onSearch("");
        });
    });

    // Add functionality for search (basic implementation)
    searchInput.addEventListener("input", (e: Event) => {
        const target = e.target as HTMLInputElement; // Type assertion to HTMLInputElement
        const searchText = target.value.trim();
        onSearch(searchText);
    });

    // focus on the search input
    searchInput.focus();

    return searchBar;
}

function replaceSearchBar(setSearchText: (searchText: string) => void) {
    // if there is a search bar, close it
    const existingSearchBar = document.querySelector(`.${searchBarClass}`);

    if (existingSearchBar) {
        const existingSearchInput = existingSearchBar.querySelector("input").value;
        // replace the search bar with the new one
        existingSearchBar.parentNode?.replaceChild(createFloatingSearchBar(setSearchText, false), existingSearchBar);
        const newSearchbar = document.getElementById(searchBarId).querySelector("input");
        newSearchbar.value = existingSearchInput;
        newSearchbar.focus();
    }
}

/**
 * Custom hook to manage the page search functionality.
 * @returns {string} The current search text.
 */
function usePageSearch() {
    const [searchText, setSearchText] = useState(
        (document.getElementById("searchInput") as HTMLInputElement)?.value || ""
    );
    const handleHighlightText = (searchText: string) => {
        highlightText(searchText, contentDiv);
    };

    useDebounce(() => handleHighlightText(searchText), 200);

    const debouncedSetSearch = useCallback(debounce(setSearchText, 200), []);

    useEffect(() => {
        // Check if the search bar already exists, if yes then replace it with the new one
        replaceSearchBar(debouncedSetSearch);

        // Event listener for keyboard shortcuts
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && ["f", "F"].includes(event.key)) {
                // Ctrl + F or Cmd + F
                event.preventDefault();

                if (!document.getElementById(searchBarId)) {
                    createFloatingSearchBar(debouncedSetSearch);
                }
            }

            if (event.key === "Escape") {
                // Escape key
                closeSearchBar(() => {
                    setSearchText("");
                });
            }
        };

        const handleWheel = (e: WheelEvent) => {
            if (!document.getElementById(searchBarId)) {
                return;
            }
            debounce(
                () => handleHighlightText((document.getElementById("searchInput") as HTMLInputElement)?.value),
                200
            )();
        };

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("wheel", handleWheel);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("wheel", handleWheel);
        };
    }, []);

    return searchText;
}

export default usePageSearch;
