import CircularProgress from "@mui/joy/CircularProgress";
import Stack from "@mui/joy/Stack";

export function PageLoader() {
    return (
        <Stack sx={{ height: "70vh" }} alignItems="center" justifyContent="center">
            <CircularProgress />
        </Stack>
    );
}
