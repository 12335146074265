import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Experimental_CssVarsProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EVENT_KEYS_TEMPLATE_TYPE_MAP } from "./TemplatesMenu";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { CustomTab, CustomTabsContainer } from "../../../components/CustomTabs";
import { RootState } from "../../../store";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import CustomMUIInput from "../../project/components/CustomMUIInput";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import {
    selectCurrentOutreachElementType,
    selectOutreachTemplateContent,
} from "@/store/reducers/outreach/outreach.slice";
import { createNewTemplate, editTemplate } from "@/store/reducers/template/template.reducer";
import { Button, Modal, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";
import { useFetchTemplatesQuery } from "@/store/apis/outreach/outreach.api";

const tabStyle = {
    padding: "0 0.5rem",
    height: "fit-content",
    minHeight: "33px",
    margin: "5px",
    borderRadius: "2.5rem",
    fontSize: 14,
    flex: 1,
    "&:hover": {
        opacity: 1,
        color: "#0891b2",
    },
    "&.Mui-selected": {
        color: "white",
        backgroundColor: "#0891b2",
    },
};

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Stack style={{ marginTop: 1, marginBottom: 2 }}>{children}</Stack>}
        </div>
    );
}
const TEMPLATE_TYPE_EVENT_KEYS_MAP = {
    EMAIL: "email",
    FOLLOW_UP: "follow-up",
    SMS: "SMS",
    LINKEDIN: "linkedin",
} as const;

const SaveTemplate = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const { currentOutreachElementId } = useOutreachParams();
    const [activeTab, setActiveTab] = useState(0);
    const [templateName, setTemplateName] = useState("");
    const [templateId, setTemplateId] = useState("");
    const dispatch = useDispatch();

    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );
    const { data, isFetching } = useFetchTemplatesQuery({ type: EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] });

    const isTemplateSaving = useSelector(checkIfLoading(createNewTemplate.type));
    const isTemplateUpdating = useSelector(checkIfLoading(editTemplate.type));

    const template = useSelector((state: RootState) => selectOutreachTemplateContent(state, currentOutreachElementId));

    const isLoading = isTemplateSaving || isTemplateUpdating || isFetching;
    const templateList = data?.filter((item) => TEMPLATE_TYPE_EVENT_KEYS_MAP[item.type] === currentElementType);

    const closeSaveTemplateModal = () => {
        setTemplateId("");
        setTemplateName("");
        onClose();
    };
    useEffect(() => {
        if (isOpen && templateId === "") {
            setTemplateId(templateList?.[0]?._id || "");
        }
    }, [isOpen, templateList]);
    function handleSave() {
        if (!template) {
            dispatch(setErrorNotification("Template not found"));
            return;
        }
        let body = "";
        let subject = "";
        let connectionReminderMessageBody = "";
        let inmailFollowupBody = "";
        let inMailBody = "";
        let inMailSubject = "";
        let inmailFollowupSubject = "";
        let connectionReminderMessageStatus = false;
        let isScheduleRecruiterOnSameDay = false;
        let inmailFollowupStatus = false;
        let inmailFollowupReminder = "";
        let connectionStatus = false;
        let inMailStatus = false;

        if ("body" in template) {
            body = template.body;
        }
        if ("subject" in template) {
            subject = template.subject;
        }
        if ("inMailBody" in template) {
            inMailBody = template.inMailBody;
        }
        if ("inMailSubject" in template) {
            inMailSubject = template.inMailSubject;
        }
        if ("connectionReminderMessageBody" in template) {
            connectionReminderMessageBody = template.connectionReminderMessageBody;
        }
        if ("inmailFollowupBody" in template) {
            inmailFollowupBody = template.inmailFollowupBody;
        }

        if ("inmailFollowupSubject" in template) {
            inmailFollowupSubject = template.inmailFollowupSubject;
        }

        if ("connectionReminderMessageStatus" in template) {
            connectionReminderMessageStatus = template.connectionReminderMessageStatus;
        }

        if ("isScheduleRecruiterOnSameDay" in template) {
            isScheduleRecruiterOnSameDay = template.isScheduleRecruiterOnSameDay;
        }

        if ("inmailFollowupStatus" in template) {
            inmailFollowupStatus = template.inmailFollowupStatus;
        }
        if ("inmailFollowupReminder" in template) {
            inmailFollowupReminder = template.inmailFollowupReminder;
        }

        if ("connectionStatus" in template) {
            connectionStatus = template.connectionStatus;
        }
        if ("inMailStatus" in template) {
            inMailStatus = template.inMailStatus;
        }

        if (activeTab === 0) {
            if (!templateName) {
                dispatch(setErrorNotification("Template name can't be empty"));
                return;
            }
            dispatch(
                createNewTemplate({
                    name: templateName,
                    body: body,
                    type: EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType],
                    ...(subject && { subject: subject }),
                    ...(inMailBody && { inMailBody: inMailBody }),
                    ...(inMailSubject && { inMailSubject: inMailSubject }),
                    ...(connectionReminderMessageBody && {
                        connectionReminderMessageBody: connectionReminderMessageBody,
                    }),
                    ...(inmailFollowupBody && { inmailFollowupBody: inmailFollowupBody }),
                    ...(inmailFollowupSubject && { inmailFollowupSubject: inmailFollowupSubject }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        connectionReminderMessageStatus: connectionReminderMessageStatus,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        isScheduleRecruiterOnSameDay: false,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        inmailFollowupStatus: inmailFollowupStatus,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        inmailFollowupReminder: inmailFollowupReminder,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        connectionStatus: connectionStatus,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        inMailStatus: inMailStatus,
                    }),
                    onSuccess: () => {
                        closeSaveTemplateModal();
                    },
                })
            );
        } else {
            if (!templateId) {
                dispatch(setErrorNotification("Select template to be updated"));
                return;
            }
            const templateName = templateList?.find((item) => item._id === templateId)?.name || "";
            dispatch(
                editTemplate({
                    name: templateName,
                    body: body,
                    type: EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType],
                    ...(subject && { subject: subject }),
                    ...(inMailBody && { inMailBody: inMailBody }),
                    ...(inMailSubject && { inMailSubject: inMailSubject }),
                    ...(connectionReminderMessageBody && {
                        connectionReminderMessageBody: connectionReminderMessageBody,
                    }),
                    ...(inmailFollowupBody && { inmailFollowupBody: inmailFollowupBody }),
                    ...(inmailFollowupSubject && { inmailFollowupSubject: inmailFollowupSubject }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        connectionReminderMessageStatus: connectionReminderMessageStatus,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        isScheduleRecruiterOnSameDay: false,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        inmailFollowupStatus: inmailFollowupStatus,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        inmailFollowupReminder: inmailFollowupReminder,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        connectionStatus: connectionStatus,
                    }),
                    ...(EVENT_KEYS_TEMPLATE_TYPE_MAP[currentElementType] === "LINKEDIN" && {
                        inMailStatus: inMailStatus,
                    }),
                    active: true,
                    _id: templateId,
                    onSuccess: () => {
                        closeSaveTemplateModal();
                    },
                })
            );
        }
    }

    return (
        <Modal open={isOpen} onClose={closeSaveTemplateModal}>
            <div
                style={{
                    width: 450,
                    borderRadius: 7,
                    padding: 2,
                    boxShadow: "lg",
                    backgroundColor: "white",
                }}
            >
                <Typography className="text-lg font-medium text-[#334d6e] pb-2">Save draft</Typography>

                <Experimental_CssVarsProvider>
                    <CustomTabsContainer
                        value={activeTab}
                        onChange={(_, val) => setActiveTab(val)}
                        sx={{
                            borderRadius: "2.5rem",
                            minHeight: "fit-content",
                            width: "100%",
                            marginBottom: 2,
                            border: "1px solid #0891b2",
                        }}
                    >
                        <CustomTab
                            label="Create New"
                            icon={<AddRoundedIcon fontSize="small" />}
                            iconPosition="start"
                            sx={tabStyle}
                        />
                        <CustomTab
                            label="Update Existing"
                            icon={<EditRoundedIcon fontSize="small" />}
                            iconPosition="start"
                            sx={tabStyle}
                        />
                    </CustomTabsContainer>

                    <TabPanel value={activeTab} index={0}>
                        <CustomMUIInput
                            fullWidth
                            placeholder="Name of draft"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <form>
                            <label>Choose draft</label>
                            <div className="relative">
                                <select
                                    value={templateId}
                                    onChange={(e) => setTemplateId(e.target.value)}
                                    className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#0891b2] focus:border-[#0891b2]"
                                >
                                    {templateList?.map(({ _id, name }) => (
                                        <option key={_id} value={_id}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </TabPanel>
                    <Stack
                        gap={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        style={{ marginTop: 15 }}
                    >
                        <Button size="sm" variant="outline" onClick={closeSaveTemplateModal}>
                            Cancel
                        </Button>
                        <Button size="sm" disabled={isLoading} onClick={handleSave} variant="default">
                            <ButtonTextWithLoading
                                text={activeTab === 0 ? "Create" : "Update"}
                                isLoading={isLoading}
                                variant="light"
                            />
                        </Button>
                    </Stack>
                </Experimental_CssVarsProvider>
            </div>
        </Modal>
    );
};

export default SaveTemplate;
