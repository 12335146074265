import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, IconButton, Typography } from "@mui/joy";

import { JoyProvider } from "../../../JoyProvider";

interface HeaderProps {
    onBack?: () => void;
}

function Header(props: HeaderProps) {
    const { onBack } = props;
    return (
        <JoyProvider>
            <Box
                sx={{
                    padding: "8px 12px",
                    display: "grid",
                    alignItems: "center",
                    position: "sticky",
                    gridTemplateColumns: "auto 1fr",
                    top: 0,
                }}
            >
                <IconButton size="sm" onClick={onBack} sx={{ justifySelf: "start" }}>
                    <ArrowBackIosNewIcon fontSize="small" htmlColor="#0d7a89" />
                </IconButton>

                <Typography
                    justifySelf={"center"}
                    sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "black",
                        marginLeft: "-12px",
                    }}
                >
                    Aish
                </Typography>
            </Box>
        </JoyProvider>
    );
}

export default Header;
