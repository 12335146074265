import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useCreateActivityFormState } from "../NotesTab";

export function CreateActivityBody() {
    const { control } = useCreateActivityFormState();
    return (
        <Controller
            control={control}
            name="body"
            render={({ field, fieldState: { error } }) => {
                return (
                    <FormControl style={{ width: "100%" }}>
                        <TextField
                            {...field}
                            size="small"
                            multiline
                            minRows={4}
                            sx={(theme) => {
                                const fontSize = theme.typography.body2;
                                return {
                                    "& .MuiFormHelperText-root": {
                                        margin: "4px 0 0",
                                    },
                                    "& .MuiFormLabel-root": {
                                        top: "-8px",
                                        ...fontSize,
                                        '&[data-shrink="true"]': {
                                            top: "0px",
                                        },
                                    },
                                    "& .MuiInputBase-root": {
                                        padding: 0,
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        padding: "0.7rem",
                                        maxHeight: "200px",
                                        overflow: "auto !important",
                                        ...fontSize,
                                    },
                                };
                            }}
                            error={Boolean(error)}
                            helperText={error?.message}
                        />
                    </FormControl>
                );
            }}
        />
    );
}
