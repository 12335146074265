import { Button } from "@mui/joy";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import { Typography } from "@mui/material";

const GiftCard = ({ product, index, handleDetailedView, cardRefs, imageUrl }) => {
    return (
        <Card
            ref={(el) => {
                if (el) {
                    cardRefs.current[product.id] = el;
                }
            }}
            sx={{
                width: 300,
                height: 380,
                marginBottom: "20px",
                marginRight: "10px",
                borderRadius: "sm",
                backgroundColor: "white",
            }}
        >
            <div style={{ height: 300 }}>
                <AspectRatio minHeight="200px" maxHeight="200px">
                    <img src={imageUrl} loading="lazy" alt="" />
                </AspectRatio>
                <Typography sx={{ fontSize: "0.8rem", marginTop: "4px" }}>{product?.name}</Typography>
            </div>
            <CardContent orientation="horizontal">
                <div>
                    <Typography fontSize="lg" fontWeight="lg">
                        $ {product?.variants[0].estimated_total_price?.price_per_unit}
                    </Typography>
                </div>
                <Button
                    variant="solid"
                    size="md"
                    color="primary"
                    aria-label="Explore Bahamas Islands"
                    sx={{ ml: "auto", alignSelf: "center", fontWeight: 600 }}
                    onClick={() => handleDetailedView(product)}
                >
                    View
                </Button>
            </CardContent>
        </Card>
    );
};
export default GiftCard;
