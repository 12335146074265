import { z } from "zod";

const projectSchema = z.object({
    _id: z.number(),
    name: z.string().optional(),
    stage: z.string().optional(),
    detailedStage: z.string().optional(),
    labels: z.string().optional(),
});

export const projectTags = ["Projects"];

export type Project = z.infer<typeof projectSchema>;

export const activityProjectSchema = z.object({
    id: z.number(),
    name: z.string(),
});

const projectFromListSchema = z.object({
    candidateId: z.string(),
    stage: z.string(),
    detailedStage: z.string().optional(),
    labels: z.array(z.string()),
    createdAt: z.string(),
    projectId: z.number(),
    projectName: z.string(),
    dashboardStatus: z.string(),
});

const candidateProjectListSchema = z.array(projectFromListSchema);

const fetchCandidateProjectsResponseSchema = z.object({
    message: z.string(),
    data: candidateProjectListSchema,
});

const reasonSchema = z.object({
    value: z.string(),
    label: z.string(),
});

export type Reason = z.infer<typeof reasonSchema>;

export const easysourcereasons = {
    "1": "We have hired someone using EasySource!",
    "2": "We have hired someone, but not using EasySource",
    "3": "Hiring requirements have changed",
    "4": "Others",
} as const;

export const easygrowthreasons = {
    "1": "Campaign goals have been met",
    "2": "Campaign has been reassigned",
    "3": "Target market criteria has changed",
    "4": "Others",
} as const;

const reasonsSchema = z.union([z.literal("1"), z.literal("2"), z.literal("3"), z.literal("4"), z.literal("")]);

export type ReasonKeys = z.infer<typeof reasonsSchema>;

export const candidateSchema = z.object({
    name: z.string(),
    profileUrl: z.string(),
    profileImage: z.string().optional(),
    _id: z.string(),
});

export type Candidate = z.infer<typeof candidateSchema>;

const fetchCandidatesByProjectResponseData = z.array(candidateSchema);

export type FetchCandidatesByProjectResponseData = z.infer<typeof fetchCandidatesByProjectResponseData>;

export const fetchCandidatesByProjectResponse = z.object({
    message: z.string(),
    data: fetchCandidatesByProjectResponseData,
});

export type FetchCandidatesByProjectResponse = z.infer<typeof fetchCandidatesByProjectResponse>;

export const submitFeedbackPayloadSchema = z.object({
    thoughts: z.string().optional(),
    projectId: z.string(),
    candidateIds: z.string().array(),
    reason: z.string(),
});

export type SubmitFeedbackPayload = z.infer<typeof submitFeedbackPayloadSchema>;

export const submitFeedbackResponse = z.object({
    success: z.boolean(),
    message: z.string(),
});

export type SubmitFeedbackResponse = z.infer<typeof submitFeedbackResponse>;

export const deleteProjectFeedbackFormSchema = z.object({
    reason: reasonsSchema.refine((val) => val !== "", {
        message: "Reason is required",
    }),
    candidates: z.array(candidateSchema).default([]),
    thoughts: z.string().optional(),
});

export type DeleteProjectFeedbackForm = z.infer<typeof deleteProjectFeedbackFormSchema>;

export type DeleteProjectFeedbackModalParams = {
    projectId: string;
    status: "on-hold" | "close";
};

export type GetCandidatesByProjectRequestPayload = Pick<DeleteProjectFeedbackModalParams, "projectId">;

export type ProjectListResponse = z.infer<typeof fetchCandidateProjectsResponseSchema>;

export type ListOfCandidateProjects = z.infer<typeof candidateProjectListSchema>;

export type ProjectFromList = z.infer<typeof projectFromListSchema>;
