import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import { setSuccessNotification } from "../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import { setModal } from "../../store/reducers/modals.slice";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import { ContactListItem, FetchContactListItemsResponse } from "../ContactListItems/contact-list-items.type";
import {
    addContactList,
    addContactListToProject,
    cancelActions,
    createContactList,
    deleteContactList,
    exportContactListCSV,
    fetchContactList,
    setContactList,
    setContactListAfterDeletion,
    setContactListCSVData,
    setUpdatedContactList,
    updateContactListName,
} from "./contact-list.slice";
import {
    AddContactListToProjectPayload,
    CreateContactListPayload,
    CreateContactListResponse,
    DeleteContactListPayload,
    DeleteContactListResponse,
    ExportContactListCSVDataPayload,
    FetchContactListResponse,
    ListItem,
    UpdateContactListNamePayload,
    UpdateContactListResponse,
} from "./contact-list.type";
import handleException from "../../utils/sentry";

function* fetchContactListSaga(action: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const response: FetchContactListResponse = yield call(new API().get, "/contact-list");

        if (!("data" in response)) {
            throw new Error("No data in response");
        }

        const { data } = response;

        const contactList: ListItem[] = data.map(({ _id, name, createdBy, metaData, updatedAt }) => ({
            id: _id,
            name,
            lastUpdated: typeof updatedAt === "string" ? Date.parse(updatedAt) : updatedAt,
            creator: createdBy ? createdBy.toString() : "-",
            listSize: metaData?.size || 0,
        }));

        yield put(setContactList(contactList));
    } catch (error) {
        handleException(error);
        console.error("**error while fetching base templates", { error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* exportContactListCSVSaga(action: ExportContactListCSVDataPayload): SagaIterator {
    try {
        const { id, handleLinkClick } = action.payload;
        yield put(startAction({ action: action.type }));

        const response: FetchContactListItemsResponse = yield call(new API().get, `/contact-list/${id}`);

        if (!("data" in response)) {
            throw new Error("No data in response");
        }

        const { data } = response;

        const contactList: ContactListItem[] = data.map(
            ({ _id, company, email, firstName, lastName, companySize, industry, jobTitle, country }) => ({
                id: _id,
                name: `${firstName} ${lastName}`,
                email,
                company,
                companySize,
                industry,
                jobTitle,
                country,
            })
        );

        yield put(setContactListCSVData(contactList));

        handleLinkClick();
    } catch (error) {
        handleException(error);
        console.error("**error while fetching base templates", { error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* createContactListSaga(action: CreateContactListPayload) {
    try {
        const payload = action.payload;
        yield put(startAction({ action: action.type }));
        const { data }: CreateContactListResponse = yield call(new API().post, `/contact-list/create`, payload);

        if ("_id" in data && "createdBy" in data && "metaData" in data && "updatedAt" in data && "name" in data) {
            yield put(
                addContactList({
                    id: data._id,
                    creator: data.createdBy.toString(),
                    lastUpdated: Date.parse(data.updatedAt),
                    listSize: data.metaData.size,
                    name: data.name,
                })
            );

            yield put(setModal({ key: "showCreateContactListModal", value: false }));

            yield put(setSuccessNotification("List Created Successfully."));
        } else {
            throw new Error("not a valid response");
        }
    } catch (error) {
        handleException(error);
        console.log("**error in createContactList", { error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addContactListToProjectSaga(action: AddContactListToProjectPayload) {
    try {
        const { contactListId, navigate, projectId } = action.payload;
        yield put(startAction({ action: action.type }));
        yield call(new API().post, `/contact-list/add-to-project`, {
            contactListId,
            projectId,
        });
        yield put(setModal({ key: "showAddContactListToProjectModal", value: false }));
        navigate();
    } catch (error) {
        handleException(error);
        console.log("**error in addContactListToProject", { error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* deleteContactListSaga(action: DeleteContactListPayload): SagaIterator {
    try {
        const ids = action.payload;
        yield put(startAction({ action: action.type }));
        const response: DeleteContactListResponse = yield call(new API().post, `/contact-list/delete`, {
            contactListIds: [...ids],
        });

        if ("success" in response && response.success) {
            yield put(setContactListAfterDeletion(ids));
        } else {
            throw new Error("error while deleting contact list");
        }
    } catch (error) {
        handleException(error);
        console.log("**error in deleteContactListSaga", { error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* updateContactListNameSaga(action: UpdateContactListNamePayload): SagaIterator {
    try {
        const { id, name } = action.payload;
        yield put(startAction({ action: action.type }));
        const response: UpdateContactListResponse = yield call(new API().post, `/contact-list/edit`, {
            id,
            name,
        });

        if ("data" in response && "_id" in response.data && "name" in response.data) {
            const { _id, name } = response.data;
            yield put(setUpdatedContactList({ id: _id, name }));
            yield put(setModal({ key: "showEditContactListNameModal", value: false }));
        } else {
            throw new Error("invalid response");
        }
    } catch (error) {
        handleException(error);
        console.log("**error in updateContactListNameSaga", { error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchContactList.type, fetchContactListSaga),
        // @ts-ignore
        yield takeLatest(exportContactListCSV.type, exportContactListCSVSaga),
        // @ts-ignore
        yield takeLatest(createContactList.type, createContactListSaga),
        // @ts-ignore
        yield takeLatest(addContactListToProject.type, addContactListToProjectSaga),
        // @ts-ignore
        yield takeLatest(deleteContactList.type, deleteContactListSaga),
        // @ts-ignore
        yield takeLatest(updateContactListName.type, updateContactListNameSaga),
    ];
    // @ts-ignore
    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
