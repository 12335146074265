import { Option, Select } from "@mui/joy";
import { Box, Button, CircularProgress, Divider, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { MailModal } from "./MailModal";

import { IProject } from "../../pages/project/project.types";
import MultiSelectDropdown from "../../pages/superAdmin/crons/MultiSelectDropdown";
import { useGetCSTemplatesMutation, useSendCSMailMutation } from "../../store/apis/notification/notification.api";
import { useGetProjectsQuery } from "../../store/apis/projects/projects.api";
import { JoyProvider } from "../JoyProvider";
import { setSuccessNotification } from "../Notification/index.reducer";

interface CSNotificationModalProps {
    isOpen: boolean;
    handleClose: () => void;
}

export function CSNotificationModal({ isOpen, handleClose }: CSNotificationModalProps) {
    const { data, isLoading: isLoadingProjectApi } = useGetProjectsQuery(0);
    const dispatch = useDispatch();
    const [type, setType] = useState("");
    const [projectId, setProjectId] = useState([0]);
    const [mailModalOpen, setMailModalOpen] = useState(false);

    const [getCSTemplates, { isLoading }] = useGetCSTemplatesMutation();
    const [sendCSEmail, { isLoading: mailSending }] = useSendCSMailMutation();
    const newData = data?.map((item: IProject) => ({ name: item?.name, _id: +item?._id })) ?? [];

    const handleProceed = async () => {
        await getCSTemplates({
            projectIds: projectId,
            type,
        });
        setMailModalOpen(true);
    };

    const handleEmailSubmit = async () => {
        await sendCSEmail({
            fromEmail: "vineet.dugar@hirequotient.com",
            toEmail: "dugarvineet211@gmail.com",
            emailBody: "EMAIL BODY TESTING",
            subject: "SUBJECT TESTING",
            toUserId: 1492,
            projectId: [13845, 13847],
            orgId: 1162,
            type: "testing",
            scheduledFor: "2024-10-17 13:00:00",
            attachments: ["url1", "url2"],
        });

        dispatch(setSuccessNotification("Email sent successfully"));
        handleClose();
    };

    const handleNotificationModalClose = () => {
        handleClose();
        setProjectId(null);
        setType(null);
    };

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleNotificationModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <div
                    style={{
                        padding: "20px",
                        backgroundColor: "white",
                        margin: "auto",
                        borderRadius: "8px",
                        minWidth: 400,
                    }}
                >
                    <Typography id="modal-title" variant="h6" sx={{ fontWeight: "600", textAlign: "center" }}>
                        CS Notification
                    </Typography>
                    <Divider sx={{ marginTop: "10px" }} />
                    <Stack flexDirection="column" padding={"20px 0 20px 0"} gap={2}>
                        <div style={{ display: "flex" }}>
                            <span style={{ marginRight: "10px", marginTop: "5px" }}>Select Project</span>
                            <MultiSelectDropdown value={projectId} onChange={setProjectId} data={newData ?? []} isLoading={isLoadingProjectApi} />
                        </div>
                        <div style={{ display: "flex" }}>
                            <span style={{ marginRight: "27px", marginTop: "5px" }}>Select Type</span>
                            <JoyProvider>
                                <Select
                                    placeholder="Select type"
                                    sx={{
                                        width: "180px",
                                        backgroundColor: "#f0f4f8",
                                        border: "none",
                                        fontSize: "14px",
                                        height: "fit-content",
                                    }}
                                    onChange={(_e, newValue) => {
                                        setType(newValue.toString() ?? "");
                                    }}
                                >
                                    <Option value={"CANDIDATE_POOL_READY"}>Candidate pool</Option>
                                    <Option value={"TYPE2"}>Type 2</Option>
                                    <Option value={"TYPE3"}>Type 3</Option>
                                </Select>
                            </JoyProvider>
                        </div>
                    </Stack>

                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                height: "fit-content",
                                marginRight: "10px",
                                borderRadius: "5px",
                                backgroundColor: "#1a76d3",
                                "&:hover, &:focus": {
                                    backgroundColor: "#1a76d3",
                                    boxShadow: "none",
                                    outline: "none",
                                },
                                "&:active": {
                                    outline: "none",
                                },
                                minWidth: "80px",
                            }}
                            onClick={handleProceed}
                            disabled={isLoading || !(projectId && type)}
                        >
                            {isLoading ? <CircularProgress size={20} sx={{ color: "white" }} /> : "Proceed"}
                        </Button>
                    </Box>
                </div>
            </Modal>

            {mailModalOpen && (
                <MailModal
                    title="Email"
                    subject="This is the subject"
                    info="This is additional information"
                    onSubmit={handleEmailSubmit}
                    show={mailModalOpen}
                    setShow={setMailModalOpen}
                    loading={mailSending}
                />
            )}
        </>
    );
}
