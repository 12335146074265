import { Box, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import resumeLibraryLogo from "../../assets/img/ai-providers-logo/resume-library-logo.png";
import easysourceLogo from "../../assets/img/email-providers-logo/easysource-logo.jpeg";
import rocketReachLogo from "../../assets/img/email-providers-logo/rocket-reach-logo.png";
import { SidebarForm as ResumeLibrarySidebarSearchForm } from "../../components/BlendedSearch/ResumeLibrary/SidebarForm";
import { SidebarForm as RocketReachSidebarSearchForm } from "../../components/BlendedSearch/RocketReach/SidebarForm";
import { SidebarSearchForm as SalesNavSidebarSearchForm } from "../../components/BlendedSearch/SalesNavSearch/SidebarSearchForm";
import {
    SearchType,
    selectSearchType,
    setCandidatesFetchStatus,
    setSearch,
    setSearchType,
} from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { getProject } from "../allProjects/index.reducer";
import { checkResumeLibraryEnabled, checkRocketReachEnabled, isSuperAdminUser } from "../Signin/Signin.reducer";

const getActiveFormConfig = (searchType: SearchType, disabled?: boolean) => {
    switch (searchType) {
        case "resume-library":
            return {
                title: "Find candidates with EasySource AI instead",
                tooltip:
                    "Keep toggle on to find authentic and up-to-date resumes from premium databases partnering with EasySource. This may lead to fewer initial results",
                form: <ResumeLibrarySidebarSearchForm disabled={disabled} />,
            };
        case "salesnav":
            return {
                title: "Find candidate resumes in US instead",
                tooltip:
                    "Keep toggle off to use EasySource AI to find pubilc candidate profiles from multiple sources and the internet",
                form: <SalesNavSidebarSearchForm disabled={disabled} />,
            };
        case "rocketReach":
            return {
                title: "Find candidates with RocketReach",
                tooltip:
                    "Keep toggle on to find authentic and up-to-date resumes from premium databases partnering with EasySource. This may lead to fewer initial results",
                form: <RocketReachSidebarSearchForm disabled={disabled} />,
            };
        default:
            return {};
    }
};

export function SidebarForm({ disabled }: { disabled?: boolean }) {
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const isResumeLibraryEnabled = useSelector(checkResumeLibraryEnabled) || isSuperAdmin;
    const isRocketReachEnabled = useSelector(checkRocketReachEnabled) || isSuperAdmin;
    const searchType = useSelector(selectSearchType);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const [_openPanel, setOpenPanel] = useState<SearchType>("salesnav");

    useEffect(() => {
        if (projectId) {
            dispatch(
                getProject({
                    projectId,
                    action: getProject.type,
                    doNotFetchCandidates: true,
                    setBlendSearchFilters: true,
                })
            );
        }
    }, []);

    const handleSearchTypeChange = (event: SelectChangeEvent<SearchType>) => {
        const newSearchType = event.target.value as SearchType;
        dispatch(setSearchType(newSearchType));
        dispatch(setSearch({ _id: null, status: null, updatedAt: null }));
        dispatch(setCandidatesFetchStatus("IDLE"));
        setOpenPanel(newSearchType);
    };

    const { form } = getActiveFormConfig(searchType, disabled);

    const dropdownOptions = [
        (isResumeLibraryEnabled || isRocketReachEnabled) && {
            value: "salesnav",
            label: "EasySource AI",
            title: "Best for live/up-to-date profiles with deep search capabilities",
            icon: easysourceLogo,
        },
        isResumeLibraryEnabled && {
            value: "resume-library",
            label: "Resume-Library",
            title: "Fastest-growing database in the US",
            icon: resumeLibraryLogo,
        },
        isRocketReachEnabled && {
            value: "rocketReach",
            label: "RocketReach",
            title: "Suitable for healthcare profiles and niche roles",
            icon: rocketReachLogo,
        },
    ].filter((option): option is { value: string; label: string; title: string; icon } => Boolean(option)); // Type guard added

    return (
        <Box>
            {(isRocketReachEnabled || isResumeLibraryEnabled) && (
                <Select
                    value={searchType}
                    onChange={handleSearchTypeChange}
                    fullWidth
                    sx={{
                        backgroundColor: "white",
                        marginBottom: 1,
                        height: "50px",
                        borderRadius: "10px",
                    }}
                >
                    {dropdownOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{ backgroundColor: "white" }}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <img src={option.icon} alt={option.label} style={{ width: 20, height: 20 }} />
                                <Typography variant="body1">{option.label}</Typography>
                                <Box display={"flex"}>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: "grey",
                                            marginLeft: "10px",
                                            marginTop: "2px",
                                            textWrap: "wrap",
                                            fontSize: "11px"
                                        }}
                                    >
                                        {option.title}
                                    </Typography>
                                </Box>
                            </Stack>
                        </MenuItem>
                    ))}
                </Select>
            )}

            {form}
        </Box>
    );
}
