import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";

const ModalFilter = ({ filter, setFilter, isFilterOpen, setIsFilterOpen }) => {
    const [open, setOpen] = useState(false);
    const initialFilterState = {
        from: "",
        to: "",
        callType: "",
        fromDate: "",
        toDate: "",
        candidateName: "",
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilter((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsFilterOpen(!isFilterOpen);
        handleClose();
        // Here you would typically call a function to apply the filter
    };

    const handleClear = () => {
        setFilter(initialFilterState);
        setIsFilterOpen(!isFilterOpen);
        handleClose();
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 2 }}>
            <Button variant="contained" onClick={handleOpen} startIcon={<FilterListIcon />} sx={{ borderRadius: 1 }}>
                Open Filter
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-filter-title">
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        maxHeight: "90vh",
                        overflowY: "auto",
                    }}
                >
                    <h2 id="modal-filter-title">Call Filter</h2>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="from"
                                    label="From"
                                    variant="outlined"
                                    value={filter.from}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="to"
                                    label="To"
                                    variant="outlined"
                                    value={filter.to}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Call Type</InputLabel>
                                    <Select
                                        name="callType"
                                        value={filter.callType}
                                        onChange={handleInputChange}
                                        label="Call Type"
                                    >
                                        <MenuItem value="BOTH">Both</MenuItem>
                                        <MenuItem value="INCOMING">Incoming</MenuItem>
                                        <MenuItem value="OUTGOING">Outgoing</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="fromDate"
                                    label="From Date"
                                    type="date"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={filter.fromDate}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="toDate"
                                    label="To Date"
                                    type="date"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={filter.toDate}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="candidateName"
                                    label="Candidate Name"
                                    variant="outlined"
                                    value={filter.candidateName}
                                    onChange={handleInputChange}
                                />
                            </Grid> */}
                            <Grid item xs={6}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Apply Filter
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    onClick={handleClear}
                                >
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
};

export default ModalFilter;
