import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import { get } from "lodash";

import { CLEVER_TAP_EXCLUDED_EMAIL_IDS } from "../constant/Constant";

function handleCleverTap(eventType: any, eventData?: any) {
    const auth = firebase.auth();
    const user = auth.currentUser;
    const email = get(user, "multiFactor.user.email") || "";

    if (
        CLEVER_TAP_EXCLUDED_EMAIL_IDS.includes(email) ||
        CLEVER_TAP_EXCLUDED_EMAIL_IDS.some((id) => email.includes(id))
    ) {
        return;
    }

    // @ts-ignore
    const { clevertap } = window;
    if (clevertap) {
        const data = eventData || {};
        clevertap.event.push(eventType, data);
    }
}

export default handleCleverTap;
