import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import style from "../../project/components/projectInfo/projectInfo.module.scss"

import { selectProjectFilters } from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { IProjectExperience } from "@/store/reducers/project/project.types";
import { getExperience } from "@/utils/helper";

function ProjectInformation() {
    const { t } = useTranslation();
    const projectData = useSelector(selectProjectFilters);
    const {
        jobTitle,
        experience,
        location,
        skills,
        education,
        industry,
    } = projectData || {};

    return (
        <div className={style["sp__tooltip"]}>
            <Stack spacing={1.5}>
                <Typography variant="body1" fontWeight={500}>
                    {t("project")} Description
                </Typography>
                <div className={style["sp__tooltipWrapper"]}>

                    {jobTitle && jobTitle.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Job Title</h2>
                            {jobTitle.length > 1 ? (
                                <ul>
                                    {jobTitle.map((title: string) => (
                                        <li>{title}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{jobTitle[0]}</p>
                            )}
                        </div>
                    ) : null}
                    {experience && experience.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Experience</h2>
                            {experience.length > 1 ? (
                                <ul>
                                    {experience.map((ex) => (
                                        <li>{getExperience(ex as IProjectExperience)}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{getExperience(experience[0] as IProjectExperience)}</p>
                            )}
                        </div>
                    ) : null}
                    {location && location.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Geography</h2>
                            {location.length > 1 ? (
                                <ul>
                                    {location.map((loc: { label: string; value: string }) => (
                                        <li>{loc.label}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{location[0].label}</p>
                            )}
                        </div>
                    ) : null}
                    {education && education.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Education</h2>
                            {education.length > 1 ? (
                                <ul>
                                    {education.map((edu: string) => (
                                        <li>{edu}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{education[0]}</p>
                            )}
                        </div>
                    ) : null}
                    {industry && industry.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Industry</h2>
                            {industry.length > 1 ? (
                                <ul>
                                    {industry.map((industry: { label: string; value: string }) => {
                                        return industry?.label ? <li>{industry.label}</li> : null;
                                    })}
                                </ul>
                            ) : (
                                <p>{industry[0].label}</p>
                            )}
                        </div>
                    ) : null}
                    {skills && skills.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Keywords</h2>
                            {skills.length > 1 ? (
                                <ul>
                                    {skills.map((skill: string) => (
                                        <li>{skill}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{skills[0]}</p>
                            )}
                        </div>
                    ) : null}
                </div>
            </Stack>
        </div>
    );
}

export default ProjectInformation;
