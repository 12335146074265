import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "..";

type State = {
    showEditExclusionListMenu: boolean;
    showEditExclusionListModal: boolean;
    showLinkedInAuthorizeModal: boolean;
    showDeleteProjectFeedbackModal: boolean;
    showValuePropositionModal: boolean;
    showAddExclusionListModal: boolean;
    showDeleteProjectModal: boolean;
    showImportContactListModal: boolean;
    showCreateContactListModal: boolean;
    showAddContactListToProjectModal: boolean;
    showDeleteContactListModal: boolean;
    showEditContactListNameModal: boolean;
    showAllContactsCSVModal: boolean;
    showCreateContactListModalFromAllContacts: boolean;
    showDeleteExclusionListModal: boolean;
    showSwitchToOrgModal: boolean;
    showEditAutoSearch: boolean;
    showCreateDuplicateProject: boolean;
    showUpcomingEventsModal: boolean;
    showEditCSNotificationSettingModal: boolean;
};

const initialState: State = {
    showEditExclusionListMenu: false,
    showEditExclusionListModal: false,
    showLinkedInAuthorizeModal: false,
    showDeleteProjectFeedbackModal: false,
    showValuePropositionModal: false,
    showEditAutoSearch: false,
    showAllContactsCSVModal: false,
    showAddExclusionListModal: false,
    showDeleteProjectModal: false,
    showCreateContactListModal: false,
    showImportContactListModal: false,
    showAddContactListToProjectModal: false,
    showDeleteContactListModal: false,
    showEditContactListNameModal: false,
    showCreateContactListModalFromAllContacts: false,
    showDeleteExclusionListModal: false,
    showSwitchToOrgModal: false,
    showEditCSNotificationSettingModal: false,
    showCreateDuplicateProject: false,
    showUpcomingEventsModal: false,
};

type SetModalPayload = {
    key: keyof typeof initialState;
    value: boolean;
};

export const modalSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        setModal: (state, action: PayloadAction<SetModalPayload>) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
    },
});

export default modalSlice.reducer;

export const { setModal } = modalSlice.actions;

export const selectLinkedAuthorizeModal = (state: RootState) => state.modals.showLinkedInAuthorizeModal;

export const selectValuePropositionModal = (state: RootState) => state.modals.showValuePropositionModal;

export const selectAddExclusionListModal = (state: RootState) => state.modals.showAddExclusionListModal;

export const selectDeleteProjectModal = (state: RootState) => state.modals.showDeleteProjectModal;

export const selectCreateContactListModal = (state: RootState) => state.modals.showCreateContactListModal;

export const selectAddContactListToProjectModal = (state: RootState) => state.modals.showAddContactListToProjectModal;

export const selectDeleteContactListModal = (state: RootState) => state.modals.showDeleteContactListModal;

export const selectEditContactListNameModal = (state: RootState) => state.modals.showEditContactListNameModal;

export const selectAllContactsCSVModal = (state: RootState) => state.modals.showAllContactsCSVModal;

export const selectCreateContactListFromAllContacts = (state: RootState) =>
    state.modals.showCreateContactListModalFromAllContacts;

export const selectShowDeleteExclusionListModal = (state: RootState) => state.modals.showDeleteExclusionListModal;

export const selectSwitchToOrgModal = (state: RootState) => state.modals.showSwitchToOrgModal;

export const selectEditCSNotificationSettingModal = (state: RootState) =>
    state.modals.showEditCSNotificationSettingModal;

export const selectShowEditAutoSearch = (state: RootState) => state.modals.showEditAutoSearch;

export const selectShowCreateDuplicateProject = (state: RootState) => state.modals.showCreateDuplicateProject;

export const selectUpcomingEventsModal = (state: RootState) => state.modals.showUpcomingEventsModal;

export const selectEditExclusionListMenu = (state: RootState) => state.modals.showEditExclusionListMenu;
