import { useRef } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
    selectContactListItemsNumberOfSelectedRows,
    setContactListItemsSearchQuery,
} from "../contact-list-items.slice";

const CustomOutlineInput = styled((props: OutlinedInputProps) => <OutlinedInput {...props} />)(() => ({
    borderRadius: "1rem",
    padding: 0,
    minWidth: "400px",
    "& .MuiOutlinedInput-input": {
        padding: "0.5rem 0.7rem",
        fontSize: "12px",
        borderRadius: "0.7rem",
    },
}));

function Search() {
    const dispatch = useDispatch();
    const inputRef = useRef<null | HTMLInputElement>();

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.target.value;
        dispatch(setContactListItemsSearchQuery(value));
    };

    const handleClearText = () => {
        if (inputRef?.current) {
            inputRef.current.value = "";
        }
    };
    return (
        <CustomOutlineInput
            placeholder="Please enter text"
            inputRef={inputRef}
            type="text"
            onChange={debounce(handleChange, 500)}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton type="submit" color="primary" onClick={handleClearText}>
                        <CancelRoundedIcon fontSize="small" />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
}

export default function ContactListItemsToolbar() {
    const numSelected = useSelector(selectContactListItemsNumberOfSelectedRows);

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tablseTitle" component="div">
                    Contact List Items
                </Typography>
            )}
            <Search />
            {/* {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteRoundedIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListRoundedIcon />
                    </IconButton>
                </Tooltip>
            )} */}
        </Toolbar>
    );
}
