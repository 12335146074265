import { Stack, StackProps, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TypeAnimation } from "react-type-animation";

const CustomStack = styled((props: StackProps) => <Stack {...props} />)(() => ({
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
}));


export function TypingLoader({ start }: { start?: 1 | 2 | 3 | 4 }) {
    const { t } = useTranslation();
    const messages = [
        "Analyzing your search query…",
        `Understanding your ${t("commonSingular")} persona…`,
        "Building initial AI-powered searches across the web…",
        "Still searching…",
        `Preparing your initial ${t("commonSingular")} pool...`,
    ];

    if (start) {
        messages.splice(start, messages.length);
    }

    const sequence = messages.flatMap((message) => [message, 2000]);

    return (
        <CustomStack>
            <TypeAnimation
                sequence={sequence}
                speed={50}
                style={{
                    fontWeight: 500,
                    color: "#479bd2",
                }}
            />
        </CustomStack>
    );
}
