import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/store";

export type EXCLUSION_LIST_TYPE = "LOCAL" | "GLOBAL" | "ROOT_GLOBAL";

export type EXCLUSION_FIELD = "EMAIL" | "COMPANY";

export interface FetchExclusionListForCSVResponse {
    message: string;
    data: FetchExclusionListForCSVData;
}

export type EditExclusionListResponse = {
    message: string;
    success: boolean;
};

export interface FetchExclusionListForCSVData {
    _id: string;
    name: string;
    emails?: string[];
    companies?: string[];
    exclusionField: "EMAIL" | "COMPANY";
}

export type AddExclusionListPayload = {
    name: string;
    csvFile: File;
    onSuccess: () => void;
    exclusionListType: EXCLUSION_LIST_TYPE;
    exclusionField: EXCLUSION_FIELD;
};

export type EditExclusionListPayload = PayloadAction<
    Omit<AddExclusionListPayload, "csvFile"> & {
        id: string;
        emailsStringArray: string[];
        action: "OVERWRITE" | "ADD";
        csvFile: File | null;
    }
>;

export type ExclusionListType = {
    _id: string;
    name: string;
    type: EXCLUSION_LIST_TYPE;
};

export type EditExclusionListStatusPayload = PayloadAction<{
    id: string;
    type: "GLOBAL" | "LOCAL";
}>;

export type DeleteExclusionListPayload = PayloadAction<{
    ids: string[];
    projectId: string;
}>;

export type FetchExclusionListForCSVPayload = PayloadAction<{
    id: string;
    handleLinkClick: () => void;
}>;

type IRootState = {
    exclusionLists: ExclusionListType[];
    exclusionListCSVData: any;
    projectExclusionLists: ExclusionListType[];
};

const initialState: IRootState = {
    exclusionLists: [],
    exclusionListCSVData: [],
    projectExclusionLists: [],
};

const reducers = {
    addExclusionList(state: typeof initialState, action: PayloadAction<AddExclusionListPayload>) {},
    getExclusionLists(state: typeof initialState) {},
    setExclusionLists(state: typeof initialState, action: PayloadAction<ExclusionListType[]>) {
        state.exclusionLists = action.payload;
    },
    submitDeleteExclusionList(state: typeof initialState, action: DeleteExclusionListPayload) {},
    fetchExclusionListForCSV(state: typeof initialState, action: FetchExclusionListForCSVPayload) {},
    editExclusionListStatus(state: typeof initialState, action: EditExclusionListStatusPayload) {},
    editExclusionList(state: typeof initialState, action: EditExclusionListPayload) {},
    setExclusionListCSVData(state: typeof initialState, action: PayloadAction<any>) {
        state.exclusionListCSVData = action.payload;
    },
    getProjectExclusionLists(state: typeof initialState, action: PayloadAction<number>) {},
    resetProjectExclusionLists(state: typeof initialState) {
        state.projectExclusionLists = [];
    },
    setProjectExclusionLists(state: typeof initialState, action: PayloadAction<ExclusionListType[]>) {
        state.projectExclusionLists = action.payload;
    },
    leavePage(state: typeof initialState) {},
};

export const page = createSlice({
    name: "exclusionList",
    initialState,
    reducers,
});

export default page.reducer;

export const {
    editExclusionList,
    addExclusionList,
    getExclusionLists,
    setExclusionLists,
    setProjectExclusionLists,
    resetProjectExclusionLists,
    leavePage,
    submitDeleteExclusionList,
    setExclusionListCSVData,
    fetchExclusionListForCSV,
    editExclusionListStatus,
    getProjectExclusionLists,
} = page.actions;

export const selectExclusionLists = (state: RootState) => state.exclusionList.exclusionLists;
export const selectExclusionListCSVData = (state: RootState) => state.exclusionList.exclusionListCSVData;
export const selectProjectExclusionLists = (state: RootState) => state.exclusionList.projectExclusionLists;

export const selectExclusionById = createSelector(
    [(state: RootState) => state.exclusionList.exclusionLists, (_: RootState, id: string | undefined) => id],
    (exclusionLists, id) => {
        return exclusionLists.find((i) => i._id === id);
    }
);
