import { PayloadAction } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import API from "../../utils/API";
import appendToOutReach from "../../utils/appendToOutReach";
import handleCleverTap from "../../utils/clevertap";
import { removeHtmlTags } from "../../utils/helper";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { IActionPayload, startAction, stopAction } from "../reducers/loaders.reducer";
import { WorkflowAttachFile } from "../reducers/workflow-upload.slice";

import { setNudges } from "@/store/reducers/nudges/Nudges.reducer";
import { getProjectWorkflows, setSelectAllCandidates, setWorkflow } from "@/store/reducers/project/project.reducer";
import { IProjectStage } from "@/store/reducers/project/project.types";
import { submitSwitchesStatus } from "@/store/reducers/trigger-workflow/customTemplates.slice";
import {
    addToWorkflowList,
    changeModalState,
    createAndTriggerWorkflow,
    createNewWorkflow,
    deleteWorkflow,
    fetchProjects,
    getWorkflowList,
    leaveWorkflow,
    postTestMail,
    resumeProjectWorkflow,
    resumeLinkedinWorkflow,
    setSelectedWorkflow,
    setSingleWorkflowDataProperty,
    setWorkflowList,
    setWorkflowProjects,
    getWorkflowStats,
    setWorkflowStats,
    stopProjectWorkflow,
    validateWorkflowName,
} from "@/store/reducers/workflow/workflow.reducer";
import {
    CreateAndTriggerWorkflowPayload,
    CreateNewWorkflowResponse,
    FetchProjectsResponse,
    IPostTestMailPayload,
    IPostTestMailRequestPayload,
    IResumeLinkedinWorkflowPayload,
    IResumeProjectWorkflowPayload,
    IStopProjectWorkflowPayload,
    Option,
    Step,
} from "@/store/reducers/workflow/workflow.types";

function* getWorkflowStatsSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getWorkflowStats.type }));
        const response = yield call(new API().post, "/workflow/detailed-stats", payload);
        if (!response?.data) return;

        yield put(setWorkflowStats(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getWorkflowStats.type }));
    }
}

function* getWorkflowListSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(new API().get, "/workflow/template/list");
        if (!response?.data) return;

        yield put(setWorkflowList(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* validateWorkflowNameSaga(): SagaIterator {
    const state = yield select();
    const errors = get(state, "workflow.errors");

    if (!errors["workflowName"]) {
        yield put(changeModalState("LINEAR"));
    }
}

type RemoveDelayArgs = {
    steps: any;
    isFirstDelayEnabled: boolean;
    isSecondDelayEnabled: boolean;
    isThirdDelayEnabled: boolean;
};

function removeDelays({ steps, isFirstDelayEnabled, isSecondDelayEnabled, isThirdDelayEnabled }: RemoveDelayArgs) {
    let delayCount = 0;
    return steps.filter((step: any) => {
        if (step?.eventName && step.eventName === "Delay") {
            delayCount++;
            if (
                (isFirstDelayEnabled === false && delayCount === 1) ||
                (isSecondDelayEnabled === false && delayCount === 2) ||
                (isThirdDelayEnabled === false && delayCount === 3)
            ) {
                return false;
            }
        }
        return true;
    });
}

/**
 * Filters the list of steps to find the first occurrence of an step with eventName === 'Email',
 * and then removes all subsequent steps that also have eventName === 'Email'.
 */
function filterStepsWithoutFollowupEmail(steps: any) {
    let isEmailPresent = false;
    const stepsWithoutFollowupEmail = [];

    for (const step of steps) {
        const isEventNamePresent = step?.eventName && step.eventName === "Email";
        if (isEventNamePresent && isEmailPresent) {
            continue;
        }

        if (isEventNamePresent && !isEmailPresent) {
            isEmailPresent = true;
        }

        stepsWithoutFollowupEmail.push(step);
    }

    return stepsWithoutFollowupEmail;
}

function filterStepsWithoutEmail(steps: any) {
    return steps.filter((step: any) => step?.eventName && step.eventName !== "Email");
}
function appendInMailInConnectionReq({
    inMailPayload,
    steps,
}: {
    inMailPayload: { subject: string; body: string; enableInMail: boolean };
    steps: any;
}) {
    return steps.map((step: any) => {
        if (step.eventName === "connection-request") {
            return {
                ...step,
                eventBody: {
                    ...step.eventBody,
                    inmailSubject: inMailPayload.subject,
                    inmailBody: inMailPayload.body,
                    inmailStatus: inMailPayload.enableInMail,
                },
            };
        }

        return step;
    });
}

function* createNewWorkflowSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const { onSuccess, action, ...rest } = payload;
        const state = yield select();

        // const state = yield select();
        // const comapnyName = get(state, "workflow.workflowData.comapnyName");
        // const companyLink = get(state, "workflow.workflowData.companyLink");
        // const companyVerified = get(state, "user.companyVerified");
        // if (!companyVerified) {
        //     rest.comapnyName = comapnyName;
        //     rest.companyLink = companyLink;
        //
        const enableConnectionReq = get(state, "template.enableConnectionReq");
        const enableFollowUpEmail = get(state, "template.enableFollowUpEmail");
        const enableInitialEmail = get(state, "template.enableEmail");
        const enableFirstDelay = get(state, "template.enableFirstDelay");
        const enableSecondDelay = get(state, "template.enableSecondDelay");
        const enableThirdDelay = get(state, "template.enableThirdDelay");
        const enableInMail = get(state, "template.enableInMail");
        const enableSMS = get(state, "template.enableSMS");
        const isSMSEnabledForUser = get(state, "signin.user.features.smsEnabled");
        const inMailParams = get(state, "steps.connectionRequest.inMail.standard");

        rest.steps = removeDelays({
            steps: rest?.steps,
            isFirstDelayEnabled: enableFirstDelay,
            isSecondDelayEnabled: enableSecondDelay && isSMSEnabledForUser,
            isThirdDelayEnabled: enableThirdDelay,
        });

        // add in-mail
        if (enableInMail) {
            rest.steps = appendInMailInConnectionReq({
                steps: rest.steps,
                inMailPayload: { ...inMailParams, enableInMail },
            });
        } else {
            rest.steps = appendInMailInConnectionReq({
                steps: rest.steps,
                inMailPayload: { enableInMail: false, body: "", subject: "" },
            });
        }

        // add conn-req
        if (rest?.steps?.length) {
            rest.steps = rest.steps.map((step: any) => {
                if (step?.eventName && step?.eventName === "connection-request") {
                    return {
                        ...step,
                        eventBody: {
                            ...step.eventBody,
                            connReqStatus: enableConnectionReq,
                            body: enableConnectionReq ? step.eventBody.body : "",
                        },
                    };
                }

                return step;
            });
        }

        // if both in-mail and conn-req is false then remove conn-req step
        if (rest?.steps?.length && enableInMail === false && enableConnectionReq === false) {
            rest.steps = rest.steps.filter(
                (step: any) => !(step?.eventName && step?.eventName === "connection-request")
            );
        }

        // remove sms if disable
        if (rest?.steps?.length && (enableSMS === false || !isSMSEnabledForUser)) {
            rest.steps = rest.steps.filter((step: any) => !(step?.eventName && step?.eventName === "sms"));
        }

        if (rest?.steps?.length && enableFollowUpEmail === false) {
            rest.steps = filterStepsWithoutFollowupEmail(rest.steps);
        }

        if (rest?.steps?.length && enableInitialEmail === false) {
            rest.steps = filterStepsWithoutEmail(rest.steps);
        }

        yield put(startAction({ action }));

        const response: CreateNewWorkflowResponse = yield call(new API().post, "/workflow/template/create/", rest);

        if (!response) {
            yield put(setErrorNotification("Something went wrong..."));
            return;
        }

        yield put(setSelectedWorkflow(response.data));
        yield put(
            setSingleWorkflowDataProperty({
                key: "name",
                value: response.data.name,
            })
        );

        yield put(addToWorkflowList(response.data));

        if (onSuccess) {
            onSuccess(response);
        }

        handleCleverTap("Created Workflow Success", {
            rest,
        });

        yield put(changeModalState("LINEAR"));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteWorkflowSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(new API().delete, "/workflow/template/delete/" + payload);
        if (!response) return;

        yield put(getWorkflowList());
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* fetchProjectsSaga({ payload }: { payload: { action: string } }): SagaIterator {
    try {
        const { action } = payload;
        yield put(startAction({ action }));
        const response: FetchProjectsResponse = yield call(new API().get, "/v2/project/project-list");

        const data: Option[] = response.data.map(({ _id, name }) => {
            return { value: String(_id), label: name };
        });

        yield put(setWorkflowProjects(data));
    } catch (err) {
        console.error("**allWorkflowsProjectSagaError=", err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function checkOutreachElements(outReachElements: Omit<Step, "_id">[]): {
    isValid: boolean;
    message?: string;
} {
    if (!outReachElements.length) {
        return {
            isValid: false,
            message: "Kindly enable a elements to start the outreach",
        };
    }

    if (
        outReachElements.some((item) => {
            const body = removeHtmlTags(item.eventBody?.body) === "";
            const subject = item.eventBody?.subject === "";

            // check connection-request
            if (
                item.eventName === "connection-request" &&
                item?.eventBody.body === "" &&
                !item?.eventBody?.inmailStatus
            ) {
                console.log("this is connection request");
                return true;
            }

            // check in-mail
            if (
                item.eventName === "connection-request" &&
                !item?.eventBody?.connReqStatus &&
                item?.eventBody?.inmailStatus
            ) {
                const inmailSubject = item?.eventBody?.inmailSubject;
                const inmailBody = item?.eventBody?.inmailBody;
                return !inmailSubject || !inmailBody;
            }

            return subject || body;
        })
    ) {
        return {
            isValid: false,
            message: "Outreach element subject or body is invalid!",
        };
    }

    // in filterComponentList check if any of itemType other than delay should be present
    if (
        !outReachElements.some(
            (item) =>
                item.eventName === "Email" ||
                item.eventName === "in-mail" ||
                item.eventName === "connection-request" ||
                item.eventName === "linked-in-msg" ||
                item.eventName === "sms"
        )
    ) {
        return {
            isValid: false,
            message: "Workflow cannot be triggered by time delay alone",
        };
    }

    return { isValid: true };
}

function* createAndTriggerWorkflowSaga({
    payload: {
        createWorkflowPayload,
        addWorkflowPayload,
        action,
        isAllCandidatesSelected,
        filters,
        searchQuery,
        showByPersonalEmails,
    },
}: {
    payload: CreateAndTriggerWorkflowPayload;
}): SagaIterator {
    try {
        const { onSuccess, ...rest } = addWorkflowPayload;
        const state = yield select();
        const {
            workflowData: { comapnyName, companyLink },
            workflowMode,
        } = state.workflow;
        const companyVerified = get(state, "user.companyVerified");
        const isPersonalized = workflowMode === "PERSONALIZED_WORKFLOW";

        // outreach components
        const enableInitialEmail = get(state, "template.enableEmail");
        const enableConnectionReq = get(state, "template.enableConnectionReq");
        const enableInMail = get(state, "template.enableInMail");
        const enableFollowUpEmail = get(state, "template.enableFollowUpEmail");
        const enableSMS = get(state, "template.enableSMS");
        const enableReminderMsg = get(state, "template.enableReminderMsg");
        const enableInMailReminderMsg = get(state, "template.enableInMailReminderMsg");

        // delay
        const enableFirstDelay = get(state, "template.enableFirstDelay");
        const enableSecondDelay = get(state, "template.enableSecondDelay");
        const enableThirdDelay = get(state, "template.enableThirdDelay");
        const isSMSEnabledForUser = get(state, "signin.user.features.smsEnabled");

        const newCookieValue = get(state, "cookiesInput.values");

        const { characterCount, toneOfVoice } = state.personalizedWorkflow;
        let outreachIntent = get(state, "personalizedWorkflow.outreachIntent");
        const customTemplates = get(state, "customTemplates");

        const reminderMsg = customTemplates?.reminderMsg?.body;
        const inMailReminderMsg = customTemplates?.inMailReminderMsg?.body;
        outreachIntent = appendToOutReach({
            email: customTemplates?.email,
            followUp: customTemplates?.followUp?.body,
            inMail: customTemplates?.inMail,
            linkedInReq: customTemplates?.linkedInReq?.body,
            outReachIntent: outreachIntent,
            sms: isSMSEnabledForUser ? customTemplates?.sms?.body : null,
        });

        if (!companyVerified) {
            createWorkflowPayload.comapnyName = comapnyName;
            createWorkflowPayload.companyLink = companyLink;
        }

        const inMailParams = get(state, "steps.connectionRequest.inMail.standard");

        createWorkflowPayload.steps = removeDelays({
            steps: createWorkflowPayload?.steps,
            isFirstDelayEnabled: enableFirstDelay,
            isSecondDelayEnabled: enableSecondDelay && isSMSEnabledForUser,
            isThirdDelayEnabled: enableThirdDelay,
        });

        // add in-mail
        if (createWorkflowPayload?.steps?.length && enableInMail) {
            createWorkflowPayload.steps = appendInMailInConnectionReq({
                steps: createWorkflowPayload.steps,
                inMailPayload: { ...inMailParams, enableInMail },
            });
        } else {
            createWorkflowPayload.steps = appendInMailInConnectionReq({
                steps: createWorkflowPayload.steps,
                inMailPayload: { enableInMail: false, body: "", subject: "" },
            });
        }

        // remove sms if disable
        if (createWorkflowPayload?.steps?.length && (enableSMS === false || !isSMSEnabledForUser)) {
            createWorkflowPayload.steps = createWorkflowPayload.steps.filter(
                (step: any) => !(step?.eventName && step?.eventName === "sms")
            );
        }

        // add conn-req
        if (createWorkflowPayload?.steps?.length) {
            createWorkflowPayload.steps = createWorkflowPayload.steps.map((step: any) => {
                if (step?.eventName && step?.eventName === "connection-request") {
                    return {
                        ...step,
                        eventBody: {
                            ...step.eventBody,
                            connReqStatus: enableConnectionReq,
                            body: enableConnectionReq ? step.eventBody.body : "",
                        },
                    };
                }

                return step;
            });
        }

        // if both in-mail and conn-req is false then remove conn-req step
        if (createWorkflowPayload?.steps?.length && enableInMail === false && enableConnectionReq === false) {
            createWorkflowPayload.steps = createWorkflowPayload.steps.filter(
                (step: any) => !(step?.eventName && step?.eventName === "connection-request")
            );
        }

        if (createWorkflowPayload?.steps?.length && enableFollowUpEmail === false) {
            createWorkflowPayload.steps = filterStepsWithoutFollowupEmail(createWorkflowPayload.steps);
        }

        if (createWorkflowPayload?.steps?.length && enableInitialEmail === false) {
            createWorkflowPayload.steps = filterStepsWithoutEmail(createWorkflowPayload.steps);
        }

        // validate all the outreach components:IF AT ANY POINT IF OUTREACH BUTTON DOESN'T WORK THEN REMOVE line 552-559

        if (!isPersonalized) {
            const validityStatus = checkOutreachElements(createWorkflowPayload.steps);

            if (!validityStatus.isValid) {
                yield put(setErrorNotification(validityStatus.message || ""));
                return;
            }
        }

        // saving switches-state call
        yield put(submitSwitchesStatus({ projectId: rest?.project }));

        yield put(startAction({ action }));
        const createWorkflowApiResponse: CreateNewWorkflowResponse = yield call(
            new API().post,
            "/workflow/template/create/",
            createWorkflowPayload
        );

        if (isEmpty(createWorkflowApiResponse.data)) return;

        const { _id: workflowTemplateId } = createWorkflowApiResponse.data;
        const files: WorkflowAttachFile[] = get(state, "workflowUpload.files");
        const senderEmail = get(state, "workflow.workflowData.senderEmail.value");
        const senderName = get(state, "workflow.workflowData.senderName.value");

        // filters
        let filterByPayload: string[] = [];
        if (filters) {
            const appliedFilters = Object.keys(filters)
                .filter((key) => filters[key as IProjectStage])
                .concat(showByPersonalEmails ? ["personalEmail"] : []);
            filterByPayload = [...appliedFilters];
        }

        const triggerPayload: any = {
            ...rest,
            workflowTemplateId,
            hyperPersonalized: isPersonalized ? true : false,
            toneOfVoice: isPersonalized ? toneOfVoice : "",
            wordCount: isPersonalized ? characterCount : "",
            outreachIntent: isPersonalized ? outreachIntent : "",
            attachment: files.map(({ file }) => file),
            email: senderEmail || "",
            nameFrom: senderName || "",
            cookie: newCookieValue["0"].cookie,
            filterBy: filterByPayload,
            search: searchQuery,
        };

        if (enableReminderMsg && isPersonalized && reminderMsg) {
            triggerPayload.reminderMsg = reminderMsg;
        }

        if (enableInMailReminderMsg && isPersonalized && inMailReminderMsg) {
            triggerPayload.inMailReminderMsg = inMailReminderMsg;
        }

        if (isAllCandidatesSelected) {
            triggerPayload.selectAll = true;
            triggerPayload.candidate = [];
        }

        const triggerWorkflowApiResponse = yield call(new API().post, "/workflow/trigger", triggerPayload);

        const { installExtension, showEmail } = state.nudges;

        const updatedNudges = {
            installExtension,
            addToWorkflow: true,
            showEmail,
        };

        yield put(setNudges(updatedNudges));

        if (onSuccess) {
            const {
                workflowModel: { _id: workflowId },
                candidateCreditCount: workflowCredits,
            } = triggerWorkflowApiResponse.data;
            onSuccess({ workflowId, workflowCredits });
        }

        yield put(setSelectAllCandidates(false));
        yield put(changeModalState("FINISHED"));
        handleCleverTap("OutReach Started Successfully", {
            triggerPayload,
        });
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action }));
    }
}

function* resumeProjectWorkflowSaga({
    payload,
}: {
    payload: IResumeProjectWorkflowPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const { workflowLogId, scheduledFor } = payload;
        const response = yield call(new API().post, `/restart/restart-workflow`, {
            workflowLogId,
            scheduledFor,
        });
        if (!response) return;

        // yield put(setWorkflow({ workflowId: payload.workflowId, status: "RESUME" }));
        yield put(
            getProjectWorkflows({
                action: getProjectWorkflows.type,
                projectId: payload.projectId,
                paginated: {
                    start: payload.pagination.start,
                    limit: payload.pagination.limit,
                },
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err) {
        console.error("**resumeProjectWorkflowSagaError=", err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* resumeLinkedinWorkflowSaga({
    payload,
}: {
    payload: IResumeLinkedinWorkflowPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const { workflowId, scheduledFor } = payload;
        const response = yield call(new API().post, `/reschedule/reschedule-queue`, {
            workflowId,
            scheduledFor,
        });
        if (!response) return;

        yield put(
            getProjectWorkflows({
                action: getProjectWorkflows.type,
                projectId: payload.projectId,
                paginated: {
                    start: payload.pagination.start,
                    limit: payload.pagination.limit,
                },
            })
        );
        yield put(setSuccessNotification(response.message));
    } catch (err) {
        console.error("**resumeLinkedinWorkflowSagaError=", err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postTestMailSaga({ payload }: { payload: IPostTestMailPayload & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const testMailPayload: IPostTestMailRequestPayload = {
            subject: payload.subject,
            body: payload.body,
            projectId: payload.projectId,
            candidateId: payload.candidateId,
        };
        if (payload.email) {
            testMailPayload.email = payload.email;
        }

        const response = yield call(new API().post, "/send-email/test", testMailPayload);
        if (!response) return;

        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err) {
        console.error("**postTestMailSagaError=", err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* stopProjectWorkflowSaga(action: PayloadAction<IStopProjectWorkflowPayload & IActionPayload>): SagaIterator {
    try {
        const { payload } = action;

        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, `/workflow/trigger-stopped/${payload.workflowId}`);
        if (!response) return;

        yield put(setWorkflow({ workflowId: payload.workflowId, status: "STOPPED" }));
        yield put(setSuccessNotification(response.message));
        yield put(
            getProjectWorkflows({
                action: getProjectWorkflows.type,
                projectId: payload.projectId,
                paginated: {
                    start: payload.pagination.start,
                    limit: payload.pagination.limit,
                },
            })
        );
    } catch (err) {
        console.error("**stopProjectWorkflowSagaError=", err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(createNewWorkflow.type, createNewWorkflowSaga),
        // @ts-ignore
        yield takeLatest(getWorkflowList.type, getWorkflowListSaga),
        // @ts-ignore
        yield takeLatest(getWorkflowStats.type, getWorkflowStatsSaga),
        // @ts-ignore
        yield takeLatest(deleteWorkflow.type, deleteWorkflowSaga),
        // @ts-ignore
        yield takeLatest(fetchProjects.type, fetchProjectsSaga),
        // @ts-ignore
        yield takeLatest(validateWorkflowName.type, validateWorkflowNameSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            createAndTriggerWorkflow.type,
            createAndTriggerWorkflowSaga
        ),
        // @ts-ignore
        yield takeLatest(resumeProjectWorkflow.type, resumeProjectWorkflowSaga),
        // @ts-ignore
        yield takeLatest(resumeLinkedinWorkflow.type, resumeLinkedinWorkflowSaga),
        // @ts-ignore
        yield takeLatest(stopProjectWorkflow.type, stopProjectWorkflowSaga),
        // @ts-ignore
        yield takeLatest(postTestMail.type, postTestMailSaga),
    ];
    // @ts-ignore
    yield takeLatest(leaveWorkflow.type, CancelSagas, tasks);
}
