import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
    selectEnableConnectionReq,
    selectEnableInMailSwitch,
    setEditMsgPopup,
    setEnableInMail,
} from "../../../template/index.reducer";

import style from "./reminder.module.scss";

import "react-quill/dist/quill.snow.css";

// import EditReminderMessage from "../../../triggerWorkflow/molecules/EditReminderMessage";
import SensorOccupiedRoundedIcon from "@mui/icons-material/SensorOccupiedRounded";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Collapse,
    Button as MUIButton,
    Skeleton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/Button/Button";
import CustomDropdown, { CustomDropdownOptionsType } from "../../../../components/CustomDropdown/CustomDropdown";
import DisableConnectionRequestSwitch from "../../../../components/DisableConnectionRequestSwitch/DisableConnectionRequestSwitch";
import DisableWorkflowComponentOverlay from "../../../../components/DisableWorkflowComponentOverlay";
import InputWithWordCount from "../../../../components/InputWithWordCount/InputWithWordCount";
import Modal from "../../../../components/Modal/Modal";
import { chevronDownIconBlue, cloneIcon, trashGrayIcon } from "../../../../constant/Constant";
import useMenu from "../../../../hooks/useMenu";
import usePersonalizeValues from "../../../../hooks/usePersonalizeValues";
import { changeCookie, getCookieInputValues } from "../../../../store/reducers/cookiesInput.slice";
import {
    changeInMailAtCursorPosition,
    changeInMailBody,
    changeInMailSubject,
    getInMailBody,
    getInMailSubject,
    resetInMailInputs,
} from "../../../../store/reducers/steps.slice";
import CustomMUIInput from "../../../project/components/CustomMUIInput";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import SaveTemplate from "../../../triggerWorkflow/components/SaveTemplate";
import SelectTemplate from "../../../triggerWorkflow/components/SelectTemplate";
import CalendlyMenu from "../../../triggerWorkflow/molecules/CalendlyMenu";
import {
    selectCandidates,
    selectIndex,
    setEditTemplatesModal,
} from "../../../triggerWorkflow/reducers/personalizedWorkflow.slice";
import { selectHyperPersonalizeMode, selectWorkflowMode } from "../../../workflow/index.reducer";
import DisableEmailSwitch from "../email/DisableEmailSwitch";
import { OutReachElementHelperMessage } from "../email/Email";

type InMailProps = {
    inmailBody: string;
    inmailStatus: boolean;
    inmailSubject: string;
};

interface reminderPropsType {
    inMailProps?: InMailProps;
    isOpen: boolean;
    index: number;
    editorVal?: string;
    setEditorVal?: (tarInd: number, newValue: string) => void;
    toggleCollapse: (tarInd: number) => void;
    handleRemove?: (tarInd: number) => void;
    handleDuplicate?: (tarInd: number) => void;
    type?: string;
    readable?: boolean;
    isLoading?: boolean;
    status?: React.ReactNode;
    handleBlurChange?: () => void;
}

export function fieldName(type: string, isOpen?: boolean) {
    switch (type) {
        case "linked-in-msg":
            return "LinkedIn Message";
        case "sms":
            return "SMS";
        case "connection-request":
            return isOpen ? "Connection Request" : "Linkedin outreach";
        default:
            return "Reminder";
    }
}

function CookiesInputModal() {
    const [open, setOpen] = useState(false);
    const inputs = useSelector(getCookieInputValues);
    const dispatch = useDispatch();

    const closeModal = () => setOpen(false);

    const openModal = () => setOpen(true);

    const handleClick = () => {
        openModal();
    };

    return (
        <>
            <MUIButton variant="outlined" sx={{ minWidth: "100px" }} onClick={handleClick}>
                Select IDs
            </MUIButton>
            {open && (
                <BaseModal onClose={closeModal}>
                    <Stack spacing={1.5}>
                        <Typography variant="h6" sx={{ fontSize: "22px" }}>
                            Edit Cookies
                        </Typography>
                        {/* <GridLayout> */}
                        <Typography variant="h6" sx={{ fontSize: "16px" }}>
                            Add LinkedIn Cookie
                        </Typography>
                        {/* <Typography
                                variant="h6"
                                sx={{ fontSize: "16px", textAlign: "center" }}
                            >
                                #
                            </Typography> */}
                        {/* </GridLayout> */}
                        {Object.values(inputs).map(({ cookie, count, id }, index) => {
                            return (
                                // <GridLayout key={id}>
                                <CustomMUIInput
                                    value={cookie}
                                    onChange={(e) =>
                                        dispatch(
                                            changeCookie({
                                                index: String(index),
                                                value: e.target.value,
                                            })
                                        )
                                    }
                                />
                                /* <CustomMUIInput
                                            type="number"
                                            value={count}
                                            onChange={(e) =>
                                                dispatch(
                                                    changeCount({
                                                        index: String(index),
                                                        value: e.target.value,
                                                    })
                                                )
                                            }
                                        /> */
                                // </GridLayout>
                            );
                        })}
                        <MUIButton
                            variant="contained"
                            onClick={closeModal}
                            sx={{ width: "fit-content", alignSelf: "center" }}
                        >
                            Submit
                        </MUIButton>
                    </Stack>
                </BaseModal>
            )}
        </>
    );
}

function InMail() {
    const dispatch = useDispatch();
    const isHyperPersonalized = useSelector(selectHyperPersonalizeMode);
    const customModeInMailSubject = useSelector(getInMailSubject);
    const customModeInMailBody = useSelector(getInMailBody);
    const isInMailEnabled = useSelector(selectEnableInMailSwitch);
    const [addTo, setAddTo] = useState<"SUBJECT" | "BODY">("SUBJECT");
    const index = useSelector(selectIndex);
    const candidates = useSelector(selectCandidates);
    const candidate = candidates[index];

    const {
        subjectRef,
        bodyRef,
        setAddTo: calendlySetAddTo,
        calendly: { calendlyActions, showCalendlyMenu, handleAddCalendlyLink },
    } = usePersonalizeValues();
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();

    useEffect(() => {
        return () => {
            dispatch(resetInMailInputs());
        };
    }, []);

    const handleSubjectChange = (value: string) => {
        dispatch(changeInMailSubject(value));
    };

    const handleBodyChange = (value: string) => {
        dispatch(changeInMailBody(value));
    };

    const handlePersonaliseValues = (label: string) => {
        if (addTo === "SUBJECT" && subjectRef?.current) {
            const cursorPosition = subjectRef.current?.selectionStart || 0;
            dispatch(changeInMailAtCursorPosition({ cursorPosition, label }));
            subjectRef.current?.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        }

        if (addTo === "BODY" && bodyRef?.current) {
            // @ts-ignore
            bodyRef.current.editor.focus();
            // @ts-ignore
            const selection = bodyRef.current?.editor?.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            // @ts-ignore
            bodyRef.current.editor.insertText(cursorPosition, label);
            // @ts-ignore
            dispatch(changeInMailBody(bodyRef.current.value));
        }
    };

    const personaliseOptions: CustomDropdownOptionsType[] = [
        {
            label: "{name}",
            key: "{name}",
            handleClick: () => handlePersonaliseValues("{name}"),
        },
        {
            label: "{lastname}",
            key: "{lastname}",
            handleClick: () => handlePersonaliseValues("{lastname}"),
        },
        {
            label: "{role}",
            key: "{role}",
            handleClick: () => handlePersonaliseValues("{role}"),
        },
        {
            label: "{company}",
            key: "{company}",
            handleClick: () => handlePersonaliseValues("{company}"),
        },
    ];

    const subject = isHyperPersonalized ? candidate?.inMail?.subject : customModeInMailSubject;

    const body = isHyperPersonalized ? candidate?.inMail?.body : customModeInMailBody;

    return (
        <Accordion onClick={(e) => e.stopPropagation()}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
                    <Typography>InMail</Typography>
                    <DisableEmailSwitch
                        checked={isInMailEnabled}
                        dispatch={(value) => dispatch(setEnableInMail(value))}
                    />
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ position: "relative" }}>
                <Stack spacing={2}>
                    <Stack direction="row" alignItems="center">
                        <InputWithWordCount
                            ref={subjectRef}
                            disabled={isHyperPersonalized}
                            customValue={subject || ""}
                            wordLimit={15}
                            handleOnChange={handleSubjectChange}
                            wrapperStyle={{
                                width: "100%",
                            }}
                            inputStyle={{
                                width: "90%",
                            }}
                            onFocus={() => {
                                setAddTo("SUBJECT");
                            }}
                        />
                        {!isHyperPersonalized && (
                            <>
                                {showCalendlyMenu && (
                                    <CalendlyMenu
                                        calendlyActions={calendlyActions}
                                        addCalendlyTag={(text, link) => handleAddCalendlyLink(text, link)}
                                    />
                                )}
                                <CustomDropdown options={personaliseOptions} tooltipTitle={"Personalise"}>
                                    <SensorOccupiedRoundedIcon
                                        fontSize="large"
                                        sx={{
                                            height: "28px",
                                            width: "28px",
                                        }}
                                    />
                                </CustomDropdown>
                            </>
                        )}
                    </Stack>
                    <ReactQuill
                        value={body}
                        ref={bodyRef}
                        className={isHyperPersonalized ? "email-quill" : ""}
                        readOnly={isHyperPersonalized}
                        onChange={(value, _, source) => {
                            if (source === "user") {
                                handleBodyChange(value);
                            }
                        }}
                        onFocus={() => {
                            setAddTo("BODY");
                            calendlySetAddTo("BODY");
                        }}
                    />
                    <Stack spacing={1} direction="row" alignItems="center" justifyContent="flex-end">
                        {!isHyperPersonalized ? (
                            <>
                                <SaveTemplate type="INMAIL" subject={subject} editorVal={body} />
                                <SelectTemplate
                                    type="INMAIL"
                                    setSubject={(subject) => handleSubjectChange(subject)}
                                    setEditorVal={(body) => handleBodyChange(body)}
                                />
                            </>
                        ) : null}
                    </Stack>
                </Stack>
                {!isInMailEnabled && (
                    <DisableWorkflowComponentOverlay>
                        Enable this step to send LinkedIn in-mail.
                    </DisableWorkflowComponentOverlay>
                )}
            </AccordionDetails>
        </Accordion>
    );
}

function ViewOnlyInMail(props: InMailProps) {
    const { inmailBody, inmailSubject } = props;
    return (
        <Stack spacing={1}>
            <Stack alignItems="center">
                <p className={style["rem__legend"]}>InMail</p>
            </Stack>

            <TextField
                multiline={true}
                disabled={true}
                inputProps={{ style: { fontSize: "14px" } }}
                value={inmailSubject}
            />
            <ReactQuill readOnly={true} value={inmailBody} />
        </Stack>
    );
}

export default function Reminder(props: reminderPropsType) {
    const {
        inMailProps,
        index,
        editorVal = "",
        setEditorVal,
        isOpen,
        toggleCollapse,
        handleRemove,
        handleDuplicate,
        type = "rem",
        readable,
        isLoading,
        handleBlurChange,
        status,
    } = props;
    const templateType = type === "linked-in-msg" ? "LI_MESSAGE_SEND" : "CONNECTION_REQUEST";
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();

    const dispatch = useDispatch();
    const workflowMode = useSelector(selectWorkflowMode);
    const enableConnectionReq = useSelector(selectEnableConnectionReq);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [checkInputFocus, setCheckInputFocus] = useState<{
        index: number;
        value: string;
    }>({
        index: 0,
        value: "",
    });

    const {
        bodyRef,
        setAddTo,
        calendly: { calendlyActions, showCalendlyMenu, handleAddCalendlyLink },
    } = usePersonalizeValues();

    const isHyperPersonalized = useSelector(selectHyperPersonalizeMode);
    // const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const handlePersonaliseValues = (key: string) => {
        if (!checkInputFocus.value || !setEditorVal) return;

        if (checkInputFocus.value === "body") {
            // @ts-ignore
            // const cursorPosition = bodyRef.current.selectionStart || 0;
            // console.log({ cursorPosition });
            // const tempEditorVal =
            //     editorVal?.substring(0, cursorPosition) +
            //     key +
            //     editorVal?.substring(cursorPosition);
            // setEditorVal(checkInputFocus.index, tempEditorVal);
            // // @ts-ignore
            // bodyRef.current?.setSelectionRange(
            //     cursorPosition + 1,
            //     cursorPosition + 1
            // );

            // @ts-ignore
            bodyRef.current.editor.focus();
            // @ts-ignore
            const selection = bodyRef.current.editor.getSelection();
            const cursorPosition = selection ? selection.index : 0;
            // @ts-ignore
            bodyRef.current.editor.insertText(cursorPosition, key);
        }
    };

    const handleReactQuillClick = () => {
        dispatch(setEditTemplatesModal(true));
        dispatch(setEditMsgPopup(true));
        setTimeout(() => {
            dispatch(setEditMsgPopup(false));
        }, 500);
    };

    const personaliseOptions: CustomDropdownOptionsType[] = [
        {
            label: "{name}",
            key: "{name}",
            handleClick: () => handlePersonaliseValues("{name}"),
        },
        {
            label: "{role}",
            key: "{role}",
            handleClick: () => handlePersonaliseValues("{role}"),
        },
        {
            label: "{company}",
            key: "{company}",
            handleClick: () => handlePersonaliseValues("{company}"),
        },
    ];

    if (readable || !handleDuplicate || !handleRemove || !setEditorVal) {
        return (
            <div className={style["rem__container"]}>
                <div className={`${style["rem__legendBox"]} ${index === 0 ? style["rem__legendBox-start"] : ""}`}>
                    {isOpen ? (
                        <div className={style["rem__actionbar"]}>
                            {handleDuplicate ? (
                                <div className={style["rem__actionBtn"]} onClick={() => handleDuplicate(index)}>
                                    <Tooltip title={"Duplicate"}>
                                        <img src={cloneIcon} alt="" />
                                    </Tooltip>
                                </div>
                            ) : null}
                            {handleRemove ? (
                                <div className={style["rem__actionBtn"]} onClick={() => handleRemove(index)}>
                                    <Tooltip title={"Delete"}>
                                        <img src={trashGrayIcon} alt="" />
                                    </Tooltip>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    <p className={style["rem__legend"]} onClick={() => toggleCollapse(index)} style={{display:"flex"}}>
                        {type && fieldName(type, isOpen)}{" "}
                        <img className={isOpen ? style["rem__invert"] : ""} src={chevronDownIconBlue} alt="" style={{marginLeft:"10px"}}/>
                        {status}
                    </p>
                </div>
                <Collapse in={isOpen}>
                    <div className={style["rem__body"]}>
                        {readable ? (
                            <Typography
                                style={{
                                    width: "100%",
                                    resize: "none",
                                    padding: 10,
                                    // background: "#eeeeee71",
                                    border: "1px solid #ccc",
                                    fontSize: "14px",
                                    color: "#000000",
                                }}
                                dangerouslySetInnerHTML={{ __html: editorVal }}
                            />
                        ) : (
                            <textarea
                                // ref={bodyRef}
                                value={editorVal}
                                disabled={readable}
                                rows={5}
                                style={{
                                    width: "100%",
                                    resize: "none",
                                    padding: 10,
                                }}
                            />
                        )}
                        {inMailProps?.inmailBody && inMailProps?.inmailSubject && inMailProps.inmailStatus ? (
                            <ViewOnlyInMail {...inMailProps} />
                        ) : null}
                    </div>
                </Collapse>
            </div>
        );
    }

    return (
        <>
            <div className={style["rem"]}>
                <div className={style["rem__container"]} id="workflow-reminder">
                    <div className={style["rem__legendBox"]}>
                        {/* {isOpen ? (
                                    <div className={style["rem__actionbar"]}>
                                        <div
                                            className={style["rem__actionBtn"]}
                                            onClick={() =>
                                                handleDuplicate(index)
                                            }
                                        >
                                            <Tooltip title={"Duplicate"}>
                                                <img src={cloneIcon} alt="" />
                                            </Tooltip>
                                        </div>
                                        <div
                                            className={style["rem__actionBtn"]}
                                            onClick={() => handleRemove(index)}
                                        >
                                            <Tooltip title={"Delete"}>
                                                <img
                                                    src={trashGrayIcon}
                                                    alt=""
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : null} */}
                        <p className={style["rem__legend"]} onClick={() => toggleCollapse(index)} style={{display:"flex"}}>
                            {type && fieldName(type)}{" "}
                            <img className={isOpen ? style["rem__invert"] : ""} src={chevronDownIconBlue} alt="" style={{marginLeft:"10px"}} />
                        </p>
                    </div>
                    <Collapse in={isOpen}>
                        {
                            <div className={style["rem__body"]}>
                                {!isHyperPersonalized && (
                                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                        {showCalendlyMenu && (
                                            <CalendlyMenu
                                                calendlyActions={calendlyActions}
                                                addCalendlyTag={(text, link) => handleAddCalendlyLink(text, link)}
                                            />
                                        )}
                                        <CustomDropdown options={personaliseOptions} tooltipTitle={"Personalise"}>
                                            <SensorOccupiedRoundedIcon
                                                fontSize="large"
                                                sx={{
                                                    height: "28px",
                                                    width: "28px",
                                                }}
                                            />
                                        </CustomDropdown>
                                    </Stack>
                                )}
                                {/* {type !== "rem" ? (
                                        <div
                                            className={style["rem__selectBox"]}
                                        >
                                            <Dropdown
                                                trigger={["click"]}
                                                menu={{ items: tempDropDown }}
                                            >
                                                <div
                                                    className={
                                                        style["rem__select"]
                                                    }
                                                >
                                                    <p>{selectedTemplate}</p>
                                                </div>
                                            </Dropdown>
                                            <Tooltip title={"Personalise"}>
                                                <Dropdown
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: personaliseOptions,
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            style[
                                                                "rem__select-personalise"
                                                            ]
                                                        }
                                                        src={personaliseIcon}
                                                        alt="personalise-icon"
                                                    />
                                                </Dropdown>
                                            </Tooltip>
                                        </div>
                                    ) : null} */}
                                {isLoading ? (
                                    <Skeleton variant="rounded" width="100%" height={200} />
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "1rem",
                                            }}
                                            onClick={isHyperPersonalized ? handleReactQuillClick : undefined}
                                        >
                                            <div
                                                style={{
                                                    position: "relative",
                                                }}
                                                onBlur={handleBlurChange}
                                            >
                                                {!enableConnectionReq && (
                                                    <DisableWorkflowComponentOverlay>
                                                        Enable this step to send connection request.
                                                    </DisableWorkflowComponentOverlay>
                                                )}
                                                <ReactQuill
                                                    className={isHyperPersonalized ? "follow-up-quill" : ""}
                                                    readOnly={isHyperPersonalized}
                                                    ref={bodyRef}
                                                    value={editorVal}
                                                    onChange={(value) => {
                                                        setEditorVal(index, value);
                                                    }}
                                                    onFocus={() => {
                                                        setAddTo("BODY");
                                                        setCheckInputFocus({
                                                            index,
                                                            value: "body",
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {workflowMode === "REGULAR_WORKFLOW" ? (
                                                <Stack
                                                    spacing={1}
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="flex-end"
                                                >
                                                    {/* <EditReminderMessage /> */}

                                                    <SaveTemplate type={templateType} editorVal={editorVal} />
                                                    <SelectTemplate
                                                        type={templateType}
                                                        setEditorVal={(value) => setEditorVal(index, value)}
                                                    />
                                                </Stack>
                                            ) : null}
                                            <InMail />
                                            {/* <TextAreaWithWordCount
                                                    ref={bodyRef}
                                                    customValue={editorVal}
                                                    disabled={true}
                                                    handleOnChange={(val) =>
                                                        setEditorVal(index, val)
                                                    }
                                                    wordLimit={
                                                        LINKED_CONN_REQ_WORD_LIMIT
                                                    }
                                                    rows={5}
                                                    onFocus={() => {
                                                        setCheckInputFocus({
                                                            index,
                                                            value: "body",
                                                        });
                                                    }}
                                                /> */}
                                        </div>
                                        <div
                                            className={style["rem__btnBox"]}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {type !== "rem" ? (
                                                <Stack>
                                                    <OutReachElementHelperMessage />
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#6C6C6C",
                                                        }}
                                                    >
                                                        **To avoid getting your LinkedIn flagged, large batch of
                                                        connection requests will be broken automatically into daily
                                                        smaller batches and sent
                                                    </Typography>
                                                </Stack>
                                            ) : null}
                                            <CookiesInputModal />
                                        </div>
                                    </>
                                )}
                                <Stack
                                    style={{
                                        maxHeight: "50px",
                                        padding: "0.3rem 1rem",
                                        position: "absolute",
                                        top: "-40px",
                                        right: "50px",
                                        backgroundColor: "white",
                                        borderTopLeftRadius: "0.7rem",
                                        borderTopRightRadius: "0.7rem",
                                    }}
                                >
                                    <DisableConnectionRequestSwitch />
                                </Stack>
                            </div>
                        }
                    </Collapse>
                </div>
            </div>
            <Modal open={isDeleteModalOpen} onClose={closeDeleteModal} title={"Confirm Delete"} width={"350px"}>
                <div className={style["rem__btnBox"]}>
                    <Button label="Cancel" variant="secondary" onClick={closeDeleteModal} />
                    <Button label="Delete" variant="primary" onClick={() => handleRemove(index)} />
                </div>
            </Modal>
        </>
    );
}
