import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { debounce, get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionMeta, MultiValue } from "react-select";

import AiCompanyDialog from "./AiCompanyDialog";
import { CompanyScrapeUrl } from "./CompanyScrape";
import styles from "./Filters.module.css";
import {
    addCurrentCompany,
    addEducation,
    addExperience,
    addIndustry,
    addJobTitle,
    addLocation,
    addSkills,
    getCompanyLinkedinUrlList,
    getOtherLinkedinUrlList,
    getScrappedCompany,
    getTypeAhead,
    pastCurrentCompany,
    searchCandidates,
    selectCurrentCompany,
    selectEducationOptions,
    selectErrors,
    selectExperienceOptions,
    selectIndustry,
    selectIndustryOptions,
    selectJobFunction,
    selectJobTitleOptions,
    selectLocationOptions,
    selectLocations,
    selectRoleFunctionOptions,
    selectScrapeCompanyRequestId,
    selectScrappedCompany,
    selectShowAICompanyPopup,
    selectShowOtherLinkedinUrlPopup,
    selectShowScrapLinkedinPopup,
    selectShowScrapeCompanyPopup,
    selectSkillOptions,
    selectVisaOptions,
    setRoleFunction,
    setShowAICompanyPopup,
    setShowScrapLinkedinPopup,
} from "./Filters.reducer";
import { errorsType, optionType, scrapeCompanyType } from "./Filters.type";
import JobFunction from "./JobFunction";
import LinkedinScrape from "./LinkedinScrape";
import LinkedinUrlList from "./LinkedinUrlList";

import CreatableSelect from "../../../../components/CreatableSelect/CreatableSelect";
import Loader from "../../../../components/Loader/Loader";
import store from "../../../../store";
import { AddMoreCompanies, CompanySuggestions } from "../CompanySuggestions";

const onChangeTypedField = (newValue: MultiValue<optionType>, action: ActionMeta<optionType>) => {
    const dispatch = useDispatch();
    const isJobTitle = action.name === "role";
    const isRoleFunction = action.name === "roleFunction";
    const isLocation = action.name === "location";
    const isSkills = action.name === "skill";
    const isIndustry = action.name === "industry";
    const isEducation = action.name === "education";
    const isExperience = action.name === "experience";
    const isCurrentCompany = action.name === "currentCompany";
    const isPastCompany = action.name === "pastCompany";

    const isCreate =
        action.action === "create-option" || action.action === "select-option" || action.action === "remove-value";
    const canClear = action.action === "clear" || action.action === "remove-value";
    // const isValueReomved = action.action === "remove-value";

    if (isCreate) {
        isJobTitle && dispatch(addJobTitle(newValue));
        isLocation && dispatch(addLocation(newValue));
        isSkills && dispatch(addSkills(newValue));
        isIndustry && dispatch(addIndustry(newValue));
        isExperience && dispatch(addExperience(newValue));
        isEducation && dispatch(addEducation(newValue));
        isCurrentCompany && dispatch(addCurrentCompany(newValue));
        isPastCompany && dispatch(pastCurrentCompany(newValue));
        isRoleFunction && dispatch(setRoleFunction(newValue));
    } else if (canClear) {
        isJobTitle && dispatch(addJobTitle([]));
        isLocation && dispatch(addLocation([]));
        isSkills && dispatch(addSkills([]));
        isIndustry && dispatch(addIndustry([]));
        isExperience && dispatch(addExperience([]));
        isEducation && dispatch(addEducation([]));
        isCurrentCompany && dispatch(addCurrentCompany([]));
        isPastCompany && dispatch(pastCurrentCompany([]));
        isRoleFunction && dispatch(setRoleFunction([]));
    }
};

export const Filters = () => {
    const dispatch = useDispatch();
    const locationOptions = useSelector(selectLocationOptions);
    const skillOptions = useSelector(selectSkillOptions);
    const educationOptions = useSelector(selectEducationOptions);
    const industryOptions = useSelector(selectIndustryOptions);
    const jobTitleOptions = useSelector(selectJobTitleOptions);
    const visaOptions = useSelector(selectVisaOptions);
    const experienceOptions = useSelector(selectExperienceOptions);
    const errors: errorsType = useSelector(selectErrors);
    const jobFunction = useSelector(selectJobFunction);
    const showOtherLinkedinUrlPopup: boolean = useSelector(selectShowOtherLinkedinUrlPopup);

    const showScrapLinkedinPopup = useSelector(selectShowScrapLinkedinPopup);
    const roleFunctionOptions = useSelector(selectRoleFunctionOptions);
    const showScrapeCompanyPopup = useSelector(selectShowScrapeCompanyPopup);
    const showAICompanyPopup = useSelector(selectShowAICompanyPopup);
    const scrapeCompanyRequestId = useSelector(selectScrapeCompanyRequestId);
    const scrappedCompany = useSelector(selectScrappedCompany);
    const currentCompany = useSelector(selectCurrentCompany);
    const [companySuggestion, setCompanySuggestion] = useState<optionType[]>([]);

    const locations = useSelector(selectLocations);
    const industry = useSelector(selectIndustry);
    const disableScrapeCompanies = isEmpty(locations) || isEmpty(industry);
    const [typeAhed, setTypeAhead] = useState<optionType>({
        value: "",
        label: "",
    });

    useEffect(() => {
        const scrapeCompanyInterval = setInterval(() => {
            const state = store.getState();
            const newScrapeCompanyRequestId = get(state, "advancedFilters.scrapeCompanyRequestId");

            if (!newScrapeCompanyRequestId) {
                clearInterval(scrapeCompanyInterval);
                return;
            }
            dispatch(getScrappedCompany());
        }, 6000);
    }, [scrapeCompanyRequestId]);

    useEffect(() => {
        if (!typeAhed.value) return;
        dispatch(getTypeAhead(typeAhed));
    }, [typeAhed]);

    useEffect(() => {
        const companySuggestionOption = {};
        let companySuggestion = [];
        scrappedCompany.map((item: scrapeCompanyType) => {
            companySuggestionOption[item.name] = item.name;
        });

        currentCompany.map((item: optionType) => {
            delete companySuggestionOption[item.label];
        });

        companySuggestion = Object.keys(companySuggestionOption).map((item) => {
            return {
                label: item,
                value: item,
            };
        });

        setCompanySuggestion(companySuggestion);
    }, [scrappedCompany, currentCompany]);

    const onSearch = () => {
        if (jobFunction?.value === "others") {
            dispatch(getOtherLinkedinUrlList());
        } else {
            dispatch(searchCandidates());
        }
    };

    const onClickScrapeCompany = (name: string) => {
        const newCurrentCompany: optionType[] = [...currentCompany];
        const option = {
            value: name,
            label: name,
        };
        newCurrentCompany.push(option);
        const action = {
            name: "currentCompany",
            action: "create-option",
            option,
        };

        //@ts-ignore
        onChangeTypedField(newCurrentCompany, action);
    };

    return (
        <Stack className={styles.container}>
            <JobFunction />
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Job title
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    onInputChange={debounce(setTypeAhead, 500)}
                    placeholder="eg: Account Executive"
                    name="role"
                    options={jobTitleOptions.length > 0 ? jobTitleOptions : []}
                />
                <Typography className={styles.error} variant="body2">
                    {errors.title}
                </Typography>
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Geography
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Add location"
                    name="location"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={locationOptions.length > 0 ? locationOptions : []}
                />
                <Typography className={styles.error} variant="body2">
                    {errors.location}
                </Typography>
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Skills
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="eg: Sales Prospecting"
                    name="skill"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={skillOptions.length > 0 ? skillOptions : []}
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Role function
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="eg: Sales Prospecting"
                    name="roleFunction"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={roleFunctionOptions.length > 0 ? roleFunctionOptions : []}
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    US work authorization
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Please Select an Option from Menu..."
                    name="Visa"
                    options={visaOptions}
                    isSearchable={false}
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Experience
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Add experience"
                    name="experience"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={experienceOptions}
                    isSearchable={false}
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Education
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Add education"
                    name="education"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={educationOptions.length > 0 ? educationOptions : []}
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Industry
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Add industry"
                    name="industry"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={industryOptions.length > 0 ? industryOptions : []}
                />
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Stack>
                    <Stack flexDirection="row" alignItems="center">
                        <Typography variant="body1" className={styles.fieldTitle}>
                            Current company
                        </Typography>
                        <Button
                            sx={{
                                padding: "0 10px",
                                fontSize: "12px",
                                height: "20px",
                                marginLeft: "10px",
                            }}
                            variant={disableScrapeCompanies ? "outlined" : "contained"}
                            onClick={() => dispatch(getCompanyLinkedinUrlList())}
                            disabled={disableScrapeCompanies}
                        >
                            Get companies
                        </Button>
                        <IconButton onClick={() => dispatch(setShowAICompanyPopup(true))}>
                            <Tooltip title="Search with AI">
                                <TravelExploreIcon />
                            </Tooltip>
                        </IconButton>
                    </Stack>
                    <Stack flexDirection="row" alignItems="center" spacing={0.5}>
                        <CreatableSelect
                            onChange={onChangeTypedField}
                            placeholder="Current company"
                            name="currentCompany"
                            onInputChange={debounce(setTypeAhead, 500)}
                            options={[]}
                            value={currentCompany}
                        />
                        <Stack flexDirection="row" alignItems="center" flex={1} ml="10px">
                            {scrapeCompanyRequestId && <Loader size="sm" />}
                        </Stack>
                        {companySuggestion?.length ? <AddMoreCompanies /> : <TravelExploreIcon sx={{ opacity: 0.2 }} />}
                    </Stack>
                    <CompanySuggestions />
                </Stack>
                <Stack display="block" mt="10px" sx={{ maxHeight: "250px", overflowY: "auto" }}>
                    {!!companySuggestion?.length &&
                        companySuggestion.map((item: optionType, i) => (
                            <Button
                                variant="outlined"
                                key={`${item.label}-${i}`}
                                sx={{
                                    width: "fit-content",
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                    padding: "3px 10px",
                                }}
                                onClick={() => onClickScrapeCompany(item.label)}
                            >
                                + {item.label}
                            </Button>
                        ))}
                </Stack>
            </Stack>
            <Stack className={styles.fieldContainer}>
                <Typography variant="body1" className={styles.fieldTitle}>
                    Past company
                </Typography>
                <CreatableSelect
                    onChange={onChangeTypedField}
                    placeholder="Past company"
                    name="pastCompany"
                    onInputChange={debounce(setTypeAhead, 500)}
                    options={[]}
                />
            </Stack>
            <Stack flexDirection="row">
                <Button
                    sx={{ flex: 1, marginRight: "10px" }}
                    variant="contained"
                    // onClick={() => dispatch(searchCandidates())}
                    onClick={onSearch}
                >
                    Search
                </Button>
                <Button
                    sx={{ flex: 1 }}
                    variant="outlined"
                    // onClick={() => dispatch(searchCandidates())}
                    onClick={() => dispatch(setShowScrapLinkedinPopup(true))}
                >
                    Scrape
                </Button>
            </Stack>
            {showOtherLinkedinUrlPopup && <LinkedinUrlList />}
            {showScrapLinkedinPopup && <LinkedinScrape />}
            {showScrapeCompanyPopup && <CompanyScrapeUrl />}
            {showAICompanyPopup && <AiCompanyDialog />}
        </Stack>
    );
};
