import Sheet from "@mui/joy/Sheet";

import WorkflowPane from "./workflows/WorkflowPane";

export default function Inbox() {
    return (
        <Sheet sx={{ border: "1px solid", borderColor: "divider" }}>
            <WorkflowPane />
        </Sheet>
    );
}
