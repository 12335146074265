import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useLocation , matchPath } from "react-router-dom";

import { useCallContext } from "../pages/project/components/TwilloModule/TwilloCallContext";

const PowerDialerSnackbar = () => {
    const { currentCampaign, callInProgress } = useCallContext();
    const location = useLocation();

    const isPowerDialerRoute =  !!matchPath("/call-logs/power-dialer", location.pathname);

    return (
        <Snackbar
            open={!!currentCampaign && !isPowerDialerRoute && callInProgress}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <Alert severity="info" sx={{ width: "100%" }}>
                Power Dialer call in progress for campaign : {currentCampaign?.name}
            </Alert>
        </Snackbar>
    );
};

export default PowerDialerSnackbar;
