import { IconButton, Stack, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";

import { selectUser } from "../../pages/Signin/Signin.reducer";
import { nameInitials } from "../../utils/helper";
import { MaterialProvider } from "../MaterialProvider";

const ProfileAvatar = ({ onClick }: { onClick?: any }) => {
    const user = useSelector(selectUser);
    const auth = getAuth();
    const firebaseProviderImage = auth?.currentUser?.providerData?.find(
        (provider) => provider?.providerId === "google.com"
    )?.photoURL;
    const profileImage = user.photoURL || firebaseProviderImage;

    return (
        <MaterialProvider>
            <IconButton onClick={onClick} size="small">
                {profileImage ? (
                    <img
                        referrerPolicy="no-referrer"
                        src={profileImage}
                        style={{ borderRadius: "50%", height: "28px", width: "28px" }}
                        alt="user profile icon"
                    />
                ) : (
                    <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={(theme) => ({
                            padding: 2.2,
                            borderRadius: "50%",
                            height: "28px",
                            width: "28px",

                            backgroundColor: theme.palette.grey[300],
                        })}
                    >
                        <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                            {nameInitials(user?.displayName ? user.displayName : "JD")}
                        </Typography>
                    </Stack>
                )}
            </IconButton>
        </MaterialProvider>
    );
};

export default ProfileAvatar;
