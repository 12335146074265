import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/joy";
import { Experimental_CssVarsProvider as CssProvider, IconButton, Tooltip as MuiTooltip } from "@mui/material";
import ReactQuill from "react-quill";

type AccordionComponentWithQuillProps = {
    sample: { title: string; editorData: string };
    index: number;
    setSampleWritingStyles: React.Dispatch<React.SetStateAction<{ title: string; editorData: string }[]>>;
    handleDeleteSample: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void;
    isExpanded: boolean;
    handleAccordionToggle: (index: number) => void;
};

const AccordionComponentWithQuill = ({
    sample,
    index,
    setSampleWritingStyles,
    handleDeleteSample,
    isExpanded,
    handleAccordionToggle,
}: AccordionComponentWithQuillProps) => {
    const [expandedState, setExpandedState] = useState(isExpanded);

    useEffect(() => {
        setExpandedState(isExpanded);
    }, [isExpanded]);

    const text = sample.editorData.replace(/<[^>]*>?/gm, "");
    const wordCount = text.split(/\s+/).filter((word) => word)?.length;

    return (
        <Accordion expanded={expandedState}>
            <AccordionSummary indicator={<AddIcon />} onClick={() => handleAccordionToggle(index)}>
                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <CssProvider>
                        <MuiTooltip title="Delete" placement="bottom">
                            <IconButton
                                aria-label="delete"
                                onClick={(e) => handleDeleteSample(e, index)}
                                sx={{ mr: 1, color: "#FF6969" }}
                            >
                                <DeleteOutlineIcon fontSize="small" sx={{ fontSize: "16px" }} />
                            </IconButton>
                        </MuiTooltip>
                    </CssProvider>
                    <Typography sx={{ fontWeight: "semibold" }}>{sample.title}</Typography>
                    {wordCount > 0 && (
                        <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                            <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                                {wordCount} words{" "}
                                {wordCount >= 50 ? (
                                    <DoneAllOutlinedIcon fontSize="small" sx={{ color: "#4CAF50" }} />
                                ) : (<span style={{ position: "relative", top: "2px" }}
                                ><ErrorOutlineOutlinedIcon fontSize="small" sx={{ color: "#FF6969" }} />
                                    <span style={{
                                        color: "#FF6969", marginLeft: "1px", fontSize: "8px", position: "relative", top: "-7px"

                                    }}>Please make sure your sample is 50 words or more </span></span>


                                )}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ width: "100%", height: 200 }}>
                    <ReactQuill
                        modules={{ toolbar: false }}
                        placeholder="Write something..."
                        value={sample.editorData}
                        onChange={(value) => {
                            setSampleWritingStyles((prev) => {
                                const newSamples = [...prev];
                                newSamples[index].editorData = value;
                                return newSamples;
                            });
                        }}
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionComponentWithQuill;
