import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../../store/reducers/loaders.reducer";

import API from "../../../utils/API";
import { getRb2bWebhookUrl, leavePage, setRb2bWebhookUrl } from "./internalRB2B.reducer";
import handleException from "../../../utils/sentry";
import { CancelSagas } from "../../../utils/saga.utils";

function* getRb2bWebhookUrlSaga(action: PayloadAction<any>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `/rb2b/generateWebhookUrl`, action.payload);
        if (!response?.data) return;

        const res = response.data;
      
        yield put(setRb2bWebhookUrl(res.url));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        yield takeLatest(getRb2bWebhookUrl.type, getRb2bWebhookUrlSaga),
    ];
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}