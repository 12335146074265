import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { checkIsLinkedinAuthorized } from "../pages/Signin/Signin.reducer";

export function AuthorizeLinkedinChecker({ children }: { children: React.ReactNode }) {
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);

    if (isLinkedInAuthorized) {
        return <Navigate to="/" />;
    }

    return <>{children}</>;
}
