import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";

import { CSVUploadWithHeadersMapper, CSVUploadWithHeadersMapperSubmit } from "./CSVUploadWithHeadersMapper";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { allCandidatesCSVUpload } from "../../pages/all-candidates/all-candidates.slice";
import { setModal, selectAllContactsCSVModal } from "../../store/reducers/modals.slice";

export function BDAllCandidatesCSVUpload() {
    const dispatch = useDispatch();
    const showAllContactsCSVUpload = useSelector(selectAllContactsCSVModal);
    const isCreatingContact = useSelector(checkIfLoading(allCandidatesCSVUpload.type));

    if (!showAllContactsCSVUpload) {
        return null;
    }

    const onClose = () => dispatch(setModal({ key: "showAllContactsCSVModal", value: false }));

    const handleSubmit = ({ file, csvHeaderMapping }: CSVUploadWithHeadersMapperSubmit) => {
        const form = new FormData();
        form.append("file", file);
        form.append("csvHeaders", JSON.stringify(csvHeaderMapping));
        dispatch(allCandidatesCSVUpload(form));
    };

    return (
        <BaseModal onClose={onClose}>
            <Stack spacing={2}>
                <Typography variant="h6">Upload contacts</Typography>
                <CSVUploadWithHeadersMapper isSubmitting={isCreatingContact} handleSubmit={handleSubmit} />
            </Stack>
        </BaseModal>
    );
}
