import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Chip, Tooltip } from "@mui/material";
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { t } from "i18next";
import { debounce } from "lodash";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    applyAllCandidatesFilters,
    selectAllCandidatesFilters,
    selectFilterValue,
    setAllCandidatesFilterValue,
    setAllCandidatesSearchQuery,
} from "./all-candidates.slice";
import { AllCandidatesTableItemKeys, FilterValue } from "./all-candidates.types";
import { useFetchCandidatesWithPayload } from "./AllCandidatesContent";
import { commonPaperProps } from "./components/ContactOverviewActivities/EditActivityForm";

import { ReadOnlyOption } from "../../common";
import TextLoading from "../../components/TextLoading";
import { RootState } from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { getAllProjectsList, selectAllProjects } from "../allProjects/index.reducer";

export const CustomOutlineInput = styled((props: OutlinedInputProps) => <OutlinedInput {...props} />)(() => ({
    borderRadius: "1rem",
    padding: 0,
    minWidth: "400px",
    "& .MuiOutlinedInput-input": {
        padding: "0.5rem 0.7rem",
        fontSize: "12px",
        borderRadius: "0.7rem",
    },
}));

export function SearchCandidates() {
    const dispatch = useDispatch();
    const inputRef = useRef<null | HTMLInputElement>();
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(setAllCandidatesSearchQuery(""));
        };
    }, []);

    useEffect(() => {
        if (showSearch) {
            inputRef?.current?.focus();
        }
    }, [showSearch]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.target.value;
        dispatch(setAllCandidatesSearchQuery(value));
    };

    const handleClick = () => {
        if (showSearch) {
            dispatch(setAllCandidatesSearchQuery(""));
        }
        setShowSearch((prev) => !prev);
    };

    return (
        <>
            <Tooltip title={t("projectsPage.candidateSection.searchCandidates")}>
                <IconButton aria-label="delete" onClick={handleClick}>
                    {showSearch ? <CancelRoundedIcon fontSize="small" /> : <SearchRoundedIcon fontSize="inherit" />}
                </IconButton>
            </Tooltip>
            {showSearch && (
                <CustomOutlineInput
                    inputRef={inputRef}
                    type="text"
                    placeholder="Search for any attribute"
                    onChange={debounce(handleChange, 500)}
                />
            )}
        </>
    );
}

type AccumulatedFilters = {
    [x: string]: FilterValue[];
};

export function AppliedFilters() {
    const isFiltersLoading = useSelector(checkIfLoading(applyAllCandidatesFilters.type));
    const filters = useSelector(selectAllCandidatesFilters);
    const dispatch = useDispatch();
    const appliedFiltersValue = Object.values(filters)?.reduce((acc: string[], val) => {
        const values = val.value.map((v) => v.value);

        return [...acc, ...values];
    }, []);

    const filterValues = Object.entries(filters)?.reduce((acc: AccumulatedFilters, [key, value]) => {
        acc[key] = value.value.map((i) => i);
        return acc;
    }, {});

    const handleRemove = ({
        key,
        newValues,
        removedValue,
    }: {
        key: AllCandidatesTableItemKeys;
        newValues: ReadOnlyOption[];
        removedValue: string;
    }) => {
        dispatch(
            setAllCandidatesFilterValue({
                key,
                value: newValues.filter(({ value }) => value !== removedValue),
            })
        );
    };

    if (!appliedFiltersValue.length) {
        return null;
    }

    return (
        <Stack direction="row" alignItems="center" gap={0.5} sx={{ maxWidth: "400px" }} flexWrap="wrap">
            {Object.entries(filterValues).map(([filterKey, filterValues]) => {
                if (!filterValues.length) {
                    return null;
                }

                return (
                    <Fragment key={filterKey}>
                        {filterValues.map(({ value, label }, i) => {
                            return (
                                <Chip
                                    label={label}
                                    key={`${value}-${i}`}
                                    sx={{
                                        "& .MuiChip-label": {
                                            fontSize: "11px",
                                        },
                                    }}
                                    size="small"
                                    disabled={isFiltersLoading}
                                    onDelete={() =>
                                        handleRemove({
                                            key: filterKey as AllCandidatesTableItemKeys,
                                            newValues: filterValues,
                                            removedValue: value,
                                        })
                                    }
                                />
                            );
                        })}
                    </Fragment>
                );
            })}
        </Stack>
    );
}

export function ProjectsList() {
    const dispatch = useDispatch();
    const allProjectsList = useSelector(selectAllProjects);
    const isProjectsFetching = useSelector(checkIfLoading(getAllProjectsList.type));
    const projectListOptions: FilterValue[] = allProjectsList.map(({ _id, name }) => ({
        label: name,
        value: String(_id),
    }));
    const value = useSelector((state: RootState) => selectFilterValue(state, "List of projects"));

    const handleChange = ({ newValue, reason }: { newValue: FilterValue[]; reason: AutocompleteChangeReason }) => {
        if (reason === "clear") {
            dispatch(
                setAllCandidatesFilterValue({
                    key: "List of projects",
                    value: [],
                })
            );
            return;
        }

        if (newValue) {
            dispatch(
                setAllCandidatesFilterValue({
                    key: "List of projects",
                    value: newValue,
                })
            );
        }
    };

    return (
        <Autocomplete
            multiple
            size="small"
            sx={{
                minWidth: 350,
                maxWidth: 400,
                "&.MuiAutocomplete-root": {
                    "& .MuiTextField-root": {
                        "& .MuiOutlinedInput-root": {
                            paddingRight: "0.7rem",
                        },
                    },
                },
            }}
            slotProps={{
                paper: {
                    sx: { ...commonPaperProps, maxHeight: "200px" },
                },
            }}
            value={value && value?.length > 1 ? [] : value}
            options={projectListOptions}
            loading={isProjectsFetching}
            getOptionLabel={(option) => option.label}
            onChange={(_event, value, reason) => {
                handleChange({
                    reason,
                    newValue: value,
                });
            }}
            clearOnEscape
            renderInput={(params) => (
                <TextField {...params} size="small" label="Projects" variant="outlined" placeholder="Select Project" />
            )}
        />
    );
}

export function Fetching() {
    const { isFetching } = useFetchCandidatesWithPayload();
    if (!isFetching) {
        return null;
    }

    return <TextLoading text="Fetching Data..." />;
}

// function AllContactsToolbar() {
//     const dispatch = useDispatch();
//     const numSelected = useSelector(numberOfSelectedRowsInAllCandidates);
//     const totalRows = useSelector(selectTotalRows);
//     const isAllSelected = useSelector(selectAllCandidatesSearchResults);

//     const toggle = () => dispatch(toggleAllCandidatesFiltersDrawer());
//     const showSelectedLabel = numSelected > 0 || isAllSelected;
//     const count = isAllSelected ? totalRows : numSelected;

//     return (
//         <Toolbar
//             sx={{
//                 justifyContent: "end",
//                 paddingX: "0.5rem !important",
//                 ...(showSelectedLabel && {
//                     bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
//                 }),
//             }}
//         >
//             <Stack sx={{ width: "100%" }}>
//                 <Stack direction="row" alignItems="center" justifyContent="space-between" px={1.5} py={1}>
//                     <Stack direction="row" alignItems="center" spacing={1}>
//                         {showSelectedLabel ? (
//                             <Typography
//                                 sx={{ flex: "1 1 10%", fontSize: "14px" }}
//                                 color="inherit"
//                                 variant="subtitle1"
//                                 component="div"
//                             >
//                                 {count} selected
//                             </Typography>
//                         ) : null}
//                         <ProjectsList />
//                         <AppliedFilters />
//                         {/* <ExportContacts /> */}
//                         {/* <CreateContactList />
//                         <AddToExclusionList /> */}
//                     </Stack>
//                     <Stack direction="row" spacing={1}>
//                         <Fetching />
//                         <FiltersLoading />
//                         <SearchLoading />
//                         <FetchLoadingWithData />
//                         <SearchCandidates />
//                         <AllCandidatesCSVUpload />
//                         {/* <ExportCandidates /> */}
//                         {numSelected <= 0 && (
//                             <Tooltip title="Filter list">
//                                 <IconButton onClick={toggle}>
//                                     <FilterAltRoundedIcon />
//                                 </IconButton>
//                             </Tooltip>
//                         )}
//                     </Stack>
//                 </Stack>
//                 {/* <SelectionPrompt /> */}
//             </Stack>
//         </Toolbar>
//     );
// }
