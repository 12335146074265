import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import { IconButton, Link } from "@mui/material";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import AccountMenu from "./accountMenu/AccountMenu";
import { CreateProject } from "./CreateProject/CreateProject";
import UploadMarketingPage from "./CreateProject/MarketingPage/Component/UploadMarketingPage";
import GlobalSearch from "./GlobalSearch";
import style from "./navbar.module.scss";

import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectAddExclusionListModal } from "../../store/reducers/modals.slice";
import { AutoPilot } from "../AutoPilot/AutoPilot";
import { AutoPilotProject } from "../AutoPilot/AutoPilotProject";
import { AutoPilotSwitch } from "../AutoPilot/AutoPilotSwitch";
import AddExclusionList from "../ExclusionList/AddExclusionList";

import { uploadprojectStatsFile } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    fetchPendingInvitations,
    getNotification,
    markActivityNotificationAsRead,
    markReadNotification,
    selectNotification,
    selectPendingInvitations,
} from "@/store/reducers/manage-teams/manageTeams.reducers";
import { INotification, NotificationSMSType } from "@/store/reducers/manage-teams/manageTeams.types";
import { selectShowUploadMarketingCompanyPage } from "@/store/reducers/marketing-page/MarketingPage.reducer";
import {
    checkIfAccountRB2B,
    checkIsV2Autopilot,
    isSuperAdminUser,
    selectFromInvitation,
    selectShowAllProjects,
    selectShowOnlyAutoPilotProjects,
    setFromInvitation,
    updateUser,
} from "@/store/reducers/signin/Signin.reducer";
import { selectIsSuperAdminView, setIsSuperAdminView } from "@/store/reducers/super-admin/superAdmin.reducer";
import { Button, Skeleton, Switch, Typography } from "@/ui";

type INotificationTile = {
    body: string;
    title: string;
    _id: string;
    candidateId: string;
    updatedAt: string;
    createdAt: string;
    onClick?: () => void;
    isSms?: boolean;
};

function getElapsedTimeFormatted(creationTime: string) {
    const currentTime = new Date();
    const _creationTime = new Date(creationTime);
    const timeDifference = currentTime.getTime() - _creationTime.getTime();

    const elapsedHours = Math.floor(timeDifference / (1000 * 60 * 60));

    if (elapsedHours < 24) {
        return `${elapsedHours} hours ago`;
    } else {
        const day = _creationTime.getDate();
        const month = _creationTime.toLocaleString("default", {
            month: "short",
        });
        let daySuffix = "th";
        if (day === 1 || day === 21 || day === 31) {
            daySuffix = "st";
        } else if (day === 2 || day === 22) {
            daySuffix = "nd";
        } else if (day === 3 || day === 23) {
            daySuffix = "rd";
        }

        return `${day}${daySuffix} ${month}`;
    }
}

function groupSMSByProjectName(smsNotifications: NotificationSMSType[]) {
    const grouped: { [key: string]: NotificationSMSType[] } = {};

    smsNotifications.forEach((obj) => {
        const projectName = obj.projectName;
        if (!grouped[projectName]) {
            grouped[projectName] = [];
        }
        grouped[projectName].push(obj);
    });
    return grouped;
}

function EmptyNotificationsIcon() {
    return (
        <div className="flex justify-center items-center m-8">
            <svg
                style={{ alignSelf: "center" }}
                fill="#0891b2"
                width="75px"
                height="75px"
                viewBox="0 0 1000 1000"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#0891b2"
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M888 853q-21-48-56-123-7-15 2-28 32-54 46-115t8-123q-8-93-58-172-49-77-127-125-79-49-172-56-88-7-170 24-78 29-137.5 88.5T135 362q-31 81-24 170 7 92 56 172 48 78 125 127 79 50 172 58 62 6 123-8t115-47q13-8 28-2l123 56q11 5 21 .5t14.5-14.5-.5-21zM485 721q-11 27-38.5 38.5t-55 0-39-39 0-55 39-38.5 55 0 38.5 38.5 0 54.5v1zm-25-127q-20-8-28-28t0-40l73-248q8-19 24.5-32.5t38-16 41 5.5 32.5 25 15.5 38-5.5 41L527 566q-8 19-27.5 27.5t-39.5.5z"></path>
                </g>
            </svg>
            <p className="text-base text-gray-500 font-medium">You have no new notifications</p>
        </div>
    );
}

function NotificationTile({
    title,
    body,
    _id,
    candidateId,
    createdAt,
    onClick,
    redirectUrl,
}: INotification & INotificationTile) {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(markReadNotification([_id]));
        dispatch(markActivityNotificationAsRead(_id));
        if (redirectUrl) {
            window.open(`/candidate-reachout${redirectUrl}`);
        }
        window.open(`/candidate-reachout/${candidateId}`);
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className="w-full p-2 px-4 space-y-1 rounded-sm hover:bg-gray-200 cursor-pointer" onClick={handleClick}>
            <div className="flex items-center font-light space-x-1">
                <div className="flex items-center font-light space-x-0.5">
                    <NotificationsRoundedIcon style={{ fill: "#0891b2" }} sx={{ width: 18, height: 18 }} />
                    <Typography className="text-[15px] text-teal-500 font-semibold">Activity</Typography>
                </div>
                <Typography className="text-[15px] text-gray-600">{getElapsedTimeFormatted(createdAt)}</Typography>
            </div>

            <div className="flex flex-col font-light space-y-0.5">
                <Typography className="text-[15px] text-gray-600">{body}</Typography>
            </div>
        </div>
    );
}

function SMSTile({ onClick, notifications }: { onClick: () => void; notifications: NotificationSMSType[] }) {
    const uniqueCandidateIds = new Set();
    notifications = notifications.filter((notification) => {
        if (uniqueCandidateIds.has(notification.candidateId)) {
            return false;
        } else {
            uniqueCandidateIds.add(notification.candidateId);
            return true;
        }
    });

    const dispatch = useDispatch();

    const handleClick = (notificationIds: string[], redirectUrl: string) => {
        dispatch(markReadNotification(notificationIds));
        window.open(redirectUrl);
        onClick();
    };

    if (notifications?.length >= 4) {
        return (
            <div
                className="w-full p-2 px-4 space-y-1 rounded-sm hover:bg-gray-200 cursor-pointer"
                onClick={() =>
                    handleClick(
                        notifications.map((i) => i._id),
                        notifications[0].redirectUrl
                    )
                }
            >
                <div className="flex items-center font-light space-x-1">
                    <div className="flex items-center font-light space-x-0.5">
                        <MessageRoundedIcon style={{ fill: "#0891b2" }} sx={{ width: 18, height: 18 }} />
                        <Typography className="text-[15px] font-semibold text-[#0891b2]">Message</Typography>
                    </div>
                    <Typography className="text-[15px] text-gray-600">
                        {getElapsedTimeFormatted(notifications[0].createdAt)}
                    </Typography>
                </div>

                <div className="flex flex-col font-light space-y-0.5">
                    <Typography className="text-[15px] text-gray-600">
                        {`You received ${notifications?.length} SMS replies in your project '${notifications[0]?.projectName}' with the latest SMS received from ${notifications[0]?.candidateName}`}
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        <>
            {notifications.map((notification, index) => {
                return (
                    <div
                        key={index}
                        className="w-full p-2 px-4 space-y-1 rounded-sm hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleClick([notification._id], notification.redirectUrl)}
                    >
                        <div className="flex items-center font-light space-x-1">
                            <div className="flex items-center font-light space-x-0.5">
                                <MessageRoundedIcon style={{ fill: "#0891b2" }} sx={{ width: 18, height: 18 }} />
                                <Typography className="text-[15px] font-semibold text-[#0891b2]">Message</Typography>
                            </div>
                            <Typography className="text-[15px] text-gray-600">
                                {getElapsedTimeFormatted(notification.createdAt)}
                            </Typography>
                        </div>

                        <div className="flex flex-col font-light space-y-0.5">
                            <Typography className="text-[15px] text-gray-600">
                                {`You received an SMS reply from ${notification.candidateName} in your project '${notification.projectName}' `}
                            </Typography>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

function NotificationSkeleton() {
    return (
        <div className="w-full space-y-1">
            <div className="flex items-center space-x-1">
                <Skeleton className="w-[20px] h-[20px] rounded-full" />
                <Skeleton className="w-[100px] h-[20px] rounded-full" />
            </div>
            <Skeleton className="w-[45px] h-[45px] rounded-full" />
        </div>
    );
}

function Notifications() {
    const invitations = useSelector(selectPendingInvitations);
    const fromInvitation = useSelector(selectFromInvitation);
    const isNotificationLoading = useSelector(checkIfLoading(getNotification.type));
    const isPendingInvitationsLoading = useSelector(checkIfLoading(fetchPendingInvitations.type));

    let notifications = useSelector(selectNotification);

    const dispatch = useDispatch();
    const notificationIconRef = useRef<HTMLButtonElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (!anchorEl && fromInvitation) {
            dispatch(setFromInvitation(false));
        }

        if (anchorEl) {
            dispatch(fetchPendingInvitations({ action: fetchPendingInvitations.type }));
            dispatch(getNotification());
        }
    }, [anchorEl]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const pendingInvitations = invitations.filter(({ status }) => status === "PENDING");

    notifications = notifications.filter((i) => i?.active);

    if (!notifications?.length) {
        return null;
    }

    const activityNotifications = notifications?.filter((notification) => !notification.isSms);

    const smsNotifications: NotificationSMSType[] = notifications?.filter((notification) => notification.isSms);

    const groupedSmsNotification = groupSMSByProjectName(smsNotifications);

    const totalNotifications =
        activityNotifications?.length +
        pendingInvitations?.length +
        Object.values(groupedSmsNotification)?.reduce((sum, i) => {
            return sum + (i?.length >= 4 ? 1 : i?.length);
        }, 0);

    return (
        <div style={{ position: "relative" }}>
            <IconButton size="small" ref={notificationIconRef} onClick={handleClick}>
                <Badge badgeContent={totalNotifications} color="primary" max={100} style={{ fontFamily: "Poppins" }}>
                    <NotificationsNoneIcon htmlColor="#C2CFE0" sx={{ height: "28px", width: "28px" }} />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    "& .MuiPaper-elevation": {
                        borderRadius: "0.5rem",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                    },
                }}
                style={{
                    borderRadius: "1rem",
                    marginTop: "10px",
                    zIndex: 99,
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <div className={`pb-2 w-[430px] ${notifications?.length === 0 ? "h-[300px]" : "h-[500px]"}`}>
                    {isNotificationLoading || isPendingInvitationsLoading ? (
                        <div className="flex flex-col justify-center items-stretch gap-3 px-2 py-2">
                            {[...Array(5)].map((_, idx) => {
                                return <NotificationSkeleton key={idx} />;
                            })}
                        </div>
                    ) : (
                        <>
                            {notifications?.length === 0 ? (
                                <EmptyNotificationsIcon />
                            ) : (
                                <>
                                    <Typography className="px-4 py-2 font-medium text-[15px] border-b border-gray-200">
                                        Notifications
                                    </Typography>
                                    <div className="overflow-auto" style={{ maxHeight: "calc(100% - 40px - 1rem)" }}>
                                        <div className="border-b border-gray-200">
                                            {activityNotifications
                                                .filter((item) => item?.active || item?.status === "PENDING")
                                                .map((item, index: number) => {
                                                    return (
                                                        <NotificationTile
                                                            {...item}
                                                            onClick={handleClose}
                                                            key={`notification-${index}`}
                                                        />
                                                    );
                                                })}

                                            {Object.keys(groupedSmsNotification).map((key, index) => {
                                                return (
                                                    <SMSTile
                                                        key={`sms-tile-${index}`}
                                                        onClick={handleClose}
                                                        notifications={groupedSmsNotification[key]}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="flex-grow"></div>
                            <div className="flex justify-center h-[40px] mt-4 px-2">
                                <Link target="_blank" href={"/teams"}>
                                    <Button variant="outline" className="rounded-md shadow-none" label="See more" />
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </Popover>
        </div>
    );
}

type NavbarProps = {
    variant?: "base" | "modified";
    containerStyle?: React.CSSProperties;
    isAdmin?: boolean;
};

export default function Navbar({ variant = "base", containerStyle, isAdmin }: NavbarProps) {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const isAddExclusionListModalOpen = useSelector(selectAddExclusionListModal);
    const isRB2BAccount = useSelector(checkIfAccountRB2B);

    const isSuperAdmin = useSelector(isSuperAdminUser);
    const showUploadMarketingCompanyPage = useSelector(selectShowUploadMarketingCompanyPage);
    const showAllProjects = useSelector(selectShowAllProjects);
    const isV2Autopilot = useSelector(checkIsV2Autopilot) ?? false;
    const showOnlyAutoPilotProjects = useSelector(selectShowOnlyAutoPilotProjects);

    const isProjectPage = pathname.startsWith("/projects");
    const isAllProjectsPage = matchPath("/", pathname);

    return (
        <div className={style["nav__container"]} style={containerStyle}>
            <div className={`flex ${isProjectPage ? "justify-between" : "justify-end"}`}>
                <div className="flex items-center gap-1">
                    {!isRB2BAccount && isProjectPage && (isV2Autopilot ? <AutoPilotProject /> : <AutoPilotSwitch />)}
                    {!isRB2BAccount && isSuperAdmin && <AutoPilot />}
                </div>
                <input
                    type="file"
                    id="uploadStatsCsv"
                    className="hidden"
                    onChange={(e) => {
                        if (e.target.files[0]) {
                            dispatch(uploadprojectStatsFile(e.target.files[0]));
                        }
                    }}
                />
                {isSuperAdminView && (
                    <Button
                        onClick={() => {
                            navigate("/superAdmin/userList");
                            dispatch(setIsSuperAdminView(false));
                        }}
                        label="Disable super admin view"
                    />
                )}
                {showUploadMarketingCompanyPage && <UploadMarketingPage />}
                {isAddExclusionListModalOpen && <AddExclusionList />}
                <CreateProject />

                <div className="flex items-center">
                    {isAdmin && (
                        <div className="flex items-center">
                            <Typography>{showAllProjects ? "All projects" : "My projects"}</Typography>
                            <Switch
                                checked={showAllProjects}
                                onChange={(checked) => {
                                    // @ts-ignore
                                    dispatch(updateUser({ showAllProjects: checked }));
                                }}
                            />
                        </div>
                    )}

                    {isSuperAdmin && isAllProjectsPage && (
                        <div className="flex items-center ml-2">
                            <Typography>AP projects</Typography>
                            <Switch
                                checked={showOnlyAutoPilotProjects}
                                onChange={(checked) => {
                                    // @ts-ignore
                                    dispatch(updateUser({ showOnlyAutoPilotProjects: checked }));
                                }}
                            />
                        </div>
                    )}
                    <Notifications />

                    <GlobalSearch />
                    <AccountMenu />
                </div>
            </div>
        </div>
    );
}
