import Stack, { StackProps } from "@mui/material/Stack";
import { parentPropsWithChildren } from "../../../../utils/react-utils";

interface ContentProps extends StackProps {
    loading?: string;
}

export default function Content(props: ContentProps) {
    return (
        <Stack
            gap={4}
            py={2}
            sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
            }}
            {...props}
        >
            {parentPropsWithChildren(props)}
        </Stack>
    );
}
