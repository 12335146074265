import { useCallback, useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";

// show (if current element is not present and user currently at the last element) and if element is unsaved
// if current element's step-id is not present in the outreach

function useBeforeUnload({ hasUnsavedChanges }: { hasUnsavedChanges: boolean }) {
    const navigate = useNavigate();

    const handleBeforeUnload = useCallback(
        (event: BeforeUnloadEvent) => {
            if (hasUnsavedChanges) {
                window.location.href = "/";
                // navigate("/");
                // return;
                // event.stopPropagation();
                event.preventDefault();
                event.returnValue = "something went wrong!";
            }

            return "something went wrong!";
        },
        [hasUnsavedChanges, navigate] // Include navigate in dependencies
    );

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload, {
            capture: true,
        });

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [handleBeforeUnload]);
}

type UseMoveAwayProps = {
    hasUnsavedChanges: (nextPathname: string) => boolean;
    proceedCallback?: () => void;
    resetCallback?: () => void;
};

export function useMoveAway({ hasUnsavedChanges, proceedCallback, resetCallback }: UseMoveAwayProps) {
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return currentLocation.pathname !== nextLocation.pathname && hasUnsavedChanges(nextLocation.pathname);
    });

    const handleBlockerReset = () => {
        blocker?.reset?.();
        resetCallback?.();
    };

    const handleBlockerProceed = () => {
        blocker?.proceed?.();
        proceedCallback?.();
    };

    return {
        isBlocked: blocker.state === "blocked",
        handleBlockerProceed,
        handleBlockerReset,
    };
}
