import CloseIcon from "@mui/icons-material/Close";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
    Box,
    Chip,
    CircularProgress,
    Experimental_CssVarsProvider,
    IconButton,
    List,
    ListItem,
    Popover,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

import style from "./Workflow.module.scss";

import { MaterialProvider } from "../../../../components/MaterialProvider";
import { useGetWorkflowDetailsQuery } from "../../../../store/apis/linkedin-workflow/linkedin-workflow.api";

function WorkflowDetails({ workflow }: { workflow: any }) {
    const { data, isLoading, isFetching } = useGetWorkflowDetailsQuery({ workflowId: workflow.workflowId });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popoverData, setPopoverData] = useState<{ title: string; items: string[] }>({
        title: "",
        items: [],
    });

    const handleContactClick = (event: React.MouseEvent<HTMLElement>, phones: string[]) => {
        const validPhones = phones.filter((phone) => phone && phone.trim().length > 0);
        setAnchorEl(event.currentTarget);
        if (validPhones?.length === 0) {
            setPopoverData({
                title: "Phone numbers",
                items: [],
            });
        } else {
            setPopoverData({
                title: "Phone numbers",
                items: validPhones,
            });
        }
    };

    const handleEmailClick = (
        event: React.MouseEvent<HTMLElement>,
        email: { primary: string; personal: string[]; professional: string[] }
    ) => {
        setAnchorEl(event.currentTarget);
        if (email?.primary?.length === 0 && email?.personal?.length === 0 && email?.professional?.length === 0) {
            setPopoverData({
                title: "Emails",
                items: [],
            });
        } else {
            setPopoverData({
                title: "Emails",
                items: [email.primary, ...email.personal, ...email.professional].filter((item) => item !== ""),
            });
        }
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setPopoverData({ title: "", items: [] });
    };
    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };
    if (isFetching || isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                }}
            >
                <CircularProgress thickness={4} />
            </Box>
        );
    }

    const truncateText = (text: string, length: number) => {
        if (text) {
            if (text.length > length) {
                return text?.slice(0, length) + "...";
            }
            return text;
        }

        return text;
    };
    const formatScheduledDate = (dateString: any) => {
        const date = new Date(dateString);
        return dayjs(date).format("MMM DD,  hh:mm a");
    };

    return (
        <>
            <MaterialProvider>
                <div>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <div className={style["animatedTypography"]}>
                            Workflow was triggered at {formatScheduledDate(workflow.triggeredAt)}
                        </div>
                    </Box>
                    <List
                        sx={{
                            margin: 0,
                            maxHeight: "180px",
                            overflowY: "auto",
                            paddingTop: 1,
                            marginLeft: "4.2rem",
                            backgroundColor: "white",
                            paddingRight: "2rem",
                            paddingLeft: "2rem",
                            marginBottom: "1rem",
                        }}
                    >
                        {data?.[0]?.candidates?.map((candidate) => (
                            <ListItem
                                key={candidate.name}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    padding: 0,
                                    marginBottom: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        padding: 2,
                                        backgroundColor: "#FAF9F6",
                                        borderRadius: "5px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                        height: "40px",
                                        marginBottom: "-13px",
                                    }}
                                >
                                    <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                                        {candidate.name}
                                    </Typography>
                                    <Box>
                                        <IconButton onClick={(event) => handleContactClick(event, candidate.phone)}>
                                            <ContactPageIcon
                                                sx={{
                                                    color: "black",
                                                    // marginBottom: "-6px",
                                                    // marginLeft: "3px",
                                                    // fontSize: "1.5rem",
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={(event) => handleEmailClick(event, candidate.email)}>
                                            <EmailIcon
                                                sx={{
                                                    color: "black",
                                                    // marginBottom: "-6px",
                                                    // marginLeft: "3px",
                                                    // fontSize: "1.5rem",
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => window.open(candidate.profileUrl)}>
                                            <LinkedInIcon
                                                sx={{
                                                    color: "#0c66c2",
                                                    // marginBottom: "-6px",
                                                    // marginLeft: "3px",
                                                    // fontSize: "1.5rem",
                                                }}
                                            />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </MaterialProvider>

            <Experimental_CssVarsProvider>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        boxShadow: "none",
                        border: "0px",
                        minWidth: "350px",
                    }}
                >
                    <Box
                        sx={{
                            p: 2,
                            minWidth: "280px",
                            position: "relative",
                            boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                            borderRadius: "8px",
                            backgroundColor: "#fff",
                        }}
                    >
                        <IconButton
                            onClick={handleClosePopover}
                            sx={{
                                position: "absolute",
                                top: 6,
                                right: 6,
                                color: "lightgrey",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                textAlign: "left",
                                color: "#333",
                                marginLeft: "5px",
                            }}
                            gutterBottom
                        >
                            {popoverData.title}
                        </Typography>
                        <Box sx={{ maxHeight: "120px", overflowY: "auto", marginBottom: "-3px" }}>
                            {popoverData.items.length > 0 ? (
                                popoverData.items.map((item, index) => (
                                    <Chip
                                        key={index}
                                        label={truncateText(item, 40)}
                                        onDelete={() => handleCopyToClipboard(item)}
                                        deleteIcon={<ContentCopyIcon sx={{ fontSize: "15px", color: "#1976d2" }} />}
                                        sx={{
                                            marginBottom: "2px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "5px",
                                            borderRadius: "4px",
                                        }}
                                    />
                                ))
                            ) : (
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontSize: "14px",
                                        color: "#888",
                                        width: "100%",
                                        mt: 1,
                                    }}
                                >
                                    No items available
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Popover>
            </Experimental_CssVarsProvider>
        </>
    );
}

export default WorkflowDetails;
