import Box from "@mui/joy/Box";
import Skeleton from "@mui/joy/Skeleton";
import { Fragment } from "react";

function SkeletonLoader() {
    return (
        <Box
            sx={{
                m: "auto",
                display: "flex",
                alignItems: "center",
                maxWidth: "100%",
                gap: 1,
                pt: 1,
                px: 2,
            }}
        >
            <Skeleton variant="circular" width={34} height={30} />
            <Box width="100%">
                <Skeleton variant="rectangular" width={200} height="1em" sx={{ mb: 1 }} />
                <Skeleton variant="rectangular" width="100%" height="1em" />
            </Box>
        </Box>
    );
}

interface IChatsLoader {
    size?: number;
}

export default function ChatsLoader({ size = 15 }: IChatsLoader) {
    return (
        <>
            {Array.from(Array(size)).map((_, idx) => (
                <Fragment key={idx}>
                    <SkeletonLoader />
                </Fragment>
            ))}
        </>
    );
}
