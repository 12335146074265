import React, { useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../../components/Loader/Loader";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { exportToCsvc } from "../../../../utils/helper";
import styles from "./Filters.module.css";
import {
    getOtherLinkedinUrlList,
    selectOtherLinkedinUrlList,
    selectSelectedLinkedinUrl,
    setSelectedLinkedinUrl,
    setShowOtherLinkedinUrlPopup,
    setShowScrapLinkedinPopup,
} from "./Filters.reducer";
import { optionType } from "./Filters.type";

const similarCompaniesUrls = ["autoSearchUrl_2", "autoSearchUrl_3"];

const LinkedinUrlList = () => {
    const dispatch = useDispatch();
    const otherLinkedinUrlList = useSelector(selectOtherLinkedinUrlList);
    const selectedLinkedinUrl: string = useSelector(selectSelectedLinkedinUrl);
    const isSearchLoading = useSelector(checkIfLoading(getOtherLinkedinUrlList.type));

    return (
        <Dialog
            open={true}
            onClose={() => dispatch(setShowOtherLinkedinUrlPopup(false))}
            classes={{
                paper: styles.popupContainer,
            }}
        >
            <DialogTitle>Linkedin urls</DialogTitle>
            <DialogContent>
                {isSearchLoading ? (
                    <Loader />
                ) : otherLinkedinUrlList?.length ? (
                    <FormControl>
                        <RadioGroup name="radio-buttons-group" defaultValue={selectedLinkedinUrl}>
                            {otherLinkedinUrlList.map((item: optionType, i: number) => (
                                <Stack flexDirection="row">
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label=""
                                        onClick={() => dispatch(setSelectedLinkedinUrl(item.value))}
                                    />
                                    <Button
                                        href={item.value}
                                        target="_blank"
                                        sx={{
                                            border: "none",
                                            boxShadow: "unset !important",
                                        }}
                                    >
                                        {item.label}&nbsp;
                                        {similarCompaniesUrls.includes(item.label) ? "(Similar Companies)" : null}
                                    </Button>
                                </Stack>
                            ))}
                        </RadioGroup>
                    </FormControl>
                ) : (
                    <DialogContentText>Oops no urls found</DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => dispatch(setShowOtherLinkedinUrlPopup(false))}>
                    Close
                </Button>
                <Button variant="outlined" onClick={() => exportToCsvc(otherLinkedinUrlList, "Linkedin urls")}>
                    Export urls
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        dispatch(setShowScrapLinkedinPopup(true));
                        dispatch(setShowOtherLinkedinUrlPopup(false));
                    }}
                    disabled={!selectedLinkedinUrl}
                >
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LinkedinUrlList;
