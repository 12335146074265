import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import InfoButton, { InfoMessage, WorkflowInfo } from "./SetupFlow/InfoButton";
import { selectEnableSMSSwitch, setEnableSMS } from "../pages/template/index.reducer";

const connectionReqMessages: InfoMessage[] = [
    {
        id: nanoid(),
        answer: "Disable this to skip sending a sms during your outreach.",
    },
];

export function DisableSMSSwitch() {
    const dispatch = useDispatch();
    const enableSMSSwitch = useSelector(selectEnableSMSSwitch);

    return (
        <Stack direction="row">
            <FormControlLabel
                sx={{ marginRight: 0 }}
                control={
                    <Switch
                        checked={enableSMSSwitch}
                        onChange={(e) => {
                            dispatch(setEnableSMS(e.target.checked));
                        }}
                    />
                }
                label={enableSMSSwitch ? "Enabled" : "Disabled"}
            />
            <InfoButton>
                <WorkflowInfo messages={connectionReqMessages} />
            </InfoButton>
        </Stack>
    );
}
