import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import {
    addNewActivity,
    addNewReferences,
    cancelActions,
    changeProjectStage,
    deleteReference,
    editActivity,
    getActivities,
    getContactReachout,
    getOrgUser,
    getProjects,
    getReference,
    setActivities,
    setContactInfo,
    setOrgUser,
    setProjects,
    setReference,
    submitCandidateActivity,
} from "./all-candidates-reachout.slice";
import { SubmitCandidateActivityPayload } from "./all-candidates-reachout.types";
import handleException from "../../utils/sentry";

function* submitCandidateActivitySaga(action: SubmitCandidateActivityPayload): SagaIterator {
    try {
        const payload = action.payload;
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `/activity/send-mail`, {
            ...payload,
        });

        if (response?.data?._id) {
            yield put(setSuccessNotification("email sent successfully"));
            if (payload?.callback) {
                payload?.callback();
            }
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("unexpected error while sending email"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getContactReachoutSaga({ payload }: { payload: string }): SagaIterator {
    try {
        yield put(startAction({ action: getContactReachout.type }));

        const response = yield call(new API().get, `/candidate/get/${payload}`);
        if (!response?.data) return;

        yield put(setContactInfo(response.data));
    } catch (error) {
        handleException(error);
        console.error("***error in getContactReachoutSaga", error);
    } finally {
        yield put(stopAction({ action: getContactReachout.type }));
    }
}

function* getActivitiesSaga({
    payload,
}: {
    payload: {
        candidateId: string;
    };
}): SagaIterator {
    try {
        yield put(startAction({ action: getActivities.type }));
        const response = yield call(new API().get, `/activity/get/candidate/${payload.candidateId}`);

        if (!response?.data) return;
        yield put(setActivities(response.data));
    } catch (error) {
        handleException(error);
        console.error("***error in getActivitiesSaga", error);
    } finally {
        yield put(stopAction({ action: getActivities.type }));
    }
}

function* addNewActivitySaga({
    payload,
}: {
    payload: {
        createdBy: string;
        title: string;
        description: string;
        candidateId: string;
        projectId: {
            name: string;
            _id: number;
        };
        body: string;
        referTo: {
            name: string;
            _id: number;
        };
        onDone?: () => void;
    };
}): SagaIterator {
    try {
        yield put(startAction({ action: addNewActivity.type }));
        const response = yield call(new API().post, `/activity/create`, {
            ...payload,
            projectId: payload.projectId._id,
            referTo: payload.referTo._id,
        });

        if (!response?.data) return;

        yield put(getActivities({ candidateId: payload.candidateId }));
        if (payload.onDone) payload.onDone();
    } catch (error) {
        handleException(error);
        console.error("***error in addNewActivitySaga", error);
    } finally {
        yield put(stopAction({ action: addNewActivity.type }));
    }
}

function* editActivitySaga({
    payload,
}: {
    payload: {
        _id: string;
        createdBy: string;
        title: string;
        description: string;
        candidateId: string;
        projectId: number;
        body: string;
        onDone?: () => void;
    };
}): SagaIterator {
    try {
        yield put(startAction({ action: editActivity.type }));
        const data = { ...payload };
        const activityId = payload._id;
        const response = yield call(new API().put, `/activity/update/${activityId}`, {
            ...data,
            activityId,
        });

        if (!response?.data) return;

        yield put(getActivities({ candidateId: payload.candidateId }));
        if (payload.onDone) payload.onDone();
    } catch (error) {
        handleException(error);
        console.error("***error in editActivitySaga", error);
    } finally {
        yield put(stopAction({ action: editActivity.type }));
    }
}

function* addNewReferencesSaga({
    payload,
}: {
    payload: {
        name: string;
        title: string;
        relation: string;
        email: string;
        linkedinUrl: string;
        body: string;
        candidateId: string;
        onDone?: Function;
    };
}): SagaIterator {
    try {
        yield put(startAction({ action: addNewReferences.type }));
        const response = yield call(new API().post, `/referral/create`, payload);

        if (!response?.data) return;

        if (payload?.onDone) payload.onDone({});
        yield put(getReference(payload.candidateId));
    } catch (error) {
        handleException(error);
        console.error("***error in addNewReferencesSaga", error);
    } finally {
        yield put(stopAction({ action: addNewReferences.type }));
    }
}

function* deleteReferenceSaga({ payload }: { payload: { _id: string; candidateId: string } }): SagaIterator {
    try {
        yield put(startAction({ action: deleteReference.type }));
        const response = yield call(new API().put, `/referral/delete/${payload._id}`, {});

        if (!response?.data) return;
        yield put(getReference(payload.candidateId));
    } catch (error) {
        handleException(error);
        console.error("***error in deleteReferenceSaga", error);
    } finally {
        yield put(stopAction({ action: deleteReference.type }));
    }
}

function* getReferenceSaga({ payload }: { payload: string }): SagaIterator {
    try {
        yield put(startAction({ action: getReference.type }));
        const response = yield call(new API().get, `/referral/get/candidate/${payload}`);

        if (!response?.data) return;
        yield put(setReference(response?.data));
    } catch (error) {
        handleException(error);
        console.error("***error in getReferenceSaga", error);
    } finally {
        yield put(stopAction({ action: getReference.type }));
    }
}

function* getProjectsSaga({ payload }: { payload: string }): SagaIterator {
    try {
        yield put(startAction({ action: getProjects.type }));
        const response = yield call(new API().get, `/candidate/projects/${payload}`);

        if (!response?.data) return;
        yield put(setProjects(response?.data));
    } catch (error) {
        handleException(error);
        console.error("***error in getProjectsSaga", error);
    } finally {
        yield put(stopAction({ action: getProjects.type }));
    }
}

function* changeProjectStageSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: changeProjectStage.type }));
        const data = {
            candidateIds: [payload.candidateId],
            projectId: payload.projectId,
            [payload.key]: payload[payload.key],
        };
        const response = yield call(new API().put, `/candidate/project-stage`, data);

        if (!response?.data) return;
        yield put(getProjects(payload.candidateId));
    } catch (error) {
        handleException(error);
        console.error("***error in changeProjectStageSaga", error);
    } finally {
        yield put(stopAction({ action: changeProjectStage.type }));
    }
}
function* getOrgUserSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: getOrgUser.type }));

        const response = yield call(new API().get, `/organization/members`);

        if (!response?.data?.members) return;
        yield put(setOrgUser(response?.data.members));
    } catch (error) {
        handleException(error);
        console.error("***error in getOrgUserSaga", error);
    } finally {
        yield put(stopAction({ action: getOrgUser.type }));
    }
}

export default function* rootSagas() {
    // @ts-ignore
    const tasks = [
        // @ts-ignore
        yield takeLatest(getContactReachout.type, getContactReachoutSaga),
        // @ts-ignore
        yield takeLatest(addNewActivity.type, addNewActivitySaga),
        // @ts-ignore
        // yield takeLatest(editActivity.type, editActivitySaga),
        // @ts-ignore
        yield takeLatest(getActivities.type, getActivitiesSaga),
        // @ts-ignore
        yield takeLatest(getReference.type, getReferenceSaga),
        // @ts-ignore
        yield takeLatest(getProjects.type, getProjectsSaga),
        // @ts-ignore
        yield takeLatest(changeProjectStage.type, changeProjectStageSaga),
        // @ts-ignore
        yield takeLatest(getOrgUser.type, getOrgUserSaga),
        // @ts-ignore
        yield takeLatest(addNewReferences.type, addNewReferencesSaga),
        // @ts-ignore
        yield takeLatest(deleteReference.type, deleteReferenceSaga),
        // @ts-ignore
        yield takeLatest(submitCandidateActivity.type, submitCandidateActivitySaga),
    ];

    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
