import CloseIcon from "@mui/icons-material/Close"; // Import the close icon
import { Box, Button, IconButton, Input, Typography } from "@mui/joy";
import Tooltip from "@mui/joy/Tooltip";
import { Experimental_CssVarsProvider, Popover } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { useSelector } from "react-redux";

import { JoyProvider } from "../../../../components/JoyProvider";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { tooltipSetting } from "../../../analytics-v2/utils";
import { checkSuperInboxResponseEnabled } from "../../../Signin/Signin.reducer";

interface EditorProps {
    subject?: string;
    onSubmit?: (body: string) => void;
    title: string;
    show?: boolean;
    info?: ReactNode;
}

export default function ResponseEditor(props: EditorProps) {
    const superInboxResponseEnabled = useSelector(checkSuperInboxResponseEnabled);

    // const { t } = useTranslation();

    const { subject, onSubmit, title, show, info } = props;

    const [body, setBody] = useState("");

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSend = () => {
        onSubmit?.(body);
        setAnchorEl(null);
        setBody("");
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const isBodyEmpty = (body: string) => {
        const trimmedBody = body.trim();
        return !trimmedBody || trimmedBody === "<p><br></p>";
    };

    const isSendDisabled = isBodyEmpty(body);

    if (!superInboxResponseEnabled) {
        return null;
    }

    return (
        <>
            {show && (
                <Button aria-describedby={id} variant="soft" onClick={handleClick}>
                    Respond to {title}
                </Button>
            )}

            {!show && (
                <Tooltip {...tooltipSetting} title={info} sx={{ maxWidth: "600px" }}>
                    <Button aria-describedby={id} variant="soft">
                        Respond to {title}
                    </Button>
                </Tooltip>
            )}

            <Experimental_CssVarsProvider>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <JoyProvider>
                        <Box
                            sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                height: "550px",
                                width: "600px",
                                position: "relative",
                            }}
                        >
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    top: 2,
                                    right: 8,
                                    color: "blue",
                                    marginBottom: "40px",
                                }}
                            >
                                <CloseIcon style={{ color: "#0c6bcc" }} />
                            </IconButton>

                            <Typography sx={{ fontWeight: 500 }}>Write your {title} response or follow up</Typography>

                            {subject ? (
                                <Tooltip
                                    size="sm"
                                    title="You cannot edit the subject as this email will be sent in the same conversation"
                                >
                                    <Input
                                        placeholder="Subject"
                                        value={subject}
                                        sx={{ marginTop: "5px", color: "rgb(106 106 106)" }}
                                        disabled
                                    />
                                </Tooltip>
                            ) : (
                                <Box sx={{ marginTop: "25px" }}></Box>
                            )}

                            <RichTextEditor
                                enableImageModule
                                style={{ height: "300px" }}
                                value={body}
                                onChange={(value) => setBody(value)}
                            />

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "50px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        width: "auto",
                                        marginRight: "20px",
                                        fontSize: "12px",
                                        color: "darkgray",
                                        fontStyle: "italic",
                                    }}
                                >
                                    This {title} message will be sent on the same {title} thread, and should be visible
                                    in your inbox once sent.
                                </Typography>
                                <Box sx={{ width: "27%", display: "flex", justifyContent: "space-between" }}>
                                    <Tooltip title={isSendDisabled ? "Please enter some text to send" : ""}>
                                        <span>
                                            <Button onClick={handleSend} disabled={isSendDisabled}>
                                                Send {title}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </JoyProvider>
                </Popover>
            </Experimental_CssVarsProvider>
        </>
    );
}
