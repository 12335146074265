import { MutableRefObject, useEffect } from "react";
import ReactQuill from "react-quill";

type useTooltipOnHighlightProps = {
    editorRef: MutableRefObject<ReactQuill | null>;
};

function useTooltipOnHighlightQuill({ editorRef }: useTooltipOnHighlightProps) {
    useEffect(() => {
        const quill = editorRef.current?.getEditor();
        if (!quill) return;

        const editorContainer = quill?.root?.parentElement;
        const showTooltip = (e: MouseEvent) => {
            const tooltip = editorContainer?.querySelector("#ai-tooltip");
            if (tooltip) tooltip.remove();
            const element = e.target as any;
            const text = (e?.target as any)?.innerText;
            if (/{{[^{}]*}}/.test(text) && (e as any)?.toElement?.localName === "span") {
                const tooltipEl = document.createElement("div");
                tooltipEl.id = "ai-tooltip";
                tooltipEl.textContent = "AI prompt (highlighted color is only for display)";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.top = element.offsetTop - element.offsetHeight - 12 + "px";
                tooltipEl.style.left = "50%";
                tooltipEl.style.backgroundColor = "gray";
                tooltipEl.style.transform = "translateX(-50%)";
                tooltipEl.style.color = "white";
                tooltipEl.style.padding = "5px";
                tooltipEl.style.fontSize = "10px";
                tooltipEl.style.borderRadius = "3px";
                tooltipEl.style.zIndex = "10";
                editorContainer?.appendChild(tooltipEl);
            }
        };

        const hideTooltip = () => {
            const tooltip = editorContainer?.querySelector("#ai-tooltip");
            if (tooltip) tooltip.remove();
        };

        quill.root.addEventListener("mouseover", showTooltip);
        quill.root.addEventListener("mouseout", hideTooltip);

        return () => {
            quill.root.removeEventListener("mouseover", showTooltip);
            quill.root.removeEventListener("mouseout", hideTooltip);
        };
    }, []);
}
export default useTooltipOnHighlightQuill;
