import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { CircularProgress, FormHelperText } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { CaptchaModal } from "./CaptchaModal";
import { LinkedInCredentialsModal } from "./LinkedinCredentialsModal";
import { OTPModal } from "./OTPModal";
import { VerificationModal } from "./VerificationModal";

import { Linkedin } from "../../icons";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { useAppDispatch } from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectLinkedAuthorizeModal, setModal } from "../../store/reducers/modals.slice";
import CustomButton from "../CustomButton";

import {
    abortAuthorizeLinkedin,
    authorizeLinkedin,
    getLinkedinAuthStatus,
    selectLinkedinAuthStatus,
    setLinkedinAuthStatus,
    submitLinkedinCaptcha,
    submitLinkedinOTP,
} from "@/store/reducers/signin/Signin.reducer";
import { IAuthorizeLinkedinPayload } from "@/store/reducers/signin/Signin.types";
import { Typography } from "@/ui";
import { AnyARecord } from "node:dns";

export function AuthorizeLinkedIn({ virtualUser, onClose }: { virtualUser: number; onClose: () => void }) {
    console.log("virtualUser", typeof virtualUser);
    const dispatch = useAppDispatch();
    const showLinkedinAuthorizedModal = useSelector(selectLinkedAuthorizeModal);
    const linkedinAuthStatus = useSelector(selectLinkedinAuthStatus);
    const isAbortingAuthorizeLinkedin = useSelector(checkIfLoading(abortAuthorizeLinkedin.type));
    const intervalRef = useRef<null | NodeJS.Timeout>(null);
    const fetchCount = useRef(0);
    const maxFetchCount = 40;
    const [error, setError] = useState<string | null>(null);
    const [limitExceeded, setLimitExceeded] = useState<boolean>(false);

    const isLoading =
        ["CREDENTIALS_PUT", "OTP_SUBMITTED", "CAPTCHA_SOLVED", "IN_PROGRESS"].includes(linkedinAuthStatus) &&
        !limitExceeded;

    const resetTimer = (error?: string) => {
        setError(error ?? null);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            fetchCount.current = 0;
        }
    };

    const handleClose = () => {
        onClose();
        if (linkedinAuthStatus === null || linkedinAuthStatus === undefined) {
            dispatch(
                setModal({
                    key: "showLinkedInAuthorizeModal",
                    value: false,
                })
            );
            dispatch(setLinkedinAuthStatus(null));
        }
    };

    useEffect(() => {
        switch (linkedinAuthStatus) {
            case "OTP_REQUESTED":
                resetTimer();
                break;
            case "OTP_INCORRECT":
                resetTimer("Invalid OTP");
                break;
            case "SUCCESS":
                resetTimer();
                handleClose();
                break;
            case "FAILED":
                resetTimer();
                break;
            case "WRONG_PASSWORD":
                resetTimer("Invalid credentials");
                break;
            case "CAPTCHA_REQUESTED":
                resetTimer();
                break;
            case "PENDING":
                resetTimer();
                break;
            default: {
                setError(null);
                if (linkedinAuthStatus && fetchCount.current < maxFetchCount) {
                    intervalRef.current = setInterval(() => {
                        fetchCount.current += 1;
                        if (fetchCount.current === maxFetchCount) {
                            clearInterval(intervalRef.current!);
                            setLimitExceeded(true);
                        } else {
                            // eslint-disable-next-line no-console
                            console.log(`${fetchCount.current}/${maxFetchCount}: ${linkedinAuthStatus}`);

                            const payload: { virtualUserId?: number } = {};
                            if (virtualUser) {
                                payload.virtualUserId = virtualUser;
                            }
                            dispatch(getLinkedinAuthStatus(payload));
                        }
                    }, 10 * 1000); // 10 seconds
                }
            }
        }
        return () => {
            if (intervalRef?.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [linkedinAuthStatus, fetchCount]);

    const handleSubmitCreds = (creds: IAuthorizeLinkedinPayload) => {
        resetTimer();
        const payload = { ...creds };
        if (virtualUser) {
            payload.virtualUserId = virtualUser;
        }
        dispatch(authorizeLinkedin(payload));
    };

    const handleSubmitOTP = (otp: string) => {
        resetTimer();
        if (virtualUser) {
            const payload = {
                otp: otp,
                virtualUserId: virtualUser,
            };
            dispatch(submitLinkedinOTP(payload));
        } else {
            const payload = {
                otp: otp,
            };
            dispatch(submitLinkedinOTP(payload));
        }
    };

    const handleSubmitCaptcha = (token: string) => {
        resetTimer();
        if (virtualUser) {
            const payload = {
                token: token,
                virtualUserId: virtualUser,
            };

            dispatch(submitLinkedinCaptcha(payload));
        } else {
            const payload = {
                token: token,
            };
            dispatch(submitLinkedinCaptcha(payload));
        }
    };

    const handleAbortLinkedinAuthorization = () => {
        resetTimer();
        if (virtualUser) {
            const payload = {
                virtualUserId: virtualUser,
            };

            dispatch(abortAuthorizeLinkedin(payload));
        } else {
            dispatch(abortAuthorizeLinkedin({}));
        }

        dispatch(setLinkedinAuthStatus(null));
        dispatch(
            setModal({
                key: "showLinkedInAuthorizeModal",
                value: false,
            })
        );
    };

    if (!showLinkedinAuthorizedModal) {
        if (!virtualUser) {
            return null;
        }
    }

    const renderModal = () => {
        if (limitExceeded) {
            return (
                <Stack gap={1} justifyContent="center" alignItems="center" direction="row">
                    <Typography>Failed to authorize LinkedIn</Typography>
                    <ErrorIcon color="error" />
                </Stack>
            );
        }

        switch (linkedinAuthStatus) {
            case undefined: // initial state
            case "WRONG_PASSWORD":
            case "PENDING":
                return <LinkedInCredentialsModal onSubmit={handleSubmitCreds} />;
            case "OTP_REQUESTED":
            case "OTP_INCORRECT":
                return <OTPModal onSubmit={handleSubmitOTP} />;
            case "CAPTCHA_REQUESTED":
                return <CaptchaModal onSubmit={handleSubmitCaptcha} />;
            case "APP_VERIFICATION":
                return <VerificationModal />;
            case "SUCCESS":
                return (
                    <Stack gap={1} justifyContent="center" alignItems="center" direction="row">
                        <Typography>LinkedIn authorized successfully</Typography>
                        <CheckCircleIcon color="success" />
                    </Stack>
                );
            case "FAILED":
                return (
                    <Stack gap={1} justifyContent="center" alignItems="center" direction="row">
                        <Typography>Failed to authorize LinkedIn</Typography>
                        <ErrorIcon color="error" />
                    </Stack>
                );
            default:
                return null;
        }
    };

    const getTitle = () => {
        if (isLoading) return "Authorizing LinkedIn";
        switch (linkedinAuthStatus) {
            case "APP_VERIFICATION":
                return "Check your LinkedIn app";
            case "SUCCESS":
            case "FAILED":
                return null;
            default:
                return "Authorize LinkedIn";
        }
    };

    const getLoaderDescription = () => {
        switch (linkedinAuthStatus) {
            default:
                return null;
        }
    };

    return (
        <BaseModal onClose={handleClose} hideCloseButton={!!linkedinAuthStatus} overlayStyles={{ width: "400px" }}>
            <Stack gap={2}>
                <Stack gap={2} height={100}>
                    <Linkedin size={110} style={{ alignSelf: "center" }} />
                    <Typography variant="h6" className="text-center">
                        {getTitle()}
                    </Typography>
                </Stack>
                <FormHelperText sx={{ fontSize: 14, textAlign: "center" }} error>
                    {error}
                </FormHelperText>
                {isLoading ? (
                    <Stack alignItems={"center"} fontSize={14} justifyContent={"center"} gap={2}>
                        {getLoaderDescription()}
                        <Stack my={5}>
                            <CircularProgress size={28} />
                        </Stack>
                        <Stack alignItems={"center"} justifyContent={"center"} sx={{ textAlign: "center" }}>
                            Authentication in progress, this might take 1-2 minutes. Please do not close or refresh.
                        </Stack>
                    </Stack>
                ) : (
                    renderModal()
                )}

                {!(linkedinAuthStatus === null || linkedinAuthStatus === undefined) && (
                    <Stack justifyContent="center" alignItems="center">
                        <CustomButton disabled={isAbortingAuthorizeLinkedin} onClick={handleAbortLinkedinAuthorization}>
                            {isAbortingAuthorizeLinkedin ? "Aborting..." : "Abort Login"}
                        </CustomButton>
                    </Stack>
                )}
            </Stack>
        </BaseModal>
    );
}
