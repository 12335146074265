import { useCallback, useEffect, useState ,ImgHTMLAttributes} from "react";
import { Skeleton } from "@mui/material";

import isValidImageUrl from "../../utils/ValidateImageUrl";

interface ImageUrlProps extends ImgHTMLAttributes<HTMLImageElement> {
    placeholder?: string;
}

export default function ImageUrl(props: ImageUrlProps) {
    const { src, placeholder, ...rest } = props;
    const [isValidUrl, setIsValidUrl] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const verifyImage = useCallback(async () => {
        if (src) {
            setIsLoading(true);
            const isValid = await isValidImageUrl(src);
            setIsValidUrl(isValid);
            setIsLoading(false);
        }
    }, [src]);

    useEffect(() => {
        verifyImage();
    }, [src, verifyImage]);

    if(!src) return null;

    if (!isValidUrl && !placeholder) return null;

    if (isLoading) return <Skeleton variant="rectangular" width={32} height={30} />;

    return (
        <img src={isValidUrl ? src : placeholder} style={{ objectFit: "contain" }} {...rest} />
    );
}