import { Portal } from "@mui/base";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { Button } from "@/ui";
import { get } from "lodash";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Nudge from "../../../../components/Nudges/Nudges";

import { useCreateOutreachMutation } from "@/store/apis/outreach/outreach.api";
import { CreateOutreachRequestPayload } from "@/store/apis/outreach/outreach.types";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import {
    selectAddToWorkflowNudge,
    selectInstallExtensionNudge,
    selectShowEmailNudge,
} from "@/store/reducers/nudges/Nudges.reducer";
import { selectSelectedCandidates } from "@/store/reducers/project/project.reducer";
import { IProject } from "@/store/reducers/project/project.types";
import { checkOutreachVersion } from "@/store/reducers/signin/Signin.reducer";

export default function AddToWorkflow({
    getCreateOutreachPayload,
    handleCreateOutreachV1,
}: {
    getCreateOutreachPayload: () => CreateOutreachRequestPayload;
    handleCreateOutreachV1: () => void;
}) {
    const navigate = useNavigate();
    const [createOutreach, { isLoading: isCreatingOutreach }] = useCreateOutreachMutation();
    const dispatch = useDispatch();
    const installExtensionNudge = useSelector(selectInstallExtensionNudge);
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const showEmailNudge = useSelector(selectShowEmailNudge);
    const projectData = useSelector<any, IProject>((state) => get(state, "allProjects.project"));
    const addToWorkflowNudge = useSelector(selectAddToWorkflowNudge);
    const outreachVersion = useSelector(checkOutreachVersion);

    const container = useRef<HTMLButtonElement | null>(null);

    const showNudgeCondn =
        installExtensionNudge &&
        showEmailNudge &&
        !addToWorkflowNudge &&
        !(projectData?.projectStatus === "IN_PROGRESS" || projectData?.projectStatus === "PENDING");

    // const continueFlow = () => {
    //     dispatch(setAuthorizeModal(false));
    //     dispatch(setCanContinueFlow(false));
    //     handleClick();
    // };

    const handleCreateReachout = () => {
        const requestPayload = getCreateOutreachPayload();
        const firstCandidateId = requestPayload.candidateIds[0];
        const projectId = requestPayload.projectId;

        createOutreach(requestPayload)
            .unwrap()
            .then((res) => {
                const { workflowId, workflowSteps } = res;
                if (workflowSteps.length) {
                    const stepId = workflowSteps[0]._id;
                    navigate(`/outreach/${projectId}/${workflowId}/${firstCandidateId}/${stepId}/base`);
                } else {
                    throw new Error();
                }
            })
            .catch((err) => {
                console.log("🚀 ~ handleReachout ~ err:", err);
                dispatch(setErrorNotification("Unexpected error while creating outreach"));
            });
    };

    const handleClick = () => {
        if (outreachVersion === "v2") {
            handleCreateReachout();
        } else {
            handleCreateOutreachV1();
        }
    };

    const disableOutreach = !selectedCandidates.length || isCreatingOutreach;

    return (
        <>
            <Button
                id="addToWorkflow"
                className="rounded-full"
                ref={container}
                onClick={handleClick}
                disabled={disableOutreach}
                loading={isCreatingOutreach}
                endDecorator={isCreatingOutreach ? undefined : <SendRoundedIcon />}
            >
                Reach out
            </Button>
            {showNudgeCondn && (
                <Portal container={container.current}>
                    <Nudge
                        variant="bottom"
                        type="addToWorkflow"
                        text={"Finally, start outreach <br />from here! ✅"}
                        wrapperStyle={{
                            position: "absolute",
                            bottom: "110%",
                            left: "60%",
                            minWidth: 220,
                            textAlign: "left",
                            cursor: "default",
                        }}
                        arrowStyle={{
                            margin: "0 1.5rem 0.75rem",
                        }}
                    />
                </Portal>
            )}
        </>
    );
}
