import CancelIcon from "@mui/icons-material/Cancel";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Box from "@mui/joy/Box";
import Grid from "@mui/joy/Grid";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import LinearProgress from "@mui/joy/LinearProgress";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { useSuperInboxContext } from "../../Context";
import { FilterDrawer } from "../filters/FilterDrawer";

function SearchInput() {
    const {
        filters: { setSearchQuery },
    } = useSuperInboxContext();
    const [query, setQuery] = useState("");

    const debouncedSetSearchQuery = useCallback(debounce(setSearchQuery, 500), []);

    useEffect(() => {
        debouncedSetSearchQuery(query);
    }, [query]);

    return (
        <Input
            size="sm"
            startDecorator={<SearchRoundedIcon />}
            endDecorator={
                query ? (
                    <IconButton onClick={() => setQuery("")}>
                        <CancelIcon />
                    </IconButton>
                ) : null
            }
            placeholder="Search"
            aria-label="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
        />
    );
}

export function ChatsPaneHeader() {
    const { totalCandidates, isFetchingCandidates, isLoadingCandidates } = useSuperInboxContext();

    return (
        <Stack>
            <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                p={2}
                pb={1.5}
            >
                <Grid xs={10}>
                    <Typography
                        fontSize={{ xs: "md", md: "lg" }}
                        component="h1"
                        fontWeight="lg"
                        sx={{ mr: "auto" }}
                    >
                        Messages
                    </Typography>
                    <Typography fontSize={11} component={"i"}>
                        <b>{totalCandidates}</b> conversations found
                    </Typography>
                </Grid>
                <Grid xs={2} display="flex" justifyContent="end" alignItems="center">
                    <FilterDrawer />
                </Grid>
            </Grid>
            <Box sx={{ px: 2, pb: 1.5 }}>
                <SearchInput />
            </Box>
            <Box height={4}>{!isLoadingCandidates && isFetchingCandidates && <LinearProgress variant="plain" />}</Box>
        </Stack>
    );
}
