import { useDispatch, useSelector } from "react-redux";

import ConfirmationModal from "../../../components/ConfirmationModal";
import { selectDeleteContactListModal, setModal } from "../../../store/reducers/modals.slice";
import { deleteContactList, selectContactListIdForProject } from "../contact-list.slice";

export default function DeleteContactListModal() {
    const dispatch = useDispatch();
    const open = useSelector(selectDeleteContactListModal);
    const contactListId = useSelector(selectContactListIdForProject);

    const onClose = () => dispatch(setModal({ key: "showDeleteContactListModal", value: false }));

    if (!open) {
        return null;
    }

    const handleSubmit = () => dispatch(deleteContactList([contactListId]));

    return (
        <ConfirmationModal
            title="Are you sure you want to delete?"
            open={open}
            onClose={onClose}
            submit={handleSubmit}
            submitText="Delete"
        />
    );
}
