import Box from "@mui/joy/Box";

import Header from "./components/Header";
import {Inbox} from "./components/Inbox";
import SuperInboxContextProvider from "./Context";

import { ErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { JoyProvider } from "../../components/JoyProvider";
import Navbar from "../../components/Navbar/Navbar";

// import FilterPopover from "./components/FilterPopover";

export function SuperInbox() {
    return (
        <>
            <ErrorBoundary>
                <SuperInboxContextProvider>
                    <Box sx={{ display: { lg: "block", xs: "none" } }}>
                        <Navbar />
                    </Box>
                    <JoyProvider>
                        <Box sx={{ display: "flex" }}>
                            <Header />
                            <Box component="main" className="MainContent" sx={{ flex: 1 }}>
                                <Inbox />
                            </Box>
                        </Box>
                    </JoyProvider>{" "}
                    {/* <FilterPopover /> */}
                </SuperInboxContextProvider>
            </ErrorBoundary>
        </>
    );
}
