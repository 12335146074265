import { useLayoutEffect, useState } from "react";

type IWindow = {
    width: number;
    height: number;
};

export default function useWindowSize() {
    const [size, setSize] = useState<IWindow>({
        width: 0,
        height: 0,
    });

    useLayoutEffect(() => {
        function updateSize() {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}
