import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { ModalClose } from "@mui/joy";
import Box from "@mui/joy/Box";
import DialogTitle from "@mui/joy/DialogTitle";
import IconButton from "@mui/joy/IconButton";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Tooltip from "@mui/joy/Tooltip";
import { Experimental_CssVarsProvider } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useFetchCandidateDetailsQuery } from "../../../store/apis/all-candidates/all-candidates.api";
import {
    EditActivityForm,
    ProjectOption,
} from "../../all-candidates/components/ContactOverviewActivities/EditActivityForm";
import { useSuperInboxContext } from "../Context";

type AddActivityNotesProps = {
    projects: ProjectOption[];
};

export function AddActivityNotes({ projects }: AddActivityNotesProps) {
    const { t } = useTranslation();
    const { candidateParamId } = useSuperInboxContext();
    const {
        data: defaultProfile,
        isLoading: isFetchCandidateDetailsLoading,
        isFetching,
    } = useFetchCandidateDetailsQuery(candidateParamId);
    // console.log({ name: defaultProfile?.name, candidateParamId, projects });
    const [showActivityCreateModal, setShowActivityCreateModal] = useState(false);

    const isLoading = isFetching || isFetchCandidateDetailsLoading;

    return (
        <>
            <Tooltip title={t("superInbox.acitivityLogInfo")} arrow>
                <IconButton
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => setShowActivityCreateModal(true)}
                    sx={{
                        marginLeft: "auto",
                        height: "fit-content",
                        width: "fit-content",
                        alignSelf: "center",
                        marginRight: "0.3rem",
                    }}
                    size="sm"
                >
                    <NoteAddOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            {showActivityCreateModal && (
                <Modal open={showActivityCreateModal} onClose={() => setShowActivityCreateModal(false)}>
                    <ModalDialog sx={{ width: "30vw" }}>
                        <ModalClose />
                        <Box display="flex" alignItems="center">
                            <DialogTitle>Create Activity</DialogTitle>
                            <Tooltip
                                arrow
                                sx={{
                                    "&.MuiTooltip-root": {
                                        maxWidth: "300px",
                                    },
                                }}
                                title="Create a note or activity for this candidate. View this on your 'Contact Overview' page, under 'Teams' view"
                            >
                                <IconButton size="sm">
                                    <InfoRoundedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Experimental_CssVarsProvider>
                            <EditActivityForm
                                closeEdit={() => setShowActivityCreateModal(false)}
                                variant="SUPER_INBOX_ACTIVITY_CREATE"
                                selectedCandidate={{
                                    label: defaultProfile?.name ?? "",
                                    value: candidateParamId,
                                }}
                                projects={projects}
                            />
                        </Experimental_CssVarsProvider>
                    </ModalDialog>
                </Modal>
            )}
        </>
    );
}
