import { Badge, Stack, Switch, Tooltip, Typography } from "@mui/joy";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CLAUDELOGO from "../../../../assets/img/ai-providers-logo/claude-logo.png";
import GEMINILOGO from "../../../../assets/img/ai-providers-logo/gemini-logo.png";
import GPTLOGO from "../../../../assets/img/ai-providers-logo/gpt-logo.jpeg";
import METALOGO from "../../../../assets/img/ai-providers-logo/meta-logo.webp";
import PERPLEXITYLOGO from "../../../../assets/img/ai-providers-logo/perplexity-logo.png";
import EASYSOURCELOGO from "../../../../assets/img/email-providers-logo/easysource-logo.jpeg";
import { JoyProvider } from "../../../../components/JoyProvider";
import { TeamsTable } from "../../../manageTeams/components/TeamsTable";
import { checkEasySource, selectUser, updateUser } from "../../../Signin/Signin.reducer";
import { AICONTROL_KEYS } from "../../../Signin/Signin.types";

const FREE_AI_MODELS = (isEasySource: boolean) => {
    return [
        {
            key: isEasySource ? "easysource-ai" : "easygrowth-ai",
            modelName: isEasySource ? "EasySource AI" : "EasyGrowth AI",
            modelNameSuperscript: "TM-2",
            modelNameTooltip:
                "Proprietary LLM developed by HireQuotient in 2021 specifically for hiring intelligence and recruitment-specific cases",
            pricing: "0.006",
            pricingSuperscript: "Included",
            logo: EASYSOURCELOGO,
        },

        {
            key: "gpt4-turbo",
            modelName: "GPT 4 (Turbo-16k)",

            modelNameTooltip: "OpenAI's flagship AI model, capable of high accuracy and speed",
            pricing: "0.002",
            pricingSuperscript: "Free",
            logo: GPTLOGO,
        },
        {
            key: "gpt-omeo",
            modelName: "GPT 4o (Turbo-16k)",
            modelNameTooltip: "OpenAI's most recent model, faster and more intelligent than any previous model",

            pricing: "0.002",
            pricingSuperscript: "Free",
            logo: GPTLOGO,
        },
        {
            key: "gpt3.5-turbo",
            modelName: "GPT 3.5 (Turbo-16k)",
            modelNameTooltip: "",
            pricing: "0.0005",
            pricingSuperscript: "Free",
            logo: GPTLOGO,
        },
    ];
};

const ADDON_AI_MODELS = [
    {
        key: "claude3",
        modelName: "Claude 3.0 (limited access)",
        modelNameTooltip: "",
        pricing: "0.002",
        pricingSuperscript: "Free (limited)",
        logo: CLAUDELOGO,
    },
    {
        key: "llama3",
        modelName: "Llama 3",
        modelNameSuperscript: "3",
        modelNameTooltip: "",
        pricing: "0.004",
        pricingSuperscript: "Free (limited)",
        logo: METALOGO,
    },
    {
        key: "gemini",
        modelName: "Gemini",
        modelNameTooltip: "",
        pricing: "0.0005",
        pricingSuperscript: "Free (limited)",
        logo: GEMINILOGO,
    },
    {
        key: "perplexity",
        modelName: "Perplexity AI",
        modelNameTooltip: "",
        pricing: "0.0004",
        pricingSuperscript: "Free (limited)",
        logo: PERPLEXITYLOGO,
    },
];

const About = () => {
    const { t } = useTranslation();
    return (
        <Stack paddingTop={2}>
            <Accordion defaultExpanded={true}>
                <AccordionSummary>
                    <Typography
                        paddingY={2}
                        width="100%"
                        level="body-sm"
                        fontWeight={500}
                        paddingBottom={1}
                        sx={{ color: "black" }}
                    >
                        {t("aiControl.aboutHeading")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack paddingY={3} paddingBottom={4} gap={3}>
                        <Typography level="body-sm">{t("aiControl.aboutPara1")}</Typography>
                        <Typography level="body-sm">{t("aiControl.aboutPara2")}</Typography>
                        <Typography level="body-sm">{t("aiControl.aboutPara3")}</Typography>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};

const ModelNameTooltipWrapper = ({
    modelName,
    modelNameTooltip,
    modelNameSuperscript,
    logo,
}: {
    modelNameSuperscript?: string;
    modelName: string;
    modelNameTooltip: string;
    logo: string;
}) => {
    if (!modelNameTooltip) {
        return (
            <Stack gap={1} direction="row" alignItems={"center"}>
                <img src={logo} style={{ objectFit: "cover", width: "25px", height: "25px" }} />
                <Typography level="body-xs">
                    {modelName} <sup>{modelNameSuperscript}</sup>
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack gap={1} direction="row">
            <img src={logo} style={{ objectFit: "cover", width: "25px", height: "25px" }} />
            <Tooltip title={modelNameTooltip}>
                <Typography level="body-xs">
                    {modelName} <sup>{modelNameSuperscript}</sup>
                </Typography>
            </Tooltip>
        </Stack>
    );
};

const ModelPricing = ({ pricing, pricingSuperscript }: { pricing: string; pricingSuperscript: string }) => {
    return (
        <Stack direction={"row"} gap={0.5}>
            <Typography level="body-xs">{pricing}</Typography>
            <Box
                sx={{
                    paddingY: 0.1,
                    paddingX: 1,
                    background: pricingSuperscript === "Included" ? "#E9ECEF" : "#C1F5C9",
                    borderRadius: "20000px",
                }}
            >
                <Typography level="body-xs">{pricingSuperscript}</Typography>
            </Box>
        </Stack>
    );
};

const AIControls = () => {
    const dispatch = useDispatch();
    const { aiControls } = useSelector(selectUser);
    const isEasySource = useSelector(checkEasySource);
    const { t } = useTranslation();
    return (
        <Stack>
            <Accordion defaultExpanded={true}>
                <AccordionSummary>
                    <Typography
                        paddingY={2}
                        width="100%"
                        level="body-sm"
                        fontWeight={500}
                        paddingBottom={1}
                        sx={{
                            color: "black",
                        }}
                    >
                        Set controls
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack paddingY={3}>
                        <TeamsTable headers={["AI model", "Control", "Pricing ($/1000 tokens) <sup>4</sup>"]}>
                            <tr style={{ background: "#F0F4F8" }}>
                                <td>
                                    <Typography level="body-xs">
                                        Included by default <sup>1</sup>
                                    </Typography>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            {FREE_AI_MODELS(isEasySource).map((i) => {
                                const {
                                    key,
                                    modelName,
                                    modelNameTooltip,
                                    pricing,
                                    pricingSuperscript,
                                    modelNameSuperscript,
                                    logo,
                                } = i;
                                return (
                                    <tr key={key}>
                                        <td>
                                            <ModelNameTooltipWrapper
                                                modelName={modelName}
                                                modelNameTooltip={modelNameTooltip}
                                                modelNameSuperscript={modelNameSuperscript}
                                                logo={logo}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip title="Activated by default">
                                                <Switch color="success" variant="outlined" checked={true} />
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <ModelPricing pricing={pricing} pricingSuperscript={pricingSuperscript} />
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr style={{ background: "#F0F4F8" }}>
                                <td>
                                    <Typography level="body-xs">Add-on AI models</Typography>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            {ADDON_AI_MODELS.map((i) => {
                                const {
                                    key,
                                    modelName,
                                    modelNameTooltip,
                                    pricing,
                                    pricingSuperscript,
                                    modelNameSuperscript,
                                    logo,
                                } = i;
                                const isActive = aiControls?.[key as AICONTROL_KEYS];
                                return (
                                    <tr key={key}>
                                        <td style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                                            <ModelNameTooltipWrapper
                                                modelName={modelName}
                                                modelNameTooltip={modelNameTooltip}
                                                modelNameSuperscript={modelNameSuperscript}
                                                logo={logo}
                                            />
                                        </td>
                                        <td>
                                            <Switch
                                                onChange={(e) => {
                                                    const newControls = { ...aiControls, [key]: e.target.checked };
                                                    // @ts-ignore
                                                    dispatch(updateUser({ aiControls: newControls }));
                                                }}
                                                checked={isActive}
                                                color={isActive ? "success" : "neutral"}
                                                sx={{
                                                    ...(!isActive && {
                                                        "& .MuiSwitch-thumb": {
                                                            "--Switch-thumbBackground": "grey !important",
                                                        },
                                                    }),
                                                }}
                                                variant="outlined"
                                            />
                                        </td>
                                        <td>
                                            <ModelPricing pricing={pricing} pricingSuperscript={pricingSuperscript} />
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td></td>
                                <td>
                                    <Typography fontWeight={500} level="body-sm">
                                        Billing
                                    </Typography>
                                </td>
                                <td style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
                                    <Stack direction={"row"} gap={0.5}>
                                        <Box
                                            sx={{
                                                paddingY: 0.1,
                                                paddingX: 1,
                                                background: "#C1F5C9",
                                                borderRadius: "20000px",
                                            }}
                                        >
                                            <Typography level="body-xs">Free (limited)</Typography>
                                        </Box>
                                    </Stack>
                                    <Typography paddingTop={0.5} level="body-xs">
                                        Since 1st June 2024
                                    </Typography>
                                </td>
                            </tr>
                        </TeamsTable>
                    </Stack>
                    <Typography paddingY={2} paddingBottom={4} level="body-sm" maxWidth={"90%"}>
                        {t("aiControl.billingInfo")}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};

const AdditionalInfo = () => {
    const { t } = useTranslation();
    const INFO: any[] = [
        t("aiControl.additionalInfo1"),
        t("aiControl.additionalInfo2"),
        t("aiControl.additionalInfo3"),
        t("aiControl.additionalInfo4"),
        t("aiControl.additionalInfo5"),
    ];

    return (
        <Stack>
            <Accordion defaultExpanded={true}>
                <AccordionSummary>
                    <Typography
                        paddingY={2}
                        width="100%"
                        level="body-sm"
                        fontWeight={500}
                        paddingBottom={1}
                        sx={{
                            color: "black",
                        }}
                    >
                        Information
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack paddingY={3} paddingBottom={4} gap={2}>
                        {INFO.map((item, index) => {
                            return (
                                <Typography key={index} level="body-sm">
                                    {index + 1}. {item}
                                </Typography>
                            );
                        })}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Typography padding={1.5} paddingTop={4} paddingBottom={5} level="body-sm">
                Have any questions? Contact your account manager or reach out on {" "}
                <a style={{ color: "#2296f3", textDecoration: "none" }} href="mailto:customer-support@hirequotient.com">
                    customer-support@hirequotient.com
                </a>
            </Typography>
        </Stack>
    );
};

const AI = () => {
    return (
        <JoyProvider>
            <Stack
                sx={{
                    height: "100%",
                }}
            >
                <Stack
                    sx={{
                        height: "calc(100vh - 150px)",
                        overflowY: "scroll",
                    }}
                >
                    <Stack sx={{ marginLeft: "auto", marginRight: "auto", maxWidth: "75%" }}>
                        <AccordionGroup>
                            <About />
                            <AIControls />
                            <AdditionalInfo />
                        </AccordionGroup>
                    </Stack>
                </Stack>
            </Stack>
        </JoyProvider>
    );
};

export default AI;
