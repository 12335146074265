import ListItem, { ListItemProps } from "@mui/material/ListItem";
import { DataCardProps } from "./DataCard";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import { tooltipSetting } from "../../utils";
import { ReactNode } from "react";

interface ListItemComponentProps extends Omit<DataCardProps, "title"> {
    active?: boolean;
    title?: ReactNode;
}

export default function ListItemComponent(props: ListItemComponentProps & Omit<ListItemProps, "title">) {
    const { children, disableHoverEffect, active, loading = "false", title, sx, ...rest } = props;

    const commonStyles = {
        alignItems: "center",
        justifyContent: "space-between",
    };

    const hoverEffect = {
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.05)",
        },
        transition: "background-color .5s",
    };

    if (loading === "true") return <Skeleton height={40} width={"100%"} animation="wave" />;

    return (
        <Tooltip {...tooltipSetting} followCursor title={title}>
            <ListItem
                sx={{
                    ...(active && { backgroundColor: "rgba(0,0,0,0.1)" }),
                    ...commonStyles,
                    ...(!disableHoverEffect && !active && hoverEffect),
                    ...sx,
                }}
                {...rest}
            >
                {children}
            </ListItem>
        </Tooltip>
    );
}
