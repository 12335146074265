import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { useRef } from "react";

import styles from "./chromeExtension.module.scss";

import useMinimizeSidebarItems from "../../hooks/useMinimizeSidebarItems";
import { EXTENSION_LINK } from "../../utils/Constants";

type ChromeExtensionProps = {
    variant?: "default" | "low";
};

export default function ChromeExtension({ variant = "default" }: ChromeExtensionProps) {
    const container = useRef<HTMLAnchorElement | null>(null);
    const shouldMinimize = useMinimizeSidebarItems();

    return (
        <a
            ref={container}
            className={
                variant === "low"
                    ? styles["blue__btn"]
                    : `${styles["chrome__btn"]} ${shouldMinimize ? styles["chrome__btn--text"] : null}`
            }
            href={EXTENSION_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textAlign: "center" }}
        >
            {shouldMinimize ? <DownloadForOfflineRoundedIcon fontSize="large" /> : "Install Chrome Extension"}
        </a>
    );
}
