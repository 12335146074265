import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store";

const initialState = Object.freeze({
    rb2bwebhookUrl: "",
});

const reducers = {
    getRb2bWebhookUrl(state: any, action: PayloadAction<any>) {},
    setRb2bWebhookUrl(state: any, action: PayloadAction<any>) {
        state.rb2bwebhookUrl = action.payload;
        console.log("GET RB2B Webhook url internal:: ", state.users);
    },
    leavePage(state: any) {},
};

const page = createSlice({
    name: "internalRB2B",
    initialState,
    // @ts-ignore
    reducers,
});

export default page.reducer;
export const { getRb2bWebhookUrl, setRb2bWebhookUrl, leavePage } = page.actions;

export const selectRb2bWebhookUrl = (state: RootState) => state.internalRB2B.rb2bwebhookUrl;
