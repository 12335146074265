import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ConfirmationModal from "../../../../components/ConfirmationModal";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { useDeleteContactOverviewActivityMutation } from "../../../../store/apis/all-candidates/all-candidates.api";
import { checkEasySource } from "../../../Signin/Signin.reducer";

export function DeleteActivityButton({ id }: { id: string }) {
    const isEasySource = useSelector(checkEasySource);
    const navigate = useNavigate();

    const handleNavigate = () => {
        const url = isEasySource
            ? `/contact-overview/activities/delete/${id}`
            : `/manage-prospects/activities/delete/${id}`;
        navigate(url);
    };

    return (
        <Tooltip title="Delete">
            <IconButton color="error" onClick={handleNavigate} size="small" sx={{ width: "fit-content" }}>
                <DeleteIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
            </IconButton>
        </Tooltip>
    );
}

export function DeleteActivity() {
    const dispatch = useDispatch();
    const { id = "" } = useParams<{ id: string }>();
    const [deleteActivities, { isLoading }] = useDeleteContactOverviewActivityMutation();

    const navigate = useNavigate();
    const onClose = () => navigate(-1);
    const handleSubmit = () => {
        deleteActivities({ activityId: id })
            .unwrap()
            .catch((_) => {
                dispatch(setErrorNotification("Unexpected error while deleting activity. Please try again later."));
            });
    };
    return (
        <ConfirmationModal
            title="Are you sure you want to delete?"
            open={true}
            onClose={onClose}
            submit={handleSubmit}
            submitText="Delete"
            isSubmitDisabled={isLoading}
        />
    );
}
