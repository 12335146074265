import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import SaveIcon from "@mui/icons-material/Save";
import { Input } from "@mui/joy";
import CssBaseline from "@mui/joy/CssBaseline";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import { CssVarsProvider } from "@mui/joy/styles";
import Typography from "@mui/joy/Typography";
import { Experimental_CssVarsProvider, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SaveTemplate from "./SaveTemplate";

import useDisclosure from "../../../hooks/useDisclosure";
import { RootState } from "../../../store";
import { useFetchTemplatesQuery } from "../../../store/apis/outreach/outreach.api";
import { EVENT_KEYS } from "../../../store/apis/outreach/outreach.enums";
import { FetchTemplatesResponseData } from "../../../store/apis/outreach/outreach.types";
import { checkEasySource } from "../../Signin/Signin.reducer";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { checkErrorInOutreachElement, editOutreachElement, selectCurrentOutreachElementType } from "../outreach.slice";

export const EVENT_KEYS_TEMPLATE_TYPE_MAP = {
    email: "EMAIL",
    "follow-up": "FOLLOW_UP",
    SMS: "SMS",
    linkedin: "LINKEDIN",
} as const;

export const TEMPLATE_TYPE_EVENT_KEYS_MAP = {
    EMAIL: "email",
    FOLLOW_UP: "follow-up",
    SMS: "SMS",
    LINKEDIN: "linkedin",
} as const;

type TemplateTypes = "EMAIL" | "FOLLOW_UP" | "SMS" | "LINKEDIN";

function TemplatesMenu({
    data,
    type,
    isOpen,
    onClose,
}: {
    data: FetchTemplatesResponseData[];
    type: TemplateTypes;
    isOpen: boolean;
    onClose: () => void;
}) {
    const [searchQuery, setSearchQuery] = useState("");

    const { currentOutreachElementId } = useOutreachParams();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const dispatch = useDispatch();

    const dispatchInMailSubject = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                inMailSubject: value,
            })
        );
    };
    const dispatchInmailFollowupSubject = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                inmailFollowupSubject: value,
            })
        );
    };
    const dispatchInMailBody = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                inMailBody: value,
            })
        );
    };
    const dispatchConnectionReminderMessageBody = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                connectionReminderMessageBody: value,
            })
        );
    };
    const dispatchInMailFollowupBody = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                inmailFollowupBody: value,
            })
        );
    };
    const dispatchSubject = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                subject: value,
            })
        );
    };
    const dispatchBody = (value: string) => {
        dispatch(
            editOutreachElement({
                id: currentOutreachElementId,
                body: value,
            })
        );
    };

    const handleTemplateChange = ({
        newId,
        subject,
        body,
        inMailSubject,
        inMailBody,
        connectionReminderMessageBody,
        inmailFollowupBody,
        inmailFollowupSubject,
    }: {
        newId: string;
        body: string;
        subject?: string;
        inMailSubject?: string;
        inMailBody?: string;
        connectionReminderMessageBody?: string;
        inmailFollowupBody?: string;
        inmailFollowupSubject?: string;
    }) => {
        dispatchBody(body);
        if (subject) {
            dispatchSubject(subject);
        }
        if (inMailSubject || currentElementType === "linkedin") {
            dispatchInMailSubject(inMailSubject || "");
        }
        if (inMailBody) {
            dispatchInMailBody(inMailBody);
        }
        if (connectionReminderMessageBody) {
            dispatchConnectionReminderMessageBody(connectionReminderMessageBody);
        }
        if (inmailFollowupBody) {
            dispatchInMailFollowupBody(inmailFollowupBody);
        }
        if (inmailFollowupSubject) {
            dispatchInmailFollowupSubject(inmailFollowupSubject);
        }
        onClose();
    };
    const isEasySource = useSelector(checkEasySource);
    const menuItem = data.filter((item) => {
        return (
            item.type === type && (searchQuery === "" || item.name.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    });

    return (
        <Modal open={isOpen} onClose={onClose} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Sheet
                variant="outlined"
                sx={{
                    width: 550,
                    borderRadius: "md",
                    py: 2,
                    boxShadow: "lg",
                }}
            >
                <Stack sx={{ px: 3, pb: 2 }} gap={1.5}>
                    <Input
                        placeholder="Search template"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {menuItem.length !== 0 && (
                        <Typography level="body-sm">
                            {searchQuery === ""
                                ? `You have ${menuItem.length} saved templates in your  ${isEasySource ? `EasySource` : `EasyGrowth`}
                                 workspace`
                                : `You have ${menuItem.length} saved tempates that matches you search`}
                        </Typography>
                    )}
                </Stack>

                <Stack sx={{ overflowY: "scroll", maxHeight: "500px" }}>
                    {menuItem.length === 0 ? (
                        <Typography sx={{ paddingY: "0.5rem", px: 3 }}>No templates found</Typography>
                    ) : (
                        menuItem.map((item) => {
                            return (
                                <Stack
                                    sx={{
                                        ":hover": {
                                            background: "#dfdfdf",
                                            cursor: "pointer",
                                        },
                                        paddingY: "0.5rem",
                                        px: 3,
                                        gap: 0.25,
                                    }}
                                >
                                    <Typography
                                        level="body-md"
                                        key={item._id}
                                        sx={{
                                            fontWeight: 500,
                                        }}
                                        onClick={() =>
                                            handleTemplateChange({
                                                newId: item._id,
                                                body: item.body,
                                                subject: item.subject,
                                                inMailSubject: item.inMailSubject,
                                                inMailBody: item.inMailBody,
                                                connectionReminderMessageBody: item.connectionReminderMessageBody,
                                                inmailFollowupBody: item.inmailFollowupBody,
                                                inmailFollowupSubject: item.inmailFollowupSubject,
                                            })
                                        }
                                    >
                                        {item.name}
                                    </Typography>
                                    <Stack sx={{ width: "100%", justifyContent: "space-between" }} direction="row">
                                        <Typography fontStyle={"italic"} sx={{ fontSize: "12px" }} level="body-sm">
                                            Created by {item.user.name}
                                        </Typography>
                                        <Typography fontStyle={"italic"} sx={{ fontSize: "12px" }} level="body-sm">
                                            Last updated on {dayjs(item.updatedAt).format("MMMM D, YYYY h:mm A")}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            );
                        })
                    )}
                </Stack>
            </Sheet>
        </Modal>
    );
}

export const FetchFromTemplate = ({ type }: { type: EVENT_KEYS }) => {
    const { currentOutreachElementId, projectId } = useOutreachParams();
    const { data, isFetching, refetch } = useFetchTemplatesQuery();
    const { isOpen: isOpenSaveTemplate, onOpen: onOpenSaveTemplate, onClose: onCloseSaveTemplate } = useDisclosure();
    const { isOpen: isOpenTemplateMenu, onOpen: onOpenTemplateMenu, onClose: onCloseTemplateMenu } = useDisclosure();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const isErrorInOutreachElement = useSelector((state: RootState) =>
        checkErrorInOutreachElement(state, currentOutreachElementId)
    );

    if (isFetching || !data) return <></>;
    return (
        <CssVarsProvider>
            <CssBaseline />
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                <Experimental_CssVarsProvider>
                    <Tooltip title="Save as Template">
                        <div>
                            <IconButton
                                sx={{ padding: 0 }}
                                disabled={isErrorInOutreachElement}
                                onClick={onOpenSaveTemplate}
                            >
                                <SaveIcon />
                            </IconButton>
                        </div>
                    </Tooltip>
                    <Tooltip title="Copy from templates">
                        <div>
                            <IconButton sx={{ padding: 0 }} disabled={false} onClick={onOpenTemplateMenu}>
                                <AssignmentReturnedIcon />
                            </IconButton>
                        </div>
                    </Tooltip>
                </Experimental_CssVarsProvider>
            </Stack>

            {isOpenSaveTemplate && (
                <SaveTemplate
                    templateList={data.filter((item) => TEMPLATE_TYPE_EVENT_KEYS_MAP[item.type] === currentElementType)}
                    isOpen={isOpenSaveTemplate}
                    onClose={onCloseSaveTemplate}
                    refetch={refetch}
                />
            )}
            {isOpenTemplateMenu && (
                <TemplatesMenu
                    isOpen={isOpenTemplateMenu}
                    onClose={onCloseTemplateMenu}
                    type={EVENT_KEYS_TEMPLATE_TYPE_MAP[type]}
                    data={data}
                />
            )}
        </CssVarsProvider>
    );
};
