import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../Store";

export type IActionPayload = {
    action?: string;
    authToken?: string;
    avoidGetStats?: boolean;
    _id?: string;
    search?: string;
};

export type IGetTwilloPowerCallListCreatePayload = {
    navigate: (path: string) => void;
    payload: any;
};

export type IEmailType = {
    action: string;
    emailType: string;
};

type ILoaderState = {
    actions: string[];
};

const initialState: ILoaderState = {
    actions: [],
};

const loadersSlice = createSlice({
    name: "loaders",
    initialState,
    reducers: {
        startAction: (state: ILoaderState, { payload }: { payload: { action: string } }) => {
            state.actions = state.actions.concat(payload.action);
        },
        stopAction: (state: ILoaderState, { payload }: { payload: { action: string } }) => {
            state.actions = state.actions.filter((t) => t !== payload.action);
        },
    },
});

export const startAction = loadersSlice.actions.startAction;
export const stopAction = loadersSlice.actions.stopAction;
export const checkIfLoading = (type: string) => (state: RootState) => state.loaders.actions.includes(type);

export default loadersSlice.reducer;
