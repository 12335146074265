import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import InfoIcon from "@mui/icons-material/Info";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { FormHelperText, MenuItem, Select, SelectChangeEvent, Switch, styled } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { debounce } from "lodash";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import CopyTemplate from "./CopyTemplate";

import CustomButton from "../../../components/CustomButton";
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor";
import { RootState, useAppDispatch } from "../../../store";
import { MAX_LINKEDIN_CONN_REQ_CHAR_COUNT, USER_MAX_LINKEDIN_REQ_CHART_COUNT } from "../../../utils/Constants";
import { calculateQuillCharacterCount, objectKeys } from "../../../utils/helper";
import CustomTextField from "../../triggerWorkflow/components/CustomTextField";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import templatePreviewStyles from "../outreach.module.css";
import { useTemplateEditorValues } from "../TemplateEditorProvider";

import { ErrorTypes } from "@/store/apis/outreach/outreach.types";
import {
    changeCurrentEditor,
    checkEmailPresentInOutreachFlow,
    checkErrorsInInMailReminderMessage,
    checkErrorsInReminderMessage,
    editOutreachElement,
    selectCurrentOutreachElementType,
    selectOutReachFlow,
    selectOutreachErrors,
    selectOutreachTemplateContent,
    setConnectionStatus,
    setInMailStatus,
    setReminderMessageStatus,
    setinmailFollowupStatus,
    toggleCurrentOutreachStep,
} from "@/store/reducers/outreach/outreach.slice";
import { checkRecruiterInMail, isSendGridEnabled, selectUser, selectUserOrgId } from "@/store/reducers/signin/Signin.reducer";
import { AccountType } from "@/store/reducers/signin/Signin.types";

const ErrorTypography = styled((props: TypographyProps) => <Typography variant="body2" {...props} />)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
}));

const WarningTypography = styled((props: TypographyProps) => <Typography variant="body2" {...props} />)(
    ({ theme }) => ({
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.warning.main,
    })
);

function ErrorMessage({ text }: { text: string }) {
    return (
        <Stack direction="row" alignItems="center" gap={0.7} mt={1}>
            <WarningRoundedIcon color="error" sx={{ fontSize: "1.3rem", alignSelf: "flex-start" }} />
            <ErrorTypography
                variant="body2"
                sx={(theme) => ({
                    color: theme.palette.error.main,
                })}
            >
                {text}
            </ErrorTypography>
        </Stack>
    );
}

export function BaseTemplates() {
    const outreach = useSelector(selectOutReachFlow);
    const orgId = useSelector(selectUserOrgId);
    const isRecruiterInMail = useSelector(checkRecruiterInMail);
    const { currentOutreachElementId, isHyperPersonalizedView } = useOutreachParams();
    const { editor1, editor2, editor3, editor4, editor5 } = useTemplateEditorValues();
    const reduxState = useSelector((state: RootState) => state);
    const dispatch = useAppDispatch();
    const template =
        useSelector((state: RootState) => selectOutreachTemplateContent(state, currentOutreachElementId)) ?? {};
    const elementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const setAddTo = (currentEditor: "editor1" | "editor2" | "editor3" | "editor4" | "editor5" | null) => {
        dispatch(changeCurrentEditor(currentEditor));
    };

    dispatch(toggleCurrentOutreachStep(elementType));
    const isSubjectPresent = "subject" in template;
    const inMailSubjectPresent = "inMailSubject" in template;
    const inMailBodyPresent = "inMailBody" in template;

    const isFollowup = elementType === "follow-up";
    const isLinkedin = elementType === "linkedin";
    const isEmailError = elementType === "email" && template.body?.includes("This is a sample template");

    const [subject, setSubject] = useState(() => (isSubjectPresent ? template.subject : ""));
    const [body, setBody] = useState(() => template.body);

    const [inMailSubject, setInMailSubject] = useState(() => (inMailSubjectPresent ? template.inMailSubject : ""));
    const [inMailBody, setInMailBody] = useState(() => (inMailBodyPresent ? template.inMailBody : ""));

    const user = useSelector(selectUser);
    const errors = useSelector((state: RootState) =>
        selectOutreachErrors(state, {
            currentOutreachElementId,
            accountType: user?.userLiProfile?.accountType,
        })
    );

    const isEmailPresent = useSelector((state: RootState) =>
        checkEmailPresentInOutreachFlow(state, isHyperPersonalizedView)
    );

    // HATE THIS BUT REQUIRED
    useEffect(() => {
        if (!template) return;
        if (isSubjectPresent) {
            setSubject(template.subject);
        }
        if (inMailBodyPresent) {
            setInMailBody(template.inMailBody);
        }
        if (inMailSubjectPresent) {
            setInMailSubject(template.inMailSubject);
        }
        setBody(template.body);
    }, [template]);

    const dispatchInMailBody = useCallback(
        debounce((value: string, accountType?: AccountType) => {
            dispatch(
                editOutreachElement({
                    reduxState,
                    id: currentOutreachElementId,
                    inMailBody: value,
                    accountType,
                })
            );
        }, 500),
        [currentOutreachElementId]
    );

    const dispatchInMailSubject = useCallback(
        debounce((value: string, accountType?: AccountType) => {
            dispatch(
                editOutreachElement({
                    reduxState,
                    id: currentOutreachElementId,
                    inMailSubject: value,
                    accountType,
                })
            );
        }, 500),
        [currentOutreachElementId]
    );

    const dispatchSubject = useCallback(
        debounce((value: string) => {
            dispatch(
                editOutreachElement({
                    reduxState,
                    id: currentOutreachElementId,
                    subject: value,
                })
            );
        }, 500),
        [currentOutreachElementId]
    );

    const dispatchBody = useCallback(
        debounce((value: string, accountType?: AccountType) => {
            dispatch(
                editOutreachElement({
                    reduxState,
                    id: currentOutreachElementId,
                    body: value,
                    accountType,
                })
            );
        }, 500),
        [currentOutreachElementId]
    );

    const handleInMailBodyChange = (value: string) => {
        setInMailBody(value);
        dispatchInMailBody(value, user?.userLiProfile?.accountType);
    };

    const handleInMailSubjectChange = (value: string) => {
        setInMailSubject(value);
        dispatchInMailSubject(value);
    };

    const handleSubjectChange = (value: string) => {
        setSubject(value);
        dispatchSubject(value);
    };

    const handleBodyChange = (value: string) => {
        setBody(value);
        dispatchBody(value, user?.userLiProfile?.accountType);
    };

    const handleReminderMessageToggle = () => {
        dispatch(setReminderMessageStatus({ currentOutreachElementId }));
    };

    const handleInMailReminderMessageToggle = () => {
        dispatch(setinmailFollowupStatus({ currentOutreachElementId }));
    };

    const handleConnectionStatus = () => {
        if (!inMailStatus) return;
        dispatch(setConnectionStatus({ currentOutreachElementId }));
    };

    const handleInMailStatus = () => {
        if (!connectionStatus) return;
        dispatch(setInMailStatus({ currentOutreachElementId }));
    };

    const showCharacterCount = elementType === "SMS" || elementType === "linkedin";

    const connectionReminderMessageStatus =
        "connectionReminderMessageStatus" in template && template.connectionReminderMessageStatus === true;
    const inmailFollowupStatus = "inmailFollowupStatus" in template && template.inmailFollowupStatus === true;
    const showReminderMessage = elementType === "linkedin" && connectionReminderMessageStatus;
    const showInMailReminderMessage = elementType === "linkedin" && inmailFollowupStatus;
    const isSMS = elementType === "SMS";

    const connectionStatus =
        elementType === "linkedin" && "connectionStatus" in template && template.connectionStatus === true;
    const inMailStatus = elementType === "linkedin" && "inMailStatus" in template && template.inMailStatus === true;

    const userMaxLinkedinReqChartCount = user?.userLiProfile?.accountType
        ? USER_MAX_LINKEDIN_REQ_CHART_COUNT[user.userLiProfile.accountType]
        : MAX_LINKEDIN_CONN_REQ_CHAR_COUNT;

    const className = (small: boolean) => {
        if (small || isSMS) {
            return templatePreviewStyles.connReq;
        }
        if (isLinkedin) {
        }

        if (isSubjectPresent || isFollowup) {
            return templatePreviewStyles.templatePreviewBodyAndSubject;
        }

        return templatePreviewStyles.templatePreviewBody;
    };
    useEffect(() => {
        if (!isRecruiterInMail) {
            dispatch(setinmailFollowupStatus({ currentOutreachElementId, status: false }));
        }
    }, [isRecruiterInMail, currentOutreachElementId]);

    const characterLength = calculateQuillCharacterCount(body || "");

    const isErrorsInSubject = Object.keys(errors?.subject ?? {}).length > 0;
    const isErrorsInMailSubject = Object.keys(errors?.inMailSubject ?? {}).length > 0;
    const isSendGrid = useSelector(isSendGridEnabled);

    const hasLinkedinOnDay1 = outreach?.some(
        (item: any) => item?.eventName?.toLowerCase() === "linkedin" && item?.scheduledDay === 1
    );

    const visibility = outreach?.some((item: any) => item?.eventBody?.inMailStatus);
    return (
        <>
            <Stack paddingBottom={5} direction={"row"} width="100%">
                <Stack width="100%" p={2} pb={0}>
                    {isLinkedin && (
                        <Stack justifyContent={"space-between"} direction="row" pb={2}>
                            <Stack direction="row" alignItems={"center"} gap={1}>
                                <Typography>Connection request</Typography>
                                <Tooltip
                                    title={
                                        isRecruiterInMail
                                            ? "If enabled, connection requests will be sent out to people along with InMails from your LinkedIn Recruiter. An individual will thus receive both an InMail and a connection request from you"
                                            : "If enabled, connection requests will be sent to people on LinkedIn with your text message. Enable InMail as well to sender longer messages before connecting"
                                    }
                                >
                                    <InfoIcon sx={{ mr: 1, fontSize: "18px", color: "grey" }} />
                                </Tooltip>
                            </Stack>
                            <Switch size="small" checked={connectionStatus} onChange={handleConnectionStatus} />
                        </Stack>
                    )}
                    {isSubjectPresent ? (
                        <Tooltip
                            title={
                                isFollowup
                                    ? "Follow-up emails have the same subject as your first email. To edit this subject, click on the 'email' icon"
                                    : undefined
                            }
                            arrow
                        >
                            <span style={{ width: "100%" }}>
                                <CustomTextField
                                    onFocus={() => setAddTo("editor4")}
                                    error={isErrorsInSubject}
                                    disabled={isFollowup && !isSendGrid}
                                    FormHelperTextProps={{
                                        component: "span",
                                    }}
                                    helperText={
                                        errors?.subject ? (
                                            <Stack gap={0.5}>
                                                {Object.keys(errors.subject).map((i, idx) => {
                                                    const value = (errors?.subject ?? {})[i as ErrorTypes];

                                                    if (!value) {
                                                        return null;
                                                    }

                                                    // NOTE: using index otherwise subject error keys are identical to body errors
                                                    return <ErrorMessage text={value} key={idx} />;
                                                })}
                                            </Stack>
                                        ) : undefined
                                    }
                                    inputRef={editor4}
                                    label="Subject"
                                    sx={(theme) => ({
                                        width: "100%",
                                        backgroundColor: theme.palette.common.white,
                                        marginBottom: "0.7rem",
                                        "& .MuiInputBase-root": {
                                            padding: 0,
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "0.7rem",
                                            fontSize: theme.typography.pxToRem(14),
                                        },
                                        "& .MuiFormHelperText-root": {
                                            marginX: "0.1rem",
                                            marginY: "0.3rem",
                                        },
                                    })}
                                    value={subject}
                                    // @ts-ignore
                                    onInput={(e) => handleSubjectChange(e.target.value)}
                                />
                            </span>
                        </Tooltip>
                    ) : null}
                    <RichTextEditor
                        enableImageModule
                        readOnly={isLinkedin && !connectionStatus}
                        ref={editor1}
                        value={body?.replaceAll("\n", "<br>")}
                        className={className(isLinkedin ? true : false)}
                        onFocus={() => setAddTo("editor1")}
                        onChange={(value, _, source) => {
                            if (source === "user") {
                                handleBodyChange(value);
                            }
                        }}
                    />
                    {isEmailError && (
                        <Stack mt={1.5}>
                            <WarningTypography variant="body2">
                                Warning: Email template might be a sample template.
                            </WarningTypography>
                        </Stack>
                    )}

                    <Stack
                        mt={1.5}
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "auto 100px",
                            gridTemplateRows: "1fr",
                        }}
                    >
                        <Stack gap={0.5}>
                            {objectKeys({ ...errors?.body, ...errors?.orderingErrors }).map((i) => {
                                const value = { ...errors?.body, ...errors?.orderingErrors }[i];
                                if (!value || (isLinkedin && connectionStatus === false)) {
                                    return null;
                                }
                                return <ErrorMessage text={value} key={i} />;
                            })}
                        </Stack>
                        {showCharacterCount && (
                            <ErrorTypography
                                variant="body2"
                                sx={(theme) => ({
                                    alignSelf: "start",
                                    justifySelf: "end",
                                    color: "CHARACTER_LIMIT_ERROR" in errors.body ? theme.palette.error.light : "#000",
                                })}
                                fontWeight={"CHARACTER_LIMIT_ERROR" in errors.body ? 600 : 500}
                            >
                                {characterLength} / {userMaxLinkedinReqChartCount}
                            </ErrorTypography>
                        )}
                    </Stack>
                    {isLinkedin && (
                        <>
                            <Tooltip title="This message will be sent to every person who accepts your connection request but does not reply to you.">
                                <CustomButton
                                    variant="outlined"
                                    startIcon={
                                        showReminderMessage ? (
                                            <RemoveRoundedIcon fontSize="small" />
                                        ) : (
                                            <AddRoundedIcon fontSize="small" />
                                        )
                                    }
                                    size="small"
                                    sx={{
                                        margin: "1rem 0",
                                        borderRadius: "0.3rem",
                                        "&:focus": {
                                            boxShadow: "none",
                                        },
                                    }}
                                    onClick={handleReminderMessageToggle}
                                >
                                    {showReminderMessage ? "Disable" : "Enable"} reminder message
                                </CustomButton>
                            </Tooltip>
                            <Collapse in={showReminderMessage}>
                                <BaseTemplatesReminderMessage
                                    readOnly={isLinkedin && !connectionStatus}
                                    editorRef={editor3}
                                    type="connection"
                                />
                            </Collapse>
                        </>
                    )}
                </Stack>
                {isLinkedin && (
                    <Stack width="100%" p={2} pb={0}>
                        <Stack justifyContent={"space-between"} direction="row" pb={2}>
                            <Stack direction={"row"} alignItems={"center"} gap={1}>
                                <Typography>InMail </Typography>
                                <Tooltip
                                    title={
                                        isRecruiterInMail
                                            ? "InMails from your LI Recruiter account will be sent out to everyone (including your 1st degree connections). If connection requests are also enabled, both will be sent out together"
                                            : "If enabled, InMails will be sent to those who are open to receiving InMails on LinkedIn. Connection requests will be sent to others, if also enabled"
                                    }
                                >
                                    <InfoIcon sx={{ mr: 1, fontSize: "18px", color: "grey" }} />
                                </Tooltip>
                            </Stack>
                            <Stack direction={"row"} alignItems="center" gap={1}>
                                {isEmailPresent && <CopyTemplate />}
                                <Switch size="small" checked={inMailStatus} onChange={handleInMailStatus} />
                            </Stack>
                        </Stack>
                        <span style={{ width: "100%" }}>
                            <CustomTextField
                                disabled={isLinkedin && !inMailStatus}
                                error={inMailStatus && isErrorsInMailSubject}
                                onFocus={() => setAddTo("editor4")}
                                FormHelperTextProps={{
                                    component: "span",
                                }}
                                helperText={
                                    inMailStatus &&
                                    errors?.inMailSubject && (
                                        <Stack gap={1}>
                                            {Object.keys(errors.inMailSubject).map((i, idx) => {
                                                const value = (errors?.inMailSubject ?? {})[i as ErrorTypes];

                                                if (!value) {
                                                    return null;
                                                }

                                                // NOTE: using index otherwise subject error keys are identical to body errors
                                                return <ErrorMessage text={value} key={idx} />;
                                            })}
                                        </Stack>
                                    )
                                }
                                inputRef={editor4}
                                label="Subject"
                                sx={(theme) => ({
                                    width: "100%",
                                    backgroundColor: theme.palette.common.white,
                                    marginBottom: "0.7rem",
                                    "& .MuiInputBase-root": {
                                        padding: 0,
                                    },
                                    "& .MuiInputBase-input": {
                                        // padding: "0.7rem",
                                        fontSize: theme.typography.pxToRem(14),
                                    },
                                    "& .MuiFormHelperText-root": {
                                        marginX: "0.1rem",
                                        marginY: "0rem",
                                    },
                                })}
                                value={inMailSubject}
                                // @ts-ignore
                                onInput={(e) => handleInMailSubjectChange(e.target.value)}
                            />
                        </span>
                        <RichTextEditor
                            readOnly={isLinkedin && !inMailStatus}
                            enableImageModule
                            ref={editor2}
                            value={(inMailBody as string)?.replaceAll("\n", "<br>")}
                            onFocus={() => setAddTo("editor2")}
                            onChange={(value, _, source) => {
                                if (source === "user") {
                                    handleInMailBodyChange(value);
                                }
                            }}
                            className={className(false)}
                        />
                        <Stack gap={0.5}>
                            {objectKeys({ ...errors?.inMailBody, ...errors?.orderingErrors }).map((i) => {
                                const value = { ...errors?.inMailBody, ...errors?.orderingErrors }[i];
                                if (!value || !inMailStatus) {
                                    return null;
                                }
                                return <ErrorMessage text={value} key={i} />;
                            })}
                        </Stack>
                        {(hasLinkedinOnDay1 && (isRecruiterInMail || orgId === 1292)) && (
                            <div className="bg-[#fce4e4] p-2 rounded mt-2" style={{ opacity: visibility ? 1 : 0.8 }}>
                                <Typography sx={{ fontSize: "14px", color: "#973643", fontStyle: "italic" }}>
                                    <ErrorRoundedIcon sx={{ color: "#973643", fontSize: "18px", marginRight: "5px" }} />
                                    In order to avoid automation detection by linkedin, any InMails scheduled on Day 1{" "}
                                    <span style={{ fontWeight: "bold" }}>(via LinkedIn Recruiter Account)</span> will be
                                    sent at the same scheduled time on Day 2.
                                </Typography>
                            </div>
                        )}
                        {isRecruiterInMail && (
                            <>
                                <Tooltip title="This message will be sent to every person who accepts your connection request but does not reply to you.">
                                    <CustomButton
                                        variant="outlined"
                                        startIcon={
                                            showInMailReminderMessage ? (
                                                <RemoveRoundedIcon fontSize="small" />
                                            ) : (
                                                <AddRoundedIcon fontSize="small" />
                                            )
                                        }
                                        size="small"
                                        sx={{
                                            margin: "1rem 0",
                                            borderRadius: "0.3rem",
                                            "&:focus": {
                                                boxShadow: "none",
                                            },
                                        }}
                                        disabled={!inMailStatus}
                                        onClick={handleInMailReminderMessageToggle}
                                    >
                                        {showInMailReminderMessage ? "Disable" : "Enable"} InMail reminder message
                                    </CustomButton>
                                </Tooltip>
                                <Collapse in={showInMailReminderMessage}>
                                    <BaseTemplatesReminderMessage
                                        readOnly={isLinkedin && !inMailStatus}
                                        editorRef={editor5}
                                        type="inMail"
                                        disabled={!inMailStatus}
                                    />
                                </Collapse>
                            </>
                        )}
                    </Stack>
                )}
            </Stack>
            <Outlet />
        </>
    );
}

type BaseTemplatesReminderMessageProps = {
    editorRef: MutableRefObject<ReactQuill | null> | null;
    readOnly: boolean;
    type?: "inMail" | "connection";
    disabled?: boolean;
};

function BaseTemplatesReminderMessage({ readOnly, editorRef, type, disabled }: BaseTemplatesReminderMessageProps) {
    const { currentOutreachElementId } = useOutreachParams();
    const reduxState = useSelector((state: RootState) => state);
    const errors = useSelector((state: RootState) =>
        type === "connection"
            ? checkErrorsInReminderMessage(state, currentOutreachElementId)
            : checkErrorsInInMailReminderMessage(state, currentOutreachElementId)
    );
    const dispatch = useAppDispatch();
    const template = useSelector((state: RootState) => selectOutreachTemplateContent(state, currentOutreachElementId));

    const [body, setBody] = useState<string>("");
    const [inMailBody, setInMailBody] = useState<string>("");
    const [inMailReminderSubject, setInMailReminderSubject] = useState<string>("");

    //a state of array which has 3-25 numbers
    const reminderDays = Array.from({ length: 23 }, (_, i) => i + 3);

    const [reminderDay, setReminderDay] = useState<string>(reminderDays[0].toString());

    useEffect(() => {
        if ("connectionReminderMessageBody" in template && "connectionReminderMessageStatus" in template) {
            setBody(template.connectionReminderMessageBody as string);
        } else {
            setBody("");
        }

        if ("inmailFollowupBody" in template && "inmailFollowupStatus" in template) {
            setInMailBody(template.inmailFollowupBody as string);
        } else {
            setInMailBody("");
        }

        if ("inmailFollowupReminder" in template) {
            setReminderDay(template.inmailFollowupReminder as string);
        } else {
            setReminderDay(reminderDays[0].toString());
        }

        if ("inmailFollowupSubject" in template) {
            setInMailReminderSubject(template.inmailFollowupSubject as string);
        } else {
            setInMailReminderSubject("");
        }
    }, [template]);

    const setAddTo = (currentEditor: "editor1" | "editor2" | "editor3" | "editor4" | "editor5" | null) => {
        dispatch(changeCurrentEditor(currentEditor));
    };

    const dispatchBody = useCallback(
        debounce((value: string) => {
            dispatch(
                editOutreachElement({
                    reduxState,
                    id: currentOutreachElementId,
                    connectionReminderMessageBody: value,
                })
            );
        }, 500),
        [currentOutreachElementId, dispatch]
    );

    const dispatchInMailBody = useCallback(
        debounce((value: string) => {
            dispatch(
                editOutreachElement({
                    reduxState,
                    id: currentOutreachElementId,
                    inmailFollowupBody: value,
                })
            );
        }, 500),
        [currentOutreachElementId, dispatch]
    );

    // //call the dispatch function to update the reminder day on initial render
    useEffect(() => {
        setReminderDay("3");
        // dispatchReminderDay("3");
    }, []);

    const dispatchReminderDay = useCallback(
        debounce((value: string) => {
            dispatch(
                editOutreachElement({
                    reduxState,
                    id: currentOutreachElementId,
                    inmailFollowupReminder: value,
                })
            );
        }, 500),
        [currentOutreachElementId, dispatch]
    );

    const dispatchInMailSubject = useCallback(
        debounce((value: string) => {
            dispatch(
                editOutreachElement({
                    reduxState,
                    id: currentOutreachElementId,
                    inmailFollowupSubject: value,
                })
            );
        }, 500),
        [currentOutreachElementId, dispatch]
    );

    const handleBodyChange = (value: string) => {
        if (type === "connection") {
            setBody(value);
            dispatchBody(value);
        } else if (type === "inMail") {
            setInMailBody(value);
            dispatchInMailBody(value);
        }
    };

    const handleInMailReminderSubjectChange = (value: string) => {
        setInMailReminderSubject(value);
        dispatchInMailSubject(value);
    };

    const handleReminderChange = (value: string) => {
        setReminderDay(value);
        dispatchReminderDay(value);
    };
    return (
        <Stack gap={2}>
            {type === "inMail" && (
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CustomTextField
                            onFocus={() => setAddTo("editor5")}
                            label="Subject"
                            value={inMailReminderSubject}
                            disabled={disabled}
                            onChange={(e) => handleInMailReminderSubjectChange(e.target.value as string)}
                            sx={(theme) => ({
                                flex: 1,
                                backgroundColor: theme.palette.common.white,
                                "& .MuiInputBase-root": {
                                    padding: 0,
                                },
                                "& .MuiInputBase-input": {
                                    padding: "0.7rem",
                                    fontSize: theme.typography.pxToRem(14),
                                },
                                "& .MuiFormHelperText-root": {
                                    marginX: "0.1rem",
                                    marginY: "0.3rem",
                                },
                            })}
                        />
                        <div style={{ marginLeft: "1rem", minWidth: "150px" }}>
                            <FormHelperText sx={{ marginLeft: "0.5rem" }}>Select reminder day</FormHelperText>
                            <Select
                                value={(template as any)?.inmailFollowupReminder ?? reminderDay}
                                onChange={(e: SelectChangeEvent<string>) =>
                                    handleReminderChange(e.target.value as string)
                                }
                                disabled={disabled}
                                inputProps={{
                                    name: "reminderDay",
                                    id: "reminderDay",
                                }}
                                sx={(theme) => ({
                                    width: "auto",
                                    minWidth: "150px",
                                    marginLeft: "0.5rem",
                                    marginBottom: "1.3rem",
                                    backgroundColor: theme.palette.common.white,
                                    "& .MuiInputBase-root": {
                                        padding: 0,
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "0.7rem",
                                        fontSize: theme.typography.pxToRem(14),
                                    },
                                    "& .MuiFormHelperText-root": {
                                        marginX: "0.1rem",
                                        marginY: "0.3rem",
                                    },
                                })}
                            >
                                {reminderDays.map((day) => (
                                    <MenuItem key={day} value={day}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
            )}
            <RichTextEditor
                ref={editorRef}
                readOnly={readOnly}
                value={(type === "connection" ? body : inMailBody)?.replace(/\n/g, "<br>")}
                className={templatePreviewStyles.reminderMessage}
                onFocus={() => (type === "inMail" ? setAddTo("editor5") : setAddTo("editor3"))}
                onChange={(value, _, source) => {
                    if (source === "user") {
                        handleBodyChange(value);
                    }
                }}
            />
            <Stack gap={0.5}>
                {Object.keys(errors).map((key) => {
                    const value = errors[key as keyof typeof errors];
                    return value ? <ErrorMessage text={value} key={key} /> : null;
                })}
            </Stack>
        </Stack>
    );
}
