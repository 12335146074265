/* eslint-disable import/no-unused-modules */
import { NotificationConfigTypes } from "@/store/reducers/signin/Signin.types";

export const typesOFNotificationMap = {
    SOURCING: "Sourcing",
    ENRICHMENT: "Enrichment",
    VETTING: "Vetting",
    ATS: "Ats",
}

export type typeOfNotificationType = typeof typesOFNotificationMap[keyof typeof typesOFNotificationMap];

export type NotificationV2Type = {
    _id: string;
    notificationType: typeOfNotificationType;
    userId: number;
    userName: string;
    actionBy: string;
    orgId: number;
    projectName: string;
    projectId: number;
    isRead: boolean;
    isDeleted: boolean;
    title: string;
    readAt?: Date;
    data: {
        description: string;
        details: any;
    };
    createdAt?: Date;
    updatedAt?: Date;
};

/* eslint-disable import/no-unused-modules */
export type notificationsFiltersType = {
    page?: number;
    perPage?: number;
    orderBy?: number;
    notificationType?: typeOfNotificationType;
    pageName?: "RECENT" | "ARCHIVES";
    unReadOnly?: boolean;
};

export interface NotificationStateType {
    UNREADS: NotificationV2Type[]; // for unread notifications, which will be shown in the notification drawer
    RECENT: NotificationV2Type[]; // for last 7 days
    ARCHIVES: NotificationV2Type[]; // for older than 7 days
}

export interface TotalNotificationsType {
    UNREADS: number;
    RECENT: number;
    ARCHIVES: number;
}

export type NotificationContextType = {
    notifications: NotificationStateType;
    totalNotifications: TotalNotificationsType;
    selectedNotifications: Record<string, boolean>;
    allNotificationsSelected: boolean;
    filters: notificationsFiltersType;
    isFetching: boolean;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
    markAsRead: (notificationIds: string[]) => void;
    markAllAsRead: () => void;
    deleteNotifications: (notificationIds: string[]) => void;
    deleteAllNotifications: () => void;
    updateNotificationConfig: (notificationConfigTypes: NotificationConfigTypes) => void;
    fetchNotifications: (filters: notificationsFiltersType) => void;
    fetchOnlyUnreadNotifications: () => void;
    selectNotification: (id: string) => void;
    selectAllNotifications: (selected: boolean) => void;
    setFilters: (filters: notificationsFiltersType) => void;
};
