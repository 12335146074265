import { signOut } from "@/store/reducers/signin/Signin.reducer";
import { Button, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";
import React from "react";
import { useDispatch } from "react-redux";

const SuspendedPage: React.FC = () => {
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(signOut());
    }
    return (
        <div style={{ height: "100%", border: "2px solid white" }}>
            <div className="w-full mt-5 h-[5%]" style={{ display: "flex", justifyContent: "right" }}>
                <Button className="mr-5" onClick={handleLogout}>Logout</Button>
            </div>

            <Stack
                gap={2}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90%",
                    backgroundColor: "white",
                }}
            >
                <Stack gap={2} className="w-full text-center">
                    <Typography variant="h6" className="text-center font-semibold">
                        Your account has been suspended due to breach of contract.
                    </Typography>
                    <Typography variant="body1" className="text-center">
                        Please reach out to us at{" "}
                        <a href="mailto:customer-support@hirequotient.com" className="text-primary">
                            customer-support@hirequotient.com.
                        </a>
                    </Typography>
                </Stack>
            </Stack>
        </div>
    );
};

export default SuspendedPage;
