/* eslint-disable */
import { useSelector } from "react-redux";

import {
    ApolloLogo,
    BouncifyLogo,
    CLAUDELOGO,
    ContactsOutLogo,
    GEMINILOGO,
    GptLogoTransperent,
    METALOGO,
    PERPLEXITYLOGO,
    PeopleDataLabLogo,
    RecruiterFlowIcon,
    RetentionLogo,
    RocketReachLogo,
    TwilloIcon,
    ZeroBounceLogo,
    bambooHRLogo,
    crunchbaseLogo,
    greenhouseLogo,
    jazzHrLogo,
    jobviteLogo,
    laborEdge,
    OracleLogo,
    leverLogo,
    monsterLogo,
    nucleusLogo,
    zohoRecruitLogo,
} from "../constant/Constant";
import BambooHRConnect from "@/pages/integrations/components/bambooHR";
import GreenhouseConnect from "@/pages/integrations/components/greenhouse";
import JazzHRConnect from "@/pages/integrations/components/jazzHR";
import LaborEdgeConnect from "@/pages/integrations/components/LaborEdgeAts";
import LeverConnect from "@/pages/integrations/components/lever";
import NucleusConnect from "@/pages/integrations/components/nucleus";
import SendosoConnect from "@/pages/integrations/components/sendoso";
import ZohoRecruitConnect from "@/pages/integrations/components/zohoRecruit";
import RecruiterFlowConnect from "@/pages/integrations/components/recruiterFlow";
import OracleConnect from "@/pages/integrations/components/OracleAts";
import { checkEasySource, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { MonsterConnect } from "@/pages/integrations/components/Monster";

export const IntegrationConstants = () => {
    const user: any = useSelector(selectUser);
    const isEasySource = useSelector(checkEasySource);
    return {
        Data: {
            title: "Our data partners",
            description:
                "We work with several data partners and solutions to bring to you the best data for hiring. Not all our data partners are public, however. Reach out to ",
            EGdescription:
                "We work with several data partners and solutions to bring to you the best data for prospects. Not all our data partners are public, however. Reach out to ",
            children: [
                {
                    logo: crunchbaseLogo,
                    title: "Crunchbase",
                    description: `Leverage the richest, most accurate source of company data intelligence right on ${isEasySource ? "EasySource" : "EasyGrowth"}, in your searches and in ${isEasySource ? "candidate" : "prospects"} profiles`,
                },
                {
                    logo: monsterLogo,
                    title: "Monster",
                    component: MonsterConnect,
                    isModalOpen: true,
                    isModal: !(
                        user?.orgData?.monster?.clientId ||
                        user?.orgData?.monster?.clientSecret ||
                        user?.orgData?.monster?.cat
                    ),
                    canEdit: true,
                    description: `Access America's fastest growing job boards to find relevant, up-to-date candidate resumes directly on EasySource`,
                },
                {
                    logo: "https://s40198.pcdn.co/wp-content/uploads/2021/06/resume-library-logo-blue-1-768x226.png",
                    title: "Resume Library",
                    imageSize: true,
                    description: `Access America's fastest growing job boards to find relevant, up-to-date candidate resumes directly on EasySource`,
                },
                {
                    logo: RocketReachLogo,
                    title: "RocketReach",
                    description: "Most versatile contact data providers globally",
                },
                {
                    logo: ContactsOutLogo,
                    title: "ContactOut",
                    description: "Highly valued, hard-to-find personal email addresses and phone numbers in the US",
                },
                {
                    logo: PeopleDataLabLogo,
                    title: "People Data Labs",
                    description: "Personal contact information from a leading people data provider ",
                },
                {
                    logo: ApolloLogo,
                    title: "Apollo.io",
                    isModal: false,
                    isModalOpen: false,
                    description: "Largest data intelligence solution with reliable contact info",
                },
            ],
        },
        "Candidate Engagement": {
            title: `Enhance ${isEasySource ? "candidate" : "prospects"} engagement`,
            description:
                "EasySource enables you to provide the best candidate hiring experience by providing with world-class partners",
            EGdescription: "EasyGrowth enables you to engage in the best possible manner with all of your prospects",
            children: [
                {
                    logo: "https://cdn.prod.website-files.com/651ad5ceeba626e1de2f2ce8/651c92a16a856b0f0d5656ca_SendosoLogo_RGB.svg",
                    title: "Sendoso",
                    isModal: !user.features.sendosoIntegrated,
                    isModalOpen: true,
                    component: SendosoConnect,
                    canEdit: true,
                    imageSize: true,
                    description:
                        "Streamlines personalized gifts and direct mail, and strengthens customer relationships",
                },
                {
                    logo: RetentionLogo,
                    isModal: false,
                    title: "RB2B",
                    description:
                        "Specialized contact info provider with a focus on reliability and sector-specific accuracy",
                },
                {
                    logo: "https://cdn.prod.website-files.com/63d0ecc632a91d417f163394/63da1ac19e0ef0bff4e142d5_logo-2.svg",
                    title: "Entropik AI",
                    comingSoon: true,
                    imageSize: true,
                    description:
                        "Leverages advanced analytics to enhance customer insights, personalize interactions, and drive data-driven decision-making",
                },
                {
                    logo: TwilloIcon,
                    title: "Twilio",
                    isModal: false,
                    imageSize: true,
                    description:
                        "Seamless communication via SMS, voice, and messaging, enhancing customer engagement and support",
                },
                {
                    logo: BouncifyLogo,
                    isModal: false,
                    title: "Bouncify",
                    isModalOpen: false,
                    description: `Leading email verification service globally, free and enabled by default with your ${isEasySource ? "EasySource" : "EasyGrowth"} license `,
                },
                {
                    logo: ZeroBounceLogo,
                    title: "ZeroBounce",
                    isModal: false,
                    isModalOpen: false,
                    description:
                        "World's most accurate and trusted bounce solution, to drive your email deliverability upwards of 95%",
                },
            ],
        },
        "Prospect Engagement": {
            title: `Enhance ${isEasySource ? "candidate" : "prospects"} engagement`,
            description:
                "EasySource enables you to provide the best candidate hiring experience by providing with world-class partners",
            EGdescription: "EasyGrowth enables you to engage in the best possible manner with all of your prospects",
            children: [
                {
                    logo: "https://cdn.prod.website-files.com/651ad5ceeba626e1de2f2ce8/651c92a16a856b0f0d5656ca_SendosoLogo_RGB.svg",
                    title: "Sendoso",
                    isModal: !user.features.sendosoIntegrated,
                    isModalOpen: true,
                    component: SendosoConnect,
                    canEdit: true,
                    imageSize: true,
                    description:
                        "Streamlines personalized gifts and direct mail, and strengthens customer relationships",
                },
                {
                    logo: RetentionLogo,
                    isModal: false,
                    title: "RB2B",
                    description:
                        "Specialized contact info provider with a focus on reliability and sector-specific accuracy",
                },
                {
                    logo: "https://cdn.prod.website-files.com/63d0ecc632a91d417f163394/63da1ac19e0ef0bff4e142d5_logo-2.svg",
                    title: "Entropik AI",
                    comingSoon: true,
                    imageSize: true,
                    description:
                        "Leverages advanced analytics to enhance customer insights, personalize interactions, and drive data-driven decision-making",
                },
                {
                    logo: TwilloIcon,
                    title: "Twilio",
                    isModal: false,
                    imageSize: true,
                    description:
                        "Seamless communication via SMS, voice, and messaging, enhancing customer engagement and support",
                },
            ],
        },
        ATS: {
            title: "ATS Partners",
            description:
                "We work with several ATS solutions to bring to you the best hiring experience. Not all our ATS partners are public, however. Click here to read more about how we partner with ATS solutions.",
            children: [
                {
                    logo: jazzHrLogo,
                    title: "JazzHR",
                    isModal: !(user.jazzHR?.jazzHRFeedURL || user.jazzHR?.username),
                    isModalOpen: true,
                    canEdit: true,
                    component: JazzHRConnect,
                    description: "ATS that enables HRs to hunt and recruit the best talent for their company",
                },
                {
                    logo: zohoRecruitLogo,
                    title: "Zoho Recruit",
                    isModal: !user.zohoRecruitCreds?.refresh_token,
                    isModalOpen: true,
                    component: ZohoRecruitConnect,
                    description:
                        "A cloud based ATS built to provide diverse, end-to-end hiring solutions for staffing agencies, corporate HRs and temporary workforce",
                },
                {
                    logo: RecruiterFlowIcon,
                    title: "RecruiterFlow",
                    isModal: !user.recruiterFlowCredentials?.apiKey,
                    isModalOpen: true,
                    canEdit: true,
                    imageSize: true,
                    component: RecruiterFlowConnect,
                    description:
                        "Unlock seamless communication, smarter automation, and real-time insights with Recruiterflow ATS",
                },
                {
                    logo: leverLogo,
                    title: "Lever",
                    isModal: !user.leverRefreshToken,
                    isModalOpen: true,
                    component: LeverConnect,
                    description:
                        "A modern, collaborative ATS that powers true talent engagement. Lever is the only platform that helps top global companies hire top talent.",
                },
                {
                    logo: greenhouseLogo,
                    title: "Greenhouse",
                    isModal: !(user.greenHouse?.apiKey || user.greenHouse?.serviceUser),
                    canEdit: true,
                    isModalOpen: true,
                    component: GreenhouseConnect,
                    description: "Talent acquisition software that offers tools and services to help with the hiring",
                },
                {
                    logo: bambooHRLogo,
                    title: "BambooHR",
                    isModal: !(user.bambooHR?.apiKey && user.bambooHR?.companyDomain),
                    isModalOpen: true,
                    canEdit: true,
                    component: BambooHRConnect,
                    description:
                        "A HR software that collects and organizes all the information you gather throughout the employee life cycle, then helps you use it to achieve great things.",
                },
                {
                    logo: jobviteLogo,
                    title: "Jobvite",
                    comingSoon: true,
                    description:
                        "ATS and recruiting platform that helps thousands of companies source, hire and onboard top talent",
                },
                {
                    logo: laborEdge,
                    title: "LaborEdge",
                    isModal: !(user.laborEdge?.username && user.laborEdge?.password),
                    isModalOpen: true,
                    canEdit: true,
                    comingSoon: false,

                    imageSize: true,

                    component: LaborEdgeConnect,
                    description:
                        "LaborEdge provides state-of-the art Healthcare ATS, VMS, and mobile solutions to staffing agencies, hospitals systems, and healthcare workers",
                },
                {
                    logo: OracleLogo,
                    title: "Oracle",
                    isModal: !(user?.oracle?.username && user?.oracle?.password && user?.oracle?.baseUrl),
                    isModalOpen: true,
                    canEdit: true,
                    comingSoon: false,
                    component: OracleConnect,
                    description:
                        "Oracle provides a comprehensive ATS solution for managing the entire recruitment process, from candidate sourcing to onboarding",
                },
                {
                    logo: nucleusLogo,
                    title: "Nucleus",
                    component: NucleusConnect,
                    isModalOpen: true,
                    isModal: !user.nucleusApiKey,
                    canEdit: true,
                    imageSize: true,

                    description:
                        "ATS and recruiting platform that helps thousands of companies source, hire and onboard top talent",
                },
            ],
        },
        "AI Credits": {
            title: "AI credits access",
            description: `
            EasySource partners with most top AI models to optimize your hiring process and deliver the best outcomes at no additional cost to you.`,
            EGdescription: `
            EasyGrowth partners with most top AI models to optimize your hiring process and deliver the best outcomes at no additional cost to you.`,
            subDescription: "to switch to the 'AI Controls' tab and select usage and access",
            navigation: "/my-account/ai-control",
            children: [
                {
                    logo: GptLogoTransperent,
                    title: "GPT-4",
                    description:
                        "AI-driven conversational capabilities, generating content, and improving overall engagement",
                },
                {
                    logo: GptLogoTransperent,
                    title: "GPT-4o",
                    description: "GPT’s most advanced feature yet",
                },
                {
                    logo: CLAUDELOGO,
                    title: "Claude 3.5",
                    description: "Delivers advanced AI-powered conversational abilities, improving user interactions",
                },
                {
                    logo: METALOGO,
                    title: "Llama 3",
                    description:
                        "AI-driven conversational and analytical features, enhancing user experience and content personalization",
                },
                {
                    logo: GEMINILOGO,
                    title: "Gemini",
                    description: "AI capabilities for conversational interactions, and personalized user experiences",
                },
                {
                    logo: PERPLEXITYLOGO,
                    title: "Perplexity AI",
                    description: "Uses data-driven insights, and content relevance, to improve user interactions",
                },
            ],
        },
    };
};
