import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { checkEasySource } from "../pages/Signin/Signin.reducer";

export function EasySourceValidator({ children }: { children: React.ReactNode }) {
    const isEasySource = useSelector(checkEasySource);

    if (!isEasySource) {
        return <Navigate to="/" />;
    }

    return <>{children}</>;
}
