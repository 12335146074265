import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

import { RootState } from "../../store";

const initialState = Object.freeze({
    canHideWalkThrough: true,
});

const reducers = {
    hideWalkThrough(state: any) {
        state.canHideWalkThrough = true;
    },
    setCanHideWalkThrough(state: any, { payload }: { payload: boolean }) {
        state.canHideWalkThrough = payload;
    },
    leavePage(state: any) {},
};

export const reducerPage = createSlice({
    name: "walkthrough",
    initialState,
    reducers,
});

export default reducerPage.reducer;
export const { setCanHideWalkThrough, hideWalkThrough, leavePage } = reducerPage.actions;

export const selectCanHideWalkThrough = (state: RootState) => get(state, "interActiveWalkthorugh.canHideWalkThrough");
