import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { checkSendosoIntegrated, selectUser, toggleIsSendosoIntegrated, updateUser } from "../../Signin/Signin.reducer";
import { updateUserFeature } from "../../superAdmin/featureList/feature.reducers";

export default function SendosoConnect({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const sendosoIntegrated = useSelector(checkSendosoIntegrated);
    const [sendosoEnabled, setSendosoEnabled] = useState(false);

    useEffect(() => {
        setSendosoEnabled(sendosoIntegrated);
    }, [sendosoIntegrated]);

    const handleConnect = () => {
        dispatch(toggleIsSendosoIntegrated(sendosoEnabled));
        // dispatch(updateUser({ ...user, features: { ...user.features, sendoso: sendosoEnabled } }));
        onClose();
    };

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Enable Sendoso gifting on EasySource</DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "2px" }}>
                    <Typography variant="body1" sx={{ fontSize: "12px" }}>
                        Toggle on to enable Sendoso gifting functionality in your projects. Then simply click the gift
                        icon in each candidate card to send out gifts
                    </Typography>
                    <Switch checked={sendosoEnabled} onChange={() => setSendosoEnabled(!sendosoEnabled)} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose} sx={{ borderRadius: 1 }}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleConnect} sx={{ borderRadius: 1 }}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
