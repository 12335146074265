import { ActivityColumn } from "./ContactOverviewActivities/ContactOverviewActivities.types";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { ContactListName } from "../AllCandidates";

export function CreatedFor(props: ActivityColumn["createdFor"] = { labels: [], _id: "", name: "" }) {
    const { labels, name, _id } = props;
    return (
        <Stack gap={0.7}>
            <ContactListName id={_id} name={name} />
            <Stack direction="row" gap={0.7} flexWrap="wrap">
                {labels.map((i) => {
                    return (
                        <Chip
                            key={i}
                            label={i}
                            size="small"
                            sx={{
                                fontSize: "12px",
                            }}
                        />
                    );
                })}
            </Stack>
        </Stack>
    );
}
