import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuList,
    Paper,
    Popper,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import { useGetOrganizationMembersQuery } from "../../../store/apis/organizations/organizations.api";
import { useGetProjectsQuery } from "../../../store/apis/projects/projects.api";
import ProjectNameWithLabel from "../../Analytics/Component/ProjectNameWithLabel";

type MultiSelectDropdownProps = {
    userProjects: any;
    setUserProjects: any;
};

const ProjectListPopper: React.FC<{
    expandedUser: number | null;
    projectAnchorEl: HTMLElement | null;
    handleCloseProjects: () => void;
    userProjects: any;
    setUserProjects: any;
    projects: any[];
}> = ({ expandedUser, projectAnchorEl, handleCloseProjects, userProjects, setUserProjects, projects }) => {
    const handleToggleProjects = useCallback(
        (project: number) => {
            setUserProjects((prev: any) => {
                const userIndex = prev.findIndex((user: any) => user.userId === expandedUser);
                if (userIndex === -1) {
                    return [...prev, { userId: expandedUser!, projectIds: [project] }];
                } else {
                    const updatedProjects = prev[userIndex].projectIds.includes(project)
                        ? prev[userIndex].projectIds.filter((id: number) => id !== project)
                        : [...prev[userIndex].projectIds, project];
                    const updatedUserProjects = [...prev];
                    updatedUserProjects[userIndex] = { ...prev[userIndex], projectIds: updatedProjects };
                    return updatedUserProjects;
                }
            });
        },
        [expandedUser, setUserProjects]
    );

    const handleSelectAllProjects = useCallback(
        (selectAll: boolean) => {
            if (selectAll) {
                setUserProjects((prev: any) => {
                    const userIndex = prev.findIndex((user: any) => user.userId === expandedUser);
                    if (userIndex === -1) {
                        return [...prev, { userId: expandedUser!, projectIds: projects?.map((p: any) => p._id) }];
                    } else {
                        const updatedUserProjects = [...prev];
                        updatedUserProjects[userIndex] = {
                            ...prev[userIndex],
                            projectIds: projects?.map((p: any) => p._id),
                        };
                        return updatedUserProjects;
                    }
                });
            } else {
                setUserProjects((prev: any) => {
                    const userIndex = prev.findIndex((user: any) => user.userId === expandedUser);
                    if (userIndex !== -1) {
                        const updatedUserProjects = [...prev];
                        updatedUserProjects[userIndex] = { ...prev[userIndex], projectIds: [] };
                        return updatedUserProjects;
                    }
                    return prev;
                });
            }
        },
        [expandedUser, projects, setUserProjects]
    );

    const isAllSelected =
        projects?.length > 0 &&
        userProjects?.find((u: any) => u?.userId === expandedUser)?.projectIds?.length === projects?.length;

    return (
        <Popper
            open={Boolean(projectAnchorEl)}
            anchorEl={projectAnchorEl}
            placement="right-start"
            style={{
                zIndex: 1300,
                padding: "5px",
                width: "300px",
                maxHeight: "400px",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                minHeight: "100px",
            }}
            modifiers={[
                {
                    name: "offset",
                    options: { offset: [0, 0] },
                },
            ]}
        >
            <Paper>
                {projects?.length > 0 ? (
                    expandedUser && (
                        <List sx={{ padding: 0 }}>
                            <ListItem sx={{ padding: "3px 14px" }}>
                                <Checkbox
                                    tabIndex={-1}
                                    disableRipple
                                    checked={isAllSelected}
                                    onChange={(event) => handleSelectAllProjects(event.target.checked)}
                                />
                                <ListItemText primary="Select all projects" />
                            </ListItem>
                            <Divider />
                            {projects.map((project: any) => (
                                <ListItem
                                    key={project._id}
                                    onClick={() => handleToggleProjects(project._id)}
                                    sx={{ padding: "2px 14px" }}
                                >
                                    <Checkbox
                                        tabIndex={-1}
                                        disableRipple
                                        checked={
                                            userProjects
                                                .find((u: any) => u.userId === expandedUser)
                                                ?.projectIds.includes(project._id) || false
                                        }
                                    />
                                    <ProjectNameWithLabel name={project.name} status={project.dashboardStatus} />
                                </ListItem>
                            ))}
                        </List>
                    )
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        sx={{ padding: "10px" }}
                    >
                        No projects found
                    </Box>
                )}
            </Paper>
        </Popper>
    );
};

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({ userProjects, setUserProjects }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [projectAnchorEl, setProjectAnchorEl] = useState<null | HTMLElement>(null);
    const [expandedUser, setExpandedUser] = useState<number | null>(null);
    const [projects, setProjects] = useState<any[]>([]);

    const { data: membersData } = useGetOrganizationMembersQuery();

    useEffect(() => {
        if (membersData?.members) {
            const allUserProjects = membersData.members.map((member: any) => ({
                userId: member._id,
                projectIds: [],
            }));
            setUserProjects(allUserProjects);
        }
    }, [membersData, setUserProjects]);

    const handleToggleUser = useCallback(
        (user: number) => {
            setUserProjects((prev: any) =>
                prev.some((u: any) => u.userId === user)
                    ? prev.filter((u: any) => u.userId !== user)
                    : [...prev, { userId: user, projectIds: [] }]
            );
        },
        [setUserProjects]
    );

    const handleCloseProjects = () => {
        setProjectAnchorEl(null);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        handleCloseProjects();
    };

    const handleOpenProjects = useCallback((event: React.MouseEvent<HTMLElement>, user: number, projects: any[]) => {
        setExpandedUser(user);
        setProjects(projects);
        setProjectAnchorEl(event.currentTarget);
    }, []);

    const selectedUsersText =
        userProjects?.length === 0
            ? "Select Members"
            : `${userProjects?.length} member${userProjects?.length > 1 ? "s" : ""} selected`;

    return (
        <div>
            <Button
                onClick={(event) => setAnchorEl(event.currentTarget)}
                style={{
                    border: "1px solid #dcdcdc",
                    borderRadius: "4px",
                    justifyContent: "space-between",
                    padding: "6px 8px",
                    minWidth: "200px",
                    color: "#000000DE", // Gray color
                    fontWeight: 500,
                    textTransform: "none",
                    marginRight: "8px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {selectedUsersText}
                <ArrowDropDownIcon />
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                keepMounted
                PaperProps={{
                    style: {
                        width: "300px",
                        border: "1px solid #dcdcdc",
                        borderRadius: "4px",
                        marginTop: "5px",
                        marginRight: "8px",
                    },
                }}
            >
                <MenuList>
                    {membersData?.members?.map((member) => (
                        <ListItemWithCheckbox
                            key={member._id}
                            member={member}
                            userProjects={userProjects}
                            setUserProjects={setUserProjects}
                            handleToggleUser={handleToggleUser}
                            handleOpenProjects={handleOpenProjects}
                        />
                    ))}
                </MenuList>
            </Menu>
            <ProjectListPopper
                expandedUser={expandedUser}
                projectAnchorEl={projectAnchorEl}
                handleCloseProjects={handleCloseProjects}
                userProjects={userProjects}
                setUserProjects={setUserProjects}
                projects={projects}
            />
        </div>
    );
};

export default MultiSelectDropdown;

const ListItemWithCheckbox: React.FC<{
    member: any;
    userProjects: any;
    setUserProjects: any;
    handleToggleUser: (user: number) => void;
    handleOpenProjects: (event: React.MouseEvent<HTMLElement>, user: number, projects: any) => void;
}> = ({ member, userProjects, setUserProjects, handleToggleUser, handleOpenProjects }) => {
    const { data: projectsData } = useGetProjectsQuery(member._id);
    useEffect(() => {
        if (projectsData?.length) {
            const activeProjects = projectsData?.filter((p: any) => p.dashboardStatus === "ACTIVE");
            setUserProjects((prev: any) => {
                const userIndex = prev.findIndex((user: any) => user.userId === member._id);
                if (userIndex === -1) {
                    return [...prev, { userId: member._id, projectIds: activeProjects?.map((p: any) => p._id) }];
                } else {
                    const updatedUserProjects = [...prev];
                    updatedUserProjects[userIndex] = {
                        ...prev[userIndex],
                        projectIds: activeProjects?.map((p: any) => p._id),
                    };
                    return updatedUserProjects;
                }
            });
        }
    }, [projectsData, member._id, setUserProjects]);

    return (
        <ListItem key={member._id}>
            <Checkbox
                checked={userProjects.some((u: any) => u.userId === member._id)}
                onChange={() => handleToggleUser(member._id)}
                tabIndex={-1}
                disableRipple
            />
            <ListItemText
                primary={member.name}
                secondary={`${userProjects?.find((u: any) => u.userId === member._id)?.projectIds?.length || 0} projects`}
            />
            <IconButton edge="end" onClick={(event) => handleOpenProjects(event, member._id, projectsData)}>
                <ArrowRightIcon />
            </IconButton>
        </ListItem>
    );
};
