import ListComponent, { ListProps } from "@mui/material/List";
import { ListItemProps } from "@mui/material/ListItem";

import Item from "./Item";
import { parentPropsWithChildren } from "../../../../utils/react-utils";
import ListItem from "./ListItem";

export interface DataCardProps extends ListItemProps {
    disableHoverEffect?: boolean;
    loading?: string;
}

export default function DataCard(props: DataCardProps & ListProps) {
    return <ListComponent>{parentPropsWithChildren(props)}</ListComponent>;
}

DataCard.Item = Item;
DataCard.ListItem = ListItem;
