import Box, { BoxProps } from "@mui/joy/Box";
import Sheet, { SheetProps } from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";

export default function Scrollable() {}

function Container(props: SheetProps) {
    const { sx, ...rest } = props;

    return (
        <Sheet
            sx={{
                height: {
                    xs: "100dvh",
                    lg: "calc(98dvh - var(--Header-height))",
                },
                display: "flex",
                flexDirection: "column",
                ...sx,
            }}
            {...rest}
        >
            {props.children}
        </Sheet>
    );
}

interface ContentProps extends BoxProps {
    scrollDirection?: "top" | "bottom";
}

function Content(props: ContentProps) {
    const { sx, scrollDirection, ...rest } = props;

    return (
        <Box
            flexDirection={scrollDirection === "top" ? "column-reverse" : "column"}
            sx={{
                display: "flex",
                flex: 1,
                minHeight: 0,
                overflowY: "scroll",
                overflowX: "hidden",
                ...sx,
            }}
            {...rest}
        >
            <Stack spacing={2} justifyContent="flex-end">
                {props.children}
            </Stack>
        </Box>
    );
}

Scrollable.Container = Container;
Scrollable.Content = Content;
