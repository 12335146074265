import React, { useEffect, useState } from "react";
import { IconButton, Tooltip, Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import { useNavigate } from "react-router-dom";

import  {JoyProvider}  from "../../components/JoyProvider";
import { MaterialProvider } from "../../components/MaterialProvider";
import Navbar from "../../components/Navbar/Navbar";

function AlreadyRejectedInvitePageLayout({ children }: { children: React.ReactNode }) {
    return (
        <JoyProvider>
            <Box sx={{ backgroundColor: "background.body", minHeight: "100vh" }}>
                <Box sx={{ pt: 1, pr: 1 }}>
                    <MaterialProvider>
                        <Navbar />
                    </MaterialProvider>
                </Box>
                {children}
            </Box>
        </JoyProvider>
    );
}

export default function AlreadyRejectedInvitePage() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/");
    };

    return (
        <AlreadyRejectedInvitePageLayout>
            <Stack
                spacing={2}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "75vh",
                }}
            >
                <Stack spacing={2}>
                    <Typography
                        level="h1"
                        sx={{
                            textAlign: "center",
                            fontWeight: "700",
                        }}
                    >
                        Invitation already rejected
                    </Typography>
                    <Typography id="modal-desc" textColor="text.tertiary">
                        You have already rejected the invitation earlier. To join now, please request for this
                        invitation again from the sender.
                    </Typography>

                    <Tooltip title="" onClick={handleClick}>
                        <IconButton
                            color="primary"
                            sx={{ color: "#479cd2", border: "1px solid #97c3f0", fontWeight: "700" }}
                        >
                            Go to dashboard
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        </AlreadyRejectedInvitePageLayout>
    );
}
