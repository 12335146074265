import React, { useEffect, useState } from "react";
import { Button, Checkbox, Stack, TablePagination, Typography } from "@mui/material";
import { chunk } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../../components/Loader/Loader";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { ICandidate } from "../../../project/project.types";
import { searchCandidates } from "../Filters/Filters.reducer";
import AddToProject from "./AddToProject";
import styles from "./Candidates.module.css";
import {
    selectCandidate,
    selectCandidates,
    selectCanShowAddToProject,
    selectSelectedCandidate,
    setCanShowAddToProject,
} from "./Candidates.reducer";
import CadidateDetails from "./Components/CandidateDetails";

const Candidates = () => {
    const dispatch = useDispatch();
    const candidates = useSelector(selectCandidates);
    const selectedCandidate: string[] = useSelector(selectSelectedCandidate);
    const canShowAddToProject = useSelector(selectCanShowAddToProject);
    const isCandidateLoading = useSelector(checkIfLoading(searchCandidates.type));
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentCandidate, setCurrentCandidate] = useState<ICandidate[]>([]);

    const onSelectAllCandidate = (isChecked: boolean) => {
        let newCandidate: string[] = [];
        if (isChecked) {
            newCandidate = currentCandidate.map((item: ICandidate) => item._id);
        }
        dispatch(selectCandidate(newCandidate));
    };

    const isAllChecked = selectedCandidate?.length === currentCandidate?.length;

    useEffect(() => {
        const chunks = chunk(candidates, pageSize);
        if (chunks[currentPage - 1]) {
            setCurrentCandidate(chunks[currentPage - 1]);
        }
        dispatch(selectCandidate([]));
    }, [pageSize, currentPage, candidates]);

    // const handlePageChange = (page: number, pageSize: number) => {
    //     setCurrentPage(page);
    //     setPageSize(pageSize);
    // };

    const handleOnPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage + 1);
    };

    const handleRowPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    return (
        <Stack className={styles.container} p="10px">
            {isCandidateLoading && <Loader />}
            {!isCandidateLoading && !candidates.length && (
                <Stack justifyContent="center" alignItems="center" height="100%">
                    <Typography>No candidate found, please start new search</Typography>
                </Stack>
            )}
            {!isCandidateLoading && !!candidates.length && (
                <>
                    <Stack className={styles.buttonContainer} flexDirection="row" alignItems="center">
                        <Checkbox
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onSelectAllCandidate(e.target.checked)
                            }
                            checked={isAllChecked}
                            size="small"
                        />
                        <Typography mr="15px" minWidth="100px">
                            {selectedCandidate?.length} Selected
                        </Typography>
                        <Button
                            variant="contained"
                            className={styles.addToProject}
                            onClick={() => dispatch(setCanShowAddToProject(true))}
                            disabled={!selectedCandidate?.length}
                        >
                            Add to Project
                        </Button>
                    </Stack>
                    <Stack sx={{ height: "99vh", overflowY: "auto" }}>
                        {currentCandidate?.length &&
                            currentCandidate.map((item: ICandidate, i) => <CadidateDetails data={item} key={i} />)}
                    </Stack>
                    {canShowAddToProject && <AddToProject />}
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100, 500, 1000]}
                        component="div"
                        count={candidates?.length || 0}
                        rowsPerPage={pageSize}
                        page={currentPage - 1}
                        onPageChange={handleOnPageChange}
                        onRowsPerPageChange={handleRowPerPageChange}
                    />
                </>
            )}
        </Stack>
    );
};

export default Candidates;
