import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

interface AcceptanceInviteProps {
    open: boolean;
    onClose: () => void;
}

export default function AcceptanceInvite({ open, onClose }: AcceptanceInviteProps) {
    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                navigate("/");
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [open, navigate]);

    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={() => navigate("/")}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    maxWidth: 500,
                    borderRadius: "md",
                    p: 3,
                    boxShadow: "lg",
                }}
            >
                <ModalClose variant="plain" sx={{ m: 1 }} onClick={onClose} />
                <Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
                    Congratulations Invite Accepted!
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary">
                    You are now officially a valued member of our esteemed organization. Welcome aboard!
                </Typography>
            </Sheet>
        </Modal>
    );
}