import { Children, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { styled } from "@mui/joy";
import Autocomplete from "@mui/joy/Autocomplete";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip, { ChipProps } from "@mui/joy/Chip";
import CircularProgress from "@mui/joy/CircularProgress";
import Divider from "@mui/joy/Divider";
import Dropdown from "@mui/joy/Dropdown";
import { checkEasyGrowth } from "../../Signin/Signin.reducer";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import { alpha, Experimental_CssVarsProvider } from "@mui/material";
import { blue, deepOrange, grey, red } from "@mui/material/colors";
import MuiFormControl from "@mui/material/FormControl";
import MuiFormLabel from "@mui/material/FormLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { OptionWithValueAsNumber } from "../../../common";
import { useAppDispatch } from "../../../store";
import {
    ActivitiesData,
    CreateActivityPayload,
    SingleActivity,
    useCreateActivityMutation,
    useDeleteActivityMutation,
    useFetchCandidateActivitiesQuery,
    useFetchOrganizationMembersQuery,
    useMarkAsCompleteMutation,
    useUpdateActivityMutation,
} from "../../../store/apis/all-candidates/all-candidates.api";
import { ACTIVITIES_SELECTED_PROJECT, formatDueDate } from "../../all-candidates/all-candidates.utils";
import { useFetchActivityTags } from "../../all-candidates/AllCandidatesContent";
import { criticalityOptions } from "../../all-candidates/components/ContactOverviewActivities/EditActivityForm";
import { selectUser } from "../../Signin/Signin.reducer";
import {
    editActivity,
    initialEditActivityState,
    selectActivitiesProject,
    selectActivityId,
    selectActivityState,
    selectActivitySubmitMode,
    selectCandidateReachoutsEditDrawer,
    setActivitiesSelectedProject,
    setEditDrawer,
} from "../all-candidates-reachout.slice";
import { ActivityFormState, activityFormStateSchema } from "../all-candidates-reachout.types";
import { AllCandidatesReachOutBodyContainer } from "../AllCandidatesReachout";
import { SelectProject as SelectProjectFilter } from "../components/SelectProject";
import { CandidatesReachoutEmptyPlaceholder } from "./CandidatesReachoutEmptyPlaceholder";
import { CandidateInfoContentContainer } from "./ContactInfo";
import { ScheduleReminderEmailForActivity } from "./ScheduleReminderEmailForActivity";
import { SelectProjectFilterProps, useFetchCandidateProjects, useSelectProjectValues } from "./SelectProject";

export function Activity() {
    return (
        <CandidateInfoContentContainer enableEdit={true} editComponent={<ActivitySidebar />}>
            <AllCandidatesReachOutBodyContainer>
                <ActivityHeader />
                <Divider />
                <Stack
                    mx={2}
                    my={1}
                    pb={2}
                    gap={3}
                    sx={() => ({
                        overflow: "auto",
                        height: "calc(100vh - 215px)",
                        borderBottom: 1,
                        borderColor: "divider",
                    })}
                >
                    <ActivitiesMain />
                </Stack>
            </AllCandidatesReachOutBodyContainer>
        </CandidateInfoContentContainer>
    );
}

function ActivityProjectFilter() {
    const dispatch = useAppDispatch();
    const selectedProject = useSelector(selectActivitiesProject);
    const { ...rest } = useSelectProjectValues({ project: selectedProject });

    const handleSelectChange: SelectProjectFilterProps["handleSelectChange"] = (value) => {
        dispatch(setActivitiesSelectedProject(value));
    };
    return (
        <SelectProjectFilter
            {...rest}
            projects={[
                {
                    label: ACTIVITIES_SELECTED_PROJECT.label,
                    value: ACTIVITIES_SELECTED_PROJECT.value,
                },
                ...rest.projects,
            ]}
            selectedProject={selectedProject}
            handleSelectChange={handleSelectChange}
        />
    );
}

function ActivityHeader() {
    const dispatch = useAppDispatch();
    const isDrawerOpen = useSelector(selectCandidateReachoutsEditDrawer);
    const openDrawer = () => dispatch(setEditDrawer("TOGGLE"));
    return (
        <Stack direction="row" justifyContent="space-between" p={1.2} sx={{ paddingRight: "3.1rem" }}>
            <ActivityProjectFilter />
            {!isDrawerOpen && (
                <Button size="sm" startDecorator={<AddCircleRoundedIcon />} onClick={openDrawer}>
                    Log an activity
                </Button>
            )}
        </Stack>
    );
}

function ActivitiesMain() {
    const params = useParams();
    const { isLoading, isError, data = {} } = useFetchCandidateActivitiesQuery(params?.id || "");
    const selectedProjectId = useSelector(selectActivitiesProject).value;

    if (isLoading) {
        return <ActivitiesLoader />;
    }

    if (isError || isEmpty(data)) {
        return (
            // TODO: do it at the end, used in many components
            <EmptyActivitiesPlaceholder />
        );
    }

    const activitiesFilteredByProject = Object.entries(data)?.reduce((acc: ActivitiesData, [key, value]) => {
        acc[key] = value.filter(
            ({ project: { _id } }) =>
                // by default all projects has value = 0, so we don't need to filter when all projects is selected
                _id === selectedProjectId || selectedProjectId === 0
        );
        return acc;
    }, {});

    return (
        <>
            {Object.entries(activitiesFilteredByProject).map(([year, activities]) => {
                return (
                    <ActivitySection year={year} key={year}>
                        {activities.map((i) => {
                            return <ActivityCard {...i} key={i._id} />;
                        })}
                    </ActivitySection>
                );
            })}
        </>
    );
}

export function ActivitySection({ year, children }: { year: string; children: React.ReactNode }) {
    if (!Children.count(children)) {
        return <EmptyActivitiesPlaceholder />;
    }

    return (
        <Stack gap={1}>
            <Typography level="body-sm">{dayjs(year).format("MMMM YYYY")}</Typography>
            <Divider sx={{ mb: 1 }} />
            <Stack gap={1.5}>{children}</Stack>
        </Stack>
    );
}

type MenuOptions = {
    label: string;
    handleClick: () => void;
    disabled?: boolean;
};

export const MARK_AS_COMPLETE = "Mark as complete";

export const EDIT = "Edit";

const CustomChip = styled(Chip)<ChipProps>(({ theme }) => ({
    borderRadius: "0.4rem",
    "& .MuiChip-label": {
        fontSize: "10px",
    },
}));

function ActivityActionsMenu({ children }: { children: React.ReactNode }) {
    const [open, setOpen] = useState(false);

    const handleOpenChange = useCallback((event: React.SyntheticEvent | null, isOpen: boolean) => {
        setOpen(isOpen);
    }, []);

    return (
        <Dropdown open={open} onOpenChange={handleOpenChange}>
            <MenuButton slots={{ root: IconButton }}>
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu style={{ zIndex: 100000 }}>{children}</Menu>
        </Dropdown>
    );
}

export function ActivityCard({
    _id,
    body,
    timestamp,
    project,
    tag,
    referTo,
    createdBy,
    read,
    handleEdit,
    variant = "CANDIDATE_REACHOUT",
    priority,
    dueDate,
}: SingleActivity & {
    handleEdit?: (value: string) => void;
    variant?: "CANDIDATE_REACHOUT" | "CANDIDATE_PROFILE_CARD";
}) {
    const dispatch = useDispatch();
    const divRef = useRef<HTMLDivElement>(null);
    const userId = useSelector(selectUser)._id;
    const { name: projectName, _id: pId } = project;
    const { name, profileImage } = createdBy;
    const [deleteActivity, { isLoading: isDeleting }] = useDeleteActivityMutation();
    const [markAsComplete, { isLoading: isMarkingAsComplete }] = useMarkAsCompleteMutation();
    const [isHashMatched, setHashMatched] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const activityId = searchParams.get("activityId");
        if (_id === activityId) {
            setHashMatched(true);

            setTimeout(() => {
                setHashMatched(false);
            }, 5000);

            divRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [_id, searchParams]);

    const menuOptions: MenuOptions[] = [
        {
            label: EDIT,
            handleClick: () => {
                if (variant === "CANDIDATE_PROFILE_CARD") {
                    handleEdit?.(_id);
                } else {
                    dispatch(
                        editActivity({
                            dueDate: dayjs(dueDate),
                            priority,
                            id: _id,
                            assignee:
                                referTo?._id && referTo?.name
                                    ? {
                                          value: referTo?._id,
                                          label: referTo?.name,
                                      }
                                    : null,
                            body,
                            tag: {
                                label: tag,
                                value: tag,
                            },
                            project: {
                                label: projectName,
                                value: pId,
                            },
                        })
                    );
                }
            },
        },
        {
            label: "Delete",
            handleClick: () => deleteActivity({ activityId: _id }),
            disabled: isDeleting,
        },
        {
            label: MARK_AS_COMPLETE,
            handleClick: () => markAsComplete({ activityId: _id }),
            disabled: isMarkingAsComplete,
        },
    ];

    return (
        <Stack ref={divRef}>
            <Card
                variant="outlined"
                orientation="horizontal"
                sx={{
                    bgcolor: "neutral.softBg",
                    display: "flex",
                    flexDirection: "row",
                    "&:hover": {
                        boxShadow: "lg",
                        borderColor: "var(--joy-palette-neutral-outlinedDisabledBorder)",
                    },
                }}
            >
                <CardContent sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    <Avatar src={profileImage || ""} sx={{ width: 56, height: 56 }} color="primary" />
                    <Stack gap={0.8}>
                        <Stack direction="row" alignItems="center" gap={0.8}>
                            <Typography level="body-xs">{name}</Typography>
                            {tag && (
                                <CustomChip size="sm" color="primary" variant="solid">
                                    {tag}
                                </CustomChip>
                            )}
                            {referTo?.name && (
                                <CustomChip size="sm" color="primary" variant="outlined">
                                    {referTo.name}
                                </CustomChip>
                            )}
                            {priority && (
                                <Chip
                                    variant="soft"
                                    size="sm"
                                    component="button"
                                    sx={(theme) => {
                                        const backgroundColor =
                                            priority === "HIGH"
                                                ? red[300]
                                                : priority === "MEDIUM"
                                                  ? deepOrange[300]
                                                  : blue[300];

                                        const color =
                                            priority === "HIGH"
                                                ? red[500]
                                                : priority === "MEDIUM"
                                                  ? deepOrange[500]
                                                  : blue[600];
                                        return {
                                            fontWeight: 600,
                                            fontSize: 10,
                                            backgroundColor: alpha(backgroundColor, 0.3),
                                            color,
                                            "&:hover": {
                                                backgroundColor: alpha(backgroundColor, 0.2),
                                            },
                                        };
                                    }}
                                >
                                    {" "}
                                    {priority}
                                </Chip>
                            )}
                            {dueDate && (
                                <Typography
                                    level="body-xs"
                                    sx={{
                                        fontSize: "11px",
                                        padding: "0 0.5rem",
                                        backgroundColor: "primary.50",
                                        borderRadius: "4px",
                                        height: "fit-content",
                                    }}
                                >
                                    {formatDueDate(dueDate)}
                                </Typography>
                            )}
                        </Stack>
                        <Typography
                            sx={{
                                wordBreak: "break-word",
                            }}
                        >
                            <Typography
                                level="body-sm"
                                component={"span"}
                                sx={(theme) => ({
                                    backgroundColor: isHashMatched
                                        ? alpha(theme.palette.warning["600"], 0.8)
                                        : "transparent",
                                })}
                            >
                                {body}
                            </Typography>
                        </Typography>
                        <Stack direction="row" gap={1}>
                            <Typography level="body-xs" color="neutral" fontStyle="italic">
                                {dayjs(timestamp).format("DD MMM YYYY, h:mm A")}
                            </Typography>
                            <Divider orientation="vertical" />
                            <Typography level="body-xs" color="neutral">
                                {projectName}
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
                <Stack justifyContent="space-between">
                    <Stack direction="row" height="fit-content" justifyContent="flex-end">
                        <ScheduleReminderEmailForActivity activityId={_id} />
                        <ActivityActionsMenu>
                            {menuOptions.map((i) => {
                                const { label, handleClick } = i;
                                if (label === MARK_AS_COMPLETE && (referTo?._id !== userId || read)) {
                                    return null;
                                }
                                return <MenuItem onClick={handleClick}>{label}</MenuItem>;
                            })}
                        </ActivityActionsMenu>
                    </Stack>
                    {read && (
                        <CustomChip variant="solid" color="success" size="sm">
                            completed
                        </CustomChip>
                    )}
                </Stack>
            </Card>
        </Stack>
    );
}

function ActivitiesCardLoader() {
    return (
        <Stack gap={1}>
            <Skeleton variant="text" sx={{ fontSize: "1.5rem", maxWidth: "8rem" }} />
            <Divider sx={{ mb: 1 }} />
            <Stack gap={1.5}>
                {Array(3)
                    .fill(1)
                    .map((_, idx) => {
                        return <Skeleton variant="rectangular" height={50} key={`activity-${idx}`} />;
                    })}
            </Stack>
        </Stack>
    );
}

function SelectProject() {
    const { control } = useActivityFormState();
    const { data: projects = [], isLoading } = useFetchCandidateProjects();
    const options = useMemo(() => {
        return projects.map(({ projectId, projectName }) => ({
            label: projectName,
            value: projectId,
        }));
    }, [projects]);
    const [query, setQuery] = useState("");

    return (
        <>
            <Controller
                control={control}
                name="project"
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <FormControl error={Boolean(error)}>
                            <FormLabel>Select Project</FormLabel>
                            <Autocomplete
                                size="sm"
                                value={isEmpty(value) ? undefined : value}
                                options={options}
                                onChange={(_event, value) => onChange(value)}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, selected) => {
                                    return option.value === selected.value;
                                }}
                                openOnFocus={true}
                                inputValue={query}
                                onInputChange={(_, newInputValue) => {
                                    setQuery(newInputValue);
                                }}
                                endDecorator={
                                    isLoading ? (
                                        <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} />
                                    ) : null
                                }
                            />
                            {error !== undefined && <FormHelperText>Please select project.</FormHelperText>}
                        </FormControl>
                    );
                }}
            />
        </>
    );
}

function TagContainer({ children, setEnabledFreeText }: { children: React.ReactNode; setEnabledFreeText: () => void }) {
    return (
        <Stack
            sx={{
                display: "grid",
                gridTemplateColumns: "1fr auto",
                gridTemplateRows: "1fr",
                gap: "0.5rem",
            }}
        >
            {children}
            <Tooltip title="create tag">
                <IconButton size="sm" onClick={setEnabledFreeText} sx={{ alignSelf: "end" }}>
                    <ModeEditRoundedIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function SelectTag() {
    const methods = useFormContext();
    const project: OptionWithValueAsNumber | null = methods.watch("project");
    const openEditDrawer = useSelector(selectCandidateReachoutsEditDrawer);
    const [enableFreeText, setEnabledFreeText] = useState(false);
    const { control } = useActivityFormState();
    const { isLoading, data = [] } = useFetchActivityTags({
        defaultProjectIds: project?.value ? [project.value] : [],
    });
    const options = useMemo(() => data.map((i) => ({ label: i, value: i })), [data]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        setEnabledFreeText(false);
    }, [openEditDrawer]);

    const handleEnabledFreeText = () => {
        setQuery("");
        setEnabledFreeText((prev) => !prev);
    };

    if (enableFreeText) {
        return (
            <Controller
                name="tag"
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    const isError = error !== undefined;
                    return (
                        <FormControl error={isError}>
                            <FormLabel>Create Tag</FormLabel>
                            <TagContainer setEnabledFreeText={handleEnabledFreeText}>
                                <Input
                                    placeholder="Enter text to create tag..."
                                    size="sm"
                                    error={isError}
                                    value={value?.value || ""}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        onChange({ label: value, value });
                                    }}
                                />
                            </TagContainer>
                            {isError && <FormHelperText>Please enter tag.</FormHelperText>}
                        </FormControl>
                    );
                }}
            />
        );
    }

    return (
        <Controller
            control={control}
            name="tag"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <FormLabel>Select Tag</FormLabel>
                        <TagContainer setEnabledFreeText={handleEnabledFreeText}>
                            <Autocomplete
                                size="sm"
                                value={value}
                                options={options}
                                onChange={(_event, value) => {
                                    onChange(value);
                                }}
                                isOptionEqualToValue={(option, selected) => {
                                    return option.value === selected.value;
                                }}
                                inputValue={query}
                                onInputChange={(_, newInputValue) => {
                                    setQuery(newInputValue);
                                }}
                                endDecorator={
                                    isLoading ? (
                                        <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} />
                                    ) : null
                                }
                            />
                        </TagContainer>
                        {error !== undefined && <FormHelperText>Please select tag.</FormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

function ActivityBody() {
    const { control } = useActivityFormState();

    return (
        <Controller
            control={control}
            name="body"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <FormLabel>Body</FormLabel>
                        <Textarea
                            size="sm"
                            minRows={15}
                            style={{ width: "100%" }}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            error={error !== undefined}
                        />
                        {error !== undefined && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

function useActivityFormState() {
    const { ...rest } = useFormContext<ActivityFormState>();

    return { ...rest };
}

function SelectAssignee() {
    const { control } = useActivityFormState();
    const { isLoading, data = [] } = useFetchOrganizationMembersQuery();
    const options = useMemo(() => data.map(({ name, _id }) => ({ label: name, value: _id })), [data]);
    const [query, setQuery] = useState("");
    return (
        <Controller
            control={control}
            name="assignee"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <FormLabel>Select Assignee</FormLabel>
                        <Autocomplete
                            size="sm"
                            value={value}
                            options={options}
                            onChange={(_event, value) => onChange(value)}
                            isOptionEqualToValue={(option, selected) => {
                                return option.value === selected.value;
                            }}
                            inputValue={query}
                            onInputChange={(_, newInputValue) => {
                                setQuery(newInputValue);
                            }}
                            endDecorator={
                                isLoading ? <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} /> : null
                            }
                        />
                        {error !== undefined && <FormHelperText>Please Select Assignee.</FormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

function SelectPriority() {
    const { control } = useActivityFormState();
    return (
        <Controller
            control={control}
            name="priority"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <FormLabel>Select Priority</FormLabel>
                        <Select value={value} onChange={(_, newValue) => onChange(newValue)} size="sm">
                            <Option
                                value={""}
                                sx={{
                                    fontSize: "14px",
                                }}
                            >
                                None
                            </Option>
                            {criticalityOptions.map((i) => {
                                return (
                                    <Option
                                        value={i}
                                        key={i}
                                        sx={{
                                            fontSize: "14px",
                                        }}
                                    >
                                        {i}
                                    </Option>
                                );
                            })}
                        </Select>
                        {error !== undefined && <FormHelperText>Please Select Priority.</FormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

export function SelectDueDate() {
    const { control } = useActivityFormState();
    return (
        <Experimental_CssVarsProvider>
            <Controller
                control={control}
                name="dueDate"
                render={({ field, fieldState: { error } }) => (
                    <MuiFormControl error={Boolean(error)}>
                        <MuiFormLabel sx={{ fontSize: 14, color: "#171a1c", fontFamily: "Inter", fontWeight: "500" }}>
                            Select Due date
                        </MuiFormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                disablePast
                                slotProps={{
                                    field: { clearable: true },
                                    actionBar: {
                                        actions: ["clear"],
                                    },
                                    textField: {
                                        variant: "standard",
                                        error: Boolean(error),
                                        helperText: error !== undefined ? "Please select valid time value" : null,
                                        FormHelperTextProps: { sx: { marginX: 0 } },
                                    },
                                }}
                                sx={(theme) => {
                                    const fontSize = theme.typography.body2;
                                    return {
                                        fontFamily: "Inter",
                                        width: "95%",
                                        marginTop: "0.2rem",
                                        "& .MuiFormLabel-root ": {
                                            ...fontSize,
                                            top: "-5px",
                                            '&[data-shrink="true"]': {
                                                top: "1px",
                                            },
                                        },
                                        "& .MuiInputBase-input": {
                                            ...fontSize,
                                            padding: "0.7rem",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline legend": {
                                            width: "57px",
                                        },
                                    };
                                }}
                                {...field}
                            />
                        </LocalizationProvider>
                        {error !== undefined && <FormHelperText>Please Select Assignee.</FormHelperText>}
                    </MuiFormControl>
                )}
            />
        </Experimental_CssVarsProvider>
    );
}

function ActivitySidebar() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const activityFormState = useSelector(selectActivityState);
    const submitMode = useSelector(selectActivitySubmitMode);
    const activityId = useSelector(selectActivityId);
    const [createActivity, { isLoading: isCreatingActivity, isSuccess: isCreatingActivitySuccess }] =
        useCreateActivityMutation();
    const [updateActivity, { isLoading: isUpdatingActivity, isSuccess: isUpdatingActivitySuccess }] =
        useUpdateActivityMutation();
    const isLoading = isCreatingActivity || isUpdatingActivity;
    const methods = useForm<ActivityFormState>({
        defaultValues: {
            ...activityFormState,
            dueDate: activityFormState?.dueDate ? dayjs(activityFormState.dueDate) : null,
        },
        resolver: zodResolver(activityFormStateSchema),
    });

    useEffect(() => {
        if (submitMode === "EDIT") {
            methods.reset({
                ...activityFormState,
                dueDate: activityFormState?.dueDate ? dayjs(activityFormState.dueDate) : null,
            });
        }

        if (submitMode === "CREATE") {
            methods.reset(initialEditActivityState);
        }
    }, [activityFormState, submitMode]);

    useEffect(() => {
        if (isCreatingActivitySuccess || isUpdatingActivitySuccess) {
            methods.reset(initialEditActivityState);
            dispatch(setEditDrawer("CLOSE"));
        }
    }, [isCreatingActivitySuccess, isUpdatingActivitySuccess, methods]);

    const onSubmit: SubmitHandler<ActivityFormState> = (data) => {
        const { body, project, tag, assignee, dueDate, priority } = data;
        if (params?.id && tag && project?.value) {
            const payload: CreateActivityPayload = {
                body: body,
                projectId: project.value,
                title: typeof tag === "string" ? tag : tag?.value,
                candidateId: params?.id,
                dueDate: dueDate ? dueDate.toISOString() : undefined,
                priority,
            };

            if (assignee?.value) {
                payload.referTo = assignee?.value;
            }

            if (submitMode === "CREATE") {
                createActivity(payload);
            }

            if (submitMode === "EDIT" && activityId) {
                updateActivity({ ...payload, activityId });
            }
        }
    };

    const btnText = submitMode === "EDIT" ? "Update" : "Create";

    const handleCancel = () => {
        dispatch(setEditDrawer("CLOSE"));
        methods.reset(initialEditActivityState);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack
                    py={2}
                    pr={2}
                    mx={2}
                    gap={2}
                    sx={{
                        overflow: "auto",
                        height: "calc(100vh - 147px)",
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <SelectProject />
                    <SelectTag />
                    <SelectAssignee />
                    <SelectPriority />
                    <SelectDueDate />
                    <ActivityBody />
                    <Stack direction="row" alignSelf="flex-end" gap={1}>
                        <Button variant="solid" type="reset" onClick={handleCancel} size="sm">
                            Cancel
                        </Button>
                        <Button variant="solid" type="submit" disabled={isLoading} loading={isLoading} size="sm">
                            {btnText}
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
}

export function ActivitiesLoader() {
    return (
        <>
            {Array(3)
                .fill(1)
                .map((_, idx) => {
                    return <ActivitiesCardLoader key={`activities-loader-${idx}`} />;
                })}
        </>
    );
}

export function EmptyActivitiesPlaceholder() {
    const isEasyGrowth = useSelector(checkEasyGrowth);
    return (
        <CandidatesReachoutEmptyPlaceholder
            icon={<BallotRoundedIcon sx={{ fontSize: "4rem", color: grey[400] }} />}
            title="No Activity found"
            message={`Activities has not been added for this ${isEasyGrowth ? "prospect" : "candidate"} on this project.`}
        />
    );
}
