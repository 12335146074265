import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContactsIcon from "@mui/icons-material/Contacts";
import GroupIcon from "@mui/icons-material/Group";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import { Box, IconButton, Skeleton, Stack, Switch, Tooltip } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { Link } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AlertToggle from "./components/AlertToggle";
import SplitChart from "./components/SplitChart";

import CustomDropdown, { CustomDropdownOptionsType } from "../../../../components/CustomDropdown/CustomDropdown";
import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { formatDate } from "../../../../utils/helper";
import {
    fetchEmailCredit,
    fetchEmailEnrichmentCredit,
    fetchLinkedinOutreachCredit,
    fetchOrganizationDetails,
    fetchPhoneEnrichmentCredit,
    fetchProfileCreditInfo,
    fetchSmsOutreachCredit,
    selectEmailCredit,
    selectEmailEnrichmentCredit,
    selectLinkedinOutreachCredit,
    selectOrganizationDetails,
    selectPhoneEnrichmentCredit,
    selectProfileCreditInfo,
    selectSmsOutreachCredit,
} from "../../myAccount.reducer";

import Loader from "@/components/Loader/Loader";

const getUserAdminCount = (members: any[]) => {
    const { noOfAdmins, noOfUsers } = members.reduce(
        (acc, member) => {
            if (member.role === "ADMIN") {
                acc.noOfAdmins += 1;
            } else {
                acc.noOfUsers += 1;
            }
            return acc;
        },
        { noOfAdmins: 0, noOfUsers: 0 }
    );

    return { noOfAdmins, noOfUsers };
};

const checkUnlimited = (value: number) => {
    if (!value) return "NA";
    return value === -1 ? "Unlimited" : value;
};

const Header = ({ organizationDetails }: any) => {
    const { licenseCount, projectCreditCount, profileCreditCount, contactDataCreditCount, isContractActive } =
        organizationDetails?.contractDetails || {};

    const { noOfAdmins, noOfUsers } = getUserAdminCount(organizationDetails?.members || []);

    return (
        <Stack
            px={3}
            py={4}
            sx={{
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                borderRadius: "0.5rem",
                marginX: "1rem",
                marginTop: "2rem",
            }}
        >
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography level="h4">Subscription plan</Typography>
                <Stack gap={0.75} direction="row" alignItems={"center"}>
                    {isContractActive ? (
                        <CheckCircleIcon sx={{ fontSize: "1.25rem", color: "#1BB267" }} />
                    ) : (
                        <CancelIcon sx={{ fontSize: "1.25rem", color: "#FF4D4F" }} />
                    )}

                    <Typography level="body-sm">{isContractActive ? "Active" : "Inactive"}</Typography>
                </Stack>
            </Stack>
            <Stack direction={"row"} paddingTop={"1rem"} justifyContent={"space-between"} alignItems={"stretch"}>
                <Stack sx={{ paddingX: "2rem", paddingTop: "1rem" }}>
                    <Stack direction={"row"} alignItems={"flex-start"} gap={0.75}>
                        <span style={{ paddingTop: "0.25rem" }}>
                            <LocalPoliceIcon sx={{ fontSize: "1.25rem", color: "#1BB267" }} />
                        </span>
                        <div>
                            <Typography fontWeight={500} level="body-lg">
                                {checkUnlimited(licenseCount)} Licenses
                            </Typography>
                            <Typography level="body-sm">{noOfUsers} Users</Typography>
                            <Typography level="body-sm">{noOfAdmins} Admins</Typography>
                        </div>
                    </Stack>
                </Stack>
                <Box sx={{ border: "0.5px solid #E0E1E3", alignSelf: "center", height: "80px" }} />

                <Stack sx={{ paddingX: "2rem", paddingTop: "1rem" }}>
                    <Stack direction={"row"} alignItems={"flex-start"} gap={0.75}>
                        <span style={{ paddingTop: "0.25rem" }}>
                            <AllInclusiveIcon sx={{ fontSize: "1.25rem", color: "#CA2B79" }} />
                        </span>
                        <div>
                            <Typography fontWeight={500} level="body-lg">
                                {checkUnlimited(projectCreditCount)}
                            </Typography>
                            <Typography level="body-sm">projects</Typography>
                        </div>
                    </Stack>
                </Stack>
                <Box sx={{ border: "0.5px solid #E0E1E3", alignSelf: "center", height: "80px" }} />
                <Stack sx={{ paddingX: "2rem", paddingTop: "1rem" }}>
                    <Stack direction={"row"} alignItems={"flex-start"} gap={0.75}>
                        <span style={{ paddingTop: "0.25rem" }}>
                            <GroupIcon sx={{ fontSize: "1.25rem", color: "#FF9752" }} />
                        </span>
                        <div>
                            <Typography fontWeight={500} level="body-lg">
                                {checkUnlimited(profileCreditCount)}
                            </Typography>
                            <Typography level="body-sm">profiles credits</Typography>
                        </div>
                    </Stack>
                </Stack>
                <Box sx={{ border: "0.5px solid #E0E1E3", alignSelf: "center", height: "80px" }} />
                <Stack sx={{ paddingX: "2rem", paddingTop: "1rem" }}>
                    <Stack direction={"row"} alignItems={"flex-start"} gap={0.75}>
                        <span style={{ paddingTop: "0.25rem" }}>
                            <ContactsIcon sx={{ fontSize: "1.25rem", color: "#136EF6" }} />
                        </span>
                        <div>
                            <Typography fontWeight={500} level="body-lg">
                                {checkUnlimited(contactDataCreditCount)}
                            </Typography>
                            <Typography level="body-sm">contact data credits</Typography>
                        </div>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

const Wrapper = ({ children }: { children: ReactNode }) => {
    return (
        <Stack
            p={3}
            sx={{
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                borderRadius: "0.5rem",
                marginX: "1rem",
            }}
        >
            {children}
        </Stack>
    );
};

const selectorDispatcherMapping = {
    PROFILE_CREDIT: {
        label: "Profile credits",
        selector: selectProfileCreditInfo,
        dispatcher: fetchProfileCreditInfo,
    },
    LINKEDIN_OUTREACH_CREDIT: {
        label: "Linkedin outreach",
        selector: selectLinkedinOutreachCredit,
        dispatcher: fetchLinkedinOutreachCredit,
    },
    SMS_OUTREACH_CREDIT: {
        label: "SMS outreach",
        selector: selectSmsOutreachCredit,
        dispatcher: fetchSmsOutreachCredit,
    },
    EMAIL_CREDIT: {
        label: "Email outreach delivery",
        selector: selectEmailCredit,
        dispatcher: fetchEmailCredit,
    },
    EMAIL_ENRICHMENT_CREDIT: {
        label: "Email enrichment credits",
        selector: selectEmailEnrichmentCredit,
        dispatcher: fetchEmailEnrichmentCredit,
    },
    PHONE_ENRICHMENT_CREDIT: {
        label: "Phone enrichment credits",
        selector: selectPhoneEnrichmentCredit,
        dispatcher: fetchPhoneEnrichmentCredit,
    },
};

const CreditsCardWithSelector = ({ item }: any) => {
    const dispatch = useDispatch();
    const { type, maxC, statsType, selectedView, handleViewChange, tooltipTitle, alertStatus } = item;
    const data: any[] = useSelector(selectorDispatcherMapping[type].selector) || [];
    const isLoading = useSelector(checkIfLoading(selectorDispatcherMapping[type].dispatcher.type));

    const [expandedView, setExpandedView] = useState(true);

    const options: CustomDropdownOptionsType[] = [
        {
            label: "Org Level",
            key: "ORG",
            handleClick: () => {
                handleViewChange(type, "ORG");
            },
        },
        {
            label: "Project Level",
            key: "PROJECT",
            handleClick: () => {
                handleViewChange(type, "PROJECT");
            },
        },
        {
            label: "User Level",
            key: "USER",
            handleClick: () => {
                handleViewChange(type, "USER");
            },
        },
        ...(type === "EMAIL_ENRICHMENT_CREDIT"
            ? [
                  {
                      label: "Email Type",
                      key: "EMAIL_TYPE",
                      handleClick: () => {
                          handleViewChange(type, "EMAIL_TYPE");
                      },
                  },
              ]
            : []),
    ];

    return (
        <Stack
            // pb={expandedView ? 4 : 0}
            sx={{
                transition: "all 0.2s",
            }}
        >
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Stack alignItems={"center"} gap={0.5} direction={"row"}>
                    <Typography level="body-sm">{selectorDispatcherMapping[type].label}</Typography>
                    <Tooltip title={item?.tooltipTitle}>
                        <HelpOutlineIcon sx={{ fontSize: "1rem" }} />
                    </Tooltip>
                </Stack>

                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={1}>
                    {statsType === "current" && (
                        <AlertToggle alertStatus={alertStatus} alertFor={type} disabled={maxC === -1} />
                    )}
                    <MaterialProvider>
                        <CustomDropdown options={options} tooltipTitle="Change view">
                            <Typography
                                level="body-sm"
                                fontSize={"14px"}
                                sx={{
                                    border: "1px solid gray",
                                    padding: "4px 10px",
                                    borderRadius: 1,
                                    textTransform: "capitalize",
                                }}
                            >
                                {selectedView.split("_").join(" ").toLowerCase()}
                            </Typography>
                        </CustomDropdown>
                    </MaterialProvider>
                    <IconButton size="sm" onClick={() => setExpandedView(!expandedView)} variant="outlined">
                        {!expandedView ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </IconButton>
                </Stack>
            </Stack>
            {expandedView && (
                <>
                    {isLoading && (
                        <Stack mt={2}>
                            <Skeleton variant="rectangular" height={15} />
                            <Stack justifyContent={"space-between"} direction={"row"} mt={1}>
                                <Skeleton variant="text" height={20} width={80} />
                                <Skeleton variant="text" height={20} width={80} />
                            </Stack>
                        </Stack>
                    )}

                    {!isLoading && data && data?.length === 0 && (
                        <Stack mt={2}>
                            <Typography level="body-sm">No data available</Typography>
                        </Stack>
                    )}

                    {!isLoading && data && data?.length > 0 && (
                        <>
                            {selectedView === "ORG" && (
                                <Stack mt={2}>
                                    <SplitChart data={data} totalCredits={maxC} isLoading={isLoading} />
                                </Stack>
                            )}

                            {selectedView === "USER" && (
                                <Stack mt={2}>
                                    <SplitChart
                                        data={data}
                                        totalCredits={maxC}
                                        isLoading={isLoading}
                                        onlyLabelForMax
                                        showEmptySegments
                                    />
                                </Stack>
                            )}

                            {selectedView === "PROJECT" ? (
                                <Stack mt={2}>
                                    <SplitChart data={data} totalCredits={maxC} isLoading={isLoading} onlyLabelForMax />
                                </Stack>
                            ) : null}

                            {selectedView === "EMAIL_TYPE" && (
                                <Stack mt={2}>
                                    <SplitChart data={data} isLoading={isLoading} />
                                </Stack>
                            )}
                        </>
                    )}
                </>
            )}
        </Stack>
    );
};

const CreditPlans = () => {
    const dispatch = useDispatch();
    const [statsType, setStatsType] = useState<"current" | "lifetime">("current");
    const isOrgLoading = useSelector(checkIfLoading(fetchOrganizationDetails.type));

    const [selectedViews, setSelectedViews] = useState<{ [key: string]: string }>({
        PROFILE_CREDIT: "ORG",
        LINKEDIN_OUTREACH_CREDIT: "ORG",
        SMS_OUTREACH_CREDIT: "ORG",
        EMAIL_CREDIT: "ORG",
        EMAIL_ENRICHMENT_CREDIT: "ORG",
        PHONE_ENRICHMENT_CREDIT: "ORG",
    });

    // Organaization details
    const organizationDetails = useSelector(selectOrganizationDetails);
    const {
        cycleStartDate,
        cycleEndDate,
        cycleType,
        profileCreditCount,
        linkedinOutreachCreditCount,
        smsOutreachCreditCount,
        emailOutreachCreditCount,
        emailEnrichmentCreditCount,
        phoneEnrichmentCreditCount,
        notificationPreferences,
    } = organizationDetails?.contractDetails || {};

    useEffect(() => {
        dispatch(
            fetchOrganizationDetails({
                action: fetchOrganizationDetails.type,
            })
        );
    }, []);

    useEffect(() => {
        if (cycleStartDate && cycleEndDate) {
            Object.keys(selectedViews).forEach((type) => {
                dispatchCreditData(type, selectedViews[type], statsType);
            });
        }
    }, [statsType, cycleStartDate, cycleEndDate]);

    const handleStatsTypeChange = () => {
        setStatsType(statsType !== "lifetime" ? "lifetime" : "current");
    };

    const handleViewChange = (type: string, view: string) => {
        setSelectedViews((prev) => ({ ...prev, [type]: view }));
        dispatchCreditData(type, view, statsType);
    };

    const dispatchCreditData = (type, view, statsType) => {
        dispatch(
            selectorDispatcherMapping[type].dispatcher({
                action: selectorDispatcherMapping[type].dispatcher.type,
                creditPayload: {
                    type: view,
                    endDate: cycleEndDate,
                    ...(statsType !== "lifetime" && { startDate: cycleStartDate }),
                },
            })
        );
    };

    // const alertStatus = {
    //     EMAIL_CREDIT: true,
    //     EMAIL_ENRICHMENT_CREDIT: true,
    //     LINKEDIN_OUTREACH_CREDIT: true,
    //     PHONE_ENRICHMENT_CREDIT: true,
    //     PROFILE_CREDIT: false,
    //     SMS_OUTREACH_CREDIT: true,
    // };

    // console.log("alertStatus.PROFILE_CREDIT", alertStatus.PROFILE_CREDIT);

    const items = [
        {
            type: "PROFILE_CREDIT",
            tooltipTitle: "Profile credits are used for fetching profiles from various sources",
            maxC: profileCreditCount || 5000,
            alertStatus: notificationPreferences?.PROFILE_CREDIT || false,
        },
        {
            type: "LINKEDIN_OUTREACH_CREDIT",
            tooltipTitle: "Linkedin outreach credits are used for sending messages to linkedin profiles",
            maxC: linkedinOutreachCreditCount || 5000,
            alertStatus: notificationPreferences?.LINKEDIN_OUTREACH_CREDIT || false,
        },
        {
            type: "SMS_OUTREACH_CREDIT",
            tooltipTitle: "SMS outreach credits are used for sending messages to phone numbers",
            maxC: smsOutreachCreditCount || 5000,
            alertStatus: notificationPreferences?.SMS_OUTREACH_CREDIT || false,
        },
        {
            type: "EMAIL_CREDIT",
            tooltipTitle: "Email outreach credits are used for sending emails to email addresses",
            maxC: emailOutreachCreditCount || 5000,
            alertStatus: notificationPreferences?.EMAIL_CREDIT || false,
        },
        {
            type: "EMAIL_ENRICHMENT_CREDIT",
            tooltipTitle: "Email enrichment credits are used for enriching email addresses",
            maxC: emailEnrichmentCreditCount || 5000,
            alertStatus: notificationPreferences?.EMAIL_ENRICHMENT_CREDIT || false,
        },
        {
            type: "PHONE_ENRICHMENT_CREDIT",
            tooltipTitle: "Phone enrichment credits are used for enriching phone numbers",
            maxC: phoneEnrichmentCreditCount || 5000,
            alertStatus: notificationPreferences?.PHONE_ENRICHMENT_CREDIT || false,
        },
    ];

    return (
        <JoyProvider>
            {isOrgLoading && (
                <div
                    style={{
                        height: "80vh",
                    }}
                >
                    <Loader />
                </div>
            )}
            {!isOrgLoading && (
                <Stack direction={"column"} gap={2}>
                    <Stack
                        sx={{
                            height: "calc(100vh - 150px)",
                            width: "75%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            overflowY: "scroll",
                        }}
                    >
                        <Header organizationDetails={organizationDetails} />
                        <Stack px={3} py={4} direction="row" alignItems={"flex-start"} justifyContent={"space-between"}>
                            <div>
                                <Typography pb={"0.25rem"} level="h4">
                                    {statsType === "current" ? "Current usage cycle" : "Lifetime usage stats"}
                                </Typography>
                                <Typography level="body-sm">{cycleType ?? "NA"} credit cycle</Typography>
                                <Typography level="body-sm">
                                    Resets on <b>{cycleEndDate ? formatDate(cycleEndDate, "D MMMM YYYY") : "NA"} </b>
                                </Typography>
                            </div>

                            <Typography
                                level="body-sm"
                                component="label"
                                endDecorator={<Switch sx={{ ml: 1 }} onChange={handleStatsTypeChange} />}
                            >
                                View lifetime stats
                            </Typography>
                        </Stack>

                        <Stack gap={3}>
                            {items.map((item) => (
                                <Wrapper key={item?.type}>
                                    <CreditsCardWithSelector
                                        item={{
                                            ...item,
                                            selectedView: selectedViews[item.type],
                                            handleViewChange,
                                            statsType,
                                        }}
                                    />
                                </Wrapper>
                            ))}
                        </Stack>

                        <Typography px={3} paddingY={10} paddingBottom={5} level="body-sm">
                            {/* Reach out to customer-support@hirequotient.com for any discussion on credits cycle */}
                            Reach out to
                            <Link href="mailto:customer-support@hirequotient.com">
                                {" "}
                                customer-support@hirequotient.com{" "}
                            </Link>
                            for any discussion on credits cycle
                        </Typography>
                    </Stack>
                </Stack>
            )}
        </JoyProvider>
    );
};

export default CreditPlans;
