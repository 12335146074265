import { Menu, MenuItem } from "@mui/material";
import { MaterialProvider } from "@/components/MaterialProvider";

const options = {
    "Sort by Latest": "latest",
    "Sort by Unread": "unread",
};

export const SortMenu = ({ anchorEl, open, onClose, onSort, sortType }) => (
    <MaterialProvider>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            {Object.entries(options).map(([label, type]) => (
                <MenuItem key={type} onClick={() => onSort(type)} selected={sortType === type}>
                    {label}
                </MenuItem>
            ))}
        </Menu>
    </MaterialProvider>
);
