/**
 * Handles the highlighting of text within the content div based on the search text.
 * @param {string} searchText - The text to search and highlight in the content.
 * @param {HTMLElement} root - The root element in which to search for text.
 * @param {boolean} removePreviousHighlights - Whether to remove previous highlights before highlighting new text.
 */
export function highlightText(searchText: string, root: HTMLElement, removePreviousHighlights: boolean = true) {
    if (!root) {
        console.error("root element not found on highlightText");
        return;
    }

    // Remove previous highlights
    if (removePreviousHighlights) {
        removeHighlights(root);
    }

    if (searchText) {
        safeHighlightMatches(root, searchText);
    }
}

/**
 * Removes all highlighted marks from the specified element.
 * @param {HTMLElement} element - The element from which to remove highlights.
 */
function removeHighlights(element: HTMLElement) {
    const marks = element.querySelectorAll("mark.search-highlight");
    marks.forEach((mark) => {
        const parent = mark.parentNode;
        if (parent) {
            parent.replaceChild(document.createTextNode(mark.textContent || ""), mark);
            parent.normalize();
        }
    });
}

/**
 * Safely highlights matching text within the specified element.
 * @param {HTMLElement} element - The element in which to highlight text.
 * @param {string} searchText - The text to highlight.
 */
function safeHighlightMatches(element: HTMLElement, searchText: string) {
    const regex = new RegExp(escapeRegExp(searchText), "gi");
    const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null);

    let node: Node | null;
    const nodesToHighlight: Node[] = [];

    while ((node = walker.nextNode())) {
        if (
            node.parentNode &&
            node.parentNode.nodeName !== "SCRIPT" &&
            node.parentNode.nodeName !== "STYLE" &&
            !node.parentNode.closest("input, textarea")
        ) {
            if (regex.test(node.textContent || "")) {
                nodesToHighlight.push(node);
            }
        }
    }

    nodesToHighlight.forEach((node) => highlightNode(node, regex));
}

/**
 * Highlights a single node with the specified regex.
 * @param {Node} node - The node to highlight.
 * @param {RegExp} regex - The regex used to find matches in the node's text.
 */
function highlightNode(node: Node, regex: RegExp) {
    const fragment = document.createDocumentFragment();
    let lastIndex = 0;
    let match: RegExpExecArray | null;

    while ((match = regex.exec(node.textContent || ""))) {
        const beforeText = (node.textContent || "").substring(lastIndex, match.index);
        fragment.appendChild(document.createTextNode(beforeText));

        const mark = document.createElement("mark");
        mark.className = "search-highlight";
        mark.textContent = match[0];
        fragment.appendChild(mark);

        lastIndex = regex.lastIndex;
    }

    if (lastIndex < (node.textContent || "").length) {
        fragment.appendChild(document.createTextNode((node.textContent || "").substring(lastIndex)));
    }

    node.parentNode?.replaceChild(fragment, node);
}

/**
 * Escapes special characters in a string for use in a regular expression.
 * @param {string} string - The string to escape.
 * @returns {string} The escaped string.
 */
function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
