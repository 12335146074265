import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Info from "@mui/icons-material/Info";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import millify from "millify";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AccountLists } from "./AccountLists";

import crunchbaseLogo from "../../../assets/img/crunchbase-logo.png";
import { CompanyHeadQuarterLocation } from "../../../components/BlendedSearch/Filters/CompanyHeadquarterLocation";
import { CompanyType } from "../../../components/BlendedSearch/Filters/CompanyType";
import { CurrentCompanyExperience } from "../../../components/BlendedSearch/Filters/CurrentCompanyExperience";
import { CurrentPositionExperience } from "../../../components/BlendedSearch/Filters/CurrentPositionExperience";
import { Degree } from "../../../components/BlendedSearch/Filters/Degree";
import { FundingTotalAmountRange } from "../../../components/BlendedSearch/Filters/FundingTotalAmountRange";
import { FundingType } from "../../../components/BlendedSearch/Filters/FundingType";
import { InvestorName } from "../../../components/BlendedSearch/Filters/InvestorName";
import { IPODateRange } from "../../../components/BlendedSearch/Filters/IPODateRange";
import { IPOStatus } from "../../../components/BlendedSearch/Filters/IPOStatus";
import { LastFundingDateRange } from "../../../components/BlendedSearch/Filters/LastFundingDateRange";
import { LastFundingTotalAmountRange } from "../../../components/BlendedSearch/Filters/LastFundingTotalAmountRange";
import { ProfileLanguage } from "../../../components/BlendedSearch/Filters/ProfileLanguage";
import { RevenueRange } from "../../../components/BlendedSearch/Filters/RevenueRange";
import { School } from "../../../components/BlendedSearch/Filters/School";
import { SharesInvestorsWith } from "../../../components/BlendedSearch/Filters/SharesInvestorsWith";
import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { CompanyHeadcount } from "../../../components/CompanyHeadcount";
import { CurrentCompany } from "../../../components/CurrentCompany";
import { Geography } from "../../../components/Geography";
import { Industry } from "../../../components/Industry";
import { JobFunction } from "../../../components/JobFunction";
import { JobTitle } from "../../../components/JobTitle";
import { Keywords } from "../../../components/Keywords";
import { Seniority } from "../../../components/Seniority";
import { UploadCompanyList } from "../../../components/UploadCompanyList";
import { YearsOfExperience } from "../../../components/YearsOfExperience";
import { getProject } from "../../../pages/allProjects/index.reducer";
import { isBlendSearchCompaniesEnabled } from "../../../pages/Signin/Signin.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import {
    selectAccountListsSearch,
    selectCurrentCompany,
    selectNoOfCompanies,
    submitSearchLite,
} from "../../../store/reducers/search/search.slice";
import {
    selectAccountLists,
    selectSearch,
    selectTotalCompanies,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";

export default function SidebarForm() {
    const ref = useRef<HTMLDivElement | null>(null);
    const handleSubmit = () => {
        dispatch(submitSearchLite({ navigate }));
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const projectNameFromUrl = searchParams.get("name");
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";

    const navigate = useNavigate();
    const checkBlendSearchCompaniesEnabled = useSelector(isBlendSearchCompaniesEnabled);
    const dispatch = useDispatch();
    const { status } = useSelector(selectSearch);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const isSubmittingSearch = useSelector(checkIfLoading(submitSearchLite.type));
    const isLoading = isSubmittingSearch || status === "PENDING" || status === "IN_PROGRESS";
    const [projectName, setProjectName] = useState(projectNameFromUrl);
    const [expanded, setExpanded] = useState(true);
    const currentCompany = useSelector(selectCurrentCompany);
    const { values: noOfCompaniesArr } = useSelector(selectNoOfCompanies);
    const noOfCompanies = noOfCompaniesArr[0]?.value;
    const totalCompanies = useSelector(selectTotalCompanies);
    const { values: selectedAccounts } = useSelector(selectAccountListsSearch);
    const accountLists = useSelector(selectAccountLists);
    const selectedAccountIds = selectedAccounts?.map((account) => account.value);

    const totalCompaniesInAccountList = selectedAccountIds?.length
        ? accountLists.reduce((acc, accountList) => {
              if (selectedAccountIds.includes(accountList._id)) {
                  return acc + (accountList?.salesnavcompany?.universeCount ?? 0);
              }
              return acc;
          }, 0)
        : 0;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    let showSelectedNoOfCompanies: number = currentCompany?.values?.length;

    if (noOfCompanies === totalCompanies) {
        showSelectedNoOfCompanies = parseInt(totalCompanies, 10);
    }
    if (parseInt(noOfCompanies, 10) > 1) {
        showSelectedNoOfCompanies = parseInt(noOfCompanies, 10);
    }
    if (currentCompany?.values?.length > showSelectedNoOfCompanies) {
        showSelectedNoOfCompanies = showSelectedNoOfCompanies;
    }
    if (totalCompaniesInAccountList > 0) {
        showSelectedNoOfCompanies = totalCompaniesInAccountList;
    }

    return (
        <>
            {isProjectLoading ? (
                <Stack
                    bgcolor="white"
                    borderRadius={(theme) => theme.shape.borderRadius}
                    p={3}
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            ) : (
                <Stack
                    ref={ref}
                    bgcolor="white"
                    borderRadius={(theme) => theme.shape.borderRadius}
                    p={3}
                    gap={2}
                    overflow="auto"
                >
                    {projectId ? (
                        <Typography>{projectName}</Typography>
                    ) : (
                        <TextField
                            size="small"
                            label="Campaign Name"
                            variant="standard"
                            value={projectName}
                            error={projectNameErr}
                            helperText={errorInSearch ? "Campaign name is required" : ""}
                            onChange={(e) => {
                                setProjectName(e.target.value);
                                if (!e.target.value) {
                                    searchParams.delete("name");
                                    setSearchParams(searchParams);
                                }
                            }}
                            onBlur={() => {
                                if (projectName) {
                                    setSearchParams({ name: projectName });
                                }
                            }}
                        />
                    )}

                    <Typography component={"b"}>People filters</Typography>
                    <Geography id="geography" />
                    <YearsOfExperience id="yearsOfExperience" />
                    <CurrentCompanyExperience id="currentCompanyExperience" />
                    <CurrentPositionExperience id="currentPositionExperience" />
                    <JobTitle id="jobTitle" />
                    <JobFunction id="jobFunction" />
                    <Keywords id="keywords" />
                    <School id="school" />
                    <Degree id="degree" />
                    <ProfileLanguage id="profileLanguage" />
                    <Seniority id="seniority" />
                    <Industry id="industry" />
                    <CompanyHeadQuarterLocation id="companyHeadQuarterLocation" />
                    <CompanyHeadcount id="companyHeadcount" />
                    <CompanyType id="companyType" />

                    <Stack
                        gap={1}
                        p={2}
                        pt={1}
                        borderRadius={2}
                        bgcolor={"#f9fcff"}
                        border={"1px solid rgba(0,0,0,0.2)"}
                        sx={{ width: "100%" }}
                    >
                        {checkBlendSearchCompaniesEnabled ? (
                            <div>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography fontSize={14} fontWeight={500} color="rgba(0, 0, 0, 0.6)">
                                        Select companies
                                    </Typography>
                                    <IconButton onClick={handleExpandClick} size="small">
                                        <ArrowDropDownIcon />
                                    </IconButton>
                                </Box>
                                {showSelectedNoOfCompanies > 0 ? (
                                    <Typography
                                        fontSize={12}
                                        color="rgba(0, 0, 0, 0.6)"
                                        style={{ marginTop: "-5px", marginLeft: "5px" }}
                                    >
                                        {showSelectedNoOfCompanies > 1
                                            ? `${millify(showSelectedNoOfCompanies)} companies selected`
                                            : `${millify(showSelectedNoOfCompanies)} company selected`}
                                    </Typography>
                                ) : null}

                                {expanded && (
                                    <Stack gap={1}>
                                        <CurrentCompany id="currentCompany" />
                                        <>
                                            <UploadCompanyList id="companyCSVUrl" />
                                            <AccountLists />
                                        </>
                                    </Stack>
                                )}
                            </div>
                        ) : (
                            <Stack gap={1} borderRadius={2} mt={1}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography fontSize={14} fontWeight={500} color="rgba(0, 0, 0, 0.7)">
                                        Companies
                                    </Typography>
                                </Box>
                                <CurrentCompany id="currentCompany" />
                            </Stack>
                        )}

                        <Divider sx={{ mt: 3, mb: 1 }}> Or </Divider>

                        <Stack pt={2}>
                            <Typography component={"b"}>Additional company filters</Typography>
                            <Typography
                                component={"i"}
                                variant="caption"
                                sx={{ display: "flex", gap: 1, alignItems: "center", ml: 6 }}
                            >
                                powered by Crunchbase{" "}
                                <img style={{ height: 20, width: 20 }} src={crunchbaseLogo} alt="crunchbase-logo" />
                                <Tooltip
                                    title={
                                        "Filter additional companies using Crunchbase company search. This may lead to fewer results and is recommended for very specific searches"
                                    }
                                >
                                    <Info fontSize="small" color="disabled" />
                                </Tooltip>
                            </Typography>
                        </Stack>

                        <InvestorName id="investorName" />
                        <SharesInvestorsWith id="sharesInvestorsWith" />
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                            <Checkbox />
                            <Typography>Actively hiring</Typography>{" "}
                            <Typography component={"i"} fontSize={12} color={"GrayText"}>
                                (coming soon)
                            </Typography>
                        </Stack>
                        <IPOStatus id="ipoStatus" />
                        <IPODateRange id="ipoDateRange" />
                        <FundingType id="fundingType" />
                        <FundingTotalAmountRange id="fundingTotalAmountRange" />
                        <LastFundingDateRange id="lastFundingDateRange" />
                        <LastFundingTotalAmountRange id="lastFundingTotalAmountRange" />
                        <RevenueRange id="revenue" />
                    </Stack>
                    <Button
                        variant="contained"
                        sx={{ alignSelf: "center" }}
                        onClick={handleSubmit}
                        disabled={isSubmittingSearch}
                    >
                        <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" />
                    </Button>
                </Stack>
            )}
        </>
    );
}
