import { useState } from "react";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import MUIModal from "@mui/material/Modal";

import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";

import BaseModal from "../components/BaseModal";
import UploadSection from "./UploadSection";

export default function FileUpload() {
    const [showModal, setShowModal] = useState(false);
    const handleOpen = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    return (
        <>
            <Tooltip title="Attach Email files" placement="top">
                <IconButton sx={{ margin: 0, padding: "5px" }} onClick={handleOpen}>
                    <AttachFileRoundedIcon fontSize="medium" />
                </IconButton>
            </Tooltip>
            <MUIModal open={showModal} onClose={handleClose}>
                <BaseModal onClose={handleClose}>
                    <UploadSection close={handleClose} />
                </BaseModal>
            </MUIModal>
        </>
    );
}
