import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { QUERIES } from "./autopilot.enums";
import { TAutoPilotStats, TPauseAutoPilotBody, TRestartAutoPilotBody, TTriggerAutoPilotBody } from "./autopilot.types";

import { fetchBaseQueryWithZod } from "../utils";

export const autoPilotApi = createApi({
    reducerPath: "autoPilotApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [QUERIES.AUTO_PILOT_STATS, QUERIES.AUTO_PILOT_PREVOIUS_STATS, QUERIES.AUTO_PILOT_SETTINGS],
    endpoints: (builder) => ({
        getProjectAutopilotData: builder.query<TAutoPilotStats[], number>({
            query: (projectId) => ({
                url: `/searches/autopilot-stats/${projectId}`,
                method: "GET",
            }),
            providesTags: [QUERIES.AUTO_PILOT_STATS],
            transformResponse(baseQueryReturnValue: { data: TAutoPilotStats[] }) {
                return baseQueryReturnValue?.data;
            },
        }),
        getPrevAutoPilotData: builder.query<TAutoPilotStats[], number>({
            query: (projectId) => ({
                url: `/searches/autopilot-stats/${projectId}?limit=${100}`,
                method: "GET",
            }),
            providesTags: [QUERIES.AUTO_PILOT_PREVOIUS_STATS],
            transformResponse(baseQueryReturnValue: { data: TAutoPilotStats[] }) {
                return baseQueryReturnValue?.data;
            },
        }),
        saveAutopilotSettings: builder.mutation<any, any>({
            query(body) {
                return {
                    url: "/v2/project/addAutopilotSettings",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [QUERIES.AUTO_PILOT_SETTINGS],
        }),
        fetchAutoPilotSettings: builder.query<any, any>({
            query: (body) => ({
                url: "/v2/project/getAutopilotSettings",
                method: "POST",
                body,
            }),
            providesTags: [QUERIES.AUTO_PILOT_SETTINGS],
            transformResponse(baseQueryReturnValue: { data: any[] }) {
                return baseQueryReturnValue?.data;
            },
        }),

        triggerAutoPilot: builder.mutation<any, TTriggerAutoPilotBody>({
            query(body) {
                return {
                    url: "/searches/trigger-autopilot",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [QUERIES.AUTO_PILOT_STATS],
        }),
        pauseAutoPilot: builder.mutation<any, TPauseAutoPilotBody>({
            query(body) {
                return {
                    url: "/searches/pause-autopilot",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [QUERIES.AUTO_PILOT_STATS],
        }),
        restartAutoPilot: builder.mutation<any, TRestartAutoPilotBody>({
            query(body) {
                return {
                    url: "/searches/restart-autopilot",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [QUERIES.AUTO_PILOT_STATS],
        }),
    }),
});

export const {
    useGetProjectAutopilotDataQuery,
    useTriggerAutoPilotMutation,
    usePauseAutoPilotMutation,
    useGetPrevAutoPilotDataQuery,
    useRestartAutoPilotMutation,
    useSaveAutopilotSettingsMutation,
    useFetchAutoPilotSettingsQuery,
} = autoPilotApi;
