import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { SyntheticEvent } from "react";
import { HeaderTab } from "../types";
import { changeHeaderTab, selectCustomHeaderTab } from "../reducers/personalizedWorkflow.slice";
import { checkSMSEnabled } from "../../Signin/Signin.reducer";

const TransparentTabs = styled(Tabs)({
    "&.MuiTabs-root": {
        minHeight: "fit-content",
        height: "fit-content",
    },
    "& .MuiTabs-scroller": {
        height: "fit-content",
    },
    "& .MuiTabs-flexContainer": {
        justifyContent: "center",
        gap: "0.5rem",
        alignItems: "center",
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
    },
});

const CustomTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    background: "rgba(0, 0, 0, 0.05)",
    fontSize: "12px",
    borderRadius: "10px",
    minHeight: "fit-content",
    padding: "0.5rem",
    color: "#000",
    "&:hover": {
        color: "#479bd2",
        opacity: 1,
    },
    "&.Mui-selected": {
        color: "#fff",
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#479bd2",
    },
}));

type HeaderTabState = readonly [HeaderTab, (_: SyntheticEvent, newValue: number) => void];

export function useHeaderTabValues(): HeaderTabState {
    const dispatch = useDispatch();
    const value = useSelector(selectCustomHeaderTab);
    const handleChange: HeaderTabState["1"] = (_, newValue) => {
        dispatch(changeHeaderTab(newValue as HeaderTab));
    };

    return [value, handleChange];
}

export default function HeaderTabs() {
    const [value, handleChange] = useHeaderTabValues();
    const isSMSEnabled = useSelector(checkSMSEnabled);
    return (
        <TransparentTabs value={value} onChange={handleChange}>
            <CustomTab label="Email" />
            <CustomTab label="LinkedIn request" />
            <CustomTab label="Follow-up email" />
            <CustomTab label="InMail" />
            {isSMSEnabled && <CustomTab label="SMS" />}
        </TransparentTabs>
    );
}
