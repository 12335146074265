import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { z } from "zod";

import { signinType } from "../../../pages/Signin/Signin.types";
import { fetchBaseQueryWithZod } from "../utils";

type CalendlyCodePayload = {
    code: string;
};

const submitCalendlyCodeResponseSchema = z.object({
    message: z.string(),
    success: z.boolean(),
});

const calendlyEventSchema = z.object({
    name: z.string(),
    scheduling_url: z.string(),
});

const calendlyEventTypesDataSchema = z.array(calendlyEventSchema);

export type CalendlyEventTypesData = z.infer<typeof calendlyEventTypesDataSchema>;

const getCalendlyEventTypesResponseSchema = z.object({
    message: z.string(),
    data: calendlyEventTypesDataSchema,
});

type CalendlyEventTypesResponse = z.infer<typeof getCalendlyEventTypesResponseSchema>;

export type AuthorizeLinkedInRequestPayload = {
    liId?: string;
    liPass?: string;
    liOtp?: string;
};

export type AuthorizeLinkedInResponsePayload = {
    data: signinType;
    message: string;
};

const customFieldEvent = z.object({
    label: z.string(),
    value: z.string(),
});

export type CustomFieldEventType = z.infer<typeof customFieldEvent>;

export const externalConnectApis = createApi({
    reducerPath: "externalConnectApis",
    baseQuery: fetchBaseQueryWithZod,
    endpoints: (builder) => ({
        submitCalendlyCode: builder.mutation<void, CalendlyCodePayload>({
            query: (code) => ({
                url: "/auth/update-calendly/auth",
                method: "POST",
                body: code,
            }),
            extraOptions: {
                dataSchema: submitCalendlyCodeResponseSchema,
            },
        }),
        getCalendlyEventTypes: builder.query<CalendlyEventTypesData, void>({
            query: () => ({
                url: "/calendly/event-types",
            }),
            transformResponse: (response: CalendlyEventTypesResponse) => {
                const { data } = response;

                return data;
            },
            extraOptions: {
                dataSchema: getCalendlyEventTypesResponseSchema,
            },
        }),
        authorizeLinkedIn: builder.mutation<signinType, AuthorizeLinkedInRequestPayload>({
            query: (payload) => ({
                url: "/auth/authorize-linkedin",
                method: "POST",
                body: payload,
            }),
            transformResponse: (response: AuthorizeLinkedInResponsePayload) => {
                const { data } = response;

                return data;
            },
        }),
        getCustomFieldEventType: builder.query<CustomFieldEventType[], number>({
            query: (projectId) => ({
                url: `/hyper-personalization/${projectId}/get-custom-field`,
            }),
            transformResponse: (response: { data: CustomFieldEventType[] }) => {
                const { data } = response;
                return data;
            },
        }),
    }),
});

export const {
    useGetCustomFieldEventTypeQuery,
    useSubmitCalendlyCodeMutation,
    useGetCalendlyEventTypesQuery,
    useAuthorizeLinkedInMutation,
} = externalConnectApis;
