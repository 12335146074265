/* eslint-disable import/no-unused-modules */
import React, { createContext, useContext } from "react";

import useWebSocket from "./useWebSocket";

const WebSocketContext = createContext<any>(null);

interface WebSocketContextProviderProps {
    children: React.ReactNode;
}

export const WebSocketContextProvider: React.FC<WebSocketContextProviderProps> = ({ children }) => {
    const webSocket = useWebSocket();

    return <WebSocketContext.Provider value={webSocket}>{children}</WebSocketContext.Provider>;
};

export const useWebSocketContext = () => {
    return useContext(WebSocketContext);
};
