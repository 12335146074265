import { matchSorter } from "match-sorter";

import usePageSearch from "./usePageSearch";

/**
 * Custom hook to filter and sort options based on page search text.
 * @param {any[]} options - The array of items to be filtered and sorted.
 * @param {string[]} filterKeys - Keys to be used for filtering.
 * @param {function(any[]): any[]} [sortingFunction=(rankedItems) => rankedItems] - Function to sort the filtered results.
 * @returns {any[]} Filtered and sorted array based on search text.
 */
export default function useFilterWithPageSearch(
    options: any[] = [],
    filterKeys: string[] = [],
    sortingFunction: (rankedItems: any[]) => any[] = (rankedItems: any[]) => rankedItems
) {
    const searchText = usePageSearch();

    return matchSorter(options, searchText, {
        keys: filterKeys,
        sorter: sortingFunction,
        ...(searchText && { threshold: matchSorter.rankings.CONTAINS }),
    });
}
