import { ButtonProps } from "@mui/material";

import CustomButton from "./CustomButton";

export const TextButton = (props: ButtonProps) => {
    return (
        <CustomButton
            {...props}
            sx={{
                border: 0,
                boxShadow: "none",
                "&:focus": {
                    boxShadow: "none",
                },
                ...props.sx,
            }}
        />
    );
};
