import { Button, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { setProjectJd, updateJd } from "../../../components/Navbar/CreateProject/CreateProject.reducer";
import JDUpload from "../../../components/Navbar/CreateProject/JDUpload";
import { resetState } from "../../../components/Navbar/CreateProject/MarketingPage/MarketingPage.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectCurrProject, updateProjectJdDescription, updateProjectJdTitle, updateProjectName } from "../../allProjects/index.reducer";
import { checkBlendedSearchVersion, checkEasyGrowth, selectUser } from "../../Signin/Signin.reducer";
import BaseModal from "../../triggerWorkflow/components/BaseModal";

export type EditProjectProps = {
    close: () => void;
};

export default function EditProject({ close }: EditProjectProps) {
    const dispatch = useDispatch();
    const project = useSelector(selectCurrProject);
    const user = useSelector(selectUser);
    const isUpdatingProject = useSelector(checkIfLoading(updateJd.type));
    const isBlendedSearchEnabled = useSelector(checkBlendedSearchVersion);
    const isEasyGrowth = useSelector(checkEasyGrowth);

    const marketingData = project?.marketing;

    if (!project?.name) {
        return null;
    }

    const handleUpdateProject = () => {
        dispatch(updateJd({}));
    };

    const handleEditSearch = () => {
        window.open(`/search?project=${project._id}&name=${project.name}&isEdit=true`);
    };

    const handleMarketingButton = () => {
        if (marketingData)
            dispatch(
                resetState({
                    marketingCompanyLogo: marketingData.logo,
                    marketingCompanyVideo: marketingData.video,
                    marketingCompanyVideoLink: marketingData.videoLink,
                    marketingOverview: marketingData.overview,
                    projectId: project._id,
                    showUploadMarketingCompanyPage: true,
                })
            );
        close();
    };

    return (
        <>
            <BaseModal onClose={close} overlayStyles={{ width: "35vw", overflowY: "auto" }}>
                <Stack spacing={3}>
                    <Stack spacing={2}>
                        <EditProjectName project={project?.name} />
                        <EditJdTitle jdTitle={project?.jdTitle} />
                        <EditJdDescription jdDescription={project?.jdDescription} />
                        <EditJD />
                        {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography>Send emails to personal email ids only</Typography>
                            <Switch
                                checked={Boolean(project.onlyPersonalEmail)}
                                onChange={(e) => dispatch(updateProjectOnlyPersonalEmail(e.target.checked))}
                            />
                        </Stack> */}
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                        {marketingData && (
                            <Button variant="outlined" onClick={handleMarketingButton}>
                                Edit/view Talent Marketing page
                            </Button>
                        )}
                        {(user?.isSuperAdmin || isBlendedSearchEnabled || !isEasyGrowth) && (
                            <Button variant="outlined" onClick={handleEditSearch}>
                                Edit candidate search
                            </Button>
                        )}

                        <Button variant="contained" onClick={handleUpdateProject}>
                            <ButtonTextWithLoading isLoading={isUpdatingProject} text="Update" />
                        </Button>
                    </Stack>
                </Stack>
            </BaseModal>
        </>
    );
}

function EditJdTitle({ jdTitle }: { jdTitle: string }) {
    const dispatch = useDispatch();
    const [text, setText] = useState(jdTitle);
    return (
        <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                Edit job title
            </Typography>
            <TextField
                type="text"
                placeholder="Job title"
                value={text}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                onBlur={() => dispatch(updateProjectJdTitle(text))}
            />
        </Stack>
    );
}

function EditJdDescription({ jdDescription }: { jdDescription: string }) {
    const dispatch = useDispatch();
    const [text, setText] = useState(jdDescription);
    return (
        <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                Edit job description
            </Typography>
            <TextField
                type="text"
                placeholder="Job description"
                value={text}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                onBlur={() => dispatch(updateProjectJdDescription(text))}
            />
        </Stack>
    );
}

function EditProjectName({ project }: { project: string }) {
    const dispatch = useDispatch();
    const [text, setText] = useState(project);
    return (
        <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                Edit project name
            </Typography>
            <TextField
                type="text"
                placeholder="Project name"
                value={text}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                onBlur={() => dispatch(updateProjectName(text))}
            />
        </Stack>
    );
}

function EditJD() {
    const dispatch = useDispatch();
    return (
        <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                Update JD
            </Typography>
            <JDUpload handleJdUpload={(val) => dispatch(setProjectJd(val[0]))} />
        </Stack>
    );
}
