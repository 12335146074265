import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import styled from "../../container/AppContainer/AppContainer.module.css";

import { useMousePosition } from "@/hooks/useMousePosition";
import { cn } from "@/lib/utils";

const TooltipProvider = TooltipPrimitive.Provider;
type TooltipContentProps = Omit<typeof TooltipPrimitive.Content, "title" | "$$typeof">;

export type TooltipProps = React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    title: React.ReactNode | string | Element;
    children: React.ReactNode;
    placement?: "top" | "right" | "bottom" | "left";
    arrow?: boolean;
    type?: "light" | "dark";
    className?: string;
    followCursor?: boolean;
    wrapped?: boolean;
};

/**
 * Tooltip component that displays a tooltip with customizable content and appearance.
 *
 * @param {Object} props - The properties for the Tooltip component.
 * @param {string} title - The text content of the tooltip.
 * @param {React.ReactNode} children - The element that triggers the tooltip.
 * @param {string} [placement="bottom"] - The preferred placement of the tooltip relative to the trigger element.
 * @param {boolean} [arrow] - Whether to display an arrow pointing to the trigger element.
 * @param {string} [type="dark"] - The theme of the tooltip, either "dark" or "light".
 * @param {string} [className] - Additional class names to apply to the tooltip.
 * @param {boolean} [followCursor=false] - Whether the tooltip should follow the cursor.
 * @param {React.Ref} ref - The ref to be forwarded to the TooltipPrimitive.Content component.
 * @param {Object} props - Additional properties to be passed to the TooltipPrimitive.Content component.
 *
 * @returns {JSX.Element} The rendered Tooltip component.
 */
const Tooltip = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, TooltipProps>(
    (
        { title, children, placement = "bottom", arrow, type = "dark", className, followCursor = false, wrapped = true,...props },
        ref
    ) => {
        const { ref: mouseRef, x, y } = useMousePosition();
        return (
            <TooltipProvider delayDuration={100} skipDelayDuration={0}>
                <TooltipPrimitive.Root>
                    <TooltipPrimitive.Trigger ref={mouseRef} asChild>
                        {wrapped ? <div>{children}</div> : children}
                    </TooltipPrimitive.Trigger>
                    <TooltipPrimitive.Portal container={document.getElementById("root")}>
                        {title && title !== "" && (
                            <TooltipPrimitive.Content
                                ref={ref}
                                side={followCursor ? "top" : placement}
                                align={followCursor ? "start" : "center"}
                                className={cn(
                                    "transition-all duration-150 ease-in-out leading-none p-2 font-medium max-w-[300px]",
                                    "z-[9999] rounded-md text-[11px] shadow-lg",
                                    "data-[state=delayed-open]:fade-in data-[state=closed]:fade-out",
                                    "data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2",
                                    "data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2",
                                    {
                                        "bg-gray-600 text-white": type === "dark",
                                        "bg-white text-gray-800": type === "light",
                                    },
                                    className,
                                    styled["zoomHandler"]
                                )}
                                alignOffset={followCursor ? x : 0}
                                sideOffset={followCursor ? -y + 5 : 5}
                                {...props}
                            >
                                {title as React.ReactNode}
                                {arrow && (
                                    <TooltipPrimitive.Arrow
                                        className={cn("fill-current", {
                                            "text-gray-800": type === "dark",
                                            "text-white": type === "light",
                                        })}
                                        width={11}
                                        height={5}
                                    />
                                )}
                            </TooltipPrimitive.Content>
                        )}
                    </TooltipPrimitive.Portal>
                </TooltipPrimitive.Root>
            </TooltipProvider>
        );
    }
);

Tooltip.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip };
