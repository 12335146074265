import Typography from "@mui/material/Typography";

const NA = ({ children = "N/A" }: { children?: React.ReactNode }) => (
    <Typography
        variant="body2"
        sx={{
            fontSize: "10px",
            color: "#656565",
            backgroundColor: "#efefef",
            textAlign: "center",
            fontWeight: "500",
            padding: "5px 10px",
            borderRadius: "3px",
        }}
    >
        {children}
    </Typography>
);

export default NA;
