export const linkedinProfileRegex = /^https?:\/\/(?:www\.)?linkedin\.com\/(?:pub|in|profile)\/[-a-zA-Z0-9]+\/?$/;
export const linkedinSalesNavRegex = /^https?:\/\/(?:www\.)?linkedin\.com\/sales\/search\/people(?:\?.*)?$/;

export function validateLinkedinProfileUrl(linkedinProfileUrl: string) {
    return linkedinProfileRegex.test(linkedinProfileUrl);
}

export function validateLinkedinSalesNavUrl(linkedinSalesNavUrl: string) {
    return linkedinSalesNavRegex.test(linkedinSalesNavUrl);
}
