import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import MarketResearchData from "./MarketResearchData";

interface IMarketResearchModal {
    projectId: number;
    onClose: () => void;
}
export function MarketResearch({ projectId, onClose }: IMarketResearchModal) {

    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                width: "75%",
                padding: "1.5rem",
                maxHeight: "80vh",
                overflow: "auto",
            }}
        >
            <MarketResearchData />
        </BaseModal>
    );
}
