import React, { useEffect, useRef, useState } from "react";
import { Stack, Typography } from "@mui/material";

interface IReadMoreProps {
    children: string;
}

export default function ReadMore({ children }: IReadMoreProps & React.HTMLAttributes<any>) {
    let text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef<HTMLSpanElement | null>(null);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    useEffect(() => {
        const textElement = textRef.current;
        if (textElement && textElement.scrollHeight > textElement.clientHeight) {
            setIsOverflowing(true);
        }
    }, [text]);

    if (!text) {
        return null;
    }

    text = text?.replace(/\n/g, "<br>");

    return (
        <Stack>
            <Typography
                variant="caption"
                fontSize={13}
                component="span"
                ref={textRef}
                sx={(theme) => ({
                    color: theme.palette.grey[800],
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: isReadMore ? 1 : "none",
                    overflow: "hidden",
                })}
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
            {isOverflowing ? (
                <Typography
                    component="span"
                    fontSize={13}
                    onClick={toggleReadMore}
                    sx={{
                        color: "#c0c0c0",
                        cursor: "pointer",
                        textDecoration: "underline",
                        textAlign: "end",
                    }}
                >
                    {isReadMore ? "...read more" : "show less"}
                </Typography>
            ) : null}
        </Stack>
    );
}
