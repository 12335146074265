import ClearAllIcon from "@mui/icons-material/ClearAll";
import { Box, IconButton, Tooltip, Typography } from "@mui/joy";

import { JoyProvider } from "../../../JoyProvider";
import { useChatbotContext } from "../../Context";

interface HeaderProps {
    onBack?: () => void;
}

function Header(_props: HeaderProps) {
    const { clearAllChats } = useChatbotContext();
    return (
        <JoyProvider>
            <Box
                sx={{
                    padding: "8px 12px",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    display: "grid",
                    gridTemplateColumns: "1.25fr 1fr",
                    alignItems: "center",
                }}
            >
                <Typography
                    justifySelf={"end"}
                    sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "black",
                    }}
                >
                    Bash
                </Typography>

                <Tooltip title="Clear all chats">
                    <IconButton size="sm" onClick={clearAllChats} sx={{ justifySelf: "end" }}>
                        <ClearAllIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </JoyProvider>
    );
}

export default Header;
