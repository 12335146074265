import { PayloadAction } from "@reduxjs/toolkit";
import { get, isNil } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../../store/reducers/loaders.reducer";
import API from "../../../utils/API";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import { optionType } from "../../AdvancedFilters/Components/Filters/Filters.type";
import {
    addCredits,
    getOrgList,
    getOrgListTracker,
    leavePage,
    setOrgList,
    setProjectsTrackers,
    submitAutoSearchStatus,
    submitTrackResponses,
    toggleAutoSearch,
    triggerToContacted,
} from "./orgList.reducers";
import {
    AddCreditsPayload,
    orgTypes,
    StatusChangeResponse,
    SubmitAutoSearchStatusPayload,
    SubmitTrackResponsesPayload,
} from "./orgList.types";

function* getOrgListSaga(action: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const response = yield call(new API().get, "/super-admin/orgList");
        if (!response?.data) return;

        const data = response.data
            .map((item: orgTypes) => ({
                ...item,
                label: `${item.name} -  ${item._id}`,
                value: item._id,
            }));

        yield put(setOrgList(data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addCreditsSaga(action: PayloadAction<AddCreditsPayload>): SagaIterator {
    const { payload, type } = action;
    try {
        yield put(startAction({ action: type }));

        const state = yield select();
        const organizations = JSON.parse(JSON.stringify(get(state, "orgList.organizations" || "")));

        const response = yield call(new API().post, "/super-admin/addCredit", {
            orgId: payload.orgId,
            credits: payload.credits,
            projectLimit: payload.projectLimit,
        });

        if (!response?.data) return;

        payload.onSuccess();

        const newOrganizations = organizations.map((org: orgTypes) =>
            org._id === response.data._id ? response.data : org
        );
        yield put(setOrgList(newOrganizations));
        yield put(setSuccessNotification(response.message));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getOrgListTrackerSaga({
    payload,
}: {
    payload: {
        orgList: any;
        contactedSinceDate: string;
        onSuccess?: (data: any[]) => {};
        hideLoader?: boolean;
    };
}): SagaIterator {
    try {
        if (!payload.hideLoader) {
            yield put(startAction({ action: getOrgListTracker.type }));
        }
        const response = yield call(new API().post, "/super-admin/orgTracker", {
            orgIds: payload.orgList.map((item: optionType) => item.value),
            contactedSince: payload.contactedSinceDate,
        });

        if (!response?.data) return;
        payload?.onSuccess?.(response.data);
        // yield put(setProjectsTrackers(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        if (!payload.hideLoader) {
            yield put(stopAction({ action: getOrgListTracker.type }));
        }
    }
}

function* submitAutoSearchStatusSaga({ payload }: SubmitAutoSearchStatusPayload): SagaIterator {
    try {
        const { id, loadingAction, status } = payload;
        yield put(startAction({ action: loadingAction }));
        const response: StatusChangeResponse = yield call(new API().post, "/auto-search/status-change", {
            projectId: id,
            autoSource: status,
        });

        if (isNil(response?.data) || isNil(response?.data?._id)) {
            throw new Error("error while updating auto search status");
        }
    } catch (err: unknown) {
        handleException(err);
        yield put(toggleAutoSearch({ ...payload, status: !payload.status }));
        yield put(setErrorNotification("error while updating auto search status"));
    } finally {
        yield put(stopAction({ action: payload.loadingAction }));
    }
}

function* triggerToContactedSaga({ payload }: { payload: number }): SagaIterator {
    try {
        yield put(startAction({ action: triggerToContacted.type }));
        const response: any = yield call(new API().put, "/candidate/project-contacted", {
            projectId: payload,
            selectAll: true,
            stage: "CONTACTED",
        });

        if (!response?.data) return;
        yield put(setSuccessNotification(response?.message));
    } catch (err: any) {
        console.error(err);
        yield put(setErrorNotification(err?.message));
        handleException(err);
    } finally {
        yield put(stopAction({ action: triggerToContacted.type }));
    }
}

function* submitTrackResponsesSaga(action: SubmitTrackResponsesPayload): SagaIterator {
    try {
        yield put(startAction({ action: `${action.type}-${action.payload.id}` }));
        const response: any = yield call(new API().post, "/searches/fetch-emails", {
            orgId: action.payload,
        });

        if (!response?.data) {
            throw new Error();
        }

        yield put(setSuccessNotification(response?.message));
    } catch (err: any) {
        console.error(err);
        handleException(err);
        yield put(setErrorNotification(err?.message));
    } finally {
        yield put(stopAction({ action: `${action.type}-${action.payload.id}` }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getOrgList.type, getOrgListSaga),
        // @ts-ignore
        yield takeLatest(addCredits.type, addCreditsSaga),
        // @ts-ignore
        yield takeLatest(getOrgListTracker.type, getOrgListTrackerSaga),
        // @ts-ignore
        yield takeLatest(submitAutoSearchStatus.type, submitAutoSearchStatusSaga),
        // @ts-ignore
        yield takeLatest(triggerToContacted.type, triggerToContactedSaga),
        // @ts-ignore
        yield takeLatest(submitTrackResponses.type, submitTrackResponsesSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
