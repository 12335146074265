import dayjs from "dayjs";

export default function formatTimestamp(timestamp: number): string {
    // Create a dayjs object from the timestamp
    const dateObj = dayjs(timestamp);

    // Format the date as "Mon Date, Year"
    const formattedDate = dateObj.format("ddd MMM DD, YYYY");

    return formattedDate;
}
