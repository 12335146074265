import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "../../../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../../../store/reducers/loaders.reducer";
import API from "../../../../utils/API";
import { CancelSagas } from "../../../../utils/saga.utils";
import handleException from "../../../../utils/sentry";
import { setSelectAllCandidates } from "../../index.reducer";
import { IProjectStage } from "../../project.types";
import {
    exportCsv,
    exportPdf,
    IExportCandidatesApiPayload,
    IExportCandidatesApiResponse,
    IExportCsvPayload,
    IExportPdfPayload,
    leavePage,
    setCsvData,
    setPdfData,
} from "./exportCandidates.reducer";

function* getExportCsvSaga({ payload, type }: PayloadAction<IExportCsvPayload>): SagaIterator {
    try {
        if (payload.mode !== "EXPORT_WITH_ACCOUNT_INFO") {
            yield put(startAction({ action: type }));
        } else {
            yield put(
                setSuccessNotification(
                    "This takes a few minutes to complete. You will receive an email with a download link."
                )
            );
        }
        const state = yield select();
        const { mode, projectId, onSuccess } = payload;
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const searchQuery = get(state, "project.searchQuery", "");
        const selectedCandidates: { candidateId: string; pageNo: number }[] = get(state, "project.selectedCandidates");
        const isAllCandidatesSelected = get(state, "project.selectAllCandidates", false);
        const showByPersonalEmailsStateValue = state.project.showByPersonalEmails;
        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);
        const filterByPayload = [...appliedFilters];

        const requestPayload: IExportCandidatesApiPayload = {
            projectId,
            exportType: mode,
        };

        if (mode === "FILTERED_CANDIDATES") {
            if (searchQuery) {
                requestPayload.search = searchQuery;
            }
            requestPayload["filterBy"] = filterByPayload;
        }

        if (mode === "SELECTED_CANDIDATES" || mode === "EXPORT_WITH_ACCOUNT_INFO") {
            if (!isAllCandidatesSelected) {
                requestPayload["candidateIds"] = selectedCandidates.map(({ candidateId }) => candidateId);
            } else if (isAllCandidatesSelected && appliedFilters.length) {
                requestPayload["filterBy"] = filterByPayload;
            }
        }

        requestPayload.exportFormat = "CSV";

        const response: IExportCandidatesApiResponse = yield call(
            new API().post,
            `/v2/project/exportCandidates`,
            requestPayload
        );

        if (!response?.data) {
            throw new Error();
        }

        if (mode === "EXPORT_WITH_ACCOUNT_INFO") {
            return;
        }

        const emailType = get(state, "myAccount.emailType.emailType", "PERSONAL_PREFERRED");

        const candidatesData = response.data.map(({ candidates }: any) => {
            if (emailType === "PERSONAL_PREFERRED") {
                candidates.primaryEmail =
                    candidates.email?.verifiedEmail?.[0] || candidates.email?.professional?.[0] || "";
            }
            return candidates;
        });

        yield put(setCsvData(candidatesData));
        yield put(setSuccessNotification(response.message));
        // clear selected candidates
        if (mode === "SELECTED_CANDIDATES") {
            yield put(setSelectAllCandidates(false));
        }
        onSuccess();
    } catch (err: any) {
        console.error(err);
        yield put(setErrorNotification("error while exporting candidates..."));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* getExportPdfSaga({ payload, type }: PayloadAction<IExportPdfPayload>): SagaIterator {
    try {
        const { projectId, onSuccess } = payload;

        yield put(startAction({ action: type }));

        const state = yield select();
        const projectFiltersStateValue = state.project.projectFilters;
        const showByPersonalEmailsStateValue = state.project.showByPersonalEmails;
        const searchQuery = state.project.searchQuery;
        const selectedCandidates: { candidateId: string; pageNo: number }[] = get(state, "project.selectedCandidates");
        const isAllCandidatesSelected = get(state, "project.selectAllCandidates", false);

        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);
        const filterByPayload = [...appliedFilters];

        const requestPayload: IExportCandidatesApiPayload = {
            projectId,
            filterBy: filterByPayload,
        };

        if (searchQuery) {
            requestPayload.search = searchQuery;
        }

        if (!isAllCandidatesSelected) {
            requestPayload.candidateIds = selectedCandidates.map(({ candidateId }) => candidateId);
        }

        requestPayload.exportFormat = "PDF";

        const response: IExportCandidatesApiResponse = yield call(
            new API().post,
            "/v2/project/exportCandidates",
            requestPayload
        );

        if (!response?.data) {
            throw new Error();
        }

        const candidatesData = response.data.map(({ candidates }) => candidates);
        yield put(setPdfData(candidatesData));
        yield put(setSuccessNotification(`${response.message}. PDF generation may take a few moments.`));
        onSuccess();
    } catch (err: any) {
        console.error(err);
        yield put(setErrorNotification("error while exporting candidates..."));
        handleException(err);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(exportCsv.type, getExportCsvSaga),
        // @ts-ignore
        yield takeLatest(exportPdf.type, getExportPdfSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
