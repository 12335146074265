import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../../components/CustomButton";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { useSaveMultipleOutreachTemplates } from "../hooks/useSaveOutreach.hook";

import { useWarningTemplatesSaveMutation } from "@/store/apis/outreach/outreach.api";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { selectIsWarningModalOpen, setWarningModalOpen } from "@/store/reducers/outreach/outreach.slice";
import { Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

export function WarningModal() {
    const dispatch = useDispatch();
    const { projectId } = useOutreachParams();
    const isWarningModalOpen = useSelector(selectIsWarningModalOpen);
    const [warningTemplatesSave, { isLoading: isSaveLoading }] = useWarningTemplatesSaveMutation();

    const {
        saveAllTemplates,
        saveMutationState: { isLoading: isWorkflowLoading },
    } = useSaveMultipleOutreachTemplates({
        successCallback: () => {
            dispatch(setSuccessNotification("Template saved successfully"));
        },
    });

    const saveTemplates = async () => {
        await onClose(); // Wait for onClose to complete.
        saveAllTemplates(false); // Call saveAllTemplates after onClose finishes.
    };

    const onClose = async () => {
        return new Promise<void>((resolve) => {
            const payload = {
                projectId: Number(projectId),
                warningIgnoredByUser: true,
            };
            // Call the function for saving warning templates.
            warningTemplatesSave(payload);

            // Dispatch the action to close the modal.
            dispatch(setWarningModalOpen({ duplicates: [], open: false }));

            // Resolve the promise after everything is completed.
            resolve();
        });
    };

    if (!isWarningModalOpen?.open) return null;

    return (
        <BaseModal onClose={onClose} overlayStyles={{ minWidth: "330px", maxWidth: "500px" }}>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography className="text-[16px]" variant="h6">
                        Would you like to proceed with the current templates?
                    </Typography>

                    {/* {isWarningModalOpen?.duplicates?.length > 0 && (
                        <Stack gap={0.5}>
                            {isWarningModalOpen.duplicates.map((template, index) => (
                                <Typography key={index} variant="body1" className="text-[14px] ml-2">
                                    • {template}
                                </Typography>
                            ))}
                        </Stack>
                    )} */}

                    <p className="mt-2" style={{ fontWeight: "400", fontSize: "14px" }}>
                        Please double-check that the templates are properly set up to ensure a successful outreach.
                    </p>
                </Stack>

                <Stack gap={1.3} direction="row" style={{ marginTop: 2 }}>
                    <CustomButton
                        onClick={saveTemplates}
                        disabled={isWorkflowLoading}
                        style={{
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                        }}
                    >
                        Yes, Proceed
                    </CustomButton>

                    <CustomButton
                        onClick={onClose}
                        disabled={isSaveLoading}
                        variant="outline"
                        style={{
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                        }}
                    >
                        No, Continue Editing
                    </CustomButton>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
