import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { findCookie } from "../utils/cookie";
import { getFirebaseWebToken } from "../utils/firebase";
import handleException from "../utils/sentry";

function useVerifyUser() {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyUser = async () => {
            try {
                const webToken = await getFirebaseWebToken();
                const token = findCookie("accessToken");

                if (!webToken && !token) {
                    const isNew = searchParams.get("isNew") === "true";
                    const redirectURL = isNew ? "/signup" : "/signin";
                    navigate(redirectURL, {
                        state: {
                            from: location,
                        },
                    });
                }
            } catch (error) {
                handleException(error);
                console.log("Error in VerifyUser:", error);
            } finally {
                setLoading(false);
            }
        };
        verifyUser();
    }, []);

    return loading;
}

export default useVerifyUser;
