import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { nanoid } from "@reduxjs/toolkit";

import InfoButton, { InfoMessage, WorkflowInfo } from "../../../../components/SetupFlow/InfoButton";

const connectionReqMessages: InfoMessage[] = [
    {
        id: nanoid(),
        answer: "Disable this to skip sending a follow-up email during your outreach.",
    },
];

type DisableSwitchProps = {
    checked: boolean;
    dispatch: (value: boolean) => void;
};

export default function DisableEmailSwitch(props: DisableSwitchProps) {
    const { checked, dispatch } = props;

    return (
        <Stack direction="row">
            <FormControlLabel
                sx={{ marginRight: 0 }}
                control={
                    <Switch
                        checked={checked}
                        onChange={(e) => {
                            dispatch(e.target.checked);
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                }
                label={checked ? "Enabled" : "Disabled"}
            />
            <InfoButton>
                <WorkflowInfo messages={connectionReqMessages} />
            </InfoButton>
        </Stack>
    );
}
