import { useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { makeConnection } from "./connection";
import useSessionStorage from "@/hooks/useSessionStorage";
import handleException from "@/utils/sentry";

type Listener = {
    event: string;
    callback?: (data: any, emit?: (event: string, data: any) => void) => void;
};

const useWebSocket = () => {
    const [isConnected, setIsConnected] = useState(false);
    const [tabUuid] = useSessionStorage("tabUuid", `tabId-${uuidv4()}`);
    const socketRef = useRef<Socket | null>(null);
    const eventQueue = useRef<Map<string, { event: string; data?: any; callback?: (data: any) => void }>>(new Map());

    const handleEventAcknowledgment = (taskId: string) => {
        if (eventQueue.current.has(taskId)) {
            console.log(
                `Event with ID '${taskId}' processed and removed from queue and the event that was removed is: ${eventQueue.current.get(taskId)}`
            );
            eventQueue.current.delete(taskId);
        } else {
            console.warn(`Event with ID '${taskId}' not found in queue.`);
        }
    };
    async function processEventQueue(socketInstance: Socket) {
        if (eventQueue.current.size > 0) {
            console.log("Processing queued events...");

            for (const [eventId, { event, data }] of eventQueue.current.entries()) {
                await new Promise((resolve) => setTimeout(resolve, 6000)); // Adding a delay of 6 second before re-emitting each event

                console.log(`Re-emitting event '${event}' with ID '${eventId}'`);
                socketInstance.emit(event, data); // Emit the event
            }

            console.log("All events processed.");
        }
    }

    const connectSocket = async () => {
        try {
            await makeConnection(socketRef, tabUuid);
            const socketInstance = socketRef.current;
            if (!socketInstance) {
                console.error("Failed to establish socket connection.");
                return;
            }

            socketInstance.on("connect", () => {
                console.log(`Connecting with Tab UUID: ${tabUuid}`);
            });

            socketInstance.on("connected", () => {
                setIsConnected(true);
                console.log(`Connected with Tab UUID: ${tabUuid}`);
                processEventQueue(socketInstance);
            });

            socketInstance.on("disconnect", () => {
                setIsConnected(false);
                console.log("Socket.IO connection closed");
            });

            socketInstance.on("taskSync", ({ taskId, status }) => {
                console.log(`Task ID: ${taskId} is ${status}`);
                handleEventAcknowledgment(taskId);
            });

            socketInstance.on("connect_error", (error) => {
                setIsConnected(false);
                if (error) {
                    socketInstance.disconnect();
                    setTimeout(connectSocket, 1000);
                }
                console.error("Socket.IO connection error:", error);
            });

            socketInstance.on("reconnect", () => {
                console.log("Socket.IO reconnected successfully, Tab UUID:", tabUuid);
            });
            socketInstance.on("connect_failed", (error) => {
                console.error("Socket.IO error:", error);
                handleException(error);
            });
        } catch (error) {
            console.error("Error during socket connection:", error);
            handleException(error);
        }
    };

    useEffect(() => {
        connectSocket();

        return () => {
            socketRef.current?.disconnect();
            socketRef.current = null;
        };
    }, [tabUuid]);

    const emit = (event: string, data: any, taskId?: string) => {
        if (socketRef.current && isConnected) {
            socketRef.current.emit(event, data);
            if (taskId) {
                eventQueue.current.set(taskId, { event, data });
            }
        } else {
            console.warn("Socket is not connected");
            if (taskId) {
                eventQueue.current.set(taskId, { event, data });
            }
        }
    };

    const disconnectSocket = () => {
        socketRef.current?.disconnect();
    };

    const useSocketListeners = (listeners: Listener[]) => {
        useEffect(() => {
            if (!isConnected) {
                console.warn("Socket is not connected; listeners will not be added.");
                return;
            }

            listeners.forEach(({ event, callback }) => {
                if (callback) {
                    socketRef.current?.on(event, (eventData: any) => callback(eventData, emit));
                }
            });

            return () => {
                listeners.forEach(({ event }) => {
                    socketRef.current?.off(event);
                });
            };
        }, [listeners, isConnected, socketRef]);
    };
    return { isConnected, emit, useSocketListeners, disconnectSocket };
};

export default useWebSocket;
