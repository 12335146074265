import { useState } from "react";
import { Stack, Tooltip } from "@mui/joy";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { JoyProvider } from "../../../components/JoyProvider";
import { useAppDispatch } from "../../../store";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUserRole } from "../../Signin/Signin.reducer";
import { editMember, selectInvitations, selectMembers } from "../manageTeams.reducers";
import { IEditMember } from "../manageTeams.types";
import { TeamsTitle } from "./Teams";
import { TeamsTable } from "./TeamsTable";

function EditRole({ roleType, userId }: { roleType: IEditMember["roleType"]; userId: number }) {
    const userRole = useSelector(selectUserRole);
    const isRoleAdmin = userRole === "ADMIN";
    const dispatch = useAppDispatch();
    const isSubmitting = useSelector(checkIfLoading(editMember.type));
    const [newRoleType, setRoleType] = useState<IEditMember["roleType"]>(roleType || "MEMBER");
    const handleChange = (_: React.SyntheticEvent | null, newValue: string | null) => {
        const value = newValue as IEditMember["roleType"];
        setRoleType(value);
        if (newValue) {
            dispatch(
                editMember({
                    userId,
                    roleType: value,
                })
            );
        }
    };
    return (
        <Select
            value={newRoleType}
            onChange={handleChange}
            size="sm"
            sx={{ width: 100 }}
            disabled={isSubmitting || !isRoleAdmin}
        >
            <Tooltip title="Admins have the right to access to all projects in the organization">
                <Option value="ADMIN">Admin</Option>
            </Tooltip>
            <Tooltip title="Members, by default, only see projects created by them">
                <Option value="MEMBER">Member</Option>
            </Tooltip>
        </Select>
    );
}

// cols: name, email, role: edit, projects: edit
export function OrganizationMembers() {
    const userRole = useSelector(selectUserRole);
    const isUserAdmin = userRole === "ADMIN";
    const members = useSelector(selectMembers);
    const invitations = useSelector(selectInvitations);
    return (
        <div>
            <JoyProvider>
                <TeamsTitle />
                <TeamsTable
                    headers={["Name", "Email", "Role", "Projects"]}
                    sx={{
                        borderRadius: "sm",
                        flexShrink: 1,
                        overflow: "auto",
                        minHeight: 0,
                        fontFamily: "Inter",
                        maxHeight: "calc(33vh)",
                    }}
                >
                    {members.map((i) => {
                        return (
                            <tr key={i._id}>
                                <td>
                                    <Typography level="body-xs">{i.name}</Typography>
                                </td>
                                <td>
                                    <Typography level="body-xs">{i.email}</Typography>
                                </td>
                                <td>
                                    <EditRole roleType={i.roleType as IEditMember["roleType"]} userId={i._id} />
                                </td>
                                <td>
                                    {i.roleType === "ADMIN" ? (
                                        <Typography level="body-xs">All ACCESS</Typography>
                                    ) : (
                                        <Typography
                                            level="body-xs"
                                            component={Link}
                                            to={`/my-account/organization-member/access/${i._id}`}
                                            sx={{
                                                color: "primary.500",
                                                pointerEvents: isUserAdmin ? "auto" : "none",
                                            }}
                                        >
                                            Select Projects
                                        </Typography>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </TeamsTable>

                <Stack direction="row" alignItems="center" gap={1} sx={{ pb: 1, pt: 1.5 }}>
                    <Typography level="h4">Invitations</Typography>
                </Stack>
                <TeamsTable
                    headers={["Name", "Email", "Role", "Status"]}
                    sx={{
                        borderRadius: "sm",
                        flexShrink: 1,
                        overflow: "auto",
                        minHeight: 0,
                        fontFamily: "Inter",
                        maxHeight: "calc(32vh)",
                    }}
                >
                    {invitations.map((i) => {
                        const { id, name, email, role, status } = i;
                        return (
                            <tr key={id}>
                                <td>
                                    <Typography level="body-xs">{name}</Typography>
                                </td>
                                <td>
                                    <Typography level="body-xs">{email}</Typography>
                                </td>
                                <td>
                                    <Typography level="body-xs">{role}</Typography>
                                </td>
                                <td>
                                    <Typography level="body-xs">{status}</Typography>
                                </td>
                            </tr>
                        );
                    })}
                </TeamsTable>
            </JoyProvider>
        </div>
    );
}
