import { HYPER_EMAIL2_BODY, HYPER_EMAIL3_BODY, HYPER_EMAIL_SUBJECT } from "../../../constant/Constant";
import { IComponentList } from "../../workflow/workflow.types";

type IProps = {
    componentList: IComponentList[];
};

export default function hyperPersonaliseFollowEmails({ componentList }: IProps) {
    let emailCount = 0;
    const tmpComponentList = componentList.map((component) => {
        if (component.type === "email") {
            emailCount++;

            switch (emailCount) {
                case 1:
                    return {
                        ...component,
                        subject: "",
                        editorVal: "",
                    };
                case 2:
                    return {
                        ...component,
                        subject: HYPER_EMAIL_SUBJECT,
                        editorVal: HYPER_EMAIL2_BODY,
                    };
                case 3:
                    return {
                        ...component,
                        subject: HYPER_EMAIL_SUBJECT,
                        editorVal: HYPER_EMAIL3_BODY,
                    };
                default:
                    return component;
            }
        }
        return component;
    });
    return tmpComponentList;
}
