import styled from "@emotion/styled";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { CssVarsProvider } from "@mui/joy/styles";
import { Badge, Box, Button, Experimental_CssVarsProvider, Tooltip, TooltipProps } from "@mui/material";
import Typography1 from "@mui/material/Typography";
import { useState } from "react";

import WritingStyleMenu from "./WritingStylesMenu";

import useDisclosure from "../../../hooks/useDisclosure";
import { useFetchWritingStylesQuery } from "../../../store/apis/outreach/outreach.api";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

const FixedTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} PopperProps={{ className }} />
))``;
export function MimicWritingStyles() {
    const { projectId } = useOutreachParams();
    const [hovered, setHovered] = useState(false);

    const {
        data: writingStylesData = {},
        refetch: refetchStyles,
        isLoading: writingStylesDataLoading,
    } = useFetchWritingStylesQuery({
        projectId,
    });
    const {
        isOpen: isMimicWritingStyle,
        onOpen: onOpenMimicWritingStyle,
        onClose: onCloseMimicWritingStyle,
    } = useDisclosure();

    if (writingStylesDataLoading) return <></>;
    return (
        <>
            <CssVarsProvider>
                <Experimental_CssVarsProvider>
                    <FixedTooltip
                        title={
                            writingStylesData?.applyStyle
                                ? "Your writing samples and writing style has been saved"
                                : null
                        }
                        aria-disabled={!hovered}
                        open={hovered}
                        placement="top"
                        PopperProps={{
                            popperOptions: {
                                modifiers: [
                                    {
                                        name: "preventOverflow",
                                        options: {
                                            altAxis: false,
                                            tether: false,
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Box width="180px" sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Badge
                                badgeContent={
                                    writingStylesData?.applyStyle ? <DoneAllIcon sx={{ color: "green" }} /> : null
                                }
                                sx={{ color: "green", fontSize: "4px" }}
                            >
                                <Button
                                    onClick={() => {
                                        onOpenMimicWritingStyle();
                                        setHovered(false);
                                    }}
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: hovered ? "auto" : "30px",
                                        color: "#2196F3",
                                        border: hovered ? "1px solid #2196F3" : "none",
                                        borderRadius: hovered ? "4px" : "none",
                                        textTransform: "none",
                                        padding: "4px",
                                        transition: "width 0.4s ease, border 0.4s ease",
                                        width: hovered ? "180px" : "30px",
                                        overflow: "hidden",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <DesignServicesIcon sx={{ transition: "margin-right 0.4s ease" }} />
                                    <Typography1
                                        variant="body2"
                                        sx={{
                                            ml: 1,
                                            transform: hovered ? "translateX(0)" : "translateX(-100%)",
                                            transition: "transform 0.4s ease, opacity 0.4s ease",
                                            whiteSpace: "nowrap",
                                            opacity: hovered ? 1 : 0,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        Mimic writing style
                                    </Typography1>
                                </Button>
                            </Badge>
                        </Box>
                    </FixedTooltip>
                </Experimental_CssVarsProvider>
                {isMimicWritingStyle && (
                    <WritingStyleMenu
                        isOpen={isMimicWritingStyle}
                        onClose={onCloseMimicWritingStyle}
                        writingStylesData={writingStylesData}
                        refetch={refetchStyles}
                    />
                )}
            </CssVarsProvider>
        </>
    );
}
