import ClearIcon from "@mui/icons-material/Clear";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    getCandidatesFromLabEdge,
    getLaborEdgeJobs,
    getLaborEdgeProfessions,
    getLaborEdgeSpecialties,
    selectLaborEdgeJobs,
    selectLaborEdgeProfessions,
    selectLaborEdgeSpecialties,
    sendCandidatesToLabEdge,
} from "../../../integrations/integrations.reducer";

const ITEMS_PER_PAGE = 100;

const LoadingAutocompleteField = ({ loading, label, options, value, onChange, disabled, ...props }) => (
    <Stack direction="row" spacing={2} alignItems="center">
        <Autocomplete
            fullWidth
            options={options}
            disabled={disabled}
            disableClearable
            getOptionLabel={(option) => option.name || ""}
            loading={loading}
            value={value ? options?.find((option) => option?.id === value) : null}
            onChange={(event, newValue) => {
                onChange(newValue ? newValue.id : null);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    disabled={loading || disabled}
                    required={label === "Status"}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    {...props}
                />
            )}
        />
        {value && (
            <IconButton size="small" onClick={() => onChange(null)} disabled={!value}>
                <ClearIcon />
            </IconButton>
        )}
    </Stack>
);

const LaborEdgeModal = ({ open, onClose, selectedCandidateIds, variant }) => {
    const { id: projectId } = useParams();
    const dispatch = useDispatch();
    const [formState, setFormState] = useState({
        startDate: null,
        endDate: null,
        selectedStatus: "Prospect",
        selectedSpecialty: "",
        selectedProfession: "",
        jobType: "TRAVEL",
        selectedJob: null,
        currentPage: 0,
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    // Selectors

    const specialties = useSelector(selectLaborEdgeSpecialties);
    const professions = useSelector(selectLaborEdgeProfessions);
    const jobs = useSelector(selectLaborEdgeJobs);

    // Loading states

    const isSpecialtyLoading = useSelector(checkIfLoading(getLaborEdgeSpecialties.type));
    const isProfessionLoading = useSelector(checkIfLoading(getLaborEdgeProfessions.type));
    const isJobsLoading = useSelector(checkIfLoading(getLaborEdgeJobs.type));

    const totalJobsCount = jobs?.count || 0;

    useEffect(() => {
        if (open && variant !== "FETCH") {
            dispatch(getLaborEdgeSpecialties({}));
            dispatch(getLaborEdgeProfessions({}));
        }
    }, [open, variant, dispatch]);

    const handleFormChange = (field) => (event) => {
        const value = event?.target ? event.target.value : event;
        setFormState((prev) => ({ ...prev, [field]: value }));
    };

    const handleGetJobs = (page, event) => {
        if (event && event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
        const { startDate, endDate, selectedSpecialty, selectedProfession } = formState;

        setFormState((prev) => ({ ...prev, currentPage: page }));

        const start = page === 0 ? 0 : page * ITEMS_PER_PAGE + 1;

        const payload = {
            dateCreatedStart: startDate ? dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss") : null,
            dateCreatedEnd: endDate ? dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss") : null,
            jobStatusCode: "OPEN",
            pagingDetails: { start },
            ...(selectedSpecialty && { specialtyIds: [Number(selectedSpecialty)] }),
            ...(selectedProfession && { professionIds: [Number(selectedProfession)] }),
        };

        dispatch(getLaborEdgeJobs(payload));
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleConfirm = async () => {
        try {
            setIsSubmitting(true);
            const { startDate, endDate, selectedSpecialty, selectedProfession, jobType, selectedJob } = formState;

            if (variant === "FETCH") {
                await dispatch(
                    getCandidatesFromLabEdge({
                        projectId: projectId,
                        dateCreatedStart: startDate ? dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss") : null,
                        dateCreatedEnd: endDate ? dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss") : null,
                    })
                );
            } else {
                await dispatch(
                    sendCandidatesToLabEdge({
                        candidateIds: selectedCandidateIds,
                        jobTypeId: jobType,
                        jobId: selectedJob?.id,
                        statusId: 2058, //prospect
                        projectId: projectId,
                        ...(selectedSpecialty && { specialtyId: Number(selectedSpecialty) }),
                        ...(selectedProfession && { professionId: Number(selectedProfession) }),
                        ...(selectedSpecialty && { primarySpecialtyId: Number(selectedSpecialty) }),
                    })
                );
            }
            onClose();
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const isConfirmValid = useCallback(() => {
        const { selectedStatus, selectedJob, startDate, endDate, selectedProfession, selectedSpecialty } = formState;
        if (variant !== "FETCH") {
            return selectedStatus && selectedJob && startDate && endDate && selectedProfession && selectedSpecialty;
        }
        return startDate && endDate;
    }, [formState, variant]);

    const handlePageChange = (newPage) => {
        handleGetJobs(newPage);
    };

    useEffect(() => {
        if (!open) {
            setFormState({
                startDate: null,
                endDate: null,
                selectedStatus: "Prospect",
                selectedSpecialty: "",
                selectedProfession: "",
                jobType: "TRAVEL",
                selectedJob: null,
                currentPage: 0,
            });
            setSearchTerm("");
        }
    }, [open]);

    const filteredJobs =
        jobs?.records?.filter((job) => {
            const searchTermLower = searchTerm?.toLowerCase() || "";
            const jobTitleMatch = job?.jobTitle?.toLowerCase().includes(searchTermLower);
            const clientNameMatch = job?.clientName?.toLowerCase().includes(searchTermLower);
            return jobTitleMatch || clientNameMatch;
        }) || [];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={open} onClose={onClose} TransitionComponent={Fade} maxWidth="xs" fullWidth>
                <DialogTitle>{variant === "FETCH" ? "Fetch from" : "Send to"} LaborEdge</DialogTitle>

                <DialogContent>
                    <Stack spacing={4} sx={{ mt: 2, width: "100%" }}>
                        <DateTimePicker
                            label="Select Created After"
                            value={formState.startDate}
                            onChange={handleFormChange("startDate")}
                            format="YYYY-MM-DD HH:mm:ss"
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    required: true,
                                },
                            }}
                        />

                        <DateTimePicker
                            label="Select Created Before"
                            value={formState.endDate}
                            onChange={handleFormChange("endDate")}
                            format="YYYY-MM-DD HH:mm:ss"
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    required: true,
                                },
                            }}
                        />

                        {variant !== "FETCH" && (
                            <>
                                <LoadingAutocompleteField
                                    label="Filter Jobs on specialty"
                                    options={specialties}
                                    disabled={false}
                                    value={formState.selectedSpecialty}
                                    onChange={handleFormChange("selectedSpecialty")}
                                    loading={isSpecialtyLoading}
                                />

                                <LoadingAutocompleteField
                                    label="Filter Jobs on profession"
                                    options={professions}
                                    disabled={false}
                                    value={formState.selectedProfession}
                                    onChange={handleFormChange("selectedProfession")}
                                    loading={isProfessionLoading}
                                />

                                {formState.startDate && formState.endDate && (
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <TextField
                                            label="Selected Job"
                                            value={formState.selectedJob?.jobTitle || ""}
                                            fullWidth
                                            onClick={(e) => handleGetJobs(0, e)}
                                            required={true}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <SearchIcon color="primary" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        <Popover
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={() => setAnchorEl(null)}
                                            PaperProps={{
                                                sx: {
                                                    width: 500,
                                                    maxHeight: 400,
                                                    overflowY: "auto",
                                                },
                                            }}
                                        >
                                            <Box p={2}>
                                                {isJobsLoading ? (
                                                    <Skeleton width={100} height={20} />
                                                ) : (
                                                    <Typography variant="subtitle1">
                                                        Total Jobs: {totalJobsCount}
                                                    </Typography>
                                                )}
                                                <TextField
                                                    label="Search Jobs"
                                                    placeholder="Search jobs or healthcare facilities"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                    margin="normal"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            <Paper>
                                                {isJobsLoading ? (
                                                    <List dense>
                                                        {[...Array(10)].map((_, index) => (
                                                            <ListItem key={index}>
                                                                <Skeleton width="100%" height={40} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                ) : filteredJobs?.length > 0 ? (
                                                    <List dense>
                                                        {filteredJobs?.map((job) => (
                                                            <ListItem
                                                                key={job?.id}
                                                                button
                                                                onClick={() => {
                                                                    setFormState((prev) => ({
                                                                        ...prev,
                                                                        selectedJob: job,
                                                                        selectedSpecialty: job?.specialtyId,
                                                                        selectedProfession: job?.professionId,
                                                                    }));
                                                                    setAnchorEl(null);
                                                                }}
                                                            >
                                                                <ListItemText
                                                                    primary={job?.jobTitle}
                                                                    secondary={
                                                                        <Stack direction="column" spacing={1}>
                                                                            {/* Render clientName if available */}
                                                                            {job?.clientName && (
                                                                                <Typography
                                                                                    variant="subtitle2"
                                                                                    color="textSecondary"
                                                                                    sx={{ fontSize: "12px" }}
                                                                                >
                                                                                    {job?.clientName}
                                                                                </Typography>
                                                                            )}

                                                                            {/* Render client location if all location fields are available */}
                                                                            {(job?.clientCity ||
                                                                                job?.clientState ||
                                                                                job?.clientCountry) && (
                                                                                <Stack
                                                                                    direction="row"
                                                                                    alignItems="center"
                                                                                    spacing={1}
                                                                                >
                                                                                    <LocationOnIcon
                                                                                        sx={{ fontSize: "16px" }}
                                                                                        color="action"
                                                                                    />
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="textSecondary"
                                                                                        sx={{ fontSize: "12px" }}
                                                                                    >
                                                                                        {`${job?.clientCity || ""}${job?.clientCity && job?.clientState ? ", " : ""}${job?.clientState || ""}${(job?.clientCity || job?.clientState) && job?.clientCountry ? ", " : ""}${job?.clientCountry || ""}`}
                                                                                    </Typography>
                                                                                </Stack>
                                                                            )}
                                                                        </Stack>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                ) : (
                                                    <Box
                                                        p={2}
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        height="100%"
                                                    >
                                                        <Typography variant="body2" color="textSecondary">
                                                            No jobs found with current filters
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Paper>
                                            {filteredJobs?.length > 0 && (
                                                <Box
                                                    p={2}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Button
                                                        disabled={formState.currentPage === 0}
                                                        onClick={() => handlePageChange(formState.currentPage - 1)}
                                                    >
                                                        Previous
                                                    </Button>
                                                    <Typography variant="body1" color="textPrimary">
                                                        Page No: {formState.currentPage + 1}
                                                    </Typography>
                                                    <Button
                                                        disabled={
                                                            totalJobsCount < formState.currentPage * ITEMS_PER_PAGE + 1
                                                        }
                                                        onClick={() => handlePageChange(formState.currentPage + 1)}
                                                    >
                                                        Next
                                                    </Button>
                                                </Box>
                                            )}
                                        </Popover>
                                    </Stack>
                                )}
                            </>
                        )}

                        {formState?.selectedJob && (
                            <TextField
                                label="Status"
                                variant="outlined"
                                value={formState.selectedStatus}
                                disabled={true}
                                style={{ backgroundColor: "#e0e0e0", color: "#555" }}
                            />
                        )}
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={handleConfirm}
                        disabled={!isConfirmValid() || isSubmitting}
                        variant="contained"
                        color="primary"
                    >
                        {isSubmitting ? <CircularProgress size={20} /> : "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};

export default LaborEdgeModal;
