import { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Link,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { nameInitials } from "../../../../utils/helper";
import { CandidateReachoutTabs } from "../../../all-candidates-reachout/all-candidates-reachout.types";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import { fetchEvents, IEventType, selectEvent } from "./upComingEvents.slice";

interface TabPanelProps {
    children?: React.ReactNode;
    index: CandidateReachoutTabs;
    value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div hidden={value !== index} {...other} style={{ height: "100%" }}>
            {children}
        </div>
    );
}

const MAX_CONTENT_HEIGHT = 600;

function formatDateString(inputDate: string): string {
    const dateObject = new Date(inputDate);

    const day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(dateObject);
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObject);
    const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(dateObject);

    function addSuffix(num: number): string {
        const suffixes: Record<string, string> = {
            "1": "st",
            "2": "nd",
            "3": "rd",
        };
        const remainder10 = num % 10;
        const remainder100 = num % 100;

        return remainder10 === 1 && remainder100 !== 11
            ? suffixes["1"]
            : remainder10 === 2 && remainder100 !== 12
              ? suffixes["2"]
              : remainder10 === 3 && remainder100 !== 13
                ? suffixes["3"]
                : "th";
    }

    const formattedDate = `${day}${addSuffix(Number(day))} ${month} ${year}`;
    return formattedDate;
}

function extractTimeFromDateString(inputDate: string): string {
    const dateObject = new Date(inputDate);

    const hours: number = dateObject.getHours();
    const minutes: number = dateObject.getMinutes();
    const period: string = hours >= 12 ? "PM" : "AM";

    const formattedHours: number = hours % 12 || 12;

    const formattedMinutes: string = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const formattedTime: string = `${formattedHours}:${formattedMinutes} ${period}`;
    return formattedTime;
}

type questionAnswer = Array<{ question: string; answer?: string }>;

const shouldShowQuestionAnswers = (
    questions_and_answers: questionAnswer | undefined
): questions_and_answers is Array<{ question: string; answer: string }> => {
    if (!questions_and_answers || questions_and_answers.length === 0) return false;

    return questions_and_answers.some((item) => item?.answer);
};

const SingleDayEvent = ({
    onClose,
    date,
    data,
    index,
    past,
}: {
    onClose: () => void;
    date: string;
    data: IEventType[];
    index: number;
    past: boolean;
}) => {
    return (
        <Stack
            sx={(theme) => ({
                borderTop: "1px solid",
                borderColor: index === 0 ? "transparent" : theme.palette.grey[300],
            })}
            py={2}
            direction={"row"}
            gap={1}
            alignItems={"flex-start"}
        >
            <Stack flexGrow={1} gap={1} direction={"column"}>
                <Typography
                    sx={(theme) => ({
                        fontSize: "12px",
                        color: theme.palette.grey[600],
                        fontWeight: "bold",
                    })}
                    width={100}
                    flexShrink={0}
                >
                    {date}
                </Typography>
                {data.map((event, index) => {
                    return (
                        <Accordion
                            disableGutters
                            elevation={0}
                            sx={(theme) => ({
                                "&:before": { display: "none" },
                                boxShadow: "none",
                                border: "1px solid",
                                borderRadius: "0.5rem !important",
                                borderColor: theme.palette.grey[300],
                            })}
                            key={index}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Stack width={"100%"} alignItems={"center"} pr={1} gap={2} direction={"row"}>
                                    <Stack gap={1} alignItems={"center"} direction={"row"} flexShrink={0}>
                                        <Typography
                                            sx={(theme) => ({
                                                fontSize: "11px",
                                                color: theme.palette.grey[600],
                                            })}
                                        >
                                            {extractTimeFromDateString(event.payload.scheduled_event.start_time)} -{" "}
                                            {extractTimeFromDateString(event.payload.scheduled_event.end_time)}
                                        </Typography>
                                    </Stack>
                                    <Typography>
                                        <Typography sx={{ fontSize: "13px" }}>{event.payload.name}</Typography>
                                        <Typography
                                            sx={(theme) => ({
                                                fontSize: "13px",
                                                fontStyle: "italic",
                                                color: theme.palette.grey[600],
                                            })}
                                        >
                                            {event.payload.scheduled_event.name}
                                        </Typography>
                                    </Typography>

                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            fontWeight: "bold",
                                            fontSize: "13px",
                                            background: "#c4c4c4",
                                            borderRadius: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <Avatar
                                            src={
                                                event?.profileImage &&
                                                event?.profileImage !== "-" &&
                                                !event?.profileImage?.startsWith("data:") &&
                                                !event?.profileImage.toLocaleLowerCase().endsWith(".svg")
                                                    ? event.profileImage
                                                    : event.payload.name
                                            }
                                            alt={event.payload.name}
                                        />
                                    </Box>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction={"column"} gap={1}>
                                    {event?.rescheduleInfo ? (
                                        <Typography fontSize={"13px"}>
                                            Rescheduled by {event.rescheduleInfo.name}
                                            {event.rescheduleInfo?.reason && (
                                                <Typography
                                                    fontSize={"13px"}
                                                    display={"inline-block"}
                                                    sx={(theme) => ({
                                                        color: theme.palette.grey[600],
                                                    })}
                                                >
                                                    : "{event.rescheduleInfo.reason}"
                                                </Typography>
                                            )}
                                        </Typography>
                                    ) : null}
                                    {shouldShowQuestionAnswers(event?.payload?.questions_and_answers) ? (
                                        <>
                                            <Typography fontSize={"13px"} fontWeight={"medium"}>
                                                Questions
                                            </Typography>
                                            <Stack direction={"column"}>
                                                {event.payload.questions_and_answers.map((item) => {
                                                    if (!item.answer) return null;
                                                    return (
                                                        <>
                                                            <Typography fontSize={"13px"}>{item.question}</Typography>
                                                            <Typography
                                                                fontSize={"13px"}
                                                                sx={(theme) => ({
                                                                    color: theme.palette.grey[600],
                                                                })}
                                                            >
                                                                {item.answer}
                                                            </Typography>
                                                        </>
                                                    );
                                                })}
                                            </Stack>
                                        </>
                                    ) : null}
                                    {!past && (
                                        <Stack alignItems={"center"} direction={"row"} gap={2}>
                                            <Link target="_blank" href={event.payload.reschedule_url}>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    onClick={onClose}
                                                    color="primary"
                                                    sx={{ boxShadow: "none" }}
                                                >
                                                    Reschedule
                                                </Button>
                                            </Link>

                                            <Link target="_blank" href={event.payload.cancel_url}>
                                                <Button
                                                    size="small"
                                                    onClick={onClose}
                                                    variant="outlined"
                                                    color="error"
                                                    sx={{ boxShadow: "none" }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Link>
                                        </Stack>
                                    )}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Stack>
        </Stack>
    );
};

const EventsList = ({
    isFetchingEvents,
    events = [],
    onClose,
    past,
}: {
    past: boolean;
    onClose: () => void;
    isFetchingEvents: boolean;
    events: IEventType[];
}) => {
    function groupUpcomingEvents(events: IEventType[]): Record<string, IEventType[]> {
        const groupedData = events?.reduce((result: Record<string, IEventType[]>, item) => {
            const startTime = formatDateString(item.payload.scheduled_event.start_time);
            if (!result[startTime]) {
                result[startTime] = [];
            }
            result[startTime].push(item);
            return result;
        }, {});
        return groupedData;
    }
    const groupedEvents = groupUpcomingEvents(events);
    const isEmpty = !events.length && !isFetchingEvents;
    return (
        <Stack
            sx={{
                position: "relative",
            }}
        >
            {isFetchingEvents && (
                <Stack
                    flexGrow={1}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"full"}
                    direction={"row"}
                    paddingY={20}
                >
                    <CircularProgress size={20} />
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.grey[600],
                        })}
                    >
                        Loading events...
                    </Typography>
                </Stack>
            )}

            {isEmpty && (
                <Stack
                    flexGrow={1}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"full"}
                    direction={"row"}
                    paddingY={5}
                >
                    <ErrorOutlineIcon sx={(theme) => ({ color: theme.palette.grey[600] })} />
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.grey[600],
                        })}
                    >
                        No events
                    </Typography>
                </Stack>
            )}

            {!isFetchingEvents && !isEmpty && (
                <Stack direction={"column"} pt={1}>
                    {Object.keys(groupedEvents).map((key, index) => {
                        return (
                            <SingleDayEvent
                                index={index}
                                onClose={onClose}
                                key={key}
                                date={key}
                                data={groupedEvents[key]}
                                past={past}
                            />
                        );
                    })}
                </Stack>
            )}
        </Stack>
    );
};

export default function UpcomingEventModal({ onClose }: { onClose: () => void }) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const isFetchingEvents = useSelector(checkIfLoading(fetchEvents.type));
    const [activeTab, setActiveTab] = useState(0);

    const events = useSelector(selectEvent);

    useEffect(() => {
        if (id) {
            dispatch(fetchEvents({ id: id }));
        }
    }, [id]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const pastEvents = events.pastEvents;
    const upcomingEvents = events.upcomingEvents;

    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                padding: "1rem 1.5rem",
                width: "35vw",
                minWidth: "500px",
            }}
        >
            <Stack sx={{ overflow: "scroll", height: `${MAX_CONTENT_HEIGHT}px` }}>
                <Tabs sx={{ borderBottom: 1, borderColor: "divider" }} value={activeTab} onChange={handleTabChange}>
                    <Tab
                        value={0}
                        label={"Upcoming Events"}
                        sx={(theme) => ({
                            fontSize: theme.typography.body2,
                        })}
                    />
                    <Tab
                        value={1}
                        label={"Past Events"}
                        sx={(theme) => ({
                            fontSize: theme.typography.body2,
                        })}
                    />
                </Tabs>
                <Box sx={{ flexGrow: 1 }}>
                    <CustomTabPanel value={activeTab} index={0}>
                        <EventsList
                            onClose={onClose}
                            past={false}
                            isFetchingEvents={isFetchingEvents}
                            events={upcomingEvents}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={activeTab} index={1}>
                        <EventsList
                            onClose={onClose}
                            past={true}
                            isFetchingEvents={isFetchingEvents}
                            events={pastEvents}
                        />
                    </CustomTabPanel>
                </Box>
            </Stack>
        </BaseModal>
    );
}
