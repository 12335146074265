import { Stack } from "@mui/material";

import BaseModal from "@/components/BaseModal";

interface DoneFeedbackModalProps {
    open: boolean;
    onClose: () => void;
    recruiterName: string;
}

function DoneFeedbackModal({ open, onClose, recruiterName }: DoneFeedbackModalProps) {

    return (
        <>
            {open ? (
                <BaseModal
                    onClose={onClose}
                    overlayStyles={{
                        padding: "1.7rem 1.5rem",
                        width: 600,
                    }}
                    hideCloseButton
                >
                    <p style={{ fontSize: "25px", fontWeight: "600", marginBottom: "1.75rem" }}>
                        Thanks for your feedback
                    </p>
                    <p style={{ fontSize: "14px", marginLeft: "2px" }}>
                        Your feedback on candidates has been successfully recorded. I appreciate your time and effort in
                        evaluating these candidates, and I will keep you updated on the next steps in the hiring
                        process.
                    </p>
                    <p style={{ fontSize: "14px", marginLeft: "2px", marginTop:"10px" }}>
                        Regards,
                        <br />
                        {recruiterName}
                    </p>

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{ marginTop: "2rem" }}
                    >
                        <p style={{ fontSize: "12px", fontStyle: "italic", color: "gray" }}>
                            PS: You can close this window now.
                        </p>
                        {/* 
                        <Button
                            variant="default"
                            style={{ borderRadius: "4px", fontSize: "12px", border: "none", minWidth: "fit-content" }}
                            disabled={isLoading}
                            onClick={() => {
                                console.log("1")
                                window.close()
                                console.log("2")
                            }}
                        >
                            {isLoading ? "Sending..." : " Yes, close"}
                        </Button> */}
                    </Stack>
                </BaseModal>
            ) : null}
        </>
    );
}

export default DoneFeedbackModal;
