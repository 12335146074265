import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import JoyOption from "@mui/joy/Option";
import JoySelect from "@mui/joy/Select";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputBase,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
    Tooltip,
    Typography,
    alpha,
    styled,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { type ActionMeta, CSSObjectWithLabel, type MultiValue, Props as SelectProps } from "react-select";
import CreatableSelect from "react-select/creatable";

import {
    addAllCandidatesFilter,
    applyAllCandidatesFilters,
    createListFromFilters,
    getSavedFiltersList,
    isFiltersEmpty,
    selectActivityErrors,
    selectAllCandidatesFilterDrawerState,
    selectAllCandidatesFilters,
    selectAllDisabledFilters,
    selectFilterValue,
    selectSavedFiltersList,
    setAllCandidatesFilterValue,
    syncFilters,
    toggleAllCandidatesFiltersDrawer,
} from "./all-candidates.slice";
import { useFetchActivityTags } from "./AllCandidatesContent";
import { Fetching } from "./AllCandidatesToolbar";

import { Option, ReadOnlyOption } from "../../common";
import { JoyProvider } from "../../components/JoyProvider";
import { CANDIDATE_STAGE_ITEMS_MUI } from "../../constant/Constant";
import useDisclosure from "../../hooks/useDisclosure";
import { RootState } from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { labelOptions } from "../all-candidates/all-candidates.utils";
import {
    activityTagsOption,
    detailedProjectStageOptionsList,
} from "../all-candidates-reachout/all-candidates-reachout.types";
import { selectAllProjects } from "../allProjects/index.reducer";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: 14,
        width: "100%",
        padding: "8px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function FilterAccordion({ filterKey, children }: { filterKey: string; children: React.ReactNode }) {
    const dispatch = useDispatch();
    const filter = useSelector(selectAllCandidatesFilters)[filterKey];
    const value = useSelector((state: RootState) => selectFilterValue(state, filterKey));
    const openAccordion = value?.length;
    const [expanded, setExpanded] = useState(() => (openAccordion ? true : false));

    if (!filter) {
        return null;
    }

    const handleChange = (e: React.SyntheticEvent<Element, Event>) => {
        e.stopPropagation();
        setExpanded((prev) => {
            if (openAccordion) {
                return prev;
            }

            return !prev;
        });
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.grey[50],
                    gridTemplateColumns: "1fr",
                    display: "grid",
                    gridTemplateRows: "1fr",
                    alignItems: "center",
                })}
            >
                <AccordionSummary
                    sx={(theme) => ({
                        backgroundColor: theme.palette.grey[50],
                        paddingLeft: 1,
                    })}
                >
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: 1 }}>
                        <Typography variant="body2">{filter?.label}</Typography>
                    </Stack>
                </AccordionSummary>
            </Box>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
}

function useFilterInputsState(filterKey: string) {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState("");
    const filters = useSelector((state: RootState) => selectFilterValue(state, filterKey));
    // @ts-ignore
    const [values, setValues] = useState<ReadOnlyOption[]>(() => (filters?.length ? filters : []));

    const handleChange = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
        const value = newValue as MultiValue<ReadOnlyOption>;
        const newValues = actionMeta.action !== "clear" ? value.map(({ label, value }) => ({ label, value })) : [];
        setValues(newValues);
        dispatchFilters(newValues);
    };

    const dispatchFilters = debounce(
        (newValues: ReadOnlyOption[]) =>
            dispatch(
                setAllCandidatesFilterValue({
                    key: filterKey,
                    value: newValues,
                })
            ),
        500
    );

    const getSelectProps = (options: Option[] = [], showOptions = false): SelectProps => ({
        styles: {
            option: (baseStyles) =>
                ({
                    ...baseStyles,
                    fontSize: "14px !important",
                }) as CSSObjectWithLabel,
            noOptionsMessage: (baseStyles) =>
                ({
                    ...baseStyles,
                    fontSize: "14px !important",
                }) as CSSObjectWithLabel,
            placeholder: (baseStyles) =>
                ({
                    ...baseStyles,
                    fontSize: "14px !important",
                }) as CSSObjectWithLabel,
            input: (baseStyles) =>
                ({
                    ...baseStyles,
                    fontSize: "14px !important",
                }) as CSSObjectWithLabel,
            menu: (baseStyles) =>
                ({
                    ...baseStyles,
                    maxHeight: "200px !important",
                    overflowY: "auto",
                }) as CSSObjectWithLabel,
        },
        isClearable: true,
        isMulti: true,
        value: values,
        placeholder: "type...",
        onInputChange: (newValue: string) => setInputValue(newValue),
        options: showOptions ? options : undefined,
        onChange: handleChange,
    });

    return { inputValue, getSelectProps, dispatchFilters };
}

function ActivityInput({ filterKey }: { filterKey: string }) {
    const { isLoading, data: response = [], isError } = useFetchActivityTags();
    const { getSelectProps } = useFilterInputsState(filterKey);

    const options = isLoading || isError ? response.map((i) => ({ label: i, value: i })) : [];

    return <Select {...getSelectProps(options, true)} isLoading={isLoading} />;
}

const internalCandidateReviewOptions = CANDIDATE_STAGE_ITEMS_MUI.map(({ key, label }) => ({
    label,
    value: key,
}));

function FiltersInput({ filterKey }: { filterKey: string }) {
    const { inputValue, getSelectProps } = useFilterInputsState(filterKey);
    const allProjectsList = useSelector(selectAllProjects);
    const projectListOptions: Option[] = allProjectsList.map(({ _id, name }) => ({ label: name, value: String(_id) }));

    const isProjectStage = filterKey === "Project Stage";

    const isContactOverview = filterKey === "Contact overview";

    const isLabel = filterKey === "Label";

    const isListOfProjects = filterKey === "List of projects";

    const isActivity = filterKey === "Activity";

    // if you're conditionally rendering, don't forget to add your check here(at-least for the activity fields).
    const showOptions = isProjectStage || isContactOverview || isLabel || isListOfProjects || isActivity;

    if (isActivity) {
        return <Select {...getSelectProps(activityTagsOption, showOptions)} />;
    }

    if (isListOfProjects) {
        return <Select {...getSelectProps(projectListOptions, showOptions)} />;
    }

    if (isProjectStage) {
        return <Select {...getSelectProps(internalCandidateReviewOptions, showOptions)} />;
    }

    if (isContactOverview) {
        return <Select {...getSelectProps(detailedProjectStageOptionsList, showOptions)} />;
    }

    if (isLabel) {
        return <Select {...getSelectProps(labelOptions, showOptions)} />;
    }

    return <CreatableSelect {...getSelectProps()} inputValue={inputValue} />;
}

function AllContactFiltersTitle() {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
                padding: theme.spacing(2),
            })}
        >
            <Typography variant="h6">Filters</Typography>
            <IconButton>
                <CloseRoundedIcon
                    sx={(theme) => ({
                        color: theme.palette.common.white,
                    })}
                />
            </IconButton>
        </Stack>
    );
}

function FiltersList({ toggle }: { toggle: () => void }) {
    const dispatch = useDispatch();
    const [query, searchQuery] = useState("");
    const disabledFilters = useSelector(selectAllDisabledFilters);
    const handleFilterClick = (filterKey: string) => {
        dispatch(
            addAllCandidatesFilter({
                key: filterKey,
                hidden: false,
            })
        );
        toggle();
    };

    return (
        <Stack
            sx={(theme) => ({
                backgroundColor: theme.palette.common.white,
                borderTopLeftRadius: theme.shape.borderRadius,
                borderTopRightRadius: theme.shape.borderRadius,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: theme.spacing(2),
            })}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            spacing={1}
        >
            <Stack spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography
                        variant="body1"
                        sx={(theme) => ({
                            fontWeight: theme.typography.fontWeightMedium,
                        })}
                    >
                        Filters
                    </Typography>
                    <IconButton onClick={toggle}>
                        <CloseRoundedIcon
                            sx={(theme) => ({
                                color: theme.palette.grey[500],
                            })}
                        />
                    </IconButton>
                </Stack>
                <BootstrapInput value={query} onChange={(e) => searchQuery(e.target.value)} />
            </Stack>
            <Box sx={{ height: 400, overflowY: "auto" }}>
                {Object.entries(disabledFilters)
                    .filter(([_, value]) => {
                        return value.label.toLocaleLowerCase().includes(query.toLocaleLowerCase());
                    })
                    .map(([key, value]) => {
                        return (
                            <ListItem disablePadding key={key}>
                                <ListItemButton onClick={() => handleFilterClick(key)}>
                                    <ListItemText
                                        primary={value.label}
                                        sx={(theme) => ({
                                            "& .MuiTypography-root": {
                                                fontSize: theme.typography.fontSize,
                                            },
                                        })}
                                    />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
            </Box>
        </Stack>
    );
}

function AllContactsFiltersContent({
    onCloseFilters,
    onOpenSaveFiltersModal,
}: {
    onCloseFilters: () => void;
    onOpenSaveFiltersModal: () => void;
}) {
    const dispatch = useDispatch();

    const [showFiltersList, setShowFiltersList] = useState(false);
    const filters = useSelector(selectAllCandidatesFilters);
    const isFiltersLoading = useSelector(checkIfLoading(applyAllCandidatesFilters.type));
    const activityError = useSelector(selectActivityErrors);
    const toggle = () => setShowFiltersList((prev) => !prev);
    const filtersArr = Object.keys(filters);
    const isDisabled = isFiltersLoading;
    const savedFiltersList = useSelector(selectSavedFiltersList);
    const isFilterDirty = useSelector(isFiltersEmpty);

    useEffect(() => {
        dispatch(getSavedFiltersList());
    }, []);

    const handleChange = (event: React.SyntheticEvent | null, newValue: string | null) => {
        if (!newValue) return;
        const selectedFilter = savedFiltersList.find((element) => element._id === newValue);
        if (!selectedFilter) return;
        dispatch(
            syncFilters({
                filters: selectedFilter.filters,
                onSuccess: () => {
                    setTimeout(() => {
                        onCloseFilters();
                    }, 0);
                },
            })
        );
    };

    return (
        <Box
            sx={(theme) => ({
                padding: theme.spacing(2),
                height: "100%",
                display: "grid",
                gridTemplateColumns: "1fr",
                gridTemplateRows: "auto 1fr auto",
                gap: "1rem",
            })}
            onClick={(e) => e.stopPropagation()}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" fontWeight={600}>
                    Advanced Filters ({filtersArr.length})
                </Typography>
                <Fetching />
            </Stack>
            <Stack sx={{ maxHeight: "73vh", overflowY: "auto" }} pb={15}>
                {filtersArr.map((key, index) => {
                    return (
                        <FilterAccordion key={key} filterKey={key}>
                            {key === "Activity" ? <ActivityInput filterKey={key} /> : <FiltersInput filterKey={key} />}
                        </FilterAccordion>
                    );
                })}{" "}
            </Stack>
            {activityError && (
                <Typography
                    variant="body2"
                    textAlign="left"
                    sx={(theme) => ({
                        color: theme.palette.primary.light,
                        fontWeight: theme.typography.fontWeightMedium,
                    })}
                >
                    {activityError}
                </Typography>
            )}

            <Stack direction="row" justifyContent={"flex-end"} gap={1}>
                <JoyProvider>
                    <JoySelect sx={{ flexGrow: 1 }} onChange={handleChange} placeholder="Select from saved filters">
                        <div style={{ maxHeight: "300px", width: "342.5px", overflowY: "scroll" }}>
                            {savedFiltersList.map((item) => {
                                return (
                                    <JoyOption key={item._id} value={item._id}>
                                        <Stack
                                            direction="row"
                                            gap={5}
                                            alignItems={"center"}
                                            width={"100%"}
                                            justifyContent={"space-between"}
                                        >
                                            <Typography>{item.name}</Typography>
                                            <Typography sx={{ fontSize: "12px", fontStyle: "italic", color: "grey" }}>
                                                {dayjs(item.createdAt).format("MMMM D, YYYY")}
                                            </Typography>
                                        </Stack>
                                    </JoyOption>
                                );
                            })}
                        </div>
                    </JoySelect>
                </JoyProvider>
                <Tooltip title={isFilterDirty ? "Select at least one filter to save filter" : ""}>
                    <span>
                        <Button
                            sx={(theme) => ({
                                width: "fit-content",
                                color: theme.palette.common.white,
                                fontWeight: theme.typography.fontWeightMedium,
                                backgroundColor: theme.palette.primary.main,
                                "&:hover": {
                                    opacity: 1,
                                    color: theme.palette.primary.main,
                                    backgroundColor: alpha(theme.palette.primary.main, 0.09),
                                },
                                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                                fontSize: theme.typography.body2.fontSize,
                                marginRight: theme.spacing(1),
                                borderRadius: "0.5rem",
                            })}
                            onClick={() => {
                                onCloseFilters();
                                onOpenSaveFiltersModal();
                            }}
                            disabled={isFilterDirty}
                        >
                            Save filters
                        </Button>
                    </span>
                </Tooltip>
            </Stack>

            {/* <Stack direction="row" spacing={1} justifyContent="center">
                <Button
                    onClick={toggle}
                    variant="contained"
                    startIcon={<AddRoundedIcon />}
                    disabled={isDisabled}
                    sx={(theme) => ({
                        width: "fit-content",
                        height: "fit-content",
                        fontSize: theme.typography.button,
                        textTransform: "capitalize",
                        alignSelf: "center",
                    })}
                >
                    Add Filter
                </Button> */}
            {/* <Button
                    onClick={handleApplyFilters}
                    variant="contained"
                    disabled={isDisableSubmit}
                    endIcon={<SendRoundedIcon />}
                    sx={(theme) => ({
                        width: "fit-content",
                        height: "fit-content",
                        fontSize: theme.typography.button,
                        textTransform: "capitalize",
                        alignSelf: "center",
                        cursor: isDisableSubmit ? "not-allowed" : "pointer",
                    })}
                >
                    Submit
                </Button> */}
            {/* </Stack> */}
            {/* {showFiltersList && (
                <Box
                    sx={(theme) => ({
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        top: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        margin: "0 !important",
                    })}
                    role="presentation"
                    onClick={toggle}
                    onKeyDown={toggle}
                >
                    <Box
                        sx={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <Stack
                            sx={(theme) => ({
                                backgroundColor: theme.palette.common.white,
                                borderTopLeftRadius: theme.shape.borderRadius,
                                borderTopRightRadius: theme.shape.borderRadius,
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                padding: theme.spacing(2),
                            })}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                            spacing={1}
                        >
                            <Stack spacing={1}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        variant="body1"
                                        sx={(theme) => ({
                                            fontWeight:
                                                theme.typography
                                                    .fontWeightMedium,
                                        })}
                                    >
                                        Contact Properties
                                    </Typography>
                                    <IconButton onClick={toggle}>
                                        <CloseRoundedIcon
                                            sx={(theme) => ({
                                                color: theme.palette.grey[500],
                                            })}
                                        />
                                    </IconButton>
                                </Stack>
                                <BootstrapInput />
                            </Stack>
                            <Box sx={{ maxHeight: 300, overflowY: "auto" }}>
                                <FiltersList toggle={toggle} />
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            )} */}
        </Box>
    );
}

export default function AllContactsFilters() {
    const dispatch = useDispatch();
    const [saveFilterName, setSaveFilterName] = useState("");
    const open = useSelector(selectAllCandidatesFilterDrawerState);
    const filters = useSelector(selectAllCandidatesFilters);
    const isSavingListFromFilters = useSelector(checkIfLoading(createListFromFilters.type));

    const toggle = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === "keydown") {
            return;
        }

        dispatch(toggleAllCandidatesFiltersDrawer());
    };

    const {
        isOpen: isOpenSaveFiltersModal,
        onOpen: onOpenSaveFiltersModal,
        onClose: onCloseSaveFiltersModal,
    } = useDisclosure();

    return (
        <>
            <Drawer anchor="right" open={open} onClose={toggle}>
                <Box
                    sx={{
                        width: 500,
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: "auto 1fr",
                        flex: 1,
                        position: "relative",
                    }}
                    onClick={toggle}
                    onKeyDown={toggle}
                >
                    <AllContactFiltersTitle />
                    <AllContactsFiltersContent
                        onCloseFilters={() => {
                            dispatch(toggleAllCandidatesFiltersDrawer());
                        }}
                        onOpenSaveFiltersModal={onOpenSaveFiltersModal}
                    />
                </Box>
            </Drawer>

            <Dialog
                onClose={onCloseSaveFiltersModal}
                open={isOpenSaveFiltersModal}
                sx={{
                    "& .MuiPaper-root": {
                        width: "500px",
                    },
                }}
            >
                <DialogTitle>Save filters</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Stack>
                            <TextField
                                placeholder="Filter list name"
                                inputProps={{
                                    style: {
                                        padding: "0.7rem",
                                        fontSize: "14px",
                                    },
                                }}
                                value={saveFilterName}
                                onChange={(e) => setSaveFilterName(e.target.value)}
                            />{" "}
                            <Typography sx={{ fontSize: "12px", pt: 1, fontStyle: "italic" }}>
                                *Ensure this name is descriptive enough to recall your filters later
                            </Typography>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" pb={1} spacing={1}>
                        <Button variant="outlined" onClick={onCloseSaveFiltersModal}>
                            Close
                        </Button>
                        <Button
                            disabled={!saveFilterName || isSavingListFromFilters}
                            variant="contained"
                            onClick={() => {
                                dispatch(
                                    createListFromFilters({
                                        projectName: saveFilterName,
                                        filters: filters,
                                        onSuccess: () => {
                                            onCloseSaveFiltersModal();
                                            setSaveFilterName("");
                                        },
                                    })
                                );
                            }}
                        >
                            Save
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
}
