import Typography, { TypographyProps } from "@mui/material/Typography";

export function ReadOnlyStatus({ status, sx }: { status?: string; sx?: TypographyProps["sx"] }) {
    if (!status) {
        return null;
    }

    return (
        <Typography
            variant="body2"
            sx={{
                position: "absolute",
                top: "7px",
                right: "-90px",
                ...sx,
            }}
        >
            {status}
        </Typography>
    );
}
