import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin
import utc from "dayjs/plugin/utc";
import { z } from "zod";

import { OutReachEvent } from "../pages/OutReach/Outreach.types";
import validateSenderEmail, { allowedDomains } from "./validateSenderEmail"; // Necessary for timezone processing

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export const SELECT_CANDIDATE = "Select candidate";

interface Timestamps {
    emailTimestamp: string;
    followupEmailTimestamp: string;
}

export function checkFollowupEarlier(timestamps: Timestamps): boolean {
    const { emailTimestamp, followupEmailTimestamp } = timestamps;
    const emailDate = dayjs(emailTimestamp);
    const followupDate = dayjs(followupEmailTimestamp);

    // Check if both dates are valid
    if (!emailDate.isValid() || !followupDate.isValid()) {
        throw new Error("One or both timestamps are invalid.");
    }

    return followupDate.isBefore(emailDate);
}

export function getTimestampBasedOnTimezone(timestamp: string) {
    // Parse the input ISO string as UTC
    const utcTime = dayjs.utc(timestamp);
    const timezone = dayjs.tz.guess();

    // Convert to user's local timezone
    const userTime = utcTime.tz(timezone);

    // Return the time in ISO string format
    return userTime.format();
}

export const senderEmailSchema = z
    .string()
    .optional()
    .refine((val) => validateSenderEmail(val || ""), {
        message: `Invalid email or domain not allowed.\nAllowed domains are: ${allowedDomains.join(", ")}
                    `,
    });

export const outreachTooltipMapping: {
    [k in OutReachEvent]: string;
} = {
    "follow-up": "Follow-up email",
    email: "Email",
    SMS: "SMS message",
    linkedin: "Linkedin",
};

export function getDaysFromNow(isoTimestamp: string): number {
    const now = dayjs(); // Current time
    const then = dayjs(isoTimestamp); // Convert ISO string to dayjs object
    const nowDate = now.format("YYYY-MM-DD");
    const thenDate = then.format("YYYY-MM-DD");
    const diff = dayjs(nowDate).diff(dayjs(thenDate), "day");
    return Math.abs(diff); // Return the absolute value to avoid negative numbers
}

export function getFutureTimestampWithSameTime(dayCount: number, timestamp: string) {
    let date = dayjs();

    date = date.add(dayCount, "day");

    if (timestamp) {
        const time = dayjs(timestamp).format("HH:mm:ss");
        date = dayjs(date.format("YYYY-MM-DD") + "T" + time);
    }

    return date; // Convert the Day.js object back to a timestamp in milliseconds.
}

export function getDescriptiveTimeZoneName(
    timestamp: string = new Date().toISOString(),
    locale: string = "en-US"
): string {
    const date: Date = new Date(timestamp); // Convert timestamp to Date object
    const options: Intl.DateTimeFormatOptions = { timeZoneName: "long" };
    const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat(locale, options);
    const parts: Intl.DateTimeFormatPart[] = formatter.formatToParts(date);
    const timeZoneNamePart: Intl.DateTimeFormatPart | undefined = parts.find((part) => part.type === "timeZoneName");

    return timeZoneNamePart ? timeZoneNamePart.value : "Unknown Time Zone";
}

function formatDate(timestamp: string): string {
    // Convert the timestamp to a dayjs object
    const date = dayjs(timestamp);

    // Format the date
    // 'D' gets the day of the month, 'MMMM' gets the full month name. Adjust format as needed.
    const dayOfMonth = date.format("D");
    const month = date.format("MMM");

    // Determine the suffix for the day of the month
    let suffix = "th";
    const j = Number(dayOfMonth) % 10,
        k = Number(dayOfMonth) % 100;
    if (j === 1 && k !== 11) {
        suffix = "st";
    } else if (j === 2 && k !== 12) {
        suffix = "nd";
    } else if (j === 3 && k !== 13) {
        suffix = "rd";
    }

    return `${dayOfMonth}${suffix} ${month}`;
}

export function formatTimeFromISOString(isoDateString: string): string {
    return dayjs(isoDateString).format("h:mma");
}

const elementDescription: {
    [k in OutReachEvent]: { leading: string; trailing: string };
} = {
    email: {
        leading: "Emails",
        trailing: "",
    },

    "follow-up": {
        leading: "Follow-up emails",
        trailing: "to people who received the previous email and who did not reply on email and LinkedIn",
    },
    SMS: {
        leading: "SMS",
        trailing: "all people with available phone numbers",
    },
    linkedin: {
        leading: "Linkedin",
        trailing: "",
    },
};

export function getElementDescription({
    elementType,
    day,
    time,
}: {
    elementType: OutReachEvent;
    time: string;
    day: string;
}) {
    const { leading, trailing } = elementDescription[elementType];
    return `${leading} will be sent on <b> ${formatDate(day)} </b> starting at <b> ${formatTimeFromISOString(
        time
    )} </b>  ${getDescriptiveTimeZoneName(time)} ${trailing}`;
}
