import { useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FileRejection, useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectCreateContactListModal, setModal } from "../../../store/reducers/modals.slice";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../../utils/helper";
import { FileTile } from "../../ApplyForJobs/ApplyForJobs";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { createContactList } from "../contact-list.slice";
import { useTranslation } from "react-i18next";

const CustomButton = styled(Button)<ButtonProps>(() => ({
    width: "fit-content",
    height: "fit-content",
    fontSize: "12px",
}));

interface CsvDropzoneProps {
    onFilesSelected: (files: File) => void;
}

const CsvDropzone: React.FC<CsvDropzoneProps> = ({ onFilesSelected }) => {
    const [error, setError] = useState<string | null>(null);
    const onDrop = useCallback(
        (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            // Handle accepted and rejected files if needed
            if (acceptedFiles.length === 1) {
                const file = acceptedFiles[0];
                setError(null);
                onFilesSelected(file);
            } else {
                setError("Please select CSV file.");
            }
        },
        [onFilesSelected]
    );

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "text/csv": [".csv"],
            },
            onDrop,
        });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <FormControl error={Boolean(error)}>
            <Stack>
                <div {...getRootProps()} style={{ ...style, textAlign: "center" }}>
                    <input {...getInputProps()} />
                    {isDragActive
                        ? "Drop CSV here ..."
                        : "Click to upload your CSV or drag and drop (allowed formats: .csv)"}
                </div>
                {Boolean(error) && (
                    <FormHelperText
                        sx={{
                            margin: "0",
                            padding: "0.7rem 0",
                            fontSize: "14px",
                        }}
                    >
                        {error}
                    </FormHelperText>
                )}
                <Stack mt={1} spacing={0.5}>
                    {acceptedFiles.map(({ name }) => {
                        return <FileTile name={name} key={name} />;
                    })}
                </Stack>
            </Stack>
        </FormControl>
    );
};

export default function CreateContactListModal() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isCreateContactListModalOpen = useSelector(selectCreateContactListModal);
    const [selectedFile, setSelectedFiles] = useState<File | null>(null);

    const isCreatingContactList = useSelector(checkIfLoading(createContactList.type));

    if (!isCreateContactListModalOpen) {
        return null;
    }

    const onClose = () => dispatch(setModal({ key: "showCreateContactListModal", value: false }));

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        if (selectedFile) {
            form.append("csv", selectedFile);
        }
        dispatch(createContactList(form));
    };

    const handleFilesSelected = (files: File) => {
        setSelectedFiles(files);
    };
    return (
        <BaseModal onClose={onClose}>
            <Stack spacing={2}>
                <Typography variant="h6">{t("allContacts.createContactListBtnText")}</Typography>
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                    }}
                >
                    <TextField
                        type="text"
                        name="name"
                        variant="outlined"
                        sx={{ width: "100%", fontSize: "14px" }}
                        inputProps={{
                            style: { padding: "0.7rem", fontSize: "14px" },
                            maxLength: "50",
                            minLength: "1",
                        }}
                        placeholder="Enter Name"
                        required
                    />
                    <CsvDropzone onFilesSelected={handleFilesSelected} />
                    <CustomButton type="submit" variant="contained" sx={{ alignSelf: "flex-end" }}>
                        <ButtonTextWithLoading isLoading={isCreatingContactList} text="create" />
                    </CustomButton>
                </form>
            </Stack>
        </BaseModal>
    );
}
