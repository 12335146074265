import { useParams } from "react-router-dom";

import Project from "../pages/project/project";
import ProjectWalkthrough from "../pages/projectWalkthrough/projectWalkthrough";

export default function SampleDataRoute() {
    const { id } = useParams();

    if (Number(id) === 9999) {
        return <ProjectWalkthrough />;
    }

    return <Project />;
}
