import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

import {
    FetchVettingCriteriaPayload,
    SetCriteriaPayload,
    cancelSagas,
    fetchVettingCriteria,
    setCriteria,
    setVettingStatus,
    setSuggestedCriteria,
    setErrorWhileFetchingVettingCriteria,
    applySuggestedCriteria,
    revertSuggestedCriteria,
    setIsSuggestionsListEmpty,
    suggestVettingCrtieria,
} from "@/store/reducers/vetting-criteria/vettingCriteria.slice";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { PayloadAction } from "@reduxjs/toolkit";

function* fetchVettingCriteriaSaga(action: FetchVettingCriteriaPayload): SagaIterator {
    try {
        const { id } = action.payload;
        yield put(setErrorWhileFetchingVettingCriteria(false));
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().get, "/v2/project/get/" + id);
        yield put(setVettingStatus(response?.data?.vettingStatus));
        if (response?.data?.suggestedVettingCriteria?.length > 0) {
            const criteria = response.data.suggestedVettingCriteria;
            yield put(setSuggestedCriteria(criteria as SetCriteriaPayload[]));
        } else {
            yield put(setSuggestedCriteria((response?.data?.vettingCriteria as SetCriteriaPayload[]) || []));
        }

        if (response?.data?.vettingCriteria) {
            const criteria = response.data.vettingCriteria;

            yield put(setCriteria(criteria as SetCriteriaPayload[]));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorWhileFetchingVettingCriteria(true));
        console.error({ error });
        yield put(setErrorNotification("error occurred while fetching vetting criteria's"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* suggestVettingCriteriaSaga(
    action: PayloadAction<{ projectId: number; onSuccess?: () => void }>
): SagaIterator {
    try {
        const { projectId, onSuccess } = action.payload;

        const state = yield select();
        const vettingCriteriaStateValue = get(state, "vettingCriteria.suggestedCriteria");

        const vettingCriteria = vettingCriteriaStateValue
            .filter(({ text }: { text: string }) => Boolean(text))
            .map((item: any) => ({
                ...item,
                updatedAt: new Date(),
            }));
        const payload = {
            projectId,
            suggestion: vettingCriteria,
        };
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/vetting-suggestions/vetting-suggested", payload);
        if (response?.status === "success") {
            if (response?.data?.vettingCriteria) {
                const criteria = response.data.vettingCriteria;
                yield put(setCriteria(criteria as SetCriteriaPayload[]));
            }

            yield put(
                setIsSuggestionsListEmpty(
                    response?.data?.suggestedVettingCriteria
                        ? response?.data?.suggestedVettingCriteria?.length === 0
                        : true
                )
            );
            yield put(setVettingStatus(response?.data?.vettingStatus));
            if (response?.data?.suggestedVettingCriteria?.length > 0) {
                const criteria = response.data.suggestedVettingCriteria;
                yield put(setSuggestedCriteria(criteria as SetCriteriaPayload[]));
            } else {
                yield put(setSuggestedCriteria(response?.data?.vettingCriteria as SetCriteriaPayload[]));
            }
            if (onSuccess) {
                onSuccess();
            }
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        console.error({ error });
        yield put(setErrorNotification("error occurred while fetching vetting criteria's"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}
function* applySuggestedCriteriaSaga(
    action: PayloadAction<{ projectId: number; onSuccess?: () => void }>
): SagaIterator {
    try {
        const { projectId, onSuccess } = action.payload;

        const state = yield select();
        const vettingCriteriaStateValue = get(state, "vettingCriteria.suggestedCriteria");

        const vettingCriteria = vettingCriteriaStateValue
            .filter(({ text }: { text: string }) => Boolean(text))
            .map((item: any) => ({
                ...item,
                updatedAt: new Date(),
                isChange: false,
            }));
        const payload = {
            projectId,
            suggestion: vettingCriteria,
        };
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/vetting-suggestions/apply", payload);
        if (response?.status === "success") {
            if (response?.data?.vettingCriteria) {
                const criteria = response.data.vettingCriteria;
                yield put(setCriteria(criteria as SetCriteriaPayload[]));
            }
            yield put(
                setIsSuggestionsListEmpty(
                    response?.data?.suggestedVettingCriteria
                        ? response?.data?.suggestedVettingCriteria?.length === 0
                        : true
                )
            );
            yield put(setVettingStatus(response?.data?.vettingStatus));
            if (response?.data?.suggestedVettingCriteria?.length > 0) {
                const criteria = response.data.suggestedVettingCriteria;
                yield put(setSuggestedCriteria(criteria as SetCriteriaPayload[]));
            } else {
                yield put(setSuggestedCriteria(response?.data?.vettingCriteria as SetCriteriaPayload[]));
            }

            if (onSuccess) {
                onSuccess();
            }
        }
    } catch (error) {
        handleException(error);
        console.error({ error });

        yield put(setErrorNotification("error occurred while fetching vetting criteria's"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}
function* revertSuggestedCriteriaSaga(
    action: PayloadAction<{ projectId: number; onSuccess?: () => void }>
): SagaIterator {
    try {
        const { projectId, onSuccess } = action.payload;

        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, "/vetting-suggestions/revert", { projectId: projectId });
        if (response?.status === "success") {
            if (response?.data?.vettingCriteria) {
                const criteria = response.data.vettingCriteria;
                yield put(setCriteria(criteria as SetCriteriaPayload[]));
            }
            yield put(
                setIsSuggestionsListEmpty(
                    response?.data?.suggestedVettingCriteria
                        ? response?.data?.suggestedVettingCriteria?.length === 0
                        : true
                )
            );
            yield put(setVettingStatus(response?.data?.vettingStatus));

            if (response?.data?.suggestedVettingCriteria?.length > 0) {
                const criteria = response.data.suggestedVettingCriteria;
                yield put(setSuggestedCriteria(criteria as SetCriteriaPayload[]));
            } else {
                yield put(setSuggestedCriteria(response?.data?.vettingCriteria as SetCriteriaPayload[]));
            }

            if (onSuccess) {
                onSuccess();
            }
        }
    } catch (error) {
        handleException(error);
        console.error({ error });
        yield put(setErrorNotification("error occurred while fetching vetting criteria's"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}
// function*

export default function* rootSagas() {
    // @ts-ignore
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchVettingCriteria.type, fetchVettingCriteriaSaga),
        // @ts-ignore
        yield takeLatest(applySuggestedCriteria.type, applySuggestedCriteriaSaga),
        // @ts-ignore
        yield takeLatest(revertSuggestedCriteria.type, revertSuggestedCriteriaSaga),
        //@ts-ignore
        yield takeLatest(suggestVettingCrtieria.type, suggestVettingCriteriaSaga),
    ];

    yield takeLatest(cancelSagas.type, CancelSagas, tasks);
}
