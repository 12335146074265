import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { ListItemIcon, ListItemText, MenuItem, type MenuItemProps } from "@mui/material";

export type VettingCriteriaMenuProps = {
    sx: MenuItemProps["sx"];
    handleClose: () => void;
    showVettingCriteriaModal: () => void;
};

export function VettingCriteriaMenuItem({ handleClose, showVettingCriteriaModal, sx }: VettingCriteriaMenuProps) {
    return (
        <MenuItem
            sx={sx}
            onClick={() => {
                handleClose();
                showVettingCriteriaModal();
            }}
        >
            <ListItemIcon>
                <EditRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
                sx={{
                    "& .MuiListItemText-primary": {
                        fontSize: "14px",
                    },
                }}
            >
                Edit Vetting Criteria
            </ListItemText>
        </MenuItem>
    );
}
