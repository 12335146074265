import { useSelector } from "react-redux";

import TextLoading from "../../../components/TextLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { deleteContactList } from "../contact-list.slice";

export default function DeleteLoading() {
    const isDeleting = useSelector(checkIfLoading(deleteContactList.type));

    if (!isDeleting) {
        return null;
    }

    return <TextLoading text="Deleting..." />;
}
