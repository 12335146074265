import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {};

const reducers = {
    checkIfUserExist(state: typeof initialState, action: PayloadAction<{ email: string; onSuccess: () => void }>) {},
    confirmReset(
        state: typeof initialState,
        action: PayloadAction<{ oobCode: string; newPassword: string; }>
    ) {},
    resetState(state: typeof initialState) {},
};

const forgetPasswordSlice = createSlice({
    name: "forgotPassword",
    initialState,
    reducers,
});

export default forgetPasswordSlice.reducer;

export const { checkIfUserExist, confirmReset, resetState } = forgetPasswordSlice.actions;
