import { ImgHTMLAttributes } from "react";
import Stack, { StackProps } from "@mui/material/Stack";

import noData from "../assets/img/no_data.svg";

type NoDataProps = {
    children?: React.ReactNode;
    imgProps?: ImgHTMLAttributes<HTMLImageElement>;
    containerProps?: StackProps;
};

export default function NoData({ children, imgProps, containerProps }: NoDataProps) {
    return (
        <Stack justifyContent="center" alignItems="center" sx={{ height: "70vh" }} {...containerProps}>
            <img src={noData} alt="no_data" height={300} width={300} {...imgProps} />
            {children}
        </Stack>
    );
}
