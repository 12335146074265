import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, IconButton, Popover, Slider, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const AudioPlayer = ({ recording }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [audioUrl, setAudioUrl] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const audioRef = useRef(null);
    const intervalRef = useRef(null);

    const duration = parseInt((recording.recordingDuration - 1).toString(), 10);

    useEffect(() => {
        const fetchAudio = async () => {
            const response = await fetch(recording?.url);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            setAudioUrl(url);
        };

        fetchAudio();
    }, [recording?.url]);

    const togglePlayPause = () => {
        const audio = audioRef.current;

        if (audio) {
            if (isPlaying) {
                audio.pause();
                clearInterval(intervalRef.current);
            } else {
                audio.play();
                intervalRef.current = setInterval(() => {
                    setCurrentTime(audio.currentTime);
                    if (audio.currentTime >= duration) {
                        audio.pause();
                        setIsPlaying(false);
                        setCurrentTime(0); // Reset to start
                        clearInterval(intervalRef.current);
                    }
                }, 1000);
            }
            setIsPlaying(!isPlaying);
        }
    };

    const changeRange = (e, value) => {
        const audio = audioRef.current;
        if (audio) {
            audio.currentTime = value;
            setCurrentTime(value);
            if (value >= duration) {
                audio.pause();
                setIsPlaying(false);
                setCurrentTime(0); // Reset to start
                clearInterval(intervalRef.current);
            }
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        clearInterval(intervalRef.current);
    };

    const open = Boolean(anchorEl);

    return (
        <Box>
            <IconButton onClick={handlePopoverOpen}>
                <PlayArrowIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#EEEEEE",
                        color: "black",
                        borderRadius: 2,
                        padding: 1,
                        display: "flex",
                        alignItems: "center",
                        width: "auto",
                    }}
                >
                    <audio ref={audioRef} src={audioUrl} />
                    <IconButton onClick={togglePlayPause} sx={{ color: "black" }}>
                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <Slider
                        value={currentTime}
                        min={0}
                        max={duration}
                        onChange={changeRange}
                        sx={{ color: "black", mx: 2, width: 150 }}
                    />
                    <Typography variant="body2" sx={{ color: "black" }}>
                        {formatTime(currentTime)} / {formatTime(duration)}
                    </Typography>
                </Box>
            </Popover>
        </Box>
    );
};

export default AudioPlayer;
