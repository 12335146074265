import { useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Tooltip } from "@mui/joy";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import Button from "@mui/joy/Button";
import ListItemContent from "@mui/joy/ListItemContent";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Box } from "@mui/material";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { JoyProvider } from "../../../../components/JoyProvider";
import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    addJazzHRJob,
    getJazzHRJobs,
    getJazzHRJobStages,
    pushJazzHRJobs,
    selectJazzHRJobs,
    selectJazzHRJobStages,
} from "../../../integrations/integrations.reducer";
import { IJazzHRJob, IJazzHRJobStage } from "../../../integrations/integrations.types";
import { selectUser } from "../../../Signin/Signin.reducer";
import { IProject } from "../../project.types";

type JazzModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

export default function JazzModal({ open, onClose, id, selectedCandidateIds, variant }: JazzModalProps) {
    const dispatch = useDispatch();
    const isVariantFetch = variant === "FETCH";

    const user: any = useSelector(selectUser);
    useEffect(() => {
        if (!open) return;
        if (user.jazzHR?.jazzHRAPIKey && user.jazzHR?.jazzHRFeedURL) {
            dispatch(getJazzHRJobs());
            dispatch(getJazzHRJobStages());
        }
    }, [open, isVariantFetch]);
    // console.log("JazzHR", selectJazzHRJobs);
    const jazzHRJobs = useSelector(selectJazzHRJobs); // get jobs from jazzhr api

    const isLoading = useSelector(checkIfLoading(addJazzHRJob.type));
    const isStageLoading = useSelector(checkIfLoading(pushJazzHRJobs.type));
    const data = useSelector<any, IProject>((state) => get(state, "allProjects.project"));
    const jazzHRMetaData = data?.jazzHRMetaData;
    const jazzHRStages = useSelector(selectJazzHRJobStages);
    const [jobStage, setJobStage] = useState<IJazzHRJobStage>(jazzHRStages?.[0]); // get selected stage id from jazzhr api();

    const [selectedJazzHRJob, setSelectedJazzHRJob] = useState<IJazzHRJob>(() =>
        jazzHRMetaData && jazzHRMetaData.jobName !== "title"
            ? { id: jazzHRMetaData.jobId, title: jazzHRMetaData.jobName }
            : { id: "", title: "" }
    );

    // useEffect(() => {
    //     console.log("selectedJazzHRJob updated:", selectedJazzHRJob);
    // }, [selectedJazzHRJob]);

    const handleCloseModal = () => {
        onClose();
    };

    const handleJobChange = (newValue: any) => {
        const val = newValue?.id as string;
        const title = newValue?.label as string;

        setSelectedJazzHRJob({ id: val, title: title });
    };
    const handleStageChange = (newValue: any) => {
        const id = newValue?.id as string;
        const title = newValue?.label as string;
        setJobStage({ jobId: id, jobName: title });
    };

    const handleClose = () => {
        setSelectedJazzHRJob(
            jazzHRMetaData ? { id: jazzHRMetaData.jobId, title: jazzHRMetaData.jobName } : { id: "", title: "" }
        );
        handleCloseModal();
    };
    const handleJazzAdd = () => {
        if (isVariantFetch) {
            // for fetch from ats

            dispatch(
                addJazzHRJob({
                    id: selectedJazzHRJob.id ?? "",
                    title: selectedJazzHRJob.title ?? "",
                    onSuccess: () => handleCloseModal(),
                })
            );
        } else {
            // for add to ATS
            if (!selectedJazzHRJob?.title) {
                dispatch(setErrorNotification("Please select a job"));
                return;
            }

            dispatch(
                pushJazzHRJobs({
                    id: selectedJazzHRJob.id ?? "",
                    stageID: jobStage?.jobId,
                    stageName: jobStage?.jobName,
                    title: selectedJazzHRJob.title ?? "",
                    candidateIds: selectedCandidateIds,
                    onSuccess: () => handleCloseModal(),
                })
            );
        }
    };

    return (
        <JoyProvider>
            <Modal
                open={open}
                onClose={onClose}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: 500,
                        borderRadius: "md",
                        p: 2,
                        py: 4,
                        boxShadow: "lg",
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography level="h4" mb={3}>
                        {!isVariantFetch ? "Send to JazzHR" : "Fetch from JazzHR"}
                    </Typography>

                    <Stack gap={3}>
                        {!isVariantFetch ? (
                            jazzHRJobs?.length ? (
                                <>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Typography level="body-sm">Select JazzHR Job</Typography>
                                        <Tooltip
                                            title={selectedJazzHRJob?.title ?? "Select new Job"}
                                            placement="right-start"
                                        >
                                            <Autocomplete
                                                key={`autocomplete-${isVariantFetch}`}
                                                value={
                                                    selectedJazzHRJob?.id
                                                        ? {
                                                            label: selectedJazzHRJob?.title,
                                                            id: selectedJazzHRJob?.id,
                                                            state: "",
                                                            city: "",
                                                        }
                                                        : null
                                                }
                                                onChange={(e, newValue) => {
                                                    handleJobChange(newValue);
                                                }}
                                                options={jazzHRJobs.map((item) => {
                                                    return {
                                                        label: item.title,
                                                        id: item.id,
                                                        state: item.state,
                                                        city: item.city,
                                                    };
                                                })}
                                                placeholder="Select Job"
                                                getOptionLabel={(option) => option.label || ""}
                                                getOptionKey={(option) => option.id}
                                                renderOption={(props, option) => (
                                                    <AutocompleteOption key={option.id} {...props}>
                                                        <Tooltip title={option.label} placement="left-start">
                                                            <ListItemContent sx={{ fontSize: "sm" }}>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignItems: "flex-start",
                                                                    }}
                                                                >
                                                                    <Typography level="body-sm" fontWeight="500">
                                                                        {option.label}
                                                                    </Typography>
                                                                    {(option.city || option.state) && (
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: 0.5,
                                                                            }}
                                                                        >
                                                                            <LocationOnIcon
                                                                                sx={{
                                                                                    fontSize: 16,
                                                                                    mr: 0.5,
                                                                                    color: "rgba(0, 0, 0, 0.63)",
                                                                                }}
                                                                            />
                                                                            <Typography
                                                                                level="body-xs"
                                                                                sx={{ color: "text.secondary" }}
                                                                            >
                                                                                {[option.city, option.state]
                                                                                    .filter(Boolean)
                                                                                    .join(", ")}
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            </ListItemContent>
                                                        </Tooltip>
                                                    </AutocompleteOption>
                                                )}
                                                sx={{ width: 200 }}
                                            />
                                        </Tooltip>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                    >
                                        <Typography level="body-sm">Select Stage</Typography>

                                        <Autocomplete
                                            value={
                                                jobStage?.jobId ? { label: jobStage.jobName, id: jobStage.jobId } : null
                                            }
                                            onChange={(e, newValue) => {
                                                handleStageChange(newValue);
                                            }}
                                            options={jazzHRStages.map((item) => {
                                                return { label: item.jobName, id: item.jobId };
                                            })}
                                            placeholder="Select stage"
                                            getOptionLabel={(option) => option.label}
                                            getOptionKey={(option) => option.id}
                                            renderOption={(props, option) => (
                                                <AutocompleteOption key={option.id} {...props}>
                                                    <Tooltip title={option.label} placement="left-start">
                                                        <ListItemContent sx={{ fontSize: "sm" }}>
                                                            {option.label}
                                                        </ListItemContent>
                                                    </Tooltip>
                                                </AutocompleteOption>
                                            )}
                                            sx={{ width: 200 }}
                                        />
                                    </Stack>
                                </>
                            ) : (
                                <Typography py={1} level="body-sm" textAlign="center">
                                    No Jobs found. Create one on your JazzHR dashboard, Or integrate JazzHR with
                                    EasySource First.
                                </Typography>
                            )
                        ) : null}

                        {isVariantFetch ? (
                            jazzHRJobs?.length ? (
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography level="body-sm">Map to JazzHR Job</Typography>
                                    <Tooltip
                                        title={selectedJazzHRJob?.title ?? "Select new Job"}
                                        placement="right-start"
                                    >
                                        <Autocomplete
                                            value={
                                                selectedJazzHRJob?.title
                                                    ? {
                                                        label: selectedJazzHRJob?.title,
                                                        id: selectedJazzHRJob?.id,
                                                        state: "",
                                                        city: "",
                                                    }
                                                    : null
                                            }
                                            onChange={(e, newValue) => {
                                                handleJobChange(newValue);
                                            }}
                                            options={jazzHRJobs.map((item) => {
                                                return {
                                                    label: item.title,
                                                    id: item.id,
                                                    state: item.state,
                                                    city: item.city,
                                                };
                                            })}
                                            placeholder="Select Job"
                                            getOptionLabel={(option) => option.label || ""}
                                            getOptionKey={(option) => option.id}
                                            renderOption={(props, option) => (
                                                <AutocompleteOption key={option.id} {...props}>
                                                    <Tooltip title={option.label} placement="left-start">
                                                        <ListItemContent sx={{ fontSize: "sm" }}>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "flex-start",
                                                                }}
                                                            >
                                                                <Typography level="body-sm" fontWeight="500">
                                                                    {option.label}
                                                                </Typography>
                                                                {(option.city || option.state) && (
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            mt: 0.5,
                                                                        }}
                                                                    >
                                                                        <LocationOnIcon
                                                                            sx={{
                                                                                fontSize: 16,
                                                                                mr: 0.5,
                                                                                color: "rgba(0, 0, 0, 0.63)",
                                                                            }}
                                                                        />
                                                                        <Typography
                                                                            level="body-xs"
                                                                            sx={{ color: "text.secondary" }}
                                                                        >
                                                                            {[option.city, option.state]
                                                                                .filter(Boolean)
                                                                                .join(", ")}
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </ListItemContent>
                                                    </Tooltip>
                                                </AutocompleteOption>
                                            )}
                                            sx={{ width: 200 }}
                                        />
                                    </Tooltip>
                                </Stack>
                            ) : (
                                <Typography py={1} level="body-sm" textAlign="center">
                                    No Jobs found. Create one on your JazzHR dashboard ,Or integrate JazzHR with
                                    EasySource First.
                                </Typography>
                            )
                        ) : null}

                        <Stack marginTop={1} gap={1} direction="row" alignItems={"center"} justifyContent={"flex-end"}>
                            <Button variant="outlined" onClick={handleClose}>
                                Cancel
                            </Button>
                            {jazzHRJobs?.length > 0 && (
                                <Button
                                    sx={{
                                        ":disabled": {
                                            color: "white",
                                            background: "#0b6bcbe6",
                                        },
                                    }}
                                    onClick={handleJazzAdd}
                                    disabled={isLoading || isStageLoading}
                                >
                                    <ButtonTextWithLoading text="Submit" isLoading={isLoading || isStageLoading} />
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Sheet>
            </Modal>
        </JoyProvider>
    );
}
