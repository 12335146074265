export enum EQueries {
    MULTIPLE_USER_FUNNEL = "multiple-users-funnel",
    FUNNEL_OVERVIEW = "Funnel_overview",
    OUTREACH_STATS = "Outreach_stats",
    PROJECTS_STATUS = "Projects_status",
    CREDIT_USAGE = "Credit_usage",
    SOURCE_GRAPH = "Source_graph",
    CHANNEL_GRAPH = "Channel_graph",
    GRAPH = "Graph",
}

export enum EFunnelOverview {
    MEETINGS_BOOKED = "meetingsBooked",
    POSITIVE_RESPONSES = "positiveResponses",
    RELEVANT_CANDIDATES = "relevantCandidates",
    RESPONSES_RECEIVED = "responsesReceived",
    TOTAL_CONTACTED = "totalContacted",
}

export enum EChannel {
    SMS = "sms",
    EMAIL = "email",
    LINKEDIN = "linkedin",
}

export const EChannelLabel = {
    [EChannel.SMS]: "SMS",
    [EChannel.LINKEDIN]: "LinkedIn",
    [EChannel.EMAIL]: "Email",
} as const;

export const EFunnelOverviewLabel = {
    [EFunnelOverview.MEETINGS_BOOKED]: "Meetings booked",
    [EFunnelOverview.POSITIVE_RESPONSES]: "Positive responses",
    [EFunnelOverview.RELEVANT_CANDIDATES]: "Relevant candidates",
    [EFunnelOverview.RESPONSES_RECEIVED]: "Responses received",
    [EFunnelOverview.TOTAL_CONTACTED]: "Total contacted",
} as const;

export const EFunnelOverviewLabelEasyGrowth = {
    [EFunnelOverview.MEETINGS_BOOKED]: "Meetings booked",
    [EFunnelOverview.POSITIVE_RESPONSES]: "Positive responses",
    [EFunnelOverview.RELEVANT_CANDIDATES]: "Total prospects",
    [EFunnelOverview.RESPONSES_RECEIVED]: "Responses received",
    [EFunnelOverview.TOTAL_CONTACTED]: "Total contacted",
};

export enum ECustomDateLabel {
    TODAY = "Today",
    LAST_7_DAYS = "Last 7 days",
    LAST_4_WEEKS = "Last 4 weeks",
    LAST_3_MONTHS = "Last 3 months",
    CUSTOM = "Custom",
    NONE = "none",
}
