import { useState } from "react";
import { FormControl, Option, Select, SelectProps, Tooltip } from "@mui/joy";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { JoyProvider } from "../../components/JoyProvider";
import DateRangePicker, { DateRangePickerProps, TDate } from "../DateRangePicker/DateRangePicker";

type TOption = {
    label: string;
    value: TDate;
};

interface CustomDateRangePickerProps {
    fullWidth?: boolean;
    value: TDate[];
    onChange: (value: TDate[]) => void;
    defaultOption?: string;
    options: TOption[];
    disabled?: boolean;
    rangePickerProps?: Omit<DateRangePickerProps, "onChange">;
    selectProps?: SelectProps<any, any>;
}

export default function CustomDateRangePicker(props: CustomDateRangePickerProps) {
    const { value, onChange, defaultOption, disabled, options: defaultOptions, rangePickerProps, selectProps } = props;
    const [selectedOption, setSelectedOption] = useState<string>(defaultOption ?? "none");

    const onChangeDateRange = (dateRange: TDate[]) => {
        setSelectedOption(dateRange[0] || dateRange[1] ? "Custom" : "none");
        onChange(dateRange);
    };

    const onClickMenu = (option: TOption) => {
        setSelectedOption(option.label);
        const startDate = dayjs(option.value);
        const endDate = dayjs();
        onChange([startDate, endDate]);
    };

    const genOptions = () => {
        return [
            ...defaultOptions,
            {
                label: "Custom",
                value: null,
            },
        ].filter((option) => {
            if (option.label === "Custom") {
                return option.label === selectedOption;
            }
            return true;
        });
    };

    const options = genOptions();
    return (
        <Stack
            direction="row"
            display="grid"
            alignItems="center"
            gridTemplateColumns="minmax(min-content, min(150px, 250px)) 1fr"
        >
            <JoyProvider>
                <Tooltip title={selectedOption === "none" ? undefined : selectedOption} placement="top">
                    <FormControl sx={{ my: 1, mr: 1 }} size="sm">
                        <Select disabled={disabled} value={selectedOption} {...selectProps} variant="soft">
                            {selectedOption === "none" && (
                                <Option value="none" disabled>
                                    Select date
                                </Option>
                            )}
                            {options.map((option) => (
                                <Option
                                    disabled={option.label === "Custom"}
                                    key={option.label}
                                    onClick={() => onClickMenu(option)}
                                    value={option.label}
                                >
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </FormControl>
                </Tooltip>
            </JoyProvider>
            <DateRangePicker disabled={disabled} value={value} onChange={onChangeDateRange} {...rangePickerProps} />
        </Stack>
    );
}
