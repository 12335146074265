import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Fragment, useEffect, useRef, useState } from "react";
import Linkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import style from "./profileTab.module.scss";

import buildings from "../../../../assets/img/buildings.svg";
import ReadMore from "../../../../components/ReadMore";
import { VettingCriteriaRating } from "../../../../components/VettingCriteriaRating";
import { LeadScoreRating } from "../../../../easy-growth/components/LeadScoreRating";
import { useGetCompaniesDetailsQuery } from "../../../../store/apis/companies/companies.api";
import { calculateExperienceDuration } from "../../../../utils/date-utils";
import parseMoney from "../../../../utils/parseMoney";
import isValidImageUrl from "../../../../utils/ValidateImageUrl";
import { isSuperAdminUser } from "../../../Signin/Signin.reducer";
import { selectScrollToSkills, selectSearchQuery, setScrollToSkills } from "../../index.reducer";
import {
    Certificate,
    Education as EducationType,
    Experience as ExperienceType,
    ICandidate,
    LeadScore,
    StarRating,
} from "../../project.types";

interface ProfileTabProps {
    data: ICandidate;
    projectId?: string;
}

type RenderStarRatingProps = {
    starRating: StarRating;
    projectId: string;
    candidateId: string;
    vettingFixed: boolean;
};

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

const CustomTypography = styled((props: TypographyProps) => <Typography {...props} variant="subtitle2" />)(
    ({ theme }) => ({
        color: theme.palette.grey[500],
        fontSize: "12px",
    })
);

type CompanyDetailsPopoverProps = {
    open: boolean;
    scrapedLink: string;
    anchorEl: HTMLElement | null;
    handleToggle: () => void;
    handleClose: () => void;
    handleOpen: () => void;
    handleAwayClick: () => void;
    parseMoney: (arg0: number) => string;
    parseSeries: (arg0: string) => string;
    org: string;
};

function TypographyWithReadMore({ text, title, limit = 50 }: { text: string; title: string; limit?: number }) {
    const [readMore, setReadMore] = useState(false);
    const spanText = readMore ? "Read Less" : "Read More";
    const value = readMore ? text : text?.slice(0, limit) + "...";
    const linkDecorator = (href: string, text: string, key: string) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );
    return (
        <CustomTypography variant="subtitle2">
            <b>{title}:</b> <Linkify componentDecorator={linkDecorator}>{value}</Linkify>{" "}
            <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setReadMore((prev) => !prev)}
            >
                {spanText}
            </span>
        </CustomTypography>
    );
}

function ImageUrl(props: { image?: string }) {
    const { image } = props;
    const [isValidUrl, setIsValidUrl] = useState(false);

    useEffect(() => {
        (async function () {
            if (image) {
                const isValid = await isValidImageUrl(image);
                setIsValidUrl(isValid);
            }
        })();
    }, [image]);

    return (
        <img
            src={isValidUrl ? image : buildings}
            style={{ objectFit: "contain" }}
            height={32}
            width={35}
            alt="company"
        />
    );
}

export function CompanyDetailsPopover({
    anchorEl,
    open,
    org,
    scrapedLink,
    handleToggle,
    handleClose,
    handleOpen,
    handleAwayClick,
    parseMoney,
    parseSeries,
}: CompanyDetailsPopoverProps) {
    const { isLoading, data, isError } = useGetCompaniesDetailsQuery([org]);
    const image = data?.image;
    const cr_linkedinlink = data?.linkedin ? data.linkedin : "-";

    return (
        <Popover
            sx={{
                pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            onClose={handleToggle}
            disableRestoreFocus
            disableEnforceFocus
            onScroll={handleToggle}
        >
            <Stack
                onClick={(e) => e.stopPropagation()}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                direction="row"
                p={1.5}
                gap={1.5}
                sx={{ width: "400px", pointerEvents: "all" }}
            >
                <ImageUrl image={image} />
                <ClickAwayListener onClickAway={handleAwayClick}>
                    {isLoading ? (
                        <Stack width="100%">
                            {Array(6)
                                .fill(0)
                                .map((v, i) => {
                                    return (
                                        <Skeleton
                                            key={`${v}-${i}`}
                                            variant="text"
                                            width={i === 0 ? "50%" : "100%"}
                                            sx={{ fontSize: "1rem" }}
                                        />
                                    );
                                })}
                        </Stack>
                    ) : (
                        <Stack sx={{ maxHeight: "200px", overflow: "auto" }}>
                            <Typography
                                variant="subtitle2"
                                fontWeight={500}
                                component={cr_linkedinlink === "-" ? "span" : Link}
                                to={cr_linkedinlink === "-" ? undefined : cr_linkedinlink}
                                target={cr_linkedinlink !== "-" ? "_blank" : undefined}
                                sx={(theme) => ({
                                    color: theme.palette.info.dark,
                                    fontWeight: theme.typography.fontWeightMedium,
                                    cursor: cr_linkedinlink === "-" ? "default" : "pointer",
                                })}
                            >
                                {org}
                                {cr_linkedinlink !== "-" && (
                                    <LinkedInIcon sx={{ ml: 0.5, verticalAlign: "sub" }} fontSize="small" />
                                )}
                            </Typography>

                            <Box mt={0.5} />
                            {data && !isError ? (
                                <Stack gap={0.3}>
                                    <>
                                        {data?.employeeCount && (
                                            <CustomTypography>
                                                <b>Employee Count : </b>
                                                {data.employeeCount}
                                            </CustomTypography>
                                        )}

                                        {data?.revenue?.amount && (
                                            <CustomTypography>
                                                <b>Revenue : </b>
                                                {data.revenue.amount}
                                            </CustomTypography>
                                        )}
                                        {data?.latestFundingRound &&
                                            data?.latestFundingRoundAmount &&
                                            data?.organization_funding_total &&
                                            data?.fundingDate && (
                                                <CustomTypography>
                                                    <b>Total Funding: </b>
                                                    {`USD ${parseMoney(
                                                        data.organization_funding_total
                                                    )} (latest round is ${parseSeries(
                                                        data.latestFundingRound
                                                    )} for $${parseMoney(data.latestFundingRoundAmount)} on ${
                                                        data.fundingDate
                                                    })`}
                                                </CustomTypography>
                                            )}
                                        {data?.latestFundingRound &&
                                            data?.fundingDate &&
                                            !data?.latestFundingRoundAmount && (
                                                <CustomTypography>
                                                    <b>Total Funding: </b>
                                                    {`Latest round is ${parseSeries(data.latestFundingRound)} on ${
                                                        data.fundingDate
                                                    }`}
                                                </CustomTypography>
                                            )}
                                    </>
                                    {data?.industries && (
                                        <TypographyWithReadMore text={data.industries.join(", ")} title="Industry" />
                                    )}
                                    {data?.description && (
                                        <TypographyWithReadMore
                                            text={data.description}
                                            title="Description"
                                            limit={200}
                                        />
                                    )}
                                </Stack>
                            ) : (
                                <CustomTypography>
                                    Accurate company information not immediately available. Try a Google search or click
                                    the company link to view on LinkedIn.
                                </CustomTypography>
                            )}
                        </Stack>
                    )}
                </ClickAwayListener>
            </Stack>
        </Popover>
    );
}

export function OrganizationName({
    org,
    link,
    noHighlightName,
}: {
    org?: string;
    link?: string;
    noHighlightName?: boolean;
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const textRef = useRef<HTMLAnchorElement | null>(null);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const searchQuery = useSelector(selectSearchQuery);
    const [open, setOpen] = useState(false);
    const [isCandidateCardOpen, setIsCandidateCardOpen] = useState(false);
    const orgName = noHighlightName ? `${org}` : `@ ${org}`;

    useEffect(() => {
        const elModalCard = document.getElementById("candidate-modal-card");

        const handleclosepopover = () => {
            if (open || isCandidateCardOpen) {
                handlePopoverClose();
                setIsCandidateCardOpen(false);
            }
        };

        elModalCard?.addEventListener("scroll", handleclosepopover);
        return () => {
            elModalCard?.removeEventListener("scroll", handleclosepopover);
        };
    }, [open, isCandidateCardOpen]);

    const handlePopoverOpen = () => {
        setAnchorEl(textRef.current);
        setOpen(true);
    };

    const handlePopoverClose = () => {
        setOpen(false);
        if (!isCandidateCardOpen) setAnchorEl(null);
    };

    const handlePopoverToggle = () => {
        setIsCandidateCardOpen((prevOpen) => !prevOpen);
    };
    const handleClickAwayClose = () => {
        if (isCandidateCardOpen && !open) {
            setIsCandidateCardOpen(false);
        }
    };
    const checkAndParseSeries = (series: string): string => {
        if (series === "series_unknown") return "of an unknown series";
        if (series === "secondary_market") return "from secondary market";
        if (series.includes("-"))
            return series
                .split("-")
                .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
                .join(" ");
        if (series.includes("_"))
            return series
                .split("_")
                .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
                .join(" ");
        return series?.charAt(0)?.toUpperCase() + series?.slice(1);
    };

    if (!org) {
        return null;
    }
    const popover = (
        <CompanyDetailsPopover
            open={open || isCandidateCardOpen}
            anchorEl={anchorEl}
            handleToggle={handlePopoverToggle}
            handleClose={handlePopoverClose}
            handleOpen={handlePopoverOpen}
            handleAwayClick={handleClickAwayClose}
            parseMoney={parseMoney}
            parseSeries={checkAndParseSeries}
            org={org}
            scrapedLink={link ? link : "-"} // linkedin link not from Crunchbase
        />
    );

    const props = {
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
        onClick: handlePopoverToggle,
    };

    return (
        <>
            <Typography
                component="span"
                ref={textRef}
                sx={(theme) => ({
                    color: theme.palette.info.dark,
                    fontWeight: theme.typography.fontWeightMedium,
                })}
                {...props}
            >
                {orgName}
            </Typography>
            {open || isCandidateCardOpen ? popover : null}
        </>
    );
}

type ExperienceProps = {
    nested?: boolean;
    position: string | ExperienceType[];
    org: string;
    orgUrl: string;
    orgLogo: string;
    location: string;
    duration: string;
    description: string;
    totalMonths: number;
    _id: string;
};

function Experience({ val }: { val: ExperienceProps }) {
    const location = val?.location && val.location !== "-" ? val.location : null;
    const duration = val?.duration && val.duration !== "-" ? val.duration : null;
    const description = val?.description && val.description !== "-" ? val.description : null;
    const orgLogo = val?.orgLogo && val.orgLogo !== "-" ? val.orgLogo : null;

    const firstPosition = Array.isArray(val?.position) ? val?.position?.slice(0, 1) : [];

    const firstPositionToRender = firstPosition.length ? firstPosition?.[0]?.position : (val?.position as string);

    const allOtherPositionsExceptFirst = Array.isArray(val?.position) ? val?.position?.slice(1) : [];
    return (
        <Stack direction="row" gap={1.5}>
            {!val?.nested && (
                <img src={orgLogo || buildings} alt="company" style={{ marginTop: "0.5rem", height: 40, width: 40 }} />
            )}
            <Stack width="100%" spacing={0.5}>
                {val?.position && (
                    <Typography variant="body1" fontWeight={500}>
                        {firstPositionToRender}
                        <OrganizationName link={val?.orgUrl} org={val?.org} />
                    </Typography>
                )}
                <Stack gap={2}>
                    <Box
                        sx={{
                            display: "grid",
                            gap: "0.5rem",
                            gridTemplateColumns: "auto 1fr",
                        }}
                    >
                        <Divider orientation="vertical" />
                        <Stack spacing={0.5}>
                            <Typography variant="body2" fontWeight={500}>
                                {location ?? ""}
                            </Typography>
                            <Typography
                                sx={(theme) => ({
                                    color: theme.palette.grey[800],
                                    fontWeight: theme.typography.fontWeightMedium,
                                })}
                                variant="body2"
                            >
                                {calculateExperienceDuration(duration)}
                            </Typography>
                            {description && <ReadMore className={style["pro__expDetail"]}>{description}</ReadMore>}
                        </Stack>
                    </Box>
                    {allOtherPositionsExceptFirst.length ? (
                        <Stack gap={2}>
                            {allOtherPositionsExceptFirst.map((val, i: number) => (
                                <Experience key={`nested-${i}`} val={{ ...val, nested: true }} />
                            ))}
                        </Stack>
                    ) : null}
                </Stack>
            </Stack>
        </Stack>
    );
}

function Education({ val }: { val: EducationType & { nested?: boolean } }) {
    const institute = val?.institute && val.institute !== "-" ? val.institute : null;
    const duration = val?.duration && val.duration !== "-" ? val.duration : null;
    const course = val?.course && val.course !== "-" ? val.course : null;
    const description = val?.description && val.description !== "-" ? val.description : null;
    const instituteLogo = val?.instituteLogo && val.instituteLogo !== "-" ? val.instituteLogo : null;

    return (
        <Stack direction="row" gap={1.5}>
            {!val?.nested && (
                <img
                    src={instituteLogo || buildings}
                    height={40}
                    width={40}
                    alt="company"
                    style={{ marginTop: "0.5rem" }}
                />
            )}
            <Stack width="100%" spacing={0.5}>
                <Typography variant="body1" fontWeight={500}>
                    {institute}
                </Typography>
                <Stack spacing={0.5}>
                    <Typography variant="body2" fontWeight={500}>
                        {course}
                    </Typography>
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.grey[800],
                            fontWeight: theme.typography.fontWeightMedium,
                        })}
                        variant="body2"
                    >
                        {duration}
                    </Typography>
                    <ReadMore className={style["pro__expDetail"]}>{description}</ReadMore>
                </Stack>
            </Stack>
        </Stack>
    );
}

function CertificatePane({ credentials, duration, institute, name }: Certificate) {
    return (
        <Stack width="100%" spacing={0.5}>
            {name && (
                <Typography variant="body1" fontWeight={500}>
                    {name}
                </Typography>
            )}
            <Stack spacing={0.5}>
                {institute && (
                    <Typography variant="body2" fontWeight={500}>
                        {institute}
                    </Typography>
                )}
                {duration && (
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.grey[800],
                            fontWeight: theme.typography.fontWeightMedium,
                        })}
                        variant="body2"
                    >
                        {duration}
                    </Typography>
                )}
                {credentials && credentials !== "-" ? (
                    <Typography
                        component={Link}
                        to={credentials}
                        target="_blank"
                        p={1}
                        sx={(theme) => ({
                            width: "fit-content",
                            height: "fit-content",
                            marginTop: "0.7rem",
                            fontSize: "0.7rem",
                            border: 1.5,
                            borderColor: theme.palette.primary.main,
                            textDecoration: "none",
                            borderRadius: "0.5rem",
                            color: theme.palette.primary.main,
                            fontWeight: "500",
                        })}
                    >
                        Show Certificate
                    </Typography>
                ) : null}
            </Stack>
        </Stack>
    );
}

const CandidateProfileHeading = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600",
    color: theme.palette.grey[800],
}));

type GroupedPosition = {
    position: ExperienceType[];
    org: string;
    orgUrl: string;
    orgLogo: string;
    location: string;
    duration: string;
    description: string;
    totalMonths: number;
    _id: string;
};

// This function groups the positions by organization
function groupPositionsByOrg(positions: ExperienceType[]): GroupedPosition[] {
    const grouped: { [key: string]: GroupedPosition } = {};

    positions?.forEach((position) => {
        const org = position.org;
        if (!(org in grouped)) {
            grouped[org] = {
                ...position,
                position: [],
            };
        }

        grouped[org].position.push(position);

        // Assuming 'Present' is always the most recent
        if (
            grouped[org]?.duration?.split(" - ")[1] === "Present" ||
            new Date(position?.duration?.split(" - ")[0]) > new Date(grouped[org]?.duration?.split(" - ")[0])
        ) {
            grouped[org].description = position.description;
            grouped[org].totalMonths = position.totalMonths;
            grouped[org]._id = position._id;
        }
    });

    return Object.values(grouped);
}

export default function ProfileTab(props: ProfileTabProps) {
    const { data, projectId } = props;
    const { starRating, leadScore } = data;
    const containerRef = useRef<HTMLDivElement>(null);
    const shouldScrollToSkills = useSelector(selectScrollToSkills);
    const dispatch = useDispatch();
    const searchQuery = useSelector(selectSearchQuery);

    const formatDate = (dateString: string) => {
        if (!dateString) return "N/A";
        return dayjs(dateString).format("YYYY-MMMM-DD");
    };

    useEffect(() => {
        if (shouldScrollToSkills && containerRef.current) {
            containerRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }

        // reset scrollToSkills so that not every candidate switch results in scrolling to skills, e.g even if user is just switching using pagination
        setTimeout(() => {
            dispatch(setScrollToSkills(false));
        }, 0);
    }, [shouldScrollToSkills]);

    return (
        <Stack spacing={3} py={2}>
            {projectId && !isEmpty(starRating) ? (
                <VettingCriteriaRating
                    esProps={{
                        projectId: projectId,
                        candidateId: data._id,
                        vettingFixed: data?.vettingFixed,
                    }}
                />
            ) : null}
            {projectId && !isEmpty(leadScore && starRating) ? (
                <LeadScoreRating
                    egProps={{
                        leadScore: leadScore as LeadScore,
                        starRating: starRating,
                        projectId: projectId,
                        candidateId: data._id,
                        vettingFixed: data?.vettingFixed,
                    }}
                />
            ) : null}
            {data?.description && data?.description !== "-" ? (
                <Stack spacing={1}>
                    <CandidateProfileHeading variant="body2">About</CandidateProfileHeading>
                    <ReadMore>{data.description}</ReadMore>
                </Stack>
            ) : null}
            {data?.healthcareData && (
                <Stack spacing={1}>
                    <CandidateProfileHeading variant="body2">Healthcare Compliance Information</CandidateProfileHeading>
                    <Typography variant="body2">
                        {data?.healthcareData?.specialization && (
                            <span>
                                <b>Specialization:</b> {data.healthcareData.specialization}
                            </span>
                        )}{" "}
                        {data?.healthcareData?.credentials && (
                            <span>
                                | <b>Credentials:</b> {data.healthcareData.credentials}
                            </span>
                        )} {" "}
                        {data?.healthcareData?.npi_number && (
                            <span>
                                | <b>NPI:</b> {data.healthcareData.npi_number}
                            </span>
                        )}{" "}
                        {data?.healthcareData?.license_number && (
                            <span>
                                | <b>License:</b> {data.healthcareData.license_number}
                            </span>
                        )}
                    </Typography>
                </Stack>
            )}
            {data?.cvSource &&
            data?.cvSource?.label === "ATS" &&
            data?.cvSource?.value === "GREENHOUSE" &&
            data?.greenhouseMetaData &&
            data?.greenhouseMetaData?.applications ? (
                <Stack spacing={1.5}>
                    <CandidateProfileHeading variant="body1">Greenhouse Info</CandidateProfileHeading>
                    {data.greenhouseMetaData.applications.map((application: any, appIndex: number) => (
                        <Stack key={application.id} spacing={1}>
                            {application?.jobs.map((job: any, jobIndex: number) => (
                                <Typography key={job.id} variant="body1" fontWeight={500}>
                                    Job Application {appIndex * application?.jobs.length + jobIndex + 1}:{" "}
                                    <span style={{ fontWeight: "400", fontSize: "15px" }}>{job.name} </span>
                                    <span
                                        style={{
                                            fontStyle: "italic",
                                            fontWeight: "400",
                                            color: "darkgrey",
                                            fontSize: "15px",
                                        }}
                                    >
                                        (Applied at: {formatDate(application?.applied_at)})
                                    </span>
                                    {application?.prospect && (
                                        <span
                                            style={{
                                                fontStyle: "italic",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                padding: "1.5px",
                                                borderRadius: "10px",
                                                backgroundColor: "#dfff0b",
                                                paddingLeft: "7px",
                                                paddingRight: "7px",
                                                marginRight: "5px",
                                                width: "max-content",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            #Prospect
                                        </span>
                                    )}
                                </Typography>
                            ))}
                        </Stack>
                    ))}
                </Stack>
            ) : null}
            {data?.experience?.length ? (
                <Stack spacing={1}>
                    <CandidateProfileHeading variant="body1">Experience</CandidateProfileHeading>
                    <Stack spacing={1.5}>
                        {groupPositionsByOrg(data.experience).map((val, i: number) => {
                            return (
                                <Fragment key={`exp-${i}`}>
                                    <Experience val={val} />
                                    {data?.experience?.length - 1 !== i && <Divider />}
                                </Fragment>
                            );
                        })}
                    </Stack>
                </Stack>
            ) : null}
            {data?.education?.length ? (
                <Stack spacing={1}>
                    <CandidateProfileHeading variant="body1">Education</CandidateProfileHeading>
                    <Stack spacing={1.5}>
                        {data.education.map((val, i: number) => {
                            return (
                                <Fragment key={`exp-${i}`}>
                                    <Education val={val} />
                                    {data?.education?.length - 1 !== i && <Divider />}
                                </Fragment>
                            );
                        })}
                    </Stack>
                </Stack>
            ) : null}
            {data.skills?.length ? (
                <Stack gap={0.5}>
                    <CandidateProfileHeading variant="body1">Skills</CandidateProfileHeading>
                    <Stack ref={containerRef} direction="row" flexWrap="wrap">
                        {data.skills.map((val: { name: string }, i: number) => {
                            return (
                                <p className={style["pro__skillItem"]} key={`${val.name}-${i}`}>
                                    {val.name}
                                </p>
                            );
                        })}
                    </Stack>
                </Stack>
            ) : null}
            {data?.languages?.length ? (
                <Stack gap={0.5}>
                    <CandidateProfileHeading variant="body1">Languages</CandidateProfileHeading>
                    <Stack direction="row" flexWrap="wrap">
                        {data.languages.map(({ name, _id }, i: number) => {
                            return (
                                <p className={style["pro__skillItem"]} key={_id}>
                                    {name}
                                </p>
                            );
                        })}
                    </Stack>
                </Stack>
            ) : null}
            {data?.certificates?.length ? (
                <Stack spacing={1.5}>
                    <CandidateProfileHeading variant="body1">Certifications</CandidateProfileHeading>
                    {data.certificates.map((i, idx) => {
                        if (!("name" in i || "institute" in i)) {
                            return null;
                        }

                        return (
                            <Fragment key={i._id}>
                                <CertificatePane {...i} />
                                {data?.certificates?.length && data?.certificates?.length - 1 !== idx ? (
                                    <Divider />
                                ) : null}
                            </Fragment>
                        );
                    })}
                </Stack>
            ) : null}
        </Stack>
    );
}
