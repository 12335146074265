import { useCallback, useEffect, useMemo, useState } from "react";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { FileRejection, useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import CustomButton from "../../../components/CustomButton";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectAllContactsCSVModal, setModal } from "../../../store/reducers/modals.slice";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../../utils/helper";
import { FileTile } from "../../ApplyForJobs/ApplyForJobs";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { allCandidatesCSVUpload } from "../all-candidates.slice";

interface CsvDropzoneProps {
    onFilesSelected: (files: File) => void;
}

const CsvDropzone: React.FC<CsvDropzoneProps> = ({ onFilesSelected }) => {
    const [error, setError] = useState<string | null>(null);
    const onDrop = useCallback(
        (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            // Handle accepted and rejected files if needed
            if (acceptedFiles.length === 1) {
                const file = acceptedFiles[0];
                setError(null);
                const form = new FormData();
                form.append("file", file);
                onFilesSelected(file);
            } else {
                setError("Please select CSV file.");
            }
        },
        [onFilesSelected]
    );

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "text/csv": [".csv"],
            },
            onDrop,
        });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <FormControl error={Boolean(error)}>
            <Stack>
                <div {...getRootProps()} style={{ ...style, textAlign: "center" }}>
                    <input {...getInputProps()} />
                    {isDragActive
                        ? "Drop CSV here ..."
                        : "Click to upload your CSV or drag and drop (allowed formats: .csv)"}
                </div>
                {Boolean(error) && (
                    <FormHelperText
                        sx={{
                            margin: "0",
                            padding: "0.7rem 0",
                            fontSize: "14px",
                        }}
                    >
                        {error}
                    </FormHelperText>
                )}
                <Stack mt={1} spacing={0.5}>
                    {acceptedFiles.map(({ name }) => {
                        return <FileTile name={name} key={name} />;
                    })}
                </Stack>
            </Stack>
        </FormControl>
    );
};

export function ESAllCandidatesCSVUpload() {
    const dispatch = useDispatch();
    const showAllContactsCSVUpload = useSelector(selectAllContactsCSVModal);
    const isCreatingContact = useSelector(checkIfLoading(allCandidatesCSVUpload.type));

    const [selectedFile, setSelectedFiles] = useState<File | null>(null);

    useEffect(() => {
        return () => {
            setSelectedFiles(null);
        };
    }, []);

    if (!showAllContactsCSVUpload) {
        return null;
    }

    const onClose = () => dispatch(setModal({ key: "showAllContactsCSVModal", value: false }));

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        if (selectedFile) {
            form.append("file", selectedFile);
            dispatch(allCandidatesCSVUpload(form));
        }
    };

    const handleFilesSelected = (files: File) => {
        setSelectedFiles(files);
    };

    return (
        <BaseModal onClose={onClose}>
            <Stack spacing={2}>
                <Typography variant="h6">Upload contacts</Typography>
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                    }}
                >
                    <CsvDropzone onFilesSelected={handleFilesSelected} />
                    <CustomButton
                        type="submit"
                        variant="contained"
                        sx={{ alignSelf: "flex-end" }}
                        disabled={!selectedFile || isCreatingContact}
                    >
                        <ButtonTextWithLoading isLoading={isCreatingContact} text="Submit" />
                    </CustomButton>
                </form>
            </Stack>
        </BaseModal>
    );
}
