import { Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../../components/CustomButton";
import { setSuccessNotification } from "../../../components/Notification/index.reducer";
import { RootState } from "../../../store";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { useMoveAway } from "../hooks/useMoveAway.hook";
import { useSaveMultipleOutreachTemplates } from "../hooks/useSaveOutreach.hook";
import { checkErrorsInOutreach, checkForUnsavedChangesInWorkflow } from "../outreach.slice";

// show this modal if selected template is unsaved or edited
export function SaveModal() {
    const dispatch = useDispatch();
    const isErrorInOutreach = useSelector(checkErrorsInOutreach);

    const hasUnsavedChangesInWorkflow = useSelector((state: RootState) => checkForUnsavedChangesInWorkflow(state));
    const { isBlocked, handleBlockerProceed, handleBlockerReset } = useMoveAway({
        hasUnsavedChanges: (nextPathname: string) => {
            if (nextPathname.startsWith("/outreach")) return false;
            return hasUnsavedChangesInWorkflow;
        },
    });

    const {
        saveAllTemplates,
        saveMutationState: { isLoading: isWorkflowLoading },
    } = useSaveMultipleOutreachTemplates({
        successCallback: () => {
            dispatch(setSuccessNotification("Template saved successfully"));
            handleBlockerProceed();
        },
    });

    if (!isBlocked) {
        return null;
    }

    return (
        <BaseModal onClose={handleBlockerReset}>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography sx={{ fontSize: "25px" }} variant="h6">
                        Save your workflow?
                    </Typography>
                    <Typography variant="body2">
                        Recent changes are not saved yet. You may want to save your workflow before proceeding.
                    </Typography>
                </Stack>

                <Stack gap={1.3} flexDirection="row" mt={1}>
                    {/* TODO: Proceed with save, make a save call to the backend */}
                    <Tooltip
                        title={isErrorInOutreach ? "There are errors in you workflow, please solve it to proceed" : ""}
                    >
                        <CustomButton
                            onClick={() => saveAllTemplates()}
                            variant="contained"
                            disabled={isWorkflowLoading || isErrorInOutreach}
                            sx={{
                                width: "100%",
                                boxShadow: "none",
                                borderRadius: "0.25rem",
                            }}
                        >
                            Yes, save changes
                        </CustomButton>
                    </Tooltip>
                    <CustomButton
                        onClick={handleBlockerProceed}
                        variant="outlined"
                        sx={{
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                        }}
                    >
                        No, proceed anyway
                    </CustomButton>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
