import { OutlinedInputProps } from "@mui/material/OutlinedInput/OutlinedInput";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";

const CustomTextField = styled((props: TextFieldProps) => (
    <TextField InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>} {...props} />
))(({ theme }) => ({
    "& .MuiFormLabel-root": {
        fontSize: "14px",
        top: "-6px",
    },
    "& .MuiInputLabel-shrink": {
        top: 0,
    },
    "& .MuiInputBase-root": {
        fontSize: 13,
    },
    "& .MuiInputBase-input": {
        padding: "10px 12px",
    },
    "& .MuiFilledInput-root": {
        overflow: "hidden",
        borderRadius: 4,
        backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-focused": {
            backgroundColor: "transparent",
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

export default CustomTextField;
