import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { SxProps, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import {
    FallbackProps,
    ErrorBoundary as ReactErrorBoundary,
    ErrorBoundaryProps as ReactErrorBoundaryProps,
} from "react-error-boundary";
import { useRouteError } from "react-router-dom";

import { hardRefresh } from "../../utils/refresh";
import handleException from "../../utils/sentry";
import Loader from "../Loader/Loader";

export default function GlobalErrorBoundary() {
    const error: ReturnType<typeof useRouteError> = useRouteError();

    useEffect(() => {
        if (error && error !== "WAITING_FOR_REFRESH") {
            console.error(error);
            handleException(error);
        }
    }, []);

    if (error === "WAITING_FOR_REFRESH") {
        return <Loader />;
    }

    if (import.meta.env.VITE_REACT_APP_ENV !== "production") {
        return (
            <div style={{ padding: "20px" }}>
                <code style={{ color: "red" }}>{JSON.stringify(error?.stack ?? error, null, "\t")}</code>
            </div>
        );
    }

    return (
        <Stack
            spacing={2}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                backgroundColor: (theme) => theme.palette.background.default,
            }}
        >
            <Stack spacing={2}>
                <Typography
                    variant="h3"
                    sx={{
                        textAlign: "center",
                        fontWeight: "700",
                    }}
                >
                    Network error
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                    }}
                >
                    The page you requested unexpectedly failed to load. Contact the support for more assistance
                </Typography>
            </Stack>
            <Button
                variant="contained"
                color="primary"
                sx={{
                    textDecoration: "none",
                }}
                onClick={() => {
                    hardRefresh(true);
                }}
            >
                Go to Home Page
            </Button>
        </Stack>
    );
}

export const FallbackUI = ({
    sx,
    width,
    height,
    text,
}: {
    sx?: SxProps<Theme>;
    width?: any;
    height?: any;
    text?: string;
}) => {
    return (
        <Stack sx={sx} alignItems="center" justifyContent="center">
            <BrokenImageIcon
                sx={(theme) => ({
                    height: height ?? 200,
                    width: width ?? 200,
                    color: theme.palette.divider,
                })}
            />
            <Stack flexDirection="row" gap={1} alignItems="center">
                <Typography variant="body2" fontWeight={400} textAlign="center">
                    {text ?? "Unexpected error occured..."}
                </Typography>
            </Stack>
        </Stack>
    );
};

function fallbackRenderComponent({ error, resetErrorBoundary }: FallbackProps) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
        </div>
    );
}

type ErrorBoundaryProps = Omit<ReactErrorBoundaryProps, "fallbackRender"> & {
    fallbackRender?: ReactErrorBoundaryProps["fallbackRender"];
};

export function ErrorBoundary({ children, fallbackRender = fallbackRenderComponent }: ErrorBoundaryProps) {
    const error: ReturnType<typeof useRouteError> = useRouteError();

    useEffect(() => {
        if (error && error !== "WAITING_FOR_REFRESH") {
            handleException(error);
        }
    }, []);

    if (error === "WAITING_FOR_REFRESH") {
        return <Loader />;
    }

    return <ReactErrorBoundary fallbackRender={fallbackRender}>{children}</ReactErrorBoundary>;
}
