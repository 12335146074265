import API from "./API";

export async function uploadFile(file: File, pathName: string) {
    if (!file) return;

    const formData = new FormData();
    formData.append("files", file);
    formData.append("projectId", pathName);

    const response = await new API().post("upload/files", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return response;
}
