import React, { ForwardedRef, forwardRef } from "react";
import Tooltip, { type TooltipProps } from "@mui/material/Tooltip";
import Typography, { type TypographyProps } from "@mui/material/Typography";

function TypographyInner(
    props: TypographyProps,
    ref: ForwardedRef<HTMLElement>
) {
    return (
        <Typography
            ref={ref}
            variant="body2"
            {...props}
            sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                ...props.sx,
            }}
        >
            {props.children}
        </Typography>
    );
}

export const TypographyWithEllipses = forwardRef(TypographyInner);

type Props = {
    typographyProps?: Partial<TypographyProps>;
    tooltipProps?: Partial<TooltipProps>;
    children: string | undefined;
    charLimit?: number;
};

export default function TextWithEllipses({
    typographyProps,
    tooltipProps,
    children,
    charLimit = 80,
}: Props) {
    const text =
        children && children?.length > charLimit
            ? children.slice(0, charLimit) + "..."
            : children;

    const typography = (
        <TypographyWithEllipses {...typographyProps}>
            {text}
        </TypographyWithEllipses>
    );

    if (children && children?.length > charLimit) {
        return (
            <Tooltip {...tooltipProps} title={children}>
                {typography}
            </Tooltip>
        );
    }

    return <>{typography}</>;
}
