import { PayloadAction } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { Option } from "../../../../common";
import { setSuccessNotification } from "../../../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../../../store/reducers/loaders.reducer";
import API, { apiBase } from "../../../../utils/API";
import { CancelSagas } from "../../../../utils/saga.utils";
import { ICandidate } from "../../../project/project.types";
import { setCandidates } from "../Candidates/Candidates.reducer";
import {
    fetchCompanySuggestions,
    getAiCompany,
    getCompanyLinkedinUrlList,
    getOtherLinkedinUrlList,
    getScrappedCompany,
    getTypeAhead,
    leavePage,
    scrapCompaniesLinkedin,
    scrapLinkedin,
    searchCandidates,
    setCompanySuggestions,
    setEducationOptions,
    setErrors,
    setIndustryOptions,
    setJobTitleOptions,
    setLocationOptions,
    setOtherLinkedinUrlList,
    setRoleFunctionOptions,
    setScrapeCompanyRequestId,
    setScrappedCompany,
    setSelectedLinkedinUrl,
    setShowAICompanyPopup,
    setShowOtherLinkedinUrlPopup,
    setShowScrapLinkedinPopup,
    setShowScrapeCompanyPopup,
    setSkillOptions,
} from "./Filters.reducer";
import { optionType, scrapPayloadType } from "./Filters.type";
import handleException from "../../../../utils/sentry";

function* getTypeAheadSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const { label, value } = payload;
        const state = yield select();
        const isJobTitle = label === "role";
        const isLocation = label === "location";
        const isSkills = label === "skill";
        const isEducation = label === "education";
        const isIndustry = label === "industry";
        const isRoleFunction = label === "roleFunction";

        const jobFunction = get(state, "advancedFilters.jobFunction");
        yield put(startAction({ action: getTypeAhead.type }));
        const response = yield call(new API().post, `${apiBase}/v2/linkedin-type-ahead/type-ahead`, [
            {
                label,
                value,
                category: jobFunction.value,
            },
        ]);

        if (!response?.data) return;

        const structuredRes: optionType[] = response.data.map((item: optionType) => ({ label: item, value: item }));

        if (isJobTitle) yield put(setJobTitleOptions(structuredRes));
        else if (isLocation) yield put(setLocationOptions(response.data));
        else if (isSkills) yield put(setSkillOptions(structuredRes));
        else if (isEducation) yield put(setEducationOptions(structuredRes));
        else if (isIndustry) yield put(setIndustryOptions(response.data));
        else if (isRoleFunction) yield put(setRoleFunctionOptions(response.data));
    } catch (e) {
        handleException(e);
        console.error("***getTypeAheadSaga err=", e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* searchCandidatesSaga(): SagaIterator {
    try {
        const state = yield select();
        const jobFunction = get(state, "advancedFilters.jobFunction.label", "");
        const jobTitles = get(state, "advancedFilters.jobTitles");
        const skills = get(state, "advancedFilters.skills");
        const experience = get(state, "advancedFilters.experience");
        const industry = get(state, "advancedFilters.industry");
        const location = get(state, "advancedFilters.location");
        const visa = get(state, "advancedFilters.visa");
        const education = get(state, "advancedFilters.education");
        const currentCompany = get(state, "advancedFilters.currentCompany");
        const pastCompany = get(state, "advancedFilters.pastCompany");
        const roleFunction = get(state, "advancedFilters.roleFunction");

        const title = jobTitles.map((item: optionType) => item.value);
        const newSkills = skills.map((item: optionType) => item.value);
        const newExperience = experience.map((item: optionType) => item.value);
        const newLocation = location.map((item: optionType) => item.label);
        const newEducation = education.map((item: optionType) => item.value);
        const newCurrentCompany = currentCompany.map((item: optionType) => item.value);
        const newPastCompany = pastCompany.map((item: optionType) => item.value);

        let errors = {
            title: isEmpty(title) ? "Job Titles should not be empty" : "",
            location: isEmpty(newLocation) ? "Geography should not be empty " : "",
        };
        yield put(setErrors(errors));
        if (errors.title || errors.location) return;

        yield put(startAction({ action: searchCandidates.type }));
        const response = yield call(new API().post, `${apiBase}/searches/query-based`, {
            jobFunction,
            title,
            skills: newSkills,
            experience: newExperience,
            industry,
            location: newLocation,
            visa: visa.value,
            education: newEducation,
            currentCompany: newCurrentCompany,
            pastCompany: newPastCompany,
            roleFunction,
        });

        if (!response?.data) return;

        response.data.map((item: ICandidate) => {
            item.email = ["*****@gmail.com"];
            item.canShowEmail = true;
        });
        yield put(setCandidates(response.data));
    } catch (e) {
        handleException(e);
        console.error("***searchCandidatesSaga err=", e);
    } finally {
        yield put(stopAction({ action: searchCandidates.type }));
    }
}

function* getOtherLinkedinUrlListSaga(): SagaIterator {
    try {
        const state = yield select();
        const jobFunction = get(state, "advancedFilters.jobFunction.label", "");
        const jobTitles = get(state, "advancedFilters.jobTitles");
        const skills = get(state, "advancedFilters.skills");
        const experience = get(state, "advancedFilters.experience");
        const industry = get(state, "advancedFilters.industry");
        const location = get(state, "advancedFilters.location");
        const visa = get(state, "advancedFilters.visa");
        const education = get(state, "advancedFilters.education");
        const currentCompany = get(state, "advancedFilters.currentCompany");
        const pastCompany = get(state, "advancedFilters.pastCompany");
        const roleFunction = get(state, "advancedFilters.roleFunction");
        const title = jobTitles.map((item: optionType) => item.value);
        const newSkills = skills.map((item: optionType) => item.value);
        const newExperience = experience.map((item: optionType) => item.value);
        const newLocation = location.map((item: optionType) => item.label);
        const newEducation = education.map((item: optionType) => item.value);
        const newCurrentCompany = currentCompany.map((item: optionType) => item.value);
        const newPastCompany = pastCompany.map((item: optionType) => item.value);

        let errors = {
            title: "",
            location: isEmpty(newLocation) ? "Geography should not be empty " : "",
        };
        yield put(setErrors(errors));
        if (errors.location) return;

        yield put(setShowOtherLinkedinUrlPopup(true));
        yield put(startAction({ action: getOtherLinkedinUrlList.type }));
        const response = yield call(new API().post, `${apiBase}/searches/other-query-based`, {
            jobFunction,
            title,
            skills: newSkills,
            experience: newExperience,
            industry,
            location: newLocation,
            visa: visa.value,
            education: newEducation,
            currentCompany: newCurrentCompany,
            pastCompany: newPastCompany,
            roleFunction,
        });
        if (!response?.data) return;

        yield put(setOtherLinkedinUrlList(response?.data));
    } catch (e) {
        handleException(e);
        console.error("***getOtherLinkedinUrlListSaga err=", e);
    } finally {
        yield put(stopAction({ action: getOtherLinkedinUrlList.type }));
    }
}

function* scrapLinkedinSaga({ payload }: { payload: scrapPayloadType }): SagaIterator {
    try {
        const { linkedinUrl, cookie, fromPage, profileCount = 10, platform, projectId } = payload;
        const isLinkedin = platform === "linkedin";
        const isSalesNav = platform === "salesnav";

        let url = "";
        let data = {};
        if (isLinkedin) {
            url = "v2/scrap-request/create";
            data = {
                pageUrl: linkedinUrl,
                pages: {
                    from: fromPage,
                    to: Math.ceil(profileCount % 10),
                },
                source: "LINKEDIN",
                projectId: projectId?.toString(),
                autoSearch: true,
                liCookie: cookie,
            };
        } else if (isSalesNav) {
            url = "v2/project/scrap-salesNav-profiles";
            data = {
                projectId,
                liCookie: cookie,
                searchUrl: linkedinUrl,
                startPage: fromPage,
                noOfProfiles: profileCount,
            };
        }

        yield put(startAction({ action: scrapLinkedin.type }));
        const response = yield call(new API().post, `${apiBase}/${url}`, data);
        if (!response?.data) return;

        yield put(setSuccessNotification("Url uploaded successfully..."));
        yield put(setShowScrapLinkedinPopup(false));
    } catch (e) {
        handleException(e);
        console.error("***scrapLinkedinSaga err=", e);
    } finally {
        yield put(stopAction({ action: scrapLinkedin.type }));
    }
}

function* fetchCompanySuggestionsSaga(action: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const state = yield select();
        const values: Option[] = get(state, "advancedFilters.currentCompany");
        const payload = values.map((item: Option) => item.value);
        if (!payload.length) {
            return;
        }
        const response = yield call(new API().post, `${apiBase}/company/similarCompany`, {
            companyNames: payload,
        });

        if (!response?.length) {
            throw new Error("Oops! Similar companies could not be fetched at this moment.");
        }

        yield put(setCompanySuggestions(response));
    } catch (error) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getCompanyLinkedinUrlListSaga(): SagaIterator {
    try {
        const state = yield select();
        const industry = get(state, "advancedFilters.industry");
        const location = get(state, "advancedFilters.location");
        const newLocation = location.map((item: optionType) => item.label);
        let errors = {
            industry: isEmpty(industry) ? "Job Titles should not be empty" : "",
            location: isEmpty(newLocation) ? "Geography should not be empty " : "",
        };

        if (errors.industry || errors.location) return;
        yield put(setShowScrapeCompanyPopup(true));
        yield put(startAction({ action: getCompanyLinkedinUrlList.type }));
        const response = yield call(new API().post, `${apiBase}/searches/generate-company-links`, {
            industry,
            location: newLocation,
        });
        if (!response?.data) return;
        yield put(setSelectedLinkedinUrl(response.data));
    } catch (e) {
        handleException(e);
        console.error("***getCompanyLinkedinUrlListSaga err=", e);
    } finally {
        yield put(stopAction({ action: getCompanyLinkedinUrlList.type }));
    }
}

function* scrapCompaniesLinkedinSaga({ payload }: { payload: scrapPayloadType }): SagaIterator {
    try {
        yield put(setShowScrapeCompanyPopup(true));
        yield put(startAction({ action: scrapCompaniesLinkedin.type }));
        const state = yield select();
        const industry = get(state, "advancedFilters.industry");
        const location = get(state, "advancedFilters.location");
        const response = yield call(new API().post, `${apiBase}/company/scrapeCompanyList`, {
            ...payload,
            industry,
            location,
        });
        if (!response?.data) return;
        yield put(setSuccessNotification("Fetching companies for you"));
        yield put(setScrapeCompanyRequestId(response.data?._id));
        yield put(setShowScrapeCompanyPopup(false));
    } catch (e) {
        handleException(e);
        console.error("***scrapCompaniesLinkedinSaga err=", e);
    } finally {
        yield put(stopAction({ action: scrapCompaniesLinkedin.type }));
    }
}

function* getScrappedCompanySaga({ payload }: { payload: scrapPayloadType }): SagaIterator {
    try {
        yield put(startAction({ action: scrapCompaniesLinkedin.type }));
        const state = yield select();
        const scrapeCompanyRequestId = get(state, "advancedFilters.scrapeCompanyRequestId");

        if (!scrapeCompanyRequestId) return;
        const response = yield call(new API().post, `${apiBase}/company/getScrappedCompany`, {
            scrapeId: scrapeCompanyRequestId,
        });
        if (!response?.data) return;
        const isStatusSuccess = response?.data?.status === "COMPLETED";
        if (isStatusSuccess && response.data?.scrapeCompany) {
            yield put(setScrappedCompany(response.data?.scrapeCompany));
            yield put(setScrapeCompanyRequestId(""));
        }
    } catch (e) {
        handleException(e);
        console.error("***scrapCompaniesLinkedinSaga err=", e);
    } finally {
        yield put(stopAction({ action: scrapCompaniesLinkedin.type }));
    }
}

function* getAiCompanySaga({ payload }: { payload: string }): SagaIterator {
    try {
        yield put(startAction({ action: getAiCompany.type }));

        const response = yield call(new API().post, `${apiBase}/gpt/topCompanies`, {
            userInput: payload,
        });
        if (!response?.data) return;

        const scrapeCompany = response.data.map((item: string) => {
            if (typeof item === "string") {
                return {
                    name: item,
                    imageUrl: "",
                };
            }
            return {
                name: item?.[0] ,
                imageUrl: item?.[1]
            };
        });
        yield put(setScrappedCompany(scrapeCompany));
        yield put(setShowAICompanyPopup(false));
    } catch (e) {
        handleException(e);
        console.error("***getAiCompany saga err=", e);
    } finally {
        yield put(stopAction({ action: getAiCompany.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getTypeAhead.type, getTypeAheadSaga),
        // @ts-ignore
        yield takeLatest(searchCandidates.type, searchCandidatesSaga),
        // @ts-ignore
        yield takeLatest(getOtherLinkedinUrlList.type, getOtherLinkedinUrlListSaga),
        // @ts-ignore
        yield takeLatest(scrapLinkedin.type, scrapLinkedinSaga),
        // @ts-ignore
        yield takeLatest(fetchCompanySuggestions.type, fetchCompanySuggestionsSaga),
        // @ts-ignore
        yield takeLatest(getCompanyLinkedinUrlList.type, getCompanyLinkedinUrlListSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            scrapCompaniesLinkedin.type,
            scrapCompaniesLinkedinSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getScrappedCompany.type,
            getScrappedCompanySaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            getAiCompany.type,
            getAiCompanySaga
        ),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
