export function detectMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
        /Windows Mobile/i,
        /Kindle/i,
        /IEMobile/i,
        /Mobile Safari/i,
        /SymbianOS/i,
        /Maemo/i,
        /Meego/i,
        /PlayStation Portable/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}
