import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { blendSearchEvents } from "./sockets";
import { useWebSocketContext } from "./WebSocketContext";

import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { submitSearch } from "@/store/reducers/search/search.slice";
import {
    selectSearch,
    setCandidates,
    setCandidatesFetchStatus,
    setSalesNavScrapStatus,
    setSearch,
} from "@/store/reducers/searchCandidates/searchCandidates.slice";

interface SalesNavEventsProps {
    triggerSocket: boolean;
    setTriggerSocket: (value: boolean) => void;
}

export const useSalesNavEvents = (props: SalesNavEventsProps) => {
    const dispatch = useDispatch();
    const { triggerSocket, setTriggerSocket } = props;

    const { isConnected, emit, useSocketListeners } = useWebSocketContext();

    const isLoading = useSelector(checkIfLoading(submitSearch.type));
    const { _id: searchId, updatedAt } = useSelector(selectSearch);

    // Emit SALES_NAV_SEARCH_REQ when loading completes and searchId is available
    useEffect(() => {
        if (!searchId) {
            dispatch(setCandidatesFetchStatus("IDLE"));
        } else if (isLoading) {
            dispatch(setCandidatesFetchStatus("LOADING"));
        } else if (isConnected && triggerSocket) {
            // Ensure WebSocket is connected and triggerSocket is true
            const taskId = uuidv4();
            emit(blendSearchEvents.SALES_NAV_SEARCH_REQ, { searchId, taskId: taskId }, taskId);

            dispatch(setCandidatesFetchStatus("LOADING"));
            dispatch(setSearch({ _id: searchId, status: "PENDING", updatedAt }));
            setTriggerSocket(false); // Reset triggerSocket after emitting
        }
    }, [isLoading, searchId, isConnected, triggerSocket]);

    // Listen for WebSocket events
    useSocketListeners([
        {
            event: blendSearchEvents.SALES_NAV_SEARCH_RESULTS,
            callback: (data) => {
                const { candidates, status, salesNavScrapStatus } = data.data || {};
                if (status) {
                    if (status === "PENDING") {
                        dispatch(setCandidatesFetchStatus("LOADING"));
                        dispatch(setSearch({ _id: searchId, status, updatedAt }));
                    } else if (["COMPLETED", "ZERO_PROFILE", "IN_PROGRESS", "CANCELLED", "CANCELED"].includes(status)) {
                        if (candidates) {
                            dispatch(setCandidates(data.data));
                        }
                        dispatch(setCandidatesFetchStatus("SUCCESS"));
                        dispatch(setSearch({ _id: searchId, status, updatedAt }));
                    }
                }

                if (salesNavScrapStatus) {
                    dispatch(setSalesNavScrapStatus(salesNavScrapStatus));
                }
            },
        },
    ]);
};
