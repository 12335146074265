import { ReactNode, RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

type Position = {
    top: number;
    left: number;
    width: number;
};
interface PortalPopupProps {
    anchorRef: RefObject<HTMLElement>;
    children: ReactNode | ((position: Position) => JSX.Element);
    open: boolean;
}

/**
 * PortalPopup component that renders its children in a portal, positioned relative to an anchor element.
 *
 * @param {PortalPopupProps} props - The props for the component.
 * @param {RefObject<HTMLElement>} props.anchorRef - The reference to the anchor element.
 * @param {ReactNode} props.children - The content to render inside the portal.
 * @param {boolean} props.open - Whether the portal is open.
 * @returns {JSX.Element} - The rendered PortalPopup component.
 */
function PortalPopup({ anchorRef, open, children }: PortalPopupProps): JSX.Element {
    const [position, setPosition] = useState<Position>({ top: 0, left: 0, width: 0 });
    const popupRef = useRef<HTMLDivElement>(null);
    const rafId = useRef<number | null>(null);

    /**
     * Updates the position of the popup based on the anchor element's position.
     */
    const updatePosition = useCallback(() => {
        if (anchorRef.current && popupRef.current) {
            const rect = anchorRef.current.getBoundingClientRect();
            const popupHeight = popupRef.current.offsetHeight;
            const popupWidth = popupRef.current.offsetWidth;
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;
            const spaceBelow = viewportHeight - rect.bottom;
            const spaceAbove = rect.top;
            const spaceRight = viewportWidth - rect.right;
            const spaceLeft = rect.left;
            const targetHeight = rect.height;
            const width = rect.width;

            const PADDING = 2;

            let top = rect.bottom;
            let left = rect.left;

            // 2px padding around the popup
            if (spaceBelow >= popupHeight) {
                // Position below
                top = rect.bottom + PADDING;
            } else if (spaceAbove >= popupHeight) {
                // Position above
                top = spaceAbove - popupHeight - PADDING;
            } else if (spaceLeft >= popupWidth) {
                // Position to the left
                top = spaceAbove + targetHeight / 2 - popupHeight / 2;
                left = spaceLeft - popupWidth - PADDING;
            } else if (spaceRight >= popupWidth) {
                // Position to the right
                top = spaceAbove + targetHeight / 2 - popupHeight / 2;
                left = rect.right + PADDING;
            }

            setPosition((prevPosition) => {
                if (prevPosition.top !== top || prevPosition.left !== left || prevPosition.width !== width) {
                    return { top, left, width };
                }
                return prevPosition;
            });
        }
    }, [anchorRef, open]);

    /**
     * Handles the scroll event by updating the position of the popup using requestAnimationFrame.
     */
    const handleScroll = () => {
        if (rafId.current) {
            cancelAnimationFrame(rafId.current);
        }
        rafId.current = requestAnimationFrame(updatePosition);
    };

    const handleResizeObserver = () => {
        const resizeObserver = new ResizeObserver(() => {
            updatePosition();
        });
        const observe = () => {
            window.addEventListener("scroll", handleScroll, true);
            if (anchorRef.current) {
                resizeObserver.observe(anchorRef.current);
            }
        };

        const unobserve = () => {
            window.removeEventListener("scroll", handleScroll, true);
            if (anchorRef.current) {
                resizeObserver.unobserve(anchorRef.current);
            }
            if (rafId.current) {
                cancelAnimationFrame(rafId.current);
            }
        };
        return { observe, unobserve };
    };

    useLayoutEffect(() => {
        // update position on mount
        if (open) {
            updatePosition();
        }
        // Since the portal is rendered in the body, we need to prevent scrolling on the body when the portal is open
        document.body.classList.add("overflow-hidden");
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [open, updatePosition]);

    useEffect(() => {
        const { observe, unobserve } = handleResizeObserver();

        if (open) {
            observe();
        } else {
            unobserve();
        }

        return unobserve;
    }, [anchorRef, open]);

    if (!open) {
        return null;
    }

    return createPortal(
        <div
            ref={popupRef}
            className="absolute z-50 rounded-md border bg-popover text-popover-foreground shadow-md outline-none"
            style={{
                top: position.top,
                left: position.left,
                minWidth: position.width,
            }}
        >
            {children instanceof Function ? children(position) : children}
        </div>,
        document.body
    );
}

export default PortalPopup;
