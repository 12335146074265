import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";

import {
    handleRequestSort,
    handleSelectAllClick,
    numberOfSelectedRows,
    selectTableOrder,
    selectTableOrderBy,
    totalRowCount,
} from "../contact-list.slice";
import { ContactListTableItem, HeadCell } from "../contact-list.type";

const headCells: readonly HeadCell[] = [
    {
        id: "name",
        numeric: false,
        label: "Name",
    },
    {
        id: "listSize",
        numeric: true,
        label: "List Size",
    },
    // {
    //     id: "creator",
    //     numeric: true,
    //     label: "Creator",
    // },
    {
        id: "lastUpdated",
        numeric: true,
        label: "Last Updated",
    },
];

export default function EnhancedTableHead() {
    const dispatch = useDispatch();
    const numSelected = useSelector(numberOfSelectedRows);
    const rowCount = useSelector(totalRowCount);
    const order = useSelector(selectTableOrder);
    const orderBy = useSelector(selectTableOrderBy);

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        dispatch(handleSelectAllClick(value));
    };

    const createSortHandler = (property: keyof ContactListTableItem) => (event: React.MouseEvent<unknown>) => {
        dispatch(handleRequestSort(property));
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={handleSelect}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            paddingRight: headCell.id === "lastUpdated" ? "30px" : 0,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
