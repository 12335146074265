import { Document, Font, Image, Link, Page, Text, View } from "@react-pdf/renderer";
import ChartJsImage from "chartjs-to-image";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import hqLogo from "../../../../assets/img/hqlogo.png";
import { EFunnelOverviewLabel, EFunnelOverviewLabelEasyGrowth } from "../../../../store/apis/analytics/analytics.enums";
import {
    CreditUsageLabel,
    EmailResponsesLabel,
    LinkedinResponseLabel,
    ProjectResponsesLabel,
    SMSResponsesLabel,
} from "../../../../store/apis/analytics/analytics.schema";
import InterMedium from "../../../project/components/exportCandidates/template-assets/Inter-Medium.ttf";
import InterRegular from "../../../project/components/exportCandidates/template-assets/Inter-Regular.ttf";

Font.register({ family: "BOLD", src: InterMedium });
Font.register({ family: "MEDIUM", src: InterRegular });

const PRIVACY_URL = "https://easysource.hirequotient.com/privacy-policy.pdf";

const FONT_SIZE = {
    md: 15,
    sm: 12,
    xs: 10,
    "2xs": 9,
};

const BG_COLOR_MAP = {
    Nexxt: "#C4E88B",
    "Resume-library": "#9EE29E",
    ZipRecruiter: "#B5A6EB",
    SalesHeads: "#C28FC9",
    EasySource: "#00B7C7",
    Linkedin: "#FEB941",
    Other: "#EBDC78",
    "Career Builder": "#FFD966",
    Glassdoor: "#80D4CC",
    Indeed: "#FFA366",
    Monster: "#F4A7B9",
    EasyGrowth: "#B3C8CF",
};

const BLUE_COLOR = "#175874";
const GREY_COLOR = "#5A5A5A";
const DARK_BORDER_COLOR = "#52575D";

const MultipleUserBarConfig = (data: { labels: string[]; datasets: any[] }) => {
    return {
        type: "bar",
        data: {
            labels: data.labels,
            datasets: data.datasets,
        },
        options: {
            legend: {
                display: true,
                position: "top",
                labels: {
                    fontSize: 10,
                    padding: 20,
                },
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            display: true,
                            fontSize: 8,
                            maxRotation: 0,
                            minRotation: 0,
                        },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontSize: 10,
                        },
                    },
                ],
            },
        },
    };
};

const BarConfig = (title: string, labels: string[], data: number[]) => {
    return {
        type: "horizontalBar",
        data: {
            labels: labels,
            datasets: [
                {
                    axis: "y",
                    label: title,
                    data: data,
                    backgroundColor: "#00b7c7",
                },
            ],
        },
        options: {
            legend: {
                display: false,
            },
            indexAxis: "y",
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        barThickness: 25,
                        gridLines: {
                            display: false,
                        },
                    },
                ],
            },
        },
    };
};

const funnelSplitChannelBarConfig = (
    lables: string[],
    data: {
        linkedin: number[];
        sms: number[];
        email: number[];
    }
) => {
    return {
        type: "horizontalBar",
        options: {
            legend: {
                position: "right",
            },
            plugins: {
                datalabels: {
                    formatter: (v: any) => {
                        if (!v) return "";
                        const val = Math.floor(v);
                        return `${val}%`;
                    },
                    display: function (ctx: any) {
                        const scale = ctx.chart.scales.x;
                        const value = ctx.dataset.data[ctx.dataIndex];
                        const range = Math.max(scale.max - scale.min, 1);
                        return (ctx.chart.height / range) * value > 16;
                    },
                    align: "center",
                    font: {
                        weight: "bold",
                        size: 12.5,
                    },
                },
            },
            scales: {
                xAxes: [
                    {
                        id: "x",
                        stacked: true,
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        barThickness: 25,
                        stacked: true,
                        gridLines: {
                            display: false,
                        },
                    },
                ],
            },
        },
        data: {
            labels: lables,
            datasets: [
                {
                    label: "Linkedin",
                    data: data.linkedin,
                    backgroundColor: "#FEB941",
                },
                {
                    label: "Email",
                    data: data.email,
                    backgroundColor: "#ebdc78",
                },
                {
                    label: "SMS",
                    data: data.sms,
                    backgroundColor: "#00b7c7",
                },
            ],
        },
    };
};

const funnelSplitSourceBarConfig = (
    labels: string[],
    data: {
        [key: string]: number[];
    }
) => {
    return {
        type: "horizontalBar",
        options: {
            legend: {
                position: "right",
            },
            plugins: {
                datalabels: {
                    formatter: (v: any) => {
                        if (!v) return "";
                        const val = Math.floor(v);
                        return `${val}%`;
                    },
                    display: function (ctx: any) {
                        const scale = ctx.chart.scales.x;
                        const value = ctx.dataset.data[ctx.dataIndex];
                        const range = Math.max(scale.max - scale.min, 1);
                        return (ctx.chart.height / range) * value > 16;
                    },
                    align: "center",
                    font: {
                        weight: "bold",
                        size: 12.5,
                    },
                },
            },
            scales: {
                xAxes: [
                    {
                        id: "x",
                        stacked: true,
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        barThickness: 25,
                        stacked: true,
                        gridLines: {
                            display: false,
                        },
                    },
                ],
            },
        },
        data: {
            labels: labels,
            datasets: Object.keys(data).map((key) => {
                return {
                    label: key,
                    data: data[key],
                    // @ts-ignore
                    backgroundColor: BG_COLOR_MAP[key],
                };
            }),
        },
    };
};

const lineChartConfig = (chartLabels: string[], labels: string[], extractedData: number[], relevantData: number[]) => {
    return {
        type: "line",
        data: {
            labels: labels,
            datasets: [
                {
                    type: "line",
                    label: chartLabels[0],
                    data: extractedData,
                    fill: false,
                    borderColor: "#00b7c7",
                },

                {
                    type: "line",
                    label: chartLabels[1],
                    fill: false,
                    data: relevantData,
                    borderColor: "#ebdc78",
                },
                ,
            ],
        },
        options: {
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {},
                    },
                ],
            },
        },
    };
};

const useChartToImage = ({ config, width, height }: { config: any; width: number; height: number }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    useEffect(() => {
        const myChart = new ChartJsImage();
        myChart.setConfig(config).setWidth(width).setHeight(height);
        setImageSrc(myChart.getUrl());
    });

    return imageSrc;
};

const Footer = ({ productName, pageNumber }: { productName: string; pageNumber: number }) => {
    return (
        <View
            style={{
                borderTop: `1px solid ${DARK_BORDER_COLOR}`,
                marginTop: "auto",
                paddingTop: 10,
                display: "flex",
                flexDirection: "row",
                color: "#808080",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 7,
            }}
        >
            <View style={{ maxWidth: 300 }}>
                <Text>Note: 1. Outreach tracking data before November 2023 may not be completely accurate</Text>
                <Text style={{ marginTop: 2 }}>
                    2. Report generated vai {productName} license for internal use only. For more details on the usage
                    of data, refer to the privacy policy{" "}
                    <Link
                        style={{
                            color: "#1264a3",
                        }}
                        href={PRIVACY_URL}
                    >
                        {" "}
                        here
                    </Link>
                    .
                </Text>
            </View>
            <Text>Page {pageNumber} of 2</Text>
        </View>
    );
};

type IAnalyticsReportTemplate = {
    analyticsMemberFilter: string;
    analyticsProjectFilter: string;
    analyticsDateFilter: string;
    funnelSplitDataSource: {
        label: string[];
        data: {
            [key: string]: number[];
        };
    };
    funnelData: {
        label: string[];
        data: number[];
    };
    multiUserFunnelData: {
        labels: string[];
        datasets: any[];
    };
    funnelSplitDataChannel: {
        label: string[];
        data: {
            linkedin: number[];
            email: number[];
            sms: number[];
        };
    };
    outreachData: {
        linkedin: { label: string[]; data: number[] };
        sms: { label: string[]; data: number[] };
        email: { label: string[]; data: number[] };
    };
    usageStats: {
        projectStatus: {
            label: string[];
            data: number[];
        };
        creditUsage: {
            label: string[];
            data: number[];
        };
    };
    graphData: {
        label: string[];
        extractedData: number[];
        relevantData: number[];
    };
    usageStatsMemberFilter: string;
    usageStatsProjectFilter: string;
    usageStagsDateFilter: string;
    isEasySource: boolean;
};

const AnalyticsReportTemplate = ({
    multiUserFunnelData,
    funnelData,
    funnelSplitDataChannel,
    funnelSplitDataSource,
    outreachData,
    usageStats,
    graphData,
    analyticsDateFilter,
    analyticsMemberFilter,
    analyticsProjectFilter,
    usageStagsDateFilter,
    usageStatsMemberFilter,
    usageStatsProjectFilter,
    isEasySource,
}: IAnalyticsReportTemplate) => {
    const { t } = useTranslation();
    const chartLables = [t("analytics.candidateSourcedLabel"), t("analytics.qualifiedCandidatesLabel")];
    const multiUserBarImage = useChartToImage({
        config: MultipleUserBarConfig(multiUserFunnelData),
        width: 800,
        height: 330,
    });
    const funnelBarImage = useChartToImage({
        config: BarConfig(
            "",
            funnelData.label.map(() => ""),
            funnelData.data
        ),
        width: 450,
        height: 255,
    });

    const splitFunnelChannelBarImage = useChartToImage({
        config: funnelSplitChannelBarConfig(
            funnelSplitDataChannel.label.map(() => ""),
            funnelSplitDataChannel.data
        ),
        width: 450,
        height: 200,
    });

    const splitFunnelSourceBarImage = useChartToImage({
        config: funnelSplitSourceBarConfig(
            funnelSplitDataSource.label.map(() => ""),
            funnelSplitDataSource.data
        ),
        width: 450,
        height: 200,
    });

    const lineChartImage = useChartToImage({
        config: lineChartConfig(chartLables, graphData.label, graphData.extractedData, graphData.relevantData),
        width: 800,
        height: 275,
    });

    const allImagesReady =
        funnelBarImage &&
        splitFunnelChannelBarImage &&
        splitFunnelSourceBarImage &&
        lineChartImage &&
        multiUserBarImage;

    if (!allImagesReady) return null;

    const TableHeader1 = ["Overview", "Total"];
    const TableHeader2 = ["Projects Status", "Total"];
    const TableHeader3 = ["Credit Usage", "Total"];

    const productName = isEasySource ? "EasySource" : "EasyGrowth";

    return (
        <Document style={{ fontFamily: "MEDIUM" }}>
            <Page
                orientation="portrait"
                size={"A4"}
                style={{
                    paddingTop: 10,
                    paddingBottom: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: FONT_SIZE["xs"],
                    color: GREY_COLOR,
                }}
            >
                <View
                    style={{
                        marginBottom: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <View>
                        <Text
                            style={{
                                fontSize: FONT_SIZE.md,
                                fontFamily: "BOLD",
                                color: BLUE_COLOR,
                            }}
                        >
                            Analytics overview
                        </Text>
                    </View>

                    <Image src={hqLogo} style={{ width: "175px" }} />
                </View>

                <View
                    style={{
                        marginBottom: 20,
                        borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                        display: "flex",
                        flexDirection: "row",
                        paddingBottom: 10,
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: FONT_SIZE["2xs"],
                    }}
                >
                    <Text>Project - {analyticsProjectFilter}</Text>
                    <Text>Member - {analyticsMemberFilter}</Text>
                    <Text>Date - {analyticsDateFilter}</Text>
                </View>
                <View
                    style={{
                        marginBottom: 20,
                    }}
                >
                    <Text
                        style={{
                            fontSize: 12.5,
                            fontFamily: "BOLD",
                            color: BLUE_COLOR,
                        }}
                    >
                        Multiple user interaction
                    </Text>
                    <Text style={{ maxWidth: 300, paddingTop: 5 }}>
                        View all your analytics in one place, powered by {productName}
                    </Text>
                </View>

                <View style={{ borderRadius: 5, width: "100%" }}>
                    <Image
                        style={{
                            width: "100%",
                        }}
                        src={multiUserBarImage}
                    />
                </View>

                <View
                    style={{
                        marginBottom: 20,
                    }}
                >
                    <Text
                        style={{
                            fontSize: 12.5,
                            fontFamily: "BOLD",
                            color: BLUE_COLOR,
                        }}
                    >
                        Overall funnel
                    </Text>
                </View>

                <View
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "80%",
                        flexDirection: "row",
                        gap: 20,
                        marginBottom: 20,
                    }}
                >
                    <View
                        style={{
                            width: "75%",
                            borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            fontSize: FONT_SIZE["2xs"],
                        }}
                    >
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            }}
                        >
                            {TableHeader1.map((item, index) => {
                                return (
                                    <View
                                        key={`overview-${item}-${index}`}
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                            width: index === 1 ? 75 : 131,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: "BOLD",
                                                textAlign: index === 1 ? "right" : "left",
                                            }}
                                        >
                                            {item}
                                        </Text>
                                    </View>
                                );
                            })}
                        </View>

                        {funnelData.label.map((item, index) => {
                            return (
                                <View
                                    key={`funnel-overview-${index}`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <View
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Text style={{ textAlign: "left" }}>
                                            {/* @ts-ignore */}
                                            {isEasySource /* @ts-ignore */
                                                ? EFunnelOverviewLabel[item]
                                                : /* @ts-ignore */
                                                  EFunnelOverviewLabelEasyGrowth[item]}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            textAlign: "right",
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            width: 75,
                                            flexShrink: 0,
                                        }}
                                    >
                                        <Text>{funnelData.data[index] || 0}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>

                    <View
                        style={{
                            borderRadius: 5,
                            width: "100%",
                            marginTop: 24,
                        }}
                    >
                        <Image
                            style={{
                                width: "100%",
                            }}
                            src={funnelBarImage}
                        />
                    </View>
                </View>

                <View style={{ marginBottom: 20 }}>
                    <Text
                        style={{
                            fontSize: 12.5,
                            fontFamily: "BOLD",
                            color: BLUE_COLOR,
                        }}
                    >
                        Funnel splits
                    </Text>
                </View>

                <View
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        gap: 5,
                        marginBottom: 20,
                    }}
                >
                    <View
                        style={{
                            gap: funnelSplitDataChannel.label.length === 4 ? 13 : 7,
                            marginTop: 27.5,
                            width: "225px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {funnelSplitDataChannel.label.map((item, index) => {
                            return (
                                <Text style={{ fontSize: FONT_SIZE["2xs"] }} key={`channel-${index}`}>
                                    {/* @ts-ignore */}
                                    {isEasySource ? EFunnelOverviewLabel[item] : EFunnelOverviewLabelEasyGrowth[item]}
                                </Text>
                            );
                        })}
                    </View>
                    <View
                        style={{
                            borderRadius: 5,
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 10,
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: "BOLD",
                                color: BLUE_COLOR,
                            }}
                        >
                            Split by channel
                        </Text>
                        <Image
                            style={{
                                width: "100%",
                            }}
                            src={splitFunnelChannelBarImage}
                        />
                    </View>
                    <View
                        style={{
                            borderRadius: 5,
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 10,
                        }}
                    >
                        <Text
                            style={{
                                color: BLUE_COLOR,
                                fontFamily: "BOLD",
                            }}
                        >
                            Split by source
                        </Text>
                        <Image
                            style={{
                                width: "100%",
                            }}
                            src={splitFunnelSourceBarImage}
                        />
                    </View>
                </View>

                <View style={{ marginBottom: 20 }}>
                    <Text
                        style={{
                            fontSize: 12.5,
                            fontFamily: "BOLD",
                            color: BLUE_COLOR,
                        }}
                    >
                        Channel-wise outreach status
                    </Text>
                </View>

                <View
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        marginBottom: 15,
                        gap: 20,
                    }}
                >
                    <View
                        style={{
                            width: "100%",
                            borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            fontSize: FONT_SIZE["2xs"],
                        }}
                    >
                        <Text
                            style={{
                                color: BLUE_COLOR,
                                fontFamily: "BOLD",
                                marginBottom: 10,
                                textAlign: "center",
                            }}
                        >
                            Linkedin
                        </Text>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            }}
                        >
                            {TableHeader1.map((item, index) => {
                                return (
                                    <View
                                        key={`linkedin-${item}-${index}`}
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                            width: index === 1 ? 75 : 131,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: "BOLD",
                                                textAlign: index === 1 ? "right" : "left",
                                            }}
                                        >
                                            {item}
                                        </Text>
                                    </View>
                                );
                            })}
                        </View>

                        {outreachData.linkedin.label.map((label, index) => {
                            return (
                                <View
                                    key={`linkedin-${label}-${index}`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <View
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        <Text style={{ textAlign: "left" }}>{LinkedinResponseLabel[label]}</Text>
                                    </View>
                                    <View
                                        style={{
                                            textAlign: "right",
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            width: 75,
                                            flexShrink: 0,
                                        }}
                                    >
                                        <Text>{outreachData.linkedin.data[index] || 0}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    <View
                        style={{
                            width: "100%",
                            borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            fontSize: FONT_SIZE["2xs"],
                            marginTop: "-20px",
                        }}
                    >
                        <Text
                            style={{
                                color: BLUE_COLOR,
                                marginBottom: 10,
                                textAlign: "center",
                                fontFamily: "BOLD",
                            }}
                        >
                            Email
                        </Text>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            }}
                        >
                            {TableHeader1.map((item, index) => {
                                return (
                                    <View
                                        key={`email-${item}-${index}`}
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                            width: index === 1 ? 75 : 131,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: "BOLD",
                                                textAlign: index === 1 ? "right" : "left",
                                            }}
                                        >
                                            {item}
                                        </Text>
                                    </View>
                                );
                            })}
                        </View>

                        {outreachData.email.label.map((label, index) => {
                            return (
                                <View
                                    key={`email-${label}-${index}`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <View
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        <Text style={{ textAlign: "left" }}>{EmailResponsesLabel[label]}</Text>
                                    </View>
                                    <View
                                        style={{
                                            textAlign: "right",
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            width: 75,
                                            flexShrink: 0,
                                        }}
                                    >
                                        <Text>{outreachData.email.data[index] || 0}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    <View
                        style={{
                            width: "100%",
                            borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            fontSize: FONT_SIZE["2xs"],
                            marginTop: "-42px",
                        }}
                    >
                        <Text
                            style={{
                                color: BLUE_COLOR,
                                marginBottom: 10,
                                fontFamily: "BOLD",
                                textAlign: "center",
                            }}
                        >
                            SMS
                        </Text>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            }}
                        >
                            {TableHeader1.map((item, index) => {
                                return (
                                    <View
                                        key={`sms-${item}-${index}`}
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                            width: index === 1 ? 75 : 131,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: "BOLD",
                                                textAlign: index === 1 ? "right" : "left",
                                            }}
                                        >
                                            {item}
                                        </Text>
                                    </View>
                                );
                            })}
                        </View>

                        {outreachData.sms.label.map((item, index) => {
                            return (
                                <View
                                    key={`sms-${item}-${index}`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <View
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        <Text style={{ textAlign: "left" }}>{SMSResponsesLabel[item]}</Text>
                                    </View>
                                    <View
                                        style={{
                                            textAlign: "right",
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            width: 75,
                                            flexShrink: 0,
                                        }}
                                    >
                                        <Text>{outreachData.sms.data[index] || 0}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                </View>

                <Footer productName={productName} pageNumber={1} />
            </Page>

            <Page
                orientation="portrait"
                size={"A4"}
                style={{
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: FONT_SIZE["xs"],
                    color: GREY_COLOR,
                }}
            >
                <View
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                        display: "flex",
                        flexDirection: "row",
                        paddingBottom: 10,
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: FONT_SIZE["2xs"],
                    }}
                >
                    <Text>Project - {usageStatsProjectFilter}</Text>
                    <Text>Member - {usageStatsMemberFilter}</Text>
                    <Text>Date - {usageStagsDateFilter}</Text>
                </View>

                <View style={{ marginBottom: 20 }}>
                    <Text
                        style={{
                            fontSize: 12.5,
                            fontFamily: "BOLD",
                            color: BLUE_COLOR,
                        }}
                    >
                        Usage Stats
                    </Text>
                </View>

                <View
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        marginBottom: 40,
                        gap: 40,
                    }}
                >
                    <View
                        style={{
                            width: 200,
                            borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            fontSize: FONT_SIZE["2xs"],
                        }}
                    >
                        <Text
                            style={{
                                color: BLUE_COLOR,
                                fontFamily: "BOLD",
                                marginBottom: 10,
                                textAlign: "center",
                            }}
                        >
                            Projects Status
                        </Text>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            }}
                        >
                            {TableHeader2.map((item, index) => {
                                return (
                                    <View
                                        key={`project-status-${item}-${index}`}
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                            width: index === 1 ? 75 : 131,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: "BOLD",
                                                textAlign: index === 1 ? "right" : "left",
                                            }}
                                        >
                                            {item}
                                        </Text>
                                    </View>
                                );
                            })}
                        </View>

                        {usageStats.projectStatus.label.map((label, index) => {
                            return (
                                <View
                                    key={`project-status-${label}-${index}`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <View
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        <Text style={{ textAlign: "left" }}>{t(ProjectResponsesLabel[label])}</Text>
                                    </View>
                                    <View
                                        style={{
                                            textAlign: "right",
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            width: 75,
                                            flexShrink: 0,
                                        }}
                                    >
                                        <Text>{usageStats.projectStatus.data[index] || 0}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    <View
                        style={{
                            width: 200,
                            borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            fontSize: FONT_SIZE["2xs"],
                        }}
                    >
                        <Text
                            style={{
                                color: BLUE_COLOR,
                                marginBottom: 10,
                                textAlign: "center",
                                fontFamily: "BOLD",
                            }}
                        >
                            Credit usage
                        </Text>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                borderBottom: `0.5px solid ${DARK_BORDER_COLOR}`,
                            }}
                        >
                            {TableHeader3.map((item, index) => {
                                return (
                                    <View
                                        key={`credit-usage-${item}-${index}`}
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                            width: index === 1 ? 75 : 131,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: "BOLD",
                                                textAlign: index === 1 ? "right" : "left",
                                            }}
                                        >
                                            {item}
                                        </Text>
                                    </View>
                                );
                            })}
                        </View>

                        {usageStats.creditUsage.label.map((label, index) => {
                            return (
                                <View
                                    key={`credit-usage-${label}-${index}`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <View
                                        style={{
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            flexGrow: 1,
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        <Text style={{ textAlign: "left" }}>{t(CreditUsageLabel[label])}</Text>
                                    </View>
                                    <View
                                        style={{
                                            textAlign: "right",
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            width: 75,
                                            flexShrink: 0,
                                        }}
                                    >
                                        <Text>{usageStats.creditUsage.data[index] || 0}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                </View>
                <View>
                    <Image
                        style={{
                            width: "100%",
                        }}
                        src={lineChartImage}
                    />
                </View>
                <Footer productName={productName} pageNumber={2} />
            </Page>
        </Document>
    );
};

export default AnalyticsReportTemplate;
