import Stack from "@mui/joy/Stack";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "./Footer";
import ResponseEditor from "./ResponseEditor";

import {
    setErrorNotification,
    setSuccessNotification,
    setWarningNotification,
} from "../../../../components/Notification/index.reducer";
import {
    useGetEmailConversationsQuery,
    useSendEmailMutation,
} from "../../../../store/apis/super-inbox/super-inbox.api";
import { EUser } from "../../../../store/apis/super-inbox/super-inbox.enums";
import { selectUser } from "../../../Signin/Signin.reducer";
import { useSuperInboxContext } from "../../Context";
import { ChannelProps } from "../channels/ChannelTabs";
import ChatBubble from "../messages/ChatBubble";
import MessagesConversationContainer from "../messages/MessagesConversationContainer";

interface EmailProps extends ChannelProps {}

export default function Email(props: EmailProps) {
    const dispatch = useDispatch();
    const { candidateProfile, setTags } = props;
    const {
        filters: { projectId, userId },
        candidateParamId,
    } = useSuperInboxContext();

    const [sendEmail, { isLoading: isSendingEmail }] = useSendEmailMutation();

    const { isLoading, isFetching, data, refetch } = useGetEmailConversationsQuery({
        candidateId: candidateParamId,
        projectIds: [projectId],
        userId,
    });

    useEffect(() => {
        setTags({
            tags: data?.tags ?? [],
            projects: data?.projects ?? [],
        });
    }, [data]);

    const user = useSelector(selectUser);
    const fromEmail = data?.messages[0]?.from;
    const userAuthEmailAddress = user?.authorizedEmailAddress;

    return (
        <>
            <MessagesConversationContainer isLoading={isLoading || isFetching}>
                {data?.messages?.map((message, index) => {
                    const isYou = message.senderType === EUser.USER;

                    return (
                        <Stack
                            key={`email-conversation-${index}`}
                            direction="row"
                            spacing={2}
                            flexDirection={isYou ? "row-reverse" : "row"}
                        >
                            {/* {!isYou && <Avatar size="sm" src={candidateProfile?.profileImage || ""} />} */}
                            <ChatBubble
                                fullWidth
                                isQueued={false}
                                isMessageFailed={false}
                                variant={isYou ? "sent" : "received"}
                                body={message.body}
                                time={message.time}
                                senderType={message.senderType}
                                candidateStatus={message.candidateStatus}
                                candidateProfile={candidateProfile}
                                from={message.from}
                                to={message.to}
                                theme="light"
                            />
                        </Stack>
                    );
                })}
            </MessagesConversationContainer>

            <Footer
                date={data?.lastUpdated}
                render={() => {
                    if (isSendingEmail) {
                        return <CircularProgress size={40} sx={{ color: "#0c6bcc" }} />;
                    }

                    return (
                        <ResponseEditor
                            info={
                                <div>
                                    Your authorized email ID ({userAuthEmailAddress}) was different from the original
                                    email ID ({fromEmail}) you sent from this.
                                    <br /> This means you cannot respond in the same thread.
                                </div>
                            }
                            show={fromEmail?.toLowerCase() === userAuthEmailAddress?.toLowerCase()}
                            title="Email"
                            subject={data?.messages[data?.messages.length - 1]?.subject}
                            onSubmit={(body) => {
                                if (data?.messages?.length) {
                                    sendEmail({
                                        candidate: candidateParamId,
                                        threadId: data?.messages[data?.messages.length - 1]?.threadId,
                                        subject: data?.messages[data?.messages.length - 1]?.subject,
                                        body: body,
                                    })
                                        .unwrap()
                                        .then((response: any) => {
                                            if (response?.data?.errors) {
                                                dispatch(setErrorNotification(response.data.errors[0].message));
                                                refetch();
                                            } else {
                                                dispatch(setSuccessNotification(response?.message));
                                            }
                                        })
                                        .catch(() => {
                                            // console.log(code,"myerrror");
                                            dispatch(setWarningNotification("error"));
                                            refetch();
                                        });
                                }
                            }}
                        />
                    );
                }}
            />
        </>
    );
}
