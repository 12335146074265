import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTour } from "@reactour/tour";
import dayjs from "dayjs";
import JSConfetti from "js-confetti";
import { debounce, get, isEmpty, isNil, isUndefined, filter as lodashFilter } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import type { DropResult, DroppableProvided } from "react-beautiful-dnd/index";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import ErrorLabel from "./atoms/ErrorLabel";
import BaseModal from "./components/BaseModal";
import FirstOccurence from "./components/FirstOccurence";
import {
    EmptyTemplatePlaceholder,
    HyperPersonalizationEditModal,
    PaneNavigation,
} from "./components/HyperPersonalizationPane";
import { OutreachErrorPlaceholder } from "./components/OutreachErrorPlaceholder";
import SampleProjectOutreachModal from "./components/SampleProjectOutreachModal";
import SelectProject from "./components/SelectProject";
import { SMS } from "./components/Sms";
import TourTriggerModal from "./components/tourTriggerModal";
import {
    emptyBodyMessages,
    emptyBodyPlaceholdersMessage,
    emptySubjectMessages,
    emptySubjectPlaceholdersMessage,
} from "./constants";
import countOccurrences from "./helpers/countOccurences";
import generateSteps from "./helpers/generateSteps";
import hyperPersonaliseFollowEmails from "./helpers/hyperPersonaliseFollowEmails";
import BCCEmail from "./molecules/BCCEmail";
import FileTile from "./molecules/FileTile";
import FileUpload from "./molecules/FileUpload";
import UnsubscribeEmailToggle from "./molecules/UnsubscribeEmailToggle";
import styles from "./triggerWorkflow.module.scss";

import { ButtonTextWithLoading } from "../../components/ButtonTextWithLoading";
import CustomButton from "../../components/CustomButton";
import { MailAuthConnect } from "../../components/MailAuthModal/MailAuthModal";
import { MaterialProvider } from "../../components/MaterialProvider";
import Modal from "../../components/Modal/Modal";
import Navbar from "../../components/Navbar/Navbar";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { WorkflowInfo, messages } from "../../components/SetupFlow/InfoButton";
import { HYPER_EMAIL2_BODY, HYPER_EMAIL3_BODY, HYPER_EMAIL_SUBJECT } from "../../constant/Constant";
import { usePrompt } from "../../hooks/usePrompt";
import { AppDispatch, RootState } from "../../store";
import { resetCookieValues } from "../../store/reducers/cookiesInput.slice";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import {
    removeFile,
    resetWorkflowUploadState,
    selectWorkflowUploadFiles,
} from "../../store/reducers/workflow-upload.slice";
import handleCleverTap from "../../utils/clevertap";
import { reverseTimeConversion, timeUnitConverter } from "../../utils/convertMin";
import { checkPlaceholdersFilled, removeHtmlTags } from "../../utils/helper";
import {
    multipleTouchpointsTemplate,
    newFollowUpTemplate,
    recruiterRecommendedTemplate,
    simpleTemplate,
} from "../../utils/workflowTemplates";
import Delay from "../editWorkflow/components/delay/Delay";
import Email from "../editWorkflow/components/email/Email";
import Reminder from "../editWorkflow/components/reminder/Reminder";
import style from "../editWorkflow/editWorkflow.module.scss";
import CustomMUIInput from "../project/components/CustomMUIInput";
import { addToWorkflow, resetProject } from "@/store/reducers/project/project.reducer";
import { AuthorizationWarningDescription } from "../workflow/components/AuthorizationWarningDescription";
import { MoreThan100CandidatesHelperMessage } from "../workflow/components/MoreThan100CandidatesHelperMessage";
import WorkflowTabs from "../workflow/components/WorkflowTabs";


import {
    authorizeEmail,
    authorizeOutlook,
    checkEmailForCreateUser,
    createUser,
    isSESIntegrated,
    selectUser,
} from "@/store/reducers/signin/Signin.reducer";
import {
    resetSwitches,
    selectEditMsgPopup,
    setEnableBulkCurrentFollowUpEmail,
} from "@/store/reducers/template/template.reducer";
import { ExtraFollowupValue } from "@/store/reducers/trigger-workflow/customTemplate.types";
import {
    addExtraFollowups,
    changeDelay,
    delayConn,
    delayFollowupEmail,
    delaySms,
    removeExtraFollowups,
    resetCustomTemplates,
    selectExtraInitialFollowups,
    selectNumberOfExtraFollowUp,
    setExtraFollowupBody,
} from "@/store/reducers/trigger-workflow/customTemplates.slice";
import {
    resetPersonalizedInputs,
    resetPersonalizedState,
    selectCandidate,
    selectEditTemplatesModalState,
    setCandidateIndex,
    setEditTemplatesModal,
    submitPersonalizedInputs,
} from "@/store/reducers/trigger-workflow/personalizedWorkflow.slice";
import {
    resetStandardWorkflow,
    selectStandardWorkflowComponents,
    updateStandardWorkflow,
} from "@/store/reducers/trigger-workflow/standardWorkflow.slice";
import {
    changeModalState,
    changeSenderEmail,
    changeSenderName,
    createAndTriggerWorkflow,
    createNewWorkflow,
    getWorkflowList,
    resetWorkflowState,
    selectHyperPersonalizeMode,
    selectModalStatus,
    selectProject,
    selectSaveWorkflowForLater,
    selectWorkflow,
    selectWorkflowData,
    selectWorkflowMode,
    setSingleWorkflowDataProperty,
} from "@/store/reducers/workflow/workflow.reducer";
import { IComponentList } from "@/store/reducers/workflow/workflow.types";

function SenderName() {
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        dispatch(changeSenderName(value));
    };

    useEffect(() => {
        return () => {
            dispatch(changeSenderName(""));
        };
    }, []);

    return (
        <Stack>
            <CustomMUIInput type="text" placeholder="Sender display name" onChange={handleChange} />
        </Stack>
    );
}

function SenderEmail() {
    const [text, setText] = useState("");
    const dispatch = useDispatch();
    const error = useSelector(selectWorkflowData)?.senderEmail?.error;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (error || error === "") {
            dispatch(changeSenderEmail(value));
        }
        setText(value);
    };

    return (
        <Stack>
            <CustomMUIInput
                type="email"
                placeholder="Sender Email"
                value={text}
                onChange={handleChange}
                onBlur={() => dispatch(changeSenderEmail(text))}
            />
            {error && <ErrorLabel message={error} />}
        </Stack>
    );
}

type Args = {
    variant: "SUBJECT" | "BODY";
    text: string;
    type: string;
};

function createPlaceholderErrorMessage({ variant, text, type }: Args) {
    return `${text} ${variant === "SUBJECT" ? emptySubjectPlaceholdersMessage[type] : emptyBodyPlaceholdersMessage[type]
        }`;
}

type validateWorkflowComponentsArgs = {
    componentList: IComponentList[];
    callback: (message: string) => void;
    ignoreCondition: boolean;
};

const getFilteredComponentList = (
    componentList: IComponentList[],
    extraFollowUpToggles: { [key: string]: boolean }
) => {
    const filteredComponentList = lodashFilter(componentList, (element, index) => {
        let flag = true;
        const extraDelay = extraFollowUpToggles[`FollowUpEmail-${index + 1}`];

        if (typeof extraDelay === "boolean") {
            if (!extraDelay) flag = false;
        } else {
            const extraFollowUp = extraFollowUpToggles[`FollowUpEmail-${index}`];
            if (typeof extraFollowUp === "boolean") {
                if (!extraFollowUp) flag = false;
            }
        }

        return flag && !isNil(element) && !isEmpty(element) && !isUndefined(element);
    });

    return filteredComponentList;
};

type CheckFirstComponentArgs = {
    componentList: IComponentList[];
    index: number;
    type: string;
};

// make a function that takes component and componentList
function CheckFirstComponent({ componentList, index, type: currentComponentType }: CheckFirstComponentArgs) {
    const enableAllNewFolllowUpEmails = useSelector((state: RootState) =>
        Object.entries(state.template).reduce((acc: any, [key, value]) => {
            if (key.startsWith("FollowUpEmail")) {
                acc[key] = value;
            }
            return acc;
        }, {})
    );
    // find all components of given type, find their indexes, sort it, take first index, if it matches the given component then it's the first component
    const filteredComponentList = getFilteredComponentList(componentList, enableAllNewFolllowUpEmails);
    const slicedArray = filteredComponentList.slice(0, index);

    const isFirstComponent = slicedArray.reduce((acc: boolean, curr: IComponentList, index: number) => {
        if (curr.type === currentComponentType) {
            acc = false;
        }

        return acc;
    }, true);

    return isFirstComponent;
}

const IsComponentsValid = ({ componentList, callback, ignoreCondition }: validateWorkflowComponentsArgs) => {
    const enableAllNewFolllowUpEmails = useSelector((state: RootState) =>
        Object.entries(state.template).reduce((acc: any, [key, value]) => {
            if (key.startsWith("FollowUpEmail")) {
                acc[key] = value;
            }
            return acc;
        }, {})
    );

    const filteredComponentList = getFilteredComponentList(componentList, enableAllNewFolllowUpEmails);

    if (!filteredComponentList.length) {
        callback("Kindly create a workflow to start the outreach");
        return false;
    }

    if (!ignoreCondition) {
        if (
            !filteredComponentList.some(
                (item) =>
                    item.type === "email" ||
                    item.type === "in-mail" ||
                    item.type === "connection-request" ||
                    item.type === "linked-in-msg" ||
                    item.type === "sms"
            )
        ) {
            callback(`Workflow cannot be triggered by time delay alone`);
            return false;
        }

        if (
            filteredComponentList.some((item) => {
                return (
                    item.subject === "" ||
                    removeHtmlTags(item.editorVal) === "" ||
                    !checkPlaceholdersFilled(item.subject)?.value ||
                    !checkPlaceholdersFilled(item.editorVal)?.value
                );
            })
        ) {
            const emptySubjectType = filteredComponentList.find(
                (item) => item.subject === "" && item.type !== "connection-request" && item.type !== "delay"
            )?.type;

            if (emptySubjectType) {
                callback(emptySubjectMessages[emptySubjectType]);
                return false;
            }

            const emptyBodyType = filteredComponentList.find((item) => {
                const withoutHTMLTags = removeHtmlTags(item.editorVal);
                return item.type !== "delay" && (!withoutHTMLTags || withoutHTMLTags === "");
            })?.type;

            if (emptyBodyType) {
                callback(emptyBodyMessages[emptyBodyType]);
                return false;
            }

            const placeholderNotFilledSubjectEle = filteredComponentList.find((item) => {
                return !checkPlaceholdersFilled(item.subject)?.value && item.type !== "delay";
            });

            const placeholderNotFilledSubject = checkPlaceholdersFilled(placeholderNotFilledSubjectEle?.subject);

            const placeholderNotFilledSubjectString = placeholderNotFilledSubject?.matches?.join(", ");

            if (placeholderNotFilledSubjectString && placeholderNotFilledSubjectEle?.type) {
                const msg = createPlaceholderErrorMessage({
                    variant: "SUBJECT",
                    text: placeholderNotFilledSubjectString,
                    type: placeholderNotFilledSubjectEle?.type,
                });
                callback(msg);
                return false;
            }

            const placeholderNotFilledBodyEle = filteredComponentList.find((item) => {
                return !checkPlaceholdersFilled(item.editorVal)?.value && item.type !== "delay";
            });

            const placeholderNotFilledBody = checkPlaceholdersFilled(placeholderNotFilledBodyEle?.editorVal);

            const placeholderNotFilledBodyString = placeholderNotFilledBody?.matches?.join(", ");

            if (
                placeholderNotFilledSubjectString &&
                placeholderNotFilledBodyEle?.type &&
                placeholderNotFilledBodyString
            ) {
                const msg = createPlaceholderErrorMessage({
                    variant: "BODY",
                    text: placeholderNotFilledBodyString,
                    type: placeholderNotFilledBodyEle?.type,
                });
                callback(msg);
                return false;
            }
        }
    }

    return true;
};

type CreditRewardModalProps = {
    open: boolean;
    onClose: () => void;
    workflowId: string;
    workflowCredits: number;
};

function CreditRewardModal({ open, onClose, workflowId, workflowCredits }: CreditRewardModalProps) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const jsConfetti = new JSConfetti();
    const user = useSelector(selectUser);
    const uuid = get(user, "uuid");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const isSubmitPasswordLoading = useSelector(checkIfLoading(createUser.type));

    const projectId = location.state?.projectId;

    useEffect(() => {
        jsConfetti.addConfetti();

        return () => jsConfetti.clearCanvas();
    }, []);

    const handleNavigate = () => {
        // const url = `/projects/${projectId}/statistics?workflow=${workflowId}`;
        const url = `/projects/${projectId}`;
        navigate(url, {
            replace: true,
            state: {
                fromWorkflowCreditsReward: true,
                workflowCreditReward: workflowCredits,
            },
        });
    };

    const onSubmitPassword = (e: any) => {
        e.preventDefault();
        dispatch(
            checkEmailForCreateUser({
                email,
                password,
                action: createUser.type,
            })
        );
        // handleNavigate();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle
                sx={{
                    color: "#747474",
                    fontSize: 24,
                    textAlign: "center",
                }}
            >
                Congratulations
            </DialogTitle>
            <DialogContent
                sx={{
                    color: "#747474",
                    textAlign: "center",
                }}
            >
                You have successfully reached out to candidates.
                {/* {!uuid && (
                    <Stack width="100%"> <form onSubmit={onSubmitPassword}>
                            <br />
                            <TextField
                                sx={{
                                    marginBottom: "15px",
                                    width: " -webkit-fill-available",
                                }}
                                size="small"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email"
                            />
                            <br />
                            <TextField
                                sx={{
                                    marginBottom: "15px",
                                    width: " -webkit-fill-available",
                                }}
                                size="small"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter password"
                            />
                            <br />
                            <Button
                                disabled={isSubmitPasswordLoading}
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </form>
                        <img
                            src={googleSignInBtn}
                            alt="signin-with-google"
                            // className={styles["googleSignin"]}
                            style={{
                                width: "200px",
                                alignSelf: "center",
                                marginTop: "15px",
                            }}
                            onClick={() =>
                                dispatch(googleLogin(CREATE_DASHBOARD_USER))
                            }
                        />
                    </Stack>
                )} */}
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: "center",
                }}
            >
                {uuid && (
                    <Button
                        variant="contained"
                        sx={{
                            background: "linear-gradient(92.1deg, #E44E4E -2.91%, #FFE600 101%);",
                            border: "none",
                        }}
                        onClick={handleNavigate}
                    >
                        Thank You
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

function ToneOfVoiceAndWordCountLabel() {
    // const toneOfVoice = useSelector(selectToneOfVoice);
    // const characterCount = useSelector(selectCharacterCount);
    return (
        <Stack spacing={1} p={1}>
            {/* <Typography variant="body2">
                Tone of voice:{" "}
                <ValueLabel>{toneOfVoice ? toneOfVoice : "-"}</ValueLabel>
            </Typography>
            <Typography variant="body2">
                Word Count:{" "}
                <ValueLabel>{characterCount ? characterCount : "-"}</ValueLabel>
            </Typography> */}
            <WorkflowInfo messages={messages} />
        </Stack>
    );
}

export default function TriggerWorkflow() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const filters = location?.state?.filters;
    const isEveryCandidateSelected = location?.state?.isAllProjectCandidatesSelected;
    const searchQuery = location?.state?.searchQuery;
    const showByPersonalEmails = location?.state?.showByPersonalEmails;
    const { currentStep } = useTour();
    const noOfExtraFollowups = useSelector(selectNumberOfExtraFollowUp);
    const extraInitialFollowups = useSelector(selectExtraInitialFollowups);

    const enableAllNewFolllowUpEmails = useSelector((state: RootState) =>
        Object.entries(state.template).reduce((acc: any, [key, value]) => {
            if (key.startsWith("FollowUpEmail")) {
                acc[key] = value;
            }
            return acc;
        }, {})
    );

    const user = useSelector(selectUser);
    const selectedWorkflow = useSelector(selectWorkflow);
    const workflowMode = useSelector(selectWorkflowMode);
    const modalStatus = useSelector(selectModalStatus);
    const selectedProject = useSelector(selectProject);
    const saveWorkflowForLater = useSelector(selectSaveWorkflowForLater);
    const workflowData = useSelector(selectWorkflowData);
    const showEditTemplatesModal = useSelector(selectEditTemplatesModalState);
    const personalizedCandidate = useSelector(selectCandidate);
    const editPopupMsg = useSelector(selectEditMsgPopup);
    const files = useSelector(selectWorkflowUploadFiles);
    const isSESIntegratedEnabled = useSelector(isSESIntegrated);
    const _delaySms = useSelector(delaySms);
    const _delayConn = useSelector(delayConn);
    const _delayFollowupEmail = useSelector(delayFollowupEmail);
    const isTemplateLoading = useSelector(checkIfLoading(submitPersonalizedInputs.type));
    const isIndividualTemplatesLoading = useSelector(
        checkIfLoading(`${setCandidateIndex.type}_${personalizedCandidate?.id}`)
    );
    const isLoading = isTemplateLoading || isIndividualTemplatesLoading;

    const isCreateWorkflowLoading = useSelector(checkIfLoading(createNewWorkflow.type));
    const isAddToWorkflowLoading = useSelector(checkIfLoading(addToWorkflow.type));
    const isCreateAndTriggerWorkflowLoading = useSelector(checkIfLoading(createAndTriggerWorkflow.type));
    const isCreateLoading = isCreateWorkflowLoading || isCreateAndTriggerWorkflowLoading;

    const [dragDropOverlay, setDragDropOverlay] = useState(false);
    const [overlayWorkflow, setOverlayWorkflow] = useState<boolean>(true);
    const [dirty, setDirty] = useState<boolean>(false);
    const [componentList, setComponentList] = useState<IComponentList[]>([]);
    const [addConfirmWorkflowModal, setAddConfirmWorkflowModal] = useState({
        workflowId: "",
        open: false,
    });
    const [creditRewardModal, setCreditRewardModal] = useState({
        workflowId: "",
        workflowCredits: 0,
        open: false,
    });
    const [openSampleOutreachModal, setOpenSampleOutreachModal] = useState(false);

    const userConnectionReqStats = user.workflowStats?.connReq;
    const userInmailStats = user.workflowStats?.inMail;
    const selectedCandidates = location.state?.candidates;
    const isSenderEmailError = Boolean(useSelector(selectWorkflowData)?.senderEmail?.error);

    const isCandidatesSelected = Array.isArray(selectedCandidates) && selectedCandidates.length > 0;

    const candidate = useSelector(selectCandidate);
    const isTemplatesUnAvailable = candidate?.fetchTemplateStatus === "ERROR";
    const isHyperPersonalizedWorkflow = useSelector(selectHyperPersonalizeMode);

    const handleNextModal = () => dispatch(changeModalState("LINEAR"));
    const closeModal = () => dispatch(changeModalState("IDLE"));

    usePrompt("There are unsaved changes are you sure you want to proceed?", dirty);

    const isEmailComponentDisabled =
        countOccurrences({
            componentList,
            type: "email",
        }) === 3 && workflowMode === "PERSONALIZED_WORKFLOW";

    const showAddConfirmWorkflowModal = (workflowId: string) => {
        setAddConfirmWorkflowModal({
            open: true,
            workflowId,
        });
    };

    const closeAddConfirmWorkflowModal = () => {
        navigate(`/projects/${location.state?.projectId}`, { state: {} });
    };

    const showCreditRewardModal = (workflowId: string, workflowCredits: number) => {
        showAddConfirmWorkflowModal(workflowId);
        setTimeout(() => {
            navigate(`/projects/${location.state?.projectId}`, { state: {} });
        }, 3000);
    };

    const closeCreditRewardModal = () => {
        setCreditRewardModal({
            workflowId: "",
            workflowCredits: 0,
            open: false,
        });
    };

    const showSelectWkModal = () => {
        const projectId = location.state?.projectId;
        if (projectId === "9999") {
            setOpenSampleOutreachModal(true);
            return;
        }

        // const isValid = isComponentsValid({
        //     callback: (message: string) => {
        //         dispatch(setErrorNotification(message));
        //     },
        //     componentList,
        //     ignoreCondition: workflowMode === "REGULAR_WORKFLOW",
        // });

        // if (!isValid) return;

        if (projectId) {
            handleCleverTap("Start outreach", {
                candidates: location?.state?.candidates?.toString(),
            });
        }

        handleNextModal();
    };

    useEffect(() => {
        if (isCandidatesSelected) {
            dispatch(changeModalState("PERSONALIZED_INPUTS"));
            setOverlayWorkflow(false);
        }
    }, [isCandidatesSelected]);

    useEffect(() => {
        if (!componentList.length && dragDropOverlay) {
            setDragDropOverlay(false);
        }
    }, [componentList.length, dragDropOverlay]);

    useEffect(() => {
        dispatch(getWorkflowList());
        // dispatch(getTemplateList());
        setComponentList(recruiterRecommendedTemplate as IComponentList[]);

        return () => {
            dispatch(resetCustomTemplates());
            dispatch(resetWorkflowState());
            dispatch(resetPersonalizedState());
            dispatch(resetPersonalizedInputs());
            dispatch(resetWorkflowUploadState());
            dispatch(resetStandardWorkflow());
            dispatch(resetCookieValues());
            dispatch(resetSwitches());
            dispatch(resetProject());
        };
    }, []);

    useEffect(() => {
        if (currentStep === 5) setOverlayWorkflow(false);
    }, [currentStep]);

    const previousComponentList = useSelector(selectStandardWorkflowComponents);

    useEffect(() => {
        if (extraInitialFollowups.length) {
            const mappedExtraFollowups = extraInitialFollowups.reduce(
                (acc: { newIndex: number; steps: ExtraFollowupValue[] }, crr) => {
                    acc.steps.push({
                        body: crr.body,
                        index: acc.newIndex,
                        isEnabled: crr.status,
                    });
                    acc.newIndex += 2;
                    return acc;
                },
                { newIndex: 8, steps: [] }
            );

            handleAddSteps(extraInitialFollowups.length, mappedExtraFollowups.steps);
        }
    }, [extraInitialFollowups.length]);

    useEffect(() => {
        if (workflowMode === "REGULAR_WORKFLOW") {
            setComponentList(previousComponentList);
            handleSubjectChange(6, previousComponentList?.[0]?.subject || "");

            if (noOfExtraFollowups) {
                handleAddSteps(noOfExtraFollowups);
            }
        } else {
            handleSubjectChange(0, personalizedCandidate?.email?.subject || "");
            handleEditorValueChange(0, personalizedCandidate?.email?.body?.replaceAll("\n", "<br>") || "");
            handleEditorValueChange(2, personalizedCandidate?.connectionReq?.body?.replaceAll("\n", "<br>") || "");
            handleEditorValueChange(4, personalizedCandidate?.sms?.body?.replaceAll("\n", "<br>") || "");
            handleSubjectChange(6, "Same as above email");
            handleEditorValueChange(6, personalizedCandidate?.followupEmail?.body?.replaceAll("\n", "<br>") || "");
        }

        // @ts-ignore
        setComponentList((prevState) => {
            const newStates = prevState.map((item, index) => {
                if (index === 1) {
                    const connectionDelay = timeUnitConverter(_delayConn || 0);
                    return {
                        ...item,
                        hours: connectionDelay.hours,
                        mins: connectionDelay.minutes,
                        weeks: connectionDelay.weeks,
                        days: connectionDelay.days,
                    };
                } else if (index === 3) {
                    const smsDelay = timeUnitConverter(_delaySms || 0);
                    return {
                        ...item,
                        hours: smsDelay.hours,
                        mins: smsDelay.minutes,
                        weeks: smsDelay.weeks,
                        days: smsDelay.days,
                    };
                } else if (index === 5) {
                    const followupDelay = timeUnitConverter(_delayFollowupEmail || 0);
                    return {
                        ...item,
                        hours: followupDelay.hours,
                        mins: followupDelay.minutes,
                        weeks: followupDelay.weeks,
                        days: followupDelay.days,
                    };
                }

                return { ...item };
            });
            return newStates;
        });
    }, [
        personalizedCandidate,
        workflowMode,
        _delaySms,
        _delayConn,
        _delayFollowupEmail,
        extraInitialFollowups?.length,
    ]);

    const handleTimeChange = useCallback(
        (tarInd: number, key: string, newValue: string) => {
            const updatedComponentList = componentList.map((component: any, index: number) => {
                if (index === tarInd) {
                    return {
                        ...component,
                        [key]: Number(newValue) < 0 ? "0" : newValue,
                    };
                }
                return component;
            });
            const newDelay = reverseTimeConversion({
                days: updatedComponentList[tarInd].days || "0",
                hours: updatedComponentList[tarInd].hours || "0",
                minutes: updatedComponentList[tarInd].mins || "0",
                weeks: updatedComponentList[tarInd].weeks || "0",
            });

            dispatch(
                changeDelay({
                    index: tarInd,
                    delay: newDelay,
                })
            );
            setComponentList(updatedComponentList);
        },
        [componentList]
    );

    const handleSubjectChange = useCallback(
        (tarInd: number, newValue: string) => {
            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr[tarInd] = { ...tmpArr[tarInd], subject: newValue };
                // if (workflowMode === "REGULAR_WORKFLOW") {
                //     dispatch(updateStandardWorkflow(tmpArr));
                // }
                return tmpArr;
            });
        },
        [componentList, workflowMode]
    );

    const handleBlur = useCallback(() => {
        if (workflowMode === "REGULAR_WORKFLOW") {
            dispatch(updateStandardWorkflow(componentList));
        }
    }, [componentList, workflowMode]);

    const handleSetExtraFollowupsValue = useCallback(
        debounce((index: number, value: string) => {
            dispatch(setExtraFollowupBody({ body: value, index }));
        }, 1500),
        []
    );

    const handleEditorValueChange = useCallback(
        (tarInd: number, newValue: string) => {
            if (tarInd > 7) {
                handleSetExtraFollowupsValue(tarInd, newValue);
            }

            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr[tarInd] = { ...tmpArr[tarInd], editorVal: newValue };
                // if (workflowMode === "REGULAR_WORKFLOW") {
                //     dispatch(updateStandardWorkflow(tmpArr));
                // }
                return tmpArr;
            });
        },
        [componentList, workflowMode]
    );

    const handleCollapseChange = useCallback(
        (tarInd: number) => {
            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr[tarInd] = {
                    ...tmpArr[tarInd],
                    isOpen: !tmpArr[tarInd].isOpen,
                };
                if (workflowMode === "REGULAR_WORKFLOW") {
                    dispatch(updateStandardWorkflow(tmpArr));
                }
                return tmpArr;
            });
        },
        [componentList, workflowMode]
    );

    const handleRemove = useCallback(
        (tarInd: number) => {
            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr.splice(tarInd, 1);

                if (workflowMode === "PERSONALIZED_WORKFLOW") {
                    const updatedArr = hyperPersonaliseFollowEmails({
                        componentList: tmpArr,
                    });
                    return updatedArr;
                }

                if (workflowMode === "REGULAR_WORKFLOW") {
                    dispatch(updateStandardWorkflow(tmpArr));
                }

                return tmpArr;
            });
        },
        [componentList, workflowMode]
    );

    const handleRemoveStep = useCallback(
        (stepIndex: number) => {
            dispatch(removeExtraFollowups({ index: stepIndex }));
            setComponentList((prevComponentList) => {
                const updatedList = prevComponentList.filter((_, index) => {
                    if (index === stepIndex || index === stepIndex - 1) {
                        return false;
                    }
                    return true;
                });
                return updatedList;
            });
        },
        [componentList, workflowMode]
    );

    const handleDuplicate = useCallback(
        (tarInd: number) => {
            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr.splice(tarInd, 0, { ...tmpArr[tarInd] });

                if (workflowMode === "PERSONALIZED_WORKFLOW") {
                    const updatedArr = hyperPersonaliseFollowEmails({
                        componentList: tmpArr,
                    });
                    return updatedArr;
                }

                if (workflowMode === "REGULAR_WORKFLOW") {
                    dispatch(updateStandardWorkflow(tmpArr));
                }

                return tmpArr;
            });
        },
        [componentList, workflowMode]
    );

    const handleAddSteps = useCallback(
        (steps: number = 1, values?: ExtraFollowupValue[]) => {
            if (values?.length) {
                dispatch(
                    setEnableBulkCurrentFollowUpEmail(
                        values.map(({ index, isEnabled }) => ({
                            index,
                            value: isEnabled,
                        }))
                    )
                );
            }

            setComponentList((prevState) => {
                dispatch(
                    addExtraFollowups(
                        values?.length
                            ? values
                            : [
                                {
                                    body: "",
                                    index: prevState.length + 1,
                                    isEnabled: true,
                                },
                            ]
                    )
                );
                const subject = prevState.find((state, index) => state.type === "email" && index !== 0)?.subject;
                const newTemplates = values?.length
                    ? [
                        ...values
                            .map((i) => {
                                const { body } = i;

                                return newFollowUpTemplate(subject, body);
                            })
                            .flat(),
                    ]
                    : [...Array(steps)]?.reduce((acc) => acc.concat(newFollowUpTemplate(subject)), []);

                return [...prevState, ...newTemplates];
            });
        },
        [componentList, workflowMode, noOfExtraFollowups]
    );

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) return;

            const { source, destination } = result;
            const isSrcComponent = source.droppableId === "col-1";
            const isDstComponent = destination.droppableId === "col-2";
            if (!isSrcComponent || !isDstComponent) return;

            if (destination.index !== source.index) setDirty(true);

            const tmpList = [...componentList];
            const fromIndex = source.index;
            const toIndex = destination.index;
            tmpList.splice(fromIndex, 1);
            tmpList.splice(toIndex, 0, componentList[fromIndex]);
            // tmpList[toIndex] = componentList[source.index - 1];
            const emailOccurences = countOccurrences({
                componentList: tmpList,
                type: "email",
            });
            if (workflowMode === "PERSONALIZED_WORKFLOW") {
                tmpList[destination.index].subject =
                    emailOccurences === 2 || emailOccurences === 3 ? HYPER_EMAIL_SUBJECT : "";
                tmpList[destination.index].editorVal =
                    emailOccurences === 2 ? HYPER_EMAIL2_BODY : emailOccurences === 3 ? HYPER_EMAIL3_BODY : "";
            }
            if (workflowMode === "REGULAR_WORKFLOW") {
                dispatch(updateStandardWorkflow(tmpList));
            }
            setComponentList(tmpList);
        },
        [componentList, workflowMode]
    );

    const handleDefaultWorkflow = (type: "SIMPLE" | "RECRUITER" | "MUTLIPLE") => {
        handleCleverTap("Select sample workflow", {
            type: type,
        });

        setOverlayWorkflow(false);
        if (type === "SIMPLE") setComponentList(simpleTemplate as IComponentList[]);
        else if (type === "RECRUITER") setComponentList(recruiterRecommendedTemplate as IComponentList[]);
        else setComponentList(multipleTouchpointsTemplate as IComponentList[]);
    };

    const handleOutReachStart = () => {
        const filteredComponentList = getFilteredComponentList(componentList, enableAllNewFolllowUpEmails);

        if (!user.emailAuthorized && filteredComponentList.some((item) => item.type === "email")) {
            dispatch(setErrorNotification("Please authorize your email first"));
            return;
        }

        if (modalStatus === "OUTREACH_MODAL" && !selectedProject?.value) {
            dispatch(setErrorNotification("Please select project"));
            return;
        }

        if (!selectedWorkflow?._id) {
            return;
        }

        setDirty(false);

        const project = selectedProject?.value ? Number(selectedProject.value) : Number(location?.state?.projectId);

        const sendOutReachToAllCandidates = selectedProject?.value ? true : false;

        // dispatch(updateDateInWorkflow());

        const addToWorkflowPayload = {
            workflowTemplateId: selectedWorkflow?._id,
            scheduledFor: dayjs(workflowData.date).toISOString(),
            bcc: workflowData.bccEmail.value,
            unsubscribeEmail: workflowData.unsubscribeEmail,
            project,
            candidate: selectedCandidates,
            onSuccess: ({ workflowId, workflowCredits }: { workflowId: string; workflowCredits: number }) =>
                showCreditRewardModal(workflowId, workflowCredits),
            action: addToWorkflow.type,
        };

        if (location.state?.projectId) {
            handleCleverTap("Confirm outreach", {
                name: selectedWorkflow?.name || workflowData.name,
                workflowTemplateId: selectedWorkflow?._id,
                scheduledFor: dayjs(workflowData.date).toISOString(),
                project,
                candidate: location?.state?.candidates?.toString(),
                bcc: workflowData.bccEmail.value,
                unsubscribeEmail: workflowData.unsubscribeEmail,
            });
        }

        dispatch(
            addToWorkflow({
                ...addToWorkflowPayload,
                selectAll: sendOutReachToAllCandidates ? true : false,
            })
        );
    };

    const handleWorkflowCreate = () => {
        // if candidates are selected then directly call trigger modal
        const filteredComponentList = getFilteredComponentList(componentList, enableAllNewFolllowUpEmails);

        // replace subject of follow-up email with hyperpersonalised email subject
        // if (isHyperPersonalizedWorkflow) {
        //     filteredComponentList[6].subject = filteredComponentList[0].subject;
        // }

        const steps = generateSteps(filteredComponentList);
        const projectId = location?.state?.projectId;

        // if (!(selectedWorkflow?.name || workflowData.name)) {
        //     dispatch(setErrorNotification("Please enter workflow name"));
        //     return;
        // }

        const workflowTempData = {
            name: workflowData.name,
            steps: steps,
            completed: saveWorkflowForLater,
        };

        setDirty(false);
        // dispatch(updateDateInWorkflow());

        if (isCandidatesSelected) {
            if (selectedWorkflow && selectedWorkflow.name) {
                handleOutReachStart();
                return;
            }

            if (location.state?.projectId) {
                handleCleverTap("Confirm outreach", {
                    name: selectedWorkflow?.name || workflowData.name,
                    workflowTemplateId: selectedWorkflow?._id,
                    scheduledFor: dayjs(workflowData.date).toISOString(),
                    project: projectId,
                    candidate: location?.state?.candidates?.toString(),
                });
            }
            dispatch(
                createAndTriggerWorkflow({
                    createWorkflowPayload: workflowTempData,
                    addWorkflowPayload: {
                        scheduledFor: dayjs(workflowData.date).toISOString(),
                        project: projectId,
                        candidate: selectedCandidates,
                        bcc: workflowData.bccEmail.value,
                        unsubscribeEmail: workflowData.unsubscribeEmail,
                        onSuccess: ({ workflowId, workflowCredits }) =>
                            showCreditRewardModal(workflowId, workflowCredits),
                    },
                    isAllCandidatesSelected: isEveryCandidateSelected,
                    action: createAndTriggerWorkflow.type,
                    filters,
                    searchQuery,
                    showByPersonalEmails,
                })
            );
            return;
        }

        if (selectedWorkflow?._id) {
            dispatch(
                createNewWorkflow({
                    ...workflowTempData,
                    _id: selectedWorkflow._id,
                    action: createNewWorkflow.type,
                })
            );
            return;
        }

        dispatch(
            createNewWorkflow({
                ...workflowTempData,
                action: createNewWorkflow.type,
            })
        );

        // workflow is saved, don't need to give prompt
    };

    const onClickCreateNewWorkflow = () => {
        handleCleverTap("Create new workflow", {});
        // clear personalized state
        dispatch(resetPersonalizedState());
        // if (isCandidatesSelected) {
        //     dispatch(changeModalState("PERSONALIZED_MODAL"));
        // }
        setOverlayWorkflow(false);
    };

    const handleModalOpen = () => dispatch(setEditTemplatesModal(true));

    const handleEmailClick = (type: "gmail" | "outlook") => {
        if (type === "gmail") dispatch(authorizeEmail());
        else dispatch(authorizeOutlook());
    };

    const showDragMessage = componentList.length === 0 && !overlayWorkflow && !dragDropOverlay;

    const title = isCandidatesSelected
        ? "Start Outreach"
        : selectedWorkflow?.name
            ? "Edit Workflow"
            : "Create Workflow";

    const createBtnText = isCandidatesSelected ? "Start" : selectedWorkflow?.name ? "Update" : "Create";

    return (
        <div className={style["wk"]}>
            <div className={style["wk__container"]}>
                <Navbar />
                <OutreachErrorPlaceholder />
                <div className={style["wk__header"]}>
                    <Stack spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            {/* <WorkflowTitle /> */}
                            <WorkflowTabs />
                            <Tooltip title={<ToneOfVoiceAndWordCountLabel />} placement="right">
                                <IconButton>
                                    <InfoIcon fontSize="medium" style={{ color: "rgba(0, 0, 0, 0.3)" }} />
                                </IconButton>
                            </Tooltip>
                            <FileUpload />
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            {files.map(({ id, name, file }) => {
                                return (
                                    <FileTile
                                        key={id}
                                        name={name}
                                        link={file}
                                        handleRemove={() => dispatch(removeFile(id))}
                                    />
                                );
                            })}
                        </Stack>
                    </Stack>
                    {isHyperPersonalizedWorkflow && (
                        <Stack alignItems="flex-end" spacing={0.5}>
                            <PaneNavigation variant="DEFAULT" />
                            <Button
                                variant="text"
                                startIcon={<EditIcon />}
                                onClick={handleModalOpen}
                                sx={{
                                    border: 0,
                                    outline: "none",
                                    backgroundColor: "transparent",
                                    boxShadow: "none",
                                    color: editPopupMsg ? "red" : "inherit",
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                    "&:focus": {
                                        boxShadow: "none",
                                    },
                                }}
                            >
                                Edit personalized messages
                            </Button>
                        </Stack>
                    )}
                    {/* <div className={style["wk__headerLeft"]}>
                        <p className={style["wk__heading"]}>
                            {selectedWorkflow?.name}
                        </p>
                        {selectedWorkflow?.createdAt && (
                            <p className={style["wk__createDate"]}>
                                {new Date(
                                    selectedWorkflow.createdAt
                                ).toLocaleDateString()}
                            </p>
                        )}
                    </div> */}
                    {/* <div className={style["wk__headerRight"]}>
                        <Tooltip
                            title={<ToneOfVoiceAndWordCountLabel />}
                            placement="left"
                        >
                            <InfoIcon
                                fontSize="medium"
                                style={{ color: "rgba(0, 0, 0, 0.3)" }}
                            />
                        </Tooltip>
                    </div> */}
                </div>
                <DragDropContext
                    onDragEnd={(res: any) => {
                        onDragEnd(res);
                        setDragDropOverlay(false);
                    }}
                    onDragStart={() => setDragDropOverlay(true)}
                >
                    <div className={style["wk__body"]}>
                        {/* <div
                            className={style["wk__overlay"]}
                            style={{
                                display: overlayWorkflow ? "block" : "none",
                            }}
                        >
                            <div className={style["wk__overlayBody"]}>
                                <p className={style["wk__overlayTitle"]}>
                                    Select from the best performing workflows,
                                    or create your own workflow{" "}
                                </p>
                                <div className={style["wk__overlayWrapper"]}>
                                    <div
                                        className={
                                            style["wk__overlayWrapperCard"]
                                        }
                                        onClick={() =>
                                            handleDefaultWorkflow("SIMPLE")
                                        }
                                    >
                                        <p>
                                            Simple
                                            <br />
                                            workflow
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            style["wk__overlayWrapperCard"]
                                        }
                                        onClick={() =>
                                            handleDefaultWorkflow("RECRUITER")
                                        }
                                    >
                                        <p>
                                            Recruiter
                                            <br />
                                            recommended
                                            <br />
                                            workflow
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            style["wk__overlayWrapperCard"]
                                        }
                                        onClick={() =>
                                            handleDefaultWorkflow("MUTLIPLE")
                                        }
                                    >
                                        <p>
                                            Multiple touchpoint
                                            <br />
                                            workflow
                                        </p>
                                    </div>
                                </div>
                                <Button
                                    variant="contained"
                                    endIcon={<AddIcon />}
                                    sx={{ marginTop: "3rem" }}
                                    onClick={onClickCreateNewWorkflow}
                                >
                                    Create a new workflow
                                </Button>
                            </div>
                        </div> */}
                        {/* <div className={style["wk__sidebar"]} id="wkSidebar">
                            <Droppable droppableId="col-1">
                                {(dropProvider: DroppableProvided) => (
                                    <div
                                        className={style["wk__sidebarScroll"]}
                                        ref={dropProvider.innerRef}
                                        {...dropProvider.droppableProps}
                                    >
                                        <div
                                            className={`${
                                                selectedWorkflow &&
                                                location.state?.candidates
                                                    ? style["wk__item-inactive"]
                                                    : ""
                                            } ${style["wk__item"]}`}
                                        >
                                            <p
                                                className={
                                                    style["wk__itemHead"]
                                                }
                                            >
                                                Outreach
                                            </p>
                                            <Draggable
                                                key={"email"}
                                                draggableId="email"
                                                index={1}
                                                isDragDisabled={
                                                    isEmailComponentDisabled
                                                }
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={`${
                                                            isEmailComponentDisabled
                                                                ? style[
                                                                      "wk__itemLink-disabled"
                                                                  ]
                                                                : style[
                                                                      "wk__itemLink"
                                                                  ]
                                                        }
                                                    `}
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Email
                                                    </p>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                draggableId="rem"
                                                index={3}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Call reminder
                                                    </p>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                key={"connection-request"}
                                                draggableId="connection-request"
                                                index={5}
                                                isDragDisabled={isDisabledComponent(
                                                    "connection-request"
                                                )}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <Tooltip
                                                        title={
                                                            isDisabledComponent(
                                                                "connection-request"
                                                            )
                                                                ? "Connection request can only be sent once"
                                                                : null
                                                        }
                                                    >
                                                        <p
                                                            className={`${
                                                                isDisabledComponent(
                                                                    "connection-request"
                                                                )
                                                                    ? style[
                                                                          "wk__itemLink-disabled"
                                                                      ]
                                                                    : style[
                                                                          "wk__itemLink"
                                                                      ]
                                                            }
                                                    `}
                                                            ref={
                                                                dragProvider.innerRef
                                                            }
                                                            {...dragProvider.dragHandleProps}
                                                            {...dragProvider.draggableProps}
                                                        >
                                                            LinkedIn Connection
                                                            Req.
                                                        </p>
                                                    </Tooltip>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                key={"in-mail"}
                                                draggableId="in-mail"
                                                index={3}
                                                isDragDisabled={isDisabledComponent(
                                                    "in-mail"
                                                )}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <Tooltip
                                                        title={
                                                            isDisabledComponent(
                                                                "in-mail"
                                                            )
                                                                ? "Inmail can only be sent once"
                                                                : null
                                                        }
                                                    >
                                                        <p
                                                            className={`${
                                                                isDisabledComponent(
                                                                    "in-mail"
                                                                )
                                                                    ? style[
                                                                          "wk__itemLink-disabled"
                                                                      ]
                                                                    : style[
                                                                          "wk__itemLink"
                                                                      ]
                                                            }
                                                    `}
                                                            ref={
                                                                dragProvider.innerRef
                                                            }
                                                            {...dragProvider.dragHandleProps}
                                                            {...dragProvider.draggableProps}
                                                        >
                                                            LinkedIn Inmail
                                                        </p>
                                                    </Tooltip>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                key={"delay"}
                                                draggableId="delay"
                                                index={2}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Time Delay
                                                    </p>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                key={"linked-in-msg"}
                                                draggableId="linked-in-msg"
                                                index={4}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        LinkedIn Message
                                                    </p>
                                                )}
                                            </Draggable>
                                        </div>
                                        <div className={style["wk__item"]}>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                mb={1}
                                            >
                                                <p
                                                    className={
                                                        style["wk__itemHead"]
                                                    }
                                                >
                                                    Saved Workflows
                                                </p>
                                                <Tooltip
                                                    title="Create new workflow"
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setSelectedWorkflow(
                                                                null
                                                            )
                                                        }
                                                        disabled={
                                                            componentList.length ===
                                                            0
                                                        }
                                                    >
                                                        <AddIcon
                                                            fontSize="small"
                                                            color={
                                                                componentList.length ===
                                                                0
                                                                    ? "disabled"
                                                                    : "primary"
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                            {workflowList.map((workflow) => (
                                                <p
                                                    className={`${
                                                        selectedWorkflow &&
                                                        selectedWorkflow._id ===
                                                            workflow._id
                                                            ? style[
                                                                  "wk__templateLink-active"
                                                              ]
                                                            : ""
                                                    } ${
                                                        style[
                                                            "wk__templateLink"
                                                        ]
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedWorkflow(
                                                            workflow
                                                        );
                                                    }}
                                                >
                                                    {workflow.name}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </div> */}
                        {isTemplatesUnAvailable && !isLoading ? (
                            <EmptyTemplatePlaceholder />
                        ) : (
                            <Droppable droppableId="col-2">
                                {(dropProvider: DroppableProvided) => (
                                    <div
                                        className={style["wk__main"]}
                                        style={{
                                            maxHeight: user.emailAuthorized
                                                ? "calc(100vh - 252px)"
                                                : "calc(100vh - 272px)",
                                        }}
                                        ref={dropProvider.innerRef}
                                        {...dropProvider.droppableProps}
                                    >
                                        <div className={style["wk__legendBox"]}>
                                            <div className={style["wk__legendWrapper"]}>
                                                <div
                                                    className={style["wk__flowWrapper"]}
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    {/* <SetupFlow /> */}
                                                    <div className={style["wk__mainBox"]}>
                                                        {componentList.map((val, i) => {
                                                            const isFirstComponent = false;
                                                            const commonProps = {
                                                                index: i,
                                                                key: i,
                                                                isOpen: val.isOpen,
                                                                type: val.type,
                                                                isLoading: isLoading,
                                                                handleRemove: handleRemove,
                                                                handleDuplicate: handleDuplicate,
                                                            };

                                                            const renderFirstOccuranceComp = () => (
                                                                <FirstOccurence
                                                                    {...commonProps}
                                                                    toggleCollapse={handleCollapseChange}
                                                                    cloneDisabled={isEmailComponentDisabled}
                                                                />
                                                            );

                                                            const renderEmailComp = () => (
                                                                <>
                                                                    {!isFirstComponent ? (
                                                                        <Email
                                                                            {...commonProps}
                                                                            handleRemoveStep={handleRemoveStep}
                                                                            handleBlurChange={handleBlur}
                                                                            subject={val.subject}
                                                                            editorVal={val.editorVal}
                                                                            setSubject={handleSubjectChange}
                                                                            setEditorVal={handleEditorValueChange}
                                                                            toggleCollapse={handleCollapseChange}
                                                                            cloneDisabled={isEmailComponentDisabled}
                                                                        />
                                                                    ) : (
                                                                        renderFirstOccuranceComp()
                                                                    )}
                                                                </>
                                                            );

                                                            const renderRamainderComp = () => (
                                                                <Reminder
                                                                    {...commonProps}
                                                                    handleBlurChange={handleBlur}
                                                                    editorVal={val.editorVal}
                                                                    setEditorVal={handleEditorValueChange}
                                                                    toggleCollapse={handleCollapseChange}
                                                                />
                                                            );

                                                            const renderConnectionReqComp = () => (
                                                                <>
                                                                    {!isFirstComponent
                                                                        ? renderRamainderComp()
                                                                        : renderFirstOccuranceComp()}
                                                                </>
                                                            );

                                                            const renderDelay = () => (
                                                                <Delay
                                                                    {...commonProps}
                                                                    toggleCollapse={handleCollapseChange}
                                                                    hours={val.hours}
                                                                    mins={val.mins}
                                                                    days={val.days}
                                                                    weeks={val.weeks}
                                                                    handleTimeChange={handleTimeChange}
                                                                />
                                                            );

                                                            const renderSMS = () => (
                                                                <SMS
                                                                    {...commonProps}
                                                                    handleBlurChange={handleBlur}
                                                                    editorVal={val.editorVal}
                                                                    setEditorVal={handleEditorValueChange}
                                                                    toggleCollapse={handleCollapseChange}
                                                                />
                                                            );

                                                            switch (val?.type) {
                                                                case "email":
                                                                    return renderEmailComp();
                                                                case "rem":
                                                                    return renderRamainderComp();
                                                                case "delay":
                                                                    return renderDelay();
                                                                case "linked-in-msg":
                                                                    return renderRamainderComp();
                                                                case "connection-request":
                                                                    return renderConnectionReqComp();
                                                                case "in-mail":
                                                                    return renderEmailComp();
                                                                case "sms":
                                                                    return renderSMS();
                                                                default:
                                                                    return null;
                                                            }
                                                        })}
                                                        <div className={styles["legendBox"]}>
                                                            <span
                                                                className={styles["legend"]}
                                                                onClick={() => handleAddSteps()}
                                                            >
                                                                +
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        )}
                    </div>
                </DragDropContext>
                <div style={{ position: "relative", alignSelf: "flex-end" }}>
                    <Button
                        variant="contained"
                        color={location.state?.candidates ? "success" : "primary"}
                        onClick={showSelectWkModal}
                        disabled={isLoading || overlayWorkflow}
                        sx={{
                            padding: "6px 36px",
                            border: "none",
                            width: "fit-content",
                        }}
                    >
                        {selectedCandidates ? "Schedule Outreach" : "Save"}
                    </Button>
                </div>
                <Modal
                    open={addConfirmWorkflowModal.open}
                    onClose={closeAddConfirmWorkflowModal}
                    title={""}
                    width={"fit-content"}
                >
                    <MaterialProvider>
                        <svg
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={style["wk__greenTick"]}
                        >
                            <g clipPath="url(#clip0_1728_22026)">
                                <path
                                    d="M11.5 0C5.15865 0 0 5.15865 0 11.5C0 17.8414 5.15865 23 11.5 23C17.8414 23 23 17.8414 23 11.5C23 5.15865 17.8414 0 11.5 0Z"
                                    fill="#4FCA64"
                                />
                                <path
                                    d="M17.3275 9.06192L11.0983 15.291C10.9114 15.4779 10.6661 15.5719 10.4208 15.5719C10.1755 15.5719 9.93018 15.4779 9.74329 15.291L6.62877 12.1764C6.25395 11.8018 6.25395 11.1961 6.62877 10.8214C7.00341 10.4466 7.60898 10.4466 7.9838 10.8214L10.4208 13.2584L15.9725 7.70689C16.3472 7.33208 16.9527 7.33208 17.3275 7.70689C17.7022 8.08154 17.7022 8.6871 17.3275 9.06192Z"
                                    fill="#FAFAFA"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1728_22026">
                                    <rect width="23" height="23" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <p className={style["wk__confirmText"]}>Workflow triggered successfully</p>
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} mt={2}>
                            {/* <Button
                            variant="outlined"
                            onClick={closeAddConfirmWorkflowModal}
                        >
                            Cancel
                        </Button> */}
                            {/* <Button
                            variant="contained"
                            onClick={() => {
                                // const url = `/projects/${location.state?.projectId}/statistics?workflow=${addConfirmWorkflowModal.workflowId}`;
                                const url = `/projects/${location.state?.projectId}`;
                                navigate(url);
                            }}
                        >
                            Go to project
                        </Button> */}
                        </Stack>
                    </MaterialProvider>
                </Modal>
            </div>
            <TourTriggerModal />
            {/* start outreach modal */}
            {modalStatus === "SAVE_WORKFLOW_NAME" ? (
                <BaseModal onClose={closeModal} overlayStyles={{ width: "550px" }}>
                    <>
                        <Stack>
                            <Typography variant="h6" mb={2}>
                                {title}
                            </Typography>
                            {!user.emailAuthorized ? (
                                <Typography fontSize={14} color="red" mb={3}>
                                    It is necessary to authorize your email provider at this step
                                </Typography>
                            ) : null}
                        </Stack>
                        {user.emailAuthorized ? (
                            <Stack spacing={2}>
                                {/* <CustomMUIInput
                                    placeholder="Enter Workflow Name"
                                    value={workflowData.name}
                                    onChange={(e) =>
                                        dispatch(
                                            setSingleWorkflowDataProperty({
                                                key: "name",
                                                value: e.target.value,
                                            })
                                        )
                                    }
                                /> */}
                                {/* {!companyVerified && (
                                    <>
                                        <CustomMUIInput
                                            placeholder="Enter Company Name"
                                            value={workflowData.comapnyName}
                                            onChange={(e) =>
                                                dispatch(
                                                    setSingleWorkflowDataProperty({
                                                        key: "comapnyName",
                                                        value: e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                        <CustomMUIInput
                                            placeholder="Enter Company Link"
                                            value={workflowData.companyLink}
                                            onChange={(e) =>
                                                dispatch(
                                                    setSingleWorkflowDataProperty({
                                                        key: "companyLink",
                                                        value: e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                    </>
                                )} */}
                                {/* {errors?.["workflowName"] ? (
                                    <Typography
                                        fontSize={14}
                                        style={{ color: "#ff0000d6" }}
                                    >
                                        {errors?.["workflowName"]}
                                    </Typography>
                                ) : null} */}
                                {isCandidatesSelected ? (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label="Schedule"
                                            value={workflowData.date}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    dispatch(
                                                        setSingleWorkflowDataProperty({
                                                            key: "date",
                                                            value: newValue,
                                                        })
                                                    );
                                                }
                                            }}
                                            // onChange={(date: Date | null) => {
                                            //     if (date) {
                                            //         dispatch(
                                            //             setSingleWorkflowDataProperty(
                                            //                 {
                                            //                     key: "date",
                                            //                     value: date,
                                            //                 }
                                            //             )
                                            //         );
                                            //     }
                                            // }}
                                            // renderInput={(params) => (
                                            //     <TextField
                                            //         {...params}
                                            //         sx={{ marginTop: "24px" }}
                                            //     />
                                            // )}
                                            // InputProps={{
                                            //     style: {
                                            //         fontFamily: "Poppins",
                                            //     },
                                            // }}
                                            slotProps={{
                                                textField: {
                                                    inputProps: {
                                                        style: {
                                                            fontFamily: "Poppins",
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                ) : null}
                                {/* <Stack direction="row" alignItems="center" mt={1}>
                                    <Typography sx={{ fontSize: 14 }}>
                                        Save workflow for later
                                    </Typography>
                                    <Switch
                                        checked={saveWorkflowForLater}
                                        onChange={(e) =>
                                            dispatch(
                                                changeSaveWorkflowForLater(
                                                    e.target.checked
                                                )
                                            )
                                        }
                                    />
                                </Stack> */}
                                {isSESIntegratedEnabled && <SenderEmail />}
                                {isSESIntegratedEnabled && <SenderName />}
                                <BCCEmail />
                                <UnsubscribeEmailToggle />
                                <Stack gap={0.7}>
                                    <AuthorizationWarningDescription />
                                    <MoreThan100CandidatesHelperMessage />
                                </Stack>
                                {/* <CustomMUIInput
                                    type="email"
                                    placeholder="Sender Email"
                                    value={workflowData.senderEmail}
                                    onChange={(e) =>
                                        dispatch(
                                            changeSenderEmail(e.target.value)
                                        )
                                    }
                                /> */}
                                <Stack direction="row" justifyContent="flex-end" spacing={2} mt={3}>
                                    <CustomButton
                                        className="px-6"
                                        onClick={handleWorkflowCreate}
                                        disabled={
                                            isCreateLoading || isSenderEmailError || !!workflowData?.bccEmail?.error
                                        }
                                    >
                                        <ButtonTextWithLoading
                                            isLoading={isCreateLoading || isAddToWorkflowLoading}
                                            text={createBtnText}
                                        />
                                    </CustomButton>
                                </Stack>
                            </Stack>
                        ) : (
                            <MailAuthConnect variant="vertical" />
                        )}
                    </>
                </BaseModal>
            ) : null}
            {modalStatus === "CREATED_WORKFLOW" ? (
                <BaseModal onClose={closeModal} overlayStyles={{ width: "550px" }}>
                    <Stack spacing={2}>
                        <CheckCircleIcon
                            fontSize="large"
                            style={{
                                alignSelf: "center",
                                color: "#4BB543",
                                fontSize: "60px",
                            }}
                        />
                        <Typography variant="body2" style={{ fontSize: "22px" }} textAlign="center">
                            Workflow {selectedWorkflow?.name ? "edited" : "created"} successfully!
                        </Typography>
                        <Typography style={{ fontSize: "18px" }} textAlign="center">
                            Do you want to start the outreach?
                        </Typography>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                gap: "1rem",
                            }}
                        >
                            <Button variant="contained" onClick={handleNextModal}>
                                Yes
                            </Button>
                            <Button variant="outlined" onClick={closeModal}>
                                Cancel
                            </Button>
                        </div>
                    </Stack>
                </BaseModal>
            ) : null}
            {modalStatus === "OUTREACH_MODAL" ? (
                <BaseModal onClose={closeModal} overlayStyles={{ width: "550px" }}>
                    <Stack gap={2}>
                        <Typography variant="h6">Start Outreach</Typography>
                        <CustomMUIInput
                            disabled={true}
                            placeholder="Enter Workflow Name"
                            value={workflowData.name}
                            onChange={(e) =>
                                dispatch(
                                    setSingleWorkflowDataProperty({
                                        key: "name",
                                        value: e.target.value,
                                    })
                                )
                            }
                        />
                        {!selectedCandidates ? <SelectProject /> : null}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Date&Time picker"
                                value={workflowData.date}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        dispatch(
                                            setSingleWorkflowDataProperty({
                                                key: "date",
                                                value: newValue,
                                            })
                                        );
                                    }
                                }}
                            />
                        </LocalizationProvider>
                        {userConnectionReqStats || userInmailStats ? (
                            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                                {userConnectionReqStats ? (
                                    <Typography fontSize={12} fontStyle="italic">
                                        Connection Req. Sent:{" "}
                                        <span className={style["wk__stats"]}>{userConnectionReqStats}</span>
                                    </Typography>
                                ) : null}
                                {userInmailStats ? (
                                    <Typography fontSize={12} fontStyle="italic">
                                        Inmails Sent: <span className={style["wk__stats"]}>{userInmailStats}</span>
                                    </Typography>
                                ) : null}
                            </Stack>
                        ) : null}
                        <Stack direction="row" spacing={2} alignSelf="flex-end">
                            <Button variant="outlined" onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleOutReachStart} disabled={isAddToWorkflowLoading}>
                                <ButtonTextWithLoading isLoading={isAddToWorkflowLoading} text="Start" variant="light" />
                            </Button>
                        </Stack>
                    </Stack>
                </BaseModal>
            ) : null}
            {/* {modalStatus === "PERSONALIZED_MODAL" ? (
                <PersonalizationModal
                    handleOverlay={() => setOverlayWorkflow(true)}
                />
            ) : null} */}
            {showEditTemplatesModal ? <HyperPersonalizationEditModal /> : null}
            {/* {modalStatus === "PERSONALIZED_INPUTS" ? (
                <PersonalizedInputsModal
                    candidateIDs={selectedCandidates}
                    projectID={selectedProjectFromWorkflows}
                />
            ) : null} */}
            {creditRewardModal.open ? (
                <CreditRewardModal
                    open={creditRewardModal.open}
                    onClose={closeCreditRewardModal}
                    workflowId={creditRewardModal.workflowId}
                    workflowCredits={creditRewardModal.workflowCredits}
                />
            ) : null}
            {openSampleOutreachModal ? (
                <SampleProjectOutreachModal onClose={() => setOpenSampleOutreachModal(false)} />
            ) : null}
        </div>
    );
}
