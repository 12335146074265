import Box from "@mui/joy/Box";
import { Experimental_CssVarsProvider } from "@mui/material";

import { JoyProvider } from "./JoyProvider";
import Navbar from "./Navbar/Navbar";

export function JoyPageLayout({ children }: { children: React.ReactNode }) {
    return (
        <JoyProvider>
            <Box sx={{ backgroundColor: "background.body", minHeight: "100vh" }}>
                <Box sx={{ pt: 1, pr: 1 }}>
                    <Experimental_CssVarsProvider>
                        <Navbar />
                    </Experimental_CssVarsProvider>
                </Box>
            </Box>
        </JoyProvider>
    );
}
