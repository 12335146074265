import React from 'react';
import { Box, Button, TextareaAutosize } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, updateUser } from '../../../Signin/Signin.reducer';

const VoiceMessage: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [voicemailMessage, setVoicemailMessage] = React.useState("");

    React.useEffect(() => {
        setVoicemailMessage(user?.twilioConfig?.twilioNoResponseText || "");
    }, [user]);

    const handleVoicemail = () => {
        dispatch(
            updateUser({
                ...user,
                twilioConfig: {
                    ...user.twilioConfig,
                    twilioNoResponseText: voicemailMessage,
                },
                action: updateUser.type,
            })
        );
    };

    return (
        <>
            <div style={{ marginTop: 10, fontSize: 16, marginBottom: 10 }}> Voice message</div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 14,
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                To enable voice message, please write the message to be conveyed in case the caller calls
                when you are unavailable or during the off working hours
            </Box>
            <TextareaAutosize
                aria-label="voice message"
                minRows={2}
                placeholder="Voice message"
                value={voicemailMessage}
                onChange={(e) => setVoicemailMessage(e.target.value)}
                style={{
                    width: "100%",
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    boxSizing: "border-box",
                    fontSize: "16px",
                    resize: "none",
                    outline: "none",
                }}
                onFocus={(e) => (e.target.style.border = "1px solid #3f51b5")}
                onBlur={(e) => (e.target.style.border = "1px solid #ccc")}
            />
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginTop: 5, marginBottom: 10, borderRadius: 5 }}
                onClick={() => handleVoicemail()}
            >
                Save
            </Button>
        </>
    );
};

export default VoiceMessage;