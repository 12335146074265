import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/store";

const initialState: any = {
    candidatesData: [],
    candidateDetails: {},
    projectName: "",
    projectDescription: "",
    projectLocation: "",
    projectCreatedOn: "",
    recruiterName: "",
    listName: [],
    selectedCandidateIds: [],
    listExpired: false,
    projectFilters: {},
    selectedList: {},
    hmDetails: {},
    passCode: "-1",
    showPassCodeModal: false,
    isAllCandidatesSelected: false
};

const reducers = {
    setCandidateStatus(state: typeof initialState, { payload }: { payload: {candidateId: string; status: string} }) {
        state.candidatesData = state.candidatesData.map((c) => {
            if (c.candidateId === payload.candidateId) {
                c.action = payload.status;
            }
            return c;
        });
    },
    setCandidateFeedback(state: typeof initialState, { payload }: { payload: {candidateId: string; reasons: string[]; review: string} }) {
        state.candidatesData = state.candidatesData.map((c) => {
            if (c.candidateId === payload.candidateId) {
                c.reasons = payload.reasons;
                c.review = payload.review;
            }
            return c;
        });
    },
    setSelectedList(state: typeof initialState, { payload }: { payload: any }) {
        state.selectedList = payload;
    },
    setCandidatesData(state: typeof initialState, { payload }: { payload: any }) {
        state.candidatesData = payload;
    },
    setCandidateDetails(state: typeof initialState, { payload }: { payload: any }) {
        state.candidateDetails = payload;
    },
    setListName(state: typeof initialState, { payload }: { payload: any }) {
        state.listName = payload;
    },
    setProjectName(state: typeof initialState, { payload }: { payload: any }) {
        state.projectName = payload;
    },
    setProjectDescription(state: typeof initialState, { payload }: { payload: any }) {
        state.projectDescription = payload;
    },
    setProjectFilters(state: typeof initialState, { payload }: { payload: any }) {
        state.projectFilters = payload;
    },
    setProjectLocation(state: typeof initialState, { payload }: { payload: any }) {
        state.projectLocation = payload;
    },
    setProjectCreatedOn(state: typeof initialState, { payload }: { payload: any }) {
        state.projectCreatedOn = payload;
    },
    setRecruiterName(state: typeof initialState, { payload }: { payload: any }) {
        state.recruiterName = payload;
    },
    setListExpired(state: typeof initialState, { payload }: { payload: any }) {
        state.listExpired = payload;
    },
    setHMDetails(state: typeof initialState, { payload }: { payload: any }) {
        state.hmDetails = payload;
    },
    setPassCode(state: typeof initialState, { payload }: { payload: any }) {
        state.passCode = payload;
    },
    setShowPassCodeModal(state: typeof initialState, { payload }: { payload: any }) {
        state.showPassCodeModal = payload;
    },
    getCandidatesData(state: any, { payload }: { payload: { uuid: string; passCode?: string } }) {},
    getCandidateDetails(state: any, { payload }: { payload: { uuid: string; candidateId: string[] } }) {},
    getListName(state: any, { payload }: { payload: { projectId: string } }) {},
    updateCandidatesData(state: any, { payload }: { payload: { uuid: string; candidateReviews: object[] } }) {},
    deleteCandidatesData(state: any, { payload }: { payload: { uuid: string; candidateIds: string[] } }) {},
    shareWithHiringManager(
        state: any,
        { payload }: { payload: { uuid: string; hiringManagerName: string; hiringManagerEmail: string } }
    ) {},
    addCandidatesToHiringManager(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                // isNew: boolean;
                // uuid: string;
                // listId: string;
                candidateIds: string[];
                name: string;
                projectId: string;
            };
        }
    ) {},
    leavePage(state: any) {},
    selectCandidate(state: typeof initialState, { payload }: { payload: string }) {
        if (!state.selectedCandidateIds.includes(payload)) {
            state.selectedCandidateIds.push(payload);
        }
    },
    deselectCandidate(state: typeof initialState, { payload }: { payload: string }) {
        state.selectedCandidateIds = state.selectedCandidateIds.filter((id) => id !== payload);
    },
    clearSelectedCandidates(state: typeof initialState) {
        state.selectedCandidateIds = [];
    },
    setAllCandidatesSelected(state, action: PayloadAction<boolean>) {
        state.isAllCandidatesSelected = action.payload;
    },
};

export const hiringManager = createSlice({
    name: "hiringManager",
    initialState,
    reducers,
});

export default hiringManager.reducer;

/*eslint-disable */
export const {
    setCandidateStatus,
    setCandidateFeedback,

    addCandidatesToHiringManager,
    setSelectedList,
    setHMDetails,

    setProjectName,
    setProjectLocation,
    setRecruiterName,

    setCandidatesData,
    getCandidatesData,

    setListName,
    getListName,

    setProjectCreatedOn,
    setProjectDescription,
    setProjectFilters,

    setCandidateDetails,
    getCandidateDetails,

    updateCandidatesData,
    deleteCandidatesData,

    shareWithHiringManager,
    setListExpired,
    leavePage,
    selectCandidate,
    deselectCandidate,
    clearSelectedCandidates,

    setPassCode,
    setShowPassCodeModal,

    setAllCandidatesSelected,
} = hiringManager.actions;

/*eslint-enable */
export const selectCandidatesData = (state: RootState) => state.hiringManager.candidatesData;
export const selectCandidateDetails = (state: RootState) => state.hiringManager.candidateDetails;
export const selectProjectName = (state: RootState) => state.hiringManager.projectName;
export const selectProjectDescription = (state: RootState) => state.hiringManager.projectDescription;
export const selectRecruiterName = (state: RootState) => state.hiringManager.recruiterName;
export const selectProjectLocation = (state: RootState) => state.hiringManager.projectLocation;
export const selectProjectCreatedOn = (state: RootState) => state.hiringManager.projectCreatedOn;
export const selectListName = (state: RootState) => state.hiringManager.listName;
export const selectSelectedCandidateIds = (state: RootState) => state.hiringManager.selectedCandidateIds;
export const selectListExpired = (state: RootState) => state.hiringManager.listExpired;
export const selectProjectFilters = (state: RootState) => state.hiringManager.projectFilters;
export const selectSelectedList = (state: RootState) => state.hiringManager.selectedList;
export const selectHMDetails = (state: RootState) => state.hiringManager.hmDetails;
export const selectPassCode = (state: RootState) => state.hiringManager.passCode;
export const selectShowPassCodeModal = (state: RootState) => state.hiringManager.showPassCodeModal;
export const selectIsAllCandidatesSelected = (state: RootState) => state.hiringManager.isAllCandidatesSelected;
