import { useEffect, useState, useMemo } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import Autocomplete from "@mui/joy/Autocomplete";
import CircularProgress from "@mui/joy/CircularProgress";
import Button from "@mui/joy/Button";
import { JoyProvider } from "../../../components/JoyProvider";
import { useAppDispatch } from "../../../store";
import { getAllProjectsList, selectAllProjects } from "../../allProjects/index.reducer";
import { useSelector } from "react-redux";
import { IProject } from "../../project/project.types";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { Option } from "../../../common";
import { useNavigate, useParams } from "react-router-dom";
import { editMember, selectMembers } from "../manageTeams.reducers";

export function SelectAccessProjects() {
    const { id = "" } = useParams<{ id: string }>();
    const projectList: IProject[] = useSelector(selectAllProjects);
    const options: Option[] = projectList.map((i) => ({ value: String(i._id), label: i.name }));
    const members = useSelector(selectMembers);
    const navigate = useNavigate();
    const [selectedProjects, setSelectedProjects] = useState<Option[]>([]);
    const isFetching = useSelector(checkIfLoading(getAllProjectsList.type));
    const dispatch = useAppDispatch();
    const isSubmitting = useSelector(checkIfLoading(editMember.type));
    const isLoading = isSubmitting || isFetching;

    useEffect(() => {
        dispatch(getAllProjectsList({ action: getAllProjectsList.type }));
    }, []);

    const accessAllowedProjects = useMemo(() => {
        const member = members.find((i) => i._id === Number(id));
        return member ? member.accessProjects : [];
    }, [members, id]);

    useEffect(() => {
        if (!projectList) return;
        const values = projectList
            .filter((i) => accessAllowedProjects?.includes(Number(i._id)))
            .map((i) => ({ value: String(i._id), label: i.name }));
        setSelectedProjects([...values]);
    }, [accessAllowedProjects]);

    const handleClose = () => {
        navigate(-1);
    };

    const handleSave = () => {
        dispatch(
            editMember({
                userId: Number(id),
                accessProjects: selectedProjects.map((i) => Number(i.value)),
                successCallback: handleClose,
            })
        );
    };

    return (
        <JoyProvider>
            <Modal open={true} onClose={handleClose}>
                <ModalDialog size="sm" variant="plain" sx={{ width: 500 }}>
                    <ModalClose />
                    <Typography level="title-lg">Select Projects</Typography>
                    <Autocomplete
                        disabled={isLoading}
                        multiple
                        limitTags={2}
                        placeholder="Select one or more projects"
                        value={selectedProjects}
                        onChange={(e, value) => {
                            setSelectedProjects(value);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.label}
                        options={options}
                        loading={isFetching}
                        endDecorator={
                            isFetching ? <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} /> : null
                        }
                    />
                    <Button
                        size="sm"
                        sx={{ alignSelf: "flex-end" }}
                        disabled={isLoading}
                        loading={isSubmitting}
                        onClick={handleSave}
                    >
                        Submit
                    </Button>
                </ModalDialog>
            </Modal>
        </JoyProvider>
    );
}
