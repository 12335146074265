import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import HouseIcon from "@mui/icons-material/House";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WorkIcon from "@mui/icons-material/Work";
import { Avatar, Box, Divider, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { useState } from "react";
import Linkify from "react-linkify";

const CustomTypography = styled((props: TypographyProps) => <Typography {...props} variant="subtitle2" />)(
    ({ theme }) => ({
        color: theme.palette.grey[500],
        fontSize: "12px",
    })
);

const truncateText = (text: string) =>
    text.split(" ").length > 6 ? text.split(" ").slice(0, 6).join(" ") + "..." : text;

function TypographyWithReadMore({ text, title, limit = 175 }: { text: string; title: string; limit?: number }) {
    const [readMore, setReadMore] = useState(false);
    const spanText = readMore ? "Read Less" : "Read More";
    const value = readMore ? text : text.slice(0, limit) + (text.length > limit ? "..." : "");
    const linkDecorator = (href: string, text: string, key: string) => (
        <p style={{ fontSize: "12px", color: "#0488d1" }}>
            {" "}
            <a href={href} key={key} target="_blank" rel="noopener noreferrer" style={{ color: "#0488d1" }}>
                {text}
            </a>
        </p>
    );

    return (
        <CustomTypography variant="subtitle2">
            <span style={{ fontSize: "0.85rem", color: "#32383f", fontWeight: "600" }}>{title}: </span>{" "}
            <Linkify componentDecorator={linkDecorator}>{value}</Linkify>{" "}
            {text.length > limit && (
                <span
                    style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "500", color: "#479cd2" }}
                    onClick={() => setReadMore((prev) => !prev)}
                >
                    {spanText}
                </span>
            )}
        </CustomTypography>
    );
}

const CompanyCard = (company: any) => {
    const { name, description, industry, headquarters, size, companyType, link, website, logoUrl } = company;
    if (!name || !description || name === "" || description === "") {
        return null;
    }
    return (
        <Paper
            elevation={0}
            sx={(theme) => ({
                borderRadius: theme.shape.borderRadius,
                padding: "1rem",
                display: "grid",
                gridTemplateColumns: "80px 1fr",
                gap: "1rem",
                border: "1px solid rgba(0,0,0,0.1)",
            })}
        >
            <Avatar variant="square" sx={{ width: 60, height: 60 }} src={logoUrl} alt="Company" />
            <Stack direction="row" spacing={2} width="100%">
                <Stack spacing={1} width="70%">
                    <Stack direction="row" alignItems="center">
                        <Typography fontSize={16} fontWeight={600}>
                            {truncateText(name)}
                        </Typography>
                        {link && (
                            <IconButton color="primary" size="small" component="a" href={link} target="_blank">
                                <LinkedInIcon fontSize="small" />
                            </IconButton>
                        )}
                        {website && (
                            <IconButton color="primary" size="small" component="a" href={website} target="_blank">
                                <HouseIcon fontSize="small" sx={{ color: "black", marginLeft: "-5px" }} />
                            </IconButton>
                        )}
                    </Stack>
                    {description && description !== "NA" && (
                        <TypographyWithReadMore text={description} title="Description" />
                    )}
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack spacing={0.5} width="30%">
                    {industry && (
                        <Tooltip title="Industry">
                            <Typography display="flex" alignItems="center" fontSize={12}>
                                <BusinessIcon color="disabled" fontSize="small" />
                                <Box component="span" ml={1}>
                                    {industry}
                                </Box>
                            </Typography>
                        </Tooltip>
                    )}

                    {headquarters && (
                        <Tooltip title="Company location">
                            <Typography display="flex" alignItems="center" fontSize={12}>
                                <FmdGoodIcon color="disabled" fontSize="small" />
                                <Box component="span" ml={1}>
                                    {truncateText(headquarters)}
                                </Box>
                            </Typography>
                        </Tooltip>
                    )}

                    {size && (
                        <Tooltip title="Company size">
                            <Typography display="flex" alignItems="center" fontSize={12}>
                                <WorkIcon color="disabled" fontSize="small" />
                                <Box component="span" ml={1}>
                                    {size}
                                </Box>
                            </Typography>
                        </Tooltip>
                    )}

                    {companyType && (
                        <Tooltip title="Company type">
                            <Typography display="flex" alignItems="center" fontSize={12}>
                                <BusinessCenterIcon color="disabled" fontSize="small" />
                                <Box component="span" ml={1}>
                                    {companyType}
                                </Box>
                            </Typography>
                        </Tooltip>
                    )}
                </Stack>
            </Stack>
        </Paper>
    );
};

export default CompanyCard;
