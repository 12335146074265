import { ComponentPropsWithRef } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";

export const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiList-root": {
        display: "grid",
        gridTemplateColumns: "1fr  1fr 1fr",
        padding: "0.7rem !important",
        gridColumnGap: "1rem",
    },
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                // color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

type CustomMenuItemProps = {
    children: React.ReactNode;
    label: string;
};

export const CustomMenuSection = ({ children, label }: CustomMenuItemProps) => {
    return (
        <FormControl
            component="fieldset"
            variant="standard"
            sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                    color: "rgba(0, 0, 0, 0.6)",
                },
                "& .MuiFormLabel-root": {
                    fontSize: "14px",
                },
                width: "fit-content",
            }}
        >
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup
                sx={{
                    padding: "0.5rem 0",
                    "& .MuiTypography-root": {
                        fontSize: "14px !important",
                    },
                    "& .MuiButtonBase-root": {
                        padding: "5px",
                    },
                }}
            >
                {children}
            </FormGroup>
        </FormControl>
    );
};

export const MenuContainer = (props: ComponentPropsWithRef<"div">) => {
    return (
        <div
            {...props}
            style={{
                backgroundColor: "white",
                position: "absolute",
                padding: "1rem",
                borderRadius: "0.7rem",
                zIndex: 1,
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                right: 0,
                width: "max-content",
                justifyContent: "center",
                ...props?.style,
            }}
        >
            {props.children}
        </div>
    );
};
