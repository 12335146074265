export default function quillWordCount(value: string) {
    const msg = value
        .replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>?/gi, "")
        .trim()
        .split(/\s+/);

    const wordCount = msg[0] === "" ? 0 : msg.length;

    return wordCount;
}
