export default async function isValidImageUrl(url: string): Promise<boolean> {
    return new Promise((resolve) => {
        fetch(url, { method: "HEAD" })
            .then((res) => {
                resolve(!!res?.headers?.get?.("Content-Type")?.startsWith("image"));
            })
            .catch((e) => {
                resolve(false);
            });
    });
}


