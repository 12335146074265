import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setSuccessNotification } from "../../components/Notification/index.reducer";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API, { apiBase } from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import {
    generateTicket,
    getAllSupportTicket,
    leavePage,
    setAllSupportTicket,
    setShowSupportTicket,
    updateStatus,
} from "./Support.reducer";
import handleException from "../../utils/sentry";

function* generateTicketSaga({ payload }: { payload: { title: string; description: string } }): SagaIterator {
    try {
        const { title, description } = payload;

        if (!title || !description) return;

        yield put(startAction({ action: generateTicket.type }));

        const response = yield call(new API().post, `${apiBase}/support/generate-ticket`, payload);
        if (!response?.data) return;
        yield put(getAllSupportTicket());
        yield put(setShowSupportTicket(false));
        yield put(setSuccessNotification(response.message));
    } catch (e) {
        handleException(e);
        console.error("***uploadTicketSaga err=", e);
    } finally {
        yield put(stopAction({ action: generateTicket.type }));
    }
}

function* getAllSupportTicketSaga(): SagaIterator {
    try {
        yield put(startAction({ action: getAllSupportTicket.type }));

        const response = yield call(new API().get, `${apiBase}/support/get-all-tickets`);
        if (!response?.data) return;
        yield put(setAllSupportTicket(response.data));
        yield put(setSuccessNotification(response.message));
    } catch (e) {
        handleException(e);
        console.error("***uploadTicketSaga err=", e);
    } finally {
        yield put(stopAction({ action: getAllSupportTicket.type }));
    }
}
function* updateStatusSaga({
    payload,
}: {
    payload: {
        status: string;
        ticketId: string;
    };
}): SagaIterator {
    try {
        if (!payload.ticketId || !payload.status) return;

        yield put(startAction({ action: updateStatus.type }));

        const response = yield call(new API().put, `${apiBase}/support/update-ticket-by-id`, payload);
        if (!response?.data) return;

        yield put(getAllSupportTicket());
        yield put(setSuccessNotification(response.message));
    } catch (e) {
        handleException(e);
        console.error("***updateStatusSaga err=", e);
    } finally {
        yield put(stopAction({ action: updateStatus.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(generateTicket.type, generateTicketSaga),
        // @ts-ignore
        yield takeLatest(getAllSupportTicket.type, getAllSupportTicketSaga),
        // @ts-ignore
        yield takeLatest(updateStatus.type, updateStatusSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
