import { useMemo, useState } from "react";
import Autocomplete from "@mui/joy/Autocomplete";
import CircularProgress from "@mui/joy/CircularProgress";
import { useParams } from "react-router-dom";

import { OptionWithValueAsNumber } from "../../../common";
import { JoyProvider } from "../../../components/JoyProvider";
import { useFetchCandidateProjectsQuery } from "../../../store/apis/all-candidates/all-candidates.api";

export function useFetchCandidateProjects() {
    const params = useParams();
    const { data = [], ...rest } = useFetchCandidateProjectsQuery(params?.id);

    return { data, ...rest };
}

export function useSelectProjectValues({ project }: { project: OptionWithValueAsNumber }) {
    const { data: projects, ...rest } = useFetchCandidateProjects();

    const options = useMemo(
        () =>
            projects
                .map(({ projectId, projectName }) => ({
                    label: projectName,
                    value: projectId,
                }))
                .filter(({ value }) => project.value !== value),
        [projects, project]
    );

    return {
        projects: options,
        projectName: project.label,
        projectId: project.value,
        ...rest,
    };
}

export type SelectProjectFilterProps = ReturnType<typeof useSelectProjectValues> & {
    handleSelectChange: (option: OptionWithValueAsNumber) => void;
    selectedProject: OptionWithValueAsNumber;
};

export function SelectProject(props: SelectProjectFilterProps) {
    const { selectedProject, projects, isLoading, handleSelectChange } = props;
    const [query, setQuery] = useState("");
    return (
        <JoyProvider>
            <Autocomplete
                size="sm"
                value={selectedProject}
                options={projects}
                loading={isLoading}
                onChange={(_event, value, reason, details) => {
                    if (value) {
                        handleSelectChange(value);
                    }
                }}
                sx={{
                    width: 350,
                    "@media (max-width: 1265px)": {
                        width: 250,
                    },
                    "@media (max-width: 1160px)": {
                        width: 180,
                    },
                    "@media (max-width: 1095px)": {
                        width: 160,
                    },
                }}
                inputValue={query}
                onInputChange={(_, newInputValue) => {
                    setQuery(newInputValue);
                }}
                endDecorator={isLoading ? <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} /> : null}
            />
        </JoyProvider>
    );
}
