import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { startAction, stopAction } from "../../../store/reducers/loaders.reducer";
import {
    getLeadRatingCriteriaList,
    ICriteria,
    leavePage,
    addLeadRatingCriteria,
    setLeadRatingCriteriaList,
    updateLeadRatingCriteria,
    submitCriteriaForInsightGeneration,
    type SubmitCriteriaForInsightGenerationPayload,
} from "./LeadRating.slice";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import API from "../../../utils/API";
import { setErrorNotification } from "../../../components/Notification/index.reducer";

function* addLeadRatingCriteriaSaga({ payload }: { payload: { onClose: () => void } }): SagaIterator {
    try {
        const state = yield select();
        const leadRatingCriteria = get(state, "leadRating.criteria");
        const leadRatingCriteriaName = get(state, "leadRating.criteriaName");

        if (!leadRatingCriteriaName) {
            yield put(setErrorNotification("criteria name is required!"));
            return;
        }

        const newCriteria: any = [];
        leadRatingCriteria.forEach((item: ICriteria) => {
            if (item.deleted) return;
            const { data } = item || {};

            const values = [
                {
                    tag: "MUST",
                    order: item.order,
                    type: item.type,
                    values: data["MUST"],
                },
                {
                    tag: "PREFERRED",
                    order: item.order,
                    type: item.type,
                    values: data["PREFERRED"],
                },
                {
                    tag: "NOT_MUST",
                    order: item.order,
                    type: item.type,
                    values: data["NOT_MUST"],
                },
            ];
            newCriteria.push(...values);
        });

        const response = yield call(new API().post, "/lead-score/create", {
            leadScoreAlgorithm: newCriteria,
            criteriaName: leadRatingCriteriaName,
        });

        if (!response?.data) return;

        yield put(getLeadRatingCriteriaList());

        payload?.onClose();
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* updateLeadRatingCriteriaSaga({ payload }: { payload: { onClose?: () => void } }): SagaIterator {
    try {
        const state = yield select();
        const criteriaId = get(state, "leadRating.criteriaId");

        const leadRatingCriteria = get(state, "leadRating.criteria");
        const leadRatingCriteriaName = get(state, "leadRating.criteriaName");

        if (!leadRatingCriteriaName) {
            yield put(setErrorNotification("criteria name is required!"));
            return;
        }

        const newCriteria: any = [];
        leadRatingCriteria.forEach((item: ICriteria) => {
            if (item.deleted) return;
            const { data } = item || {};

            const values = [
                {
                    tag: "MUST",
                    order: item.order,
                    type: item.type,
                    values: data["MUST"],
                },
                {
                    tag: "PREFERRED",
                    order: item.order,
                    type: item.type,
                    values: data["PREFERRED"],
                },
                {
                    tag: "NOT_MUST",
                    order: item.order,
                    type: item.type,
                    values: data["NOT_MUST"],
                },
            ];
            newCriteria.push(...values);
        });

        yield put(startAction({ action: updateLeadRatingCriteria.type }));
        const response = yield call(new API().put, "/lead-score/update", {
            _id: criteriaId,
            leadScoreAlgorithm: newCriteria,
            criteriaName: leadRatingCriteriaName,
        });

        if (!response?.data) return;

        yield put(getLeadRatingCriteriaList());
        payload?.onClose?.();
    } catch (err: unknown) {
        yield put(setErrorNotification("Unexpected error occurred while updating lead score criteria"));
        handleException(err);
    } finally {
        yield put(stopAction({ action: updateLeadRatingCriteria.type }));
    }
}

function* getLeadRatingCriteriaListSaga(): SagaIterator {
    try {
        yield put(startAction({ action: getLeadRatingCriteriaList.type }));
        const response = yield call(new API().get, "/lead-score/list");
        console.log("****getVettingCriteriaListSaga");
        if (!response?.data) return;

        yield put(setLeadRatingCriteriaList(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: getLeadRatingCriteriaList.type }));
    }
}

function* submitCriteriaForInsightGenerationSaga(action: SubmitCriteriaForInsightGenerationPayload): SagaIterator {
    try {
        const { onClose } = action.payload;
        const state = yield select();
        const criteriaId = get(state, "leadRating.criteriaId");
        const leadRatingCriteriaList = get(state, "leadRating.leadRatingCriteriaList");
        console.log({ leadRatingCriteriaList });
        const leadRatingCriteriaName = get(state, "leadRating.criteriaName");
        const selectedCriteria = leadRatingCriteriaList.find((i: any) => i._id === criteriaId);
        console.log({ selectedCriteria });

        if (!criteriaId || !leadRatingCriteriaName || !selectedCriteria) {
            yield put(setErrorNotification("Please select criteria!"));
            return;
        }

        yield put(startAction({ action: action.type }));
        const response = yield call(new API().put, "/lead-score/update", {
            _id: criteriaId,
            leadScoreAlgorithm: selectedCriteria.leadScoreAlgorithm,
            criteriaName: leadRatingCriteriaName,
        });

        if (!response?.data) {
            throw new Error();
        } else {
            onClose?.();
        }
    } catch (error) {
        console.log({ error });
        handleException(error);
        yield put(setErrorNotification("Unexpected error occurred while setting lead score criteria"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getLeadRatingCriteriaList.type, getLeadRatingCriteriaListSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            updateLeadRatingCriteria.type,
            updateLeadRatingCriteriaSaga
        ),
        // yield takeLatest(
        // @ts-ignore
        yield takeLatest(addLeadRatingCriteria.type, addLeadRatingCriteriaSaga),
        // @ts-ignore
        yield takeLatest(submitCriteriaForInsightGeneration.type, submitCriteriaForInsightGenerationSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
