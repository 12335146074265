import { useMediaQuery } from "@mui/material";
import { detectMobile } from "../utils/device";
import { Theme } from "@mui/joy/styles";

export function useIsMobile() {
    const isResponsiveDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm")
    );
    const isMobileDevice = detectMobile();
    if (import.meta.env.VITE_REACT_APP_ENV === "local") {
        return isResponsiveDevice;
    }
    return isMobileDevice;
}
