import { useEffect, useRef, useState } from "react";

interface Position {
    bottom: number;
    right: number;
}
type DraggableElement = HTMLDivElement & {
    dragOffsetX: number;
    dragOffsetY: number;
};

const useDragAndDrop = (initialPosition: Position) => {
    const [position, setPosition] = useState<Position>(initialPosition);
    const dragging = useRef(false);
    const elementRef = useRef<DraggableElement | null>(null);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        dragging.current = true;
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            elementRef.current.dragOffsetX = e.clientX - rect.right;
            elementRef.current.dragOffsetY = e.clientY - rect.bottom;
        }
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (!dragging.current || !elementRef.current) return;
        setPosition({
            bottom: window.innerHeight - e.clientY - elementRef.current.dragOffsetY,
            right: window.innerWidth - e.clientX - elementRef.current.dragOffsetX,
        });
    };

    const handleMouseUp = () => {
        dragging.current = false;
    };

    useEffect(() => {
        const handleWindowMouseMove = (e: MouseEvent) => {
            if (dragging.current) {
                handleMouseMove(e);
            }
        };

        const handleWindowMouseUp = () => {
            if (dragging.current) {
                handleMouseUp();
            }
        };

        window.addEventListener("mousemove", handleWindowMouseMove);
        window.addEventListener("mouseup", handleWindowMouseUp);

        return () => {
            window.removeEventListener("mousemove", handleWindowMouseMove);
            window.removeEventListener("mouseup", handleWindowMouseUp);
        };
    }, []);

    return {
        position,
        handleMouseDown,
        elementRef,
    };
};

export default useDragAndDrop;
