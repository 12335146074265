import { Button, Pagination, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import CustomDropdown from "../CustomDropdown/CustomDropdown";

interface CustomPaginationProps {
    currentPage: number;
    totalCandidates: number;
    pageSize: number;
    handlePageChange: (page: number, pageSize: number) => void;
    pageOptions?: any[];
    enablePageSizeChange?: boolean;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
    currentPage,
    totalCandidates,
    pageSize,
    handlePageChange,
    pageOptions,
    enablePageSizeChange = false,
}) => {
    const { t } = useTranslation();

    const getPaginationLabel = () => {
        const start = (currentPage - 1) * pageSize + 1;
        const end = Math.min(currentPage * pageSize, totalCandidates);
        return `${start}-${end} of ${totalCandidates} ${t("common")}`;
    };

    const totalPages = Math.ceil(totalCandidates / pageSize);

    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            padding="10px"
            borderBottom="none"
            fontSize="12px"
        >
            <Typography fontSize="12px" fontWeight="400">
                {getPaginationLabel()}
            </Typography>
            <Pagination
                count={totalPages}
                shape="rounded"
                page={currentPage}
                onChange={(_, value) => handlePageChange(value, pageSize)}
                hideNextButton={currentPage === totalPages}
                size="small"
                sx={paginationStyles}
            />
            {enablePageSizeChange && (
                <CustomDropdown options={pageOptions}>
                    <Button
                        variant="outlined"
                        sx={dropdownButtonStyles}
                    >
                        {`${pageSize}/page`}
                    </Button>
                </CustomDropdown>
            )}
        </Stack>
    );
};

const paginationStyles = {
    "& .MuiPaginationItem-root": {
        color: "black",
        fontSize: "12px",
        fontWeight: "400",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
        backgroundColor: "transparent",
        color: "#1677ff",
        border: "1px solid #1677ff",
    },
};

const dropdownButtonStyles = {
    padding: "3px 14px",
    borderRadius: "5px",
    fontSize: "12px",
    fontWeight: "400",
    color: "black",
    border: "1px solid #1677ff",
    "&:focus": {
        boxShadow: "none",
    },
};

export default CustomPagination;
