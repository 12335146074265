import ChatIcon from "@mui/icons-material/Chat";
import Stack from "@mui/joy/Stack";
import { ReactNode } from "react";

interface MessagesPaneEmptyStateType {
    children: ReactNode;
}

export default function MessagesPaneEmptyState({ children }: MessagesPaneEmptyStateType) {
    return (
        <Stack
            sx={{
                height: {
                    lg: "calc(98dvh - var(--Header-height))",
                    xs: "100dvh",
                },
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                px: 2,
            }}
        >
            <ChatIcon style={{ fontSize: 100 }} />
            {children}
        </Stack>
    );
}
