import {
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Stack,
    StackProps,
    Typography,
    styled,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { googleLogo, outlookLogo } from "../../constant/Constant";
import useOAuth2 from "../../hooks/useOAuth2/useOAuth2";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import style from "../MailAlert/mailAlert.module.scss";

import {
    selectCanContinueFlow,
    selectContinueFlow,
    setAuthorizeModal,
} from "@/store/reducers/mail-alert/mailAlert.reducers";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { selectUser, setUser } from "@/store/reducers/signin/Signin.reducer";

const CustomStack = styled((props: StackProps) => <Stack {...props} />)(() => ({
    cursor: "pointer",
    borderRadius: 8,
    padding: "20px 50px",
    border: "1px solid #c4c4c4",
    width: "100%",
    "&:hover": {
        boxShadow: "0 0 0 0.2rem #80DEEA",
    },
}));

const CustomStack2 = styled((props: StackProps) => <Stack {...props} />)(() => ({
    cursor: "pointer",
    borderRadius: 8,
    width: "100%",
    maxWidth: 300,
    padding: "0.5rem 1rem",
    border: "1px solid #c4c4c4",
    "&:hover": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

interface AuthConnectProps {
    variant: "horizontal" | "vertical";
    onSuccess: (response: any, code: string) => void;
    extraQueryParameters?: Record<string, any>;
    selectedPermissions?: Record<string, boolean>;
}

export function GmailConnect({ variant, extraQueryParameters, onSuccess, selectedPermissions }: AuthConnectProps) {
    const dispatch = useDispatch();

    const scopes = ["https://www.googleapis.com/auth/userinfo.email", "https://www.googleapis.com/auth/gmail.modify"];

    if (selectedPermissions?.CALENDAR) {
        scopes.push("https://www.googleapis.com/auth/calendar", "https://www.googleapis.com/auth/calendar.events");
    }

    const { loading, getAuth } = useOAuth2({
        authorizeUrl: "https://accounts.google.com/o/oauth2/v2/auth",
        clientId: `${import.meta.env.VITE_REACT_APP_GOOGLE_CLIENT_ID}`,
        redirectUri: `${import.meta.env.VITE_REACT_APP_BASE_URL}/auth/gmail`,
        scope: scopes.join(" "),
        responseType: "code",
        extraQueryParameters: {
            prompt: "consent",
            access_type: "offline",
            ...extraQueryParameters,
        },
        exchangeCodeForTokenServerURL: `${import.meta.env.VITE_REACT_APP_BASE_URL}/api/auth/update-gmail-auth?userId=${extraQueryParameters?.userId ?? ""}`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess,
        onError: () => dispatch(setErrorNotification("Error in connecting gmail")),
    });

    const handleConnect = () => {
        if (loading) return;
        getAuth();
    };

    if (variant === "vertical") {
        return (
            <CustomStack2
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                sx={{
                    opacity: loading ? 0.5 : 1,
                }}
                onClick={handleConnect}
            >
                {loading ? (
                    <CircularProgress size={26} />
                ) : (
                    <>
                        <Box
                            component="img"
                            sx={{
                                width: 25,
                                height: "auto",
                            }}
                            alt="google-logo"
                            src={googleLogo}
                        />
                        <Typography>Gmail</Typography>
                    </>
                )}
            </CustomStack2>
        );
    }
    return (
        <CustomStack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
                opacity: loading ? 0.5 : 1,
            }}
            onClick={handleConnect}
        >
            {loading ? (
                <CircularProgress size={30} />
            ) : (
                <>
                    <Box
                        component="img"
                        className={style["app__mailprovider-gmail"]}
                        alt="google-logo"
                        src={googleLogo}
                    />
                    <p className={style["app__mailprovider-text"]}>Gmail</p>
                </>
            )}
        </CustomStack>
    );
}

export function OutlookConnect({ variant, onSuccess, extraQueryParameters, selectedPermissions }: AuthConnectProps) {
    const dispatch = useDispatch();

    const scopes = ["Mail.Send", "Mail.Read", "User.Read", "offline_access", "openid profile"];

    if (selectedPermissions?.CALENDAR) {
        scopes.push("Calendars.Read", "Calendars.ReadWrite");
    }

    const { loading, getAuth } = useOAuth2({
        authorizeUrl: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
        clientId: `${import.meta.env.VITE_REACT_APP_OUTLOOK_CLIENT_ID}`,
        redirectUri: `${import.meta.env.VITE_REACT_APP_BASE_URL}/auth/outlook`,
        scope: scopes.join(" "),
        responseType: "code",
        extraQueryParameters: {
            response_mode: "query",
            ...extraQueryParameters,
        },
        exchangeCodeForTokenServerURL: `${import.meta.env.VITE_REACT_APP_BASE_URL}/api/auth/update-outlook-auth?userId=${extraQueryParameters?.userId ?? ""}`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess,
        onError: () => dispatch(setErrorNotification("Network error, please try again!")),
    });

    const handleConnect = () => {
        if (loading) return;
        getAuth();
    };

    if (variant === "vertical") {
        return (
            <CustomStack2
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                    opacity: loading ? 0.5 : 1,
                }}
                onClick={handleConnect}
            >
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <Box
                        component="img"
                        sx={{
                            width: 100,
                            height: "auto",
                        }}
                        alt="outlook-logo"
                        src={outlookLogo}
                    />
                )}
            </CustomStack2>
        );
    }
    return (
        <CustomStack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
                opacity: loading ? 0.5 : 1,
            }}
            onClick={handleConnect}
        >
            {loading ? (
                <CircularProgress size={30} />
            ) : (
                <Box
                    component="img"
                    className={style["app__mailprovider-outlook"]}
                    alt="outlook-logo"
                    src={outlookLogo}
                />
            )}
        </CustomStack>
    );
}

export function MailAuthConnect({
    variant,
    selectedPermissions,
}: {
    variant: "horizontal" | "vertical";
    selectedPermissions?: Record<string, boolean>;
}) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const id = user?._id;
    const userId = id.toString() || "";

    const onSuccess = (response: any) => {
        dispatch(
            setUser({
                ...user,
                emailAuthorized: true,
            })
        );
        dispatch(setAuthorizeModal(false));
        dispatch(setSuccessNotification(response.message));
    };

    return (
        <Stack direction={variant === "vertical" ? "column" : "row"} alignItems="center" spacing={2}>
            <GmailConnect
                variant={variant}
                onSuccess={onSuccess}
                extraQueryParameters={{ userId: userId }}
                selectedPermissions={selectedPermissions}
            />
            <OutlookConnect
                variant={variant}
                onSuccess={onSuccess}
                extraQueryParameters={{ userId: userId }}
                selectedPermissions={selectedPermissions}
            />
        </Stack>
    );
}

interface PermissionsCheckboxProps {
    label: string;
    description: string;
    checked: boolean;
    disabled?: boolean;
    onChange: () => void;
}

const PermissionsCheckbox: React.FC<PermissionsCheckboxProps> = ({
    label,
    description,
    checked,
    disabled,
    onChange,
}) => {
    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={onChange} />}
            label={
                <Box>
                    <Typography>{label}</Typography>
                    <Typography fontSize={12} color="#878787">
                        {description}
                    </Typography>
                </Box>
            }
            sx={{
                alignItems: "flex-start",
                gap: "0.5rem",
                "& .MuiCheckbox-root": {
                    padding: "2px",
                },
            }}
            disabled={disabled}
        />
    );
};

export default function MailAuthModal() {
    const dispatch = useDispatch();
    const continueFlow = useSelector(selectContinueFlow);
    const canContinueFlow = useSelector(selectCanContinueFlow);

    const closeAuthorizeModal = () => {
        dispatch(setAuthorizeModal(false));
    };

    const [selectedPermissions, setSelectedPermissions] = useState<Record<string, boolean>>({
        EMAIL: true,
        CALENDAR: false,
    });

    const handlePermissionChange = (permission: string) => {
        setSelectedPermissions((prev) => ({
            ...prev,
            [permission]: !prev[permission],
        }));
    };

    return (
        <BaseModal
            onClose={closeAuthorizeModal}
            overlayStyles={{
                width: 550,
                padding: "1rem 2rem 1rem 1.5rem",
            }}
        >
            <Typography fontSize={20} fontWeight={500} color="#334d6e" mb={4}>
                Choose your email provider
            </Typography>
            <CustomStack
                direction="column"
                alignItems="left"
                justifyContent="center"
                spacing={2}
                sx={{
                    opacity: 1,
                    width: "100%",
                    marginBottom: "1rem",
                    px: "1rem",

                    "&:hover": {
                        boxShadow: "0 0 0 0 rgba(0,123,255,.5)",
                    },
                }}
            >
                <PermissionsCheckbox
                    label="Authorize Email"
                    description="Connect effortlessly with your contacts and view all conversations in SuperInbox for a streamlined communication experience"
                    checked={selectedPermissions.EMAIL}
                    onChange={() => handlePermissionChange("EMAIL")}
                    disabled
                />

                <PermissionsCheckbox
                    label="Seamless Calendar Sync (Coming Soon...)"
                    description="Integrate your calendar to effortlessly organize your day and stay ahead of your meetings"
                    checked={selectedPermissions.CALENDAR}
                    onChange={() => handlePermissionChange("CALENDAR")}
                    disabled
                />
            </CustomStack>
            <MailAuthConnect variant="horizontal" selectedPermissions={selectedPermissions} />
            {canContinueFlow ? (
                <Typography
                    mt={1}
                    fontSize={14}
                    color="#bbb"
                    textAlign="end"
                    fontStyle="italic"
                    sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                    }}
                    onClick={continueFlow}
                >
                    Skip
                </Typography>
            ) : null}
        </BaseModal>
    );
}
