import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import { selectRocketReachRadius, setValues } from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

export function Radius({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectRocketReachRadius);

    const handleChange = (value: string) => {
        dispatch(setValues({ key: id, value: [{ value, excluded: false }] }));
    };

    return (
        <FormControl fullWidth>
            <InputLabel size="small" id={id}>
                Radius
            </InputLabel>
            <Select
                id={id}
                size="small"
                label="Radius"
                value={values[0]?.value || ""}
                onChange={(e) => handleChange(e.target.value)}
                MenuProps={{
                    sx: {
                        "& .MuiPaper-root.MuiList-root.MuiButtonBase-root": {
                            fontSize: "14px",
                        },
                    },
                }}
            >
                {Object.entries(options).map(([label, miles]) => (
                    <MenuItem key={label} value={miles}>
                        {miles} miles
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
