import LockOpen from "@mui/icons-material/LockOpen";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectSelectedCandidates, unlockCandidatesResume } from "../index.reducer";

function UnlockCandidatesResume() {
    const selectedCandidateIds = useSelector(selectSelectedCandidates).map((c) => c.candidateId);

    const isLoading = useSelector(checkIfLoading(unlockCandidatesResume.type));

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(unlockCandidatesResume({}));
    };

    return (
        <Tooltip placement="top" title={"Unlock resume"}>
            <IconButton color="primary" disabled={!selectedCandidateIds?.length || isLoading} onClick={handleClick}>
                {isLoading ? <CircularProgress color="primary" size={20} /> : <LockOpen fontSize="inherit" />}
            </IconButton>
        </Tooltip>
    );
}

export default UnlockCandidatesResume;
