import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";

import styles from "../MarketingPage.module.css";
import { setMarketingCompanyVideo, setMarketingCompanyVideoLink } from "../MarketingPage.reducer";
import { uploadFile } from "../../../../../utils/file";

const UploadVideo = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const [videoLink, setVideoLink] = useState<null | string>(null);
    const [video, setVideo] = useState<any>(null);

    const handleUpdateVideoLink = (link: string) => {
        const embeddedLink = link.replace("watch?v=", "embed/");
        setVideoLink(embeddedLink);
    };

    async function uploadVideo(file?: File) {
        if (!file) return;
        setIsUploading(true);
        const response = await uploadFile(file, "marketing");
        if (Array.isArray(response)) {
            setVideo(response);
        }
        setIsUploading(false);
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>
                <Typography className={styles.uploadTitle}>Edit video</Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: "40px" }}>
                <Stack>
                    <Typography sx={{ fontSize: "12px" }}>Supported format: .mp4</Typography>
                    <TextField
                        type="file"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => uploadVideo(e.target.files?.[0])}
                    />
                    <Typography my="10px" alignSelf="center">
                        OR
                    </Typography>

                    <TextField
                        placeholder="Paste your youtube video link"
                        sx={{ marginBottom: "20px" }}
                        onChange={(e) => handleUpdateVideoLink(e.target.value)}
                    />
                    <Stack flexDirection="row" justifyContent="flex-end" mt="30px">
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            sx={{
                                width: "fit-content",
                                alignSelf: "flex-end",
                                marginRight: "10px",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!video && !videoLink}
                            onClick={() => {
                                if (video && video[0]) {
                                    dispatch(setMarketingCompanyVideo(video[0]));
                                }
                                if (videoLink) {
                                    dispatch(setMarketingCompanyVideoLink(videoLink));
                                }
                                onClose();
                            }}
                            variant="contained"
                            sx={{
                                width: "fit-content",
                                alignSelf: "flex-end",
                            }}
                        >
                            {isUploading ? "Uploading..." : "Submit"}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default UploadVideo;
