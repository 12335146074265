import { CircularProgress } from "@mui/joy";
import Checkbox from "@mui/joy/Checkbox";
import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import { useRef, useState } from "react";

import VirtualizedSelect from "../../../components/VirtualizedSelect";
import useOnClickOutside from "../../../hooks/useClickOutside";

interface DropdownProps {
    value: number[];
    onChange: (value: number[]) => void;
    data: { _id: number; name: string }[];
    excludeAllMembers?: boolean;
    isLoading?: boolean;
}

function DropdownContent(props: DropdownProps) {
    const { onChange, value, data, excludeAllMembers, isLoading } = props;
    const userNames = data?.filter(item => value?.includes(item?._id)).map(item => item?.name);
    const displayUserName = userNames?.join(", ");
    const allSelected = data?.length === value?.length && value?.length > 0;

    const handleCheckboxChange = (id: number) => {
        if (value?.includes(id)) {
            onChange(value?.filter((item) => item !== id));
        } else {
            onChange([...value, id]);
        }
    };

    const handleSelectAll = () => {
        const allIds = data?.map((item) => item?._id) || [];
        onChange(allIds);
    };

    const handleDeselectAll = () => {
        onChange([]);
    };

    const [_listboxOpen, setOpenListbox] = useState<boolean>(false);
    const selectRef = useRef<HTMLButtonElement>(null);
    const searchBarRef = useRef<HTMLInputElement>(null);
    const listboxRef = useRef<HTMLUListElement>(null);
    useOnClickOutside(listboxRef, () => setOpenListbox(false), [searchBarRef?.current, selectRef?.current]);

    const defaultOption = excludeAllMembers
        ? { label: "Select Members", value: "0" }
        : allSelected
            ? { label: "Deselect All", value: "0", onClick: handleDeselectAll }
            : {
                label: "Select All",
                value: "0",
                onClick: () => {
                    handleSelectAll();
                },
            };

    return (
        <VirtualizedSelect
            placeholder={displayUserName || "Select Members"}
            value={"Select Members"}
            sx={{
                maxWidth: "180px",
                minWidth: "180px",
            }}
            inputProps={{
                placeholder: "Search here",
            }}
            size="sm"
            variant="soft"
            options={data?.map((m) => ({ label: m?.name, value: m?._id?.toString(), ...m }))}
            onChange={(option) => onChange(option?.map((o: any) => parseInt(o?.value)))}
            defaultOption={defaultOption}
            enableSearch={data?.length > 10}
            renderOption={(option) => (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                    onClick={() => handleCheckboxChange(parseInt(option?.value))}
                >
                    {option?.label}
                    <Checkbox checked={value.includes(parseInt(option?.value))} />
                </div>
            )}
            endDecorator={isLoading ? <CircularProgress size="sm" variant="soft" sx={{ '--CircularProgress-size': '17px' }}/> : null}
        />
    );
}

export default function MultiSelectDropdown(props: DropdownProps) {
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <DropdownContent {...props} />
        </CssVarsProvider>
    );
}
