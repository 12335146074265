import { useWebSocketContext } from "./WebSocketContext";
import { projectLevelNotificationEvents } from "./sockets";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPhoneEnrich, setEmailEnrich, setCandidates } from "@/store/reducers/project/project.reducer";
import useFetchCandidatesProject from "./useFetchCandidatesProject";
import { getSessionStorage } from "@/hooks/useSessionStorage";
import { selectCurrProject } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    setIsSuggestionsListEmpty,
    setSuggestedCriteria,
} from "@/store/reducers/vetting-criteria/vettingCriteria.slice";
interface InsightsData {
    status?: string;
    [key: string]: any;
}

export const useProjectsSocket = (projectId) => {
    const dispatch = useDispatch();
    const { useSocketListeners, emit, isConnected } = useWebSocketContext();

    const [phoneEnrichData, setPhoneEnrichData] = useState({});
    const [emailEnrichData, setEmailEnrichData] = useState({});

    const [generateInsightsData, setGenerateInsightsData] = useState<any>({});
    const tabUuid = getSessionStorage.read("tabUuid");
    const projectData = useSelector(selectCurrProject);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (projectId && isConnected) {
                emit(projectLevelNotificationEvents.UNREGISTER_PROJECT_REQ, { projectId, tabId: tabUuid });
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        if (projectId && isConnected) {
            emit(projectLevelNotificationEvents.REGISTER_PROJECT_REQ, { projectId, tabId: tabUuid });
        }
        if (!isConnected && candidates.length > 0 && Number(projectId) === Number(projectIdData)) {
            dispatch(setCandidates(candidates));
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);

            if (!isConnected) {
                return;
            }

            if (isConnected && projectId) {
                console.log("Unregistering project", projectId, tabUuid, isConnected);
                emit(projectLevelNotificationEvents.UNREGISTER_PROJECT_REQ, { projectId, tabId: tabUuid });
            }
        };
    }, [projectId, tabUuid, isConnected]);

    // Helper function for enrichment updates
    const handleEnrichment = (data, enrichType, setDataCallback) => {
        const { status } = data;
        const isCompleted = status === "COMPLETED";
        const isInProgress = status === "IN_PROGRESS";
        if (enrichType === "phone") {
            dispatch(setPhoneEnrich({ phoneEnrich: isInProgress }));
        } else if (enrichType === "email") {
            dispatch(setEmailEnrich({ emailEnrich: isInProgress }));
        }
        if (isCompleted || isInProgress) {
            setDataCallback(data);
        }
    };

    const { candidates, projectIdData } = useFetchCandidatesProject({
        projectId,
        generateInsightsStatus: generateInsightsData?.status,
        setGenerateInsightsData,
        isConnected,
        emit,
        useSocketListeners,
    });

    // Setup socket listeners
    useSocketListeners([
        {
            event: projectLevelNotificationEvents.INSIGHTS_GENERATION_PROGRESS_RES,
            callback: (data) => setGenerateInsightsData(data),
        },
        {
            event: projectLevelNotificationEvents.PHONE_ENRICHMENT_PROGRESS_RES,
            callback: (data) => {
                handleEnrichment(data, "phone", setPhoneEnrichData);
            },
        },
        {
            event: projectLevelNotificationEvents.EMAIL_ENRICHMENT_PROGRESS_RES,
            callback: (data) => handleEnrichment(data, "email", setEmailEnrichData),
        },
        {
            event: projectLevelNotificationEvents.SYNC_AI_SUGGESTION,
            callback: (data) => {
                dispatch(setSuggestedCriteria(data?.data?.suggestedVettingCriteria || []));
                dispatch(setIsSuggestionsListEmpty(data?.data?.suggestedVettingCriteria?.length === 0));
            },
        },
        {
            event: projectLevelNotificationEvents.SYNC_AI_SUGGESTION_APPLIED,
            callback: (data) => {
                dispatch(setSuggestedCriteria(data?.data?.vettingCriteria || []));
                dispatch(setIsSuggestionsListEmpty(data?.data?.suggestedVettingCriteria?.length === 0));
            },
        },
    ]);

    return {
        phoneEnrichData,
        emailEnrichData,
        generateInsightsData,
        setGenerateInsightsData,
        candidates,
    };
};
