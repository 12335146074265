import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loader from "../../components/Loader/Loader";
import Navbar from "../../components/Navbar/Navbar";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { getProject } from "../allProjects/index.reducer";
import ProjectHeader from "../project/components/header/Header";
import ProjectStats from "../project/components/projectStats/ProjectStats";
import { selectCandidateCurrentPage, setSelectedCandidates, setShowByPersonalEmails } from "../project/index.reducer";
import StatsTab from "./components/statsTab/statsTab";
import style from "./projectStatistics.module.scss";

export default function ProjectStatistics() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const candidatePageNo = useSelector(selectCandidateCurrentPage);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));

    useEffect(() => {
        dispatch(getProject({ projectId: id, action: getProject.type }));
        window.history.replaceState({}, document.title);

        return () => {
            dispatch(setShowByPersonalEmails(false));
            dispatch(
                setSelectedCandidates({
                    candidateId: [],
                    deselectAll: candidatePageNo,
                })
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isProjectLoading) return <Loader />;
    return (
        <div className={style["sp__container"]}>
            <Navbar />
            <ProjectHeader />
            <div className={style["sp__actionbar"]}>
                <ProjectStats />
            </div>
            <StatsTab />
        </div>
    );
}
