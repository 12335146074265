import { CircularProgress, Tooltip } from "@mui/material";
import millify from "millify";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TypeAnimation } from "react-type-animation";

import {
    fetchCandidates,
    selectCandidates,
    selectCandidatesFetchStatus,
    selectSalesNavScrapStatus,
    selectSearch,
    selectSupersetCount,
    selectTotalCandidates,
    sendEmailNotification,
    setCandidatesFetchStatus,
    setSearch,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { endTracking, startTracking } from "../../../utils/sentry";
import AddToProject from "../AddToProject";
import CandidateCard from "../CandidateCard";
import { useProjectData } from "../hooks";
import { DisplayMessage } from "../molecules/DisplayMessage";
import { TypingLoader } from "../molecules/TypingLoader";

import VirtualizedList from "@/components/VirtualizedList";
import { checkUniverseCountEnabled } from "@/store/reducers/signin/Signin.reducer";
import { Typography } from "@/ui";

function useFetchCandidatesEveryMinute() {
    const dispatch = useDispatch();
    const { _id: searchId, status, updatedAt } = useSelector(selectSearch);
    const { projectId } = useProjectData();
    const intervalRef = useRef<null | NodeJS.Timeout>(null);
    const fetchCount = useRef(0);
    const errorCondn = !["COMPLETED", "CANCELLED", "ZERO_PROFILE"].includes(status || "");
    const [transaction, setTransaction] = useState(null);

    useEffect(() => {
        if (searchId) {
            const tracking = startTracking("Blended search salesnav results", "EasySource");
            setTransaction(tracking);
            dispatch(fetchCandidates(searchId));
            fetchCount.current = 1;
        }
    }, [searchId, updatedAt]);

    useEffect(() => {
        if (intervalRef?.current && errorCondn) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            endTracking(transaction);
        }

        if (searchId && fetchCount.current < 60 && errorCondn) {
            intervalRef.current = setInterval(() => {
                fetchCount.current += 1;
                if (fetchCount.current === 60) {
                    endTracking(transaction);
                    clearInterval(intervalRef.current!);
                    if (status === "IN_PROGRESS") {
                        dispatch(
                            setSearch({
                                _id: searchId,
                                updatedAt,
                                status: "COMPLETED",
                            })
                        );
                    } else {
                        dispatch(sendEmailNotification({ projectId, searchId }));
                        dispatch(setCandidatesFetchStatus("ERROR"));
                        dispatch(
                            setSearch({
                                _id: searchId,
                                updatedAt,
                                status: "CANCELLED",
                            })
                        );
                    }
                } else {
                    dispatch(fetchCandidates(searchId));
                }
            }, 5000);
        }

        return () => {
            if (intervalRef?.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [status, searchId]);
}

export function CandidateList() {
    const { status } = useSelector(selectSearch);
    const candidates = useSelector(selectCandidates);
    const totalCandidates = useSelector(selectTotalCandidates);
    const supersetCount = useSelector(selectSupersetCount);
    const candidatesFetchStatus = useSelector(selectCandidatesFetchStatus);
    const salesNavScrapStatus = useSelector(selectSalesNavScrapStatus);
    const universeCountEnabled = useSelector(checkUniverseCountEnabled);
    const containerRef = useRef<HTMLDivElement>(null);
    useFetchCandidatesEveryMinute();

    if (candidatesFetchStatus === "IDLE") {
        return null;
    }

    if (
        (candidatesFetchStatus === "LOADING" && status === "PENDING") ||
        (status === "IN_PROGRESS" && !candidates.length)
    ) {
        return <TypingLoader />;
    }

    const zeroProfile = status === "ZERO_PROFILE";
    const cancelledStatus = status === "CANCELLED";
    const errorCandidateStatus = candidatesFetchStatus === "ERROR";

    return (
        <>
            <DisplayMessage
                visible={errorCandidateStatus}
                text="AI scraping agents are busy right now. Search results typically become available within 15-30 minutes of this message"
            />
            <DisplayMessage
                visible={!errorCandidateStatus && cancelledStatus}
                text="Our AI Agents are working hard to get best profiles matching your requirements. Do visit later to have a look!"
            />
            <DisplayMessage
                visible={!errorCandidateStatus && !cancelledStatus && zeroProfile}
                text="Our AI Agents were not able to find relevant profiles matching your requirements. Please modify your search and try again."
            />

            <div className="flex flex-col h-full">
                <div className="flex flex-col bg-white h-[calc(100%-55px)] rounded-md p-2 gap-1 border border-[rgba(0,0,0,0.1)]">
                    {candidates?.length ? (
                        <>
                            <div className="flex flex-row items-center justify-between mb-1">
                                <div className="flex flex-row items-center gap-1">
                                    <Typography className="font-semibold">Candidates Preview</Typography>
                                    {status === "IN_PROGRESS" && salesNavScrapStatus === "BACKUP" && (
                                        <TypeAnimation
                                            sequence={[
                                                "The AI is trying a broader search to get you relevant candidates...",
                                                2000,
                                            ]}
                                            speed={50}
                                            style={{
                                                fontStyle: "italic",
                                                fontWeight: 500,
                                                fontSize: 14,
                                                color: "#0891b2",
                                            }}
                                        />
                                    )}
                                </div>

                                <Tooltip title="This amount is based on estimate and actual amount may change">
                                    <Typography className="flex text-[12px] item-center">
                                        {status === "IN_PROGRESS" && (
                                            <CircularProgress size={12} color="primary" sx={{ mr: 1 }} />
                                        )}
                                        <i>
                                            {totalCandidates} candidates{" "}
                                            {`${universeCountEnabled && supersetCount ? `(estimated universe of ${supersetCount > 2000 ? "2k+" : millify(supersetCount)} candidates)` : ""}`}
                                        </i>
                                    </Typography>
                                </Tooltip>
                            </div>
                            <hr className="border-t border-gray-300" />
                            <div className="flex flex-col overflow-y-hidden">
                                <div ref={containerRef} style={{ overflowY: "scroll" }}>
                                    {candidates.length < 25 ? (
                                        candidates.map((candidate, index) => (
                                            <div key={index} className="py-1">
                                                <CandidateCard {...candidate} />
                                            </div>
                                        ))
                                    ) : (
                                        <VirtualizedList
                                            containerRef={containerRef}
                                            numItems={candidates.length}
                                            itemHeight={130}
                                            renderItem={({ index, ...rest }) => {
                                                const candidate = candidates[index];
                                                return (
                                                    <div key={index} {...rest}>
                                                        <CandidateCard {...candidate} />
                                                    </div>
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    ) : null}
                    <DisplayMessage
                        visible={!errorCandidateStatus && !cancelledStatus && !zeroProfile && !candidates?.length}
                        sx={{ textAlign: "center" }}
                        text="No candidates found. <br/> <br/> Try fewer filters or different filters to broaden your pool and get started. <br/> You can always narrow this down with AI later"
                    />
                </div>
                <AddToProject />
            </div>
        </>
    );
}
