import { BarChart } from "@mui/x-charts/BarChart";
import { useTranslation } from "react-i18next";

import EmptyChartState from "./EmptyChartState";

import { EChannelLabel, EFunnelOverview } from "../../../../../store/apis/analytics/analytics.enums";
import { defaultChartColors, defaultChartSetting } from "../../../utils";
import { FunnelViewProps } from "../FunnelView";

interface ChannelBarChartProps extends FunnelViewProps {}

export default function ChannelBarChart(props: ChannelBarChartProps) {
    const { t } = useTranslation();
    const { selectedOverview, data } = props;

    if (!selectedOverview) {
        return <EmptyChartState>Select funnel stage from above to display split</EmptyChartState>;
    }

    if (selectedOverview.key === EFunnelOverview.RELEVANT_CANDIDATES) {
        return <EmptyChartState>{t("analytics.channelBarRelevantCandidateTitle")}</EmptyChartState>;
    }

    const series = data?.graphs?.channelGraph?.[selectedOverview["key"]] ?? [];

    if (!series.length) {
        return <EmptyChartState>No split data is available for this stage</EmptyChartState>;
    }

    return (
        <BarChart
            disableAxisListener
            series={series.map((item: any) => ({
                data: [item.count],
                stack: "A",
                label: (EChannelLabel as any)[item._id],
            }))}
            tooltip={{ trigger: "item" }}
            layout="horizontal"
            margin={{ left: 0, top: 5, bottom: 0 }}
            slotProps={{
                bar: {
                    rx: 5,
                },
                legend: {
                    direction: "row",
                    position: { vertical: "top", horizontal: "middle" },
                    padding: 18,
                    itemGap: 12,
                },
            }}
            {...{
                ...defaultChartSetting,
                height: 50,
                colors: [defaultChartColors[2], defaultChartColors[1], defaultChartColors[0]],
            }}
        />
    );
}
