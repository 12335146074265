import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Email from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import MessagesPaneHeaderLoader from "./MessagePaneHeaderLoader";

import CopyableText from "../../../../atoms/CopyableText";
import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import {
    CandidateDetails,
    useFetchCandidateProjectsQuery,
} from "../../../../store/apis/all-candidates/all-candidates.api";
import { useSuperInboxContext } from "../../Context";
import { sortProjectsByStatus, toggleMessagesPane } from "../../utils";

import { ContactTypeTooltip } from "@/components/ContactTypeTooltip";
import { selectUser } from "@/pages/Signin/Signin.reducer";

interface MessagesPaneHeaderProps {
    candidateProfile?: CandidateDetails;
    loading?: boolean;
}

const getPrefferedEmails = (
    emailSendType: string,
    emailWithTypes: { personal?: string[]; professional?: string[]; primary?: string }
) => {
    if (!emailWithTypes) return [];

    let preference;
    if (["ONLY_PROFESSIONAL", "PROFESSIONAL_PREFERRED"].includes(emailSendType)) {
        preference = "professional";
    } else if (["ONLY_PERSONAL", "PERSONAL_PREFERRED"].includes(emailSendType)) {
        preference = "personal";
    }

    // get all the emails of the preferred type
    const preferredEmails = emailWithTypes[preference] || [];

    return preferredEmails;
};

export default function MessagesPaneHeader(props: MessagesPaneHeaderProps) {
    const {
        candidateParamId,
        filters: { projectId },
    } = useSuperInboxContext();
    const { candidateProfile, loading } = props;
    const { data: candidateProjects, isLoading: loadingCandidateProjects } =
        useFetchCandidateProjectsQuery(candidateParamId);
    const user = useSelector(selectUser);

    const candidateActiveProjectId = sortProjectsByStatus(candidateProjects)?.[0]?.projectId;
    const activeProjectId = projectId || candidateActiveProjectId;

    if (loading || loadingCandidateProjects) return <MessagesPaneHeaderLoader />;

    const { emailSendType } = user;
    const userPrefferedEmails = getPrefferedEmails(emailSendType, candidateProfile?.emailWithTypes);

    const onClickCandidateName = () => {
        window.open(
            `/projects/${activeProjectId}?${queryString.stringify({
                query: candidateProfile?.name,
                candidateId: candidateParamId,
            })}`
        );
    };

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
                borderBottom: "1px solid",
                borderColor: "divider",
                backgroundColor: "background.body",
            }}
            py={{ xs: 2, md: 2 }}
            px={{ xs: 1, md: 2 }}
        >
            <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{
                        display: { xs: "inline-flex", sm: "none" },
                    }}
                    onClick={() => toggleMessagesPane()}
                >
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>

                <Avatar size="lg" src={candidateProfile?.profileImage || ""} />

                <div>
                    <Box display="flex" gap={0.5}>
                        <HoverTruncateText
                            fontWeight="lg"
                            fontSize="lg"
                            component="h2"
                            maxWidth={250}
                            {...(activeProjectId && {
                                sx: { cursor: "pointer" },
                                onClick: onClickCandidateName,
                            })}
                        >
                            {candidateProfile?.name}
                        </HoverTruncateText>

                        {candidateProfile?.profileUrl && (
                            <Link style={{ display: "inline-flex" }} to={candidateProfile.profileUrl} target={"_blank"}>
                                <LinkedInIcon htmlColor="#0072B1" />
                            </Link>
                        )}
                    </Box>
                    {candidateProfile?.phone && (
                        <CopyableText
                            fontSize={14}
                            message="Phone number copied successfully"
                            textToCopy={candidateProfile.phone}
                        >
                            <ContactTypeTooltip
                                profileUrl={candidateProfile.profileUrl}
                                phones={[candidateProfile.phone]}
                                contactType="phone"
                            >
                                <div>{candidateProfile.phone}</div>
                            </ContactTypeTooltip>
                        </CopyableText>
                    )}
                    {userPrefferedEmails?.length ? (
                        <CopyableText
                            fontSize={14}
                            message="email copied successfully"
                            textToCopy={candidateProfile?.email[0]}
                        >
                            <ContactTypeTooltip
                                profileUrl={candidateProfile?.profileUrl}
                                emails={candidateProfile?.email}
                                contactType="email"
                                emailResponseStats={candidateProfile?.emailResponseStats}
                                professionalEmailsWithoutEmptyValues={candidateProfile?.emailWithTypes[
                                    "professional"
                                ]?.filter((i: string) => Boolean(i))}
                            >
                                <div>{userPrefferedEmails[0]}</div>
                            </ContactTypeTooltip>
                        </CopyableText>
                    ) : (
                        <>
                            {!!candidateProfile?.email?.length && (
                                <ContactTypeTooltip
                                    profileUrl={candidateProfile?.profileUrl}
                                    emails={candidateProfile?.email}
                                    contactType="email"
                                    emailResponseStats={candidateProfile?.emailResponseStats}
                                    professionalEmailsWithoutEmptyValues={candidateProfile?.emailWithTypes[
                                        "professional"
                                    ]?.filter((i: string) => Boolean(i))}
                                >
                                    <Email sx={{fontSize:"18px", marginLeft:"5px"}} />
                                </ContactTypeTooltip>
                            )}
                        </>
                    )}
                </div>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
                {/* <Button
                    color="neutral"
                    variant="outlined"
                    size="sm"
                    sx={{
                        display: { xs: "none", md: "inline-flex" },
                    }}
                >
                    View candidateProfile
                </Button> */}
                {/* <IconButton size="sm" variant="plain" color="neutral">
                    <MoreVertRoundedIcon />
                </IconButton> */}
            </Stack>
        </Stack>
    );
}
