import { Fragment } from "react";
import Divider from "@mui/material/Divider";
import Stack, { StackProps } from "@mui/material/Stack";
import { SectionProps } from "./Section";
import AnalyticsLoader from "../AnalyticsLoader";

interface HeaderProps extends StackProps {
    divider?: boolean;
}

export default function Header(props: HeaderProps & SectionProps) {
    const { children, divider, fetching, ...rest } = props;
    return (
        <Fragment>
            {children && (
                <Stack mb={1} display="flex" flexDirection="row" alignItems="center" {...rest} flexWrap="wrap" gap={1}>
                    {children}
                </Stack>
            )}
            {divider && <Divider />}
            <AnalyticsLoader loading={fetching} />
        </Fragment>
    );
}
