import ConfirmationModal from "@/components/ConfirmationModal";

interface ShareListModalProps {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
}


export default function DeleteConfirmationModal({ open, onClose, onDelete }: ShareListModalProps) {

    if (!open) {
        return null;
    }

    return (
        <ConfirmationModal
            title="Are you sure you want to delete?"
            open={open}
            onClose={onClose}
            submit={onDelete}
            submitText="Delete"
        />
    );
}
