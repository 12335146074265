import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import { cancelActions, fetchContactListItems, setContactListItems } from "./contact-list-items.slice";
import { ContactListItem, FetchContactListItemsResponse } from "./contact-list-items.type";
import handleException from "../../utils/sentry";

function* fetchContactListItemsSaga(action: PayloadAction<string>): SagaIterator {
    try {
        const id = action.payload;
        yield put(startAction({ action: action.type }));

        const response: FetchContactListItemsResponse = yield call(new API().get, `/contact-list/${id}`);

        if (!("data" in response)) {
            throw new Error("No data in response");
        }

        const { data } = response;

        const contactList = data.map(({ _id, company, email, firstName, lastName }) => ({
            id: _id,
            name: `${firstName} ${lastName}`,
            email,
            company,
        }));

        yield put(setContactListItems(contactList as ContactListItem[]));
    } catch (error) {
        handleException(error);
        console.error("**error while fetching base templates", { error });
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchContactListItems.type, fetchContactListItemsSaga),
    ];
    // @ts-ignore
    yield takeLatest(cancelActions.type, CancelSagas, tasks);
}
