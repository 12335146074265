import { useSelector } from "react-redux";

import { useCheckProjectInProgress } from "./useCheckProjectInProgress";

import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { getProjectCandidates } from "@/store/reducers/project/project.reducer";

function useCheckProjectCandidatesLoading() {
    const isCandidatesLoading = useSelector(checkIfLoading(getProjectCandidates.type));
    const isProjectInProgress = useCheckProjectInProgress();

    return isCandidatesLoading && !isProjectInProgress;
}

export { useCheckProjectCandidatesLoading };
