import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";

interface MessagesPaneHeaderLoaderProps {}

export default function MessagesPaneHeaderLoader(_props: MessagesPaneHeaderLoaderProps) {
    return (
        <Stack spacing={2}>
            <Card variant="plain">
                <CardContent orientation="horizontal">
                    <Skeleton animation="wave" variant="circular" width={48} height={48} />
                    <Box width="100%" flex={1}>
                        <Skeleton animation="wave" variant="text" sx={{ width: { xs: "60%", lg: "30%" } }} />
                        <Skeleton
                            animation="wave"
                            variant="text"
                            level="body-sm"
                            sx={{ width: { xs: "80%", lg: "60%" } }}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Stack>
    );
}
