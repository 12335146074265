import useMenu from "../../../hooks/useMenu";
import CustomButton from "../../../components/CustomButton";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Menu, MenuItem } from "@mui/material";
import { Option } from "../../../common";
import { getCandidateStageColor } from "../../all-candidates/AllCandidates";

export type ProjectStatusDropDownAction = {
    label: string;
    value: string;
    handleClick: () => void;
};

type ProjectStatusDropDownProps = {
    selectedOption: Option;
    actions: ProjectStatusDropDownAction[];
    isLoading: boolean;
};

export function ProjectStatusDropDown({ selectedOption, actions, isLoading = false }: ProjectStatusDropDownProps) {
    const { label, value } = selectedOption;
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();

    const handleMenuItemClick = (callback: () => void) => {
        callback();
        handleClose();
    };

    return (
        <>
            <CustomButton
                disabled={isLoading}
                size="small"
                sx={{
                    border: 0,
                    borderRadius: "0.3rem",
                    fontSize: 10,
                    height: "22px",
                    minWidth: 48,
                    boxShadow: "none",
                    "& .MuiButton-endIcon": {
                        marginLeft: 0.5,
                    },
                    backgroundColor: `${getCandidateStageColor(value)}.light`,
                    "&:hover": {
                        boxShadow: "none",
                        opacity: 0.5,
                    },
                }}
                variant="contained"
                onClick={(e) => {
                    e.stopPropagation();
                    handleClick(e);
                }}
                endIcon={<KeyboardArrowDownRoundedIcon />}
            >
                {label}
            </CustomButton>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiMenuItem-root": {
                        fontSize: 13,
                        borderRadius: "0.5rem",
                        padding: "6px 10px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    handleClose();
                }}
                onBlur={(e) => e.stopPropagation()}
            >
                {actions.map(({ label, handleClick, value }) => {
                    return (
                        <MenuItem
                            key={value}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleMenuItemClick(handleClick);
                            }}
                        >
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
