import { Box, Skeleton } from "@mui/material";
import { BarChart } from "@mui/x-charts";

import { defaultChartSettingVertical } from "../../../utils";

type ClubbableBarChartProps = {
    data: UserData[];
    isFetching?: boolean;
    isLoading?: boolean;
};

interface UserCounts {
    relevantCandidates: number;
    totalContacted: number;
    responsesReceived: number;
    positiveResponses: number;
}

interface UserData {
    userName: string;
    data: {
        counts: UserCounts;
    };
}

interface TransformedData {
    type: string;
    [key: string]: string | number;
}

export default function ClubbableBarChart({ data, isFetching, isLoading }: ClubbableBarChartProps) {
    const transformData = (data: UserData[]): TransformedData[] => {
        if (!data || data.length < 1) return [];

        const userNames = data.map((user) => user.userName.toLowerCase().split(" ")[0]);

        const types = ["relevantCandidates", "totalContacted", "responsesReceived", "positiveResponses"];
        const typeLabels: { [key in keyof UserCounts]: string } = {
            relevantCandidates: "Relevant candidates",
            totalContacted: "Total contacted",
            responsesReceived: "Responses received",
            positiveResponses: "Positive responses",
        };

        const dataset: TransformedData[] = types.map((type) => {
            const entry: TransformedData = { type: typeLabels[type as keyof UserCounts] };
            userNames.forEach((userName, index) => {
                entry[userName] = data[index].data.counts[type as keyof UserCounts];
            });
            return entry;
        });

        return dataset;
    };

    const dataset = transformData(data);

    const valueFormatter = (value: any) => {
        return value;
    };

    const series = data?.map((user: UserData) => ({
        dataKey: user?.userName.toLowerCase().split(" ")[0],
        label: user?.userName,
        valueFormatter,
    }));

    const isDataEmpty = dataset?.every((data) => {
        return Object.keys(data).every((key) => key === "type" || data[key] === 0);
    });

    return (
        <>
            {isLoading ? (
                <Box sx={{ width: 1000, marginTop: "10px" }}>
                    <Skeleton variant="rectangular" height={250} />
                    <Skeleton animation="wave" />
                </Box>
            ) : dataset?.length > 0 && !isDataEmpty ? (
                <BarChart
                    dataset={dataset ?? []}
                    margin={{ top: 30, bottom: 100 }}
                    xAxis={[
                        {
                            id: "quarters",
                            scaleType: "band",
                            disableLine: true,
                            dataKey: "type",
                        },
                    ]}
                    yAxis={[
                        {
                            id: "total",
                            scaleType: "linear",
                            disableLine: true,
                        },
                    ]}
                    series={series}
                    {...defaultChartSettingVertical}
                    slotProps={{
                        bar: {
                            rx: 5,
                            ry: 5,
                        },
                        legend: {
                            direction: "row",
                            position: { vertical: "bottom", horizontal: "middle" },
                        },
                    }}
                />
            ) : (
                <Box
                    sx={{
                        width: 1000,
                        marginTop: "10px",
                        height: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <div> Oops, your comparison chart is unavailable at the moment.</div>
                    <div>
                        {" "}
                        Please contact your account manager or{" "}
                        <a
                            style={{ color: "#00B7C7", textDecoration: "none" }}
                            href="mailto:customer-support@hirequotient.com"
                        >
                            customer-support@hirequotient.com
                        </a>{" "}
                        for assistance.
                    </div>
                </Box>
            )}
        </>
    );
}
