import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import AutocompleteWithExclusion from "../../../molecules/AutocompleteWithExclusion/AutocompleteWithExclusion";
import { selectCompanyHeadQuarterLocation, setValues } from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

export function CompanyHeadQuarterLocation({ id }: { id: SearchStateKeys }) {
    const { query, setQuery, isLoading, dispatch } = useSearchState(id);
    const { values, options, error } = useSelector(selectCompanyHeadQuarterLocation);

    const handleChange = (value: typeof values) => {
        dispatch(
            setValues({
                key: id,
                value: value,
            })
        );
    };

    return (
        <AutocompleteWithExclusion
            size="small"
            label="Company headquarter location"
            placeholder="Company headquarter location"
            value={values}
            options={Object.keys(options).map((i) => i)}
            onChange={handleChange}
            error={error}
            loading={isLoading}
            inputValue={query}
            onInputChange={setQuery}
        />
    );
}
