import { IndexedDBConfig } from "./interface";

export const iDBConfig: IndexedDBConfig = {
    databaseName: "HQ",
    version: 1,
    stores: [
        {
            name: "org-trackers",
            id: { keyPath: "_id", autoIncrement: true },
            indices: [{ name: "name", keyPath: "name", options: { unique: false } }],
        },
        {
            name: "org-select",
            id: { keyPath: "_id", autoIncrement: true },
            indices: [{ name: "name", keyPath: "name", options: { unique: false } }],
        },
    ],
};
