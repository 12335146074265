/* eslint-disable @typescript-eslint/no-explicit-any */
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // for unknown
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import { useDispatch } from "react-redux";

import { JoyProvider } from "./JoyProvider";

import { useGetCandidateSourceTypesQuery } from "../store/apis/projects/projects.api";

import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { Tooltip } from "@/ui";

const ContactTypeMapping: Record<string, string> = {
    ROCKETREACH: "Rocketreach",
    PROXYCURL: "Proxycurl",
    CONTACTS_OUT: "ContactOut",
    PEOPLE_DATA_LABS: "People Data Labs",
    RB2BAPI: "RB2B",
    APOLLO: "Apollo",
};

type EmailStatusIconProps = {
    finalStatus: {
        title: string;
        color: string;
        Icon: any;
    };
};

const EmailStatusIcon = (props: EmailStatusIconProps) => {
    const { title, color, Icon } = props.finalStatus;

    return (
        <Tooltip title={title} placement="left">
            <Icon sx={{ color, fontSize: "16px" }} />
        </Tooltip>
    );
};

const formatData = (data: any, type: string) => {
    return data?.map((item: any) => {
        return {
            value: item,
            contactType: type,
        };
    });
};

const useGetContactTypeData = (emails: string[], phones: string[], profileUrl?: string, cType?: "email" | "phone") => {
    const formattedEmails = formatData(emails, "email");
    const formattedPhones = formatData(phones, "phone");

    const combinedContactInfo = [...(formattedEmails ?? []), ...(formattedPhones ?? [])];

    const { data: contactsType } = useGetCandidateSourceTypesQuery(
        {
            profileUrl,
            data: combinedContactInfo,
        },
        { skip: !profileUrl || !combinedContactInfo?.length }
    );

    const filterContactType = (type) => {
        return contactsType?.sourceTypeData?.filter((item: any) => item.contactType === type);
    };

    return filterContactType(cType);
};

type ContactTypeTooltipProps = {
    children: any;
    contactTypeData?: [
        {
            value: string;
            sourceType: string[];
            contactType: "email" | "phone";
        },
    ];
    professionalEmailsWithoutEmptyValues?: string[];
    emailResponseStats?: object;
    profileUrl?: string;
    contactType?: "email" | "phone";
    emails?: string[];
    phones?: string[];
};

export const ContactTypeTooltip = ({
    children,
    contactTypeData,
    professionalEmailsWithoutEmptyValues,
    emailResponseStats,
    profileUrl,
    contactType,
    emails,
    phones,
}: ContactTypeTooltipProps) => {
    const dispatch = useDispatch();

    let finalContactTypeData = contactTypeData;
    const contactTypeDataCase2 = useGetContactTypeData(emails, phones, profileUrl, contactType);

    if (contactTypeDataCase2?.length) {
        finalContactTypeData = contactTypeDataCase2;
    }

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        dispatch(setSuccessNotification("Copied to clipboard!"));
    };

    const renderEmailStatusIcon = (email: string) => {
        const emailStatus = emailResponseStats?.[email];
        if (!emailStatus) return null;

        const { bouncifyResponse, zeroBounceStatus, zeroBounceAIScore } = emailStatus;

        const statusMap = {
            valid: { title: "Email verified", color: "green", Icon: CheckCircleOutlineIcon },
            invalid: { title: "Invalid email", color: "red", Icon: ErrorOutlineOutlinedIcon },
            failed: { title: "Email verification failed", color: "red", Icon: NewReleasesOutlinedIcon },
            unknown: { title: "Unknown", color: "orange", Icon: HelpOutlineIcon },
        };

        const responseTypeMap = {
            valid: ["deliverable", "valid"],
            invalid: ["undeliverable", "invalid", "disposable", "role-based"],
            failed: ["bouncify-api-failed", "zeroBounce-valid-api-failed"],
        };

        const getStatusFromResponse = (response: string): keyof typeof statusMap => {
            for (const [status, types] of Object.entries(responseTypeMap)) {
                if (types.includes(response)) {
                    return status as keyof typeof statusMap;
                }
            }
            return "unknown";
        };

        const checkZeroBounce = () => {
            if (zeroBounceStatus === "catch-all" && zeroBounceAIScore && zeroBounceAIScore > 8) {
                return statusMap["valid"];
            }
            return statusMap[getStatusFromResponse(zeroBounceStatus)];
        };
        const checkBouncify = () => {
            if (bouncifyResponse === "accept all" && zeroBounceAIScore && zeroBounceAIScore > 8) {
                return statusMap["valid"];
            }

            const status = getStatusFromResponse(bouncifyResponse);
            if (status === "unknown" || status === "failed") {
                return checkZeroBounce();
            }
            return statusMap[status];
        };

        const finalStatus = bouncifyResponse ? checkBouncify() : null;

        return finalStatus ? <EmailStatusIcon finalStatus={finalStatus} /> : null;
    };

    return (
        <JoyProvider>
            <Tooltip
                type="light"
                style={{
                    backgroundColor: "white",
                    border: "1px solid #e0e0e0",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    minWidth: "max-content",
                    maxWidth: "500px",
                    fontSize: "14px",
                    lineHeight: "1.5",
                    color: "#333",
                }}
                variant="outlined"
                title={
                    Array.isArray(finalContactTypeData) && finalContactTypeData?.length
                        ? finalContactTypeData.map((item: any, index: number) => {
                            const emailType = professionalEmailsWithoutEmptyValues?.includes(item?.value)
                                ? "Professional email"
                                : "Personal email";
                            const isEmail = item?.value?.includes("@");

                            return (
                                <div key={index} style={{ fontSize: "12px", marginBottom: "7px" }}>
                                    {isEmail ? (
                                        <strong style={{ color: "#00599a", fontSize: "13px" }}>{emailType}</strong>
                                    ) : (
                                        <strong style={{ color: "#00599a", fontSize: "13px" }}>Phone number</strong>
                                    )}
                                    <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
                                        <JoyProvider>{isEmail && renderEmailStatusIcon(item?.value)}</JoyProvider>
                                        <strong>{item?.value}</strong>:{" "}
                                        {/* {item.sourceType
                                            .map((type: any) => ContactTypeMapping[type] ?? type)
                                            ?.join(", ")} */}
                                        {item?.sourceType && item?.sourceType?.length > 0
                                            ? item?.sourceType
                                                .map((type: any) => ContactTypeMapping[type] ?? type)
                                                .join(", ")
                                            : "HireQuotient"}
                                        <ContentCopyIcon
                                            sx={{
                                                fontSize: "12px",
                                                "&:hover": {
                                                    color: "black",
                                                },
                                            }}
                                            onClick={() => handleCopyToClipboard(item?.value)}
                                        />
                                    </div>
                                </div>
                            );
                        })
                        : undefined
                }
            >
                {children}
            </Tooltip>
        </JoyProvider>
    );
};
