import { useEffect, useState } from "react";
import JoiStack from "@mui/joy/Stack";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    List,
    ListItemButton,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { JoyPageLayout } from "../../components/JoyPageLayout";
import { PageLoader } from "../../components/PageLoader";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { getContactReachout, selectContactReachouts } from "./all-contacts.slice";

const ContactReachout = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const isLoading = useSelector(checkIfLoading(getContactReachout.type));

    useEffect(() => {
        if (!params?.id) return;
        dispatch(getContactReachout(params.id));
    }, [params?.id]);

    return (
        <JoyPageLayout>
            {isLoading ? (
                <PageLoader />
            ) : (
                <JoiStack flexDirection="row" height="100%">
                    <ContactInfo />
                    {/* <Reachouts /> */}
                </JoiStack>
            )}
        </JoyPageLayout>
    );
};

const ContactInfo = () => {
    const contactReachouts = useSelector(selectContactReachouts);
    const { contactInfo } = contactReachouts || {};

    return !isEmpty(contactInfo) ? (
        <Stack sx={{ background: "#fff" }}>
            <Stack p="15px" justifyContent="center" alignItems="center">
                <Avatar sx={{ width: 56, height: 56 }}>{contactInfo["First Name"]?.charAt(0)}</Avatar>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>
                    {`${contactInfo["First Name"]} ${contactInfo["Last Name"]}`}
                </Typography>
            </Stack>
            <Divider />
            <Stack p="15px">
                <Stack mb="15px">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        First Name:
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        {contactInfo["First Name"]}
                    </Typography>
                </Stack>
                <Stack mb="15px">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        Last Name:
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        {contactInfo["Last Name"]}
                    </Typography>
                </Stack>
                <Stack mb="15px">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        Email:
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        {contactInfo["Email"]}
                    </Typography>
                </Stack>
                <Stack mb="15px">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        LinkedIn URL:
                    </Typography>
                    <Button sx={{ fontSize: "14px" }} href={contactInfo["LinkedIn URL"]} target="_blank" type="text">
                        {contactInfo["LinkedIn URL"]}
                    </Button>
                </Stack>

                <Stack mb="15px">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        Country/Region:
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        {contactInfo["Country/Region"]}
                    </Typography>
                </Stack>
                <Stack mb="15px">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        City:
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        {contactInfo["City"]}
                    </Typography>
                </Stack>
                <Stack mb="15px">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        Job Title:
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        {contactInfo["Job Title"]}
                    </Typography>
                </Stack>
                <Stack mb="15px">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        Company Name :
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        {contactInfo["Company Name"]}
                    </Typography>
                </Stack>
                <Stack mb="15px">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        Industry:
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        {contactInfo["Industry"]}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    ) : (
        <div>Empty Contact info</div>
    );
};

const Reachouts = () => {
    const contactReachouts = useSelector(selectContactReachouts);
    const { reachout } = contactReachouts || {};
    const [reachoutDetail, setReachoutDetail] = useState({});

    return reachout?.length ? (
        <Stack
            height="calc(100% - 50px)"
            sx={{
                marginLeft: "10px",
                background: "#fff",
                padding: "15px",
                flex: 1,
            }}
        >
            <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                Reachout:
            </Typography>
            <List
                sx={{
                    padding: "10px",
                    height: "calc(100% - 50px)",
                    overflowY: "auto",
                }}
            >
                {reachout.map((item: any) => {
                    const { projectInfo, workflowLogs } = item || {};
                    const createdAt = new Date(projectInfo?.createdAt);
                    return (
                        <Paper key={item._id} sx={{ marginBottom: "10px" }}>
                            <ListItemButton
                                sx={{
                                    flexDirection: "column",
                                    alignItems: "baseline",
                                }}
                                onClick={() => setReachoutDetail(item)}
                            >
                                <Stack
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    sx={{ width: "100%" }}
                                    mb="15px"
                                >
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontSize: "16px",
                                            paddingRight: "10px",
                                            flex: 3,
                                        }}
                                    >
                                        {projectInfo?.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{ flex: 1, textAlign: "right" }}>
                                        {`${createdAt?.toDateString()} ${createdAt?.toLocaleTimeString()}`}
                                    </Typography>
                                </Stack>
                                <Stack
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    sx={{ width: "100%" }}
                                    mb="15px"
                                >
                                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                                        {workflowLogs?.triggerBy}
                                    </Typography>
                                    <Stack flexDirection="row">
                                        <Typography variant="body2" sx={{ marginLeft: "10px" }}>
                                            <span>Open: </span>
                                            <span
                                                style={{
                                                    fontWeight: workflowLogs?.openCount ? 700 : 500,
                                                }}
                                            >
                                                {workflowLogs?.openCount ? "Yes" : "No"}
                                            </span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ marginLeft: "10px" }}>
                                            <span>Click: </span>
                                            <span
                                                style={{
                                                    fontWeight: workflowLogs?.clickCount ? 700 : 500,
                                                }}
                                            >
                                                {workflowLogs?.clickCount ? "Yes" : "No"}
                                            </span>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </ListItemButton>
                        </Paper>
                    );
                })}
            </List>
            {!isEmpty(reachoutDetail) && (
                <ReachoutDetailPopup data={reachoutDetail} onClose={() => setReachoutDetail({})} />
            )}
        </Stack>
    ) : (
        <Typography
            sx={{
                marginLeft: "10px",
                padding: "15px",
            }}
        >
            No Reachout
        </Typography>
    );
};

const ReachoutDetailPopup = ({ data, onClose }: { data: any; onClose: () => void }) => {
    return (
        <Dialog
            open={true}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: 2,
                },
            }}
            onClose={onClose}
        >
            <DialogTitle>Reachout details:</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h6" sx={{ fontSize: "16px" }}>
                        Email:
                    </Typography>
                    <Paper sx={{ padding: "10px", marginTop: "10px" }}>
                        <Typography variant="h6" sx={{ fontSize: "14px" }}>
                            Subject:
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: "14px" }}>
                            {data["subject"]}
                        </Typography>
                        <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                            Body:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontSize: "14px" }}
                            dangerouslySetInnerHTML={{ __html: data["body"] }}
                        />
                    </Paper>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContactReachout;
