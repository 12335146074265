import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    checkSendosoIntegrated,
    toggleIsSendosoIntegrated,
    updateUser,
    selectUser,
} from "@/store/reducers/signin/Signin.reducer";
import { Button, Modal, Switch, Typography } from "@/ui";

export default function SendosoConnect({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const sendosoIntegrated = useSelector(checkSendosoIntegrated);
    const [sendosoEnabled, setSendosoEnabled] = useState(false);
    const user = useSelector(selectUser);
    const userPayload = {
        name: user?.name ?? "",
        orgName: user?.orgData?.name ?? "",
        companyName: user?.companyName ?? "",
        companyLink: user?.companyLink ?? "",
        linkedinUrl: user?.userLiProfile?.profileUrl ?? "",
        authorizeEmailAddress: user.authorizedEmailAddress ?? "",
        email: user?.email ?? "",
        action: updateUser.type,
    };

    useEffect(() => {
        setSendosoEnabled(sendosoIntegrated);
    }, [sendosoIntegrated]);

    const handleConnect = () => {
        dispatch(toggleIsSendosoIntegrated(sendosoEnabled));
        dispatch(
            updateUser({
                sendosoIntegrated: sendosoEnabled,
                ...userPayload,
            })
        );

        onClose();
    };

    return (
        <Modal open={true} onClose={onClose}>
            <div style={{ maxWidth: "500px" }}>
                <h2 className="text-xl font-semibold mb-5">Enable Sendoso gifting on EasySource</h2>

                <div style={{ display: "flex", alignItems: "center", marginBottom: "2px" }}>
                    <Typography className="text-sm">
                        Toggle on to enable Sendoso gifting functionality in your projects. Then simply click the gift
                        icon in each candidate card to send out gifts
                    </Typography>
                    <Switch checked={sendosoEnabled} onChange={() => setSendosoEnabled(!sendosoEnabled)} />
                </div>

                <div className="flex justify-end space-x-2 mt-6">
                    <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                    <Button variant="default" onClick={handleConnect} className="rounded-sm" label="Save" />
                </div>
            </div>
        </Modal>
    );
}
