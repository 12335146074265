import AddRoundedIcon from "@mui/icons-material/AddRounded";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";

import { LinkedinUrlUploadModal } from "../../../components/Navbar/CreateProject/UploadCsv";

interface AddCandidateProps {
    handleOpen: () => void;
}

export function AddCandidate({ handleOpen }: AddCandidateProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openLinkedinModal, setOpenLinkedinModal] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title="Add contact">
                <IconButton onClick={handleClick}>
                    <AddRoundedIcon />
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleOpen();
                    }}
                >
                    Add single contact
                </MenuItem>
                <MenuItem onClick={() => setOpenLinkedinModal(true)}>Add via LinkedIn URL</MenuItem>
            </Menu>
            <LinkedinUrlUploadModal open={openLinkedinModal} onClose={() => setOpenLinkedinModal(false)} />
        </>
    );
}
