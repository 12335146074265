import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import { getProject } from "../allProjects/index.reducer";
import { getCompanyPage, leavePage, resetState, setCompanyPage, updateCompanyPage } from "./CompanyPage.reducer";
import handleException from "../../utils/sentry";

function* updateCompanyPageSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const state = yield select();
        const projectId = get(state, "allProjects.project._id");
        const overview = get(state, "companyPageReducer.overview");
        const companyLogo = get(state, "companyPageReducer.companyLogo[0]");
        const companyVideo = get(state, "companyPageReducer.companyVideo[0]");
        const companyVideoLink = get(state, "companyPageReducer.companyVideoLink");

        yield put(startAction({ action: updateCompanyPage.type }));

        const data: any = { ...payload };
        if (!projectId) return;
        data.projectId = projectId;
        if (payload.fileType === "logo") {
            data.file = companyLogo;
        } else if (payload.fileType === "videoFile") {
            data.file = companyVideo;
        } else if (payload.fileType === "videoLink" && companyVideoLink) {
            data.video = companyVideoLink;
        }

        data.overview = overview || "";

        const response = yield call(new API().put, "/v2/project/update-company-page", data, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        if (!response?.data) return;
        yield put(
            getProject({
                projectId: String(projectId),
                action: "",
            })
        );
        yield put(resetState());
    } catch (err: unknown) {
        handleException(err);
        console.error(err);
    } finally {
        yield put(stopAction({ action: updateCompanyPage.type }));
    }
}

function* getCompanyPageSaga(action: PayloadAction<string>): SagaIterator {
    try {
        const { type, payload } = action;
        if (!payload) return;
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, `/v2/project/companyPage/${payload}`);
        if (!response?.data) return;
        yield put(setCompanyPage(response?.data));
    } catch (err: unknown) {
        handleException(err);
        console.error(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(updateCompanyPage.type, updateCompanyPageSaga),
        // @ts-ignore
        yield takeLatest(getCompanyPage.type, getCompanyPageSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
