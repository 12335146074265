import React from "react";
import { useTranslation } from "react-i18next";

import LogoSvg from "../../assets/img/hqlogo.png";
import styles from "../../container/AppContainer/AppContainer.module.css";

const MobileView: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.mobileView}>
            <img src={LogoSvg} className={styles.logo} alt="hirequotient-logo" />
            <div className={styles.text}>
                {t("PLEASE_TRY_TO_LOGIN_USING")} <br />
                {t("EASYSOURCE_ONLY_SUPPORTS_WEB_APPLICATION")}
            </div>
        </div>
    );
};

export default MobileView;
