import { useSelector } from "react-redux";

import style from "./mailAlert.module.scss";
import { setAuthorizeModal } from "./mailAlert.reducers";

import {
    checkIsLinkedinAuthorized,
    isSuperAdminUser,
    selectUser,
    setLinkedinAuthStatus,
} from "../../pages/Signin/Signin.reducer";
import { useAppDispatch } from "../../store";
import { setModal } from "../../store/reducers/modals.slice";

export default function MailAlert() {
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);
    const isEmailAuthorized = user.emailAuthorized;
    const showAuthorizeModal = () => {
        dispatch(setAuthorizeModal(true));
    };
    const handleLinkedInAuthorizeModalOpen = () => {
        dispatch(setModal({ key: "showLinkedInAuthorizeModal", value: true }));
        dispatch(setLinkedinAuthStatus(null));
    };

    const isSuperAdmin = useSelector(isSuperAdminUser);

    const text =
        !isLinkedInAuthorized && !isEmailAuthorized && !isSuperAdmin ? (
            <p className={style["app__mailalert-text"]}>
                <span onClick={showAuthorizeModal}>Click here</span> to authorize your email and{" "}
                <span onClick={handleLinkedInAuthorizeModalOpen}>click here</span> to authorize your LinkedIn account to
                enable automated outreach on both platforms.{" "}
            </p>
        ) : !isLinkedInAuthorized && !isSuperAdmin ? (
            <p className={style["app__mailalert-text"]}>
                <span onClick={handleLinkedInAuthorizeModalOpen}>Click here</span> to authorize your LinkedIn account to
                enable automated outreach.
            </p>
        ) : !isEmailAuthorized && !isSuperAdmin ? (
            <p className={style["app__mailalert-text"]}>
                Your email for outreach needs to be verified. Click on profile icon or verify from{" "}
                <span onClick={showAuthorizeModal}>here</span>
            </p>
        ) : (
            ""
        );

    return <>{text && <div className={style["app__mailalert"]}>{text}</div>}</>;
}
