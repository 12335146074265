import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Chip, FormLabel, Table, Textarea, Tooltip } from "@mui/joy";
import CardActions from "@mui/joy/CardActions";
import {
    Card,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Select as MaterialSelect,
    Tooltip as MaterialTooltip,
    Menu,
    MenuItem,
    Popover,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import smtpLogo from "../../../../assets/img/ai-providers-logo/smtp.png";
import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import { JoyProvider } from "../../../../components/JoyProvider";
import { GmailConnect, OutlookConnect } from "../../../../components/MailAuthModal/MailAuthModal";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import useWindowSize from "../../../../hooks/useWindowSize";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";

import Loader from "@/components/Loader/Loader";
import { MaterialProvider } from "@/components/MaterialProvider";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import {
    addVirtualUserAlias,
    disconnectAlias,
    disconnectLIAlias,
    // disconnectLIAlias,
    fetchAlias,
    selectVirtualUser,
    setDefaultAlias,
    setSmtpAuthorization,
    setVirtualUserSignature,
    updateAlias,
} from "@/store/reducers/my-account/myAccount.reducer";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { checkRotatingMailBoxEnabled, selectUser, setLinkedinAuthStatus } from "@/store/reducers/signin/Signin.reducer";
import { Button, Popover as TailwindPopover } from "@/ui";
import { AuthorizeLinkedIn } from "@/components/AuthorizeLinkedin/AuthorizeLinkedin";
import { AuthorizeLinkedinChecker } from "@/components/AuthorizeLinkedinChecker";
import { getLinkedinAuthStatus, selectLinkedinAuthStatus } from "@/store/reducers/signin/Signin.reducer";

const EmailAliasesHeader = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [aliases, setAliases] = useState<string>("");
    const [anchorElDisclaimer, setAnchorElDisclaimer] = useState<null | HTMLElement>(null);
    const [userEmail, setUserEmail] = useState<string>("");
    const open = Boolean(anchorEl);
    const openDisclaimer = Boolean(anchorElDisclaimer);
    const user = useSelector(selectUser);
    const virtualUserData = useSelector(selectVirtualUser);

    const virtualUserCount = user?.virtualUserCount;

    const handleAddClick = () => {
        const virtualUserPayload = { name: aliases, email: userEmail };
        dispatch(addVirtualUserAlias(virtualUserPayload));
        setAliases("");
        setUserEmail("");
        setAnchorEl(null);
    };

    const disabled = true;

    return (
        <Stack>
            {virtualUserCount && virtualUserCount > 0 && virtualUserCount > virtualUserData?.length && (
                <Stack direction="row" justifyContent="right" paddingLeft={4} paddingRight={4}>
                    <Button
                        onClick={(event) => {
                            if (disabled) {
                                setAnchorEl(event.currentTarget);
                            } else {
                                setAnchorElDisclaimer(event.currentTarget);
                            }
                        }}
                        variant="outline"
                    >
                        + Add virtual user
                    </Button>
                </Stack>
            )}

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Box p={2} sx={{ minWidth: 400, maxWidth: 400 }}>
                    <Typography sx={{ fontSize: "16px", marginBottom: "10px" }}>
                        Create new virtual users to execute outbound from new email addresses
                    </Typography>
                    <Stack spacing={2}>
                        <JoyProvider>
                            <Textarea
                                placeholder="Virtual user name"
                                variant="outlined"
                                value={aliases}
                                onChange={(e) => setAliases(e.target.value)}
                            />

                            <Textarea
                                placeholder="Virtual user email"
                                variant="outlined"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                            />
                            <Typography sx={{ fontSize: "12px", color: "grey", fontStyle: "italic" }}>
                                This email address can be different from the email address you want to use for outreach
                            </Typography>
                            <CardActions
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: "5px",
                                }}
                            >
                                <Button
                                    size="sm"
                                    variant="outline"
                                    color="neutral"
                                    onClick={() => {
                                        setUserEmail("");
                                        setAliases("");
                                        setAnchorEl(null);
                                    }}
                                    label="Cancel"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    size="sm"
                                    variant="default"
                                    type="submit"
                                    onClick={handleAddClick}
                                    disabled={!aliases || !userEmail}
                                    className="min-w-[5rem] max-w-[5rem]"
                                    label="Add"
                                >
                                    Add
                                </Button>
                            </CardActions>
                        </JoyProvider>
                    </Stack>
                </Box>
            </Popover>

            <Popover
                open={openDisclaimer}
                anchorEl={anchorElDisclaimer}
                onClose={() => {
                    setAnchorElDisclaimer(null);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Box p={2} sx={{ minWidth: 450, maxWidth: 450 }}>
                    <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        This feature may have additional charges associated. Please contact your account manager or
                        reach out to <span style={{ color: "#0b6bcb" }}>customer-support@hirequotient.com</span> for
                        billing and access.
                    </Typography>
                </Box>
            </Popover>
        </Stack>
    );
};

const EmailAliasesTable = ({ aliases, setAliases }) => {
    const [modalState, setModalState] = useState<{
        makeDefault: boolean;
        disconnect: boolean;
        authorize: boolean;
        authorizeLinkedin: boolean;
        provider: boolean;
        editUser: boolean;
        smtp: boolean;
        disconnectLI: boolean;
    }>({
        makeDefault: false,
        disconnect: false,
        authorize: false,
        authorizeLinkedin: false,
        provider: false,
        editUser: false,
        smtp: false,
        disconnectLI: false,
    });

    const [smtpConfig, setSmtpConfig] = useState<{
        host?: string;
        port?: string;
        secure?: string;
        user?: string;
        pass?: string;
        imapPort?: string;
    }>({});
    const user = useSelector(selectUser);
    const [selectedAlias, setSelectedAlias] = useState(null);
    const [extraFeatureAnchorEl, setExtraFeatureAnchorEl] = useState<any>(null);
    const [authPopoverAnchorEl, setAuthPopoverAnchorEl] = useState<any>(null);
    const [signatureAnchorEl, setSignatureAnchorEl] = useState<any>(null);
    const [signValue, setSignValue] = useState<any>();
    const [updatedName, setUpdatedName] = useState<string>("");
    const [updatedEmail, setUpdatedEmail] = useState<string>("");
    const [updatedOutboundEmail, setUpdatedOutboundEmail] = useState<string>("");
    const size = useWindowSize();
    const disabled = true;
    const windowWidth = size?.width;
    const linkedinAuthStatus = useSelector(selectLinkedinAuthStatus);

    const handleSaveSignature = () => {
        setSignValue(signValue);
        setSignatureAnchorEl(null);
        dispatch(
            setVirtualUserSignature({
                userId: selectedAlias?.virtualUserId,
                signature: signValue,
                orgName: user?.orgData?.name,
                role: user?.roleType,
            })
        );
    };

    const handleSmtpChange = (key: keyof typeof smtpConfig, value: any) => {
        setSmtpConfig((prevConfig) => ({
            ...prevConfig,
            [key]: value,
        }));
    };

    const handleModalStateChange = (modalName: keyof typeof modalState, isOpen: boolean) => {
        setModalState((prevState) => ({
            ...prevState,
            [modalName]: isOpen,
        }));
    };

    const openExtraFeature = Boolean(extraFeatureAnchorEl);
    const authPopover = Boolean(authPopoverAnchorEl);
    const openSignature = Boolean(signatureAnchorEl);
    const dispatch = useDispatch();

    const handleMakeDefault = (aliasData: any) => {
        setSelectedAlias(aliasData);
        handleModalStateChange("makeDefault", true);
    };

    const handleSetDefault = () => {
        const updatedAliases = aliases.map((alias: any) => ({
            ...alias,
            default: alias.alias === selectedAlias.alias,
        }));
        setAliases(updatedAliases);
        const makeDefaultPayload = {
            virtualUserId: selectedAlias.virtualUserId,
        };

        dispatch(setDefaultAlias(makeDefaultPayload));
        handleModalStateChange("makeDefault", false);
    };

    const handleDisconnect = (aliasData: any) => {
        setSelectedAlias(aliasData);
        handleModalStateChange("disconnect", true);
    };
    const handleDisconnectLI = (aliasData: any) => {
        setSelectedAlias(aliasData);
        handleModalStateChange("disconnectLI", true);
    };

    const handleConfirmDisconnect = () => {
        const disconnectPayload = {
            virtualUserId: selectedAlias?.virtualUserId,
        };
        dispatch(disconnectAlias(disconnectPayload));
        handleModalStateChange("disconnect", false);
    };

    const handleConfirmDisconnectLI = () => {
        const disconnectPayload = {
            virtualUserId: selectedAlias?.virtualUserId,
        };
        dispatch(disconnectLIAlias(disconnectPayload));
        handleModalStateChange("disconnectLI", false);
    };

    const handleProceedAuthorize = () => {
        handleModalStateChange("authorize", false);
        handleModalStateChange("provider", true);
    };

    const handleAuthorize = (aliasData: any) => {
        setSelectedAlias(aliasData);
        handleModalStateChange("authorize", true);
    };

    const handleAuthorizeLinkedin = (aliasData: any) => {
        setSelectedAlias(aliasData);
        handleModalStateChange("authorizeLinkedin", true);
    };

    const onGmailSuccess = (response: any) => {
        handleModalStateChange("provider", false);
        dispatch(setSuccessNotification(response?.message));
        dispatch(fetchAlias({ action: fetchAlias.type }));
    };

    const onOutlookSuccess = (response: any) => {
        dispatch(setSuccessNotification(response?.message));
        dispatch(fetchAlias({ action: fetchAlias.type }));
    };

    const handleSignature = (aliasData: any) => {
        console.log("mai hu don");
        setSelectedAlias(aliasData);

        setSignatureAnchorEl(true);
        setSignValue(aliasData?.signature);
        setUpdatedName(aliasData?.alias);
        setUpdatedEmail(aliasData?.availableEmail);
        setUpdatedOutboundEmail(aliasData?.outboundEmail);
        // setSignatureAnchorEl();
    };

    const handleUpdateUserDetails = (updatedName: string, updatedEmail: string, updateUserData: string) => {
        dispatch(
            updateAlias({
                updatedName: updatedName,
                updatedEmail: updatedEmail,
                updateUserData: updateUserData,
            })
        );
    };

    const handleSmtpSubmit = () => {
        handleModalStateChange("smtp", false);
        const smtpPayload = {
            host: smtpConfig.host,
            port: +smtpConfig.port,
            secure: smtpConfig.secure === "true",
            user: smtpConfig.user,
            pass: smtpConfig.pass,
            imapPort: +smtpConfig.imapPort,
            userId: selectedAlias?.virtualUserId,
        };

        dispatch(setSmtpAuthorization(smtpPayload));
    };

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    marginTop: "20px",
                    backgroundColor: "#fbfcfe",
                    padding: 2,
                    paddingBottom: 1,
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxHeight: "66vh",
                    overflowY: "scroll",
                    width: windowWidth > 1450 ? "95%" : "100%",
                    pointerEvents: disabled ? "auto" : "none",
                    opacity: disabled ? "1" : "0.5",
                }}
            >
                <JoyProvider>
                    <Table style={{ borderCollapse: "collapse", overflowX: "scroll" }}>
                        <thead>
                            <tr>
                                <th
                                    style={{
                                        width: "12vw",
                                    }}
                                ></th>
                                <th
                                    style={{
                                        borderBottom: "2px solid #ddd",
                                        padding: "10px",
                                        textAlign: "left",
                                        fontWeight: "500",
                                        fontSize: "16px",
                                    }}
                                >
                                    Virtual user name
                                </th>
                                <th
                                    style={{
                                        borderBottom: "2px solid #ddd",
                                        padding: "10px",
                                        textAlign: "left",
                                        fontWeight: "500",
                                        fontSize: "16px",
                                    }}
                                >
                                    Virtual user email
                                </th>
                                <th
                                    style={{
                                        borderBottom: "2px solid #ddd",
                                        padding: "10px",
                                        textAlign: "left",
                                        fontWeight: "500",
                                        fontSize: "16px",
                                    }}
                                >
                                    Outbound email
                                </th>
                                <th
                                    style={{
                                        borderBottom: "2px solid #ddd",
                                        padding: "10px",
                                        textAlign: "left",
                                        fontWeight: "500",
                                        fontSize: "16px",
                                    }}
                                >
                                    Email Auth
                                </th>
                                <th
                                    style={{
                                        borderBottom: "2px solid #ddd",
                                        padding: "10px",
                                        textAlign: "left",
                                        fontWeight: "500",
                                        fontSize: "16px",
                                    }}
                                >
                                    LinkedIn Auth
                                </th>
                                <th
                                    style={{
                                        borderBottom: "2px solid #ddd",
                                        padding: "10px",
                                        textAlign: "left",
                                        fontWeight: "500",
                                        fontSize: "16px",
                                    }}
                                >
                                    Signature
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {aliases.map((aliasData: any, index: any) => (
                                <tr key={index}>
                                    <td
                                        style={{
                                            padding: "8px",
                                            textAlign: "left",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {aliasData?.default ? (
                                            <Tooltip
                                                title="Emails will be sent out from this email address by default"
                                                sx={{ backgroundColor: "black", color: "white", maxWidth: "14rem" }}
                                            >
                                                <Chip
                                                    sx={{
                                                        backgroundColor: "#C1F5C9",
                                                        marginLeft: "8px",
                                                        fontSize: "14px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Default
                                                </Chip>
                                            </Tooltip>
                                        ) : (
                                            <Chip
                                                sx={{
                                                    backgroundColor: "lightgrey",
                                                    marginLeft: "8px",
                                                    fontSize: "14px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleMakeDefault(aliasData)}
                                            >
                                                <div style={{ display: "flex" }}>
                                                    <span style={{ marginTop: "2px" }}>Set as default</span>
                                                </div>
                                            </Chip>
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            padding: "8px",
                                            textAlign: "left",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <HoverTruncateText maxWidth={300} tooltipPlacement="bottom">
                                                {aliasData?.alias}
                                            </HoverTruncateText>

                                            <span style={{ fontSize: "12px", color: "grey", fontStyle: "italic" }}>
                                                Created on {dayjs(aliasData?.createdOn).format("MMM D, YYYY")}{" "}
                                            </span>
                                        </div>
                                    </td>

                                    <td
                                        style={{
                                            padding: "8px",
                                            textAlign: "left",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13px",
                                                color: "black",
                                                fontStyle: "italic",
                                                whiteSpace: "normal",
                                                display: "block",
                                                overflowWrap: "break-word",
                                            }}
                                        >
                                            {aliasData?.availableEmail}
                                        </span>
                                    </td>

                                    <td
                                        style={{
                                            padding: "8px",
                                            textAlign: "left",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13px",
                                                color: "black",
                                                fontStyle: "italic",
                                                whiteSpace: "normal",
                                                display: "block",
                                                overflowWrap: "break-word",
                                            }}
                                        >
                                            {aliasData?.outboundEmail}
                                        </span>
                                    </td>

                                    <td
                                        style={{
                                            padding: "8px",
                                            textAlign: "center",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {aliasData?.authorizedStatus ? (
                                                <Button
                                                    variant="outline"
                                                    onClick={() => handleDisconnect(aliasData)}
                                                    className="min-w-[7rem]"
                                                    label={"Disconnect"}
                                                    style={{ color: "#9f631f", borderColor: "#efc994" }}
                                                />
                                            ) : (
                                                <Button
                                                    variant="outline"
                                                    onClick={() => handleAuthorize(aliasData)}
                                                    className="min-w-[7rem]"
                                                    label="Authorize"
                                                />
                                            )}
                                        </div>
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {aliasData?.linkedinAuthStatus === "SUCCESS" ? (
                                                <Button
                                                    variant="outline"
                                                    onClick={() => handleDisconnectLI(aliasData)}
                                                    className="min-w-[7rem]"
                                                    label={"Disconnect"}
                                                    style={{ color: "#9f631f", borderColor: "#efc994" }}
                                                />
                                            ) : (
                                                <Button
                                                    variant="outline"
                                                    onClick={() => handleAuthorizeLinkedin(aliasData)}
                                                    className="min-w-[7rem]"
                                                    label="Authorize"
                                                />
                                            )}
                                        </div>
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Button
                                                variant="outline"
                                                onClick={(e) => {
                                                    setSelectedAlias(aliasData);
                                                    setSignValue(aliasData?.signature);
                                                    setUpdatedName(aliasData?.alias);
                                                    setUpdatedEmail(aliasData?.availableEmail);
                                                    setUpdatedOutboundEmail(aliasData?.outboundEmail);
                                                    setSignatureAnchorEl(e.currentTarget);
                                                }}
                                                className="min-w-[7rem]"
                                                label={`${aliasData?.signature ? "Edit signature" : "Set signature"}`}
                                                style={{ color: aliasData?.signature ? "#9f631f" : "", borderColor: aliasData?.signature ? "#efc994" : "", }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </JoyProvider>
            </Card>

            {modalState.makeDefault && (
                <BaseModal
                    onClose={() => handleModalStateChange("makeDefault", false)}
                    overlayStyles={{
                        padding: "1.7rem 1.5rem",
                        width: 550,
                    }}
                    hideCloseButton={false}
                >
                    <Typography sx={{ fontSize: "25px", fontWeight: "600", marginBottom: "10px" }}>
                        Set email address as default?
                    </Typography>

                    <Typography sx={{ fontSize: "14px" }}>
                        Emails will be sent out from this email address by default
                    </Typography>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={4}>
                        <Button
                            variant="outline"
                            onClick={() => handleModalStateChange("makeDefault", false)}
                            label="No, do not set"
                        />

                        <Button variant="default" onClick={handleSetDefault} label="Continue" />
                    </Stack>
                </BaseModal>
            )}

            {modalState.disconnect && (
                <BaseModal
                    onClose={() => handleModalStateChange("disconnect", false)}
                    overlayStyles={{
                        padding: "1.7rem 1.5rem",
                        width: 550,
                    }}
                    hideCloseButton={false}
                >
                    <Typography sx={{ fontSize: "25px", fontWeight: "600", marginBottom: "10px" }}>
                        Disconnect email
                    </Typography>

                    <Typography sx={{ fontSize: "14px" }}>
                        You will not be able to send out emails using this email address. You can always connect your
                        email back again.
                    </Typography>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={4}>
                        <Button
                            variant="outline"
                            onClick={() => handleModalStateChange("disconnect", false)}
                            label="Cancel"
                        />
                        <Button variant="default" onClick={handleConfirmDisconnect} label="Disconnect" />
                    </Stack>
                </BaseModal>
            )}

            {modalState.disconnectLI && (
                <BaseModal
                    onClose={() => handleModalStateChange("disconnectLI", false)}
                    overlayStyles={{
                        padding: "1.7rem 1.5rem",
                        width: 550,
                    }}
                    hideCloseButton={false}
                >
                    <Typography sx={{ fontSize: "25px", fontWeight: "600", marginBottom: "10px" }}>
                        Disconnect LinkedIn
                    </Typography>

                    <Typography sx={{ fontSize: "14px" }}>
                        You will not be able to send out LinkedIn using this LinkedIn id. You can always connect your
                        linkedin back again.
                    </Typography>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={4}>
                        <Button
                            variant="outline"
                            onClick={() => handleModalStateChange("disconnect", false)}
                            label="Cancel"
                        />
                        <Button variant="default" onClick={handleConfirmDisconnectLI} label="Disconnect" />
                    </Stack>
                </BaseModal>
            )}
            {modalState.authorize && (
                <BaseModal
                    onClose={() => handleModalStateChange("authorize", false)}
                    overlayStyles={{
                        padding: "1.7rem 1.5rem",
                        width: 550,
                    }}
                    hideCloseButton={false}
                >
                    <Typography sx={{ fontSize: "25px", fontWeight: "600", marginBottom: "10px" }}>
                        Authorize email
                    </Typography>

                    <Typography sx={{ fontSize: "14px" }}>
                        You are about to authorize an email for the user <strong>{selectedAlias?.alias}</strong> for
                        outbound emails. This authorized email address can be used to send out emails.
                    </Typography>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={4}>
                        <Button
                            variant="outline"
                            onClick={() => handleModalStateChange("authorize", false)}
                            label="Cancel"
                        />

                        <Button variant="default" onClick={handleProceedAuthorize} label="Proceed" />
                    </Stack>
                </BaseModal>
            )}

            {modalState?.authorizeLinkedin && (
                <AuthorizeLinkedIn
                    onClose={() => handleModalStateChange("authorizeLinkedin", false)}
                    virtualUser={selectedAlias?.virtualUserId}
                />
            )}

            {modalState.provider && (
                <BaseModal
                    onClose={() => handleModalStateChange("provider", false)}
                    overlayStyles={{
                        width: 750,
                        padding: "1rem 2rem 1rem 1.5rem",
                    }}
                >
                    <Typography fontSize={20} fontWeight={500} color="#334d6e" mb={4}>
                        Choose your email provider
                    </Typography>
                    <Stack direction={"row"} alignItems="center" spacing={2}>
                        <GmailConnect
                            variant="horizontal"
                            onSuccess={onGmailSuccess}
                            extraQueryParameters={{ userId: selectedAlias?.virtualUserId }}
                        />
                        <OutlookConnect
                            variant="horizontal"
                            onSuccess={onOutlookSuccess}
                            extraQueryParameters={{ userId: selectedAlias?.virtualUserId }}
                        />

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                borderRadius: 2,
                                padding: "15px",
                                border: "1px solid #c4c4c4",
                                width: "100%",
                                "&:hover": {
                                    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
                                },
                            }}
                            onClick={() => {
                                handleModalStateChange("smtp", true), handleModalStateChange("provider", false);
                            }}
                        >
                            <img
                                src={smtpLogo}
                                alt="SMTP"
                                height={39}
                                width={39}
                                style={{ marginRight: "10px", marginLeft: "8px" }}
                            />
                            <Typography sx={{ fontWeight: "400", fontSize: "20px" }}>SMTP</Typography>
                        </Box>
                    </Stack>
                </BaseModal>
            )}

            {modalState.editUser && (
                <BaseModal
                    onClose={() => handleModalStateChange("editUser", false)}
                    overlayStyles={{
                        width: 440,
                        padding: "1rem 2rem 1rem 1.5rem",
                    }}
                >
                    <Typography fontSize={20} fontWeight={500} color="#334d6e" mb={2}>
                        Edit virtual user details
                    </Typography>
                    <JoyProvider>
                        <FormLabel sx={{ fontSize: "16px" }}>Virtual user name</FormLabel>
                        <Textarea
                            placeholder="Virtual user name"
                            variant="outlined"
                            sx={{ marginBottom: "1rem" }}
                            value={updatedName}
                            onChange={(e) => setUpdatedName(e.target.value)}
                        />

                        <FormLabel sx={{ fontSize: "16px" }}>Virtual user email</FormLabel>
                        <Textarea
                            placeholder="Virtual user email"
                            variant="outlined"
                            sx={{ marginBottom: "1rem" }}
                            value={updatedEmail}
                            onChange={(e) => setUpdatedEmail(e.target.value)}
                        />

                        <FormLabel sx={{ fontSize: "16px" }}>Outbound email</FormLabel>
                        <Textarea
                            placeholder="Virtual user outbound email"
                            variant="outlined"
                            disabled={true}
                            value={updatedOutboundEmail}
                            sx={{ marginBottom: "3rem" }}
                        />

                        <CardActions
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "5px",
                            }}
                        >
                            <Button variant="outline" color="neutral" label="Cancel" />

                            <Button
                                variant="default"
                                type="submit"
                                onClick={() => handleUpdateUserDetails(updatedName, updatedEmail, updatedOutboundEmail)}
                                label="Save"
                            />
                        </CardActions>
                    </JoyProvider>
                </BaseModal>
            )}

            {modalState.smtp && (
                <BaseModal
                    onClose={() => handleModalStateChange("smtp", false)}
                    overlayStyles={{
                        width: 440,
                        padding: "1rem 2rem 1rem 1.5rem",
                    }}
                >
                    <Typography fontSize={20} fontWeight={500} color="#334d6e" mb={2}>
                        Enter authrization details
                    </Typography>
                    <JoyProvider>
                        <FormLabel sx={{ fontSize: "16px" }}>Host </FormLabel>
                        <Textarea
                            placeholder="Enter host details"
                            variant="outlined"
                            sx={{ marginBottom: "1rem" }}
                            value={smtpConfig.host}
                            onChange={(e) => handleSmtpChange("host", e.target.value)}
                        />

                        <FormLabel sx={{ fontSize: "16px" }}>Port</FormLabel>

                        <MaterialProvider>
                            <TextField
                                placeholder="Enter port number"
                                variant="outlined"
                                sx={{ marginBottom: "1rem" }}
                                size="small"
                                type="number"
                                value={smtpConfig.port}
                                onChange={(e) => handleSmtpChange("port", e.target.value)}
                            />
                        </MaterialProvider>

                        <MaterialProvider>
                            <InputLabel id="demo-select-small-label" sx={{ color: "black" }}>
                                Secure
                            </InputLabel>
                            <MaterialSelect
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label="Secure"
                                placeholder="Secure"
                                value={smtpConfig.secure}
                                size="small"
                                sx={{ marginBottom: "8px" }}
                                onChange={(e) => handleSmtpChange("secure", e.target.value)}
                            >
                                <MenuItem value={"true"}>true</MenuItem>
                                <MenuItem value={"false"}>false</MenuItem>
                            </MaterialSelect>
                        </MaterialProvider>

                        <FormLabel sx={{ fontSize: "16px" }}>User</FormLabel>
                        <Textarea
                            placeholder="Enter user details"
                            variant="outlined"
                            sx={{ marginBottom: "1rem" }}
                            value={smtpConfig.user}
                            onChange={(e) => handleSmtpChange("user", e.target.value)}
                        />

                        <FormLabel sx={{ fontSize: "16px" }}>Pass</FormLabel>
                        <Textarea
                            placeholder="Enter pass"
                            variant="outlined"
                            sx={{ marginBottom: "1rem" }}
                            value={smtpConfig.pass}
                            onChange={(e) => handleSmtpChange("pass", e.target.value)}
                        />

                        <FormLabel sx={{ fontSize: "16px" }}>Imap port</FormLabel>
                        <MaterialProvider>
                            <TextField
                                placeholder="Enter imap port number"
                                variant="outlined"
                                sx={{ marginBottom: "1rem" }}
                                size="small"
                                type="number"
                                value={smtpConfig.imapPort}
                                onChange={(e) => handleSmtpChange("imapPort", e.target.value)}
                            />
                        </MaterialProvider>

                        <CardActions
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "5px",
                            }}
                        >
                            <Button size="sm" variant="outline" color="neutral" label="Cancel" />
                            <Button
                                size="sm"
                                variant="default"
                                type="submit"
                                onClick={handleSmtpSubmit}
                                label="Submit"
                            />
                        </CardActions>
                    </JoyProvider>
                </BaseModal>
            )}

            <Popover
                open={openSignature}
                anchorEl={signatureAnchorEl}
                onClose={() => {
                    setSignatureAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Box p={2} sx={{ minWidth: 400, maxWidth: 400 }}>
                    <Typography sx={{ fontSize: "16px", marginBottom: "10px" }}>Signature</Typography>
                    <Stack spacing={2} sx={{ marginBottom: "10px" }}>
                        <RichTextEditor
                            enableImageModule
                            onChange={(e: any) => {
                                setSignValue(e);
                            }}
                            value={signValue?.replaceAll("\n", "<br>")}
                        />
                    </Stack>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "5px",
                        }}
                    >
                        <Button
                            variant="outline"
                            onClick={() => {
                                setSignatureAnchorEl(null), setSignValue(null);
                            }}
                            label="Cancel"
                        />

                        <Button variant="default" onClick={handleSaveSignature} label="Save" />
                    </div>
                </Box>
            </Popover>
        </>
    );
};

const EmailAliases = () => {
    const dispatch = useDispatch();
    const virtualUserData = useSelector(selectVirtualUser);
    const [aliases, setAliases] = useState<any>(virtualUserData);

    useEffect(() => {
        if (virtualUserData.length === 0) {
            dispatch(fetchAlias({ action: fetchAlias.type }));
        }
    }, [dispatch]);

    useEffect(() => {
        if (virtualUserData) {
            setAliases(virtualUserData);
        }
    }, [virtualUserData]);

    const loadingAddVirtualUser = useSelector(checkIfLoading(addVirtualUserAlias.type));
    const tableLoading = useSelector(checkIfLoading(fetchAlias.type));
    const loading = tableLoading || loadingAddVirtualUser;
    if (loading) {
        return (
            <div style={{ height: "50vh" }}>
                <Loader />
            </div>
        );
    }

    return (
        <>
            <div style={{ maxHeight: "calc(100% - 190px)" }}>
                <EmailAliasesHeader />
                {aliases && <EmailAliasesTable aliases={aliases} setAliases={setAliases} />}
            </div>
        </>
    );
};

export default EmailAliases;
