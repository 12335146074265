import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type Props = {
    children: React.ReactNode;
};

export default function DisableWorkflowComponentOverlay(props: Props) {
    const { children } = props;
    return (
        <Box
            sx={{
                zIndex: 1,
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                borderRadius: "1.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backdropFilter: "blur(3px)",
            }}
        >
            <Typography
                sx={{
                    backgroundColor: "white",
                    boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                }}
                p={2}
                fontWeight={600}
                borderRadius={2}
            >
                {children}
            </Typography>
        </Box>
    );
}
