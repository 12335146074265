import CloseIcon from "@mui/icons-material/Close"; // Import the close icon
import Tooltip from "@mui/joy/Tooltip";
import { Experimental_CssVarsProvider, Popover } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { useSelector } from "react-redux";

import { JoyProvider } from "../../../../components/JoyProvider";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";

import { checkSuperInboxResponseEnabled } from "@/store/reducers/signin/Signin.reducer";
import { Button, Typography } from "@/ui";
import { tooltipSetting } from "@/utils/tooltip";

interface EditorProps {
    subject?: string;
    onSubmit?: (body: string) => void;
    title: string;
    show?: boolean;
    info?: ReactNode;
}

export default function ResponseEditor(props: EditorProps) {
    const superInboxResponseEnabled = useSelector(checkSuperInboxResponseEnabled);
    const { subject, onSubmit, title, show, info } = props;

    const [body, setBody] = useState("");

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSend = () => {
        onSubmit?.(body);
        setAnchorEl(null);
        setBody("");
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const isBodyEmpty = (body: string) => {
        const trimmedBody = body.trim();
        return !trimmedBody || trimmedBody === "<p><br></p>";
    };

    const isSendDisabled = isBodyEmpty(body);

    if (!superInboxResponseEnabled) {
        return null;
    }

    return (
        <>
            {show && (
                <Button
                    aria-describedby={id}
                    variant="secondary"
                    label={`Respond to ${title}`}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                />
            )}

            {!show && (
                <Tooltip {...tooltipSetting} title={info} sx={{ maxWidth: "600px" }}>
                    <Button aria-describedby={id} variant="secondary" label={`Respond to ${title}`} />
                </Tooltip>
            )}

            <Experimental_CssVarsProvider>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <JoyProvider>
                        <div className="p-2 flex flex-col gap-2 h-[550px] w-[600px] relative">
                            <button onClick={handleClose} className="absolute top-2 right-8 text-blue-500 mb-[40px]">
                                <CloseIcon style={{ color: "#0c6bcc" }} />
                            </button>

                            <Typography className="font-medium">Write your {title} response or follow up</Typography>

                            {subject ? (
                                <Tooltip
                                    size="sm"
                                    title="You cannot edit the subject as this email will be sent in the same conversation"
                                >
                                    <input
                                        placeholder="Subject"
                                        value={subject}
                                        style={{
                                            marginTop: "5px",
                                            color: "rgb(106 106 106)",
                                            backgroundColor: "white",
                                            border: "1.5px solid lightgrey",
                                            padding: "3px",
                                        }}
                                        disabled
                                    />
                                </Tooltip>
                            ) : (
                                <div className="mt-[25px]"></div>
                            )}

                            <RichTextEditor
                                enableImageModule
                                style={{ height: "300px" }}
                                value={body}
                                onChange={(value) => setBody(value)}
                            />

                            <div className="flex justify-between items-center mt-[70px]">
                                <Typography className="w-auto mr-5 text-[12px] text-darkgray italic">
                                    This {title} message will be sent on the same {title} thread, and should be visible
                                    in your inbox once sent.
                                </Typography>
                                <div className="w-[27%] flex justify-between">
                                    <Tooltip title={isSendDisabled ? "Please enter some text to send" : ""}>
                                        <span>
                                            <Button
                                                onClick={handleSend}
                                                disabled={isSendDisabled}
                                                label={`Send ${title}`}
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </JoyProvider>
                </Popover>
            </Experimental_CssVarsProvider>
        </>
    );
}
