import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Fragment } from "react";
import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import { selectCompanyHeadcount, selectCompanyHeadcount2, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

export function CompanyHeadcount({ id }: { id: SearchStateKeys }) {
    const { isLoading, query, setQuery, dispatch } = useSearchState(id);
    const companyHeadcountState = useSelector(selectCompanyHeadcount);
    const companyHeadcount2State = useSelector(selectCompanyHeadcount2);

    const { values, options, error } = id === "companyHeadcount" ? companyHeadcountState : companyHeadcount2State;

    const filteredOptions =
        id === "companyHeadcount2"
            ? Object.keys(options).filter((option) => option !== "Self-employed")
            : Object.keys(options);

    const handleChange = (event: any, value: any) => {
        dispatch(
            setValues({
                key: id,
                value: value ? value.map((val: string) => ({ value: val, excluded: false })) : [],
            })
        );
    };

    return (
        <Autocomplete
            multiple
            size="small"
            ChipProps={{ color: "success" }}
            value={values.map(({ value }) => value)}
            options={filteredOptions}
            loading={isLoading}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label="Company Headcount"
                    placeholder="Company Headcount"
                    value={query}
                    error={Boolean(error)}
                    helperText={error}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        sx: { fontSize: 14 },
                        endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
