import { useParams } from "react-router-dom";
import {
    ContactOverviewActivitiesTable,
    useFetchContactOverviewActivities,
} from "../../all-candidates/components/ContactOverviewActivities/ContactOverviewActivities";
import { useFetchActivityTags } from "../../all-candidates/AllCandidatesContent";

type ProjectParams = {
    id: string;
};

export function ActivitiesView() {
    const { id = "" } = useParams<ProjectParams>();
    const { ...rest } = useFetchContactOverviewActivities([id]);
    useFetchActivityTags({ defaultProjectIds: [Number(id)] });

    return <ContactOverviewActivitiesTable {...rest} />;
}
