import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser } from "../../Signin/Signin.reducer";
import { postBambooHRCredentials } from "../integrations.reducer";
import { BambooHRKey, BambooHRState } from "../integrations.types";

export default function BambooHRConnect({ onClose }: { onClose: () => void }) {
    const user = useSelector(selectUser);
    const isCredsSubmitting = useSelector(checkIfLoading(postBambooHRCredentials.type));

    const dispatch = useDispatch();

    const [credentials, setCredentials] = useState<BambooHRState>({
        apiKey: "",
        companyDomain: "",
    });
    const [error, setError] = useState({
        apiKey: false,
        companyDomain: false,
    });

    useEffect(() => {
        if (isEmpty(user)) return;
        setCredentials({
            apiKey: user.bambooHR?.apiKey ?? "",
            companyDomain: user.bambooHR?.companyDomain ?? "",
        });
    }, [user]);

    function validateField(field: BambooHRKey, isValid: boolean) {
        if (!isValid) {
            setError((prevError) => ({ ...prevError, [field]: true }));
            return;
        }
    }

    const handleSubmit = () => {
        validateField("apiKey", Boolean(credentials.apiKey));
        validateField("companyDomain", Boolean(credentials.companyDomain));

        dispatch(
            postBambooHRCredentials({
                apiKey: credentials.apiKey,
                companyDomain: credentials.companyDomain,
                onSuccess: onClose,
            })
        );
    };

    return (
        <Dialog onClose={onClose} maxWidth="sm" fullWidth open={true}>
            <DialogTitle>Connect BambooHR</DialogTitle>
            <DialogContent>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography fontSize={14} noWrap>
                        Company Domain
                    </Typography>
                    <TextField
                        hiddenLabel
                        size="small"
                        value={credentials.companyDomain}
                        onChange={(e) =>
                            setCredentials((prev) => ({
                                ...prev,
                                companyDomain: e.target.value,
                            }))
                        }
                        sx={{
                            minWidth: 300,
                            "& .MuiInputBase-input": {
                                fontSize: 14,
                            },
                        }}
                        error={error.companyDomain}
                        helperText={error.companyDomain ? "Enter valid company domain" : null}
                    />
                </Stack>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography fontSize={14} noWrap>
                        API Key
                    </Typography>
                    <TextField
                        hiddenLabel
                        size="small"
                        value={credentials.apiKey}
                        onChange={(e) =>
                            setCredentials((prev) => ({
                                ...prev,
                                apiKey: e.target.value,
                            }))
                        }
                        sx={{
                            minWidth: 300,
                            "& .MuiInputBase-input": {
                                fontSize: 14,
                            },
                        }}
                        error={error.apiKey}
                        helperText={error.apiKey ? "Enter valid api key" : null}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose} sx={{ borderRadius: 1 }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isCredsSubmitting}
                    sx={{ borderRadius: 1 }}
                >
                    <ButtonTextWithLoading text="Submit" isLoading={isCredsSubmitting} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
