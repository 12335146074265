import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useFetchOutreachCandidatesQuery } from "../../../store/apis/outreach/outreach.api";
import { WorkflowStepValue } from "../../../store/apis/outreach/outreach.types";
import { selectOutReachFlow } from "../outreach.slice";

type OutreachParams = {
    projectId: string;
    outreachId: string;
    stepId: string;
    candidateId: string;
};

export function useOutreachParams(): {
    candidateId: string;
    outreachId: string;
    projectId: string;
    currentOutreachElementId: string;
    isHyperPersonalizedView: boolean;
    navigateToHyperPersonalizedTemplates: () => void;
    navigateToBaseTemplates: () => void;
    navigateToNextOutreachElement: (elementId: string) => void;
    navigateToCandidate: (candidateId: string) => void;
    getOutreachElement: (eventEnum: number) => WorkflowStepValue | undefined;
} {
    const { candidateId: candidateIdFromUrl, outreachId = "", projectId, stepId } = useParams<OutreachParams>();
    const values = useLocation();
    const navigate = useNavigate();
    useFetchOutreachCandidatesQuery({ outreachId });
    const outreach = useSelector(selectOutReachFlow);
    const isHyperPersonalizedView = values.pathname.includes("preview");
    // TODO: make sure preview button is disabled till we fetch candidates list
    const url = useMemo(() => `/outreach/${projectId}/${outreachId}`, [projectId, outreachId]);

    const navigateToNextOutreachElement = (elementId: string) => {
        const navigateUrl = `${url}/${candidateIdFromUrl}/${elementId}`;
        if (isHyperPersonalizedView) {
            navigate(`${navigateUrl}/preview`);
        } else {
            const newUrl = `${navigateUrl}/base`;
            navigate(newUrl);
        }
    };

    const navigateToHyperPersonalizedTemplates = () => {
        navigate(`${url}/${candidateIdFromUrl}/${stepId}/preview`);
    };

    const navigateToBaseTemplates = () => {
        navigate(`${url}/${candidateIdFromUrl}/${stepId}/base`);
    };

    const navigateToCandidate = (candidateId: string) => {
        navigate(`/outreach/${projectId}/${outreachId}/${candidateId}/${stepId}/preview/`);
    };

    const getOutreachElement = (eventEnum: number) => {
        const outreachElement = outreach?.find((item) => {
            return item.eventEnum === eventEnum;
        });
        return outreachElement;
    };

    return {
        candidateId: candidateIdFromUrl || "",
        outreachId: outreachId || "",
        projectId: projectId || "",
        currentOutreachElementId: stepId || "",
        isHyperPersonalizedView,
        navigateToHyperPersonalizedTemplates,
        navigateToBaseTemplates,
        navigateToNextOutreachElement,
        navigateToCandidate,
        getOutreachElement,
    };
}
