import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, ButtonProps, Dropdown, IconButton, IconButtonProps, MenuButton, MenuItem, Tooltip } from "@mui/joy";
import Menu from "@mui/joy/Menu";
import Typography from "@mui/joy/Typography";
import Box from "@mui/material/Box";
import { StaticDatePicker, StaticDatePickerProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";

import { JoyProvider } from "../../components/JoyProvider";
import { MaterialProvider } from "../../components/MaterialProvider";
import useOnClickOutside from "../../hooks/useClickOutside";

export type TDate = Dayjs | null;

function DatePickerComponent(props: StaticDatePickerProps<TDate>) {
    return (
        <MaterialProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                    sx={{
                        "& .MuiPickersToolbar-root": {
                            display: "none",
                        },
                        "& .MuiDialogActions-root": {
                            display: "none",
                        },
                    }}
                    {...props}
                />
            </LocalizationProvider>
        </MaterialProvider>
    );
}

export interface DateRangePickerProps extends Omit<StaticDatePickerProps<TDate>, "value" | "onChange"> {
    value?: TDate[];
    onChange: (dateRange: TDate[]) => void | Dispatch<SetStateAction<TDate[]>>;
    buttonProps?: IconButtonProps;
    startDateLabel?: string;
    endDateLabel?: string;
    format?: string;
    startDateProps?: StaticDatePickerProps<TDate>;
    endDateProps?: StaticDatePickerProps<TDate>;
    clearable?: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
}

export default function DateRangePicker(props: DateRangePickerProps) {
    const {
        value = [],
        onChange,
        buttonProps = {},
        format = "MMM D, YYYY",
        startDateLabel = "--",
        endDateLabel = "--",
        startDateProps,
        endDateProps,
        clearable,
        disabled,
        hideLabel,
        ...rest
    } = props;
    const [startDate, endDate] = value;

    const datePickerRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    useOnClickOutside(datePickerRef, () => {
        setOpen(false);
    });

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const onChangeStartDate = (date: TDate) => {
        onChange([date, null]);
    };

    const onChangeEndDate = (date: TDate) => {
        onChange([startDate, date]);
        handleClose();
    };

    const buttonText = `${startDate?.format(format) ?? startDateLabel} - ${endDate?.format(format) ?? endDateLabel}`;

    const cancelIcon = () => {
        if (clearable && (startDate || endDate)) {
            return (
                <CancelIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        onChange([]);
                    }}
                    color="action"
                    fontSize="inherit"
                    sx={{
                        marginLeft: 1,
                    }}
                />
            );
        }
    };

    const { sx, ...restBtnProps } = buttonProps;

    const renderButton = () => {
        if (hideLabel) {
            return (
                <MenuButton
                    disabled={disabled}
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: "soft", ...restBtnProps } }}
                    startDecorator={
                        <CalendarMonthIcon
                            sx={{
                                fontSize: "inherit",
                                marginRight: 1,
                                margin: 0
                            }}
                        />
                    }
                    sx={{
                        px: 1,
                        py: 0,
                        minHeight: "2rem",
                        boxShadow: "0 1px 2px rgb(21 21 21 / 8%)",
                    }}
                    onClick={handleOpen}
                    endDecorator={cancelIcon()}
                />
            );
        }
        return (
            <MenuButton
                disabled={disabled}
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: "soft", ...restBtnProps } }}
                startDecorator={
                    <CalendarMonthIcon
                        sx={{
                            fontSize: "inherit",
                            marginRight: 1,
                        }}
                    />
                }
                sx={{
                    px: 1,
                    py: 0,
                    minHeight: "2rem",
                    boxShadow: "0 1px 2px rgb(21 21 21 / 8%)",
                }}
                onClick={handleOpen}
                endDecorator={cancelIcon()}
            >
                <Typography fontSize="13px" noWrap>
                    {buttonText}
                </Typography>
            </MenuButton>
        );
    };

    return (
        <JoyProvider>
            <Dropdown open={open}>
                <Tooltip title={buttonText} placement="top">
                    {renderButton()}
                </Tooltip>
                <Menu
                    ref={datePickerRef}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        zIndex: 1300,
                    }}
                    placement="bottom-start"
                >
                    <DatePickerComponent value={startDate} onChange={onChangeStartDate} {...rest} {...startDateProps} />
                    <DatePickerComponent
                        disabled={!startDate}
                        minDate={startDate}
                        value={endDate}
                        onChange={onChangeEndDate}
                        {...rest}
                        {...endDateProps}
                    />
                </Menu>
            </Dropdown>
        </JoyProvider>
    );
}
