import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import AutocompleteWithExclusion from "../molecules/AutocompleteWithExclusion/AutocompleteWithExclusion";
import { selectIndustry, selectIndustryCompany, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

export function Industry({ id }: { id: SearchStateKeys }) {
    const { isLoading, dispatch } = useSearchState(id);

    const industryState = useSelector(selectIndustry);
    const industryCompanyState = useSelector(selectIndustryCompany);

    const { values, options } = id === 'industry' ? industryState : industryCompanyState;

    const handleChange = (value: typeof values) => {
        dispatch(
            setValues({
                key: id,
                value,
            })
        );
    };

    return (
        <AutocompleteWithExclusion
            size="small"
            label="Industry"
            placeholder="Industry"
            value={values}
            options={Object.keys(options).map((i) => i)}
            onChange={handleChange}
            loading={isLoading}
            fullWidth
        />
    );
}
