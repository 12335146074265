import AddIcon from "@mui/icons-material/Add";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CsvHeaders from "../../components/CSVHeaders/CSVHeaders";
import {
    createProject,
    resetState,
    selectCanShowAddProject,
    selectCanShowUpdateJd,
    selectCsvFile,
    selectErrors,
    selectShowCsvHeaderDialog,
    setProjectName,
    setShowCsvHeaderDialog,
    updateJd,
    uploadCsv,
} from "../../components/Navbar/CreateProject/CreateProject.reducer";
import { outreachRegions } from "../../pages/project/project.types";
import { useAppDispatch } from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";

export const BDCreateProject = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const canShowCreateProject = useSelector(selectCanShowAddProject);
    const canShowUpdateJd = useSelector(selectCanShowUpdateJd);
    const showCsvHeaderDialog = useSelector(selectShowCsvHeaderDialog);
    const csvFile = useSelector(selectCsvFile);
    const errors = useSelector(selectErrors);

    const isProjectCreating = useSelector(checkIfLoading(createProject.type));
    const isCsvUploading = useSelector(checkIfLoading(uploadCsv.type));
    const isJDUploading = useSelector(checkIfLoading(updateJd.type));
    const [objective, setObjective] = useState("");
    const [platform, setPlatform] = useState("");
    const [outreachRegion, setOutreachRegion] = useState("");
    useEffect(() => {
        dispatch(resetState());
    }, []);

    return (
        <Dialog
            onClose={() => dispatch(resetState())}
            open={canShowCreateProject}
            sx={{
                "& .MuiPaper-root": {
                    width: "500px",
                },
            }}
        >
            <DialogTitle>{canShowUpdateJd ? "Update Campaign" : "Create New Campaign"}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {!canShowUpdateJd && (
                        <Stack>
                            <TextField
                                placeholder="*Campaign name"
                                onBlur={(e) => dispatch(setProjectName(e.target.value))}
                                inputProps={{
                                    style: {
                                        padding: "0.7rem",
                                        fontSize: "14px",
                                    },
                                }}
                            />
                            {errors?.projectName && <Typography color="red">{errors?.projectName}</Typography>}
                        </Stack>
                    )}

                    <FormControl size="small">
                        <InputLabel id="outreach-region">Outreach Region</InputLabel>
                        <Select
                            value={outreachRegion}
                            onChange={(e) => {
                                setOutreachRegion(e.target.value);
                            }}
                            label="Outreach Region"
                        >
                            {Object.keys(outreachRegions).map((item: string, i: number) => {
                                return (
                                    <MenuItem key={i} value={outreachRegions[item]}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={!isProjectCreating && !isCsvUploading && <AddIcon />}
                    onClick={() => {
                        canShowUpdateJd
                            ? dispatch(updateJd({}))
                            : csvFile
                              ? dispatch(setShowCsvHeaderDialog(true))
                              : dispatch(
                                    createProject({
                                        action: createProject.type,
                                        navigate,
                                        objective,
                                        platform,
                                        bdrRegions: outreachRegion,
                                    })
                                );
                    }}
                    disabled={isProjectCreating || isCsvUploading || isJDUploading}
                    sx={{
                        width: "fit-content",
                        padding: "5px 15px",
                        alignSelf: "center",
                    }}
                >
                    {canShowUpdateJd
                        ? "Update Project"
                        : isProjectCreating || isCsvUploading
                          ? "Creating project..."
                          : "Create"}
                </Button>
            </DialogActions>
            {showCsvHeaderDialog && csvFile && (
                <CsvHeaders
                    onSubmit={(mappedCsvHeaders: any) => {
                        dispatch(
                            createProject({
                                action: createProject.type,
                                navigate,
                                mappedCsvHeaders,
                                objective,
                                platform,
                                bdrRegions: outreachRegion,
                            })
                        );
                    }}
                />
            )}
        </Dialog>
    );
};
