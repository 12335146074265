import ChatIcon from "@mui/icons-material/Chat";
import Stack from "@mui/joy/Stack";
import { ReactNode } from "react";

interface ChatsPaneEmptyState {
    children: ReactNode;
}

export default function ChatsPaneEmptyState({ children }: ChatsPaneEmptyState) {
    return (
        <Stack
            sx={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                p: 2,
                fontSize: 12,
            }}
        >
            <ChatIcon style={{ fontSize: 50 }} />
            {children}
        </Stack>
    );
}
