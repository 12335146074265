import Typography from "@mui/material/Typography";

type IErrorLabelProps = {
    message: string;
};

export default function ErrorLabel({ message }: IErrorLabelProps) {
    return (
        <Typography variant="subtitle1" sx={{ color: "#ff0000d6", fontSize: "14px" }}>
            {message}
        </Typography>
    );
}
