import Chip, { ChipProps } from "@mui/joy/Chip";
import { useTranslation } from "react-i18next";

import { IProjectStatus } from "../../pages/project/project.types";
import { useGetProjectsQuery } from "../../store/apis/projects/projects.api";
import VirtualizedSelect from "../VirtualizedSelect";

import { formatDate } from "@/utils/helper";

enum EDashboardStatusLabel {
    ACTIVE = "Active",
    ON_HOLD = "On hold",
    CLOSED = "Closed",
}

function getProjectChipColor(status: IProjectStatus): ChipProps["color"] {
    switch (status) {
        case "ACTIVE":
            return "success";
        case "ON_HOLD":
            return "neutral";
        case "CLOSED":
            return "danger";
        default:
            return "neutral";
    }
}

interface ProjectsDropdownProps {
    projectId: number;
    handleChange: (projectId: number, project: any) => void;
    userId?: number;
    disabled?: boolean;
    width?: number | string;
    defaultOptionLabel?: string;
}

export default function ProjectsDropdown({
    projectId,
    handleChange,
    userId,
    disabled,
    width,
    defaultOptionLabel,
}: ProjectsDropdownProps) {
    const { isLoading, data: allProjectsList = [] } = useGetProjectsQuery(userId);
    const { t } = useTranslation();

    return (
        <VirtualizedSelect
            size="sm"
            sx={{ width: width ?? "150px" }}
            inputProps={{ placeholder: "Search..." }}
            searchKeys={["createdBy.email", "createdBy.name"]}
            value={projectId.toString()}
            defaultOption={{ label: defaultOptionLabel ?? t("superInbox.projectFiltersAllProjects"), value: "0" }}
            variant="soft"
            color="neutral"
            disabled={isLoading || disabled}
            sortingFunction={(Items) =>
                Items?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            }
            options={allProjectsList
                .filter(({ dashboardStatus }) => dashboardStatus === "ACTIVE" || dashboardStatus === "ON_HOLD")
                .map((item) => ({ label: item.name, value: item._id.toString(), ...item }))}
            // placeholder={t("superInbox.projectFiltersAllProjects")}
            onChange={(item) => handleChange(parseInt(item.value), item)}
            enableSearch={allProjectsList.length > 10}
            itemHeight={55}
            renderOption={(item) => {
                return (
                    // <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <span>{item.label}</span>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    marginLeft: "0.5rem",
                                }}
                            >
                                <Chip variant="solid" size="sm" color={getProjectChipColor(item.dashboardStatus)}>
                                    {EDashboardStatusLabel[item.dashboardStatus]}
                                </Chip>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginTop: "0.25rem",
                                fontSize: "0.75rem",
                                fontStyle: "italic",
                            }}
                        >
                            <span>Created on {formatDate(new Date(item.createdAt).toString())}</span>
                        </div>
                    </div>
                );
            }}
        />
    );
}
