import { Children, PropsWithChildren, cloneElement, isValidElement, ComponentType } from "react";

type ReactComponentType = ComponentType<any>;

export function isFunctionalComponent(element: ReactComponentType): boolean {
    return typeof element === "function";
}

export function isClassComponent(element: ReactComponentType): boolean {
    return typeof element === "function" && element.prototype && (element.prototype as any).isReactComponent;
}

export function parentPropsWithChildren(props: PropsWithChildren) {
    const { children, ...parentProps } = props;
    return Children.toArray(children).map((child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { ...parentProps, ...child.props }); // child props can override parent props
        }
        return child;
    });
}
