import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentCompany, selectPastCompany, setValues } from "../../store/reducers/search/search.slice";

export function CheckPastCompanies() {
    const dispatch = useDispatch();
    const { values } = useSelector(selectCurrentCompany);
    const { values: pastCompanyValues } = useSelector(selectPastCompany);

    const [isChecked, setIsChecked] = useState(false);

    const handlePastCompanyCheck = (_e: React.SyntheticEvent, val: boolean) => {
        setIsChecked(val);
    };

    useEffect(() => {
        dispatch(
            setValues({
                key: "pastCompany",
                value: isChecked ? values : [],
            })
        );
    }, [isChecked]);

    useEffect(() => {
        if (!values?.length) {
            setIsChecked(false);
        }
    }, [values]);

    useEffect(() => {
        if (pastCompanyValues?.length && !isChecked) {
            setIsChecked(true);
        }
    }, [pastCompanyValues]);

    return (
        <FormControlLabel
            disabled={!values?.length}
            checked={isChecked}
            onChange={handlePastCompanyCheck}
            control={<Checkbox size="small" />}
            sx={{
                "& .MuiFormControlLabel-label": {
                    fontSize: 12,
                },
            }}
            label="Include companies under past work experience as well"
        />
    );
}
