import { ActionMeta, ClassNamesConfig, GroupBase, MultiValue } from "react-select";
import _CreatableSelect from "react-select/creatable";

import styles from "./CreatableSelect.module.css";

import { optionType } from "../../pages/AdvancedFilters/Components/Filters/Filters.type";



const CreatableSelect = ({
    classNames,
    isLoading = false,
    onChange,
    onInputChange,
    placeholder,
    noOptionsMessage,
    isClearable = true,
    name,
    options,
    isSearchable = true,
    value,
    customStyles,
    disabled,
}: {
    classNames?: string;
    isLoading?: boolean;
    isClearable?: boolean;
    placeholder?: string;
    noOptionsMessage?: string;
    name: string;
    onChange: (value: MultiValue<optionType>, actionMeta: ActionMeta<optionType>) => void;
    onInputChange?: Function;
    options: optionType[];
    isSearchable?: boolean;
    value?: optionType[];
    customStyles?: any;
    disabled?: boolean;

}) => {
    return (
        <_CreatableSelect
            styles={customStyles}
            isClearable={isClearable}

            isMulti

            className={`${styles.container} ${classNames}`}
            classNames={selectClassNames}
            isLoading={isLoading}
            onChange={onChange}
            placeholder={placeholder}
            noOptionsMessage={() => <span>{noOptionsMessage || "Please start typing..."}</span>}
            name={name}
            onInputChange={(newValue: string) =>
                onInputChange &&
                onInputChange({
                    value: newValue,
                    label: name,
                })
            }

            options={options}
            isSearchable={isSearchable}
            value={value}
            createOptionPosition="first"
            isDisabled={disabled}
        />
    );
};

const selectClassNames: ClassNamesConfig<optionType, true, GroupBase<optionType>> = {
    container: (_) => styles.container,
    input: (_) => styles.input,
    placeholder: (_) => styles.placeholder,
    multiValueLabel: (_) => styles.multiValue,
    option: (_) => styles.option,
    singleValue: (_) => styles.singleValue,
    control: (_) => styles.control,
    valueContainer: (_) => styles.valueContainer,
};

export default CreatableSelect;
