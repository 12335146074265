import { Fragment, useState } from "react";
import { HomeRepairServiceOutlined } from "@mui/icons-material";
import HouseIcon from "@mui/icons-material/House";
import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { isEmpty } from "lodash";
import Linkify from "react-linkify";

import { VettingCriteriaRating } from "../../../../components/VettingCriteriaRating";
import { LeadScoreRating } from "../../../../easy-growth/components/LeadScoreRating";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

const CandidateProfileHeading = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600",
    color: theme.palette.grey[800],
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: "0.875rem",
    color: "#32383f",
    fontWeight: "400",
}));

function TypographyWithReadMore({ text, title, limit = 250 }: { text: string; title: string; limit?: number }) {
    const [readMore, setReadMore] = useState(false);
    const spanText = readMore ? "Read Less" : "Read More";
    const value = readMore ? text : text?.slice(0, limit) + "...";
    const linkDecorator = (href: string, text: string, key: string) => (
        <p style={{ fontSize: "30px" }}>
            {" "}
            <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        </p>
    );
    return (
        <CustomTypography variant="subtitle2">
            <span style={{ fontSize: "0.85rem", color: "#32383f", fontWeight: "600" }}>Description: </span>{" "}
            <Linkify componentDecorator={linkDecorator}>{value}</Linkify>{" "}
            <span
                style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "400" }}
                onClick={() => setReadMore((prev) => !prev)}
            >
                {spanText}
            </span>
        </CustomTypography>
    );
}

function ResearchPaper({ paper }: { paper: any }) {
    return (
        <Stack spacing={1.4} p={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography
                    variant="h6"
                    component="a"
                    href={paper.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        color: "#01579b",
                        textDecoration: "none",
                        fontSize: "1rem",
                        lineHeight: "1.5",
                        fontWeight: "500",
                    }}
                >
                    {paper?.title?.split(" ").length > 9
                        ? `${paper?.title?.split(" ")?.slice(0, 9)?.join(" ")}...`
                        : paper?.title}{" "}
                    <sup>↗</sup>
                </Typography>
                {paper?.year && (
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {paper.year}
                    </Typography>
                )}
            </Stack>

            {paper?.authors && (
                <Typography variant="body2">
                    <b>Authors:</b> {paper.authors}
                </Typography>
            )}

            {paper?.journal && (
                <Typography variant="body2">
                    <b>Journal:</b> {paper.journal}
                </Typography>
            )}

            {(paper?.paperScore || paper?.citations) && (
                <Typography variant="body2">
                    {paper?.paperScore && paper?.paperScore !== 0 && (
                        <span>
                            <b>Score:</b> {paper?.paperScore?.slice(0, 5)}
                        </span>
                    )}{" "}
                    {paper?.citations && paper?.citations !== 0 && (
                        <span>
                            | <b>Citations:</b> {paper?.citations}
                        </span>
                    )}
                </Typography>
            )}

            {paper?.description && paper?.description !== "N/A" && (
                <TypographyWithReadMore text={paper.description} title={""} />
            )}
        </Stack>
    );
}

export default function ResearchPapersTab({
    data,
    starRating,
    leadScore,
    projectId,
    candidateId,
    vettingFixed,
}: {
    data: any;
    starRating?: any;
    leadScore: any;
    projectId: any;
    candidateId: any;
    vettingFixed: any;
}) {
    return (
        <Stack spacing={5} py={2} sx={{ maxHeight: "80vh", overflowY: "auto" }}>
            <Stack spacing={1}>
                {!isEmpty(starRating) ? (
                    <VettingCriteriaRating
                        esProps={{
                            projectId: projectId,
                            candidateId: candidateId,
                            vettingFixed: vettingFixed,
                        }}
                    />
                ) : null}
            </Stack>
            {/* {!isEmpty(leadScore && starRating) ? (
                <LeadScoreRating
                    egProps={{
                        leadScore: leadScore,
                        starRating: starRating,
                        projectId: projectId,
                        candidateId:candidateId,
                        vettingFixed: vettingFixed,
                    }}
                />
            ) : null} */}

            {/* <Stack spacing={1}>
                <CandidateProfileHeading variant="body2">Overview</CandidateProfileHeading>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ paddingLeft: "15px" }}>
                    <Typography variant="body2" sx={{ fontSize: "1rem", lineHeight: "1", fontWeight: "500" }}>
                        {data.degreeType}
                    </Typography>

                    {data.homePage && data.homePage !== "N/A" && (
                        <Button
                            href={data.homePage}
                            target="_blank"
                            sx={{
                                fontWeight: "bold",
                                color: "#01579b",
                                marginRight: "12px",
                                "&:focus": {
                                    border: "0px",
                                    boxShadow: "none",
                                },
                            }}
                        >
                            <IconButton
                                component="a"
                                href={data.homePage}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                                sx={{ p: 0, marginRight: "3px" }}
                            >
                                <HouseIcon sx={{ fontSize: "20px", color: "black", paddingBottom: "2px" }} />
                            </IconButton>
                            Homepage
                        </Button>
                    )}
                </Stack>

                <Stack sx={{ paddingLeft: "15px" }}>
                    <Stack direction="row" sx={{ marginBottom: "5px" }} spacing={1}>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: "0.875rem", lineHeight: "1.43", fontWeight: "500" }}
                        >
                            {data.citations && data.citations !== 0 && <span>Citations: {data.citations} |</span>}{" "}
                            {data.hIndex && data.hIndex !== 0 && <span>H-Index: {data.hIndex} |</span>}{" "}
                            {data.i10Index && data.i10Index !== 0 && <span>i10-Index: {data.i10Index} </span>}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack> */}

            {data?.papers && data?.papers?.length > 1 && (
                <Stack spacing={1}>
                    <CandidateProfileHeading variant="body2">Research Papers</CandidateProfileHeading>
                    {data.papers.map((paper: any, i: any) => (
                        <Fragment key={`paper-${i}`}>
                            <ResearchPaper paper={paper} />
                            {data.papers.length - 1 !== i && <Divider />}
                        </Fragment>
                    ))}
                </Stack>
            )}
        </Stack>
    );
}
