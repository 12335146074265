import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import { analyticsDataType, graphType } from "./Analytics.type";

const initialState = Object.freeze({
    graph: {
        chartLabel: {},
    },
    sourcing: {
        sourcedCandidates: 0,
        prevSourcedCandidates: 0,
        qualifiedCandidatesCount: 0,
        prevQualifiedCandidatesCount: 0,
        totalContacted: 0,
        prevTotalContacted: 0,
        emailSentCount: 0,
        prevEmailSentCount: 0,
        inMailCount: 0,
        prevInMailCount: 0,
        connectionReqCount: 0,
        prevConnectionReqCount: 0,
    },
});

const reducers = {
    getAnalytics(
        state: any,
        {
            payload,
        }: {
            payload: {
                fromDate: Date;
                toDate?: Date;
                projectId?: number;
                userId?: number;
            };
        }
    ) {},
    setAnalytics(state: any, { payload }: { payload: analyticsDataType }) {
        state.sourcing = payload;
    },
    getGraph(
        state: any,
        {
            payload,
        }: {
            payload: {
                fromDate: Date;
                toDate?: Date;
                projectId?: number;
                userId?: number;
            };
        }
    ) {},
    setGraph(state: any, { payload }: { payload: graphType }) {
        state.graph = payload;
    },
    leaveRoute(state: typeof initialState, { payload }: { payload: any }) {},
};

export const pageSlice = createSlice({
    name: "analytics",
    initialState,
    reducers,
});

export default pageSlice.reducer;

export const { getAnalytics, getGraph, setAnalytics, setGraph, leaveRoute } = pageSlice.actions;

export const selectSourcing = (state: RootState) => state.analytics.sourcing;

export const selectGraph = (state: RootState) => state.analytics.graph;
