import { useEffect, useState } from "react";
import { TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { ErrorBoundary, FallbackUI } from "../../components/ErrorBoundary/ErrorBoundary";
import { setVettingCriteriaText } from "../../components/Navbar/CreateProject/CreateProject.reducer";
import Navbar from "../../components/Navbar/Navbar";
import Search from "../../components/Search/index";
import useFilterWithPageSearch from "../../hooks/useFilterWithPageSearch";
import usePageSearch from "../../hooks/usePageSearch";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import handleCleverTap from "../../utils/clevertap";
import { IProject } from "../project/project.types";
import { checkEasyGrowth, checkShowAdditionalStats, selectShowAllProjects, selectUser } from "../Signin/Signin.reducer";
import { signinType } from "../Signin/Signin.types";
import style from "./allProjects.module.scss";
import Table from "./components/table/Table";
import {
    getAllProjectsList,
    selectAllProjects,
    selectCurrentPage,
    selectPageSize,
    selectQuery,
    selectTotalProjects,
    setCurrentPage,
    setPageSize,
    setQuery,
} from "./index.reducer";

const getFilteredProjects = (user: signinType, showAllProjects: boolean, allProjectsList: IProject[]): IProject[] => {
    if (user && allProjectsList) {
        const { roleType, _id } = user;

        if (showAllProjects === true) {
            return [...allProjectsList];
        }
        // this check is unnecessary since , showAllProeject can only be toggled by ADMIN
        if (roleType && roleType === "ADMIN") {
            return allProjectsList.filter((project) => {
                return project.createdBy?._id == _id;
            });
        }
    }
    return [];
};

export default function AllProjects() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [currentPage, setCurrentPage] = useState<number>(1);
    // const [pageSize, setPageSize] = useState<number>(25);
    const allProjectsList = useSelector(selectAllProjects) ?? [];
    const showAllProjects = useSelector(selectShowAllProjects);
    const checkProjectsListLoading = useSelector(checkIfLoading(getAllProjectsList.type));
    // const currentPage = useSelector(selectCurrentPage);
    const pageSize = useSelector(selectPageSize);
    const totalProjects = useSelector(selectTotalProjects);
    const showAdditionalStats = useSelector(checkShowAdditionalStats);
    const { t } = useTranslation();
    const isEasyGrowth = useSelector(checkEasyGrowth);
    // const query = useSelector(selectQuery);
    const query = isEasyGrowth ? usePageSearch() : useSelector(selectQuery);

    useEffect(() => {
        const payload = {
            action: getAllProjectsList.type,
            ...(isEasyGrowth && {
                paginated: {
                    start: currentPage,
                    limit: pageSize,
                },
                search: query,
                showAdditionalStats: showAdditionalStats && isEasyGrowth,
                ...(showAllProjects === false && { userId: String(user._id) }),
            }),
        };
        dispatch(setVettingCriteriaText(false));
        dispatch(getAllProjectsList(payload));
    }, [pageSize, currentPage, showAllProjects, query, showAdditionalStats, isEasyGrowth]);

    const filteredProjectAll = getFilteredProjects(user, showAllProjects, allProjectsList);
    const filteredProject = isEasyGrowth
        ? filteredProjectAll
        : useFilterWithPageSearch(filteredProjectAll, ["name", "_id", "createdBy.name", "createdBy.email"]);

    const onPageChange = (page: number, pageSize: number) => {
        // dispatch(setCurrentPage(page));
        dispatch(setPageSize(pageSize));
        setCurrentPage(page);
        // setPageSize(pageSize);
        setQuery(query);
    };

    const getPaginationText = (total: number, range: [number, number]) => {
        if (total === 0) {
            return `No ${t("projects")} found`;
        }
        return `${range[0]}-${range[1]} of ${total} ${t("projects")}`;
    };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        onPageChange(newPage + 1, pageSize);
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onPageChange(1, parseInt(event?.target?.value, 10));
    };

    return (
        <div className={style["pro"]}>
            <Navbar isAdmin={user?.roleType === "ADMIN"} />
            <ErrorBoundary fallbackRender={() => <FallbackUI sx={{ mt: "5rem" }} />}>
                <Table data={filteredProject} loading={checkProjectsListLoading} />
            </ErrorBoundary>

            <div className={style["arrowMessage"]}>Use Ctrl/Cmd + F keys to search</div>

            {isEasyGrowth && !checkProjectsListLoading && (
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={totalProjects}
                    rowsPerPage={pageSize}
                    page={currentPage - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    labelDisplayedRows={({ from, to, count }) => getPaginationText(count, [from, to])}
                />
            )}
            <Outlet />
        </div>
    );
}
