import { Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { ArcElement, ChartData, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { isUndefined } from "lodash";
import { Pie } from "react-chartjs-2";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import buildings from "../../../../assets/img/buildings.svg";
import { getExperience } from "../../../../utils/helper";
import { selectCurrProject } from "../../../allProjects/index.reducer";
import { selectProjectStats } from "../../index.reducer";
import { IProjectExperience } from "../../project.types";
import style from "./projectInfo.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function GenderPieChart() {
    const projectStats = useSelector(selectProjectStats);
    const genderStats = projectStats?.gender;

    const state = {
        labels: ["Male", "Female", "Others"],
        datasets: [
            {
                backgroundColor: ["#003f5c", "#58508d", "#bc5090"],
                data: [genderStats?.male, genderStats?.female, genderStats?.others],
            },
        ],
    };

    if (isUndefined(genderStats) || (genderStats?.male === 0 && genderStats.female === 0)) {
        return null;
    }

    return (
        <>
            <Divider orientation="vertical" sx={{ maxHeight: "400px" }} />
            <Stack spacing={1}>
                <Typography variant="body1" fontWeight={500}>
                    Results Overview
                </Typography>
                <Stack mt={1}>
                    <Typography fontSize={14} fontWeight={500} color="#6B6B6B" sx={{ textDecoration: "underline" }}>
                        Diversity Snapshot of evaluated candidates:
                    </Typography>
                    <Typography color="#6B6B6B" fontSize={12} fontStyle="italic">
                        (Result of a predictive analysis using candidate profile stats)
                    </Typography>
                    <Stack height={300} width={300}>
                        <Pie
                            data={state}
                            options={{
                                responsive: true,
                                plugins: {
                                    tooltip: {
                                        enabled: false,
                                    },
                                    legend: {
                                        display: true,
                                        position: "right",
                                        labels: {
                                            textAlign: "left",
                                            generateLabels: (chart) => {
                                                const chartData = chart.data as ChartData;
                                                if (chartData?.labels?.length && chartData.datasets.length) {
                                                    return chartData.labels.map((label, index) => {
                                                        const dataset = chartData.datasets[0];
                                                        const currentValue = dataset.data[index];

                                                        const total = dataset.data?.reduce(
                                                            // @ts-ignore
                                                            (accumulator: number, currentValue: number) =>
                                                                (accumulator + currentValue) as number,
                                                            0
                                                        );

                                                        const percentage = Math.round(
                                                            //@ts-ignore
                                                            (currentValue /
                                                                //@ts-ignore
                                                                total) *
                                                                100
                                                        );
                                                        return {
                                                            text: `${label}:(${percentage}%)`,
                                                            fillStyle:
                                                                // @ts-ignore
                                                                dataset.backgroundColor[index],
                                                            index,
                                                        };
                                                    });
                                                }
                                                return [];
                                            },
                                        },
                                    },
                                    datalabels: {
                                        formatter: () => {
                                            return "";
                                        },
                                        // formatter: (value, ctx) => {
                                        //     let sum = 0;
                                        //     const dataArr =
                                        //         ctx.chart.data.datasets[0].data;
                                        //     dataArr.forEach((data) => {
                                        //         sum += data as number;
                                        //     });
                                        //     const percentage =
                                        //         ((value * 100) / sum).toFixed(1) + "%";
                                        //     return percentage;
                                        // },
                                        // color: "#fff",
                                    },
                                },
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
}

export default function ProjectInfo() {
    const { t } = useTranslation();
    const projectData = useSelector(selectCurrProject);
    const {
        source,
        jobTitle,
        experience,
        location,
        skills,
        education,
        industry,
        // visa,
        // vettingCriteria,
    } = projectData || {};

    return (
        <div className={style["sp__tooltip"]}>
            <Stack spacing={1.5}>
                <Typography variant="body1" fontWeight={500}>
                    {t("project")} Description
                </Typography>
                <div className={style["sp__tooltipWrapper"]}>
                    {source ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Source</h2>
                            <p>
                                {source === "RB2B" ? "RB2B" : source === "LINKEDIN" ? "LinkedIn" : "LinkedIn Recruiter"}
                            </p>{" "}
                        </div>
                    ) : null}
                    {jobTitle && jobTitle.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Job Title</h2>
                            {jobTitle.length > 1 ? (
                                <ul>
                                    {jobTitle.map((title: string) => (
                                        <li>{title}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{jobTitle[0]}</p>
                            )}
                        </div>
                    ) : null}
                    {experience && experience.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Experience</h2>
                            {experience.length > 1 ? (
                                <ul>
                                    {experience.map((ex) => (
                                        <li>{getExperience(ex as IProjectExperience)}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{getExperience(experience[0] as IProjectExperience)}</p>
                            )}
                        </div>
                    ) : null}
                    {location && location.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Geography</h2>
                            {location.length > 1 ? (
                                <ul>
                                    {location.map((loc: { label: string; value: string }) => (
                                        <li>{loc.label}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{location[0].label}</p>
                            )}
                        </div>
                    ) : null}
                    {education && education.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Education</h2>
                            {education.length > 1 ? (
                                <ul>
                                    {education.map((edu: string) => (
                                        <li>{edu}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{education[0]}</p>
                            )}
                        </div>
                    ) : null}
                    {industry && industry.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Industry</h2>
                            {industry.length > 1 ? (
                                <ul>
                                    {industry.map((industry: { label: string; value: string }) => {
                                        return industry?.label ? <li>{industry.label}</li> : null;
                                    })}
                                </ul>
                            ) : (
                                <p>{industry[0].label}</p>
                            )}
                        </div>
                    ) : null}
                    {skills && skills.length ? (
                        <div className={style["sp__tooltipItem"]}>
                            <h2>Keywords</h2>
                            {skills.length > 1 ? (
                                <ul>
                                    {skills.map((skill: string) => (
                                        <li>{skill}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{skills[0]}</p>
                            )}
                        </div>
                    ) : null}
                    {/* {.course && course.length ? (
                    <div className={style["sp__tooltipItem"]}>
                        <h2>Course</h2>
                        {course.length > 1 ? (
                            <ul>
                                {course.map((course: string) => (
                                    <li>{course}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{course[0]}</p>
                        )}
                    </div>
                ) : null} */}
                    {/* <div className={style["sp__tooltipItem"]}>
                    <h2>Visa</h2>
                    <p>{visa ? "Yes" : "No"}</p>
                </div> */}
                </div>
            </Stack>
            {/* {vettingCriteria && vettingCriteria.length ? (
                <Stack spacing={1}>
                    <Typography
                        mt={1}
                        fontSize={14}
                        fontWeight={500}
                        color="#6B6B6B"
                        sx={{ textDecoration: "underline" }}
                    >
                        Vetting Criteria:
                    </Typography>
                    <ul className={style["sp__vettingCriteria"]}>
                        {vettingCriteria?.map(({ id, text }) => (
                            <li key={id}>{text}</li>
                        ))}
                    </ul>
                </Stack>
            ) : null} */}
            <ErrorBoundary
                fallback={
                    <Typography variant="body2" textAlign="center">
                        Pie Chart Unavailable...
                    </Typography>
                }
            >
                <GenderPieChart />
            </ErrorBoundary>
        </div>
    );
}
