import { Button, DialogActions, DialogContent, DialogTitle, Input, Stack, Typography } from "@mui/joy";
import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { JoyProvider } from "../../../components/JoyProvider";
import { setErrorNotification } from "../../../components/Notification/index.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectUser, updateUser } from "../../Signin/Signin.reducer";

interface LaborEdgeCredentials {
    username: string;
    password: string;
}

interface LaborEdgeConnectProps {
    onClose: () => void;
}

export default function LaborEdgeConnect({ onClose }: LaborEdgeConnectProps) {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const isCredsSubmitting = useSelector(checkIfLoading(updateUser.type));

    const [laborEdge, setLaborEdge] = useState<LaborEdgeCredentials>({
        username: user.laborEdge?.username ?? "",
        password: user.laborEdge?.password ?? "",
    });

    useEffect(() => {
        setLaborEdge({
            username: user.laborEdge?.username ?? "",
            password: user.laborEdge?.password ?? "",
        });
    }, [user.laborEdge]);

    const handleSubmit = () => {
        if (laborEdge.username.length === 0) {
            dispatch(setErrorNotification("Please enter LaborEdge username"));
            return;
        }
        if (laborEdge.password.length === 0) {
            dispatch(setErrorNotification("Please enter LaborEdge password"));
            return;
        }

        dispatch(
            updateUser({
                ...user,
                laborEdge: laborEdge,
                action: updateUser.type,
                onSuccess: onClose,
            })
        );
    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="connect-laboredge-title"
            sx={{ borderRadius: "md", boxShadow: "lg" }}
        >
            <JoyProvider>
                <DialogTitle id="connect-laboredge-title" sx={{ fontWeight: "bold", padding: 2 }}>
                    Connect LaborEdge
                </DialogTitle>
                <DialogContent sx={{ width: "400px" }}>
                    <Stack spacing={2} sx={{ padding: 2 }}>
                        <Stack direction="column" spacing={1}>
                            <Typography level="body-md" sx={{ fontWeight: "bold" }}>
                                Username
                            </Typography>
                            <Input
                                id="username"
                                placeholder="Enter Username"
                                value={laborEdge.username}
                                onChange={(e) =>
                                    setLaborEdge((prev) => ({
                                        ...prev,
                                        username: e.target.value,
                                    }))
                                }
                                fullWidth
                            />
                        </Stack>
                        <Stack direction="column" spacing={1}>
                            <Typography level="body-md" sx={{ fontWeight: "bold" }}>
                                Password
                            </Typography>
                            <Input
                                id="password"
                                type="password"
                                placeholder="Enter Password"
                                value={laborEdge.password}
                                onChange={(e) =>
                                    setLaborEdge((prev) => ({
                                        ...prev,
                                        password: e.target.value,
                                    }))
                                }
                                fullWidth
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "flex-start", padding: 2 }}>
                    <Button
                        onClick={handleSubmit}
                        loading={isCredsSubmitting}
                        variant="solid"
                        color="primary"
                        sx={{ margin: 2 }}
                    >
                        {isCredsSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                    <Button variant="outlined" onClick={onClose} color="neutral">
                        Cancel
                    </Button>
                </DialogActions>
            </JoyProvider>
        </Dialog>
    );
}
