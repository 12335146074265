import { useDispatch, useSelector } from "react-redux";

import { useOutreachParams } from "./useOutreachParams.hook";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { useAddWorkflowStepMutation } from "../../../store/apis/outreach/outreach.api";
import { AddWorkflowResponse } from "../../../store/apis/outreach/outreach.types";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { selectVirtualUserId } from "@/store/reducers/template/template.reducer";

type UseAddWorkflowProp = {
    successCallback?: (data: AddWorkflowResponse) => void;
};

const useAddWorkflowStep = ({ successCallback }: UseAddWorkflowProp) => {
    const dispatch = useDispatch();
    const [addWorkflowStepMutation, { ...saveMutationState }] = useAddWorkflowStepMutation();
    const { projectId } = useOutreachParams();
    const user = useSelector(selectUser);
    const virtualUserId = useSelector(selectVirtualUserId);

    const addWorkflowStep = (type: "email" | "SMS" | "follow-up" | "linkedin") => {
        const payload = {
            projectId: Number(projectId),
            eventName: type,
        };
        const finalPayload =
            !virtualUserId || Number(virtualUserId) === user?._id
                ? payload
                : { virtualUserId: Number(virtualUserId), ...payload };

        addWorkflowStepMutation(finalPayload)
            .unwrap()
            .then((res) => {
                successCallback?.(res);
            })
            .catch((err) => {
                console.log({ err });
                dispatch(setErrorNotification("An error occurred, please try again after some time"));
            });
    };

    return {
        addWorkflowStep,
        saveMutationState,
    };
};

export default useAddWorkflowStep;
