import { uniqueId } from "lodash";
import { Children, cloneElement } from "react";
import { useParams } from "react-router-dom";

import { useCreateActionChatMutation, useUpdateActionChatMutation } from "../../../../store/apis/chatbot/chatbot.api";
import { useChatbotContext } from "../../Context";

interface MessageParserProps {
    children: any;
    actions: {
        reply: (response: { message: string; loaderId: string; citations?: any[]; triggerAction?: any }) => void;
        wait: (loaderId: string) => void;
    };
}

function MessageParser({ children, actions }: MessageParserProps) {
    const params = useParams();
    const { actionbot, setActionbot } = useChatbotContext();
    const [createActionChat] = useCreateActionChatMutation();
    const [updateActionChat] = useUpdateActionChatMutation();

    const parse = async (message: any) => {
        const loaderId = uniqueId();

        actions.wait(loaderId);
        let res: any;

        try {
            if (actionbot?.threadId) {
                res = await updateActionChat({ threadId: actionbot.threadId, userResponse: message });
            } else {
                res = await createActionChat({ query: message, projectId: params.id });
                setActionbot({ ...actionbot, threadId: res?.data.threadId, reload: false });
            }
        } catch (error) {
            console.error("Error creating or updating action chat:", error);
            actions.reply({ message: "Oops! Something went wrong. Please try again later.", loaderId });
            return;
        }
        const replyMsg = res?.data?.confirmationMessage ?? "Oops! Something went wrong. Please try again later.";

        actions.reply({ message: replyMsg, loaderId, triggerAction: res?.data?.triggerAction });
    };

    return (
        <div>
            {Children.map(children, (child) => {
                return cloneElement(child, {
                    parse: parse,
                    actions: actions,
                });
            })}
        </div>
    );
}

export default MessageParser;
