import NotificationPageCellWrapper from "./NotificationPageCellWrapper";

import { Progress } from "@/ui";

type NotificationPageHeaderProps = {
    isLoading?: boolean;
};

function NotificationPageHeader({ isLoading = false }: NotificationPageHeaderProps) {
    // const handleSelectAllNotifications = () => {
    //     selectAllNotifications(!allNotificationsSelected);
    // };

    return (
        <div className="relative">
            <div className="mt-2 mx-4 p-3 bg-white rounded-t-lg border-[1.6px] grid gap-4 grid-cols-[4fr_1fr_1fr_1fr_1fr_auto]">
                {/* TEMPORARY REMOVAL OF SELECTION OPTION */}
                {/* <div className="flex items-center justify-center">
                <Checkbox
                    size="sm"
                    className="w-[16px] h-[16px]"
                    onChange={handleSelectAllNotifications}
                    checked={_isAllNotificationsSelected}
                />
            </div> */}
                <NotificationPageCellWrapper typographyClasses="font-semibold text-sm">
                    Notification
                </NotificationPageCellWrapper>
                <NotificationPageCellWrapper typographyClasses="font-semibold text-sm">
                    Project
                </NotificationPageCellWrapper>
                <NotificationPageCellWrapper typographyClasses="font-semibold text-sm">
                    User
                </NotificationPageCellWrapper>
                <NotificationPageCellWrapper className="justify-end" typographyClasses="font-semibold text-sm">
                    Received at
                </NotificationPageCellWrapper>
                <NotificationPageCellWrapper typographyClasses="font-semibold text-sm">
                    {""}
                </NotificationPageCellWrapper>
            </div>
            {isLoading ? (
                <Progress variant="indeterminate" thickness={1} className="mx-4 absolute bottom-0 left-0 right-0" />
            ) : null}
        </div>
    );
}

export default NotificationPageHeader;
