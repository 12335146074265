import { z } from "zod";

const graphDataSchema = z.array(
    z
        .object({
            _id: z.string().nullable(),
            count: z.number(),
        })
        .optional()
);

const graphSchema = z.object({
    meetingsBooked: graphDataSchema,
    positiveResponses: graphDataSchema,
    relevantCandidates: graphDataSchema,
    responsesReceived: graphDataSchema,
    totalContacted: graphDataSchema,
});

export const funnelOverviewSchema = z.object({
    counts: z.object({
        meetingsBooked: z.number(),
        positiveResponses: z.number(),
        relevantCandidates: z.number(),
        responsesReceived: z.number(),
        totalContacted: z.number(),
    }),

    graphs: z.object({
        sourceGraph: graphSchema,
        channelGraph: graphSchema,
    }),
});

export const linkedinResponseSchema = z.object({
    connectionReqSent: z.number(),
    inMailSent: z.number(),
    requestAccepted: z.number(),
    responsesReceived: z.number(),
    positiveResponses: z.number(),
});

export const emailResponseSchema = z.object({
    emailsSent: z.number(),
    followupEmailsSent: z.number(),
    responsesReceived: z.number(),
    positiveResponses: z.number(),
});

export const smsResponseSchema = z.object({
    smsMessagesDelivered: z.number(),
    smsResponsesReceived: z.number(),
    positiveResponses: z.number(),
});

export const outreachStatsSchema = z.object({
    linkedin: linkedinResponseSchema,
    email: emailResponseSchema,
    sms: smsResponseSchema,
});

export const projectsStatusSchema = z.object({
    projects: z.number(),
    activeProjects: z.number(),
    onHoldProjects: z.number(),
    closedProjects: z.number(),
});

export const LinkedinResponseLabel = {
    connectionReqSent: "Connection requests sent",
    inMailSent: "InMails sent",
    requestAccepted: "Requests accepted",
    responsesReceived: "Responses received",
    positiveResponses: "Positive responses",
};

export const SMSResponsesLabel = {
    smsMessagesDelivered: "SMS messages delivered",
    smsResponsesReceived: "SMS responses received",
    positiveResponses: "Positive responses",
};

export const EmailResponsesLabel = {
    emailsSent: "Emails sent",
    followupEmailsSent: "Followup emails sent",
    responsesReceived: "Responses received",
    positiveResponses: "Positive responses",
};

export const ProjectResponsesLabel = {
    projects: "analytics.projectStatusTotalLabel",
    activeProjects: "analytics.projectStatusActiveLabel",
    onHoldProjects: "analytics.projectStatusHoldLabel",
    closedProjects: "analytics.projectStatusClosedLabel",
};

export const CreditUsageLabel = {
    sourcedCandidates: "analytics.creditUsageExtracted",
    qualifiedCandidates: "analytics.creditUsageQualifiedExtracted",
    verifiedEmails: "analytics.verifiedEmail",
    phoneNumbers: "analytics.phoneNumber",
};
