import "./JobDescriptionViewer.css";
import DocumetViewer from "../../components/DocumentViewer/DocumentViewer";

type Props = {
    jd: string;
};

export default function JobDescriptionViewer({ jd }: Props) {
    return <DocumetViewer url={jd} />;
}
