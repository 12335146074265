import { PayloadAction } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
    CreateProjectPayload,
    ICreateProjectErrors,
    IUpdateMarketingProjectPayload,
    UploadCSVPayload,
    addContactListToProject,
    createProject,
    leavePage,
    resetState,
    setCanShowAddProject,
    setCanShowIntegratedProjectModal,
    setCanShowUpdateJd,
    setErrors,
    setShowCsvHeaderDialog,
    submitValuePropositionInputs,
    updateJd,
    updateMarketingProject,
    uploadCsv,
} from "./CreateProject.reducer";

import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import { SubmitValuePropositionPayload } from "../../../easy-growth/components/AddValueProposition";
import { getProject } from "../../../pages/allProjects/index.reducer";
import { startAction, stopAction } from "../../../store/reducers/loaders.reducer";
import { setModal } from "../../../store/reducers/modals.slice";
import API, { apiBase } from "../../../utils/API";
import { startSse } from "../../../utils/firebase";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";

function* createProjectSaga(action: CreateProjectPayload): SagaIterator {
    try {
        const { navigate } = action.payload;
        const state = yield select();
        const name = get(state, "createProject.projectName");
        const jd = get(state, "createProject.projectJd");
        const csvFile = get(state, "createProject.csvFile");
        const detailScrape = get(state, "createProject.detailScrape");
        const liCookie = get(state, "createProject.liCookie");
        const purpose = get(state, "createProject.purpose");

        // marketing data
        const marketingCompanyLogo = get(state, "marketingPageReducer.marketingCompanyLogo");
        const marketingCompanyVideo = get(state, "marketingPageReducer.marketingCompanyVideo");
        const marketingCompanyVideoLink = get(state, "marketingPageReducer.marketingCompanyVideoLink");
        const marketingOverview = get(state, "marketingPageReducer.marketingOverview");

        const errors: ICreateProjectErrors = {};
        if (!name) {
            errors.projectName = "Please enter valid project name";
        }

        if (detailScrape && !liCookie) {
            errors.liCookie = "LinkedIn cookie is required";
        }

        if (!isEmpty(errors)) {
            yield put(setErrors(errors));
            return;
        }

        yield put(startAction({ action: action.type }));

        const formData = new FormData();

        formData.append("name", name);
        if (purpose) formData.append("purpose", purpose);
        if (jd) formData.append("file", jd);
        if (marketingCompanyLogo) formData.append("logo", marketingCompanyLogo);
        if (marketingCompanyVideo) formData.append("video", marketingCompanyVideo);
        if (marketingCompanyVideoLink) formData.append("videoLink", marketingCompanyVideoLink);
        if (marketingOverview) formData.append("overview", marketingOverview);
        if (action.payload.bdrRegions) formData.append("bdrRegions", action.payload.bdrRegions);

        const response = yield call(new API().post, `${apiBase}/v2/project/create-jd-project`, formData);

        if (!response?.data) return;

        if (response?.data?._id) {
            navigate(`/projects/${response.data._id}`);
        } else {
            throw new Error();
        }

        if (action.payload.callback) {
            action.payload.callback(response.data._id);
        }

        if (!csvFile?.size) {
            yield put(setCanShowAddProject(false));
            yield put(setCanShowIntegratedProjectModal(false));
            yield put(setSuccessNotification("Project created successfully"));
            return;
        }

        yield put(uploadCsv({ projectId: response?.data?._id }));
    } catch (e) {
        handleException(e);
        console.error("***createProjectSaga err=", e);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* uploadCsvSaga(action: PayloadAction<UploadCSVPayload>): SagaIterator {
    const { payload, type } = action;
    try {
        const state = yield select();
        const { mappedCsvHeaders } = action.payload;
        const isEasySource = get(state, "signin.isEasySource");
        const csvFile = get(state, "createProject.csvFile");
        const liCookie = get(state, "createProject.liCookie");
        const detailScrape = get(state, "createProject.detailScrape");
        const emailScrape = get(state, "createProject.canScrapeEmail");
        const isSalesNav = get(state, "createProject.isSalesNav");

        if (!csvFile?.size) {
            yield put(setErrorNotification("CSV is required"));
            return;
        }

        if (!isEasySource) {
            const isEmailPresent = Object.values(mappedCsvHeaders).indexOf("email") > -1;
            // const isNamePresent =
            //     Object.values(mappedCsvHeaders).includes("name") ||
            //     Object.values(mappedCsvHeaders).includes("first name");
            const project = get(state, "allProjects.project");
            // if (!isNamePresent) {
            //     yield put(setErrorNotification("Name field should be mapped correctly"));
            //     return;
            // }
            if (!isEmailPresent && ["Email", "Email & Linkedin"].includes(project?.outreachChannel)) {
                yield put(setErrorNotification("Email field should be mapped correctly"));
                return;
            }
            yield put(setShowCsvHeaderDialog(false));
        }

        const errors: ICreateProjectErrors = {};

        // if (detailScrape && !liCookie) {
        //     errors.liCookie = "*This is a required field";
        // }

        if (!isEmpty(errors)) {
            yield put(setErrors(errors));
            return;
        }

        yield put(startAction({ action: type }));

        const { projectId, onSuccess, cvSource } = payload;

        const csvResponse = yield call(
            new API().post,
            `${apiBase}/v2/project/bulk-upload`,
            {
                projectId,
                file: csvFile,
                csvHeaders: payload.mappedCsvHeaders,
                liCookie,
                detailScrape,
                emailScrape,
                isSalesNav,
                cvSource,
            },
            { headers: { "Content-Type": "multipart/form-data" } }
        );

        if ("error" in csvResponse) {
            throw new Error();
        }

        if (onSuccess) onSuccess();

        startSse();
        yield put(resetState());
        yield put(
            getProject({
                projectId,
                action: getProject.type,
            })
        );

        yield put(setCanShowAddProject(false));
        yield put(setCanShowIntegratedProjectModal(false));
        yield put(setSuccessNotification("Csv uploaded successfully"));
    } catch (e) {
        handleException(e);
        console.error("***uploadCsvSaga err=", e);
        yield put(setErrorNotification("error while uploading csv"));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* updateJdSaga({ payload }: { payload: { objective?: string; platform?: string } }): SagaIterator {
    try {
        const state = yield select();
        const jd = get(state, "createProject.projectJd");
        const projectId = get(state, "allProjects.project._id");
        const name = get(state, "allProjects.project.name");
        const jdTitle = get(state, "allProjects.project.jdTitle");
        const jdDescription = get(state, "allProjects.project.jdDescription");
        const onlyPersonalEmail = get(state, "allProjects.project.onlyPersonalEmail");

        // if (!jd?.size) return;
        yield put(startAction({ action: updateJd.type }));

        const csvResponse = yield call(
            new API().put,
            `${apiBase}/v2/project/update-jd`,
            {
                projectId: projectId,
                file: jd,
                name,
                onlyPersonalEmail,
                objective: payload.objective,
                outreachChannel: payload.platform,
                jdTitle,
                jdDescription,
            },
            { headers: { "Content-Type": "multipart/form-data" } }
        );

        if (!csvResponse.data) return;
        yield put(
            getProject({
                projectId: String(projectId),
                action: getProject.type,
            })
        );

        yield put(setSuccessNotification("Project Updated Successfully"));
    } catch (e) {
        handleException(e);
        console.error("***uploadCsvSaga err=", e);
    } finally {
        yield put(stopAction({ action: updateJd.type }));
        yield put(setCanShowUpdateJd(false));
        yield put(setCanShowAddProject(false));
        yield put(setCanShowIntegratedProjectModal(false));
        yield put(resetState());
    }
}

function* submitValuePropositionInputsSaga(action: SubmitValuePropositionPayload): SagaIterator {
    try {
        const state = yield select();
        const projectId = get(state, "allProjects.project._id");
        yield put(startAction({ action: action.type }));

        const csvResponse = yield call(
            new API().put,
            `${apiBase}/v2/project/update-jd`,
            {
                projectId: projectId,
                productInfo: {
                    ...action.payload,
                },
            },
            { headers: { "Content-Type": "multipart/form-data" } }
        );

        if (!csvResponse.data) {
            yield put(setErrorNotification("Project Updated Successfully"));
        } else {
            yield put(setSuccessNotification("Project Updated Successfully"));
            yield put(setModal({ value: false, key: "showValuePropositionModal" }));
        }
    } catch (e) {
        handleException(e);
        console.error("***submitValuePropositionInputsSaga err=", e);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addContactListToProjectSaga(action: PayloadAction<string>): SagaIterator {
    try {
        const state = yield select();
        const projectId = get(state, "allProjects.project._id");

        yield put(startAction({ action: addContactListToProject.type }));

        const csvResponse = yield call(new API().put, `${apiBase}/v2/project/update-jd`, {
            projectId: projectId,
            contactListId: action.payload,
        });

        if (!csvResponse.data) return;
        yield put(
            getProject({
                projectId: String(projectId),
                action: getProject.type,
            })
        );

        yield put(setSuccessNotification("Project Updated Successfully"));
    } catch (e) {
        handleException(e);
        console.error("***addContactListToProjectSaga err=", e);
    } finally {
        yield put(stopAction({ action: addContactListToProject.type }));
        yield put(setCanShowUpdateJd(false));
        yield put(setCanShowAddProject(false));
        yield put(setCanShowIntegratedProjectModal(false));
        yield put(resetState());
    }
}

function* updateMarketingProjectSaga(action: PayloadAction<IUpdateMarketingProjectPayload>): SagaIterator {
    try {
        yield put(startAction({ action: updateMarketingProject.type }));

        const formData = {
            ...action.payload,
        };

        const response = yield call(new API().put, `${apiBase}/v2/project/update-marketing-project`, formData);
        if (response.data) {
            yield put(setSuccessNotification(response.message));
        }
    } catch (error) {
        handleException(error);
        console.error("***updateMarketingProjectSaga err=", error);
    } finally {
        yield put(stopAction({ action: updateMarketingProject.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(addContactListToProject.type, addContactListToProjectSaga),
        // @ts-ignore
        yield takeLatest(submitValuePropositionInputs.type, submitValuePropositionInputsSaga),
        // @ts-ignore
        yield takeLatest(createProject.type, createProjectSaga),
        // @ts-ignore
        yield takeLatest(uploadCsv.type, uploadCsvSaga),
        // @ts-ignore
        yield takeLatest(updateJd.type, updateJdSaga),
        // @ts-ignore
        yield takeLatest(updateMarketingProject.type, updateMarketingProjectSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
