import { MutableRefObject, useEffect, useMemo, useState } from "react";
import ImageResize from "quill-image-resize-module-react";
import ImageUploader from "quill-image-uploader";
import ReactQuill, { Quill } from "react-quill";

import API from "../utils/API";
import handleException from "../utils/sentry";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageUploader", ImageUploader);

(window as any).Quill = Quill;

function compressImage(file: File, maxWidth: number, maxHeight: number, quality: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event: ProgressEvent<FileReader>) {
            const image = new Image();
            image.onload = function () {
                const canvas = document.createElement("canvas");
                let width = image.width;
                let height = image.height;

                // Calculate the new dimensions
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                // Set the canvas dimensions
                canvas.width = width;
                canvas.height = height;

                // Draw the image onto the canvas
                const context = canvas.getContext("2d");
                if (context) {
                    context.drawImage(image, 0, 0, width, height);

                    // Convert canvas to base64-encoded JPEG
                    canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error("Failed to compress image."));
                            }
                        },
                        file.type,
                        quality
                    );
                } else {
                    reject(new Error("Failed to get 2D context."));
                }
            };
            if (event.target) {
                const target = event.target as FileReader;
                image.src = target.result as string;
            }
        };
        reader.onerror = function (error: ProgressEvent<FileReader>) {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
}

function uploadImages(file: File, callback: (status: boolean) => void) {
    return new Promise(async (resolve, reject) => {
        try {
            callback(true);
            const formData = new FormData();
            const compressedFile = await compressImage(file, 400, 300, 0.8);
            formData.append("files", compressedFile);
            formData.append("projectId", "editor-uploads");
            const response = await new API().post("upload/files", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (Array.isArray(response)) {
                resolve(response);
            } else {
                reject("Uploading failed");
            }
        } catch (error) {
            handleException(error);
            console.log("Error image uploading: ", error);
            reject("Uploading failed");
        } finally {
            callback(false);
        }
    });
}

function useImageUploadingObserver(editorRoot: HTMLElement | null = null) {
    useEffect(() => {
        if (!editorRoot) return;

        // MutationObserver to detect changes in the editor content
        const observer = new MutationObserver(() => {
            const uploadingElements = editorRoot.getElementsByClassName("image-uploading");

            const uploadingElementsArray = Array.from(uploadingElements);
            uploadingElementsArray.forEach((uploadingElement: Element) => {
                uploadingElement.childNodes.forEach((node: ChildNode) => {
                    // Check if the child node is an image being uploaded as a blob
                    if ((node as HTMLElement).tagName === "IMG") {
                        const isBeingUploaded = (node as HTMLImageElement)?.src?.startsWith("data:image");
                        if (isBeingUploaded) {
                            uploadingElement.classList.add("image-uploading-indicator");
                        } else {
                            uploadingElement.classList.remove("image-uploading-indicator");
                        }
                    }
                });
            });
        });

        // Configure and start the observer
        const config = { childList: true, subtree: true };
        observer.observe(editorRoot, config);
    }, [editorRoot]);
}

export function useQuillImageModule(ref: MutableRefObject<ReactQuill | null>) {
    const [isUploading, setIsUploading] = useState(false);

    useImageUploadingObserver(ref.current?.getEditor?.().root);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [["image"]],
            },
            imageResize: {
                parchment: Quill.import("parchment"),
            },
            imageUploader: {
                upload: (file: any) => uploadImages(file, setIsUploading),
            },
        }),
        []
    );

    return { modules, isUploading };
}
