import Typography from "@mui/material/Typography";

import NoData from "../../../components/NoData";

export default function OutreachNoMatch() {
    return (
        <NoData
            containerProps={{
                height: "70%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
            }}
        >
            <Typography variant="h6">Templates Unavailable...</Typography>
        </NoData>
    );
}
