import MessagesPaneHeader from "./MessagesPaneHeader";

import { ErrorBoundary, FallbackUI } from "../../../../components/ErrorBoundary/ErrorBoundary";
import { useFetchCandidateDetailsQuery } from "../../../../store/apis/all-candidates/all-candidates.api";
import { useSuperInboxContext } from "../../Context";
import ChannelTabs from "../channels/ChannelTabs";
import Email from "../conversations/Email";
import Linkedin from "../conversations/Linkedin";
import SMS from "../conversations/SMS";
import Scrollable from "../Scrollable";

interface MessagesPaneContentProps {}

export default function MessagesPaneContent(_props: MessagesPaneContentProps) {
    const { candidateParamId } = useSuperInboxContext();
    const { data: defaultProfile, isLoading, isFetching } = useFetchCandidateDetailsQuery(candidateParamId);

    const candidateProfile = defaultProfile && {
        ...defaultProfile,
        profileImage: defaultProfile?.profileImage?.startsWith("data:")
            ? defaultProfile?.name
            : defaultProfile?.profileImage,
    };

    const commonProps = { candidateProfile };

    return (
        <Scrollable.Container sx={{ backgroundColor: "background.level1" }}>
            <MessagesPaneHeader loading={isLoading || isFetching} {...commonProps} />
            <ErrorBoundary fallbackRender={() => <FallbackUI sx={{ mt: "5rem" }} />}>
                <ChannelTabs>
                    {(activeChannel, tagsProps) => {
                        switch (activeChannel) {
                            case "email":
                                return <Email {...{ ...commonProps, ...tagsProps }} />;
                            case "linkedin":
                                return <Linkedin {...{ ...commonProps, ...tagsProps }} />;
                            case "sms":
                                return <SMS {...{ ...commonProps, ...tagsProps }} />;
                            default:
                                return null;
                        }
                    }}
                </ChannelTabs>
            </ErrorBoundary>
        </Scrollable.Container>
    );
}
