import { RefObject, useEffect, useRef, useState } from "react";

/**
 * Custom hook for using the Intersection Observer API.
 *
 * @param {Function} callback - Function to call when the intersection state changes.
 * @param {IntersectionObserverInit} options - Options for the Intersection Observer.
 * @returns {[RefObject<Element>, boolean]} A tuple containing a ref to the target element and the visibility state.
 */
function useIntersectionObserver(
    options: IntersectionObserverInit = {},
    callback: (entry: IntersectionObserverEntry) => void = () => {}
): [RefObject<HTMLElement>, boolean] {
    const targetRef = useRef<HTMLElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);
            if (entry.isIntersecting && callback) {
                callback(entry);
            }
        }, options);

        const targetElement = targetRef.current;

        if (targetElement) {
            observer.observe(targetElement);
        }

        return () => {
            if (targetElement) {
                observer.unobserve(targetElement);
            }
        };
    }, [callback, options]);

    return [targetRef, isVisible];
}

export default useIntersectionObserver;
