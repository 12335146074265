/* eslint-disable import/no-unused-modules */
import NotificationCard from "./NotificationCard";
import NotificationPageHeader from "./NotificationPageHeader";

import { useNotifications } from "../NotificationContext";

import { CustomDropdownOptionsType } from "@/components/CustomDropdown/CustomDropdown";
import CustomPagination from "@/components/CustomPagination/CustomPagination";
import Loader from "@/components/Loader/Loader";
import { ScrollArea } from "@/ui/scroll-area";

export function NotificationsPageBody() {
    const { isFetching, notifications, totalNotifications, filters, setFilters } = useNotifications();

    const handlePageOptionChange = (page: number) => {
        const newFilters = { ...filters, perPage: page };
        setFilters(newFilters);
    };

    const handlePageChange = (pageNumber: number) => {
        const newFilters = { ...filters, page: pageNumber };
        setFilters(newFilters);
    };

    const pageOptions: CustomDropdownOptionsType[] = [
        {
            label: "25 / page",
            key: "25",
            handleClick: () => handlePageOptionChange(25),
        },
        {
            label: "50 / page",
            key: "50",
            handleClick: () => handlePageOptionChange(50),
        },
    ];

    if (isFetching && !notifications[filters.pageName].length) {
        return (
            <div className="p-4 text-center h-[80vh]">
                <Loader />
            </div>
        );
    }

    if (!isFetching && !notifications[filters.pageName].length) {
        return <div className="p-4 text-center h-[80vh] flex items-center justify-center">No notifications found</div>;
    }

    return (
        <>
            <NotificationPageHeader isLoading={isFetching && !!notifications[filters.pageName]?.length} />
            <ScrollArea className="p-4 pb-0 pt-0 mt-0 h-[70vh]">
                {notifications[filters.pageName]?.map((notification) => (
                    <NotificationCard key={notification._id} notification={notification} />
                ))}
            </ScrollArea>

            <CustomPagination
                currentPage={filters.page}
                totalCandidates={totalNotifications[filters.pageName]}
                pageSize={filters.perPage}
                handlePageChange={handlePageChange}
                pageOptions={pageOptions as any}
                enablePageSizeChange={true}
            />
        </>
    );
}
