import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { get, isEmpty, isNil, isUndefined } from "lodash";
import queryString from "query-string";
import { SagaIterator } from "redux-saga";
import { call, cancelled, put, select, takeLatest } from "redux-saga/effects";

import { SAMPLE_PROJECT } from "../../constant/Constant";
import API from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import {
    createNewNote,
    createNewProject,
    deleteNote,
    deleteProject,
    editNote,
    editProject,
    fetchRB2BWebhookUrl,
    getActivities,
    getAllProjectsList,
    getCandidate,
    getCandidateEmail,
    getNotes,
    getProject,
    leaveAllProjects,
    refreshProjectNetNew,
    removeCandidateFromProject,
    saveAndRunExclusionList,
    setActivities,
    setAllProjectsList,
    setAutoSearchStatus,
    setCandidateEmail,
    setCustomField,
    setEmailProviderConfig,
    setNotes,
    setProject,
    setProjectsFetchError,
    setRB2BWebhookUrl,
    setRb2bConnectState,
    setTotalProjects,
    stopGenerateInsights,
    submitAutoSearchStatus,
    updateProjectCredits,
    updateProjectNetNew,
    uploadprojectStatsFile,
} from "../reducers/allProjects/allProjects.reducer";
import {
    EditProjectPayload,
    ICreateNotePayload,
    IDeleteProjectPayload,
    IEditNotePayload,
    IGetProjectPayload,
    IRemoveCandidateFromProjectPayload,
    ISaveAndRunExclusionList,
    IUpdateProjectCreditsPayload,
    SetProjectModePayload,
    StopGenerateInsightsPayload,
    emailProviders,
} from "../reducers/allProjects/allProjects.types";
import { setLinkedinScrapingState } from "../reducers/linkedin-scraping/linkedin-scraping.slice";
import { IActionPayload, startAction, stopAction } from "../reducers/loaders.reducer";
import { setProjectData } from "../reducers/outreach/outreach.slice";
import {
    checkIfMonsterResumeFiltersAreSet,
    checkIfResumeLibraryFiltersAreSet,
    checkIfRocketReachFiltersAreSet,
    setProjectSearchFilters,
} from "../reducers/search/search.slice";
import { setSearchType } from "../reducers/searchCandidates/searchCandidates.slice";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    getProjectCandidates,
    getProjectStats,
    setCandidateViewedStatus,
    setSelectAllCandidates,
} from "@/store/reducers/project/project.reducer";
import { IProject, IProjectStage } from "@/store/reducers/project/project.types";
import { signOut } from "../reducers/signin/Signin.reducer";

function* getAllProjectsSaga({
    payload,
}: {
    payload: IActionPayload & {
        paginated?: {
            limit: number;
            start: number;
        };
        userId?: string;
        showAdditionalStats?: boolean;
    };
}): SagaIterator {
    try {
        yield put(startAction({ action: getAllProjectsList.type }));
        let projectList: any = [];
        if (payload.paginated) {
            const baseUrl = payload?.showAdditionalStats ? `/v2/project/list-stats-add` : `/v2/project/list-stats`;
            const response = yield call(
                new API().get,
                `${baseUrl}?${queryString.stringify({
                    limit: payload.paginated.limit,
                    start: payload.paginated.start,
                    ...(payload.userId && { userId: payload.userId }),
                    ...(payload.search && { search: payload.search }),
                })}`
            );
            projectList = get(response, "data") || [];
            projectList = projectList.map((item: any) => {
                return {
                    ...item,
                    pipelined: item?.totalCandidates,
                };
            });
            const totalProjects = get(response, "totalProjects") || 0;
            yield put(setTotalProjects(totalProjects));
        } else {
            const response = yield call(new API().get, "/v2/project/list");
            projectList = get(response, "data") || [];
            if (!payload.avoidGetStats) {
                const statsResponse = yield call(new API().get, "/v2/project/stats");
                const statsList = get(statsResponse, "data") || [];

                if (!isEmpty(projectList) && !isEmpty(statsList)) {
                    projectList.map((item: any) => {
                        const projectStats = statsList.find((stats: any) => item._id === stats.projectId);
                        const pipelined = get(projectStats, "totalCandidate", 0);
                        const responded = get(projectStats, "responded", 0);
                        const contacted = get(projectStats, "contacted", 0);
                        const acceptedCSReview = get(projectStats, "acceptedCSReview", 0);

                        item.pipelined = pipelined;
                        item.contacted = contacted;
                        item.responded = responded;
                        item.acceptedCSReview = acceptedCSReview;
                    });
                }
            }
        }
        yield put(setAllProjectsList([...projectList, SAMPLE_PROJECT]));
    } catch (err: any) {
        console.error(err);
        // if (err?.response?.data?.error === "Access Denied") {
        //     yield put(signOut());
        // }
        handleException(err);
    } finally {
        yield put(stopAction({ action: getAllProjectsList.type }));
    }
}

function* createNewProjectSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(new API().post, "/v2/project/create", payload);

        yield put(getAllProjectsList({ action: getAllProjectsList.type }));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* editProjectSaga({ payload }: { payload: EditProjectPayload }): SagaIterator {
    try {
        const {
            _id,
            name,
            exclusionLists,
            onSuccess,
            handleGenerateInsights,
            action,
            excludeVettingCriteria,
            ...rest
        } = payload || {};

        const state = yield select();
        const vettingCriteriaStateValue = get(state, "vettingCriteria.criteria");

        const vettingCriteria = vettingCriteriaStateValue
            .filter(({ text }: { text: string }) => Boolean(text))
            .map((item: any) => ({
                ...item,
                updatedAt: new Date(),
            }));

        const data: any = {
            _id,
            name,
            ...rest,
        };

        console.log({ data });

        if (!excludeVettingCriteria) {
            data.vettingCriteria = vettingCriteria;
        }

        if (!isUndefined(exclusionLists)) {
            const exclusionListsIds = exclusionLists.map(({ value }: { value: string }) => value);
            data.exclusionLists = exclusionListsIds;
        }

        if (action) {
            yield put(startAction({ action }));
        }

        const response = yield call(new API().put, "/v2/project/update", data);

        if (isEmpty(response?.data)) return;

        const allProjectsListStateValue = get(state, "allProjects.projectList");
        const projectStateValue = get(state, "allProjects.project");

        const tempProjectList = allProjectsListStateValue.map((item: IProject) => {
            if (item._id === Number(_id)) {
                return {
                    ...item,
                    ...rest,
                };
            }
            return item;
        });

        console.log({ tempProjectList });

        if (onSuccess) onSuccess();

        if (handleGenerateInsights) handleGenerateInsights();

        const updatedProject = {
            ...projectStateValue,
            exclusionLists: isUndefined(exclusionLists) ? projectStateValue?.exclusionLists : exclusionLists,
        };
        if (name) updatedProject.name = name;
        if (!isEmpty(vettingCriteriaStateValue)) {
            updatedProject.vettingCriteria = vettingCriteriaStateValue;
        }

        yield put(setProject(updatedProject));
        yield put(setAllProjectsList(tempProjectList));
        yield put(setSuccessNotification(response.message));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        if (payload.action) {
            yield put(stopAction({ action: payload.action }));
        }
    }
}

function* getProjectSaga({ payload }: { payload: IGetProjectPayload & IActionPayload }): SagaIterator {
    const cancelTokenSource = axios?.CancelToken?.source();
    try {
        const {
            projectId,
            action,
            doNotFetchCandidates,
            doNotShowLoader,
            setBlendSearchFilters,
            doNotFetchStats = false,
        } = payload;

        yield put(setProjectsFetchError(false));
        if (!doNotShowLoader) {
            yield put(startAction({ action }));
        }

        const response = yield call(new API().get, "/v2/project/get/" + projectId, {
            cancelToken: cancelTokenSource?.token,
        });

        // if(response?.data?.rocketReach?.location.length > 0){
        //     yield put(setSearchType("rocketReach"))
        // }

        if (checkIfRocketReachFiltersAreSet(response?.data?.rocketReach)) {
            yield put(setSearchType("rocketReach"));
        }

        if (checkIfMonsterResumeFiltersAreSet(response?.data?.monster)) {
            yield put(setSearchType("monster"));
        }

        if (isEmpty(response?.data)) return;

        yield put(setProject(response.data));
        yield put(setProjectData(response.data));

        const provider = emailProviders.map((item) => {
            const data = response?.data?.emailProviderConfig?.find((i: any) => i?.type === item.type);
            if (!data) return item;
            const { status } = data;
            return { type: item.type, status: status };
        });
        yield put(setEmailProviderConfig(provider));

        if (!doNotFetchStats) {
            yield put(getProjectStats({ projectId, action: getProjectStats.type }));
        }

        const state = yield select();
        const pageNo = get(state, "project.candidateCurrentPage");
        const pageSize = get(state, "project.pageSize");

        if (response?.data?.customFields) {
            const customFields: { label: string; value: string }[] = [];
            response.data.customFields.forEach((item: string) => {
                customFields.push({
                    label: item,
                    value: `custom.${item}`,
                });
            });
            yield put(setCustomField({ customFields }));
        }

        if (setBlendSearchFilters) {
            const isResumeLibraryEnabled = checkIfResumeLibraryFiltersAreSet(response.data?.resumeLibrary);
            if (isResumeLibraryEnabled) {
                yield put(setSearchType("resume-library"));
            }
            yield put(setProjectSearchFilters(response.data));
            if (response.data.linkedinScrapingModal) {
                yield put(setLinkedinScrapingState(response.data.linkedinScrapingModal));
            }
        }

        if (doNotFetchCandidates) {
            return;
        }

        yield put(
            getProjectCandidates({
                projectId,
                start: pageNo,
                limit: pageSize,
                action: doNotShowLoader ? "" : getProjectCandidates.type,
            })
        );

        handleCleverTap("Project Details", {
            "Project id": payload?.projectId,
            candidates: response.data.candidates.length || 0,
        });
    } catch (err: unknown) {
        console.log("*****getProjectSaga err=", err);
        yield put(setProjectsFetchError(true));
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
        if (yield cancelled()) {
            cancelTokenSource?.cancel();
        }
    }
}

function* deleteProjectSaga(action: IDeleteProjectPayload): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().put, "/v2/project/delete", {
            projectIds: action.payload.projectIds,
        });

        if (!response) {
            throw new Error("Permission Restricted: Please contact your account manager to perform this action.");
        }

        yield put(setProject({}));
        yield put(getAllProjectsList({ action: getAllProjectsList.type }));
        yield put(setSuccessNotification("Project deleted successfully"));
    } catch (err: any) {
        setErrorNotification(err?.message);
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* getNotesSaga({ payload }: { payload: { id: string } & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/notes/get/" + payload.id);
        if (isNil(response?.data) || !Array.isArray(response?.data)) return;

        yield put(setNotes(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* createNewNoteSaga({ payload }: { payload: ICreateNotePayload & IActionPayload }): SagaIterator {
    try {
        const { action, ...rest } = payload;
        yield put(startAction({ action }));
        const response = yield call(new API().post, "/notes/create/", rest);
        yield put(setSuccessNotification(response.message));
        yield put(getNotes({ id: payload.candidateId, action: getNotes.type }));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editNoteSaga({ payload }: { payload: IEditNotePayload & IActionPayload }): SagaIterator {
    try {
        const { action, onSuccess, ...rest } = payload;

        yield put(startAction({ action }));
        const response = yield call(new API().put, "/notes/edit", rest);

        if (onSuccess) onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(getNotes({ id: payload.candidateId, action: getNotes.type }));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteNoteSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(
            //@ts-ignore
            new API().delete,
            "/notes/edit",
            payload
        );

        yield put(getNotes(payload.candidateId));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* getActivitiesSaga({ payload }: { payload: { id: string } & IActionPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/activity/get/" + payload.id);
        if (isEmpty(response?.data)) return;

        yield put(setActivities(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getCandidateEmailSaga(): SagaIterator {
    try {
        const response = yield call(new API().get, "/communication/get-candidate-emails");
        if (isEmpty(response?.data)) return;

        yield put(setCandidateEmail(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* getCandidateSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(new API().get, "/candidate/get/" + payload);
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* removeCandidateFromProjectSaga({ payload }: { payload: IRemoveCandidateFromProjectPayload }): SagaIterator {
    try {
        const state = yield select();
        const selectAllCandidatesStateValue = get(state, "project.selectAllCandidates");
        const projectFiltersStateValue = get(state, "project.projectFilters");
        const showByPersonalEmailsStateValue = state.project.showByPersonalEmails;
        const searchQuery = state.project.searchQuery;
        const appliedFilters = Object.keys(projectFiltersStateValue)
            .filter((key) => projectFiltersStateValue[key as IProjectStage])
            .concat(showByPersonalEmailsStateValue ? ["personalEmail"] : []);
        const filterByPayload = [...appliedFilters];

        const triggerPayload: {
            filterBy: string[];
            projectId: string;
            candidateIds: string[];
            selectAll?: boolean | undefined;
            search?: string;
        } = { ...payload, filterBy: filterByPayload };

        if (searchQuery) {
            triggerPayload.search = searchQuery;
        }

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }

        const response = yield call(new API().post, "/v2/project/candidates-from-project", triggerPayload);

        if (!response) return;

        yield put(
            getProject({
                projectId: payload.projectId,
                action: getProject.type,
            })
        );
        yield put(setSelectAllCandidates(false));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* updateProjectCreditsSaga({
    payload,
}: {
    payload: IUpdateProjectCreditsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().put, "/v2/project/update", payload.project);
        if (isEmpty(response?.data)) return;

        if (payload.onSuccess) payload.onSuccess();
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* stopGenerateInsightsSaga(action: StopGenerateInsightsPayload): SagaIterator {
    try {
        const { payload, type } = action;
        const { projectId, closeModal } = payload;
        yield put(startAction({ action: type }));
        const response = yield call(new API().get, `/v2/project/${projectId}/stop-rating`);
        console.log({ response });

        if (!response?.data?._id) {
            setErrorNotification("Error encountered while attempting to stop generating insights.");
            return;
        }

        yield put(setProject(response.data));
        closeModal();
    } catch (error) {
        handleException(error);
        setErrorNotification("Error encountered while attempting to stop generating insights.");
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* submitAutoSearchStatusSaga(action: SetProjectModePayload): SagaIterator {
    try {
        const { type, payload } = action;
        yield put(startAction({ action: type }));

        if (!payload?.project) {
            throw new Error("project unavailable");
        }

        const response = yield call(new API().post, "/auto-search/status-change", {
            autoSource: payload.status,
            projectId: payload.project,
        });

        if (!response?.data?._id) {
            throw new Error("error while updating auto search status");
        }
    } catch (err: unknown) {
        handleException(err);
        yield put(setErrorNotification("Error encountered while updating auto search status."));
        yield put(
            setAutoSearchStatus({
                ...action?.payload,
                status: !action?.payload?.status,
            })
        );
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* uploadprojectStatsFileSaga({ payload }: { payload: File }): SagaIterator {
    try {
        yield put(startAction({ action: uploadprojectStatsFile.type }));
        console.log("*****uploadprojectStatsFileSaga payload=", payload);
        const response = yield call(
            new API().put,
            `/v2/project/upload/project-stats-file`,
            {
                file: payload,
            },
            { headers: { "Content-Type": "multipart/form-data" } }
        );

        const id = document.getElementById("uploadStatsCsv");
        //@ts-ignore
        id.value = "";
    } catch (error) {
        handleException(error);
        console.error("*****uploadprojectStatsFileSaga response=", error);
    } finally {
        yield put(stopAction({ action: uploadprojectStatsFile.type }));
    }
}

function* saveAndRunExclusionListSaga(action: PayloadAction<ISaveAndRunExclusionList>): SagaIterator {
    const { type, payload } = action;
    try {
        const { _id, exclusionLists, onSuccess } = payload;

        yield put(startAction({ action: type }));

        const data: any = {
            _id,
        };

        if (!isUndefined(exclusionLists)) {
            const exclusionListsIds = exclusionLists.map(({ value }: { value: string }) => value);
            data.exclusionLists = exclusionListsIds;
        }

        const updateProjectResponse = yield call(new API().put, "/v2/project/update", data);

        if (!("data" in updateProjectResponse)) {
            throw new Error();
        }

        const response = yield call(new API().post, `/v2/project/${_id}/exclusion-rating`, {
            selectAll: true,
            candidateIds: [],
            search: "",
            filterBy: [],
        });

        if (!("data" in response)) {
            throw new Error();
        }

        onSuccess();
        yield put(
            getProject({
                projectId: _id,
                action: getProject.type,
            })
        );
    } catch (error) {
        handleException(error);
        console.error("*****saveAndRunExclusionListSaga err=", error);
        yield put(setErrorNotification("error in save & run exclusion lists"));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* refreshProjectNetNewSaga(
    action: PayloadAction<{
        projectId: number;
        customType: string;
    }>
): SagaIterator {
    const { payload } = action;
    const { projectId, customType } = payload;
    try {
        yield put(startAction({ action: customType }));

        const response = yield call(new API().get, `/v2/project/refresh-stats/${projectId}`);

        const newNet = response.data.netNew;

        yield put(
            updateProjectNetNew({
                projectId: projectId,
                newNet: newNet,
            })
        );
        yield put(setSuccessNotification("Prospects count updated successfully"));
    } catch (error) {
        handleException(error);
        // @ts-ignore
        yield put(setErrorNotification(error?.response?.data?.error));
    } finally {
        yield put(stopAction({ action: customType }));
    }
}

function* fetchRB2BWebhookUrlSaga(action: PayloadAction<{ projectId: number; onSuccess: () => void }>): SagaIterator {
    const { projectId, onSuccess } = action.payload;

    try {
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().post, `/rb2b/integraterb2b`, {
            projectId: projectId,
        });
        if (!response.data) {
            yield put(setErrorNotification(response.message));
            return;
        }
        const { url } = response.data;
        yield put(setRb2bConnectState(true));
        yield put(setRB2BWebhookUrl(url));
        onSuccess();
    } catch (error) {
        handleException(error);
        // @ts-ignore
        yield put(setErrorNotification(error?.response?.data?.error));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* updateViewedSaga(
    action: PayloadAction<{ projectId: number; viewed: boolean; candidateId: string }>
): SagaIterator {
    const { projectId, viewed, candidateId } = action.payload;
    try {
        yield put(startAction({ action: action.type }));
        yield call(new API().post, "/v2/project/viewed", {
            candidateId,
            projectId,
            viewed,
        });
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(fetchRB2BWebhookUrl.type, fetchRB2BWebhookUrlSaga),
        // @ts-ignore
        yield takeLatest(getAllProjectsList.type, getAllProjectsSaga),
        // @ts-ignore
        yield takeLatest(createNewProject.type, createNewProjectSaga),
        // @ts-ignore
        yield takeLatest(editProject.type, editProjectSaga),
        // @ts-ignore
        yield takeLatest(getProject.type, getProjectSaga),
        // @ts-ignore
        yield takeLatest(deleteProject.type, deleteProjectSaga),
        // @ts-ignore
        yield takeLatest(getNotes.type, getNotesSaga),
        // @ts-ignore
        yield takeLatest(createNewNote.type, createNewNoteSaga),
        // @ts-ignore
        yield takeLatest(deleteNote.type, deleteNoteSaga),
        // @ts-ignore
        yield takeLatest(editNote.type, editNoteSaga),
        // @ts-ignore
        yield takeLatest(getActivities.type, getActivitiesSaga),
        // @ts-ignore
        yield takeLatest(getCandidateEmail.type, getCandidateEmailSaga),
        // @ts-ignore
        yield takeLatest(getCandidate.type, getCandidateSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            removeCandidateFromProject.type,
            removeCandidateFromProjectSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            updateProjectCredits.type,
            updateProjectCreditsSaga
        ),
        // @ts-ignore
        yield takeLatest(stopGenerateInsights.type, stopGenerateInsightsSaga),
        // @ts-ignore
        yield takeLatest(submitAutoSearchStatus.type, submitAutoSearchStatusSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            uploadprojectStatsFile.type,
            uploadprojectStatsFileSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            saveAndRunExclusionList.type,
            saveAndRunExclusionListSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            refreshProjectNetNew.type,
            refreshProjectNetNewSaga
        ),
        yield takeLatest(
            // @ts-ignore
            setCandidateViewedStatus.type,
            updateViewedSaga
        ),
    ];
    // @ts-ignore
    yield takeLatest(leaveAllProjects.type, CancelSagas, tasks);
}
