import { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Menu, MenuItem } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useDispatch } from "react-redux";

import { setSuccessNotification } from "../../../components/Notification/index.reducer";
import useMenu from "../../../hooks/useMenu";
import {
    ReferTo,
    useFetchOrganizationMembersQuery,
    useUpdateContactOverviewActivityMutation,
} from "../../../store/apis/all-candidates/all-candidates.api";
import { CommonActivityCellProps } from "./ContactOverviewActivities/ContactOverviewActivities.types";

export default function AssignToMenu({
    index = 0,
    activityId,
    name,
    _id,
    ...props
}: ReferTo & CommonActivityCellProps) {
    const disaptch = useDispatch();
    const { anchorEl, handleClick, handleClose, open, menuSX } = useMenu();
    const [assignedTo, setAssignedTo] = useState<{ name: string; _id: number }>(() => ({
        name,
        _id: _id,
    }));
    const { data = [] } = useFetchOrganizationMembersQuery();

    useEffect(() => {
        setAssignedTo({ name: name, _id: _id });
    }, [name, _id]);

    const [updateContactOverviewActivityMutation] = useUpdateContactOverviewActivityMutation();

    const handleAssignedToUpdate = (name: string, _id: number) => {
        updateContactOverviewActivityMutation({
            activityId: activityId,
            // @ts-ignore
            referTo: {
                name,
                _id,
            },
        })
            .unwrap()
            .then(() => {
                disaptch(setSuccessNotification("Assigned to updated successfully"));
            })
            .catch(() => {
                disaptch(setSuccessNotification("Error while updating assigned to"));
            });
    };

    const handleMenuItemClick = (name: string, _id: number) => {
        handleClose();
        setAssignedTo({ name, _id });
        handleAssignedToUpdate(name, _id);
    };

    return (
        <>
            {/* @ts-ignore */}
            <Chip
                icon={<FiberManualRecordIcon fontSize="small" />}
                variant="filled"
                label={assignedTo.name}
                onClick={handleClick}
                size="small"
                sx={{
                    flexDirection: "row",
                    fontSize: "12px",
                    width: "fit-content",
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
            />
            <Menu sx={menuSX} anchorEl={anchorEl} open={open} onClose={handleClose}>
                {data.map(({ name, _id }) => {
                    if (!_id) {
                        return null;
                    }

                    return (
                        <MenuItem
                            key={_id}
                            onClick={() => handleMenuItemClick(name, _id)}
                            sx={{
                                "&.MuiMenuItem-root": {
                                    fontSize: "12px",
                                },
                            }}
                        >
                            {name}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
