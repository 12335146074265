import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { connectMonster, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Modal, Typography } from "@/ui";

function MonsterConnect({ onClose }: { onClose: () => void }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [clientId, setClientId] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [cat, setCat] = useState("");

    const isLoading = useSelector(checkIfLoading(connectMonster.type));

    const monster = user?.orgData?.monster;

    useEffect(() => {
        if (isEmpty(user)) return;
        setClientId(monster?.clientId ?? "");
        setClientSecret(monster?.clientSecret ?? "");
        setCat(monster?.cat ?? "");
    }, [user]);

    const handleConnect = () => {
        dispatch(
            connectMonster({
                clientId,
                clientSecret,
                cat,
            })
        );
        onClose();
    };

    return (
        <Modal open={true} onClose={onClose}>
            <div style={{ maxWidth: "500px" }}>
                <h2 className="text-xl font-semibold mb-5">Connect Monster ATS</h2>
                <div className=" space-x-2 mb-2">
                    <Typography className="text-sm">
                        Monster ATS is a powerful applicant tracking system. Enter your ClientId , ClientSecret and Cat
                        key to connect your Monster ATS account.
                    </Typography>
                </div>
                <input
                    placeholder="Monster ClientId"
                    value={clientId}
                    onChange={(e) => setClientId(e.target.value)}
                    className={`min-w-[300px] mt-4 text-sm px-3 py-2 border rounded focus:outline-none focus:ring-2  border-gray-300 focus:ring-blue-500"}`}
                    style={{ fontSize: "14px", backgroundColor: "white", padding: 6, width: "100%" }}
                />
                <input
                    placeholder="Monster ClientSecret"
                    value={clientSecret}
                    onChange={(e) => setClientSecret(e.target.value)}
                    className={`min-w-[300px] mt-4 text-sm px-3 py-2 border rounded focus:outline-none focus:ring-2  border-gray-300 focus:ring-blue-500"}`}
                    style={{ fontSize: "14px", backgroundColor: "white", padding: 6, width: "100%" }}
                />
                <input
                    placeholder="Monster Cat Key"
                    value={cat}
                    onChange={(e) => setCat(e.target.value)}
                    className={`min-w-[300px] mt-4 text-sm px-3 py-2 border rounded focus:outline-none focus:ring-2  border-gray-300 focus:ring-blue-500"}`}
                    style={{ fontSize: "14px", backgroundColor: "white", padding: 6, width: "100%" }}
                />
                <div className="flex justify-end space-x-2 mt-6">
                    <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                    <Button
                        variant="default"
                        onClick={handleConnect}
                        className="rounded-sm"
                        disabled={!cat || !clientSecret || !clientId || isLoading}
                        label={monster?.clientId && monster?.clientSecret && monster?.cat ? "Connected" : "Connect"}
                    />
                </div>
            </div>
        </Modal>
    );
}

export { MonsterConnect };
