import { ThemeProvider } from "@mui/material/styles";
import { useTour } from "@reactour/tour";
import * as Sentry from "@sentry/react";
import Tap from "@tapfiliate/tapfiliate-js";
import firebase from "firebase/compat/app";
import { get } from "lodash";
import { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";

import "firebase/compat/auth";
import "./App.scss";

import IntercomLauncher from "./components/IntercomLauncher";
import AppLoader from "./components/Loader/AppLoader";
import Notification from "./components/Notification/Notification";
import {
    listenExtension,
    selectIsExtensionInstalled,
    selectLiBasedToken,
    selectShowLoginLoader,
    setShowLoginLoader,
} from "./container/AppContainer/AppContainer.reducer";
import { selectTourIsOpen } from "./container/TourContainer/TourContainer.reducer";
import initializeIndexedDB from "./indexedDB/IndexedDB";
import {
    authLogin,
    initialState as initialSignInState,
    selectUser,
    setFromExtension,
    setFromInvitation,
} from "./pages/Signin/Signin.reducer";
import { signinType } from "./pages/Signin/Signin.types";
import { routes } from "./routes";
import store from "./store";
import theme from "./theme";
import { findCookie } from "./utils/cookie";
import { loadHotjar } from "./utils/hotjar";
import HandleException from "./utils/sentry";

Tap.init(import.meta.env.VITE_VITE_REACT_APP_TAPFILIATE_ACC_ID);

initializeIndexedDB()
    .then(() => console.log("db connected"))
    .catch(console.error);

function RoutesContainer() {
    const showLoginLoader = useSelector(selectShowLoginLoader);
    const tourIsOpen = useSelector(selectTourIsOpen);
    const isExtensionInstalled = useSelector(selectIsExtensionInstalled);
    const liBasedToken = useSelector(selectLiBasedToken);

    const { setIsOpen } = useTour();
    const searchParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();

    useEffect(() => {
        try {
            const auth = firebase.auth();
            const token = findCookie("accessToken");

            dispatch(listenExtension());
            const fromExtension = searchParams.get("fromExtension");
            const fromInvite = searchParams.get("fromInvite");

            if (fromExtension) {
                dispatch(setFromExtension(!!fromExtension));
            }

            if (fromInvite) {
                dispatch(setFromInvitation(!!fromInvite));
            }

            if (token) {
                dispatch(setShowLoginLoader(false));
            }

            const unsubscribe = auth.onAuthStateChanged(async (user) => {
                dispatch(setShowLoginLoader(false));
                if (user) {
                    console.log("onAuthStateChanged user registered");
                    const currentUser: signinType = get(user, "multiFactor.user") || initialSignInState.user;
                    const { displayName, email } = currentUser;
                    dispatch(
                        authLogin({
                            action: authLogin.type,
                            user: currentUser,
                            displayName,
                            email,
                        })
                    );
                    Sentry.setUser({ email, displayName });
                } else {
                    console.log("onAuthStateChanged user not registered");
                    setTimeout(() => {
                        const token = findCookie("accessToken");
                        if (token) {
                            dispatch(
                                authLogin({
                                    action: authLogin.type,
                                    user: { name: "", email: "" },
                                })
                            );
                            Sentry.setUser(null);
                        }
                    }, 1000);
                }
            });

            return () => {
                unsubscribe();
                console.log("onAuthStateChanged unregistered");
            };
        } catch (error: any) {
            console.log("Authentication error:", error);
        }
    }, []);

    useEffect(() => {
        if (isExtensionInstalled) return;
        if (liBasedToken) {
            dispatch(
                authLogin({
                    action: authLogin.type,
                    user: { name: "", email: "" },
                })
            );
        }
    }, [isExtensionInstalled, liBasedToken]);

    useEffect(() => {
        setIsOpen(tourIsOpen);
    }, [tourIsOpen]);

    if (showLoginLoader) {
        return <AppLoader />;
    }

    return <RouterProvider router={routes} />;
}

function AppContent() {
    const user = useSelector(selectUser);

    useEffect(() => loadHotjar(user), [user]);

    useEffect(() => {
        const logButtonClick = (event: any) => {
            if (event?.target?.tagName === "BUTTON") {
                const originalHandler = event?.target?.onclick;
                if (originalHandler) {
                    try {
                        originalHandler(event);
                    } catch (error) {
                        HandleException(error);
                    }
                }
            }
        };
        document.addEventListener("click", logButtonClick);

        return () => {
            document.removeEventListener("click", logButtonClick);
        };
    }, []);

    return (
        <>
            <RoutesContainer />
            <Notification />
            <IntercomLauncher />
        </>
    );
}

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <AppContent />
            </ThemeProvider>
        </Provider>
    );
}

export default App;
