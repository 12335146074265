import { useEffect } from "react";
import { Block } from "@mui/icons-material";
import { Box, Button, List, ListItem, ListItemText, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getBlacklist, getRemoveFromBlocklist, selectBlockList } from "../project/index.reducer";

const CallBlockingModal = ({ open, onClose }) => {
    const blockList = useSelector(selectBlockList);
    const dispatch = useDispatch();
    const handleClose = () => {
        onClose();
    };
    useEffect(() => {
        dispatch(getBlacklist({}));
    }, []);
    const handleUnblock = (candidate_id) => {
        dispatch(getRemoveFromBlocklist({ candidateId: candidate_id }));
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="call-block-list-modal"
            aria-describedby="list-of-blocked-numbers"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    maxWidth: 400,
                    overflow: "auto",
                    maxHeight: "90vh",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography id="call-block-list-modal" variant="h6" component="h2" gutterBottom>
                    Blocked Numbers
                </Typography>
                {blockList?.length === 0 && (
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        No numbers blocked.
                    </Typography>
                )}
                <List>
                    {blockList?.map((item, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <Button
                                    variant="outlined"
                                    startIcon={<Block />}
                                    size="small"
                                    color="error"
                                    onClick={() => handleUnblock(item.candidateId)}
                                >
                                    Unblock
                                </Button>
                            }
                        >
                            <ListItemText
                                primary={item.name}
                                secondary={item?.phone?.find((phone) => phone !== null)}
                            />
                        </ListItem>
                    ))}
                </List>
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleClose}>Close</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CallBlockingModal;
