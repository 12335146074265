import React, { ReactElement, useEffect, useState } from "react";
import { ListItemDecorator } from "@mui/joy";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Stack from "@mui/joy/Stack";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import { SvgIconProps } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

import { withConfigModeHOC } from "../../hocs/withConfigMode";
import { minimizeSidebarPaths } from "../../utils/Constants";
import style from "./sidebar.module.scss";

export type SidebarItemProps = {
    link: string;
    icon: ReactElement<SvgIconProps>;
    text: string;
    isNewFeature?: boolean;
    onClick?: () => void;
};

const defaultIconProps: SvgIconProps = {
    fontSize: "small",
    sx: {
        width: 20,
        height: 20,
        marginRight: 0,
        color: "inherit",
    },
};

export const BDSidebarItem = withConfigModeHOC<{}, SidebarItemProps>({
    Eg: SidebarItem,
});

export const ESSidebarItem = withConfigModeHOC<SidebarItemProps, {}>({
    Es: SidebarItem,
});

export function useMinimizeSidebar() {
    const location = useLocation();
    const [minimize, setMinimize] = useState(false);

    useEffect(() => {
        if (minimizeSidebarPaths.includes(location.pathname)) {
            setMinimize(true);
        } else {
            setMinimize(false);
        }
    }, [location]);

    return { minimize, setMinimize };
}

export function SidebarItem({ icon, link, text, onClick, isNewFeature }: SidebarItemProps) {
    const { minimize } = useMinimizeSidebar();
    const [isintegrationPageViewed, setIsintegrationPageViewed] = useLocalStorageState("isintegrationPageViewed");

    const newProps: SvgIconProps = {
        ...defaultIconProps,
        ...(icon?.props || {}),
    };

    const clonedIcon = React.cloneElement(icon, newProps);

    const handleOnClick = () => {
        if (isNewFeature) {
            setIsintegrationPageViewed(true);
        }
        onClick && onClick();
    };

    return (
        <ListItem>
            <Tooltip title={minimize ? text : undefined} arrow>
                <ListItemButton className={style["sidebar__sidebarItem__listItemHover"]}>
                    <NavLink
                        to={link}
                        style={{
                            textDecoration: "none",
                            alignSelf: minimize ? "center" : "flex-start",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                        }}
                        onClick={handleOnClick}
                    >
                        {({ isActive }) => {
                            const classNames = isActive
                                ? `${style["sidebar__sidebarItem"]} ${style["sidebar__sidebarItem--active"]} ${minimize ? `${style["sidebar__sidebarItem--minimize"]}` : ""
                                }`
                                : `${style["sidebar__sidebarItem"]} ${minimize ? `${style["sidebar__sidebarItem--minimize"]}` : ""
                                }`;
                            return (
                                <Stack
                                    direction="row"
                                    className={classNames}
                                    alignItems="center"
                                    justifyContent={minimize ? "center" : "flex-start"}
                                    gap={2.5}
                                >
                                    <ListItemDecorator
                                        sx={{
                                            minWidth: "fit-content",
                                            "&:hover": {
                                                backgroundColor: "primary.light",
                                                color: "primary.main",
                                            },
                                        }}
                                    >
                                        {clonedIcon}
                                    </ListItemDecorator>
                                    {!minimize && (
                                        <ListItemContent>
                                            <Typography
                                                level="body-md"
                                                sx={{ color: "inherit" }}
                                                className={style["sidebar__sidebarItem__listItemHover--text"]}
                                            >
                                                {text}
                                            </Typography>
                                        </ListItemContent>
                                    )}
                                    {isNewFeature && !minimize && !isintegrationPageViewed && (
                                        <div className={style["sidebar__sidebarItem__new-feature"]}>New</div>
                                    )}
                                </Stack>
                            );
                        }}
                    </NavLink>
                </ListItemButton>
            </Tooltip>
        </ListItem>
    );
}
