import { FormHelperText, FormHelperTextProps, styled } from "@mui/material";

export const CustomFormHelperText = styled((props: FormHelperTextProps) => {
    return (
        <FormHelperText
            {...props}
            sx={{
                color: "error.main",
                marginX: 0,
                ...props.sx,
            }}
        />
    );
})({});
