import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { uniq, uniqBy } from "lodash";

import {
    ExtractEmails,
    ExtractPhone,
    IAddCandidateFeedbackPayload,
    IAddToWorkflowPayload,
    IBulkUploadCandCVsPayload,
    ICandidate,
    ICandidateEmailData,
    ICandidateEmailPayload,
    ICopyToProjectPayload,
    IEditCandidatePayload,
    IEmailStats,
    IGetProjectWorkflows,
    ILinkedInStats,
    ILinkedinUrlUploadPayload,
    IMarketResearchPayload,
    IMaxExpPayload,
    IMinExpPayload,
    IMostRecentPayload,
    IMoveToPayload,
    IPersonalEmailPayload,
    IProjectFiltersPayload,
    IProjectStats,
    IProjectWorkflows,
    IRefreshCandidateRatingPayload,
    IRelevantCandPayload,
    IRootState,
    ISSEEventPayload,
    ISelectedCandidateId,
    ISelectedCandidatePayload,
    IStageFilters,
    TWorkflowStatus,
    TisActionableClicked,
    candidateWrokflowType,
    getCandidateWorkflowsType,
} from "./project.types";

import { CV_SOURCE_OPTIONS } from "../../constant/Constant";
import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import { optionType } from "../AdvancedFilters/Components/Filters/Filters.type";
import { DetailedStageOptionKey } from "../all-candidates-reachout/all-candidates-reachout.types";
import { IGetProjectPayload } from "../allProjects/allProjects.types";

export const initialFilters = {
    TO_BE_REVIEWED: false,
    NO_RATING: false,
    PIPELINE: false,
    CONTACTED: false,
    RESPONDED: false,
    POSITIVE_RESPONSE: false,
    FROM_ATS: false,
    SENT_TO_ATS: false,
    NOT_SENT_TO_ATS: false,
    feedback: false,
    "SHORTLISTED++": false,
    SHORTLISTED: false,
    REJECTED: false,
    NOT_INTERESTED: false,
    ON_HOLD: false,
    COMPLETED: false,
    INCOMPLETE: false,
    CV_AVAILABLE: false,
    EXCLUDED: false,
    NOT_EXCLUDED: false,
    personalEmail: false,
    professionalEmail: false,
    bothPersonalProfessional: false,
    hasPhoneNumber: false,
    noPhoneNumber: false,
    noEmails: false,
    5: false,
    4: false,
    3: false,
    2: false,
    1: false,
    0: false,
    "-1": false,
    "outreachConnectionStatus.NOT_SENT,FAILED": false,
    "outreachConnectionStatus.TRIGGERED": false,
    "outreachConnectionStatus.SENT_WITH_NOTE,SENT_WITHOUT_NOTE": false,
    "outreachConnectionStatus.ACCEPTED_WITH_NOTE,ACCEPTED_WITHOUT_NOTE": false,
    "outreachConnectionStatus.MESSAGE_SENT": false,
    "outreachConnectionStatus.RESPONDED": false,
    "outreachInMailStatus.NOT_SENT,FAILED": false,
    "outreachInMailStatus.TRIGGERED": false,
    "outreachInMailStatus.SENT": false,
    "outreachInMailStatus.OPENED": false,
    "outreachEmailStatus.NOT_SENT,FAILED": false,
    "outreachEmailStatus.BOUNCED,NOT_FOUND": false,
    "outreachEmailStatus.TRIGGERED": false,
    "outreachEmailStatus.SENT": false,
    "outreachEmailStatus.OPENED": false,
    "outreachEmailStatus.RESPONDED": false,
    "outreachFollowupEmailStatus.RESPONDED": false,
    "outreachSMSStatus.NOT_SENT": false,
    "outreachSMSStatus.OPTED_OUT": false,
    "outreachSMSStatus.NOT_FOUND": false,
    "outreachSMSStatus.UNDELIVERED,FAILED": false,
    "outreachSMSStatus.QUEUED,ACCEPTED": false,
    "outreachSMSStatus.SENT,DELIVERED": false,
    "outreachSMSStatus.DELIVERED": false,
    "outreachSMSStatus.RECEIVED": false,
    "outreachFollowupEmailStatus.NOT_SENT": false,
    "outreachFollowupEmailStatus.TRIGGERED": false,
    "outreachFollowupEmailStatus.FAILED,BOUNCED,NOT_FOUND": false,
    "outreachFollowupEmailStatus.SENT": false,
    "outreachFollowupEmailStatus.OPENED": false,
    "csReviewStage.ACCEPTED": false,
    "csReviewStage.REJECTED": false,
    "csReviewStage.PENDING": false,
    "source.AP": false,
    "source.BS": false,
    "OPEN_TO_WORK": false,
    "externalSource.WELCOME_PAGE": false,
    ...CV_SOURCE_OPTIONS.reduce((acc: any, option) => {
        acc[`cvSource.${option.label}`] = false;
        return acc;
    }, {}),
};

const initialState: IRootState = {
    isErrorWhileFetchingStats: false,
    isErrorWhileFetchingCandidates: false,
    isErrorWhileFetchingAssociatedReachouts: false,
    marketResearchDataFetchStatus: "",
    marketResearchDataFetchPercentage: 100,
    scrollToSkills: false,
    projectStats: null,
    projectWorkflows: [],
    workflowCount: 0,
    showByRelevance: true,
    showByMaxExperience: false,
    showByMinExperience: false,
    showByPersonalEmails: false,
    showByMostRecent: false,
    projectFilters: initialFilters,
    candidates: [],
    projectCandidates: {},
    totalCandidates: 0,
    totalSearchCount: 0,
    selectedCandidates: [],
    candidateCurrentPage: 1,
    selectAllCandidates: false,
    emailStats: null,
    linkedInStats: null,
    pageSize: 25,
    showSrapeCandidateModal: false,
    liCookie: "",
    relevenceToggle: false,
    searchQuery: "",
    sendosoGifts: null,
    showCandidateSearch: false,
    sentGiftsCandidates: [],
    setAllProductsLoaded: false,
    candidateWorkflows: [],
    gridViewColumns: [],
    requestId: "",
    singleCandidateInsightGenerating: null,
    emailEnrich: false,
    phoneEnrich: false,
    linkedInEnrich: false,
    marketResearchData: [],
    twilloToken: "",
    twilloCalls: [],
    blockList: [],
    candidateDetails: {},
    twilloPowerCallListCreate: null,
    twilloPowerCallCandidateList: null,
    updateBulkCallCampaignStatus: null,
    isActionableClicked: { 4: false, 5: false, PIPELINE: false, TO_BE_REVIEWED: false },
};

const reducers = {
    setSingleCandidateInsightGenerating(
        state: typeof initialState,
        action: PayloadAction<{ candidateId: string | null }>
    ) {
        const { candidateId } = action.payload;
        state.singleCandidateInsightGenerating = candidateId;
    },
    setCandidateDetailedProjectStatus(
        state: typeof initialState,
        action: PayloadAction<{ candidateId: string; detailedStage: DetailedStageOptionKey }>
    ) {
        const { candidateId, detailedStage } = action.payload;
        const index = state.candidates.findIndex(({ _id }) => _id === candidateId);
        if (index >= 0) {
            state.candidates[index].detailedStage = detailedStage;

            /* 
            This extra state for projects is used to store the candidates for each page,
            the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
            */

            const projectId = state.candidates[0]?.projectId;

            if (projectId) {
                if (state.projectCandidates[projectId]?.[index]) {
                    state.projectCandidates[projectId][index].detailedStage = detailedStage;
                }
            }
        }
    },
    setStatsFetchErrorStatus(state: typeof initialState, action: PayloadAction<boolean>) {
        state.isErrorWhileFetchingStats = action.payload;
    },
    setCandidatesFetchErrorStatus(state: typeof initialState, action: PayloadAction<boolean>) {
        state.isErrorWhileFetchingCandidates = action.payload;
    },
    setAssociatedReachoutsFetchErrorStatus(state: typeof initialState, action: PayloadAction<boolean>) {
        state.isErrorWhileFetchingAssociatedReachouts = action.payload;
    },
    setScrollToSkills(state: typeof initialState, action: PayloadAction<boolean>) {
        state.scrollToSkills = action.payload;
    },
    setSearchQuery(state: typeof initialState, action: PayloadAction<string>) {
        state.searchQuery = action.payload;
    },
    setFilters(state: typeof initialState, { payload }: { payload: IStageFilters }) {
        state.projectFilters = payload;
    },
    resetFilters(state: typeof initialState) {
        state.projectFilters = initialFilters;
    },
    copyToProject(state: typeof initialState, { payload }: { payload: ICopyToProjectPayload }) { },
    moveTo(state: typeof initialState, { payload }: { payload: IMoveToPayload }) { },
    addToWorkflow(state: typeof initialState, { payload }: { payload: IAddToWorkflowPayload }) { },
    getCandidateEmail(state: typeof initialState, { payload }: { payload: IActionPayload & ICandidateEmailPayload }) { },
    getProjectStats(
        state: typeof initialState,
        { payload }: { payload: { projectId?: string | number } & IActionPayload }
    ) { },
    getTwilloPowerCallListCreate(state: typeof initialState, { payload }: { payload: any }) { },

    setTwilloPowerCallListCreate(state: typeof initialState, { payload }: { payload: any }) {
        state.twilloPowerCallListCreate = payload;
    },
    getListOfPowerDialerCampaigns(state: typeof initialState, { payload }: { payload: any }) { },

    getTwilloPowerCallCandidateList(state: typeof initialState, { payload }: { payload: any }) { },
    setTwilloPowerCallCandidateList(state: typeof initialState, { payload }: { payload: any }) {
        state.twilloPowerCallCandidateList = payload;
    },

    setProjectStats(state: typeof initialState, { payload }: { payload: IProjectStats }) {
        state.projectStats = {
            ...payload,
            contacted: payload.contacted,
        };
    },
    getProjectWorkflows(state: typeof initialState, { payload }: { payload: IGetProjectWorkflows & IActionPayload }) { },
    setProjectWorkflows(state: typeof initialState, { payload }: { payload: Array<IProjectWorkflows> }) {
        state.projectWorkflows = payload;
    },
    getShowByRelevance(state: typeof initialState, { payload }: { payload: IRelevantCandPayload & IActionPayload }) {
        state.showByRelevance = payload.showByRelevance;
        state.showByMinExperience = false;
        state.showByMaxExperience = false;
        state.showByMostRecent = false;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    getShowByMinExperience(state: typeof initialState, { payload }: { payload: IMinExpPayload & IActionPayload }) {
        state.showByMinExperience = payload.showByMinExperience;
        state.showByRelevance = false;
        state.showByMaxExperience = false;
        state.showByMostRecent = false;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    getShowByMaxExperience(state: typeof initialState, { payload }: { payload: IMaxExpPayload & IActionPayload }) {
        state.showByMaxExperience = payload.showByMaxExperience;
        state.showByRelevance = false;
        state.showByMinExperience = false;
        state.showByMostRecent = false;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    getShowByRecent(state: typeof initialState, { payload }: { payload: IMostRecentPayload & IActionPayload }) {
        state.showByMostRecent = payload.showByMostRecent;
        state.showByMinExperience = false;
        state.showByMaxExperience = false;
        state.showByRelevance = false;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    getShowByPersonalEmails(
        state: typeof initialState,
        { payload }: { payload: IPersonalEmailPayload & IActionPayload }
    ) {
        state.showByPersonalEmails = payload.showByPersonalEmails;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
    },
    setShowByPersonalEmails(state: typeof initialState, { payload }: { payload: boolean }) {
        state.showByPersonalEmails = payload;
    },
    getShowByProjectFilters(
        state: typeof initialState,
        { payload }: { payload: IProjectFiltersPayload & IActionPayload }
    ) {
        state.projectFilters = payload.projectFilters;
        state.candidateCurrentPage = 1;
        state.selectedCandidates = [];
        if (payload.onSuccess) {
            payload.onSuccess();
        }
    },
    setSendosoGifts(state: typeof initialState, { payload }: { payload: any }) {
        state.sendosoGifts = payload;
    },
    setAllSendosoProductsLoaded(state: typeof initialState, { payload }: { payload: boolean }) {
        state.setAllProductsLoaded = payload;
    },

    setTwillioToken(state: typeof initialState, { payload }: { payload: string }) {
        state.twilloToken = payload;
    },

    setCandidate(state: typeof initialState, { payload }: { payload: ICandidate }) {
        const index = state.candidates.findIndex(({ _id }) => _id === payload._id);
        if (index >= 0) {
            state.candidates[index].name = payload.name;
            state.candidates[index].profileUrl = payload.profileUrl;
            state.candidates[index].resume = payload.resume;
            state.candidates[index].email = payload.email;
            state.candidates[index].professional_email = payload.professional_email;
            state.candidates[index].primaryEmail = payload.primaryEmail;
            state.candidates[index].starRating = payload.starRating;
            state.candidates[index].phone = payload.phone;

            /* 
            This extra state for projects is used to store the candidates for each page,
            the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
            */

            const projectId = state.candidates[0]?.projectId;

            if (projectId) {
                // Overwrite the candidates for the current page for the given projectId
                if (state.projectCandidates[projectId]?.[index]) {
                    state.projectCandidates[projectId][index].name = payload.name;
                    state.projectCandidates[projectId][index].profileUrl = payload.profileUrl;
                    state.projectCandidates[projectId][index].resume = payload.resume;
                    state.projectCandidates[projectId][index].email = payload.email;
                    state.projectCandidates[projectId][index].professional_email = payload.professional_email;
                    state.projectCandidates[projectId][index].primaryEmail = payload.primaryEmail;
                    state.projectCandidates[projectId][index].starRating = payload.starRating;
                    state.projectCandidates[projectId][index].phone = payload.phone;
                }
            }
        }
    },
    setCandidates(state: typeof initialState, { payload }: { payload: ICandidate[] }) {
        if (state.selectAllCandidates) {
            state.selectedCandidates = payload.map((i) => ({
                candidateId: i._id,
                pageNo: state.candidateCurrentPage,
            }));
        }
        // Set the current page's candidates
        state.candidates = payload;

        /* 
        This extra state for projects is used to store the candidates for each page,
        the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
        */

        if (payload.length === 0) {
            // If no candidates are returned, reset the projectCandidates
            state.projectCandidates = {};
        } else {
            // assuming that the first candidate's projectId is the same for all candidates
            const projectId = payload[0]?.projectId;
            if (projectId) {
                // Overwrite the candidates for the current page for the given projectId
                state.projectCandidates[projectId] = payload;
            }
        }
    },

    setRequestId(state: typeof initialState, { payload }: { payload?: string }) {
        state.requestId = payload ?? "";
    },
    resetRequestId(state: typeof initialState, _action: PayloadAction) {
        state.requestId = "";
    },
    addSSECandidateDetails(state: typeof initialState, { payload }: { payload: ISSEEventPayload }) {
        const candidates = [...state.candidates];
        const existingCandidateIndex = candidates.findIndex((candidate) => candidate._id === payload._id);
        const newCandidate = {
            ...payload,
            stage: "PIPELINE",
            canShowEmail: true,
            email: ["Fetching email..."],
        };
        if (existingCandidateIndex === -1) {
            candidates.splice(0, 0, newCandidate);
        } else {
            candidates[existingCandidateIndex] = newCandidate;
        }
        state.candidates = candidates;

        /* 
        This extra state for projects is used to store the candidates for each page,
        the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
        */
        const projectId = state.candidates[0]?.projectId;

        if (projectId) {
            state.projectCandidates[projectId] = candidates;
        }
    },
    getProjectCandidates(state: typeof initialState, { payload }: { payload: IGetProjectPayload & IActionPayload }) {
        // if (state.selectAllCandidates) {
        //     state.selectedCandidates = [];
        //     state.selectAllCandidates = false;
        // }
    },
    setSelectedCandidates(state: typeof initialState, { payload }: { payload: ISelectedCandidatePayload }) {
        if (payload.deselectAll) {
            const candidatesMap = new Map();
            for (const item of state.candidates) {
                candidatesMap.set(item._id, item._id);
            }
            const selectedCandidates = state.selectedCandidates.filter(
                ({ candidateId }) => !candidatesMap.has(candidateId)
            );
            state.selectedCandidates = selectedCandidates;
            return;
        }

        const candidateWithPageNos = payload.candidateId.map((item) => {
            return {
                candidateId: item,
                pageNo: payload.pageNo || 1,
            };
        });

        const uniqueSelectedCandidates = uniqBy([...state.selectedCandidates, ...candidateWithPageNos], "candidateId");
        state.selectedCandidates = uniqueSelectedCandidates;
    },
    removeSelectedCandidate(state: typeof initialState, { payload }: { payload: string }) {
        if (state.selectAllCandidates) state.selectAllCandidates = false;
        state.selectedCandidates = state.selectedCandidates.filter(
            (item: ISelectedCandidateId) => item.candidateId !== payload
        );
    },
    setCandidatePage(state: typeof initialState, { payload }: { payload: number }) {
        state.candidateCurrentPage = payload;
    },
    setSelectAllCandidates(state: typeof initialState, { payload }: { payload: boolean }) {
        if (!payload) {
            state.selectedCandidates = [];
        }
        state.selectAllCandidates = payload;
    },
    setTotalCandidates(state: typeof initialState, { payload }: { payload: number }) {
        state.totalCandidates = payload;
    },
    setTotalSearchCount(state: typeof initialState, { payload }: { payload: number }) {
        state.totalSearchCount = payload;
    },
    setSalesNavProfilesCount(state: typeof initialState, { payload }: { payload: number }) {
        state.salesNavProfilesCount = payload;
    },
    getWorkflowEmailStats(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: IActionPayload & { workflowId: string; projectId: string };
        }
    ) { },
    setWorkflowEmailStats(state: typeof initialState, { payload }: { payload: IEmailStats }) {
        state.emailStats = payload;
    },
    getWorkflowLinkedInStats(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: IActionPayload & { workflowId: string; projectId: string };
        }
    ) { },
    getTwilloCallsOutgoing(state: typeof initialState, { payload }: { payload: any }) { },

    getDeletePowerDialerCandidates(
        state: typeof initialState,
        { payload }: { payload: { campaignId: string; candidateIdsToRemove: string[] } }
    ) { },

    getTwilloCallLogs(state: typeof initialState, { payload }: { payload: any }) { },
    getCandidateDetails(state: typeof initialState, { payload }: { payload: any }) { },
    setCandidateDetails(state: typeof initialState, { payload }: { payload: any }) {
        state.candidateDetails = payload;
    },
    getAddtoBlocklist(state: typeof initialState, { payload }: { payload: any }) { },
    getRemoveFromBlocklist(state: typeof initialState, { payload }: { payload: any }) { },
    getBlacklist(state: typeof initialState, { payload }: { payload: any }) { },
    setBlacklist(state: typeof initialState, { payload }: { payload: any }) {
        state.blockList = payload;
    },
    setTwilloCallLogs(state: typeof initialState, { payload }: { payload: any }) {
        state.twilloCalls = payload;
    },
    getSendosoGifts(state: typeof initialState, { payload }: PayloadAction<any>) { },

    getSendosoGiftsSend(state: typeof initialState, { payload = {} }: PayloadAction<any>) { },

    setWorkflowLinkedInStats(state: typeof initialState, { payload }: { payload: ILinkedInStats }) {
        state.linkedInStats = payload;
    },
    setCandidateEmails(state: typeof initialState, { payload }: { payload: ICandidateEmailData }) {
        const EMAIL_KEY = payload.type === "SUPERADMIN" ? "email" : "personal_email";
        const tmpCandidates = state.candidates.map((item) => {
            const showCandidate = payload.showEmail.find(({ _id }) => item._id === _id);
            if (showCandidate) {
                const professionalEmails = item.professional_email || [];
                const showCandidateProfessionalEmails = showCandidate?.professional_email || [];

                return {
                    ...item,
                    canShowEmail: true,
                    email: showCandidate[EMAIL_KEY],
                    professional_email: uniq([...professionalEmails, ...showCandidateProfessionalEmails]),
                };
            }

            return item;
        });
        state.candidates = tmpCandidates;

        /* 
        This extra state for projects is used to store the candidates for each page,
        the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
        */

        const projectId = state.candidates[0]?.projectId;

        if (projectId) {
            state.projectCandidates[projectId] = tmpCandidates;
        }
    },
    setCandidatePhoneNumbers(state: typeof initialState, { payload }: { payload: ICandidate[] }) {
        const tmpCandidates = state.candidates.map((item) => {
            const updatedCandidate = payload.find(({ _id }) => item._id === _id);
            if (updatedCandidate) {
                return {
                    ...item,
                    phone: updatedCandidate.phone,
                };
            }
            return item;
        });
        state.candidates = tmpCandidates;

        /* 
        This extra state for projects is used to store the candidates for each page,
        the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
        */

        const projectId = state.candidates[0]?.projectId;

        if (projectId) {
            state.projectCandidates[projectId] = tmpCandidates;
        }
    },
    setSentGiftsCandidates(state: typeof initialState, { payload }: { payload: number }) {
        state.sentGiftsCandidates = [...state.sentGiftsCandidates, payload];
    },

    setCandidateResumeInfo(state: typeof initialState, { payload }: { payload: ICandidate[] }) {
        const tmpCandidates = state.candidates.map((item) => {
            const updatedCandidate: any = payload.find(({ _id }) => item._id === _id);
            if (updatedCandidate) {
                return {
                    ...item,
                    email: Array.from(new Set([...item.email, updatedCandidate.email.primary])),
                    name: updatedCandidate.name,
                    skills: updatedCandidate.skills,
                    phone: updatedCandidate.phone,
                    primaryEmail: updatedCandidate.email.primary,
                    resume: updatedCandidate?.resume,
                };
            }
            return item;
        });
        state.candidates = tmpCandidates;

        /* 
        This extra state for projects is used to store the candidates for each page,
        the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
        */

        const projectId = state.candidates[0]?.projectId;

        if (projectId) {
            state.projectCandidates[projectId] = tmpCandidates;
        }
    },
    setWorkflow(state: typeof initialState, { payload }: { payload: { workflowId: string; status: TWorkflowStatus } }) {
        const { workflowId, status } = payload;
        const tmpWorkflows = state.projectWorkflows.map((workflow) => {
            if (workflow._id === workflowId) {
                return {
                    ...workflow,
                    status,
                };
            }
            return workflow;
        });
        state.projectWorkflows = tmpWorkflows;
    },
    setWorkflowCount(state: typeof initialState, { payload }: { payload: number }) {
        state.workflowCount = payload;
    },
    bulkUploadCandidatesCVs(
        state: typeof initialState,
        { payload }: { payload: IBulkUploadCandCVsPayload & IActionPayload }
    ) { },
    linkedinUrlUpload(
        state: typeof initialState,
        { payload }: { payload: ILinkedinUrlUploadPayload & IActionPayload }
    ) { },
    refreshCandidateRating(
        state: typeof initialState,
        { payload }: { payload: IRefreshCandidateRatingPayload & IActionPayload }
    ) { },

    setProjectCandidate(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: ICandidate[];
        }
    ) {
        const candidateIds = payload.map(({ _id }) => _id);
        const tmpCandidates = state.candidates.map((item) => {
            if (candidateIds.includes(item._id)) {
                const updatedCandidate = payload.find(({ _id }) => _id === item._id);
                return updatedCandidate || item;
            }
            return item;
        });
        state.candidates = tmpCandidates;

        /* 
        This extra state for projects is used to store the candidates for each page,
        the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
        */

        const projectId = state.candidates[0]?.projectId;

        if (projectId) {
            state.projectCandidates[projectId] = tmpCandidates;
        }
    },
    setPageSize(state: typeof initialState, { payload }: { payload: number }) {
        state.pageSize = payload;
    },
    leaveProject(state: typeof initialState) { },
    changeCandidatePrimaryEmail(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                candidateId: string;
                email: string[];
                primaryEmail: string;
            };
        }
    ) { },
    setScrapeCandidateModal(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: boolean;
        }
    ) {
        state.showSrapeCandidateModal = payload;
    },
    scrapeCandidates(state: typeof initialState) { },
    marketResearch(state: typeof initialState, action: PayloadAction<IMarketResearchPayload>) { },
    marketResearchFetchData(state: typeof initialState, action: PayloadAction<any>) { },
    marketResearchStop(state: typeof initialState, action: PayloadAction<any>) { },
    setLiCookie(state: typeof initialState, { payload }: { payload: string }) {
        state.liCookie = payload;
    },
    getTwilloCall(state: typeof initialState, { payload }: { payload: any; onSuccess: Function }) { },
    editCandidate(state: typeof initialState, action: PayloadAction<IEditCandidatePayload>) { },
    addCandidateFeedback(state: typeof initialState, action: PayloadAction<IAddCandidateFeedbackPayload>) { },
    resetProject(state: typeof initialState) {
        state.pageSize = 25;
        state.candidateCurrentPage = 1;
        state.projectFilters = initialFilters;
    },
    setRelevenceToggle(
        state: typeof initialState,
        { payload }: { payload: { isChecked: boolean; experience: string[] } }
    ) {
        state.relevenceToggle = payload.isChecked;
        if (!payload.isChecked) return;

        const candidates = JSON.parse(JSON.stringify(state.candidates));
        const { experience } = payload;
        let expRange = [0, 100];
        if (experience?.length) {
            let min = 100;
            let max = 0;
            experience?.forEach((range: any) => {
                let [start, end] = range.split("-").map(Number);
                if (end === undefined) {
                    end = 100;
                }
                min = Math.min(min, start);
                max = Math.max(max, end);
            });
            expRange = [min, max];
        }
        const candidatesList = candidates.filter((item: ICandidate) => {
            const minValue = item?.totalExperience?.value && Math.ceil(item?.totalExperience?.value);
            const maxValue = item?.totalExperience?.value && Math.floor(item?.totalExperience?.value);
            return minValue >= expRange[0] && maxValue <= expRange[1];
        });
        state.candidates = candidatesList;

        /* 
        This extra state for projects is used to store the candidates for each page,
        the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
        */

        const projectId = state.candidates[0]?.projectId;

        if (projectId) {
            state.projectCandidates[projectId] = candidatesList;
        }
    },
    getWorkflowsLogsById(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                workflowLogId: string;
                workflowId: string;
                workflowLogIds: string[];
                type: string;
                callBack?: Function;
            };
        }
    ) { },
    searchCandidates(state: typeof initialState, { payload }: { payload: IGetProjectPayload & IActionPayload }) { },
    getCandidateWorkflows(state: typeof initialState, { payload }: { payload: getCandidateWorkflowsType }) {
        state.candidateWorkflows = [];
    },
    getLinkedinApplicantCandidates(state: typeof initialState, { payload }: { payload: any }) { },

    setCandidateWorkflows(state: typeof initialState, { payload }: { payload: candidateWrokflowType[] }) {
        state.candidateWorkflows = payload;
    },
    setShowCandidateSearch(state: typeof initialState, action: PayloadAction<boolean>) {
        state.showCandidateSearch = action.payload;
    },
    changeStarRating(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                value: number;
                label: string;
                description?: {
                    text: string;
                    intent: number;
                    criterion: string;
                }[];
                candidateId: string;
                enableFixedVetting: boolean;
            };
        }
    ) { },
    setGridViewColumns(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                checked: boolean;
                item: optionType;
            };
        }
    ) {
        const columns = JSON.parse(JSON.stringify(state.gridViewColumns));
        const { item, checked } = payload;
        const index = columns.findIndex((col: optionType) => item.value === col.value);
        if (checked) {
            index < 0 && columns.push(item);
        } else {
            columns.splice(index, 1);
        }
        state.gridViewColumns = columns;
    },
    getSuperAdminCandEmails(_state: typeof initialState, _action: PayloadAction<ExtractEmails>) { },
    getSuperAdminCandPhoneNumbers(_state: typeof initialState, _action: PayloadAction<ExtractPhone>) { },
    unlockCandidatesResume(_state: typeof initialState, _action: PayloadAction) { },
    reExtractCandidateCV(_state: typeof initialState, _action: PayloadAction) { },
    findLinkedinProfiles(_state: typeof initialState, _action: PayloadAction) { },
    excludeCandidates(_state: typeof initialState, _action: PayloadAction) { },
    setEmailEnrich(state: any, action: PayloadAction<{ emailEnrich: boolean }>) {
        const { emailEnrich } = action.payload;
        state.emailEnrich = emailEnrich;
    },
    setPhoneEnrich(state: any, action: PayloadAction<{ phoneEnrich: boolean }>) {
        const { phoneEnrich } = action.payload;
        state.phoneEnrich = phoneEnrich;
    },
    setLinkedinEnrich(state: any, action: PayloadAction<{ linkedInEnrich: boolean }>) {
        const { linkedInEnrich } = action.payload;
        state.linkedInEnrich = linkedInEnrich;
    },
    setCandidateStarRating(state: typeof initialState, action: PayloadAction<{ candidateId: string; data: any }>) {
        const { candidateId, data } = action.payload;
        const index = state.candidates.findIndex(({ _id }) => _id === candidateId);
        if (index >= 0) {
            state.candidates[index].starRating = data;

            /* 
            This extra state for projects is used to store the candidates for each page,
            the reason is redux-perstist which is used to store the state in local storage and persists across sessions.
            */

            const projectId = state.candidates[0]?.projectId;

            if (projectId) {
                if (state.projectCandidates[projectId]?.[index]) {
                    state.projectCandidates[projectId][index].starRating = data;
                }
            }
        }
    },
    setMarketResearchData(state: typeof initialState, { payload }: { payload: any }) {
        state.marketResearchData = payload;
    },
    setMarketResearchDataFetchStatus(state: typeof initialState, { payload }: { payload: any }) {
        state.marketResearchDataFetchStatus = payload;
    },
    setMarketResearchDataFetchPercentage(state: typeof initialState, { payload }: { payload: any }) {
        state.marketResearchDataFetchPercentage = payload;
    },
    updateBulkCallCampaign(_state: typeof initialState, { payload }: { payload: any }) { },
    setUpdateBulkCallCampaignStatus(state: typeof initialState, { payload }: { payload: any }) {
        state.updateBulkCallCampaignStatus = payload;
    },
    setIsActionableClicked(state: typeof initialState, { payload }: { payload: TisActionableClicked }) {
        state.isActionableClicked = payload;
    },
    updateProjectCandidatesCSReviewStage(_state: typeof initialState, _action: PayloadAction<string>) { },
};

export const projectPage = createSlice({
    name: "project",
    initialState,
    reducers,
});

export default projectPage.reducer;
/*eslint-disable */
export const {
    setCandidateDetailedProjectStatus,
    setStatsFetchErrorStatus,
    setCandidatesFetchErrorStatus,
    setAssociatedReachoutsFetchErrorStatus,
    setMarketResearchDataFetchStatus,
    setMarketResearchDataFetchPercentage,
    setScrollToSkills,
    changeCandidatePrimaryEmail,
    copyToProject,
    moveTo,
    addToWorkflow,
    getProjectStats,
    setProjectStats,
    leaveProject,
    getTwilloCall,
    getTwilloCallLogs,
    getTwilloPowerCallListCreate,
    setTwilloPowerCallListCreate,
    setTwillioToken,
    getCandidateEmail,
    getProjectWorkflows,

    setWorkflowCount,
    setProjectWorkflows,
    setCandidate,
    setCandidates,
    getShowByRelevance,
    getShowByMinExperience,
    getShowByMaxExperience,
    getShowByRecent,
    getShowByProjectFilters,
    setSendosoGifts,
    getSendosoGiftsSend,
    getSendosoGifts,
    getDeletePowerDialerCandidates,
    addSSECandidateDetails,
    getProjectCandidates,
    getShowByPersonalEmails,
    setShowByPersonalEmails,
    getWorkflowEmailStats,
    getWorkflowLinkedInStats,
    setSelectedCandidates,
    removeSelectedCandidate,
    setCandidatePage,
    setSelectAllCandidates,
    setTotalCandidates,
    setWorkflowEmailStats,
    setWorkflowLinkedInStats,
    setCandidateEmails,
    setCandidatePhoneNumbers,
    setWorkflow,
    bulkUploadCandidatesCVs,
    linkedinUrlUpload,
    refreshCandidateRating,
    setProjectCandidate,
    setPageSize,
    setScrapeCandidateModal,
    scrapeCandidates,
    setLiCookie,
    editCandidate,
    addCandidateFeedback,
    marketResearch,
    marketResearchFetchData,
    marketResearchStop,
    resetProject,
    setRelevenceToggle,
    setSentGiftsCandidates,
    resetFilters,
    getWorkflowsLogsById,
    searchCandidates,
    setTwilloCallLogs,
    setSearchQuery,
    getCandidateWorkflows,
    setCandidateWorkflows,
    setShowCandidateSearch,
    changeStarRating,
    setGridViewColumns,
    getSuperAdminCandEmails,
    getSuperAdminCandPhoneNumbers,
    setAllSendosoProductsLoaded,
    unlockCandidatesResume,
    reExtractCandidateCV,
    findLinkedinProfiles,
    setCandidateResumeInfo,
    getTwilloCallsOutgoing,
    setRequestId,
    resetRequestId,
    setFilters,
    setSingleCandidateInsightGenerating,
    excludeCandidates,
    setTotalSearchCount,
    setSalesNavProfilesCount,
    setEmailEnrich,
    setPhoneEnrich,
    setLinkedinEnrich,
    setCandidateStarRating,
    setMarketResearchData,
    getListOfPowerDialerCampaigns,
    setTwilloPowerCallCandidateList,
    getTwilloPowerCallCandidateList,

    getAddtoBlocklist,
    getRemoveFromBlocklist,
    getBlacklist,
    getLinkedinApplicantCandidates,
    getCandidateDetails,
    setCandidateDetails,
    setBlacklist,
    updateBulkCallCampaign,
    setUpdateBulkCallCampaignStatus,
    setIsActionableClicked,
    updateProjectCandidatesCSReviewStage,
} = projectPage.actions;

/*eslint-enable */

export const selectIsActionableClicked = (state: RootState) => state.project.isActionableClicked;
export const selectScrollToSkills = (state: RootState) => state.project.scrollToSkills;
export const selectProjectCandidates = (state: RootState) => state.project.candidates;
export const selectCurrentProjectCandidates = (projectId: number | string) => (state: RootState) =>
    state.project.projectCandidates[projectId] ?? [];
export const selectCandidateCurrentPage = (state: RootState) => state.project.candidateCurrentPage;
export const selectProjectStats = (state: RootState) => state.project.projectStats;
export const selectProjectWorkflows = (state: RootState) => state.project.projectWorkflows;
export const selectWorkflowCount = (state: RootState) => state.project.workflowCount;
export const selectWorkflowEmailStats = (state: RootState) => state.project.emailStats;
export const selectWorkflowLinkedInStats = (state: RootState) => state.project.linkedInStats;
export const selectSelectedCandidates = (state: RootState) => state.project.selectedCandidates;
export const selectProjectTotalCandidates = (state: RootState) => state.project.totalCandidates;
export const selectProjectAllCandidates = (state: RootState) => state.project.selectAllCandidates;
export const selectShowByRelevance = (state: RootState) => state.project.showByRelevance;
export const selectShowByPersonalEmails = (state: RootState) => state.project.showByPersonalEmails;
export const selectShowByMostRecent = (state: RootState) => state.project.showByMostRecent;
export const selectShowByMinExperience = (state: RootState) => state.project.showByMinExperience;
export const selectShowByMaxExperience = (state: RootState) => state.project.showByMaxExperience;
export const selectProjectFilters = (state: RootState) => state.project.projectFilters;
export const selectPageSize = (state: RootState) => state.project.pageSize;
export const selectShowSrapeCandidateModal = (state: RootState) => state.project.showSrapeCandidateModal;
export const selectRelevenceToggle = (state: RootState) => state.project.relevenceToggle;
export const selectSearchQuery = (state: RootState) => state.project.searchQuery;
export const selectShowCandidateSearch = (state: RootState) => state.project.showCandidateSearch;
export const sentGiftsCandidatesData = (state: RootState) => state.project.sentGiftsCandidates;
export const selectSalesNavProfilesCount = (state: RootState) => state.project.salesNavProfilesCount;
export const isAllProductsLoaded = (state: RootState) => state.project.setAllProductsLoaded;
export const selectCandidateDetails = (state: RootState) => state.project.candidateDetails;
export const selectTwilloPowerCallListCreate = (state: RootState) => state.project.twilloPowerCallListCreate;
export const selectTwilloPowerCallCandidateList = (state: RootState) => state.project.twilloPowerCallCandidateList;
export const selectSingleCandidateInsightGenerating = (state: RootState) =>
    state.project.singleCandidateInsightGenerating;
export const isAllCandidatesSelected = (state: RootState) => {
    const { candidates, selectedCandidates } = state.project;
    const candidateIds = candidates.map((item) => item._id);
    const selectedCandidateIds = selectedCandidates.map((item) => item.candidateId);

    return (
        !!candidateIds.length &&
        candidateIds.every((item) => {
            const value = selectedCandidateIds.length && selectedCandidateIds.includes(item) ? true : false;
            return value;
        })
    );
};

export const isAllFiltersDisabled = (state: RootState) => {
    const { projectFilters } = state.project;
    return Object.values(projectFilters).every((i) => !i);
};
export const selectTotalSearchCount = (state: RootState) => state.project.totalSearchCount;
export const selectCandidateWorkflows = (state: RootState) => state.project.candidateWorkflows;
export const selectGridViewColumns = (state: RootState) => state.project.gridViewColumns;
export const checkErrorWhileFetchingCandidates = (state: RootState) => state.project.isErrorWhileFetchingCandidates;
export const checkErrorWhileFetchingStats = (state: RootState) => state.project.isErrorWhileFetchingStats;
export const checkErrorWhileFetchingAssociatedReachouts = (state: RootState) =>
    state.project.isErrorWhileFetchingAssociatedReachouts;
export const checkEmailEnrich = (state: RootState) => state.project.emailEnrich;
export const checkPhoneEnrich = (state: RootState) => state.project.phoneEnrich;
export const checkLinkedinEnrich = (state: RootState) => state.project.linkedInEnrich;
export const sendosoGifts = (state: RootState) => state.project.sendosoGifts;
export const selectMarketResearchData = (state: RootState) => state.project.marketResearchData;
export const selectMarketResearchDataFetchStatus = (state: RootState) => state.project.marketResearchDataFetchStatus;
export const selectMarketResearchDataFetchPercentage = (state: RootState) =>
    state.project.marketResearchDataFetchPercentage;
export const selectTwilloToken = (state: RootState) => state.project.twilloToken;
export const selectTwilloCalls = (state: RootState) => state.project.twilloCalls;
export const selectBlockList = (state: RootState) => state.project.blockList;
export const selectUpdateBulkCallCampaignStatus = (state: RootState) => state.project.updateBulkCallCampaignStatus;
