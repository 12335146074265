import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../../store";
import { ICandidate, IProjectFiltersRequestPayload } from "../../project.types";
import { CONTACT_TYPE_PROVIDER_MAP } from "../../../../constant/Constant";

export type EXPORT_MODES =
    | "FILTERED_CANDIDATES"
    | "ALL_CANDIDATES"
    | "SELECTED_CANDIDATES"
    | "EXPORT_WITH_ACCOUNT_INFO";

export type IExportCsvPayload = {
    projectId: string;
    onSuccess: () => void;
    mode?: EXPORT_MODES;
};

export type IExportPdfPayload = {
    projectId: string;
    onSuccess: () => void;
};

export type IExportCandidatesApiPayload = {
    projectId: string;
    exportType?: EXPORT_MODES;
    exportFormat?: "PDF" | "CSV";
} & IProjectFiltersRequestPayload;

export type IExportCandidatesApiResponse = {
    message: string;
    data?: {
        _id: number;
        candidates: ICandidate;
    }[];
};

type IRootState = {
    csvData: any;
    pdfData: ICandidate[];
};

const initialState: IRootState = {
    csvData: [],
    pdfData: [],
};

const reducers = {
    exportCsv(state: typeof initialState, action: PayloadAction<IExportCsvPayload>) {},
    exportPdf(state: typeof initialState, action: PayloadAction<IExportPdfPayload>) {},
    setCsvData(state: typeof initialState, action: PayloadAction<any[]>) {
        const data = action.payload.map((item) => {
            // const emailsCombined = [];
            const emailPersonal = [];
            const emailProfessional = [];

            if (item.email?.personal?.length) {
                emailPersonal.push(item.email?.personal);
            }

            if (item.email?.professional?.length) {
                emailProfessional.push(item.email?.professional);
            }

            // if (item.email?.primary) {
            //     emailsCombined.push(item.email?.primary);
            // }
            // if (item.email?.personal?.length) {
            //     emailsCombined.push(...item.email?.personal);
            // }
            // if (item.email?.professional?.length) {
            //     emailsCombined.push(...item.email?.professional);
            // }

            let skillsCombined = [];
            if (item?.skills && Array.isArray(item.skills)) {
                skillsCombined = item.skills.map(({ name }: { name: string }) => name).join(", ");
            }

            return {
                ...item,
                // email: emailsCombined.join(", "),
                emailSource: item.emailSource
                    ?.map((source: any) => (CONTACT_TYPE_PROVIDER_MAP as any)[source] ?? source)
                    .join(", "),
                phoneSource: item.phoneSource
                    ?.map((source: any) => (CONTACT_TYPE_PROVIDER_MAP as any)[source] ?? source)
                    .join(", "),
                emailPersonal: emailPersonal.join(", "),
                emailProfessional: emailProfessional.join(", "),
                skills: skillsCombined,
                extractedSkills: item.extractedSkills?.join(", "),
            };
        });
        state.csvData = data;
    },
    setPdfData(state: typeof initialState, action: PayloadAction<any[]>) {
        state.pdfData = action.payload;

        const data = action.payload.map((item) => {
            let primaryEmail = "";

            if (item.email?.primary) {
                primaryEmail = item.email?.primary;
            }

            return {
                ...item,
                primaryEmail,
            };
        });

        state.pdfData = data;
    },
    leavePage(state: typeof initialState) {},
};

export const page = createSlice({
    name: "exportCandidates",
    initialState,
    reducers,
});

export default page.reducer;

export const { exportCsv, exportPdf, setPdfData, leavePage, setCsvData } = page.actions;

export const selectCsvData = (state: RootState) => state.exportCandidates.csvData;

export const selectPdfData = (state: RootState) => state.exportCandidates.pdfData;
