import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

export default function TextLoading({ text }: { text: string }) {
    return (
        <Stack alignItems="center" direction="row" spacing={1}>
            <CircularProgress size={20} />
            <Typography variant="body2">{text}</Typography>
        </Stack>
    );
}
