// Import the close icon
import { Box, Typography } from "@mui/joy";
import Tooltip from "@mui/joy/Tooltip";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface FooterProps {
    date?: string;
    render?: () => ReactNode;
}

export default function Footer(props: FooterProps) {
    const { t } = useTranslation();
    const { date, render } = props;

    return (
        <>
            <Box
                justifyContent="space-between"
                display="flex"
                alignItems="center"
                sx={{
                    borderTop: "1px solid",
                    borderColor: "divider",
                    backgroundColor: "background.body",
                }}
                p={{ xs: 1, md: 3 }}
            >
                {date && (
                    <Tooltip size="sm" title={t("superInbox.footerTooltip")}>
                        <Typography fontSize={11} component={"i"}>
                            Last updated at {dayjs(date).format("h:mm a, DD MMM")}
                        </Typography>
                    </Tooltip>
                )}

                <Box flex="1" />
                {render?.()}
            </Box>
        </>
    );
}
