import { Socket, io } from "socket.io-client";

import { findCookie } from "@/utils/cookie";
import { getFirebaseWebToken } from "@/utils/firebase";
import handleException from "@/utils/sentry";

const url = import.meta.env.VITE_REACT_APP_BASE_URL;

interface SocketRef {
    current: Socket | null;
}

interface Auth {
    token: string | null;
    webToken: string;
    tabId: string;
}

export const makeConnection = async (socketRef: SocketRef, tabUuid: string): Promise<void> => {
    try {
        const authToken = findCookie("accessToken");
        const webToken = await getFirebaseWebToken();

        socketRef.current = io(url, {
            auth: {
                token: authToken,
                webToken: webToken,
                tabId: tabUuid,
            } as Auth,
            transports: ["websocket", "polling"],
            path: "/socket.io/",
            // path: "hqSync",
            reconnection: true,
            reconnectionAttempts: 10,
            reconnectionDelay: 3000,
            timeout: 20000,
            extraHeaders: {
                "x-webauthorization": webToken,
                "x-tabid": tabUuid,
            },
        });
    } catch (error) {
        console.error("Error establishing socket connection:", error);
        handleException(error);
    }
};
