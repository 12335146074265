
export type MRData = {
    candidateName: string;
    Competitors: string;
    FoundingYear: string;
    RevenueinUSD: string;
    Industry: string;
    MarketCap: string;
    Employees: string;
    CEO: string;
    IPO: string;
    Headquarters: string;
    IPODate: string;
    Funding: string;
    ProductsandServices: string;
    FreeTrial: string;
    DemoOffering: string;
    RecentlyLaunchedProducts: string;
    PricingPlans: string;
    BusinessType: string;
    ParentCompany: string;
    ChildCompanies: string;
    CrunchbaseDetails: string;
    VacantPositions: string;
    PhoneNumber: string;
    DomainLink: string;
    Instagram: string;
    Twitter: string;
    YouTube: string;
    FundingType: string;
    JobTitle: string;
    Location: string;
    Company: string;
    Tenure: string;
};


export const allOptions = [
    "Competitors",
    "FoundingYear",
    "RevenueinUSD",
    "Industry",
    "MarketCap",
    "Employees",
    "Headquarters",
    "CEO",
    "IPO",
    "IPODate",
    "Funding",
    "ProductsandServices",
    "FreeTrial",
    "DemoOffering",
    "RecentlyLaunchedProducts",
    "PricingPlans",
    "BusinessType",
    "ParentCompany",
    "ChildCompanies",
    "CrunchbaseDetails",
    "VacantPositions",
    "PhoneNumber",
    "DomainLink",
    "Instagram",
    "Twitter",
    "YouTube",
    "FundingType",
    "JobTitle",
    "Location",
    "Company",
    "Tenure",
];


export const titleMapping = {
    JobTitle: "Job Title",
    Location: "Location",
    Company: "Company",
    CEO: "CEO",
    
    FoundingYear: "Founding Year",
    RevenueinUSD: "Revenue in USD",
    DomainLink: "Domain Link",
    MarketCap: "Market Cap",
    Employees: "Employees",
    Headquarters: "Headquarters",
    IPO: "IPO",
    IPODate: "IPO Date",
    Funding: "Funding",
    PhoneNumber: "Phone Number",
    Instagram: "Instagram",
    Twitter: "Twitter",
    YouTube: "YouTube",
    FreeTrial: "Free Trial",
    DemoOffering: "Demo Offering",
    RecentlyLaunchedProducts: "Recently Launched Products",
    PricingPlans: "Pricing Plans",
    BusinessType: "Business Type",
    ParentCompany: "Parent Company",
    ChildCompanies: "Child Companies",
    VacantPositions: "Vacant Positions",
    Tenure: "Tenure",
    FundingType: "Funding Type",
    Competitors: "Competitors",
    ProductsandServices: "Products and Services",
    Industry: "Industry",
    CrunchbaseDetails: "Crunchbase Details",
};
