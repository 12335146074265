export const phonePattern = /\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}(?=\s|$)/g;

export const phoneMatchPattern = /(?:\+\d{1,2}\s)?\(?\d{3}\)?[.\s/-]?\d{3}[.\s/-]?\d{4}/g;
/*
 phoneMatchPattern works with 
 (555) 123-4567 
 +1 555-123-4567 
 +15551234567 
 555.123.4567 
 555-123-4567 
 555 123 4567 
 +1 (555) 123-4567 
 555/123/4567 
 +1 (555).123.4567 
 */

export const tenToTwelveDigitsPattern = /\b\d{10,12}\b/g;

export default function validatePhone(phone: any) {
    let isValid = false;

    if (phone?.match(phonePattern)) {
        isValid = true;
    }

    if (!isValid && phone?.match(tenToTwelveDigitsPattern)) {
        isValid = true;
    }

    return isValid;
}

export function validatePhoneDigits(phone: any) {
    let isValid = false;
    if (phone?.startsWith("+")) {
        isValid = phone.match(/\d/g)?.length === 11 || phone.match(/\d/g)?.length === 12;
    } else {
        isValid =
            phone?.match(/\d/g)?.length === 10 ||
            phone?.match(/\d/g)?.length === 11 ||
            phone?.match(/\d/g)?.length === 12;
    }
    return isValid;
}

export function extractPhoneNumbers(text: string) {
    return text.match(phonePattern);
}
