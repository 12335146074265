import { lightenDarkenColor } from "react-papaparse";
import { CSSProperties } from "react";
import { Option } from "../common";

export const GREY = "#CCC";
export const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
export const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
export const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
export const GREY_DIM = "#686868";

export const styles = {
    zone: {
        alignItems: "center",
        border: `2px dashed ${GREY}`,
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        padding: 20,
    } as CSSProperties,
    file: {
        background: "linear-gradient(to bottom, #EEE, #DDD)",
        borderRadius: 20,
        display: "flex",
        height: 120,
        width: 120,
        position: "relative",
        zIndex: 10,
        flexDirection: "column",
        justifyContent: "center",
    } as CSSProperties,
    info: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        paddingLeft: 10,
        paddingRight: 10,
    } as CSSProperties,
    size: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        marginBottom: "0.5em",
        justifyContent: "center",
        display: "flex",
    } as CSSProperties,
    name: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        fontSize: 12,
        marginBottom: "0.5em",
    } as CSSProperties,
    progressBar: {
        bottom: 14,
        position: "absolute",
        width: "100%",
        paddingLeft: 10,
        paddingRight: 10,
    } as CSSProperties,
    zoneHover: {
        borderColor: GREY_DIM,
    } as CSSProperties,
    default: {
        borderColor: GREY,
    } as CSSProperties,
    remove: {
        height: 23,
        position: "absolute",
        right: 6,
        top: 6,
        width: 23,
    } as CSSProperties,
};

export const columnOptions: Option[] = [
    {
        label: "Name",
        value: "name",
    },
    {
        label: "First Name",
        value: "first name",
    },
    {
        label: "Last Name",
        value: "last name",
    },
    {
        label: "Title",
        value: "title",
    },
    {
        label: "Country/Region",
        value: "country/region",
    },
    {
        label: "Email",
        value: "email",
    },
    {
        label: "Linkedin Profile",
        value: "linkedinProfile",
    },
    {
        label: "Company",
        value: "company",
    },
    {
        label: "Company Revenue",
        value: "companyRevenue",
    },
    {
        label: "Company Website",
        value: "companyWebsite",
    },
    {
        label: "Company Location",
        value: "companyLocation",
    },
    {
        label: "Department",
        value: "department",
    },
    {
        label: "Industry",
        value: "industry",
    },
    {
        label: "Employee Count",
        value: "employeeCount",
    },
    {
        label: "Company Type",
        value: "companyType",
    },
    {
        label: "Lead Type",
        value: "leadType",
    },
    {
        label: "Opted Out Status",
        value: "optedOutStatus",
    },
    {
        label: "CTA Link",
        value: "ctaLink",
    },
];
