import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    selectProjectFilters,
    selectShowByMaxExperience,
    selectShowByMinExperience,
    selectSearchQuery,
    selectShowByMostRecent,
    selectPageSize,
    selectCandidateCurrentPage,
    setLinkedinEnrich,
    selectShowByRelevance,
    setTotalCandidates,
    setCandidates,
} from "@/store/reducers/project/project.reducer";
import {
    selectProjectStatus,
    setCurrentProjectStatus,
    setProjectStatus,
    setProjectInsightGenerationStatus,
    selectProjectInsightGenerationStatus,
} from "@/store/reducers/allProjects/allProjects.reducer";

import { projectLevelNotificationEvents } from "./sockets";
import { getSessionStorage } from "@/hooks/useSessionStorage";

interface FetchCandidatesProjectProps {
    projectId: string;
    generateInsightsStatus: any;
    isConnected: boolean;
    emit: any;
    useSocketListeners: any;
    setGenerateInsightsData: any;
}

const useFetchCandidatesProject = (props: FetchCandidatesProjectProps) => {
    const { projectId, generateInsightsStatus, isConnected, emit, useSocketListeners, setGenerateInsightsData } = props;

    const dispatch = useDispatch();

    const filters = useSelector(selectProjectFilters);
    const pageSize = useSelector(selectPageSize);
    const pageNo = useSelector(selectCandidateCurrentPage);
    const searchQuery = useSelector(selectSearchQuery);
    const projectStatus = useSelector(selectProjectStatus);
    const tabUuid = getSessionStorage.read("tabUuid");
    const insightGenerationStatus = useSelector(selectProjectInsightGenerationStatus);

    const [candidates, setcandidatesData] = useState([]);
    const [projectIdData, setProjectIdData] = useState("");

    const [sortBy, setSortBy] = useState("relevance");
    // Filter and sort state
    const showByOptions = {
        relevance: useSelector(selectShowByRelevance),
        mostRecent: useSelector(selectShowByMostRecent),
        maxExperience: useSelector(selectShowByMaxExperience),
        minExperience: useSelector(selectShowByMinExperience),
    };
    // Update sorting logic
    useEffect(() => {
        const newSortBy = Object.entries(showByOptions).find(([_, value]) => value)?.[0] || "relevance";
        setSortBy(newSortBy);
    }, [showByOptions]);

    useEffect(() => {
        setGenerateInsightsData({ status: insightGenerationStatus });
    }, [insightGenerationStatus]);

    // Trigger updates on filters, loading state, or sort changes
    useEffect(() => {
        if (
            projectStatus === "PENDING" ||
            projectStatus === "IN_PROGRESS" ||
            generateInsightsStatus === "IN_PROGRESS"
        ) {
            if (isConnected) {
                const appliedFilters = Object.keys(filters).filter((key) => filters[key]);
                emit(projectLevelNotificationEvents.UPDATE_PROJECT_FILTERS_REQ, {
                    projectId,
                    filters: appliedFilters,
                    sortBy,
                    search: searchQuery,
                    start: pageNo ? pageNo - 1 : 0,
                    limit: pageSize,
                    tabId: tabUuid,
                });
            }
        }
    }, [filters, sortBy, pageNo, pageSize, searchQuery, projectStatus, isConnected, generateInsightsStatus]);
    useSocketListeners([
        {
            event: projectLevelNotificationEvents.GET_CANDIDATE_PROJECT_RES,
            callback: (response) => {
                if (!isConnected) {
                    console.warn("Skipping dispatch due to disconnected socket.");
                    return; // Prevent dispatching actions if disconnected
                }

                const { data, projectId: projectIdRes } = response;
                console.log(projectIdRes, projectId);
                if (Number(projectIdRes) !== Number(projectId)) {
                    console.warn("Skipping dispatch due to projectId mismatch.");
                    return;
                }
                if (projectIdRes && isConnected) {
                    setProjectIdData(projectIdRes);
                }

                if (data?.projectStatus && isConnected) {
                    dispatch(setProjectStatus(data.projectStatus));
                    dispatch(setCurrentProjectStatus({ projectId, status: data.projectStatus }));
                }
                if (data?.linkedinEnrichmentProgress === "IN_PROGRESS" && isConnected) {
                    dispatch(
                        setLinkedinEnrich({
                            linkedInEnrich: true,
                        })
                    );
                }
                if (data?.linkedinEnrichmentProgress === "COMPLETED" && isConnected) {
                    dispatch(
                        setLinkedinEnrich({
                            linkedInEnrich: false,
                        })
                    );
                }
                if (data.generateInsightsStatus && isConnected) {
                    dispatch(setProjectInsightGenerationStatus(data.generateInsightsStatus));
                }
                if (data?.candidates && isConnected) {
                    dispatch(setCandidates(data.candidates));
                    setcandidatesData(data.candidates);
                }
                if (data.totalCandidates && isConnected) {
                    dispatch(setTotalCandidates(data.totalCandidates));
                }
            },
        },
    ]);

    return { candidates, projectIdData };
};
export default useFetchCandidatesProject;
