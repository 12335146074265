import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setErrorNotification, setSuccessNotification } from "../../../components/Notification/index.reducer";
import { IActionPayload, startAction, stopAction } from "../../../store/reducers/loaders.reducer";
import API from "../../../utils/API";
import { exportToCsvc } from "../../../utils/helper";
import { CancelSagas } from "../../../utils/saga.utils";
import handleException from "../../../utils/sentry";
import {
    getUserList,
    leavePage,
    setTotalCandidatesScraped,
    setTotalEmailsExtracted,
    setTotalProjectsCreated,
    setTotalUserCount,
    setTotalWorkflowsTriggered,
    setUserList,
    submitSwitchOrdId,
    updateUserTracking,
} from "./userList.reducers";
import { IGetUserListPayload, ITracking, IUser } from "./userList.types";

function* getUserListSaga({ payload }: { payload: IActionPayload & IGetUserListPayload }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));
        const state = yield select();
        const sortBy = get(state, "userList.sortBy");
        const response = yield call(
            new API().get,
            `/super-admin/userList?start=${payload.start}&limit=${payload.limit}&sortBy=${sortBy}`
        );
        if (!response?.data.users) return;

        const users = response.data.users; // TODO: remove this line after testing

        yield put(setUserList(users));
        yield put(setTotalUserCount(response.data.total));
        yield put(setTotalProjectsCreated(response.data.totalProjectsCreated));
        yield put(setTotalWorkflowsTriggered(response.data.totalWorkflowsTriggered));
        yield put(setTotalCandidatesScraped(response.data.totalCandidatesScraped));
        yield put(setTotalEmailsExtracted(response.data.totalEmailsExtracted));

        if (payload.isExportAll) {
            exportToCsvc(response.data.users);
        }
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* submitSwitchOrdIdSaga(action: PayloadAction): SagaIterator {
    try {
        const state = yield select();
        const orgId = get(state, "userList.orgId");
        yield put(startAction({ action: action.type }));
        const response = yield call(new API().put, "auth/editUser", {
            orgId,
        });

        if (response?.message) {
            window.location.href = "/";
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(setErrorNotification("Sorry, an error occurred while changing your organization. Please try again"));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* updateUserTrackingSaga(
    action: PayloadAction<{ type: string; userId: string; tracking: ITracking }>
): SagaIterator {
    try {
        const state = yield select();
        const users = get(state, "userList.users");
        yield put(startAction({ action: action.payload.type }));
        const { userId, tracking } = action.payload;
        const response = yield call(new API().post, `super-admin/updateUserTracking/${userId}`, {
            trackingObj: tracking,
        });

        if (response?.message) {
            yield put(setSuccessNotification(response.message));
            const updatedUserList = users.map((user: IUser) => (user._id === userId ? { ...user, tracking } : user));
            yield put(setUserList(updatedUserList));
        } else {
            throw new Error();
        }
    } catch (error) {
        handleException(error);
        yield put(
            setErrorNotification("Sorry, an error occurred while updating tracking information. Please try again")
        );
    } finally {
        yield put(stopAction({ action: action.payload.type }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getUserList.type, getUserListSaga),
        // @ts-ignore
        yield takeLatest(submitSwitchOrdId.type, submitSwitchOrdIdSaga),
        // @ts-ignore
        yield takeLatest(updateUserTracking.type, updateUserTrackingSaga),
    ];
    // @ts-ignore
    yield takeLatest(leavePage.type, CancelSagas, tasks);
}
