import { TooltipProps } from "@mui/material/Tooltip";
import { BarChartProps } from "@mui/x-charts";

export const defaultChartColors = [
    "#00B7C7",
    "#EBDC78",
    "#0D88E6",
    "#7C1158",
    "#4421AF",
    "#5AD45A",
    "#8BE04E",
    "#EBDC78",
];

export const defaultChartSetting: Pick<
    BarChartProps,
    "width" | "height" | "slots" | "colors" | "leftAxis" | "rightAxis" | "topAxis" | "bottomAxis"
> = {
    // width: 500,
    height: 300,
    colors: defaultChartColors,
    leftAxis: null,
    bottomAxis: null,
    rightAxis: null,
    topAxis: null,
    slots: {
        legend: () => null,
    },
};

type customBarChartProps = Pick<
    BarChartProps,
    "width" | "layout" | "height" | "slots" | "colors" | "leftAxis" | "rightAxis" | "topAxis" | "bottomAxis"
> & {
    borderRadius: number;
};
export const defaultChartSettingCustom: customBarChartProps = {
    width: 500,
    height: 300,
    colors: defaultChartColors,
    leftAxis: null,
    rightAxis: null,
    topAxis: null,
    bottomAxis: null,
    layout: "vertical",
    slots: {
        legend: () => null,
    },
    borderRadius: 10,
};

export const defaultChartSettingVertical:
    | Partial<BarChartProps>
    | Pick<
          BarChartProps,
          | "width"
          | "height"
          | "slots"
          | "colors"
          | "leftAxis"
          | "rightAxis"
          | "topAxis"
          | "bottomAxis"
          | "xAxis"
          | "slotProps"
          | "layout"
          | "sx"
      > = {
    height: 500,
    colors: defaultChartColors,
    layout: "vertical",
    leftAxis: { disableTicks: true, axisId: "total" },
    // rightAxis: null,
    topAxis: null,
    bottomAxis: { axisId: "quarters", disableLine: true, disableTicks: true },
};

export const tooltipSetting: Pick<TooltipProps, "componentsProps" | "placement"> = {
    placement: "top",
    componentsProps: {
        tooltip: {
            sx: {
                fontSize: 14,
                padding: 1,
                bgcolor: "common.white",
                color: "rgba(0,0,0,0.7)",
                border: "1px solid rgba(0,0,0,0.2)",
            },
        },
    },
};
