import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { isValidEmail } from "../../../utils/helper";
import { selectUser } from "../../Signin/Signin.reducer";
import { postGreenhouseCredentials } from "../integrations.reducer";
import { GreenHouseKey, GreenHouseState } from "../integrations.types";

export default function GreenhouseConnect({ onClose }: { onClose: () => void }) {
    const user = useSelector(selectUser);
    const isCredsSubmitting = useSelector(checkIfLoading(postGreenhouseCredentials.type));

    const dispatch = useDispatch();
    const [greenHouse, setGreenHouse] = useState<GreenHouseState>({
        harvestApiKey: "",
        apiKey: "",
        serviceUser: "",
        clientGreenHouseUrl: "",
    });
    const [error, setError] = useState({
        harvestApiKey: false,
        apiKey: false,
        serviceUser: false,
    });

    useEffect(() => {
        if (isEmpty(user)) return;
        setGreenHouse({
            harvestApiKey: user.greenHouse?.harvestApiKey ?? "",
            apiKey: user.greenHouse?.apiKey ?? "",
            serviceUser: user.greenHouse?.serviceUser ?? "",
            clientGreenHouseUrl: user.greenHouse?.clientGreenHouseUrl ?? "",
        });
    }, [user]);

    function validateField(field: GreenHouseKey, isValid: boolean) {
        if (!isValid) {
            setError((prevError) => ({ ...prevError, [field]: true }));
            return;
        }
    }

    const handleSubmit = () => {
        validateField("harvestApiKey", Boolean(greenHouse.harvestApiKey));
        validateField("apiKey", Boolean(greenHouse.apiKey));
        validateField("serviceUser", isValidEmail(greenHouse.serviceUser));
        dispatch(
            postGreenhouseCredentials({
                action: postGreenhouseCredentials.type,
                harvestApiKey: greenHouse.harvestApiKey,
                apiKey: greenHouse.apiKey,
                serviceUser: greenHouse.serviceUser,
                clientGreenHouseUrl: greenHouse?.clientGreenHouseUrl,
                onSuccess: onClose,
            })
        );
    };

    return (
        <>
            <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Connect Greenhouse</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography fontSize={14} noWrap>
                            Harvest API Key
                        </Typography>
                        <TextField
                            hiddenLabel
                            size="small"
                            placeholder="Enter harvest api key"
                            value={greenHouse.harvestApiKey}
                            onChange={(e) =>
                                setGreenHouse((prev) => ({
                                    ...prev,
                                    harvestApiKey: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                            error={error.harvestApiKey}
                            helperText={error.harvestApiKey ? "Enter valid api key" : null}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography fontSize={14} noWrap>
                            Candidate Ingestion API Key
                        </Typography>
                        <TextField
                            hiddenLabel
                            size="small"
                            placeholder="Enter candidate ingestion api key"
                            value={greenHouse.apiKey}
                            onChange={(e) =>
                                setGreenHouse((prev) => ({
                                    ...prev,
                                    apiKey: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                            error={error.apiKey}
                            helperText={error.apiKey ? "Enter valid api key" : null}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography fontSize={14} noWrap>
                            Service User Email
                        </Typography>
                        <TextField
                            hiddenLabel
                            size="small"
                            placeholder="Enter email"
                            value={greenHouse.serviceUser}
                            onChange={(e) =>
                                setGreenHouse((prev) => ({
                                    ...prev,
                                    serviceUser: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                            error={error.serviceUser}
                            helperText={error.serviceUser ? "Enter valid email" : null}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mt={2}>
                        <Typography fontSize={14} noWrap>
                            Your Greenhouse domain
                        </Typography>
                        <TextField
                            hiddenLabel
                            size="small"
                            placeholder="Enter your Greenhouse domain"
                            value={greenHouse.clientGreenHouseUrl}
                            onChange={(e) =>
                                setGreenHouse((prev) => ({
                                    ...prev,
                                    clientGreenHouseUrl: e.target.value,
                                }))
                            }
                            sx={{
                                minWidth: 300,
                                "& .MuiInputBase-input": {
                                    fontSize: 14,
                                },
                            }}
                        />
                    </Stack>
                    <div style={{ height: 14, fontSize: 10, color: "rgba(0, 0, 0, 0.54)", fontStyle: "italic" }}>
                        Example: https://abcd.greenhouse.com, where abcd may be custom text for your account or your
                        company name
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose} sx={{ borderRadius: 1 }}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isCredsSubmitting}
                        sx={{ borderRadius: 1 }}
                    >
                        <ButtonTextWithLoading text="Submit" isLoading={isCredsSubmitting} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
