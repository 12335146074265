import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useRef } from "react";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";

import BaseModal from "@/components/BaseModal";
import { ButtonTextWithLoading } from "@/components/ButtonTextWithLoading";
import { MaterialProvider } from "@/components/MaterialProvider";
import { useRetriggerWorkflowMutation } from "@/store/apis/linkedin-workflow/linkedin-workflow.api";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { Button, Switch } from "@/ui";

type RetriggerWorkflowModalProps = {
    open: boolean;
    onClose: () => void;
};

const retriggerWorkflowSchema = z
    .object({
        workflowLogIds: z.array(z.string()).nullable(),
        userIds: z.array(z.string()).nullable(),
        startDate: z
            .custom<Dayjs>((val) => {
                return val ? dayjs(val).isValid() : false;
            }, "Start date is required")
            .nullable()
            .transform((val) => {
                if (typeof val === "string") return dayjs(val).utc().startOf("day").toISOString();
                return val ? val.utc().startOf("day").toISOString() : null;
            }),
        nextDayAfterEndDate: z
            .custom<Dayjs>((val) => {
                return val ? dayjs(val).isValid() : false;
            }, "End date is required")
            .nullable()
            .transform((val) => {
                if (typeof val === "string") return dayjs(val).utc().startOf("day").toISOString();
                return val ? val.utc().startOf("day").toISOString() : null;
            }),
        isClearFromRedis: z.boolean().default(true),
        isConnectWithApi: z.boolean().default(false),
        isScheduleWorkflowsNow: z.boolean().default(false),
        isSemiFailedWorkflows: z.boolean().default(false),
    })
    .refine(
        (data) => {
            // Require userIds only if workflowLogIds is empty
            return data.workflowLogIds.length > 0 || data.userIds.length > 0;
        },
        {
            message: "Either Workflow Log IDs or User IDs must be provided",
            path: ["userIds"],
        }
    );

type RetriggerWorkflowFormState = z.infer<typeof retriggerWorkflowSchema>;

const initialValues: RetriggerWorkflowFormState = {
    workflowLogIds: [],
    userIds: [],
    startDate: dayjs().toString(),
    nextDayAfterEndDate: dayjs().toString(),
    isClearFromRedis: true,
    isConnectWithApi: false,
    isScheduleWorkflowsNow: false,
    isSemiFailedWorkflows: false,
};

function RetriggerWorkflowForm() {
    const ref = useRef(null);
    const {
        control,
        formState: { errors },
    } = useFormContext<RetriggerWorkflowFormState>();

    // Watch workflowLogIds to disable userIds field
    const workflowLogIds = useWatch({ control, name: "workflowLogIds" });
    const isUserIdsDisabled = workflowLogIds.length > 0;

    return (
        <div className="space-y-6">
            {/* Workflow Log ids */}
            <Controller
                control={control}
                name="workflowLogIds"
                render={({ field }) => (
                    <Autocomplete
                        multiple
                        freeSolo
                        size="small"
                        ChipProps={{ color: "success" }}
                        value={field.value}
                        options={[]}
                        onChange={(_, value) => {
                            const normalizedValue = value.map((v) => v.split(",").map((v) => v.trim())).flat();
                            field.onChange(normalizedValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!errors.workflowLogIds}
                                helperText={errors.workflowLogIds?.message}
                                InputLabelProps={{ sx: { fontSize: 14 } }}
                                ref={ref}
                                label="Workflow Log Ids"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: <>{params?.InputProps?.endAdornment}</>,
                                    sx: { fontSize: 14 },
                                }}
                            />
                        )}
                    />
                )}
            />

            {/* userIds */}
            <Controller
                control={control}
                name="userIds"
                render={({ field }) => (
                    <Autocomplete
                        multiple
                        freeSolo
                        size="small"
                        ChipProps={{ color: "success" }}
                        value={field.value}
                        options={[]}
                        disabled={isUserIdsDisabled}
                        onChange={(_, value) => {
                            const normalizedValue = value.map((v) => v.split(",").map((v) => v.trim())).flat();
                            field.onChange(normalizedValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                helperText={
                                    isUserIdsDisabled
                                        ? "This field is disabled when Workflow Log IDs are provided"
                                        : null
                                }
                                ref={ref}
                                InputLabelProps={{ sx: { fontSize: 14 } }}
                                label="User Ids"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: <>{params?.InputProps?.endAdornment}</>,
                                    sx: { fontSize: 14 },
                                }}
                            />
                        )}
                    />
                )}
            />

            {/* Select date */}
            <div className="flex justify-between items-center gap-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        control={control}
                        name="startDate"
                        render={({ field }) => (
                            <DatePicker
                                label="Start Date"
                                value={dayjs(field.value)}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                                slotProps={{
                                    textField: {
                                        error: !!errors.startDate,
                                        helperText: errors.startDate?.message,
                                    },
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="nextDayAfterEndDate"
                        render={({ field }) => (
                            <DatePicker
                                label="Next Day After End Date"
                                value={dayjs(field.value)}
                                onChange={(date) => field.onChange(date)}
                                slotProps={{
                                    textField: {
                                        error: !!errors.nextDayAfterEndDate,
                                        helperText: errors.nextDayAfterEndDate?.message,
                                    },
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>

            {/* switch for isClearFromRedis,  isConnectWithApi*/}
            <div>
                <div className="flex items-center justify-between">
                    <p className="text-sm">Cleared from redis</p>
                    <Controller
                        control={control}
                        name="isClearFromRedis"
                        render={({ field }) => (
                            <Switch checked={field.value} onChange={(checked) => field.onChange(checked)} />
                        )}
                    />
                </div>

                <div className="flex items-center justify-between">
                    <p className="text-sm">Connect with api</p>
                    <Controller
                        control={control}
                        name="isConnectWithApi"
                        render={({ field }) => (
                            <Switch checked={field.value} onChange={(checked) => field.onChange(checked)} />
                        )}
                    />
                </div>

                <div className="flex items-center justify-between">
                    <p className="text-sm">Schedule workflows now</p>
                    <Controller
                        control={control}
                        name="isScheduleWorkflowsNow"
                        render={({ field }) => (
                            <Switch checked={field.value} onChange={(checked) => field.onChange(checked)} />
                        )}
                    />
                </div>

                <div className="flex items-center justify-between">
                    <p className="text-sm">Semi failed workflow</p>
                    <Controller
                        control={control}
                        name="isSemiFailedWorkflows"
                        render={({ field }) => (
                            <Switch
                                checked={field.value}
                                onChange={(checked) => field.onChange(checked)}
                                disabled={!useWatch({ control, name: "isClearFromRedis" })}
                            />
                        )}
                    />
                </div>
            </div>

            {/* error */}
            <div className="text-red-500 text-sm min-h-16px">{errors?.userIds?.message}</div>
        </div>
    );
}

function RetriggerWorkflowModal({ open, onClose }: RetriggerWorkflowModalProps) {
    const dispatch = useDispatch();
    const [retriggerWorkflow, { isLoading }] = useRetriggerWorkflowMutation();
    const methods = useForm<RetriggerWorkflowFormState>({
        defaultValues: initialValues,
        resolver: zodResolver(retriggerWorkflowSchema),
        mode: "onChange",
    });

    const onSubmit: SubmitHandler<RetriggerWorkflowFormState> = (data) => {
        retriggerWorkflow(data)
            .unwrap()
            .then((res: any) => {
                dispatch(setSuccessNotification(res?.message || "Workflow retriggered successfully"));
                onClose();
            })
            .catch((err: any) => {
                console.error("Error retriggering workflow", err);
                dispatch(setErrorNotification(err?.data?.message || "Error retriggering workflow"));
            });
    };

    if (!open) return null;

    return (
        <MaterialProvider>
            <BaseModal
                onClose={onClose}
                overlayStyles={{
                    padding: "1rem",
                }}
            >
                <div className="text-lg font-semibold mb-4">Retrigger Workflow</div>
                <FormProvider {...methods}>
                    <form className="flex flex-col gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
                        <RetriggerWorkflowForm />
                        <Button type="submit">
                            <ButtonTextWithLoading isLoading={isLoading} text="Retrigger Workflow" />
                        </Button>
                    </form>
                </FormProvider>
            </BaseModal>
        </MaterialProvider>
    );
}

export default RetriggerWorkflowModal;
