import { createApi } from "@reduxjs/toolkit/dist/query/react";
import queryString from "query-string";

import { EQueries } from "./analytics.enums";
import {
    TFilter,
    TFilterGraph,
    TFunnelOverview,
    TGraph,
    TMultipleUserFunnelFilter,
    TOutreactStats,
    TProjectsStatus,
} from "./analytics.types";

import { useRTKQueryCachedState } from "../../../hooks/useRTKQueryCachedState";
import { fetchBaseQueryWithZod } from "../utils";

export const analyticsApi = createApi({
    reducerPath: "analyticsApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [
        EQueries.MULTIPLE_USER_FUNNEL,
        EQueries.FUNNEL_OVERVIEW,
        EQueries.SOURCE_GRAPH,
        EQueries.CHANNEL_GRAPH,
        EQueries.OUTREACH_STATS,
        EQueries.PROJECTS_STATUS,
        EQueries.CREDIT_USAGE,
        EQueries.GRAPH,
    ],
    endpoints: (builder) => ({
        getMultipleUserFunnel: builder.query<any, TMultipleUserFunnelFilter>({
            query: (filters) => ({
                url: `/analytics/multiple-users-funnel`,
                body: filters,
                method: "POST",
            }),
            providesTags: [EQueries.MULTIPLE_USER_FUNNEL],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data;
            },
        }),
        getFunnelOverview: builder.query<TFunnelOverview, TFilter>({
            query: (filters) => ({
                url: `/analytics/funnel-overview?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.FUNNEL_OVERVIEW],
            transformResponse(baseQueryReturnValue: { data: TFunnelOverview }) {
                return baseQueryReturnValue?.data || [];
            },
        }),

        getGraph: builder.query<TGraph, TFilter>({
            query: (filters) => ({
                url: `/analytics/graph`,
                method: "POST",
                body: {
                    fromDate: filters.startDate,
                    toDate: filters.endDate,
                    projectId: filters.projectId,
                    userId: filters.userId,
                },
            }),
            providesTags: [EQueries.GRAPH],
            transformResponse(baseQueryReturnValue: { data: TGraph }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getSourceGraph: builder.query<TFunnelOverview, TFilterGraph>({
            query: (filters) => ({
                url: `/analytics/source-graph?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.SOURCE_GRAPH],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getChannelGraph: builder.query<TFunnelOverview, TFilterGraph>({
            query: (filters) => ({
                url: `/analytics/channel-graph?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.CHANNEL_GRAPH],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getOutreachStats: builder.query<TOutreactStats, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.OUTREACH_STATS],
            transformResponse(baseQueryReturnValue: { data: TOutreactStats }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getProjectsStatus: builder.query<TProjectsStatus, TFilter>({
            query: (filters) => ({
                url: `/analytics/projects-status?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.PROJECTS_STATUS],
            transformResponse(baseQueryReturnValue: { data: TProjectsStatus }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getCreditUsage: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/credit-usage?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.CREDIT_USAGE],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
    }),
});

export const {
    useGetMultipleUserFunnelQuery,
    useGetFunnelOverviewQuery,
    useGetGraphQuery,
    useGetSourceGraphQuery,
    useGetChannelGraphQuery,
    useGetOutreachStatsQuery,
    useGetProjectsStatusQuery,
    useGetCreditUsageQuery,
} = analyticsApi;

const config = {
    reducerPath: analyticsApi.reducerPath,
    skip: false, // this is used to skip the cache
};

export const useCachedGetFunnelOverviewQuery = (...args: Parameters<typeof useGetFunnelOverviewQuery>) =>
    useRTKQueryCachedState(config, useGetFunnelOverviewQuery, ...args);
export const useCachedGetGraphQuery = (...args: Parameters<typeof useGetGraphQuery>) =>
    useRTKQueryCachedState(config, useGetGraphQuery, ...args);
export const useCachedGetSourceGraphQuery = (...args: Parameters<typeof useGetSourceGraphQuery>) =>
    useRTKQueryCachedState(config, useGetSourceGraphQuery, ...args);
export const useCachedGetChannelGraphQuery = (...args: Parameters<typeof useGetChannelGraphQuery>) =>
    useRTKQueryCachedState(config, useGetChannelGraphQuery, ...args);
export const useCachedGetOutreachStatsQuery = (...args: Parameters<typeof useGetOutreachStatsQuery>) =>
    useRTKQueryCachedState(config, useGetOutreachStatsQuery, ...args);
export const useCachedGetProjectsStatusQuery = (...args: Parameters<typeof useGetProjectsStatusQuery>) =>
    useRTKQueryCachedState(config, useGetProjectsStatusQuery, ...args);
export const useCachedGetCreditUsageQuery = (...args: Parameters<typeof useGetCreditUsageQuery>) =>
    useRTKQueryCachedState(config, useGetCreditUsageQuery, ...args);
export const useCachedGetMultipleUserFunnelQuery = (...args: Parameters<typeof useGetMultipleUserFunnelQuery>) =>
    useRTKQueryCachedState(config, useGetMultipleUserFunnelQuery, ...args);
