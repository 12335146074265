import { styled } from "@mui/material";
import Chip, { ChipProps } from "@mui/material/Chip";
import { capitalize } from "lodash";

export type StageType = "PIPELINE" | "CONTACTED" | "RESPONDED" | "TRIGGERED";

export type StageComponents = Record<StageType, (label: string) => React.ReactNode>;

const CustomChip = styled((props: ChipProps) => (
    <Chip
        {...props}
        sx={(theme) => ({
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.common.white,
            width: "fit-content",
            alignSelf: "flex-end",
            fontSize: theme.typography.pxToRem(11),
        })}
    />
))({});

const components: StageComponents = {
    CONTACTED: (label: string) => (
        <CustomChip
            label={label}
            sx={(theme) => ({
                backgroundColor: theme.palette.warning.light,
            })}
        />
    ),
    RESPONDED: (label: string) => (
        <CustomChip
            label={label}
            sx={(theme) => ({
                backgroundColor: theme.palette.success.light,
            })}
        />
    ),
    PIPELINE: (label: string) => (
        <CustomChip
            label={label}
            color="default"
            sx={(theme) => ({
                color: theme.palette.grey[600],
            })}
        />
    ),
    TRIGGERED: (label: string) => (
        <CustomChip
            label={label}
            sx={(theme) => ({
                backgroundColor: theme.palette.warning.light,
            })}
        />
    ),
};

export default function Stage({ stage }: { stage: StageType }) {
    const value = components?.[stage]?.(capitalize(stage));
    return <>{value}</>;
}
