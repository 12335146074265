import { useSelector } from "react-redux";
import { selectIsExtensionInstalled } from "../../container/AppContainer/AppContainer.reducer";
import ChromeExtension from "../ChromeExtension/ChromeExtension";

type ExtensionBtnProps = {
    variant?: "default" | "low";
};

export default function ExtensionBtn({ variant = "default" }: ExtensionBtnProps) {
    const isExtensionInstalled = useSelector(selectIsExtensionInstalled);
    return <>{isExtensionInstalled ? null : <ChromeExtension variant={variant} />}</>;
}
