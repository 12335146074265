export enum EQueryKeys {
    SMS_CONVERSATIONS = "sms_conversations",
    EMAIL_CONVERSATIONS = "email_conversations",
    LINKEDIN_CONVERSATIONS = "linkedin_conversations",
    CANDIDATES = "candidates",
    PROJECTS = "projects",
    CANDIDATE_INFO = "candidate_info",
    WORKFLOWS = "workflows",
    RESCHEDULE_WORKFLOW = "reschedule_workflow",
    SWITCH_WORKFLOW = "switch_workflow",
    WORKFLOWS_LIST = "workflow_list",
    RETRIGGER_WORKFLOW = "retrigger_workflow",
}

export enum EChannelLabel {
    sms = "SMS",
    email = "Email",
    linkedin = "LinkedIn",
}

export enum EStatusLabel {
    sent = "Sent",
    engaged = "Engaged",
    responded = "Responded",
}

export enum EChannel {
    EMAIL = "email",
    SMS = "sms",
    LINKEDIN = "linkedin",
}

export enum EUser {
    USER = "USER",
    CANDIDATE = "CANDIDATE",
}

export enum EUserStatus {
    READ = "READ",
    UNREAD = "UNREAD",
}

export enum EStatus {
    SENT = "sent",
    ENGAGED = "engaged",
    RESPONDED = "responded",
}

export enum ESentiment {
    POSITIVE = "POSITIVE",
    NEGATIVE = "NEGATIVE",
    UNKNOWN = "UNKNOWN",
}

export enum EDashboardStatusLabel {
    ACTIVE = "Active",
    ON_HOLD = "On hold",
    CLOSED = "Closed",
}

export enum ESentimentLabel {
    POSITIVE = "Positive",
    NEGATIVE = "Negative",
    UNKNOWN = "Unknown",
}
